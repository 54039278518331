//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {checked, children, id, onClick} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './CopyBtnOnHover.css';
import btnIcoSrc from './../../../../assets/copy-btn-on-hover.svg';
import type {contentIsCopiedLabel, showContentIsCopied} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  onClick: onClick,
  showContentIsCopied?: showContentIsCopied,
  contentIsCopiedLabel?: contentIsCopiedLabel,
  checked?: checked,
  checkedComponent?: React.Node
};

const CopyBtnOnHover = (props: Props) => {
  return (
    <div className="CopyBtnOnHover__container" onClick={props.onClick}>
      <div className="CopyBtnOnHover__icon-container">
        {props.checked ? (
          <div className="CopyBtnOnHover__checked-icon"/>
        ) : null}
        {props.checkedComponent}
        <button type="button" id={props.id}
                className={baseClass("CopyBtnOnHover", props.addClasses)}>
          <img src={btnIcoSrc} alt={'CopyBtn'} />
        </button>
      </div>
      {props.showContentIsCopied ? props.contentIsCopiedLabel : props.children}
    </div>
  );
};

export default CopyBtnOnHover;