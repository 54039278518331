//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {formFieldName, id, onChange} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './NSwitcher.css';
import type {
  NSwitcherOptions,
  selectedOption
} from "../../../../services/flowTypes/componentFlowTypes";
import DisabledContentContainer from "../../Containers/DisabledContentContainer/DisabledContentContainer";
import text from "../../../../services/localization/text";


type Props = {
  id?: id,
  addClasses?: addClasses,
  options: NSwitcherOptions,
  onChange: onChange,
  value: selectedOption,
  name?: formFieldName,
  disabled?: boolean
};

class NSwitcher extends React.Component <Props> {
  render() {
    const options = this.props.options.map((option, index) => {
      if (this.props.value !== null && this.props.value !== undefined && option.value === this.props.value.value) {
        return (
          <div key={'tab-' + index} id={option.value} className={"NSwitcher__tab NSwitcher__tab_active"}>{option.label}</div>
        );
      } else {
        return (
          <div key={'tab-' + index} id={option.value} className={"NSwitcher__tab"}
               onClick={()=>this.props.onChange(option)}>{option.label}</div>
        );
      }
    });
    const switcherComponent = (
      <div id={this.props.id} data-name={this.props.name} className={baseClass("NSwitcher", this.props.addClasses)}>
        {options.length ? options : text.noOptionsToSelect}
      </div>
    );
    return this.props.disabled ? (
      <DisabledContentContainer disabled={this.props.disabled}>
        {switcherComponent}
      </DisabledContentContainer>
    ) : switcherComponent;
  }
}

export default NSwitcher;