import * as React from 'react';
import './DocumentFolderList.css';
import folderImgSrc from '../../../../assets/folder-icon.svg';
import folderHoveredImgSrc from '../../../../assets/folder-hovered-icon.svg';
import protectedFolderImgSrc from '../../../../assets/protected-folder-icon.svg';
import protectedFolderHoveredImgSrc from '../../../../assets/protected-folder-hovered-icon.svg';
import NHeading2 from "../../../simple/TextComponents/NHeading2/NHeading2";
import newFolderImg from '../../../../assets/new-folder-icon.svg';
import newFolderHoveredImg from '../../../../assets/new-folder-hovered-icon.svg';
import trashFolderImgSrc from '../../../../assets/trash-folder-icon.svg';
import trashFolderHoveredImgSrc from '../../../../assets/trash-folder-hovered-icon.svg';
import NCard from "../../../simple/Containers/NCard/NCard";
import NModal from "../../../simple/Modals/NModal/NModal";
import text from "../../../../services/localization/text";
import NFormGroup from "../../../simple/FormComponents/NFormGroup/NFormGroup";
import NInput from "../../../simple/FormComponents/NInput/NInput";
import type {
    agentId,
    contractId,
    documentFolderList,
    folderId,
    folderName, organizationId
} from "../../../../services/flowTypes/dataFlowTypes";
import type {itemType, permittedActionObj,} from "../../../../services/flowTypes/componentFlowTypes";
import api from "../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../services/apiInstance/apiErrorHandler";
import {connect} from "react-redux";
import documentFoldersActions from "../../../../store/organization/item/documentFolders/documentFoldersActions";
import RenderOnReady from "../../../service/RenderOnReady/RenderOnReady";
import Text1 from "../../../simple/TextComponents/Text1/Text1";
import { withRouter } from 'react-router-dom';
import {documentsPageIds} from "../../../../tests/testIds";
import {Link} from "react-router-dom";
import type {
    onInitDocumentFoldersState,
    onUpdateDocumentFoldersList
} from "../../../../services/flowTypes/propFnFlowTypes";
import type {errorObject, history, isReadyToRender, modalIsShown} from "../../../../services/flowTypes/appFlowTypes";
import Can from "../../../../services/permissions/Can";
import ability from "../../../../services/permissions/permissions";
import BtnEditAct from "../../../simple/Buttons/BtnEditAct/BtnEditAct";
import HoveredIcon from "../../HoveredIcon/HoveredIcon";
import handleCorrectOrgIdInResponse from "../../../../utils/handleCorrectOrgIdInResponse";
import RedirectToCorrectOrgUrl from "../../../service/RedirectToCorrectOrgUrl/RedirectToCorrectOrgUrl";

type Props = {
    itemType: itemType,
    itemId: contractId | agentId,
    pathname: string,
    folderId: folderId,
    folderList: documentFolderList,
    onInitDocumentFoldersState: onInitDocumentFoldersState,
    onUpdateDocumentFoldersList: onUpdateDocumentFoldersList,
    permittedActionObj: permittedActionObj,
    isAllFilesShown: boolean,
    history: history
};

type State = {
    modalIsShown: modalIsShown,
    deleteModalIsShown: modalIsShown,
    renameModalIsShown: modalIsShown,
    modalFolderName: string,
    isReadyToRender: isReadyToRender,
    errorObject: errorObject,
    folderName: folderName,
    correctOrganizationId: organizationId
};
const defineFolderPriority = (folderName) => {
    switch (folderName) {
        case "Trash":
            return 1;
        case "Дополнительные соглашения":
            return 2;
        case "Основные документы":
            return 3;
        default:
            return 0;
    }
}
class DocumentFolderList extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            modalIsShown: false,
            deleteModalIsShown: false,
            renameModalIsShown: false,
            modalFolderName: '',
            isReadyToRender: false,
            errorObject: null,
            folderName: '',
            correctOrganizationId: null
        };
    }
    defineGetFoldersListPromise = () => {
        if (this.props.itemType === 'contract') {
            return api.getContractFolderList;
        }
        if (this.props.itemType === 'agent') {
            return api.getAgentFolderList;
        }
        if (this.props.itemType === 'organizations') {
            return api.getOrganizationFolderList;
        }
    };
    handleResponse = (responseData) => {
        this.props.onUpdateDocumentFoldersList(responseData);
        this.setState({isReadyToRender: true});
    };
    getFolderList = () => {
        this.setState({isReadyToRender: false});
        this.props.onInitDocumentFoldersState();
        const promise = this.defineGetFoldersListPromise();
        if (promise) {
            promise(this.props.itemId)
                .then(response => {
                    handleCorrectOrgIdInResponse(this, response.data, this.setState, this.handleResponse);
                })
                .catch(error => {
                    this.hideModal();
                    this.setState({
                        errorObject: apiErrorHandler(error)
                    });
                });
        }
    };
    showModal = () => {
        this.setState({modalIsShown: true});
    };
    showDeleteModal = () => {
        this.setState({deleteModalIsShown: true});
    };
    showRenameModal = () => {
        const currentFolder = this.props.folderList.filter(item => {
            return item.id === this.props.folderId;
        })[0];
        this.setState({
            folderName: currentFolder.name,
            renameModalIsShown: true,
        });
    };
    hideModal = () => {
        this.setState({
            modalIsShown: false,
            modalFolderName: ''
        });
    };
    hideDeleteModal = () => {
        this.setState({deleteModalIsShown: false});
    };
    hideRenameModal = () => {
        this.setState({renameModalIsShown: false});
    };
    defineAddFolderPromise = () => {
        if (this.props.itemType === 'contract') {
            return api.addContractFolder;
        }
        if (this.props.itemType === 'agent') {
            return api.addAgentFolder;
        }
        if (this.props.itemType === 'organizations') {
            return api.addOrganizationFolder;
        }
    };
    defineDeleteFolderPromise = () => {
        if (this.props.itemType === 'contract') {
            return api.deleteContractFolder;
        }
        if (this.props.itemType === 'agent') {
            return api.deleteAgentFolder;
        }
        if (this.props.itemType === 'organizations') {
            return api.deleteOrganizationFolder;
        }
    };
    defineRenameFolderPromise = () => {
        if (this.props.itemType === 'contract') {
            return api.renameContractFolder;
        }
        if (this.props.itemType === 'agent') {
            return api.renameAgentFolder;
        }
        if (this.props.itemType === 'organizations') {
            return api.renameOrganizationFolder;
        }
    };
    addFolder(){
        const promise = this.defineAddFolderPromise();
        if (promise) {
            promise(this.props.itemId, this.state.modalFolderName)
                .then(response => {
                    this.props.onUpdateDocumentFoldersList(response.data);
                    this.hideModal();
                })
                .catch(error => {
                    this.hideModal();
                    this.setState({
                        isReadyToRender:true,
                        errorObject: apiErrorHandler(error)
                    });
                });
        }
    }
    selectInputTextOnFocus = (e:SyntheticFocusEvent<HTMLInputElement>) => {
        e.currentTarget.select();
    };
    handleModalNameChange = (e) => {
        this.setState({
            modalFolderName: e.target.value
        })
    };
    deleteFolder = () => {
        const promise = this.defineDeleteFolderPromise();
        if (promise) {
            promise(this.props.itemId, this.props.folderId)
                .then(response => {
                    this.props.onUpdateDocumentFoldersList(response.data);
                    this.hideDeleteModal();
                    this.props.history.push(this.props.pathname.substring(0, this.props.pathname.indexOf('/folder')));
                })
                .catch(error => {
                    this.hideDeleteModal();
                    this.setState({
                        isReadyToRender:true,
                        errorObject: apiErrorHandler(error)
                    });
                });
        }
    };
    handleFolderNameChange = (e) => {
        this.setState({
            folderName: e.target.value
        });
    };
    renameFolder = () => {
        const promise = this.defineRenameFolderPromise();
        if (promise) {
            promise(this.props.itemId, this.props.folderId, this.state.folderName)
                .then(response => {
                    this.props.onUpdateDocumentFoldersList(response.data);
                    this.hideRenameModal();
                })
                .catch(error => {
                    this.hideRenameModal();
                    this.setState({
                        isReadyToRender:true,
                        errorObject: apiErrorHandler(error)
                    });
                });
        }
    };
    sortFolders = (a, b) => {
        return defineFolderPriority(b.name) - defineFolderPriority(a.name);
    };
    componentDidMount(){
        this.getFolderList();
    }
    render(){
        const currentFolder = this.props.folderId ? this.props.folderList.filter(item => {
            return item.id === this.props.folderId;
        })[0] : null;
        const filteredFolderList = this.props.folderList ? this.props.folderList.sort(this.sortFolders) : [];
        let folderItems = filteredFolderList.map((item, i) => {
            const url = this.props.pathname + '/folder/' + item.id;
            const imgSrc = item.isProtected ?
              (item.name === 'Trash' ? trashFolderImgSrc : protectedFolderImgSrc)
               : folderImgSrc;
            const imgHoveredSrc = item.isProtected ?
              (item.name === 'Trash' ? trashFolderHoveredImgSrc : protectedFolderHoveredImgSrc)
               : folderHoveredImgSrc;
            return (
                <Link to={url} key={`folder${i}`} id={documentsPageIds.folderLink(i)} className='DocumentFolderItem p_2du'>
                    <div className="DocumentFolderItem__iconContainer">
                        <HoveredIcon baseIconSrc={imgSrc} hoveredIconSrc={imgHoveredSrc}/>
                    </div>
                    <div className='DocumentFolderItem__docnumber pl_2du mb_1du'>{item.documentsNumber}</div>
                    <div className='DocumentFolderItem__docname' title={item.name}>{item.name}</div>
                </Link>
            );
        });
        if (ability.can('usePermission', this.props.permittedActionObj.create)) {
            folderItems.push((
              <div className="DocumentFolderItem__newFolder" key='newFolder' id={documentsPageIds.showAddFolderModal}
                   onClick={this.showModal}>
                  <HoveredIcon baseIconSrc={newFolderImg} hoveredIconSrc={newFolderHoveredImg}/>
              </div>
            ));
        }
        let backUrl = this.props.folderId ? '#' + this.props.pathname.substring(0, this.props.pathname.indexOf('/folder')) : null;
        return (
            <>
                <NCard addClasses='NCard__wide-width p_2du mb_3du'>
                    {this.props.folderId ? (
                        <NHeading2 addClasses='flex_jc-sb'>
                            <div>
                                <a href={backUrl} id={documentsPageIds.backLink} className='DocumentFolderItem__link-back'>{text.folder.folders}</a>
                                <span className='ml_3du'>{currentFolder ? currentFolder.name : ''}</span>
                            </div>
                            {currentFolder && currentFolder.hasOwnProperty('isProtected') && currentFolder.isProtected ?
                              null : (
                            <div className='flex_jc-sb'>
                                <Can do={'usePermission'} on={this.props.permittedActionObj.update}>
                                    <BtnEditAct addClasses={"ml_3du"}
                                             id={documentsPageIds.folderEdit}
                                                onClick={this.showRenameModal} >{text.editBtnLabel}</BtnEditAct>
                                </Can>
                                  <Can do={'usePermission'} on={this.props.permittedActionObj.delete}>
                                      <BtnEditAct addClasses={"BtnEditAct_delete ml_3du"}
                                              id={documentsPageIds.folderDelete}
                                                  onClick={this.showDeleteModal} >{text.deleteBtnLabel}</BtnEditAct>
                                  </Can>
                            </div>
                            )}
                        </NHeading2>
                    ) : (
                        <>
                            <div className="mb_3du flex_jc-sb">
                                <NHeading2>
                                    {this.props.isAllFilesShown ? '' : text.folder.folders}
                                </NHeading2>

                                <Text1 addClasses='Text1_link flex' href='#' id={documentsPageIds.toggleAllFiles}
                                       onClick={(e) => {this.props.toggleShowAllFiles(e)}}>
                                    {this.props.isAllFilesShown ? text.filesAndFolders : text.allFiles}</Text1>
                            </div>
                            {this.props.isAllFilesShown ? null : (
                              <div className='flex flex_wrap mb_1du'>{folderItems}</div>
                            )}

                        </>
                    )}
                </NCard>
                {this.state.modalIsShown ? (
                    <NModal
                        isModalDocumentEdit={true}
                        modalTitle={text.folder.add}
                        modalActionBtnText={text.btnAdd}
                        modalCancelBtnText={text.cancel}
                        onModalCancelBtnClick={this.hideModal}
                        onModalActionBtnClick={() => {this.addFolder()}}
                    >
                        <NFormGroup label={text.folder.folderName} addClasses={'mt_3du mb_3du'}>
                            <NInput id={documentsPageIds.addFolder} value={this.state.modalFolderName}
                                    onFocus={this.selectInputTextOnFocus}
                                    onChange={(e) => {this.handleModalNameChange(e)}}
                            />
                        </NFormGroup>
                    </NModal>
                ) : null}
                {this.state.deleteModalIsShown && currentFolder ? (
                    <NModal
                        isModalDocumentEdit={true}
                        modalTitle={text.folder.delete}
                        modalActionBtnText={text.btnDel}
                        modalCancelBtnText={text.cancel}
                        onModalCancelBtnClick={this.hideDeleteModal}
                        onModalActionBtnClick={() => {this.deleteFolder()}}
                    >
                        <NHeading2 addClasses={'mt_3du mb_3du'}>
                            {text.folder.deleteText1 + " "}
                            <span className={"NHeading2 NHeading2__span-link-color"}>{currentFolder.name + " "}</span>
                            {text.folder.deleteText2} {currentFolder.documentsNumber} {text.folder.deleteText3}
                        </NHeading2>
                    </NModal>
                ) : null}
                {this.state.renameModalIsShown && currentFolder ? (
                    <NModal
                        isModalDocumentEdit={true}
                        modalTitle={text.folder.rename}
                        modalActionBtnText={text.save}
                        modalCancelBtnText={text.cancel}
                        onModalCancelBtnClick={this.hideRenameModal}
                        onModalActionBtnClick={() => {this.renameFolder()}}
                    >
                        <NFormGroup label='Новое название' addClasses={'mt_2du mb_3du'}>
                            <NInput value={this.state.folderName}
                                    id={documentsPageIds.renameFolder}
                                    onChange={(e) => this.handleFolderNameChange(e)}/>
                        </NFormGroup>
                    </NModal>
                ) : null}
                <RedirectToCorrectOrgUrl correctOrganizationId={this.state.correctOrganizationId} history={this.props.history}>
                    <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
                </RedirectToCorrectOrgUrl>
            </>
        ) ;
    };
}

const mapStateToProps = state => {
    return {
        folderList: state.organization.item.documentFolders
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitDocumentFoldersState: () => dispatch({type: documentFoldersActions.ITEM_FOLDERS_STATE_INIT}),
        onUpdateDocumentFoldersList: (value) => dispatch({type: documentFoldersActions.ITEM_FOLDERS_LIST_SET, value: value})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentFolderList));