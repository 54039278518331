//@flow
import * as React from 'react';
import Heading5 from "../../../../../../../../../components/simple/TextComponents/Heading5/Heading5";
import Text1 from "../../../../../../../../../components/simple/TextComponents/Text1/Text1";
import text from "../../../../../../../../../services/localization/text";
import Can from "../../../../../../../../../services/permissions/Can";
 import {organizationParamsPageIds} from "../../../../../../../../../tests/testIds";
import NCard from "../../../../../../../../../components/simple/Containers/NCard/NCard";
import type {objectParam} from "../../../../../../../../../services/flowTypes/dataFlowTypes";
import DeleteItemParam from "./DeleteItemParam/DeleteItemParam";
import type {isSupLevel, itemType} from "../../../../../../../../../services/flowTypes/componentFlowTypes";
import type {index} from "../../../../../../../../../services/flowTypes/appFlowTypes";
import {initUpdateOrganizationParams} from "../../../../../../../../../services/flowTypes/propFnFlowTypes";
import EditItemParam from "./EditItemParam/EditItemParam";
import SecText from "../../../../../../../../../components/simple/TextComponents/SecText/SecText";
import {
  globalPermissionsStrValues,
  permissionsStrValues
} from "../../../../../../../../../configData/permissionsStrValues";

type Props = {
  objectParam: objectParam,
  itemType: itemType,
  paramIndex: index,
  initUpdateOrganizationParams: initUpdateOrganizationParams,
  isSupLevel?: isSupLevel
};

const ItemParamCard = (props: Props) => {
  const permissionType = props.isSupLevel ? 'useGlobalPermission' : 'usePermission';
  const permittedAction = props.isSupLevel ? globalPermissionsStrValues.admin : permissionsStrValues.organizationParams.update;
  return (
    <NCard addClasses={'NCard__wide-width mb_1du'}>
      <Heading5 addClasses={'Heading5_no-underline mb_1du'}>{props.objectParam.label}</Heading5>
      <Text1 addClasses={'mb_1du'}>{props.objectParam.name}</Text1>
      <div className="flex_jc-sb">
        <SecText addClasses={'SecText_lh16'}>{text.paramType[props.objectParam.type]}</SecText>
        <div className="flex">
          <Can do={permissionType} on={permittedAction}>
            <DeleteItemParam
              itemType={props.itemType}
              paramIndex={props.paramIndex}
              objectParamName={props.objectParam.name}
              initUpdateOrganizationParams={props.initUpdateOrganizationParams}
            />
          </Can>
          <Can do={permissionType} on={permittedAction}>
            <EditItemParam
              itemType={props.itemType}
              objectParamId={props.objectParam.id}
              paramIndex={props.paramIndex}
              btn={<div className="BtnEditAct" id={organizationParamsPageIds.linkToEdit(props.paramIndex)}>{text.btnEdit}</div>}
              editOrAdd={'edit'}
              initUpdateOrganizationParams={props.initUpdateOrganizationParams}
            />
          </Can>
        </div>
      </div>
    </NCard>
  );
};

export default ItemParamCard;    