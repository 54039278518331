//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {contractId, groupFinTermsSearch} from "../../../../../../services/flowTypes/dataFlowTypes";
import type {errorObject, isReadyToRender} from "../../../../../../services/flowTypes/appFlowTypes";
import api from "../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import debounce from 'lodash.debounce';

type Props = {
  contractId: contractId,
  groupFinTermsSearch: groupFinTermsSearch,
  handleResponse: Function
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  isFirstRequestAfterMount: boolean
};


class ContractFinTermsFetcher extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true,
      isFirstRequestAfterMount: true
    };
    this.debounceContractFinTermsRequest = debounce(this.getContractFinTerms, 500);
  }
  getContractFinTerms = () => {
    if (this.state.isFirstRequestAfterMount) {
      this.setState({isFirstRequestAfterMount: false});
    } else {
      this.setState({isReadyToRender: false});
    }

    api.getContractFinTermsList(this.props.contractId, this.props.groupFinTermsSearch)
      .then(response => {
        this.props.handleResponse(response.data);
        this.setState({isReadyToRender: true});
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  componentDidUpdate(prevProps){
    if (prevProps.groupFinTermsSearch !== this.props.groupFinTermsSearch) {
      this.debounceContractFinTermsRequest();
    }
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    groupFinTermsSearch: state.organization.item.groupFinTermsSearch
  };
};
export default connect(mapStateToProps, null)(ContractFinTermsFetcher);