//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {children, id, onClick} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './Text3.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  onClick?: onClick
};

const Text3 = (props: Props) => {
  return (
    <div id={props.id} className={baseClass("Text3", props.addClasses)} onClick={props.onClick}>
      {props.children}</div>
  );
};

export default Text3;