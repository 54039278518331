//@flow
import * as React from 'react';
import connect from "react-redux/es/connect/connect";
import api from "../../../services/apiInstance/api";
import type {
  errorObject,
  history,
  isReadyToRender,
  stateToCompare
} from "../../../services/flowTypes/appFlowTypes";
import apiErrorHandler from "../../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../../components/service/RenderOnReady/RenderOnReady";
import type {notificationResponse} from "../../../services/flowTypes/dataFlowTypes";
import type {onGetNotificationSettings} from "../../../services/flowTypes/propFnFlowTypes";
import notificationSettingsActions from "../../../store/notificationSettings/notificationSettingsActions";
import ItemControls from "../ItemControls/ItemControls";

type Props = {
  history: history,
  notificationSettings: notificationResponse,
  onGetNotificationSettings: onGetNotificationSettings
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  stateToCompare: stateToCompare
};

class NotificationSettingsControls extends React.Component <Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true,
      stateToCompare: {}
    };
  }
  requestUpdate = () => {
    api.updateNotificationSettings(this.props.notificationSettings)
      .then(response => {
        this.props.onGetNotificationSettings(response.data);
        this.setState({isReadyToRender: true});
        this.props.history.goBack();
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  sendRequest = () => {
    this.setState({isReadyToRender: false});
    this.requestUpdate();
  };
  cancel = () => {
    this.props.history.goBack();
  };
  componentDidMount(){
    this.setState({stateToCompare: JSON.stringify(this.props.notificationSettings)});
  }
  componentDidUpdate(prevProps){
    if (!prevProps.notificationSettings.length && this.props.notificationSettings.length ) {
      this.setState({stateToCompare: JSON.stringify(this.props.notificationSettings)});
    }
  }
  render(){
    const submitIsDisabled = this.state.stateToCompare === JSON.stringify(this.props.notificationSettings);
    const saveFn = this.sendRequest;
    return(
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <ItemControls
          submitIsDisabled={submitIsDisabled}
          saveFn={saveFn}
          cancelFn={this.cancel}
        />
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    notificationSettings: state.notificationSettings
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetNotificationSettings: (value) => dispatch({type: notificationSettingsActions.NOTIFICATION_SETTINGS_UPDATE, value})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsControls);