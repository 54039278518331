//@flow
import * as React from 'react';
import {connect} from "react-redux";
import api from "../../../services/apiInstance/api";
import organizationDataActions from "./../../../store/organization/organizationData/organizationDataActions";
import {onGetOrganizationDataProp} from "../../../services/flowTypes/propFnFlowTypes";
import Routes from "../Routes/Routes";
import type {organizationData, organizationId} from "../../../services/flowTypes/dataFlowTypes";
import GetPrerenderData from "./GetPrerenderData";

type Props = {
  onGetOrganizationDataProp: onGetOrganizationDataProp,
  organizationId: organizationId,
  organizationData: organizationData
};

class GetOrganizationData extends React.Component <Props> {
  render() {
    const fetchFnArr = [
      {
        fetch: api.getContractTypes, fetchArg: this.props.organizationId, propName: 'contractTypes'
      },
      {
        fetch: api.getAgentsList, propName: 'agentsList'
      },
      {
        fetch: api.getContractsList, propName: 'contractsList'
      },
      {
        fetch: api.getActTemplatesList, propName: 'actTemplatesList'
      },
      {
        fetch: api.getUsersList, propName: 'usersList'
      },
      {
        fetch: api.getTagsList, fetchingTagsList: true, propName: 'tagsList'
      },
      {
        fetch: api.getConfigFinTerms, propName: 'configFinTerms'
      },
      {
        fetch: api.getStatusesLists, propName: 'statuses'
      },
      {
        fetch: api.getGroupFinTermsList, propName: 'groupFinTermsList'
      }
    ];
    return (
      <GetPrerenderData fetchFnArr={fetchFnArr} storeDataObj={this.props.organizationData}
                        setFn={this.props.onGetOrganizationDataProp}>
        <Routes organizationId={this.props.organizationId}/>

      </GetPrerenderData>
    );
  }
}
const mapStateToProps = state => {
  return {
    organizationData: state.organization.organizationData,
    organizationId: state.organization.organizationId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetOrganizationDataProp: (prop, data) => dispatch({type: organizationDataActions.ORGANIZATION_DATA_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GetOrganizationData);