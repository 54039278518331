//@flow
import finTermsEditActions from './finTermsEditActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {finTerm} from "../../../../services/flowTypes/dataFlowTypes";
import update from 'immutability-helper';


type State = Array<finTerm>;
type reducerAction = action;
const initialState = [];

const finTermsEditReducer = (state:State = initialState, action:reducerAction) => {
  switch (action.type) {
    case finTermsEditActions.FIN_TERMS_EDIT_SET:
      return action.value;
    case finTermsEditActions.FIN_TERM_DELETE:
      return update(state, {[action.value]: {status: {$set: 'DELETED'}}});
    case finTermsEditActions.FIN_TERM_STATUS_DELETE_SET:
      return update(state, {$splice: [[action.value, 1]]});
    case finTermsEditActions.FIN_TERM_ADD:
      return update(state, {$unshift: [action.value]});
    case finTermsEditActions.FIN_TERM_PROP_UPDATE:
      return update(state, {
        [action.value.finTermIndex]: {[action.value.finTermPropName]: {$set: action.value.propValue}}
      });
    case finTermsEditActions.FIN_TERM_PARAM_UPDATE:
      const {finTermIndex, finTermParamsRowIndex, finTermParamIndex, propValue} = action.value;
      return update(state, {
        [finTermIndex]: {params: {[finTermParamsRowIndex]: {[finTermParamIndex]: {value: {$set: propValue}}}}}
      });
    case finTermsEditActions.FIN_TERM_PARAMS_ROW_ADD:
      return update(state, {[action.value.finTermIndex]: {params: {$push: [action.value.paramsRow]}}});
    case finTermsEditActions.FIN_TERM_PARAMS_ROW_DELETE:
      return update(state, {[action.value.finTermIndex]: {params: {$splice: [[action.value.finTermParamsRowIndex, 1]]}}});
    default:
      return state;
  }
};

export default finTermsEditReducer;