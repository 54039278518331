//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {children, id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './DisabledContentContainer.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  disabled: boolean
};

const DisabledContentContainer = (props: Props) => {
  return (
    props.disabled ?
    <div id={props.id} className={baseClass("DisabledContentContainer", props.addClasses)}>
      {props.children}
      <div className="DisabledContentContainer__over-screen" />
    </div> : props.children
  );
};

export default DisabledContentContainer;