//@flow
import * as React from 'react';
import {contractFinTermsMenuItems} from "../../../../../../configData/contractMenuItems";
import RowMenu from "../../../../../../components/simple/Containers/RowMenu/RowMenu";
import type {contractId, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";

type Props = {
  contractId: contractId,
  organizationId: organizationId
};

const ContractSourcesSubMenu = (props:Props) => {
  const menuItemsData = contractFinTermsMenuItems(props.organizationId, props.contractId);
  return <RowMenu menuItemsData={menuItemsData} addClasses={"ContractFinTermMenu"}/>
};

export default ContractSourcesSubMenu;