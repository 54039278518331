//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  finTermIndex,
  finTermParamsRowIndex, finTermParamsRowsLength
} from "../../../../../../../../../../../../services/flowTypes/componentFlowTypes";
import './FinTermParamsRow.css';
import FinTermParam from "./FinTermParam/FinTermParam";
import BtnEditAct from "../../../../../../../../../../../../components/simple/Buttons/BtnEditAct/BtnEditAct";
import text from "../../../../../../../../../../../../services/localization/text";
import {onDeleteParamsRow} from "../../../../../../../../../../../../services/flowTypes/propFnFlowTypes";
import finTermsEditActions
  from "../../../../../../../../../../../../store/organization/item/finTermsEdit/finTermsEditActions";
import RateParamsValidatorWrapper from "./RateParamsValidator/RateParamsValidatorWrapper";
import {groupFinTermEditPageIds} from "../../../../../../../../../../../../tests/testIds";
import type {groupFinTermType} from "../../../../../../../../../../../../services/flowTypes/dataFlowTypes";
import getUniqueKey from "../../../../../../../../../../../../utils/getUniqueKey";
import {
  oneInArrRequiredTypes,
  onlyOneRowParamsTypeArr
} from "../../../../../../../../../../../../configData/groupFinTermTypesFeatures";

type Props = {
  finTermIndex: finTermIndex,
  finTermParamsRowIndex: finTermParamsRowIndex,
  finTermParamsRowLength: finTermParamsRowsLength,
  onDeleteParamsRow: onDeleteParamsRow,
  readonly?: boolean,
  groupFinTermType: groupFinTermType
};



class FinTermParamsRow extends React.Component <Props, void> {
  render() {
    let finTermParams = [];
    for (let i=0; i<this.props.finTermParamsRowLength; i++) {
      finTermParams.push(
        <FinTermParam
          readonly={this.props.readonly}
          key={getUniqueKey()}
          finTermIndex={this.props.finTermIndex}
          finTermParamsRowIndex={this.props.finTermParamsRowIndex}
          finTermParamIndex={i}
          groupFinTermType={this.props.groupFinTermType}
        />);
    }
    const onDeleteClick = () => this.props.onDeleteParamsRow(this.props.finTermIndex, this.props.finTermParamsRowIndex);
    const classNameByType = `FinTermParamsRow FinTermParamsRow_${this.props.groupFinTermType}`;
    return (
      <div className={this.props.readonly ? classNameByType + ' FinTermParamsRow_readonly mb_1du' : classNameByType + ' mb_1du'}>
        {finTermParams}
        {this.props.readonly || onlyOneRowParamsTypeArr.includes(this.props.groupFinTermType)? null :
            <BtnEditAct id={groupFinTermEditPageIds.buttonDeleteFinTermRow(this.props.finTermIndex, this.props.finTermParamsRowIndex)}
                        addClasses={'BtnEditAct_delete as_fe mb_2du'} onClick={onDeleteClick}>{text.btnDel}</BtnEditAct>
        }
        {oneInArrRequiredTypes.includes(this.props.groupFinTermType) ? (
          <RateParamsValidatorWrapper finTermIndex={this.props.finTermIndex}
                                      finTermParamsRowIndex={this.props.finTermParamsRowIndex} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    finTermParamsRowLength: state.organization.item.finTermsEdit[ownProps.finTermIndex].params[ownProps.finTermParamsRowIndex].length,
    groupFinTermType: state.organization.item.groupFinTermsEdit.type
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDeleteParamsRow: (finTermIndex, finTermParamsRowIndex) => dispatch({
      type: finTermsEditActions.FIN_TERM_PARAMS_ROW_DELETE,
      value: {finTermIndex, finTermParamsRowIndex}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinTermParamsRow);