//@flow
import * as React from 'react';
import {connect} from "react-redux";
import reportsActionTypes from "../../../../../store/organization/reports/reportsActions";
import {
  onGetReports
} from "../../../../../services/flowTypes/propFnFlowTypes";
import type {
  organizationId,
  reportAccountStatusName,
  reportsSearch, totalReportsNumber
} from "../../../../../services/flowTypes/dataFlowTypes";
import RenderOnReady from "../../../../../components/service/RenderOnReady/RenderOnReady";
import type {errorObject, isReadyToRender} from "../../../../../services/flowTypes/appFlowTypes";
import api from "../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../services/apiInstance/apiErrorHandler";
import type {
  isGetReportsOnMount
} from "../../../../../services/flowTypes/componentFlowTypes";
import debounce from 'lodash.debounce';

type Props = {
  onGetReports: onGetReports,
  reportsSearch: reportsSearch,
  organizationId: organizationId,
  statusFromParams: reportAccountStatusName,
  isGetReportsOnMount: isGetReportsOnMount,
  total: totalReportsNumber
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};
// let timerID;
class ReportsFetcher extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true
    };
    this.debounceReportsRequest = debounce(this.getReports, 500);
  }
  getReportsSearchData = () => {
    const reportsSearchData = {...this.props.reportsSearch};
    reportsSearchData.userId = this.props.reportsSearch.userId === "ALL" ? "" : this.props.reportsSearch.userId;
    reportsSearchData.contractId = this.props.reportsSearch.contractId === "ALL" ? "" : this.props.reportsSearch.contractId;
    reportsSearchData.actTemplateId = this.props.reportsSearch.actTemplateId === "ALL" ? "" : this.props.reportsSearch.actTemplateId;
    reportsSearchData.payerId = this.props.reportsSearch.payerId === "ALL" ? "" : this.props.reportsSearch.payerId;
    reportsSearchData.payeeId = this.props.reportsSearch.payeeId === "ALL" ? "" : this.props.reportsSearch.payeeId;

    if (this.props.statusFromParams !== "ALL") {
      reportsSearchData.reportStatus = this.props.reportsSearch.reportStatus === "ALL" ?
        "" : this.props.reportsSearch.reportStatus;
    } else {
      reportsSearchData.reportStatus = '';
    }
    reportsSearchData.organizationId = this.props.organizationId;
    reportsSearchData.reportAccountStatus = this.props.reportsSearch.reportAccountStatusFromPath !== "ALL" ?
      this.props.reportsSearch.reportAccountStatusFromPath :
      this.props.reportsSearch.reportAccountStatus === "ALL" ?
          "" : this.props.reportsSearch.reportAccountStatus;

    delete reportsSearchData.reportAccountStatusFromPath;
    return reportsSearchData;
  };
  getReports = () => {
    this.setState({isReadyToRender: false});
    const reportsSearchData = this.getReportsSearchData();
    api.getReportsList(reportsSearchData)
      .then((response) => {
        this.props.onGetReports(response.data);
        this.setState({isReadyToRender: true});
      })
      .catch((error) => {
        this.setState({
          errorObject: apiErrorHandler(error)
        });
      });
  };
  componentDidUpdate(prevProps){
    // !!!!! find how to compare objects
    if (prevProps.reportsSearch !== this.props.reportsSearch) {
      this.debounceReportsRequest();
    }
  }
  componentDidMount(){
    // if (this.props.isGetReportsOnMount) {
      //to prevent bug when componentDidMount api call is handled later than componentDidUpdate call
      // this.getReports('cDM');
    // }
    // timerID = setInterval(this.getReports, 10000);
  }
  componentWillUnmount(){
    // clearInterval(timerID);
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    reportsSearch: state.organization.reportsSearch,
    organizationId: state.organization.organizationId,
    advancedSearchIsShown: state.organization.reportsSearch.advancedSearchIsShown,
    total: state.organization.reports.total
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetReports: (value) => dispatch({type: reportsActionTypes.GET_REPORTS, value: value}),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportsFetcher);    