//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import type {onClick} from "../../../../services/flowTypes/appFlowTypes";

type Props = {
  onClick: onClick,
  addClasses?: addClasses,
  isActive?: boolean
};

const RowMenuOverflowBtn = (props: Props) => {
  const className = props.isActive ? "RowMenuOverflowBtn RowMenuOverflowBtn_active" : "RowMenuOverflowBtn";
  return (
    <div className={baseClass(className, props.addClasses)} onClick={props.onClick}>
      <div className="RowMenuOverflowBtn__dots"/>
    </div>
  );
};

export default RowMenuOverflowBtn;    