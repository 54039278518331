// import * as React from 'react';

export const testChildString = "Test Content";
export const testCssClass = "test-css-class";
export const testSelectorId = "testSelectorId";
export const testHtmlFor = "testInputId";
export const testPlaceholder = "Test placeholder";
export const testFormFieldValue = "Test value";
export const testFormFieldName = "testName";

export const testTags = ['test tag 1', 'test tag 2', 'test tag 3'];
export const testAgentListItem1 = {id: 1, name: "Test Agent 1"};
export const testAgentListItem2 = {id: 2, name: "Test Agent 2"};
export const testAgentListItem3 = {id: 3, name: "Test Agent 3"};
export const testAgentsList = [testAgentListItem1, testAgentListItem2, testAgentListItem3];
export const testDoc1 = "test-doc.docx";
export const testDoc2 = "test-document.pdf";
export const testDoc3 = "test-img.jpg";
export const testDocsList = [testDoc1, testDoc2, testDoc3];
export const testFinTermsIdsList = [11, 12, 13];
export const testActTemplatesIdsList = [21, 22, 23];
export const testContractType1 = {id: 1, name: "Лицензионное соглашение"};
export const testContractType2 = {id: 2, name: "Договор"};
export const testContractTypesList = [testContractType1, testContractType2];

export const testMerchantParams = [
  {source: "PNE", merchantId: ["234", "76", "89"] },
  {source: "PNE_EU", merchantId: ["24", "756", "189"] }
];

export const ComponentWithError = ()=> { throw new Error()};

export const testSbcOrg = {
  id: 1,
  name: "ЭсБиСи Технологии",
  status: 'ACTIVE',
  createDate: '2018-07-08',
  organizationCode: 'XXX-YYY-ZZZZ',
  locale: 'ru',
  sourceNames: ['PNE', 'MANUAL'],
  currencyCodes: ['EUR', 'RUB', 'USD']
};
export const testOrg1 = {
  id: 3,
  name: 'Super Puper Organization',
  status: 'ACTIVE',
  createDate: '2018-07-08',
  organizationCode: 'XXX-YYY-ZZZZ',
  locale: 'ru',
  sourceNames: ['PNE', 'MANUAL'],
  currencyCodes: ['EUR', 'RUB', 'USD']
};
export const testOrg2 = {
  id: 18,
  name: 'Secret Organization',
  status: 'ACTIVE',
  createDate: '2018-09-08',
  organizationCode: 'XXX-000-777',
  locale: 'en',
  sourceNames: ['PNE'],
  currencyCodes: ['EUR', 'RUB', 'USD']
};
export const testOrg3 = {
  id: 27,
  name: 'Famous Organization',
  status: 'ACTIVE',
  createDate: '2018-09-09',
  organizationCode: '111-000-777',
  locale: 'en',
  sourceNames: ['PNE'],
  currencyCodes: ['EUR', 'RUB', 'USD']
};
export const testOrgList = {
  total: 3,
  myOrganizations: [testSbcOrg, testOrg1, testOrg2, testOrg3]
};

export const testUser1 = {
  id: 3,
  name: 'General Pavlov',
  status: 'CREATED',
  email: 'pavlov@mail.ru',
  phone: '8-888-789-09-65',
  userPermissions: [
    {organizationId: 1, role: 'ADMIN'},
    {organizationId: 2, role: 'LAWYER'},
    {organizationId: 3, role: 'ACCOUNTANT'},
  ]
};
export const testUser2 = {
  id: 4,
  name: 'Michael Gerome',
  status: 'ACTIVE',
  email: 'mg@gmail.com',
  phone: '8-777-789-09-65',
  userPermissions: [
    {organizationId: 1, role: 'ADMIN'},
    {organizationId: 2, role: 'LAWYER'},
    {organizationId: 3, role: 'ACCOUNTANT'},
  ]
};
export const testUser3 = {
  id: 6,
  name: 'Anton Frolov',
  status: 'ACTIVE',
  email: 'frol@mail.ru',
  phone: '8-888-018-09-65',
  userPermissions: [
    {organizationId: 1, role: 'ADMIN'},
    {organizationId: 2, role: 'LAWYER'},
    {organizationId: 3, role: 'ACCOUNTANT'},
  ]
};

export const testUsersList = {
  total: 3,
  users: [testUser1, testUser2, testUser3]
};

export const testNotificationResponse = [
  {orgId: 1, notifications: [
      {notificationCategory: 'Notifications on contracts actions', turnedOn: true},
      {notificationCategory: 'Notifications on agents actions', turnedOn: true},
      {notificationCategory: 'Notifications on reports actions', turnedOn: true},
      {notificationCategory: 'Notifications on linked contracts actions', turnedOn: true},
      {notificationCategory: 'Notifications on linked agents actions', turnedOn: true}
    ]},
  {orgId: 2, notifications: [
      {notificationCategory: 'Notifications on contracts actions', turnedOn: true},
      {notificationCategory: 'Notifications on agents actions', turnedOn: false},
      {notificationCategory: 'Notifications on reports actions', turnedOn: false},
      {notificationCategory: 'Notifications on linked contracts actions', turnedOn: true},
      {notificationCategory: 'Notifications on linked agents actions', turnedOn: true}
    ]},
  {orgId: 3, notifications: [
      {notificationCategory: 'Notifications on contracts actions', turnedOn: true},
      {notificationCategory: 'Notifications on agents actions', turnedOn: false},
      {notificationCategory: 'Notifications on reports actions', turnedOn: false},
      {notificationCategory: 'Notifications on linked contracts actions', turnedOn: true},
      {notificationCategory: 'Notifications on linked agents actions', turnedOn: false}
    ]},
];

const testAddAgreement = {
  id: 23,
  fileId: 721,
  contractId: 1,
  name: 'Test additional agreement for test purpose additional agreement for test purpose',
  number: 'AA-8398-38892038-38',
  description: '/src/pages/Contracts/pages/Contract/pages/ActTemplatesEdit/actTemplatesEdit/ReportTagsExpressions.js  Line 28:  Unexpected template string expression  no-template-curly-in-string  Line 32:  Unexpected template string expression  no-template-curly-in-string  Line 40:  Unexpected template string expression  no-template-curly-in-string',
  startDate: '2020-09-30',
  endDate: '',
  addStatus: 'ACTIVE'
};
export const testAddAgreementsListResponse = [testAddAgreement, testAddAgreement, testAddAgreement, testAddAgreement];

export const testFiles = [
  {
    createDate: "2020-05-15T13:19:04.144+03:00",
    fileDescription: "",
    fileId: 2142,
    folderId: 90,
    folderName: "Дополнительные соглашения",
    indexStatus: "INDEXED",
    name: "файл какой-то чтоб скачать.pdf",
    size: 43556
  },
  {
    createDate: "2019-11-01T15:39:53.265+03:00",
    fileDescription: "",
    fileId: 659,
    folderId: 28,
    folderName: "folder 8",
    indexStatus: "INDEXED",
    name: "test356.txt",
    size: 333154
  },
  {
    createDate: "2019-11-01T15:39:30.339+03:00",
    fileDescription: "",
    fileId: 658,
    folderId: 34,
    folderName: "folder 6",
    indexStatus: "INDEXED",
    name: "test3.txt",
    size: 333154
  }
];

const testAddServiceSheet = {
  currency: 'RUB',
  totalAmount: '30100,45',
  calculatedItems: [
    {index: 0, addServiceTypeId: 1, price: '100,15', number: 3, amount: '300,45'},
    {index: 1, addServiceTypeId: 1, price: '100,15', number: 3, amount: '300,45'},
    {index: 2, addServiceTypeId: 1, price: '100,15', number: 3, amount: '300,45'}
  ]
};
const testAddService = {
  id: 23,
  status: 'ACTIVE',
  nomenNumber1c: '2334ff 5644',
  projectName: 'ttt ddd fff gggg',
  name: 'Test Additional Service',
  userId: 1,
  date: '2020-09-01',
  payoutPeriod: 'MONTHLY',
  ignoreInExport: false,
  isDetachedInvoice: false,
  actTemplateId: 434,
  files: [],
  // tempFileId: tempFileId,
  // tempFileDescription: fileDescription,
  contractId: 1,
  sheets: [testAddServiceSheet]
};

export const testAddServicesListResponse = [testAddService, testAddService, testAddService];

const testAgentTermGate1 = {
  gateId: 323,
  source: "PNE",
  terminals: [
    {terminalId: 123, groupFinTerms: [{groupFinTermId: 24, groupFinTermName: "Test ddd"},
        {groupFinTermId: 27, groupFinTermName: "Test ccc sdfa sadfafsd asdfsafs"}]},
    {terminalId: 127, groupFinTerms: [{groupFinTermId: 24, groupFinTermName: "Test ddd"}]}
  ]
};
const testAgentTermGate2 = {
  gateId: 423,
  source: "PNE",
  terminals: [
    {terminalId: 123, groupFinTerms: [{groupFinTermId: 24, groupFinTermName: "Test ddd"},
        {groupFinTermId: 27, groupFinTermName: "Test ccc sdfa sadfafsd asdfsafs"}]},
    {terminalId: 127, groupFinTerms: [{groupFinTermId: 24, groupFinTermName: "Test ddd"}]}
  ]
};
const testAgentTermGate3 = {
  gateId: 523,
  source: "PNE",
  terminals: [
    {terminalId: 123, groupFinTerms: [{groupFinTermId: 24, groupFinTermName: "Test ddd"},
        {groupFinTermId: 27, groupFinTermName: "Test ccc sdfa sadfafsd asdfsafs"}]},
    {terminalId: 127, groupFinTerms: [{groupFinTermId: 24, groupFinTermName: "Test ddd"}]}
  ]
};
const testAgentTermGate4 = {
  gateId: 523,
  source: "PNE_EU",
  terminals: [
    {terminalId: 145, groupFinTerms: [{groupFinTermId: 24, groupFinTermName: "Test ddd"},
        {groupFinTermId: 27, groupFinTermName: "Test ccc sdfa sadfafsd asdfsafs"}]},
    {terminalId: 67, groupFinTerms: [{groupFinTermId: 24, groupFinTermName: "Test ddd"}]}
  ]
};
export const testPNEAgentGateList = [testAgentTermGate1, testAgentTermGate2, testAgentTermGate3];
export const testPNE_EUAgentGateList = [testAgentTermGate4];

const terminal1 = {
  terminalId: 123,
  terminalStatus: "ENABLED",
  isIgnored: false,
  groupFinTerms: [{groupFinTermId: 142, groupFinTermName: "Test ddd"},
      // {groupFinTermId: 27, groupFinTermName: "Test ccc sdfa sadfafsd asdfsafs"}
      ]
};
const terminal2 = {
  terminalId: 143,
  terminalStatus: "DISABLED",
  isIgnored: false,
  groupFinTerms: [{groupFinTermId: 142, groupFinTermName: "Test ddd"},
      {groupFinTermId: 143, groupFinTermName: "Test ccc sdfa sadfafsd asdfsafs"}]
};
const terminal3 = {
  terminalId: 153,
  terminalStatus: "ENABLED",
  isIgnored: true,
  groupFinTerms: []
};
const terminal4 = {
  terminalId: 163,
  terminalStatus: "ENABLED",
  isIgnored: false,
  groupFinTerms: []
};
export const testGatesParamsItem1 = {
  gateId: 789,
  source: "PNE",
  gateStatus: 'ENABLED',
  gateCreationDate: "2019-11-01T15:39:53.265+03:00",
  gateChangeDate: "2019-11-01T15:39:53.265+03:00",
  isIgnored: false,
  terminals: [terminal1, terminal2, terminal3, terminal4]
};
export const testGatesParamsItem2 = {
  gateId: 749,
  source: "PNE",
  gateStatus: 'ENABLED',
  gateCreationDate: "2019-11-01T15:39:53.265+03:00",
  gateChangeDate: "2019-11-01T15:39:53.265+03:00",
  isIgnored: false,
  terminals: [terminal1, terminal2, terminal3, terminal4]
};
export const testGatesParamsItem3 = {
  gateId: 759,
  source: "PNE",
  gateStatus: 'ENABLED',
  gateCreationDate: "2019-11-01T15:39:53.265+03:00",
  gateChangeDate: "2019-11-01T15:39:53.265+03:00",
  isIgnored: false,
  terminals: [terminal3]
};
export const testGatesParamsItem4 = {
  gateId: 769,
  source: "PNE_EU",
  gateStatus: 'ENABLED',
  gateCreationDate: "2019-11-01T15:39:53.265+03:00",
  gateChangeDate: "2019-11-01T15:39:53.265+03:00",
  isIgnored: true,
  terminals: [terminal3, terminal4]
};
export const testGatesParams = [testGatesParamsItem1,testGatesParamsItem2,testGatesParamsItem3,testGatesParamsItem4];
export const testGatesParamsResponse = {
  requestPaynetDate: "2019-11-01T15:39:53.265+03:00",
  gatesParams: testGatesParams
};



const testAgentFinTermsListItem1 = {
  contractId: 3,
  contractName: "Test name for third contract",
  groupFinTerms: []
};
// const testAgentFinTermsListItem2 = {
//   contractId: 4,
//   contractName: "Test name for forth contract",
//   groupFinTerms: []
// };
// const testAgentFinTermsListItem3 = {
//   contractId: 5,
//   contractName: "Test name for fifth contract",
//   groupFinTerms: []
// };

export const testAgentFinTermsList = [testAgentFinTermsListItem1];