//@flow
import * as React from 'react';
import {connect} from "react-redux";
import api from "../../../services/apiInstance/api";
import apiErrorHandler from "../../../services/apiInstance/apiErrorHandler";
import notificationSettingsActions from "../../../store/notificationSettings/notificationSettingsActions";
import type {
  onGetNotificationSettings
} from "../../../services/flowTypes/propFnFlowTypes";
import type {errorObject, isReadyToRender, locationType, match} from "../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../service/RenderOnReady/RenderOnReady";
import type {notificationResponse, organizationId} from "../../../services/flowTypes/dataFlowTypes";
import NotificationSettingsForm from "./NotificationSettingsForm";
import NotificationSettingsFormSingleOrg from "./NotificationSettingsFormSingleOrg";

type Props = {
  onGetNotificationSettings: onGetNotificationSettings,
  notificationSettings: notificationResponse,
  orgIdForFilter?: organizationId,
  location: locationType,
  match: match,
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject
};

class NotificationSettings extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null
    };
  }
  componentDidMount() {
    api.getNotificationSettings()
      .then(response => {
        this.props.onGetNotificationSettings(response.data);
        this.setState({isReadyToRender: true});
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  }
  render() {
    const organizationId = this.props.match.params.organizationId;
    const isSupLevel = !organizationId;
    const editMode = this.props.location.pathname.includes('/edit');
    const className = isSupLevel ? "NotificationSettings" : "NotificationSettings NotificationSettings_notSupLevel";
    const editModeClassName = editMode ? className + " NotificationSettings_editMode" : className;
    return (
      <div className={editModeClassName}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
          {isSupLevel ? (
            <NotificationSettingsForm isSupLevel={isSupLevel}
                                      editMode={editMode} orgIdToFilter={+organizationId}
                                      notificationSettings={this.props.notificationSettings}/>
          ) : (
            <NotificationSettingsFormSingleOrg
                                      editMode={editMode} orgIdToFilter={+organizationId}
                                      notificationSettings={this.props.notificationSettings}/>
          )}

        </RenderOnReady>
      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    notificationSettings: state.notificationSettings
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetNotificationSettings: (value) => dispatch({type: notificationSettingsActions.NOTIFICATION_SETTINGS_UPDATE, value})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);    