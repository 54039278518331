import * as React from 'react';

export const leftColumnClassName = "test-left-column";
export const rightColumnClassName = "test-right-column";
export const leftColumnString = "Test Left Column";
export const rightColumnString = "Test Right Column";


export const DocRowContainerDefProps = {
  leftColumn: <div className={leftColumnClassName}>{leftColumnString}</div>,
  rightColumn: <div className={rightColumnClassName}>{rightColumnString}</div>
};