//@flow
import * as React from 'react';
import type {contentIsCopied, showContentIsCopied} from "../../services/flowTypes/componentFlowTypes";

const withClickToCopy = (WrappedComponent) => {


  type State = {
    contentIsCopied: contentIsCopied,
    showContentIsCopied: showContentIsCopied
  };

  class HOC extends React.Component <Props, State> {
    constructor(props: Props){
      super(props);
      this.state = {
        contentIsCopied: false,
        showContentIsCopied: false
      };
    }
    timer:TimeoutID;
    copyContent = () => {
      const textField = document.createElement('textarea');
      textField.innerText =  this.props.children;
      if (document.body) {
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        this.setState({
          contentIsCopied: true,
          showContentIsCopied: true
        });
        this.timer = setTimeout(this.hideTooltip, 2000);
      }
    };

    hideTooltip = () => {
      this.setState({
        showContentIsCopied: false
      });
      clearTimeout(this.timer);
    };
    render(){
      return (
        <WrappedComponent contentIsCopied={this.state.contentIsCopied} copyOnClick={this.copyContent}
                          showContentIsCopied={this.state.showContentIsCopied} {...this.props}/>
      );
    }
  }
  return HOC;
};

export default withClickToCopy;