//@flow
import groupFinTermsViewActions from './groupFinTermsViewActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {groupFinTerm} from "../../../../services/flowTypes/dataFlowTypes";

type State = Array<groupFinTerm>;
type reducerAction = action;
const initialState = null;

const groupFinTermsViewReducer = (state:State = initialState, action:reducerAction) => {
  switch (action.type) {
    case groupFinTermsViewActions.GROUP_FIN_TERMS_VIEW_INIT:
      return initialState;
    case groupFinTermsViewActions.GROUP_FIN_TERMS_VIEW_SET:
      return action.value;
    default:
      return state;
  }
};

export default groupFinTermsViewReducer;