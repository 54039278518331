//@flow
import * as React from 'react';
import text from "../../../../../../../services/localization/text";
import fileIco from "../../../../../../../assets/file-ico.svg";
import BtnEditAct from "../../../../../../../components/simple/Buttons/BtnEditAct/BtnEditAct";
import api from "../../../../../../../services/apiInstance/api";
import type {contractId, groupFinTermId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import createTempLinkToDownloadFile from "../../../../../../../utils/createTempLinkToDownloadFile";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import type {errorObject, isReadyToRender} from "../../../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import Text1 from "../../../../../../../components/simple/TextComponents/Text1/Text1";
import {actTemplatesEditPageIds} from "../../../../../../../tests/testIds";
import ConnectedViewTemplateParams from "../../ActTemplateView/ViewTemplateParams/ConnectedViewTemplateParams";

type Props = {
  contractId: contractId,
  finTermGroupIds: Array<groupFinTermId>
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};
class FileWithTags extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null
    };
  }
  downloadParamsFile = () => {
    this.setState({isReadyToRender: false});
    api.getTemplateParamsFile(this.props.contractId, this.props.finTermGroupIds)
      .then(response => {
        this.setState({isReadyToRender: true});
        createTempLinkToDownloadFile(response.data, text.fileWithTagsLink);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    return (
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <Text1 addClasses={'mb_1du'}>{text.fileWithTags1}<b>{text.fileWithTags2}</b>{text.fileWithTags3}</Text1>
        {this.props.finTermGroupIds.length ? (
          <div className="flex mb_3du">
            <div className="mr_1du">
              <img src={fileIco} alt={"file-icon"} className="FinancialConditionItem__file-ico"/>
            </div>
            <div className="flex">
              <BtnEditAct id={actTemplatesEditPageIds.linkToDownloadFileWithTags}
                          onClick={this.downloadParamsFile}>{text.fileWithTagsLink}</BtnEditAct>
              <ConnectedViewTemplateParams isAddendumTemplateParams={false}/>
              {/*<ViewTemplateParams contractId={this.props.contractId} finTermGroupIds={this.props.finTermGroupIds}/>*/}
            </div>
          </div>
        ) : (
          <div className="mb_3du"></div>
        )}

      </>
    );
  }
}

export default FileWithTags;    