import * as React from "react";
import contractBaseIconSrc from "../assets/contracts-menu-icons-v4.svg";
import agentBaseIconSrc from "../assets/agents-menu-icon-v4.svg";
import reportBaseIconSrc from "../assets/reports-menu-icon-v4.svg";
import docsBaseIconSrc from "../assets/documents-menu-icon-v4.svg";
import historyBaseIconSrc from "../assets/history-menu-icon-v4.svg";
import settingsBaseIconSrc from "../assets/settings-menu-icon-v4.svg";
import contractHoveredIconSrc from "../assets/contracts-menu-icons-hover-v4.svg";
import agentHoveredIconSrc from "../assets/agents-menu-icon-hover-v4.svg";
import reportHoveredIconSrc from "../assets/reports-menu-icon-hover-v4.svg";
import docsHoveredIconSrc from "../assets/documents-menu-icon-hover-v4.svg";
import historyHoveredIconSrc from "../assets/history-menu-icon-hover-v4.svg";
import settingsHoveredIconSrc from "../assets/settings-menu-icon-hover-v4.svg";
import text from "../services/localization/text";
import {
  agentsSuffix,
  contractsSuffix,
  orgPath,
  reportsSuffix,
  documentsSuffix,
  historySuffix, orgSettingsSuffix
} from "../services/urlStrings/urlStrings";
import HistoryMenuUnviewedMark from "../components/composed/HistoryView/HistoryMenuUnviewedMark";
import {permissionsStrValues} from "./permissionsStrValues";

const mainMenuItems = [
  {
    to: agentsSuffix,
    baseIconSrc: agentBaseIconSrc,
    hoveredIconSrc: agentHoveredIconSrc,
    activeIconSrc: agentHoveredIconSrc,
    text: text.agents,
    itemType: 'agent'
  },
  {
    to: contractsSuffix,
    baseIconSrc: contractBaseIconSrc,
    hoveredIconSrc: contractHoveredIconSrc,
    activeIconSrc: contractHoveredIconSrc,
    text: text.contracts,
    itemType: 'contract'
  },
  {
    to: reportsSuffix,
    baseIconSrc: reportBaseIconSrc,
    hoveredIconSrc: reportHoveredIconSrc,
    activeIconSrc: reportHoveredIconSrc,
    text: text.reports,
    itemType: 'report',
    checkPermission: {
      action: 'usePermission',
      subject: permissionsStrValues.report.view
    },
    // ,subMenu: <ReportsSubMenu/>
  },
  {
    to: documentsSuffix,
    baseIconSrc: docsBaseIconSrc,
    hoveredIconSrc: docsHoveredIconSrc,
    activeIconSrc: docsHoveredIconSrc,
    text: text.documents
  },
  {
    to: historySuffix,
    baseIconSrc: historyBaseIconSrc,
    hoveredIconSrc: historyHoveredIconSrc,
    activeIconSrc: historyHoveredIconSrc,
    text: text.historyTitle,
    unviewedIcoComp: <HistoryMenuUnviewedMark/>
  },
  {
    to: orgSettingsSuffix,
    baseIconSrc: settingsBaseIconSrc,
    hoveredIconSrc: settingsHoveredIconSrc,
    activeIconSrc: settingsHoveredIconSrc,
    text: text.orgSettingsTitle,
    itemType: 'settings'
  },
  {
    to: (orgPath(1) + '/new-ui-kit'),
    baseIconSrc: null,
    hoveredIconSrc: null,
    text: 'New UiKit',
    onlyDevMode: true
  },
];

export default mainMenuItems;