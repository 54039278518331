//@flow
import * as React from 'react';
import text from "../../services/localization/text";
import NFormGroup from "../../components/simple/FormComponents/NFormGroup/NFormGroup";
import NInput from "../../components/simple/FormComponents/NInput/NInput";
import type {authCode} from "../../services/flowTypes/componentFlowTypes";
import LoginFormContainer from "../../components/simple/Containers/LoginFormContainer/LoginFormContainer";
import {loginPages} from "../../tests/testIds";

type Props = {};
type State = {
  authCode: authCode
};
class CodeLoginPage extends React.Component <Props, State> {
  inputCode: { current: null | HTMLInputElement };
  constructor(props: Props) {
    super(props);
    this.state = {
      authCode: ''
    };
    this.inputCode = React.createRef();
  }
  onChange = (e:SyntheticEvent<HTMLInputElement>) => {
    this.setState({authCode: e.currentTarget.value});
  };
  componentDidMount(){
    if (this.inputCode.current) {
      this.inputCode.current.focus();
    }
  }
  render() {
    const propError = window.location.href.includes('?error') ? text.wrongAuthCode : '';
    const windowData = window['CODE_LOGIN_DATA'];
    const formAction = '/doLoginCode';
    const submitIsDisabled = !this.state.authCode.length;
    const hashForRedirect = window.location.search.includes('?hashForRedirect=') ?
      window.location.search.replace('?hashForRedirect=', '') : '';
    return (
      <LoginFormContainer formAction={formAction} propError={propError} submitIsDisabled={submitIsDisabled}>
        <NFormGroup label={text.authCodeLabel} addClasses={'mb_5du'}>
          <NInput name={'authCode'} value={this.state.authCode} onChange={this.onChange} ref={this.inputCode}
                  id={loginPages.authenticationCode}/>
        </NFormGroup>
        <input type="hidden" name={windowData.csrfToken.name}
               value={windowData.csrfToken.value} />
        <input type="hidden" name={'hashForRedirect'}
               value={hashForRedirect} />
      </LoginFormContainer>
    );
  }
}

export default CodeLoginPage;    