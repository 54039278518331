//@flow
import * as React from 'react';
import DocumentUploader from "../../../../../../components/composed/ItemDocuments/DocumentUploader/DocumentUploader";
import type {agentId} from "../../../../../../services/flowTypes/dataFlowTypes";
import type {match} from "../../../../../../services/flowTypes/appFlowTypes";
import {permissionsStrValues} from "../../../../../../configData/permissionsStrValues";

type Props = {
  agentId: agentId,
  match: match
};

const UploadAgentDocumentButton = (props: Props) => {
  const folderId = +props.match.params.folderId;
  return (
    <DocumentUploader permittedAction={permissionsStrValues.agentDocument.create} itemType={'agent'}
                      folderId={folderId} itemId={props.agentId}/>
  );
};

export default UploadAgentDocumentButton;