//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {disabled, id, label, placeholder} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './FilterSelect.css';
import Select, { components } from 'react-select';
import type {
  filterSelectBtn, isAddFilterMode,
  isClearable,
  isSearchable, isStaticMode, noOptionsMessage,
  onSelectChange,
  selectedOption, selectOptions
} from "../../../../services/flowTypes/componentFlowTypes";
import TextOverflowHandler from "../../Containers/TextOverflowHandler/TextOverflowHandler";
import FilterSelectIcon from "../../Icons/FilterSelectIcon/FilterSelectIcon";
import AddFilterBtn from "../../Buttons/AddFilterBtn/AddFilterBtn";
import Heading4 from "../../TextComponents/Heading4/Heading4";
import Text1 from "../../TextComponents/Text1/Text1";

type Props = {
  id?: id,
  addClasses?: addClasses,
  isSearchable?: isSearchable,
  value: selectedOption,
  onChange: onSelectChange,
  options: selectOptions,
  placeholder?: placeholder,
  noOptionsMessage?: noOptionsMessage,
  isClearable?: isClearable,
  isDisabled?: disabled,
  inputId?: id,
  propNameLabel?: label,
  filterSelectBtn?: filterSelectBtn,
  isAddFilterMode?: isAddFilterMode,
  isStaticMode?: isStaticMode
};

class FilterSelect extends React.Component <Props, void> {
  render(){
    const isSearchable = this.props.isSearchable ? this.props.isSearchable : false;
    const isClearable = this.props.isClearable ? this.props.isClearable : false;
    const customSelectStyles = {
      container: (provided, state) => ({
        ...provided,
        display: 'flex'
      }),
      control: (provided, state) => ({
        ...provided,
        borderRadius: this.props.isAddFilterMode || this.props.isStaticMode ? 'var(--border-radius)' : 'var(--filter-select-border-radius)',
        backgroundColor: 'var(--filter-select-back-color)',
        // backgroundColor: 'var(--item-back-color)',
        border: '1px solid transparent',
        height: 'var(--el-height)',
        cursor: 'pointer',
        padding: this.props.isAddFilterMode ? '0 0 0 16px' : '0 16px',
        boxShadow: 'unset'
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: 'transparent'
      }),
      input: (provided, state) => ({
        ...provided,
        backgroundColor: 'transparent',
        color: 'var(--main-text-color)'
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        padding: '2px 0'
      }),
      menuList: (provided, state) => ({
        ...provided,
        backgroundColor: '#ffffff'
      }),
      menu: (provided, state) => ({
        ...provided,
        marginTop: '0',
        width: 'auto',
        maxWidth: '200%',
        minWidth: '100%',
        boxShadow: "var(--select-box-shadow)"
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: 'var(--main-accent-color)',
        fontFamily: 'var(--main-font-family)',
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '36px',
        position: 'static',
        transform: 'unset',
        maxWidth: '100%'
      }),
      placeholder: (provided, state) => ({
        ...provided,
        position: 'static',
        transform: 'unset',
        color: 'var(--main-text-color)',
        fontFamily: 'var(--main-font-family)',
        fontSize: '14px',
        fontWeight: '700',
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'var(--main-accent-color)' : 'var(--main-text-color)',
        backgroundColor: state.isFocused ? 'var(--item-back-color)' : '#ffffff',
        fontFamily: 'var(--main-font-family)',
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '40px'
      })
    };
    const Option = (props) => {
      const label = typeof props.data.label === 'string' ?
        props.data.statusClassName ?
          <span className={props.data.statusClassName}>{props.data.label}</span> :
          <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{props.data.label}</TextOverflowHandler>
        : props.data.label;
      const innerProps = {...props.innerProps, id: props.data.value};
      const newProps = {...props, innerProps: innerProps, children: label};
      return (
        <components.Option {...newProps} />
      );
    };
    const SingleValue = (singleValueProps) => {
      const children = this.props.propNameLabel ?
        <div className={"flex ai_c"}>
          <Heading4 addClasses={"mr_1du"}>{this.props.propNameLabel + ": "}</Heading4>
          <Text1 addClasses={"Text1_card-label-color"}>{singleValueProps.children}</Text1></div>
        : singleValueProps.children;
      const newProps = {...singleValueProps, children: children};
      return (
        <components.SingleValue {...newProps} />
      );
    };
    const DropdownIndicator = (dropdownIndicatorProps) => {
      return this.props.isAddFilterMode ? (
        <div className={'AddFilterBtn__wrapper'}>
          <AddFilterBtn onClick={null} isActive={dropdownIndicatorProps.isFocused}/>
        </div>
        ) : (<FilterSelectIcon/>)};
    const className = this.props.isDisabled ? "FilterSelect FilterSelect_disabled" : "FilterSelect";
    return (
      <div className={'flex'}>
        <div id={this.props.id} className={baseClass(className, this.props.addClasses)}>
          <Select
            components={{DropdownIndicator, Option, SingleValue}}
            noOptionsMessage={this.props.noOptionsMessage}
            placeholder={this.props.placeholder}
            isClearable={isClearable}
            isSearchable={isSearchable}
            value={this.props.value}
            onChange={this.props.onChange}
            options={this.props.options}
            styles={customSelectStyles}
            inputId={this.props.inputId}
            isDisabled={this.props.isDisabled}
            // defaultMenuIsOpen={true}
          />
        </div>
        {this.props.filterSelectBtn ? (
          <div className="ml_2px" >
            {this.props.filterSelectBtn}
          </div>
        ) : null}
      </div>

    );
  }
}

export default FilterSelect;