//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  finTermIndex, finTermParamIndex,
  finTermParamsRowIndex, inputIco, labelTooltip, paramName, propName, propValidationPatternsArr, propValue
} from "../../../../../../../../../../../../../services/flowTypes/componentFlowTypes";
import FinTermFormGroup from "../../FinTermFormGroup";
import RenderOnReady from "../../../../../../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {isReadyToRender} from "../../../../../../../../../../../../../services/flowTypes/appFlowTypes";
import groupFinTermsValidationScheme from "../../../../../../../../../../../../../configData/groupFinTermsValidationScheme";
import finTermsEditActions
  from "../../../../../../../../../../../../../store/organization/item/finTermsEdit/finTermsEditActions";
import {onUpdateFinTermParam} from "../../../../../../../../../../../../../services/flowTypes/propFnFlowTypes";
import text from "../../../../../../../../../../../../../services/localization/text";
import getMaskedInputValue from "../../../../../../../../../../../../../utils/getMaskedInputValue";
import getParamUnitByNameAndCurr from "../../../../../../../../../../../../../utils/getParamUnitByNameAndCurr";
import type {currency, groupFinTermType} from "../../../../../../../../../../../../../services/flowTypes/dataFlowTypes";
import PropValueView from "../../../../../../../../../../../../../components/composed/PropValueView/PropValueView";
import getFinTermParamRateLocaleKey from "../../../../../../../../../../../../../utils/getFinTermParamRateLocaleKey";
import {rangeParamNames} from "../../../../../../../../../../../../../utils/getMinMaxParamNumberValue";
import QuestionTooltip
  from "../../../../../../../../../../../../../components/simple/Containers/QuestionTooltip/QuestionTooltip";

type Props = {
  finTermIndex: finTermIndex,
  finTermParamsRowIndex: finTermParamsRowIndex,
  finTermParamIndex: finTermParamIndex,
  propValue: propValue,
  onUpdateFinTermParam: onUpdateFinTermParam,
  paramName: paramName,
  currency: currency,
  readonly?: boolean,
  groupFinTermType: groupFinTermType
};
type State = {
  isReadyToRender: isReadyToRender,
  propName: propName,
  propValidationPatternsArr: propValidationPatternsArr,
  labelTooltip?: labelTooltip,
  inputIco: inputIco
};
class FinTermParam extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      propName: '',
      propValidationPatternsArr: [],
      inputIco: null,
      labelTooltip: null
    };
  }
  setPropName = () => {
    return 'finTerm' + this.props.finTermIndex + 'paramsRow' + this.props.finTermParamsRowIndex + this.props.paramName;
  };
  onGetPropValue = (propName, propValue) => {
    const value = getMaskedInputValue(propValue)
    const trimmedValue = value.replace(/\s/g, '');
    this.props.onUpdateFinTermParam(this.props.finTermIndex, this.props.finTermParamsRowIndex,
      this.props.finTermParamIndex, trimmedValue);
  };
  componentDidMount(){
    const propValidationPatternsArr = typeof groupFinTermsValidationScheme.finTerms.params[this.props.paramName] === 'function' ?
      groupFinTermsValidationScheme.finTerms.params[this.props.paramName](this.props.groupFinTermType)
      : groupFinTermsValidationScheme.finTerms.params[this.props.paramName];
    const labelTooltipText = text.finTermParamTooltip[this.props.groupFinTermType] &&
    text.finTermParamTooltip[this.props.groupFinTermType][this.props.paramName] ?
      text.finTermParamTooltip[this.props.groupFinTermType][this.props.paramName] : '';
    const labelTooltip = labelTooltipText ? (
      <QuestionTooltip isHighZIndex={true}>{labelTooltipText}</QuestionTooltip>
    ) : null;
    this.setState({
      propName: this.setPropName(),
      propValidationPatternsArr: propValidationPatternsArr,
      isReadyToRender: true,
      inputIco: (<div className="FinTermParamIco">{getParamUnitByNameAndCurr(this.props.paramName, this.props.currency)}</div>),
      labelTooltip
    });
  }
  render() {
    const paramNameKey = getFinTermParamRateLocaleKey(this.props.paramName, this.props.groupFinTermType);
    const finTermFormGroupAddClasses = rangeParamNames.min.includes(this.props.paramName) ||
      rangeParamNames.max.includes(this.props.paramName) ? "mr_2du MinMaxParamFormGroup" : "mr_2du";
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={null}>
        {this.props.readonly ?
            <div className="flex_jc-sb mb_1du">
              <PropValueView label={text[paramNameKey]} value={getMaskedInputValue(this.props.propValue)}/>
            </div>
            :
            <FinTermFormGroup
                formFieldType={'input'}
                propName={this.state.propName}
                propValidationPatternsArr={this.state.propValidationPatternsArr}
                propValue={getMaskedInputValue(this.props.propValue)}
                onGetPropValue={this.onGetPropValue}
                label={text[paramNameKey]}
                addClasses={finTermFormGroupAddClasses}
                inputIco={this.state.inputIco}
                idForTest={this.state.propName}
                labelTooltip={this.state.labelTooltip}
            />
        }
      </RenderOnReady>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    paramName: state.organization.item.finTermsEdit[ownProps.finTermIndex].params[ownProps.finTermParamsRowIndex][ownProps.finTermParamIndex].name,
    propValue: state.organization.item.finTermsEdit[ownProps.finTermIndex].params[ownProps.finTermParamsRowIndex][ownProps.finTermParamIndex].value,
    currency: state.organization.item.groupFinTermsEdit.currency
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateFinTermParam: (finTermIndex, finTermParamsRowIndex, finTermParamIndex, propValue) => dispatch({
      type: finTermsEditActions.FIN_TERM_PARAM_UPDATE,
      value: { finTermIndex, finTermParamsRowIndex, finTermParamIndex, propValue } })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinTermParam);    