//@flow
import * as React from 'react';
import ItemDocuments from "../../../../../../components/composed/ItemDocuments/ItemDocuments";
import type {match, locationPathname, history} from "../../../../../../services/flowTypes/appFlowTypes";
import {permissionsStrValues} from "../../../../../../configData/permissionsStrValues";

type Props = {
  match: match,
  location: locationPathname,
  history: history
};

const AgentDocuments = (props: Props) => {
  const agentId = +props.match.params.id;
  const folderId = +props.match.params.folderId;
  return (<ItemDocuments itemType={'agent'} permittedActionObj={permissionsStrValues.agentDocument} itemId={agentId}
                         folderId={folderId} pathname={props.location.pathname} history={props.history}/>);
};

export default AgentDocuments;    