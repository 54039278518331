//@flow
import * as React from 'react';
import {connect} from "react-redux";
import Filters from "../../../../../../../components/composed/Filters/Filters";
import groupFinTermsSearchActions
  from "../../../../../../../store/organization/item/groupFinTermsSearch/groupFinTermsSearchActions";
import type {
  agentsList, configSourceParams,
  currencyOptions,
  groupFinTermsSearch, payoutPeriodOptions, sourceOptions
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../../services/localization/text";
import getCurrencyLabel from "../../../../../../../utils/getCurrencyLabel";
import createOption from "../../../../../../../utils/createOption";
import type {selectOptions} from "../../../../../../../services/flowTypes/componentFlowTypes";

type Props = {
  onUpdateGroupFinTermsSearchProp: Function,
  onDeleteGroupFinTermsSearchProp: Function,
  groupFinTermsSearch: groupFinTermsSearch,
  agentsList: agentsList,
  currencyOptions: currencyOptions,
  payoutPeriodOptions: payoutPeriodOptions,
  sourceOptions: sourceOptions,
  configSourceParams: configSourceParams,
  isSourceFilterHidden?: boolean,
  isModalMode?: boolean
};
type State = {
  agentIdOptions: selectOptions,
  currencyOptions: selectOptions,
  payoutPeriodOptions: selectOptions,
  sourceOptions: selectOptions,
  cardTypeOptions: selectOptions,
  transactionStatusOptions: selectOptions,
  transactionTypeOptions: selectOptions
};

const groupFinTermsFiltersPropsList = ["groupFinTermName", "payerId", "payeeId", "payerMerchantId", "payeeMerchantId",
  "currency", "payoutPeriod", "cardType", "transactionStatus", "transactionType", "source"];

export const getOptionsForPNESourceParam = (sourceParamName, configSourceParams) => {
  const configSourceParamRu = configSourceParams.PNE ?
    configSourceParams.PNE.sourceParams.find(item => item.name === sourceParamName) : null;
  const configSourceParamEu = configSourceParams.PNE_EU ?
    configSourceParams.PNE_EU.sourceParams.find(item => item.name === sourceParamName) : null;
  const optionsRu = configSourceParamRu && configSourceParamRu.options ? configSourceParamRu.options : [];
  const optionsEu = configSourceParamEu && configSourceParamEu.options ? configSourceParamEu.options : [];
  const options = [...optionsRu];
  for (let i=0; i < optionsEu.length; i++) {
    if (!options.includes(optionsEu[i])) {
      options.push(optionsEu[i]);
    }
  }
  if (options) {
    return options.map(item => {
      return createOption(item);
    });
  } else {
    return [];
  }
};

class GroupFinTermsFilters extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      agentIdOptions: [],
      currencyOptions: [],
      payoutPeriodOptions: [],
      sourceOptions: [],
      cardTypeOptions: [],
      transactionStatusOptions: [],
      transactionTypeOptions: []
    };
  }
  getFiltersList = (allFiltersList) => {
    const filtersList = [];
    const filtersForOptions = [];
    for (let i=0; i < allFiltersList.length; i++) {
      const condition = this.props.groupFinTermsSearch.hasOwnProperty(allFiltersList[i].propName);
      if (condition) {
        filtersList.push(allFiltersList[i]);
      } else {
        filtersForOptions.push(allFiltersList[i]);
      }
    }
    return {filtersList, filtersForOptions};
  };
  componentDidMount(): * {
    const agentIdOptions = this.props.agentsList.map(item => {
      return {value: item.id, label: item.name}
    });
    const currencyOptions = this.props.currencyOptions.map(item => {
      return {value: item, label: getCurrencyLabel(item)};
    });
    const payoutPeriodOptions = this.props.payoutPeriodOptions.map(item => {
      return {value: item, label: text[item]};
    });
    const sourceOptions = this.props.sourceOptions.map(item => {
      return {value: item, label: text[item]};
    });
    const cardTypeOptions = getOptionsForPNESourceParam("cardType", this.props.configSourceParams);
    const transactionStatusOptions = getOptionsForPNESourceParam("transactionStatus", this.props.configSourceParams);
    const transactionTypeOptions = getOptionsForPNESourceParam("transactionType", this.props.configSourceParams);
    this.setState({agentIdOptions, currencyOptions, payoutPeriodOptions, sourceOptions, cardTypeOptions,
      transactionStatusOptions, transactionTypeOptions});
  }

  render() {
    const allFiltersList = [
      {propName: 'groupFinTermName', formFieldType: 'filter-input', initValue: '', placeholder: text.groupFinTermsFilters.groupFinTermNamePlaceholder},
      {propName: 'payerId', formFieldType: 'filter-select', options: this.state.agentIdOptions, initValue: null, placeholder: text.filtersLabels.payerId},
      {propName: 'payeeId', formFieldType: 'filter-select', options: this.state.agentIdOptions, initValue: null, placeholder: text.filtersLabels.payeeId},
      {propName: 'payerMerchantId', formFieldType: 'filter-select', options: this.state.agentIdOptions, initValue: null, placeholder: text.filtersLabels.payerMerchantId},
      {propName: 'payeeMerchantId', formFieldType: 'filter-select', options: this.state.agentIdOptions, initValue: null, placeholder: text.filtersLabels.payeeMerchantId},
      {propName: 'currency', formFieldType: 'filter-select', options: this.state.currencyOptions, initValue: null, placeholder: text.filtersLabels.currency},
      {propName: 'payoutPeriod', formFieldType: 'filter-select', options: this.state.payoutPeriodOptions, initValue: null, placeholder: text.filtersLabels.payoutPeriod},
      {propName: 'cardType', formFieldType: 'filter-delimitedList', options: this.state.cardTypeOptions, initValue: "", placeholder: text.filtersLabels.cardType},
      {propName: 'transactionStatus', formFieldType: 'filter-delimitedList', options: this.state.transactionStatusOptions, initValue: "", placeholder: text.filtersLabels.transactionStatus},
      {propName: 'transactionType', formFieldType: 'filter-delimitedList', options: this.state.transactionTypeOptions, initValue: "", placeholder: text.filtersLabels.transactionType},
      {propName: 'source', formFieldType: 'filter-select', options: this.state.sourceOptions, initValue: null, placeholder: text.filtersLabels.source}
    ];
    if (this.props.isSourceFilterHidden) {
      const sourceFilterIndex = allFiltersList.findIndex(item => item.propName === "source");
      allFiltersList.splice(sourceFilterIndex, 1);
    }
    const {filtersList, filtersForOptions} = this.getFiltersList(allFiltersList);
    const resetFilters = () => this.props.onDeleteGroupFinTermsSearchProp(groupFinTermsFiltersPropsList);
    const addClasses = this.props.isModalMode ? "mt_4du" : "Filters_sticky";
    return (
      <Filters onGetFiltersProp={this.props.onUpdateGroupFinTermsSearchProp}
               onDeleteFiltersProp={this.props.onDeleteGroupFinTermsSearchProp}
               filtersState={this.props.groupFinTermsSearch}
               filtersForOptions={filtersForOptions}
               filtersList={filtersList}
               resetFilters={resetFilters}
               addClasses={addClasses}
      />
    );
  }
}

const mapStateToProps = state => {
  const sourceOptions = state.organization.organizationData.configFinTerms.sourceOptions ?
    state.organization.organizationData.configFinTerms.sourceOptions : [];
  return {
    groupFinTermsSearch: state.organization.item.groupFinTermsSearch,
    agentsList: state.organization.organizationData.agentsList,
    currencyOptions: state.organization.organizationData.configFinTerms.currencyOptions,
    payoutPeriodOptions: state.organization.organizationData.configFinTerms.payoutPeriodOptions,
    sourceOptions,
    configSourceParams: state.organization.organizationData.configFinTerms.configSourceParams,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateGroupFinTermsSearchProp: (groupFinTermsSearchObj) => dispatch({type: groupFinTermsSearchActions.GROUP_FIN_TERMS_SEARCH_PROP_UPDATE,
      value: groupFinTermsSearchObj}),
    onDeleteGroupFinTermsSearchProp: (groupFinTermsSearchPropNameArr) => dispatch({
      type: groupFinTermsSearchActions.GROUP_FIN_TERMS_SEARCH_PROP_DELETE,
      value: groupFinTermsSearchPropNameArr
    })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupFinTermsFilters);    