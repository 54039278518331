//@flow
import * as React from 'react';
import {connect} from "react-redux";
import text from "../../../../services/localization/text";
import type {isReadyToRender} from "../../../../services/flowTypes/appFlowTypes";
import type {
    organizationId, reportAccountStatusList
} from "../../../../services/flowTypes/dataFlowTypes";
import RenderOnReady from "../../../../components/service/RenderOnReady/RenderOnReady";
import type {menuItemsData} from "../../../../services/flowTypes/componentFlowTypes";
import {orgPath, reportsListByStatusPath} from "../../../../services/urlStrings/urlStrings";
import RowMenu from "../../../../components/simple/Containers/RowMenu/RowMenu";

type Props = {
  reportAccountStatusList: reportAccountStatusList,
  organizationId: organizationId
};
type State = {
  isReadyToRender: isReadyToRender,
  subMenuItems: menuItemsData
};

class ReportsSubMenu extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      subMenuItems: []
    };
  }
  setSubMenuItems = () => {
    let allCount = 0;
    const reportsSubMenuItems = this.props.reportAccountStatusList.map(item => {
      allCount = allCount + item.count;
      return {...item};
    });
    const statusAllObj = {name: 'ALL', count: allCount};
    reportsSubMenuItems.splice(0, 0, statusAllObj);
    const cancelledIndex = reportsSubMenuItems.findIndex(item => item.name === 'CANCELLED');
    reportsSubMenuItems.splice(cancelledIndex, 1);
    const adjustedIndex = reportsSubMenuItems.findIndex(item => item.name === 'ADJUSTED');
    const adjustedObj = reportsSubMenuItems.find(item => item.name === 'ADJUSTED');
    reportsSubMenuItems.splice(adjustedIndex, 1);
    const preparedIndex = reportsSubMenuItems.findIndex(item => item.name === 'PREPARED');
    if (adjustedObj) {
      reportsSubMenuItems.splice(preparedIndex+1, 0, adjustedObj);
    }
    const subMenuItems = reportsSubMenuItems.map((item, i) => {
      const linkNameSuffix = item.name === "ALL" ? "" : " / " + item.count;
      return {
        linkTo: orgPath(this.props.organizationId) + reportsListByStatusPath(item.name),
        linkName: text.reportsSubMenu[item.name] + linkNameSuffix,
        isPermitted: true
      };
    });
    this.setState({subMenuItems, isReadyToRender: true});
  };
  componentDidUpdate(prevProps){
    if (prevProps.organizationId !== this.props.organizationId
        || prevProps.reportAccountStatusList !== this.props.reportAccountStatusList) {
      this.setSubMenuItems();
    }
  }
  componentDidMount(){
    this.setSubMenuItems();
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
        <RowMenu menuItemsData={this.state.subMenuItems} addClasses={"ReportsSubMenu"}/>
      </RenderOnReady>
      );
  }
}

const mapStateToProps = state => {
  const reportAccountStatusList = state.organization.organizationData.statuses ?
      state.organization.organizationData.statuses.reportAccountStatusList : [];
  return {
    organizationId: state.organization.organizationId,
    reportAccountStatusList
  };
};
export default connect(mapStateToProps, null)(ReportsSubMenu);