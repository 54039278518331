//@flow
import * as React from 'react';
import type {filtersFromParams, match} from "../../../../../../services/flowTypes/appFlowTypes";
import type {setFiltersToUrl} from "../../../../../../services/flowTypes/propFnFlowTypes";
import HistoryView from "../../../../../../components/composed/HistoryView/HistoryView";

type Props = {
  filtersFromParams: filtersFromParams,
  setFiltersToUrl: setFiltersToUrl,
  match:match
};

const UserHistory = (props: Props) => {
  const userId = +props.match.params.id;
  return (
    <HistoryView filtersFromParams={props.filtersFromParams} setFiltersToUrl={props.setFiltersToUrl}
                 historyType={'user'} itemId={userId}/>
  );
};

export default UserHistory;    