//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  addServicePayoutPeriodOptions, agentsList, itemDate,
  usersList
} from "../../../../../../services/flowTypes/dataFlowTypes";
import AddServiceFormGroup from "./addServiceEdit/AddServiceFormGroup";
import text from "../../../../../../services/localization/text";
import {addServiceEditPageIds} from "../../../../../../tests/testIds";
import type {selectOptions} from "../../../../../../services/flowTypes/componentFlowTypes";
import SectionHeader from "../../../../../../components/composed/SectionHeader/SectionHeader";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import type {match} from "../../../../../../services/flowTypes/appFlowTypes";
import SelectAddServiceActTemplateId from "./addServiceEdit/SelectAddServiceActTemplateId";
import AdaptiveFormContainer
  from "../../../../../../components/simple/Containers/AdaptiveFormContainer/AdaptiveFormContainer";

type Props = {
  usersList: usersList,
  addServicePayoutPeriodOptions: addServicePayoutPeriodOptions,
  match: match,
  agentsList: agentsList,
  contractStartDate: itemDate,
  contractEndDate: itemDate
};
type State = {
  userIdOptions: selectOptions,
  payoutPeriodSelectOptions: selectOptions,
  agentsOptions: selectOptions
};

class AddServiceTypeEdit extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      agentsOptions: [],
      userIdOptions: [],
      payoutPeriodSelectOptions: []
    };
  }
  componentDidMount(): * {
    const userIdOptions = this.props.usersList.map(item => {
      return {value: item.id, label: item.name}
    });
    const payoutPeriodSelectOptions = this.props.addServicePayoutPeriodOptions.map(item => {
      return {value: item, label: text[item]};
    });
    const agentsList = this.props.agentsList.filter(item => item.status === 'ACTIVE');
    const agentsOptions = agentsList.map(item => {
      return {value: item.id, label: item.name}
    });
    this.setState({userIdOptions, payoutPeriodSelectOptions, agentsOptions});
  }

  render() {
    return (
      <div className="AddServiceTypeEdit">
        {/*<SectionDivider addClasses={'mb_4du'}/>*/}
        <NCard addClasses={'NCard__wide-width mb_5du'}>
          <AddServiceFormGroup propName={'name'} formFieldType={'input'} label={text.addServiceFormLabels.name}
                               placeholder={text.addServiceFormPlaceholders.name} addClasses={'mb_3du'}
                               idForTest={addServiceEditPageIds.inputName}/>
          <AdaptiveFormContainer fieldsArr={[
            <AddServiceFormGroup formFieldType={'select'} propName={'payerId'} label={text.payer} key={"payerId"}
                                 addClasses={''} options={this.state.agentsOptions}
                                 placeholder={text.select.placeholder.payer} isSearchable={true}
                                 selectInputId={addServiceEditPageIds.selectInputPayer}
                                 idForTest={addServiceEditPageIds.selectPayer}/>,
            <AddServiceFormGroup formFieldType={'select'} propName={'payeeId'} label={text.payee} key={"payeeId"}
                                 addClasses={''} options={this.state.agentsOptions}
                                 placeholder={text.select.placeholder.payee} isSearchable={true}
                                 selectInputId={addServiceEditPageIds.selectInputPayee}
                                 idForTest={addServiceEditPageIds.selectPayee}/>
          ]} containerMode={"twoHalfCardWidthFieldsJustifyContentNormal"} addClasses={"mb_3du"}/>
          <AdaptiveFormContainer fieldsArr={[
            <AddServiceFormGroup propName={'userId'} formFieldType={'select'} label={text.addServiceFormLabels.userId}
                                 placeholder={text.addServiceFormPlaceholders.userId} addClasses={''}
                                 options={this.state.userIdOptions} selectInputId={addServiceEditPageIds.selectInputUserId}
                                 isSearchable={true} noOptionsMessage={text.addServiceFormNoOptionsMessage.userId}
                                 idForTest={addServiceEditPageIds.selectUserId} key={"userId"}/>,
            <div className="flex" key={"dateAndPayoutPeriod"}>
              <AddServiceFormGroup propName={'date'} formFieldType={'date'} label={text.addServiceFormLabels.date}
                                   openDirection={'down'} addClasses={"mr_3du"}
                                   argsForValidationFn={[this.props.contractStartDate, this.props.contractEndDate]}
                                   idForTest={addServiceEditPageIds.dateInput}/>
              <AddServiceFormGroup propName={'payoutPeriod'} formFieldType={'select'}
                                   label={text.addServiceFormLabels.payoutPeriod}
                                   placeholder={text.addServiceFormPlaceholders.payoutPeriod} addClasses={'w_quarter-card'}
                                   options={this.state.payoutPeriodSelectOptions}
                                   selectInputId={addServiceEditPageIds.selectInputPayoutPeriod}
                                   isSearchable={true} noOptionsMessage={text.addServiceFormNoOptionsMessage.payoutPeriod}
                                   idForTest={addServiceEditPageIds.selectPayoutPeriod}/>
            </div>
          ]} containerMode={"twoHalfCardWidthFields1100JustifyContentNormal"} addClasses={"mb_3du"}/>
        </NCard>

        {/*<SectionDivider addClasses={'mb_6du'}/>*/}
        <NCard addClasses={'NCard__wide-width mb_5du'}>
          <SectionHeader sectionHeaderTitle={text.header1c} addClasses={"mb_3du"}/>
          <AdaptiveFormContainer fieldsArr={[
            <AddServiceFormGroup propName={'nomenNumber1c'} formFieldType={'input'} addClasses={''}
                                 label={text.addServiceFormLabels.nomenNumber1c} key={"nomenNumberic1c"}
                                 placeholder={text.addServiceFormPlaceholders.nomenNumber1c}
                                 idForTest={addServiceEditPageIds.inputNomenNumber1c}/>,
            <AddServiceFormGroup propName={'projectName'} formFieldType={'input'} addClasses={''}
                                 label={text.addServiceFormLabels.projectName} key={"projectName"}
                                 placeholder={text.addServiceFormPlaceholders.projectName}
                                 idForTest={addServiceEditPageIds.inputProjectName}/>
          ]} containerMode={"twoHalfCardWidthFields1100JustifyContentNormal"} addClasses={"mb_3du"}/>
          <AddServiceFormGroup propName={'ignoreInExport'} formFieldType={'checkbox-boolean'}
                               label={text.addServiceFormLabels.ignoreInExport}
                               idForTest={addServiceEditPageIds.checkboxIgnoreInExport}/>
        </NCard>

        {/*<SectionDivider addClasses={'mb_6du'}/>*/}
        <NCard addClasses={'NCard__wide-width mb_5du'}>
          <SectionHeader sectionHeaderTitle={text.addServiceInvoiceSubheader}/>
          <AddServiceFormGroup propName={'isDetachedInvoice'} formFieldType={'checkbox-boolean'} addClasses={'mb_1du'}
                               label={text.addServiceFormLabels.isDetachedInvoice}
                               idForTest={addServiceEditPageIds.checkboxIsDetachedInvoice}/>
          <SelectAddServiceActTemplateId contractId={+this.props.match.params.id}/>
        </NCard>

        {/*<SectionDivider addClasses={'mb_6du'}/>*/}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contractStartDate: state.organization.item.contractMainData.startDate,
    contractEndDate: state.organization.item.contractMainData.endDate ? state.organization.item.contractMainData.endDate : "",
    agentsList: state.organization.organizationData.agentsList,
    usersList: state.organization.organizationData.usersList,
    addServicePayoutPeriodOptions: state.organization.organizationData.statuses.addServicePayoutPeriodOptions
  };
};
export default connect(mapStateToProps, null)(AddServiceTypeEdit);