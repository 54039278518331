//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id, onClick, disabled} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './EditConvertedTagBtn.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  onClick: onClick,
  disabled?: disabled
};

const EditConvertedTagBtn = (props: Props) => {
  return (
    <button type="button" id={props.id} onClick={props.onClick} disabled={props.disabled}
         className={baseClass("EditConvertedTagBtn", props.addClasses)}>
      <span className="EditConvertedTagBtn__ico-container"/>
    </button>
  );
};

export default EditConvertedTagBtn;