//@flow
import * as React from 'react';
import './LogoContainer.css';
import {Link} from "react-router-dom";
import Logo from "./Logo/Logo";

type Props = {
};

const LogoContainer = (props:Props) => {
  const appVersion = window.APP_DATA.appVersion;
  const appVersionTrimmed = appVersion ? appVersion.replace('-SNAPSHOT', '') : '';
  return (
    <div className="LogoContainer">
      <Link to={'/'} title={'FLOWDOCv'+ appVersionTrimmed}>
        <Logo/>
      </Link>
    </div>
  );
};
export default LogoContainer;