//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  onGetLinkedItemsRequestParamsProp
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import type {
  linkedItemsRequestParams
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../../services/localization/text";
import type {propValue, selectOptions} from "../../../../../../../services/flowTypes/componentFlowTypes";
import linkedItemsRequestParamsActions
  from "../../../../../../../store/organization/linkedItemsRequestParams/linkedItemsRequestParamsActions";
import Filters from "../../../../../../../components/composed/Filters/Filters";

type Props = {
  linkedItemsRequestParams: linkedItemsRequestParams,
  onGetLinkedItemsRequestParamsProp: onGetLinkedItemsRequestParamsProp,
  statusSelectOptions: selectOptions,
  initValue?: propValue
};

class SelectStatusFilter extends React.Component <Props> {
  handleOnGetAddAgreementsFiltersPropCall = (filtersProps) => {
    const addAgreementsFiltersProps = {...filtersProps};
    this.props.onGetLinkedItemsRequestParamsProp(addAgreementsFiltersProps);
  };
  getFiltersList = (allFiltersList) => {
    const searchRequest =  this.props.linkedItemsRequestParams;
    const filtersList = [];
    const filtersForOptions = [];
    for (let i=0; i < allFiltersList.length; i++) {
      const condition =
        allFiltersList[i].propName === 'dateRange' ?
          searchRequest.hasOwnProperty('startDate') || searchRequest.hasOwnProperty('endDate')
          :  (allFiltersList[i].propName === 'createDateRange' ?
          searchRequest.hasOwnProperty('startCreateDate') || searchRequest.hasOwnProperty('endCreateDate')
          : searchRequest.hasOwnProperty(allFiltersList[i].propName) );
      if (condition) {
        filtersList.push(allFiltersList[i]);
      } else {
        filtersForOptions.push(allFiltersList[i]);
      }
    }
    return {filtersList, filtersForOptions};
  };
  render() {
    const initValue = this.props.initValue ? this.props.initValue : '';
    const statusFilter = {propName: 'status', formFieldType: 'filter-select', options: this.props.statusSelectOptions,
      placeholder: text.status, initValue};
    const allFiltersList = [statusFilter];
    const isStaticMode = true;
    const {filtersList, filtersForOptions} = this.getFiltersList(allFiltersList);
    const resetFilters = () => {};
    const filtersState = this.props.linkedItemsRequestParams;
    return (
      <Filters onGetFiltersProp={this.handleOnGetAddAgreementsFiltersPropCall}
               onDeleteFiltersProp={null}
               filtersState={filtersState}
               filtersForOptions={filtersForOptions}
               filtersList={filtersList}
               resetFilters={resetFilters}
               addClasses={'Filters_sticky'}
               isStaticMode={isStaticMode}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    linkedItemsRequestParams: state.organization.linkedItemsRequestParams
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetLinkedItemsRequestParamsProp: (searchRequestObj) => dispatch({
      type: linkedItemsRequestParamsActions.LINKED_ITEMS_REQUEST_PARAMS_PROP_SET,
      value: searchRequestObj})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectStatusFilter);