//@flow
import * as React from 'react';
import {connect} from "react-redux";
import SearchInput from "./SearchInput/SearchInput";
import type {
  inputIsFocused, inputRefType,
  onBlur,
  onChange,
  onFocus,
  spinnerIsShown
} from "../../../../../services/flowTypes/appFlowTypes";
import {
  handleSearchResultsUpdate,
  onGetSearchRequestProp
} from "../../../../../services/flowTypes/propFnFlowTypes";
import type {searchCategory, searchRequestText, tagsList} from "../../../../../services/flowTypes/dataFlowTypes";
import searchRequestActions from "../../../../../store/organization/searchRequest/searchRequestActions";
import text from "../../../../../services/localization/text";

type Props = {
  category: searchCategory,
  spinnerIsShown: spinnerIsShown,
  inputIsFocused: inputIsFocused,
  handleSearchResultsUpdate: handleSearchResultsUpdate,
  onFocus: onFocus,
  onBlur: onBlur,
  onChange: onChange,
  text: searchRequestText,
  selectedTags: tagsList,
  onGetSearchRequestProp: onGetSearchRequestProp
};

class ConnnectedSearchInput extends React.Component <Props> {
  ref: inputRefType;
  constructor(props: Props) {
    super(props);
    this.ref = React.createRef();
  }
  onKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.props.handleSearchResultsUpdate(true);
      if (this.ref.current) {
        this.ref.current.blur();
      }
    }
  };
  handleSearchIcoClick = () => {
    this.props.handleSearchResultsUpdate(true);
  };
  onChange = (e) => {
    this.props.handleSearchResultsUpdate(false);
    const searchRequestObj = {
      offset: 0,
      text: e.target.value
    };
    this.props.onGetSearchRequestProp(searchRequestObj);
  };
  onSearchTagDeleteClick = (index) => {
    const selectedTags =  [...this.props.selectedTags];
    selectedTags.splice(index, 1);
    const searchRequestObj = {
      offset: 0, selectedTags
    };
    this.props.onGetSearchRequestProp(searchRequestObj);
  };
  render() {
    return (
      <SearchInput
        ref={this.ref}
        spinnerIsShown={this.props.spinnerIsShown}
        inputIsFocused={this.props.inputIsFocused}
        handleSearchIcoClick={this.handleSearchIcoClick}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        text={this.props.text}
        placeholder={text.search.placeholder.general}
        tagsList={this.props.selectedTags}
        onSearchTagDeleteClick={this.onSearchTagDeleteClick}
      />
    );
  }
}
const mapStateToProps = state => {
  return {
    text: state.organization.searchRequest.text,
    category: state.organization.searchRequest.category,
    selectedTags: state.organization.searchRequest.selectedTags
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetSearchRequestProp: (searchRequestObj) => dispatch({type: searchRequestActions.SEARCH_REQUEST_PROP_SET,
      value: searchRequestObj})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConnnectedSearchInput);