//@flow
import * as React from 'react';
import GroupFinTermCardLabel
  from "../../../../../../../../../components/simple/TextComponents/GroupFinTermCardLabel/GroupFinTermCardLabel";
import text from "../../../../../../../../../services/localization/text";
import getCurrencyLabel from "../../../../../../../../../utils/getCurrencyLabel";
import type {convertedTag} from "../../../../../../../../../services/flowTypes/dataFlowTypes";

type Props = {
  convertedTag: convertedTag
};

const ConvertedTagLabels = (props:Props) => {
  return (
    <>
      <div className="flex">
        <GroupFinTermCardLabel addClasses={'mr_2du'}>{text.convertationDate[props.convertedTag.date]}</GroupFinTermCardLabel>
        <GroupFinTermCardLabel>{getCurrencyLabel(props.convertedTag.currency)}</GroupFinTermCardLabel>
      </div>
      <GroupFinTermCardLabel>{text.currencyConverterSource[props.convertedTag.source]}</GroupFinTermCardLabel>
    </>
  );
};
export default ConvertedTagLabels;