//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  contractsRelatedSearchData,
  contractsSearchData,
  totalCountSearchData
} from "../../../services/flowTypes/dataFlowTypes";
import type {isWideMode, shouldSearchResultsUpdate} from "../../../services/flowTypes/componentFlowTypes";
import {contractsPageIds} from "../../../tests/testIds";
import ConnectedContractCard from "./ContractCard/ConnectedContractCard";
import EmptyListV2 from "../../../components/simple/Containers/EmptyList/EmptyListV2";

type Props = {
  contracts: contractsSearchData,
  contractsRelated: contractsRelatedSearchData,
  totalCount: totalCountSearchData,
  shouldSearchResultsUpdate: shouldSearchResultsUpdate,
  isWideMode?: isWideMode
};

class ContractsSearchResults extends React.Component <Props, void> {
  shouldComponentUpdate(nextProps){
    return nextProps.shouldSearchResultsUpdate;
  }
  render() {
    const searchResultsCards = this.props.contracts === null ? null :
    this.props.contracts && this.props.contracts.length ? this.props.contracts.map((item, i) => {
      return (<ConnectedContractCard key={'ContractCard'+i} addClasses={"mb_1du"} linkId={contractsPageIds.linkToContract(i)}
                            isWideMode={this.props.isWideMode}
                            contract={item} contractRelated={this.props.contractsRelated[i]}/>);
    }) : <EmptyListV2/>;
    return (
      <div className={"ContractsSearchResults"}>
        {searchResultsCards}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contracts: state.organization.searchData.contracts,
    contractsRelated: state.organization.searchData.contractsRelated,
    totalCount: state.organization.searchData.totalCount
  };
};

export default connect(mapStateToProps, null)(ContractsSearchResults);