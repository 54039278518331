//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../RenderOnReady/RenderOnReady";
import historyLocationsActions from "../../../store/historyLocations/historyLocationsActions";
import type {onAddHistoryLocation} from "../../../services/flowTypes/propFnFlowTypes";
import type {locationType} from "../../../services/flowTypes/appFlowTypes";

type Props = {
  onAddHistoryLocation: onAddHistoryLocation,
  location: locationType
};

class HistoryLocationsHandler extends React.Component <Props> {
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if (prevProps.location !== this.props.location) {
      this.props.onAddHistoryLocation(this.props.location);
    }
  }
  componentDidMount() {
    this.props.onAddHistoryLocation(this.props.location);
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={true}/>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onAddHistoryLocation: (locationPathname) => dispatch({type: historyLocationsActions.LOCATION_ADD, value: locationPathname})
  };
};
export default connect(null, mapDispatchToProps)(HistoryLocationsHandler);