//@flow
import * as React from 'react';
import type {windowData} from "../../../services/flowTypes/appFlowTypes";

type Props = {
  windowData: windowData,
  isAuthorized: React.Node,
  isSetPassPage: React.Node,
  isResetPassPage: React.Node,
  isGoogleAuthPage: React.Node,
  isLoginPage: React.Node,
  isCodeLoginPage: React.Node,
  isNotAuthorized: React.Node
};

const Authorization = (props:Props) => {
  return (
    props.windowData.appData ? props.isAuthorized
      : props.windowData.setPassData ? props.isSetPassPage :
        props.windowData.resetPassData ? props.isResetPassPage :
        props.windowData.googleAuthData ? props.isGoogleAuthPage :
        props.windowData.loginData ? props.isLoginPage :
          props.windowData.codeLoginData ? props.isCodeLoginPage :
        props.isNotAuthorized
  );
};

export default Authorization;    