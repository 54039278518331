import * as React from 'react';

// got from https://gist.github.com/Gibbs/3920259#gistcomment-2259740
const currencyEntities = {
  'AED' : <span>&#1583;.&#1573;</span>, // ?
  'AFN' : <span>&#65;&#102;</span>,
  'ALL' : <span>&#76;&#101;&#107;</span>,
  'AMD' : <span></span>,
  'ANG' : <span>&#402;</span>,
  'AOA' : <span>&#75;&#122;</span>, // ?
  'ARS' : <span>&#36;</span>,
  'AUD' : <>{'A'}<span>&#36;</span></>,
  'AWG' : <span>&#402;</span>,
  'AZN' : <span>&#1084;&#1072;&#1085;</span>,
  'BAM' : <span>&#75;&#77;</span>,
  'BBD' : <span>&#36;</span>,
  'BDT' : <span>&#2547;</span>, // ?
  'BGN' : <span>&#1083;&#1074;</span>,
  'BHD' : <span>.&#1583;.&#1576;</span>, // ?
  'BIF' : <span>&#70;&#66;&#117;</span>, // ?
  'BMD' : <span>&#36;</span>,
  'BND' : <span>&#36;</span>,
  'BOB' : <span>&#36;&#98;</span>,
  'BRL' : <span>&#82;&#36;</span>,
  'BSD' : <span>&#36;</span>,
  'BTN' : <span>&#78;&#117;&#46;</span>, // ?
  'BWP' : <span>&#80;</span>,
  'BYR' : <span>&#112;&#46;</span>,
  'BZD' : <span>&#66;&#90;&#36;</span>,
  'CAD' : <span>&#36;</span>,
  'CDF' : <span>&#70;&#67;</span>,
  'CHF' : <span>&#67;&#72;&#70;</span>,
  'CLF' : <span></span>, // ?
  'CLP' : <span>&#36;</span>,
  'CNY' : <span>&#165;</span>,
  'COP' : <span>&#36;</span>,
  'CRC' : <span>&#8353;</span>,
  'CUP' : <span>&#8396;</span>,
  'CVE' : <span>&#36;</span>, // ?
  'CZK' : <span>&#75;&#269;</span>,
  'DJF' : <span>&#70;&#100;&#106;</span>, // ?
  'DKK' : <span>&#107;&#114;</span>,
  'DOP' : <span>&#82;&#68;&#36;</span>,
  'DZD' : <span>&#1583;&#1580;</span>, // ?
  'EGP' : <span>&#163;</span>,
  'ETB' : <span>&#66;&#114;</span>,
  'EUR' : <span>&#8364;</span>,
  'FJD' : <span>&#36;</span>,
  'FKP' : <span>&#163;</span>,
  'GBP' : <span>&#163;</span>,
  'GEL' : <span>&#4314;</span>, // ?
  'GHS' : <span>&#162;</span>,
  'GIP' : <span>&#163;</span>,
  'GMD' : <span>&#68;</span>, // ?
  'GNF' : <span>&#70;&#71;</span>, // ?
  'GTQ' : <span>&#81;</span>,
  'GYD' : <span>&#36;</span>,
  'HKD' : <span>&#36;</span>,
  'HNL' : <span>&#76;</span>,
  'HRK' : <span>&#107;&#110;</span>,
  'HTG' : <span>&#71;</span>, // ?
  'HUF' : <span>&#70;&#116;</span>,
  'IDR' : <span>&#82;&#112;</span>,
  'ILS' : <span>&#8362;</span>,
  'INR' : <span>&#8377;</span>,
  'IQD' : <span>&#1593;.&#1583;</span>, // ?
  'IRR' : <span>&#65020;</span>,
  'ISK' : <span>&#107;&#114;</span>,
  'JEP' : <span>&#163;</span>,
  'JMD' : <span>&#74;&#36;</span>,
  'JOD' : <span>&#74;&#68;</span>, // ?
  'JPY' : <span>&#165;</span>,
  'KES' : <span>&#75;&#83;&#104;</span>, // ?
  'KGS' : <span>&#1083;&#1074;</span>,
  'KHR' : <span>&#6107;</span>,
  'KMF' : <span>&#67;&#70;</span>, // ?
  'KPW' : <span>&#8361;</span>,
  'KRW' : <span>&#8361;</span>,
  'KWD' : <span>&#1583;.&#1603;</span>, // ?
  'KYD' : <span>&#36;</span>,
  'KZT' : <span>&#1083;&#1074;</span>,
  'LAK' : <span>&#8365;</span>,
  'LBP' : <span>&#163;</span>,
  'LKR' : <span>&#8360;</span>,
  'LRD' : <span>&#36;</span>,
  'LSL' : <span>&#76;</span>, // ?
  'LTL' : <span>&#76;&#116;</span>,
  'LVL' : <span>&#76;&#115;</span>,
  'LYD' : <span>&#1604;.&#1583;</span>, // ?
  'MAD' : <span>&#1583;.&#1605;.</span>, //?
  'MDL' : <span>&#76;</span>,
  'MGA' : <span>&#65;&#114;</span>, // ?
  'MKD' : <span>&#1076;&#1077;&#1085;</span>,
  'MMK' : <span>&#75;</span>,
  'MNT' : <span>&#8366;</span>,
  'MOP' : <span>&#77;&#79;&#80;&#36;</span>, // ?
  'MRO' : <span>&#85;&#77;</span>, // ?
  'MUR' : <span>&#8360;</span>, // ?
  'MVR' : <span>.&#1923;</span>, // ?
  'MWK' : <span>&#77;&#75;</span>,
  'MXN' : <span>&#36;</span>,
  'MYR' : <span>&#82;&#77;</span>,
  'MZN' : <span>&#77;&#84;</span>,
  'NAD' : <span>&#36;</span>,
  'NGN' : <span>&#8358;</span>,
  'NIO' : <span>&#67;&#36;</span>,
  'NOK' : <span>&#107;&#114;</span>,
  'NPR' : <span>&#8360;</span>,
  'NZD' : <span>&#36;</span>,
  'OMR' : <span>&#65020;</span>,
  'PAB' : <span>&#66;&#47;&#46;</span>,
  'PEN' : <span>&#83;&#47;&#46;</span>,
  'PGK' : <span>&#75;</span>, // ?
  'PHP' : <span>&#8369;</span>,
  'PKR' : <span>&#8360;</span>,
  'PLN' : <span>&#122;&#322;</span>,
  'PYG' : <span>&#71;&#115;</span>,
  'QAR' : <span>&#65020;</span>,
  'RON' : <span>&#108;&#101;&#105;</span>,
  'RSD' : <span>&#1044;&#1080;&#1085;&#46;</span>,
  'RUB' : <span>&#8381;</span>,
  'RWF' : <span>&#1585;.&#1587;</span>,
  'SAR' : <span>&#65020;</span>,
  'SBD' : <span>&#36;</span>,
  'SCR' : <span>&#8360;</span>,
  'SDG' : <span>&#163;</span>, // ?
  'SEK' : <span>&#107;&#114;</span>,
  'SGD' : <span>&#36;</span>,
  'SHP' : <span>&#163;</span>,
  'SLL' : <span>&#76;&#101;</span>, // ?
  'SOS' : <span>&#83;</span>,
  'SRD' : <span>&#36;</span>,
  'STD' : <span>&#68;&#98;</span>, // ?
  'SVC' : <span>&#36;</span>,
  'SYP' : <span>&#163;</span>,
  'SZL' : <span>&#76;</span>, // ?
  'THB' : <span>&#3647;</span>,
  'TJS' : <span>&#84;&#74;&#83;</span>, // ? TJS (guess)
  'TMT' : <span>&#109;</span>,
  'TND' : <span>&#1583;.&#1578;</span>,
  'TOP' : <span>&#84;&#36;</span>,
  'TRY' : <span>&#8356;</span>, // New Turkey Lira (old symbol used)
  'TTD' : <span>&#36;</span>,
  'TWD' : <span>&#78;&#84;&#36;</span>,
  'TZS' : <span></span>,
  'UAH' : <span>&#8372;</span>,
  'UGX' : <span>&#85;&#83;&#104;</span>,
  'USD' : <span>&#36;</span>,
  'UYU' : <span>&#36;&#85;</span>,
  'UZS' : <span>&#1083;&#1074;</span>,
  'VEF' : <span>&#66;&#115;</span>,
  'VND' : <span>&#8363;</span>,
  'VUV' : <span>&#86;&#84;</span>,
  'WST' : <span>&#87;&#83;&#36;</span>,
  'XAF' : <span>&#70;&#67;&#70;&#65;</span>,
  'XCD' : <span>&#36;</span>,
  'XDR' : <span></span>,
  'XOF' : <span></span>,
  'XPF' : <span>&#70;</span>,
  'YER' : <span>&#65020;</span>,
  'ZAR' : <span>&#82;</span>,
  'ZMK' : <span>&#90;&#75;</span>, // ?
  'ZWL' : <span>&#90;&#36;</span>,
};
export default currencyEntities;