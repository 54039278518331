//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {propName, selectOptions} from "../../../../services/flowTypes/componentFlowTypes";
import text from "../../../../services/localization/text";
import type {agentId, agentsList} from "../../../../services/flowTypes/dataFlowTypes";
import ReportsSeparateFilterFormGroup from "./ReportsSeparateFilterFormGroup";

type Props = {
  propName: propName,
  agentsList: agentsList,
  linkedAgentId: agentId
};
type State = {
  agentIdOptions: selectOptions
};

class PayerPayeeFilterFormGroup extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      agentIdOptions: []
    };
  }
  componentDidMount() {
    const defaultOption =  {value: 'ALL', label: text.reportStatus['ALL']};
    const agentIdOptions = this.props.agentsList.map(item => {
      return {value: item.id, label: item.name}
    });
    agentIdOptions.unshift(defaultOption);
    const linkedAgentOption = agentIdOptions.find(option => option.value === this.props.linkedAgentId);
    agentIdOptions.splice(agentIdOptions.indexOf(linkedAgentOption), 1);
    if (linkedAgentOption) {
      linkedAgentOption.isSticked = true;
      agentIdOptions.unshift(linkedAgentOption);
    }
    this.setState({agentIdOptions});
  }

  render() {
    const capitalizedPropName = this.props.propName.charAt(0).toUpperCase() + this.props.propName.slice(1);
    const idForTest = 'separateFilterSelect' + capitalizedPropName;
    const inputIdForTest = 'separateFilterInputSelect' + capitalizedPropName;
    const initValue = 'ALL';
    return (
      <ReportsSeparateFilterFormGroup propName={this.props.propName} selectOptions={this.state.agentIdOptions}
                                      idForTest={idForTest} inputIdForTest={inputIdForTest} initValue={initValue}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    agentsList: state.organization.organizationData.agentsList,
    linkedAgentId: state.organization.organizationData.statuses.linkedAgentId ?
      state.organization.organizationData.statuses.linkedAgentId : null
  };
};
export default connect(mapStateToProps, null)(PayerPayeeFilterFormGroup);