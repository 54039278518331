//@flow
import historyLogsActions from './historyLogsActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {historyActionDescription} from "../../../services/flowTypes/dataFlowTypes";
import update from 'immutability-helper';

type State = Array<historyActionDescription>;
type reducerAction = action;
const initialState = [];

const historyLogsReducer = (state:State = initialState, action:reducerAction) => {
  switch (action.type) {
    case historyLogsActions.HISTORY_LOGS_SET:
      return action.value;
    case historyLogsActions.HISTORY_LOGS_ADD:
      return update(state, {$push: [action.value]});
    default:
      return state;
  }
};

export default historyLogsReducer;