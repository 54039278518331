//@flow
import * as React from 'react';
import HistoryView from "../../components/composed/HistoryView/HistoryView";
import scrollToTop from "../../utils/scrollToTop";
import type {filtersFromParams, locationType, history} from "../../services/flowTypes/appFlowTypes";
import type {setFiltersToUrl} from "../../services/flowTypes/propFnFlowTypes";
import text from "../../services/localization/text";
import FixedHeaderContent from "../../components/simple/Containers/FixedHeader/FixedHeaderContent";

type Props = {
  filtersFromParams: filtersFromParams,
  setFiltersToUrl: setFiltersToUrl,
  location: locationType,
  history: history
};


class OrganizationDataHistory extends React.Component <Props> {
  componentDidMount(){
    scrollToTop();
  }
  render(){
    return (
      <>
        <FixedHeaderContent title={text.historyTitle} history={this.props.history}
                            pathname={this.props.location.pathname}
        />
        <HistoryView filtersFromParams={this.props.filtersFromParams} setFiltersToUrl={this.props.setFiltersToUrl}
                     historyType={'organizationData'}/>
      </>
    );
  }
}
export default OrganizationDataHistory;