//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './SectionDivider.css';

type Props = {
  id?: id,
  addClasses?: addClasses
};
//TODO: fix test
const SectionDivider = (props: Props) => {
  return (
    <div id={props.id} className={baseClass("SectionDivider", props.addClasses)}/>
  );
};

export default SectionDivider;