//@flow
import * as React from 'react';
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import text from "../../../../../../services/localization/text";
import type {
  errorObject,
  isReadyToRender,
  locationType,
  match
} from "../../../../../../services/flowTypes/appFlowTypes";
import type {adjustmentHistory, report} from "../../../../../../services/flowTypes/dataFlowTypes";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import formatDate from "../../../../../../utils/formatDate";
import NHeading2 from "../../../../../../components/simple/TextComponents/NHeading2/NHeading2";
import Text1 from "../../../../../../components/simple/TextComponents/Text1/Text1";
import ReportGroupFinTerms from "../ReportMainDataView/ReportGroupFinTerms/ReportGroupFinTerms";
import connect from "react-redux/es/connect/connect";
import api from "../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import TotalRow from "../ReportMainDataView/TotalRow/TotalRow";

type Props = {
  match: match,
  location: locationType
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  historyList: adjustmentHistory,
  reportMainData: report
};

class AdjustmentHistory extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      historyList: []
    };
  }
  componentDidMount(){
    const reportId = +this.props.match.params.id;
    api.getReportAdjustmentHistory(reportId)
      .then(response => {
        this.setState({
          historyList: response.data,
          isReadyToRender: true
        });
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  }
  render() {
    const historyCards = this.state.historyList.length ? this.state.historyList.map((item, i) => {
      return (
        <NCard key={'history' + i} addClasses="card_history mb_1du">
          <NHeading2 addClasses={'mb_1du'}>{formatDate(item.date)}</NHeading2>
          <div className="flex_jc-sb mb_3du">
            <Text1>{text.adjustmentType[item.adjustmentType]}</Text1>
            <div className="BtnEditAct">{item.userName}</div>
          </div>
          <ReportGroupFinTerms contractId={this.props.reportMainData.contractId}
                               reportId={this.props.reportMainData.id}
                               finTermsList={item.finTermsList}/>
          {(item.rateAmount || item.totalAmount) ? (
            <TotalRow
              currency={item.finTermsList[0].currency}
              rateAmount={item.rateAmount}
              totalAmount={item.totalAmount}
              count={item.count}
            />
          ) : null}
        </NCard>
      );
    }) : null;
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
        {historyCards}
      </RenderOnReady>
    );
  }
}
const mapStateToProps = state => {
  return {
    reportMainData: state.organization.item.reportMainData
  };
};
export default connect(mapStateToProps, null)(AdjustmentHistory);
