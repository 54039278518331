//@flow
import * as React from 'react';
import type {pageYOffsetUnderPopup} from "../../../services/flowTypes/componentFlowTypes";

type Props = {
  forbidBodyHiddenOverflow?: boolean,
};
type State = {
  pageYOffsetUnderPopup: pageYOffsetUnderPopup
};

class ForbidScrollUnder extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pageYOffsetUnderPopup: 0
    };
  }
  componentDidMount(){
    if (document.body && !this.props.forbidBodyHiddenOverflow) {
      const windowPageYOffset = window.pageYOffset;
      document.body.classList.add('ov_h');
      document.body.scrollTop = windowPageYOffset;
      this.setState({
        pageYOffsetUnderPopup: windowPageYOffset
      })
    }
  }
  componentWillUnmount(){
    if (document.body) {
      document.body.classList.remove('ov_h');
      window.scroll(0, this.state.pageYOffsetUnderPopup);
    }
  }
  render() {
    return this.props.children;
  }
}

export default ForbidScrollUnder;    