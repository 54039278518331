//@flow
import * as React from 'react';
import connect from "react-redux/es/connect/connect";
import type {agent} from "../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../services/localization/text";
import type {locationType, match} from "../../../../../../services/flowTypes/appFlowTypes";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import SectionHeader from "../../../../../../components/composed/SectionHeader/SectionHeader";
import PropValueView from "../../../../../../components/composed/PropValueView/PropValueView";
import formatDate from "../../../../../../utils/formatDate";
import NFormGroup from "../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import FormTagSelect from "../../../../../../components/simple/FormComponents/FormTagSelect/FormTagSelect";
import getParamValue from "../../../../../../utils/getParamValue";
import scrollToTop from "../../../../../../utils/scrollToTop";
import NLabel from "../../../../../../components/simple/FormComponents/NLabel/NLabel";
import NDropDownDetails from "../../../../../../components/simple/Containers/NDropDownDetails/NDropDownDetails";
import SectionDivider from "../../../../../../components/simple/Containers/SectionDivider/SectionDivider";
import StatusLabel from "../../../../../../components/simple/TextComponents/StatusLabel/StatusLabel";
import AlertPaynetSync from "../../../../../../components/simple/Containers/AlertCard/AlertPaynetSync";
import "./AgentMainDataView.css";
import {agentsPageIds} from "../../../../../../tests/testIds";

type Props = {
  agentMainData: agent,
  match: match,
  location: locationType
};

class AgentMainDataView extends React.Component <Props, void> {
  componentDidMount(){
    scrollToTop();
  }
  render(){
    const params = this.props.agentMainData.params.map((item, i) => {
      const fields = item.fields.map((field, index) => {
        return (
          <PropValueView key={'block' + i + 'param' + index}
                         label={field.label}
                         value={getParamValue(field)}
                         addClasses={""}/>
        );
      });
      return (
        <div className="mb_1du" key={'block'+i}>
          <NDropDownDetails
            detailsSummary={item.blockName}
            detailsOptions={fields}
          />
        </div>
      );
    });
    return (
      +this.props.match.params.id === this.props.agentMainData.id ?
      (<div className="AgentMainDataView">
        <NCard>
          {this.props.agentMainData.isNotCompliedWithPaynetParams ? (
            <AlertPaynetSync addClasses={"mb_3du"}/>
          ) : null}
          <PropValueView
            testIdForValue={agentsPageIds.agentName}
            label={text.name}
            value={this.props.agentMainData.name}
            addClasses={"mb_3du"}
          />
          <div className="flex_jc-sb mb_3du">
            <div className="flex_jc-sb w_half-card">
              <PropValueView
                label={text.status}
                testIdForValue={agentsPageIds.agentStatus}
                value={
                  <StatusLabel addClasses={`StatusLabel_${this.props.agentMainData.status} StatusLabel_bold`}>
                    {text.agent.status[this.props.agentMainData.status]}
                  </StatusLabel>
                }
                addClasses={"w_three-quarter-card"}
              />
              <PropValueView label={text.createDate}
                             testIdForValue={agentsPageIds.agentCreateDate}
                             value={formatDate(this.props.agentMainData.createDate)}
                             addClasses={"w_three-quarter-card"}
              />
            </div>

          </div>
          {(this.props.agentMainData.statusDate || this.props.agentMainData.statusReason) ? (
            <div className="flex_jc-sb mb_3du">
              {this.props.agentMainData.statusDate ? (
                <PropValueView label={text.terminationDate}
                               testIdForValue={agentsPageIds.terminationDate}
                               value={formatDate(this.props.agentMainData.statusDate)}
                               addClasses={"w_quarter-card"}/>
              ) : null}
              {this.props.agentMainData.statusReason ? (
                <PropValueView label={text.terminationReason}
                               testIdForValue={agentsPageIds.terminationReason}
                               value={this.props.agentMainData.statusReason}
                               addClasses={"w_three-quarter-card"}
                />
              ) : null}
            </div>
          ) : null}
          {this.props.agentMainData.tags.length ? (
            <NFormGroup addClasses={"mb_3du"}>
              <NLabel>{text.tags}</NLabel>
              <FormTagSelect
                idForTest={agentsPageIds.agentTagsSelect}
                disabled={true}
                value={this.props.agentMainData.tags}
              />
            </NFormGroup>
          ) : (
            <PropValueView
              addClasses={"mb_3du"}
              idForTest={agentsPageIds.agentTags}
              label={text.tags}
              value={''} />
          )}
          <SectionDivider addClasses={'mt_5du mb_5du'}/>
          <SectionHeader sectionHeaderTitle={text.header1c} addClasses={"mb_3du"}/>
          {this.props.agentMainData.hasOwnProperty('agentCode') ? (
              <PropValueView
                idForTest={agentsPageIds.agentCode}
                label={text.agentCode}
                addClasses={"mb_3du"}
                value={this.props.agentMainData.agentCode}
              />
          ) : null}
          {this.props.agentMainData.hasOwnProperty('inn1c') ? (
              <PropValueView
                idForTest={agentsPageIds.agentInn1c}
                label={text.inn1c}
                addClasses={"mb_3du"}
                value={this.props.agentMainData.inn1c}
              />
          ) : null}
          {this.props.agentMainData.hasOwnProperty('name1c') ? (
              <PropValueView
                idForTest={agentsPageIds.agentName1c}
                label={text.name1c}
                value={this.props.agentMainData.name1c}
              />
          ) : null}

          {this.props.agentMainData.params.length ? (
            <>
              <SectionDivider addClasses={'mt_5du mb_5du'}/>
              <SectionHeader
                sectionHeaderTitle={text.addParamsTitle}
                addClasses={"mb_3du"}
              />
              {params}
            </>
          ) : null}
        </NCard>
      </div> )
      : null
    );
  }
}
const mapStateToProps = state => {
  return {
    agentMainData: state.organization.item.agentMainData
  };
};

export default connect(mapStateToProps, null)(AgentMainDataView);
