//@flow
import * as React from 'react';
import {connect} from "react-redux";
import NFormGroup from "../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import type {
  argsForValidationFn,
  creationIsProhibited,
  formFieldType, inputDelimiter,
  inputIco,
  isClearable, isOptionOverflowVisible,
  isSearchable, isTagLabelEditable,
  labelTooltip,
  noOptionsMessage,
  openDirection,
  propError,
  propName,
  propValidationPatternsArr,
  propValue,
  selectOptions
} from "../../../services/flowTypes/componentFlowTypes";
import {
  onGetValidationStateFormProp, onGetValidationStateProp
} from "../../../services/flowTypes/propFnFlowTypes";
import type {disabled, id, label, placeholder, readOnly} from "../../../services/flowTypes/appFlowTypes";
import validationActions from "../../../store/validation/validationActions";
import validationPatterns from "../../../services/validationPatterns/validationPatterns";
import type {isScrollToError, paramMask, validationIsAllowed} from "../../../services/flowTypes/dataFlowTypes";
import {onGetPropValue} from "../../../services/flowTypes/propFnFlowTypes";
import DynamicTypeFormField from "../DynamicTypeFormField/DynamicTypeFormField";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import defineInputMaxLength from "../../../utils/defineInputMaxLength";

type Props = {
  formFieldType: formFieldType,
  propName: propName,
  propValue: propValue,
  propError: propError,
  onGetPropValue: onGetPropValue,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  onGetValidationStateProp: onGetValidationStateProp,
  label: label,
  options?: selectOptions,
  validationIsAllowed: validationIsAllowed,
  isScrollToError: isScrollToError,
  propValidationPatternsArr?: propValidationPatternsArr,
  addClasses?: addClasses,
  idForTest?: id,
  isClearable?: isClearable,
  placeholder?: placeholder,
  noOptionsMessage?: noOptionsMessage,
  inputIco?: inputIco,
  disabled?: disabled,
  labelTooltip?: labelTooltip,
  selectInputId?: id,
  openDirection?: openDirection,
  isSearchable?: isSearchable,
  creationIsProhibited?: creationIsProhibited,
  inputDelimiter?: inputDelimiter,
  mask?: paramMask,
  isOptionOverflowVisible?: isOptionOverflowVisible,
  argsForValidationFn?: argsForValidationFn,
  isTagLabelEditable?: isTagLabelEditable,
  readOnly?: readOnly,
  dataUiTestId?: string
};
//TODO: consider to combine  onGetValidationStateProp and onGetValidationStateProp into one fn call
class ConnectedFormGroup extends React.Component <Props, void> {
  validateValue = (value, isScrollToError) => {
    if (this.props.propValidationPatternsArr) {
      let error = '';
      for (let i=0; i < this.props.propValidationPatternsArr.length; i++) {
        if (this.props.propValidationPatternsArr[i].includes('_withArgs')) {
          error = validationPatterns[this.props.propValidationPatternsArr[i]](value, ...this.props.argsForValidationFn);
        } else {
          error = validationPatterns[this.props.propValidationPatternsArr[i]](value);
        }
        if (error.length) {break;}
      }
      this.props.onGetValidationStateProp('isScrollToError', isScrollToError);
      this.props.onGetValidationStateFormProp(this.props.propName, error);
    }
  };
  onChange = (value) => {
    this.props.onGetPropValue(this.props.propName, value);
  };
  componentDidUpdate(prevProps){
    if (prevProps.propValue !== this.props.propValue) {
      // validateValue was moved from onChange to componentDidUpdate because in some cases propValue can be updated
      // in store directly without call onChange fn (e.g. PasteAllTags.js)
      this.validateValue(this.props.propValue, false);
    }
  }
  componentDidMount(){
    this.validateValue(this.props.propValue, false);
  }
  render() {
    const isRequired = this.props.propValidationPatternsArr ?
      this.props.propValidationPatternsArr.includes('required') : false;
    const inputMaxLength = this.props.propValidationPatternsArr ?
      defineInputMaxLength(this.props.propValidationPatternsArr) : null;
    const propError = this.props.validationIsAllowed ? this.props.propError : '';
    const label = this.props.formFieldType === 'checkbox' || this.props.formFieldType === 'checkbox-boolean' ?
      '' : this.props.label;
    const isEmptyLabelMode = this.props.formFieldType === 'checkbox' || this.props.formFieldType === 'checkbox-boolean';
    return (
      <NFormGroup label={label} propError={propError} isRequired={isRequired} isEmptyLabelMode={isEmptyLabelMode}
                  isScrollToError={this.props.isScrollToError} addClasses={this.props.addClasses}
                  labelTooltip={this.props.labelTooltip}>
        <DynamicTypeFormField
          formFieldType={this.props.formFieldType}
          propName={this.props.propName}
          propValue={this.props.propValue}
          onChange={this.onChange}
          options={this.props.options}
          inputMaxLength={inputMaxLength}
          idForTest={this.props.idForTest}
          isClearable={this.props.isClearable}
          placeholder={this.props.placeholder}
          noOptionsMessage={this.props.noOptionsMessage}
          inputIco={this.props.inputIco}
          disabled={this.props.disabled}
          isSearchable={this.props.isSearchable}
          selectInputId={this.props.selectInputId}
          openDirection={this.props.openDirection}
          creationIsProhibited={this.props.creationIsProhibited}
          inputDelimiter={this.props.inputDelimiter}
          label={this.props.label}
          mask={this.props.mask}
          isOptionOverflowVisible={this.props.isOptionOverflowVisible}
          isTagLabelEditable={this.props.isTagLabelEditable}
          readOnly={this.props.readOnly}
          dataUiTestId={this.props.dataUiTestId}
        />
      </NFormGroup>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propError: state.validation.form[ownProps.propName],
    validationIsAllowed: state.validation.validationIsAllowed,
    isScrollToError: state.validation.isScrollToError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetValidationStateProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }})

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedFormGroup);