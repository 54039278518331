//@flow
import * as React from 'react';
import type {organizationId} from "../../../services/flowTypes/dataFlowTypes";
import mainMenuItems from "../../../configData/mainMenuItems";
import HoveredIcon from "../HoveredIcon/HoveredIcon";
import './MainMenu.css';
import '../../simple/Containers/MainMenuItem/MainMenuItem.css';
import {orgPath} from "../../../services/urlStrings/urlStrings";
import type {isSupLevel} from "../../../services/flowTypes/componentFlowTypes";
import ability from "../../../services/permissions/permissions";
import {mainPageIds} from "../../../tests/testIds";
import MainMenuItem from "../../simple/Containers/MainMenuItem/MainMenuItem";
import supMainMenuItems from "../../../configData/supMainMenuItems";
import adminMenuItems from "../../../configData/adminMenuItems";

type Props = {
  organizationId?: organizationId,
  isSupLevel?: isSupLevel
};

const MainMenu = (props:Props) => {
  // const organizationId = props.isSupLevel ? null : +props.match.params.organizationId;
  const organizationId = props.isSupLevel ? null : props.organizationId;
  const supMenuItems = [...supMainMenuItems, ...adminMenuItems];
  const configMenuItems = props.isSupLevel ? supMenuItems : mainMenuItems;
  const pathPrefix = (onlyDevMode) => props.isSupLevel ? "" :
    (onlyDevMode ? '' : orgPath(organizationId));
  const menuItems = configMenuItems.map( (item, index) => {
    const unviewedIcoComp = item.unviewedIcoComp ? (organizationId !== '' ? item.unviewedIcoComp : null) : null;
    const hoveredIconComponent = item.baseIconSrc ? (
            <div className={"MainMenuItem__icon-container"}>
              <HoveredIcon baseIconSrc={item.baseIconSrc} hoveredIconSrc={item.hoveredIconSrc}
                           activeIconSrc={item.activeIconSrc}/>
            </div>
          ) : null;
    const itemTo = item.to ? item.to.replace("/", "") : "";
    const mainMenuItem = <MainMenuItem key={"menu-item-" + index} dataUiTestId={"mainMenuItem_" + itemTo}
                                       linkId={mainPageIds.mainMenuItem(index)} linkTo={pathPrefix(item.onlyDevMode) + item.to}
                                       linkText={item.text} hoveredIconComponent={hoveredIconComponent}
                                       unviewedIcoComp={unviewedIcoComp} subMenuComponent={item.subMenu}/>
    const permittedComp =
      (item.checkPermission && !ability.can(item.checkPermission.action, item.checkPermission.subject)) ?
      null : mainMenuItem;
    return item.onlyDevMode ?  (process.env.NODE_ENV !== 'production' ? permittedComp : null) : permittedComp;
  });
  return (
    <div className="MainMenu">
      {menuItems}
    </div>
  );
};

export default MainMenu;