//@flow
import * as React from 'react';
import FetchPrerenderData from "./FetchPrerenderData";
import RenderOnReady from "../RenderOnReady/RenderOnReady";
import type {children, fetchFnArr, isReadyToRender} from "../../../services/flowTypes/appFlowTypes";
import type {setFn} from "../../../services/flowTypes/componentFlowTypes";

type Props = {
  fetchFnArr: fetchFnArr,
  storeDataObj: any,
  children: children,
  setFn: setFn
};
type State = {
  isReadyToRender: isReadyToRender
};

class GetPrerenderData extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false
    };
  }
  componentDidUpdate(){
    //listen for condition
    // console.log('test how many times cDU is called');
    let conditionToRender = true;
    this.props.fetchFnArr.forEach((fetchFnObj) => {
      conditionToRender = conditionToRender && this.props.storeDataObj[fetchFnObj.propName];
    });
    if (!this.state.isReadyToRender && conditionToRender) {
      this.setState({isReadyToRender: true});
    }
  }
  render() {
    const fetchers = this.props.fetchFnArr.map((fetchFnObj, i) => {
      return (<FetchPrerenderData key={'Fetch'+i} fetchFnObj={fetchFnObj}
                                  setFn={this.props.setFn}/>)
    });
    return (
      <>
        {fetchers}
        <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
          {this.props.children}
        </RenderOnReady>
      </>
    );
  }
}
export default GetPrerenderData;