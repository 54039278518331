//@flow
import * as React from 'react';
import './FilterSelectIcon.css';
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";

type Props = {
  addClasses?: addClasses
};
const FilterSelectIcon = (props:Props) => {
  return (<div className={baseClass("FilterSelectIcon", props.addClasses)}/>);
};

export default FilterSelectIcon;