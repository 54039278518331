import * as React from 'react';

const style = {
  "whiteSpace": "nowrap",
  "backgroundColor": "white"
};
const menuItemsList = [
  {menuLabel: 'Option 1', component: (<div style={style}>Some component for Option 1</div>)},
  {menuLabel: 'Option 2', component: (<div style={style}>Some component for Option 2</div>)},
  {menuLabel: 'Option 3', component: (<div style={style}>Some component for Option 3</div>)},
  {menuLabel: 'Option 4', component: (<div style={style}>Some component for Option 4</div>)}
];

const headerComponentFn = (props) => {
  return <div >{"isFocused: " + props.isFocused}</div>
};
const aboveItemsComponent = (<div>Some Btn above list</div>);

export const DropMenuComponentsDefProps = {
  menuItemsList: menuItemsList,
  aboveItemsComponent: aboveItemsComponent,
  headerComponentFn: headerComponentFn,
  addClasses: "DropMenuComponents_shiftedRightForUiKit"
};