//@flow
import * as React from 'react';
import './EmptyList.css';
import text from "../../../../services/localization/text";
import type {emptyListLabel, hideArrowIcon} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  emptyListLabel?: emptyListLabel,
  hideArrowIcon?: hideArrowIcon,
  insertedLabel?: React.Node,
  isSearchResultsMode?: boolean
};

const EmptyList = (props: Props) => {
  return props.isSearchResultsMode ? (
    <div className="EmptyList EmptyList_searchResultsMode">
      <div className={'flex mr_4du'}>
        <div className="EmptyList__text-container">
          <div className="EmptyList__title">{text.emptyListTitleSearchResultsMode}</div>
          <div className="EmptyList__text">{text.emptyListLabel.searchResultsMode}</div>
        </div>
      </div>
    </div>
  ) : (
    <div className="EmptyList">
      <div className="EmptyList__img"/>
      <div className={'flex mr_4du'}>
        <div className="EmptyList__text-container">
          <div className="EmptyList__title">{text.emptyListTitle}</div>
          {props.insertedLabel ? (
            <div className="EmptyList__text">{props.insertedLabel}</div>
          ) : (
            <div className="EmptyList__text">{props.emptyListLabel + text.emptyListMsgEnd}</div>
          )}
        </div>
        <div className="EmptyList__rel-container">
          {props.hideArrowIcon ? null : (
            <div className="EmptyList__arrow-img"/>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmptyList;    