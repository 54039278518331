//@flow
import * as React from 'react';
import text from "../../../../../../../../services/localization/text";
import NModal from "../../../../../../../../components/simple/Modals/NModal/NModal";
import {cancelModal, proceedFn} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import NHeading2 from "../../../../../../../../components/simple/TextComponents/NHeading2/NHeading2";

type Props = {
  cancelModal: cancelModal,
  proceedFn: proceedFn
};

class ReportAdjustmentCautionModal extends React.Component <Props> {
  render() {
    return (
      <NModal
        addClasses={'NModal_ov'}
        modalTitle={text.warningModalTitle}
        onModalActionBtnClick={this.props.proceedFn}
        onModalCancelBtnClick={this.props.cancelModal}
        modalActionBtnText={text.btnProceed}
        modalCancelBtnText={text.cancel}
      >
        <NHeading2 addClasses={'p_3du ta_c'}>{text.adjustmentCaution}</NHeading2>
      </NModal>
    );
  }
}

export default ReportAdjustmentCautionModal;    