//@flow
import templateParamsActions from './templateParamsActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {templateParams} from "../../../../services/flowTypes/dataFlowTypes";
import update from 'immutability-helper';

type reducerAction = action;
const initialState = [];

const templateParamsReducer = (state:templateParams = initialState, action:reducerAction) => {
  switch (action.type) {
    case templateParamsActions.TEMPLATE_PARAMS_INIT:
      return initialState;
    case templateParamsActions.TEMPLATE_PARAMS_SET:
      return action.value;
    case templateParamsActions.TEMPLATE_PARAMS_TEXT_TAG_UPDATE:
      const groupFinTermIndex = state.findIndex(item => item.groupId === action.value.groupFinTermId);
      const paramIndex = state[groupFinTermIndex].actTemplateParams.findIndex(item => item.name === 'manualInput');
      return update(state, {
        [groupFinTermIndex]: {actTemplateParams: {[paramIndex]: {textValue: {$set: action.value.textValue}}}}
      });
    default:
      return state;
  }
};
export default templateParamsReducer;