//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  actTemplateId,
  contractId,
  groupFinTerm,
  groupFinTermId, organizationId
} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import actTemplatesEditActions
  from "../../../../../../../../store/organization/item/actTemplatesEdit/actTemplatesEditActions";
import {
  onGetActTemplateProp,
  onGetValidationStateFormProp
} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import type {
  selectOptions, validationRule
} from "../../../../../../../../services/flowTypes/componentFlowTypes";
import ActTemplateFinTermsCards from "./ActTemplateFinTermsCards/ActTemplateFinTermsCards";
import validationActions from "../../../../../../../../store/validation/validationActions";
import actTemplateValidationScheme from "../../../../../../../../configData/actTemplateValidationScheme";
import validationPatterns from "../../../../../../../../services/validationPatterns/validationPatterns";
import ConnectedGroupFinTermBlock
  from "../../../../../../../../components/composed/ConnectedGroupFinTermBlock/ConnectedGroupFinTermBlock";
import FinTermsSelect from "./ActTemplateFinTermsCards/FinTermsSelect";
import api from "../../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../../services/apiInstance/apiErrorHandler";
import templateParamsActions
  from "../../../../../../../../store/organization/item/templateParams/templateParamsActions";
import actTemplateParamsListActions
  from "../../../../../../../../store/organization/item/actTemplateParamsList/actTemplateParamsListActions";
import RenderOnReady from "../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {
  onGetActTemplateParamsList,
  onGetTemplateParams, onInitActTemplateParamsList, onInitTemplateParams, onResetActTemplateExpressions
} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import type {errorObject, isReadyToRender} from "../../../../../../../../services/flowTypes/appFlowTypes";

type Props = {
  finTermGroupIds: Array<groupFinTermId>,
  groupFinTermsView: Array<groupFinTerm>,
  onGetActTemplateProp: onGetActTemplateProp,
  contractId: contractId,
  organizationId: organizationId,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  onGetTemplateParams: onGetTemplateParams,
  onGetActTemplateParamsList: onGetActTemplateParamsList,
  onInitTemplateParams: onInitTemplateParams,
  onInitActTemplateParamsList: onInitActTemplateParamsList,
  onResetActTemplateExpressions: onResetActTemplateExpressions,
  readonly?: boolean,
  actTemplateId: actTemplateId
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  selectGroupFinTermsOptions: selectOptions,
  selectGroupFinTermsOptionsAll: selectOptions,
  validationRule: validationRule
};
class FinTermsSelectContainer extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      selectGroupFinTermsOptions: [],
      selectGroupFinTermsOptionsAll: [],
      validationRule: validationPatterns[actTemplateValidationScheme.finTermGroupIds[0]]
    };
  }
  getTemplateParams = (isOnUpdate, finTermGroupIds, selectGroupFinTermsOptions) => {
    this.props.onInitActTemplateParamsList();
    const actTemplateId = this.props.actTemplateId ? this.props.actTemplateId : '';
    api.getGroupFinTermTemplateParams(this.props.contractId, finTermGroupIds, actTemplateId)
      .then(response => {
        if (finTermGroupIds.length) {
          this.getActTemplateParamsList(finTermGroupIds);
        }
        this.props.onGetTemplateParams(response.data);
        if (isOnUpdate) {
          this.props.onResetActTemplateExpressions();
          this.props.onGetActTemplateProp('finTermGroupIds', finTermGroupIds);
          this.setState({
            selectGroupFinTermsOptions
          });
        }
        this.setState({
          isReadyToRender: true
        });
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  getActTemplateParamsList = (finTermGroupIds) => {
    api.getTemplateParamsBlocks(this.props.contractId, finTermGroupIds)
      .then(response => {
        this.props.onGetActTemplateParamsList(response.data);
      })
      .catch(error => {
        this.setState({
          errorObject: apiErrorHandler(error)
        });
      });
  };
  handleOptionsAndFinTermGroupIds = (finTermGroupIds) => {
    this.setState({
      isReadyToRender: false
    });
    const error = this.state.validationRule(finTermGroupIds);
    this.props.onGetValidationStateFormProp('finTermGroupIds', error);
    const selectGroupFinTermsOptions =
      this.state.selectGroupFinTermsOptionsAll.filter(item => {return !finTermGroupIds.includes(item.value)});
    this.getTemplateParams(true, finTermGroupIds, selectGroupFinTermsOptions);
  };
  handleExcludeGroupFinTerm = (groupFinTermId) => {
    const excludedIndex = this.props.finTermGroupIds.findIndex(item => item === groupFinTermId);
    const finTermGroupIds = this.props.finTermGroupIds.slice();
    finTermGroupIds.splice(excludedIndex, 1);
    this.handleOptionsAndFinTermGroupIds(finTermGroupIds);
  };
  handleGroupFinTermData = (groupFinTermId) => {
    return this.props.groupFinTermsView.find(item => item.groupId === groupFinTermId);
  };
  createGroupFinTermsOption = (groupFinTermId) => {
    const groupFinTerm = this.handleGroupFinTermData(groupFinTermId);
    return (
      <ConnectedGroupFinTermBlock addClasses={'mb_2du'} groupFinTerm={groupFinTerm} isSelectOptionMode={true}/>);
  };
  initSelectOptions = () => {
    const selectGroupFinTermsOptionsAll = this.props.groupFinTermsView.map((item) => {
      return {
        value: item.groupId,
        label: this.createGroupFinTermsOption(item.groupId)
      };
    });
    const selectGroupFinTermsOptions = selectGroupFinTermsOptionsAll.filter(item => {
      return !this.props.finTermGroupIds.includes(item.value);
    });
    this.setState({selectGroupFinTermsOptions, selectGroupFinTermsOptionsAll});
  };
  componentDidMount(){
    this.initSelectOptions();
    const error = this.state.validationRule(this.props.finTermGroupIds);
    this.props.onGetValidationStateFormProp('finTermGroupIds', error);
    this.props.onInitTemplateParams();
    this.getTemplateParams(false, this.props.finTermGroupIds);
  }
  componentDidUpdate(prevProps){
    if (prevProps.groupFinTermsView !== this.props.groupFinTermsView) {
      this.initSelectOptions();
    }
  }
  componentWillUnmount(){
    this.props.onInitTemplateParams();
    this.props.onInitActTemplateParamsList();
  }
  render() {
    return (
      <>
        {this.props.readonly ? null :
          <FinTermsSelect selectGroupFinTermsOptions={this.state.selectGroupFinTermsOptions}
                          handleOptionsAndFinTermGroupIds={this.handleOptionsAndFinTermGroupIds}
                          finTermGroupIds={this.props.finTermGroupIds}/>
        }
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <ActTemplateFinTermsCards finTermGroupIds={this.props.finTermGroupIds}
                                  handleGroupFinTermData={this.handleGroupFinTermData}
                                  handleExcludeGroupFinTerm={this.handleExcludeGroupFinTerm}
                                  readonly={this.props.readonly}/>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    groupFinTermsView: state.organization.item.groupFinTermsView,
    finTermGroupIds: state.organization.item.actTemplatesEdit.finTermGroupIds
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetActTemplateProp: (prop, data) => dispatch({type: actTemplatesEditActions.ACT_TEMPLATES_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onInitTemplateParams: () => dispatch({type: templateParamsActions.TEMPLATE_PARAMS_INIT}),
    onInitActTemplateParamsList: () => dispatch({type: actTemplateParamsListActions.ACT_TEMPLATE_PARAMS_LIST_INIT}),
    onResetActTemplateExpressions: () => dispatch({type: actTemplatesEditActions.ACT_TEMPLATES_EXPRESSIONS_RESET}),
    onGetTemplateParams: (templateParams) => dispatch({type: templateParamsActions.TEMPLATE_PARAMS_SET, value: templateParams}),
    onGetActTemplateParamsList: (actTemplateParamsList) => dispatch({
      type: actTemplateParamsListActions.ACT_TEMPLATE_PARAMS_LIST_SET, value: actTemplateParamsList})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinTermsSelectContainer);