//@flow
import * as React from 'react';
import NModal from "../../../components/simple/Modals/NModal/NModal";
import CheckboxInput from "../../../components/simple/FormComponents/CheckboxInput/CheckboxInput";
import CheckListContainer
  from "../../../components/simple/FormComponents/CheckListContainer/CheckListContainer";
import Text1 from "../../../components/simple/TextComponents/Text1/Text1";
import BtnEditAct from "../../../components/simple/Buttons/BtnEditAct/BtnEditAct";
import type {
  checkedCount, linkedItemsIds,
  sectionHeaderTitle
} from "../../../services/flowTypes/componentFlowTypes";
import Heading4 from "../../../components/simple/TextComponents/Heading4/Heading4";
import text from "../../../services/localization/text";
import {getCheckedListItems, hideModal} from "../../../services/flowTypes/propFnFlowTypes";
import {contractEditPageIds} from "../../../tests/testIds";
import SearchBox from "../SearchPage/SearchBox/SearchBox";
import type {errorObject, history, isReadyToRender, spinnerIsShown} from "../../../services/flowTypes/appFlowTypes";
import './AddItemsModal.css';
import RenderOnReady from "../../service/RenderOnReady/RenderOnReady";
import {connect} from "react-redux";
import searchRequestActions from "../../../store/organization/searchRequest/searchRequestActions";
import type {searchCategory, searchPropName} from "../../../services/flowTypes/dataFlowTypes";
import SearchResultsFetcher from "../SearchPage/SearchBox/SearchResultsFetcher/SearchResultsFetcher";
import apiErrorHandler from "../../../services/apiInstance/apiErrorHandler";
import AddItemRow from "./AddItemRow";
import type {onGetSearchRequestProp, onInitSearchRequestState} from "../../../services/flowTypes/propFnFlowTypes";
import ConnectedPagination from "../SearchPage/SearchBox/ConnectedPagination/ConnectedPagination";
import AddItemRowHeading from "./AddItemRowHeading";

type Props = {
  getCheckedListItems: getCheckedListItems,
  hideModal: hideModal,
  modalTitle: sectionHeaderTitle,
  history: history,
  category: searchCategory,
  searchPropName: searchPropName,
  linkedItemsIds: linkedItemsIds,
  onGetSearchRequestProp: onGetSearchRequestProp,
  onInitSearchRequestState: onInitSearchRequestState
};
type State = {
  checkedCount: checkedCount,
  isReadyToRender: isReadyToRender,
  checkedItemsIds: linkedItemsIds,
  spinnerIsShown: spinnerIsShown,
  errorObject: errorObject
};

class AddItemsModal extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      checkedItemsIds: [],
      checkedCount: 0,
      spinnerIsShown: false,
      isReadyToRender: false,
      errorObject: null
    };
  }
  showSpinner = () => {
    this.setState({spinnerIsShown: true});
  };
  hideSpinner = () => {
    this.setState({
      spinnerIsShown: false,
      isReadyToRender: true
    });
  };
  handleError = (error) => {
    this.setState({
      isReadyToRender: true,
      errorObject: apiErrorHandler(error)
    });
  };

  resetCheckboxes = () => {
    this.setState({
      checkedItemsIds: [],
      checkedCount: 0
    });
  };
  handleCheckboxClick = (itemId) => {
    const checkedItemsIds = [...this.state.checkedItemsIds];
    let checkedCount = this.state.checkedCount;
    if (checkedItemsIds.includes(itemId)) {
      const itemIdIndex = checkedItemsIds.findIndex(id => id === itemId);
      checkedItemsIds.splice(itemIdIndex, 1);
      checkedCount = checkedCount - 1;
    } else {
      checkedItemsIds.push(itemId);
      checkedCount = checkedCount + 1;
    }
    this.setState({checkedItemsIds, checkedCount});
  };
  addCheckedListItems = () => {
    this.props.getCheckedListItems(this.state.checkedItemsIds);
  };
  componentDidMount(){
    this.props.onInitSearchRequestState();
    const searchRequestObj = {
      offset: 0,
      count: 10,
      category: this.props.category,
      searchPropName: this.props.searchPropName
    };
    this.props.onGetSearchRequestProp(searchRequestObj);
  }
  render() {
    const emptyFn = () => {};
    const items = this.props[this.props.category];
    const listItems = items ? items.map((item, i) => {
      const itemRelated = this.props[this.props.category + 'Related'][i];
      const checkboxLabel = <AddItemRow category={this.props.category} item={item} itemRelated={itemRelated}/>;
      const onClick = () => this.handleCheckboxClick(item.id);
      const checkedAndDisabled = this.props.linkedItemsIds.includes(item.id);
      const checked = checkedAndDisabled || this.state.checkedItemsIds.includes(item.id);
      return (<CheckboxInput key={'agent'+i} checked={checked} onClick={onClick} checkboxLabel={checkboxLabel}
                             disabled={checkedAndDisabled} addClasses={'CheckboxInput_fs12'}
                             id={contractEditPageIds.checkboxListItem(i)}/>);
    }) : [];
    return (
      <NModal
        addClasses='NModal_ViewTemplateParams'
        modalTitle={this.props.modalTitle}
        onModalCancelBtnClick={this.props.hideModal}
        onModalActionBtnClick={this.addCheckedListItems}
        modalCancelBtnText={text.cancel}
        modalActionBtnText={text.btnAdd}
      >
        <SearchResultsFetcher showSpinner={this.showSpinner} hideSpinner={this.hideSpinner} handleError={this.handleError}/>
        <div className="flex_jc-sb pt-pb_2du">
          <div className={"flex"}>
            <Text1 addClasses={"mr_1du"}>{text.checkedFields}</Text1>
            <Heading4 addClasses={"Heading4_lh16"}>{this.state.checkedCount}</Heading4>
          </div>
          <BtnEditAct onClick={this.resetCheckboxes}>{text.nullify}</BtnEditAct>
        </div>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
          <div className='AddItemsModal__content-wrapper'>
            <SearchBox
              spinnerIsShown={this.state.spinnerIsShown} inputIsFocused={false}
              handleSearchResultsUpdate={emptyFn} onFocus={emptyFn} onBlur={emptyFn} searchDropdownIsShown={false}
              history={this.props.history} isAddItemsMode={true} addClasses={'SearchBox_in_AddItemsModal'}/>
            <div className="AddItemsModal__list-wrapper">
              <AddItemRowHeading category={this.props.category}/>
              {listItems.length ?
                <CheckListContainer>
                  {listItems}
                </CheckListContainer>
                : (<div className="pt-pb_2du">{text.noFieldsToAdd}</div>)}
            </div>
            <ConnectedPagination shouldSearchResultsUpdate={true}/>
          </div>
        </RenderOnReady>
      </NModal>
    );
  }
}
const mapStateToProps = state => {
  return {
    contracts: state.organization.searchData.contracts,
    contractsRelated: state.organization.searchData.contractsRelated,
    agents: state.organization.searchData.agents,
    agentsRelated: state.organization.searchData.agentsRelated,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetSearchRequestProp: (searchRequestObj) => dispatch({type: searchRequestActions.SEARCH_REQUEST_PROP_SET,
      value: searchRequestObj}),
    onInitSearchRequestState: () => dispatch({type: searchRequestActions.SEARCH_REQUEST_STATE_INIT})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddItemsModal);