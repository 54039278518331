//@flow
import * as React from 'react';
import {connect} from "react-redux";
import NCard from "../../../../../../../components/simple/Containers/NCard/NCard";
import type {
  actTemplateId, contractId,
  fileName, groupFinTermId, isScrollToError,
  itemDate, reportFileNamePattern, validationIsAllowed
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import Text1 from "../../../../../../../components/simple/TextComponents/Text1/Text1";
import text from "../../../../../../../services/localization/text";
import BtnEditAct from "../../../../../../../components/simple/Buttons/BtnEditAct/BtnEditAct";
import Document from "../../../../../../../components/composed/ItemDocuments/DocumentEdit/Document/Document";
import boldWhiteArrow from '../../../../../../../assets/bold-white-arrow.svg';
import actTemplatesEditActions
  from "../../../../../../../store/organization/item/actTemplatesEdit/actTemplatesEditActions";
import {
  onGetActTemplateProp,
  onGetValidationStateFormProp
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import NFormGroup from "../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import validationActions from "../../../../../../../store/validation/validationActions";
import {actTemplatesEditPageIds} from "../../../../../../../tests/testIds";
import InputFile from "../../../../../../../components/simple/FormComponents/InputFile/InputFile";
import type {onAddAlert, onGetValidationStateProp} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import FileWithTags from "./FileWithTags";
import alertsActions from "../../../../../../../store/alerts/alertsActions";
import alertsList from "../../../../../../../services/alertsList/alertsList";
import ReportFileNamePattern from "./ReportFileNamePattern";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import api from "../../../../../../../services/apiInstance/api";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {errorObject, isReadyToRender} from "../../../../../../../services/flowTypes/appFlowTypes";
import {defineWrongFileName} from "../../../../../../../components/composed/ItemDocuments/DocumentUploader/DocumentUploaderContent";
import AlertOnWrongFileName from "../../../../../../../components/composed/Alerts/AlertOnWrongFileName";

type Props = {
  fileName: fileName,
  documentDate: itemDate,
  actTemplateId: actTemplateId,
  onGetActTemplateProp: onGetActTemplateProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  validationForm: Object,
  isScrollToError: isScrollToError,
  validationIsAllowed: validationIsAllowed,
  readonly?: boolean,
  onGetValidationStateProp: onGetValidationStateProp,
  contractId: contractId,
  finTermGroupIds: Array<groupFinTermId>,
  onAddAlert: onAddAlert,
  reportFileNamePattern: reportFileNamePattern
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  wrongFileName: fileName
};


class ActTemplateDocument extends React.Component <Props, State> {
  inputFile: { current: null | HTMLInputElement };
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true,
      wrongFileName: ''
    };
    this.inputFile = React.createRef();
  }
  getFormatFileErrorStr = (ext) => {
    switch (ext) {
      case 'doc':
        return text.errorFileFormat.oldDoc;
      case 'xls':
        return text.errorFileFormat.oldXls;
      default:
        return text.errorFileFormat.unknownFileFormat;
    }
  };
  uploadDoc = () => {
    if (this.inputFile.current && this.inputFile.current.files) {
      this.setState({wrongFileName: ''});
      const file = this.inputFile.current.files[0];
      const fileName = this.inputFile.current.files[0].name;
      const wrongFileName = defineWrongFileName(fileName);
      if (wrongFileName) {
        this.setState({wrongFileName});
        return;
      }
      const re = /(?:\.([^.]+))?$/;
      const fileNameArr = re.exec(fileName);
      let ext;
      if(fileNameArr !== null){
        ext = fileNameArr[1];
      }
      if(ext && ext !== 'docx' && ext !== 'txt' && ext !== 'xlsx'){
        this.props.onGetValidationStateFormProp('tempFileId', this.getFormatFileErrorStr(ext));
        this.props.onGetValidationStateProp('validationIsAllowed', true);
        this.props.onGetActTemplateProp('fileName', fileName);
        this.props.onGetActTemplateProp('tempFileId', '');
        return false;
      }

      const formData = new FormData();
      formData.append('file', file);
      this.setState({isReadyToRender: false});
      api.uploadTempFile(formData)
        .then(response => {
          this.props.onGetActTemplateProp('tempFileId', response.data.id);
          this.props.onGetActTemplateProp('fileName', fileName);
          this.props.onGetValidationStateFormProp('tempFileId', '');
          this.props.onAddAlert(alertsList.onActTemplateDocument);
          this.setState({isReadyToRender: true});
        })
        .catch(error => {
          this.setState({
            isReadyToRender: true,
            errorObject: apiErrorHandler(error)
          });
        });
    }
  };
  componentDidMount(){
    if (!this.props.actTemplateId) {
      this.props.onGetValidationStateFormProp('tempFileId', text.error.noActTemplateFile);
    }
  }
  render() {
    const document = {
      name: this.props.fileName,
      fileDescription: '',
      createDate: this.props.documentDate
    };
    const propError = this.props.validationIsAllowed ?
      (this.props.validationForm.tempFileId ? this.props.validationForm.tempFileId : '')
      : '';
    const documentUploader = (
      <NFormGroup propError={propError} isScrollToError={this.props.isScrollToError}>
        <div className={'flex'}>
          <AlertOnWrongFileName wrongFileName={this.state.wrongFileName}/>
          <InputFile id={actTemplatesEditPageIds.buttonUploadDocument}  onInputFileChange={this.uploadDoc}
                     ref={this.inputFile} label={
            <div className="BtnNewItem pl-pr_2du">
              <img className={"ButtonNewItem__arrow"} src={boldWhiteArrow} alt="arrow-icon"/>
              {text.chooseActTemplateDoc}
            </div>
          }/>
          <BtnEditAct addClasses={'ml_3du'} onClick={null}>{this.props.fileName}</BtnEditAct>
        </div>
      </NFormGroup>

    );
    const documentBtns = (this.props.readonly ? null :(
      <div className="flex jc_fe">
        <AlertOnWrongFileName wrongFileName={this.state.wrongFileName}/>
        <InputFile id={actTemplatesEditPageIds.buttonUploadDocument}  onInputFileChange={this.uploadDoc}
                   ref={this.inputFile} label={
          <div className="BtnEditAct">{text.substituteDocument}</div>
        }/>
      </div>
    ));
    const isUpload = this.props.file !== undefined;
    const reportFileNamePattern = this.props.reportFileNamePattern && this.props.reportFileNamePattern.length ?
      this.props.reportFileNamePattern : 'N/A';
    return (
      <NCard addClasses={'mb_3du'}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <Text1 addClasses={'mb_1du'}>{text.uploadActTemplateFile1} <b>{text.uploadActTemplateFile2}</b>
          {text.uploadActTemplateFile3}<b>{text.uploadActTemplateFile4}</b>{'.'}
        </Text1>
        <FileWithTags contractId={this.props.contractId} finTermGroupIds={this.props.finTermGroupIds} />
        <ReportFileNamePattern reportFileNamePatternValue={reportFileNamePattern} readonly={this.props.readonly}/>
        {this.props.actTemplateId ? (
          <NFormGroup propError={propError} isScrollToError={this.props.isScrollToError}>
            <Document document={document} itemId={this.props.actTemplateId} isActTemplateDocument={true}
                      documentBtns={documentBtns} isUpload={isUpload}
                      linkIdToDownload={actTemplatesEditPageIds.buttonDownloadDocument}/>
          </NFormGroup>
        ) : (
          documentUploader
        )}
      </NCard>
    );
  }
}

const mapStateToProps = state => {
  return {
    fileName: state.organization.item.actTemplatesEdit.fileName,
    documentDate: state.organization.item.actTemplatesEdit.documentDate,
    finTermGroupIds: state.organization.item.actTemplatesEdit.finTermGroupIds,
    validationForm: state.validation.form,
    isScrollToError: state.validation.isScrollToError,
    validationIsAllowed: state.validation.validationIsAllowed,
    reportFileNamePattern: state.organization.item.actTemplatesEdit.reportFileNamePattern
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetActTemplateProp: (prop, data) => dispatch({type: actTemplatesEditActions.ACT_TEMPLATES_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetValidationStateProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onAddAlert: (newAlert) => dispatch({
      type: alertsActions.ALERT_ADD, value: newAlert})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActTemplateDocument);