//@flow
import {safeArray} from "./safe";

export const getSourceParams = (
  source: string,
  merchantParams: [{ source: string }],
  managerIds: [{ source: string }],
  processorIds: [{ source: string }]
) => {
  let merchant = safeArray(merchantParams).find(s => s.source && s.source.toUpperCase() === source);
  let manager = safeArray(managerIds).find(s => s.source && s.source.toUpperCase() === source);
  let processor = safeArray(processorIds).find(s => s.source && s.source.toUpperCase() === source);

  return {
    merchant, manager, processor
  }
}