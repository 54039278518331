//@flow
import * as React from 'react';
import type {groupFinTerm, itemDate, reportId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../../services/localization/text";
import ConnectedGroupFinTermBlock from "../../../../../../../components/composed/ConnectedGroupFinTermBlock/ConnectedGroupFinTermBlock";
import ReportBaseDocDownload from "./BaseAndAddendumDocDownload";
import PropValueView from "../../../../../../../components/composed/PropValueView/PropValueView";
import getCurrencyLabel from "../../../../../../../utils/getCurrencyLabel";
import ConvertedDataRow from "./ConvertedDataRow";
import ReportGroupFinTermWarning from "./ReportGroupFinTermWarning";
import {reportViewPageIds} from "../../../../../../../tests/testIds";

type Props = {
  startReportPeriodDate: itemDate,
  endReportPeriodDate: itemDate,
  reportId: reportId,
  finTermsList: Array<groupFinTerm>
};

class ReportGroupFinTerms extends React.Component <Props> {
  render() {
    const itemsList = this.props.finTermsList.map((groupFinTerm, i) => {
      const rightTopComponent = (<ReportBaseDocDownload groupFinTerm={groupFinTerm} reportId={this.props.reportId}
                                                        groupFinTermIndex={i}/>);
      const currencyLabel = getCurrencyLabel(groupFinTerm.currency);
      const warningComponent = (
        <ReportGroupFinTermWarning startReportPeriodDate={this.props.startReportPeriodDate}
                                   endReportPeriodDate={this.props.endReportPeriodDate} finTerms={groupFinTerm.finTerms}/>
      );
      const bottomComponent =
        groupFinTerm.source === 'REDMINE' ? (
          (groupFinTerm.rateAmount || groupFinTerm.rate) ? (
            <>
              <div className="flex_jc-sb flex_wrap">
                <PropValueView label={text.rateHours} value={
                  <>
                    <span id={reportViewPageIds.spanRateHours(groupFinTerm.groupId)} className={'mr_1du'}>{groupFinTerm.rate}</span> {currencyLabel}
                  </>
                }/>
                <PropValueView label={text.hours} value={groupFinTerm.hours} testIdForValue={reportViewPageIds.divHours(groupFinTerm.groupId)}/>
                <PropValueView label={text.rateAmount} value={<>
                  <span id={reportViewPageIds.spanRateAmount(groupFinTerm.groupId)}  className={'mr_1du'}>{groupFinTerm.rateAmount}</span> {currencyLabel}
                </>}/>
              </div>
              <ConvertedDataRow groupFinTerm={groupFinTerm}/>
            </>

          ) : null
        ) : (
          (groupFinTerm.rateAmount || groupFinTerm.totalAmount) ? (
            <>
              <div className="flex_jc-sb flex_wrap">
                <PropValueView label={text.rateAmount} value={
                  <>
                    <span id={reportViewPageIds.spanRateAmount(groupFinTerm.groupId)} className={'mr_1du'}>{groupFinTerm.rateAmount}</span> {currencyLabel}
                  </>
                }/>
                <PropValueView label={text.count} value={groupFinTerm.count} testIdForValue={reportViewPageIds.divCount(groupFinTerm.groupId)}/>
                <PropValueView label={text.totalAmount} value={<>
                  <span id={reportViewPageIds.spanTotalAmount(groupFinTerm.groupId)} className={'mr_1du'}>{groupFinTerm.totalAmount}</span> {currencyLabel}
                </>}/>
              </div>
              <ConvertedDataRow groupFinTerm={groupFinTerm}/>
            </>
          ) : null
        );
      return (<ConnectedGroupFinTermBlock key={'groupFinTerm'+i} groupFinTerm={groupFinTerm}
                                          rightTopComponent={rightTopComponent}
                                          bottomComponent={bottomComponent}
                                          warningComponent={warningComponent}
                                          addClasses={'GroupFinTermBlock_with-back mb_1du GroupFinTermBlock_in_ReportMainDataView'}
      />);
    });
    return (
     itemsList
    );
  }
}
export default ReportGroupFinTerms;