//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  finTermIndex,
  finTermParamsRowIndex, rateParamIndex
} from "../../../../../../../../../../../../../services/flowTypes/componentFlowTypes";
import type {finTermParamValue} from "../../../../../../../../../../../../../services/flowTypes/dataFlowTypes";
import validationActions from "../../../../../../../../../../../../../store/validation/validationActions";
import validationPatterns from "../../../../../../../../../../../../../services/validationPatterns/validationPatterns";
import groupFinTermsValidationScheme from "../../../../../../../../../../../../../configData/groupFinTermsValidationScheme";
import {
  onGetValidationStateFormProp,
  onGetValidationStateProp
} from "../../../../../../../../../../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  finTermIndex: finTermIndex,
  finTermParamsRowIndex: finTermParamsRowIndex,
  ratePercentIndex: rateParamIndex,
  rateMinIndex: rateParamIndex,
  rateAbsIndex: rateParamIndex,
  ratePercentValue: finTermParamValue,
  rateMinValue: finTermParamValue,
  rateAbsValue: finTermParamValue,
  onGetValidationStateProp: onGetValidationStateProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp
};
//This component is hack to validate rule 'required any one value of three rate values'. It's invisible on page
class RateParamsValidator extends React.Component <Props, State> {
  setPropName = () => {
    return 'finTerm' + this.props.finTermIndex + 'paramsRow' + this.props.finTermParamsRowIndex + 'ratePercent';
  };
  validate = (rateValuesArr) => {
    const error = validationPatterns[groupFinTermsValidationScheme.finTerms.params.paramsRow[0]](rateValuesArr);
    this.props.onGetValidationStateProp('isScrollToError', false);
    this.props.onGetValidationStateFormProp(this.setPropName(), error);
  };
  componentDidUpdate(){
    this.validate([this.props.ratePercentValue, this.props.rateMinValue, this.props.rateAbsValue]);
  }
  componentDidMount(){
    this.validate([this.props.ratePercentValue, this.props.rateMinValue, this.props.rateAbsValue]);
  }
  render() {
    return (
      <div className="RateParamsValidator">{this.props.ratePercentValue + this.props.rateMinValue + this.props.rateAbsValue}</div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ratePercentValue: state.organization.item.finTermsEdit[ownProps.finTermIndex]
      .params[ownProps.finTermParamsRowIndex][ownProps.ratePercentIndex].value,
    rateMinValue: state.organization.item.finTermsEdit[ownProps.finTermIndex]
      .params[ownProps.finTermParamsRowIndex][ownProps.rateMinIndex].value,
    rateAbsValue: state.organization.item.finTermsEdit[ownProps.finTermIndex]
      .params[ownProps.finTermParamsRowIndex][ownProps.rateAbsIndex].value
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetValidationStateProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RateParamsValidator);    