//@flow
import * as React from 'react';
import getUniqueKey from "../../../../utils/getUniqueKey";
import moment from "moment";
import {documentsPageIds} from "../../../../tests/testIds";
import NFormGroup from "../../../simple/FormComponents/NFormGroup/NFormGroup";
import text from "../../../../services/localization/text";
import NInput from "../../../simple/FormComponents/NInput/NInput";
import NSelect from "../../../simple/FormComponents/NSelect/NSelect";
import Document from "../DocumentEdit/Document/Document";
import Heading4 from "../../../simple/TextComponents/Heading4/Heading4";
import SecText from "../../../simple/TextComponents/SecText/SecText";
import InputFile from "../../../simple/FormComponents/InputFile/InputFile";
import type {
  agentId,
  contractId,
  document,
  documentFolderList, fileDescription, fileName,
  folderId
} from "../../../../services/flowTypes/dataFlowTypes";
import type {onDocumentsArrUpdate} from "../../../../services/flowTypes/propFnFlowTypes";
import type {inputFileKey, isMultipleUpload} from "../../../../services/flowTypes/appFlowTypes";
import type {
  isAddAgreementMode,
  itemType,
  selectOptions
} from "../../../../services/flowTypes/componentFlowTypes";
import Text1 from "../../../simple/TextComponents/Text1/Text1";
import BtnEditAct from "../../../simple/Buttons/BtnEditAct/BtnEditAct";
import AlertOnWrongFileName from "../../Alerts/AlertOnWrongFileName";

type Props = {
  documentsArr: Array<document>,
  onDocumentsArrUpdate: onDocumentsArrUpdate,
  folderId?: folderId,
  selectOptions?: selectOptions,
  folderList?: documentFolderList,
  itemType: itemType,
  itemId: contractId | agentId,
  isMultipleUpload: isMultipleUpload,
  isAddAgreementMode?: isAddAgreementMode,
  inheritedFileDescription?: fileDescription
};
type State = {
  inputFileKey: inputFileKey,
  wrongFileName: fileName
};

export const defineWrongFileName = (fileName) => {
  const prohibitedSymbols = ["%"];
  for (let i=0; i < prohibitedSymbols.length; i++) {
    if (fileName.includes(prohibitedSymbols[0])) {
      return fileName;
    }
  }
  return '';
};

class DocumentUploaderContent extends React.Component <Props, State> {
  inputFile: { current: null | HTMLInputElement };
  constructor(props: Props) {
    super(props);
    this.inputFile = React.createRef();
    this.state = {
      inputFileKey: 'defaultInputFileKey',
      wrongFileName: ''
    };
  }
  resetDoc = (index) => {
    const documentsArr = this.props.documentsArr.map(item => {return {...item}});
    documentsArr.splice(index, 1);
    this.setState({
      inputFileKey: getUniqueKey()
    });
    this.props.onDocumentsArrUpdate(documentsArr);
  };
  addDoc = (e:SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();
    this.setState({wrongFileName: ''});
    const folderId = this.props.folderId ? +this.props.folderId : 0;
    if (this.inputFile.current && this.inputFile.current.files) {
      const files = this.inputFile.current.files;
      let newDocumentsArr = [];
      for (let i=0; i < files.length; i++) {
        const wrongFileName = defineWrongFileName(files[i].name);
        if (wrongFileName) {
          this.setState({wrongFileName});
          return;
        }
        newDocumentsArr[i] = {
          name: files[i].name,
          fileDescription: this.props.inheritedFileDescription ? this.props.inheritedFileDescription : '',
          size: files[i].size,
          createDate: moment(),
          file: files[i],
          selectedOption: this.props.selectOptions ? this.props.selectOptions.find(item => item.value === folderId) : null
        };
      }
      const documentsArr = this.props.documentsArr.map(item => {return {...item}});
      const updatedDocumentsArr = this.props.isMultipleUpload ? [...documentsArr, ...newDocumentsArr] : newDocumentsArr;
      this.props.onDocumentsArrUpdate(updatedDocumentsArr);
    }
  };
  onDescriptionChange = (e:SyntheticEvent<HTMLInputElement>, index) => {
    const documentsArr = this.props.documentsArr.map(item => {return {...item}});
    documentsArr[index].fileDescription = e.currentTarget.value;
    this.props.onDocumentsArrUpdate(documentsArr);
  };
  handleFolderChange = (folder, index) => {
    const documentsArr = this.props.documentsArr.map(item => {return {...item}});
    documentsArr[index].selectedOption = {
      value: folder.value,
      label: folder.label
    };
    this.props.onDocumentsArrUpdate(documentsArr);
  };
  render() {
    const documentsArr = this.props.documentsArr.map((item, index) => {
      const documentBtns = <div className="flex jc_fe">
        <BtnEditAct addClasses={'BtnEditAct_delete'} id={documentsPageIds.buttonDeleteUploadedDocument(index)}
                    onClick={() => this.resetDoc(index)} >{text.deleteBtnLabel}</BtnEditAct>
      </div> ;
      // const formGroupClassName = this.props.isAddAgreementMode ? "mb_3du" : 'mb_3du';
      const descriptionAndFolderEdit = (
        <div className=''>
          <NFormGroup label={text.description} addClasses={"mb_1du" }>
            <NInput id={documentsPageIds.inputUploadedDocumentDescription(index)} value={this.props.documentsArr[index].fileDescription}
                    onChange={(e) => this.onDescriptionChange(e, index)}/>
          </NFormGroup>
          {this.props.isAddAgreementMode ? null : (
            <NFormGroup label={text.folder.folder} addClasses={'m1_3du'}>
              <NSelect value={this.props.documentsArr[index].selectedOption}
                       isSearchable={true}
                       id={documentsPageIds.selectUploadedDocumentFolder(index)}
                       inputId={documentsPageIds.selectInputUploadedDocumentFolder(index)}
                       onChange={(folder) => this.handleFolderChange(folder, index)}
                       isSelectMenuWide={false}
                       options={this.props.selectOptions}/>
            </NFormGroup>
          )}
        </div>
      );
      return <Document key={'document'+index}
                       folderList={this.props.folderList}
                       isDocumentEdit={false}
                       document={this.props.documentsArr[index]} itemType={this.props.itemType} itemId={this.props.itemId}
                       isUpload={true} documentBtns={documentBtns} isDocumentDescriptionEdit={true}
                       descriptionAndFolderEdit={descriptionAndFolderEdit}/>
    });
    const inputFileLabel = this.props.isAddAgreementMode ? (
      <Text1 addClasses={'Text1_link'}>{text.chooseFile}</Text1>
    ) : (
      <div className={'BtnNewItem pl-pr_2du'}>{text.chooseFile}</div>
    );
    return (
      <div className={'mb_3du'}>
        <div className="flex_jc-sb pt_3du mb_3du">
          <div>
            <Heading4>{text.volumeLimit}</Heading4>
            <div className="flex">
              <Heading4 addClasses={'mr_2du'}>{text.allowedDocTypes}</Heading4>
              <SecText> {text.allowedDocTypesList}</SecText>
            </div>
          </div>
          <AlertOnWrongFileName wrongFileName={this.state.wrongFileName}/>
          <InputFile id={documentsPageIds.buttonUploadDocument} ref={this.inputFile}
                     key={this.state.inputFileKey} isMultiple={this.props.isMultipleUpload}
                     onInputFileChange={this.addDoc} label={inputFileLabel}/>
        </div>
        {documentsArr}
      </div>
    );
  }
}

export default DocumentUploaderContent;    