//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  reportTotalAmountExpression, reportTotalCountExpression,
  reportTotalExpression, variableAlias, variableName
} from "../../../../../../../../../services/flowTypes/dataFlowTypes";

type Props = {
  reportTotalExpression: reportTotalExpression,
  reportTotalAmountExpression: reportTotalAmountExpression,
  reportTotalCountExpression: reportTotalCountExpression,
  tag: variableName | variableAlias
};

class TagAppliedInExpressionIcon extends React.Component <Props> {
  defineIsTagAppliedInExpression = (tag, expressionsArr) => {
    const slicedTag = tag.slice(2, -1);
    let isTagAppliedInExpression = false;
    for (let i=0; i < expressionsArr.length; i++) {
      if (expressionsArr[i].includes(slicedTag)) {
        isTagAppliedInExpression = true;
        break;
      }
    }
    return isTagAppliedInExpression;
  };

  render() {
    const expressionsArr = [this.props.reportTotalExpression, this.props.reportTotalAmountExpression, this.props.reportTotalCountExpression];
    return (
      this.defineIsTagAppliedInExpression(this.props.tag, expressionsArr) ?
        <div className="CopyBtnOnHover__checked-icon"/> : null
    );
  }
}

const mapStateToProps = state => {
  return {
    reportTotalExpression: state.organization.item.actTemplatesEdit.reportTotalExpression,
    reportTotalAmountExpression: state.organization.item.actTemplatesEdit.reportTotalAmountExpression,
    reportTotalCountExpression: state.organization.item.actTemplatesEdit.reportTotalCountExpression
  };
};

export default connect(mapStateToProps, null)(TagAppliedInExpressionIcon);