//@flow
import * as React from 'react';
import RenderOnReady from "../../../../components/service/RenderOnReady/RenderOnReady";
import type {
  errorObject,
  history,
  isReadyToRender,
  locationType,
  match
} from "../../../../services/flowTypes/appFlowTypes";
import type {
  actTemplateId,
  actTemplateName,
  addServiceName,
  contractId,
  contractName,
  groupFinTermId,
  groupFinTermName,
  organizationId
} from "../../../../services/flowTypes/dataFlowTypes";
import connect from "react-redux/es/connect/connect";
import contractMainDataActions from "../../../../store/organization/item/contractMainData/contractMainDataActions";
import {onGetContractMainData} from "../../../../services/flowTypes/propFnFlowTypes";
import concatIdName from "../../../../utils/concatIdName";
import api from "../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../services/apiInstance/apiErrorHandler";
import GetDataOnMountAndUpdate from "../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import FixedHeaderContent from "../../../../components/simple/Containers/FixedHeader/FixedHeaderContent";
import ContractButtonsRoutes from "../../../../components/service/Routes/ContractButtonsRoutes";
import RedirectToCorrectOrgUrl from "../../../../components/service/RedirectToCorrectOrgUrl/RedirectToCorrectOrgUrl";
import handleCorrectOrgIdInResponse from "../../../../utils/handleCorrectOrgIdInResponse";
import FixedSubHeader from "../../../../components/simple/Containers/FixedHeader/FixedSubHeader";
import text from "../../../../services/localization/text";
import {
  actTemplatesSuffix,
  addServicesSuffix,
  contractsSuffix,
  contrPathView,
  mainDataSuffix,
  newActTemplateSuffix,
  newAddServiceSuffix,
  newGroupFinTermSuffix,
  orgPath
} from "../../../../services/urlStrings/urlStrings";
import ContractMenu from "./contract/ContractMenu/ContractMenu";
import AlertUnverifiedContract from "../../../../components/simple/Containers/AlertCard/AlertUnverifiedContract";
import contractMenuItems from "../../../../configData/contractMenuItems";

type Props = {
  isCheckedAndVerified?: boolean;
  match: match,
  onGetContractMainData: onGetContractMainData,
  contractId: contractId,
  name: contractName,
  location: locationType,
  organizationId: organizationId,
  history: history,
  groupFinTermName: groupFinTermName,
  groupFinTermId: groupFinTermId,
  actTemplateName: actTemplateName,
  actTemplateId: actTemplateId,
  addServiceName: addServiceName
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  correctOrganizationId: organizationId
};

class Contract extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);

    const baseContractsPath = orgPath(this.props.organizationId) + contractsSuffix

    this.state = {
      isReadyToRender: false,
      errorObject: null,
      correctOrganizationId: null,
      baseContractsPath
    };
  }

  defineNestedPageTitle = () => {
    const pathnameArr = this.props.location.pathname.split('/');

    if (this.props.location.pathname.includes(newGroupFinTermSuffix)) {
      return text.subHeaderTitle.newGroupFinTerm
    } else if (this.props.location.pathname.includes(newActTemplateSuffix)) {
      return text.subHeaderTitle.newActTemplate;
    } else if (this.props.location.pathname.includes(actTemplatesSuffix)) {
      if (pathnameArr.length === 9) {
        const actTemplateTitle = concatIdName(this.props.actTemplateId, this.props.actTemplateName);
        return this.props.location.pathname.includes("edit") ? actTemplateTitle + text.editTitleSuffix : actTemplateTitle
      }
    } else if (this.props.location.pathname.includes(newAddServiceSuffix)) {
      return text.subHeaderTitle.newAddService
    } else if (this.props.location.pathname.includes(addServicesSuffix)) {
      if (pathnameArr.length > 8) {
        const addServiceTitle = this.props.addServiceName;
        return this.props.location.pathname.includes("edit") ? addServiceTitle + text.editTitleSuffix : addServiceTitle
      }
    } else if (this.props.location.pathname.includes("edit")) {
      return text.editTitleLabel
    }
    return null
  }
  definePageTitle = () => {
    const path = this.props.location.pathname
    const sources = contractMenuItems(this.props.organizationId, this.props.contractId)
    const filtered = sources.filter(s => path.indexOf(s.linkTo) > -1)
    if (filtered.length > 0) {
      return filtered[0].linkName
    }
    return "n/a"
  }
  defineTitles = () => {
    let result = {
      isNested: false,
      headerTitle: text.contracts,
      title: "",
      headerTitleLinkTo: this.props.contractId ? orgPath(this.props.organizationId) + contrPathView(this.props.contractId) + mainDataSuffix : "",
      subHeaderTitle: this.props.contractId ? concatIdName(this.props.contractId, this.props.name) : ""
    }

    if (!this.props.contractId) {
      return result
    }

    const nestedTitle = this.defineNestedPageTitle()
    if (nestedTitle !== null) {
      return {
        ...result,
        isNested: true,
        title: nestedTitle,
      }
    }

    return {
      ...result,
      title: this.definePageTitle(),
    }
  };
  setContractMainData = (contractMainData) => {
    this.props.onGetContractMainData(contractMainData);
    this.setState({isReadyToRender: true});
  };
  getContractMainData = () => {
    const contractId = +this.props.match.params.id;
    api.getContractById(contractId)
      .then(response => {
        handleCorrectOrgIdInResponse(this, response.data, this.setState, this.setContractMainData);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };

  render() {
    const {title, isNested, headerTitle, headerTitleLinkTo, subHeaderTitle} = this.defineTitles();
    return (
      <RedirectToCorrectOrgUrl correctOrganizationId={this.state.correctOrganizationId} history={this.props.history}>
        <GetDataOnMountAndUpdate itemId={this.props.match.params.id} getDataOnMountAndUpdate={this.getContractMainData}>
          <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
            <FixedHeaderContent title={headerTitle} subtitle={subHeaderTitle} titleLinkTo={headerTitleLinkTo}
                                history={this.props.history}/>
            <FixedSubHeader
              title={
                <div className="flex">
                  {title}
                  {this.props.isCheckedAndVerified || <AlertUnverifiedContract addClasses="ml_2du"/>}
                </div>
              }
              onBtnBackClick={() => {
                if (isNested) {
                  this.props.history.goBack()
                } else {
                  this.props.history.push({pathname: this.state.baseContractsPath});
                }
              }}
              buttons={
                <ContractButtonsRoutes
                  organizationId={this.props.organizationId}
                  contractId={this.props.contractId}
                />
              }
              history={this.props.history}
            />
            {this.props.location.pathname.includes("edit") || this.props.location.pathname.includes("/new-") ?
              null : (
                <ContractMenu contractId={this.props.contractId} organizationId={this.props.organizationId}/>
              )}
          </RenderOnReady>
        </GetDataOnMountAndUpdate>
      </RedirectToCorrectOrgUrl>
    );
  }
}

const mapStateToProps = state => {
  return {
    contractId: state.organization.item.contractMainData.id,
    isCheckedAndVerified: state.organization.item.contractMainData.isCheckedAndVerified,
    name: state.organization.item.contractMainData.name,
    organizationId: state.organization.organizationId,
    groupFinTermName: state.organization.item.groupFinTermsEdit.name,
    groupFinTermId: state.organization.item.groupFinTermsEdit.groupId,
    actTemplateName: state.organization.item.actTemplatesEdit.name,
    actTemplateId: state.organization.item.actTemplatesEdit.id,
    addServiceName: state.organization.item.addServiceEdit.name
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetContractMainData: (contractMainData) => dispatch({
      type: contractMainDataActions.CONTRACT_MAIN_DATA_SET,
      value: contractMainData
    })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Contract);