//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {children, htmlFor, id, label} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './ParamFormGroup.css';
import type {DnDisForbidden, labelTooltip, onDelParamClick} from "../../../../services/flowTypes/componentFlowTypes";
import NFormGroup from "../NFormGroup/NFormGroup";
import BtnDel from "../../Buttons/BtnDel/BtnDel";

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  validationState?: any,
  labelTooltip?: labelTooltip,
  label?: label,
  labelForId?: htmlFor,
  onDelParamClick?: onDelParamClick,
  DnDisForbidden?: DnDisForbidden
};

//TODO: add type to validationState

const ParamFormGroup = (props: Props) => {
  const className = props.DnDisForbidden ? "ParamFormGroup ParamFormGroup_DnDisForbidden" : "ParamFormGroup";
  return (
    <div id={props.id} className={baseClass(className, props.addClasses)}>
      <div className="DnD-ico-container mr_3du"/>
      <NFormGroup validationState={props.validationState} label={props.label}
                  labelForId={props.labelForId} labelTooltip={props.labelTooltip}>
        {props.children}
      </NFormGroup>
      <BtnDel addClasses={"ml_1du"} onClick={props.onDelParamClick}/>
    </div>
  );
};

export default ParamFormGroup;