//@flow
import contractAgentsActions from './contractAgentsActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {contractAgents} from "../../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
export const initialState = null;

const contractAgentsReducer = (state:contractAgents = initialState, action:reducerAction) => {
  switch (action.type) {
    case contractAgentsActions.CONTRACT_AGENTS_INIT:
      return initialState;
    case contractAgentsActions.CONTRACT_AGENTS_SET:
      return action.value;
    default:
      return state;
  }
};

export default contractAgentsReducer;