const steps = [
  {name: 'Type of Service', pathnameSuffix: 'type'},
  {name: 'Documents', pathnameSuffix: 'docs'},
  {name: 'Sheets', pathnameSuffix: 'sheets'},
  {name: 'Confirmation', pathnameSuffix: 'confirmation'}
];

export const TabStepsDefProps = {
  steps: steps,
  locationPathname: '/some/path/docs'
};
