//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './ForwardButtonIcon.css';

type Props = {
  addClasses?: addClasses
};

const ForwardButtonIcon = (props: Props) => {
  return (
    <div className={baseClass("ForwardButtonIcon", props.addClasses)}/>
  );
};

export default ForwardButtonIcon;