//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './EditModeIcon.css';

type Props = {
  id?: id,
  addClasses?: addClasses
};

const EditModeIcon = (props: Props) => {
  return (
    <div id={props.id} className={baseClass("EditModeIcon", props.addClasses)}/>
  );
};

export default EditModeIcon;