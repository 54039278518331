//@flow
import * as React from 'react';
import {connect} from "react-redux";
import BtnDel from "../../../../../../../components/simple/Buttons/BtnDel/BtnDel";
import type {
  addAgreement,
  contractStatus,
  documentsList
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {errorObject, isReadyToRender, modalIsShown} from "../../../../../../../services/flowTypes/appFlowTypes";
import Can from "../../../../../../../services/permissions/Can";
import {addAgreementsPageIds} from "../../../../../../../tests/testIds";
import text from "../../../../../../../services/localization/text";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import NModal from "../../../../../../../components/simple/Modals/NModal/NModal";
import api from "../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import addAgreementsActions from "../../../../../../../store/organization/item/addAgreements/addAgreementsActions";
import type {
  mountDocumentsListFetcher,
  onGetAddAgreements,
  unmountDocumentsListFetcher
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import concatIdName from "../../../../../../../utils/concatIdName";
import CheckboxInput from "../../../../../../../components/simple/FormComponents/CheckboxInput/CheckboxInput";
import type {isDeleteWithFile} from "../../../../../../../services/flowTypes/componentFlowTypes";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";
import NHeading2 from "../../../../../../../components/simple/TextComponents/NHeading2/NHeading2";

type Props = {
  addAgreement: addAgreement,
  statusQueryParam: contractStatus,
  onGetAddAgreements: onGetAddAgreements,
  addAgreementIndex: number,
  documentsList: documentsList,
  mountDocumentsListFetcher: mountDocumentsListFetcher,
  unmountDocumentsListFetcher: unmountDocumentsListFetcher
};
type State = {
  modalIsShown: modalIsShown,
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  isDeleteWithFile: isDeleteWithFile
};


class ButtonDeleteAddAgreement extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null,
      modalIsShown: false,
      isDeleteWithFile: false
    };
  }
  onCheckboxClick = () => {
    this.setState({isDeleteWithFile: !this.state.isDeleteWithFile});
  };
  showModal = () => {
    this.setState({
      modalIsShown: true
    });
  };
  hideModal = () => {
    this.setState({
      modalIsShown: false,
      isDeleteWithFile: false
    });
  };
  deleteAddAgreement = () => {
    this.props.unmountDocumentsListFetcher();
    api.deleteAddAgreement(this.props.addAgreement, this.props.statusQueryParam, this.state.isDeleteWithFile)
      .then(response => {
        this.props.mountDocumentsListFetcher();
        this.props.onGetAddAgreements(response.data);
        this.setState({
          isReadyToRender: true,
          modalIsShown: false,
          isDeleteWithFile: false
        });
      })
      .catch(error => {
        this.setState({
          modalIsShown: false,
          isDeleteWithFile: false,
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    const file = this.props.documentsList.find(item => item.fileId === this.props.addAgreement.fileId);
    const fileName = file ? file.name : 'File name is not defined';
    const isBtnDelDisabled = !!(this.props.addAgreement.finTermGroupIds && this.props.addAgreement.finTermGroupIds.length);
    const finTermGroupIdsStr = this.props.addAgreement.finTermGroupIds.map(item => "#" + item);
    const finTermGroupIdsStrJoined = finTermGroupIdsStr.join(', ');
    const disabledReasonTooltipText = isBtnDelDisabled ?
      text.addAgreementsDisabledDeleteTooltip(finTermGroupIdsStrJoined) : '';
    return (
      <>
        <Can do={'usePermission'} on={permissionsStrValues.addAgreement.delete}>
          <BtnDel id={addAgreementsPageIds.buttonDeleteAddAgreement(this.props.addAgreementIndex)}
                  disabled={isBtnDelDisabled} disabledReasonTooltipText={disabledReasonTooltipText}
                  onClick={this.showModal} isCardButtonMode={true}/>
        </Can>
        {this.state.modalIsShown ? (
          <NModal modalTitle={text.deleteAddAgreementModalTitle}
                  modalActionBtnText={text.btnDel} modalCancelBtnText={text.cancel} isModalDocumentEdit={true}
                  onModalActionBtnClick={this.deleteAddAgreement} onModalCancelBtnClick={this.hideModal}>
            <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
            <NHeading2 addClasses={'mt_3du mb_3du'}>
              {text.deleteAddAgreementConfirmation}
              <span className={"NHeading2 NHeading2__span-link-color"}>{concatIdName(this.props.addAgreement.id, this.props.addAgreement.name)}</span>
              {"?"}
            </NHeading2>
            <CheckboxInput checked={this.state.isDeleteWithFile} onClick={this.onCheckboxClick}
                           checkboxLabel={text.deleteWithFileCheckboxLabel(fileName)}/>
          </NModal>
            ) : null}
      </>

    );
  }
}

const mapStateToProps = state => {
  return {
    statusQueryParam: state.organization.linkedItemsRequestParams.hasOwnProperty('status') ?
      state.organization.linkedItemsRequestParams.status : null,
    documentsList: state.organization.item.itemDocuments
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetAddAgreements: (addAgreements) => dispatch({
      type: addAgreementsActions.ADD_AGREEMENTS_SET, value: addAgreements}),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ButtonDeleteAddAgreement);    