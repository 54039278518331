//@flow
import adminActions from './adminActions';
import type {action} from "../../services/flowTypes/appFlowTypes";
import type {admin} from "../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = {
  allOrganizationsList: null,
  rolesList: null,
  globalPermissionsList: null,
  statuses: null
};

const adminReducer = (state:admin = initialState, action:reducerAction) => {
  switch (action.type) {
    case adminActions.ADMIN_STATE_PROP_SET:
      return {
        ...state,
        [action.value.prop]: action.value.data
      }
    default:
      return state;
  }
};

export default adminReducer;