//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {children, disabled, id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './TileIcon.css';
import type {tileIconType} from "../../../../services/flowTypes/componentFlowTypes";
import ButtonTooltipContainer from "../ButtonTooltipContainer/ButtonTooltipContainer";
import text from "../../../../services/localization/text";

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  tileIconType: tileIconType,
  disabled?: disabled
};

const TileIcon = (props: Props) => {
  const TileIconClassName = props.disabled ? "TileIcon TileIcon_disabled" : "TileIcon";
  return (
    <ButtonTooltipContainer tooltipContent={text.tileIconTooltip[props.tileIconType]} tooltipPosition={'top-24'}>
      <div id={props.id} className={baseClass(TileIconClassName, props.addClasses)}>
        <div className={`TileIcon__container TileIcon__${props.tileIconType}-ico`}/>
        {props.children}
      </div>
    </ButtonTooltipContainer>

  );
};

export default TileIcon;