import * as React from "react";
import currencyEntities from "./currencyEntities";

const getCurrencyEntity = (currency) => {
  return currencyEntities[currency] ? <span>{currencyEntities[currency]}</span> : '';
};
const getCurrencyLabel = (currency) => {
  return (<span>{getCurrencyEntity(currency)} {" " + currency}</span> );
};

export const getCurrencyIco = (currency) => {
  const currEnt = getCurrencyEntity(currency);
  return currEnt ? currEnt : (<span>{currency}</span>);
};

export default getCurrencyLabel;