//@flow
import * as React from 'react';
import {HashRouter} from "react-router-dom";
import {orgPath, supMyOrgsPath} from "../../../services/urlStrings/urlStrings";
import {Redirect, Route} from "react-router-dom";
import Layout from "../../../pages/Layout";
import SupLayout from "../../../supPages/SupLayout";
import HistoryLocationsHandler from "../HistoryLocationsHandler/HistoryLocationsHandler";

type Props = {};

class LayoutRoutes extends React.Component <Props> {
  render() {
    return (
      <HashRouter>
        <Route exact path="/" render={() => (<Redirect to={supMyOrgsPath} />)} />
        <Route path={'/sup'} component={SupLayout}/>
        <Route path={orgPath('') + '/new-ui-kit'} component={Layout}/>
        <Route path={orgPath('')} component={Layout}/>
        <Route path="/" component={HistoryLocationsHandler}/>
      </HashRouter>
    );
  }
}

export default LayoutRoutes;    