//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {children, id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './WarningContainer.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  title: string | React.Node
};

const WarningContainer = (props: Props) => {
  return (
    <div id={props.id} className={baseClass("WarningContainer", props.addClasses)}>
      <div className="WarningContainer__title">{props.title}</div>
      <div className="WarningContainer__text">
        {props.children}
      </div>
    </div>
  );
};

export default WarningContainer;