//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  children,
  errorObject,
  history,
  isReadyToRender,
  match
} from "../../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import GetDataOnMountAndUpdate
  from "../../../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import RedirectToCorrectOrgUrl
  from "../../../../../../components/service/RedirectToCorrectOrgUrl/RedirectToCorrectOrgUrl";
import api from "../../../../../../services/apiInstance/api";
import handleCorrectOrgIdInResponse from "../../../../../../utils/handleCorrectOrgIdInResponse";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import type {addServiceStatus, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import addServiceEditActions from "../../../../../../store/organization/item/addServiceEdit/addServiceEditActions";
import type {onSetAddServiceEdit, onSetSheets} from "../../../../../../services/flowTypes/propFnFlowTypes";
import sheetsActions from "../../../../../../store/organization/item/sheets/sheetsActions";
import {getImmutableCopyOfSheets} from "../AddServiceEdit/AddServiceSheetsEdit";

type Props = {
  match: match,
  history: history,
  onSetAddServiceEdit: onSetAddServiceEdit,
  status: addServiceStatus,
  onSetSheets: onSetSheets,
  children: children
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  correctOrganizationId: organizationId
};

class GetAddService extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: false,
      correctOrganizationId: null
    };
  }
  setAddServiceData = (addService) => {
    this.props.onSetSheets(getImmutableCopyOfSheets(addService.sheets));
    this.props.onSetAddServiceEdit(addService);
    this.setState({isReadyToRender: true});
  };
  getAddService = () => {
    const contractId = +this.props.match.params.id;
    const addServiceId = +this.props.match.params.addServiceId;
    api.getAddService(contractId, addServiceId)
      .then(response => {
        handleCorrectOrgIdInResponse(this, response.data, this.setState, this.setAddServiceData);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    return (
      <RedirectToCorrectOrgUrl correctOrganizationId={this.state.correctOrganizationId} history={this.props.history}>
        <GetDataOnMountAndUpdate itemId={this.props.match.params.addServiceId} getDataOnMountAndUpdate={this.getAddService}>
          <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
            {this.props.children}
          </RenderOnReady>
        </GetDataOnMountAndUpdate>
      </RedirectToCorrectOrgUrl>
    );
  }
}

const mapStateToProps = state => {
  return {
    status: state.organization.item.addServiceEdit.status,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSetAddServiceEdit: (addService) => dispatch({type: addServiceEditActions.ADD_SERVICE_EDIT_SET, value: addService}),
    onSetSheets: (sheets) => dispatch({type: sheetsActions.SHEETS_SET, value: sheets})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GetAddService);