//@flow
import * as React from 'react';
import type {tagsList} from "../../../../../../../services/flowTypes/dataFlowTypes";
import {
  contractSourceConfig,
  contractSourceValue,
  paynetSource
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import NFormGroup from "../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import text from "../../../../../../../services/localization/text";
import FormTagSelect from "../../../../../../../components/simple/FormComponents/FormTagSelect/FormTagSelect";
import type {placeholder} from "../../../../../../../services/flowTypes/appFlowTypes";

type Props = {
  paynetSource: paynetSource,
  paynetParamConfig: contractSourceConfig,
  onGetPaynetParam: (data:contractSourceValue) => void,
  isViewMode?: boolean,
  selectPlaceholder: placeholder,
  selectPaynetParamTestId: string
};

class PaynetParamsItem2 extends React.Component <Props> {
  handlePaynetParamChange = (value: tagsList) => {
    this.props.onGetPaynetParam(
      {
        source: this.props.paynetSource.source,
        config: this.props.paynetParamConfig,
        value
      }
    );
  };

  render() {
    return this.props.isViewMode ?
      (<div className={"flex_jc-sb mb_3du"}>
          <NFormGroup label={text[this.props.paynetParamConfig.name]} addClasses={"w_three-quarter-card"}>
            <FormTagSelect value={this.props.paynetSource[this.props.paynetParamConfig.name]}
                           disabled={true}
                           placeholder={this.props.selectPlaceholder}/>
          </NFormGroup>
        </div>
      )
      :
      (<div className={"mb_3du"}>
        <NFormGroup label={text[this.props.paynetParamConfig.name]} addClasses={"w_100"}>
          <FormTagSelect value={this.props.paynetSource[this.props.paynetParamConfig.name]}
                         onChange={value => this.handlePaynetParamChange(value)}
                         delimiters={[',']}
                         mask={'\\d'}
                         placeholder={this.props.selectPlaceholder}
                         idForTest={this.props.selectPaynetParamTestId}/>
        </NFormGroup>
      </div>);
  }
}

export default PaynetParamsItem2;