//@flow
import * as React from 'react';
import {connect} from "react-redux";
import Pagination from "../../../Pagination/Pagination";
import type {
  paginationCount,
  paginationOffset,
  totalCountSearchData
} from "../../../../../services/flowTypes/dataFlowTypes";
import searchRequestActions from "../../../../../store/organization/searchRequest/searchRequestActions";
import {onGetSearchRequestProp} from "../../../../../services/flowTypes/propFnFlowTypes";
import type {shouldSearchResultsUpdate} from "../../../../../services/flowTypes/componentFlowTypes";

type Props = {
  offset: paginationOffset,
  count: paginationCount,
  totalCount: totalCountSearchData,
  onGetSearchRequestProp: onGetSearchRequestProp,
  shouldSearchResultsUpdate: shouldSearchResultsUpdate
};

class ConnectedPagination extends React.Component <Props> {
  setOffset = (offset) => {
    const searchRequestObj = { offset };
    this.props.onGetSearchRequestProp(searchRequestObj);
  };
  setCount = (count) => {
    const searchRequestObj = { count, offset: 0 };
    this.props.onGetSearchRequestProp(searchRequestObj);
  };
  shouldComponentUpdate(nextProps){
    return nextProps.shouldSearchResultsUpdate;
  }
  render() {
    return (
      <Pagination
        offset={this.props.offset}
        count={this.props.count}
        totalCount={this.props.totalCount}
        setOffset={this.setOffset}
        setCount={this.setCount}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    offset: state.organization.searchRequest.offset,
    count: state.organization.searchRequest.count,
    totalCount: state.organization.searchData.totalCount
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSearchRequestProp: (searchRequestObj) => dispatch({type: searchRequestActions.SEARCH_REQUEST_PROP_SET,
      value: searchRequestObj}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedPagination);    