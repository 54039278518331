//@flow
import * as React from 'react';
import {connect} from "react-redux";
import text from "../../../../../../../services/localization/text";
import ability from "../../../../../../../services/permissions/permissions";
import {contractLinkedContractsPageIds, contractsPageIds} from "../../../../../../../tests/testIds";
import type {
  contractId,
  contractLinkedContracts,
  contractLinkedTypeList, contractName, contractStatus, linkDate, linkDescription, linkTypeId, organizationId
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {editLinkedContract} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import EditLinkedItemModal from "../../../../../../../components/composed/EditLinkedItemModal/EditLinkedItemModal";
import {contrPathView, mainDataSuffix, orgPath} from "../../../../../../../services/urlStrings/urlStrings";
import formatDate from "../../../../../../../utils/formatDate";
import ListItemCard from "../../../../../../../components/composed/ListItemCard/ListItemCard";
import type {modalIsShown} from "../../../../../../../services/flowTypes/appFlowTypes";
import DeleteLinkedContractModal from "./DeleteLinkedContractModal";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";
import EmptyListV2 from "../../../../../../../components/simple/Containers/EmptyList/EmptyListV2";

type Props = {
  contractLinkedContracts: contractLinkedContracts,
  contractId: contractId,
  editLinkedContract: editLinkedContract,
  contractLinkedTypeList: contractLinkedTypeList,
  organizationId: organizationId,
  status: contractStatus
};
type State = {
  modalIsShown: modalIsShown,
  id: contractId,
  linkDate: linkDate,
  typeId: linkTypeId,
  description: linkDescription,
  deleteModalIsShown: modalIsShown,
  contractIdToDelete: contractId,
  contractNameToDelete: contractName
};
const initialState = {
  modalIsShown: false,
  id: null,
  linkDate: '',
  typeId: null,
  description: ''
};

class ConnectedContractLinkedContractsList extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false,
      id: null,
      linkDate: '',
      typeId: null,
      description: '',
      deleteModalIsShown: false,
      contractIdToDelete: null,
      contractNameToDelete: ''
    };
  }
  hideDeleteModal = () => {
    this.setState({
      deleteModalIsShown: false,
      contractIdToDelete: null,
      contractNameToDelete: ''
    });
  };
  showDeleteModal = (contractIdToDelete, contractNameToDelete) => {
    this.setState({
      deleteModalIsShown: true,
      contractIdToDelete, contractNameToDelete
    });
  };
  hideModal = () => {
    const {modalIsShown, id, linkDate, typeId, description} = initialState;
    this.setState({modalIsShown, id, linkDate, typeId, description});
  };
  editLinkedContract = (requestObj) => {
    const updatedContract = {...requestObj, date: requestObj.linkDate};
    delete updatedContract.linkDate;
    this.props.editLinkedContract(updatedContract);
  };
  showEditModal = (linkedContract) => {
    const id = linkedContract.id;
    const linkDate = linkedContract.linkDate ? linkedContract.linkDate : '';
    const typeId = linkedContract.typeId ? linkedContract.typeId : null;
    const description = linkedContract.description ? linkedContract.description : '';
    this.setState({modalIsShown: true, id, linkDate, typeId, description});
  };
  render() {
    const relatedContracts = this.props.contractLinkedContracts === null ? null :
      this.props.contractLinkedContracts && this.props.contractLinkedContracts.length ? this.props.contractLinkedContracts.map((item, i) => {
      const onDelContractClick = ability.can('usePermission', permissionsStrValues.contractLinkedContract.delete) ?
        () => this.showDeleteModal(item.id, item.name) : null;
      const onEditContractClick = ability.can('usePermission', permissionsStrValues.contractLinkedContract.update) ?
        () => this.showEditModal(item) : null;
      const linkTo = orgPath(this.props.organizationId) + contrPathView(item.id) + mainDataSuffix;
      // const createDate = formatDate(item.createDate);
      const createDate = null;
      const startDate = formatDate(item.startDate);
      const endDate = formatDate(item.endDate);
      const validityTerm = startDate + " – " + (endDate ? endDate : text.endDateNotSet);
      const linkTypeObj = this.props.contractLinkedTypeList.find(type => type.id === item.typeId);
      const linkType = linkTypeObj ? linkTypeObj.name : null;
      const cardTiles = [
        {tileIconType: 'finterms', numberLabel: item.finTermsCount},
        {tileIconType: 'templates', numberLabel: item.actTemplatesCount},
        {tileIconType: 'documents', numberLabel: item.documentsCount}
      ];
      return (
          <ListItemCard
            key={'Contract' + item.id}
            addClasses={'ListItemCard_wide mb_1du'}
            itemId={item.id}
            itemName={item.name}
            linkId={contractsPageIds.linkToContract(i)}
            linkTo={linkTo}
            onEditBtnClick={onEditContractClick}
            linkDate={item.linkDate}
            linkType={linkType}
            linkDescription={item.description}
            onDeleteBtnClick={onDelContractClick}
            itemStatus={item.status}
            itemStatusLabel={text.contract.status[item.status]}
            createDate={createDate}
            validityTerm={validityTerm}
            btnEditId={contractLinkedContractsPageIds.buttonEditContract(i)}
            btnDelId={contractLinkedContractsPageIds.buttonDeleteContract(i)}
            cardTiles={cardTiles}
            isLinkedItemsMode={true}
          />
      )
    }) : <EmptyListV2/>;
    return (
        <div className="ConnectedContractAgentsList">
          {relatedContracts}
          {this.state.modalIsShown ? (
            <EditLinkedItemModal
              modalIsShown={this.state.modalIsShown}
              hideModal={this.hideModal}
              itemLinkedTypeList={this.props.contractLinkedTypeList}
              onEditLinkedItemClick={this.editLinkedContract}
              id={this.state.id}
              linkDate={this.state.linkDate}
              typeId={this.state.typeId}
              description={this.state.description}
              itemType={'contract'}
            />
          ) : null}
          <DeleteLinkedContractModal
            contractId={this.props.contractId}
            cancelModal={this.hideDeleteModal}
            modalIsShown={this.state.deleteModalIsShown}
            contractIdToDelete={this.state.contractIdToDelete}
            contractNameToDelete={this.state.contractNameToDelete}
            status={this.props.status}
          />
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contractLinkedContracts: state.organization.item.contractLinkedContracts,
    contractLinkedTypeList: state.organization.organizationData.statuses.contractLinkedTypeList,
    organizationId: state.organization.organizationId
  };
};


export default connect(mapStateToProps, null)(ConnectedContractLinkedContractsList);