const addServiceValidationScheme = {
  name: ['required'],
  status: ['required'],
  date: ['required', 'compliedWithContractDates_withArgs'],
  payerId: ['required'],
  payeeId: ['required'],
  payoutPeriod: ['required'],
  actTemplateId: ['required'], //if isDetachedInvoice === false
  sheetsLength: ['notEmptySheetsArr'],
  sheets: {
    currency: ['required'],
    calculatedItems: {
      addServiceTypeId: ['required'],
      price: ['required'],
      number: ['required']
    },
    calculatedItemsLength: ['notEmptyCalculatedItemsArr']
  }
};
export default addServiceValidationScheme;