//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  contract,
  contractRelated, contractTypes,
  organizationId
} from "../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../services/localization/text";
import {
  contrPathEdit,
  contrPathView, mainDataSuffix,
  orgPath
} from "../../../../services/urlStrings/urlStrings";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import concatIdName from "../../../../utils/concatIdName";
import type {isWideMode, linkId} from "../../../../services/flowTypes/componentFlowTypes";
import formatDate from "../../../../utils/formatDate";
import ConnectedTagLabel from "../../../../components/composed/ConnectedTagLabel/ConnectedTagLabel";
import {contractsPageIds} from "../../../../tests/testIds";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import AlertPaynetSync from "../../../../components/simple/Containers/AlertCard/AlertPaynetSync";
import AlertUnverifiedAddAgreements
  from "../../../../components/simple/Containers/AlertCard/AlertUnverifiedAddAgreements";

const withHandledContractData = (WrappedComponent) => {
  type Props = {
    id?: id,
    addClasses?: addClasses,
    contract: contract,
    contractRelated: contractRelated,
    contractTypes: contractTypes,
    organizationId: organizationId,
    linkId?: linkId,
    isWideMode?: isWideMode
  };
  class HOC extends React.Component <Props> {
    render() {
      const idName = concatIdName(this.props.contract.id, this.props.contract.name);
      const linkToView = orgPath(this.props.organizationId) + contrPathView(this.props.contract.id) + mainDataSuffix;
      const linkToEdit = orgPath(this.props.organizationId) + contrPathEdit(this.props.contract.id) + mainDataSuffix;
      const createDate = formatDate(this.props.contract.createDate);
      const startDate = formatDate(this.props.contract.startDate);
      const endDate = formatDate(this.props.contract.endDate);
      const statusDate = this.props.contract.statusDate ? formatDate(this.props.contract.statusDate) : "-";
      const validityTerm = startDate + " – " + (endDate ? endDate : text.endDateNotSet);
      const contractType = this.props.contractTypes.find(item => item.id === this.props.contract.typeId);
      const contractTypeName = contractType ? contractType.name : '';
      const itemStatusLabel= text.contract.status[this.props.contract.status];
      const tags = this.props.contract.tags.map((tag,index) => {
        return (<ConnectedTagLabel key={'tag-'+index}
                                   id={contractsPageIds.tagContractCard(index, this.props.contract.id)}>
          {tag}
        </ConnectedTagLabel>);
      });
      const agents = this.props.contractRelated.agents.map((agent) => {
        return agent.name;
      });
      const warningPaynetSyncComponent = this.props.contract.isNotCompliedWithPaynetParams ?
        <AlertPaynetSync addClasses={"mb_1du"}
                         agentIdIsNotCompliedWIthPaynetParams={this.props.contract.agentIdIsNotCompliedWIthPaynetParams}
                         organizationId={this.props.organizationId}/>
        : null;
      const warningUnverifiedAddAgreementsComponent = this.props.contract.hasUnverifiedAddAgreements ?
        <AlertUnverifiedAddAgreements addClasses={"mb_1du"}
                         contractId={this.props.contract.id}
                         organizationId={this.props.organizationId}/>
        : null;
      const warningComponent = <>{warningPaynetSyncComponent}{warningUnverifiedAddAgreementsComponent}</>
      const handledContract = {
        idName, linkToView, linkToEdit, createDate, startDate, endDate, validityTerm, contractTypeName, itemStatusLabel,
        tags, agents, statusDate, warningComponent
      };
      return (
        <WrappedComponent handledContract={handledContract} {...this.props}/>
      );
    }
  }

  const mapStateToProps = state => {
    return {
      contractTypes: state.organization.organizationData.contractTypes,
      organizationId: state.organization.organizationId
    };
  };

  return  connect(mapStateToProps, null)(HOC);
};

export default withHandledContractData;