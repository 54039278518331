//@flow
import * as React from 'react';
import EditUserOrganizations from "./EditUserOrganizations";
import {userOrganizationsPageIds} from "../../../../../../../tests/testIds";
import text from "../../../../../../../services/localization/text";
import Can from "../../../../../../../services/permissions/Can";
import type {userId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {needUpdateUser} from "../../../../../../../services/flowTypes/componentFlowTypes";
import UserUpdater from "./UserUpdater/UserUpdater";
import {globalPermissionsStrValues} from "../../../../../../../configData/permissionsStrValues";
import BtnNewItem from "../../../../../../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  userId: userId
};
type State = {
  needUpdateUser: needUpdateUser
};


class AddUserOrganizationsButton extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      needUpdateUser: false
    };
  }
  initUpdateUser = () => {
    this.setState({needUpdateUser: true});
  };
  cancelUpdateUser = () => {
    this.setState({needUpdateUser: false});
  };
  render(): React$Node {
    const btn = <BtnNewItem id={userOrganizationsPageIds.buttonAdd}>{text.btnAdd}</BtnNewItem>
    return (
      <>
        <UserUpdater needUpdateUser={this.state.needUpdateUser} handleUpdateResponse={this.cancelUpdateUser}/>
        <Can do={'useGlobalPermission'} on={globalPermissionsStrValues.user.update}>
          <EditUserOrganizations
            userId={this.props.userId}
            editOrAdd={'add'}
            btn={btn}
            initUpdateUser={this.initUpdateUser}
          />
        </Can>
      </>
    );
  }
}

export default AddUserOrganizationsButton;