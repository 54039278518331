//@flow
import * as React from 'react';
import {cancelModal} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import text from "../../../../../../../../services/localization/text";
import NModal from "../../../../../../../../components/simple/Modals/NModal/NModal";
import withHandledGroupFinTermData from "../GroupFinTermCard/withHandledGroupFinTermData";
import type {handledGroupFinTerm} from "../../../../../../../../services/flowTypes/componentFlowTypes";
import WarningContainer from "../../../../../../../../components/simple/Containers/WarningContainer/WarningContainer";
import Text1 from "../../../../../../../../components/simple/TextComponents/Text1/Text1";

type Props = {
  cancelModal: cancelModal,
  handledGroupFinTerm: handledGroupFinTerm
};

const PreventDeleteGroupFinTermModal = (props: Props) => {
  return (
    <NModal
      modalTitle={text.preventDeleteFinTermModalTitle}
      onModalCancelBtnClick={props.cancelModal}
      isModalDocumentEdit={true}
      isDeleteProhibitedMode={true}
    >
      <WarningContainer title={text.preventDeleteFinTermModal.warningContainerTitle} addClasses={"mb_3du"}>
        {text.preventDeleteFinTermModal.warningContainerText}</WarningContainer>
      <Text1 addClasses={"Text1_card-label-color Text1_lh20"}>{text.preventDeleteFinTermModal.modalText}</Text1>
      {/*<NHeading1 addClasses={'mt_3du ta_c'}>*/}
      {/*  <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>*/}
      {/*    {text.finTerms + " " + props.handledGroupFinTerm.groupFinTermIdName}*/}
      {/*  </TextOverflowHandler>*/}
      {/*</NHeading1>*/}
      {/*<NHeading1 addClasses={'flex jc_c'}>*/}
      {/*  <div className="w_half-card ta_r"><TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>*/}
      {/*    {props.handledGroupFinTerm.payerName}*/}
      {/*  </TextOverflowHandler></div>*/}
      {/*  <ArrowIcon addClasses={'ArrowIcon_in-NHeading1'}/>*/}
      {/*  <div className="w_half-card"><TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>*/}
      {/*    {props.handledGroupFinTerm.payeeName}*/}
      {/*  </TextOverflowHandler></div>*/}
      {/*</NHeading1>*/}
      {/*<NHeading1 addClasses={'ta_c mb_2du'}>{" " + text.preventDeleteFinTermModalText}</NHeading1>*/}
    </NModal>
  );
};

const ConnectedPreventDeleteGroupFinTermModal = withHandledGroupFinTermData(PreventDeleteGroupFinTermModal)
export default ConnectedPreventDeleteGroupFinTermModal;