//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {finTerm} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import {
  onSetFinTermsEdit
} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import RenderOnReady from "../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import finTermsEditActions
  from "../../../../../../../../store/organization/item/finTermsEdit/finTermsEditActions";
import type {isReadyToRender} from "../../../../../../../../services/flowTypes/appFlowTypes";
import FinTermsEdit from "./FinTermsEdit/FinTermsEdit";

type Props = {
  finTerms: Array<finTerm>,
  onSetFinTermsEdit: onSetFinTermsEdit,
  readonly?: boolean
};
type State = {
  isReadyToRender: isReadyToRender
};
class SetFinTermsToEdit extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
    };
  }
  setFinTermsToEdit = () => {
    const finTerms = this.props.finTerms.map(item => {
      const params = item.params.map(paramsRow => {
        return paramsRow.map(param => {return {...param};});
      });
      return {...item, params};
    });
    this.props.onSetFinTermsEdit(finTerms);
  };
  componentDidMount(){
    this.setFinTermsToEdit();
    this.setState({isReadyToRender: true});
  }
  componentDidUpdate(){
    // console.log('how many times set finTerms to edit is called in cdu');
    this.setFinTermsToEdit();
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} >
        <FinTermsEdit readonly={this.props.readonly}/>
      </RenderOnReady>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    finTerms: state.organization.item.groupFinTermsEdit.finTerms
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSetFinTermsEdit: (data) => dispatch({type: finTermsEditActions.FIN_TERMS_EDIT_SET, value: data})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SetFinTermsToEdit);