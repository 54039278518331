//@flow
import * as React from 'react';
import connect from "react-redux/es/connect/connect";
import ability from "../../../../../../../services/permissions/permissions";
import api from "../../../../../../../services/apiInstance/api";
import type {
  contractId, finTerm,
  groupFinTerm,
  organizationId, sourceParams
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {
  errorObject,
  history,
  isReadyToRender,
  stateToCompare
} from "../../../../../../../services/flowTypes/appFlowTypes";
import ConnectedItemControls
  from "../../../../../../../components/composed/ConnectedItemControls/ConnectedItemControls";
import validationActions from "../../../../../../../store/validation/validationActions";
import {
  onSetGroupFinTermsView,
  onSetDataToValidationState
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import groupFinTermsViewActions
  from "../../../../../../../store/organization/item/groupFinTermsView/groupFinTermsViewActions";
import {
  contrPathView,
  groupFinTermPath,
  orgPath
} from "../../../../../../../services/urlStrings/urlStrings";
import needFetchFlagsActions from "../../../../../../../store/needFetchFlags/needFetchFlagsActions";
import type {onUpdateNeedFetchFlagsProp} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";

type Props = {
  organizationId: organizationId,
  contractId: contractId,
  history: history,
  groupFinTerm: groupFinTerm,
  onSetGroupFinTermsView: onSetGroupFinTermsView,
  onSetDataToValidationState: onSetDataToValidationState,
  sourceParams: sourceParams,
  finTerms: Array<finTerm>,
  onUpdateNeedFetchFlagsProp: onUpdateNeedFetchFlagsProp
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  stateToCompare: stateToCompare
};

class GroupFinTermsControls extends React.Component <Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true,
      stateToCompare: {}
    };
  }
  getGroupFinTermId = () => {
    return this.props.match.params.groupId ? +this.props.match.params.groupId : null;
  };
  goToGroupFinTermView = (groupId) => {
    const path = orgPath(this.props.organizationId) + contrPathView(this.props.contractId) + groupFinTermPath(groupId);
    this.props.history.push({pathname: path});
  };
  goToFinTerm = () => {
    const path =  orgPath(this.props.organizationId) + contrPathView(this.props.groupFinTerm.contractId)
      + groupFinTermPath(this.props.groupFinTerm.groupId);
    this.props.history.push({pathname: path});
  };
  updateParamsInGroupFinTerms = () => {
    return {...this.props.groupFinTerm, sourceParams: this.props.sourceParams, finTerms: this.props.finTerms};
  };
  setGroupFinTerms = (groupFinTerms) => {
    this.props.onSetGroupFinTermsView(groupFinTerms);
    const groupFinTerm = groupFinTerms.find(item => item.groupId === this.getGroupFinTermId());
    this.props.onSetDataToValidationState(groupFinTerm);
  };
  getGroupFinTerms = (goBack) => {
    api.getGroupFinTerms({contractId: this.props.contractId})
      .then(response => {
        this.setState({isReadyToRender: true});
        this.setGroupFinTerms(response.data);
        if (goBack) {
          this.props.history.goBack();
        } else {
          this.goToGroupFinTermView();
        }
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      })
  };
  handleDataBeforeSend = () => {
    const groupFinTerm = this.updateParamsInGroupFinTerms();
    // delete groupFinTerm.gates;
    if (groupFinTerm.hasOwnProperty('hasReportTemplates')) {
      delete groupFinTerm.hasReportTemplates;
    }
    //TODO: clean this out after fix on backend
    if (this.getGroupFinTermId() !== null) {
      const newFinTerms = groupFinTerm.finTerms.map(item => {
        if (item.status === 'DELETED') {
          return {...item};
        } else {
          return {...item, status: 'ACTIVE'};
        }
      });
      groupFinTerm.finTerms = newFinTerms;
    }
    return groupFinTerm;
  };
  requestUpdate = (groupFinTerm) => {
    api.updateGroupFinTerm(groupFinTerm)
      .then(response => {
        this.getGroupFinTerms(true);
        this.props.onUpdateNeedFetchFlagsProp('groupFinTermsList', true);
      })
      .catch(error => {
        console.log(error);
        console.log(error.response);
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  requestCreate = (groupFinTerm) => {
    api.createGroupFinTerm(groupFinTerm)
      .then(response => {
        this.setState({isReadyToRender: true});
        this.goToGroupFinTermView(response.data.id);
        this.props.onUpdateNeedFetchFlagsProp('groupFinTermsList', true);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  sendRequest = () => {
    this.setState({isReadyToRender: false});
    const groupFinTerm = this.handleDataBeforeSend();
    if (this.getGroupFinTermId() === null) {
      this.requestCreate(groupFinTerm);
    } else {
      this.requestUpdate(groupFinTerm);
    }
  };
  cancel = () => {
    // this.setState({isReadyToRender: false});
    // const goBack = true;
    // this.getGroupFinTerms(goBack);
    if(this.getGroupFinTermId() !== null){
      this.goToFinTerm();
    } else {
      this.props.history.goBack();
    }
  };
  componentDidMount(){
    //for update groupFinTerm
    if (this.getGroupFinTermId() === this.props.groupFinTerm.groupId) {
      this.setState({stateToCompare: JSON.stringify(this.props.groupFinTerm)});
    }
    //for new groupFinTerm
    if (this.getGroupFinTermId() === null) {
      this.setState({stateToCompare: JSON.stringify(this.props.groupFinTerm)});
    }
  }
  componentDidUpdate(prevProps, prevState){
    if ( (this.getGroupFinTermId() === this.props.groupFinTerm.groupId &&
      prevProps.groupFinTerm.groupId !== this.props.groupFinTerm.groupId) ) {
      this.setState({stateToCompare: JSON.stringify(this.props.groupFinTerm)});
    }
  }
  render(){
    const groupFinTerm = this.updateParamsInGroupFinTerms();
    const permittedAction = this.getGroupFinTermId() === null ?
      permissionsStrValues.finTerm.create : permissionsStrValues.finTerm.update;
    const actionIsPermitted = ability.can('usePermission', permittedAction);
    const submitIsDisabled = this.state.stateToCompare === JSON.stringify(groupFinTerm);
    return(
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <ConnectedItemControls
          actionIsPermitted={actionIsPermitted}
          submitIsDisabled={submitIsDisabled}
          cancelFn={this.cancel}
          sendRequest={this.sendRequest}
        />
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    groupFinTerm: state.organization.item.groupFinTermsEdit,
    sourceParams: state.organization.item.sourceParamsEdit,
    finTerms: state.organization.item.finTermsEdit
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSetGroupFinTermsView: (groupFinTerms) => dispatch({
      type: groupFinTermsViewActions.GROUP_FIN_TERMS_VIEW_SET, value: groupFinTerms}),
    onSetDataToValidationState: (data) => dispatch({
      type: validationActions.VALIDATION_STATE_SET,
      value: data}),
    onUpdateNeedFetchFlagsProp: (prop, needFetch) => dispatch({type: needFetchFlagsActions.NEED_FETCH_FLAGS_PROP_UPDATE,
      value: {
        prop: prop,
        data: needFetch
      }})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupFinTermsControls);