//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {children, id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './EmptyBox.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children
};

const EmptyBox = (props: Props) => {
  return (
    <div id={props.id} className={baseClass("EmptyBox", props.addClasses)}>
      <div className="EmptyBox__text">
        {props.children}
      </div>
    </div>
  );
};

export default EmptyBox;