//@flow
import * as React from 'react';
import HistoryCard from "../../simple/Containers/HistoryCard/HistoryCard";
import type {
  historyActionDescription,
  historyActionId,
  historyItem, logIdFromUrl,
  organizationId
} from "../../../services/flowTypes/dataFlowTypes";
import formatDate from "../../../utils/formatDate";
import formatTime from "../../../utils/formatTime";
import text from "../../../services/localization/text";
import {
  actTemplatesSuffix, addAgreementsSuffix, addServicesSuffix,
  agentPathView,
  contrPathView, documentsSuffix, groupFinTermsSuffix,
  mainDataSuffix, orgPath, reportPathView,
  supOrgPathView,
  supUserPathView
} from "../../../services/urlStrings/urlStrings";
import {connect} from "react-redux";
import RenderOnReady from "../../service/RenderOnReady/RenderOnReady";
import type {errorObject} from "../../../services/flowTypes/appFlowTypes";
import apiErrorHandler from "../../../services/apiInstance/apiErrorHandler";
import api from "../../../services/apiInstance/api";
import type {onAddHistoryLog} from "../../../services/flowTypes/propFnFlowTypes";
import historyLogsActions from "../../../store/organization/historyLogs/historyLogsActions";
import concatIdName from "../../../utils/concatIdName";
import type {historyCardIndex} from "../../../services/flowTypes/componentFlowTypes";

type Props = {
  historyItem: historyItem,
  organizationId: organizationId,
  historyLogs: Array<historyActionDescription>,
  onAddHistoryLog: onAddHistoryLog,
  historyCardIndex: historyCardIndex,
  logIdFromUrl?: logIdFromUrl
};
type State = {
  requestedActionIdList: Array<historyActionId>,
  errorObject: errorObject
};

const emptyLogActionArr = ['DELETE_ACT_TEMPLATE', 'CREATE', 'CREATE_FINTERMS', 'UPLOAD_ACT_TEMPLATE',
  'DELETE_FINTERMS', 'AUTO_ADJUST_REPORT', 'UPDATE_REPORT_STATUS', 'DELETE_ADD_AGREEMENT', 'CREATE_ADD_AGREEMENT',
  'CREATE_ADD_SERVICE'];
export const defineLinkLabel = (objectId, objectName, objectType, actionType, newObjectValue) => {
  const parsedNewObjectValue = JSON.parse(newObjectValue);
  switch (actionType) {
    case 'CREATE':
    case 'UPDATE':
    case 'UPDATE_REPORT_STATUS':
    case 'AUTO_ADJUST_REPORT':
      const name = objectType === 'REPORT' ? text.reportLabel : objectName;
      return concatIdName(objectId, name);
    case 'CREATE_ADD_AGREEMENT':
    case 'UPDATE_ADD_AGREEMENT':
    case 'DELETE_ADD_AGREEMENT':
      return concatIdName(objectId, objectName) + "/" + text.addAgreements + " (" + parsedNewObjectValue.additional_agreement.name + ")";
    case 'UPLOAD_FILE':
    case 'DELETE_FILE':
    case 'UNDELETE_FILE':
    case 'UPDATE_FILE':
      return concatIdName(objectId, objectName) + "/" + text.documents;
    case 'DELETE_FINTERMS':
      return concatIdName(objectId, objectName) + "/" + text.finTerms;
    case 'CREATE_FINTERMS':
    case 'UPDATE_FINTERMS':
      return concatIdName(parsedNewObjectValue.group.id, parsedNewObjectValue.group.name);
    case 'DELETE_ACT_TEMPLATE':
      return concatIdName(objectId, objectName) + "/" + text.actTemplates;
    case 'UPLOAD_ACT_TEMPLATE':
    case 'UPDATE_ACT_TEMPLATE':
      return concatIdName(parsedNewObjectValue.actTemplate.id, parsedNewObjectValue.actTemplate.name);
    case 'CREATE_ADD_SERVICE':
    case 'UPDATE_ADD_SERVICE':
      return parsedNewObjectValue.addService.name;
    case 'DELETE_ADD_SERVICE':
      return concatIdName(objectId, objectName) + "/" + text.addServices;
    default:
      return 'undefinded link label';
  }
};
export const defineLink = (organizationId, objectType, objectId, actionType, newObjectValue) => {
  let objectPartLink = '';
  switch (objectType) {
    case 'CONTRACT':
      objectPartLink = contrPathView(objectId);
      break;
    case 'AGENT':
      objectPartLink = agentPathView(objectId);
      break;
    case 'REPORT':
      objectPartLink = reportPathView(objectId);
      break;
    case 'USER':
      objectPartLink = supUserPathView(objectId);
      break;
    case 'ORGANIZATION':
      objectPartLink = supOrgPathView(objectId);
      break;
    default:
      objectPartLink = '';
  }
  let subObjectPartLink = '';
  switch (actionType) {
    case 'CREATE':
    case 'UPDATE':
    case 'UPDATE_REPORT_STATUS':
    case 'AUTO_ADJUST_REPORT':
      subObjectPartLink = mainDataSuffix;
      break;
    case 'CREATE_ADD_AGREEMENT':
    case 'UPDATE_ADD_AGREEMENT':
    case 'DELETE_ADD_AGREEMENT':
      subObjectPartLink = addAgreementsSuffix;
      break;
    case 'UPLOAD_FILE':
    case 'DELETE_FILE':
    case 'UNDELETE_FILE':
    case 'UPDATE_FILE':
      subObjectPartLink = documentsSuffix;
      break;
    case 'DELETE_FINTERMS':
      subObjectPartLink = groupFinTermsSuffix;
      break;
    case 'CREATE_FINTERMS':
    case 'UPDATE_FINTERMS':
      subObjectPartLink = groupFinTermsSuffix + '/' + JSON.parse(newObjectValue).group.id;
      break;
    case 'DELETE_ACT_TEMPLATE':
      subObjectPartLink = actTemplatesSuffix;
      break;
    case 'CREATE_ADD_SERVICE':
    case 'UPDATE_ADD_SERVICE':
      subObjectPartLink = addServicesSuffix + '/' + JSON.parse(newObjectValue).addService.id;
      break;
    case 'DELETE_ADD_SERVICE':
      subObjectPartLink = addServicesSuffix;
      break;
    case 'UPLOAD_ACT_TEMPLATE':
    case 'UPDATE_ACT_TEMPLATE':
      subObjectPartLink = actTemplatesSuffix + '/' + JSON.parse(newObjectValue).actTemplate.id;
      break;
    default:
      subObjectPartLink = '';
      break;
  }
  return objectType === 'USER'|| objectType === 'ORGANIZATION' ?
    (['UPLOAD_FILE', 'DELETE_FILE','UPDATE_FILE'].includes(actionType) ?
      orgPath(organizationId) + subObjectPartLink:
    objectPartLink + mainDataSuffix)
    : orgPath(organizationId) + objectPartLink + subObjectPartLink;
};

class ConnectedHistoryCard extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      requestedActionIdList: [],
      errorObject: null
    };
  }
  defineGroupFinTermType = (newObjectValue) => {
    const newObjectValueJSON = JSON.parse(newObjectValue);
    if (newObjectValueJSON.group) {
      return newObjectValueJSON.group.type;
    } else {
      return '';
    }
  };
  formatActionDescription = (actionType, objectType) => {
    if (actionType === 'CREATE' || actionType === 'UPDATE') {
      return text.historyObjectAction[objectType][actionType];
    }
    return text.historyActionTypes[actionType];
  };
  handleResponse = (actionId) => {
    const updatedRequestedActionIdList = this.state.requestedActionIdList.filter(item => item !== actionId);
    this.setState({requestedActionIdList: updatedRequestedActionIdList});
  };
  getHistoryLog = (actionId, actionParams, organizationId) => {
    const requestedActionIdList = [...this.state.requestedActionIdList];
    requestedActionIdList.push(actionId);
    this.setState({requestedActionIdList});
    api.getHistoryActionDescription(actionId, actionParams, organizationId)
      .then((response) => {
        this.handleResponse(response.data.actionId);
        this.props.onAddHistoryLog(response.data);
      })
      .catch((error) => {
        this.setState({
          errorObject: apiErrorHandler(error),
        });
        this.handleResponse(actionId);
      });
  };
  getHistoryLogForLogIdFromUrl = () => {
    if (this.props.logIdFromUrl) {
      for (let i=0; i < this.props.historyItem.userActions.length; i++) {
        for (let j=0; j < this.props.historyItem.userActions[i].actions.length; j++) {
          const action = this.props.historyItem.userActions[i].actions[j];
          if (!emptyLogActionArr.includes(action.actionType)) {
            this.getHistoryLog(action.id,
              {actionType: action.actionType, objectType: action.objectType, objectId: action.objectId},
                this.props.historyItem.userActions[i].organizationId
                );
          }
        }
      }
    }
  };
  componentDidUpdate(prevProps: Props) {
    if (this.props.logIdFromUrl && this.props.logIdFromUrl !== prevProps.logIdFromUrl) {
      this.getHistoryLogForLogIdFromUrl();
    }
  }
  componentDidMount() {
    this.getHistoryLogForLogIdFromUrl();
  }
  render() {
    const formattedHistoryItemUserActions = this.props.historyItem.userActions.map((userActions, i) => {
      const formattedActions = userActions.actions.map((action, index) => {
        return {
          id: action.id,
          formattedTime: formatTime(action.date),
          formattedActionDescription: this.formatActionDescription(action.actionType, action.objectType),
          formattedLink: defineLink(this.props.organizationId, action.objectType, action.objectId, action.actionType, action.newObjectValue),
          formattedLinkLabel: defineLinkLabel(action.objectId, action.objectName,  action.objectType, action.actionType, action.newObjectValue),
          groupFinTermType: this.defineGroupFinTermType(action.newObjectValue),
          isUnviewed: action.isUnviewed,
          objectType: action.objectType,

          getDescriptionFn: emptyLogActionArr.includes(action.actionType)
          // || action.objectType === 'USER'
          || (action.objectType === 'USER' && action.actionType !== 'UPDATE')
              ?
            null : () => this.getHistoryLog(action.id,
            {actionType: action.actionType, objectType: action.objectType, objectId: action.objectId})
        };
      });
      return {
        userName: userActions.userName,
        formattedActions: formattedActions
      };
    });
    const formattedHistoryItem = {
      date: formatDate(this.props.historyItem.date),
      formattedHistoryItemUserActions: formattedHistoryItemUserActions
    };
    return (
      <>
        <RenderOnReady isReadyToRender={true} errorObject={this.state.errorObject}/>
        <HistoryCard formattedHistoryItem={formattedHistoryItem} addClasses={"mb_1du"}
                     optionsAreShownOnMount={this.props.logIdFromUrl}
                     historyLogs={this.props.historyLogs} historyCardIndex={this.props.historyCardIndex}
                     requestedActionIdList={this.state.requestedActionIdList}/>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    historyLogs: state.organization.historyLogs
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onAddHistoryLog: (historyLog) => dispatch({type: historyLogsActions.HISTORY_LOGS_ADD, value: historyLog})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedHistoryCard);
