//@flow
import * as React from 'react';
import AddServicesCard from "../../../../../Contracts/pages/Contract/pages/AddServices/addServices/AddServicesCard";
import text from "../../../../../../services/localization/text";
import type {
  addService,
  contractId,
  organizationId,
  usersList
} from "../../../../../../services/flowTypes/dataFlowTypes";
import SectionHeader from "../../../../../../components/composed/SectionHeader/SectionHeader";

type Props = {
  addServicesList: Array<addService>,
  contractId: contractId,
  organizationId: organizationId,
  usersList: usersList
};

const ReportAddServices = (props: Props) => {
  const addServices = props.addServicesList.map((addService, i) => {
    const addClasses = props.addServicesList.length === i+1 ? 'mb_2du' : 'mb_1du';
    return (
      <AddServicesCard key={'addService'+i} addService={addService} contractId={props.contractId}
                       organizationId={props.organizationId} addServiceIndex={i} usersList={props.usersList}
                       isActTemplateCardMode={true} addClasses={addClasses}
      />
    );
  });
  return (
    <>
      <SectionHeader sectionHeaderTitle={text.addServices} addClasses={"mb_3du"}/>
      {addServices}
    </>
  );
};

export default ReportAddServices;    