//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  creationIsProhibited,
  formFieldType, isSearchable, openDirection,
  propName,
  propValue,
  selectOptions
} from "../../../../../../../services/flowTypes/componentFlowTypes";
import {
  onGetUserMainDataProp,
  onGetValidationStateFormProp
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import type {id, label, placeholder} from "../../../../../../../services/flowTypes/appFlowTypes";
import ConnectedFormGroup from "../../../../../../../components/composed/ConnectedFormGroup/ConnectedFormGroup";
import userMainDataValidationScheme from "../../../../../../../configData/userMainDataValidationScheme";
import type {addClasses} from "../../../../../../../services/flowTypes/uiKitFlowTypes";
import validationActions from "../../../../../../../store/validation/validationActions";
import userMainDataActions from "../../../../../../../store/user/userMainData/userMainDataActions";

type Props = {
  formFieldType: formFieldType,
  propName: propName,
  propValue: propValue,
  onGetUserMainDataProp: onGetUserMainDataProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  label: label,
  options?: selectOptions,
  addClasses?: addClasses,
  idForTest?: id,
  openDirection?: openDirection,
  isSearchable?: isSearchable,
  creationIsProhibited?: creationIsProhibited,
  placeholder?: placeholder
};

class UserMainDataFormGroup extends React.Component <Props, void> {
  onGetPropValue = (prop, data) => {
    this.props.onGetUserMainDataProp(prop, data);
  };
  render() {
    const propValidationPatternsArr = userMainDataValidationScheme[this.props.propName] ?
      userMainDataValidationScheme[this.props.propName] : null;
    return (
      <ConnectedFormGroup
        formFieldType={this.props.formFieldType}
        propName={this.props.propName}
        propValue={this.props.propValue}
        onGetPropValue={this.onGetPropValue}
        label={this.props.label}
        options={this.props.options}
        propValidationPatternsArr={propValidationPatternsArr}
        addClasses={this.props.addClasses}
        idForTest={this.props.idForTest}
        openDirection={this.props.openDirection}
        isSearchable={this.props.isSearchable}
        creationIsProhibited={this.props.creationIsProhibited}
        placeholder={this.props.placeholder}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propValue: state.user.mainData[ownProps.propName]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetUserMainDataProp: (prop, data) => dispatch({type: userMainDataActions.USER_MAIN_DATA_PROP_SET, value: {
        prop: prop,
        data: data
      }})

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMainDataFormGroup);