import {
  historySuffix,
  mainDataSuffix,
  supUserPathView,
  userOrganizationsSuffix
} from "../services/urlStrings/urlStrings";
import text from "../services/localization/text";
import {globalPermissionsStrValues} from "./permissionsStrValues";

const userMenuItems = (userId) => {
  return [
    {
      linkTo: supUserPathView(userId) + mainDataSuffix,
      linkName: text.mainData,
      permittedAction: globalPermissionsStrValues.user.view
    },
    {
      linkTo: supUserPathView(userId) + userOrganizationsSuffix,
      linkName: text.organizations,
      permittedAction: globalPermissionsStrValues.user.view
    },
    {
      linkTo: supUserPathView(userId) + historySuffix,
      linkName: text.userHistory,
      permittedAction: globalPermissionsStrValues.user.view
    },

  ];
};
export default userMenuItems;