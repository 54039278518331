//@flow
import * as React from 'react';
import type {
  addService,
  contractId,
  organizationId,
  usersList
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../../services/localization/text";
import formatDate from "../../../../../../../utils/formatDate";
import './AddServicesCard.css';
import StatusLabel from "../../../../../../../components/simple/TextComponents/StatusLabel/StatusLabel";
import type {addClasses} from "../../../../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../../../UiKit/newUiKit/utilities/baseClass";
import Heading4 from "../../../../../../../components/simple/TextComponents/Heading4/Heading4";
import Text3 from "../../../../../../../components/simple/TextComponents/Text3/Text3";
import {getItemNameByIdFromList} from "../../../../../../../utils/getItemByIdFromList";
import {addServicesPageIds} from "../../../../../../../tests/testIds";
import {Link} from "react-router-dom";
import {
  addServicePath,
  contrPathEdit,
  contrPathView, mainDataSuffix,
  orgPath, reportPathView,
  typeSuffix
} from "../../../../../../../services/urlStrings/urlStrings";
import ButtonDeleteAddService from "./ButtonDeleteAddService";
import fileIco from "../../../../../../../assets/file-ico-grey.svg";
import Text1 from "../../../../../../../components/simple/TextComponents/Text1/Text1";
import declOfNum from "../../../../../../../utils/declOfNum";
import BtnEditAct from "../../../../../../../components/simple/Buttons/BtnEditAct/BtnEditAct";
import getMaskedInputValue from "../../../../../../../utils/getMaskedInputValue";
import type {isSheetsAreShown} from "../../../../../../../services/flowTypes/componentFlowTypes";
import SheetView from "../../../../../../../components/composed/Sheet/SheetView";
import ButtonEditAddService from "./ButtonEditAddService";
import SimplifiedAlertWarning from "../../../../../../../components/simple/Containers/AlertCard/SimplifiedAlertWarning";

type Props = {
  addService: addService,
  contractId: contractId,
  organizationId: organizationId,
  addServiceIndex: number,
  addClasses?: addClasses,
  usersList: usersList,
  isActTemplateCardMode?: boolean,
  checkboxComponent?: React.Node
};
type State = {
  isSheetsAreShown: isSheetsAreShown
};

class AddServicesCard extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSheetsAreShown: false
    };
  }
  toggleSheets = () => {
    this.setState({isSheetsAreShown: !this.state.isSheetsAreShown});
  };
  render(): React$Node {
    const itemStatusLabel= text.addService.status[this.props.addService.status];
    const userName = getItemNameByIdFromList(this.props.addService.userId, this.props.usersList, '!User not found');
    const linkToTypeEdit = orgPath(this.props.organizationId) + contrPathEdit(this.props.contractId)
      + addServicePath(this.props.addService.id) + typeSuffix;
    const linkToView = orgPath(this.props.organizationId) + contrPathView(this.props.contractId)
      + addServicePath(this.props.addService.id);
    const files = this.props.addService.files.map((file, i) => {
      return (<div className="AddServicesCard__file mb_half-du" key={'file'+i}>
        <img src={fileIco} alt={"file-icon"} className="AddServicesCard__file-ico"/>
        <Text1 addClasses={'Text1_card-label-color ml_1du'}>{file.name}</Text1>
      </div>)
    });
    let totalCalculatedItemsNumber = 0;
    for (let i=0; i < this.props.addService.sheets.length; i++) {
      totalCalculatedItemsNumber = totalCalculatedItemsNumber + this.props.addService.sheets[i].calculatedItems.length;
    }
    const serviceStrArr = [text.declension.addService.single, text.declension.addService.few, text.declension.addService.many];
    const totalAmounts = this.props.addService.sheets.map((sheet, i) => {
      return (
        <div key={'totalAmount'+i} className="AddServicesCard__totalAmount mb_half-du">
          <Heading4 addClasses={'Heading4_lh16'} id={addServicesPageIds.addServiceCardTotalAmount(this.props.addService.id, i)}>
            {getMaskedInputValue(sheet.totalAmount)}</Heading4>
          <Text3 addClasses={'ml_1du'}>{sheet.currency}</Text3>
        </div>
      );
    });
    const sheets = this.props.addService.sheets.map((item, i) => {
      return (<SheetView key={'sheet'+i} sheetIndex={i} sheet={item}/>)
    });
    const showAllBtnLabel = this.state.isSheetsAreShown ? text.hideAllBtnLabel : text.showAllBtnLabel;
    const linkToReports = this.props.addService.reportIds.map((item, i) => {
      const linkTo = orgPath(this.props.organizationId) + reportPathView(item) + mainDataSuffix;
      const link = <Link to={linkTo}>{"#"+item}</Link>
      if (i === this.props.addService.reportIds.length-1) {
        return link;
      } else {
        return <>{link}{", "}</>
      }
    });
    return (
      <div className={baseClass('NoShadowCard AddServicesCard', this.props.addClasses)}
           data-ui-test-id={"addServiceCard_"+this.props.addService.id}>
        <div className="AddServicesCard__top">
          <div className="AddServicesCard__top-left">
            <div className="flex_jc-sb mb_2du">
              <div className={"flex"}>
                {this.props.checkboxComponent ? (<div>{this.props.checkboxComponent}</div>) : null}
                <div>
                  <StatusLabel addClasses={`StatusLabel_${this.props.addService.status}`}>
                    {itemStatusLabel}</StatusLabel>
                  <Text3>{formatDate(this.props.addService.date)}</Text3>
                </div>
              </div>
              <div>
                <Text3>{text[this.props.addService.payoutPeriod]}</Text3>
                <Text3 addClasses={'Text3_SecTextColor ta_r'}>{userName}</Text3>
              </div>
            </div>
            {this.props.isActTemplateCardMode && this.props.checkboxComponent && this.props.addService.reportIds.length ? (
              <SimplifiedAlertWarning addClasses={"mt_1du mb_1du"}>
                {text.addServiceHasReportsWarning}
                {linkToReports}
              </SimplifiedAlertWarning>
            ) : null}
            <Link to={linkToView} id={"addServiceCard_id_" + this.props.addService.id}>
              <Heading4 addClasses={'Heading4_link-on-hover Heading4_fwb Heading4_lh20'}>
                {this.props.addService.name}</Heading4>
            </Link>
          </div>
          {this.props.isActTemplateCardMode ? null : (
            <div className="AddServicesCard__top-right">
              <ButtonEditAddService hasPreparedAddServiceReport={this.props.addService.hasPreparedAddServiceReport}
                                    linkToTypeEdit={linkToTypeEdit} addServiceIndex={this.props.addServiceIndex}
                                    addServiceName={this.props.addService.name}/>
              <ButtonDeleteAddService addService={this.props.addService} addServiceIndex={this.props.addServiceIndex}/>
            </div>
          )}
        </div>
        <div className="AddServicesCard__bottom">
          <div className="flex_jc-sb">
            <div>
              {files}
              <div className="flex">
                <Text1 addClasses={'Text1_card-label-color mr_2du'}>
                  {totalCalculatedItemsNumber + " " + declOfNum(totalCalculatedItemsNumber, serviceStrArr)}</Text1>
                <BtnEditAct addClasses={'BtnEditAct_dashed'} onClick={this.toggleSheets}>{showAllBtnLabel}</BtnEditAct>
              </div>
            </div>
            <div className="">
              {totalAmounts}
            </div>
          </div>
          {this.state.isSheetsAreShown ? (
            <div className="AddServicesCard__sheets-container">
              {sheets}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
export default AddServicesCard;