//@flow
import * as React from 'react';
import type {history, locationType} from "../../services/flowTypes/appFlowTypes";

const withURLSearchParamsHandler = (WrappedComponent) => {
  type Props = {
    location: locationType,
    history: history
  };

  class HOC extends React.Component <Props> {
    getSearchFromFilters = (filtersFromParams) => {
      let search = "?";
      for (let prop in filtersFromParams) {
        search = search === "?" ? search : search + "&";
        search = search + prop + "=" + filtersFromParams[prop];
      }
      return search;
    };
    setFiltersToUrl = (filtersFromParams) => {
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: this.getSearchFromFilters(filtersFromParams)
      });
    };
    render(){
      const urlParams = new URLSearchParams(this.props.location.search);
      const filtersFromParams = {};
      for (const [key, value] of urlParams) {
        filtersFromParams[key] = value;
      }
      return (
        <WrappedComponent filtersFromParams={filtersFromParams} setFiltersToUrl={this.setFiltersToUrl} {...this.props}/>
      );
    }
  }
  return HOC;
};

export default withURLSearchParamsHandler;