//@flow
import * as React from 'react';
import TextOverflowHandler
  from "../../../../../../components/simple/Containers/TextOverflowHandler/TextOverflowHandler";
import concatIdName from "../../../../../../utils/concatIdName";
import SecText from "../../../../../../components/simple/TextComponents/SecText/SecText";
import text from "../../../../../../services/localization/text";
import ability from "../../../../../../services/permissions/permissions";
import {Link} from "react-router-dom";
import type {agentId, agentsList, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import type {
  isLabelHidden,
  parentComponentType,
  payerOrPayee
} from "../../../../../../services/flowTypes/componentFlowTypes";
import NLabel from "../../../../../../components/simple/FormComponents/NLabel/NLabel";
import {baseClass} from "../../../../../UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../../../../services/flowTypes/uiKitFlowTypes";
import {agentPathView, mainDataSuffix, orgPath} from "../../../../../../services/urlStrings/urlStrings";
import connect from "react-redux/es/connect/connect";
import Heading4 from "../../../../../../components/simple/TextComponents/Heading4/Heading4";
import {permissionsStrValues} from "../../../../../../configData/permissionsStrValues";

type Props = {
  organizationId: organizationId,
  agentId: agentId,
  parentComponentType: parentComponentType,
  addClasses?: addClasses,
  isLabelHidden?: isLabelHidden,
  agentsList: agentsList,
  payerOrPayee: payerOrPayee
};

class ReportAgentLink extends React.Component <Props> {
  render() {
    const agentObj = this.props.agentsList.find(item => item.id === this.props.agentId);
    const agentName = agentObj ? agentObj.name : 'Name not found';
    const linkToAgent = orgPath(this.props.organizationId) + agentPathView(this.props.agentId) + mainDataSuffix;
    const AgentTitle = (
      <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>
        {concatIdName(this.props.agentId, agentName)}
      </TextOverflowHandler>
    );
    const Label = () => {
      switch (this.props.parentComponentType) {
        case 'card':
          return (<SecText addClasses={'SecText_lh16 mb_1du'}>{text[this.props.payerOrPayee]}</SecText>);
        case 'page':
          return (<NLabel addClasses={'mb_half-du'}>{text[this.props.payerOrPayee]}</NLabel>);
        default:
          console.error('this.props.parentComponentType does not correspond switch cases!');
      }
    };
    return (
      <div className={baseClass("ReportAgentLink", this.props.addClasses)} >
        {this.props.isLabelHidden ? null : Label()}
        {ability.can('usePermission', permissionsStrValues.agent.view) ? (
          <Link to={linkToAgent} >
            <Heading4 addClasses={'Heading4_card-label-color'}>{AgentTitle}</Heading4>
          </Link>
        ) : (
          <Heading4 addClasses={'Heading4_card-label-color'}>{AgentTitle}</Heading4>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    agentsList: state.organization.organizationData.agentsList
  };
};
export default connect(mapStateToProps, null)(ReportAgentLink);