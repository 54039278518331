//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './ColumnMenu.css';
import type {isGlobalPermission, menuItemsData} from "../../../../services/flowTypes/componentFlowTypes";
import {NavLink} from "react-router-dom";
import Can from "../../../../services/permissions/Can";
import {canUseOnlyForRoles} from "../../../service/Routes/canUseAnyone";

type Props = {
  id?: id,
  addClasses?: addClasses,
  menuItemsData: menuItemsData,
  isGlobalPermission?: isGlobalPermission,
};
type State = {
  isOverflow: boolean,
  visibleItemsData: menuItemsData,
  overflowItemsData: menuItemsData,
  opacity: string
};


const menuItemKeyName = "ColumnMenu__link";
const ColumnMenu = ({menuItemsData, addClasses, isGlobalPermission, id}: Props) => {
  const permissionStr = isGlobalPermission ? 'useGlobalPermission' : 'usePermission';
  return <div id={id} className={baseClass("ColumnMenu ColumnMenu__options", addClasses)}>
    {
      menuItemsData.map((item, i) => {
        const itemTo = item.linkTo ? item.linkTo.replace(/^.+\//, "") : "";
        const rowMenuItem = (key) => (
          <NavLink activeClassName={"ColumnMenuItem_active"}
                   className={"ColumnMenuItem"}
                   key={key}
                   exact={item.exact}
                   data-ui-test-id={"columnMenuLink_" + itemTo}
                   to={item.linkTo}>
            {item.linkName}
          </NavLink>
        );
        return (item.isPermitted ? rowMenuItem(menuItemKeyName + i)
            : (
              item.onlyForRoles ? (
                  canUseOnlyForRoles(item.onlyForRoles) ? (
                    <Can do={permissionStr} key={menuItemKeyName + i} on={item.permittedAction}>
                      {rowMenuItem()}
                    </Can>
                  ) : null
                )
                : (
                  <Can do={permissionStr} key={menuItemKeyName + i} on={item.permittedAction}>
                    {rowMenuItem()}
                  </Can>
                )
            )
        );
      })
    }
  </div>
}

export default ColumnMenu;