//@flow
import * as React from 'react';
import text from "../../../../services/localization/text";
import connect from "react-redux/es/connect/connect";
import {onInitOrganizationMainData} from "../../../../services/flowTypes/propFnFlowTypes";
import type { history} from "../../../../services/flowTypes/appFlowTypes";
import organizationMainDataActions
  from "../../../../store/organization/organizationMainData/organizationMainDataActions";
import OrganizationButtonsRoutes from "../../../../components/service/Routes/OrganizationButtonsRoutes";
import FixedHeaderContent from "../../../../components/simple/Containers/FixedHeader/FixedHeaderContent";
import FixedSubHeader from "../../../../components/simple/Containers/FixedHeader/FixedSubHeader";

type Props = {
  onInitOrganizationMainData: onInitOrganizationMainData,
  history: history
};

class NewOrganization extends React.Component <Props> {
  componentDidMount(){
    this.props.onInitOrganizationMainData();
  }
  render() {
    return (
      <>
        <FixedHeaderContent title={text.organizations} history={this.props.history} isSupLevel={true}/>
        <FixedSubHeader title={text.newOrganization}
                        buttons={<OrganizationButtonsRoutes organizationId={null}/>}
                        history={this.props.history}/>
      </>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onInitOrganizationMainData: () => dispatch({type: organizationMainDataActions.ORGANIZATION_MAIN_DATA_INIT})
  };
};
export default connect(null, mapDispatchToProps)(NewOrganization);