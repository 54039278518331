//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../service/RenderOnReady/RenderOnReady";
import type {addServiceSheet} from "../../../services/flowTypes/dataFlowTypes";
import type {setSelectedCurrencies} from "../../../services/flowTypes/propFnFlowTypes";
import type {sheetIndex} from "../../../services/flowTypes/componentFlowTypes";

type Props = {
  sheets: Array<addServiceSheet>,
  sheetIndex: sheetIndex,
  setSelectedCurrencies: setSelectedCurrencies
};

class CurrencyValuesHandler extends React.Component <Props> {
  pushSelectedCurrenciesToArr = () => {
    const filteredSheets = this.props.sheets.filter((item, i) => {return i !== this.props.sheetIndex;} );
    const selectedCurrencies = filteredSheets.map(item => {return item.currency});
    this.props.setSelectedCurrencies(selectedCurrencies);
  };
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if (prevProps.sheets.length !== this.props.sheets.length) {
      this.pushSelectedCurrenciesToArr();
    }
    let condition = false;
    for (let i=0; i < this.props.sheets.length; i++) {
      condition = condition || prevProps.sheets[i].currency !== this.props.sheets[i].currency;
    }
    if (condition) {
      this.pushSelectedCurrenciesToArr();
    }
  }
  componentDidMount(): * {
    this.pushSelectedCurrenciesToArr();
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={true}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    sheets: state.organization.item.sheets
  };
};
export default connect(mapStateToProps, null)(CurrencyValuesHandler);