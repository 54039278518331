//@flow
import * as React from 'react';
import {connect} from "react-redux";
import ItemDataParamsEdit from "../../../../../../../components/composed/ItemDataParamsEdit/ItemDataParamsEdit";
import type {itemDataParams, organizationId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import {
  onSetItemDataParamsEdit, onSetItemDataParamsList
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import itemDataParamsEditActions
  from "../../../../../../../store/organization/item/itemDataParamsEdit/itemDataParamsEditActions";
import api from "../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import itemDataParamsListActions
  from "../../../../../../../store/organization/item/itemDataParamsList/itemDataParamsListActions";
import type {errorObject, isReadyToRender} from "../../../../../../../services/flowTypes/appFlowTypes";

type Props = {
  params: itemDataParams,
  onSetItemDataParamsEdit: onSetItemDataParamsEdit,
  organizationId: organizationId,
  onSetItemDataParamsList: onSetItemDataParamsList
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject
};

class AgentMainDataParamsEdit extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null
    };
  }
  setParamsToEdit = () => {
    const params = this.props.params.map(item => {
      return {...item};
    });
    this.props.onSetItemDataParamsEdit(params);
  };
  componentDidMount(){
    this.setParamsToEdit();
    this.setState({isReadyToRender: true});
    //TODO: consider to move this request and connect it with related component to show errMsg if the response is not got (in contract file too)
    api.getAgentDataParamsList(this.props.organizationId)
      .then(response => {
        this.props.onSetItemDataParamsList(response.data);
      })
      .catch(error => {
        this.setState({
          errorObject: apiErrorHandler(error)
        });
      });
  }
  componentDidUpdate(){
    // console.log('how many times set params to edit is called in cdu');
    this.setParamsToEdit();
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
        <ItemDataParamsEdit />
      </RenderOnReady>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    params: state.organization.item.agentMainData.params
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSetItemDataParamsEdit: (data) => dispatch({type: itemDataParamsEditActions.ITEM_DATA_PARAMS_SET, value: data}),
    onSetItemDataParamsList: (data) => dispatch({type: itemDataParamsListActions.ITEM_DATA_PARAMS_LIST_SET, value: data})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentMainDataParamsEdit);