//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {history, isReadyToRender, locationType} from "../../../../../../services/flowTypes/appFlowTypes";
import api from "../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import type {errorObject, match} from "../../../../../../services/flowTypes/appFlowTypes";
import {onGetAddAgreements, onInitAddAgreements} from "../../../../../../services/flowTypes/propFnFlowTypes";
import GetDataOnMountAndUpdate
  from "../../../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import type {contractStatus, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import type {
  onGetLinkedItemsRequestParamsProp,
  onInitLinkedItemsRequestParamsState
} from "../../../../../../services/flowTypes/propFnFlowTypes";
import linkedItemsRequestParamsActions
  from "../../../../../../store/organization/linkedItemsRequestParams/linkedItemsRequestParamsActions";
import addAgreementsActions from "../../../../../../store/organization/item/addAgreements/addAgreementsActions";
import AddAgreementsFilters from "./addAgreements/AddAgreementsFilters";
import AddAgreementsList from "./addAgreements/AddAgreementsList";
import DocumentsListFetcher
  from "../../../../../../components/composed/ItemDocuments/DocumentsList/DocumentsListFetcher/DocumentsListFetcher";
import type {isDocumentsListFetcherMounted} from "../../../../../../services/flowTypes/componentFlowTypes";
import handleCorrectOrgIdInResponse from "../../../../../../utils/handleCorrectOrgIdInResponse";
import RedirectToCorrectOrgUrl
  from "../../../../../../components/service/RedirectToCorrectOrgUrl/RedirectToCorrectOrgUrl";

type Props = {
  match: match,
  location: locationType,
  onGetAddAgreements: onGetAddAgreements,
  onInitAddAgreements: onInitAddAgreements,
  history: history,
  onGetLinkedItemsRequestParamsProp: onGetLinkedItemsRequestParamsProp,
  onInitLinkedItemsRequestParamsState: onInitLinkedItemsRequestParamsState,
  status: contractStatus
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  isDocumentsListFetcherMounted: isDocumentsListFetcherMounted,
  correctOrganizationId: organizationId
};

class AddAgreements extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      isDocumentsListFetcherMounted: true,
      correctOrganizationId: null
    };
  }
  handleResponse = (responseData) => {
    this.props.onGetAddAgreements(responseData);
    this.setState({isReadyToRender: true});
  };
  getAddAgreements = () => {
    this.props.onInitAddAgreements();
    const contractId = +this.props.match.params.id;

    api.getAddAgreements(contractId, this.props.status)
      .then(response => {
        handleCorrectOrgIdInResponse(this, response.data, this.setState, this.handleResponse);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  setReadyToRender = () => {
    this.setState({isReadyToRender: true});
  };
  unmountDocumentsListFetcher = () => {
    this.setState({
      isReadyToRender: false,
      isDocumentsListFetcherMounted: false
    });
  };
  mountDocumentsListFetcher = () => {
    this.setState({
      isReadyToRender: false,
      isDocumentsListFetcherMounted: true
    });
  };
  componentDidUpdate(prevProps: Props) {
    if (this.props.status !== prevProps.status && prevProps.status !== null && this.props.status !== null) {
      this.getAddAgreements();
    }
  }
  componentDidMount() {
    this.props.onInitLinkedItemsRequestParamsState();
  }
  render() {
    const contractId = this.props.match.params.id;
    return (
      <>
        {this.state.isDocumentsListFetcherMounted ? (
          <DocumentsListFetcher
            itemType={'contract'}
            setReadyToRender={this.setReadyToRender}
            itemId={contractId}
            isAllFilesShown={true}
          />
        ) : null}
        <RedirectToCorrectOrgUrl correctOrganizationId={this.state.correctOrganizationId} history={this.props.history}>
          <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
            <div className="AddAgreements">
              <AddAgreementsFilters/>
              {this.props.status !== null ? (
                <GetDataOnMountAndUpdate itemId={contractId} getDataOnMountAndUpdate={this.getAddAgreements}>
                  <AddAgreementsList
                    mountDocumentsListFetcher={this.mountDocumentsListFetcher}
                    unmountDocumentsListFetcher={this.unmountDocumentsListFetcher}
                    contractId={contractId}/>
                </GetDataOnMountAndUpdate>
              ) : null}
            </div>
          </RenderOnReady>
        </RedirectToCorrectOrgUrl>
      </>

    );
  }
}

const mapStateToProps = state => {
  return {
    status: state.organization.linkedItemsRequestParams.hasOwnProperty('status') ?
      state.organization.linkedItemsRequestParams.status : null,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetAddAgreements: (addAgreements) => dispatch({
      type: addAgreementsActions.ADD_AGREEMENTS_SET, value: addAgreements}),
    onInitAddAgreements: () => dispatch({type: addAgreementsActions.ADD_AGREEMENTS_INIT}),
    onGetLinkedItemsRequestParamsProp: (searchRequestObj) => dispatch({type: linkedItemsRequestParamsActions.LINKED_ITEMS_REQUEST_PARAMS_PROP_SET,
      value: searchRequestObj}),
    onInitLinkedItemsRequestParamsState: () => dispatch({type: linkedItemsRequestParamsActions.LINKED_ITEMS_REQUEST_PARAMS_STATE_INIT})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAgreements);