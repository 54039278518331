//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  addServiceType,
  agentsList,
  contractTypes, groupFinTermType,
  historyObjectType, notedTerminalType,
  propNameValue, usersList
} from "../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../services/localization/text";
import formatDate from "../../../../utils/formatDate";
import getFinTermParamRateLocaleKey from "../../../../utils/getFinTermParamRateLocaleKey";

const withHandledHistoryLogPropName = (WrappedComponent) => {
  type Props = {
    propNameValue: propNameValue,
    objectType: historyObjectType,
    contractTypes: contractTypes,
    agentsList: agentsList,
    groupFinTermType: groupFinTermType,
    addServiceTypeList: Array<addServiceType>,
    usersList: usersList,
    notedTerminalTypeList: Array<notedTerminalType>
  };
  class HOC extends React.Component <Props> {
    defineGateTerminalPropName = (nameStr) => {
      // name: "PNE/gateId/146/terminalId/3263/groupFinTerms"
      const arr = nameStr.split('/');
      if (nameStr.includes("terminalId/")) {
        return text.historyLog.AGENT.gateTerminalProps[arr[5]] + " (" + text.historyLog.AGENT.gateId + ": " + [arr[2]]
          + "; " + text.historyLog.AGENT.terminalId + ": " + [arr[4]] + ")";
        // return text.historyLog.AGENT.gateId + ": " + [arr[2]] + " " + text.historyLog.AGENT.terminalId + ": " + [arr[4]] + " "
        //   + text.historyLog.AGENT.gateTerminalProps[arr[5]];
      } else {
        // name: "PNE/gateId/146/isIgnored"
        return text.historyLog.AGENT.gateTerminalProps[arr[3]] + " (" + text.historyLog.AGENT.gateId + ": "
          + [arr[2]] + ")";
      }
    };
    defineFinTermGateName = (nameStr) => {
      // name: "ftgGateParams/PNE/0/346"
      const arr = nameStr.split('/');
      return text.historyLog.CONTRACT.gateId + ": " + [arr[3]];
    };
    defineMerchIdsName = (nameStr) => {
      // name: "agentMercIds/PNE_EU/mercIds"
      // name: "agentMercIds/PNE/mercIds"
      const source = nameStr.replace("agentMercIds/", "").replace("/mercIds", "");
      return text.historyLog.AGENT.merchantId + " (" + text[source] + ")";
    };
    defineManagerIdsName = (nameStr) => {
      // name: "agentMngrIds/PNE/mngrIds"
      const source = nameStr.replace("agentMngrIds/", "").replace("/mngrIds", "");
      return text.historyLog.AGENT.managerId + " (" + text[source] + ")";
    };
    defineProcessorIdsName = (nameStr) => {
      // name: "agentProcIds/PNE/procIds"
      const source = nameStr.replace("agentProcIds/", "").replace("/procIds", "");
      return text.historyLog.AGENT.processorId + " (" + text[source] + ")";
    };
    defineManualInputName = (nameStr) => {
      // name: "manualInput/188"
      const arr = nameStr.split('/');
      return (text.historyLog.CONTRACT.manualInput + " (#" + arr[1] + " " + text.historyLog.CONTRACT.groupFinTerm + ")" );
    };
    defineFinTermParamName = (nameStr) => {
      // name: "finTerm/188/rate/0/minAmount"
      const arr = nameStr.split('/');
      const paramNameKey = getFinTermParamRateLocaleKey(arr[4], this.props.groupFinTermType);
      return (text.historyLog.CONTRACT[paramNameKey] + " (#" + arr[1] + " " + text.historyLog.CONTRACT.finTerm + " / "
        + text.historyLog.CONTRACT.row + " " + arr[3] + ")" );
    };
    defineAddServiceSheetParamName = (nameStr) => {
      // name: "addServiceSheet/0/currency"
      // name: "addServiceSheet/0/item/0/addServiceTypeId"
      const arr = nameStr.split('/');
      if (nameStr.includes("currency")) {
        const paramNameKey = arr[2];
        return (text.historyLog.CONTRACT[paramNameKey] + " (" + text.historyLog.CONTRACT.addServiceSheetLabel
          + " " + arr[1] + ")" );
      } else {
        const paramNameKey = arr[4];
        return (text.historyLog.CONTRACT.addServiceSheet[paramNameKey] + " (" + text.historyLog.CONTRACT.addServiceSheetLabel
          + " " + arr[1] +
          " / " + text.historyLog.CONTRACT.addServiceCalculatedItemLabel + " " + arr[3] + ")" );
      }
    };
    defineStatus = (status, objectType) => {
      if (objectType === 'CONTRACT' || objectType === 'AGENT') {
        return text[objectType.toLowerCase()].status[status];
      }
    };
    defineAgentName = (agentIdStr) => {
      const agentId = +agentIdStr;
      const agentIdName = this.props.agentsList.find(agent => agent.id === agentId);
      return  agentIdName ? agentIdName.name : 'Agent not found in agents list';
    };
    defineHistoryLogPropValue = (propNameValue, objectType) => {
      if (propNameValue.name.includes("addServiceTypeId")) {
        return this.defineAddServiceTypeValue(propNameValue.value);
      }
      if (propNameValue.name.includes("ftgGateParams")) {
        return this.defineFinTermGateTerminalsValue(propNameValue.value);
      }
      if (propNameValue.name.includes("isIgnored")
        || propNameValue.name === "isNotVerifiedWithFinTerms"
        || propNameValue.name === "isCheckedAndVerified") {
        return propNameValue.value === "true" ? text.yesLabel : text.noLabel;
      }
      if (propNameValue.name.includes("notedTerminalTypeId")) {
        return this.defineNotedTerminalType(propNameValue.value);
      }
      if (propNameValue.name.includes("commentUserId")) {
        return this.defineUserName(propNameValue.value);
      }
      if (propNameValue.name.includes("commentDate")) {
        return formatDate(propNameValue.value);
      }
      switch (propNameValue.name) {
        case 'typeId':
          return this.defineContractTypeValue(propNameValue.value);
        case 'startDate':
        case 'endDate':
          return formatDate(propNameValue.value);
        case 'status':
          return this.defineStatus(propNameValue.value, objectType);
        case 'payerId':
        case 'payeeId':
          return this.defineAgentName(propNameValue.value);
        case 'payoutPeriod':
          return text[propNameValue.value];
        case 'sourceParam/usersExcluded/textValue':
          return text.usersExcludedValue[propNameValue.value];
        default:
          return propNameValue.value;
      }
    };
    defineFinTermGateTerminalsValue = (terminalIds) => {
      return text.historyLog.CONTRACT.terminalIds + ": " + terminalIds;
    };
    defineContractTypeValue = (typeIdStr) => {
      const typeIdNumber = +typeIdStr;
      const contractTypeObj = this.props.contractTypes.find(item => item.id === typeIdNumber);
      return contractTypeObj ? contractTypeObj.name : 'Type label is not defined';
    };
    defineAddServiceTypeValue = (typeIdStr) => {
      const typeIdNumber = +typeIdStr;
      const addServiceTypeObj = this.props.addServiceTypeList.find(item => item.id === typeIdNumber);
      return addServiceTypeObj ? addServiceTypeObj.name : 'AddServiceType label is not defined';
    };
    defineNotedTerminalType = (typeIdStr) => {
      const typeIdNumber = +typeIdStr;
      const notedTerminalTypeObj = this.props.notedTerminalTypeList.find(item => item.id === typeIdNumber);
      return notedTerminalTypeObj ? notedTerminalTypeObj.name : 'NotedTerminalType label is not defined';
    };
    defineUserName = (userIdStr) => {
      const userIdNumber = +userIdStr;
      const userObj = this.props.usersList.find(item => item.id === userIdNumber);
      return userObj ? userObj.name : 'User name is not defined';
    };
    defineByDef = (name, objectType) => {
      const propName = getFinTermParamRateLocaleKey(name, this.props.groupFinTermType);
      if (text.historyLog.hasOwnProperty(objectType) && text.historyLog[objectType].hasOwnProperty(propName)) {
        return text.historyLog[objectType][propName];
      } else {
        return name;
      }
    };
    defineHistoryLogPropName = (name, objectType) => {
      if (name.includes('finTerm/')) {
        return this.defineFinTermParamName(name);
      } else if (name.includes('addServiceSheet/')) {
        return this.defineAddServiceSheetParamName(name);
      } else if (name.includes('manualInput/')){
        return this.defineManualInputName(name, objectType);
      } else if (name.includes('agentMercIds/')){
        return this.defineMerchIdsName(name);
      } else if (name.includes('agentMngrIds/')){
        return this.defineManagerIdsName(name);
      } else if (name.includes('agentProcIds/')){
        return this.defineProcessorIdsName(name);
      } else if (name.includes('ftgGateParams/')){
        return this.defineFinTermGateName(name);
      } else if (name.includes('gateId/')){
        return this.defineGateTerminalPropName(name);
      } else {
        return this.defineByDef(name, objectType);
      }
    };
    render() {
      const handledName = this.defineHistoryLogPropName(this.props.propNameValue.name, this.props.objectType);
      const handledValue = this.defineHistoryLogPropValue(this.props.propNameValue, this.props.objectType);
      const handledPropNameValue = {name: handledName, value: handledValue};
      return (
        <WrappedComponent handledPropNameValue={handledPropNameValue} {...this.props}/>
      );
    }
  }

  const mapStateToProps = state => {
    return {
      addServiceTypeList: state.organization.organizationData.statuses.addServiceTypeList,
      contractTypes: state.organization.organizationData.contractTypes,
      agentsList: state.organization.organizationData.agentsList,
      usersList: state.organization.organizationData.usersList,
      notedTerminalTypeList: state.organization.organizationData.statuses.notedTerminalTypeList
    };
  };

  return  connect(mapStateToProps, null)(HOC);
};

export default withHandledHistoryLogPropName;