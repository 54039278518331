//@flow
import * as React from 'react';
import text from "../../../../../../../../services/localization/text";
import BtnEditAct from "../../../../../../../../components/simple/Buttons/BtnEditAct/BtnEditAct";
import type {modalIsShown} from "../../../../../../../../services/flowTypes/appFlowTypes";
import ReportManualAdjustmentModal from "./ReportManualAdjustmentModal/ReportManualAdjustmentModal";
import type {reportId} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import {reportViewPageIds} from "../../../../../../../../tests/testIds";

type Props = {
  reportId: reportId
};
type State = {
  modalIsShown: modalIsShown
};

class ReportManualAdjustment extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false
    };
  }
  showModal = () => {this.setState({modalIsShown: true});};
  hideModal = () => {this.setState({modalIsShown: false});};
  render() {
    return (
      <>
        <BtnEditAct id={reportViewPageIds.buttonManualAdjust} onClick={this.showModal}>{text.manualAdjustment}</BtnEditAct>
        {this.state.modalIsShown ? (
          <ReportManualAdjustmentModal hideModal={this.hideModal} reportId={this.props.reportId}/>
        ) : null}
      </>

    );
  }
}
export default ReportManualAdjustment;