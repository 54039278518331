export const formatWithDelimiter = (value, delimiter) => {
  const regExpDelimiter = new RegExp(delimiter, 'g');
  return value.replace(regExpDelimiter, delimiter + ' ');
};


const formatInputWithDelimiter = (value, delimiter, mask, prevValue) => {
  const regExpMask = new RegExp('[^' + mask + ']', 'g');
  const formattedValue = mask ? value.replace(regExpMask, '') : value;
  if (formattedValue.length < prevValue.length) {
    return formattedValue;
  }
  if (formattedValue.endsWith(delimiter + "  ")) {
    return formattedValue.trim() + " ";
  } else if (formattedValue.endsWith(delimiter + " " + delimiter)) {
    return formattedValue.slice(0, -1);
  } else if (formattedValue.endsWith(delimiter + " ")) {
    return formattedValue;
  } else if (formattedValue.endsWith(" ")) {
    return formattedValue.trim() + delimiter + " ";
  } else if (formattedValue.endsWith(delimiter)) {
    return formattedValue.trim() + " ";
  } else {
    return formattedValue;
  }
};
export default formatInputWithDelimiter;