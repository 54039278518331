//@flow
import * as React from 'react';
import type {addServiceName, hasPreparedAddServiceReport} from "../../../../../../../services/flowTypes/dataFlowTypes";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";
import {Link} from "react-router-dom";
import {addServicesPageIds} from "../../../../../../../tests/testIds";
import BtnEdit from "../../../../../../../components/simple/Buttons/BtnEdit/BtnEdit";
import Can from "../../../../../../../services/permissions/Can";
import type {linkTo} from "../../../../../../../services/flowTypes/componentFlowTypes";
import type {modalIsShown} from "../../../../../../../services/flowTypes/appFlowTypes";
import PreventEditDeleteModal from "./PreventEditDeleteModal";

type Props = {
  hasPreparedAddServiceReport: hasPreparedAddServiceReport,
  linkToTypeEdit: linkTo,
  addServiceIndex: number,
  addServiceName: addServiceName
};
type State = {
  modalIsShown: modalIsShown
};

class ButtonEditAddService extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false
    };
  }
  showModal = () => {
    this.setState({modalIsShown: true});
  };
  hideModal = () => {
    this.setState({modalIsShown: false});
  };
  render() {
    return this.props.hasPreparedAddServiceReport ? (
      <>
        <div className="BtnEdit_cardButtonMode__link-container" onClick={this.showModal}>
          <BtnEdit isCardButtonMode={true} />
        </div>
        {this.state.modalIsShown ? (
          <PreventEditDeleteModal onModalCancelBtnClick={this.hideModal} addServiceName={this.props.addServiceName}/>
        ) : null}
      </>
    ) : (
      <Can do={'usePermission'} on={permissionsStrValues.addService.update}>
        <Link to={this.props.linkToTypeEdit} className={'BtnEdit_cardButtonMode__link-container'}
              id={addServicesPageIds.buttonEditAddService(this.props.addServiceIndex)}>
          <BtnEdit isCardButtonMode={true} />
        </Link>
      </Can>
    );
  }
}

export default ButtonEditAddService;    