//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {children, errorObject, isReadyToRender} from "../../../../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import api from "../../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../../services/apiInstance/apiErrorHandler";
import groupFinTermsViewActions
  from "../../../../../../../../store/organization/item/groupFinTermsView/groupFinTermsViewActions";
import type {configFinTermParams, contractId} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import {onSetGroupFinTermsView} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import GetDataOnMountAndUpdate
  from "../../../../../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";

type Props = {
  contractId: contractId,
  onSetGroupFinTermsView: onSetGroupFinTermsView,
  configFinTermParams: configFinTermParams,
  children: children
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};
//TODO: review this solution, because it's temporal fix
class GroupFinTermsViewFetcher extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: false
    };
  }
  prepareGroupFinTermsToStore = (groupFinTerms) => {
    const groupFinTermsReversed = groupFinTerms.reverse();
    const groupFinTermsSorted = groupFinTermsReversed.map(item => {
      const sampleRow = this.props.configFinTermParams[item.type];
      const finTerms = item.finTerms.map(finTerm => {
        const params = finTerm.params.map(paramsRow => {
          paramsRow.sort((a, b)=>{
            return sampleRow.findIndex(item => item.name === a.name) - sampleRow.findIndex(item => item.name === b.name);
          });
          const newParamsRow = paramsRow.map(param => {
            if (!param.value) {
              param.value = '';
            }
            return param;
          });
          return newParamsRow;
        });
        return {...finTerm, params};
      });
      return {...item, finTerms}
    });
    return groupFinTermsSorted;
  };
  getGroupFinTerms = () => {
    this.setState({isReadyToRender: false});
    api.getGroupFinTerms({contractId: this.props.contractId})
      .then(response => {
        const groupFinTerms = this.prepareGroupFinTermsToStore(response.data);
        this.props.onSetGroupFinTermsView(groupFinTerms);
        this.setState({isReadyToRender: true});
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    return (
      <GetDataOnMountAndUpdate itemId={this.props.contractId} getDataOnMountAndUpdate={this.getGroupFinTerms}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
          {this.props.children}
        </RenderOnReady>
      </GetDataOnMountAndUpdate>
    );
  }
}

const mapStateToProps = state => {
  return {
    configFinTermParams: state.organization.organizationData.configFinTerms.configFinTermParams
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetGroupFinTermsView: (groupFinTerms) => dispatch({
      type: groupFinTermsViewActions.GROUP_FIN_TERMS_VIEW_SET, value: groupFinTerms})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupFinTermsViewFetcher);    