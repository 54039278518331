//@flow
import * as React from 'react';
import {connect} from "react-redux";
import BtnDel from "../../../../../../../../../components/simple/Buttons/BtnDel/BtnDel";
import api from "../../../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../../../services/apiInstance/apiErrorHandler";
import type {
  contractId, groupFinTermId,
  templateParams,
  variableName
} from "../../../../../../../../../services/flowTypes/dataFlowTypes";
import type {errorObject, id, isReadyToRender} from "../../../../../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {
  onGetActTemplateProp,
  onGetTemplateParams, onResetActTemplateExpressions
} from "../../../../../../../../../services/flowTypes/propFnFlowTypes";
import templateParamsActions
  from "../../../../../../../../../store/organization/item/templateParams/templateParamsActions";
import actTemplatesEditActions
  from "../../../../../../../../../store/organization/item/actTemplatesEdit/actTemplatesEditActions";
import type {addClasses} from "../../../../../../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../../../../../UiKit/newUiKit/utilities/baseClass";

type Props = {
  variableName: variableName,
  templateParams: templateParams,
  contractId: contractId,
  onGetTemplateParams: onGetTemplateParams,
  onGetActTemplateProp: onGetActTemplateProp,
  id: id,
  addClasses?: addClasses,
  groupFinTermId: groupFinTermId,
  onResetActTemplateExpressions: onResetActTemplateExpressions
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject
};

class DeleteConvertedTagBtn extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null
    };
  }
  deleteConvertedTagFromTemplateParams = () => {
    const templateParams = this.props.templateParams.map(item => {
      const actTemplateParams = item.actTemplateParams.map(templateParamObj => {
        const actTemplateParamObj = {...templateParamObj};
        if (item.groupId === this.props.groupFinTermId && templateParamObj.variableName === this.props.variableName) {
          delete actTemplateParamObj.convertedTag;
        }
        return actTemplateParamObj;
      });
      return {groupId: item.groupId, actTemplateParams};
    });
    return templateParams;
  };
  handleDelClick = () => {
    const requestObject = {
      currency: null,
      date: null,
      source: null,
      variableName: this.props.variableName,
      finTermTemplateParams: this.deleteConvertedTagFromTemplateParams()
    };
    this.setState({isReadyToRender: false});
    api.getConvertedTag(this.props.contractId, requestObject)
      .then(response => {
        this.props.onGetTemplateParams(response.data);
        this.props.onResetActTemplateExpressions();
        //this function call is hack to change actTemplatesEdit state to able the Save button, this prop is not used in request to update actTemplate
        this.props.onGetActTemplateProp('convertedTagUpdated', true);
        this.setState({
          isReadyToRender: true
        });
        this.props.cancelModal();
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    const addClasses = baseClass(this.props.addClasses, 'DeleteConvertedTagBtn');
    return (
      <>
        <BtnDel onClick={this.handleDelClick} id={this.props.id} addClasses={addClasses}/>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
      </>

    );
  }
}

const mapStateToProps = state => {
  return {
    contractId: state.organization.item.contractMainData.id,
    templateParams: state.organization.item.templateParams
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onResetActTemplateExpressions: () => dispatch({type: actTemplatesEditActions.ACT_TEMPLATES_EXPRESSIONS_RESET}),
    onGetTemplateParams: (templateParams) => dispatch({type: templateParamsActions.TEMPLATE_PARAMS_SET, value: templateParams}),
    onGetActTemplateProp: (prop, data) => dispatch({type: actTemplatesEditActions.ACT_TEMPLATES_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteConvertedTagBtn);    