//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  addServiceStatusList
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../../services/localization/text";
import type {selectOptions} from "../../../../../../../services/flowTypes/componentFlowTypes";
import SelectStatusFilter from "../../AddAgreements/addAgreements/SelectStatusFilter";

type Props = {
  addServiceStatusList: addServiceStatusList
};
type State = {
  statusSelectOptions: selectOptions
};

class AddServicesFilters extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      statusSelectOptions: [],
    };
  }
  componentDidMount() {
    const optionsByCategory = this.props.addServiceStatusList.map(item => {
      return {
        value: item,
        label: text.addService.status[item],
        statusClassName: `StatusLabel StatusLabel_${item}`
      };
    });
    const statusSelectOptions = [
      {value: '', label: text.all, statusClassName: `StatusLabel`},
      ...optionsByCategory
    ];
    this.setState({statusSelectOptions});
  }
  render() {
    return (
      <SelectStatusFilter statusSelectOptions={this.state.statusSelectOptions} initValue={"ACTIVE"}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    addServiceStatusList: state.organization.organizationData.statuses.addServiceStatusList
  };
};
export default connect(mapStateToProps, null)(AddServicesFilters);