//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  formFieldType, isOutputMode, isSearchable, noOptionsMessage,
  propName,
  propValue,
  selectOptions,
  sheetIndex, withCurrencyIco
} from "../../../services/flowTypes/componentFlowTypes";
import type {id, isReadyToRender, label, placeholder} from "../../../services/flowTypes/appFlowTypes";
import type {
  onDeleteValidationStateFormProp,
  onGetSheetCalculatedItemProp
} from "../../../services/flowTypes/propFnFlowTypes";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import ConnectedFormGroup from "../ConnectedFormGroup/ConnectedFormGroup";
import type {currency} from "../../../services/flowTypes/dataFlowTypes";
import sheetsActions from "../../../store/organization/item/sheets/sheetsActions";
import {getCurrencyIco} from "../../../utils/getCurrencyLabel";
import SheetCalculationUpdater from "./SheetCalculationUpdater";
import getMaskedInputValue from "../../../utils/getMaskedInputValue";
import RenderOnReady from "../../service/RenderOnReady/RenderOnReady";
import validationActions from "../../../store/validation/validationActions";
import addServiceValidationScheme from "../../../configData/addServiceValidationScheme";

type Props = {
  sheetIndex: sheetIndex,
  calculatedItemIndex: number,
  formFieldType: formFieldType,
  propName: propName,
  propValue: propValue,
  onGetSheetCalculatedItemProp: onGetSheetCalculatedItemProp,
  label: label,
  options?: selectOptions,
  addClasses?: addClasses,
  placeholder?: placeholder,
  noOptionsMessage?: noOptionsMessage,
  idForTest?: id,
  isSearchable?: isSearchable,
  selectInputId?: id,
  isOutputMode?: isOutputMode,
  withCurrencyIco?: withCurrencyIco,
  currency: currency,
  onDeleteValidationStateFormProp: onDeleteValidationStateFormProp
};
type State = {
  propName: propName,
  isReadyToRender: isReadyToRender
};

const updatedPropsListNeedCalculation = ['price', 'number'];

class SheetCalculatedItemFormGroup extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      propName: ''
    };
  }
  handleInputData = (data, isForStore) => {
    if (updatedPropsListNeedCalculation.includes(this.props.propName)) {
      const valueWithReplacedComa = getMaskedInputValue(data);
      return isForStore ? valueWithReplacedComa.replace(/\s/g, '') : valueWithReplacedComa;
    } else {
      return data;
    }
  };
  handleGetCalculatedItemProp = (prop, data) => {
    const actionValue = {
      sheetIndex: this.props.sheetIndex,
      calculatedItemIndex: this.props.calculatedItemIndex,
      propName: this.props.propName,
      propValue: this.handleInputData(data, true)
    };
    this.props.onGetSheetCalculatedItemProp(actionValue);
  };
  setPropName = () => {
    return 'sheet' + this.props.sheetIndex + 'calculatedItem' + this.props.calculatedItemIndex + this.props.propName;
  };
  componentDidMount(): * {
    this.setState({
      propName: this.setPropName(),
      isReadyToRender: true
    });
  }
  componentWillUnmount(){
    this.props.onDeleteValidationStateFormProp(this.state.propName);
  }
  render() {
    const propValidationPatternsArr = addServiceValidationScheme.sheets.calculatedItems[this.props.propName] ?
      addServiceValidationScheme.sheets.calculatedItems[this.props.propName] : null;
    const inputIco = this.props.withCurrencyIco ? (
      <div className="FinTermParamIco">{getCurrencyIco(this.props.currency)}</div>
    ) : null;
    const outputComponentClassName = this.props.withCurrencyIco ? "SheetCalculatedItemFormGroup__outputMode pr_forIco"
      : "SheetCalculatedItemFormGroup__outputMode";
    const connectedFormGroupComponent = (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
        <ConnectedFormGroup
          formFieldType={this.props.formFieldType}
          propName={this.state.propName}
          propValue={this.handleInputData(this.props.propValue, false)}
          onGetPropValue={this.handleGetCalculatedItemProp}
          label={this.props.label}
          options={this.props.options}
          propValidationPatternsArr={propValidationPatternsArr}
          addClasses={this.props.addClasses}
          idForTest={this.props.idForTest}
          placeholder={this.props.placeholder}
          noOptionsMessage={this.props.noOptionsMessage}
          isSearchable={this.props.isSearchable}
          selectInputId={this.props.selectInputId}
          inputIco={inputIco}
        />
      </RenderOnReady>
    );
    return (
      this.props.isOutputMode ? (
        <div className="Heading4 SheetCalculatedItemFormGroup__outputMode-container flex">
          <div className={outputComponentClassName} id={this.props.idForTest}>{getMaskedInputValue(this.props.propValue)}</div>
          {inputIco}
        </div>
      ) : updatedPropsListNeedCalculation.includes(this.props.propName) ? (
        <><SheetCalculationUpdater sheetIndex={this.props.sheetIndex} propValue={this.props.propValue}/>
          {connectedFormGroupComponent}</>
      ) : (
        <>{connectedFormGroupComponent}</>
      )
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propValue: state.organization.item.sheets[ownProps.sheetIndex].calculatedItems[ownProps.calculatedItemIndex][ownProps.propName],
    currency: state.organization.item.sheets[ownProps.sheetIndex].currency
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetSheetCalculatedItemProp: (value) => dispatch({type: sheetsActions.SHEETS_CALCULATED_ITEM_PROP_UPDATE, value}),
    onDeleteValidationStateFormProp: (prop) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_DELETE, value: prop})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SheetCalculatedItemFormGroup);    