//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  contractGateListItem,
  groupFinTermId,
  groupFinTermSource,
  organizationId,
  payerId
} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import NCard from "../../../../../../../../components/simple/Containers/NCard/NCard";
import text from "../../../../../../../../services/localization/text";
import SectionHeader from "../../../../../../../../components/composed/SectionHeader/SectionHeader";
import RenderOnReady from "../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {errorObject, isReadyToRender} from "../../../../../../../../services/flowTypes/appFlowTypes";
import api from "../../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../../services/apiInstance/apiErrorHandler";
import groupFinTermsEditActions
  from "../../../../../../../../store/organization/item/groupFinTermsEdit/groupFinTermsEditActions";
import type {onGetGroupFinTermProp} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import FinTermGate from "./FinTermGate";
import getUniqueKey from "../../../../../../../../utils/getUniqueKey";
import {
  contrPathView,
  mainDataSuffix,
  orgPath
} from "../../../../../../../../services/urlStrings/urlStrings";
import {Link} from "react-router-dom";

type Props = {
  gatesLength: number,
  source: groupFinTermSource,
  contractId: payerId,
  groupFinTermId: groupFinTermId,
  onGetGroupFinTermProp: onGetGroupFinTermProp,
  organizationId: organizationId
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  contractGateList: contractGateListItem[]
};

const groupFinTermsWithGatesArr = ["PNE", "PNE_EU"];

class FinTermGates extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: false,
      contractGateList: []
    };
  }
  getPayerGateList = (isSetGatesToStore) => {
    this.setState({isReadyToRender: false});
    api.getContractGateList(this.props.contractId, this.props.source)
      .then(response => {
        if (isSetGatesToStore) {
          const finTermGates = response.data.map(item => {
            return {...item, isIgnored: true};
          });
          this.props.onGetGroupFinTermProp("gates", finTermGates);
        }
        this.setState({
          contractGateList: response.data,
          isReadyToRender: true
        });
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if ( (prevProps.contractId !== this.props.contractId && groupFinTermsWithGatesArr.includes(this.props.source)) ||
      (prevProps.source !== this.props.source && groupFinTermsWithGatesArr.includes(this.props.source) && this.props.contractId)) {
      this.getPayerGateList(true);
    }
  }
  componentDidMount(): * {
    if (this.props.groupFinTermId && groupFinTermsWithGatesArr.includes(this.props.source)) {
      this.getPayerGateList();
    }
  }
  render() {
    let gates = [];
    for (let i=0; i < this.props.gatesLength; i++) {
      const contractGateListItem = this.state.contractGateList[i];
      if (contractGateListItem) {
        const gate = <FinTermGate key={getUniqueKey()} finTermGateIndex={i} contractGateListItem={contractGateListItem}/>
        gates.push(gate);
      }
    }
    return groupFinTermsWithGatesArr.includes(this.props.source) && this.props.payerId ?
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <NCard addClasses={"mb_3du"}>
          <SectionHeader sectionHeaderTitle={text.finTermGatesSectionTitle} addClasses={"mb_3du"}/>
          {gates.length ? gates :
            <div className={"flex"}>{text.finTermGatesArrayIsEmpty}
              <Link to={orgPath(this.props.organizationId) + contrPathView(this.props.contractId) + mainDataSuffix}
                    className={"ml_1du link-color"}>
                {text.finTermGatesArrayIsEmptyLink}</Link>
            </div>}
        </NCard>
    </> : null;
  }
}

const mapStateToProps = state => {
  return {
    gatesLength: state.organization.item.groupFinTermsEdit.gates ? state.organization.item.groupFinTermsEdit.gates.length : [],
    source: state.organization.item.groupFinTermsEdit.source,
    contractId: state.organization.item.contractMainData.id,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetGroupFinTermProp: (prop, data) => dispatch({type: groupFinTermsEditActions.GROUP_FIN_TERMS_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FinTermGates);