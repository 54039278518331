//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {editMode} from "../../../services/flowTypes/componentFlowTypes";
import text from "../../../services/localization/text";
import Checkbox from "../../simple/FormComponents/Checkbox/Checkbox";
import notificationSettingsActions from "../../../store/notificationSettings/notificationSettingsActions";
import type {
  onUpdateNotificationSettingsPropsOfAll,
  onUpdateNotificationSettingsPropsOfCategory,
  onUpdateNotificationSettingsPropsOfOrg
} from "../../../services/flowTypes/propFnFlowTypes";
import notificationOnIconSrc from '../../../assets/notification-on-icon.svg';
import notificationOffIconSrc from '../../../assets/notification-off-icon.svg';

type Props = {
  editMode: editMode,
  onUpdateNotificationSettingsPropsOfOrg: onUpdateNotificationSettingsPropsOfOrg,
  onUpdateNotificationSettingsPropsOfCategory: onUpdateNotificationSettingsPropsOfCategory,
  onUpdateNotificationSettingsPropsOfAll: onUpdateNotificationSettingsPropsOfAll,
  orgNotificationIndex?: number,
  notificationIndex?: number,
  turnedOn: boolean
};

class NotificationSettingsSetAllForOrgBtn extends React.Component <Props> {
  handleCheckboxClick = () => {
    if (this.props.hasOwnProperty('orgNotificationIndex')) {
      const actionValue = {
        orgNotificationIndex: this.props.orgNotificationIndex,
        turnedOn: !this.props.turnedOn
      };
      this.props.onUpdateNotificationSettingsPropsOfOrg(actionValue);
    } else if (this.props.hasOwnProperty('notificationIndex')) {
      const actionValue = {
        notificationIndex: this.props.notificationIndex,
        turnedOn: !this.props.turnedOn
      };
      this.props.onUpdateNotificationSettingsPropsOfCategory(actionValue);
    } else {
      const actionValue = {
        turnedOn: !this.props.turnedOn
      };
      this.props.onUpdateNotificationSettingsPropsOfAll(actionValue);
    }
  };
  render() {
    const inputValue = this.props.turnedOn ? text.notificationSettingsInputValue.yes
      : text.notificationSettingsInputValue.no;
    const imgSrc = this.props.turnedOn ? notificationOnIconSrc : notificationOffIconSrc;
    return (
      this.props.editMode ? (
        <div className="NotificationSettingsForm__input-value">
          <Checkbox addClasses={'Checkbox_16'} onClick={this.handleCheckboxClick}
                    checked={this.props.turnedOn}/>
        </div>
      ) : (
        <div className={"NotificationSettingsForm__input-value NotificationSettingsForm__input-value_view-mode"}>
          <img src={imgSrc} alt={inputValue}/>
        </div>
      )
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onUpdateNotificationSettingsPropsOfOrg: (value) => dispatch({
      type: notificationSettingsActions.NOTIFICATION_SETTINGS_PROPS_OF_ORG_UPDATE, value}),
    onUpdateNotificationSettingsPropsOfCategory: (value) => dispatch({
      type: notificationSettingsActions.NOTIFICATION_SETTINGS_PROPS_OF_CATEGORY_UPDATE, value}),
    onUpdateNotificationSettingsPropsOfAll: (value) => dispatch({
      type: notificationSettingsActions.NOTIFICATION_SETTINGS_PROPS_OF_ALL_UPDATE, value})
  };
};

export default connect(null, mapDispatchToProps)(NotificationSettingsSetAllForOrgBtn);