//@flow
import * as React from 'react';
import {orgPath} from "../../../services/urlStrings/urlStrings";
import {Route} from "react-router-dom";
import SetOrganizationIdByRoute from "./SetOrganizationIdByRoute";

type Props = {};

const OrgRoute = (props: Props) => {
  return (
    <Route path={orgPath(':organizationId')} component={SetOrganizationIdByRoute} />
  );
};

export default OrgRoute;    