//@flow
import * as React from 'react';
import {baseClass} from "../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import type {formFieldValue, label} from "../../../services/flowTypes/appFlowTypes";
import Heading4 from "../../simple/TextComponents/Heading4/Heading4";
import NLabel from "../../simple/FormComponents/NLabel/NLabel";

type Props = {
  id?: id,
  addClasses?: addClasses,
  label: label,
  value: formFieldValue,
  testIdForValue?: id
};

const PropValueView = (props: Props) => {
  const value = (props.value === null || props.value === undefined) ? '-' : (props.value === '' ? "-" : props.value);
  return (
    <div id={props.id} className={baseClass("PropValueView", props.addClasses)}>
      <NLabel addClasses={'mb_half-du'}>{props.label}</NLabel>
      <Heading4 addClasses={'Heading4_lh16'} id={props.testIdForValue}>{value}</Heading4>
    </div>
  );
};

export default PropValueView;