import ImportComponentWithClickToCopy from "../ImportComponentWithClickToCopy/ImportComponentWithClickToCopy";
import Pre from "../Pre/Pre";
import NHeading2 from "../../../../components/simple/TextComponents/NHeading2/NHeading2";
import SecText from "../../../../components/simple/TextComponents/SecText/SecText";
import BtnNewItem from "../../../../components/simple/Buttons/BtnNewItem/BtnNewItem";
import HeaderContent from "../../../../components/composed/HeaderContent/HeaderContent";
import {HeaderContentDefProps} from "../../../../components/composed/HeaderContent/defProps";
import NCard from "../../../../components/simple/Containers/NCard/NCard";
import NHeading1 from "../../../../components/simple/TextComponents/NHeading1/NHeading1";
import BtnEditAct from "../../../../components/simple/Buttons/BtnEditAct/BtnEditAct";
import BtnLogout from "../../../../components/simple/Buttons/BtnLogout/BtnLogout";
import HoveredIcon from "../../../../components/composed/HoveredIcon/HoveredIcon";
import LabelSmall from "../../../../components/simple/TextComponents/LabelSmall/LabelSmall";
import Heading4 from "../../../../components/simple/TextComponents/Heading4/Heading4";
import MainMenuItem from "../../../../components/simple/Containers/MainMenuItem/MainMenuItem";
import NHeading3 from "../../../../components/simple/TextComponents/NHeading3/NHeading3";
import Text1 from "../../../../components/simple/TextComponents/Text1/Text1";
import NInput from "../../../../components/simple/FormComponents/NInput/NInput";
import {NInputDefProps} from "../../../../components/simple/FormComponents/NInput/defProps";
import DocRowContainer from "../../../../components/simple/Containers/DocRowContainer/DocRowContainer";
import {DocRowContainerDefProps} from "../../../../components/simple/Containers/DocRowContainer/defProps";
import NFormGroup from "../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import {NFormGroupDefProps} from "../../../../components/simple/FormComponents/NFormGroup/defProps";
import NLabel from "../../../../components/simple/FormComponents/NLabel/NLabel";
import ContractCard from "../../../Contracts/contracts/ContractCard/ContractCard";
import {ContractCardDefProps} from "../../../Contracts/contracts/ContractCard/defProps";
import TagLabel from "../../../../components/simple/TextComponents/TagLabel/TagLabel";
import StatusLabel from "../../../../components/simple/TextComponents/StatusLabel/StatusLabel";
import CardBtn from "../../../../components/simple/Buttons/CardBtn/CardBtn";
import TileIcon from "../../../../components/simple/Containers/TileIcon/TileIcon";
import {TileIconDefProps} from "../../../../components/simple/Containers/TileIcon/defProps";
import NSwitcher from "../../../../components/simple/FormComponents/NSwitcher/NSwitcher";
import {NSwitcherDefProps} from "../../../../components/simple/FormComponents/NSwitcher/defProps";
import ParamFormGroup from "../../../../components/simple/FormComponents/ParamFormGroup/ParamFormGroup";
import {ParamFormGroupDefProps} from "../../../../components/simple/FormComponents/ParamFormGroup/defProps";
import Checkbox from "../../../../components/simple/FormComponents/Checkbox/Checkbox";
import CheckboxInput from "../../../../components/simple/FormComponents/CheckboxInput/CheckboxInput";
import {CheckboxInputDefProps} from "../../../../components/simple/FormComponents/CheckboxInput/defProps";
import CheckListContainer from "../../../../components/simple/FormComponents/CheckListContainer/CheckListContainer";
import {CheckListContainerDefProps} from "../../../../components/simple/FormComponents/CheckListContainer/defProps";
import BtnDel from "../../../../components/simple/Buttons/BtnDel/BtnDel";
import BtnCloseModal from "../../../../components/simple/Buttons/BtnCloseModal/BtnCloseModal";
import NModal from "../../../../components/simple/Modals/NModal/NModal";
import {NModalDefProps} from "../../../../components/simple/Modals/NModal/defProps";
import DragAndDrop from "../../../../components/composed/DragAndDrop/DragAndDrop";
import {DragAndDropDefProps} from "../../../../components/composed/DragAndDrop/defProps";
import AgentCard from "../../../Agents/agents/AgentCard/AgentCard";
import {AgentCardDefProps} from "../../../Agents/agents/AgentCard/defProps";
import DataListContainer from "../../../../components/simple/Containers/DataListContainer/DataListContainer";
import {DataListContainerDefProps} from "../../../../components/simple/Containers/DataListContainer/defProps";
import NDropDownDetails from "../../../../components/simple/Containers/NDropDownDetails/NDropDownDetails";
import {NDropDownDetailsDefProps} from "../../../../components/simple/Containers/NDropDownDetails/defProps";
import DropDown from "../../../../components/simple/Containers/DropDown/DropDown";
import {DropDownDefProps} from "../../../../components/simple/Containers/DropDown/defProps";
import NSelect from "../../../../components/simple/FormComponents/NSelect/NSelect";
import {NSelectDefProps} from "../../../../components/simple/FormComponents/NSelect/defProps";
import SectionHeader from "../../../../components/composed/SectionHeader/SectionHeader";
import {SectionHeaderDefProps} from "../../../../components/composed/SectionHeader/defProps";
import PropValueView from "../../../../components/composed/PropValueView/PropValueView";
import {PropValueViewDefProps} from "../../../../components/composed/PropValueView/defProps";
import FormDateRangePicker from "../../../../components/simple/FormComponents/FormDateRangePicker/FormDateRangePicker";
import {FormDateRangePickerDefProps} from "../../../../components/simple/FormComponents/FormDateRangePicker/defProps";
import FormSingleDatePicker
  from "../../../../components/simple/FormComponents/FormSingleDatePicker/FormSingleDatePicker";
import {FormSingleDatePickerDefProps} from "../../../../components/simple/FormComponents/FormSingleDatePicker/defProps";
import GroupFinTermCardLabel from "../../../../components/simple/TextComponents/GroupFinTermCardLabel/GroupFinTermCardLabel";
import GroupFinTermCardInfoRow from "../../../../components/composed/GroupFinTermCardInfoRow/GroupFinTermCardInfoRow";
import {GroupFinTermCardInfoRowDefProps} from "../../../../components/composed/GroupFinTermCardInfoRow/defProps";
import Textarea from "../../../../components/simple/FormComponents/Textarea/Textarea";
import {TextareaDefProps} from "../../../../components/simple/FormComponents/Textarea/defProps";
import GroupFinTermCard from "../../../Contracts/pages/Contract/pages/GroupFinTermsView/groupFinTermsView/GroupFinTermCard/GroupFinTermCard";
import {GroupFinTermCardDefProps} from "../../../Contracts/pages/Contract/pages/GroupFinTermsView/groupFinTermsView/GroupFinTermCard/defProps";
import EmptyList from "../../../../components/simple/Containers/EmptyList/EmptyList";
import {EmptyListDefProps} from "../../../../components/simple/Containers/EmptyList/defProps";
import SelectFullCard from "../../../../components/simple/FormComponents/SelectFullCard/SelectFullCard";
import {SelectFullCardDefProps} from "../../../../components/simple/FormComponents/SelectFullCard/defProps";
import FullSizeButton from "../../../../components/simple/Buttons/FullSizeButton/FullSizeButton";
import BtnAddRow from "../../../../components/simple/Buttons/BtnAddRow/BtnAddRow";
import QuestionTooltip from "../../../../components/simple/Containers/QuestionTooltip/QuestionTooltip";
import TextActionButton from "../../../../components/simple/Buttons/TextActionButton/TextActionButton";
import GroupFinTermBlock from "../../../../components/composed/GroupFinTermBlock/GroupFinTermBlock";
import {GroupFinTermBlockDefProps} from "../../../../components/composed/GroupFinTermBlock/defProps";
import ButtonTooltip from "../../../../components/simple/Containers/ButtonTooltipContainer/ButtonTooltip/ButtonTooltip";
import ButtonTooltipContainer
  from "../../../../components/simple/Containers/ButtonTooltipContainer/ButtonTooltipContainer";
import {ButtonTooltipContainerDefProps} from "../../../../components/simple/Containers/ButtonTooltipContainer/defProps";
import TextOverflowHandler from "../../../../components/simple/Containers/TextOverflowHandler/TextOverflowHandler";
import Text2 from "../../../../components/simple/TextComponents/Text2/Text2";
import InputFile from "../../../../components/simple/FormComponents/InputFile/InputFile";
import {InputFileDefProps} from "../../../../components/simple/FormComponents/InputFile/defProps";
import SectionDivider from "../../../../components/simple/Containers/SectionDivider/SectionDivider";
import Heading5 from "../../../../components/simple/TextComponents/Heading5/Heading5";
import BtnBack from "../../../../components/simple/Buttons/BtnBack/BtnBack";
import DropDownSelect from "../../../../components/simple/Containers/DropDownSelect/DropDownSelect";
import {DropDownSelectDefProps} from "../../../../components/simple/Containers/DropDownSelect/defProps";
import Breadcrumbs from "../../../../components/simple/Containers/Breadcrumbs/Breadcrumbs";
import {BreadcrumbsDefProps} from "../../../../components/simple/Containers/Breadcrumbs/defProps";
import TagWithClickToCopy from "../../../../components/simple/TextComponents/TagWithClickToCopy/TagWithClickToCopy";
import FormTagSelect from "../../../../components/simple/FormComponents/FormTagSelect/FormTagSelect";
import {FormTagSelectDefProps} from "../../../../components/simple/FormComponents/FormTagSelect/defProps";
import BtnEdit from "../../../../components/simple/Buttons/BtnEdit/BtnEdit";
import BtnUpload from "../../../../components/simple/Buttons/BtnUpload/BtnUpload";
import ConvertTagBtn from "../../../../components/simple/Buttons/ConvertTagBtn/ConvertTagBtn";
import EditConvertedTagBtn from "../../../../components/simple/Buttons/EditConvertedTagBtn/EditConvertedTagBtn";
import AlertCard from "../../../../components/simple/Containers/AlertCard/AlertCard";
import {AlertCardDefProps} from "../../../../components/simple/Containers/AlertCard/defProps";
import HistoryCard from "../../../../components/simple/Containers/HistoryCard/HistoryCard";
import {HistoryCardDefProps} from "../../../../components/simple/Containers/HistoryCard/defProps";
import FilterFlags from "../../../../components/simple/FormComponents/FilterFlags/FilterFlags";
import {FilterFlagsDefProps} from "../../../../components/simple/FormComponents/FilterFlags/defProps";
import FilterSelect from "../../../../components/simple/FormComponents/FilterSelect/FilterSelect";
import {FilterSelectDefProps} from "../../../../components/simple/FormComponents/FilterSelect/defProps";
import AddFilterBtn from "../../../../components/simple/Buttons/AddFilterBtn/AddFilterBtn";
import DelFilterBtn from "../../../../components/simple/Buttons/DelFilterBtn/DelFilterBtn";
import SmallTextBtn from "../../../../components/simple/Buttons/SmallTextBtn/SmallTextBtn";
import LinkToHistory from "../../../../components/simple/Buttons/LinkToHistory/LinkToHistory";
import BtnSearchIco from "../../../../components/simple/Buttons/BtnSearchIco/BtnSearchIco";
import SearchCard from "../../../../components/simple/Containers/SearchCard/SearchCard";
import {SearchCardDefProps} from "../../../../components/simple/Containers/SearchCard/defProps";
import {MainMenuItemDefProps} from "../../../../components/simple/Containers/MainMenuItem/defProps";
import {BorderlessSelectSelectDefProps} from "../../../../components/simple/FormComponents/BorderlessSelect/defProps";
import BorderlessSelect from "../../../../components/simple/FormComponents/BorderlessSelect/BorderlessSelect";
import MainHeaderBtn from "../../../../components/simple/Buttons/MainHeaderBtn/MainHeaderBtn";
import FilterInput from "../../../../components/simple/FormComponents/FilterInput/FilterInput";
import {FilterInputDefProps} from "../../../../components/simple/FormComponents/FilterInput/defProps";
import Text3 from "../../../../components/simple/TextComponents/Text3/Text3";
import Text4 from "../../../../components/simple/TextComponents/Text4/Text4";
import TabSteps from "../../../../components/simple/Containers/TabSteps/TabSteps";
import {TabStepsDefProps} from "../../../../components/simple/Containers/TabSteps/defProps";
import BtnIgnore from "../../../../components/simple/Buttons/BtnIgnore/BtnIgnore";
import DisabledContentContainer
  from "../../../../components/simple/Containers/DisabledContentContainer/DisabledContentContainer";
import {DisabledContentContainerDefProps} from "../../../../components/simple/Containers/DisabledContentContainer/defProps";
import CustomDropDownSelect from "../../../../components/simple/Containers/CustomDropDownSelect/CustomDropDownSelect";
import {CustomDropDownSelectDefProps} from "../../../../components/simple/Containers/CustomDropDownSelect/defProps";
import CustomDropDownContainer
  from "../../../../components/simple/Containers/CustomDropDownContainer/CustomDropDownContainer";
import {CustomDropDownContainerDefProps} from "../../../../components/simple/Containers/CustomDropDownContainer/defProps";
import DropMenuComponents from "../../../../components/simple/Containers/DropMenuComponents/DropMenuComponents";
import {DropMenuComponentsDefProps} from "../../../../components/simple/Containers/DropMenuComponents/defProps";
import CheckboxSelect from "../../../../components/simple/FormComponents/CheckboxSelect/CheckboxSelect";
import {CheckboxSelectDefProps} from "../../../../components/simple/FormComponents/CheckboxSelect/defProps";
import WrappedDateRangePicker
  from "../../../../components/simple/FormComponents/WrappedDateRangePicker/WrappedDateRangePicker";
import {WrappedDateRangePickerDefProps} from "../../../../components/simple/FormComponents/WrappedDateRangePicker/defProps";
import InfoPanelLabel from "../../../../components/simple/TextComponents/InfoPanelLabel/InfoPanelLabel";
import IconlessSelect from "../../../../components/simple/FormComponents/IconlessSelect/IconlessSelect";
import {IconlessSelectDefProps} from "../../../../components/simple/FormComponents/IconlessSelect/defProps";
import ConfirmActionModal from "../../../../components/simple/Modals/ConfirmActionModal/ConfirmActionModal";
import {ConfirmActionModalDefProps} from "../../../../components/simple/Modals/ConfirmActionModal/defProps";
import TurnOnOffBtn from "../../../../components/simple/Buttons/TurnOnOffBtn/TurnOnOffBtn";
import ContentPlaceholder from "../../../../components/simple/Containers/ContentPlaceholder/ContentPlaceholder";
import ModalDeleteBtn from "../../../../components/simple/Buttons/ModalDeleteBtn/ModalDeleteBtn";
import WarningContainer from "../../../../components/simple/Containers/WarningContainer/WarningContainer";
import {WarningContainerDefProps} from "../../../../components/simple/Containers/WarningContainer/defProps";
import AdaptiveFormContainer
  from "../../../../components/simple/Containers/AdaptiveFormContainer/AdaptiveFormContainer";
import {AdaptiveFormContainerDefProps} from "../../../../components/simple/Containers/AdaptiveFormContainer/defProps";
import RowMenu from "../../../../components/simple/Containers/RowMenu/RowMenu";
import {RowMenuDefProps} from "../../../../components/simple/Containers/RowMenu/defProps";

const uiKitData = [
  {
    title: 'Text Components',
    group: [
      {
        component: NHeading1,
        title: 'NHeading1'
      },
      {
        component: NHeading2,
        title: 'NHeading2'
      },
      {
        component: NHeading3,
        title: 'NHeading3'
      },
      {
        component: Heading4,
        title: 'Heading4'
      },
      {
        component: Heading5,
        title: 'Heading5'
      },
      {
        component: SecText,
        title: 'SecText'
      },
      {
        component: LabelSmall,
        title: 'LabelSmall'
      },
      {
        component: Text1,
        title: 'Text1'
      },
      {
        component: Text2,
        title: 'Text2'
      },
      {
        component: Text3,
        title: 'Text3'
      },
      {
        component: Text4,
        title: 'Text4'
      },
      {
        component: TagLabel,
        title: 'TagLabel'
      },
      {
        component: StatusLabel,
        title: 'StatusLabel'
      },
      {
        component: GroupFinTermCardLabel,
        title: 'GroupFinTermCardLabel'
      },
      {
        component: TagWithClickToCopy,
        title: 'TagWithClickToCopy'
      },
      {
        component: InfoPanelLabel,
        title: 'InfoPanelLabel'
      }
    ]
  },
  {
    title: 'Form Components',
    group: [
      {
        component: NLabel,
        title: 'NLabel'
      },
      {
        component: NInput,
        title: 'NInput',
        hasChildren: false,
        defProps: NInputDefProps
      },
      {
        component: Textarea,
        title: 'Textarea',
        hasChildren: false,
        defProps: TextareaDefProps
      },
      {
        component: NSelect,
        title: 'NSelect',
        hasChildren: false,
        defProps: NSelectDefProps
      },
      {
        component: IconlessSelect,
        title: 'IconlessSelect',
        hasChildren: false,
        defProps: IconlessSelectDefProps
      },
      {
        component: SelectFullCard,
        title: 'SelectFullCard',
        hasChildren: false,
        defProps: SelectFullCardDefProps,
        hideBackCard: true
      },
      {
        component: FormTagSelect,
        title: 'FormTagSelect',
        hasChildren: false,
        defProps: FormTagSelectDefProps
      },
      {
        component: FormDateRangePicker,
        title: 'FormDateRangePicker',
        hasChildren: false,
        defProps: FormDateRangePickerDefProps
      },
      {
        component: FormSingleDatePicker,
        title: 'FormSingleDatePicker',
        hasChildren: false,
        defProps: FormSingleDatePickerDefProps
      },
      {
        component: WrappedDateRangePicker,
        title: 'WrappedDateRangePicker',
        hasChildren: false,
        defProps: WrappedDateRangePickerDefProps
      },
      {
        component: NFormGroup,
        title: 'NFormGroup',
        defProps: NFormGroupDefProps
      },
      {
        component: ParamFormGroup,
        title: 'ParamFormGroup',
        defProps: ParamFormGroupDefProps
      },
      {
        component: NSwitcher,
        title: 'NSwitcher',
        hasChildren: false,
        defProps: NSwitcherDefProps
      },
      {
        component: Checkbox,
        title: 'Checkbox',
        hasChildren: false
      },
      {
        component: CheckboxInput,
        title: 'CheckboxInput',
        hasChildren: false,
        defProps: CheckboxInputDefProps
      },
      {
        component: CheckListContainer,
        title: 'CheckListContainer',
        hasChildren: false,
        defProps: CheckListContainerDefProps
      },
      {
        component: CheckboxSelect,
        title: 'CheckboxSelect',
        hasChildren: false,
        defProps: CheckboxSelectDefProps
      },
      {
        component: InputFile,
        title: 'InputFile',
        hasChildren: false,
        defProps: InputFileDefProps
      },
      {
        component: FilterFlags,
        title: 'FilterFlags',
        hasChildren: false,
        defProps: FilterFlagsDefProps
      },
      {
        component: FilterSelect,
        title: 'FilterSelect',
        hasChildren: false,
        defProps: FilterSelectDefProps
      },
      {
        component: BorderlessSelect,
        title: 'BorderlessSelect',
        hasChildren: false,
        defProps: BorderlessSelectSelectDefProps
      },
      {
        component: FilterInput,
        title: 'FilterInput',
        hasChildren: false,
        defProps: FilterInputDefProps
      }
    ]
  },
  {
    title: 'Buttons',
    group: [
      {
        component: BtnNewItem,
        title: 'BtnNewItem'
      },
      {
        component: BtnEditAct,
        title: 'BtnEditAct'
      },
      {
        component: BtnLogout,
        title: 'BtnLogout',
        hasChildren: false
      },
      {
        component: CardBtn,
        title: 'CardBtn'
      },
      {
        component: BtnDel,
        title: 'BtnDel',
        hasChildren: false
      },
      {
        component: BtnEdit,
        title: 'BtnEdit',
        hasChildren: false
      },
      {
        component: BtnCloseModal,
        title: 'BtnCloseModal',
        hasChildren: false
      },
      {
        component: FullSizeButton,
        title: 'FullSizeButton',
        hideBackCard: true
      },
      {
        component: BtnAddRow,
        title: 'BtnAddRow'
      },
      {
        component: TextActionButton,
        title: 'TextActionButton'
      },
      {
        component: BtnBack,
        title: 'BtnBack',
        hasChildren: false
      },
      {
        component: BtnUpload,
        title: 'BtnUpload',
        hasChildren: false
      },
      {
        component: ConvertTagBtn,
        title: 'ConvertTagBtn',
        hasChildren: false
      },
      {
        component: EditConvertedTagBtn,
        title: 'EditConvertedTagBtn',
        hasChildren: false
      },
      {
        component: AddFilterBtn,
        title: 'AddFilterBtn',
        hasChildren: false
      },
      {
        component: DelFilterBtn,
        title: 'DelFilterBtn',
        hasChildren: false
      },
      {
        component: SmallTextBtn,
        title: 'SmallTextBtn'
      },
      {
        component: LinkToHistory,
        title: 'LinkToHistory',
        hasChildren: false
      },
      {
        component: BtnSearchIco,
        title: 'BtnSearchIco',
        hasChildren: false
      },
      {
        component: MainHeaderBtn,
        title: 'MainHeaderBtn'
      },
      {
        component: BtnIgnore,
        title: 'BtnIgnore',
        hasChildren: false
      },
      {
        component: TurnOnOffBtn,
        title: 'TurnOnOffBtn',
        hasChildren: false
      },
      {
        component: ModalDeleteBtn,
        title: 'ModalDeleteBtn'
      }
    ]
  },
  {
    title: 'Containers',
    group: [
      {
        component: NCard,
        title: 'NCard',
        hideBackCard: true
      },
      {
        component: MainMenuItem,
        title: 'MainMenuItem',
        hasChildren: false,
        defProps: MainMenuItemDefProps
      },
      {
        component: DocRowContainer,
        title: 'DocRowContainer',
        hasChildren: false,
        defProps: DocRowContainerDefProps
      },
      {
        component: NModal,
        title: 'NModal',
        hasChildren: false,
        defProps: NModalDefProps
      },
      {
        component: ConfirmActionModal,
        title: 'ConfirmActionModal',
        hasChildren: false,
        defProps: ConfirmActionModalDefProps
      },
      {
        component: TileIcon,
        title: 'TileIcon',
        defProps: TileIconDefProps
      },
      {
        component: DataListContainer,
        title: 'DataListContainer',
        hasChildren: false,
        defProps: DataListContainerDefProps
      },
      {
        component: DropDown,
        title: 'DropDown',
        hasChildren: false,
        defProps: DropDownDefProps
      },
      {
        component: NDropDownDetails,
        title: 'NDropDownDetails',
        hasChildren: false,
        defProps: NDropDownDetailsDefProps
      },
      {
        component: EmptyList,
        title: 'EmptyList',
        hasChildren: false,
        defProps: EmptyListDefProps,
        hideBackCard: true
      },
      {
        component: QuestionTooltip,
        title: 'QuestionTooltip'
      },
      {
        component: ButtonTooltip,
        title: 'ButtonTooltip'
      },
      {
        component: ButtonTooltipContainer,
        title: 'ButtonTooltipContainer',
        hasChildren: false,
        defProps: ButtonTooltipContainerDefProps
      },
      {
        component: TextOverflowHandler,
        title: 'TextOverflowHandler'
      },
      {
        component: SectionDivider,
        title: 'SectionDivider',
        hasChildren: false
      },
      {
        component: DropDownSelect,
        title: 'DropDownSelect',
        hasChildren: false,
        defProps: DropDownSelectDefProps
      },
      {
        component: CustomDropDownSelect,
        title: 'CustomDropDownSelect',
        hasChildren: false,
        defProps: CustomDropDownSelectDefProps
      },
      {
        component: CustomDropDownContainer,
        title: 'CustomDropDownContainer',
        defProps: CustomDropDownContainerDefProps
      },
      {
        component: DropMenuComponents,
        title: 'DropMenuComponents',
        hasChildren: false,
        defProps: DropMenuComponentsDefProps
      },
      {
        component: Breadcrumbs,
        title: 'Breadcrumbs',
        hasChildren: false,
        defProps: BreadcrumbsDefProps
      },
      {
        component: AlertCard,
        title: 'AlertCard',
        hasChildren: false,
        defProps: AlertCardDefProps
      },
      {
        component: HistoryCard,
        title: 'HistoryCard',
        hasChildren: false,
        defProps: HistoryCardDefProps,
        hideBackCard: true
      },
      {
        component: SearchCard,
        title: 'SearchCard',
        hasChildren: false,
        defProps: SearchCardDefProps
      },
      {
        component: TabSteps,
        title: 'TabSteps',
        hasChildren: false,
        defProps: TabStepsDefProps
      },
      {
        component: DisabledContentContainer,
        title: 'DisabledContentContainer',
        defProps: DisabledContentContainerDefProps
      },
      {
        component: ContentPlaceholder,
        title: 'ContentPlaceholder',
        hasChildren: false
      },
      {
        component: WarningContainer,
        title: 'WarningContainer',
        defProps: WarningContainerDefProps
      },
      {
        component: AdaptiveFormContainer,
        title: 'AdaptiveFormContainer',
        hasChildren: false,
        defProps: AdaptiveFormContainerDefProps
      },
      {
        component: RowMenu,
        title: 'RowMenu',
        hasChildren: false,
        defProps: RowMenuDefProps
      }
    ]
  },
  {
    title: 'Composed',
    group: [
      {
        component: HeaderContent,
        title: 'HeaderContent',
        hasChildren: false,
        defProps: HeaderContentDefProps,
        hideBackCard: true
      },
      {
        component: SectionHeader,
        title: 'SectionHeader',
        hasChildren: false,
        defProps: SectionHeaderDefProps
      },
      {
        component: HoveredIcon,
        title: 'HoveredIcon'
      },
      {
        component: ContractCard,
        title: 'ContractCard',
        hasChildren: false,
        defProps: ContractCardDefProps,
        hideBackCard: true
      },
      {
        component: AgentCard,
        title: 'AgentCard',
        hasChildren: false,
        defProps: AgentCardDefProps,
        hideBackCard: true
      },
      {
        component: DragAndDrop,
        title: 'DragAndDrop',
        hasChildren: false,
        defProps: DragAndDropDefProps
      },
      {
        component: PropValueView,
        title: 'PropValueView',
        hasChildren: false,
        defProps: PropValueViewDefProps
      },
      {
        component: GroupFinTermCardInfoRow,
        title: 'GroupFinTermCardInfoRow',
        hasChildren: false,
        defProps: GroupFinTermCardInfoRowDefProps
      },
      {
        component: GroupFinTermBlock,
        title: 'GroupFinTermBlock',
        hasChildren: false,
        defProps: GroupFinTermBlockDefProps
      },
      {
        component: GroupFinTermCard,
        title: 'GroupFinTermCard',
        hasChildren: false,
        defProps: GroupFinTermCardDefProps,
        hideBackCard: true
      },
    ]
  },
  {
    title: 'UiKitComponents',
    group: [
      {
        component: ImportComponentWithClickToCopy,
        title: 'ImportComponentWithClickToCopy'
      },
      {
        component: Pre,
        title: 'Pre'
      }
    ]
  }
];

export default uiKitData;