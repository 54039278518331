//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {
  formFieldName, formFieldValue,
  id, inputRefType, inputType, onBlur,
  onChange, onFocus, onKeyDown,
  placeholder, readOnly
} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './NInput.css';
import type {inputMaxLength} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  placeholder?: placeholder,
  value: formFieldValue,
  name?: formFieldName,
  onChange?: onChange,
  onFocus?: onFocus,
  onBlur?: onBlur,
  inputMaxLength?: inputMaxLength,
  readOnly?: readOnly,
  inputType?: inputType,
  onKeyDown?: onKeyDown,
  dataUiTestId?: string
};

const NInput = React.forwardRef<Props, HTMLInputElement>((props:Props, ref:inputRefType) => {
  const inputType = props.inputType ? props.inputType : 'text';
  return (
    <input id={props.id} placeholder={props.placeholder} value={props.value} name={props.name}
           type={inputType} ref={ref} onKeyDown={props.onKeyDown}
           onChange={props.onChange} onFocus={props.onFocus} onBlur={props.onBlur}
           className={baseClass("NInput", props.addClasses)} maxLength={props.inputMaxLength}
           readOnly={props.readOnly} data-ui-test-id={props.dataUiTestId}/>
  );
});

export default NInput;