//@flow
import * as React from 'react';
import {connect} from "react-redux";
import contractAgentsActions from '../../../../../../store/organization/item/contractAgents/contractAgentsActions';
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {history, isReadyToRender} from "../../../../../../services/flowTypes/appFlowTypes";
import api from "../../../../../../services/apiInstance/api";
import ConnectedContractAgentsList from "./contractAgents/ConnectedContractAgentsList";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import type {errorObject, match, locationType} from "../../../../../../services/flowTypes/appFlowTypes";
import {onGetContractAgents, onInitContractAgents} from "../../../../../../services/flowTypes/propFnFlowTypes";
import GetDataOnMountAndUpdate
  from "../../../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import SearchPageFilters from "../../../../../../components/composed/SearchPage/SearchBox/SearchPageFilters";
import type {
  onGetLinkedItemsRequestParamsProp,
  onInitLinkedItemsRequestParamsState
} from "../../../../../../services/flowTypes/propFnFlowTypes";
import type {agentStatus, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import linkedItemsRequestParamsActions
  from "../../../../../../store/organization/linkedItemsRequestParams/linkedItemsRequestParamsActions";
import handleCorrectOrgIdInResponse from "../../../../../../utils/handleCorrectOrgIdInResponse";
import RedirectToCorrectOrgUrl
  from "../../../../../../components/service/RedirectToCorrectOrgUrl/RedirectToCorrectOrgUrl";

type Props = {
  match: match,
  location: locationType,
  onGetContractAgents: onGetContractAgents,
  onInitContractAgents: onInitContractAgents,
  onGetLinkedItemsRequestParamsProp: onGetLinkedItemsRequestParamsProp,
  onInitLinkedItemsRequestParamsState: onInitLinkedItemsRequestParamsState,
  status: agentStatus,
  history: history
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  correctOrganizationId: organizationId
};

class ContractAgents extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      correctOrganizationId: null
    };
  }
  handleResponse = (responseData) => {
    this.props.onGetContractAgents(responseData);
    this.setState({isReadyToRender: true});
  };
  getContractsAgents = () => {
    this.props.onInitContractAgents();
    const contractId = +this.props.match.params.id;
    api.getContractAgents(contractId, this.props.status)
      .then(response => {
        handleCorrectOrgIdInResponse(this, response.data, this.setState, this.handleResponse);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  componentDidUpdate(prevProps: Props) {
    if (this.props.status !== prevProps.status && prevProps.status !== null && this.props.status !== null) {
      this.getContractsAgents();
    }
  }
  componentDidMount() {
    this.props.onInitLinkedItemsRequestParamsState();
    const searchRequestObj = {
      category: 'agents'
    };
    this.props.onGetLinkedItemsRequestParamsProp(searchRequestObj);
    this.setState({isReadyToRender: true});
  }
  render() {
    const contractId = this.props.match.params.id;
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
        <SearchPageFilters isLinkedItemsMode={true} addClasses={"Filters_sticky"}/>
        {this.props.status !== null ? (
          <RedirectToCorrectOrgUrl correctOrganizationId={this.state.correctOrganizationId} history={this.props.history}>
            <GetDataOnMountAndUpdate itemId={contractId} getDataOnMountAndUpdate={this.getContractsAgents}>
              <ConnectedContractAgentsList contractId={contractId} status={this.props.status}/>
            </GetDataOnMountAndUpdate>
          </RedirectToCorrectOrgUrl>
        ) : null}
      </RenderOnReady>
    );
  }
}

const mapStateToProps = state => {
  return {
    status: state.organization.linkedItemsRequestParams.hasOwnProperty('status') ?
      state.organization.linkedItemsRequestParams.status : null,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetContractAgents: (contractAgents) => dispatch({type: contractAgentsActions.CONTRACT_AGENTS_SET, value: contractAgents}),
    onInitContractAgents: () => dispatch({type: contractAgentsActions.CONTRACT_AGENTS_INIT}),
    onGetLinkedItemsRequestParamsProp: (searchRequestObj) => dispatch({type: linkedItemsRequestParamsActions.LINKED_ITEMS_REQUEST_PARAMS_PROP_SET,
      value: searchRequestObj}),
    onInitLinkedItemsRequestParamsState: () => dispatch({type: linkedItemsRequestParamsActions.LINKED_ITEMS_REQUEST_PARAMS_STATE_INIT})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractAgents);