//@flow
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {user} from "../../../services/flowTypes/dataFlowTypes";
import userMainDataActions from "./userMainDataActions";
import update from 'immutability-helper';


type State = user;
type reducerAction = action;
export const initialState = {
  name: '',
  email: '',
  phone: '',
  globalPermissions: [],
  userPermissions: []
};

const userMainDataReducer = (state:State = initialState, action:reducerAction) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case userMainDataActions.USER_MAIN_DATA_SET:
      return action.value;
    case userMainDataActions.USER_MAIN_DATA_INIT:
      return initialState;
    case userMainDataActions.USER_MAIN_DATA_PROP_SET:
      newState[action.value.prop] = action.value.data;
      return newState;
    case userMainDataActions.USER_PERMISSIONS_ADD:
      return update(newState, {userPermissions: {$push: action.value}});
    case userMainDataActions.USER_PERMISSIONS_DELETE:
      return update(newState, {userPermissions: {$splice: [[action.value, 1]]}});
    case userMainDataActions.USER_PERMISSION_UPDATE:
      return update(newState, {userPermissions: {[action.value.index]: {$set:  action.value.orgPermission}}});
    default:
      return state;
  }
};

export default userMainDataReducer;