//@flow
import * as React from 'react';
import text from "../../services/localization/text";
import UsersSearchBox from "./users/UsersSearchBox/UsersSearchBox";
import UsersSearchResults from "./users/UsersSearchResults/UsersSearchResults";
import UsersPagination from "./users/UsersPagination/UsersPagination";
import UsersTotal from "./users/UsersTotal";
import Can from "../../services/permissions/Can";
import {Link} from "react-router-dom";
import {newUserSuffix, supUsersPath} from "../../services/urlStrings/urlStrings";
import {usersPageIds} from "../../tests/testIds";
import connect from "react-redux/es/connect/connect";
import usersSearchActions from "../../store/users/usersSearch/usersSearchActions";
import {onInitUsersSearch} from "../../services/flowTypes/propFnFlowTypes";
import FixedHeaderContent from "../../components/simple/Containers/FixedHeader/FixedHeaderContent";
import {globalPermissionsStrValues} from "../../configData/permissionsStrValues";
import BtnNewItem from "../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  onInitUsersSearch: onInitUsersSearch
};

class Users extends React.Component <Props> {
  componentDidMount(){
    this.props.onInitUsersSearch();
  }
  componentWillUnmount(): * {
    this.props.onInitUsersSearch();
  }
  render(){
    const button = (
      <Can do={'useGlobalPermission'} on={globalPermissionsStrValues.user.create} key={'newUser'}>
        <Link to={supUsersPath + newUserSuffix}>
          <BtnNewItem addClasses={"BtnNewItem_grey"} id={usersPageIds.buttonNewUser}>{text.btnAdd}</BtnNewItem>
        </Link>
      </Can>
    );
    const headerTitle = <>{text.users + " - "}<UsersTotal/></>
    return (
      <div className="Users">
        <FixedHeaderContent title={headerTitle} buttons={[button]} isSupLevel={true}/>
        <UsersSearchBox/>
        <UsersSearchResults/>
        <UsersPagination/>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onInitUsersSearch: () => dispatch({type: usersSearchActions.USERS_SEARCH_INIT_STATE_SET})
  };
};
export default connect(null, mapDispatchToProps)(Users);