//@flow
import * as React from 'react';
import {connect} from "react-redux";
import NCard from "../../../../../../../../components/simple/Containers/NCard/NCard";
import {Link} from "react-router-dom";
import concatIdName from "../../../../../../../../utils/concatIdName";
import BtnDel from "../../../../../../../../components/simple/Buttons/BtnDel/BtnDel";
import {onBtnDelClick} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import type {
  actTemplate, addServicesList,
  contractId,
  groupFinTerm,
  organizationId, usersList
} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../../../services/localization/text";
import SecText from "../../../../../../../../components/simple/TextComponents/SecText/SecText";
import Text1 from "../../../../../../../../components/simple/TextComponents/Text1/Text1";
import formatDate from "../../../../../../../../utils/formatDate";
import createTempLinkToDownloadFile from "../../../../../../../../utils/createTempLinkToDownloadFile";
import apiErrorHandler from "../../../../../../../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {errorObject, history, isReadyToRender} from "../../../../../../../../services/flowTypes/appFlowTypes";
import api from "../../../../../../../../services/apiInstance/api";
import Can from "../../../../../../../../services/permissions/Can";
import ability from "../../../../../../../../services/permissions/permissions";
import type {actTemplateIndex} from "../../../../../../../../services/flowTypes/componentFlowTypes";
import {actTemplatesViewPageIds} from "../../../../../../../../tests/testIds";
import getNameOrDefName from "../../../../../../../../utils/getNameOrDefName";
import NHeading3 from "../../../../../../../../components/simple/TextComponents/NHeading3/NHeading3";
import {
  actTemplatePath,
  contrPathView, historySearchSuffix, historySuffix,
  orgPath
} from "../../../../../../../../services/urlStrings/urlStrings";
import TextOverflowHandler
  from "../../../../../../../../components/simple/Containers/TextOverflowHandler/TextOverflowHandler";
import ConnectedGroupFinTermBlock
  from "../../../../../../../../components/composed/ConnectedGroupFinTermBlock/ConnectedGroupFinTermBlock";
import LinkToHistory from "../../../../../../../../components/simple/Buttons/LinkToHistory/LinkToHistory";
import GenerateReportButton from "../GenerateReportModal/GenerateReportButton";
import VerifiedStatusLabel from "../../../../../../../../components/composed/VerifiedStatusLabel/VerifiedStatusLabel";
import {permissionsStrValues} from "../../../../../../../../configData/permissionsStrValues";
import Heading4 from "../../../../../../../../components/simple/TextComponents/Heading4/Heading4";
import AddServicesCard from "../../../AddServices/addServices/AddServicesCard";
import DropDown from "../../../../../../../../components/simple/Containers/DropDown/DropDown";
import AlertPaynetSync from "../../../../../../../../components/simple/Containers/AlertCard/AlertPaynetSync";
import "./ActTemplateCard.css";
import AlertUnverifiedAddAgreements
  from "../../../../../../../../components/simple/Containers/AlertCard/AlertUnverifiedAddAgreements";

type Props = {
  onBtnDelClick: onBtnDelClick,
  actTemplate: actTemplate,
  groupFinTermsView: Array<groupFinTerm>,
  organizationId: organizationId,
  contractId: contractId,
  actTemplateIndex: actTemplateIndex,
  history: history,
  addServices: addServicesList,
  usersList: usersList
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};
class ActTemplateCard extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null
    };
  }
  getDocument = () => {
    this.setState({isReadyToRender: false});
    api.getActTemplateDocument(this.props.actTemplate.id)
      .then(response => {
        this.setState({isReadyToRender: true});
        createTempLinkToDownloadFile(response.data, this.props.actTemplate.fileName);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    const linkTo = orgPath(this.props.organizationId) + contrPathView(this.props.contractId)
      + actTemplatePath(this.props.actTemplate.id);
    const linkToHistory = orgPath(this.props.organizationId) + historySuffix + historySearchSuffix
      + "&actTemplateId=" + this.props.actTemplate.id;
    const actTemplateName = getNameOrDefName(this.props.actTemplate.name, text.actTemplate);
    const name = concatIdName(this.props.actTemplate.id, actTemplateName);
    const groupFinTerms = this.props.actTemplate.finTermGroupIds.map((groupFinTermId, i) => {
      const groupFinTermObj = this.props.groupFinTermsView.find(item => item.groupId === groupFinTermId);
      return (
        <ConnectedGroupFinTermBlock
          key={'groupFinTermBlock'+i}
          groupFinTerm={groupFinTermObj}
          addClasses={'GroupFinTermBlock_with-back mb_1du'}
        />
      );
    });
    const lastRowClassName = this.props.actTemplate.addendumTemplateFileName ? "ActTemplateCard__files mt_1du" : "ActTemplateCard__files";
    const addServices = this.props.actTemplate.addServiceIds.map((addServiceId, i) => {
       const addService = this.props.addServices.find(item => item.id === addServiceId);
       if (addService) {
         const addClasses = this.props.actTemplate.addServiceIds.length === i+1 ? 'mb_2du' : 'mb_1du';
         return (
           <AddServicesCard key={'addService'+i} addService={addService} contractId={this.props.contractId}
                            organizationId={this.props.organizationId} addServiceIndex={i} usersList={this.props.usersList}
                            isActTemplateCardMode={true} addClasses={addClasses}
           />
         );
       } else {
         return (
           <div key={'alert'+i} className={`AlertCard__header AlertCard__header_rounded AlertCard__header_ERROR mb_2du`}>
             <div className="AlertCard__header-ico"/>
             <div className="AlertCard__header-title">{text.addServiceWithGivenIdIsNotFound(addServiceId)}
             </div>
           </div>
         )
       }

    });
    const isGenerationProhibited = this.props.actTemplate.hasUnverifiedAddAgreements ? true
      : (this.props.actTemplate.lastValidReportDate ? false : this.props.actTemplate.isNotCompliedWithPaynetParams);
    return (
      <NCard addClasses={'ActTemplateCard mb_1du'}>
        {this.props.actTemplate.isNotCompliedWithPaynetParams ? (
          <AlertPaynetSync addClasses={"mb_1du"}
                           agentIdIsNotCompliedWIthPaynetParams={this.props.actTemplate.agentIdIsNotCompliedWIthPaynetParams}
                           isActTemplateMode={true}
                           lastValidReportDate={this.props.actTemplate.lastValidReportDate}
                           organizationId={this.props.organizationId}/>
        ) : null}
        {this.props.actTemplate.hasUnverifiedAddAgreements ? (
          <AlertUnverifiedAddAgreements addClasses={"mb_1du"} contractId={this.props.contractId}
                                        isActTemplateMode={true} organizationId={this.props.organizationId}/>
        ) : null}
        <div className="ActTemplateCard__name-and-btns mb_2du">
          {ability.can('usePermission', permissionsStrValues.actTemplate.view) && this.props.actTemplate.finTermGroupIds.length  ? (
            <Link className={"ActTemplateCard__link"} to={linkTo}
                  id={actTemplatesViewPageIds.linkToActTemplate(this.props.actTemplateIndex)}>
              <NHeading3 addClasses={'NHeading3_link-color ActTemplateCard__name'}>
                <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{name}</TextOverflowHandler>
              </NHeading3>
            </Link>
          ) : (
            <NHeading3 addClasses={'ActTemplateCard__name'}>
              <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{name}</TextOverflowHandler>
            </NHeading3>
          )}
          <div className={'ActTemplateCard__btns'}>
            <VerifiedStatusLabel isVerified={this.props.actTemplate.isVerified}
                                 isVerifiedAtDate={this.props.actTemplate.isVerifiedAtDate}
                                 isVerifiedByUserId={this.props.actTemplate.isVerifiedByUserId}
                                 addClasses={'mr_2du'} />
            <Link to={linkToHistory}
                  id={actTemplatesViewPageIds.linkToActTemplateHistory(this.props.actTemplateIndex)} className={'mr_2du'}>
              <LinkToHistory/>
            </Link>
            <GenerateReportButton contractId={this.props.contractId} organizationId={this.props.organizationId}
                                  actTemplateId={this.props.actTemplate.id} actTemplateIndex={this.props.actTemplateIndex}
                                  finTermGroupIds={this.props.actTemplate.finTermGroupIds} history={this.props.history}
                                  addServiceIds={this.props.actTemplate.addServiceIds}
                                  isGenerationProhibited={isGenerationProhibited}
                                  lastValidReportDate={this.props.actTemplate.lastValidReportDate}
                                  isNotCompliedWithPaynetParams={this.props.actTemplate.isNotCompliedWithPaynetParams}
                                  isButtonInCard={true}/>
            {this.props.actTemplate.finTermGroupIds.length ? (
              <Can do={'usePermission'} on={permissionsStrValues.actTemplate.update}>
                <BtnDel addClasses={'ml_2du'} onClick={this.props.onBtnDelClick}
                        id={actTemplatesViewPageIds.buttonDeleteActTemplate(this.props.actTemplateIndex)}/>
              </Can>
            ) : null}
          </div>
        </div>
        {this.props.actTemplate.finTermGroupIds.length ? (
          <DropDown isDropDownTitleWithIco={true} dropDownTitle={
            <Heading4 addClasses={'Heading4_lh16 mb_2du'}>{text.actTemplateCardHeadings.finTerms}</Heading4>
          } dropDownOptions={
            <div className="mb_2du">
              {groupFinTerms}
            </div>
          }/>
        ) : null}

        {this.props.actTemplate.addServiceIds.length ? (
            <DropDown isDropDownTitleWithIco={true} dropDownTitle={
              <Heading4 addClasses={'Heading4_lh16 mb_2du'}>{text.actTemplateCardHeadings.addServices}</Heading4>
            } dropDownOptions={
              <div className="mb_2du">
                {addServices}
              </div>
            }/>
        ) : null}
        <div className="flex flex_wrap">
          <div className="flex mr_4du">
            <SecText addClasses={'SecText_lh16 mr_1du'}>{text.wasCreated}</SecText>
            <Text1>{formatDate(this.props.actTemplate.createDate)}</Text1>
          </div>
          <div className="flex mr_4du">
            <SecText addClasses={'SecText_lh16 mr_1du'}>{text.wasChanged}</SecText>
            <Text1>{formatDate(this.props.actTemplate.updateDate)}</Text1>
          </div>
          <div className={lastRowClassName}>
            <div className="flex">
              <SecText addClasses={'SecText_lh16 mr_1du'}>{text.baseDoc}</SecText>
              {/*<BtnEditAct id={actTemplatesViewPageIds.linkDownloadBaseDocument(this.props.actTemplateIndex)}*/}
              {/*            addClasses={'max-w_quarter-card'}*/}
              {/*            onClick={this.getDocument}>*/}
              {/*  <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>*/}
              {/*    {this.props.actTemplate.fileName}</TextOverflowHandler>*/}
              {/*</BtnEditAct>*/}
              <a target={'_blank'} className={'BtnEditAct max-w_quarter-card'} rel="noopener noreferrer"
                 href={`/actTemplate/${this.props.actTemplate.id}/downloadFile?o_id=${this.props.organizationId}`}
                 id={actTemplatesViewPageIds.linkDownloadBaseDocument(this.props.actTemplateIndex)}>
                <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>
                  {this.props.actTemplate.fileName}</TextOverflowHandler>
              </a>
            </div>
            {this.props.actTemplate.addendumTemplateFileName ? (
              <div className="ActTemplateCard__addendum-file">
                <SecText addClasses={'SecText_lh16 mr_1du ws_n'}>{text.addendumTemplateFileLabel}</SecText>
                <a target={'_blank'} className={'BtnEditAct max-w_quarter-card'} rel="noopener noreferrer"
                   href={`/actTemplate/${this.props.actTemplate.id}/downloadAddendumTemplateFile?o_id=${this.props.organizationId}`}
                   id={actTemplatesViewPageIds.linkDownloadAddendumTemplateFile(this.props.actTemplateIndex)}>
                  <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>
                    {this.props.actTemplate.addendumTemplateFileName}</TextOverflowHandler>
                </a>
              </div>
            ) : null}
          </div>
        </div>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
      </NCard>
    );
  }
}

const mapStateToProps = state => {
  return {
    organizationId: state.organization.organizationId,
    groupFinTermsView: state.organization.item.groupFinTermsView,
    addServices: state.organization.item.addServices,
    usersList: state.organization.organizationData.usersList
  };
};

export default connect(mapStateToProps, null)(ActTemplateCard);
