//@flow
import * as React from 'react';
import type {isFocused, selectedOption as selectedOptionType, selectOptions} from "../../../../services/flowTypes/componentFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {onDropDownSelectChange} from "../../../../services/flowTypes/propFnFlowTypes";
import './DropDownSelect.css';
import CloseOnClickOutside from "../../../service/CloseOnClickOutside/CloseOnClickOutside";
import type {id} from "../../../../services/flowTypes/appFlowTypes";

type Props = {
  addClasses?: addClasses,
  options: selectOptions,
  selectedOption: selectedOptionType,
  onDropDownSelectChange: onDropDownSelectChange,
  idForTest?: id
};
type State = {
  isFocused: isFocused
};

class DropDownSelect extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isFocused: false
    };
  }
  handleFocus = (e:SyntheticEvent<HTMLElement>) => {
    if (!this.state.isFocused) {
      this.setState({isFocused: true});
    }
  };
  toggleFocus = () => {
    this.setState({isFocused: !this.state.isFocused});
  };
  handleSelect = (e:SyntheticEvent<HTMLElement>, selectedOption:selectedOptionType) => {
    this.setState({isFocused: false});
    this.props.onDropDownSelectChange(selectedOption);
  };
  onClickOutside = () => {this.setState({isFocused: false})};
  render() {
    const className = this.state.isFocused ? "DropDownSelect DropDownSelect_focused" : "DropDownSelect";
    const options = this.props.options.map((option, index) => {
      const optionClassName = this.props.selectedOption.value === option.value ? "DropDownSelect__option DropDownSelect__option_active"
      : "DropDownSelect__option";
      const onClick = this.props.selectedOption.value === option.value ? () => this.setState({isFocused: false})
        : (e) => this.handleSelect(e, option);
      return (
        <div className={optionClassName} key={'DropDownSelectOption'+index} onClick={onClick} id={option.value}>
          {option.label}
        </div>
      );
    });
    return (
      <CloseOnClickOutside onClickOutside={this.onClickOutside} isActive={this.state.isFocused}>
        <div className={baseClass(className, this.props.addClasses)} onClick={this.handleFocus} id={this.props.idForTest}>
          {options}
          <div className="DropDownSelect__ico" onClick={this.toggleFocus}/>
        </div>
      </CloseOnClickOutside>
    );
  }
}

export default DropDownSelect;    