//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {agentId, agentsList} from "../../../../../../../services/flowTypes/dataFlowTypes";
import BtnEditAct from "../../../../../../../components/simple/Buttons/BtnEditAct/BtnEditAct";
import text from "../../../../../../../services/localization/text";
import {groupFinTermEditPageIds} from "../../../../../../../tests/testIds";
import GroupFinTermsFormGroup from "./GroupFinTermsFormGroup";
import QuestionTooltip from "../../../../../../../components/simple/Containers/QuestionTooltip/QuestionTooltip";
import groupFinTermsEditActions
  from "../../../../../../../store/organization/item/groupFinTermsEdit/groupFinTermsEditActions";
import type {onGetGroupFinTermProp} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import type {selectOptions} from "../../../../../../../services/flowTypes/componentFlowTypes";
import AdaptiveFormContainer
  from "../../../../../../../components/simple/Containers/AdaptiveFormContainer/AdaptiveFormContainer";

type Props = {
  agentsList: agentsList,
  payerMerchantId: agentId,
  payeeMerchantId: agentId,
  onGetGroupFinTermProp: onGetGroupFinTermProp
};
type State = {
  agentsOptions: selectOptions
};

class PayerPayeeMerchant extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      agentsOptions: [],
      isPayerMerchantAdded: false,
      isPayeeMerchantAdded: false
    };
  }
  getStateProps = (agentType: "payer" | "payee") => {
    const stateProp = "is" + agentType.charAt(0).toUpperCase() + agentType.slice(1) + "MerchantAdded";
    const storeStateProp = agentType + "MerchantId";
    return {stateProp, storeStateProp};
  };
  addField = (agentType: "payer" | "payee") => {
    const {stateProp, storeStateProp} = this.getStateProps(agentType);
    this.setState({[stateProp]: true});
    this.props.onGetGroupFinTermProp(storeStateProp, "");
  };
  removeField = (agentType: "payer" | "payee") => {
    const {stateProp, storeStateProp} = this.getStateProps(agentType);
    this.setState({[stateProp]: false});
    this.props.onGetGroupFinTermProp(storeStateProp, null);
  };
  componentDidMount(): * {
    const agentsList = this.props.agentsList.filter(item => item.status === 'ACTIVE');
    const agentsOptions = agentsList.map(item => {
      return {value: item.id, label: item.name}
    });
    this.setState({agentsOptions})
  }

  render() {
    return (
      <div className="mb_3du">
        <AdaptiveFormContainer fieldsArr={[
          <div key={"payerId"}>
            <GroupFinTermsFormGroup formFieldType={'select'} propName={'payerId'} label={text.payer}
                                    options={this.state.agentsOptions}
                                    placeholder={text.select.placeholder.payer} isSearchable={true}
                                    selectInputId={groupFinTermEditPageIds.selectInputPayer}
                                    labelTooltip={<QuestionTooltip isHighZIndex={true}>{text.payerTooltip}</QuestionTooltip>}
                                    idForTest={groupFinTermEditPageIds.selectPayer}/>
            <div style={{height: "16px"}}>
              {this.props.payerMerchantId || this.state.isPayerMerchantAdded ? null : (
                <BtnEditAct onClick={()=>{this.addField("payer")}}>
                  {text.addMerchantBtnLabel}</BtnEditAct>
              )}
            </div>
          </div>,
          <div key={'payeeId'}>
            <GroupFinTermsFormGroup formFieldType={'select'} propName={'payeeId'} label={text.payee}
                                    options={this.state.agentsOptions}
                                    placeholder={text.select.placeholder.payee} isSearchable={true}
                                    selectInputId={groupFinTermEditPageIds.selectInputPayee}
                                    labelTooltip={<QuestionTooltip>{text.payeeTooltip}</QuestionTooltip>}
                                    idForTest={groupFinTermEditPageIds.selectPayee}/>
            <div style={{height: "16px"}}>
              {this.props.payeeMerchantId || this.state.isPayeeMerchantAdded ? null : (
                <BtnEditAct onClick={()=>{this.addField("payee")}}>
                  {text.addMerchantBtnLabel}</BtnEditAct>
              )}
            </div>
          </div>
        ]} containerMode={"twoHalfCardWidthFields"}/>

        <AdaptiveFormContainer fieldsArr={[
          <div key={'payerMerchantId'}>
            {this.props.payerMerchantId || this.state.isPayerMerchantAdded ? (
              <>
                <GroupFinTermsFormGroup formFieldType={'select'} propName={'payerMerchantId'} label={text.payerMerchantId}
                                        addClasses={'mt_2du'} options={this.state.agentsOptions}
                                        placeholder={text.select.placeholder.payerMerchant} isSearchable={true}
                                        selectInputId={groupFinTermEditPageIds.selectInputPayerMerchant}
                                        idForTest={groupFinTermEditPageIds.selectPayerMerchant}/>
                <BtnEditAct addClasses={"BtnEditAct_delete"} onClick={()=>{this.removeField("payer")}}>
                  {text.removeMerchantBtnLabel}</BtnEditAct>
              </>
            ) : null}
          </div>,
          <div key={'payeeMerchantId'}>
            {this.props.payeeMerchantId || this.state.isPayeeMerchantAdded ? (
              <>
                <GroupFinTermsFormGroup formFieldType={'select'} propName={'payeeMerchantId'} label={text.payeeMerchantId}
                                        addClasses={'mt_2du'} options={this.state.agentsOptions}
                                        placeholder={text.select.placeholder.payeeMerchant} isSearchable={true}
                                        selectInputId={groupFinTermEditPageIds.selectInputPayeeMerchant}
                                        idForTest={groupFinTermEditPageIds.selectPayeeMerchant}/>
                <BtnEditAct addClasses={"BtnEditAct_delete"} onClick={()=>{this.removeField("payee")}}>
                  {text.removeMerchantBtnLabel}</BtnEditAct>
              </>
            ) : null}
          </div>
        ]} containerMode={"twoHalfCardWidthFields"}/>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    agentsList: state.organization.organizationData.agentsList,
    payerMerchantId: state.organization.item.groupFinTermsEdit.payerMerchantId,
    payeeMerchantId: state.organization.item.groupFinTermsEdit.payeeMerchantId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetGroupFinTermProp: (prop, data) => dispatch({type: groupFinTermsEditActions.GROUP_FIN_TERMS_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayerPayeeMerchant);    