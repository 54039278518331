//@flow
import * as React from 'react';
import text from "../../services/localization/text";
import OrganizationsSearchBox from "./organizations/OrganizationsSearchBox/OrganizationsSearchBox";
import OrganizationsTotal from "./organizations/OrganizationsTotal";
import OrganizationsSearchResults from "./organizations/OrganizationsSearchResults/OrganizationsSearchResults";
import OrganizationsPagination from "./organizations/OrganizationsPagination/OrganizationsPagination";
import Can from "../../services/permissions/Can";
import {Link} from "react-router-dom";
import {newOrganizationSuffix, supOrgsPath} from "../../services/urlStrings/urlStrings";
import {organizationsPageIds} from "../../tests/testIds";
import organizationsSearchActions
  from "../../store/organizations/organizationsSearch/organizationsSearchActions";
import connect from "react-redux/es/connect/connect";
import {onInitOrganizationsSearch} from "../../services/flowTypes/propFnFlowTypes";
import FixedHeaderContent from "../../components/simple/Containers/FixedHeader/FixedHeaderContent";
import {globalPermissionsStrValues} from "../../configData/permissionsStrValues";
import BtnNewItem from "../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  onInitOrganizationsSearch: onInitOrganizationsSearch
};

class Organizations extends React.Component <Props> {
  componentDidMount(){
    this.props.onInitOrganizationsSearch();
  }
  componentWillUnmount(): * {
    this.props.onInitOrganizationsSearch();
  }
  render() {
    const button = (
      <Can do={'useGlobalPermission'} on={globalPermissionsStrValues.admin} key={'newOrganization'}>
        <Link to={supOrgsPath + newOrganizationSuffix}>
          <BtnNewItem id={organizationsPageIds.buttonNewOrganization} addClasses={"BtnNewItem_grey"}>
            {text.btnAdd}</BtnNewItem>
        </Link>
      </Can>
    );
    const headerTitle = <>{text.organizations + " - " }<OrganizationsTotal/></>;
    return (
      <div className="Organizations">
        <FixedHeaderContent title={headerTitle} buttons={[button]} isSupLevel={true}/>
        <OrganizationsSearchBox/>
        <OrganizationsSearchResults/>
        <OrganizationsPagination/>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitOrganizationsSearch: () => dispatch({type: organizationsSearchActions.ORG_SEARCH_INIT_STATE_SET})
  };
};
export default connect(null, mapDispatchToProps)(Organizations);