const rulesFn = {
  passMinLength: (passValue) => {
    return (passValue && passValue.length > 7);
  },
  containsLatinUppercase: (passValue) => {
    return /[A-Z]/g.test(passValue);
  },
  containsLatinLowercase: (passValue) => {
    return /[a-z]/g.test(passValue);
  },
  containsNumber: (passValue) => {
    return /[0-9]/g.test(passValue);
  },
  freeOfSpaces: (passValue) => {
    return !/\s/g.test(passValue);
  }
};
const passwordRulesLabels = [
  'passMinLength', 'containsLatinUppercase', 'containsLatinLowercase', 'containsNumber', 'freeOfSpaces'
];
const passwordRules = passwordRulesLabels.map(item => {
  return {
    name: item,
    fn: rulesFn[item]
  }
});
export default passwordRules;