//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {children, id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './TagWithClickToCopy.css';
import text from "../../../../services/localization/text";
import withClickToCopy from "../../../hoc/withClickToCopy";
import type {
  contentIsCopied,
  isTagAppliedInExpression,
  showContentIsCopied
} from "../../../../services/flowTypes/componentFlowTypes";
import type {copyOnClick} from "../../../../services/flowTypes/propFnFlowTypes";
import GroupFinTermCardLabel from "../GroupFinTermCardLabel/GroupFinTermCardLabel";
import CopyBtnOnHover from "../../Buttons/CopyBtnOnHover/CopyBtnOnHover";

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  contentIsCopied: contentIsCopied,
  showContentIsCopied: showContentIsCopied,
  copyOnClick: copyOnClick,
  isTagAppliedInExpression?: isTagAppliedInExpression,
  checkedComponent?: React.Node
};

const TagToCopy = (props: Props) => {
  return (
    <CopyBtnOnHover checked={props.isTagAppliedInExpression} onClick={props.copyOnClick}
                    checkedComponent={props.checkedComponent}
                    showContentIsCopied={props.showContentIsCopied} contentIsCopiedLabel={
      <GroupFinTermCardLabel
        addClasses={'GroupFinTermCardLabel_no-uppercase-fw500 GroupFinTermCardLabel_lh17'}>
        {text.contentIsCopied}</GroupFinTermCardLabel>
    }>
      <div id={props.id} className={baseClass("TagToCopy", props.addClasses)}
      >{props.children}</div>
    </CopyBtnOnHover>
  );
};

const TagWithClickToCopy = withClickToCopy(TagToCopy);

export default TagWithClickToCopy;