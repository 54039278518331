//@flow
import addServicesActions from './addServicesActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {addServicesList} from "../../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = null;

const addServicesReducer = (state:addServicesList = initialState, action:reducerAction) => {
  switch (action.type) {
    case addServicesActions.ADD_SERVICES_INIT:
      return initialState;
    case addServicesActions.ADD_SERVICES_SET:
      return action.value;
    default:
      return state;
  }
};

export default addServicesReducer;