//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  agent, agentLinkedTypeList,
  agentRelated, contractAgentsItem,
  organizationId
} from "../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../services/localization/text";
import {
  agentPathEdit,
  agentPathView, mainDataSuffix,
  orgPath
} from "../../../../services/urlStrings/urlStrings";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import concatIdName from "../../../../utils/concatIdName";
import type {
  delAgentIsDisabled,
  isWideMode,
  linkId,
  onDelAgentClick
} from "../../../../services/flowTypes/componentFlowTypes";
import formatDate from "../../../../utils/formatDate";
import ConnectedTagLabel from "../../../../components/composed/ConnectedTagLabel/ConnectedTagLabel";
import {agentsPageIds} from "../../../../tests/testIds";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {onEditBtnClick} from "../../../../services/flowTypes/propFnFlowTypes";
import AlertPaynetSync from "../../../../components/simple/Containers/AlertCard/AlertPaynetSync";

const withHandledAgentData = (WrappedComponent) => {
  type Props = {
    id?: id,
    addClasses?: addClasses,
    agent: agent | contractAgentsItem,
    agentRelated: agentRelated,
    onDelAgentClick?: onDelAgentClick,
    delAgentIsDisabled?: delAgentIsDisabled,
    organizationId: organizationId,
    linkId?: linkId,
    buttonDeleteAgentId?: id,
    onEditBtnClick?: onEditBtnClick,
    btnEditId?: id,
    agentLinkedTypeList: agentLinkedTypeList,
    isWideMode?: isWideMode
  };
  class HOC extends React.Component <Props> {
    render() {
      const idName = concatIdName(this.props.agent.id, this.props.agent.name);
      const linkToView = orgPath(this.props.organizationId) + agentPathView(this.props.agent.id) + mainDataSuffix;
      const linkToEdit = orgPath(this.props.organizationId) + agentPathEdit(this.props.agent.id) + mainDataSuffix;
      const createDate = formatDate(this.props.agent.createDate);
      const statusDate = this.props.agent.statusDate ? formatDate(this.props.agent.statusDate) : "-";
      const itemStatusLabel= text.agent.status[this.props.agent.status];
      const tags = this.props.agent.tags ? this.props.agent.tags.map((tag,index) => {
        return (<ConnectedTagLabel key={'tag-'+index}
                                   id={agentsPageIds.tagAgentCard(index, this.props.agent.id)}>{tag}</ConnectedTagLabel>);
      }) : null;
      const warningComponent = this.props.agent.isNotCompliedWithPaynetParams ?
        <AlertPaynetSync addClasses={"mb_1du"}/> : null;
      const handledAgent = {
        idName, linkToView, linkToEdit, createDate, itemStatusLabel, tags, statusDate, warningComponent
      };
      return (
        <WrappedComponent handledAgent={handledAgent} {...this.props}/>
      );
    }
  }

  const mapStateToProps = state => {
    return {
      organizationId: state.organization.organizationId
    };
  };

  return  connect(mapStateToProps, null)(HOC);
};

export default withHandledAgentData;