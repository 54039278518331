//@flow
import organizationsSearchActions from './organizationsSearchActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {organizationsSearch} from "../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = {
  name: '',
  count: 10,
  offset: 0,
  status: 'ACTIVE'
};

const organizationsSearchReducer = (state:organizationsSearch = initialState, action:reducerAction) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case organizationsSearchActions.ORG_SEARCH_INIT_STATE_SET:
      return initialState;
    case organizationsSearchActions.ORG_SEARCH_PROP_UPDATE:
      return {...newState, ...action.value};
    case organizationsSearchActions.ORG_SEARCH_PROP_DELETE:
      for (let i=0; i < action.value.length; i++) {
        delete newState[action.value[i]];
      }
      return newState;
    default:
      return state;
  }
};

export default organizationsSearchReducer;
