//@flow
import reportsSearchActions from './reportsSearchActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {reportsSearch} from "../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = {
  // text: '',
  reportAccountStatusFromPath: '',
  // reportStatus: 'ALL',
  // reportAccountStatus: '',
  // startCreateDate: '',
  // endCreateDate: '',
  // startReportPeriodDate: '',
  // endReportPeriodDate: '',
  // userId: 'ALL',
  count: 10,
  offset: 0,
  organizationId: '', // only for render purpose
  // contractId: 'ALL',
  // actTemplateId: 'ALL',
  // payerId: 'ALL',
  // payeeId: 'ALL',
  // advancedSearchIsShown: false
};

const reportsSearchReducer = (state:reportsSearch = initialState, action:reducerAction) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case reportsSearchActions.REPORTS_SEARCH_INIT_STATE_SET:
      return {...initialState, ...action.value};
    case reportsSearchActions.REPORTS_SEARCH_PROP_UPDATE:
      return {...newState, ...action.value};
    case reportsSearchActions.REPORTS_SEARCH_PROP_DELETE:
      for (let i=0; i < action.value.length; i++) {
        delete newState[action.value[i]];
      }
      return newState;
    default:
      return state;
  }
};

export default reportsSearchReducer;