//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {organization, organizationsList} from "../../../../services/flowTypes/dataFlowTypes";
import RenderOnReady from "../../../service/RenderOnReady/RenderOnReady";
import type {history, isReadyToRender} from "../../../../services/flowTypes/appFlowTypes";
import type {selectOptions} from "../../../../services/flowTypes/componentFlowTypes";
import {agentsSuffix, orgPath} from "../../../../services/urlStrings/urlStrings";
import {mainPageIds} from "../../../../tests/testIds";
import BorderlessSelect from "../../../simple/FormComponents/BorderlessSelect/BorderlessSelect";

type Props = {
  organizationsList: organizationsList,
  organization: organization,
  history: history
};
type State = {
  isReadyToRender: isReadyToRender,
  organizationsSelectOptions: selectOptions
};

class OrganizationSwitcher extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      organizationsSelectOptions: []
    };
  }
  handleSelect = (selectedOption) => {
    const path = orgPath(selectedOption.value) + agentsSuffix;
    this.props.history.push({pathname: path});
  };
  componentDidMount(){
    const organizationsSelectOptions = this.props.organizationsList.map(item => {
      return {value: item.id, label: item.name};
    });
    this.setState({organizationsSelectOptions, isReadyToRender: true});
  }
  render() {
    const selectedOrganization = this.props.organization ?
      {value: this.props.organization.id, label: this.props.organization.name} : null;
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
        <BorderlessSelect value={selectedOrganization} isOrganizationSwitcher={true}
                 onChange={this.handleSelect}
                 isSearchable={true}
                 options={this.state.organizationsSelectOptions}
                 id={mainPageIds.switcherOrganization}
                 inputId={mainPageIds.inputSwitcherOrganization}
        />
      </RenderOnReady>
    );
  }
}
const mapStateToProps = state => {
  return {
    organizationsList: state.general.organizationsList
  };
};
export default connect(mapStateToProps, null)(OrganizationSwitcher);