//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  agentId, agentName,
  agentsRelatedSearchData,
  agentsSearchData,
  totalCountSearchData
} from "../../../services/flowTypes/dataFlowTypes";
import type {shouldSearchResultsUpdate} from "../../../services/flowTypes/componentFlowTypes";
import type {history, modalIsShown} from "../../../services/flowTypes/appFlowTypes";
import {agentsPageIds} from "../../../tests/testIds";
import ConnectedAgentCard from "./AgentCard/ConnectedAgentCard";
import EmptyListV2 from "../../../components/simple/Containers/EmptyList/EmptyListV2";
import ability from "../../../services/permissions/permissions";
import {permissionsStrValues} from "../../../configData/permissionsStrValues";
import {agentPathEdit, mainDataSuffix, orgPath} from "../../../services/urlStrings/urlStrings";
import DeleteAgentModal from "../../Contracts/pages/Contract/pages/ContractAgents/contractAgents/DeleteAgentModal";
import text from "../../../services/localization/text";


type Props = {
  history: history,
  agents: agentsSearchData,
  agentsRelated: agentsRelatedSearchData,
  totalCount: totalCountSearchData,
  shouldSearchResultsUpdate: shouldSearchResultsUpdate
};
type State = {
  deleteModalIsShown: modalIsShown,
  agentIdToDelete: agentId,
  agentNameToDelete: agentName
};

class AgentsSearchResults extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      deleteModalIsShown: false,
      agentIdToDelete: null,
      agentNameToDelete: ''
    };
  }
  hideDeleteModal = () => {
    this.setState({
      deleteModalIsShown: false,
      agentIdToDelete: null,
      agentNameToDelete: ''
    });
  };
  showDeleteModal = (agentIdToDelete, agentNameToDelete) => {
    this.setState({
      deleteModalIsShown: true,
      agentIdToDelete, agentNameToDelete
    });
  };
  shouldComponentUpdate(nextProps){
    return nextProps.shouldSearchResultsUpdate;
  }
  render() {
    const searchResultsCards = this.props.agents === null ? null :
      this.props.agents && this.props.agents.length ? this.props.agents.map((item, i) => {
        const onDelAgentClick = ability.can('usePermission', permissionsStrValues.agent.delete) ?
          () => {
            this.showDeleteModal(item.id, item.name);
          } : null;
        const onEditBtnClick = ability.can('usePermission', permissionsStrValues.agent.update) ?
          () => {
            const linkTo = orgPath(item.organizationId) + agentPathEdit(item.id) + mainDataSuffix;
            this.props.history.push(linkTo);
          } : null;
        const btnDelDisabledReasonTooltipText = item.deleteValidationError ?
          text.deleteAgentForbiddenReason[item.deleteValidationError] : '';
        return (<ConnectedAgentCard key={'AgentCard'+i} addClasses={"mb_1du"} linkId={agentsPageIds.linkToAgent(i)}
                                    delAgentIsDisabled={item.deleteValidationError}
                                    btnDelDisabledReasonTooltipText={btnDelDisabledReasonTooltipText}
                                  onDelAgentClick={onDelAgentClick} onEditBtnClick={onEditBtnClick}
                         isWideMode={true} agent={item} agentRelated={this.props.agentsRelated[i]}/>);
    }) : <EmptyListV2/>;
    return (
      <>
        <div className={"AgentsSearchResults"}>
          {searchResultsCards}
        </div>
        <DeleteAgentModal
          agentIdToDelete={this.state.agentIdToDelete}
          modalIsShown={this.state.deleteModalIsShown}
          cancelModal={this.hideDeleteModal}
          agentNameToDelete={this.state.agentNameToDelete}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    agents: state.organization.searchData.agents,
    agentsRelated: state.organization.searchData.agentsRelated,
    totalCount: state.organization.searchData.totalCount
  };
};

export default connect(mapStateToProps, null)(AgentsSearchResults);