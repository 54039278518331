//@flow
import * as React from 'react';
import {connect} from "react-redux";
import Pagination from "../../../../components/composed/Pagination/Pagination";
import type {paginationCount, paginationOffset, totalReportsNumber} from "../../../../services/flowTypes/dataFlowTypes";
import reportsSearchActionTypes from "../../../../store/organization/reportsSearch/reportsSearchActions";
import {onUpdateReportsSearchProp} from "../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  count: paginationCount,
  offset: paginationOffset,
  total: totalReportsNumber,
  onUpdateReportsSearchProp: onUpdateReportsSearchProp
};

class ReportsPagination extends React.Component <Props> {
  setOffset = (offset) => {
    const reportsSearchObj = {offset};
    this.props.onUpdateReportsSearchProp(reportsSearchObj);
  };
  setCount = (count) => {
    const reportsSearchObj = {count, offset: 0 };
    this.props.onUpdateReportsSearchProp(reportsSearchObj);
  };
  render() {
    return (
      <Pagination
        offset={this.props.offset}
        count={this.props.count}
        totalCount={this.props.total}
        setOffset={this.setOffset}
        setCount={this.setCount}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    count: state.organization.reportsSearch.count,
    offset: state.organization.reportsSearch.offset,
    total: state.organization.reports.total
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateReportsSearchProp: (reportsSearchObj) => dispatch({type: reportsSearchActionTypes.REPORTS_SEARCH_PROP_UPDATE,
      value: reportsSearchObj})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPagination);    