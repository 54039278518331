export const rangeParamNames = {
  min: ['minAmount', 'minCount'],
  max: ['maxAmount', 'maxCount']
};
const getMinMaxParamNumberValue = (paramsRow, paramType) => {
  const paramObj = paramsRow.find(item => rangeParamNames[paramType].includes(item.name));
  if (paramObj) {
    const trimmedParamValue = paramObj.value.trim();
    return trimmedParamValue.length ? +trimmedParamValue : 'Empty string';
  } else {
    throw new Error('MinMaxParam is not found!')
  }
};
export default getMinMaxParamNumberValue;