//@flow
import * as React from 'react';
import RenderOnReady from "../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {user} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import type {errorObject, isReadyToRender} from "../../../../../../../../services/flowTypes/appFlowTypes";
import userMainDataActions from "../../../../../../../../store/user/userMainData/userMainDataActions";
import {handleUpdateResponse, onGetUserMainData} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import api from "../../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../../services/apiInstance/apiErrorHandler";
import type {needUpdateUser} from "../../../../../../../../services/flowTypes/componentFlowTypes";
import {connect} from "react-redux";

type Props = {
  mainData: user,
  onGetUserMainData: onGetUserMainData,
  needUpdateUser: needUpdateUser,
  handleUpdateResponse: handleUpdateResponse
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
};

class UserUpdater extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true
    };
  }
  updateUser = () => {
    this.setState({isReadyToRender: false});
    api.updateUser(this.props.mainData)
      .then(response => {
        this.props.onGetUserMainData(response.data);
        this.setState({isReadyToRender: true});
        this.props.handleUpdateResponse();
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  componentDidUpdate(prevProps){
    if (prevProps.needUpdateUser !== this.props.needUpdateUser && this.props.needUpdateUser) {
      this.updateUser();
    }
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    mainData: state.user.mainData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetUserMainData: (user) => dispatch({type: userMainDataActions.USER_MAIN_DATA_SET, value: user})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserUpdater);