//@flow
import * as React from 'react';
import FixedHeader from "./FixedHeader";
import HeaderContent from "../../../composed/HeaderContent/HeaderContent";
import type {
  HeaderContentInfo,
  HeaderContentTitle,
  isSupLevel, subItemIdName
} from "../../../../services/flowTypes/componentFlowTypes";
import type {button, id, history, locationPathname} from "../../../../services/flowTypes/appFlowTypes";
// import ConnectedBreadcrumbs from "../../../service/ConnectedBreadcrumbs/ConnectedBreadcrumbs";
import type {breadcrumbsMaxWidth} from "../Breadcrumbs/Breadcrumbs";

type Props = {
  title: HeaderContentTitle,
  subtitle: HeaderContentTitle,
  titleLinkTo?: string,
  info?: HeaderContentInfo,
  buttons?: Array<button>,
  titleId?: id,
  isSupLevel?: isSupLevel,
  pathname?: locationPathname,
  subItemIdName?: subItemIdName,
  history: history,
  breadcrumbsMaxWidth?: breadcrumbsMaxWidth
};

const FixedHeaderContent = (props: Props) => {
  return (
    <FixedHeader>
      <HeaderContent title={props.title}
                     subtitle={props.subtitle}
                     titleLinkTo={props.titleLinkTo}
                     titleId={props.titleId}
                     // info={props.pathname ?
                     //   <ConnectedBreadcrumbs isSupLevel={props.isSupLevel} pathname={props.pathname}
                     //                         subItemIdName={props.subItemIdName}
                     //                         breadcrumbsMaxWidth={props.breadcrumbsMaxWidth}/>
                     //                         : null
                     // }
                     isSupLevel={props.isSupLevel}
                     buttons={props.buttons}
                     history={props.history}
      />
    </FixedHeader>
  );
};

export default FixedHeaderContent;    