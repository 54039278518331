//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  setFiltersToUrl
} from "../../../services/flowTypes/propFnFlowTypes";
import type {
  actTemplatesList,
  groupFinTermsList,
  usersList,
  viewHistory
} from "../../../services/flowTypes/dataFlowTypes";
import Filters from "../Filters/Filters";
import text from "../../../services/localization/text";
import type {historyType, selectOptions} from "../../../services/flowTypes/componentFlowTypes";
import concatIdName from "../../../utils/concatIdName";
import getNameOrDefName from "../../../utils/getNameOrDefName";
import type {filtersFromParams} from "../../../services/flowTypes/appFlowTypes";

type Props = {
  usersList: usersList,
  viewHistory: viewHistory,
  actTemplatesList: actTemplatesList,
  groupFinTermsList: groupFinTermsList,
  filtersFromParams: filtersFromParams,
  historyType: historyType,
  setFiltersToUrl: setFiltersToUrl
};
type State = {
  usersOptions: selectOptions,
  actTemplateIdOptions: selectOptions,
  finTermGroupIdOptions: selectOptions
};

class HistoryFilters extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      usersOptions: [],
      actTemplateIdOptions: [],
      finTermGroupIdOptions: []
    };
  }
  handleDeleteHistoryFiltersProp = (historyFiltersPropNameArr) => {
    const filtersFromParams = {...this.props.filtersFromParams};
    for (let i=0; i < historyFiltersPropNameArr.length; i++) {
      delete filtersFromParams[historyFiltersPropNameArr[i]];
    }
    this.props.setFiltersToUrl(filtersFromParams);
  };
  handleOnGetHistoryFiltersPropCall = (filtersProps) => {
    const historyFiltersProps = {...filtersProps};
    if (!historyFiltersProps.hasOwnProperty('offset')) {
      historyFiltersProps.offset = 0;
    }
    const filtersFromParams = {...this.props.filtersFromParams, ...historyFiltersProps};
    if (filtersFromParams.hasOwnProperty('logId')) {
      delete filtersFromParams.logId;
    }
    this.props.setFiltersToUrl(filtersFromParams);
  };
  getFiltersList = (allFiltersList) => {
    const filtersList = [];
    const filtersForOptions = [];
    for (let i=0; i < allFiltersList.length; i++) {
      const condition = allFiltersList[i].propName === 'dateRange' ?
        this.props.filtersFromParams.hasOwnProperty('startDate') || this.props.filtersFromParams.hasOwnProperty('endDate')
        : this.props.filtersFromParams.hasOwnProperty(allFiltersList[i].propName);
      if (condition) {
        filtersList.push(allFiltersList[i]);
      } else {
        filtersForOptions.push(allFiltersList[i]);
      }
    }
    return {filtersList, filtersForOptions};
  };
  defineAllFiltersList = () => {
    const userFilter = {propName: 'userId', formFieldType: 'filter-select', options: this.state.usersOptions,
      placeholder: text.selectUser, initValue: 'ALL'};
    const dateRangeFilter = {propName: 'dateRange', formFieldType: 'filter-wrapped-date-range', initValue: ''};
    const actTemplateFilter = {propName: 'actTemplateId', formFieldType: 'filter-select', options: this.state.actTemplateIdOptions, initValue: 'ALL'};
    const finTermFilter = {propName: 'finTermGroupId', formFieldType: 'filter-select', options: this.state.finTermGroupIdOptions, initValue: 'ALL'};
    switch (this.props.historyType) {
      case 'organizationData':
        return [userFilter, dateRangeFilter, actTemplateFilter, finTermFilter];
      case 'contract':
      case 'agent':
      case 'report':
        return [userFilter, dateRangeFilter];
      case 'user':
        return [dateRangeFilter];
      default:
        throw new Error('Undefined this.props.historyType');
    }

  };
  defineAllFiltersArrToDelete = () => {
    switch (this.props.historyType) {
      case 'organizationData':
        return ['userId', 'startDate', 'endDate', 'actTemplateId', 'finTermGroupId'];
      case 'contract':
      case 'agent':
      case 'report':
        return ['userId', 'startDate', 'endDate'];
      case 'user':
        return ['startDate', 'endDate'];
      default:
        throw new Error('Undefined this.props.historyType');
    }

  };
  componentDidMount() {
    if (this.props.historyType !== 'user') {
      const defaultOption =  {value: 'ALL', label: text.reportStatus['ALL']};
      const usersOptions = this.props.usersList ? this.props.usersList.map(item => {
        return {value: item.id+"", label: item.name};
      }) : [];
      const actTemplateIdOptions = this.props.actTemplatesList ? this.props.actTemplatesList.map(item => {
        return {value: item.id+"", label: concatIdName(item.id, getNameOrDefName(item.name, text.actTemplate))};
      }): [];
      const finTermGroupIdOptions =  this.props.groupFinTermsList ? this.props.groupFinTermsList.map(item => {
        return {value: item.id+"", label: concatIdName(item.id, getNameOrDefName(item.name, text.finTerm))};
      }): [];
      usersOptions.unshift(defaultOption);
      actTemplateIdOptions.unshift(defaultOption);
      finTermGroupIdOptions.unshift(defaultOption);
      this.setState({usersOptions, actTemplateIdOptions, finTermGroupIdOptions });
    }
  }
  render() {
    const allFiltersList = this.defineAllFiltersList();
    const allFiltersArrToDelete = this.defineAllFiltersArrToDelete();
    const resetFilters =
      () => this.handleDeleteHistoryFiltersProp(allFiltersArrToDelete);
    const {filtersList, filtersForOptions} = this.getFiltersList(allFiltersList);
    return (
      <Filters onGetFiltersProp={this.handleOnGetHistoryFiltersPropCall}
               onDeleteFiltersProp={this.handleDeleteHistoryFiltersProp}
               filtersState={this.props.filtersFromParams}
               resetFilters={resetFilters}
               filtersForOptions={filtersForOptions}
               filtersList={filtersList}
               addClasses={'Filters_sticky'}
               />
    );
  }
}

const mapStateToProps = state => {
  return {
    usersList: state.organization.organizationData.usersList,
    actTemplatesList: state.organization.organizationData.actTemplatesList,
    groupFinTermsList: state.organization.organizationData.groupFinTermsList,
    viewHistory: state.organization.historyData.viewHistory
  };
};
export default connect(mapStateToProps, null)(HistoryFilters);