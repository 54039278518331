//@flow
import * as React from 'react';
import NCard from "../../../../../components/simple/Containers/NCard/NCard";
import type {organization} from "../../../../../services/flowTypes/dataFlowTypes";
import {mainDataSuffix, supOrgPathView} from "../../../../../services/urlStrings/urlStrings";
import NHeading3 from "../../../../../components/simple/TextComponents/NHeading3/NHeading3";
import {organizationsPageIds} from "../../../../../tests/testIds";
import type {organizationIndex} from "../../../../../services/flowTypes/componentFlowTypes";
import concatIdName from "../../../../../utils/concatIdName";
import {Link} from "react-router-dom";
import Text1 from "../../../../../components/simple/TextComponents/Text1/Text1";
import text from "../../../../../services/localization/text";
import {globalPermissionsStrValues} from "../../../../../configData/permissionsStrValues";

type Props = {
  organization: organization,
  organizationIndex: organizationIndex
};

const OrganizationCard = (props: Props) => {
  const linkToOrganization = supOrgPathView(props.organization.id) + mainDataSuffix;
  const CardHeading = (
    <NHeading3 addClasses={'NHeading3_lh16 NHeading3_link-color mb_2du'}
               id={organizationsPageIds.headingCardOrganizationId(props.organizationIndex)}>
        {concatIdName(props.organization.id, props.organization.name)}
    </NHeading3>);
  const sourceNames = props.organization.sourceNames.map((item,i) => {
    const str = text.sourceNames[item] ? text.sourceNames[item] : item;
    return (<Text1 addClasses={'Text1_card-label-color ws_n mr_2du'} key={'sourceName'+i}>{str}</Text1>)
  });
  const organizationCode =
    (<Text1 addClasses={'Text1_card-label-color mr_4du'}>{props.organization.organizationCode}</Text1>);
  const currencyCodes = props.organization.currencyCodes.map((item,i) => {
    return (<Text1 addClasses={'Text1_card-label-color mr_2du'} key={'currencyCode'+i}>{item}</Text1>)
  });
  return (
    <NCard addClasses={'mb_1du'}>
      {props.organization.status === 'ACTIVE' || window.APP_DATA.globalPermissions?.includes(globalPermissionsStrValues.admin) ? (
          <Link to={linkToOrganization} id={organizationsPageIds.linkToOrganization(props.organizationIndex)}>
            {CardHeading}
          </Link>
      ) : (
          <h3 className={'NHeading3_lh16 mb_2du'}>{concatIdName(props.organization.id, props.organization.name)}</h3>
      )}
      <div className="flex_jc-sb">
        <div className={'flex mr_4du'}>
          {organizationCode}
          <div className="flex flex_wrap">{currencyCodes}</div>
        </div>
        <div className={'flex jc_fe flex_wrap'}>{sourceNames}</div>
      </div>
    </NCard>
  );
};

export default OrganizationCard;
