//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../service/RenderOnReady/RenderOnReady";
import alertsActions from "../../../store/alerts/alertsActions";
import type {onAddAlert} from "../../../services/flowTypes/propFnFlowTypes";
import type {fileName} from "../../../services/flowTypes/dataFlowTypes";
import alertsList from "../../../services/alertsList/alertsList";

type Props = {
  wrongFileName: fileName,
  onAddAlert: onAddAlert
};

class AlertOnWrongFileName extends React.Component <Props>{
  showAlert = () => {
    this.props.onAddAlert(alertsList.onWrongFileName);
  };
  componentDidUpdate(prevProps){
    if (this.props.wrongFileName && prevProps.wrongFileName !== this.props.wrongFileName) {
      this.showAlert();
    }
  }
  render(){
    return (
      <RenderOnReady isReadyToRender={true}/>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAddAlert: (newAlert) => dispatch({
      type: alertsActions.ALERT_ADD, value: newAlert})
  };
};
export default connect(null, mapDispatchToProps)(AlertOnWrongFileName);