import * as React from 'react';
import ParamFormGroup from "../../simple/FormComponents/ParamFormGroup/ParamFormGroup";
import {NFormGroupDefProps} from "../../simple/FormComponents/NFormGroup/defProps";


const setNewOrder = () => {
  console.log('Dnd is tried');
};
const dragAndDropInitArr = [
  (NFormGroupDefProps.children),
  (NFormGroupDefProps.children),
  (NFormGroupDefProps.children)
];
const dragAndDropItems = dragAndDropInitArr.map((item, index)=> {
  return (<ParamFormGroup key={'pfg-'+index} onDelParamClick={()=>{}}>{item}</ParamFormGroup>);
});
export const DragAndDropDefProps = {
  dragAndDropInitArr: dragAndDropInitArr,
  setNewOrder: setNewOrder,
  dragAndDropItems: dragAndDropItems
};