//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id, onClick} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './BtnCloseModal.css';
import HoveredIcon from "../../../composed/HoveredIcon/HoveredIcon";
import baseIconSrc from "./../../../../assets/v3-close-modal-ico.svg";
import hoveredIconSrc from "./../../../../assets/v3-close-modal-hover-ico.svg";

type Props = {
  id?: id,
  addClasses?: addClasses,
  onClick: onClick
};

const BtnCloseModal = (props: Props) => {
  return (
    <button type="button" id={props.id} onClick={props.onClick}
         className={baseClass("BtnCloseModal", props.addClasses)}>
      <HoveredIcon baseIconSrc={baseIconSrc} hoveredIconSrc={hoveredIconSrc}/>
    </button>
  );
};

export default BtnCloseModal;