//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import ConnnectedSearchInput from "./ConnectedSearchInput/ConnnectedSearchInput";
import SearchPropName from "./SearchPropName/SearchPropName";
import SearchDropdown from "./SearchDropdown/SearchDropdown";
import SearchPageFilters from "./SearchPageFilters";
import ConnnectedSorting from "./ConnectedSorting/ConnectedSorting";
import type {
  history,
  inputIsFocused,
  onBlur,
  onFocus,
  spinnerIsShown
} from "../../../../services/flowTypes/appFlowTypes";
import type {
  isAddItemsMode,
  searchDropdownIsShown
} from "../../../../services/flowTypes/componentFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import type {handleSearchResultsUpdate} from "../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  spinnerIsShown: spinnerIsShown,
  inputIsFocused: inputIsFocused,
  addClasses?: addClasses,
  handleSearchResultsUpdate: handleSearchResultsUpdate,
  onFocus: onFocus,
  onBlur: onBlur,
  searchDropdownIsShown: searchDropdownIsShown,
  history: history,
  isAddItemsMode?: isAddItemsMode
};

const SearchBox = (props:Props) => {
  return (
    <div className={baseClass("SearchBox SearchBox_SearchPropName", props.addClasses)}>
      <ConnnectedSearchInput
        spinnerIsShown={props.spinnerIsShown}
        inputIsFocused={props.inputIsFocused}
        handleSearchResultsUpdate={props.handleSearchResultsUpdate}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      />
      <SearchPropName />
      <SearchDropdown
        searchDropdownIsShown={props.searchDropdownIsShown}
        history={props.history}
        handleSearchResultsUpdate={props.handleSearchResultsUpdate}
      />
      <SearchPageFilters addClasses={'mt_2du'}/>
      {props.isAddItemsMode ? null : (
        <ConnnectedSorting handleSearchResultsUpdate={props.handleSearchResultsUpdate}/>
      )}
    </div>
  );
};
export default SearchBox;