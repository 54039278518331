import axios from 'axios';
import {testLoginUrl} from "../../pages/TestLogin/test-login-url";
import store from "../../store/store";


export const apiClient = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-Organization-Id': store.getState().organization.organizationId
  }
});


export default class DefaultApi {
  constructor() {
    this.apiClient = apiClient;
  }

  testLogin = (role) => {
    return this.apiClient.get(testLoginUrl + role);
  };

  logout = (csrf) => {
    return this.apiClient.post('/logout', (csrf.name + '=' + csrf.value), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
  };

  getUsersList = () => {
    return this.apiClient.get('/users/list');
  };

  getGroupFinTermsList = () => {
    return this.apiClient.get('/finTerm/list');
  };

  getTagsList = () => {
    return this.apiClient.get('/search/all-tags');
  };

  getOrganizationsList = () => {
    return this.apiClient.get('/organizations/list');
  };

  getConfigFinTerms = () => {
    return this.apiClient.get('/contract/configFinTerms');
  };

  getStatusesLists = () => {
    return this.apiClient.get('/status/lists');
  };

  getContractTypes = (organizationId) => {
    return this.apiClient.get('/contract/list-types?organizationId=' + organizationId);
  };

  searchAllByCategory = (searchCategory, offset, count) => {
    return this.apiClient.get('search/all-' + searchCategory + '?offset=' + offset + '&count=' + count)
  };

  searchSimple = (searchRequest) => {
    return this.apiClient.post('/search/simple', searchRequest);
  };

  searchAdvanced = (searchRequest) => {
    return this.apiClient.post('/search/advanced', searchRequest);
  };

  getContractById = (contractId) => {
    return this.apiClient.get('/contract/' + contractId);
  };

  getContractAgents = (contractId, status) => {
    return this.apiClient.get(`/contract/${contractId}/agents?status=${status}`);
  };

  getContractLinkedContracts = (contractId, status) => {
    return this.apiClient.get(`/contract/${contractId}/linkedContracts?status=${status}`);
  };

  updateContractGatesParams = (contractId, requestBody, params) => {
    return this.apiClient.post(`/contract/${contractId}/updateGatesParams`, requestBody, {params: params});
  };

  getContractGatesParams = (contractId, params) => {
    return this.apiClient.get('/contract/' + contractId + '/gatesParams', {params: params});
  };


  deleteContractAgent = (contractId, agentId, status) => {
    return this.apiClient.post(`/contract/${contractId}/deleteAgent?status=${status}`, {id: agentId});
  };

  deleteContractLinkedContract = (contractId, linkedContractId, status) => {
    return this.apiClient.post(`/contract/${contractId}/deleteLinkedContract?status=${status}`, {id: linkedContractId});
  };

  editContractLinkedContract = (contractId, updatedContract, status) => {
    return this.apiClient.post(`/contract/${contractId}/editLinkedContract?status=${status}`, updatedContract);
  };

  addContractAgents = (contractId, agentsIdList, status) => {
    return this.apiClient.post(`/contract/${contractId}/addAgents?status=${status}`, agentsIdList);
  };

  addContractLinkedContracts = (contractId, linkedContractsIdList, status) => {
    return this.apiClient.post(`/contract/${contractId}/addLinkedContracts?status=${status}`, linkedContractsIdList);
  };

  getAgentsList = (organizationId) => {
    const url = organizationId ? '/agent/list?organizationId=' + organizationId : '/agent/list';
    return this.apiClient.get(url);
  };

  getContractsList = () => {
    return this.apiClient.get('/contract/list');
  };

  getActTemplatesList = () => {
    return this.apiClient.get('/actTemplate/list');
  };

  updateContract = (contract) => {
    return this.apiClient.post('/contract/update', contract);
  };

  createContract = (contract) => {
    return this.apiClient.post('/contract/create', contract);
  };

  getContractDataParamsList = (organizationId) => {
    return this.apiClient.get('/contract/additionalParamsList?organizationId=' + organizationId);
  };

  getAgentDataParamsList = (organizationId) => {
    return this.apiClient.get('/agent/additionalParamsList?organizationId=' + organizationId);
  };

  getAgentById = (agentId) => {
    return this.apiClient.get('/agent/' + agentId);
  };

  updateAgent = (agent) => {
    return this.apiClient.post('/agent/update', agent);
  };

  createAgent = (agent) => {
    return this.apiClient.post('/agent/create', agent);
  };

  deleteAgent = (agentId, searchRequest) => {
    return this.apiClient.post(`/agent/${agentId}/delete`, searchRequest);
  };

  getGroupFinTerms = (params) => {
    return this.apiClient.get('/finTerm/view', {params: params});
  };

  deleteGroupFinTerm = (groupFinTermId) => {
    return this.apiClient.post('/finTerm/group/' + groupFinTermId + '/delete');
  };

  updateGroupFinTerm = (groupFinTerm) => {
    return this.apiClient.post('/finTerm/update', groupFinTerm);
  };

  createGroupFinTerm = (groupFinTerm) => {
    return this.apiClient.post('/finTerm/create', groupFinTerm);
  };

  getOrganizationDocumentsList = (organizationId) => {
    return this.apiClient.get('/organizations/' + organizationId + '/documentsList');
  };

  getContractDocumentsList = (contractId, onlyAddAgreements) => {
    if (onlyAddAgreements) {
      return this.apiClient.get('/contract/' + contractId + '/documentsList?onlyAddAgreements=' + onlyAddAgreements);
    } else {
      return this.apiClient.get('/contract/' + contractId + '/documentsList');
    }
  };

  getOrganizationFolderList = (organizationId) => {
    return this.apiClient.get('/organizations/' + organizationId + '/folderList');
  };

  getContractFolderList = (contractId) => {
    return this.apiClient.get('/contract/' + contractId + '/folderList');
  };

  addOrganizationFolder = (organizationId, folderName) => {
    return this.apiClient.post('/organizations/'+ organizationId + '/addFolder', {name: folderName});
  };

  addContractFolder = (contractId, folderName) => {
    return this.apiClient.post('/contract/'+ contractId + '/addFolder', {name: folderName});
  };

  deleteOrganizationFolder = (organizationId, folderId) => {
    return this.apiClient.post('/organizations/'+ organizationId + '/deleteFolder', {id: folderId});
  };

  deleteContractFolder = (contractId, folderId) => {
    return this.apiClient.post('/contract/'+ contractId + '/deleteFolder', {id: folderId});
  };

  renameOrganizationFolder = (organizationId, folderId, folderName) => {
    return this.apiClient.post('/organizations/'+ organizationId + '/editFolderName', {id: folderId, name: folderName});
  };

  renameContractFolder = (contractId, folderId, folderName) => {
    return this.apiClient.post('/contract/'+ contractId + '/editFolderName', {id: folderId, name: folderName});
  };

  getAgentDocumentsList = (agentId) => {
    return this.apiClient.get('/agent/' + agentId + '/documentsList');
  };

  getAgentFolderList = (agentId) => {
    return this.apiClient.get('/agent/' + agentId + '/folderList');
  };

  addAgentFolder = (agentId, folderName) => {
    return this.apiClient.post('/agent/'+ agentId + '/addFolder', {name: folderName});
  };

  deleteAgentFolder = (agentId, folderId) => {
    return this.apiClient.post('/agent/'+ agentId + '/deleteFolder', {id: folderId});
  };

  renameAgentFolder = (agentId, folderId, folderName) => {
    return this.apiClient.post('/agent/'+ agentId + '/editFolderName', {id: folderId, name: folderName});
  };

  getContractDocument = (contractId, fileId, fileName) => {
    return this.apiClient({
      url: `/contract/${contractId}/downloadDocument/${fileId}/${fileName}`,
      method: 'GET',
      responseType: 'blob',
    });
  };

  getAgentDocument = (agentId, fileId, fileName) => {
    return this.apiClient({
      url: `/agent/${agentId}/downloadDocument/${fileId}/${fileName}`,
      method: 'GET',
      responseType: 'blob',
    });
  };

  getOrganizationDocument = (organizationId, fileId, fileName) => {
    return this.apiClient({
      url: `/organizations/${organizationId}/downloadDocument/${fileId}/${fileName}`,
      method: 'GET',
      responseType: 'blob',
    });
  };

  updateOrganizationDocument = (organizationId, fileParams) => {
    return this.apiClient.post('/organizations/' + organizationId + '/documentUpdate', fileParams);
  };

  updateContractDocument = (contractId, fileParams) => {
    return this.apiClient.post('/contract/' + contractId + '/documentUpdate', fileParams);
  };

  updateAgentDocument = (agentId, fileParams) => {
    return this.apiClient.post('/agent/' + agentId + '/documentUpdate', fileParams);
  };

  deleteOrganizationDocument = (organizationId, fileId) => {
    return this.apiClient.post('/organizations/' + organizationId + '/deleteDocument/' + fileId);
  };

  deleteContractDocument = (contractId, fileId) => {
    return this.apiClient.post('/contract/' + contractId + '/deleteDocument/' + fileId);
  };

  deleteAgentDocument = (agentId, fileId) => {
    return this.apiClient.post('/agent/' + agentId + '/deleteDocument/' + fileId);
  };

  restoreOrganizationDocument = (organizationId, fileId) => {
    return this.apiClient.post('/organizations/' + organizationId + '/restoreDocument/' + fileId);
  };

  restoreContractDocument = (contractId, fileId) => {
    return this.apiClient.post('/contract/' + contractId + '/restoreDocument/' + fileId);
  };

  restoreAgentDocument = (agentId, fileId) => {
    return this.apiClient.post('/agent/' + agentId + '/restoreDocument/' + fileId);
  };

  uploadOrganizationDocument = (organizationId, formData) => {
    return this.apiClient.post('/organizations/' + organizationId + '/uploadDocument', formData);
  };

  uploadContractDocument = (contractId, formData) => {
    return this.apiClient.post('/contract/' + contractId + '/uploadDocument', formData);
  };

  uploadAgentDocument = (agentId, formData) => {
    return this.apiClient.post('/agent/' + agentId + '/uploadDocument', formData);
  };

  getActTemplates = (contractId) => {
    return this.apiClient.get('/actTemplate/' + contractId + '/view');
  };

  getActTemplateDocument = (actTemplateId) => {
    return this.apiClient({
      url: `/actTemplate/${actTemplateId}/downloadFile`,
      method: 'GET',
      responseType: 'blob',
    });
  };

  getAddendumTemplateFile = (actTemplateId) => {
    return this.apiClient({
      url: `/actTemplate/${actTemplateId}/downloadAddendumTemplateFile`,
      method: 'GET',
      responseType: 'blob',
    });
  };

  deleteActTemplate = (actTemplateId) => {
    return this.apiClient.post('/actTemplate/' + actTemplateId + '/delete');
  };

  scheduleReport = (actTemplateId, reportParams) => {
    return this.apiClient.post('/report/' + actTemplateId + '/scheduleReport', reportParams);
  };

  getTemplateParamsFile = (contractId, finTermGroupIds) => {
    return this.apiClient({
      url: '/actTemplate/' + contractId + '/templateParamsFile',
      method: 'POST',
      responseType: 'blob',
      data: finTermGroupIds
    });
  };

  getTemplateParamsBlocks = (contractId, finTermGroupIds) => {
    return this.apiClient.post('/actTemplate/' + contractId + '/templateParams', finTermGroupIds);
  };

  getTemplateAddendumParams = (contractId, finTermGroupIds, actTemplateId) => {
    return this.apiClient.post('/actTemplate/' + contractId + '/templateAddendumParams?actTemplateId=' + actTemplateId, finTermGroupIds);
  };

  getGroupFinTermTemplateParams = (contractId, finTermGroupIds, actTemplateId) => {
    return this.apiClient.post('/actTemplate/' + contractId + '/finTermsTemplateParams?actTemplateId=' + actTemplateId, finTermGroupIds);
  };

  updateActTemplate = (formData) => {
    return this.apiClient.post('/actTemplate/update', formData);
  };

  updateActTemplateWithFile = (formData) => {
    return this.apiClient.post('/actTemplate/updateWithFile', formData);
  };

  createActTemplate = (formData) => {
    return this.apiClient.post('/actTemplate/create', formData);
  };

  uploadTempFile = (formData) => {
    return this.apiClient.post('/common/uploadTempFile', formData);
  };

  getAgentContracts = (agentId, count, offset, status) => {
    return this.apiClient.get('/agent/' + agentId + '/contracts?count=' + count + '&offset=' + offset + '&status=' + status);
  };

  // getContractHistory = (contractId, offset, count, startDate, endDate, userId) => {
  //   return this.apiClient.get('/history/contract/' + contractId, {params: {
  //     offset: offset, count: count, startDate: startDate, endDate:endDate, userId: userId
  //     }});
  // };
  getContractHistory = (contractId, params) => {
    return this.apiClient.get('/history/contract/' + contractId, {params: params});
  };

  // getAgentHistory = (agentId, offset, count, startDate, endDate, userId) => {
  //   return this.apiClient.get('/history/agent/' + agentId, {params: {
  //       offset: offset, count: count, startDate: startDate, endDate:endDate, userId: userId
  //     }});
  // };
  getAgentHistory = (agentId, params) => {
    return this.apiClient.get('/history/agent/' + agentId, {params: params});
  };

  // getReportHistory = (reportId, offset, count, startDate, endDate, userId) => {
  //   return this.apiClient.get('/history/report/' + reportId, {params: {
  //       offset: offset, count: count, startDate: startDate, endDate:endDate, userId: userId
  //     }});
  // };
  getReportHistory = (reportId, params) => {
    return this.apiClient.get('/history/report/' + reportId, {params: params});
  };

  getReportsList = (reportsListRequest) => {
    return this.apiClient.post('/report/list', reportsListRequest);
  };

  getReportDocument = (reportId, fileId, fileName) => {
    return this.apiClient({
      url: `/report/${reportId}/downloadReportDocument/${fileId}/${fileName}`,
      method: 'GET',
      responseType: 'blob',
    });
  };

  getReportDocumentPdf = (reportId, fileId, fileName) => {
    return this.apiClient({
      url: `/report/${reportId}/downloadReportDocumentPdf/${fileId}/${fileName}`,
      method: 'GET',
      responseType: 'blob',
    });
  };

  getExportDocument = (reportId, fileId, fileName) => {
    return this.apiClient({
      url: `/report/${reportId}/downloadExportDocument/${fileId}/${fileName}`,
      method: 'GET',
      responseType: 'blob',
    });
  };

  getReportBaseDocument = (reportId, fileId, fileName) => {
    return this.apiClient({
      url: `/report/${reportId}/downloadReportBaseDocument/${fileId}/${fileName}`,
      method: 'GET',
      responseType: 'blob',
    });
  };

  download1CExportFile = (requestBody) => {
    return this.apiClient({
      url: `/report/download1CExportFile`,
      method: 'POST',
      responseType: 'blob',
      data: requestBody
    });
  };

  updateReportAccountStatus = (reportId, reportAccountStatusObj) => {
    return this.apiClient.post('/report/' + reportId + '/updateReportAccountStatus', reportAccountStatusObj);
  };

  autoAdjustReport = (reportId) => {
    return this.apiClient.post('/report/' + reportId + '/autoAdjust');
  };

  manualAdjustReport = (reportId, requestBody) => {
    return this.apiClient.post('/report/' + reportId + '/manualAdjust', requestBody);
  };

  bulkUpdateReportState = (requestBody) => {
    return this.apiClient.post('/report/bulkUpdateReportAccountStatus', requestBody);
  };

  getReportById = (reportId) => {
    return this.apiClient.get('/report/' + reportId);
  };

  uploadAdjustmentBaseDocument = (reportId, formData) => {
    return this.apiClient.post('/report/' + reportId + '/uploadAdjustmentBaseDocument', formData);
  };

  getReportAdjustmentHistory = (reportId) => {
    return this.apiClient.get('/report/' + reportId + '/adjustmentHistory');
  };

  searchOrganizations = (organizationsListRequest) => {
    return this.apiClient.post('/admin/organization/list', organizationsListRequest);
  };

  searchUsers = (usersListRequest) => {
    return this.apiClient.post('/admin/user/list', usersListRequest);
  };

  createUser = (user) => {
    return this.apiClient.post('/admin/user/create', user);
  };

  updateUser = (user) => {
    return this.apiClient.post('/admin/user/update', user);
  };

  getUserById = (userId) => {
    return this.apiClient.get('/admin/user/' + userId);
  };

  getOrganizationById = (organizationId) => {
    return this.apiClient.get('/admin/organization/' + organizationId);
  };

  getRolesLists = () => {
    return this.apiClient.get('/admin/list-all-roles');
  };

  getGlobalPermissionsLists = () => {
    return this.apiClient.get('/admin/list-all-permissions');
  };

  getAllOrganizationsLists = () => {
    return this.apiClient.get('/admin/list-all-organizations');
  };

  getAdminStatuses = () => {
    return this.apiClient.get('/admin/list-statuses');
  };

  sendSetPassLink = (userId, requestBody) => {
    return this.apiClient.post('/admin/user/' + userId + '/send-password-reset-email', requestBody);
  };

  createOrganization = (organization) => {
    return this.apiClient.post('/admin/organization/create', organization);
  };

  updateOrganization = (organization) => {
    return this.apiClient.post('/admin/organization/update', organization);
  };

  getMyOrganizationsList = (myOrganizationsListRequest) => {
    return this.apiClient.post('/organizations/myList', myOrganizationsListRequest)
  };

  getOrganizationParams = (organizationId) => {
    return this.apiClient.get('/admin/organization/' + organizationId + '/params');
  };

  updateOrganizationParams = (organizationId, organizationParams) => {
    return this.apiClient.post('/admin/organization/' + organizationId + '/params/update', organizationParams);
  };

  // getUserHistory = (userId, offset, count, startDate, endDate) => {
  //   return this.apiClient.get('/admin/history/user/' + userId, {params: {
  //       offset: offset, count: count, startDate: startDate, endDate:endDate
  //     }});
  // };
  getUserHistory = (userId, params) => {
    return this.apiClient.get('/admin/history/user/' + userId, {params: params});
  };

  updateContractAgent = (contractId, contractAgent, status) => {
    return this.apiClient.post(`/contract/${contractId}/editAgents?status=${status}`, contractAgent);
  };

  getConverterSources = () => {
    return this.apiClient.get('/actTemplate/getConverterSources');
  };

  getConvertedTag = (contractId, requestObject) => {
    return this.apiClient.post(`/actTemplate/${contractId}/convertedTag`, requestObject);
  };

  getOrganizationDataHistory = (params) => {
    return this.apiClient.get('/history/organizationData', {params: params});
  };

  getHistoryActionDescription = (actionId, actionParams, organizationId) => {
    let config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Organization-Id': store.getState().organization.organizationId
      }
    }
    if(!store.getState().organization.organizationId){
      config.headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Organization-Id': organizationId || 0
      }
    }
    return this.apiClient.post(`/history/actionDescription/${actionId}`, actionParams, config);
  };

  getHistoryViewStatus = () => {
    return this.apiClient.get('/history/viewStatus');
  };

  setAllAsViewed = (params) => {
    return this.apiClient.get('/history/markAllAsViewed', {params: params});
  };

  getNotificationSettings = () => {
    return this.apiClient.get( '/notification/viewNotification');
  };

  updateNotificationSettings = (requestBody) => {
    return this.apiClient.post('/notification/updateNotification', requestBody);
  };

  getAddAgreements = (contractId, status) => {
    return this.apiClient.get(`/contract/${contractId}/addAgreementsList?status=${status}`);
  };

  createAddAgreement = (requestBody, status) => {
    return this.apiClient.post(`/contract/createAddAgreement?status=${status}`, requestBody);
  };

  updateAddAgreement = (requestBody, status) => {
    return this.apiClient.post(`/contract/updateAddAgreement?status=${status}`, requestBody);
  };

  deleteAddAgreement = (requestBody, status, isDeleteWithFile) => {
    return this.apiClient.post(`/contract/deleteAddAgreement?status=${status}&isDeleteWithFile=${isDeleteWithFile}`, requestBody);
  };

  searchInOrgContext = (requestBody) => {
    return this.apiClient.post(`/search/inOrgContext`, requestBody);
  };

  getAddServices = (contractId, status) => {
    return this.apiClient.get(`/contract/${contractId}/addServicesList?status=${status}`);
  };

  calculateAddServiceSheet = (addServiceSheet) => {
    return this.apiClient.post(`/contract/calculateAddServiceSheet`, addServiceSheet);
  };

  createAddService = (addServiceData) => {
    return this.apiClient.post(`/contract/createAddService`, addServiceData);
  };

  updateAddService = (addServiceData) => {
    return this.apiClient.post(`/contract/updateAddService`, addServiceData);
  };

  getAddService = (contractId, addServiceId) => {
    return this.apiClient.get(`/contract/${contractId}/addService/${addServiceId}`);
  };

  deleteAddService = (requestBody, status) => {
    return this.apiClient.post(`/contract/deleteAddService?status=${status}`, requestBody);
  };

  getContractGateList = (contractId, source) => {
    return this.apiClient.get('/contract/' + contractId + '/gateList?source=' + source);
  };

  getContractFinTermsList = (contractId, params) => {
    return this.apiClient.get('/contract/' + contractId + '/finTermsList', {params: params});
  };

  getCustomFunctionList = (contractId, payerId) => {
    return this.apiClient.get(`/contract/${contractId}/customFunctionList?payerId=${payerId}`);
  };

}
