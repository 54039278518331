//@flow
import * as React from 'react';
import {connect} from "react-redux";
import SearchInput
  from "../../../../../components/composed/SearchPage/SearchBox/ConnectedSearchInput/SearchInput/SearchInput";
import text from "../../../../../services/localization/text";
import type {inputIsFocused, spinnerIsShown} from "../../../../../services/flowTypes/appFlowTypes";
import type {
  userName
} from "../../../../../services/flowTypes/dataFlowTypes";
import {handleSearchIcoClick, onUpdateUsersSearchProp} from "../../../../../services/flowTypes/propFnFlowTypes";
import usersSearchActions
  from "../../../../../store/users/usersSearch/usersSearchActions";

type Props = {
  name: userName,
  onUpdateUsersSearchProp: onUpdateUsersSearchProp,
  handleSearchIcoClick: handleSearchIcoClick,
  spinnerIsShown: spinnerIsShown
};
type State = {
  inputIsFocused: inputIsFocused
};
class ConnectedUsersSearchInput extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      inputIsFocused: true
    };
  }
  onBlur = () => {this.setState({inputIsFocused: false})};
  onFocus = () => {this.setState({inputIsFocused: true})};
  onChange = (e:SyntheticEvent<HTMLInputElement>) => {
    const usersSearchObj = {
      offset: 0,
      name: e.target.value
    };
    this.props.onUpdateUsersSearchProp(usersSearchObj);
  };
  render() {
    return (
      <SearchInput
        spinnerIsShown={this.props.spinnerIsShown}
        inputIsFocused={this.state.inputIsFocused}
        handleSearchIcoClick={this.props.handleSearchIcoClick}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onChange={this.onChange}
        text={this.props.name}
        placeholder={text.usersSearchPlaceholder}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    name: state.users.search.name
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateUsersSearchProp: (usersSearchObj) => dispatch({type: usersSearchActions.USERS_SEARCH_PROP_UPDATE,
      value: usersSearchObj})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedUsersSearchInput);