//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {totalCountSearchData} from "../../../../services/flowTypes/dataFlowTypes";
import type {shouldSearchResultsUpdate} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  totalCount: totalCountSearchData,
  shouldSearchResultsUpdate: shouldSearchResultsUpdate
};

class TotalCount extends React.Component <Props> {
  shouldComponentUpdate(nextProps){
    return nextProps.shouldSearchResultsUpdate;
  }
  render() {
    return this.props.totalCount;
  }
}
const mapStateToProps = state => {
  return {
    totalCount: state.organization.searchData.totalCount,
  };
};
export default connect(mapStateToProps, null)(TotalCount);