//@flow
import * as React from 'react';
import './OrganizationSidebar.css';
import connect from "react-redux/es/connect/connect";
import type {organizationId, organizationsList} from "../../../services/flowTypes/dataFlowTypes";
import OrganizationSwitcher from "./OrganizationSwitcher/OrganizationSwitcher";
import type {history} from "../../../services/flowTypes/appFlowTypes";

type Props = {
  organizationId: organizationId,
  organizationsList: organizationsList,
  history: history
};

class OrganizationSidebar extends React.Component <Props> {
  render(){
    const organization = this.props.organizationsList.find(item => item.id === this.props.organizationId);
    return (
      <div className="OrganizationSideBar">
        <OrganizationSwitcher organization={organization} history={this.props.history}/>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    organizationsList: state.general.organizationsList,
    organizationId: state.organization.organizationId
  };
};
export default connect(mapStateToProps, null)(OrganizationSidebar);