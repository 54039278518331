//@flow
import * as React from 'react';
import {connect} from "react-redux";
import NFormGroup from "../../../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import validationActions from "../../../../../../../../../store/validation/validationActions";
import type {
  onDeleteValidationStateFormProp,
  onGetValidationStateFormProp,
  onGetValidationStateProp
} from "../../../../../../../../../services/flowTypes/propFnFlowTypes";
import type {children} from "../../../../../../../../../services/flowTypes/appFlowTypes";
import type {
  isScrollToError,
  payoutPeriod,
  validationIsAllowed
} from "../../../../../../../../../services/flowTypes/dataFlowTypes";
import type {groupFinTermIndex, propError} from "../../../../../../../../../services/flowTypes/componentFlowTypes";
import validationPatterns from "../../../../../../../../../services/validationPatterns/validationPatterns";

type Props = {
  onGetValidationStateProp: onGetValidationStateProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  onDeleteValidationStateFormProp: onDeleteValidationStateFormProp,
  children: children,
  validationIsAllowed: validationIsAllowed,
  isScrollToError: isScrollToError,
  propError: propError,
  groupFinTermIndex: groupFinTermIndex,
  groupFinTermPayoutPeriod: payoutPeriod,
  actTemplatePayoutPeriod: payoutPeriod
};

class PayoutPeriodComplianceValidator extends React.Component <Props, State> {
  validate = () => {
    const error = validationPatterns.payoutPeriodCompliance(this.props.groupFinTermPayoutPeriod, this.props.actTemplatePayoutPeriod);
    this.props.onGetValidationStateProp('isScrollToError', false);
    this.props.onGetValidationStateFormProp('payoutPeriodCompliance'+this.props.groupFinTermIndex, error);
  };
  componentDidUpdate(prevProps){
    if (prevProps.groupFinTermIndex !== this.props.groupFinTermIndex
      || prevProps.groupFinTermPayoutPeriod !== this.props.groupFinTermPayoutPeriod
      || prevProps.actTemplatePayoutPeriod !== this.props.actTemplatePayoutPeriod) {
      this.validate();
    }
  }
  componentDidMount(){
    this.validate();
  }
  componentWillUnmount(){
    this.props.onDeleteValidationStateFormProp('payoutPeriodCompliance'+this.props.groupFinTermIndex);
  }

  render() {
    const propError = this.props.validationIsAllowed ? this.props.propError : '';
    return (
      <NFormGroup isScrollToError={this.props.isScrollToError} propError={propError}
                  addClasses={'PayoutPeriodComplianceValidator'}>
        {this.props.children}
      </NFormGroup>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propError: state.validation.form['payoutPeriodCompliance'+ownProps.groupFinTermIndex],
    validationIsAllowed: state.validation.validationIsAllowed,
    isScrollToError: state.validation.isScrollToError,
    actTemplatePayoutPeriod: state.organization.item.actTemplatesEdit.payoutPeriod,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetValidationStateProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onDeleteValidationStateFormProp: (prop) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_DELETE, value: prop})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutPeriodComplianceValidator);    