//@flow
import * as React from 'react';
import contractMainDataActions
  from "../../../../../../../store/organization/item/contractMainData/contractMainDataActions";
import connect from "react-redux/es/connect/connect";
import ability from "../../../../../../../services/permissions/permissions";
import api from "../../../../../../../services/apiInstance/api";
import type {
  contract,
  contractId,
  documentsList,
  itemDataParams,
  organizationId
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import {groupFinTermSource} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {
  errorObject,
  history,
  isReadyToRender,
  stateToCompare
} from "../../../../../../../services/flowTypes/appFlowTypes";
import ConnectedItemControls
  from "../../../../../../../components/composed/ConnectedItemControls/ConnectedItemControls";
import validationActions from "../../../../../../../store/validation/validationActions";
import {
  onGetContractMainData,
  onSetDataToValidationState,
  onUpdateNeedFetchFlagsProp
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import needFetchFlagsActions from "../../../../../../../store/needFetchFlags/needFetchFlagsActions";
import {contrPathView, mainDataSuffix, orgPath} from "../../../../../../../services/urlStrings/urlStrings";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";
import {selectOptions} from "../../../../../../../services/flowTypes/componentFlowTypes";
import BtnActions from "../../../../../../../components/simple/Containers/BtnActions/BtnActions";
import {paynetSources} from "./PaynetParams";
import text from "../../../../../../../services/localization/text";
import {getUniqueStrings} from "../../../../../../../utils/array";

type Props = {
  contractId: contractId,
  organizationId: organizationId,
  pathSuffix?: string,
  history: history,
  contractMainData: contract,
  onGetContractMainData: onGetContractMainData,
  onSetDataToValidationState: onSetDataToValidationState,
  params: itemDataParams,
  onUpdateNeedFetchFlagsProp: onUpdateNeedFetchFlagsProp,
  itemFiles: documentsList,
  availableSources: selectOptions
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  stateToCompare: stateToCompare
};

class ContractMainDataControls extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true,
      stateToCompare: {}
    };
  }

  goToContractMaiDataView = (contractId) => {
    const suffix = this.props.pathSuffix ? this.props.pathSuffix : mainDataSuffix;
    const path = orgPath(this.props.organizationId) + contrPathView(contractId) + suffix;
    this.props.history.push({
      pathname: path
    });
    this.props.onUpdateNeedFetchFlagsProp('tagsList', true);
    this.props.onUpdateNeedFetchFlagsProp('contractsList', true);
  };
  updateParamsInContractMainData = () => {
    return {...this.props.contractMainData, params: this.props.params, files: this.props.itemFiles};
  };
  setContractMainData = (contractMainData) => {
    this.props.onGetContractMainData(contractMainData);
    this.props.onSetDataToValidationState(contractMainData);
  };
  getContractById = (goBack) => {
    api.getContractById(this.props.contractId)
      .then(response => {
        this.setState({isReadyToRender: true});
        this.setContractMainData(response.data);
        if (goBack) {
          this.props.history.goBack();
        } else {
          this.goToContractMaiDataView(this.props.contractId);
        }
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      })
  };
  handleDataBeforeSend = () => {
    const contract = this.updateParamsInContractMainData();
    if (contract.status === 'ACTIVE') {
      if (contract.statusReason && contract.statusDate) {
        delete contract.statusReason;
        delete contract.statusDate;
      }
    }
    if (contract.createDate) {
      delete contract.createDate;
    }
    if (contract.organizationId) {
      delete contract.organizationId;
    }
    return contract;
  };
  requestUpdate = (contract) => {
    api.updateContract(contract)
      .then(response => {
        const goBack = false;
        this.getContractById(goBack);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  requestCreate = (contract) => {
    api.createContract(contract)
      .then(response => {
        this.setState({isReadyToRender: true});
        this.goToContractMaiDataView(response.data.id);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  sendRequest = () => {
    this.setState({isReadyToRender: false});
    const contract = this.handleDataBeforeSend();
    if (this.props.contractId === null) {
      this.requestCreate(contract);
    } else {
      this.requestUpdate(contract);
    }
  };
  cancel = () => {
    if (this.props.contractId === null) {
      this.props.history.goBack();
    } else {
      this.setState({isReadyToRender: false});
      const goBack = true;
      this.getContractById(goBack);
    }
  };

  componentDidMount() {
    //for update contract
    if (this.props.contractId === this.props.contractMainData.id) {
      this.setState({stateToCompare: JSON.stringify(this.props.contractMainData)});
    }
    //for new contract
    if (this.props.contractId === null) {
      this.setState({stateToCompare: JSON.stringify(this.props.contractMainData)});
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.contractId === this.props.contractMainData.id &&
      prevProps.contractMainData.id !== this.props.contractMainData.id)) {
      this.setState({stateToCompare: JSON.stringify(this.props.contractMainData)});
    }
  }

  render() {
    const contractMainData = this.updateParamsInContractMainData();
    const permittedAction = this.props.contractId === null
      ? permissionsStrValues.contract.create
      : permissionsStrValues.contract.update
    ;
    const actionIsPermitted = ability.can('usePermission', permittedAction);
    const submitIsDisabled = this.state.stateToCompare === JSON.stringify(contractMainData);
    return (
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <BtnActions
          actions={
            this.props.availableSources
          }
          title={"+  добавить источник"}
          addClasses={"mr_3du"}
          onAction={({value}) => {
            this.props.onAddSource(this.props.contractMainData, value)
          }}
        />
        <ConnectedItemControls
          actionIsPermitted={actionIsPermitted}
          submitIsDisabled={submitIsDisabled}
          cancelFn={this.cancel}
          sendRequest={this.sendRequest}
        />
      </>
    );
  }
}

const availbaleTpAddSources = (sourceOptions:string[], sources: { source: groupFinTermSource }[]) => {
  const uniqueStrings = getUniqueStrings(sources.map(s => s.source));
  return sourceOptions
    .filter(s=> uniqueStrings.indexOf(s) === -1)

}

const mapStateToProps = state => {
  const contractSources = state.organization.organizationData.configFinTerms.sourceOptions ?
    state.organization.organizationData.configFinTerms.sourceOptions : [];

  const contractMainData = state.organization.item.contractMainData;

  const sources = availbaleTpAddSources(
    contractSources.filter(s => paynetSources.includes(s)),
    [...contractMainData.merchantParams, ...contractMainData.managerIds, ...contractMainData.processorIds]
  )

  return {
    contractMainData,
    params: state.organization.item.itemDataParamsEdit,
    itemFiles: state.organization.item.itemFiles,
    availableSources: sources
      .map((s) => ({value: s, label: text[s]})),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onAddSource: (contractMainData: contract, source) => {
      dispatch({
        type: contractMainDataActions.CONTRACT_MAIN_DATA_SET,
        value: {
          ...contractMainData,
          merchantParams: [...contractMainData.merchantParams, {
            source,
            merchantId: []
          }]
        },
      })
    },
    onGetContractMainData: (contractMainData) => dispatch({
      type: contractMainDataActions.CONTRACT_MAIN_DATA_SET,
      value: contractMainData
    }),
    onSetDataToValidationState: (data) => dispatch({
      type: validationActions.VALIDATION_STATE_SET,
      value: data
    }),
    onUpdateNeedFetchFlagsProp: (prop, needFetch) => dispatch({
      type: needFetchFlagsActions.NEED_FETCH_FLAGS_PROP_UPDATE,
      value: {
        prop: prop,
        data: needFetch
      }
    })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContractMainDataControls);