//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../../../components/service/RenderOnReady/RenderOnReady";
import type {isReadyToRender, locationType, history} from "../../../../services/flowTypes/appFlowTypes";
import text from "../../../../services/localization/text";
import contractMainDataActions from "../../../../store/organization/item/contractMainData/contractMainDataActions";
import {onInitContractMainData} from "../../../../services/flowTypes/propFnFlowTypes";
import ContractButtonsRoutes from "../../../../components/service/Routes/ContractButtonsRoutes";
import FixedHeaderContent from "../../../../components/simple/Containers/FixedHeader/FixedHeaderContent";
import FixedSubHeader from "../../../../components/simple/Containers/FixedHeader/FixedSubHeader";

type Props = {
  onInitContractMainData: onInitContractMainData,
  history: history,
  location: locationType
};
type State = {
  isReadyToRender: isReadyToRender
};
class NewContract extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false
    };
  }
  componentDidMount(){
    this.props.onInitContractMainData();
    this.setState({isReadyToRender: true});
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
          <FixedHeaderContent title={text.contracts} history={this.props.history}/>
          <FixedSubHeader title={text.newContract}
                          buttons={<ContractButtonsRoutes organizationId={this.props.organizationId}
                                                          contractId={null}/>}
                          history={this.props.history}/>
      </RenderOnReady>
    );
  }
}
const mapStateToProps = state => {
  return {
    organizationId: state.organization.organizationId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onInitContractMainData: () => dispatch({type: contractMainDataActions.CONTRACT_MAIN_DATA_INIT})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewContract);