//@flow
import * as React from 'react';
import {connect} from "react-redux";
import Pagination from "../../../../components/composed/Pagination/Pagination";
import type {paginationCount, paginationOffset, totalUsersNumber} from "../../../../services/flowTypes/dataFlowTypes";
import usersSearchActionTypes from "../../../../store/users/usersSearch/usersSearchActions";
import {onUpdateUsersSearchProp} from "../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  count: paginationCount,
  offset: paginationOffset,
  total: totalUsersNumber,
  onUpdateUsersSearchProp: onUpdateUsersSearchProp
};

class UsersPagination extends React.Component <Props> {
  setOffset = (offset) => {
    const usersSearchObj = {offset};
    this.props.onUpdateUsersSearchProp(usersSearchObj);
  };
  setCount = (count) => {
    const usersSearchObj = {count, offset: 0 };
    this.props.onUpdateUsersSearchProp(usersSearchObj);
  };
  render() {
    return (
      <Pagination
        offset={this.props.offset}
        count={this.props.count}
        totalCount={this.props.total}
        setOffset={this.setOffset}
        setCount={this.setCount}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    count: state.users.search.count,
    offset: state.users.search.offset,
    total: state.users.searchResults.total
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateUsersSearchProp: (usersSearchObj) => dispatch({type: usersSearchActionTypes.USERS_SEARCH_PROP_UPDATE,
      value: usersSearchObj})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPagination);