//@flow
import * as React from 'react';
import {connect} from "react-redux";
import validationActions from "../../../../../../../../../../../store/validation/validationActions";
import validationPatterns from "../../../../../../../../../../../services/validationPatterns/validationPatterns";
import groupFinTermsValidationScheme from "../../../../../../../../../../../configData/groupFinTermsValidationScheme";
import {
  onGetValidationStateFormProp,
  onGetValidationStateProp
} from "../../../../../../../../../../../services/flowTypes/propFnFlowTypes";
import type {
  finTermParamsRow,
  isScrollToError,
  validationIsAllowed
} from "../../../../../../../../../../../services/flowTypes/dataFlowTypes";
import NFormGroup from "../../../../../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import type {children} from "../../../../../../../../../../../services/flowTypes/appFlowTypes";
import type {finTermIndex, propError} from "../../../../../../../../../../../services/flowTypes/componentFlowTypes";
import type {onDeleteValidationStateFormProp} from "../../../../../../../../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  finTermIndex: finTermIndex,
  params: Array<finTermParamsRow>,
  onGetValidationStateProp: onGetValidationStateProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  onDeleteValidationStateFormProp: onDeleteValidationStateFormProp,
  children: children,
  validationIsAllowed: validationIsAllowed,
  isScrollToError: isScrollToError,
  propError: propError
};

class MinMaxParamsValidator extends React.Component <Props, State> {
  validate = () => {
    const error = validationPatterns[groupFinTermsValidationScheme.finTerms.params.minMaxParams](this.props.params);
    this.props.onGetValidationStateProp('isScrollToError', false);
    this.props.onGetValidationStateFormProp('minMaxParams'+this.props.finTermIndex, error);
  };
  componentDidUpdate(prevProps){
    if (prevProps.finTermIndex !== this.props.finTermIndex
    || prevProps.params !== this.props.params) {
      this.validate();
    }
  }
  componentDidMount(){
    this.validate();
  }
  componentWillUnmount(){
    this.props.onDeleteValidationStateFormProp('minMaxParams'+this.props.finTermIndex);
  }
  render() {
    const propError = this.props.validationIsAllowed ? this.props.propError : '';
    return (
      <NFormGroup isScrollToError={this.props.isScrollToError} propError={propError} addClasses={'MinMaxParamsValidator'}>
        {this.props.children}
      </NFormGroup>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    params: state.organization.item.finTermsEdit[ownProps.finTermIndex].params,
    propError: state.validation.form['minMaxParams'+ownProps.finTermIndex],
    validationIsAllowed: state.validation.validationIsAllowed,
    isScrollToError: state.validation.isScrollToError
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetValidationStateProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onDeleteValidationStateFormProp: (prop) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_DELETE, value: prop})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MinMaxParamsValidator);