//@flow
import * as React from 'react';
import {connect} from "react-redux";
import UnviewedMark from "../../simple/Icons/UnviewedMark/UnviewedMark";
import type {isUnviewed, organizationId} from "../../../services/flowTypes/dataFlowTypes";
import type {errorObject, isReadyToRender} from "../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../service/RenderOnReady/RenderOnReady";
import api from "../../../services/apiInstance/api";
import apiErrorHandler from "../../../services/apiInstance/apiErrorHandler";
import historyDataActions from "../../../store/organization/historyData/historyDataActions";
import type {onGetHistoryViewStatus} from "../../../services/flowTypes/propFnFlowTypes";


type Props = {
  allNewIsUnviewed: isUnviewed,
  organizationId: organizationId,
  onGetHistoryViewStatus: onGetHistoryViewStatus
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject
};

class HistoryMenuUnviewedMark extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null
    };
  }
  getHistoryViewStatus = () => {
    api.getHistoryViewStatus()
      .then((response) => {
        this.props.onGetHistoryViewStatus(response.data);
      })
      .catch((error) => {
        this.setState({
          errorObject: apiErrorHandler(error)
        });
      });
  };
  componentDidUpdate(prevProps: Props, ) {
    if (prevProps.organizationId !== this.props.organizationId) {
      this.getHistoryViewStatus();
    }
  }
  componentDidMount() {
    const apiHeadersOrgId = api.apiClient.defaults.headers['X-Organization-Id'];
    if (apiHeadersOrgId && apiHeadersOrgId === this.props.organizationId) {
      this.getHistoryViewStatus();
    }
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
        <div className="HistoryMenuUnviewedMark">
          <UnviewedMark isUnviewed={this.props.allNewIsUnviewed}/>
        </div>
      </RenderOnReady>
    );
  }
}
const mapStateToProps = state => {
  return {
    allNewIsUnviewed: state.organization.historyData.viewHistory.allNewIsUnviewed,
    organizationId: state.organization.organizationId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetHistoryViewStatus: (value) => dispatch({type: historyDataActions.HISTORY_VIEW_STATUS_SET, value})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HistoryMenuUnviewedMark);    