//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id, onChange} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './WrappedDateRangePicker.css';
import type {
  dateIconComp,
  dateRangePeriodType,
  dropdownDatesValue,
  dropdownIsShown,
  isActive,
  itemDateId,
  needToInitFocus,
  propValue,
  selectOptions
} from "../../../../services/flowTypes/componentFlowTypes";
import WrappedDatePickerInput from "./WrappedDatePickerInput";
import text from "../../../../services/localization/text";
import NSwitcher from "../NSwitcher/NSwitcher";
import WrappedDayPicker from "./WrappedDayPicker";
import BtnNewItem from "../../Buttons/BtnNewItem/BtnNewItem";
import WrappedMonthPicker from "./WrappedMonthPicker";
import type {handleClickToInitInputFocus, resetNeedToInitFocus} from "../../../../services/flowTypes/propFnFlowTypes";
import CloseOnClickOutside from "../../../service/CloseOnClickOutside/CloseOnClickOutside";
import delBtnIcoSrc from "./../../../../assets/del-filter-btn-icon.svg";

type Props = {
  id?: id,
  addClasses?: addClasses,
  preferableDateRangePeriodType?: dateRangePeriodType,
  startDateId?: itemDateId,
  endDateId?: itemDateId,
  onChange: onChange,
  propValue: propValue,
  dateIconComp?: dateIconComp,
  needToInitFocus?: needToInitFocus,
  resetNeedToInitFocus?: resetNeedToInitFocus,
  handleClickToInitInputFocus?: handleClickToInitInputFocus,
  isOnlyModalMode?: boolean
};
type State = {
  dateRangePeriodType: dateRangePeriodType,
  dropdownIsShown: dropdownIsShown,
  isActive: isActive,
  dateRangePeriodTypeOptions: selectOptions,
  dropdownDatesValue: dropdownDatesValue

};

const dateRangePeriodTypes = ['day', 'month', 'quarter'];

class WrappedDateRangePicker extends React.Component <Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      dateRangePeriodType: '',
      dropdownIsShown: false,
      dateRangePeriodTypeOptions: [],
      dropdownDatesValue: {
        startDate: '',
        endDate: ''
      }
    };
  }
  onKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      this.setState({dropdownIsShown: false});
    }
  };
  onClickOutside = () => {
    this.setState({dropdownIsShown: false});
  };
  onSwitcherChange = (selectedOption) => {
    const value = selectedOption === null ? null : selectedOption.value;
    this.setState({dateRangePeriodType: value});
  };
  handleDropdownDatesValueOnFocus = () => {
    // if (this.props.propValue.startDate === '' && this.props.propValue.endDate === '') {
    //   return {startDate: formattedYesterdayValue, endDate: formattedYesterdayValue};
    // } else {
      return {...this.props.propValue};
    // }
  };
  handleDatePickerInputFocus = () => {
    this.setState({
      dropdownIsShown: true,
      dropdownDatesValue: this.handleDropdownDatesValueOnFocus()
    });
  };
  onWrappedDatePickerInputChange = (dateValue, propDateName) => {
    this.props.onChange({...this.props.propValue, [propDateName]: dateValue});
  };
  onWrappedDayPickerChange = (datesValue) => {
    this.setState({dropdownDatesValue: {...datesValue}});
  };
  onReadyBtnClick = () => {
    this.props.onChange(this.state.dropdownDatesValue);
    this.setState({dropdownIsShown: false});
  };
  onClearValuesClick = () => {
    this.props.onChange({startDate: '', endDate: ''});
    this.props.handleClickToInitInputFocus();
  };
  componentDidMount(): * {
    const dateRangePeriodTypeOptions = dateRangePeriodTypes.map(item => {
      return {value: item, label: text.dateRangePeriodTypeLabels[item]};
    });
    const dateRangePeriodType = this.props.preferableDateRangePeriodType ?
      this.props.preferableDateRangePeriodType : dateRangePeriodTypeOptions[0].value;
    this.setState({dateRangePeriodTypeOptions, dateRangePeriodType});
  }

  render() {
    const selectedSwitcherOption = this.state.dateRangePeriodTypeOptions ?
      this.state.dateRangePeriodTypeOptions.find(item => item.value === this.state.dateRangePeriodType) : null;
    const dropdownComponentClassName = this.props.isOnlyModalMode ?
      "WrappedDateRangePicker__dropdown WrappedDateRangePicker__dropdown_isOnlyModalMode"
    : "WrappedDateRangePicker__dropdown";
    const dropdownComponent = (
      <div className={dropdownComponentClassName}>
        <NSwitcher options={this.state.dateRangePeriodTypeOptions} onChange={this.onSwitcherChange}
                   value={selectedSwitcherOption} addClasses={'NSwitcher_style-v2'}/>
        <div className="WrappedDateRangePicker__section-border mt_2du mb_2du"></div>

        {this.state.dateRangePeriodType === 'day' ? (
          <div className={'flex_jc-sb'}>
            <WrappedDayPicker label={text.start} propValue={this.state.dropdownDatesValue} addClasses={'mr_2du'}
                              onChange={this.onWrappedDayPickerChange} datePropName={'startDate'}/>
            <WrappedDayPicker label={text.end} propValue={this.state.dropdownDatesValue}
                              onChange={this.onWrappedDayPickerChange} datePropName={'endDate'}/>
          </div>
        ) : null}

        {this.state.dateRangePeriodType === 'month' ? (
          <div className={''}>
            <WrappedMonthPicker label={text.start} propValue={this.state.dropdownDatesValue}
                                onChange={this.onWrappedDayPickerChange} addClasses={'mb_4du'}
                                datePropName={'startDate'}/>
            <WrappedMonthPicker label={text.end} propValue={this.state.dropdownDatesValue}
                                onChange={this.onWrappedDayPickerChange}
                                datePropName={'endDate'}/>
          </div>
        ) : null}

        {this.state.dateRangePeriodType === 'quarter' ? (
          <div className={''}>
            <WrappedMonthPicker label={text.start} propValue={this.state.dropdownDatesValue}
                                onChange={this.onWrappedDayPickerChange} addClasses={'mb_4du'}
                                isQuarterMode={true}
                                datePropName={'startDate'}/>
          </div>
        ) : null}
        <div className="WrappedDateRangePicker__section-border mt_2du mb_2du"></div>
        <BtnNewItem onClick={this.onReadyBtnClick} addClasses={'BtnNewItem_style-v2'}>
          {text.btnApplyDatesLabel}</BtnNewItem>
      </div>
    );
    return (
      this.props.isOnlyModalMode ? dropdownComponent : (
        <CloseOnClickOutside onClickOutside={this.onClickOutside} isActive={this.state.dropdownIsShown}>
          <div id={this.props.id} className={baseClass("WrappedDateRangePicker", this.props.addClasses)}>
            <div className="WrappedDateRangePicker__wrappedInputContainer">
              <WrappedDatePickerInput propValue={this.props.propValue.startDate}
                                      onFocus={this.handleDatePickerInputFocus}
                                      onKeyDown={this.onKeyDown}
                                      onDateInputChange={this.onWrappedDatePickerInputChange}
                                      placeholder={text.start}
                                      needToInitFocus={this.props.needToInitFocus}
                                      resetNeedToInitFocus={this.props.resetNeedToInitFocus}
                                      datePropName={'startDate'}/>
              <div className="WrappedDateRangePicker__inputSeparator">-</div>
              <WrappedDatePickerInput propValue={this.props.propValue.endDate}
                                      onFocus={this.handleDatePickerInputFocus}
                                      onKeyDown={this.onKeyDown}
                                      onDateInputChange={this.onWrappedDatePickerInputChange}
                                      placeholder={text.end}
                                      datePropName={'endDate'}/>
              {this.props.propValue.startDate !== '' || this.props.propValue.endDate !== '' ?
                <div className="WrappedDateRangePicker__dateIconContainer" onClick={this.onClearValuesClick}>
                  <img src={delBtnIcoSrc} alt={'Clear values'}/>
                </div> :
                this.props.dateIconComp ? this.props.dateIconComp : null}
            </div>

            {this.state.dropdownIsShown ? dropdownComponent : null}


          </div>
        </CloseOnClickOutside>
      )
    );
  }
}

export default WrappedDateRangePicker;