//@flow
import * as React from 'react';
import type {children} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";

type Props = {
  children: children,
  addClasses?: addClasses,
  alertTypeClassNameSuffix?: addClasses
};

const SimplifiedAlertWarning = (props: Props) => {
  const alertTypeClassNameSuffix = props.alertTypeClassNameSuffix ? props.alertTypeClassNameSuffix
    : "REPORT-WARNING";
  return (
    <div className={baseClass(`AlertCard__header AlertCard__header_rounded
    AlertCard__header_in_ReportTotalExpression AlertCard__header_${alertTypeClassNameSuffix}`, props.addClasses)}>
      <div className="AlertCard__header-ico"/>
      <div className="AlertCard__header-title">{props.children}
      </div>
    </div>
  );
};

export default SimplifiedAlertWarning;    