//@flow
import * as React from 'react';
import {baseClass} from "../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import type {
  handledGroupFinTerm
} from "../../../services/flowTypes/componentFlowTypes";
import Heading4 from "../../simple/TextComponents/Heading4/Heading4";
import GroupFinTermCardLabel from "../../simple/TextComponents/GroupFinTermCardLabel/GroupFinTermCardLabel";
import ArrowIcon from "../../simple/Icons/ArrowIcon/ArrowIcon";
import TextOverflowHandler from "../../simple/Containers/TextOverflowHandler/TextOverflowHandler";

type Props = {
  id?: id,
  addClasses?: addClasses,
  // payerName: agentName,
  // payeeName: agentName,
  // currencyLabel?: currencyLabel,
  // payoutPeriodLabel?: payoutPeriodLabel,
  // transactionTypeLabel?: transactionTypeLabel,
  // transactionStatusLabel?: transactionStatusLabel,
  // groupFinTermSourceLabel: groupFinTermSourceLabel
  handledGroupFinTerm: handledGroupFinTerm
};

const GroupFinTermCardInfoRow = (props: Props) => {
  const transactionStatusClassName = props.handledGroupFinTerm.transactionStatusLabel
  && props.handledGroupFinTerm.transactionStatusLabel.includes(',')
    ? "" : props.handledGroupFinTerm.transactionStatusLabel;
  return (
    <div id={props.id} className={baseClass("GroupFinTermCardInfoRow flex_jc-sb ai_fe flex_wrap", props.addClasses)}>
      <div>
        <div className="flex">
          <div className="max-w_quarter-card">
            <Heading4 addClasses={"Heading4_lh16 "}>
              <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{props.handledGroupFinTerm.payerName}</TextOverflowHandler>
            </Heading4>
            {props.handledGroupFinTerm.payerMerchantName ? (
              <Heading4 addClasses={"Heading4_lh16 Heading4_card-label-color"}>
                <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{props.handledGroupFinTerm.payerMerchantName}</TextOverflowHandler>
              </Heading4>
            ) : null}
          </div>
          <ArrowIcon addClasses={"as_c"}/>
          <div className="max-w_quarter-card">
            <Heading4 addClasses={"Heading4_lh16 "}>
              <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{props.handledGroupFinTerm.payeeName}</TextOverflowHandler>
            </Heading4>
            {props.handledGroupFinTerm.payeeMerchantName ? (
              <Heading4 addClasses={"Heading4_lh16 Heading4_card-label-color"}>
                <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{props.handledGroupFinTerm.payeeName}</TextOverflowHandler>
              </Heading4>
            ) : null}
          </div>
        </div>
        {props.handledGroupFinTerm.currencyLabel || props.handledGroupFinTerm.payoutPeriodLabel ? (
          <div className="flex mt_1du">
            {props.handledGroupFinTerm.currencyLabel ? (
              <GroupFinTermCardLabel addClasses={"mr_2du"}>{props.handledGroupFinTerm.currencyLabel}</GroupFinTermCardLabel>
            ) : null}
            {props.handledGroupFinTerm.payoutPeriodLabel ? (
              <GroupFinTermCardLabel>{props.handledGroupFinTerm.payoutPeriodLabel}</GroupFinTermCardLabel>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className={"ml_a"}>
        {props.handledGroupFinTerm.customFunction ? (
          <div className="flex jc_fe">
            <GroupFinTermCardLabel addClasses={"GroupFinTermCardLabel_italic"}>{props.handledGroupFinTerm.customFunction}</GroupFinTermCardLabel>
          </div>
        ) : null}
        {props.handledGroupFinTerm.cardTypeLabel ? (
          <div className="flex jc_fe mt_1du">
            <GroupFinTermCardLabel>{props.handledGroupFinTerm.cardTypeLabel}</GroupFinTermCardLabel>
          </div>
        ) : null}
        <div className="flex mt_1du">
          {props.handledGroupFinTerm.directionLabel ? (
            <GroupFinTermCardLabel addClasses={`mr_2du`}>
              {props.handledGroupFinTerm.directionLabel}
            </GroupFinTermCardLabel>
          ) : null}
          {props.handledGroupFinTerm.transactionStatusLabel ? (
            <GroupFinTermCardLabel addClasses={`mr_2du ${transactionStatusClassName}`}>
              {props.handledGroupFinTerm.transactionStatusLabel}
            </GroupFinTermCardLabel>
          ) : null}
          {props.handledGroupFinTerm.transactionTypeLabel ? (
            <GroupFinTermCardLabel addClasses={"mr_2du"}>{props.handledGroupFinTerm.transactionTypeLabel}</GroupFinTermCardLabel>
          ) : null}
          <GroupFinTermCardLabel addClasses={"GroupFinTermCardLabel_dark"}>{props.handledGroupFinTerm.groupFinTermSourceLabel}</GroupFinTermCardLabel>
        </div>
      </div>
    </div>
  );
};

export default GroupFinTermCardInfoRow;