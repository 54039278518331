//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  onDeleteUsersSearchProp,
  onUpdateUsersSearchProp
} from "../../../../services/flowTypes/propFnFlowTypes";
import type {
  organizationsList, rolesList, usersSearch, userStatusList
} from "../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../services/localization/text";
import type {selectOptions} from "../../../../services/flowTypes/componentFlowTypes";
import Filters from "../../../../components/composed/Filters/Filters";
import usersSearchActionTypes from "../../../../store/users/usersSearch/usersSearchActions";

type Props = {
  onUpdateUsersSearchProp: onUpdateUsersSearchProp,
  allOrganizationsList: organizationsList,
  rolesList: rolesList,
  userStatusList: userStatusList,
  onDeleteUsersSearchProp: onDeleteUsersSearchProp,
  search: usersSearch
};
type State = {
  statusOptions: selectOptions,
  organizationIdOptions: selectOptions,
  roleOptions: selectOptions
};

class UsersFilters extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      statusOptions: [],
      organizationIdOptions: [],
      roleOptions: []
    };
  }
  handleOnGetUsersFiltersPropCall = (filtersProps) => {
    const usersFiltersProps = {...filtersProps};
    if (!usersFiltersProps.hasOwnProperty('offset')) {
      usersFiltersProps.offset = 0;
    }
    this.props.onUpdateUsersSearchProp(usersFiltersProps);
  };
  getFiltersList = (allFiltersList) => {
    const filtersList = [];
    const filtersForOptions = [];
    for (let i=0; i < allFiltersList.length; i++) {
      const condition = this.props.search.hasOwnProperty(allFiltersList[i].propName);
      if (condition) {
        filtersList.push(allFiltersList[i]);
      } else {
        filtersForOptions.push(allFiltersList[i]);
      }
    }
    return {filtersList, filtersForOptions};
  };
  componentDidMount() {
    const defaultStatusOption =  {
      value: 'ALL',
      label: text.userStatus['ALL'],
      statusClassName: `StatusLabel StatusLabel_ALL`
    };
    const defaultOption =  {value: 'ALL', label: text.reportStatus['ALL']};
    const statusOptions = this.props.userStatusList.map(item => {
      return {
        value: item,
        label: text.userStatus[item],
        statusClassName: `StatusLabel StatusLabel_${item}`
      };
    });
    const roleOptions = this.props.rolesList.map(item => {
      return {value: item.name,
        label: text.role[item.name]};
    });
    const organizationIdOptions = this.props.allOrganizationsList.map(item => {
      return {value: item.id, label: item.name}
    });
    statusOptions.unshift(defaultStatusOption);
    organizationIdOptions.unshift(defaultOption);
    roleOptions.unshift(defaultOption);
    this.setState({statusOptions, organizationIdOptions, roleOptions});
  }
  render() {
    const allFiltersList = [
      {propName: 'status', formFieldType: 'filter-select', options: this.state.statusOptions, initValue: 'ALL'},
      {propName: 'organizationId', formFieldType: 'filter-select', options: this.state.organizationIdOptions, initValue: 'ALL'},
      {propName: 'role', formFieldType: 'filter-select', options: this.state.roleOptions, initValue: 'ALL'}
    ];
    const {filtersList, filtersForOptions} = this.getFiltersList(allFiltersList);
    const resetFilters = () => this.props.onDeleteUsersSearchProp(['status', 'organizationId', 'role']);
    return (
      <Filters onGetFiltersProp={this.handleOnGetUsersFiltersPropCall}
               onDeleteFiltersProp={this.props.onDeleteUsersSearchProp}
               filtersState={this.props.search}
               filtersForOptions={filtersForOptions}
               filtersList={filtersList}
               resetFilters={resetFilters}
               addClasses={'mt_2du'}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    rolesList: state.admin.rolesList,
    userStatusList: state.admin.statuses.userStatusList,
    allOrganizationsList: state.admin.allOrganizationsList,
    search: state.users.search
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateUsersSearchProp: (usersSearchObj) => dispatch({type: usersSearchActionTypes.USERS_SEARCH_PROP_UPDATE,
      value: usersSearchObj}),
    onDeleteUsersSearchProp: (usersPropNameArr) => dispatch({
      type: usersSearchActionTypes.USERS_SEARCH_PROP_DELETE,
      value: usersPropNameArr
    })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersFilters);