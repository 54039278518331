//@flow
import * as React from 'react';
import {baseClass} from "../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {button, history, id} from "../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import './HeaderContent.css';
import type {HeaderContentInfo, HeaderContentTitle, isSupLevel} from "../../../services/flowTypes/componentFlowTypes";
import TextOverflowHandler from "../../simple/Containers/TextOverflowHandler/TextOverflowHandler";
import NHeading1 from "../../simple/TextComponents/NHeading1/NHeading1";
import OrganizationSidebar from "../OrganizationSidebar/OrganizationSidebar";
import {Link} from "react-router-dom";

type Props = {
  id?: id,
  addClasses?: addClasses,
  title: HeaderContentTitle,
  subtitle?: HeaderContentTitle,
  info?: HeaderContentInfo,
  buttons?: Array<button>,
  titleId?: id,
  history?: history,
  isSupLevel?: isSupLevel,
  titleLinkTo?: string
};

const HeaderContent = (props: Props) => {
  let element = <NHeading1 id={props.titleId} addClasses={props.subtitle ? '' : 'NHeading1_lh28'} title={props.title}>
    <TextOverflowHandler addClasses={"TextOverflowHandler_mw-100"}>{props.title}</TextOverflowHandler>
    {props.subtitle &&
      <TextOverflowHandler addClasses={"TextOverflowHandler_mw-100 HeaderContentSubtitle"}>
        {props.subtitle}
      </TextOverflowHandler>
    }
  </NHeading1>;
  return (
    <div id={props.id} className={baseClass("HeaderContent", props.addClasses)}>
      <div className="HeaderContent__left-div">
        {props.titleLinkTo
          ? <Link to={props.titleLinkTo}>{element}</Link>
          : element
        }
        {props.info}
      </div>
      <div className="flex">
        {props.buttons ? (
          props.buttons
        ) : null}
        {props.isSupLevel ? null : <OrganizationSidebar history={props.history}/>}
      </div>
    </div>
  );
};

export default HeaderContent;