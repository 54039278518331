//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  paramsBlockFieldIndex,
  paramsBlockIndex, propValidationPatternsArr
} from "../../../../../services/flowTypes/componentFlowTypes";
import {
  onDeleteField
} from "../../../../../services/flowTypes/propFnFlowTypes";
import RenderOnReady from "../../../../service/RenderOnReady/RenderOnReady";
import type {isReadyToRender} from "../../../../../services/flowTypes/appFlowTypes";
import contractMainDataValidationScheme from "../../../../../configData/contractMainDataValidationScheme";
import {contractEditPageIds} from "../../../../../tests/testIds";
import ParamsBlockFieldValue from "./ParamsBlockFieldValue/ParamsBlockFieldValue";
import defineFormFieldType from "../../../../../utils/defineFormFieldType";
import type {objectParamLabel, objectParamName, objectParamType} from "../../../../../services/flowTypes/dataFlowTypes";

type Props = {
  paramsBlockIndex: paramsBlockIndex,
  paramsBlockFieldIndex: paramsBlockFieldIndex,
  paramType: objectParamType,
  paramLabel: objectParamLabel,
  onDeleteField: onDeleteField,
  paramName: objectParamName
};
type State = {
  isReadyToRender: isReadyToRender,
  propValidationPatternsArr: propValidationPatternsArr
};
class ParamsBlockField extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      propValidationPatternsArr: []
    };
  }
  setPropName = () => {
    return 'block' + this.props.paramsBlockIndex + 'paramValue' + this.props.paramsBlockFieldIndex;
  };
  setValueType = () => {
    if (this.props.paramType === 'DATE') {
      return 'dateValue';
    } else {
      return 'textValue';
    }
  };
  componentDidMount(){
    this.setState({
      propValidationPatternsArr: contractMainDataValidationScheme.params.paramValue,
      isReadyToRender: true
    });
  }
  render() {
    const formFieldType = defineFormFieldType(this.props.paramType);
    const propName = this.setPropName();
    const valueType = this.setValueType();
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
        <ParamsBlockFieldValue
          paramsBlockIndex={this.props.paramsBlockIndex}
          paramsBlockFieldIndex={this.props.paramsBlockFieldIndex}
          formFieldType={formFieldType}
          onDeleteField={this.props.onDeleteField}
          valueType={valueType}
          propName={propName}
          propValidationPatternsArr={this.state.propValidationPatternsArr}
          label={this.props.paramLabel}
          addClasses={'mb_2du'}
          idForTest={contractEditPageIds.inputBlockParamValue(this.props.paramsBlockIndex, this.props.paramName)}
        />
      </RenderOnReady>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    paramType: state.organization.item.itemDataParamsEdit[ownProps.paramsBlockIndex].fields[ownProps.paramsBlockFieldIndex].type,
    paramLabel: state.organization.item.itemDataParamsEdit[ownProps.paramsBlockIndex].fields[ownProps.paramsBlockFieldIndex].label,
    paramName: state.organization.item.itemDataParamsEdit[ownProps.paramsBlockIndex].fields[ownProps.paramsBlockFieldIndex].name
  };
};

export default connect(mapStateToProps, null)(ParamsBlockField);