//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {children, id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './TextOverflowHandler.css';
import type {isInputWrapperMode, propValue} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  isInputWrapperMode?: isInputWrapperMode,
  inputValue?: propValue
};

const TextOverflowHandler = (props: Props) => {
  const title = props.isInputWrapperMode ? props.inputValue : props.children;
  return (
    <div id={props.id} title={title} className={baseClass("TextOverflowHandler", props.addClasses)}>
      {props.children}</div>
  );
};

export default TextOverflowHandler;