//@flow
import documentFoldersActions from './documentFoldersActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {documentFolderList} from "../../../../services/flowTypes/dataFlowTypes";

type State = documentFolderList;
type reducerAction = action;
const initialState = [];

const documentFoldersReducer = (state:State = initialState, action:reducerAction) => {
  switch (action.type) {
    case documentFoldersActions.ITEM_FOLDERS_STATE_INIT:
      return initialState;
    case documentFoldersActions.ITEM_FOLDERS_LIST_SET:
      return action.value;
    default:
      return state;
  }
};

export default documentFoldersReducer;