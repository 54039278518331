const getCsrf = () => {
  let csrf;
  if (window.APP_DATA) {
    csrf = window.APP_DATA.csrfToken;
  } else {
    const storageAppData = localStorage.getItem('appData');
    if (storageAppData) {
      const parsedAppData = JSON.parse(storageAppData);
      csrf = parsedAppData.csrfToken;
    }
    localStorage.removeItem('appData');
  }
  return csrf;
};
export default getCsrf;