//@flow
import * as React from 'react';
import uiKitData from "./uiKitData/uiKitData";
import ImportComponentWithClickToCopy from "./ImportComponentWithClickToCopy/ImportComponentWithClickToCopy";
import type {uiKitDataType, uiKitType} from "../../../services/flowTypes/uiKitFlowTypes";
import NHeading1 from "../../../components/simple/TextComponents/NHeading1/NHeading1";
import NCard from "../../../components/simple/Containers/NCard/NCard";






type Props = {};

class NewUiKitContent extends React.Component <Props, void> {
  // constructor(props:Props){
  //   super(props);
  //   this.state = {
  //   };
  // }

  makeUiKit = (dataUiKit:uiKitDataType) => {
    const UiKit:uiKitType = dataUiKit.map((uiKitGroup,ukgIndex) => {
      const uiKitGroupHeading = (<NHeading1 addClasses="mb_2du mt_4du" id={uiKitGroup.title}>{uiKitGroup.title}</NHeading1>);
      const uiKitGroupItems = uiKitGroup.group.map((item, index) => {
        const ItemComponent = item.component;
        const children = item.hasChildren ?
          (item.hasChildren === false ? null : item.hasChildren)
          : item.title;
        const componentToImport = item.hasChildren === false ?
          ('<' + item.title + '/>') :
          ('<' + item.title + '></' + item.title + '>');
        const defProps = item.defProps ? item.defProps : {};
        const addClasses = item.hideBackCard ? 'mb_1du NCard_hideBackCard' : 'mb_1du';
        return (
          <NCard addClasses={addClasses} key={'rf' + item.title + index}>
            <ItemComponent id={item.title} children={children} {...defProps}/>
            <ImportComponentWithClickToCopy>{componentToImport}</ImportComponentWithClickToCopy>
          </NCard>
          );
      });
      return (
        <React.Fragment key={'rfukg' + ukgIndex}>
          {uiKitGroupHeading}
          {uiKitGroupItems}
        </React.Fragment>
      );
    });
    return UiKit;
  };
  render(){
    return (
      <div className="UiKitContent">
        {this.makeUiKit(uiKitData)}
      </div>
    );
  }

}

export default NewUiKitContent;