const dropDownOptions = [
  {value: 'option1', label: 'Option 1'},
  {value: 'option2', label: 'Option 2'},
  {value: 'option3', label: 'Option 3'},
  {value: 'option4', label: 'Option 4'}
];

export const DropDownSelectDefProps = {
  options: dropDownOptions,
  selectedOption: dropDownOptions[0],
  onDropDownSelectChange: () => {}
};