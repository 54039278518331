//@flow
import * as React from 'react';
import {connect} from "react-redux";
import BtnEditAct from "../../../../../../../../components/simple/Buttons/BtnEditAct/BtnEditAct";
import text from "../../../../../../../../services/localization/text";
import type {addClasses} from "../../../../../../../../services/flowTypes/uiKitFlowTypes";
import type {errorObject, isReadyToRender, modalIsShown} from "../../../../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import api from "../../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../../services/apiInstance/apiErrorHandler";
import {onGetReportMainData} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import reportMainDataActions
  from "../../../../../../../../store/organization/item/reportMainData/reportMainDataActions";
import type {reportId} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import {reportViewPageIds} from "../../../../../../../../tests/testIds";
import ReportAdjustmentCautionModal from "../ReportAdjustmentCautionModal/ReportAdjustmentCautionModal";

type Props = {
  addClasses: addClasses,
  onGetReportMainData: onGetReportMainData,
  reportId: reportId
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  modalIsShown: modalIsShown
};

class ReportAutoAdjustment extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true,
      modalIsShown: false
    };
  }
  showModal = () => {this.setState({modalIsShown: true});};
  hideModal = () => {this.setState({modalIsShown: false});};
  autoAdjust = () => {
    this.setState({isReadyToRender: false});
    api.autoAdjustReport(this.props.reportId)
      .then(response => {
        this.props.onGetReportMainData(response.data);
        this.setState({
          isReadyToRender: true,
          modalIsShown: false
        });
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          modalIsShown: false,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    return (
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <BtnEditAct id={reportViewPageIds.buttonAutoAdjust} addClasses={this.props.addClasses}
                    onClick={this.showModal}>{text.autoAdjustment}</BtnEditAct>
        {this.state.modalIsShown ? (
          <ReportAdjustmentCautionModal cancelModal={this.hideModal} proceedFn={this.autoAdjust}/>
        ) : null}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetReportMainData: (reportMainData) => dispatch({
      type: reportMainDataActions.REPORT_MAIN_DATA_SET,
      value: reportMainData})
  };
};

export default connect(null, mapDispatchToProps)(ReportAutoAdjustment);