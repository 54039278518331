//@flow
import * as React from 'react';
import {connect} from "react-redux";
import DocumentsListFetcher from "./DocumentsListFetcher/DocumentsListFetcher";
import type {
  isDocumentsListFetcherMounted,
  itemType,
  permittedActionObj
} from "../../../../services/flowTypes/componentFlowTypes";
import type {
  agentId,
  contractId,
  documentFolderList,
  documentsList,
  folderId
} from "../../../../services/flowTypes/dataFlowTypes";
import RenderOnReady from "../../../service/RenderOnReady/RenderOnReady";
import type {errorObject, isReadyToRender} from "../../../../services/flowTypes/appFlowTypes";
import NCard from "../../../simple/Containers/NCard/NCard";
import DocumentEdit from "../DocumentEdit/DocumentEdit";
import apiErrorHandler from "../../../../services/apiInstance/apiErrorHandler";
import text from "../../../../services/localization/text";
import NHeading3 from "../../../simple/TextComponents/NHeading3/NHeading3";
import EmptyListV2 from "../../../simple/Containers/EmptyList/EmptyListV2";
import Text1 from "../../../simple/TextComponents/Text1/Text1";

type Props = {
  itemType: itemType,
  itemId: contractId | agentId,
  documentsList: documentsList,
  permittedActionObj: permittedActionObj,
  folderId: folderId,
  folderList: documentFolderList,
  isAllFilesShown: boolean,
  pathname: string
};
type State = {
  isReadyToRender: isReadyToRender,
  isDocumentsListFetcherMounted: isDocumentsListFetcherMounted,
  errorObject: errorObject
};
class DocumentsList extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      isDocumentsListFetcherMounted: true
    };
  }
  setReadyToRender = () => {
    this.setState({isReadyToRender: true});
  };
  unmountDocumentsListFetcher = () => {
    this.setState({
      isReadyToRender: false,
      isDocumentsListFetcherMounted: false
    });
  };
  mountDocumentsListFetcher = () => {
    this.setState({isDocumentsListFetcherMounted: true});
  };
  setErrorObject = (error) => {
    this.setState({
      errorObject: apiErrorHandler(error),
      isReadyToRender: true
    });
  };
  render() {
    const documents = this.props.documentsList.map((item, i) => {
      return (<DocumentEdit key={'document'+i} document={item}
                            documentIndex={i}
                            folderId={this.props.folderId}
                            // folderList={this.props.folderList}
                            pathname={this.props.pathname}
                            itemType={this.props.itemType} itemId={this.props.itemId}
                            mountDocumentsListFetcher={this.mountDocumentsListFetcher}
                            unmountDocumentsListFetcher={this.unmountDocumentsListFetcher}
                            setErrorObject={this.setErrorObject} permittedActionObj={this.props.permittedActionObj}/>);
    });
    return (
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        {this.state.isDocumentsListFetcherMounted ? (
          <DocumentsListFetcher itemType={this.props.itemType} setReadyToRender={this.setReadyToRender} folderId={this.props.folderId}
                                itemId={this.props.itemId} isAllFilesShown={this.props.isAllFilesShown}/>
        ) : null}
        {documents.length ? (
          <NCard addClasses={'NCard__wide-width'}>
            {this.props.folderId ? null : <NHeading3 addClasses='mb_3du'>
              {this.props.isAllFilesShown ? text.files : text.filesOutOfFolders}</NHeading3>}
            { documents }
          </NCard>
        ) : (
          <EmptyListV2 insertedLabelComponent={
            <Text1 addClasses={"mt_3du"}>{text.emptyListLabel.itemDocuments}</Text1>
          }/>
        )}
      </>

    );
  }
}

const mapStateToProps = state => {
  return {
    documentsList: state.organization.item.itemDocuments,
    folderList: state.organization.item.documentFolders
  };
};
export default connect(mapStateToProps, null)(DocumentsList);