//@flow
import * as React from 'react';
import {connect} from "react-redux";
import text from "../../../services/localization/text";
import SectionHeader from "../SectionHeader/SectionHeader";
import type {paramsLength} from "../../../services/flowTypes/dataFlowTypes";
import {
  onAddParamsBlock,
  onDeleteParamsBlock
} from "../../../services/flowTypes/propFnFlowTypes";
import ParamsBlock from "./ParamsBlock/ParamsBlock";
import BtnEditAct from "../../simple/Buttons/BtnEditAct/BtnEditAct";
import itemDataParamsEditActions from "../../../store/organization/item/itemDataParamsEdit/itemDataParamsEditActions";
import {contractEditPageIds} from "../../../tests/testIds";
import SectionDivider from "../../simple/Containers/SectionDivider/SectionDivider";
import getUniqueKey from "../../../utils/getUniqueKey";

type Props = {
  paramsLength: paramsLength,
  onDeleteParamsBlock: onDeleteParamsBlock,
  onAddParamsBlock: onAddParamsBlock
};

class ItemDataParamsEdit extends React.Component <Props, void> {
  render() {
    let paramsBlocks = [];
    for (let i=0; i < this.props.paramsLength; i++ ) {
      const onDeleteBlock = () => this.props.onDeleteParamsBlock(i);
      paramsBlocks.push(<ParamsBlock
        key={getUniqueKey()}
        paramsBlockIndex={i} onDeleteBlock={onDeleteBlock}/>);
    }
    return (
      <div className="ItemDataParamsEdit">
        <SectionDivider addClasses={'mt_5du mb_5du'}/>
        <SectionHeader sectionHeaderTitle={text.addParamsTitle} addClasses={"mb_3du"}/>
        {paramsBlocks}
        <BtnEditAct addClasses={'BtnEditAct_addBlock m_a'} onClick={this.props.onAddParamsBlock}
                    id={contractEditPageIds.buttonAddBlock}>
          {text.addBlock}
        </BtnEditAct>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paramsLength: state.organization.item.itemDataParamsEdit.length
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onDeleteParamsBlock: (paramsBlockIndex) => dispatch({
      type: itemDataParamsEditActions.PARAMS_BLOCK_DELETE, value: paramsBlockIndex}),
    onAddParamsBlock: () => dispatch({type: itemDataParamsEditActions.PARAMS_BLOCK_ADD})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ItemDataParamsEdit);