//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import SourceParamFormGroup from "./SourceParamFormGroup";
import type {
  formFieldType,
  labelTooltip,
  propName,
  selectOptions,
  sourceParamIndex
} from "../../../../../../../../../../services/flowTypes/componentFlowTypes";
import type {isReadyToRender, label, placeholder} from "../../../../../../../../../../services/flowTypes/appFlowTypes";
import defineFormFieldType from "../../../../../../../../../../utils/defineFormFieldType";
import type {
  groupFinTermSource,
  managerIds,
  merchantParams,
  paramMask,
  processorIds,
  sourceParamName,
  sourceParams,
  sourceParamType
} from "../../../../../../../../../../services/flowTypes/dataFlowTypes";
import {paynetSource} from "../../../../../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../../../../../services/localization/text";
import createOption from "../../../../../../../../../../utils/createOption";
import {groupFinTermEditPageIds} from "../../../../../../../../../../tests/testIds";
import QuestionTooltip
  from "../../../../../../../../../../components/simple/Containers/QuestionTooltip/QuestionTooltip";
import {getSourceParams} from "../../../../../../../../../../utils/paynetSourcea";
import sourceParamsEditActions
  from "../../../../../../../../../../store/organization/item/sourceParamsEdit/sourceParamsEditActions";
import {onUpdateSourceParam} from "../../../../../../../../../../services/flowTypes/propFnFlowTypes";
import SmallTextBtn from "../../../../../../../../../../components/simple/Buttons/SmallTextBtn/SmallTextBtn";
import './SourceParam.css'
import {isSourceParamVisible} from "../../../../../../../../../../utils/sourceParamsUtil";

type Props = {
  onUpdateSourceParam: onUpdateSourceParam,
  sourceParamIndex: sourceParamIndex,
  sourceParamType: sourceParamType,
  sourceParamName: sourceParamName,
  sourceParamValue: string,
  existValue: string,
  configSourceParams: sourceParams,
  sourceParamMask?: paramMask,
  source: groupFinTermSource,
  ignoreGates: boolean
};
type State = {
  isReadyToRender: isReadyToRender,
  formFieldType: formFieldType,
  propName: propName,
  propValue: string,
  label: label,
  options: selectOptions,
  labelTooltip?: labelTooltip,
  placeholder: placeholder,
  isVisible: boolean
};

class SourceParam extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      formFieldType: '',
      propName: '',
      label: '',
      placeholder: '',
      options: [],
      labelTooltip: null
    };
  }

  setPropName = () => {
    return 'sourceParam' + this.props.sourceParamIndex + this.props.sourceParamName;
  };
  setOptions = () => {
    const configSourceParam = this.props.configSourceParams.find(item => item.name === this.props.sourceParamName);
    const options = configSourceParam && configSourceParam.options ? configSourceParam.options : null;
    if (options) {
      return options.map(item => {
        return createOption(item);
      });
    } else {
      return [];
    }
  };
  setLabelTooltip = () => {
    const labelTooltipText = text.sourceParamTooltip[this.props.sourceParamName];
    if (labelTooltipText) {
      return <QuestionTooltip isHighZIndex={true}>{labelTooltipText}</QuestionTooltip>;
    } else if (this.props.sourceParamValue && this.props.existValue && this.props.existValue !== '' && this.props.existValue === this.props.sourceParamValue) {
      return <QuestionTooltip isHighZIndex={true}>{text.sourceParams.pastedDataFromContractSource}</QuestionTooltip>;
    } else if (this.props.sourceParamValue && this.props.sourceParamValue !== ''){
      return <SmallTextBtn
        addClasses={"ml_1du SmallTextBtn--text_transform_none"}
        onClick={()=> {this.props.onUpdateSourceParam(this.props.sourceParamIndex, this.props.sourceParamValue);}}
      >
        <span title={this.props.sourceParamValue}>{text.sourceParams.pasteDataFromContractSource}</span>
      </SmallTextBtn>
    }
    return null
  };

  componentDidMount() {
    this.setState({
      isReadyToRender: true,
      formFieldType: defineFormFieldType(this.props.sourceParamType),
      propName: this.setPropName(),
      label: text.sourceParams.editLabel[this.props.source][this.props.sourceParamName],
      options: this.setOptions(),
      placeholder: this.setOptions() ? text.select.placeholder[this.props.sourceParamName] : null,
      labelTooltip: this.setLabelTooltip(),
      isVisible: isSourceParamVisible(this.props.sourceParamName, this.props.ignoreGates)
    });
    if (this.props.existValue === '' && this.props.sourceParamValue) {
      this.props.onUpdateSourceParam(this.props.sourceParamIndex, this.props.sourceParamValue);
    }
  }

  render() {
    if (!this.state.isVisible) {
      return null
    }
    const inputDelimiter = this.state.formFieldType === 'input-with-delimiter' ? ',' : null;
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
        <SourceParamFormGroup
          sourceParamIndex={this.props.sourceParamIndex}
          formFieldType={this.state.formFieldType}
          propName={this.state.propName}
          label={this.state.label}
          options={this.state.options}
          placeholder={this.state.placeholder}
          idForTest={groupFinTermEditPageIds.inputSourceParam(this.props.sourceParamName)}
          selectInputId={groupFinTermEditPageIds.selectInputSourceParam(this.props.sourceParamName)}
          isSearchable={true}
          inputDelimiter={inputDelimiter}
          mask={this.props.sourceParamMask}
          addClasses={`SourceParam_${this.props.sourceParamName}`}
          labelTooltip={this.setLabelTooltip()}
        />
      </RenderOnReady>
    );
  }
}

const normalizeModel = (source: string,
                        merchantParams: merchantParams,
                        managerIds: managerIds,
                        processorIds: processorIds
): paynetSource[] => {
  const {merchant, manager, processor} = getSourceParams(source, merchantParams, managerIds, processorIds)

  return {
    source,
    merchantIds: merchant && merchant.merchantId ? merchant.merchantId.join(',') : '',
    managerIds: manager && manager.managerId ? manager.managerId.join(',') : '',
    processorIds: processor && processor.processorId ? processor.processorId.join(',') : '',
  };
}

const mapStateToProps = (state, ownProps) => {
  const source = state.organization.item.groupFinTermsEdit.source;
  const configSourceParams = source ? state.organization.organizationData.configFinTerms.configSourceParams[source].sourceParams : [];

  if (!configSourceParams) {
    console.error("No sourceParams for such source!");
    return {
      source: source,
      sourceParamType: '',
      sourceParamName: '',
      existValue: '',
      configSourceParams
    };
  }

  let {merchantParams, managerIds, processorIds} = state.organization.item.contractMainData;
  const model = normalizeModel(source, merchantParams, managerIds, processorIds)

  let sourceParamName = state.organization.item.sourceParamsEdit[ownProps.sourceParamIndex].name;
  let existValue = state.organization.item.sourceParamsEdit[ownProps.sourceParamIndex].value || '';
  let sourceParamValue = model && model[sourceParamName] ? model[sourceParamName] : ''
  let sourceParamMask = configSourceParams.find(({name}) => name === sourceParamName);

  return {
    source: source,
    sourceParamType: state.organization.item.sourceParamsEdit[ownProps.sourceParamIndex].type,
    existValue,
    sourceParamValue,
    sourceParamName,
    sourceParamMask: sourceParamMask['mask'],
    configSourceParams: configSourceParams,
    ignoreGates: state.organization.item.contractMainData.ignoreGates
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateSourceParam: (sourceParamIndex, sourceParamValue) => dispatch({
      type: sourceParamsEditActions.SOURCE_PARAM_UPDATE,
      value: {sourceParamIndex, sourceParamValue}
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceParam);