//@flow
import * as React from 'react';
import NModal from "../../../components/simple/Modals/NModal/NModal";
import CheckboxInput from "../../../components/simple/FormComponents/CheckboxInput/CheckboxInput";
import CheckListContainer
  from "../../../components/simple/FormComponents/CheckListContainer/CheckListContainer";
import Text1 from "../../../components/simple/TextComponents/Text1/Text1";
import BtnEditAct from "../../../components/simple/Buttons/BtnEditAct/BtnEditAct";
import type {
  checkedCount, isLabelInsteadOfName,
  listItems,
  sectionHeaderTitle
} from "../../../services/flowTypes/componentFlowTypes";
import Heading4 from "../../../components/simple/TextComponents/Heading4/Heading4";
import text from "../../../services/localization/text";
import {getCheckedListItems, hideModal} from "../../../services/flowTypes/propFnFlowTypes";
import {contractEditPageIds, contractsPageIds} from "../../../tests/testIds";
import './CheckboxesModal.css';
import NInput from "../../simple/FormComponents/NInput/NInput";

type Props = {
  listItems: listItems,
  getCheckedListItems: getCheckedListItems,
  hideModal: hideModal,
  modalTitle: sectionHeaderTitle,
  isLabelInsteadOfName?: isLabelInsteadOfName,
  showSearch?: boolean,
  isModalActionBtnDisabled?: boolean
};
type State = {
  listItems: listItems,
  checkedCount: checkedCount,
  searchVal: string
};

class CheckboxesModal extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      listItems: [],
      checkedCount: 0,
      searchVal: ''
    };
  }
  resetCheckboxes = () => {
    const listItems = this.state.listItems.map(item => {
      return {...item, checked: false};
    });
    const checkedCount = 0;
    this.setState({listItems, checkedCount});
  };
  handleCheckboxClick = (index:number) => {
    const checked = !this.state.listItems[index].checked;
    const listItems = this.state.listItems.map(item => {
      return {...item};
    });
    let checkedCount = checked ? (this.state.checkedCount + 1) : (this.state.checkedCount - 1);
    listItems[index].checked = checked;
    this.setState({listItems, checkedCount});
  };
  addCheckedListItems = () => {
    const checkedListItems = this.state.listItems.filter(item => item.checked);
    checkedListItems.forEach(item => { delete item.checked});
    this.props.getCheckedListItems(checkedListItems);
  };
  handleSearch = (e) => {
    this.setState({searchVal: e.target.value});
  };
  componentDidMount(){
    const listItems = this.props.listItems.map(item => {
      return {...item, checked: false};
    });
    this.setState({listItems});
  }
  render() {
    const listItems = this.state.listItems.map((item, i) => {
      const checkboxLabel = this.props.isLabelInsteadOfName ? item.label : item.name;
      if(this.props.showSearch && !checkboxLabel.toLowerCase().includes(this.state.searchVal.toLowerCase())) return null;
      const onClick = () => this.handleCheckboxClick(i);
      return (<CheckboxInput key={'agent'+i} checked={item.checked} onClick={onClick} checkboxLabel={checkboxLabel}
                             id={contractEditPageIds.checkboxListItem(i)} dataUiTestId={item.name}/>);
    });
    const listItemsToDisplay = listItems.filter(item => item !== null);
    return (
      <NModal
        addClasses=''
        modalTitle={this.props.modalTitle}
        onModalCancelBtnClick={this.props.hideModal}
        onModalActionBtnClick={this.addCheckedListItems}
        modalCancelBtnText={text.cancel}
        modalActionBtnText={text.btnAdd}
        isModalActionBtnDisabled={this.props.isModalActionBtnDisabled}
      >
        {listItems.length ? (
          <>
            <div className="flex_jc-sb pt-pb_2du">
              <div className={"flex"}>
                <Text1 addClasses={"mr_1du"}>{text.checkedFields}</Text1>
                <Heading4 addClasses={"Heading4_lh16"}>{this.state.checkedCount}</Heading4>
              </div>
              <BtnEditAct onClick={this.resetCheckboxes}>{text.nullify}</BtnEditAct>
            </div>

            <div className='CheckboxModal__content-wrapper'>
              {this.props.showSearch ? (
                <NInput id={contractsPageIds.inputSearch}
                        addClasses={'mb_3du'}
                  // className="SearchInput__input SearchInput__input_border pl-pr_2du SearchInput__input_full-width mb_2du SearchInput__input_ml_0"
                        value={this.state.searchVal}
                        onChange={this.handleSearch}
                        placeholder={text.search.placeholder.general}/>
              ) : null}
              {listItemsToDisplay.length ?
                  <CheckListContainer addClasses={"mb_3du"}>
                    {listItemsToDisplay}
                  </CheckListContainer>
                  : null}
            </div>
          </>
        ) : (<div className="pt-pb_2du">{text.noFieldsToAdd}</div>)}
      </NModal>
    );
  }
}

export default CheckboxesModal;