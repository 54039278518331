//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  availableReportAccountStatuses,
  report,
  reportAccountStatus,
  reportAccountStatusList, reportAccountStatusName, reportIds
} from "../../../../services/flowTypes/dataFlowTypes";
import ReportCard from "./ReportCard/ReportCard";
import BulkUpdateReportAccountStatus from "./BulkUpdateReportAccountStatus/BulkUpdateReportAccountStatus";
import EmptyListV2 from "../../../../components/simple/Containers/EmptyList/EmptyListV2";

type Props = {
  items: Array<report>,
  reportAccountStatusFromPath: reportAccountStatus,
  statusFromParams: reportAccountStatusName,
  reportAccountStatusList: reportAccountStatusList,
  history: Object
};
type State = {
  availableReportAccountStatuses: availableReportAccountStatuses,
  reportIds: reportIds
};

class ReportsSearchResults extends React.Component <Props, State> {
  constructor(props: Props){
    super(props);
    this.state = {
      availableReportAccountStatuses: [],
      reportIds: []
    };
  }
  updateAvailableReportAccountStatuses = (availableReportAccountStatuses) => {
    this.setState({availableReportAccountStatuses})
  };
  updateReportIds = (reportIds) => {this.setState({reportIds})};
  componentDidUpdate(prevProps){
    if (prevProps.reportAccountStatusFromPath !== this.props.reportAccountStatusFromPath) {
      const prevReportAccountStatusName =
        !prevProps.reportAccountStatusFromPath || prevProps.reportAccountStatusFromPath === 'ALL' ? 'ALL'
        : this.props.reportAccountStatusList.find(item => item.id === prevProps.reportAccountStatusFromPath).name;
      if (prevReportAccountStatusName === "PREPARED") {
        this.setState({reportIds: []});
      }
    }
    if (prevProps.items !== this.props.items) {
      this.setState({reportIds: []});
    }
  }
  render() {
    const reportsCards = this.props.items === null ? null :
      this.props.items && this.props.items.length ?
      this.props.items.map((item, index)=> {
        return <ReportCard key={'ReportCard' + index} report={item} reportIndex={index}
                           statusFromParams={this.props.statusFromParams}
                           updateReportIds={this.updateReportIds} checkedReportIds={this.state.reportIds}/>
      })
      : (<EmptyListV2/>);
    return (
      <>
        {(this.props.statusFromParams === "PREPARED" || this.props.statusFromParams === "ADJUSTED") ? (
          <BulkUpdateReportAccountStatus
            availableReportAccountStatuses={this.state.availableReportAccountStatuses}
            reportIds={this.state.reportIds}
            reportAccountStatus={this.props.reportAccountStatusFromPath}
            updateAvailableReportAccountStatuses={this.updateAvailableReportAccountStatuses}
            updateReportIds={this.updateReportIds}
            history={this.props.history}
          />
        ) : null}
        <div className="">
          {reportsCards}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    items: state.organization.reports.items,
    reportAccountStatusFromPath: state.organization.reportsSearch.reportAccountStatusFromPath,
    reportAccountStatusList: state.organization.organizationData.statuses.reportAccountStatusList
  };
};
export default connect(mapStateToProps, null)(ReportsSearchResults);