//@flow
import * as React from 'react';
import type {
  reportId
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import ReportAutoAdjustment from "./ReportAutoAdjustment/ReportAutoAdjustment";
import ReportManualAdjustment from "./ReportManualAdjustment/ReportManualAdjustment";

type Props = {
  reportId: reportId
};

class ReportAdjustment extends React.Component <Props> {
  render() {
    return (
      <div className={'mb_3du mt_3du'}>
        <div className="w_half-card mb_2du">
          <ReportAutoAdjustment reportId={this.props.reportId}/>
        </div>
        <div className="w_half-card">
          <ReportManualAdjustment reportId={this.props.reportId}/>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   return {
//
//     reportAccountStatus: state.organization.item.reportMainData.reportAccountStatus,
//     reportId: state.organization.item.reportMainData.id
//   };
// };

// const mapDispatchToProps = dispatch => {
//   return {
//     onTest: (value) => dispatch({type:, value:})
//   };
// };

export default ReportAdjustment;
// export default connect(mapStateToProps, null)(ReportAdjustment);