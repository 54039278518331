//@flow
import * as React from 'react';
import {Redirect, Route} from "react-router-dom";
import {
  editNotificationsSuffix,
  historySuffix,
  mainDataSuffix,
  newOrganizationSuffix,
  newUserSuffix, paramsSuffix, supMyOrgsPath, supOrgPath, supOrgPathEdit, supOrgPathView,
  supOrgsPath, supSettPath,
  supUserPath, supUserPathEdit, supUserPathView,
  supUsersPath, userOrganizationsSuffix, viewNotificationsSuffix
} from "../../../services/urlStrings/urlStrings";
import PrivateRoute, {PrivateRouteInRightSidebar} from "./PrivateRoute";
import Organizations from "../../../supPages/Organizations/Organizations";
import Users from "../../../supPages/Users/Users";
import ability from "../../../services/permissions/permissions";
import NewUser from "../../../supPages/Users/pages/User/NewUser";
import UserMainDataEdit from "../../../supPages/Users/pages/User/pages/UserMainDataEdit/UserMainDataEdit";
import UserOrganizations from "../../../supPages/Users/pages/User/pages/UserOrganizations/UserOrganizations";
import User from "../../../supPages/Users/pages/User/User";
import UserMainDataView from "../../../supPages/Users/pages/User/pages/UserMainDataView/UserMainDataView";
import MyOrganizations from "../../../supPages/MyOrganizations/MyOrganizations";
import Organization from "../../../supPages/Organizations/pages/Organization/Organization";
import OrganizationMainDataView
  from "../../../supPages/Organizations/pages/Organization/pages/OrganizationMainDataView/OrganizationMainDataView";
import OrganizationMainDataEdit
  from "../../../supPages/Organizations/pages/Organization/pages/OrganizationMainDataEdit/OrganizationMainDataEdit";
import NewOrganization from "../../../supPages/Organizations/pages/Organization/NewOrganization";
import OrganizationParams
  from "../../../supPages/Organizations/pages/Organization/pages/OrganizationParams/OrganizationParams";
import GenSettings from "../../../supPages/GenSettings/GenSettings";
import NotificationSettings from "../../composed/NotificationSettings/NotificationSettings";
import UserHistoryWithURLSearchParamsHandler
  from "../../../supPages/Users/pages/User/pages/UserHistory/UserHistoryWithURLSearchParamsHandler";
import {globalPermissionsStrValues} from "../../../configData/permissionsStrValues";
import UserMainDataRequiredOrganizationValidator
  from "../../../supPages/Users/pages/User/pages/UserMainDataEdit/UserMainDataRequiredOrganizationValidator";

type Props = {};

const SupRoutes = (props: Props) => {
  return (
    <>
      <PrivateRoute exact path={supMyOrgsPath} component={MyOrganizations} canUseRoute={true} />

      <PrivateRoute exact path={supOrgsPath} component={Organizations}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.admin)} />
      <PrivateRoute exact path={supOrgsPath + newOrganizationSuffix} component={NewOrganization}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.admin)} />
      <PrivateRouteInRightSidebar exact path={supOrgsPath + newOrganizationSuffix} component={OrganizationMainDataEdit}
                    addProps={{isSupLevel: true}}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.admin)} />
      <PrivateRoute path={supOrgPath(':id')} component={Organization}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.admin)}
                    addProps={{isSupLevel: true}}/>
      {/*<PrivateRoute path={supOrgPathView(':id')} component={OrganizationMenu}*/}
      {/*              canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.admin)} />*/}
      <PrivateRouteInRightSidebar path={supOrgPathView(':id') + mainDataSuffix} component={OrganizationMainDataView}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.admin)} />
      <PrivateRouteInRightSidebar path={supOrgPathEdit(':id') + mainDataSuffix} component={OrganizationMainDataEdit}
                    addProps={{isSupLevel: true}}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.admin)} />
      <PrivateRouteInRightSidebar path={supOrgPathView(':id') + paramsSuffix} component={OrganizationParams}
                    addProps={{isSupLevel: true}}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.admin)} />

      <PrivateRoute exact path={supUsersPath} component={Users}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.user.view)} />
      <PrivateRouteInRightSidebar exact path={supUsersPath + newUserSuffix} component={NewUser}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.user.create)} />
      <PrivateRouteInRightSidebar exact path={supUsersPath + newUserSuffix} component={UserMainDataEdit}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.user.create)} />
      <PrivateRouteInRightSidebar exact path={supUsersPath + newUserSuffix} component={UserMainDataRequiredOrganizationValidator}
                                  canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.user.create)} />

      <PrivateRoute path={supUserPath(':id')} component={User}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.user.view)} />
      {/*<PrivateRoute path={supUserPathView(':id')} component={UserMenu}*/}
      {/*              canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.user.view)} />*/}
      <PrivateRouteInRightSidebar exact path={supUserPathView(':id') + mainDataSuffix} component={UserMainDataView}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.user.view)} />
      <PrivateRouteInRightSidebar exact path={supUserPathEdit(':id') + mainDataSuffix} component={UserMainDataEdit}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.user.update)} />
      <PrivateRouteInRightSidebar exact path={supUserPathView(':id') + userOrganizationsSuffix} component={UserOrganizations}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.user.view)} />
      <PrivateRouteInRightSidebar exact path={supUserPathView(':id') + historySuffix} component={UserHistoryWithURLSearchParamsHandler}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.user.view)} />

      <PrivateRoute path={supSettPath} component={GenSettings} canUseRoute={true} />
      <Route exact path={supSettPath}
        render={()=>(<Redirect to={supSettPath + viewNotificationsSuffix}/>)}      />
      {/*<PrivateRoute path={supSettPath + '/view'} component={GenSettingsMenu} canUseRoute={true} />*/}
      <PrivateRouteInRightSidebar exact path={supSettPath + viewNotificationsSuffix} component={NotificationSettings} canUseRoute={true} />
      <PrivateRouteInRightSidebar exact path={supSettPath + editNotificationsSuffix} component={NotificationSettings} canUseRoute={true} />


    </>
  );
};

export default SupRoutes;    