//@flow
import * as React from 'react';
import './ButtonTooltip.css';
import {baseClass} from "../../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../../../services/flowTypes/uiKitFlowTypes";
import type {children} from "../../../../../services/flowTypes/appFlowTypes";
import type {tooltipPosition} from "../../../../../services/flowTypes/componentFlowTypes";

type Props = {
  addClasses?: addClasses,
  children: children,
  tooltipPosition: tooltipPosition
}

const ButtonTooltip = (props: Props) => (
    <div className={baseClass(`button-tooltip button-tooltip_${props.tooltipPosition}`, props.addClasses)}>{props.children}</div>
);

export default ButtonTooltip;
