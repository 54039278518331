//@flow
import * as React from 'react';
import LoginPagesLayout from "../../components/simple/Containers/LoginPagesLayout/LoginPagesLayout";
import text from "../../services/localization/text";
import './GoogleAuthPage.css';
import NHeading3 from "../../components/simple/TextComponents/NHeading3/NHeading3";
import Text1 from "../../components/simple/TextComponents/Text1/Text1";
import NInput from "../../components/simple/FormComponents/NInput/NInput";
import NFormGroup from "../../components/simple/FormComponents/NFormGroup/NFormGroup";
import type {authCode} from "../../services/flowTypes/componentFlowTypes";
import BtnNewItem from "../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {};
type State = {
  authCode: authCode
};

class GoogleAuthPage extends React.Component <Props, State> {
  inputCode: { current: null | HTMLInputElement };
  constructor(props: Props) {
    super(props);
    this.state = {
      authCode: ''
    };
    this.inputCode = React.createRef();
  }
  onChange = (e:SyntheticEvent<HTMLInputElement>) => {
    this.setState({authCode: e.currentTarget.value});
  };
  componentDidMount(){
    if (this.inputCode.current) {
      this.inputCode.current.focus();
    }
  }
  render() {
    const propError = window.location.href.includes('?error') ? text.wrongAuthCode : '';
    const androidLink = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
    const iphoneLink = "https://apps.apple.com/ru/app/google-authenticator/id388497605";
    const windowData = window['GOOGLE_AUTH_DATA'];
    const loginPageLayoutTitle = text.helloUser(windowData.userName);
    const loginPageLayoutSubTitle= text.subTitleGoogleAuth;
    const loginPageLayoutContent= (
      <>
        <div className={'flex flex_wrap'}>
          <div className="GoogleAuthPage__qrCodeContainer">
            <img src={windowData.qrCodeUrl} alt={'qr-code'}/>
          </div>
          <div>
            <NHeading3 addClasses={'mb_2du'}>{text.requiredSteps}</NHeading3>
            <Text1>{text.googleAuthStep1}</Text1>
            <div className="flex">
              <a className={'BtnEditAct mr_1du'} target="_blank" rel="noopener noreferrer" href={androidLink}>{'Android'}</a>
              <a className={'BtnEditAct mr_1du'} target="_blank" rel="noopener noreferrer" href={iphoneLink}>{'IPhone'}</a>
            </div>
            <Text1>{text.googleAuthStep2}</Text1>
            <Text1 addClasses={'mb_2du'}>{text.googleAuthStep3}</Text1>
            <NFormGroup label={text.authCodeLabel} addClasses={'w_half-card'} propError={propError}>
              <NInput name={'authCode'} value={this.state.authCode} onChange={this.onChange} ref={this.inputCode} />
            </NFormGroup>
            <input type="hidden" name={windowData.csrfToken.name}
                   value={windowData.csrfToken.value} />
            <input type="hidden" name={'key'} value={windowData.key} />
          </div>
        </div>
      </>
    );
    const loginPageLayoutBtn =
      (<BtnNewItem disabled={!this.state.authCode.length} buttonType={'submit'}>{text.btnProve}</BtnNewItem>);
    const formAction = "/send-auth-code";
    return (
      <LoginPagesLayout
        loginPageLayoutTitle={loginPageLayoutTitle}
        loginPageLayoutSubTitle={loginPageLayoutSubTitle}
        loginPageLayoutContent={loginPageLayoutContent}
        loginPageLayoutBtn={loginPageLayoutBtn}
        formAction={formAction}
      />
    );
  }
}

export default GoogleAuthPage;