//@flow
import usersSearchActions from './usersSearchActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {usersSearch} from "../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = {
  name: '',
  count: 10,
  offset: 0
};

const usersSearchReducer = (state:usersSearch = initialState, action:reducerAction) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case usersSearchActions.USERS_SEARCH_INIT_STATE_SET:
      return initialState;
    case usersSearchActions.USERS_SEARCH_PROP_UPDATE:
      return {...newState, ...action.value};
    case usersSearchActions.USERS_SEARCH_PROP_DELETE:
      for (let i=0; i < action.value.length; i++) {
        delete newState[action.value[i]];
      }
      return newState;
    default:
      return state;
  }
};

export default usersSearchReducer;