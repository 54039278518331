import connect from "react-redux/es/connect/connect";
import ContractSourcesSubMenu from "./contract/ContractFinTermsMenu/ContractSourcesSubMenu";
import type {contractId, organizationId} from "../../../../services/flowTypes/dataFlowTypes";
import type {history} from "../../../../services/flowTypes/appFlowTypes";

type Props = {
  history: history,
  contractId: contractId,
  organizationId: organizationId,
};
const ContractSourcesSubMenuView = (props:Props) => {
  const {contractId, organizationId, history} = props
  const parts =  history.location.pathname.split('/');
  if (parts[parts.length - 1] === 'sources') {
    // do something
    history.push({pathname: history.location.pathname + "/main"});
  }

  return <ContractSourcesSubMenu
    contractId={contractId}
    organizationId={organizationId}
  />
}

const mapStateToProps = state => {
  return {
    contractId: state.organization.item.contractMainData.id,
    organizationId: state.organization.organizationId,
    groupFinTermName: state.organization.item.groupFinTermsEdit.name,
    groupFinTermId: state.organization.item.groupFinTermsEdit.groupId,
  };
};
export default connect(mapStateToProps, null)(ContractSourcesSubMenuView);