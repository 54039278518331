//@flow
import * as React from 'react';
import {baseClass} from "../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {disabled, id} from "../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import './ItemControls.css';
import text from "../../../services/localization/text";
import type {cancelFn, saveFn} from "../../../services/flowTypes/componentFlowTypes";
import {contractEditPageIds} from "../../../tests/testIds";
import BtnNewItem from "../../simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  id?: id,
  addClasses?: addClasses,
  saveFn: saveFn,
  cancelFn: cancelFn,
  submitIsDisabled: disabled
};

const ItemControls = (props: Props) => {
  return (
    <div id={props.id} className={baseClass("ItemControls", props.addClasses)}>
      <BtnNewItem onClick={props.cancelFn} addClasses={"BtnNewItem_grey mr_1du"}
                     id={contractEditPageIds.buttonCancel}>
        {text.cancel}
      </BtnNewItem>
      <BtnNewItem disabled={props.submitIsDisabled} onClick={props.saveFn}
                  id={contractEditPageIds.buttonSave}>
        {text.save}
      </BtnNewItem>
    </div>
  );
};

export default ItemControls;