//@flow
import agentMainDataActions from './agentMainDataActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {agent} from "../../../../services/flowTypes/dataFlowTypes";

type State = agent;
type reducerAction = action;
export const initialState = {
  name: '',
  tags: [],
  params: [],
  agentCode: '',
  inn1c: '',
  name1c: '',
};
//!!!!!!!! params are used only for view component, in edit mode params are handled from itemDataParamsEdit state

const agentMainDataReducer = (state:State = initialState, action:reducerAction) => {
  switch (action.type) {
    case agentMainDataActions.AGENT_MAIN_DATA_SET:
      return action.value;
    case agentMainDataActions.AGENT_MAIN_DATA_INIT:
      return initialState;
    case agentMainDataActions.AGENT_MAIN_DATA_PROP_SET:
      return {
        ...state,
        [action.value.prop]: action.value.data
      }
    default:
      return state;
  }
};

export default agentMainDataReducer;
