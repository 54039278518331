//@flow
import * as React from 'react';
import {connect} from "react-redux";
import DropDownSelect from "../../../../simple/Containers/DropDownSelect/DropDownSelect";
import type {searchCategory, searchPropName} from "../../../../../services/flowTypes/dataFlowTypes";
import searchRequestActions from "../../../../../store/organization/searchRequest/searchRequestActions";
import {onGetSearchRequestProp} from "../../../../../services/flowTypes/propFnFlowTypes";
import contractsSearchPropNameList from "../../../../../configData/contractsSearchPropNameList";
import agentsSearchPropNameList from "../../../../../configData/agentsSearchPropNameList";
import text from "../../../../../services/localization/text";
import {contractsPageIds} from "../../../../../tests/testIds";

type Props = {
  category: searchCategory,
  onGetSearchRequestProp: onGetSearchRequestProp,
  searchPropName: searchPropName
};

class SearchPropName extends React.Component <Props> {
  onChange = (selectedOption) => {
    const searchRequestObj = {
      offset: 0,
      searchPropName: selectedOption.value,
      text: ''
    };
    this.props.onGetSearchRequestProp(searchRequestObj);
  };
  render() {
    const searchPropNameList = this.props.category === 'contracts' ? contractsSearchPropNameList
      : agentsSearchPropNameList;
    const options = searchPropNameList.map(item => {
      return {value: item, label: text.searchPropName[item]};
    });
    const selectedOption = {value: this.props.searchPropName, label: text.searchPropName[this.props.searchPropName]};
    return (
      <div className="SearchPropName">
        <DropDownSelect options={options} selectedOption={selectedOption} onDropDownSelectChange={this.onChange}
                        idForTest={contractsPageIds.selectSearchPropName}/>
      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    category: state.organization.searchRequest.category,
    searchPropName: state.organization.searchRequest.searchPropName
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSearchRequestProp: (searchRequestObj) => dispatch({type: searchRequestActions.SEARCH_REQUEST_PROP_SET,
      value: searchRequestObj})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPropName);    