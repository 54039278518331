//@flow
import * as React from 'react';
import uiKitData from "./uiKitData/uiKitData";
import {Link} from "react-router-dom";
import createHashHistoryObserver from "./utilities/createHashHistoryObserver";
import type {history} from "../../../services/flowTypes/appFlowTypes";

type Props = {
  history:history
};
type State = {
  groupIsShown: Array<boolean>
};

class NewUiKitMenu extends React.Component <Props, State> {
  constructor(props:Props){
    super(props);
    this.state = {
      groupIsShown: []
    };
  }
  handleUiKitMenuHeadingClick = (e:SyntheticEvent<HTMLElement>, uiKitGroupIndex:number) => {
    e.preventDefault();
    let uiKitGroupIsShown = this.state.groupIsShown;
    uiKitGroupIsShown[uiKitGroupIndex] = !uiKitGroupIsShown[uiKitGroupIndex];
    this.setState({groupIsShown: uiKitGroupIsShown});
  };
  componentDidMount(){
    const uiKitGroupIsShown = uiKitData.map(item => {
      return false;
    });
    this.setState({groupIsShown: uiKitGroupIsShown});
    createHashHistoryObserver(this.props.history);
  }
  render() {
    const UiKitMenuItems = uiKitData.map((uiKitGroup,uiKitGroupIndex) => {
      const uiKitGroupHeading =
        (
          <h2 onClick={(e) => this.handleUiKitMenuHeadingClick(e, uiKitGroupIndex)}
            className="ui-kit__menu-heading-link-item" >{uiKitGroup.title}</h2>
        );
      const uiKitGroupItems = uiKitGroup.group.map((item, index) => {
        return (
          <Link className="ui-kit__menu-link-item StyledLink" key={item.title + index} to={{hash: "#" + item.title}}>{item.title}</Link>
        );
      });
      const uiKitMenuGroupClassName = this.state.groupIsShown[uiKitGroupIndex] ? 'ui-kit__menu-group'
        : 'ui-kit__menu-group ui-kit__menu-group_hide';
      return (
        <React.Fragment key={'uiKitMenuGroup' + uiKitGroupIndex}>
          {uiKitGroupHeading}
          <div className={uiKitMenuGroupClassName}>
            {uiKitGroupItems}
          </div>
        </React.Fragment>
      );
    });
    return (
      <div className="fixed-on-right-side">
        {UiKitMenuItems}
      </div>
    );
  }
}

export default NewUiKitMenu;