//@flow
import * as React from 'react';
import {connect} from "react-redux";
import NCard from "../../../../../../../components/simple/Containers/NCard/NCard";
import CheckboxInput from "../../../../../../../components/simple/FormComponents/CheckboxInput/CheckboxInput";
import {groupFinTermEditPageIds} from "../../../../../../../tests/testIds";
import text from "../../../../../../../services/localization/text";
import GroupFinTermsFormGroup from "./GroupFinTermsFormGroup";
import type {selectOptions} from "../../../../../../../services/flowTypes/componentFlowTypes";
import api from "../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import type {errorObject, isReadyToRender} from "../../../../../../../services/flowTypes/appFlowTypes";
import type {contractId, customFunction, payerId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import getObjProp from "../../../../../../../utils/getObjProp";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";

type Props = {
  contractId: contractId,
  payerId: payerId,
  customFunction: customFunction
};
type State = {
  isCustomFunctionApplied: boolean,
  customFunctionOptions: selectOptions,
  isReadyToRender: isReadyToRender,
  errorObject: errorObject
};

class CustomFunction extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isCustomFunctionApplied: false,
      customFunctionOptions: [],
      isReadyToRender: true,
      errorObject: null
    };
  }
  handleBooleanCheckboxInputChange = () => {
    this.setState({isCustomFunctionApplied: !this.state.isCustomFunctionApplied});
  };
  handleCustomFunctionUpdate = () => {
    this.setState({isCustomFunctionApplied: false});
  };
  getCustomFunctionList = () => {
    if (this.props.payerId) {
      this.setState({isReadyToRender: false});
      api.getCustomFunctionList(this.props.contractId, this.props.payerId)
        .then(response => {
          const customFunctionOptions = response.data.map(item => {
            return {
              value: item.name,
              label: item.name
            }
          });
          this.setState({
            isReadyToRender: true,
            customFunctionOptions
          });
        })
        .catch(error => {
          this.setState({
            isReadyToRender: true,
            errorObject: apiErrorHandler(error)
          });
        });
    }
  };
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if (prevState.isCustomFunctionApplied !== this.state.isCustomFunctionApplied && this.state.isCustomFunctionApplied) {
      this.getCustomFunctionList();
    }
    if (prevProps.customFunction !== this.props.customFunction && !this.props.customFunction) {
      this.handleCustomFunctionUpdate();
    }
    if (this.state.isCustomFunctionApplied &&
      ( (prevProps.payerId !== this.props.payerId)
        || (prevProps.contractId !== this.props.contractId)
      )) {
      this.getCustomFunctionList();
    }
  }

  componentDidMount(): * {
    if (this.props.customFunction && this.props.customFunction !== "Is not found!") {
      this.handleBooleanCheckboxInputChange();
    }
  }

  render() {
    return (
      <NCard>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <div className={'flex_jc-sb'}>
          <div className="w_half-card">
            <CheckboxInput checked={this.state.isCustomFunctionApplied} onClick={this.handleBooleanCheckboxInputChange}
                           id={groupFinTermEditPageIds.checkboxApplyCustomFunction}
                           checkboxLabel={text.applyCustomFunction}/>
          </div>
          <div className="w_half-card">
            {this.state.isCustomFunctionApplied ? (
              <GroupFinTermsFormGroup formFieldType={'select'} propName={'customFunction'} label={text.customFunction}
                                      placeholder={text.select.placeholder.customFunction} isClearable={true}
                                      options={this.state.customFunctionOptions} isSearchable={true}
                                      selectInputId={groupFinTermEditPageIds.selectInputCustomFunction}
                                      idForTest={groupFinTermEditPageIds.selectCustomFunction}/>
            ) : null}
          </div>
        </div>
      </NCard>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    customFunction: getObjProp(state.organization.item.groupFinTermsEdit, "customFunction"),
    payerId: state.organization.item.groupFinTermsEdit.payerId
  };
};

export default connect(mapStateToProps, null)(CustomFunction);