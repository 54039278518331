//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {agentsList, groupFinTerm, organizationId} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import getNameOrDefName from "../../../../../../../../utils/getNameOrDefName";
import text from "../../../../../../../../services/localization/text";
import type {onBtnDelClick} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import {
  contrPathEdit,
  contrPathView,
  groupFinTermPath, historySearchSuffix, historySuffix,
  orgPath
} from "../../../../../../../../services/urlStrings/urlStrings";
import GroupFinTermCardParamsRow
  from "../../../../../../../../components/composed/GroupFinTermCardParamsRow/GroupFinTermCardParamsRow";
import getCurrencyLabel from "../../../../../../../../utils/getCurrencyLabel";
import {groupFinTermViewPageIds} from "../../../../../../../../tests/testIds";
import type {groupFinTermIndex} from "../../../../../../../../services/flowTypes/componentFlowTypes";
import type {addClasses} from "../../../../../../../../services/flowTypes/uiKitFlowTypes";
import concatIdName from "../../../../../../../../utils/concatIdName";
import {formatWithDelimiter} from "../../../../../../../../utils/formatInputWithDelimiter";
import {permissionsStrValues} from "../../../../../../../../configData/permissionsStrValues";
import AlertPaynetSync from "../../../../../../../../components/simple/Containers/AlertCard/AlertPaynetSync";

const withHandledGroupFinTermData = (WrappedComponent) => {
  type Props = {
    agentsList: agentsList,
    groupFinTerm: groupFinTerm,
    onBtnDelClick: onBtnDelClick,
    organizationId: organizationId,
    groupFinTermIndex: groupFinTermIndex,
    addClasses?: addClasses
  };
  class HOC extends React.Component <Props> {
    getAgentName = (agentId) => {
      const agentIdName = this.props.agentsList.find(agent => agent.id === agentId);
      return  agentIdName ? agentIdName.name : 'Agent not found in agents list';
    };
    getSourceParamLabel = (sourceParamName) => {
      const sourceParamObj = this.props.groupFinTerm.sourceParams
        .find(sourceParam => sourceParam.name === sourceParamName);
      return sourceParamObj ? formatWithDelimiter(sourceParamObj.value, ',') : null;
    };
    render() {
      const linkTo = orgPath(this.props.organizationId) + contrPathView(this.props.groupFinTerm.contractId)
        + groupFinTermPath(this.props.groupFinTerm.groupId);
      const linkToEdit = orgPath(this.props.organizationId) + contrPathEdit(this.props.groupFinTerm.contractId)
        + groupFinTermPath(this.props.groupFinTerm.groupId);
      const linkToHistory = orgPath(this.props.organizationId) + historySuffix + historySearchSuffix
        + "&finTermGroupId=" + this.props.groupFinTerm.groupId;
      const groupFinTermCardParamRows = this.props.groupFinTerm.finTerms.map((finTerm, i) => {
        return (<GroupFinTermCardParamsRow key={'finTerm' + i} finTerm={finTerm} type={this.props.groupFinTerm.type}/>)
      });
      const warningComponent = this.props.groupFinTerm.isNotCompliedWithPaynetParams ?
        <AlertPaynetSync addClasses={"mb_1du"}
                         agentIdIsNotCompliedWIthPaynetParams={this.props.groupFinTerm.agentIdIsNotCompliedWIthPaynetParams}
                         organizationId={this.props.organizationId}/>
        : null;
      const handledGroupFinTerm = {
        linkTo, linkToEdit, linkToHistory, groupFinTermCardParamRows, warningComponent,
        groupId: this.props.groupFinTerm.groupId,
        customFunction: this.props.groupFinTerm.customFunction,
        cardTypeLabel: this.getSourceParamLabel("cardType"),
        directionLabel: this.getSourceParamLabel("direction"),
        transactionTypeLabel: this.getSourceParamLabel("transactionType"),
        transactionStatusLabel: this.getSourceParamLabel('transactionStatus'),
        payerName: this.getAgentName(this.props.groupFinTerm.payerId),
        payeeName: this.getAgentName(this.props.groupFinTerm.payeeId),
        payerMerchantName: this.props.groupFinTerm.payerMerchantId ? this.getAgentName(this.props.groupFinTerm.payerMerchantId) : "",
        payeeMerchantName: this.props.groupFinTerm.payeeMerchantId ? this.getAgentName(this.props.groupFinTerm.payeeMerchantId) : "",
        groupFinTermIdName: concatIdName(this.props.groupFinTerm.groupId, getNameOrDefName(this.props.groupFinTerm.name, text.finTerm)),
        currencyLabel: getCurrencyLabel(this.props.groupFinTerm.currency),
        payoutPeriodLabel: text[this.props.groupFinTerm.payoutPeriod],
        groupFinTermSourceLabel: text[this.props.groupFinTerm.source],
        groupFinTermTypeLabel: text[this.props.groupFinTerm.type],
        linkToGroupFinTermId: this.props.groupFinTermIndex ?
          groupFinTermViewPageIds.linkToGroupFinTerm(this.props.groupFinTermIndex) : 'linkToGroupFinTermId',
        buttonEditGroupFinTermId: this.props.groupFinTermIndex ?
          groupFinTermViewPageIds.buttonEditGroupFinTerm(this.props.groupFinTermIndex) : 'buttonEditGroupFinTermId',
        buttonDeleteGroupFinTermId: this.props.groupFinTermIndex ?
          groupFinTermViewPageIds.buttonDeleteGroupFinTerm(this.props.groupFinTermIndex) : 'buttonDeleteGroupFinTermId',
        linkToGroupFinTermHistoryId: this.props.groupFinTermIndex ?
          groupFinTermViewPageIds.linkToGroupFinTermHistory(this.props.groupFinTermIndex) : 'linkToGroupFinTermHistoryId',
        permittedActionObj: permissionsStrValues.finTerm,
        onBtnDelClick: this.props.onBtnDelClick
      };
      return (
        <WrappedComponent handledGroupFinTerm={handledGroupFinTerm} {...this.props}/>
      );
    }
  }

  const mapStateToProps = state => {
    return {
      agentsList: state.organization.organizationData.agentsList,
      organizationId: state.organization.organizationId
    };
  };

  return  connect(mapStateToProps, null)(HOC);
};

export default withHandledGroupFinTermData;