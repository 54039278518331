//@flow
import * as React from 'react';
import TextOverflowHandler from "../TextOverflowHandler/TextOverflowHandler";
import "./FixedHeader.css";
import type {history, onClick} from "../../../../services/flowTypes/appFlowTypes";
import BtnBack from "../../Buttons/BtnBack/BtnBack";
import NHeading2 from "../../TextComponents/NHeading2/NHeading2";

type Props = {
  title: React.Node | string,
  buttons: Array<React.Node>,
  onBtnBackClick: onClick,
  history: history
};

const FixedSubHeader = (props: Props) => {
  const {onBtnBackClick = props.history.goBack} = props
  return (
    <div className="FixedSubHeader">
      <div className="FixedSubHeaderContent">
        <div className="FixedSubHeaderContent__left">
          <div className="FixedSubHeaderContentBack">
            <BtnBack onClick={onBtnBackClick} addClasses={"mr_2du"} label={"Назад"}/>
          </div>
        </div>
        <div className="FixedSubHeaderContent__right">
          <NHeading2 addClasses={'NHeading2_lh40'} title={props.title}>
            <TextOverflowHandler addClasses={"TextOverflowHandler_mw-100"}>{props.title}</TextOverflowHandler>
          </NHeading2>
          <div className="flex">
            {props.buttons ? (
              props.buttons
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixedSubHeader;    