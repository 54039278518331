const defineFormFieldType = (type) => {
  switch (type) {
    case 'LIST':
      return 'select';
    case 'TEXT':
      return 'input';
    case 'DELIMITED_TEXT':
      return 'input-with-delimiter';
    case 'DELIMITED_LIST':
      return 'delimitedList';
    case 'TEXT_AREA':
      return 'textarea';
    case 'DATE':
      return 'date';
    case 'BOOLEAN':
      return 'checkbox';
    default:
      console.error('Unknown type of param!');
  }
};
export default defineFormFieldType;