//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {inOrgContextSearchResponse} from "../../../../services/flowTypes/dataFlowTypes";
import Heading5 from "../../../simple/TextComponents/Heading5/Heading5";
import text from "../../../../services/localization/text";
import SearchInOrgContextCard from "./SearchInOrgContextCard";
import type {requestInProcess} from "../../../../services/flowTypes/componentFlowTypes";
import SearchRequestLoadingIcon from "../../../simple/Icons/SearchRequestLoadingIcon/SearchRequestLoadingIcon";
import SearchInOrgContextDocumentCard from "./SearchInOrgContextDocumentCard";

type Props = {
  searchInOrgContextResponse: inOrgContextSearchResponse,
  requestInProcess: requestInProcess
};

class SearchInOrgContextResults extends React.Component <Props> {
  render() {
    const contractsTotal = this.props.searchInOrgContextResponse.contracts.total;
    const contractsTotalComp = this.props.requestInProcess ? <SearchRequestLoadingIcon addClasses={'ml_1du'}/> : contractsTotal;
    const agentsTotal = this.props.searchInOrgContextResponse.agents.total;
    const agentsTotalComp = this.props.requestInProcess ? <SearchRequestLoadingIcon addClasses={'ml_1du'}/> : agentsTotal;
    const documentsTotal = this.props.searchInOrgContextResponse.documents.total;
    const documentsTotalComp = this.props.requestInProcess ? <SearchRequestLoadingIcon addClasses={'ml_1du'}/> : documentsTotal;
    const contractCards = this.props.searchInOrgContextResponse.contracts.itemsList.map((item, i) => {
      return (<SearchInOrgContextCard key={'ContractSearchCard'+i} searchedItem={item} itemType={'contract'}/>);
    });
    const agentCards = this.props.searchInOrgContextResponse.agents.itemsList.map((item, i) => {
      return (<SearchInOrgContextCard key={'AgentSearchCard'+i} searchedItem={item} itemType={'agent'}/>);
    });
    const documentsCards = this.props.searchInOrgContextResponse.documents.itemsList.map((item, i) => {
      return (<SearchInOrgContextDocumentCard key={'DocumentSearchCard'+i} searchedDocumentResponse={item}/>);
    });
    return (
      <div className="flex_jc-sb">
        <div className="contracts w_32per">
          <Heading5 addClasses={'Heading5_no-underline mb_2du flex'}>{text.contracts + " - "}{contractsTotalComp}</Heading5>
          {contractsTotal ? contractCards : null}
        </div>
        <div className="agents w_32per">
          <Heading5 addClasses={'Heading5_no-underline mb_2du flex'}>{text.agents + " - "}{agentsTotalComp}</Heading5>
          {agentsTotal ? agentCards : null}
        </div>
        <div className="documents w_32per">
          <Heading5 addClasses={'Heading5_no-underline mb_2du flex'}>{text.documents + " - "}{documentsTotalComp}</Heading5>
          {documentsTotal ? documentsCards : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchInOrgContextResponse: state.organization.searchInOrgContextResponse
  };
};
export default connect(mapStateToProps, null)(SearchInOrgContextResults);