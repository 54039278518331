//@flow
import * as React from 'react';
import Text3 from "../../simple/TextComponents/Text3/Text3";
import text from "../../../services/localization/text";

type Props = {};

const SheetCalculatedItemsHeader = (props: Props) => {
  return (
    <div className="Sheet__calculated-items-header mb_2du">
      <Text3 addClasses={'Text3_lh40 Sheet__calculated-items-header-addServiceTypeId'}>{text.sheetCalculatedItemsHeader.addServiceTypeId}</Text3>
      <Text3 addClasses={'Text3_lh40 Sheet__calculated-items-header-price'}>{text.sheetCalculatedItemsHeader.price}</Text3>
      <Text3 addClasses={'Text3_lh40 Sheet__calculated-items-header-number'}>{text.sheetCalculatedItemsHeader.number}</Text3>
      <Text3 addClasses={'Text3_lh40 Sheet__calculated-items-header-amount'}>{text.sheetCalculatedItemsHeader.amount}</Text3>
    </div>
  );
};

export default SheetCalculatedItemsHeader;    