//@flow
import * as React from 'react';
import {connect} from "react-redux";
import {searchInOrgContextIds} from "../../../../tests/testIds";
import searchInOrgContextActions from "../../../../store/organization/searchInOrgContext/searchInOrgContextActions";
import type {onUpdateSearchInOrgContextRequestBodyProp} from "../../../../services/flowTypes/propFnFlowTypes";
import type {searchRequestText} from "../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../services/localization/text";
import type {inputRefType} from "../../../../services/flowTypes/appFlowTypes";

type Props = {
  onUpdateSearchInOrgContextRequestBodyProp: onUpdateSearchInOrgContextRequestBodyProp,
  text: searchRequestText
};

class SearchInOrgContextInput extends React.Component <Props> {
  ref: inputRefType;
  constructor(props: Props) {
    super(props);
    this.ref = React.createRef();
  }
  handleSearchInputChange = (e:SyntheticEvent<HTMLInputElement>) => {
    this.props.onUpdateSearchInOrgContextRequestBodyProp({propName: 'text', propValue: e.currentTarget.value});
  };
  componentDidMount(): * {
    this.ref.current.focus();
  }
  render() {
    return (
      <input type="text" className={'SearchInOrgContextInput'} id={searchInOrgContextIds.inputSearchInOrgContext}
             name="text"
             ref={this.ref}
             value={this.props.text}
             onChange={this.handleSearchInputChange}
             placeholder={text.search.placeholder.general}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    text: state.organization.searchInOrgContext.requestBody.text
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateSearchInOrgContextRequestBodyProp: (propObj) => dispatch({
      type: searchInOrgContextActions.SEARCH_IN_ORG_CONTEXT_REQUEST_BODY_PROP_UPDATE,
      value: propObj})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchInOrgContextInput);    