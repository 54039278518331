//@flow
import * as React from 'react';
import {connect} from "react-redux";
import agentMainDataActions
  from "../../../../../../../store/organization/item/agentMainData/agentMainDataActions";
import type {
  formFieldType, openDirection,
  propName,
  propValue,
  selectOptions
} from "../../../../../../../services/flowTypes/componentFlowTypes";
import {
  onGetAgentMainDataProp,
  onGetValidationStateFormProp
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import type {id, label, placeholder} from "../../../../../../../services/flowTypes/appFlowTypes";
import ConnectedFormGroup from "../../../../../../../components/composed/ConnectedFormGroup/ConnectedFormGroup";
import agentMainDataValidationScheme from "../../../../../../../configData/agentMainDataValidationScheme";
import type {addClasses} from "../../../../../../../services/flowTypes/uiKitFlowTypes";
import validationActions from "../../../../../../../store/validation/validationActions";
import getObjProp from "../../../../../../../utils/getObjProp";
import OutputOrWarn from "../../../../../../../components/simple/Containers/AlertCard/OutputOrWarn";

type Props = {
  formFieldType: formFieldType,
  propName: propName,
  propValue: propValue,
  onGetAgentMainDataProp: onGetAgentMainDataProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  label: label,
  options?: selectOptions,
  addClasses?: addClasses,
  idForTest?: id,
  openDirection?: openDirection,
  placeholder?: placeholder
};

class AgentMainDataFormGroup extends React.Component <Props, void> {
  handleInactiveStatus = () => {
    //TODO: consider to combine these call into single
    // and possibility to separate handling of terminated status into single component
    this.props.onGetAgentMainDataProp('statusReason', '');
    this.props.onGetAgentMainDataProp('statusDate', '');
    this.props.onGetValidationStateFormProp('statusReason', '');
    this.props.onGetValidationStateFormProp('statusDate', '');
  };
  onGetPropValue = (prop, data) => {
    if (prop === 'status' && data === 'INACTIVE') {
      this.handleInactiveStatus();
    }
    this.props.onGetAgentMainDataProp(prop, data);
  };
  render() {
    const propValidationPatternsArr = agentMainDataValidationScheme[this.props.propName] ?
      agentMainDataValidationScheme[this.props.propName] : null;
    return <OutputOrWarn propName={this.props.propName} propValue={this.props.propValue} component={
      <ConnectedFormGroup
        formFieldType={this.props.formFieldType}
        propName={this.props.propName}
        propValue={this.props.propValue}
        onGetPropValue={this.onGetPropValue}
        label={this.props.label}
        options={this.props.options}
        propValidationPatternsArr={propValidationPatternsArr}
        addClasses={this.props.addClasses}
        idForTest={this.props.idForTest}
        openDirection={this.props.openDirection}
        placeholder={this.props.placeholder}
      />
    }/>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propValue: getObjProp(state.organization.item.agentMainData, ownProps.propName)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetAgentMainDataProp: (prop, data) => dispatch({type: agentMainDataActions.AGENT_MAIN_DATA_PROP_SET, value: {
        prop: prop,
        data: data
      }})

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentMainDataFormGroup);