const contractMainDataValidationScheme = {
  typeId: ['required'],
  name: ['required', 'maxLength150'],
  contractNo: ['required', 'maxLength128'],
  comment: ['maxLength1000'],
  startDate: ['dateFormat', 'required'],
  // endDate: ['dateFormat'],
  // statusDate: ['dateFormat'],
  status: ['required'],
  statusReason: ['maxLength128'],
  contractMainDataFilesLength: ['notEmptyContractMainDataFilesArr'],
  params: {
    blockName: ['required'],
    paramValue: ['required', 'maxLength2048']
  }
};

export default contractMainDataValidationScheme;