//@flow
import * as React from 'react';
import connect from "react-redux/es/connect/connect";
import type {contract, contractTypes} from "../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../services/localization/text";
import type {locationType, match} from "../../../../../../services/flowTypes/appFlowTypes";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import SectionHeader from "../../../../../../components/composed/SectionHeader/SectionHeader";
import PropValueView from "../../../../../../components/composed/PropValueView/PropValueView";
import formatDate from "../../../../../../utils/formatDate";
import NFormGroup from "../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import FormTagSelect from "../../../../../../components/simple/FormComponents/FormTagSelect/FormTagSelect";
import getParamValue from "../../../../../../utils/getParamValue";
import scrollToTop from "../../../../../../utils/scrollToTop";
import NLabel from "../../../../../../components/simple/FormComponents/NLabel/NLabel";
import NDropDownDetails from "../../../../../../components/simple/Containers/NDropDownDetails/NDropDownDetails";
import SectionDivider from "../../../../../../components/simple/Containers/SectionDivider/SectionDivider";
import StatusLabel from "../../../../../../components/simple/TextComponents/StatusLabel/StatusLabel";
import ContractMainDataFiles from "../ContractMainDataEdit/ItemFiles/ItemFiles";
import {contractViewPageIds} from "../../../../../../tests/testIds";
import AlertUnverifiedAddAgreements
  from "../../../../../../components/simple/Containers/AlertCard/AlertUnverifiedAddAgreements";
import "./ContractMainDataView.css";

type Props = {
  contractMainData: contract,
  match: match,
  contractTypes: contractTypes,
  location: locationType
};

class ContractMainDataView extends React.Component <Props, void> {
  componentDidMount(){
    scrollToTop();
  }
  render(){
    const contractTypeObj = this.props.contractTypes.find(item => item.id === this.props.contractMainData.typeId);
    const contractTypeName = contractTypeObj ? contractTypeObj.name : '';
    const params = this.props.contractMainData.params.map((item, i) => {
      const fields = item.fields.map((field, index) => {
        return (<PropValueView key={'block'+i+'param'+index} label={field.label}
                               value={getParamValue(field)} addClasses={""}/>);
      });
      return (
        <div className={'mb_1du'} key={'block'+i}>
          <NDropDownDetails
            detailsSummary={item.blockName}
            detailsOptions={fields}/>
        </div>
      );
    });
    return (
      +this.props.match.params.id === this.props.contractMainData.id ?
      (<div className="ContractMainDataView">
        <NCard>
          {this.props.contractMainData.hasUnverifiedAddAgreements ? (
            <AlertUnverifiedAddAgreements addClasses={"mb_3du"} contractId={this.props.contractMainData.id}
                                          organizationId={this.props.match.params.organizationId}/>
          ) : null}
          <PropValueView label={text.name} value={this.props.contractMainData.name} addClasses={"mb_3du"}
                         testIdForValue={contractViewPageIds.divItemName}/>
          <div className="flex_jc-sb mb_3du">
            <PropValueView label={text.type} value={contractTypeName} addClasses={"w_half-card"}/>
            <PropValueView label={text.number} value={this.props.contractMainData.contractNo} addClasses={"w_half-card"}/>
          </div>

          {this.props.contractMainData.hasOwnProperty('comment') ? (
            <PropValueView label={text.comment} value={this.props.contractMainData.comment} addClasses={"mb_3du"}/>
          ) : null}
          {this.props.contractMainData.tags.length ? (
            <NFormGroup>
              <NLabel>{text.tags}</NLabel>
              <FormTagSelect disabled={true} value={this.props.contractMainData.tags} />
            </NFormGroup>
          ) : (
            <PropValueView label={text.tags} value={''} />
          )}

          <SectionDivider addClasses={'mt_5du mb_5du'}/>
          <SectionHeader sectionHeaderTitle={text.validityTerm} addClasses={"mb_3du"}/>
          <div className="flex mb_3du">
            <PropValueView label={text.start} value={formatDate(this.props.contractMainData.startDate)} 
                           addClasses={"w_quarter-card mr_3du"}/>
            {this.props.contractMainData.endDate ? (
              <PropValueView label={text.end} value={formatDate(this.props.contractMainData.endDate)} 
                             addClasses={"w_quarter-card mr_3du"}/>
            ) : null}
            <PropValueView label={text.status} value={
                  <StatusLabel addClasses={`StatusLabel_${this.props.contractMainData.status} StatusLabel_bold`}>
                    {text.contract.status[this.props.contractMainData.status]}
                  </StatusLabel>
                }
             addClasses={"w_quarter-card mr_3du"}/>
          </div>
          <div className="flex mb_3du">
            {this.props.contractMainData.statusDate ? (
              <PropValueView label={text.terminationDate} value={formatDate(this.props.contractMainData.statusDate)} 
                             addClasses={"w_quarter-card mr_3du"}/>
            ) : null}
            {this.props.contractMainData.statusReason ? (
              <PropValueView label={text.terminationReason} value={this.props.contractMainData.statusReason}/>
            ) : null}  
          </div>
          <ContractMainDataFiles contractId={this.props.contractMainData.id} readOnly={true} itemFilesType={'contract'}/>
          <SectionDivider addClasses={'mt_5du mb_5du'}/>
          <SectionHeader sectionHeaderTitle={text.header1c} addClasses={"mb_3du"}/>
          {this.props.contractMainData.hasOwnProperty('contractCode1c')  ? (
              <PropValueView label={text.contractCode1c} value={this.props.contractMainData.contractCode1c} addClasses={"mb_3du"}/>
          ) : null}
          {this.props.contractMainData.params.length ? (
            <>
              <SectionDivider addClasses={'mt_5du mb_5du'}/>
              <SectionHeader sectionHeaderTitle={text.addParamsTitle} addClasses={"mb_3du"}/>
              {params}
            </>
          ) : null}
          <SectionDivider addClasses={'mt_5du mb_5du'}/>
          <PropValueView
            testIdForValue={"verified-with-fin-terms-view-id"}
            label={text.isNotVerifiedWithFinTerms}
            value={this.props.contractMainData.isNotVerifiedWithFinTerms ? text.isNotVerifiedWithFinTermsOptions.yes : text.isNotVerifiedWithFinTermsOptions.no}
          />
          {
            this.props.contractMainData.isNotVerifiedWithFinTermsComment
            && this.props.contractMainData.isNotVerifiedWithFinTerms
            && <PropValueView label={text.isNotVerifiedWithFinTermsComment}
                              testIdForValue={"verified-with-fin-terms-view-comment-id"}
                              addClasses={"mt_3du"}
                              value={this.props.contractMainData.isNotVerifiedWithFinTermsComment}
            />
          }
        </NCard>
      </div>)
      : null
    );
  }
}
const mapStateToProps = state => {
  return {
    contractMainData: state.organization.item.contractMainData,
    contractTypes: state.organization.organizationData.contractTypes
  };
};

export default connect(mapStateToProps, null)(ContractMainDataView);