//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {organization} from "../../../../services/flowTypes/dataFlowTypes";
import OrganizationCard from "./OrganizationCard/OrganizationCard";
import EmptyListV2 from "../../../../components/simple/Containers/EmptyList/EmptyListV2";

type Props = {
  organizations: Array<organization>
};

class OrganizationsSearchResults extends React.Component <Props> {
  render() {
    const organizationsCards = this.props.organizations === null ? null :
      this.props.organizations && this.props.organizations.length ?
      this.props.organizations.map((item, index)=> {
        return <OrganizationCard key={'Organization' + index} organization={item} organizationIndex={index}/>
      })
      : (<EmptyListV2/>);
    return (
      <div>
        {organizationsCards}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    organizations: state.organizations.searchResults.organizations
  };
};
export default connect(mapStateToProps, null)(OrganizationsSearchResults);