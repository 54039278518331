//@flow
import * as React from 'react';
import text from "../../services/localization/text";
import type {errorObject, isReadyToRender, setAppData} from "../../services/flowTypes/appFlowTypes";
import api from "../../services/apiInstance/api";
import apiErrorHandler from "../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../components/service/RenderOnReady/RenderOnReady";
import updateCsrf from "../../services/permissions/updateCsrf";
import updateGlobalPermissions from "../../services/permissions/updateGlobalPermissions";
import type {role} from "../../services/flowTypes/dataFlowTypes";
import {rolesStrValues} from "../../configData/permissionsStrValues";

type Props = {
  setAppData: setAppData
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  role: role
};
//TODO: clean cDM and corresponding test

class TestLogin extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null,
      role: "ADMIN"
    };
  }
  handleSelect = (e) => {
    this.setState({role: e.target.value});
  };
  updateAbilitiesAndCsrf = (appData) => {
    updateCsrf(appData.csrfToken);
    updateGlobalPermissions();
  };
  submitTestLogin = () => {
    this.setState({isReadyToRender: false});
    api.testLogin(this.state.role).then((response) =>{
      this.setState({isReadyToRender: true});
      localStorage.setItem('appData', JSON.stringify(response.data));
      this.updateAbilitiesAndCsrf(response.data);
      this.props.setAppData(response.data);
    }).catch(error => {
      this.setState({
        isReadyToRender: true,
        errorObject: apiErrorHandler(error)
      });
    });
  };
  componentDidMount(){
    // const storageAppData = localStorage.getItem('appData');
    // if (storageAppData) {
    //   const parsedAppData = JSON.parse(storageAppData);
    //   this.props.setAppData(parsedAppData);
    // } else {
    //   this.setState({isReadyToRender: true});
    // }
  }
  render() {
    return (
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <div id="test-login-wrapper" className="login-form-container">
          <div className="login-form">
            <div className="login-form-container__logo"><span className="login-form-container__logo_blue">{text.logoStart}</span>{text.logoEnd}
            </div>
            <select id="test-role" data-testid="test-role" value={this.state.role} className="login-form__input" onChange={this.handleSelect}>
              <option value={rolesStrValues.admin} >{text.admin}</option>
              <option value={rolesStrValues.lawyer} >{text.lawyer}</option>
              <option value={rolesStrValues.accountant} >{text.accountant}</option>
            </select>
            <button id="test-login" className="login-form__submit" type="button" onClick={this.submitTestLogin}>{text.logIn}</button>
          </div>
        </div>
      </>

    );
  }
}

export default TestLogin;    