//@flow
import * as React from 'react';
import {
  agentPathEdit, agentPathView, documentsSuffix, gatesParamsSuffix,
  mainDataSuffix, newAgentSuffix, orgPath
} from "../../../services/urlStrings/urlStrings";
import PrivateRoute from "./PrivateRoute";
import ability from "../../../services/permissions/permissions";
import type {agentId, organizationId} from "../../../services/flowTypes/dataFlowTypes";
import AgentMainDataEditButton from "../../../pages/Agents/pages/Agent/pages/AgentMainDataView/AgentMainDataEditButton";
import AgentMainDataControls
  from "../../../pages/Agents/pages/Agent/pages/AgentMainDataEdit/agentMainDataEdit/AgentMainDataControls";
import UploadAgentDocumentButton
  from "../../../pages/Agents/pages/Agent/pages/AgentDocuments/UploadAgentDocumentButton";
import {permissionsStrValues} from "../../../configData/permissionsStrValues";
import RequestPaynetGatesParamsButton
  from "../../../pages/Agents/pages/Agent/pages/GatesParams/RequestPaynetGatesParamsButton";

type Props = {
  organizationId: organizationId,
  agentId: agentId
};

const AgentButtonsRoutes = (props: Props) => {
  return (
    <>
      <PrivateRoute exact path={orgPath(':organizationId') + agentPathView(':id') + mainDataSuffix}
                    addProps={{organizationId: props.organizationId, agentId: props.agentId}}
                    component={AgentMainDataEditButton}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.agent.update)} />
      <PrivateRoute exact path={orgPath(':organizationId') + agentPathEdit(':id') + mainDataSuffix}
                    addProps={{organizationId: props.organizationId, agentId: props.agentId}}
                    component={AgentMainDataControls}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.agent.update)} />
      <PrivateRoute exact path={orgPath(':organizationId') + newAgentSuffix}
                    addProps={{organizationId: props.organizationId, agentId: props.agentId}}
                    component={AgentMainDataControls}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.agent.create)} />
      <PrivateRoute exact path={orgPath(':organizationId') + agentPathView(':id') + documentsSuffix}
                    addProps={{agentId: props.agentId}}
                    component={UploadAgentDocumentButton}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.agentDocument.create)} />
      <PrivateRoute exact path={orgPath(':organizationId') + agentPathView(':id') + documentsSuffix + '/folder/:folderId'}
                    addProps={{agentId: props.agentId}}
                    component={UploadAgentDocumentButton}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.agentDocument.create)} />
      <PrivateRoute exact path={orgPath(':organizationId') + agentPathView(':id') + gatesParamsSuffix}
                    addProps={{agentId: props.agentId}}
                    component={RequestPaynetGatesParamsButton}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.gatesParams.view)} />

    </>
  );
};

export default AgentButtonsRoutes;