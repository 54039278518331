//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {disabled, id, onClick} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './BtnIgnore.css';
import text from "../../../../services/localization/text";

type Props = {
  id?: id,
  addClasses?: addClasses,
  onClick: onClick,
  disabled?: disabled,
  isIgnored?: boolean
};

const BtnIgnore = (props: Props) => {
  const className = props.isIgnored ? "BtnIgnore BtnIgnore_isIgnored" : "BtnIgnore";
  const title = props.isIgnored ? text.clickToResetIgnore : text.clickToIgnore;
  return (
    <button type="button" id={props.id} onClick={props.onClick} title={title} disabled={props.disabled}
         className={baseClass(className, props.addClasses)}/>
  );
};

export default BtnIgnore;