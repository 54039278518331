//@flow
import * as React from 'react';
import {connect} from "react-redux";
import scrollToTop from "../../../../../../utils/scrollToTop";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import text from "../../../../../../services/localization/text";
import SectionHeader from "../../../../../../components/composed/SectionHeader/SectionHeader";
import type {
  organizationId,
  report,
  reportAccountStatusList,
  usersList
} from "../../../../../../services/flowTypes/dataFlowTypes";
import PropValueView from "../../../../../../components/composed/PropValueView/PropValueView";
import formatDate from "../../../../../../utils/formatDate";
import Text1 from "../../../../../../components/simple/TextComponents/Text1/Text1";
import ReportContractLink
  from "../../../../reports/ReportsSearchResults/ReportCard/ReportContractLink/ReportContractLink";
import ReportActTemplateLink
  from "../../../../reports/ReportsSearchResults/ReportCard/ReportActTemplateLink/ReportActTemplateLink";
import type {locationType, match} from "../../../../../../services/flowTypes/appFlowTypes";
import ReportCardDownloadDoc
  from "../../../../reports/ReportsSearchResults/ReportCard/ReportCardDownloadDoc/ReportCardDownloadDoc";
import UpdateReportAccountStatus from "./UpdateReportAccountStatus/UpdateReportAccountStatus";
import formatTime from "../../../../../../utils/formatTime";
import SecText from "../../../../../../components/simple/TextComponents/SecText/SecText";
import ReportGroupFinTerms from "./ReportGroupFinTerms/ReportGroupFinTerms";
import ReportStatus from "../../../../reports/ReportsSearchResults/ReportCard/ReportStatus/ReportStatus";
import Can from "../../../../../../services/permissions/Can";
import LoadingIcon from "../../../../../../components/simple/Icons/LoadingIcon/LoadingIcon";
import SectionDivider from "../../../../../../components/simple/Containers/SectionDivider/SectionDivider";
import ReportAdjustment from "./ReportAdjustment/ReportAdjustment";
import {reportsPageIds, reportViewPageIds} from "../../../../../../tests/testIds";
import {statusesForbiddenToAdjust} from "../../../../../../configData/statuses";
import getCurrencyLabel from "../../../../../../utils/getCurrencyLabel";
import ReportAgentLink from "../../../../reports/ReportsSearchResults/ReportCard/ReportAgentLink/ReportAgentLink";
import getMaskedInputValue from "../../../../../../utils/getMaskedInputValue";
import {permissionsStrValues} from "../../../../../../configData/permissionsStrValues";
import ReportAddServices from "./ReportAddServices";
import AdaptiveFormContainer
  from "../../../../../../components/simple/Containers/AdaptiveFormContainer/AdaptiveFormContainer";
import "./ReportMainDataView.css";

type Props = {
  match: match,
  reportMainData: report,
  usersList: usersList,
  reportAccountStatusList: reportAccountStatusList,
  location: locationType,
  organizationId: organizationId
};
//TODO: terminate hardcode this.props.reportMainData.finTermsList[0]
class ReportMainDataView extends React.Component <Props> {
  getUser = () => {
    return this.props.usersList.find(item => item.id === this.props.reportMainData.userId).name;
  };
  getReportAccountStatusName = () => {
    return this.props.reportAccountStatusList.find(item =>
      item.id === this.props.reportMainData.reportAccountStatus).name;
  };
  getAgents = () => {
    return this.props.reportMainData.finTermsList.map((item, i) => {
      return (
        <div className="flex_jc-sb mb_1du" key={'agents'+i}>
          <div className="w_half-card">
            <ReportAgentLink organizationId={this.props.match.params.organizationId} agentId={item.payerId}
                             parentComponentType={'page'} payerOrPayee={'payer'}/>
          </div>
          <div className="w_half-card">
            <ReportAgentLink organizationId={this.props.match.params.organizationId} agentId={item.payeeId}
                             parentComponentType={'page'} payerOrPayee={'payee'}/>
          </div>
        </div>
      );
    });
  };
  componentDidMount(){
    scrollToTop();
  }
  render() {
    const organizationId = this.props.match.params.organizationId;
    const reportTotalRow = (
      <div className="flex_jc-sb">
        <PropValueView label={text.reportTotal} value={
          <>
                <span id={reportViewPageIds.spanReportTotal} className={'mr_1du'}>{this.props.reportMainData.reportTotal === "N/A" ?
                  this.props.reportMainData.reportTotal :
                  getMaskedInputValue(this.props.reportMainData.reportTotal)}</span>
            {this.props.reportMainData.reportTotalCurrency ?
              getCurrencyLabel(this.props.reportMainData.reportTotalCurrency) : ''}
          </>
        } addClasses={'p_3du'}/>

        <div className={'p_3du'}>
          {this.props.reportMainData.calculatedResultFileName && this.props.reportMainData.calculatedResultFileName.length ? (
            <a id={reportsPageIds.linkToDownloadCalculatedResultFileForReport}
               className={'BtnEditAct'}
               target={'_blank'}
               rel="noopener noreferrer"
               href={`/report/${this.props.reportMainData.id}/downloadAllAddendumsDocument/${this.props.reportMainData.calculatedResultFileId}/${this.props.reportMainData.calculatedResultFileName}?o_id=${this.props.organizationId}`}
            >
              {text.calculatedResultFile}
            </a>
          ) : null}
          {this.props.reportMainData.calculatedResultAddendumFileName && this.props.reportMainData.calculatedResultAddendumFileName.length ? (
            <a id={reportsPageIds.linkToDownloadCalculatedResultAddendumFileForReport}
               className={'BtnEditAct'}
               target={'_blank'}
               rel="noopener noreferrer"
               href={`/report/${this.props.reportMainData.id}/downloadAllAddendumsDocument/${this.props.reportMainData.calculatedResultAddendumFileId}/${this.props.reportMainData.calculatedResultAddendumFileName}?o_id=${this.props.organizationId}`}
            >
              {text.calculatedResultAddendumFile}
            </a>
          ) : null}
        </div>

      </div>
    );
    return (
      this.props.reportMainData.reportStatus ? (
          <NCard>
            {this.props.reportMainData.errorMessage && this.props.reportMainData.errorMessage.length ? (
              <Text1 addClasses={'Text1_error mb_2du'}>{this.props.reportMainData.errorMessage}</Text1>
            ) : null}
            <AdaptiveFormContainer fieldsArr={[
              <PropValueView label={text.reportPeriod} value={formatDate(this.props.reportMainData.startReportPeriodDate) + " - "
                + formatDate(this.props.reportMainData.endReportPeriodDate) } key={"reportPeriod"}/>,
              <PropValueView label={text.reportCreateDate} value={formatDate(this.props.reportMainData.createDate)}
                             key={"reportCreateDate"}/>
            ]} containerMode={"twoHalfCardWidthFields"} addClasses={"mb_3du"}/>
            <AdaptiveFormContainer fieldsArr={[
              <ReportContractLink organizationId={organizationId} contractId={this.props.reportMainData.contractId}
                                  contractName={this.props.reportMainData.contractName} parentComponentType={'page'}
                                  key={"contractLink"}/>,
              <ReportActTemplateLink organizationId={organizationId} contractId={this.props.reportMainData.contractId}
                                     actTemplateId={this.props.reportMainData.actTemplateId} parentComponentType={'page'}
                                     actTemplateName={this.props.reportMainData.actTemplateName} key={"actTemplateLink"}/>
            ]} containerMode={"twoHalfCardWidthFields1100"} addClasses={"mb_3du"}/>
            {/*<div className="mb_3du">*/}
            {/*{this.getAgents()}*/}
            {/*</div>*/}
            <AdaptiveFormContainer fieldsArr={[
              <div key="reportFile">
                {this.props.reportMainData.reportStatus === 'SUCCESS' ? (
                  <ReportCardDownloadDoc reportIndex={''} docNum={this.props.reportMainData.docNum}
                                         fileId={this.props.reportMainData.reportFileId}
                                         fileName={this.props.reportMainData.reportFileName}
                                         reportId={this.props.reportMainData.id}
                                         addServiceReportFileId={this.props.reportMainData.addServiceReportFileId}
                                         addServiceReportFileName={this.props.reportMainData.addServiceReportFileName}
                                         idForTest={reportViewPageIds.linkToDownloadReport}
                                         finTermsListLength={this.props.reportMainData.finTermsList.length}
                                         addServicesListLength={this.props.reportMainData.addServicesList ?
                                           this.props.reportMainData.addServicesList.length : 0}
                                         parentComponentType={'page'} reportFileType={'report'}/>
                ) : null}
              </div>,
              <div key="exportFile">
                {(this.props.reportMainData.exportFileId && this.props.reportMainData.exportFileName) ? (
                  <ReportCardDownloadDoc reportIndex={''} docNum={this.props.reportMainData.docNum}
                                         fileId={this.props.reportMainData.exportFileId}
                                         fileName={this.props.reportMainData.exportFileName}
                                         reportId={this.props.reportMainData.id}
                                         idForTest={reportViewPageIds.linkToDownloadBaseForReport}
                                         parentComponentType={'page'} reportFileType={'export'}/>
                ) : null}
              </div>
            ]} containerMode={"twoHalfCardWidthFields1100"} addClasses={""}/>
            {/*{(this.props.reportMainData.addServiceReportFileId && this.props.reportMainData.addServiceReportFileName) ? (*/}
            {/*  <div className="mt_3du">*/}
            {/*    <ReportCardDownloadDoc reportIndex={''} docNum={this.props.reportMainData.docNum}*/}
            {/*                           fileId={this.props.reportMainData.addServiceReportFileId}*/}
            {/*                           fileName={this.props.reportMainData.addServiceReportFileName}*/}
            {/*                           reportId={this.props.reportMainData.id}*/}
            {/*                           idForTest={reportViewPageIds.linkToDownloadAddServiceReport}*/}
            {/*                           parentComponentType={'page'} reportFileType={'addServiceReport'}/>*/}
            {/*  </div>*/}
            {/*) : null}*/}
            <SectionDivider addClasses={'mt_5du mb_5du'}/>
            <SectionHeader sectionHeaderTitle={text.status} addClasses={"mb_3du"}/>
            <AdaptiveFormContainer fieldsArr={[
              <div key="reportStatus">
                <PropValueView label={text.currentStatus} value={
                  (<div className={'flex'}>
                      <ReportStatus reportAccountStatus={this.props.reportMainData.reportAccountStatus}
                                    reportStatus={this.props.reportMainData.reportStatus}/>
                      {(this.props.reportMainData.reportStatus === 'PROCESSING'
                        || this.props.reportMainData.reportStatus === 'CREATED'
                        || this.props.reportMainData.reportStatus === 'SCHEDULED_FOR_ADJUST') ? (
                        <LoadingIcon addClasses={'ml_1du'}/>
                      ) : null }
                    </div>
                  )
                } addClasses={'mb_half-du'}/>
                <Can do={'usePermission'} on={permissionsStrValues.report.updateState}>
                  <UpdateReportAccountStatus reportAccountStatus={this.props.reportMainData.reportAccountStatus}
                                             reportId={this.props.reportMainData.id}
                                             endReportPeriodDate={this.props.reportMainData.endReportPeriodDate}
                                             availableReportAccountStatuses={this.props.reportMainData.availableReportAccountStatuses}
                  />
                </Can>
              </div>,
              <div key="lastStatusUpdate">
                <PropValueView label={text.lastStatusUpdate} value={
                  this.getUser()
                } addClasses={'mb_half-du'}/>
                <SecText>{formatDate(this.props.reportMainData.reportAccountStatusDate)
                  + " " + formatTime(this.props.reportMainData.reportAccountStatusDate)}</SecText>
              </div>
            ]} containerMode={"twoHalfCardWidthFields"} addClasses={""}/>
            {!statusesForbiddenToAdjust.includes(this.getReportAccountStatusName()) ? (
              <>
                <SectionDivider addClasses={'mt_5du mb_5du'}/>
                <SectionHeader sectionHeaderTitle={text.adjustment} addClasses={"mb_3du"}/>
                <ReportAdjustment reportId={this.props.reportMainData.id}/>
              </>
            ) : null}
            <SectionDivider addClasses={'mt_5du mb_5du'}/>
            {this.props.reportMainData.finTermsList.length ? (
              <>
                <SectionHeader sectionHeaderTitle={text.finTerms} addClasses={"mb_3du"}/>
                <ReportGroupFinTerms
                  startReportPeriodDate={this.props.reportMainData.startReportPeriodDate}
                  endReportPeriodDate={this.props.reportMainData.endReportPeriodDate}
                  reportId={this.props.reportMainData.id}
                  finTermsList={this.props.reportMainData.finTermsList}/>
              </>
            ) : null}
            {/*{(this.props.reportMainData.rateAmount || this.props.reportMainData.totalAmount) ? (*/}
            {/*<TotalRow*/}
            {/*currency={this.props.reportMainData.finTermsList[0].currency}*/}
            {/*rateAmount={this.props.reportMainData.rateAmount}*/}
            {/*totalAmount={this.props.reportMainData.totalAmount}*/}
            {/*count={this.props.reportMainData.count}*/}
            {/*/>*/}
            {/*) : null}*/}
            {this.props.reportMainData.finTermsList.length ? reportTotalRow : null}

            {(this.props.reportMainData.addServicesList && this.props.reportMainData.addServicesList.length) ? (
              <ReportAddServices addServicesList={this.props.reportMainData.addServicesList}
                                 contractId={this.props.reportMainData.contractId} organizationId={organizationId}
                                 usersList={this.props.usersList}/>
            ) : null}
            {!this.props.reportMainData.finTermsList.length ? reportTotalRow : null}
          </NCard>
      ) : null
    );
  }
}

const mapStateToProps = state => {
  return {
    reportMainData: state.organization.item.reportMainData,
    usersList: state.organization.organizationData.usersList,
    reportAccountStatusList: state.organization.organizationData.statuses.reportAccountStatusList,
    organizationId: state.organization.organizationId
  };
};
export default connect(mapStateToProps, null)(ReportMainDataView);
