//@flow
import * as React from 'react';
import {connect} from "react-redux";
import text from "../../../../../../services/localization/text";
import type {
  reportAccountStatus,
  reportAccountStatusList,
  reportStatus
} from "../../../../../../services/flowTypes/dataFlowTypes";
import {reportViewPageIds} from "../../../../../../tests/testIds";

type Props = {
  reportAccountStatus: reportAccountStatus,
  reportStatus: reportStatus,
  reportAccountStatusList: reportAccountStatusList
};

class ReportStatus extends React.Component <Props> {
  render() {
    const reportAccountStatus = this.props.reportAccountStatusList.find(item =>
      item.id === this.props.reportAccountStatus).name;
    const definedReportStatus = this.props.reportStatus  === 'SUCCESS' ?
      text.reportAccountStatus[reportAccountStatus] :
      (this.props.reportStatus  === 'FAILED' ? text.reportStatus[this.props.reportStatus]
        : text.reportStatusInProcess);
    const className = this.props.reportStatus  === 'SUCCESS' ? `report-status_${reportAccountStatus}`
      : `report-status_${this.props.reportStatus}`;
    return (
      <span id={reportViewPageIds.spanReportStatus} className={className}>{definedReportStatus}</span>
    );
  }
}

const mapStateToProps = state => {
  return {
    reportAccountStatusList: state.organization.organizationData.statuses.reportAccountStatusList,
  };
};

export default connect(mapStateToProps, null)(ReportStatus);