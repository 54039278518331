//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  groupFinTermIndex, inputIco, paramName, propName, propValidationPatternsArr, propValue, paramIndex
} from "../../../services/flowTypes/componentFlowTypes";
import RenderOnReady from "../../service/RenderOnReady/RenderOnReady";
import type {isReadyToRender} from "../../../services/flowTypes/appFlowTypes";
import {onUpdateReportFinTermParam} from "../../../services/flowTypes/propFnFlowTypes";
import text from "../../../services/localization/text";
import getMaskedInputValue from "../../../utils/getMaskedInputValue";
import getParamUnitByNameAndCurr from "../../../utils/getParamUnitByNameAndCurr";
import type {currency} from "../../../services/flowTypes/dataFlowTypes";
import FinTermFormGroup
  from "../../../pages/Contracts/pages/Contract/pages/GroupFinTermsEdit/groupFinTermsEdit/SetFinTermsToEdit/FinTermsEdit/FinTerm/finTerm/FinTermFormGroup";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import reportManualAdjustmentActions
  from "../../../store/organization/item/reportManualAdjustment/reportManualAdjustmentActions";

type Props = {
  groupFinTermIndex: groupFinTermIndex,
  propValue: propValue,
  onUpdateReportFinTermParam: onUpdateReportFinTermParam,
  paramName: paramName,
  currency: currency,
  addClasses?: addClasses,
  paramIndex: paramIndex
};
type State = {
  isReadyToRender: isReadyToRender,
  propName: propName,
  propValidationPatternsArr: propValidationPatternsArr,
  inputIco: inputIco
};
class ReportFinTermParam extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      propName: '',
      propValidationPatternsArr: [],
      inputIco: null
    };
  }
  setPropName = () => {
    return 'groupFinTerm' + this.props.groupFinTermIndex + this.props.paramName;
  };
  onGetPropValue = (propName, propValue) => {
    const value = getMaskedInputValue(propValue)
    const trimmedValue = value.replace(/\s/g, '');
    this.props.onUpdateReportFinTermParam(this.props.groupFinTermIndex, this.props.paramIndex, trimmedValue);
  };
  componentDidMount(){
    this.setState({
      propName: this.setPropName(),
      propValidationPatternsArr: [],
      isReadyToRender: true,
      inputIco: (<div className="FinTermParamIco">{getParamUnitByNameAndCurr(this.props.paramName, this.props.currency)}</div>)
    });
  }
  render() {
    const propValue = this.props.propValue.toString();
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={null}>
        <FinTermFormGroup
          formFieldType={'input'}
          propName={this.state.propName}
          propValidationPatternsArr={this.state.propValidationPatternsArr}
          propValue={getMaskedInputValue(propValue)}
          onGetPropValue={this.onGetPropValue}
          label={text.reportFinTermParamLabel[this.props.paramName]}
          addClasses={this.props.addClasses}
          inputIco={this.state.inputIco}
          idForTest={this.state.propName}
        />
      </RenderOnReady>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    paramName: state.organization.item.reportManualAdjustment[ownProps.groupFinTermIndex].adjustedParams[ownProps.paramIndex].name,
    propValue: state.organization.item.reportManualAdjustment[ownProps.groupFinTermIndex].adjustedParams[ownProps.paramIndex].value
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateReportFinTermParam: (groupFinTermIndex, paramIndex, propValue) => dispatch({
      type: reportManualAdjustmentActions.REPORT_FIN_TERM_PARAM_UPDATE,
      value: { groupFinTermIndex, paramIndex, propValue } })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportFinTermParam);