//@flow
import * as React from 'react';
import {Link} from "react-router-dom";
import {groupFinTermViewPageIds} from "../../../../../../../tests/testIds";
import text from "../../../../../../../services/localization/text";
import Can from "../../../../../../../services/permissions/Can";
import {contrPath, newGroupFinTermSuffix, orgPath} from "../../../../../../../services/urlStrings/urlStrings";
import type {contractId, organizationId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";
import BtnNewItem from "../../../../../../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  organizationId: organizationId,
  contactId: contractId
};

const NewGroupFinTermButton = (props: Props) => {
  const linkToNew = orgPath(props.organizationId) + contrPath(props.contractId) + newGroupFinTermSuffix;
  return (
    <Can do={'usePermission'} on={permissionsStrValues.finTerm.create}>
      <Link className={"button-wrapper-link"} to={linkToNew}>
        <BtnNewItem id={groupFinTermViewPageIds.buttonNewGroupFinTerm}>{text.newGroupFinTerm}</BtnNewItem>
      </Link>
    </Can>
  );
};

export default NewGroupFinTermButton;    