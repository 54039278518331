//@flow
import * as React from 'react';
import type {propNameValue} from "../../../../services/flowTypes/dataFlowTypes";
import TextOverflowHandler from "../../../simple/Containers/TextOverflowHandler/TextOverflowHandler";

type Props = {
  handledPropNameValue: propNameValue
};

const HistoryLogPropNameValue = (props: Props) => {
  return (
    <div className="HistoryLogPropNameValue">
      <div className="HistoryLogPropNameValue__name mb_1du">{props.handledPropNameValue.name}</div>
      <div className="HistoryLogPropNameValue__value">
        <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>
          {props.handledPropNameValue.value}
        </TextOverflowHandler>
      </div>
    </div>
  );
};

export default HistoryLogPropNameValue;    