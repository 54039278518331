//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {children, id, onClick} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './AddFilterBtn.css';
import type {isActive} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  onClick: onClick,
  isActive?: isActive
};

const AddFilterBtn = (props: Props) => {
  const className = props.isActive ? "AddFilterBtn AddFilterBtn_active" : "AddFilterBtn";
  return (
    <button type="button" id={props.id} onClick={props.onClick}
         className={baseClass(className, props.addClasses)}/>
  );
};

export default AddFilterBtn;