//@flow
import searchInOrgContextActions from './searchInOrgContextActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {searchInOrgContext} from "../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = {
  sheetIsShown: false,
  requestBody: {
    text: ''
  }
};

const searchInOrgContextReducer = (state:searchInOrgContext = initialState, action:reducerAction) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case searchInOrgContextActions.SEARCH_IN_ORG_CONTEXT_INIT_STATE_SET:
      return initialState;
    case searchInOrgContextActions.SHEET_IS_SHOWN_UPDATE:
      newState.sheetIsShown = action.value;
      return newState;
    case searchInOrgContextActions.SEARCH_IN_ORG_CONTEXT_REQUEST_BODY_PROP_UPDATE:
      const requestBody = {...newState.requestBody};
      requestBody[action.value.propName] = action.value.propValue;
      newState.requestBody = requestBody;
      return newState;
    default:
      return state;
  }
};

export default searchInOrgContextReducer;