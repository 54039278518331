//@flow

import React from "react";
import type {children} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import './SideLeftContainer.css';

type Props = {
  children: children,
  addClasses?: addClasses,
}

const SideLeftContainer = (props: Props) => {
  return (
    <div className={baseClass("SideLeftContainer", props.addClasses)}>
      {props.children}
    </div>
  );
};

export default SideLeftContainer;