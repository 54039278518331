//@flow
import text from "../services/localization/text";
import {
  adjustmentHistorySuffix,
  historySuffix,
  mainDataSuffix,
  orgPath,
  reportPathView
} from "../services/urlStrings/urlStrings";
import type {organizationId as organizationIdType, reportId as reportIdType} from "../services/flowTypes/dataFlowTypes";
import {permissionsStrValues} from "./permissionsStrValues";

const reportMenuItems = (organizationId:organizationIdType, reportId:reportIdType, isReportAdjusted:boolean) => {
  const items = [
    {
      linkTo: orgPath(organizationId) + reportPathView(reportId) + mainDataSuffix,
      linkName: text.mainData,
      permittedAction: permissionsStrValues.report.view
    },
    {
      linkTo: orgPath(organizationId) + reportPathView(reportId) + historySuffix,
      linkName: text.history,
      permittedAction: permissionsStrValues.report.view
    },
  ];
  const adjustmentHistoryItem = {
    linkTo: orgPath(organizationId) + reportPathView(reportId) + adjustmentHistorySuffix,
    linkName: text.adjustmentHistory,
    permittedAction: permissionsStrValues.report.view
  };
  if (isReportAdjusted) {
    items.push(adjustmentHistoryItem);
  }
  return items;
};

export default reportMenuItems;