//@flow
import * as React from 'react';
import text from "../../../../../services/localization/text";
import type {id, modalIsShown} from "../../../../../services/flowTypes/appFlowTypes";
import NModal from "../../../../simple/Modals/NModal/NModal";
import type {agentId, contractId, fileId, fileName} from "../../../../../services/flowTypes/dataFlowTypes";
import {
  mountDocumentsListFetcher, onCancelBtnClick, setErrorObject,
  unmountDocumentsListFetcher
} from "../../../../../services/flowTypes/propFnFlowTypes";
import api from "../../../../../services/apiInstance/api";
import type {itemType, permittedAction} from "../../../../../services/flowTypes/componentFlowTypes";
import Can from "../../../../../services/permissions/Can";
import type {addClasses} from "../../../../../services/flowTypes/uiKitFlowTypes";
import type {
  deleteContractMainDataFile,
  onUpdateDocumentFoldersList
} from "../../../../../services/flowTypes/propFnFlowTypes";
import BtnEditAct from "../../../../simple/Buttons/BtnEditAct/BtnEditAct";
import NHeading2 from "../../../../simple/TextComponents/NHeading2/NHeading2";

type Props = {
  fileName: fileName,
  unmountDocumentsListFetcher: unmountDocumentsListFetcher,
  mountDocumentsListFetcher: mountDocumentsListFetcher,
  itemType: itemType,
  onCancelBtnClick?: onCancelBtnClick,
  itemId: contractId | agentId,
  fileId: fileId,
  setErrorObject: setErrorObject,
  idForTest?: id,
  permittedAction: permittedAction,
  addClasses: addClasses,
  deleteContractMainDataFile?: deleteContractMainDataFile,
  onUpdateDocumentFoldersList: onUpdateDocumentFoldersList
};
type State = {
  modalIsShown: modalIsShown,
  promiseDeleteDocument: Object
}
class DeleteDocument extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false,
      promiseDeleteDocument: {
        contract: api.deleteContractDocument,
        agent: api.deleteAgentDocument,
        organizations: api.deleteOrganizationDocument
      }
    };
  }
  showModal = () => {
    this.setState({modalIsShown: true});
  };
  hideModal = () => {
    this.setState({modalIsShown: false});
  };
  deleteDocument = () => {
    if (this.props.deleteContractMainDataFile) {
      this.props.deleteContractMainDataFile();
    } else {
      this.props.unmountDocumentsListFetcher();
      const promise = this.state.promiseDeleteDocument[this.props.itemType];
      promise(this.props.itemId, this.props.fileId)
        .then(response => {
          this.props.onUpdateDocumentFoldersList(response.data);
          this.props.onCancelBtnClick();
          this.props.mountDocumentsListFetcher();
        })
        .catch(error => {this.props.setErrorObject(error)})
    }
    this.hideModal();
  };
  render() {
    return (
      <>
        <Can do={'usePermission'} on={this.props.permittedAction}>
          <BtnEditAct id={this.props.idForTest} onClick={this.showModal} addClasses={'BtnEditAct_delete ml_2du'}>
            {text.deleteBtnLabel}</BtnEditAct>
        </Can>
        {this.state.modalIsShown ? (
          <NModal
            modalTitle={text.deleteDocumentModalTitle}
            modalActionBtnText={text.btnDel}
            modalCancelBtnText={text.cancel}
            onModalCancelBtnClick={this.hideModal}
            onModalActionBtnClick={this.deleteDocument}
            isModalDocumentEdit={true}
          >
            <NHeading2 addClasses={'mt_3du mb_3du'}>
              {text.btnDel + " "}
              <span className={"NHeading2 NHeading2__span-link-color"}>{this.props.fileName}</span>
              {"?"}
            </NHeading2>
          </NModal>
        ) : null}
      </>

    );
  }
}

export default DeleteDocument;    