//@flow
import * as React from 'react';
import TextOverflowHandler
  from "../../../../../../components/simple/Containers/TextOverflowHandler/TextOverflowHandler";
import concatIdName from "../../../../../../utils/concatIdName";
import SecText from "../../../../../../components/simple/TextComponents/SecText/SecText";
import text from "../../../../../../services/localization/text";
import ability from "../../../../../../services/permissions/permissions";
import {Link} from "react-router-dom";
import Text1 from "../../../../../../components/simple/TextComponents/Text1/Text1";
import type {contractId, contractName, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import type {isLabelHidden, parentComponentType} from "../../../../../../services/flowTypes/componentFlowTypes";
import NLabel from "../../../../../../components/simple/FormComponents/NLabel/NLabel";
import {baseClass} from "../../../../../UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../../../../services/flowTypes/uiKitFlowTypes";
import {contrPathView, mainDataSuffix, orgPath} from "../../../../../../services/urlStrings/urlStrings";
import {permissionsStrValues} from "../../../../../../configData/permissionsStrValues";
import {reportViewPageIds} from "../../../../../../tests/testIds";

type Props = {
  organizationId: organizationId,
  contractId: contractId,
  contractName: contractName,
  parentComponentType: parentComponentType,
  addClasses?: addClasses,
  isLabelHidden?: isLabelHidden
};

const ReportContractLink = (props: Props) => {
  const linkToContract = orgPath(props.organizationId) + contrPathView(props.contractId) + mainDataSuffix;
  const ContractTitle = (
    <TextOverflowHandler>
      {concatIdName(props.contractId, props.contractName)}
    </TextOverflowHandler>
  );
  const Label = () => {
    switch (props.parentComponentType) {
      case 'card':
        return (<SecText addClasses={'SecText_lh16 mb_1du'}>{text.contractLabel}</SecText>);
      case 'page':
        return (<NLabel addClasses={'mb_half-du'}>{text.contractLabel}</NLabel>);
        default:
          console.error('props.parentComponentType does not correspond switch cases!');
    }
  };
  const reportContractLinkIcon = <div className="ReportContractLink__icon"/>;
  return (
    <div className={baseClass("ReportContractLink", props.addClasses)} >
      {props.isLabelHidden ? null : Label()}
      {ability.can('usePermission', permissionsStrValues.contract.view) ? (
        <Link to={linkToContract} id={reportViewPageIds.linkToReportContract}>
          <Text1 addClasses={'Text1_report-contract-link-color flex'}>{reportContractLinkIcon}{ContractTitle}</Text1>
        </Link>
      ) : (
        <Text1 addClasses={'flex Text1_report-contract-link-color'}>{reportContractLinkIcon}{ContractTitle}</Text1>
      )}
    </div>
  );
};

export default ReportContractLink;    