//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {errorObject, isReadyToRender, match} from "../../../../../../services/flowTypes/appFlowTypes";
import organizationParamsActions
  from "../../../../../../store/organization/organizationParams/organizationParamsActions";
import api from "../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import {onSetOrganizationParams} from "../../../../../../services/flowTypes/propFnFlowTypes";
import type {isSupLevel, needUpdateOrganizationParams} from "../../../../../../services/flowTypes/componentFlowTypes";
import OrganizationParamsUpdater from "./OrganizationParamsUpdater/OrganizationParamsUpdater";
import ItemParams from "./ItemParams/ItemParams";
import GetDataOnMountAndUpdate
  from "../../../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import "./OrganizationParams.css";

type Props = {
  onSetOrganizationParams: onSetOrganizationParams,
  match: match,
  isSupLevel?: isSupLevel
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  needUpdateOrganizationParams: needUpdateOrganizationParams
};

class OrganizationParams extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      needUpdateOrganizationParams: false
    };
  }
  initUpdateOrganizationParams = () => {
    this.setState({needUpdateOrganizationParams: true});
  };
  cancelUpdateOrganizationParams = () => {
    this.setState({needUpdateOrganizationParams: false});
  };
  getOrganizationParams = () => {
    const organizationId = this.props.isSupLevel ?
      +this.props.match.params.id : +this.props.match.params.organizationId;
    api.getOrganizationParams(organizationId)
      .then(response => {
        this.props.onSetOrganizationParams(response.data);
        this.setState({isReadyToRender: true});
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    const organizationId = this.props.isSupLevel ?
      +this.props.match.params.id : +this.props.match.params.organizationId;
    return (
      <GetDataOnMountAndUpdate itemId={organizationId} getDataOnMountAndUpdate={this.getOrganizationParams}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
          <div className="OrganizationParams">
            <ItemParams itemType={'contract'} initUpdateOrganizationParams={this.initUpdateOrganizationParams}
                        isSupLevel={this.props.isSupLevel}/>
            <ItemParams itemType={'agent'} initUpdateOrganizationParams={this.initUpdateOrganizationParams}
                        isSupLevel={this.props.isSupLevel}/>
          </div>
          <OrganizationParamsUpdater
            needUpdateOrganizationParams={this.state.needUpdateOrganizationParams}
            handleUpdateResponse={this.cancelUpdateOrganizationParams}
            reloadOrganizationParams={this.getOrganizationParams}
            organizationId={organizationId}
          />
        </RenderOnReady>
      </GetDataOnMountAndUpdate>

    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onSetOrganizationParams: (organizationParams) => dispatch({type: organizationParamsActions.ORGANIZATION_PARAMS_SET,
      value: organizationParams})
  };
};
export default connect(null, mapDispatchToProps)(OrganizationParams);