//@flow
import * as React from 'react';
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {children, history, isReadyToRender, match} from "../../../../../../../services/flowTypes/appFlowTypes";
import type {addService} from "../../../../../../../services/flowTypes/dataFlowTypes";
import {connect} from "react-redux";
import validationPatterns from "../../../../../../../services/validationPatterns/validationPatterns";
import addServiceValidationScheme from "../../../../../../../configData/addServiceValidationScheme";
import type {linkTo} from "../../../../../../../services/flowTypes/componentFlowTypes";

type Props = {
  children: children,
  addService: addService,
  history: history,
  linkToTypeEdit: linkTo,
  linkToSheetsEdit?: linkTo,
  match: match
};
type State = {
  isReadyToRender: isReadyToRender
};

class ValidateAllDataOnMount extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false
    };
  }
  getErrorLength = (propValidationPatternsArr, propValue) => {
    let error = '';
    for (let i=0; i < propValidationPatternsArr.length; i++) {
      error = validationPatterns[propValidationPatternsArr[i]](propValue);
      if (error.length) {break;}
    }
    return error.length;
  };
  checkValidationRuleForProp = (propValidationPatternsArr, propName) => {
    if (!propValidationPatternsArr) {
      throw new Error(`No validation rule for prop ${propName}: ValidateAllDataOnMount.js file!`);
    }
  };
  validatePageProps = (propName, propValue, linkTo, validationScheme) => {
    let propValidationPatternsArr = validationScheme[propName] ?
      validationScheme[propName] : null;
    this.checkValidationRuleForProp(propValidationPatternsArr, propName);
    const errorLength = this.getErrorLength(propValidationPatternsArr, propValue);
    if (errorLength) {
      this.props.history.push(linkTo);
    }
    return errorLength;
  };
  validateAllData = () => {
    // if the name prop has error then redirect user to add service type edit page
    const errorLength = this.validatePageProps('name', this.props.addService.name, this.props.linkToTypeEdit, addServiceValidationScheme);

    if (!errorLength && this.props.linkToSheetsEdit) {
      // check if currency of first sheet is set, if not - redirect user to add service sheets edit page
      this.validatePageProps('sheetsLength', this.props.addService.sheets.length, this.props.linkToSheetsEdit, addServiceValidationScheme);
    }

  };
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if (prevProps.match.params !== this.props.match.params) {
      this.validateAllData();
    }
  }
  componentDidMount(): * {
    this.setState({isReadyToRender: true}, this.validateAllData);
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
        {this.props.children}
      </RenderOnReady>
    );
  }
}
const mapStateToProps = state => {
  return {
    addService: state.organization.item.addServiceEdit
  };
};
export default connect(mapStateToProps, null)(ValidateAllDataOnMount);