//@flow
import * as React from 'react';
import SimplifiedAlertWarning from "./SimplifiedAlertWarning";
import type {
  contractId,
  organizationId
} from "../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../services/localization/text";
import {Link} from "react-router-dom";
import {contrPathView, addAgreementsSuffix, orgPath} from "../../../../services/urlStrings/urlStrings";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";

type Props = {
  contractId?: contractId,
  isActTemplateMode?: boolean,
  organizationId?: organizationId,
  addClasses?: addClasses
};

const AlertUnverifiedAddAgreements = (props: Props) => {
  const linkTo = orgPath(props.organizationId) + contrPathView(props.contractId) + addAgreementsSuffix;
  return (
    <SimplifiedAlertWarning alertTypeClassNameSuffix={"ERROR"} addClasses={props.addClasses}>
      <div>{text.alertUnverifiedAddAgreementsLabel}</div>
      {props.isActTemplateMode ? (
        <div className={"mt_half-du"}>{text.alertUnverifiedAddAgreementsGenerationIsProhibited}</div>
      ) : null}
      {props.contractId ? (
        <div className="mt_half-du">
          <Link to={linkTo} className={"td_u_onHover"}>{text.linkToUnverifiedAddAgreements}</Link>
        </div>
      ) : null}
    </SimplifiedAlertWarning>
  );
};

export default AlertUnverifiedAddAgreements;