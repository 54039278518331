//@flow
import organizationParamsActions from './organizationParamsActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {organizationParams} from "../../../services/flowTypes/dataFlowTypes";
import update from "immutability-helper";

type reducerAction = action;
const initialState = {
  contract: null,
  agent: null
};

const organizationParamsReducer = (state:organizationParams = initialState, action:reducerAction) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case organizationParamsActions.ORGANIZATION_PARAMS_INIT:
      return initialState;
    case organizationParamsActions.ORGANIZATION_PARAMS_SET:
      return action.value;
    case organizationParamsActions.ORGANIZATION_PARAMS_ADD:
      return update(newState, {[action.value.itemType]: {$push: action.value.paramArr}});
    case organizationParamsActions.ORGANIZATION_PARAMS_DELETE:
      return update(newState, {[action.value.itemType]: {$splice: [[action.value.paramIndex, 1]]}});
    case organizationParamsActions.ORGANIZATION_PARAMS_UPDATE:
      return update(newState, {[action.value.itemType]: {[action.value.paramIndex]: {$set:  action.value.param}}});
    default:
      return state;
  }
};

export default organizationParamsReducer;