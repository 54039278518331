//@flow
import * as React from 'react';
import NCard from "../../simple/Containers/NCard/NCard";
import SheetFormGroup from "./SheetFormGroup";
import type {selectOptions, sheetIndex} from "../../../services/flowTypes/componentFlowTypes";
import text from "../../../services/localization/text";
import {connect} from "react-redux";
import type {currency, currencyOptions} from "../../../services/flowTypes/dataFlowTypes";
import getCurrencyLabel from "../../../utils/getCurrencyLabel";
import {sheetIds} from "../../../tests/testIds";
import './Sheet.css';
import SheetCalculatedItems from "./SheetCalculatedItems";
import SheetTotalAmount from "./SheetTotalAmount";
import SheetCalculatedItem from "./SheetCalculatedItem";
import getUniqueKey from "../../../utils/getUniqueKey";
import BtnDel from "../../simple/Buttons/BtnDel/BtnDel";
import sheetsActions from "../../../store/organization/item/sheets/sheetsActions";
import type {onDeleteSheet} from "../../../services/flowTypes/propFnFlowTypes";
import SheetCalculatedItemsHeader from "./SheetCalculatedItemsHeader";
import CurrencyValuesHandler from "./CurrencyValuesHandler";

type Props = {
  sheetIndex: sheetIndex,
  currencyOptions: currencyOptions,
  calculatedItemsLength: number,
  onDeleteSheet: onDeleteSheet
};
type State = {
  currencySelectOptions: selectOptions,
  selectedCurrencies: Array<currency>
};

class Sheet extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      currencySelectOptions: [],
      selectedCurrencies: []
    };
  }
  setCurrencySelectOptions = () => {
    const currencyOptionsWithExcludedSelectedValues = this.props.currencyOptions.filter(item => !this.state.selectedCurrencies.includes(item));
    const currencySelectOptions = currencyOptionsWithExcludedSelectedValues.map(item => {
      return {value: item, label: getCurrencyLabel(item)};
    });
    this.setState({currencySelectOptions});
  };
  setSelectedCurrencies = (arr) => {
    this.setState({selectedCurrencies: arr});
  };
  handleDelClick = () => {
    this.props.onDeleteSheet(this.props.sheetIndex);
  };
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if (prevState.selectedCurrencies !== this.state.selectedCurrencies) {
      this.setCurrencySelectOptions();
    }
  }
  componentDidMount(): * {
    this.setCurrencySelectOptions();
  }
  render(): React$Node {
    const currencyLabel = this.props.sheetIndex ? text.sheetLabels.secCurrency : text.sheetLabels.baseCurrency;
    let calculatedItemsComponents = [];
    for (let i=0; i < this.props.calculatedItemsLength; i++) {
      calculatedItemsComponents.push(<SheetCalculatedItem key={getUniqueKey()} sheetIndex={this.props.sheetIndex} calculatedItemIndex={i}/>);
    }
    return (
      <NCard addClasses={'NCard__wide-width mb_5du'}>
        <div className="flex_jc-sb mb_2du">
          <CurrencyValuesHandler setSelectedCurrencies={this.setSelectedCurrencies} sheetIndex={this.props.sheetIndex}/>
          <SheetFormGroup propName={'currency'} formFieldType={'select'} label={currencyLabel} isSearchable={true}
                          options={this.state.currencySelectOptions} placeholder={text.sheetPlaceholders.currency}
                          sheetIndex={this.props.sheetIndex} noOptionsMessage={text.sheetNoOptionsMessage.currency}
                          idForTest={sheetIds.selectCurrency(this.props.sheetIndex)} addClasses={'Sheet__currency-select'}
                          selectInputId={sheetIds.selectInputCurrency(this.props.sheetIndex)} />
          <div className="flex">
            <SheetTotalAmount sheetIndex={this.props.sheetIndex}/>
            {this.props.sheetIndex ? (
              <BtnDel id={sheetIds.buttonDeleteSheet(this.props.sheetIndex)}
                      addClasses={"ml_3du"} onClick={this.handleDelClick}/>
            ) : null}
          </div>
        </div>
        <SheetCalculatedItemsHeader/>
        <SheetCalculatedItems sheetIndex={this.props.sheetIndex} calculatedItemsComponents={calculatedItemsComponents}/>
      </NCard>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currencyOptions: state.organization.organizationData.configFinTerms.currencyOptions,
    calculatedItemsLength: state.organization.item.sheets[ownProps.sheetIndex].calculatedItems.length
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onDeleteSheet: (sheetIndex) => dispatch({
      type: sheetsActions.SHEET_DELETE, value: sheetIndex})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sheet);