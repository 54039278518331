//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {disabled, id, placeholder} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './NSelect.css';
import Select, { components } from 'react-select';
import type {
  isClearable,
  isSearchable, isSelectMenuWide, menuPlacement, noOptionsMessage,
  onSelectChange,
  selectedOption, selectOptions
} from "../../../../services/flowTypes/componentFlowTypes";
import TextOverflowHandler from "../../Containers/TextOverflowHandler/TextOverflowHandler";

type Props = {
  id?: id,
  addClasses?: addClasses,
  isSearchable?: isSearchable,
  value: selectedOption,
  onChange: onSelectChange,
  options: selectOptions,
  placeholder?: placeholder,
  noOptionsMessage?: noOptionsMessage,
  isClearable?: isClearable,
  isDisabled?: disabled,
  inputId?: id,
  isSelectMenuWide?: isSelectMenuWide,
  maxMenuHeight?: number,
  menuPlacement?: menuPlacement
};
//TODO: style ico according to ui kit, delete css file, handle test
const NSelect = (props: Props) => {
  const selectProps = {...props};
  const isSearchable = props.isSearchable ? props.isSearchable : false;
  const isClearable = props.isClearable ? props.isClearable : false;
  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: 'var(--border-radius)',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: '1px solid var(--form-field-border-color)',
      minHeight: 'var(--el-height)'
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent'
    }),
    menuList: (provided, state) => ({
      ...provided,
      backgroundColor: '#ffffff'
    }),
    menu: (provided, state) => ({
      ...provided,
      left: props.isSelectMenuWide ? '-50%' : '0',
      width: props.isSelectMenuWide ? '200%' : '100%',
      boxShadow: "var(--select-box-shadow)",
      marginTop: '0px'
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'var(--dark-text-color)',
      fontFamily: 'var(--main-font-family)',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '40px'
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: '14px'
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? 'var(--main-accent-color)' : 'var(--main-text-color)',
      backgroundColor: state.isFocused ? 'var(--item-back-color)' : '#ffffff',
      fontFamily: 'var(--main-font-family)',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '40px'
    })
  };
  const Option = (props) => {
    const label = typeof props.data.label === 'string' ?
      props.data.statusClassName ?
        <span className={props.data.statusClassName}>{props.data.label}</span> :
        <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{props.data.label}</TextOverflowHandler>
    : props.data.label;
    const innerProps = {...props.innerProps, id: props.data.value,
      "data-ui-test-id": selectProps.id + "_" + props.data.value};
    const newProps = {...props, innerProps: innerProps, children: label};
    return (
      <components.Option {...newProps} />
    );
  };
  const className = props.isDisabled ? "NSelect NSelect_disabled" : "NSelect";
  return (
    <div id={props.id} className={baseClass(className, props.addClasses)}>
      <Select
        menuPlacement={props.menuPlacement}
        maxMenuHeight={props.maxMenuHeight}
        components={{Option}}
        noOptionsMessage={props.noOptionsMessage}
        placeholder={props.placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        value={props.value}
        onChange={props.onChange}
        options={props.options}
        styles={customSelectStyles}
        inputId={props.inputId}
        isDisabled={props.isDisabled}
        // defaultMenuIsOpen={true}
      />
    </div>
  );
};

export default NSelect;