//@flow
import * as React from 'react';
import BaseLayout from "../components/composed/BaseLayout/BaseLayout";
import SupRoutes from "../components/service/Routes/SupRoutes";
import UnsetOrganizationId from "../components/service/Routes/UnsetOrganizationId";
import MainMenu from "../components/composed/MainMenu/MainMenu";

type Props = {};

class SupLayout extends React.Component <Props> {
  render() {
    return (
      <>
        <UnsetOrganizationId/>
        <BaseLayout
          leftSideBarContent={
            <>
              <MainMenu isSupLevel={true}/>
            </>
          }
          layoutContent={<SupRoutes/>}
          searchButton={<div style={{height: "78px"}}></div>}
        />
      </>
    );
  }
}

export default SupLayout;    