//@flow
import * as React from 'react';
import genSettingsMenuItems from "../../../configData/genSettingsMenuItems";
import SideLeftContainer from "../../../components/simple/Containers/SideLeftContainer/SideLeftContainer";
import ColumnMenu from "../../../components/simple/Containers/RowMenu/ColumnMenu";

type Props = {

};

const GenSettingsMenu = (props:Props) => {
  const menuItemsData = genSettingsMenuItems();
  return <SideLeftContainer>
    <ColumnMenu menuItemsData={menuItemsData} isGlobalPermission={true}/>
  </SideLeftContainer>
};
export default GenSettingsMenu;