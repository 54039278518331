//@flow
import {connect} from "react-redux";
import contractMainDataActions
    from "../../../../../../../store/organization/item/contractMainData/contractMainDataActions";
import text from "../../../../../../../services/localization/text";
import NFormGroup from "../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import NSelect from "../../../../../../../components/simple/FormComponents/NSelect/NSelect";
import Textarea from "../../../../../../../components/simple/FormComponents/Textarea/Textarea";
import CheckboxInput from "../../../../../../../components/simple/FormComponents/CheckboxInput/CheckboxInput";
import validationPatterns from "../../../../../../../services/validationPatterns/validationPatterns";
import validationActions from "../../../../../../../store/validation/validationActions";
import {useEffect} from "react";

type Props = {
    setMainDataProp: (prop: string, data: any) => void,
    onGetValidationStateFormProp: (prop: string, data: any) => void,
    validationIsAllowed: boolean,
    hasUnverifiedAddAgreements: boolean,
    isCheckedAndVerified: boolean,
    isNotVerifiedWithFinTerms: boolean,
    isNotVerifiedWithFinTermsComment?: string
}

const isNotVerifiedWithFinTermsOptions = [
    {value: "yes", label: text.isNotVerifiedWithFinTermsOptions.yes},
    {value: "no", label: text.isNotVerifiedWithFinTermsOptions.no}
];

const getVerifiedWithFinTermsOption = (val) => {
    if (val === undefined) {
        return undefined;
    }
    return isNotVerifiedWithFinTermsOptions.find(item => item.value === (val ? "yes" : "no"));
}

const ContractFinTermVerification = (props: Props) => {
    const {
        setMainDataProp,
        onGetValidationStateFormProp,
        validationIsAllowed,
        isCheckedAndVerified,
        isNotVerifiedWithFinTerms,
        isNotVerifiedWithFinTermsComment = '',
    } = props

    const finTermOption = getVerifiedWithFinTermsOption(isNotVerifiedWithFinTerms);
    const disableToEdit = finTermOption && finTermOption.value === 'yes' && isCheckedAndVerified
    useEffect(() => {
        onGetValidationStateFormProp('isNotVerifiedWithFinTerms', validationPatterns.required(finTermOption))
    }, [onGetValidationStateFormProp, finTermOption]);

    return <>
        <NFormGroup label={text.isNotVerifiedWithFinTerms}
                    addClasses={'mt_3du mb_3du'}
                    isRequired={true}
                    propError={validationIsAllowed && validationPatterns.required(finTermOption)}>
            <NSelect options={isNotVerifiedWithFinTermsOptions}
                     id="verified-with-fin-terms-options-id"
                     inputId="verified-with-fin-terms-input-id"
                     onChange={({value}) => {
                         setMainDataProp('isNotVerifiedWithFinTerms', value === "yes")
                         setMainDataProp('isCheckedAndVerified', false)
                     }}
                     value={finTermOption}
                     placeholder={text.isNotVerifiedWithFinTermsPlaceholder}
                     isDisabled={disableToEdit}/>
        </NFormGroup>
        {isNotVerifiedWithFinTerms ? (
            <NFormGroup label={text.isNotVerifiedWithFinTermsComment}>
                <Textarea value={isNotVerifiedWithFinTermsComment}
                          id="verified-with-fin-terms-comment-id"
                          onChange={(e) => setMainDataProp('isNotVerifiedWithFinTermsComment', e.currentTarget.value)}
                          disabled={disableToEdit}
                />
            </NFormGroup>
        ) : null}
        {isNotVerifiedWithFinTerms && isCheckedAndVerified !== undefined ? (
            <CheckboxInput checked={isCheckedAndVerified} addClasses={"mt_3du"}
                           onClick={() => setMainDataProp('isCheckedAndVerified', !isCheckedAndVerified)}
                           checkboxLabel={text.contract.isCheckedAndVerified}/>
        ) : null}
    </>;
}


const mapStateToProps = (state) => {
    return {
        validationIsAllowed: state.validation.validationIsAllowed,
        isCheckedAndVerified: state.organization.item.contractMainData.isCheckedAndVerified,
        isNotVerifiedWithFinTerms: state.organization.item.contractMainData.isNotVerifiedWithFinTerms,
        isNotVerifiedWithFinTermsComment: state.organization.item.contractMainData.isNotVerifiedWithFinTermsComment,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setMainDataProp: (prop, data) => dispatch({
            type: contractMainDataActions.CONTRACT_MAIN_DATA_PROP_SET, value: {
                prop: prop,
                data: data
            }
        }),
        onGetValidationStateFormProp: (prop, data) => dispatch({
            type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
                prop: prop,
                data: data
            }
        }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractFinTermVerification);