//@flow
import alertsActions from './alertsActions';
import type {action, alerts} from "../../services/flowTypes/appFlowTypes";
import update from 'immutability-helper';

type State = alerts;
type reducerAction = action;
const initialState = [];

const alertsReducer = (state:State = initialState, action:reducerAction) => {
  switch (action.type) {
    case alertsActions.ALERTS_STATE_INIT:
      return initialState;
    case alertsActions.ALERT_ADD:
      return update(state, {$push: [action.value]});
    case alertsActions.ALERT_DELETE:
      const alertIndex = state.findIndex(item => item.id === action.value);
      return update(state, {$splice: [[alertIndex, 1]]});
    default:
      return state;
  }
};

export default alertsReducer;