//@flow
import * as React from 'react';
import {connect} from "react-redux";
import NLabel from "../../simple/FormComponents/NLabel/NLabel";
import text from "../../../services/localization/text";
import NHeading2 from "../../simple/TextComponents/NHeading2/NHeading2";
import getCurrencyLabel from "../../../utils/getCurrencyLabel";
import type {currency, totalAmount} from "../../../services/flowTypes/dataFlowTypes";
import type {sheetIndex} from "../../../services/flowTypes/componentFlowTypes";
import getMaskedInputValue from "../../../utils/getMaskedInputValue";

type Props = {
  currency: currency,
  totalAmount: totalAmount,
  sheetIndex: sheetIndex
};

class SheetTotalAmount extends React.Component <Props> {
  render() {
    return (
      <div className="ta_r">
        <NLabel>{text.sheetLabels.totalAmount}</NLabel>
        <div className="flex jc_fe">
          <NHeading2 addClasses={'mr_1du NHeading2_lh40'}>{getMaskedInputValue(this.props.totalAmount)}</NHeading2>
          <NHeading2 addClasses={'NHeading2_grey NHeading2_lh40'}>{getCurrencyLabel(this.props.currency)}</NHeading2>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const sheet = state.organization.item.sheets[ownProps.sheetIndex]
  return {
    currency: sheet ? sheet.currency : '',
    totalAmount: sheet ? sheet.totalAmount : ''
  };
};
export default connect(mapStateToProps, null)(SheetTotalAmount);