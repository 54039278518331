//@flow
import * as React from 'react';
import NLabel from "../NLabel/NLabel";
import type {label, onChange} from "../../../../services/flowTypes/appFlowTypes";
import FormSingleDatePicker from "../FormSingleDatePicker/FormSingleDatePicker";
import type {datePropName, propValue} from "../../../../services/flowTypes/componentFlowTypes";
import type {itemDate} from "../../../../services/flowTypes/dataFlowTypes";
import {datePresets} from "../FormDateRangePicker/FormDateRangePicker";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import moment from "../../../../services/moment/momentInstance";

type Props = {
  label?: label,
  propValue: propValue,
  onChange: onChange,
  datePropName: datePropName,
  addClasses?: addClasses
};

class WrappedDayPicker extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  handleDateChange = (value:itemDate) => {
    const formattedValue = moment(value).format('YYYY-MM-DD');
    const newValue = {...this.props.propValue, [this.props.datePropName]: formattedValue};
    this.props.onChange(newValue);
  };
  render() {
    const date = this.props.propValue[this.props.datePropName];
    const datePresetsOfDayPicker = datePresets.slice(0, 2);
    const datePresetsBtns = datePresetsOfDayPicker.map((item,i) => {
      const onClick = () => this.handleDateChange(item.start);
      return (<div key={this.props.datePropName +'ShortcutBtn' +i} className="TagToCopy tt_uc mr_2du"
                   onClick={onClick}>{item.text}</div>);
    });
    return (
      <div className={baseClass("WrappedDayPicker", this.props.addClasses)}>
        <NLabel>{this.props.label}</NLabel>
        <FormSingleDatePicker date={date} dateId={this.props.datePropName + 'DateId'}
                              openDirection={'down'}
                              onDatesChange={this.handleDateChange} />
        <div className="flex mt_1du">
          {datePresetsBtns}
        </div>
      </div>
    );
  }
}

export default WrappedDayPicker;