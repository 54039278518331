//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  totalHistoryCount
} from "../../../services/flowTypes/dataFlowTypes";
import type {setFiltersToUrl} from "../../../services/flowTypes/propFnFlowTypes";
import Pagination from "../Pagination/Pagination";
import type {filtersFromParams} from "../../../services/flowTypes/appFlowTypes";
import type {isInitOnMount} from "../../../services/flowTypes/componentFlowTypes";

type Props = {
  total: totalHistoryCount,
  filtersFromParams: filtersFromParams,
  setFiltersToUrl: setFiltersToUrl,
  isInitOnMount?: isInitOnMount
};

class HistoryPagination extends React.Component <Props> {
  setOffset = (offset) => {
    const historyFiltersProps = { offset };
    const filtersFromParams = {...this.props.filtersFromParams, ...historyFiltersProps};
    this.props.setFiltersToUrl(filtersFromParams);
  };
  setCount = (count) => {
    const historyFiltersProps = { count, offset: 0 };
    const filtersFromParams = {...this.props.filtersFromParams, ...historyFiltersProps};
    this.props.setFiltersToUrl(filtersFromParams);
  };
  componentDidMount() {
    if (this.props.isInitOnMount) {
      const historyFiltersProps = { count: 20, offset: 0 };
      const filtersFromParams = {...this.props.filtersFromParams, ...historyFiltersProps};
      this.props.setFiltersToUrl(filtersFromParams);
    }
  }
  render() {
    return (
      <Pagination
        offset={+this.props.filtersFromParams.offset}
        count={+this.props.filtersFromParams.count}
        totalCount={this.props.total}
        setOffset={this.setOffset}
        setCount={this.setCount}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    total: state.organization.historyData.total
  };
};
export default connect(mapStateToProps, null)(HistoryPagination);