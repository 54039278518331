//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  formFieldType, isSearchable,
  propName,
  propValue,
  selectOptions
} from "../../../../../../../services/flowTypes/componentFlowTypes";
import {
  onGetActTemplateProp,
  onGetValidationStateFormProp
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import type {disabled, id, label, placeholder} from "../../../../../../../services/flowTypes/appFlowTypes";
import ConnectedFormGroup from "../../../../../../../components/composed/ConnectedFormGroup/ConnectedFormGroup";
import actTemplateValidationScheme from "../../../../../../../configData/actTemplateValidationScheme";
import type {addClasses} from "../../../../../../../services/flowTypes/uiKitFlowTypes";
import actTemplatesEditActions
  from "../../../../../../../store/organization/item/actTemplatesEdit/actTemplatesEditActions";
import PropValueView from "../../../../../../../components/composed/PropValueView/PropValueView";

type Props = {
  formFieldType: formFieldType,
  propName: propName,
  propValue: propValue,
  onGetActTemplateProp: onGetActTemplateProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  label: label,
  options?: selectOptions,
  addClasses?: addClasses,
  idForTest?: id,
  disabled?: disabled,
  placeholder?: placeholder,
  readonly?: boolean,
  isSearchable?: isSearchable,
  selectInputId?: id
};

class ActTemplatesFormGroup extends React.Component <Props, void> {
  onGetPropValue = (prop, data) => {
    this.props.onGetActTemplateProp(prop, data);
  };
  render() {
    const propValidationPatternsArr = actTemplateValidationScheme[this.props.propName] ?
      actTemplateValidationScheme[this.props.propName] : null;
    return (
        <>
        {this.props.readonly ?
          (this.props.propName === 'isVerified' ? null :
            <PropValueView label={this.props.label} value={this.props.propValue} testIdForValue={this.props.idForTest}/>)
              :
              <ConnectedFormGroup
                  formFieldType={this.props.formFieldType}
                  propName={this.props.propName}
                  propValue={this.props.propValue}
                  onGetPropValue={this.onGetPropValue}
                  label={this.props.label}
                  options={this.props.options}
                  propValidationPatternsArr={propValidationPatternsArr}
                  addClasses={this.props.addClasses}
                  idForTest={this.props.idForTest}
                  disabled={this.props.disabled}
                  placeholder={this.props.placeholder}
                  isSearchable={this.props.isSearchable}
                  selectInputId={this.props.selectInputId}
              />
        }
        </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propValue: state.organization.item.actTemplatesEdit[ownProps.propName]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetActTemplateProp: (prop, data) => dispatch({type: actTemplatesEditActions.ACT_TEMPLATES_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActTemplatesFormGroup);