//@flow
import * as React from 'react';
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import './DocRowContainer.css';
import type {leftColumn, rightColumn} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  leftColumn: leftColumn,
  rightColumn: rightColumn
};

const DocRowContainer = (props: Props) => {
  return (
    <div id={props.id} className={baseClass("DocRowContainer", props.addClasses)}>
      <div className={"DocRowContainer__left-column p_3du"}>{props.leftColumn}</div>
      <div className={"DocRowContainer__right-column p_3du"}>{props.rightColumn}</div>
    </div>
  );
};

export default DocRowContainer;