//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  contractId,
  gatesParams,
  gatesParamsResponse,
  gatesParamsSearch
} from "../../../../../../services/flowTypes/dataFlowTypes";
import GatesParamsCard from "./GatesParamsCard";
import text from "../../../../../../services/localization/text";
import contractGatesParamsActions
  from "../../../../../../store/organization/item/agentGatesParams/contractGatesParamsActions";
import Checkbox from "../../../../../../components/simple/FormComponents/Checkbox/Checkbox";
import clone from "../../../../../../utils/cloneObject";
import api from "../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import GatesParamsCardGroupFinTerms from "./GatesParamsCardGroupFinTerms";
import ConfirmActionModal from "../../../../../../components/simple/Modals/ConfirmActionModal/ConfirmActionModal";
import NHeading1 from "../../../../../../components/simple/TextComponents/NHeading1/NHeading1";
import FilterAndActInfoPanel from "../../../../../../components/composed/SortAndFilter/FilterAndActInfoPanel";
import gatesParamsSearchActions
  from "../../../../../../store/organization/item/gatesParamsSearch/gatesParamsSearchActions";
import TextActionButton from "../../../../../../components/simple/Buttons/TextActionButton/TextActionButton";
import type {errorObject, isReadyToRender} from "../../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import GatesParamsFilters from "./GatesParamsFilters";
import EmptyListV2 from "../../../../../../components/simple/Containers/EmptyList/EmptyListV2";
import contractMainDataActions
  from "../../../../../../store/organization/item/contractMainData/contractMainDataActions";

type Props = {
  gatesParams: gatesParams,
  gatesParamsResponse: gatesParamsResponse,
  contractId: contractId,
  onGetGatesParams: Function,
  onGetContractMainData: Function,
  gatesParamsSearch: gatesParamsSearch,
  onUpdateGatesParamsSearchProp: Function,
  total: number,
  foundByFilters: number,
  ignoreGates: boolean
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  chosenGates: gatesParams
};

class ChooseGatesParamsCards extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null,
      chosenGates: []
    };
  }
  cleanFinTermsAndIgnoresForChosenGates = () => {
    const gatesParams = this.props.gatesParams.map((item) => {
      const indexInChosenGate = this.state.chosenGates.findIndex(el => el.gateId === item.gateId);
      if (indexInChosenGate === -1) {
        return clone(item);
      } else {
        const terminals = item.terminals.map(terminal => {
          return {...terminal, groupFinTerms: [], isIgnored: false};
        });
        return  {...item, isIgnored: false, terminals};
      }
    });
    const gatesParamsResponse = {...this.props.gatesParamsResponse, gatesParams};
    this.updateGateParams(gatesParamsResponse);
  };
  ignoreChosenGates = () => {
    const gatesParams = this.props.gatesParams.map((item) => {
      const indexInChosenGate = this.state.chosenGates.findIndex(el => el.gateId === item.gateId);
      if (indexInChosenGate === -1) {
        return clone(item);
      } else {
        const terminals = item.terminals.map(terminalItem => {
          return {...terminalItem, isIgnored: true};
        });
        return  {...item, terminals, isIgnored: true};
      }
    });
    const gatesParamsResponse = {...this.props.gatesParamsResponse, gatesParams};
    this.updateGateParams(gatesParamsResponse);
  };
  defineSourceAndDisabled = () => {
    let source = this.state.chosenGates.length ? this.state.chosenGates[0].source : "";
    for (let i=0; i < this.state.chosenGates.length; i++) {
      if (source !== this.state.chosenGates[i].source) {
        return {source: null, disabled: true};
      }
    }
    return {source, disabled: !this.state.chosenGates.length};
  };
  getContractMainData = (callbackFn) => {
    api.getContractById(this.props.contractId)
      .then(response => {
        this.props.onGetContractMainData(response.data);
        this.resetChosen();
        if (callbackFn) {callbackFn();}
        this.setState({isReadyToRender: true});
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  updateGateParams = (gatesParamsResponse, callbackFn) => {
    this.setState({isReadyToRender: false});
    api.updateContractGatesParams(this.props.contractId, gatesParamsResponse, this.props.gatesParamsSearch)
      .then(response => {
        this.props.onGetGatesParams(response.data);
        this.getContractMainData(callbackFn);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  resetChosen = () => {
    this.setState({
      chosenGates: [],
      activeChooseBtn: ""
    })};
  isDisableForIgnoreAndAddFinTerms = () => {
    let hasFinTermsOrIgnored = false;
    for (let i=0; i < this.state.chosenGates.length; i++) {
      for (let j=0; j < this.state.chosenGates[i].terminals.length; j++) {
        hasFinTermsOrIgnored = !!this.state.chosenGates[i].terminals[j].groupFinTerms.length || this.state.chosenGates[i].terminals[j].isIgnored;
        if (hasFinTermsOrIgnored) {
          return hasFinTermsOrIgnored;
        }
      }
    }
    return hasFinTermsOrIgnored;
  };
  filterByBtn = (gateShowMode) => {
    this.props.onUpdateGatesParamsSearchProp({gateShowMode})
  };
  isFilterBtnActive = (gateShowMode) => {
    if (this.props.gatesParamsSearch.hasOwnProperty("gateShowMode")) {
      return this.props.gatesParamsSearch.gateShowMode === gateShowMode;
    } else {
      return false;
    }
  };
  handleCheckboxAllClick = () => {
    if (this.state.chosenGates.length === this.props.foundByFilters && this.state.chosenGates.length) {
      this.setState({chosenGates: []});
    } else {
      const chosenGates = this.props.gatesParams.map((item) => {return clone(item);});
      this.setState({chosenGates});
    }
  };
  defineIsHeaderCheckboxChecked = () => {
    if (!this.state.chosenGates.length) {
      return false;
    } else if (this.state.chosenGates.length !== this.props.foundByFilters) {
      return "some of all are chosen";
    } else if (this.state.chosenGates.length === this.props.foundByFilters) {
      return true;
    } else {
      throw new Error("Unknown case of checkbox view in ChooseGatesParamsCard.js");
    }
  };
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if (prevProps.gatesParamsSearch !== this.props.gatesParamsSearch) {
      this.setState({chosenGates: []});
    }
  }
  render() {
    const gatesParamsCards = this.props.gatesParams === null ? null :
      this.props.gatesParams && this.props.gatesParams.length ? this.props.gatesParams.map((item, i) => {
      const data = clone(item);
      const indexInChosenGate = this.state.chosenGates.findIndex(gateItem => gateItem.gateId === data.gateId);
      const checked = -1 !== indexInChosenGate;
      const onCheckboxClick = () => {
        const chosenGates = clone(this.state.chosenGates);
        if (checked) {
          chosenGates.splice(indexInChosenGate, 1);
        } else {
          chosenGates.push(data);
        }
        this.setState({chosenGates });
      };
      const checkbox = <Checkbox checked={checked} onClick={onCheckboxClick}
                                 isContainerMode={true} containerAddClasses={"mr_4du"}
                                 addClasses={"Checkbox_12"}/>;
      return <GatesParamsCard key={ "GatesParamsCard"+i} gatesParamsItem={item} gatesParamsItemIndex={i}
                              gatesParamsResponse={this.props.gatesParamsResponse}
                              ignoreGates={this.props.ignoreGates}
                              updateContractParams={this.updateGateParams} contractId={this.props.contractId} checkbox={checkbox}/>
    }) : <EmptyListV2/>;

    // const filterBtnArray = (<>
    //   <BtnEditAct isChooseActive={this.isFilterBtnActive("withErrors")}
    //               onClick={() => this.filterByBtn("withErrors")}
    //               addClasses={"mr_1du"}>{text.sortAndFilterInfoPanel.chooseWithErrors}</BtnEditAct>
    //   <BtnEditAct isChooseActive={this.isFilterBtnActive("withoutErrors")}
    //               onClick={() => this.filterByBtn("withoutErrors")}
    //               addClasses={"mr_1du"}>{text.sortAndFilterInfoPanel.chooseWithoutErrors}</BtnEditAct>
    //   <BtnEditAct isChooseActive={this.isFilterBtnActive("withIgnores")}
    //               onClick={() => this.filterByBtn("withIgnores")}
    //               addClasses={"mr_1du"}>{text.sortAndFilterInfoPanel.chooseWithIgnores}</BtnEditAct>
    //   <BtnEditAct isChooseActive={this.isFilterBtnActive("all")}
    //               onClick={() => this.filterByBtn("all")} >
    //     {text.sortAndFilterInfoPanel.chooseAll}</BtnEditAct>
    //   </>);
    const checkboxComp = <Checkbox checked={this.defineIsHeaderCheckboxChecked()} onClick={this.handleCheckboxAllClick}
                                   addClasses={"Checkbox_12 mr_2du"}/>;

    const areDisabledForIgnoreAndAddFinTerms = this.isDisableForIgnoreAndAddFinTerms();
    const isCleanFinTermsAndIgnoresBtnDisabled = !this.state.chosenGates.length || !areDisabledForIgnoreAndAddFinTerms;
    const {source, disabled} = this.defineSourceAndDisabled();
    const isAddFinTermsBtnDisabled = disabled || areDisabledForIgnoreAndAddFinTerms;
    const chosenActionBtnArray = (<>
      <GatesParamsCardGroupFinTerms isInfoPanelMode={true}
                                    contractId={this.props.contractId}
                                    source={source}
                                    updateContractParams={this.updateGateParams}
                                    chosenGates={this.state.chosenGates}
                                    gatesParamsResponse={this.props.gatesParamsResponse}
                                    disabled={isAddFinTermsBtnDisabled}
      />
      <ConfirmActionModal addClasses={"ml_1du"} initActionBtn={
        <TextActionButton disabled={!this.state.chosenGates.length || areDisabledForIgnoreAndAddFinTerms} addClasses={"TextActionButton_in_InfoPanel"}>
          {text.sortAndFilterInfoPanel.ignoreBtnLabel}</TextActionButton>
      }
                          onModalActionBtnClick={this.ignoreChosenGates}
                          disabledBtn={!this.state.chosenGates.length || areDisabledForIgnoreAndAddFinTerms}>
        <NHeading1 addClasses={"mt_2du mb_2du ta_c"}>{text.confirmIgnoreSelectedGates}</NHeading1>
      </ConfirmActionModal>
      <ConfirmActionModal addClasses={"ml_1du"} initActionBtn={
        <TextActionButton disabled={isCleanFinTermsAndIgnoresBtnDisabled} addClasses={"TextActionButton_in_InfoPanel"}>
          {text.sortAndFilterInfoPanel.cleanFinTermsAndIgnores}</TextActionButton>
      }
                          onModalActionBtnClick={this.cleanFinTermsAndIgnoresForChosenGates}
                          disabledBtn={isCleanFinTermsAndIgnoresBtnDisabled}>
        <NHeading1 addClasses={"mt_2du mb_2du ta_c"}>{text.confirmCleanFinTermsAndIgnoresForChosenGates}</NHeading1>
      </ConfirmActionModal>
    </>);
    return (
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <div className="GatesParams__filters-container">
          <GatesParamsFilters />
          <FilterAndActInfoPanel totalAmount={this.props.total} filteredAmount={this.props.foundByFilters}
                                 chosenAmount={this.state.chosenGates.length}
            // filterBtnArray={filterBtnArray}
                                 checkboxComp={this.props.ignoreGates ? null : checkboxComp} chosenActionBtnArray={chosenActionBtnArray}
                                 addClasses={""}
          />
        </div>
        {gatesParamsCards}
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    gatesParams: state.organization.item.contractGatesParams.gatesParams,
    gatesParamsResponse: state.organization.item.contractGatesParams,
    gatesParamsSearch: state.organization.item.gatesParamsSearch,
    total: state.organization.item.contractGatesParams.total,
    foundByFilters: state.organization.item.contractGatesParams.foundByFilters
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onUpdateGatesParamsSearchProp: (gatesParamsSearchObj) => dispatch({
      type: gatesParamsSearchActions.GATES_PARAMS_SEARCH_PROP_UPDATE, value: gatesParamsSearchObj
    }),
    onGetGatesParams: (value) => dispatch({type: contractGatesParamsActions.CONTRACT_GATES_PARAMS_SET, value}),
    onGetContractMainData: (contractMainData) => dispatch({
      type: contractMainDataActions.CONTRACT_MAIN_DATA_SET,
      value: contractMainData
    })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChooseGatesParamsCards);
