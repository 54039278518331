//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {addAgreementsListResponse, contractId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import AddAgreementCard from "./AddAgreementCard";
import type {
  mountDocumentsListFetcher,
  unmountDocumentsListFetcher
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import EmptyListV2 from "../../../../../../../components/simple/Containers/EmptyList/EmptyListV2";

type Props = {
  addAgreements: addAgreementsListResponse,
  contractId: contractId,
  mountDocumentsListFetcher: mountDocumentsListFetcher,
  unmountDocumentsListFetcher: unmountDocumentsListFetcher
};

class AddAgreementsList extends React.Component <Props, State> {

  render() {
    return  this.props.addAgreements === null ? null :
      this.props.addAgreements && this.props.addAgreements.length ? this.props.addAgreements.map((item, i)=> {
      return (<AddAgreementCard key={'addAgreement'+i} addAgreement={item} addAgreementIndex={i}
                                mountDocumentsListFetcher={this.props.mountDocumentsListFetcher}
                                unmountDocumentsListFetcher={this.props.unmountDocumentsListFetcher}
                                contractId={this.props.contractId}/>);
    }) : <EmptyListV2/>;
  }
}

const mapStateToProps = state => {
  return {
    addAgreements: state.organization.item.addAgreements
  };
};
export default connect(mapStateToProps, null)(AddAgreementsList);