//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id, onClick, onMouseOut, onMouseOver} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './BtnLogout.css';
import logoutIcon from './../../../../assets/v4-logout-icon.svg';
import logoutIconHover from './../../../../assets/v4-logout-icon-hover.svg';
import HoveredIcon from "../../../composed/HoveredIcon/HoveredIcon";

type Props = {
  id?: id,
  addClasses?: addClasses,
  onClick: onClick,
  onMouseOver?: onMouseOver,
  onMouseOut?: onMouseOut
};

const BtnLogout = (props: Props) => {
  return (
    <button type="button" id={props.id} onClick={props.onClick}
            onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut}
         className={baseClass("BtnLogout", props.addClasses)}>
      <HoveredIcon baseIconSrc={logoutIcon} hoveredIconSrc={logoutIconHover}/>
    </button>
  );
};

export default BtnLogout;