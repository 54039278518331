//@flow
import * as React from 'react';
import './Filters.css';
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../pages/UiKit/newUiKit/utilities/baseClass";
import text from "../../../services/localization/text";

type Props = {
  addClasses?: addClasses
};
const SelectFilterPlaceholder = (props:Props) => {
  return (
    <div className={baseClass("SelectFilterPlaceholder", props.addClasses)}>
      <div className="SelectFilterPlaceholder__icon mr_2du"/>
      {text.selectFilterPlaceholder}
    </div>
  );
};

export default SelectFilterPlaceholder;