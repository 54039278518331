//@flow
import * as React from 'react';
import {connect} from "react-redux";
import BtnDel from "../../simple/Buttons/BtnDel/BtnDel";
import SheetCalculatedItemFormGroup from "./SheetCalculatedItemFormGroup";
import type {selectOptions, sheetIndex} from "../../../services/flowTypes/componentFlowTypes";
import type {addServiceCalculatedItemIndex, addServiceType} from "../../../services/flowTypes/dataFlowTypes";
import {sheetIds} from "../../../tests/testIds";
import text from "../../../services/localization/text";
import sheetsActions from "../../../store/organization/item/sheets/sheetsActions";
import type {onDeleteSheetCalculatedItem} from "../../../services/flowTypes/propFnFlowTypes";

type Props = {
  addServiceTypeList: Array<addServiceType>,
  sheetIndex: sheetIndex,
  calculatedItemIndex: addServiceCalculatedItemIndex,
  onDeleteSheetCalculatedItem: onDeleteSheetCalculatedItem
};
type State = {
  addServiceTypeOptions: selectOptions
};

class SheetCalculatedItem extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      addServiceTypeOptions: []
    };
  }
  handleDelClick = () => {
    this.props.onDeleteSheetCalculatedItem(this.props.sheetIndex, this.props.calculatedItemIndex);
  };
  componentDidMount(): * {
    const addServiceTypeOptions = this.props.addServiceTypeList.map(item => {
      return {value: item.id, label: item.name};
    });
    this.setState({addServiceTypeOptions});
  }
  render() {
    return (
      <div className="SheetCalculatedItem mb_2du">
        <div className="DnD-ico-container mr_3du"/>
        <div className="SheetCalculatedItem__addServiceTypeId mr_1du">
          <SheetCalculatedItemFormGroup propName={'addServiceTypeId'} formFieldType={'select'} sheetIndex={this.props.sheetIndex}
                                        calculatedItemIndex={this.props.calculatedItemIndex}
                                        idForTest={sheetIds.selectSheetAddServiceType(this.props.sheetIndex, this.props.calculatedItemIndex)}
                                        selectInputId={sheetIds.selectInputSheetAddServiceType(this.props.sheetIndex, this.props.calculatedItemIndex)}
                                        placeholder={text.sheetCalculatedItemPlaceholders.addServiceTypeId}
                                        isSearchable={true} options={this.state.addServiceTypeOptions}
                                        noOptionsMessage={()=>text.sheetCalculatedItemNoOptionsMessage.addServiceTypeId} />
        </div>
        <div className="SheetCalculatedItem__price mr_1du">
          <SheetCalculatedItemFormGroup propName={'price'} formFieldType={'input'} sheetIndex={this.props.sheetIndex}
                                        calculatedItemIndex={this.props.calculatedItemIndex}
                                        withCurrencyIco={true}
                                        idForTest={sheetIds.inputSheetCalculatedItemPrice(this.props.sheetIndex, this.props.calculatedItemIndex)}/>
        </div>
        <div className="SheetCalculatedItem__number mr_1du">
          <SheetCalculatedItemFormGroup propName={'number'} formFieldType={'input'} sheetIndex={this.props.sheetIndex}
                                        calculatedItemIndex={this.props.calculatedItemIndex}
                                        idForTest={sheetIds.inputSheetCalculatedItemNumber(this.props.sheetIndex, this.props.calculatedItemIndex)}/>
        </div>
        <div className="SheetCalculatedItem__amount">
          <SheetCalculatedItemFormGroup propName={'amount'} isOutputMode={true} sheetIndex={this.props.sheetIndex}
                                        calculatedItemIndex={this.props.calculatedItemIndex}
                                        withCurrencyIco={true}
                                        idForTest={sheetIds.divSheetCalculatedItemAmount(this.props.sheetIndex, this.props.calculatedItemIndex)}/>
        </div>

        <BtnDel id={sheetIds.buttonDeleteSheetCalculatedItem(this.props.sheetIndex, this.props.calculatedItemIndex)}
                addClasses={"ml_1du"} onClick={this.handleDelClick}/>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    addServiceTypeList: state.organization.organizationData.statuses.addServiceTypeList
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onDeleteSheetCalculatedItem: (sheetIndex, calculatedItemIndex) => dispatch({type: sheetsActions.SHEETS_CALCULATED_ITEM_DELETE,
      value: {
        sheetIndex, calculatedItemIndex
      }
    })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SheetCalculatedItem);    