//@flow
import * as React from 'react';
import {connect} from "react-redux";
import {
  onSetSourceParamsEdit
} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import RenderOnReady from "../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import sourceParamsEditActions
  from "../../../../../../../../store/organization/item/sourceParamsEdit/sourceParamsEditActions";
import type {isReadyToRender} from "../../../../../../../../services/flowTypes/appFlowTypes";
import type {sourceParams} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import SourceParamsEdit from "./SourceParamsEdit/SourceParamsEdit";

type Props = {
  sourceParams: sourceParams,
  onSetSourceParamsEdit: onSetSourceParamsEdit
};
type State = {
  isReadyToRender: isReadyToRender
};
class SetSourceParamsToEdit extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
    };
  }
  setSourceParamsToEdit = () => {
    const sourceParams = this.props.sourceParams.map(item => {
      return {...item};
    });
    this.props.onSetSourceParamsEdit(sourceParams);
  };
  componentDidMount(){
    this.setSourceParamsToEdit();
    this.setState({isReadyToRender: true});
  }
  componentDidUpdate(){
    // console.log('how many times set sourceParams to edit is called in cdu');
    this.setSourceParamsToEdit();
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} >
        <SourceParamsEdit/>
      </RenderOnReady>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    sourceParams: state.organization.item.groupFinTermsEdit.sourceParams
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSetSourceParamsEdit: (data) => dispatch({type: sourceParamsEditActions.SOURCE_PARAMS_EDIT_SET, value: data})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SetSourceParamsToEdit);