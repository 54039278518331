//@flow
import * as React from 'react';
import NCard from "../../../../../../../../../components/simple/Containers/NCard/NCard";
import PayoutPeriodComplianceValidator from "./PayoutPeriodComplianceValidator";
import NHeading2 from "../../../../../../../../../components/simple/TextComponents/NHeading2/NHeading2";
import TextOverflowHandler
  from "../../../../../../../../../components/simple/Containers/TextOverflowHandler/TextOverflowHandler";
import concatIdName from "../../../../../../../../../utils/concatIdName";
import getNameOrDefName from "../../../../../../../../../utils/getNameOrDefName";
import text from "../../../../../../../../../services/localization/text";
import BtnDel from "../../../../../../../../../components/simple/Buttons/BtnDel/BtnDel";
import {actTemplatesEditPageIds} from "../../../../../../../../../tests/testIds";
import ConnectedGroupFinTermBlock
  from "../../../../../../../../../components/composed/ConnectedGroupFinTermBlock/ConnectedGroupFinTermBlock";
import GroupFinTermTemplateParams from "./GroupFinTermTemplateParams";
import type {groupFinTermId} from "../../../../../../../../../services/flowTypes/dataFlowTypes";
import type {
  handleExcludeGroupFinTerm,
  handleGroupFinTermData
} from "../../../../../../../../../services/flowTypes/propFnFlowTypes";
import type {groupFinTermIndex} from "../../../../../../../../../services/flowTypes/componentFlowTypes";

type Props = {
  groupFinTermId: groupFinTermId,
  handleGroupFinTermData: handleGroupFinTermData,
  groupFinTermIndex: groupFinTermIndex,
  handleExcludeGroupFinTerm: handleExcludeGroupFinTerm,
  readonly?: boolean
};

class ActTemplateFinTermsCard extends React.Component <Props> {
  shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
    return nextProps.groupFinTermId !== this.props.groupFinTermId;
  }
  render() {
    const groupFinTermObj = this.props.handleGroupFinTermData(this.props.groupFinTermId);
    if (groupFinTermObj) {
    
      const onBtnDelClick = () => this.props.handleExcludeGroupFinTerm(groupFinTermObj.groupId);
      return (
        <NCard addClasses={'mb_1du'} >
          <PayoutPeriodComplianceValidator groupFinTermIndex={this.props.groupFinTermIndex}
                                           groupFinTermPayoutPeriod={groupFinTermObj.payoutPeriod}>
            <div className="flex_jc-sb mb_3du">
              <NHeading2 addClasses={'w_half-card pos_rel'}>
                <div className="hookIdAbsPos" id={'groupFinTermCard'+groupFinTermObj.groupId}></div>
                <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>
                  {concatIdName(groupFinTermObj.groupId, getNameOrDefName(groupFinTermObj.name, text.finTerm))}
                </TextOverflowHandler>
              </NHeading2>
              {this.props.readonly ? null :
                <div className="flex">
                  <BtnDel id={actTemplatesEditPageIds.buttonDeleteGroupFinTerm(this.props.groupFinTermIndex)} addClasses={'ml_1du'}
                          onClick={onBtnDelClick}/>
                </div>
              }
            </div>
            <ConnectedGroupFinTermBlock addClasses={'GroupFinTermBlock_with-back'}
                                        groupFinTerm={groupFinTermObj}
            />
            <GroupFinTermTemplateParams
              groupFinTermId={this.props.groupFinTermId}
              groupFinTermCurrency={groupFinTermObj.currency} readonly={this.props.readonly}/>
          </PayoutPeriodComplianceValidator>
        </NCard>
      );
    } else {
      return <NCard>groupFinTermObj is not found (ActTemplateFinTermsCard.js)</NCard>;
    }
  }
}

export default ActTemplateFinTermsCard;    