//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {history, locationType, match} from "../../../../../../services/flowTypes/appFlowTypes";
import text from "../../../../../../services/localization/text";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import {userEditPageIds} from "../../../../../../tests/testIds";
import UserMainDataFormGroup from "./userMainDataEdit/UserMainDataFormGroup";
import type {selectOptions} from "../../../../../../services/flowTypes/componentFlowTypes";
import type {
  globalPermissions,
  globalPermissionsList,
  userStatusList
} from "../../../../../../services/flowTypes/dataFlowTypes";
import StatusLabel from "../../../../../../components/simple/TextComponents/StatusLabel/StatusLabel";
import CleanValidationStateOnUnmount
  from "../../../../../../components/service/CleanValidationStateOnUnmount/CleanValidationStateOnUnmount";
import AdaptiveFormContainer
  from "../../../../../../components/simple/Containers/AdaptiveFormContainer/AdaptiveFormContainer";

type Props = {
  history: history,
  match: match,
  userStatusList: userStatusList,
  globalPermissionsList: globalPermissionsList,
  globalPermissions: globalPermissions,
  location: locationType
};
type State = {
  userStatusOptions: selectOptions,
  globalPermissionsOptions: selectOptions
};

class UserMainDataEdit extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      globalPermissionsOptions: [],
      userStatusOptions: []
    };
  }
  componentDidMount(){
    const userStatusOptions = this.props.userStatusList.map(item => {
      return {value: item,
        label: (<StatusLabel addClasses={`StatusLabel_${item}`}>{text.userStatus[item]}</StatusLabel>)};
    });
    const globalPermissionsOptions = this.props.globalPermissionsList.map(item => {
      return {value: item.name, label: item.name};
    });
    this.setState({userStatusOptions, globalPermissionsOptions});
  }
  render() {
    const userId = this.props.match.params.id ? +this.props.match.params.id : null;
    return (
      <>
        <CleanValidationStateOnUnmount/>
        <NCard addClasses={'mb_3du'}>
          <AdaptiveFormContainer fieldsArr={[
            <UserMainDataFormGroup propName={"name"} label={text.userName} formFieldType={'input'} key={"name"}
                                   addClasses={'w_half-card'} idForTest={userEditPageIds.inputName}/>,
            <div>
              {userId ? (
                <UserMainDataFormGroup propName={"status"} label={text.status} formFieldType={'select'}
                                       options={this.state.userStatusOptions} isSearchable={true}
                                       addClasses={'w_half-card'} idForTest={userEditPageIds.selectStatus}/>
              ) : null}
            </div>
          ]} containerMode={"twoHalfCardWidthFields"} addClasses={"mb_3du"}/>
          <AdaptiveFormContainer fieldsArr={[
            <UserMainDataFormGroup propName={"phone"} label={text.phone} formFieldType={'input'} key={"phone"}
                                   addClasses={'w_half-card'} idForTest={userEditPageIds.inputPhone}/>,
            <div key={"email"}>
              {userId ? null : (
                <UserMainDataFormGroup propName={"email"}  label={text.email} formFieldType={'input'}
                                       addClasses={'w_half-card'} idForTest={userEditPageIds.inputEmail}/>
              )}
            </div>
          ]} containerMode={"twoHalfCardWidthFields"} addClasses={"mb_3du"}/>
          {/*<div>*/}
          {/*  {this.props.globalPermissions ? (*/}
          {/*    <UserMainDataFormGroup propName={"globalPermissions"} label={text.globalPermissionsLabel}*/}
          {/*                           formFieldType={'tagSelect'} options={this.state.globalPermissionsOptions}*/}
          {/*                           creationIsProhibited={true} placeholder={text.globalPermissionsPlaceholder}*/}
          {/*                           idForTest={userEditPageIds.inputGlobalPermissions}/>*/}
          {/*  ) : null}*/}
          {/*</div>*/}
        </NCard>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    userStatusList: state.admin.statuses.userStatusList,
    globalPermissionsList: state.admin.globalPermissionsList,
    globalPermissions: state.user.mainData.globalPermissions
  };
};

export default connect(mapStateToProps, null)(UserMainDataEdit);
