import {
  agentPathView, contractsSuffix,
  contrPathView,
  documentsSuffix,
  mainDataSuffix,
  orgPath
} from "../services/urlStrings/urlStrings";

const getItemPathView = (organizationId, itemType, itemId) => {
  switch (itemType) {
    case 'agent':
      return orgPath(organizationId) + agentPathView(itemId) + mainDataSuffix;
    case 'contract':
      return orgPath(organizationId) + contrPathView(itemId) + mainDataSuffix;
    case 'organization':
      return orgPath(organizationId) + contractsSuffix;
    default:
      throw new Error('undefined itemType');
  }
};
export const getItemPathDocumentsView = (organizationId, itemType, itemId) => {
  switch (itemType) {
    case 'agent':
      return orgPath(organizationId) + agentPathView(itemId) + documentsSuffix;
    case 'contract':
      return orgPath(organizationId) + contrPathView(itemId) + documentsSuffix;
    case 'organization':
      return orgPath(organizationId) + documentsSuffix;
    default:
      throw new Error('undefined itemType');
  }
};
export default getItemPathView;