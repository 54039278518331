//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {checked, id, onClick} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './Checkbox.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  onClick?: onClick,
  checked: checked | "some of all are chosen",
  isContainerMode?: boolean,
  containerAddClasses?: addClasses
};

const Checkbox = (props: Props) => {
  const className = props.checked ?
    (props.checked === "some of all are chosen" ? "Checkbox Checkbox_checkedSome" : "Checkbox Checkbox_checked")
    : "Checkbox";
  const containerClassName = props.checked ? "Checkbox__container Checkbox__container_checked" : "Checkbox__container";
  return (
    props.isContainerMode ?
      <div className={baseClass(containerClassName, props.containerAddClasses)}>
        <div id={props.id} className={baseClass(className, props.addClasses)} onClick={props.onClick}/>
      </div>
      :
    <div id={props.id} className={baseClass(className, props.addClasses)} onClick={props.onClick}/>
  );
};

export default Checkbox;