//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../service/RenderOnReady/RenderOnReady";
import alertsActions from "../../../store/alerts/alertsActions";
import type {onAddAlert} from "../../../services/flowTypes/propFnFlowTypes";
import moment from '../../../services/moment/momentInstance';
import type {itemDate} from "../../../services/flowTypes/dataFlowTypes";
import alertsList from "../../../services/alertsList/alertsList";

type Props = {
  endDate: itemDate,
  onAddAlert: onAddAlert
};

class AlertOnEndDateIncompliance extends React.Component <Props>{
  showAlert = () => {
    if(moment().isAfter(this.props.endDate, 'day')){
      this.props.onAddAlert(alertsList.onEndDateIncompliance);
    }
  };
  componentDidUpdate(prevProps){
    if (this.props.endDate && prevProps.endDate !== this.props.endDate) {
      this.showAlert();
    }
  }
  render(){
    return (
      <RenderOnReady isReadyToRender={true}/>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAddAlert: (newAlert) => dispatch({
      type: alertsActions.ALERT_ADD, value: newAlert})
  };
};
export default connect(null, mapDispatchToProps)(AlertOnEndDateIncompliance);