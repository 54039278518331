//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {addServiceCalculatedItem} from "../../../services/flowTypes/dataFlowTypes";
import DragAndDrop from "../DragAndDrop/DragAndDrop";
import type {calculatedItemsComponents, sheetIndex} from "../../../services/flowTypes/componentFlowTypes";
import sheetsActions from "../../../store/organization/item/sheets/sheetsActions";
import type {onAddSheetCalculatedItem, onUpdateSheetCalculatedItems} from "../../../services/flowTypes/propFnFlowTypes";
import FullSizeButton from "../../simple/Buttons/FullSizeButton/FullSizeButton";
import {
  newCalculatedItem
} from "../../../pages/Contracts/pages/Contract/pages/AddServiceEdit/AddServiceSheetsEdit";
import CalculatedItemsLengthValidator from "./CalculatedItemsLengthValidator";
import SheetCalculationUpdater from "./SheetCalculationUpdater";
import {sheetIds} from "../../../tests/testIds";

type Props = {
  calculatedItems: Array<addServiceCalculatedItem>,
  sheetIndex: sheetIndex,
  onUpdateSheetCalculatedItems: onUpdateSheetCalculatedItems,
  calculatedItemsComponents: calculatedItemsComponents,
  onAddSheetCalculatedItem: onAddSheetCalculatedItem
};

class SheetCalculatedItems extends React.Component <Props> {
  setNewOrder = (calculatedItems) => {
    const newCalculatedItems = calculatedItems.map((item) => {
      return {...item};
    });
    this.props.onUpdateSheetCalculatedItems(this.props.sheetIndex, newCalculatedItems);
  };
  handleAddCalculatedItemClick = () => {
    this.props.onAddSheetCalculatedItem(this.props.sheetIndex, newCalculatedItem);
  };
  render() {
    return (
      <>
        <DragAndDrop dragAndDropInitArr={this.props.calculatedItems} setNewOrder={this.setNewOrder}
                     dragAndDropItems={this.props.calculatedItemsComponents}/>
        <CalculatedItemsLengthValidator sheetIndex={this.props.sheetIndex}>
          <FullSizeButton addClasses={'FullSizeButton_h40'} onClick={this.handleAddCalculatedItemClick}
                          id={sheetIds.buttonAddSheetCalculatedItem(this.props.sheetIndex)}>
           {'+'}</FullSizeButton>
        </CalculatedItemsLengthValidator>
        <SheetCalculationUpdater sheetIndex={this.props.sheetIndex} propValue={this.props.calculatedItems.length}/>
      </>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    calculatedItems: state.organization.item.sheets[ownProps.sheetIndex].calculatedItems
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onUpdateSheetCalculatedItems: (sheetIndex, propValue) => dispatch({type: sheetsActions.SHEETS_CALCULATED_ITEMS_UPDATE,
      value: {sheetIndex, propValue}}),
    onAddSheetCalculatedItem: (sheetIndex, calculatedItem) => dispatch({type: sheetsActions.SHEETS_CALCULATED_ITEM_ADD,
      value: {sheetIndex, calculatedItem}})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SheetCalculatedItems);    