//@flow
import * as React from 'react';
import {connect} from "react-redux";
import NCard from "../../../../../../../components/simple/Containers/NCard/NCard";
import type {
  actTemplateId, addendumTemplateFileName, contractId, fileName, groupFinTermId, isAddendumFileDeleted, isScrollToError,
  itemDate, reportFileNamePattern, validationIsAllowed
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import Text1 from "../../../../../../../components/simple/TextComponents/Text1/Text1";
import text from "../../../../../../../services/localization/text";
import BtnEditAct from "../../../../../../../components/simple/Buttons/BtnEditAct/BtnEditAct";
import Document from "../../../../../../../components/composed/ItemDocuments/DocumentEdit/Document/Document";
import boldWhiteArrow from '../../../../../../../assets/bold-white-arrow.svg';
import actTemplatesEditActions
  from "../../../../../../../store/organization/item/actTemplatesEdit/actTemplatesEditActions";
import {
  onGetActTemplateProp,
  onGetValidationStateFormProp
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import NFormGroup from "../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import validationActions from "../../../../../../../store/validation/validationActions";
import {actTemplatesEditPageIds} from "../../../../../../../tests/testIds";
import InputFile from "../../../../../../../components/simple/FormComponents/InputFile/InputFile";
import type {
  onAddAlert,
  onDeleteActTemplateProp, onDeleteValidationStateFormProp,
  onGetValidationStateProp
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import alertsActions from "../../../../../../../store/alerts/alertsActions";
import alertsList from "../../../../../../../services/alertsList/alertsList";
import ConnectedViewTemplateParams from "../../ActTemplateView/ViewTemplateParams/ConnectedViewTemplateParams";
import api from "../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import type {errorObject, isReadyToRender} from "../../../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import {defineWrongFileName} from "../../../../../../../components/composed/ItemDocuments/DocumentUploader/DocumentUploaderContent";
import AlertOnWrongFileName from "../../../../../../../components/composed/Alerts/AlertOnWrongFileName";

type Props = {
  addendumTemplateFileName: addendumTemplateFileName,
  documentDate: itemDate,
  actTemplateId: actTemplateId,
  onGetActTemplateProp: onGetActTemplateProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  onGetValidationStateProp: onGetValidationStateProp,
  onDeleteActTemplateProp: onDeleteActTemplateProp,
  onDeleteValidationStateFormProp: onDeleteValidationStateFormProp,
  validationForm: Object,
  isScrollToError: isScrollToError,
  validationIsAllowed: validationIsAllowed,
  readonly?: boolean,
  contractId: contractId,
  finTermGroupIds: Array<groupFinTermId>,
  onAddAlert: onAddAlert,
  reportFileNamePattern: reportFileNamePattern,
  isAddendumFileDeleted: isAddendumFileDeleted
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  wrongFileName: fileName
};

class ActTemplateDocument extends React.Component <Props, State> {
  inputFile: { current: null | HTMLInputElement };
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true,
      wrongFileName: ''
    };
    this.inputFile = React.createRef();
  }
  getFormatFileErrorStr = (ext) => {
    switch (ext) {
      case 'xls':
        return text.errorFileFormat.oldXls;
      default:
        return text.errorFileFormat.unknownFileFormat;
    }
  };
  uploadDoc = () => {
    if (this.inputFile.current && this.inputFile.current.files) {
      this.setState({wrongFileName: ''});
      const file = this.inputFile.current.files[0];
      const fileName = this.inputFile.current.files[0].name;
      const wrongFileName = defineWrongFileName(fileName);
      if (wrongFileName) {
        this.setState({wrongFileName});
        return;
      }
      const re = /(?:\.([^.]+))?$/;
      const fileNameArr = re.exec(fileName);
      let ext;
      if(fileNameArr !== null){
        ext = fileNameArr[1];
      }
      this.props.onDeleteActTemplateProp('isAddendumFileDeleted');
      if(ext && ext !== 'xlsx'){
        this.props.onGetValidationStateFormProp('tempAddendumTemplateFileId', this.getFormatFileErrorStr(ext));
        this.props.onGetValidationStateProp('validationIsAllowed', true);
        this.props.onGetActTemplateProp('addendumTemplateFileName', fileName);
        this.props.onGetActTemplateProp('tempAddendumTemplateFileId', '');
        return false;
      }


      const formData = new FormData();
      formData.append('file', file);
      this.setState({isReadyToRender: false});
      api.uploadTempFile(formData)
        .then(response => {
          this.props.onGetActTemplateProp('tempAddendumTemplateFileId', response.data.id);
          this.props.onGetActTemplateProp('addendumTemplateFileName', fileName);
          this.props.onGetValidationStateFormProp('tempAddendumTemplateFileId', '');
          this.props.onAddAlert(alertsList.onAddendumTemplateFile);
          this.setState({isReadyToRender: true});
        })
        .catch(error => {
          this.setState({
            isReadyToRender: true,
            errorObject: apiErrorHandler(error)
          });
        });
    }
  };
  deleteAddendumTemplateFile = () => {
    this.props.onDeleteActTemplateProp('tempAddendumTemplateFileId');
    this.props.onDeleteValidationStateFormProp('tempAddendumTemplateFileId');
    this.props.onGetActTemplateProp('isAddendumFileDeleted', true);
    this.props.onGetActTemplateProp('addendumTemplateFileName', '');
    this.props.onGetActTemplateProp('addendumTemplateFileId', -1);
    this.props.onGetActTemplateProp('addendumTemplateFileDate', '');
  };
  componentDidMount(){
    if (!this.props.actTemplateId) {
      this.props.onGetValidationStateFormProp('tempAddendumTemplateFileId', '');
    }
  }
  render() {
    const document = {
      name: this.props.addendumTemplateFileName,
      fileDescription: '',
      createDate: this.props.addendumTemplateFileDate
    };
    const propError = this.props.validationIsAllowed ?
      (this.props.validationForm.tempAddendumTemplateFileId ? this.props.validationForm.tempAddendumTemplateFileId : '')
      : '';
    const documentUploader = (
      <NFormGroup propError={propError} isScrollToError={this.props.isScrollToError}>
        <div className={'flex'}>
          <AlertOnWrongFileName wrongFileName={this.state.wrongFileName}/>
          <InputFile id={actTemplatesEditPageIds.buttonUploadAddendumTemplateFile}  onInputFileChange={this.uploadDoc}
                     ref={this.inputFile} label={
            <div className="BtnNewItem pl-pr_2du">
              <img className={"ButtonNewItem__arrow"} src={boldWhiteArrow} alt="arrow-icon"/>
              {text.chooseAddendumTemplateFile}
            </div>
          }/>
          <BtnEditAct addClasses={'ml_3du'} onClick={null}>{this.props.addendumTemplateFileName}</BtnEditAct>
        </div>
      </NFormGroup>
    );
    const documentBtns = (this.props.readonly ? null :(
      <div className={'flex ai_c jc_fe'}>
        <AlertOnWrongFileName wrongFileName={this.state.wrongFileName}/>
        <InputFile id={actTemplatesEditPageIds.buttonUploadAddendumTemplateFile}  onInputFileChange={this.uploadDoc}
                   ref={this.inputFile} label={
          <div className="BtnEditAct">{text.substituteDocument}</div>
        }/>
        <BtnEditAct id={actTemplatesEditPageIds.buttonDeleteAddendumTemplateFile} onClick={this.deleteAddendumTemplateFile}
                    addClasses={'BtnEditAct_delete ml_2du'}>{text.deleteBtnLabel}</BtnEditAct>
      </div>

    ));
    const isUpload = this.props.addendumTemplateFile !== undefined;
    return (
      <NCard addClasses={'mb_3du'}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <Text1 addClasses={'mb_1du'}>{text.uploadActTemplateFile1} <b>{text.uploadActTemplateFile2addendum}</b>
          {text.uploadActTemplateFile3}<b>{text.uploadActTemplateFile4}</b>{'.'}
        </Text1>
        {this.props.finTermGroupIds.length ? (
          <ConnectedViewTemplateParams isAddendumTemplateParams={true} actTemplateId={this.props.actTemplateId}
                                       contractId={this.props.contractId} finTermGroupIds={this.props.finTermGroupIds}/>
        ) : null}
        {this.props.actTemplateId && !this.props.isAddendumFileDeleted ?
            this.props.addendumTemplateFileName ? (
              <NFormGroup propError={propError} isScrollToError={this.props.isScrollToError}>
                <Document document={document} itemId={this.props.actTemplateId} isAddendumTemplateFile={true}
                          documentBtns={documentBtns} isUpload={isUpload}
                          linkIdToDownload={actTemplatesEditPageIds.buttonDownloadAddendumTemplateFile}/>
              </NFormGroup>
            ) : (this.props.readonly ? <Text1>{text.uploadAddendumTemplateFileText}</Text1> : documentUploader)
         : (
          documentUploader
        )}
      </NCard>
    );
  }
}

const mapStateToProps = state => {
  return {
    addendumTemplateFileName: state.organization.item.actTemplatesEdit.addendumTemplateFileName,
    addendumTemplateFileDate: state.organization.item.actTemplatesEdit.addendumTemplateFileDate,
    isAddendumFileDeleted: state.organization.item.actTemplatesEdit.isAddendumFileDeleted,
    finTermGroupIds: state.organization.item.actTemplatesEdit.finTermGroupIds,
    validationForm: state.validation.form,
    isScrollToError: state.validation.isScrollToError,
    validationIsAllowed: state.validation.validationIsAllowed
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetActTemplateProp: (prop, data) => dispatch({type: actTemplatesEditActions.ACT_TEMPLATES_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetValidationStateProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onDeleteValidationStateFormProp: (prop) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_DELETE, value: prop}),
    onAddAlert: (newAlert) => dispatch({
      type: alertsActions.ALERT_ADD, value: newAlert}),
    onDeleteActTemplateProp: (propName) => dispatch({type: actTemplatesEditActions.ACT_TEMPLATES_PROP_DELETE, value: propName})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActTemplateDocument);