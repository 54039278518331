import * as React from 'react';
import {Route} from "react-router-dom";
import SideRightContainer from "../../simple/Containers/SideRightContainer/SideRightContainer";


const PrivateRoute = ({ component: Component, ...rest }) => {
    return (<Route
        {...rest}
        render={(props =>
            rest.canUseRoute ? (
                <Component {...props} {...rest.addProps}/>
            ) : null
            )}
    />);
};
const PrivateRouteInRightSidebar = ({ component: Component, ...rest }) => {
  return (<Route
    {...rest}
    render={(props =>
        rest.canUseRoute ? (
          <SideRightContainer addClasses={rest.addClasses}>
            <Component {...props} {...rest.addProps} />
          </SideRightContainer>
        ) : null
    )}
  />);
};

export default PrivateRoute;

export {
  PrivateRouteInRightSidebar
}