//@flow
import * as React from 'react';
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import './MainMenuItem.css';
import {NavLink} from "react-router-dom";
import type {
  hoveredIconComponent, linkId, linkText,
  linkTo,
  subMenuComponent, unviewedIcoComp
} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  addClasses?: addClasses,
  subMenuComponent?: subMenuComponent,
  linkTo: linkTo,
  linkId: linkId,
  hoveredIconComponent?: hoveredIconComponent,
  linkText: linkText,
  unviewedIcoComp?: unviewedIcoComp,
  dataUiTestId?: string
};

const MainMenuItem = (props: Props) => {
  const mainMenuItemContainerClassName = props.subMenuComponent ? "MainMenuItemContainer MainMenuItemContainer_hasSubMenu"
    : "MainMenuItemContainer";
  return (
    <div className={baseClass(mainMenuItemContainerClassName, props.addClasses)}>
      <NavLink activeClassName={"MainMenuItem_active"} className={"MainMenuItem"}
               id={props.linkId} data-ui-test-id={props.dataUiTestId}
               to={props.linkTo}>
        {props.hoveredIconComponent ? props.hoveredIconComponent : null}
        <div className={"MainMenuItem__linkText"}>{props.linkText}
          <div className="MainMenuItem__unviewedIcoComp">
            {props.unviewedIcoComp ? props.unviewedIcoComp : null}
          </div>
        </div>
        <div className="MainMenuItem__ledge"/>
      </NavLink>
      {props.subMenuComponent ? props.subMenuComponent : null}
      <div className="MainMenuItem__bottom-line"/>
    </div>
  );
};

export default MainMenuItem;