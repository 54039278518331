//@flow
import * as React from 'react';
import text from "../../services/localization/text";
import NFormGroup from "../../components/simple/FormComponents/NFormGroup/NFormGroup";
import NInput from "../../components/simple/FormComponents/NInput/NInput";
import type {propName as propNameType} from "../../services/flowTypes/componentFlowTypes";
import LoginFormContainer from "../../components/simple/Containers/LoginFormContainer/LoginFormContainer";
import type {userEmail} from "../../services/flowTypes/dataFlowTypes";
import type {passValue} from "../../services/flowTypes/appFlowTypes";
import {loginPages} from "../../tests/testIds";

type Props = {};
type State = {
  email: userEmail,
  password: passValue
};
class LoginPage extends React.Component <Props, State> {
  inputEmail: { current: null | HTMLInputElement };
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };
    this.inputEmail = React.createRef();
  }
  onChange = (e:SyntheticEvent<HTMLInputElement>, propName:propNameType) => {
    this.setState({
      [propName]: e.currentTarget.value
    });
  };
  componentDidMount(){
    if (this.inputEmail.current) {
      this.inputEmail.current.focus();
    }
  }
  render() {
    const propError = window.location.href.includes('?error') ? text.loginPassError : '';
    const windowData = window['LOGIN_DATA'];
    const formAction = '/doLogin';
    const emailRegexp = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/i;
    const isFormValid = emailRegexp.test(this.state.email);
    const submitIsDisabled = !isFormValid;
    const hashForRedirect = window.location.hash.length ? '/main' + window.location.hash : '';
    return (
      <LoginFormContainer formAction={formAction} propError={propError} submitIsDisabled={submitIsDisabled}>
        <NFormGroup label={text.loginLabel} addClasses={'mb_5du'}>
          <NInput name={'email'} value={this.state.email} onChange={(e)=>{this.onChange(e, 'email')}}
                  id={loginPages.email} inputType={'email'} ref={this.inputEmail}/>
        </NFormGroup>
        <NFormGroup label={text.passwordLabel} addClasses={'mb_5du'}>
          <NInput name={'password'} value={this.state.password} onChange={(e)=>{this.onChange(e, 'password')}}
                  id={loginPages.password} inputType={'password'} />
        </NFormGroup>
        <input type="hidden" name={windowData.csrfToken.name}
               value={windowData.csrfToken.value} />
        <input type="hidden" name={'hashForRedirect'}
               value={hashForRedirect} />
      </LoginFormContainer>
    );
  }
}

export default LoginPage;