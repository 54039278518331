//@flow
import * as React from 'react';
import type {isUnviewed} from "../../../../services/flowTypes/dataFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import './UnviewedMark.css';

type Props = {
  isUnviewed: isUnviewed,
  addClasses?: addClasses
};

const UnviewedMark = (props: Props) => {
  const className = props.isUnviewed ? "UnviewedMark UnviewedMark_on" : "UnviewedMark";
  return (
    <div className={baseClass(className, props.addClasses)}/>
  );
};

export default UnviewedMark;    