//@flow
import * as React from 'react';
import {connect} from "react-redux";
import ArrLengthValidator from "../../service/ArrLengthValidator/ArrLengthValidator";
import addServiceValidationScheme from "../../../configData/addServiceValidationScheme";
import type {addServiceCalculatedItem} from "../../../services/flowTypes/dataFlowTypes";
import type {sheetIndex} from "../../../services/flowTypes/componentFlowTypes";

type Props = {
  calculatedItems: Array<addServiceCalculatedItem>,
  sheetIndex: sheetIndex,
};

class CalculatedItemsLengthValidator extends React.Component <Props> {
  determineArrLength = () => {
    return this.props.calculatedItems.length;
  };
  render() {
    return (
      <ArrLengthValidator propName={'calculatedItemsLength'} arrLength={this.props.calculatedItems.length}
                          propValidationPatternsArr={addServiceValidationScheme.sheets.calculatedItemsLength}
                          determineArrLength={this.determineArrLength}>
        {this.props.children}
      </ArrLengthValidator>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    calculatedItems: state.organization.item.sheets[ownProps.sheetIndex].calculatedItems
  };
};
export default connect(mapStateToProps, null)(CalculatedItemsLengthValidator);