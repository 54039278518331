//@flow
import * as React from 'react';
import type {
  groupFinTermId
} from "../../../../../../../../../services/flowTypes/dataFlowTypes";
import {
  handleExcludeGroupFinTerm,
  handleGroupFinTermData
} from "../../../../../../../../../services/flowTypes/propFnFlowTypes";
import './ActTemplateFinTermsCards.css';
import ActTemplateFinTermsCard from "./ActTemplateFinTermsCard";

type Props = {
  finTermGroupIds: Array<groupFinTermId>,
  handleGroupFinTermData: handleGroupFinTermData,
  handleExcludeGroupFinTerm: handleExcludeGroupFinTerm,
  readonly?: boolean
};

class ActTemplateFinTermsCards extends React.Component <Props> {
  render(){
    const actTemplateFinTermsCards = this.props.finTermGroupIds.map((finTermGroupId, i) => {
      return (
        <ActTemplateFinTermsCard groupFinTermId={finTermGroupId} key={"ActTemplateFinTermsCard_"+finTermGroupId}
                                 readonly={this.props.readonly}
                                 handleGroupFinTermData={this.props.handleGroupFinTermData} groupFinTermIndex={i}
                                 handleExcludeGroupFinTerm={this.props.handleExcludeGroupFinTerm}/>
      );
    });
    return (
      actTemplateFinTermsCards
    );
  }
}

export default ActTemplateFinTermsCards;
