//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {errorObject, isReadyToRender} from "../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../components/service/RenderOnReady/RenderOnReady";
import api from "../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../services/apiInstance/apiErrorHandler";
import type {organizationsSearch} from "../../../../../services/flowTypes/dataFlowTypes";
import {handleResponse, onGetMyOrganizations, showSpinner} from "../../../../../services/flowTypes/propFnFlowTypes";
import type {searchIcoIsClicked} from "../../../../../services/flowTypes/componentFlowTypes";
import myOrganizationsSearchResultsActions
  from "../../../../../store/myOrganizations/myOrganizationsSearchResults/myOrganizationsSearchResultsActions";
import ability from "../../../../../services/permissions/permissions";
import {globalPermissionsStrValues} from "../../../../../configData/permissionsStrValues";
import debounce from 'lodash.debounce';

type Props = {
  myOrganizationsSearch: organizationsSearch,
  onGetMyOrganizations: onGetMyOrganizations,
  showSpinner: showSpinner,
  handleResponse: handleResponse,
  searchIcoIsClicked: searchIcoIsClicked
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};
class MyOrganizationsFetcher extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true
    };
    this.debounceMyOrganizationsRequest = debounce(this.getMyOrganizations, 500);
  }
  getMyOrganizationsSearchData = () => {
    const myOrganizationsSearchData = {...this.props.myOrganizationsSearch};
    if (!ability.can('useGlobalPermission', globalPermissionsStrValues.admin)) {
      myOrganizationsSearchData.status = '';
    }
    return myOrganizationsSearchData;
  };
  getMyOrganizations = () => {
    this.props.showSpinner();
    const myOrganizationSearchData = this.getMyOrganizationsSearchData();
    // emulateRequest(testOrgList)
    api.getMyOrganizationsList(myOrganizationSearchData)
      .then((response) => {
        this.props.onGetMyOrganizations(response.data);
        this.props.handleResponse();
        this.setState({isReadyToRender: true});
      })
      .catch((error) => {
        this.setState({
          errorObject: apiErrorHandler(error)
        });
        this.props.handleResponse();
      });
  };
  componentDidUpdate(prevProps){
    if ( (prevProps.myOrganizationsSearch !== this.props.myOrganizationsSearch)
      || (prevProps.searchIcoIsClicked !== this.props.searchIcoIsClicked
        && this.props.searchIcoIsClicked) ) {
      this.debounceMyOrganizationsRequest();
    }
  }
  componentDidMount(){
    this.getMyOrganizations();
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    myOrganizationsSearch: state.myOrganizations.search,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetMyOrganizations: (value) => dispatch({type: myOrganizationsSearchResultsActions.GET_MY_ORGANIZATIONS, value: value})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOrganizationsFetcher);