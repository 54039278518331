//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  organizationId,
  report, reportAccountStatusList, reportAccountStatusName, reportIds,
  usersList
} from "../../../../../services/flowTypes/dataFlowTypes";
import ability from "../../../../../services/permissions/permissions";
import {Link} from "react-router-dom";
import {reportsPageIds} from "../../../../../tests/testIds";
import './ReportCard.css';
import type {reportIndex} from "../../../../../services/flowTypes/componentFlowTypes";
import Text1 from "../../../../../components/simple/TextComponents/Text1/Text1";
import formatDate from "../../../../../utils/formatDate";
import Heading4 from "../../../../../components/simple/TextComponents/Heading4/Heading4";
import ReportCardDownloadDoc from "./ReportCardDownloadDoc/ReportCardDownloadDoc";
import TextOverflowHandler from "../../../../../components/simple/Containers/TextOverflowHandler/TextOverflowHandler";
import ReportContractLink from "./ReportContractLink/ReportContractLink";
import ReportActTemplateLink from "./ReportActTemplateLink/ReportActTemplateLink";
import ReportStatus from "./ReportStatus/ReportStatus";
import {updateReportIds} from "../../../../../services/flowTypes/propFnFlowTypes";
import ReportCardCheckbox from "./ReportCardCheckbox";
import {mainDataSuffix, orgPath, reportPathView} from "../../../../../services/urlStrings/urlStrings";
import ReportAgentLink from "./ReportAgentLink/ReportAgentLink";
import {getCurrencyIco} from "../../../../../utils/getCurrencyLabel";
import Text3 from "../../../../../components/simple/TextComponents/Text3/Text3";
import SecText from "../../../../../components/simple/TextComponents/SecText/SecText";
import ArrowIcon from "../../../../../components/simple/Icons/ArrowIcon/ArrowIcon";
import {permissionsStrValues} from "../../../../../configData/permissionsStrValues";

type Props = {
  report: report,
  organizationId: organizationId,
  reportIndex: reportIndex,
  usersList: usersList,
  reportAccountStatusList: reportAccountStatusList,
  updateReportIds?: updateReportIds,
  checkedReportIds?: reportIds,
  statusFromParams: reportAccountStatusName
};

class ReportCard extends React.Component <Props> {
  getAgentId = (typeOfAgent: 'payer' | 'payee') => {
    return this.props.report.finTermsList.length ? this.props.report.finTermsList[0][typeOfAgent] :
      this.props.report.addServicesList.length ? this.props.report.addServicesList[0][typeOfAgent]
        : null;
  };
  render() {
    const linkToReport = orgPath(this.props.organizationId) + reportPathView(this.props.report.id) + mainDataSuffix;
    const CardHeading = (
      <Heading4 addClasses={'Heading4_lh16 Heading4_link-on-hover mb_1du'}
                 id={reportsPageIds.headingCardReportId(this.props.reportIndex)}>
        <TextOverflowHandler>
          {"#" + this.props.report.id}
        </TextOverflowHandler>
      </Heading4>);
    const user = this.props.usersList.find(item => item.id === this.props.report.userId).name;
    const isDownloadLinkIsShown = this.props.report.reportStatus === 'SUCCESS';
    return (
      <div className={'ReportCard NCard NCard__wide-width mb_1du'}>
        <div className={"ReportCard__left"}>
          <div className="ReportCard__left-top">
            <div className="ReportCard__left-top-left">
              <div className={'ReportCard__id-and-checkbox'}>
                {ability.can('usePermission', permissionsStrValues.report.view) ? (
                  <Link to={linkToReport} id={reportsPageIds.linkToReport(this.props.reportIndex)}>
                    {CardHeading}
                  </Link>
                ) : (
                  CardHeading
                )}
                {(this.props.statusFromParams === "PREPARED" || this.props.statusFromParams === "ADJUSTED") ? (
                  <ReportCardCheckbox updateReportIds={this.props.updateReportIds} checkedReportIds={this.props.checkedReportIds}
                                      reportId={this.props.report.id}
                                      idForTest={reportsPageIds.checkboxReport(this.props.reportIndex)}/>
                ) : null}
              </div>
              <div className="ReportCard__period-and-status">
                <Text3 addClasses={'mb_1du'}
                       id={reportsPageIds.divReportStatus(this.props.reportIndex)}>
                  <ReportStatus reportAccountStatus={this.props.report.reportAccountStatus}
                                reportStatus={this.props.report.reportStatus} />
                </Text3>
                <SecText addClasses={'SecText_lh16'}>
                  <TextOverflowHandler>
                    {formatDate(this.props.report.startReportPeriodDate) + " - "
                    + formatDate(this.props.report.endReportPeriodDate)}
                  </TextOverflowHandler>
                </SecText>
              </div>
              <div className={'ReportCard__user-and-createDate'}>
                <Text3 addClasses={'mb_1du'}>
                  <TextOverflowHandler>
                    {user}
                  </TextOverflowHandler>
                </Text3>
                <SecText addClasses={'SecText_lh16'}>{formatDate(this.props.report.createDate)}</SecText>
              </div>
            </div>
            <div className="ReportCard__contract-and-actTemplate">
              <ReportContractLink organizationId={this.props.organizationId} contractId={this.props.report.contractId}
                                  contractName={this.props.report.contractName} parentComponentType={'card'}
                                  isLabelHidden={true} addClasses={"mb_1du"}/>
              <ReportActTemplateLink organizationId={this.props.organizationId} contractId={this.props.report.contractId}
                                     actTemplateId={this.props.report.actTemplateId}
                                     actTemplateName={this.props.report.actTemplateName} parentComponentType={'card'}
                                     isLabelHidden={true}/>
            </div>
          </div>
          <div className="ReportCard__left-bottom">
            <div className="ReportCard__agents">
              {this.props.report.finTermsList.length || this.props.report.addServicesList.length  ? (
                <>
                  <ReportAgentLink organizationId={this.props.organizationId} agentId={this.getAgentId('payerId')}
                                   parentComponentType={'card'} payerOrPayee={'payer'} isLabelHidden={true} />
                  <div className="flex">
                    <ArrowIcon/>
                    <ReportAgentLink organizationId={this.props.organizationId} agentId={this.getAgentId('payeeId')}
                                     parentComponentType={'card'} payerOrPayee={'payee'} isLabelHidden={true}/>
                  </div>
                </>
              ) : null}
            </div>
            <div className="ReportCard__reportTotal-docNum">
              <Text1 addClasses={'ReportCard__reportTotal Text1_card-label-color'}>
                {this.props.report.reportTotalCurrency ?
                  (<span className="mr_1du">{getCurrencyIco(this.props.report.reportTotalCurrency)}</span>) : ""}
                <b className="ws_n">{this.props.report.reportTotal === "N/A" ? this.props.report.reportTotal
                  : this.props.report.reportTotal}</b>
              </Text1>
              <div className="ReportCard__docNum flex">
                <div className={'ReportCard__1C-doc-name-icon mr_1du'}/>
                <Text1 addClasses={'Text1_card-label-color Text1_bold'}>
                  <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{this.props.report.docNum}
                  </TextOverflowHandler>
                </Text1>
              </div>
            </div>
          </div>
        </div>
        {isDownloadLinkIsShown ? (
          <div className="ReportCard__right">
            <ReportCardDownloadDoc
              parentComponentType={'card'}
              reportFileType={'report'}
              reportIndex={this.props.reportIndex}
              docNum={this.props.report.docNum}
              fileId={this.props.report.reportFileId}
              fileName={this.props.report.reportFileName}
              addServiceReportFileId={this.props.report.addServiceReportFileId}
              addServiceReportFileName={this.props.report.addServiceReportFileName}
              reportId={this.props.report.id}
              idForTest={reportsPageIds.linkToDownloadReport(this.props.reportIndex)}
              finTermsListLength={this.props.report.finTermsList.length}
              addServicesListLength={this.props.report.addServicesList ? this.props.report.addServicesList.length : 0}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    organizationId: state.organization.organizationId,
    usersList: state.organization.organizationData.usersList,
    reportAccountStatusList: state.organization.organizationData.statuses.reportAccountStatusList
  };
};
export default connect(mapStateToProps, null)(ReportCard);