export const testSelectedOption = {
  value: 'test1',
  label: 'test label 1'
};
export const testSelectOptions = [
  {value: 'test1', label: 'test label 1'},
  {value: 'test2', label: 'test label 2'},
  {value: 'test3', label: 'test label 3'},
];
export const NSelectDefProps = {
  isSearchable: true,
  value: testSelectedOption,
  onChange: ()=>{},
  options: testSelectOptions,
};