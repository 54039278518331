//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {alert} from "../../../services/flowTypes/appFlowTypes";
import AlertCard from "../../simple/Containers/AlertCard/AlertCard";
import alertsActions from "../../../store/alerts/alertsActions";
import type {onDeleteAlert} from "../../../services/flowTypes/propFnFlowTypes";
import type {alertIndex, isInitSelfDelete} from "../../../services/flowTypes/componentFlowTypes";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";

type Props = {
  alert: alert,
  onDeleteAlert: onDeleteAlert,
  alertIndex: alertIndex,
  isInitSelfDelete: isInitSelfDelete
};
type State = {
  alertAddClasses: addClasses
};

class ConnectedAlertCard extends React.Component <Props, State>{
  constructor(props: Props){
    super(props);
    this.state = {
      alertAddClasses: 'AlertCard_translatedY',
    };
  }
  timer:TimeoutID;
  showAlert = () => {
    this.setState({alertAddClasses: ''});
    if (this.props.alert.type !== 'ERROR') {
      if (this.props.alert.type === 'WARNING') {
        this.timer = setTimeout(this.hideAlert, 10000);
      } else {
        this.timer = setTimeout(this.hideAlert, 5000);
      }
    }
  };
  hideAlert = () => {
    clearTimeout(this.timer);
    this.setState({alertAddClasses: 'AlertCard_deleted'});
    this.timer = setTimeout(this.deleteAlert, 500);
  };
  deleteAlert = () => {
    this.props.onDeleteAlert(this.props.alert.id);
  };
  componentDidUpdate(prevProps: Props) {
    if (this.props.isInitSelfDelete && this.props.isInitSelfDelete !== prevProps.isInitSelfDelete) {
      this.hideAlert();
    }
  }
  componentDidMount() {
    this.timer = setTimeout(this.showAlert, 500);
  }
  render(){
    return (
      <AlertCard alert={this.props.alert} addClasses={this.state.alertAddClasses} hideOnClick={this.hideAlert}/>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onDeleteAlert: (alertId) => dispatch({
      type: alertsActions.ALERT_DELETE, value: alertId})
  };
};
export default connect(null, mapDispatchToProps)(ConnectedAlertCard);