import moment from "moment";

const valid_DD_MM_YYYY = (date, format = 'DD.MM.YYYY') => {
  if (!date || date.trim().length === 0) {
    return undefined
  }
  const momentDate = moment(date.trim(), format)
  return momentDate.isValid() ? momentDate : undefined;
};

export default valid_DD_MM_YYYY;