//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {errorObject, isReadyToRender} from "../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../components/service/RenderOnReady/RenderOnReady";
import api from "../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../services/apiInstance/apiErrorHandler";
import type {organizationsSearch} from "../../../../../services/flowTypes/dataFlowTypes";
import {handleResponse, onGetOrganizations, showSpinner} from "../../../../../services/flowTypes/propFnFlowTypes";
import organizationsSearchResultsActions
  from "../../../../../store/organizations/organizationsSearchResults/organizationsSearchResultsActions";
import type {searchIcoIsClicked} from "../../../../../services/flowTypes/componentFlowTypes";
import debounce from 'lodash.debounce';

type Props = {
  organizationsSearch: organizationsSearch,
  onGetOrganizations: onGetOrganizations,
  showSpinner: showSpinner,
  handleResponse: handleResponse,
  searchIcoIsClicked: searchIcoIsClicked
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};
class OrganizationsFetcher extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true
    };
    this.debounceOrganizationsRequest = debounce(this.getOrganizations, 500);
  }
  getOrganizationsSearchData = () => {
    const organizationsSearchData = {...this.props.organizationsSearch};
    organizationsSearchData.status = this.props.organizationsSearch.status === "ALL" ?
      "" : this.props.organizationsSearch.status;
    return organizationsSearchData;
  };
  getOrganizations = () => {
    this.props.showSpinner();
    const organizationSearchData = this.getOrganizationsSearchData();
    // emulateRequest(testOrgList)
    api.searchOrganizations(organizationSearchData)
      .then((response) => {
        this.props.onGetOrganizations(response.data);
        this.props.handleResponse();
        this.setState({isReadyToRender: true});
      })
      .catch((error) => {
        this.setState({
          errorObject: apiErrorHandler(error)
        });
        this.props.handleResponse();
      });
  };
  componentDidUpdate(prevProps){
    if ( (prevProps.organizationsSearch !== this.props.organizationsSearch)
      || (prevProps.searchIcoIsClicked !== this.props.searchIcoIsClicked
        && this.props.searchIcoIsClicked) ) {
      this.debounceOrganizationsRequest();
    }
  }
  componentDidMount(){
    this.getOrganizations();
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    organizationsSearch: state.organizations.search,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetOrganizations: (value) => dispatch({type: organizationsSearchResultsActions.GET_ORGANIZATIONS, value: value})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsFetcher);    