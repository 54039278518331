import text from "../localization/text";

const errorCodeWithParamList = [3001, 3002, 3003, 3004, 3005, 3008, 3009, 3010, 3011, 3012, 3014, 3022, 4001, 5002, 5003, 5007, 5010, 5011];
const warningsList = [3002, 3003, 3004, 3006, 3007, 3008, 3009, 3010, 3011, 4001];

function apiErrorHandler(error){
  if (error.response) {
    let errorObj = {
      title: error.response.status,
      errorId: (error.response.data && error.response.data.error) ?
          error.response.data.error.errorId : null,
      type: (error.response.data && error.response.data.error
        && warningsList.includes(error.response.data.error.errorParams[0].errorCode)) ? 'WARNING' : 'ERROR',
      msg: [],
      localizedMsg: [],
      params: []
    };

    if (error.response.status === 401) {
      errorObj.msg[0] = "Access denied";
      return errorObj;
    }

    if (error.response.status === 403) {
      if (process.env.NODE_ENV === 'production') {
        window.location = '/';
      } else {
        localStorage.removeItem('appData');
        window.location = '/';
      }
      return null;
    }

    if (error.response.status === 400 || error.response.status === 404 || error.response.status === 500) {
      if (error.response.data && error.response.data.error
        && error.response.data.error.errorParams && error.response.data.error.errorParams.length) {
        errorObj.reportId = error.response.data.error.errorParams[0].errorCode === 4001 ?
          error.response.data.error.errorParams[0].params[0] : null;
        for (let i=0; i<error.response.data.error.errorParams.length; i++) {
          const params = error.response.data.error.errorParams[i].params ? error.response.data.error.errorParams[i].params: [];
          errorObj.localizedMsg[i] = errorCodeWithParamList.includes(error.response.data.error.errorParams[i].errorCode) ?
            text.errorCode[error.response.data.error.errorParams[i].errorCode](params.length === 1 ? params[0] : params)
            : text.errorCode[error.response.data.error.errorParams[i].errorCode];
          errorObj.msg[i] = errorObj.localizedMsg ? null : error.response.data.error.errorParams[i].errorMessage;
        }
        return errorObj;
      }
    }

    if (error.response.data && error.response.data.error
      && error.response.data.error.errorParams && error.response.data.error.errorParams.length) {
      for (let i=0; i<error.response.data.error.errorParams.length; i++) {
        errorObj.msg[i] = (error.response.data && error.response.data.error && error.response.data.error.errorParams
          && error.response.data.error.errorParams.length) ?
          error.response.data.error.errorParams[i].errorMessage : null;
        errorObj.params[i] = (error.response.data && error.response.data.error && error.response.data.error.errorParams
          && error.response.data.error.errorParams.length) ?
          error.response.data.error.errorParams[i].params : null;
      }
    }


    return errorObj;

  } else {
    return {
      title: error.toString()
    };
  }

}
export default apiErrorHandler;


