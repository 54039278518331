//@flow
import * as React from 'react';
import type {
  groupFinTermType,
  historyActionDescription,
  historyObjectType
} from "../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../services/localization/text";
import ConnectedHistoryLogPropNameValue from "./ConnectedHistoryLogPropNameValue";
import './HistoryLog.css';
import HistoryArrowIcon from "../../../simple/Icons/HistoryArrowIcon/HistoryArrowIcon";

type Props = {
  historyActionDescription: historyActionDescription,
  objectType: historyObjectType,
  groupFinTermType: groupFinTermType
};

const HistoryLog = (props: Props) => {
  const updatedLogs = props.historyActionDescription.updated ?
    props.historyActionDescription.updated.map((log, i) => {
    return (
      <div key={'updatedLog'+i} className="HistoryLog__changedDataValue HistoryLog__changedDataValue_updated">
        <ConnectedHistoryLogPropNameValue objectType={props.objectType} propNameValue={log.oldValue}
                                          groupFinTermType={props.groupFinTermType}/>
        <HistoryArrowIcon/>
        <ConnectedHistoryLogPropNameValue objectType={props.objectType} propNameValue={log.newValue}
                                          groupFinTermType={props.groupFinTermType}/>
      </div>
    );
  }) : [];
  const createdLogs = props.historyActionDescription.created ?
    props.historyActionDescription.created.map((log, i) => {
    return (
      <div key={'createdLog'+i} className="HistoryLog__changedDataValue">
        <ConnectedHistoryLogPropNameValue objectType={props.objectType} propNameValue={log.newValue}
                                          groupFinTermType={props.groupFinTermType}/>
      </div>
    );
  }) : [];
  const deletedLogs = props.historyActionDescription.deleted ?
    props.historyActionDescription.deleted.map((log, i) => {
    return (
      <div key={'deletedLog'+i} className="HistoryLog__changedDataValue">
        <ConnectedHistoryLogPropNameValue objectType={props.objectType} propNameValue={log.oldValue}
                                          groupFinTermType={props.groupFinTermType}/>
      </div>
    );
  }) : [];
  return (
    <div className="HistoryLog">
      {updatedLogs.length ? (
        <div className="HistoryLog__section">
          <div className="HistoryLog__section-title HistoryLog__section-title_updated">
            {text.historyLogSectionTitle.updated}</div>
          <div className="HistoryLog__section-logs">{updatedLogs}</div>
        </div>
      ) : null}
      {createdLogs.length ? (
        <div className="HistoryLog__section">
          <div className="HistoryLog__section-title HistoryLog__section-title_created">
            {text.historyLogSectionTitle.created}</div>
          <div className="HistoryLog__section-logs">{createdLogs}</div>
        </div>
      ) : null}
      {deletedLogs.length ? (
        <div className="HistoryLog__section">
          <div className="HistoryLog__section-title HistoryLog__section-title_deleted">
            {text.historyLogSectionTitle.deleted}</div>
          <div className="HistoryLog__section-logs">{deletedLogs}</div>
        </div>
      ) : null}
    </div>
  );
};

export default HistoryLog;    