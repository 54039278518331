//@flow
import actTemplatesViewActions from './actTemplatesViewActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {actTemplates} from "../../../../services/flowTypes/dataFlowTypes";

type State = actTemplates;
type reducerAction = action;
const initialState = null;

const actTemplatesViewReducer = (state:State = initialState, action:reducerAction) => {
  switch (action.type) {
    case actTemplatesViewActions.ACT_TEMPLATES_VIEW_INIT:
      return initialState;
    case actTemplatesViewActions.ACT_TEMPLATES_VIEW_SET:
      return action.value;
    default:
      return state;
  }
};

export default actTemplatesViewReducer;