import {testAgentsList, testDocsList,
  testFinTermsIdsList, testTags, testActTemplatesIdsList} from "../../../../tests/test-data";

export const ContractCardDefProps = {
  contract: {
    id: 18,
    typeId: 1,
    organizationId: 1,
    name: "Contract Test Name",
    contractNo: "4535df",
    comment: "Test comment on contract",
    createDate: "2019-03-12",
    status: "ACTIVE",
    startDate: "2019-03-29",
    endDate: "2019-03-29",
    tags: testTags,
    params: []
  },
  contractRelated: {
    contractId: 18,
    agents: testAgentsList,
    documents: testDocsList,
    finTerms: testFinTermsIdsList,
    actTemplates: testActTemplatesIdsList
  },
  handledContract: {
    linkToView: '/link/to/view',
    validityTerm: '20.02.2020 - 20.03.2020',
    contractTypeName: 'Действующий',
    itemStatusLabel: 'StatusLabel',
    tags: [],
    agents: []
  }
};

//TODO: add params