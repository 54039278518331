//@flow
import * as React from 'react';
import type {
  onDeleteFiltersProp,
  onGetFiltersProp,
  resetFilters
} from "../../../services/flowTypes/propFnFlowTypes";
import type {
  filtersList,
  filtersState, isStaticMode
} from "../../../services/flowTypes/componentFlowTypes";
import DynamicTypeFormField from "../DynamicTypeFormField/DynamicTypeFormField";
import text from "../../../services/localization/text";
import './Filters.css';
import DelFilterBtn from "../../simple/Buttons/DelFilterBtn/DelFilterBtn";
import FilterSelect from "../../simple/FormComponents/FilterSelect/FilterSelect";
import SelectFilterPlaceholder from "./SelectFilterPlaceholder";
import SmallTextBtn from "../../simple/Buttons/SmallTextBtn/SmallTextBtn";
import {baseClass} from "../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import {filterIds} from "../../../tests/testIds";

type Props = {
  onGetFiltersProp: onGetFiltersProp,
  onDeleteFiltersProp: onDeleteFiltersProp,
  filtersList: filtersList,
  filtersState: filtersState,
  filtersForOptions: filtersList,
  resetFilters: resetFilters,
  addClasses?: addClasses,
  isStaticMode: isStaticMode,
  isSortAndFilterBarMode?: boolean
};

const dateRangeFilters = ['dateRange', 'createDateRange', 'reportPeriodDateRange'];
const getDateRangeFiltersProp = (propName, startDateValue, endDateValue, checkboxPropName, checkboxValue) => {
  const filterProps = {};
  switch (propName) {
    case 'dateRange':
      filterProps.startDate = startDateValue;
      filterProps.endDate = endDateValue;
      break;
    case 'createDateRange':
      filterProps.startCreateDate = startDateValue;
      filterProps.endCreateDate = endDateValue;
      break;
    case 'reportPeriodDateRange':
      filterProps.startReportPeriodDate = startDateValue;
      filterProps.endReportPeriodDate = endDateValue;
      break;
    default:
      throw new Error('undefined date range filter');
  }
  if (checkboxPropName) {
    filterProps[checkboxPropName] = checkboxValue;
  }
  return filterProps;
};

class Filters extends React.Component <Props> {
  setFilterOptions = () => {
    return this.props.filtersForOptions.map((filter, i) => {
      return {value: filter.propName, label: text.filtersLabels[filter.propName]}
    });
  };
  onSelectFilterChange = (selectedOption) => {
    const filterObj = this.props.filtersForOptions.find(item => item.propName === selectedOption.value);
    let newFilter = {};
    if (dateRangeFilters.includes(selectedOption.value)) {
      newFilter =
    getDateRangeFiltersProp(selectedOption.value, filterObj.initValue, filterObj.initValue, filterObj.checkboxPropName, filterObj.checkboxInitValue);
    } else {
      newFilter = {[selectedOption.value]: filterObj.initValue};
    }
    this.props.onGetFiltersProp(newFilter);
  };
  componentDidMount() {
    if (this.props.isStaticMode) {
      const filterOptions = this.setFilterOptions();
      for (let i=0; i < filterOptions.length; i++) {
        this.onSelectFilterChange(filterOptions[i]);
      }
    }
  }
  render() {
    const filterOptions = this.setFilterOptions();
    const filtersInputs = this.props.filtersList.map((filter, i) => {
      const onChange = (value) => {
        const historyFiltersProps =
          dateRangeFilters.includes(filter.propName) ? getDateRangeFiltersProp(filter.propName, value.startDate, value.endDate)
            : {[filter.propName]: value};
        this.props.onGetFiltersProp(historyFiltersProps);
      };
      const propValue =
        filter.propName === 'dateRange' ?
          {startDate: this.props.filtersState.startDate, endDate: this.props.filtersState.endDate}
          : filter.propName === 'createDateRange' ?
            {startDate: this.props.filtersState.startCreateDate, endDate: this.props.filtersState.endCreateDate}
            : filter.propName === 'reportPeriodDateRange' ?
              {startDate: this.props.filtersState.startReportPeriodDate, endDate: this.props.filtersState.endReportPeriodDate}
              : this.props.filtersState[filter.propName];
      const onClick =
        filter.propName === 'dateRange' ?
          () => this.props.onDeleteFiltersProp(['startDate', 'endDate'])
          : filter.propName === 'createDateRange' ?
            () => this.props.onDeleteFiltersProp(['startCreateDate', 'endCreateDate'])
            : filter.propName === 'reportPeriodDateRange' ?
              () => this.props.onDeleteFiltersProp(['startReportPeriodDate', 'endReportPeriodDate', 'strictPeriod'])
              : () => this.props.onDeleteFiltersProp([filter.propName])  ;
      const filterSelectBtn = this.props.isStaticMode ? null : (<DelFilterBtn onClick={onClick}/>);
      const dateRangeCheckboxLabel = filter.withCheckbox ? text.filterCheckboxLabel[filter.checkboxPropName] : null;
      const dateRangeCheckboxValue = filter.withCheckbox ? this.props.filtersState[filter.checkboxPropName] : null;
      const onDateRangeCheckboxChange = filter.withCheckbox ? (value) => {
        this.props.onGetFiltersProp({[filter.checkboxPropName]: value});
      } : null;
      return (
        <div key={'filter'+i} className={'mr_2du mb_2du'}>
          <DynamicTypeFormField
            propNameLabel={text.filtersLabels[filter.propName]}
            propValue={propValue}
            formFieldType={filter.formFieldType}
            options={filter.options}
            onChange={onChange}
            placeholder={filter.placeholder}
            filterSelectBtn={filterSelectBtn}
            idForTest={filter.propName + "Filter"}
            isStaticMode={this.props.isStaticMode}
            dateRangeCheckboxLabel={dateRangeCheckboxLabel}
            dateRangeCheckboxValue={dateRangeCheckboxValue}
            onDateRangeCheckboxChange={onDateRangeCheckboxChange}
            preferableDateRangePeriodType={filter.preferableDateRangePeriodType}
          />
        </div>
      );
    });
    return (
      <>
        <div className={baseClass('Filters', this.props.addClasses)}>
          {this.props.isStaticMode || this.props.isSortAndFilterBarMode ? null : (
            <FilterSelect value={null} onChange={this.onSelectFilterChange} options={filterOptions}
                          placeholder={(<SelectFilterPlaceholder/>)}
                          id={filterIds.selectFilter}
                          isAddFilterMode={true} addClasses={'mr_2du mb_2du'}
            />
          )}
          {filtersInputs}
          {!this.props.isStaticMode && filtersInputs.length > 1 ? (
            <SmallTextBtn id={filterIds.buttonResetAllFilters} onClick={this.props.resetFilters}
                          addClasses={'SmallTextBtn_reset SmallTextBtn_lh40 mb_2du'}>
              {text.reset}</SmallTextBtn>
          ) : null}
        </div>
      </>
    );
  }
}

export default Filters;    