//@flow
import * as React from 'react';
import {connect} from "react-redux";
import api from "../../../../../services/apiInstance/api";
import type {
  handleError,
  hideSpinner,
  onGetSearchData,
  showSpinner
} from "../../../../../services/flowTypes/propFnFlowTypes";
import type {searchRequest} from "../../../../../services/flowTypes/dataFlowTypes";
import searchDataActions from "../../../../../store/organization/searchData/searchDataActions";
import RenderOnReady from "../../../../service/RenderOnReady/RenderOnReady";
import debounce from 'lodash.debounce';

type Props = {
  showSpinner: showSpinner,
  searchRequest: searchRequest,
  onGetSearchData: onGetSearchData,
  hideSpinner: hideSpinner,
  handleError: handleError
};

class SearchResultsFetcher extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
    this.debounceSearchRequest = debounce(this.getSearchResults, 500);
  }
  getSearchResults = () => {
    this.props.showSpinner();
    api.searchAdvanced(this.props.searchRequest).then(response => {
      this.props.onGetSearchData(response.data);
      this.props.hideSpinner();
    }).catch(error => {
      this.props.handleError(error);
      console.log(error);
    });
  };
  componentDidUpdate(prevProps){
    if (prevProps.searchRequest !== this.props.searchRequest) {
      this.debounceSearchRequest();
    }
  }
  componentDidMount(){
    // this.getSearchResults();
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={true}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchRequest: state.organization.searchRequest,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSearchData: (data) => dispatch({type: searchDataActions.SEARCH_DATA_SET, value: data})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsFetcher);    