//@flow
import * as React from 'react';
import {connect} from "react-redux";
import validationActions from "../../../../../../../store/validation/validationActions";
import validationPatterns from "../../../../../../../services/validationPatterns/validationPatterns";
import {
  onGetValidationStateFormProp,
  onGetValidationStateProp
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import type {
  isScrollToError,
  validationIsAllowed
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import NFormGroup from "../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import type {children} from "../../../../../../../services/flowTypes/appFlowTypes";
import type {propError} from "../../../../../../../services/flowTypes/componentFlowTypes";
import type {documentsList} from "../../../../../../../services/flowTypes/dataFlowTypes";
import contractMainDataValidationScheme from "../../../../../../../configData/contractMainDataValidationScheme";

type Props = {
  itemFiles: documentsList,
  onGetValidationStateProp: onGetValidationStateProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  children: children,
  validationIsAllowed: validationIsAllowed,
  isScrollToError: isScrollToError,
  propError: propError
};

class ContractMainDataFilesLengthValidator extends React.Component <Props, State> {
  validate = () => {
    const error = validationPatterns[contractMainDataValidationScheme.contractMainDataFilesLength](this.props.itemFiles.length);
    this.props.onGetValidationStateProp('isScrollToError', false);
    this.props.onGetValidationStateFormProp('contractMainDataFilesLength', error);
  };
  componentDidUpdate(prevProps){
    if (prevProps.itemFiles !== this.props.itemFiles) {
      this.validate();
    }
  }
  componentDidMount(){
    this.validate();
  }
  render() {
    const propError = this.props.validationIsAllowed ? this.props.propError : '';
    return (
      <NFormGroup isScrollToError={this.props.isScrollToError} propError={propError}>
        {this.props.children}
      </NFormGroup>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    itemFiles: state.organization.item.itemFiles,
    propError: state.validation.form.contractMainDataFilesLength,
    validationIsAllowed: state.validation.validationIsAllowed,
    isScrollToError: state.validation.isScrollToError
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetValidationStateProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContractMainDataFilesLengthValidator);