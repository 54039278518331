//@flow

import React from "react";
import type {children} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import './SideRightContainer.css';

type Props = {
  children: children,
  addClasses?: addClasses,
}

const SideRightContainer = (props: Props) => {
  return (
    <div className={baseClass("SideRightContainer", props.addClasses)}>
      {props.children}
    </div>
  );
};

export default SideRightContainer;