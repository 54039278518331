const testTagSelectOption = ['tag1', 'tag2'];
const testTagSelectOptions = [
  {value: 'tag1', label: 'tag1'},
  {value: 'tag2', label: 'tag2'},
  {value: 'tag3', label: 'tag3'}
];

export const FormTagSelectDefProps = {
  value: testTagSelectOption,
  onChange: ()=>{},
  options: testTagSelectOptions,
};