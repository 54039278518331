//@flow
import * as React from 'react';
import Document from "./Document/Document";
import type {
  agentId,
  contractId,
  document, documentFolderList,
  fileDescription,
  folderId, folderName, isProtected
} from "../../../../services/flowTypes/dataFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import type {
  documentIndex, isAddAgreementMode, isDocumentEdit,
  itemType,
  permittedActionObj, selectedOption, selectOptions
} from "../../../../services/flowTypes/componentFlowTypes";
import text from "../../../../services/localization/text";
import NFormGroup from "../../../simple/FormComponents/NFormGroup/NFormGroup";
import NInput from "../../../simple/FormComponents/NInput/NInput";
import api from "../../../../services/apiInstance/api";
import {
  mountDocumentsListFetcher,
  setErrorObject,
  unmountDocumentsListFetcher
} from "../../../../services/flowTypes/propFnFlowTypes";
import DeleteDocument from "./DeleteDocument/DeleteDocument";
import Can from "../../../../services/permissions/Can";
import {documentsPageIds} from "../../../../tests/testIds";
import NModal from "../../../simple/Modals/NModal/NModal";
import NSelect from "../../../simple/FormComponents/NSelect/NSelect";
import documentFoldersActions from "../../../../store/organization/item/documentFolders/documentFoldersActions";
import {connect} from "react-redux";
import BtnEditAct from "../../../simple/Buttons/BtnEditAct/BtnEditAct";
import type {onUpdateDocumentFoldersList} from "../../../../services/flowTypes/propFnFlowTypes";
import RestoreDocument from "./RestoreDocument/RestoreDocument";

type Props = {
  document: document,
  addClasses?: addClasses,
  itemType: itemType,
  itemId: contractId | agentId,
  mountDocumentsListFetcher: mountDocumentsListFetcher,
  unmountDocumentsListFetcher: unmountDocumentsListFetcher,
  setErrorObject: setErrorObject,
  permittedActionObj: permittedActionObj,
  documentIndex: documentIndex,
  folderId: folderId,
  folderList: documentFolderList,
  isAddAgreementMode?: isAddAgreementMode,
  pathname: string,
  onUpdateDocumentFoldersList: onUpdateDocumentFoldersList
};
type State = {
  isDocumentEdit: isDocumentEdit,
  fileDescription: fileDescription,
  promiseUpdateDescription: Object,
  selectOptions: selectOptions,
  selectedOption: selectedOption,
  isFolderProtected: isProtected,
  folderName: folderName
};

class DocumentEdit extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isDocumentEdit: false,
      fileDescription: this.props.document.fileDescription,
      promiseUpdateDescription: {
        contract: api.updateContractDocument,
        agent: api.updateAgentDocument,
        organizations: api.updateOrganizationDocument,
      },
      selectOptions: [],
      selectedOption: {},
      isFolderProtected: false
    };
  }
  onEditBtnClick = () => {
    this.setState({
      isDocumentEdit: true
    })
  };
  onCancelBtnClick = () => {
    this.setState({
      isDocumentEdit: false,
      fileDescription: this.props.document.fileDescription
    })
  };
  onSaveBtnClick = () => {
    this.props.unmountDocumentsListFetcher();
    const promise = this.state.promiseUpdateDescription[this.props.itemType];
    const fileParams = {
      fileId: this.props.document.fileId,
      fileDescription: this.state.fileDescription,
      fileFolderId: this.state.selectedOption.value === 0 ? null : this.state.selectedOption.value
    };
    promise(this.props.itemId, fileParams)
      .then(response => {
        this.props.mountDocumentsListFetcher();
        if(!this.props.folderId){
          this.props.onUpdateDocumentFoldersList(response.data);
          this.setState({isDocumentEdit: false});
        }
      })
      .catch(error => {this.props.setErrorObject(error)});
  };
  hideModal = () => {
    this.setState({
      modalIsShown: false
    });
  };
  handleDescriptionChange = (e) => this.setState({fileDescription: e.target.value});
  handleFolderChange = (folder) => {
    this.setState({
      selectedOption: {
        value: folder.value,
        label: folder.label
      }
    });
  };
  defineRestoreBtn = () => {
    return (
      <RestoreDocument unmountDocumentsListFetcher={this.props.unmountDocumentsListFetcher}
                       mountDocumentsListFetcher={this.props.mountDocumentsListFetcher}
                       itemType={this.props.itemType} itemId={this.props.itemId}
                       fileId={this.props.document.fileId} setErrorObject={this.props.setErrorObject}
                       permittedAction={this.props.permittedActionObj.delete}
                       onUpdateDocumentFoldersList={this.props.onUpdateDocumentFoldersList}/>
    );
  };
  setSelectOptions = () => {
    let selectOptions = this.props.folderList.map(item => {
      return {
        value: item.id,
        label: item.name
      }
    });
    const firstOption = {label: 'Без папки', value: 0};
    selectOptions.unshift(firstOption);
    let selectedOption = this.props.document ? selectOptions.find(item => item.value === this.props.document.folderId) : selectOptions[0];
    if(!selectedOption){
      selectedOption = selectOptions[0];
    }
    const folderObj = this.props.folderList.find(item => item.id === this.props.document.folderId);
    const isFolderProtected = folderObj && folderObj.isProtected;
    const folderName = folderObj ? folderObj.name : undefined;
    this.setState({selectOptions, selectedOption, isFolderProtected, folderName});
  };
  componentDidMount() {
    this.setSelectOptions();
  }

  componentDidUpdate(prevProps: Props) {
    if(prevProps.folderList.length !== this.props.folderList.length){
      this.setSelectOptions();
    }
  }

  render() {
    const DeleteBtn = this.props.isAddAgreementMode || this.state.isFolderProtected ?
      (this.state.folderName === 'Trash' ? this.defineRestoreBtn() : null)
      :
      <DeleteDocument onCancelBtnClick={this.onCancelBtnClick} itemType={this.props.itemType}
                                      itemId={this.props.itemId} fileId={this.props.document.fileId}
                                      fileName={this.props.document.name}
                                      permittedAction={this.props.permittedActionObj.delete}
                                      idForTest={documentsPageIds.buttonDeleteDocument(this.props.documentIndex)}
                                      mountDocumentsListFetcher={this.props.mountDocumentsListFetcher}
                                      unmountDocumentsListFetcher={this.props.unmountDocumentsListFetcher}
                                      onUpdateDocumentFoldersList={this.props.onUpdateDocumentFoldersList}
                                      setErrorObject={this.props.setErrorObject}/>;
    const EditBtn = this.state.isFolderProtected ? null : (
      <Can do={'usePermission'} on={this.props.permittedActionObj.update}>
        <BtnEditAct id={documentsPageIds.buttonEditDocument(this.props.documentIndex)}
                    onClick={this.onEditBtnClick}>{text.editBtnLabel}</BtnEditAct>
      </Can>
      );
    const editModal = (
        <NModal
            addClasses='NModal_ov'
            isModalDocumentEdit={true}
            modalTitle={text.document.edit}
            modalCancelBtnText={text.cancel}
            modalActionBtnText={text.save}
            onModalCancelBtnClick={this.onCancelBtnClick}
            onModalActionBtnClick={this.onSaveBtnClick}
        >
          <NFormGroup label={text.document.name} addClasses={'mt_4du mb_3du'}>
            <NInput value={this.props.document.name} readOnly id={documentsPageIds.modalInputDocumentName(this.props.documentIndex)}/>
          </NFormGroup>
          <NFormGroup label={text.description} addClasses={'mb_3du'}>
            <NInput value={this.state.fileDescription}
                    onChange={(e) => this.handleDescriptionChange(e)}
                    id={documentsPageIds.modalInputDocumentDescription(this.props.documentIndex)}
            />
          </NFormGroup>
          {this.props.isAddAgreementMode ? null : (
            <NFormGroup label={text.folderSelectInModalLabel} addClasses={'mb_3du'}>
              <NSelect value={this.state.selectedOption}
                       isSearchable={true}
                       onChange={(folder) => this.handleFolderChange(folder)}
                       isSelectMenuWide={false}
                       options={this.state.selectOptions}
                       id={documentsPageIds.modalSelectDocumentFolder(this.props.documentIndex)}
              />
            </NFormGroup>
          )}
        </NModal>
    );
    const documentBtns = <div className={'flex jc_fe'}>{EditBtn}{DeleteBtn}</div>;
    return (
      <Document document={this.props.document} itemType={this.props.itemType} itemId={this.props.itemId}
                addClasses={this.props.addClasses}
                isDocumentEdit={this.state.isDocumentEdit}
                editModal={this.state.isDocumentEdit ? editModal : null}
                documentBtns={documentBtns}
                pathname={this.props.pathname}
                linkIdToDownload={documentsPageIds.buttonDownloadDocument(this.props.documentIndex)}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    folderList: state.organization.item.documentFolders
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitDocumentFoldersState: () => dispatch({type: documentFoldersActions.ITEM_FOLDERS_STATE_INIT}),
    onUpdateDocumentFoldersList: (value) => dispatch({type: documentFoldersActions.ITEM_FOLDERS_LIST_SET, value: value})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentEdit);