//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  formFieldType,
  paramsBlockFieldIndex,
  paramsBlockIndex, propName, propValidationPatternsArr
} from "../../../../../../services/flowTypes/componentFlowTypes";
import {
  onDeleteField,
  onUpdateParamValue
} from "../../../../../../services/flowTypes/propFnFlowTypes";
import itemDataParamsEditActions
  from "../../../../../../store/organization/item/itemDataParamsEdit/itemDataParamsEditActions";
import ConnectedParamFormGroup from "../../../../ConnnectedParamFormGroup/ConnectedParamFormGroup";
import type {propValue, valueType} from "../../../../../../services/flowTypes/componentFlowTypes";
import type {id, label} from "../../../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../../../services/flowTypes/uiKitFlowTypes";
import {contractEditPageIds} from "../../../../../../tests/testIds";

type Props = {
  paramsBlockIndex: paramsBlockIndex,
  paramsBlockFieldIndex: paramsBlockFieldIndex,
  onUpdateParamValue: onUpdateParamValue,
  onDeleteField: onDeleteField,
  valueType: valueType,
  propValue: propValue,
  formFieldType: formFieldType,
  propName: propName,
  propValidationPatternsArr: propValidationPatternsArr,
  label: label,
  addClasses: addClasses,
  idForTest: id,
  dataUiTestId?: string
};
class ParamsBlockFieldValue extends React.Component <Props> {
  onGetPropValue = (propName, propValue) => {
    const data = {
      paramsBlockIndex: this.props.paramsBlockIndex,
      paramsBlockFieldIndex: this.props.paramsBlockFieldIndex,
      valueType: this.props.valueType,
      propValue
    };
    this.props.onUpdateParamValue(data);
  };
  render() {
    return (
      <ConnectedParamFormGroup
        formFieldType={this.props.formFieldType}
        propName={this.props.propName}
        propValue={this.props.propValue}
        onGetPropValue={this.onGetPropValue}
        propValidationPatternsArr={this.props.propValidationPatternsArr}
        addClasses={this.props.addClasses}
        label={this.props.label}
        onDelParamClick={this.props.onDeleteField}
        idForTest={this.props.idForTest}
        btnDelId={contractEditPageIds.buttonDeleteBlockParamValue(this.props.paramsBlockIndex, this.props.paramsBlockFieldIndex)}
        dataUiTestId={this.props.dataUiTestId}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propValue: state.organization.item.itemDataParamsEdit[ownProps.paramsBlockIndex].fields[ownProps.paramsBlockFieldIndex][ownProps.valueType]
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onUpdateParamValue: (data) => dispatch({type: itemDataParamsEditActions.PARAM_VALUE_UPDATE, value: data})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ParamsBlockFieldValue);