//@flow
import * as React from 'react';
import {connect} from "react-redux";
import validationActions from "../../../store/validation/validationActions";
import ItemControls from "../ItemControls/ItemControls";
import type {actionIsPermitted, disabled, history, locationPathname} from "../../../services/flowTypes/appFlowTypes";
import type {
  cancelFn,
  finishButtonLabel,
  isNavigationButtonsMode, isTabStepsMode,
  steps
} from "../../../services/flowTypes/componentFlowTypes";
import {onGetValidationStateProp, sendRequest} from "../../../services/flowTypes/propFnFlowTypes";
import type {validationForm, validationIsAllowed} from "../../../services/flowTypes/dataFlowTypes";
import StepsNavigationButtons from "../StepsNavigationButtons/StepsNavigationButtons";
import TabSteps from "../../simple/Containers/TabSteps/TabSteps";

type Props = {
  validationIsAllowed: validationIsAllowed,
  actionIsPermitted: actionIsPermitted,
  submitIsDisabled: disabled,
  cancelFn: cancelFn,
  onGetValidationStateProp: onGetValidationStateProp,
  sendRequest: sendRequest,
  validationForm: validationForm,
  isNavigationButtonsMode?: isNavigationButtonsMode,
  history?: history,
  steps?: steps,
  locationPathname?: locationPathname,
  finishButtonLabel?: finishButtonLabel,
  isTabStepsMode?: isTabStepsMode
};
//TODO: consider to combine  onGetValidationStateProp('validationIsAllowed', true) and
// onGetValidationStateProp('isScrollToError', true) into one fn call
class ConnectedItemControls extends React.Component <Props> {
  validateBeforeSubmit = () => {
    let totalErrMsgLength = 0;
    for (let propName in this.props.validationForm) {
      totalErrMsgLength = totalErrMsgLength +
        this.props.validationForm[propName].length;
    }
    if (totalErrMsgLength) {
      return true;
    }
  };
  validateAndSend = () => {
    if (!this.validateBeforeSubmit()) {
      this.props.sendRequest();
    }
  };
  allowValidation = () => {
    if (!this.props.validationIsAllowed) {
      this.props.onGetValidationStateProp('validationIsAllowed', true);
    }
  };
  save = () => {
    this.allowValidation();
    this.props.onGetValidationStateProp('isScrollToError', true);
    this.validateAndSend();
  };
  handleButtonForwardClick = () => {
    this.allowValidation();
    this.props.onGetValidationStateProp('isScrollToError', true);
  };
  render() {
    const save = this.props.actionIsPermitted ? this.save : null;
    if (this.props.isNavigationButtonsMode) {
      return (
        <StepsNavigationButtons cancelFn={this.props.cancelFn}
                                history={this.props.history}
                                steps={this.props.steps}
                                locationPathname={this.props.locationPathname}
                                validateBeforeSubmit={this.validateBeforeSubmit}
                                handleButtonForwardClick={this.handleButtonForwardClick}
                                handleButtonFinishClick={save}
                                finishButtonLabel={this.props.finishButtonLabel}
        />
      );
    } else if (this.props.isTabStepsMode) {
      return (
        <TabSteps steps={this.props.steps} locationPathname={this.props.locationPathname} history={this.props.history}
                  validateBeforeSubmit={this.validateBeforeSubmit} handleButtonForwardClick={this.handleButtonForwardClick}
                  addClasses={'mb_3du'}/>
      );
    } else {
      return (
        <ItemControls submitIsDisabled={this.props.submitIsDisabled} saveFn={save} cancelFn={this.props.cancelFn}/>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    validationIsAllowed: state.validation.validationIsAllowed,
    validationForm: state.validation.form
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedItemControls);    