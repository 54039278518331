//@flow
import * as React from 'react';
import type {addAgreement, contractId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import TextOverflowHandler
  from "../../../../../../../components/simple/Containers/TextOverflowHandler/TextOverflowHandler";
import SecText from "../../../../../../../components/simple/TextComponents/SecText/SecText";
import text from "../../../../../../../services/localization/text";
import Heading4 from "../../../../../../../components/simple/TextComponents/Heading4/Heading4";
import formatDate from "../../../../../../../utils/formatDate";
import Text1 from "../../../../../../../components/simple/TextComponents/Text1/Text1";
import AddAgreementDocument from "./AddAgreementDocument";
import ButtonEditAddAgreement from "./ButtonEditAddAgreement";
import ButtonDeleteAddAgreement from "./ButtonDeleteAddAgreement";
import StatusLabel from "../../../../../../../components/simple/TextComponents/StatusLabel/StatusLabel";
import type {
  mountDocumentsListFetcher,
  unmountDocumentsListFetcher
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import './AddAgreementCard.css';

type Props = {
  addAgreement: addAgreement,
  contractId: contractId,
  addAgreementIndex: number,
  mountDocumentsListFetcher: mountDocumentsListFetcher,
  unmountDocumentsListFetcher: unmountDocumentsListFetcher
};

const AddAgreementCard = (props: Props) => {
  const startDate = formatDate(props.addAgreement.startDate);
  const endDate = formatDate(props.addAgreement.endDate);
  const validityTerm = startDate + " – " + (endDate ? endDate : text.endDateNotSet);
  const itemStatusLabel= text.addAgreement.status[props.addAgreement.addStatus];
  const isNotVerifiedClassName = props.addAgreement.isCheckedAndVerified ? "" : "NCard_error";
  return (
    <div className={'NCard mb_1du AddAgreementCard ListItemCard ListItemCard_wide ' + isNotVerifiedClassName}>
      <div className="AddAgreementCard__left">
        <StatusLabel addClasses={`StatusLabel_${props.addAgreement.addStatus}`}>
          {itemStatusLabel}</StatusLabel>
        <Heading4 addClasses={'Heading4_card-label-color'}>{props.addAgreement.number}</Heading4>
        <SecText addClasses={'mb_1du'}>{validityTerm}</SecText>

        <div className="flex mb_half-du">
          {props.addAgreement.hasOwnProperty('isCheckedAndVerified') ?
            (props.addAgreement.isCheckedAndVerified ? (
              <div className={"AddAgreementCard__isVerifiedIco mr_1du"}  title={props.addAgreement.isNotVerifiedWithFinTermsComment}/>
            ) : (
              <div className={"AddAgreementCard__isNotVerifiedIco mr_1du"}  title={props.addAgreement.isNotVerifiedWithFinTermsComment}/>
            ))
          : null}
          <Heading4 addClasses={'Heading4_fwb Heading4_lh16'}>
            <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>
              {props.addAgreement.name}</TextOverflowHandler>
          </Heading4>
        </div>
        <Text1 addClasses={'Text1_card-label-color Text1_italic'}>
          {props.addAgreement.description && props.addAgreement.description.length ? props.addAgreement.description : "-"}
        </Text1>
      </div>
      <div className="AddAgreementCard__right">
        <div className="AddAgreementCard__doc-container">
          <AddAgreementDocument contractId={props.contractId} cardIndex={props.addAgreementIndex}
                                mountDocumentsListFetcher={props.mountDocumentsListFetcher}
                                unmountDocumentsListFetcher={props.unmountDocumentsListFetcher}
                                fileId={props.addAgreement.fileId}/>
        </div>
        <div className="ListItemCard__buttons">
          <ButtonEditAddAgreement contractId={props.contractId} addAgreementIndex={props.addAgreementIndex}
                                  mountDocumentsListFetcher={props.mountDocumentsListFetcher}
                                  unmountDocumentsListFetcher={props.unmountDocumentsListFetcher}
                                  addAgreement={props.addAgreement}/>
          <ButtonDeleteAddAgreement contractId={props.contractId} addAgreementIndex={props.addAgreementIndex}
                                    mountDocumentsListFetcher={props.mountDocumentsListFetcher}
                                    unmountDocumentsListFetcher={props.unmountDocumentsListFetcher}
                                    addAgreement={props.addAgreement}/>
        </div>
      </div>
    </div>
  );
};

export default AddAgreementCard;    