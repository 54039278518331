//@flow
import * as React from 'react';
import type {
  addServiceId,
  addServicesList, contractId,
  itemDate,
  organizationId, usersList
} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import AddServicesCard from "../../../AddServices/addServices/AddServicesCard";
import {connect} from "react-redux";
import Checkbox from "../../../../../../../../components/simple/FormComponents/Checkbox/Checkbox";
import moment from "../../../../../../../../services/moment/momentInstance";
import text from "../../../../../../../../services/localization/text";
import Heading4 from "../../../../../../../../components/simple/TextComponents/Heading4/Heading4";
import EmptyBox from "../../../../../../../../components/simple/Containers/EmptyBox/EmptyBox";
import "./AddServicesCheckList.css";

type Props = {
  startDate: itemDate,
  endDate: itemDate,
  reportAddServiceIds: Array<addServiceId>,
  addServiceIds: Array<addServiceId>,
  addServices: addServicesList,
  organizationId: organizationId,
  contractId: contractId,
  usersList: usersList,
  setReportAddServiceIds: Function
};
type State = {
  actTemplateAddServices: addServicesList
};

class AddServicesCheckList extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      actTemplateAddServices: []
    };
  }
  handleCheckboxClick = (addServiceId) => {
    const reportAddServiceIds = [...this.props.reportAddServiceIds];
    const index = reportAddServiceIds.indexOf(addServiceId);
    if (index === -1) {
      reportAddServiceIds.push(addServiceId);
    } else {
      reportAddServiceIds.splice(index, 1);
    }
    this.props.setReportAddServiceIds(reportAddServiceIds);
  };
  isInDateRange = (addServiceDate) => {
    const startDate = this.props.startDate ? this.props.startDate : undefined;
    const endDate = this.props.endDate ? this.props.endDate : undefined;
    return moment(addServiceDate).isBetween(startDate, endDate,  undefined, '[]');
  };
  handleReportPeriodUpdate = () => {
    const reportAddServiceIds = [];
    for (let i=0; i < this.state.actTemplateAddServices.length; i++) {
      if (this.state.actTemplateAddServices[i].status !== 'UPLOADED' &&
        this.isInDateRange(this.state.actTemplateAddServices[i].date)  ) {
        reportAddServiceIds.push(this.state.actTemplateAddServices[i].id);
      }
    }
    this.props.setReportAddServiceIds(reportAddServiceIds);
  };
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
      this.handleReportPeriodUpdate();
    }
  }
  componentDidMount() {
    const actTemplateAddServices = [];
    for (let i=0; i < this.props.addServiceIds.length; i++) {
      const addService = this.props.addServices.find(item => item.id === this.props.addServiceIds[i]);
      if (addService) {
        actTemplateAddServices.push(addService);
      }
    }
    this.setState({actTemplateAddServices}, this.handleReportPeriodUpdate);
  }

  render() {
    const filteredAddServices = this.state.actTemplateAddServices.filter(item => this.isInDateRange(item.date));
    let uploadedAddServicesAmount = 0;
    const addServices = filteredAddServices.map((addService, i) => {
      const onClick = addService.status === 'UPLOADED' ? null : () => this.handleCheckboxClick(addService.id);
      if (addService.status === 'UPLOADED') {
        uploadedAddServicesAmount++;
      }
      const checked = this.props.reportAddServiceIds.includes(addService.id);
      const addClasses = checked ? "AddServicesCard_checked mb_1du" : "mb_1du";
      const checkboxComponent = <Checkbox checked={checked} addClasses={"mr_2du"} onClick={onClick}/>
       return (
        <AddServicesCard key={'addService'+i} addService={addService} contractId={this.props.contractId}
                         organizationId={this.props.organizationId} addServiceIndex={i} usersList={this.props.usersList}
                         isActTemplateCardMode={true} addClasses={addClasses} checkboxComponent={checkboxComponent}
        />
      );
    });
    const addServicesAmount = addServices.length ? " - " + addServices.length : "";
    return (
      <>
        {uploadedAddServicesAmount ? (
          <div className={`AlertCard__header AlertCard__header_REPORT-WARNING mb_2du`}>
            <div className="AlertCard__header-ico"/>
            <div className="AlertCard__header-title">{text.uploadedAddServicesAmountWarning(uploadedAddServicesAmount)}
            </div>
          </div>
        ) : null}
        <Heading4 addClasses={'mb_1du mt_2du'}>{text.actTemplateCardHeadings.addServices + addServicesAmount}</Heading4>
        {addServices.length ? (<div className="AddServicesCheckList mb_1du">{addServices}</div>)  : (
          <EmptyBox>{text.noAddServicesForGivenPeriod}</EmptyBox>
        )}
      </>
    ) ;
  }
}

const mapStateToProps = state => {
  return {
    addServices: state.organization.item.addServices,
    usersList: state.organization.organizationData.usersList
  };
};

export default connect(mapStateToProps, null)(AddServicesCheckList);