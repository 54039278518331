//@flow
import * as React from 'react';
import {Link} from "react-router-dom";
import {actTemplatesViewPageIds} from "../../../../../../../tests/testIds";
import text from "../../../../../../../services/localization/text";
import Can from "../../../../../../../services/permissions/Can";
import {contrPath, newActTemplateSuffix, orgPath} from "../../../../../../../services/urlStrings/urlStrings";
import type {contractId, organizationId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";
import BtnNewItem from "../../../../../../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  organizationId: organizationId,
  contractId: contractId
};

const NewActTemplateButton = (props: Props) => {
  const linkToNew = orgPath(props.organizationId) + contrPath(props.contractId) + newActTemplateSuffix;
  return (
    <Can do={'usePermission'} on={permissionsStrValues.actTemplate.create}>
      <Link className={"button-wrapper-link"} id={actTemplatesViewPageIds.buttonNewActTemplate} to={linkToNew}>
        <BtnNewItem>{text.newActTemplate}</BtnNewItem>
      </Link>
    </Can>
  );
};

export default NewActTemplateButton;    