//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {contractId, documentsList, fileId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import DocumentEdit from "../../../../../../../components/composed/ItemDocuments/DocumentEdit/DocumentEdit";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {errorObject} from "../../../../../../../services/flowTypes/appFlowTypes";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import text from "../../../../../../../services/localization/text";
import Text1 from "../../../../../../../components/simple/TextComponents/Text1/Text1";
import type {
  mountDocumentsListFetcher,
  unmountDocumentsListFetcher
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import type {isEditModalMode} from "../../../../../../../services/flowTypes/componentFlowTypes";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";

type Props = {
  fileId: fileId,
  documentsList: documentsList,
  contractId: contractId,
  cardIndex: number,
  mountDocumentsListFetcher: mountDocumentsListFetcher,
  unmountDocumentsListFetcher: unmountDocumentsListFetcher,
  isEditModalMode?: isEditModalMode
};
type State = {
  errorObject: errorObject
};

class AddAgreementDocument extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null
    };
  }
  setErrorObject = (error) => {
    this.setState({
      errorObject: apiErrorHandler(error)
    });
  };
  render() {
    const document = this.props.documentsList.find(item => item.fileId === this.props.fileId);
    const permittedActionObj = this.props.isEditModalMode ?
      {create: 'readOnly', read: 'readOnly', update: 'readOnly', delete: 'readOnly' } : permissionsStrValues.contractDocument;
    const addClasses = this.props.isEditModalMode ? "" : "";
    return (
      <RenderOnReady isReadyToRender={true} errorObject={this.state.errorObject}>
        {document ? (
          <DocumentEdit document={document} addClasses={addClasses}
                        documentIndex={this.props.cardIndex}
                        isAddAgreementMode={true}
            // folderId={this.props.folderId}
            // folderList={this.props.folderList}
                        itemType={'contract'} itemId={this.props.contractId}
                        mountDocumentsListFetcher={this.props.mountDocumentsListFetcher}
                        unmountDocumentsListFetcher={this.props.unmountDocumentsListFetcher}
                        setErrorObject={this.setErrorObject} permittedActionObj={permittedActionObj}/>
        ) : (
          <Text1 addClasses={'Text1_error'}>{text.fileWithIdNotFound(this.props.fileId)}</Text1>
        )}
      </RenderOnReady>);

  }
}

const mapStateToProps = state => {
  return {
    documentsList: state.organization.item.itemDocuments
  };
};
export default connect(mapStateToProps, null)(AddAgreementDocument);