//@flow
import * as React from 'react';
import reportMenuItems from "../../../../../../configData/reportMenuItems";
import connect from "react-redux/es/connect/connect";
import type {adjustmentId, reportId, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import SideLeftContainer from "../../../../../../components/simple/Containers/SideLeftContainer/SideLeftContainer";
import ColumnMenu from "../../../../../../components/simple/Containers/RowMenu/ColumnMenu";

type Props = {
  reportId: reportId,
  organizationId: organizationId,
  adjustmentId: adjustmentId
};

class ReportMenu extends React.Component <Props> {
  render(){
    const menuItemsData = reportMenuItems(this.props.organizationId, this.props.reportId, !!this.props.adjustmentId);
    return (
      <SideLeftContainer>
        <ColumnMenu menuItemsData={menuItemsData}/>
      </SideLeftContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    adjustmentId: state.organization.item.reportMainData.adjustmentId
  };
};

export default connect(mapStateToProps, null)(ReportMenu);