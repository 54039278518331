//@flow
import * as React from 'react';
import {connect} from "react-redux";
import validationActions from "../../../../../../../../../store/validation/validationActions";
import validationPatterns from "../../../../../../../../../services/validationPatterns/validationPatterns";
import groupFinTermsValidationScheme from "../../../../../../../../../configData/groupFinTermsValidationScheme";
import {
  onGetValidationStateFormProp,
  onGetValidationStateProp
} from "../../../../../../../../../services/flowTypes/propFnFlowTypes";
import type {
  isScrollToError,
  sourceParams,
  validationIsAllowed
} from "../../../../../../../../../services/flowTypes/dataFlowTypes";
import NFormGroup from "../../../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import type {children} from "../../../../../../../../../services/flowTypes/appFlowTypes";
import type {propError} from "../../../../../../../../../services/flowTypes/componentFlowTypes";

type Props = {
  sourceParams: sourceParams,
  onGetValidationStateProp: onGetValidationStateProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  children: children,
  validationIsAllowed: validationIsAllowed,
  isScrollToError: isScrollToError,
  propError: propError
};

class SourceParamsValidator extends React.Component <Props, State> {
  validate = (sourceParamsValuesArr) => {
    const error = validationPatterns[groupFinTermsValidationScheme.sourceParams](sourceParamsValuesArr);
    this.props.onGetValidationStateProp('isScrollToError', false);
    this.props.onGetValidationStateFormProp('sourceParams', error);
  };
  setSourceParamsValuesArr = () => {
    return this.props.sourceParams.map(item => {
      return item.value;
    });
  };
  componentDidUpdate(prevProps){
    if (prevProps.sourceParams !== this.props.sourceParams) {
      this.validate(this.setSourceParamsValuesArr());
    }
  }
  componentDidMount(){
    this.validate(this.setSourceParamsValuesArr());
  }
  render() {
    const propError = this.props.validationIsAllowed ? this.props.propError : '';
    return (
      <NFormGroup isScrollToError={this.props.isScrollToError} propError={propError}>
        <div className="SourceParamsValidator">{this.setSourceParamsValuesArr()}</div>
        {this.props.children}
      </NFormGroup>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    sourceParams: state.organization.item.sourceParamsEdit,
    propError: state.validation.form.sourceParams,
    validationIsAllowed: state.validation.validationIsAllowed,
    isScrollToError: state.validation.isScrollToError
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetValidationStateProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SourceParamsValidator);