//@flow
import usersSearchResultsActions from './usersSearchResultsActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {usersSearchResults} from "../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = {
  total: 0,
  users: null
};

const usersSearchResultsReducer = (state:usersSearchResults = initialState, action:reducerAction) => {
  switch (action.type) {
    case usersSearchResultsActions.SET_USERS_INIT_STATE:
      return initialState;
    case usersSearchResultsActions.GET_USERS:
      return action.value;
    default:
      return state;
  }
};

export default usersSearchResultsReducer;