//@flow
import itemDocumentsActions from './itemDocumentsActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {documentsList} from "../../../../services/flowTypes/dataFlowTypes";

type State = documentsList;
type reducerAction = action;
const initialState = [];

const itemDocumentsListReducer = (state:State = initialState, action:reducerAction) => {
  switch (action.type) {
    case itemDocumentsActions.ITEM_DOCUMENTS_STATE_INIT:
      return initialState;
    case itemDocumentsActions.ITEM_DOCUMENTS_LIST_SET:
      return action.value;
    default:
      return state;
  }
};

export default itemDocumentsListReducer;