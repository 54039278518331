//@flow
import * as React from 'react';
import {contractViewPageIds} from "../../../tests/testIds";
import text from "../../../services/localization/text";
import {Link} from "react-router-dom";
import {
  editNotificationsSuffix,
  orgPath,
  orgSettingsSuffix,
  supSettPath
} from "../../../services/urlStrings/urlStrings";
import type {match} from "../../../services/flowTypes/appFlowTypes";
import BtnNewItem from "../../simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  match: match
};

const NotificationSettingsEditButton = (props: Props) => {
  const organizationId = props.match.params.organizationId;
  const isSupLevel = !organizationId;
  const linkToEdit = isSupLevel ? supSettPath + editNotificationsSuffix
    : orgPath(organizationId) + orgSettingsSuffix + editNotificationsSuffix;
  return (
    <Link to={linkToEdit} key={'btnEditNotifications'}>
      <BtnNewItem addClasses={"BtnNewItem_grey"} id={contractViewPageIds.buttonEdit}>{text.btnEdit}</BtnNewItem>
    </Link>
  );
};

export default NotificationSettingsEditButton;    