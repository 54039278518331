//@flow
import * as React from 'react';
import {
  editNotificationsSuffix,
  supSettPath, viewNotificationsSuffix
} from "../../../services/urlStrings/urlStrings";
import PrivateRoute from "./PrivateRoute";
import NotificationSettingsEditButton from "../../composed/NotificationSettings/NotificationSettingsEditButton";
import NotificationSettingsControls from "../../composed/NotificationSettings/NotificationSettingsControls";

type Props = {};

const GenSettingsButtonsRoutes = (props: Props) => {
  return (
    <>
      <PrivateRoute exact path={supSettPath + viewNotificationsSuffix} component={NotificationSettingsEditButton}
                    canUseRoute={true} />
      <PrivateRoute exact path={supSettPath + editNotificationsSuffix} component={NotificationSettingsControls} canUseRoute={true} />
    </>
  );
};

export default GenSettingsButtonsRoutes;