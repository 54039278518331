//@flow
import * as React from 'react';
import './QuestionTooltip.css';
import type {children} from "../../../../services/flowTypes/appFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import type {isHighZIndex, linkTo} from "../../../../services/flowTypes/componentFlowTypes";
import text from "../../../../services/localization/text";
import preventEventPropagation from "../../../../utils/preventEventPropagation";

type Props = {
  children: children,
  isRightBottomPositioned?: boolean,
  isRightPositioned?: boolean,
  addClasses?: addClasses,
  isHighZIndex?: isHighZIndex,
  moreLinkTo?: linkTo
};
//TODO: add test
const QuestionTooltip = (props: Props) => {
  const baseClassName = props.isHighZIndex ? "QuestionTooltip__text QuestionTooltip__text_z9000" : "QuestionTooltip__text";
  const questionTooltipTextClassName = props.isRightBottomPositioned ? baseClassName + " QuestionTooltip__text_right-bottom" :
    props.isRightPositioned ? baseClassName + " QuestionTooltip__text_right" : baseClassName;
  return (<div className={baseClass("QuestionTooltip", props.addClasses)}>
    ?
    <div className={questionTooltipTextClassName}>
      {props.children}
      {props.moreLinkTo ? (
        <a href={props.moreLinkTo} className="QuestionTooltip__more-link" rel="noopener noreferrer"
           onClick={preventEventPropagation} target="_blank">{text.moreLinkTo}</a>
      ) : null}
    </div>
  </div>);
};

export default QuestionTooltip;
