//@flow
import * as React from 'react';
import type {handledContract} from "../../../services/flowTypes/componentFlowTypes";
import type {contract} from "../../../services/flowTypes/dataFlowTypes";
import TextOverflowHandler from "../../simple/Containers/TextOverflowHandler/TextOverflowHandler";

type Props = {
  handledContract: handledContract,
  contract: contract
};

const AddContractRow = (props: Props) => {
  return (
    <div className="flex">
      <div className="AddContractRow__name">
        <TextOverflowHandler>{props.handledContract.idName}</TextOverflowHandler>
      </div>
      <div className="AddContractRow__contractNo">
        <TextOverflowHandler>{props.contract.contractNo}</TextOverflowHandler>
      </div>
      <div className="AddContractRow__validityTerm">{props.handledContract.validityTerm}</div>
      <div className="AddContractRow__contractTypeName">
        <TextOverflowHandler>{props.handledContract.contractTypeName}</TextOverflowHandler>
      </div>
      <div className="AddContractRow__statusLabel">
        <div className={`StatusLabel StatusLabel_${props.contract.status}`}>
          {props.handledContract.itemStatusLabel}
        </div>
      </div>
      <div className="AddContractRow__statusDate">{props.handledContract.statusDate}</div>
    </div>
  );
};

export default AddContractRow;