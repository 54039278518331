//@flow
import * as React from 'react';
import {connect} from "react-redux";
import DeleteDocument
  from "../../../../../../../components/composed/ItemDocuments/DocumentEdit/DeleteDocument/DeleteDocument";
import {documentsPageIds} from "../../../../../../../tests/testIds";
import Can from "../../../../../../../services/permissions/Can";
import text from "../../../../../../../services/localization/text";
import NModal from "../../../../../../../components/simple/Modals/NModal/NModal";
import NFormGroup from "../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import NInput from "../../../../../../../components/simple/FormComponents/NInput/NInput";
import Document from "../../../../../../../components/composed/ItemDocuments/DocumentEdit/Document/Document";
import type {contractId, document, fileDescription} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {
  documentIndex,
  isDocumentEdit,
  permittedActionObj
} from "../../../../../../../services/flowTypes/componentFlowTypes";
import type {addClasses} from "../../../../../../../services/flowTypes/uiKitFlowTypes";
import type {readOnly} from "../../../../../../../services/flowTypes/appFlowTypes";
import itemFilesActions
  from "../../../../../../../store/organization/item/itemFiles/itemFilesActions";
import type {
  onDeleteItemFiles,
  onUpdateItemFiles
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import ContractMainDataFileUploader from "./ItemFileUploader";
import BtnEditAct from "../../../../../../../components/simple/Buttons/BtnEditAct/BtnEditAct";

type Props = {
  contractId: contractId,
  permittedActionObj: permittedActionObj,
  documentIndex: documentIndex,
  document: document,
  addClasses?: addClasses,
  readOnly?: readOnly,
  onUpdateItemFiles: onUpdateItemFiles,
  onDeleteItemFiles: onDeleteItemFiles
};
type State = {
  isDocumentEdit: isDocumentEdit,
  fileDescription: fileDescription
};

class ItemFile extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isDocumentEdit: false,
      fileDescription: this.props.document.fileDescription
    };
  }
  handleDescriptionChange = (e) => this.setState({fileDescription: e.target.value});
  onCancelBtnClick = () => {
    this.setState({
      isDocumentEdit: false,
      fileDescription: this.props.document.fileDescription
    });
  };
  onSaveBtnClick = () => {
    const actionValue = {
      fileIndex: this.props.documentIndex,
      paramName: 'fileDescription',
      paramValue: this.state.fileDescription
    };
    this.props.onUpdateItemFiles(actionValue);
    this.setState({
      isDocumentEdit: false
    })
  };
  onEditBtnClick = () => {
    this.setState({
      isDocumentEdit: true
    })
  };
  deleteContractMainDataFile = () => {
    if (this.props.document.fileId) {
      const actionValue = {
        fileIndex: this.props.documentIndex,
        paramName: 'isFileDeleted',
        paramValue: true
      };
      this.props.onUpdateItemFiles(actionValue);
    } else {
      this.props.onDeleteItemFiles(this.props.documentIndex);
    }
  };
  render() {
    const DeleteBtn = this.props.readOnly ? null :
      <DeleteDocument itemType={'contract'}
                      itemId={this.props.contractId} fileId={this.props.document.fileId}
                      fileName={this.props.document.name}
                      permittedAction={this.props.permittedActionObj.delete}
                      idForTest={documentsPageIds.buttonDeleteDocument(this.props.documentIndex)}
                      deleteContractMainDataFile={this.deleteContractMainDataFile}
                      // mountDocumentsListFetcher={this.props.mountDocumentsListFetcher}
                      // unmountDocumentsListFetcher={this.props.unmountDocumentsListFetcher}
                      // setErrorObject={this.props.setErrorObject}
      />;
    const EditBtn = this.props.readOnly ? null :
      (
      <Can do={'usePermission'} on={this.props.permittedActionObj.update}>
        <BtnEditAct id={documentsPageIds.buttonEditDocument(this.props.documentIndex)}
                    onClick={this.onEditBtnClick}>{text.editBtnLabel}</BtnEditAct>
      </Can>
    );
    const substituteBtn = this.props.readOnly ? null : (
      <ContractMainDataFileUploader permittedAction={this.props.permittedActionObj.update}
                                    inheritedFileDescription={this.props.document.fileDescription}
                                    inheritedFileId={this.props.document.fileId}
                                    fileIndex={this.props.documentIndex}
                                    contractId={this.props.contractId} isSubstituteMode={true}/>
    );
    const editModal = (
      <NModal
        addClasses='NModal_ov'
        isModalDocumentEdit={true}
        modalTitle={text.document.edit}
        modalCancelBtnText={text.cancel}
        modalActionBtnText={text.save}
        onModalCancelBtnClick={this.onCancelBtnClick}
        onModalActionBtnClick={this.onSaveBtnClick}
      >
        <NFormGroup label={text.document.name} addClasses={'mt_4du mb_3du'}>
          <NInput value={this.props.document.name} readOnly id={documentsPageIds.modalInputDocumentName(this.props.documentIndex)}/>
        </NFormGroup>
        <NFormGroup label={text.description} addClasses={'mb_3du'}>
          <NInput value={this.state.fileDescription}
                  onChange={(e) => this.handleDescriptionChange(e)}
                  id={documentsPageIds.modalInputDocumentDescription(this.props.documentIndex)}
          />
        </NFormGroup>
        {/*<NFormGroup label='Каталог' addClasses={'mb_3du'}>*/}
        {/*  <NSelect value={this.state.selectedOption}*/}
        {/*           isSearchable={true}*/}
        {/*           isDisabled={true}*/}
        {/*           onChange={(folder) => this.handleFolderChange(folder)}*/}
        {/*           isSelectMenuWide={false}*/}
        {/*           options={this.state.selectOptions}*/}
        {/*           id={documentsPageIds.modalSelectDocumentFolder(this.props.documentIndex)}*/}
        {/*  />*/}
        {/*</NFormGroup>*/}
      </NModal>
    );
    const documentBtns = <div className={'flex jc_fe'}>{substituteBtn}{EditBtn}{DeleteBtn}</div>;
    return (
      <Document document={this.props.document} itemType={'contract'} itemId={this.props.contractId}
                addClasses={this.props.addClasses}
                isDocumentEdit={this.state.isDocumentEdit}
                editModal={this.state.isDocumentEdit ? editModal : null}
                documentBtns={documentBtns}
                linkIdToDownload={documentsPageIds.buttonDownloadDocument(this.props.documentIndex)}
      />
    );
  }
}

// const mapStateToProps = state => {
//   return {
//     files: state.organization.item.contractMainData.files,
//   };
// };

const mapDispatchToProps = dispatch => {
  return {
    onUpdateItemFiles: (value) => dispatch({
      type: itemFilesActions.ITEM_FILES_UPDATE, value: value}),
    onDeleteItemFiles: (value) => dispatch({
      type: itemFilesActions.ITEM_FILES_DELETE, value: value})
  };
};

export default connect(null, mapDispatchToProps)(ItemFile);