const agentMainDataValidationScheme = {
  name: ['required', 'maxLength128'],
  status: ['required'],
  // statusDate: ['dateFormat'],
  statusReason: ['maxLength128'],
  params: {
    blockName: ['required'],
    paramValue: ['required', 'maxLength2048']
  }
};

export default agentMainDataValidationScheme;