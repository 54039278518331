export const globalPermissionsStrValues = {
  admin: "ACTION_ADMIN",
  viewDashboard: "ACTION_VIEW_DASHBOARD",
  user: {
    view: "ACTION_VIEW_USER",
    create: "ACTION_CREATE_USER",
    update: "ACTION_UPDATE_USER"
  },
};

export const permissionsStrValues = {
  contract: {
    view: "ACTION_VIEW_CONTRACT",
    create: "ACTION_CREATE_CONTRACT",
    update: "ACTION_UPDATE_CONTRACT"
  },
  agent: {
    view: "ACTION_VIEW_AGENT",
    create: "ACTION_CREATE_AGENT",
    update: "ACTION_UPDATE_AGENT",
    delete: "ACTION_DELETE_AGENT"
  },
  contractDocument: {
    view: "ACTION_VIEW_CONTRACT_DOCUMENT",
    create: "ACTION_CREATE_CONTRACT_DOCUMENT",
    update: "ACTION_UPDATE_CONTRACT_DOCUMENT",
    delete: "ACTION_DELETE_CONTRACT_DOCUMENT"
  },
  agentDocument: {
    view: "ACTION_VIEW_AGENT_DOCUMENT",
    create: "ACTION_CREATE_AGENT_DOCUMENT",
    update: "ACTION_UPDATE_AGENT_DOCUMENT",
    delete: "ACTION_DELETE_AGENT_DOCUMENT"
  },
  organizationDocument: {
    view: "ACTION_VIEW_ORGANIZATION_DOCUMENT",
    create: "ACTION_CREATE_ORGANIZATION_DOCUMENT",
    update: "ACTION_UPDATE_ORGANIZATION_DOCUMENT",
    delete: "ACTION_DELETE_ORGANIZATION_DOCUMENT"
  },
  contractHistory: {
    view: "ACTION_VIEW_CONTRACT_HISTORY"
  },
  agentHistory: {
    view: "ACTION_VIEW_AGENT_HISTORY"
  },
  organizationHistory: {
    view: "ACTION_VIEW_HISTORY_INTO_ORGANIZATION"
  },
  addAgreement: {
    view: "ACTION_VIEW_CONTRACT_ADDITIONAL_AGREEMENT",
    create: "ACTION_CREATE_CONTRACT_ADDITIONAL_AGREEMENT",
    update: "ACTION_UPDATE_CONTRACT_ADDITIONAL_AGREEMENT",
    delete: "ACTION_DELETE_CONTRACT_ADDITIONAL_AGREEMENT"
  },
  contractLinkedContract: {
    view: "ACTION_VIEW_CONTRACT_LINKED_CONTRACT",
    create: "ACTION_CREATE_CONTRACT_LINKED_CONTRACT",
    update: "ACTION_UPDATE_CONTRACT_LINKED_CONTRACT",
    delete: "ACTION_DELETE_CONTRACT_LINKED_CONTRACT"
  },
  contractLinkedAgent: {
    view: "ACTION_VIEW_CONTRACT_LINKED_AGENT",
    create: "ACTION_CREATE_CONTRACT_LINKED_AGENT",
    update: "ACTION_UPDATE_CONTRACT_LINKED_AGENT",
    delete: "ACTION_DELETE_CONTRACT_LINKED_AGENT"
  },
  actTemplate: {
    view: "ACTION_VIEW_CONTRACT_REPORT_TEMPLATE",
    create: "ACTION_CREATE_CONTRACT_REPORT_TEMPLATE",
    update: "ACTION_UPDATE_CONTRACT_REPORT_TEMPLATE"
  },
  finTerm: {
    view: "ACTION_VIEW_FINTERM",
    create: "ACTION_CREATE_FINTERM",
    update: "ACTION_UPDATE_FINTERM"
  },
  organizationParams: {
    view: "ACTION_ORGANIZATION_PARAMS_VIEW",
    update: "ACTION_ORGANIZATION_PARAMS_UPDATE",
  },
  report: {
    view: "ACTION_VIEW_REPORT",
    create: "ACTION_CREATE_REPORT",
    updateState: "ACTION_UPDATE_REPORT_STATE"
  },
  organization: {
    view: "ACTION_ORGANIZATION_VIEW",
    update: "ACTION_ORGANIZATION_UPDATE",
  },
  addService: {
    view: "ACTION_VIEW_CONTRACT_REPORT_TEMPLATE",
    create: "ACTION_CREATE_CONTRACT_REPORT_TEMPLATE",
    update: "ACTION_UPDATE_CONTRACT_REPORT_TEMPLATE",
    delete: "ACTION_UPDATE_CONTRACT_REPORT_TEMPLATE"
  },
  gatesParams: {
    view: "ACTION_VIEW_AGENT",
    update: "ACTION_UPDATE_AGENT"
  },
};

export const rolesStrValues = {
  lawyer: 'LAWYER',
  admin: 'ADMIN',
  accountant: 'ACCOUNTANT',
  manager: 'MANAGER',
  chiefAccountant: 'CHIEF_ACCOUNTANT',
  chiefLawyer: 'CHIEF_LAWYER'
};
