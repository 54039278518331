//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {totalReportsNumber} from "../../../services/flowTypes/dataFlowTypes";

type Props = {
  total: totalReportsNumber
};

class ReportsTotalCount extends React.Component <Props> {
  render() {
    return this.props.total;
  }
}

const mapStateToProps = state => {
  return {
    total: state.organization.reports.total,
  };
};

export default connect(mapStateToProps, null)(ReportsTotalCount);