//@flow
import addAgreementsActions from './addAgreementsActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {addAgreementsListResponse} from "../../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = null;

const addAgreementsReducer = (state:addAgreementsListResponse = initialState, action:reducerAction) => {
  switch (action.type) {
    case addAgreementsActions.ADD_AGREEMENTS_INIT:
      return initialState;
    case addAgreementsActions.ADD_AGREEMENTS_SET:
      return action.value;
    default:
      return state;
  }
};

export default addAgreementsReducer;