//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {isAddendumTemplateParams} from "../../../../../../../services/flowTypes/componentFlowTypes";
import type {errorObject, isReadyToRender, modalIsShown} from "../../../../../../../services/flowTypes/appFlowTypes";
import BtnEditAct from "../../../../../../../components/simple/Buttons/BtnEditAct/BtnEditAct";
import {actTemplatesEditPageIds} from "../../../../../../../tests/testIds";
import text from "../../../../../../../services/localization/text";
import ViewTemplateParams from "./ViewTemplateParams";
import type {
  actTemplateId,
  actTemplateParamsList,
  contractId, groupFinTermId
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import api from "../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";

type Props = {
  isAddendumTemplateParams: isAddendumTemplateParams,
  actTemplateParamsList: actTemplateParamsList,
  actTemplateId?: actTemplateId,
  contractId?: contractId,
  finTermGroupIds?: Array<groupFinTermId>
};
type State = {
  modalIsShown: modalIsShown,
  actTemplateParamsList: actTemplateParamsList,
  isReadyToRender: isReadyToRender,
  errorObject: errorObject
};


class ConnectedViewTemplateParams extends React.Component <Props, State> {
  constructor(props){
    super(props);
    this.state = {
      modalIsShown: false,
      actTemplateParamsList: [],
      isReadyToRender: true,
      errorObject: null
    };
  }
  hideModal = () => {
    this.setState({
    modalIsShown:false,
    actTemplateParamsList: []
  });
  };
  showModal = () => {this.setState({
    modalIsShown:true
  })};
  requestDataAndShowModal = () => {
    this.setState({isReadyToRender: false})
    const actTemplateId = this.props.actTemplateId ? this.props.actTemplateId : ''
    api.getTemplateAddendumParams(this.props.contractId, this.props.finTermGroupIds, actTemplateId)
      .then(response => {
        this.setState({
          isReadyToRender: true,
          modalIsShown:true,
          actTemplateParamsList: response.data
        });
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    const actTemplateParamsList = this.props.isAddendumTemplateParams ? this.state.actTemplateParamsList
      : this.props.actTemplateParamsList;
    const onClick = this.props.isAddendumTemplateParams ? this.requestDataAndShowModal : this.showModal;
    const addClasses = this.props.isAddendumTemplateParams ? "mb_1du" : 'ml_3du';
    return (
      <>
        <BtnEditAct id={actTemplatesEditPageIds.linkToViewTags} onClick={onClick}
                    addClasses={addClasses}>{text.btnViewTags}</BtnEditAct>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        {this.state.modalIsShown ? (
          <ViewTemplateParams hideModal={this.hideModal} actTemplateParamsList={actTemplateParamsList}/>
        ) : null}
      </>

    );
  }
}

const mapStateToProps = state => {
  return {
    actTemplateParamsList: state.organization.item.actTemplateParamsList
  };
};
export default connect(mapStateToProps, null)(ConnectedViewTemplateParams);