//@flow
import contractGatesParamsActions from './contractGatesParamsActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {gatesParamsResponse} from "../../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = {
  requestPaynetDate: "",
  gatesParams: null
};

const gatesParamsReducer = (state:gatesParamsResponse = initialState, action:reducerAction) => {
  switch (action.type) {
    case contractGatesParamsActions.CONTRACT_GATES_PARAMS_INIT:
      return initialState;
    case contractGatesParamsActions.CONTRACT_GATES_PARAMS_SET:
      return action.value;
    default:
      return state;
  }
};

export default gatesParamsReducer;