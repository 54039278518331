//@flow
import * as React from 'react';
import type {organizationId} from "../../../services/flowTypes/dataFlowTypes";
import organizationSettingsMenuItems from "../../../configData/organizationSettingsMenuItems";
import ColumnMenu from "../../../components/simple/Containers/RowMenu/ColumnMenu";
import SideLeftContainer from "../../../components/simple/Containers/SideLeftContainer/SideLeftContainer";

type Props = {
  organizationId: organizationId
};

const OrganizationSettingsMenu = (props:Props) => {
  const menuItemsData = organizationSettingsMenuItems(props.organizationId);
  return (
    <SideLeftContainer><ColumnMenu menuItemsData={menuItemsData} addClasses={"OrganizationSettingsMenu"}/></SideLeftContainer>
  );
};
export default OrganizationSettingsMenu;