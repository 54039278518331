//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../service/RenderOnReady/RenderOnReady";
import historyDataActions from "../../../store/organization/historyData/historyDataActions";
import type {onGetHistory} from "../../../services/flowTypes/propFnFlowTypes";
import type {errorObject, filtersFromParams, history, isReadyToRender} from "../../../services/flowTypes/appFlowTypes";
import api from "../../../services/apiInstance/api";
import apiErrorHandler from "../../../services/apiInstance/apiErrorHandler";
import type {historyType} from "../../../services/flowTypes/componentFlowTypes";
import type {agentId, contractId, organizationId} from "../../../services/flowTypes/dataFlowTypes";
import handleCorrectOrgIdInResponse from "../../../utils/handleCorrectOrgIdInResponse";
import RedirectToCorrectOrgUrl from "../../service/RedirectToCorrectOrgUrl/RedirectToCorrectOrgUrl";

type Props = {
  onGetHistory: onGetHistory,
  filtersFromParams: filtersFromParams,
  historyType: historyType,
  itemId?: contractId | agentId,
  routerHistory: history
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  correctOrganizationId: organizationId
};

export const handleHistoryFilters = (preHistoryFilters) => {
  const historyFilters = {...preHistoryFilters};
  if (historyFilters.hasOwnProperty('startDate')) {
    historyFilters.startDate = historyFilters.startDate === '' ? null : historyFilters.startDate;
  }
  if (historyFilters.hasOwnProperty('endDate')) {
    historyFilters.endDate = historyFilters.endDate === '' ? null : historyFilters.endDate;
  }
  if (historyFilters.hasOwnProperty('userId')) {
    historyFilters.userId = historyFilters.userId === '' || historyFilters.userId === 'ALL' ? null : historyFilters.userId;
  }
  if (historyFilters.hasOwnProperty('actTemplateId')) {
    historyFilters.actTemplateId = historyFilters.actTemplateId === '' || historyFilters.actTemplateId === 'ALL' ? null : historyFilters.actTemplateId;
  }
  if (historyFilters.hasOwnProperty('finTermGroupId')) {
    historyFilters.finTermGroupId = historyFilters.finTermGroupId === '' || historyFilters.finTermGroupId === 'ALL' ? null : historyFilters.finTermGroupId;
  }
  return historyFilters;
};
const sendApiRequest = (historyType, params, itemId) => {
  switch (historyType) {
    case 'contract':
      return api.getContractHistory(itemId, params);
    case 'agent':
      return api.getAgentHistory(itemId, params);
    case 'report':
      return api.getReportHistory(itemId, params);
    case 'user':
      return api.getUserHistory(itemId, params);
    case 'organizationData':
      return api.getOrganizationDataHistory(params);
    default:
      throw new Error('Undefined historyType in HistoryFetcher.js');
  }
};

class HistoryFetcher extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true,
      correctOrganizationId: null
    };
  }
  handleResponse = (responseData) => {
    this.props.onGetHistory(responseData);
    this.setState({isReadyToRender: true});
  };
  getHistory = () => {
    const historyFilters = handleHistoryFilters(this.props.filtersFromParams);
    this.setState({isReadyToRender: false});
    const apiRequest = sendApiRequest(this.props.historyType, historyFilters, this.props.itemId);
    apiRequest.then((response) => {
      handleCorrectOrgIdInResponse(this, response.data, this.setState, this.handleResponse);
      })
      .catch((error) => {
        this.setState({
          errorObject: apiErrorHandler(error),
          isReadyToRender: true
        });
      });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.filtersFromParams !== this.props.filtersFromParams) {
      this.getHistory();
    }
  }
  // componentDidMount() {
  //   if (this.props.filtersFromParams.hasOwnProperty('count')) {
  //     this.getHistory();
  //   }
  // }
  render() {
    return (
      <RedirectToCorrectOrgUrl correctOrganizationId={this.state.correctOrganizationId} history={this.props.routerHistory}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
      </RedirectToCorrectOrgUrl>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onGetHistory: (value) => dispatch({type: historyDataActions.HISTORY_SET, value})
  };
};
export default connect(null, mapDispatchToProps)(HistoryFetcher);