//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {userEmail, userId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {errorObject, isReadyToRender, modalIsShown} from "../../../../../../../services/flowTypes/appFlowTypes";
import NModal from "../../../../../../../components/simple/Modals/NModal/NModal";
import text from "../../../../../../../services/localization/text";
import NFormGroup from "../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import {userViewPageIds} from "../../../../../../../tests/testIds";
import BtnEditAct from "../../../../../../../components/simple/Buttons/BtnEditAct/BtnEditAct";
import NInput from "../../../../../../../components/simple/FormComponents/NInput/NInput";
import api from "../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {linkIsSent} from "../../../../../../../services/flowTypes/componentFlowTypes";
import Text1 from "../../../../../../../components/simple/TextComponents/Text1/Text1";

type Props = {
  email: userEmail,
  userId: userId
};
type State = {
  modalIsShown: modalIsShown,
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  emailValue: userEmail,
  linkIsSent: linkIsSent
};

class SendActivationLink extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false,
      errorObject: null,
      isReadyToRender: true,
      emailValue: '',
      linkIsSent: false
    };
  }
  sendLink = () => {
    this.setState({isReadyToRender: false});
    console.log(this.state.emailValue);
    const requestBody = {
      email: this.state.emailValue
    };
    api.sendSetPassLink(this.props.userId, requestBody)
      .then(response => {
        this.setState({
          linkIsSent: true,
          isReadyToRender: true
        });
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  handleEmailChange = (e:SyntheticEvent<HTMLInputElement>) => {
    this.setState({emailValue: e.currentTarget.value});
  };
  hideModal = () => {
    this.setState({
      modalIsShown: false,
      emailValue: '',
      linkIsSent: false
    });
  };
  showModal = () => {
    this.setState({
      modalIsShown: true,
      emailValue: this.props.email
    });
  };
  render() {
    return (
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <BtnEditAct onClick={this.showModal} id={userViewPageIds.buttonSendLink}>{text.sendLinkToActivate}</BtnEditAct>
        {this.state.modalIsShown ? (
          <NModal
            modalTitle={text.sendActivationLinkModalTitle}
            onModalCancelBtnClick={this.hideModal}
            onModalActionBtnClick={this.sendLink}
            modalCancelBtnText={text.cancel}
            modalActionBtnText={text.btnSend}
            addClasses={'NModal_ov'}
          >
            <div className="flex_jc-sb pt-pb_2du">
              {this.state.linkIsSent ? (
                <Text1 id={userViewPageIds.divEmailSentMsg} addClasses={'Text1_success ta_c'}>{text.linkIsSent}</Text1>
                ) : (
                <NFormGroup label={text.email} addClasses={'w_half-card'}>
                  <NInput id={userViewPageIds.inputEmailActivationLink} value={this.state.emailValue}
                          onChange={this.handleEmailChange}/>
                </NFormGroup>
              )}
            </div>
          </NModal>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    email: state.user.mainData.email
  };
};
export default connect(mapStateToProps, null)(SendActivationLink);