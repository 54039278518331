//@flow
import * as React from 'react';
import './Logo.css';

type Props = {};

const Logo = (props: Props) => {
  return (
    <div className="Logo">
      <div className="Logo__full">
        <div className='Logo__blue'>FLOW</div>
        <div>DOC</div>
      </div>
      <div className="Logo__small">
        <span className='Logo__blue'>F</span><span>D</span>
      </div>
    </div>
  );
};

export default Logo;    