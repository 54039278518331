//@flow
import * as React from 'react';
import NHeading3 from "../../simple/TextComponents/NHeading3/NHeading3";
import type {errorObject, isReadyToRender, isSetOrResetPass, passValue} from "../../../services/flowTypes/appFlowTypes";
import text from "../../../services/localization/text";
import NFormGroup from "../../simple/FormComponents/NFormGroup/NFormGroup";
import NInput from "../../simple/FormComponents/NInput/NInput";
import type {passwordsAreIdentical, propName as propNameType} from "../../../services/flowTypes/componentFlowTypes";
import BtnNewItem from "../../simple/Buttons/BtnNewItem/BtnNewItem";
import LoginPagesLayout from "../../simple/Containers/LoginPagesLayout/LoginPagesLayout";
import PasswordHandler from "../../../loginPages/SetPassPage/PasswordHandler/PasswordHandler";
import passwordRules from "../../../services/passwordRules/passwordRules";
import PasswordRuleLabel from "../../../loginPages/SetPassPage/PasswordHandler/PasswordRuleLabel/PasswordRuleLabel";

type Props = {
  isSetOrResetPass: isSetOrResetPass
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  passValue: passValue,
  repeatPassValue: passValue,
  passwordsAreIdentical: passwordsAreIdentical
};
class HandlePassPage extends React.Component <Props, State> {
  inputPassword: { current: null | HTMLInputElement };
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null,
      passValue: '',
      repeatPassValue: '',
      passwordsAreIdentical: false
    };
    this.inputPassword = React.createRef();
  }
  onChange = (e:SyntheticEvent<HTMLInputElement>, propName:propNameType) => {
    const anotherPropName = propName === 'passValue' ? 'repeatPassValue' : 'passValue';
    this.setState({
      [propName]: e.currentTarget.value,
      passwordsAreIdentical: this.state[anotherPropName] === e.currentTarget.value
    });
  };
  componentDidMount(){
    if (this.inputPassword.current) {
      this.inputPassword.current.focus();
    }
  }
  render() {
    const propError = window.location.href.includes('?error') ? text.errorModalTitle : '';
    const windowDataType = this.props.isSetOrResetPass === 'setPass' ? "SET_PASS_DATA" : "RESET_PASS_DATA";
    const windowData = window[windowDataType];
    const loginPageLayoutTitle = text.helloUser(windowData.userName);
    const loginPageLayoutSubTitle = text.needToSetPass;
    const formAction = '/set-password';
    const loginPageLayoutContent = (
      <div className={'flex_jc-sb flex_wrap'}>
        <div>
          <NHeading3 addClasses={'mb_3du'}>{text.modalSubTitle[this.props.isSetOrResetPass]}</NHeading3>
          <NFormGroup label={text.passLabel} addClasses={'w_half-card mb_3du'} propError={propError}>
            <NInput name={'password'} value={this.state.passValue} onChange={(e)=>{this.onChange(e, 'passValue')}}
                    inputType={'password'} ref={this.inputPassword} />
          </NFormGroup>
          <NFormGroup label={text.repeatPassLabel} addClasses={'w_half-card mb_3du'}>
            <NInput value={this.state.repeatPassValue} onChange={(e)=>{this.onChange(e, 'repeatPassValue')}}
                    inputType={'password'}/>
          </NFormGroup>
          <input type="hidden" name={windowData.csrfToken.name}
                 value={windowData.csrfToken.value} />
          <input type="hidden" name={'key'} value={windowData.key} />
        </div>
        <div>
          <PasswordHandler
            passwordRules={passwordRules}
            passValue={this.state.passValue}
          />
          <PasswordRuleLabel ruleIsRight={this.state.passwordsAreIdentical}>{text.passwordsAreIdentical}</PasswordRuleLabel>
        </div>
      </div>
    );
    let allRulesAreRight = true;
    for (let i=0; i < passwordRules.length; i++) {
      allRulesAreRight = allRulesAreRight && passwordRules[i].fn(this.state.passValue);
    }
    const submitIsDisabled = !(allRulesAreRight && this.state.passwordsAreIdentical);
    const loginPageLayoutBtn = (<BtnNewItem disabled={submitIsDisabled} buttonType={'submit'}>{text.save}</BtnNewItem>);
    return (
      <LoginPagesLayout
        loginPageLayoutTitle={loginPageLayoutTitle}
        loginPageLayoutSubTitle={loginPageLayoutSubTitle}
        formAction={formAction}
        loginPageLayoutContent={loginPageLayoutContent}
        loginPageLayoutBtn={loginPageLayoutBtn}
      />
    );
  }
}

export default HandlePassPage;    