//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id, onClick} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './BtnSearchIco.css';
import type {isFocused} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  onClick?: onClick,
  isFocused?: isFocused
};

const BtnSearchIco = (props: Props) => {
  const className = props.isFocused ? "BtnSearchIco BtnSearchIco_focused" : "BtnSearchIco";
  return (
    <div id={props.id} onClick={props.onClick}
         className={baseClass(className, props.addClasses)}/>
  );
};

export default BtnSearchIco;