//@flow
import contractMainDataActions from './contractMainDataActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {contract} from "../../../../services/flowTypes/dataFlowTypes";

type State = contract;
type reducerAction = action;
export const initialState = {
  typeId: '',
  contractNo: '',
  name: '',
  comment: '',
  startDate: '',
  endDate: '',
  contractCode1c: '',
  tags: [],
  params: [],
  files: []
};
//!!!!!!!! params are used only for view component, in edit mode params are handled from itemDataParamsEdit state

const contractMainDataReducer = (state:State = initialState, action:reducerAction) => {
  switch (action.type) {
    case contractMainDataActions.CONTRACT_MAIN_DATA_SET:
      return action.value;
    case contractMainDataActions.CONTRACT_MAIN_DATA_INIT:
      return initialState;
    case contractMainDataActions.CONTRACT_MAIN_DATA_PROP_SET:
      return {
        ...state,
        [action.value.prop]: action.value.data
      }
    default:
      return state;
  }
};

export default contractMainDataReducer;