//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {elementRefType, id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './RowMenu.css';
import type {isGlobalPermission, menuItemsData} from "../../../../services/flowTypes/componentFlowTypes";
import {NavLink} from "react-router-dom";
import Can from "../../../../services/permissions/Can";
import RowMenuOverflow from "./RowMenuOverflow";
import debounce from 'lodash.debounce';
import {canUseOnlyForRoles} from "../../../service/Routes/canUseAnyone";

type Props = {
  id?: id,
  addClasses?: addClasses,
  menuItemsData: menuItemsData,
  isGlobalPermission?: isGlobalPermission,
};
type State = {
  isOverflow: boolean,
  visibleItemsData: menuItemsData,
  overflowItemsData: menuItemsData,
  opacity: string
};


class RowMenu extends React.Component <Props, State> {
  ref: elementRefType;
  constructor(props: Props) {
    super(props);
    this.state = {
      visibleItemsData: [],
      overflowItemsData: [],
      isOverflow: false,
      opacity: "0"
    };
    this.ref = React.createRef();
    this.debounceDetermineOverflow = debounce(this.determineOverflow, 500);
  }
  determineOverflow = () => {
    if (this.state.isOverflow) {
      this.setState({
        visibleItemsData: [],
        overflowItemsData: [],
        isOverflow: false,
        opacity: "0"
      }, this.calculateOverflow);
    } else {
      this.calculateOverflow();
    }
  };
  calculateOverflow = () => {
    let totalChildWidth = 0;
    let isOverflow = false;
    let childNodeIndex = 0;
    let isOverflowIndex = -1;
    const menuInnerWidth = this.ref.current.offsetWidth;
    do {
      totalChildWidth = totalChildWidth + this.ref.current.childNodes[childNodeIndex].offsetWidth;
      if (totalChildWidth > menuInnerWidth) {
        isOverflowIndex = childNodeIndex;
        isOverflow = true;
      }
      childNodeIndex++;
    } while (!isOverflow && childNodeIndex < this.ref.current.childNodes.length)

    const opacity = "1";
    if (isOverflow) {
      const visibleItemsData = this.props.menuItemsData.slice(0, isOverflowIndex);
      const overflowItemsData = this.props.menuItemsData.slice(isOverflowIndex);
      this.setState({
        visibleItemsData, overflowItemsData, isOverflow, opacity
      });
    } else {
      this.setState({
        visibleItemsData: [],
        overflowItemsData: [],
        isOverflow, opacity
      });
    }
  };
  componentDidMount() {
    this.debounceDetermineOverflow();
    window.addEventListener('resize', this.debounceDetermineOverflow);
  }
  componentWillUnmount(){
    window.removeEventListener('resize', this.debounceDetermineOverflow);
  }
  render() {
    const menuItemKeyName = "RowMenu__link";
    const permissionStr = this.props.isGlobalPermission ? 'useGlobalPermission' : 'usePermission';
    const visibleItems = this.state.isOverflow ? this.state.visibleItemsData : this.props.menuItemsData;
    const menuItems = visibleItems.map((item, i) => {
      const itemTo = item.linkTo ? item.linkTo.replace(/^.+\//, "") : "";
      const rowMenuItem = (key) =>  (
        <NavLink activeClassName={"RowMenuItem_active"} className={"RowMenuItem"} key={key}
                 data-ui-test-id={"rowMenuLink_" + itemTo}
                 to={item.linkTo}>
          {item.linkName}
          <span className="RowMenuItem__dash"/>
        </NavLink>
      );
      return (item.isPermitted ? rowMenuItem(menuItemKeyName + i)
          : (
            item.onlyForRoles ? (
              canUseOnlyForRoles(item.onlyForRoles) ? (
                <Can do={permissionStr} key={menuItemKeyName + i} on={item.permittedAction}>
                  {rowMenuItem()}
                </Can>
              ) : null
            )
            :
            (
              <Can do={permissionStr} key={menuItemKeyName + i} on={item.permittedAction}>
                {rowMenuItem()}
              </Can>
            )
          )
      );
    });
    return (
      <div id={this.props.id} className={baseClass("RowMenu", this.props.addClasses)}>
        <div className="RowMenu__inner-container">
          <div className="RowMenu__options" ref={this.ref} style={{opacity: this.state.opacity}}>
            {menuItems}
          </div>
          {this.state.isOverflow ? <RowMenuOverflow overflowItemsData={this.state.overflowItemsData} addClasses={"ml_3du"}/>
            : null}
        </div>
      </div>
    );
  }
}

export default RowMenu;