//@flow
import * as React from 'react';
import text from "../../../../../../services/localization/text";
import ConnectedUserOrganizationsList from "./ConnectedUserOrganizationsList/ConnectedUserOrganizationsList";
import EditUserOrganizations from "./EditUserOrganizations/EditUserOrganizations";
import type {locationType, match} from "../../../../../../services/flowTypes/appFlowTypes";
import {userOrganizationsPageIds} from "../../../../../../tests/testIds";
import Can from "../../../../../../services/permissions/Can";
import NHeading1 from "../../../../../../components/simple/TextComponents/NHeading1/NHeading1";
import type {needUpdateUser} from "../../../../../../services/flowTypes/componentFlowTypes";
import UserUpdater from "./EditUserOrganizations/UserUpdater/UserUpdater";
import {globalPermissionsStrValues} from "../../../../../../configData/permissionsStrValues";

type Props = {
  match: match,
  location: locationType
};
type State = {
  needUpdateUser: needUpdateUser
};

class UserOrganizations extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      needUpdateUser: false
    };
  }
  initUpdateUser = () => {
    this.setState({needUpdateUser: true});
  };
  cancelUpdateUser = () => {
    this.setState({needUpdateUser: false});
  };
  render() {
    const userId = this.props.match.params.id ? +this.props.match.params.id : null;
    return (userId ?
        <>
          <UserUpdater needUpdateUser={this.state.needUpdateUser} handleUpdateResponse={this.cancelUpdateUser}/>
          <ConnectedUserOrganizationsList userId={userId} initUpdateUser={this.initUpdateUser}/>
        </>
         :
      <>
        <div className="flex_jc-sb mb_3du">
          <NHeading1 addClasses={'NHeading1_lh24'}>{text.organizations}</NHeading1>
          <Can do={'useGlobalPermission'} on={globalPermissionsStrValues.user.update}>
            <EditUserOrganizations
              userId={userId}
              editOrAdd={'add'}
              btn={<div className="BtnNewItem pl-pr_2du" id={userOrganizationsPageIds.buttonAdd}>{text.btnAdd}</div>}
            />
          </Can>
        </div>
        <ConnectedUserOrganizationsList userId={userId}/>
      </>
    );
  }
}
export default UserOrganizations;