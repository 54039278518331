//@flow
import * as React from 'react';
import {
  mainDataSuffix,
  newOrganizationSuffix,
  supOrgPathEdit, supOrgPathView,
  supOrgsPath
} from "../../../services/urlStrings/urlStrings";
import PrivateRoute from "./PrivateRoute";
import ability from "../../../services/permissions/permissions";
import OrganizationEditButton
  from "../../../supPages/Organizations/pages/Organization/pages/OrganizationMainDataView/OrganizationEditButton";
import type {organizationId} from "../../../services/flowTypes/dataFlowTypes";
import OrganizationMainDataControls
  from "../../../supPages/Organizations/pages/Organization/pages/OrganizationMainDataEdit/organizationMainDataEdit/OrganizationMainDataControls";
import {globalPermissionsStrValues} from "../../../configData/permissionsStrValues";

type Props = {
  organizationId: organizationId
};

const OrganizationButtonsRoutes = (props: Props) => {
  return (
    <>
      <PrivateRoute exact path={supOrgsPath + newOrganizationSuffix} addProps={{organizationId: props.organizationId, isSupLevel: true}}
                    component={OrganizationMainDataControls}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.admin)} />
      <PrivateRoute path={supOrgPathView(':id') + mainDataSuffix} addProps={{organizationId: props.organizationId, isSupLevel: true}}
                    component={OrganizationEditButton}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.admin)} />
      <PrivateRoute path={supOrgPathEdit(':id') + mainDataSuffix} addProps={{organizationId: props.organizationId, isSupLevel: true}}
                    component={OrganizationMainDataControls}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.admin)} />
    </>
  );
};

export default OrganizationButtonsRoutes;