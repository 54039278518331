const isSourceParamVisible = (propName: string, ignoreGates: boolean) => {
  if (ignoreGates) {
    return true
  }
  const hideWhenEnabledGates = [
    "merchantIds",
    "managerIds",
    "processorIds",
    "procIdsExcluded",
    "mngrMercIdsExcluded",
    "mngrIdsExcluded",
    "mercIdsExcluded",
  ]
  return !hideWhenEnabledGates.includes(propName)
}

export {
  isSourceParamVisible
}