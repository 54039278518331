//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {user} from "../../../../services/flowTypes/dataFlowTypes";
import UserCard from "./UserCard/UserCard";
import EmptyListV2 from "../../../../components/simple/Containers/EmptyList/EmptyListV2";

type Props = {
  users: Array<user>
};

class UsersSearchResults extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const usersCards = this.props.users === null ? null :
      this.props.users && this.props.users.length ?
      this.props.users.map((item, index)=> {
        return <UserCard key={'User' + index} user={item} userIndex={index}/>
      })
      : (<EmptyListV2/>);
    return (
      <div>
        {usersCards}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users.searchResults.users
  };
};
export default connect(mapStateToProps, null)(UsersSearchResults);