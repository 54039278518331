//@flow
import * as React from 'react';
import {connect} from "react-redux";
import text from "../../../../../../../services/localization/text";
import type {
  index,
  modalIsShown
} from "../../../../../../../services/flowTypes/appFlowTypes";
import type {
  organizationsList,
  rolesList, userId,
  userPermissions
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import userMainDataActions from "../../../../../../../store/user/userMainData/userMainDataActions";
import {
  onAddUserPermissions,
  onUpdateUserPermission
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import NFormGroup from "../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import NSelect from "../../../../../../../components/simple/FormComponents/NSelect/NSelect";
import type {
  btn, editOrAdd,
  selectedOption,
  selectOptions
} from "../../../../../../../services/flowTypes/componentFlowTypes";
import NModal from "../../../../../../../components/simple/Modals/NModal/NModal";
import {userOrganizationsPageIds} from "../../../../../../../tests/testIds";
import type {initUpdateUser} from "../../../../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  allOrganizationsList: organizationsList,
  userPermissions: userPermissions,
  onAddUserPermissions: onAddUserPermissions,
  rolesList: rolesList,
  userId: userId,
  btn: btn,
  editOrAdd: editOrAdd,
  cardIndex?: index,
  onUpdateUserPermission: onUpdateUserPermission,
  initUpdateUser?: initUpdateUser
};
type State = {
  modalIsShown: modalIsShown,
  rolesOptions: selectOptions,
  selectedRoleOption: selectedOption,
  selectedOrganizationOption: selectedOption,
  organizationsOptions: selectOptions
};

class EditUserOrganizations extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false,
      selectedRoleOption: null,
      selectedOrganizationOption: null,
      rolesOptions: [],
      organizationsOptions: []
    };
  }
  showModal = () => {
    const organizationsOptions = this.getOrganizationsOptions();
    const selectedRoleOption = this.props.editOrAdd === "add" ? null
      : this.state.rolesOptions.find(roleOption => roleOption.value === this.props.userPermissions[this.props.cardIndex].role);
    const selectedOrganizationOption = this.props.editOrAdd === "add" ? null
      : organizationsOptions.find(org => org.value === this.props.userPermissions[this.props.cardIndex].organizationId);
    this.setState({
      modalIsShown: true,
      organizationsOptions,
      selectedRoleOption,
      selectedOrganizationOption
    });
  };
  hideModal = () => {
    this.setState({
      modalIsShown: false,
    });
  };
  onSelectRole = (selectedRoleOption) => {
    this.setState({selectedRoleOption});
  };
  onSelectOrganization = (selectedOrganizationOption) => {
    this.setState({selectedOrganizationOption});
  };
  getOrganizationsOptions = () => {
    const availableOrganizationsOptions = this.props.editOrAdd === "add" ? this.props.allOrganizationsList.filter(org => {
      return !this.props.userPermissions.find(item => item.organizationId === org.id);
    }) : this.props.allOrganizationsList;
    return availableOrganizationsOptions.map(org => {
      return {value: org.id, label: org.name}
    });
  };
  addOrganization = () => {
    if (this.state.selectedOrganizationOption && this.state.selectedRoleOption) {
      const newUserPermission = {
        organizationId: this.state.selectedOrganizationOption.value,
        role: this.state.selectedRoleOption.value
      };
      this.props.onAddUserPermissions([newUserPermission]);
      this.handleFinish();
    } else {
      this.hideModal();
    }
  };
  handleFinish = () => {
    if (this.props.userId) {
      this.props.initUpdateUser();
      this.hideModal();
    } else {
      this.hideModal();
    }
  };
  editOrganization = () => {
    const newUserPermission = {
      organizationId: this.state.selectedOrganizationOption.value,
      role: this.state.selectedRoleOption.value
    };
    this.props.onUpdateUserPermission(this.props.cardIndex, newUserPermission);
    this.handleFinish();
  };
  componentDidMount(){
    const rolesOptions = this.props.rolesList.map((item) => {
      return {value: item.name, label: text.role[item.name]}
    });
    this.setState({rolesOptions});
  }
  render() {
    const noOrganizationsAvailable = () => text.noOrganizationsAvailable;
    const modalTitle = this.props.editOrAdd === "add" ? text.addOrganizationsToUserModal : text.editUserRoleModalTitle;
    const onModalActionBtnClick = this.props.editOrAdd === "add" ? this.addOrganization : this.editOrganization;
    const modalActionBtnText  = this.props.editOrAdd === "add" ? text.btnAdd : text.btnEdit;
    return (
      <>

        <div className="btn-container" onClick={this.showModal}>
          {this.props.btn}
        </div>
        {this.state.modalIsShown ? (
          <NModal
            modalTitle={modalTitle}
            onModalCancelBtnClick={this.hideModal}
            onModalActionBtnClick={onModalActionBtnClick}
            modalCancelBtnText={text.cancel}
            modalActionBtnText={modalActionBtnText}
            addClasses={'NModal_ov'}
          >
            <div className="flex_jc-sb pt-pb_2du">
              <NFormGroup label={text.organization} addClasses={'w_half-card'}>
                <NSelect value={this.state.selectedOrganizationOption} onChange={this.onSelectOrganization}
                         isDisabled={this.props.editOrAdd === "edit"}
                         isSearchable={true}
                         inputId={userOrganizationsPageIds.selectInputOrganization}
                         placeholder={text.selectOrganization}
                         noOptionsMessage={noOrganizationsAvailable}
                         options={this.state.organizationsOptions}
                         id={userOrganizationsPageIds.selectOrganization}
                />
              </NFormGroup>
              <NFormGroup label={text.roleLabel} addClasses={'w_half-card'}>
                <NSelect value={this.state.selectedRoleOption}
                         onChange={this.onSelectRole}
                         placeholder={text.selectRole}
                         isSearchable={true}
                         inputId={userOrganizationsPageIds.selectInputRole}
                         options={this.state.rolesOptions}
                         id={userOrganizationsPageIds.selectRole}
                />
              </NFormGroup>
            </div>
          </NModal>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    rolesList: state.admin.rolesList,
    allOrganizationsList: state.admin.allOrganizationsList,
    userPermissions: state.user.mainData.userPermissions
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onAddUserPermissions: (userPermissions) => dispatch({type: userMainDataActions.USER_PERMISSIONS_ADD,
      value: userPermissions}),
    onUpdateUserPermission: (index, orgPermission) => dispatch({type: userMainDataActions.USER_PERMISSION_UPDATE,
      value: {index, orgPermission}
    })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditUserOrganizations);