//@flow
import * as React from 'react';
import text from "../../../../../services/localization/text";
import type {id} from "../../../../../services/flowTypes/appFlowTypes";
import type {agentId, contractId, fileId} from "../../../../../services/flowTypes/dataFlowTypes";
import {
  mountDocumentsListFetcher, setErrorObject,
  unmountDocumentsListFetcher
} from "../../../../../services/flowTypes/propFnFlowTypes";
import api from "../../../../../services/apiInstance/api";
import type {itemType, permittedAction} from "../../../../../services/flowTypes/componentFlowTypes";
import Can from "../../../../../services/permissions/Can";
import type {
  onUpdateDocumentFoldersList
} from "../../../../../services/flowTypes/propFnFlowTypes";
import BtnEditAct from "../../../../simple/Buttons/BtnEditAct/BtnEditAct";

type Props = {
  unmountDocumentsListFetcher: unmountDocumentsListFetcher,
  mountDocumentsListFetcher: mountDocumentsListFetcher,
  itemType: itemType,
  itemId: contractId | agentId,
  fileId: fileId,
  setErrorObject: setErrorObject,
  idForTest?: id,
  permittedAction: permittedAction,
  onUpdateDocumentFoldersList: onUpdateDocumentFoldersList
};
type State = {
  promiseRestoreDocument: Object
};

class RestoreDocument extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      promiseRestoreDocument: {
        contract: api.restoreContractDocument,
        agent: api.restoreAgentDocument,
        organizations: api.restoreOrganizationDocument
      }
    };
  }
  restoreDocument = () => {
    this.props.unmountDocumentsListFetcher();
    const promise = this.state.promiseRestoreDocument[this.props.itemType];
    promise(this.props.itemId, this.props.fileId)
      .then(response => {
        this.props.onUpdateDocumentFoldersList(response.data);
        this.props.mountDocumentsListFetcher();
      })
      .catch(error => {this.props.setErrorObject(error)})
  };
  render() {
    return (
      <Can do={'usePermission'} on={this.props.permittedAction}>
        <BtnEditAct id={this.props.idForTest} onClick={this.restoreDocument}>
          {text.restoreBtnLabel}</BtnEditAct>
      </Can>
    );
  }
}

export default RestoreDocument;