//@flow
import * as React from 'react';
import {connect} from "react-redux";
import BtnDel from "../../../../../../../../../../../components/simple/Buttons/BtnDel/BtnDel";
import type {finTermIndex} from "../../../../../../../../../../../services/flowTypes/componentFlowTypes";
import finTermsEditActions from "../../../../../../../../../../../store/organization/item/finTermsEdit/finTermsEditActions";
import type {finTermId} from "../../../../../../../../../../../services/flowTypes/dataFlowTypes";
import concatIdName from "../../../../../../../../../../../utils/concatIdName";
import text from "../../../../../../../../../../../services/localization/text";
import type {modalIsShown} from "../../../../../../../../../../../services/flowTypes/appFlowTypes";
import NModal from "../../../../../../../../../../../components/simple/Modals/NModal/NModal";
import {onDeleteFinTerm, onSetStatusDeleteFinTerm} from "../../../../../../../../../../../services/flowTypes/propFnFlowTypes";
import {groupFinTermEditPageIds} from "../../../../../../../../../../../tests/testIds";
import NHeading2 from "../../../../../../../../../../../components/simple/TextComponents/NHeading2/NHeading2";

type Props = {
  id: finTermId,
  finTermIndex: finTermIndex,
  onDeleteFinTerm: onDeleteFinTerm,
  onSetStatusDeleteFinTerm: onSetStatusDeleteFinTerm
};
type State = {
  modalIsShown: modalIsShown
}

class DeleteFinTerm extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false
    };
  }
  showModal = () => {
    this.setState({modalIsShown: true});
  };
  hideModal = () => {
    this.setState({modalIsShown: false});
  };
  deleteFinTerm = () => {
    if (this.props.id) {
      this.props.onDeleteFinTerm(this.props.finTermIndex);
    } else {
      this.props.onSetStatusDeleteFinTerm(this.props.finTermIndex);
    }
    this.hideModal();
  };
  render() {
    const name = this.props.id ? concatIdName(this.props.id, text.finTermTitle) : text.newFinTermTitle;
    return (
      <>
        <BtnDel id={groupFinTermEditPageIds.buttonDeleteFinTerm(this.props.finTermIndex)} onClick={this.showModal}/>
        {this.state.modalIsShown ? (
          <NModal
            modalTitle={text.deleteFinTermModalTitle}
            modalActionBtnText={text.btnDel}
            modalCancelBtnText={text.cancel}
            onModalCancelBtnClick={this.hideModal}
            onModalActionBtnClick={this.deleteFinTerm}
            isModalDocumentEdit={true}
          >
            <NHeading2 addClasses={'mt_3du mb_3du'}>
              {text.btnDel + " "}
              <span className={"NHeading2 NHeading2__span-link-color"}>{name}</span>
              {"?"}
            </NHeading2>
          </NModal>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    id: state.organization.item.finTermsEdit[ownProps.finTermIndex] ?
      state.organization.item.finTermsEdit[ownProps.finTermIndex].id : null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDeleteFinTerm: (finTermIndex) => dispatch({
      type: finTermsEditActions.FIN_TERM_DELETE, value: finTermIndex}),
    onSetStatusDeleteFinTerm: (finTermIndex) => dispatch({
      type: finTermsEditActions.FIN_TERM_STATUS_DELETE_SET, value: finTermIndex}),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteFinTerm);    