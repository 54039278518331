//@flow
import * as React from 'react';
import {connect} from "react-redux";
import text from "../../../../../../services/localization/text";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import {
  onInitActTemplatesEdit,
  onSetActTemplatesEdit,
  onSetDataToValidationState
} from "../../../../../../services/flowTypes/propFnFlowTypes";
import actTemplatesEditActions
  from "../../../../../../store/organization/item/actTemplatesEdit/actTemplatesEditActions";
import type {
  actTemplateName,
  agentIdIsNotCompliedWIthPaynetParams,
  currency,
  groupFinTerm,
  isNotCompliedWithPaynetParams,
  isVerified,
  itemDate,
  lastValidReportDate,
  payoutPeriod,
  reportTotalExpression,
  userId
} from "../../../../../../services/flowTypes/dataFlowTypes";
import api from "../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import type {
  errorObject,
  history,
  isReadyToRender, locationType,
  match
} from "../../../../../../services/flowTypes/appFlowTypes";
import ActTemplatesFormGroup from "../ActTemplatesEdit/actTemplatesEdit/ActTemplateFormGroup";
import '../ActTemplatesEdit/ActTemplateEdit.css';
import FinTermsSelectContainer from "../ActTemplatesEdit/actTemplatesEdit/FinTermsSelect/FinTermsSelectContainer";
import GroupFinTermsViewFetcher
  from "../ActTemplatesView/actTemplateView/GroupFinTermsViewFetcher/GroupFinTermsViewFetcher";
import ActTemplateDocument from "../ActTemplatesEdit/actTemplatesEdit/ActTemplateDocument";
import validationActions from "../../../../../../store/validation/validationActions";
import {actTemplatesEditPageIds} from "../../../../../../tests/testIds";
import type {actTemplateIndex} from "../../../../../../services/flowTypes/componentFlowTypes";
import GetDataOnMountAndUpdate
  from "../../../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import ReportTagsExpressions from "../ActTemplatesEdit/actTemplatesEdit/ReportTagsExpressions";
import VerifiedStatusLabel from "../../../../../../components/composed/VerifiedStatusLabel/VerifiedStatusLabel";
import AddendumTemplateFile from "../ActTemplatesEdit/actTemplatesEdit/AddendumTemplateFile";
import clientError404 from "../../../../../../services/apiInstance/clientError404";
import PropValueView from "../../../../../../components/composed/PropValueView/PropValueView";
import AddServicesFetcher from "../ActTemplatesView/actTemplateView/AddServicesFetcher/AddServicesFetcher";
import ForbidAddServiceActTemplatePage from "./ForbidAddServiceActTemplatePage";
import AlertPaynetSync from "../../../../../../components/simple/Containers/AlertCard/AlertPaynetSync";


type Props = {
  onInitActTemplatesEdit: onInitActTemplatesEdit,
  onSetActTemplatesEdit: onSetActTemplatesEdit,
  name: actTemplateName,
  groupFinTermsView: Array<groupFinTerm>,
  onSetDataToValidationState: onSetDataToValidationState,
  history: history,
  match: match,
  actTemplateIndex: actTemplateIndex,
  reportTotalExpression: reportTotalExpression,
  location: locationType,
  isVerified: isVerified,
  isVerifiedByUserId: userId,
  isVerifiedAtDate: itemDate,
  currency: currency,
  payoutPeriod: payoutPeriod,
  addServiceIdsLength: number,
  isNotCompliedWithPaynetParams?: isNotCompliedWithPaynetParams,
  agentIdIsNotCompliedWIthPaynetParams?: agentIdIsNotCompliedWIthPaynetParams,
  lastValidReportDate?: lastValidReportDate
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  readonly: boolean
};
class ActTemplateView extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      readonly: true
    };
  }
  getActTemplateForView = () => {
    this.props.onInitActTemplatesEdit();
    this.props.onSetDataToValidationState();
    const actTemplateId = +this.props.match.params.actTemplateId;
    if (actTemplateId) {
      const contractId = +this.props.match.params.id;
      api.getActTemplates(contractId)
        .then(response => {
          const actTemplate = response.data.find(item => item.id === actTemplateId);
          if (actTemplate) {
            this.props.onSetActTemplatesEdit(actTemplate);
            this.setState({isReadyToRender: true});
          } else {
            this.setState({
              isReadyToRender: true,
              errorObject: apiErrorHandler(clientError404)
            });
          }
        })
        .catch(error => {
          this.setState({
            isReadyToRender: true,
            errorObject: apiErrorHandler(error)
          });
        });
    } else {
      this.setState({isReadyToRender: true});
    }
  };
  render() {
    const actTemplateId = this.props.match.params.actTemplateId ? +this.props.match.params.actTemplateId : null;
    const contractId = +this.props.match.params.id;
    const organizationId = +this.props.match.params.organizationId;
    return (
      <ForbidAddServiceActTemplatePage actTemplateId={actTemplateId} contractId={contractId}
                                       organizationId={organizationId} history={this.props.history}
                                       addServiceIdsLength={this.props.addServiceIdsLength}
      >
        <GroupFinTermsViewFetcher contractId={contractId} >
          <AddServicesFetcher contractId={contractId}>
            <GetDataOnMountAndUpdate itemId={actTemplateId} getDataOnMountAndUpdate={this.getActTemplateForView}
                                     fnOnUnmount={this.props.onInitActTemplatesEdit}>
              <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
                <div className="ActTemplateView">
                  <NCard addClasses={'mb_3du'}>
                    {this.props.isNotCompliedWithPaynetParams ? (
                      <AlertPaynetSync addClasses={"mb_3du"} isActTemplateMode={true}
                                       agentIdIsNotCompliedWIthPaynetParams={this.props.agentIdIsNotCompliedWIthPaynetParams}
                                       lastValidReportDate={this.props.lastValidReportDate}
                                       organizationId={organizationId}/>
                    ) : null}
                    <ActTemplatesFormGroup readonly={this.state.readonly} formFieldType={'input'} propName={'name'}
                                           label={text.name}
                                           idForTest={actTemplatesEditPageIds.inputActTemplateName}/>
                    <input type="hidden" id={actTemplatesEditPageIds.inputHiddenActTemplateId} value={actTemplateId}/>
                    {this.props.currency !== undefined || this.props.payoutPeriod !== undefined ? (
                      <div className="flex_jc-sb mt_2du">
                        {this.props.currency !== undefined ? (
                          <div className="w_half-card">
                            <PropValueView label={text.currency} value={this.props.currency}/>
                          </div>
                        ) : null}
                        {this.props.payoutPeriod !== undefined ? (
                          <div className="w_half-card">
                            <PropValueView label={text.payoutPeriod} value={text[this.props.payoutPeriod]}/>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    <VerifiedStatusLabel isVerified={this.props.isVerified}
                                         isVerifiedAtDate={this.props.isVerifiedAtDate}
                                         isVerifiedByUserId={this.props.isVerifiedByUserId} addClasses={'flex mt_2du'}/>
                  </NCard>
                  <FinTermsSelectContainer readonly={this.state.readonly} contractId={contractId} organizationId={organizationId}
                                  actTemplateId={actTemplateId}/>
                  <ReportTagsExpressions readonly={this.state.readonly} history={this.props.history}/>
                  <ActTemplateDocument readonly={this.state.readonly} actTemplateId={actTemplateId} contractId={contractId}/>
                  <AddendumTemplateFile readonly={this.state.readonly} actTemplateId={actTemplateId} contractId={contractId}/>
                </div>
              </RenderOnReady>
            </GetDataOnMountAndUpdate>
          </AddServicesFetcher>
        </GroupFinTermsViewFetcher>
      </ForbidAddServiceActTemplatePage>

    )
  }
}

const mapStateToProps = state => {
  return {
    isNotCompliedWithPaynetParams: state.organization.item.actTemplatesEdit.isNotCompliedWithPaynetParams,
    agentIdIsNotCompliedWIthPaynetParams: state.organization.item.actTemplatesEdit.agentIdIsNotCompliedWIthPaynetParams,
    name: state.organization.item.actTemplatesEdit.name,
    addServiceIdsLength: state.organization.item.actTemplatesEdit.addServiceIds ?
    state.organization.item.actTemplatesEdit.addServiceIds.length : 0,
    currency: state.organization.item.actTemplatesEdit.currency,
    payoutPeriod: state.organization.item.actTemplatesEdit.payoutPeriod,
    isVerified: state.organization.item.actTemplatesEdit.isVerified,
    isVerifiedByUserId: state.organization.item.actTemplatesEdit.isVerifiedByUserId,
    isVerifiedAtDate: state.organization.item.actTemplatesEdit.isVerifiedAtDate,
    reportTotalExpression: state.organization.item.actTemplatesEdit.reportTotalExpression,
    lastValidReportDate: state.organization.item.actTemplatesEdit.lastValidReportDate,
    groupFinTermsView: state.organization.item.groupFinTermsView
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitActTemplatesEdit: () => dispatch({type: actTemplatesEditActions.ACT_TEMPLATES_EDIT_INIT}),
    onSetActTemplatesEdit: (actTemplate) => dispatch({
    type: actTemplatesEditActions.ACT_TEMPLATES_EDIT_SET, value: actTemplate}),
    onSetDataToValidationState: (data) => dispatch({
      type: validationActions.VALIDATION_STATE_SET,
      value: data})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActTemplateView);