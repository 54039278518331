//@flow
import * as React from 'react';
import templateParamsActions
  from "../../../../../../../../../store/organization/item/templateParams/templateParamsActions";
import {connect} from "react-redux";
import type {
  actTemplateParamName, convertedTag,
  currency,
  variableName
} from "../../../../../../../../../services/flowTypes/dataFlowTypes";
import type {id, modalIsShown} from "../../../../../../../../../services/flowTypes/appFlowTypes";
import GetConvertedTagModal from "./GetConvertedTagModal";
import ConvertTagBtn from "../../../../../../../../../components/simple/Buttons/ConvertTagBtn/ConvertTagBtn";
import type {editMode} from "../../../../../../../../../services/flowTypes/componentFlowTypes";
import EditConvertedTagBtn
  from "../../../../../../../../../components/simple/Buttons/EditConvertedTagBtn/EditConvertedTagBtn";
import type {addClasses} from "../../../../../../../../../services/flowTypes/uiKitFlowTypes";

type Props = {
  groupFinTermCurrency: currency,
  paramName: actTemplateParamName,
  variableName: variableName,
  id: id,
  editMode?: editMode,
  convertedTag?: convertedTag,
  addClasses?: addClasses
};
type State = {
  modalIsShown: modalIsShown
};

class GetConvertedTagBtn extends React.Component <Props, State>{
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false
    };
  }
  showModal = () => {this.setState({modalIsShown: true})};
  hideModal = () => {this.setState({modalIsShown: false})};
  render() {
    return (
      <>
        {this.props.editMode ? (
          <EditConvertedTagBtn onClick={this.showModal} id={this.props.id} addClasses={this.props.addClasses}/>
        ) : (
          <ConvertTagBtn onClick={this.showModal} id={this.props.id} addClasses={this.props.addClasses}/>
        )}

        {this.state.modalIsShown ? (
          <GetConvertedTagModal paramName={this.props.paramName} cancelModal={this.hideModal}
                                editMode={this.props.editMode} convertedTag={this.props.convertedTag}
                                groupFinTermCurrency={this.props.groupFinTermCurrency} variableName={this.props.variableName}/>
        ) : null}
      </>

    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitTemplateParams: () => dispatch({type: templateParamsActions.TEMPLATE_PARAMS_INIT}),
    onGetTemplateParams: (templateParams) => dispatch({type: templateParamsActions.TEMPLATE_PARAMS_SET, value: templateParams})
  };
};

export default connect(null, mapDispatchToProps)(GetConvertedTagBtn);
