import * as React from 'react';
import BtnEditAct from "../../simple/Buttons/BtnEditAct/BtnEditAct";
import GroupFinTermCardParamsRow from "../GroupFinTermCardParamsRow/GroupFinTermCardParamsRow";
import {permissionsStrValues} from "../../../configData/permissionsStrValues";

const finTerms = [
  {
    fileId: "",
    id: 142,
    params: [[{name: "ratePercent", value: "0.65"}, {name: "rateMin"}, {name: "rateAbs", value: "35.00"}]],
    startDate: "2016-01-01"
  }
];

const groupFinTermCardParamRows = finTerms.map((finTerm, i) => {
  return (<GroupFinTermCardParamsRow key={'finTerm' + i} finTerm={finTerm} type={"BYRATE"}/>)
});
export const handledGroupFinTerm = {
  linkTo: '/linkTo',
  linkToEdit: '/linkToEdit',
  linkToHistory: '/linkToHistory',
  groupId: 2,
  groupFinTermCardParamRows: groupFinTermCardParamRows,
  transactionTypeLabel: 'SALE',
  transactionStatusLabel: 'APPROVED',
  payerName: 'Payer',
  payeeName: 'Payee',
  groupFinTermIdName: '#44 Actual Fin Terms',
  currencyLabel: (<span><span>&euro;</span> {" EUR"}</span> ),
  payoutPeriodLabel: 'Ежемесячно',
  groupFinTermSourceLabel: 'PPG (EU)',
  groupFinTermTypeLabel: 'Фиксированная',
  linkToGroupFinTermId: 'someId',
  buttonEditGroupFinTermId: 'buttonEditGroupFinTermId',
  buttonDeleteGroupFinTermId: 'buttonDeleteGroupFinTermId',
  permittedActionObj: permissionsStrValues.finTerm,
  onBtnDelClick: () => {}
};
export const GroupFinTermBlockDefProps = {
  rightTopComponent: <BtnEditAct>{'Link to Something'}</BtnEditAct>,
  handledGroupFinTerm: handledGroupFinTerm
};