//@flow
import * as React from 'react';
import type {selectOptions} from "../../../../../../../services/flowTypes/componentFlowTypes";
import api from "../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import GetDataOnMountAndUpdate
  from "../../../../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import {connect} from "react-redux";
import actTemplatesViewActions
  from "../../../../../../../store/organization/item/actTemplatesView/actTemplatesViewActions";
import type {onSetActTemplatesView} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import type {contractId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {errorObject, isReadyToRender} from "../../../../../../../services/flowTypes/appFlowTypes";

type Props = {
  contractId: contractId,
  onSetActTemplatesView: onSetActTemplatesView
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  actTemplateIdOptions: selectOptions
};

class GetActTemplates extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null
    };
  }
  getActTemplates = () => {
    api.getActTemplates(this.props.contractId)
      .then(response => {
        this.props.onSetActTemplatesView(response.data);
        this.setState({isReadyToRender: true});
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render(){

    return (
      <GetDataOnMountAndUpdate itemId={this.props.contractId} getDataOnMountAndUpdate={this.getActTemplates}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
          {this.props.children}
        </RenderOnReady>
      </GetDataOnMountAndUpdate>

    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onSetActTemplatesView: (actTemplates) => dispatch({
      type: actTemplatesViewActions.ACT_TEMPLATES_VIEW_SET, value: actTemplates})
  };
};
export default connect(null, mapDispatchToProps)(GetActTemplates);