//@flow
import * as React from 'react';
import {addServicesPageIds} from "../../../../../../../tests/testIds";
import text from "../../../../../../../services/localization/text";
import Can from "../../../../../../../services/permissions/Can";
import type {contractId, organizationId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import {Link} from "react-router-dom";
import {
  contrPath,
  newAddService_typeSuffix,
  orgPath
} from "../../../../../../../services/urlStrings/urlStrings";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";
import BtnNewItem from "../../../../../../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  contractId: contractId,
  organizationId: organizationId
};

const ButtonNewAddService = (props:Props) => {
  const linkTo = orgPath(props.organizationId) + contrPath(props.contractId) + newAddService_typeSuffix;
  return (
    <Can do={'usePermission'} on={permissionsStrValues.addService.create}>
      <Link to={linkTo} className={"button-wrapper-link"} id={addServicesPageIds.buttonNewAddService}>
        <BtnNewItem>{text.btnNewAddService}</BtnNewItem>
      </Link>
    </Can>
  );
};

export default ButtonNewAddService;