//@flow
import * as React from 'react';
import {connect} from "react-redux";
import text from "../../../../services/localization/text";
import Text1 from "../../../../components/simple/TextComponents/Text1/Text1";
import type {
  propName,
  propValue,
  selectedOption,
  selectOptions
} from "../../../../services/flowTypes/componentFlowTypes";
import BorderlessSelect from "../../../../components/simple/FormComponents/BorderlessSelect/BorderlessSelect";
import reportsSearchActionTypes from "../../../../store/organization/reportsSearch/reportsSearchActions";
import type {onUpdateReportsSearchProp} from "../../../../services/flowTypes/propFnFlowTypes";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {reportsSearch} from "../../../../services/flowTypes/dataFlowTypes";

type Props = {
  propName: propName,
  onUpdateReportsSearchProp: onUpdateReportsSearchProp,
  selectOptions: selectOptions,
  idForTest?: id,
  inputIdForTest?: id,
  reportsSearch: reportsSearch,
  initValue?: propValue
};
type selectedOptionType = selectedOption;

class ReportsSeparateFilterFormGroup extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  handleSelect = (selectedOption:selectedOptionType) => {
    const value = selectedOption === null ? null : selectedOption.value;
    const reportsSearchObj = {
      offset: 0,
      [this.props.propName]: value
    };
    this.props.onUpdateReportsSearchProp(reportsSearchObj);
  };
  componentDidMount() {
    if (this.props.initValue) {
      const reportsSearchObj = {
        offset: 0,
        [this.props.propName]: this.props.initValue
      };
      this.props.onUpdateReportsSearchProp(reportsSearchObj);
    }
  }

  render() {
    // !only select is implemented, because task concerns only payer and payee
    const propValue = this.props.reportsSearch.hasOwnProperty(this.props.propName) ?
      this.props.reportsSearch[this.props.propName] : null;
    const selectedValue = this.props.selectOptions.find(item => item.value === propValue);
    return (
      <div className="ReportsSeparateFilterFormGroup">
        <Text1 addClasses={'Text1_card-label-color Text1_uppercase Text1_bold'}>{text.filtersLabels[this.props.propName] + ":"}</Text1>
        <BorderlessSelect value={selectedValue}
                          onChange={this.handleSelect}
                          isSearchable={true}
                          options={this.props.selectOptions}
                          id={this.props.idForTest}
                          inputId={this.props.inputIdForTest}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    reportsSearch: state.organization.reportsSearch
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateReportsSearchProp: (reportsSearchObj) => dispatch({type: reportsSearchActionTypes.REPORTS_SEARCH_PROP_UPDATE,
      value: reportsSearchObj}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsSeparateFilterFormGroup);    