const clientError404 = {
  response: {
    status: 404,
    data: {
      error: {
        errorParams: [{errorCode: 404, errorMessage: "Not found"}]
      }
    }
  }
};
export default clientError404;