//@flow
import * as React from 'react';
import * as ReactDOM from "react-dom";
import './Spinner.css';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";

type Props = {
  addClasses?: addClasses
};

class Spinner extends React.Component <Props, void> {
  render() {
    const Spinner = (
      <div className={baseClass("Spinner ", this.props.addClasses)}>
        <div className="Spinner__balls">
          <span className="ball-1"/>
          <span className="ball-2"/>
          <span className="ball-3"/>
          <span className="ball-4"/>
          <span className="ball-5"/>
          <span className="ball-6"/>
          <span className="ball-7"/>
          <span className="ball-8"/>
        </div>
      </div>
    );
    const modal = document.getElementById('modal');
    if (modal) {
      return ReactDOM.createPortal(Spinner, modal);
    } else {
      return null;
    }
  }
}

export default Spinner;    