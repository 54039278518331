import * as React from 'react';
import UnviewedMark from "../../Icons/UnviewedMark/UnviewedMark";

export const FilterFlagsDefProps = {
  flagsList: ['flag1', 'flag2','flag3'],
  filtersState: {flag1: true, flag2: false, flag3: false},
  onChange: ()=> {},
  flagsUnviewedMarks: [
    {flag: 'flag1', component: (<UnviewedMark isUnviewed={true}/>)},
    {flag: 'flag2', component: (<UnviewedMark isUnviewed={true}/>)},
    {flag: 'flag3', component: (<UnviewedMark isUnviewed={true}/>)}
  ]
};