//@flow
import * as React from 'react';
import type {children} from "../../../services/flowTypes/appFlowTypes";
import validationActions from "../../../store/validation/validationActions";
import {connect} from "react-redux";
import type {onSetDataToValidationState} from "../../../services/flowTypes/propFnFlowTypes";
import RenderOnReady from "../RenderOnReady/RenderOnReady";

type Props = {
  children: children,
  onSetDataToValidationState: onSetDataToValidationState
};

class CleanValidationStateOnUnmount extends React.Component <Props> {
  componentWillUnmount() {
    this.props.onSetDataToValidationState({});
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={true}/>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetDataToValidationState: (data) => dispatch({
      type: validationActions.VALIDATION_STATE_SET,
      value: data})
  };
};

export default connect(null, mapDispatchToProps)(CleanValidationStateOnUnmount);