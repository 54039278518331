//@flow
import * as React from 'react';
import type {errorObject, isReadyToRender, modalIsShown} from "../../../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {cancelModal, onGetContractLinkedContracts} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import type {contractId, contractName, contractStatus} from "../../../../../../../services/flowTypes/dataFlowTypes";
import {connect} from "react-redux";
import api from "../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import NModal from "../../../../../../../components/simple/Modals/NModal/NModal";
import text from "../../../../../../../services/localization/text";
import concatIdName from "../../../../../../../utils/concatIdName";
import contractLinkedContractsActions
  from "../../../../../../../store/organization/item/contractLinkedContracts/contractLinkedContractsActions";
import NHeading2 from "../../../../../../../components/simple/TextComponents/NHeading2/NHeading2";

type Props = {
  modalIsShown: modalIsShown,
  cancelModal: cancelModal,
  contractIdToDelete: contractId,
  contractNameToDelete: contractName,
  contractId: contractId,
  onGetContractLinkedContracts: onGetContractLinkedContracts,
  status: contractStatus
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};

class DeleteLinkedContractModal extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true
    };
  }
  deleteContractLinkedContract = () => {
    this.setState({isReadyToRender: false});
    api.deleteContractLinkedContract(this.props.contractId, this.props.contractIdToDelete, this.props.status)
      .then(response => {
        this.props.onGetContractLinkedContracts(response.data);
        this.setState({isReadyToRender: true});
        this.props.cancelModal();
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    const contractName = concatIdName(this.props.contractIdToDelete, this.props.contractNameToDelete);
    return (
      this.props.modalIsShown ? (
        <>
          <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
          <NModal
            modalTitle={text.deleteLinkedContractModal}
            onModalActionBtnClick={this.deleteContractLinkedContract}
            onModalCancelBtnClick={this.props.cancelModal}
            modalActionBtnText={text.btnDel}
            modalCancelBtnText={text.cancel}
            isModalDocumentEdit={true}
          >
            <NHeading2 addClasses={'mt_3du mb_3du'}>
              {text.deleteLinkedContractModalText1}
              <span className={"NHeading2 NHeading2__span-link-color"}>{contractName}</span>
              {text.deleteLinkedContractModalText2}
            </NHeading2>
          </NModal>
        </>) : null
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetContractLinkedContracts: (contractLinkedContracts) => dispatch({
      type: contractLinkedContractsActions.CONTRACT_LINKED_CONTRACTS_SET, value: contractLinkedContracts})
  };
};

export default connect(null, mapDispatchToProps)(DeleteLinkedContractModal);