//@flow
import * as React from 'react';
import SearchPage from "../../components/composed/SearchPage/SearchPage";
import text from "../../services/localization/text";
import {Link} from "react-router-dom";
import type {history, isReadyToRender, locationType, match} from "../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../components/service/RenderOnReady/RenderOnReady";
import searchRequestActions from "../../store/organization/searchRequest/searchRequestActions";
import connect from "react-redux/es/connect/connect";
import {onGetSearchRequestProp, onInitSearchRequestState} from "../../services/flowTypes/propFnFlowTypes";
import {contractsPageIds} from "../../tests/testIds";
import Can from "../../services/permissions/Can";
import {
  contractsSuffix,
  newContractSuffix,
  orgPath
} from "../../services/urlStrings/urlStrings";
import {permissionsStrValues} from "../../configData/permissionsStrValues";
import BtnNewItem from "../../components/simple/Buttons/BtnNewItem/BtnNewItem";
import ability from "../../services/permissions/permissions";
import PrivateRoute from "../../components/service/Routes/PrivateRoute";

type Props = {
  match: match,
  onGetSearchRequestProp: onGetSearchRequestProp,
  onInitSearchRequestState: onInitSearchRequestState,
  history: history,
  location: locationType
};
type State = {
  isReadyToRender: isReadyToRender,
};

class Contracts extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false
    };
  }
  componentDidMount(){
    //TODO: consider to unite these two functions in single
    this.props.onInitSearchRequestState();
    const searchRequestObj = {
      offset: 0,
      category: 'contracts',
      searchPropName: 'contractNo'
    };
    this.props.onGetSearchRequestProp(searchRequestObj);
    this.setState({isReadyToRender: true});
  }
  render(){
    const organizationId = this.props.match.params.organizationId;
    const pageTitle = text.contracts;
    const button = () => {
      return (
        <Can do={'usePermission'} on={permissionsStrValues.contract.create} >
          <Link to={orgPath(organizationId) + newContractSuffix} data-ui-test-id={contractsPageIds.buttonNewContract}>
            <BtnNewItem id={contractsPageIds.buttonNewContract} addClasses={"BtnNewItem_grey"}>
              {text.newContract}</BtnNewItem>
          </Link>
        </Can>
      );
    };
    const routedButton = (
      <PrivateRoute exact path={orgPath(':organizationId') + contractsSuffix} key={'newContractBtn'}
                    component={button}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.contract.create)} />
    );
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
        <SearchPage
          pageTitle={pageTitle}
          button={routedButton}
          history={this.props.history}
          pathname={this.props.location.pathname}
        />
      </RenderOnReady>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetSearchRequestProp: (searchRequestObj) => dispatch({type: searchRequestActions.SEARCH_REQUEST_PROP_SET,
      value: searchRequestObj}),
    onInitSearchRequestState: () => dispatch({type: searchRequestActions.SEARCH_REQUEST_STATE_INIT})
  };
};
export default connect(null, mapDispatchToProps)(Contracts);