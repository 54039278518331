//@flow
import * as React from 'react';
import text from "../../../services/localization/text";
import Text1 from "../../simple/TextComponents/Text1/Text1";
import {connect} from "react-redux";
import type {organizationId} from "../../../services/flowTypes/dataFlowTypes";
import {mainDataSuffix, orgPath, reportPathView} from "../../../services/urlStrings/urlStrings";
import Heading4 from "../../simple/TextComponents/Heading4/Heading4";
import type {errorObject} from "../../../services/flowTypes/appFlowTypes";

type Props = {
  errorObject: errorObject,
  organizationId: organizationId
};

class AlertCardErrorBody extends React.Component <Props> {
  render(){
    const errorParams = this.props.errorObject.params ? (
      this.props.errorObject.params.join(' ')
    ) : null;
    return (
      <>
        {this.props.errorObject.title ? (
          <div className="flex mb_1du">
            <Heading4 addClasses={'Heading4_lh16 mr_2du'}>{'Status:'}</Heading4>
            <Text1>{this.props.errorObject.title}</Text1>
          </div>
        ) : null}
        {this.props.errorObject.clientError ? (
          <>
            <div className="mb_2du">
              <a href={"/"} className={'BtnEditAct'}>
                {text.goToMainPage}</a>
            </div>
            <div className="flex mb_1du">
              <Heading4 addClasses={'Heading4_lh16 mr_2du'}>{'Frontend Error:'}</Heading4>
              <Text1>{this.props.errorObject.clientError}</Text1>
            </div>
          </>
        ) : null}
        {this.props.errorObject.errorId ? (
          <div className="flex mb_1du">
            <Heading4 addClasses={'Heading4_lh16 mr_2du'}>{'Error Id:'}</Heading4>
            <Text1>{this.props.errorObject.errorId}</Text1>
          </div>
        ) : null}
        { (this.props.errorObject.msg && this.props.errorObject.msg.length) ? (
          this.props.errorObject.msg.map((item, i) => {
            return (<Text1 key={'msg'+i}>{item}</Text1>)
          })
        ) : null}
        {(this.props.errorObject.localizedMsg && this.props.errorObject.localizedMsg) ? (
          this.props.errorObject.localizedMsg.map((item, i) => {
            return (<Text1 key={'localizedMsg'+i}>{item}</Text1>);
          })
        ) : null}
        {errorParams ? (
          <div className="flex mb_1du">
            <Heading4 addClasses={'Heading4_lh16 mr_2du'}>{'Error Description:'}</Heading4>
            <Text1>{errorParams}</Text1>
          </div>
        ) : null}
        {this.props.errorObject.reportId ? (
          <a href={"#"+ orgPath(this.props.organizationId) + reportPathView(this.props.errorObject.reportId)
          + mainDataSuffix} className={'BtnEditAct'}>
            {text.goToPreparedReport + ` #${this.props.errorObject.reportId}`}</a>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    organizationId: state.organization.organizationId
  };
};
export default connect(mapStateToProps, null)(AlertCardErrorBody);
