//@flow
import * as React from 'react';
import {connect} from "react-redux";
import FullSizeButton from "../../simple/Buttons/FullSizeButton/FullSizeButton";
import text from "../../../services/localization/text";
import sheetsActions from "../../../store/organization/item/sheets/sheetsActions";
import type {onAddSheet} from "../../../services/flowTypes/propFnFlowTypes";
import {newSheet} from "../../../pages/Contracts/pages/Contract/pages/AddServiceEdit/AddServiceSheetsEdit";
import {sheetIds} from "../../../tests/testIds";

type Props = {
  onAddSheet: onAddSheet
};

class AddSheetButton extends React.Component <Props> {
  handleAddSheetButtonClick = () => {
    this.props.onAddSheet(newSheet);
  };
  render() {
    return (
      <FullSizeButton onClick={this.handleAddSheetButtonClick} id={sheetIds.buttonAddSheet}>
          {text.addSheetButtonLabel}</FullSizeButton>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAddSheet: (value) => dispatch({type: sheetsActions.SHEET_ADD, value})
  };
};
export default connect(null, mapDispatchToProps)(AddSheetButton);