//@flow
import * as React from 'react';
import {connect} from "react-redux";
import Can from "../../../../../../../services/permissions/Can";
import type {errorObject, isReadyToRender, modalIsShown} from "../../../../../../../services/flowTypes/appFlowTypes";
import type {
  agentStatus,
  contractAgents,
  contractId
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import contractAgentsActions from "../../../../../../../store/organization/item/contractAgents/contractAgentsActions";
import api from "../../../../../../../services/apiInstance/api";
import text from "../../../../../../../services/localization/text";
import {onGetContractAgents} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import {contractAgentsPageIds} from "../../../../../../../tests/testIds";
import AddItemsModal from "../../../../../../../components/composed/AddItemsModal/AddItemsModal";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";
import BtnNewItem from "../../../../../../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  contractId: contractId,
  onGetContractAgents: onGetContractAgents,
  contractAgents: contractAgents,
  status: agentStatus
};
type State = {
  modalIsShown: modalIsShown,
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};

class ButtonAddAgents extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false,
      errorObject: null,
      isReadyToRender: true
    };
  }
  showModal = () => {
    this.setState({
      modalIsShown: true
    });
  };
  hideModal = () => {
    this.setState({
      isReadyToRender: true,
      modalIsShown: false
    });
  };
  getCheckedListItems = (listItemsIds) => {
    this.setState({isReadyToRender: false});
    api.addContractAgents(this.props.contractId, listItemsIds, this.props.status)
      .then(response => {
        this.props.onGetContractAgents(response.data);
        this.hideModal();
      })
      .catch(error => {
        this.hideModal();
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    const linkedItemsIds = this.props.contractAgents ? this.props.contractAgents.map(item => item.id) : [];
    return (
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <Can do={'usePermission'} on={permissionsStrValues.contractLinkedAgent.create}>
          <BtnNewItem id={contractAgentsPageIds.buttonAddRelatedAgent} onClick={this.showModal}>{text.btnAdd}</BtnNewItem>
        </Can>
        {this.state.modalIsShown ? (
          <AddItemsModal
            getCheckedListItems={this.getCheckedListItems} linkedItemsIds={linkedItemsIds}
            history={this.props.history} category={'agents'} searchPropName={'name'}
            hideModal={this.hideModal} modalTitle={text.relatedAgentsModalTitle}/>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    status: state.organization.linkedItemsRequestParams.hasOwnProperty('status') ?
      state.organization.linkedItemsRequestParams.status : null,
    contractAgents: state.organization.item.contractAgents
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetContractAgents: (contractAgents) => dispatch({
      type: contractAgentsActions.CONTRACT_AGENTS_SET, value: contractAgents})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ButtonAddAgents);    