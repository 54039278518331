//@flow
import * as React from 'react';
import type {errorObject, isReadyToRender} from "../../../../../../services/flowTypes/appFlowTypes";
import api from "../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import GetDataOnMountAndUpdate
  from "../../../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import type {agentId, agentsList, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import concatIdName from "../../../../../../utils/concatIdName";
import text from "../../../../../../services/localization/text";

type Props = {
  organizationId: organizationId,
  linkedAgentId: agentId
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  agentList: agentsList
};


class OrganizationLinkedAgentValue extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      agentList: []
    };
  }
  getOrganizationAgentsList = () => {
    api.getAgentsList(this.props.organizationId)
      .then(response => {
        this.setState({
          agentList:response.data,
          isReadyToRender: true
        });
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    const agent = this.state.agentList.find(item => item.id === this.props.linkedAgentId);
    const agentIdName = agent ? concatIdName(agent.id, agent.name) : text.linkedAgentIsNotSelected;
    return (
      this.props.organizationId ? (
        <GetDataOnMountAndUpdate itemId={this.props.organizationId} getDataOnMountAndUpdate={this.getOrganizationAgentsList}>
          <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
            <span>{agentIdName}</span>
          </RenderOnReady>
        </GetDataOnMountAndUpdate>
      ) : null
        );
  }
}

export default OrganizationLinkedAgentValue;