//@flow
import * as React from 'react';
import {connect} from "react-redux";
import contractMainDataActions
  from "../../../../../../../store/organization/item/contractMainData/contractMainDataActions";
import type {
  formFieldType, isSearchable, noOptionsMessage,
  propName,
  propValue,
  selectOptions
} from "../../../../../../../services/flowTypes/componentFlowTypes";
import {
  onGetContractMainDataProp,
  onGetValidationStateFormProp
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import type {id, label, placeholder} from "../../../../../../../services/flowTypes/appFlowTypes";
import ConnectedFormGroup from "../../../../../../../components/composed/ConnectedFormGroup/ConnectedFormGroup";
import contractMainDataValidationScheme from "../../../../../../../configData/contractMainDataValidationScheme";
import type {addClasses} from "../../../../../../../services/flowTypes/uiKitFlowTypes";
import validationActions from "../../../../../../../store/validation/validationActions";

type Props = {
  formFieldType: formFieldType,
  propName: propName,
  propValue: propValue,
  onGetContractMainDataProp: onGetContractMainDataProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  label: label,
  options?: selectOptions,
  addClasses?: addClasses,
  placeholder?: placeholder,
  noOptionsMessage?: noOptionsMessage,
  idForTest?: id,
  isSearchable?: isSearchable,
  selectInputId?: id
};

class ContractMainDataFormGroup extends React.Component <Props, void> {
  handleTerminatedStatus = () => {
    //TODO: consider to combine these call into single
    // and possibility to separate handling of terminated status into single component
    this.props.onGetContractMainDataProp('statusReason', '');
    this.props.onGetContractMainDataProp('statusDate', '');
    this.props.onGetValidationStateFormProp('statusReason', '');
    this.props.onGetValidationStateFormProp('statusDate', '');
  };
  onGetPropValue = (prop, data) => {
    if (prop === 'status' && data === 'TERMINATED') {
      this.handleTerminatedStatus();
    }
    this.props.onGetContractMainDataProp(prop, data);
  };
  render() {
    const propValidationPatternsArr = contractMainDataValidationScheme[this.props.propName] ?
      contractMainDataValidationScheme[this.props.propName] : null;
    return (
      <ConnectedFormGroup
        formFieldType={this.props.formFieldType}
        propName={this.props.propName}
        propValue={this.props.propValue}
        onGetPropValue={this.onGetPropValue}
        label={this.props.label}
        options={this.props.options}
        propValidationPatternsArr={propValidationPatternsArr}
        addClasses={this.props.addClasses}
        idForTest={this.props.idForTest}
        placeholder={this.props.placeholder}
        noOptionsMessage={this.props.noOptionsMessage}
        isSearchable={this.props.isSearchable}
        selectInputId={this.props.selectInputId}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propValue: state.organization.item.contractMainData[ownProps.propName]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetContractMainDataProp: (prop, data) => dispatch({type: contractMainDataActions.CONTRACT_MAIN_DATA_PROP_SET, value: {
        prop: prop,
        data: data
      }})

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractMainDataFormGroup);    