//@flow
import * as React from 'react';
import RenderOnReady from "../../../../components/service/RenderOnReady/RenderOnReady";
import type {
  errorObject,
  history,
  isReadyToRender,
  locationType,
  match
} from "../../../../services/flowTypes/appFlowTypes";
import type {agentId, agentName, organizationId} from "../../../../services/flowTypes/dataFlowTypes";
import connect from "react-redux/es/connect/connect";
import agentMainDataActions from "../../../../store/organization/item/agentMainData/agentMainDataActions";
import {onGetAgentMainData} from "../../../../services/flowTypes/propFnFlowTypes";
import concatIdName from "../../../../utils/concatIdName";
import api from "../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../services/apiInstance/apiErrorHandler";
import GetDataOnMountAndUpdate from "../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import FixedHeaderContent from "../../../../components/simple/Containers/FixedHeader/FixedHeaderContent";
import AgentButtonsRoutes from "../../../../components/service/Routes/AgentButtonsRoutes";
import handleCorrectOrgIdInResponse from "../../../../utils/handleCorrectOrgIdInResponse";
import RedirectToCorrectOrgUrl from "../../../../components/service/RedirectToCorrectOrgUrl/RedirectToCorrectOrgUrl";
import {
  contrPathView,
  mainDataSuffix,
  orgPath
} from "../../../../services/urlStrings/urlStrings";
import text from "../../../../services/localization/text";
import FixedSubHeader from "../../../../components/simple/Containers/FixedHeader/FixedSubHeader";
import AgentMenu from "./agent/AgentMenu/AgentMenu";

type Props = {
  match: match,
  onGetAgentMainData: onGetAgentMainData,
  agentId: agentId,
  name: agentName,
  location: locationType,
  organizationId: organizationId,
  history: history
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  correctOrganizationId: organizationId
};

class Agent extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      correctOrganizationId: null
    };
  }
  defineTitles = () => {
    if (!this.props.agentId) {
      return {headerTitle: "", headerTitleLinkTo: "", subHeaderTitle: ""};
    }
    const agentTitle = concatIdName(this.props.agentId, this.props.name);
    const agentTitleLinkTo =  orgPath(this.props.organizationId) + contrPathView(this.props.agentId) + mainDataSuffix;

    return this.props.location.pathname.includes("edit") ?
      {headerTitle: agentTitle, headerTitleLinkTo: agentTitleLinkTo, subHeaderTitle: text.editTitleLabel}
      :  {headerTitle: text.agents, headerTitleLinkTo: null, subHeaderTitle: agentTitle}
      ;
  };
  handleResponse = (responseData) => {
    this.props.onGetAgentMainData(responseData);
    this.setState({isReadyToRender: true});
  };
  getAgentMainData = () => {
    const agentId = +this.props.match.params.id;
    api.getAgentById(agentId)
      .then(response => {
        handleCorrectOrgIdInResponse(this, response.data, this.setState, this.handleResponse);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render(){
    const {headerTitle, headerTitleLinkTo, subHeaderTitle} = this.defineTitles();
    return (
      <RedirectToCorrectOrgUrl correctOrganizationId={this.state.correctOrganizationId} history={this.props.history}>
        <GetDataOnMountAndUpdate itemId={this.props.match.params.id} getDataOnMountAndUpdate={this.getAgentMainData}>
          <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
            <FixedHeaderContent title={headerTitle} info={subHeaderTitle} titleLinkTo={headerTitleLinkTo} history={this.props.history}/>
            <FixedSubHeader title={subHeaderTitle}
                            buttons={<AgentButtonsRoutes organizationId={this.props.organizationId}
                                                         agentId={this.props.agentId}/>}
                            history={this.props.history}/>
            {this.props.location.pathname.includes("edit") || this.props.location.pathname.includes("/new-") ?
              null : (
                <AgentMenu agentId={this.props.agentId} organizationId={this.props.organizationId}/>
              )}
          </RenderOnReady>
        </GetDataOnMountAndUpdate>
      </RedirectToCorrectOrgUrl>
    );
  }
}
const mapStateToProps = state => {
  return {
    organizationId: state.organization.organizationId,
    agentId: state.organization.item.agentMainData.id,
    name: state.organization.item.agentMainData.name,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetAgentMainData: (agentMainData) => dispatch({
      type: agentMainDataActions.AGENT_MAIN_DATA_SET,
      value: agentMainData})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Agent);
