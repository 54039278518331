//@flow
import * as React from 'react';
import type {spinnerIsShown} from "../../../../services/flowTypes/appFlowTypes";
import type {searchIcoIsClicked} from "../../../../services/flowTypes/componentFlowTypes";
import ConnectedOrganizationsSearchInput from "./ConnectedOrganizationsSearchInput/ConnectedOrganizationsSearchInput";
import OrganizationsFetcher from "./OrganizationsFetcher/OrganizationsFetcher";
import OrganizationsFilters from "./OrganizationsFilters";

type Props = {};
type State = {
  spinnerIsShown: spinnerIsShown,
  searchIcoIsClicked: searchIcoIsClicked
};

class OrganizationsSearchBox extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      spinnerIsShown: false,
      searchIcoIsClicked: false,
      advancedSearchIsShown: false
    };
  }
  handleResponse = () => {
    this.setState({
      spinnerIsShown: false,
      searchIcoIsClicked: false
    });
  };
  showSpinner = () => {this.setState({spinnerIsShown: true})};
  handleSearchIcoClick = () => {this.setState({searchIcoIsClicked: true})};
  render() {
    return (
      <>
        <OrganizationsFetcher
          showSpinner={this.showSpinner}
          handleResponse={this.handleResponse}
          searchIcoIsClicked={this.state.searchIcoIsClicked}
        />
        <ConnectedOrganizationsSearchInput
          handleSearchIcoClick={this.handleSearchIcoClick}
          spinnerIsShown={this.state.spinnerIsShown}
        />
        <OrganizationsFilters/>
      </>
    );
  }
}

export default OrganizationsSearchBox;    