//@flow
import * as React from 'react';
import {connect} from "react-redux";
import ability from "../../../../../../../services/permissions/permissions";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import ConnectedItemControls
  from "../../../../../../../components/composed/ConnectedItemControls/ConnectedItemControls";
import type {user, userId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {
  errorObject,
  history,
  isReadyToRender,
  stateToCompare
} from "../../../../../../../services/flowTypes/appFlowTypes";
import api from "../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import {onGetUserMainData, onUpdateNeedFetchFlagsProp} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import {
  mainDataSuffix,
  supUserPathView
} from "../../../../../../../services/urlStrings/urlStrings";
import needFetchFlagsActions from "../../../../../../../store/needFetchFlags/needFetchFlagsActions";
import userMainDataActions from "../../../../../../../store/user/userMainData/userMainDataActions";
import {globalPermissionsStrValues} from "../../../../../../../configData/permissionsStrValues";

type Props = {
  userId: userId,
  history: history,
  userMainData: user,
  onGetUserMainData: onGetUserMainData,
  onUpdateNeedFetchFlagsProp: onUpdateNeedFetchFlagsProp
};
type State = {
  stateToCompare: stateToCompare,
  isReadyToRender: isReadyToRender,
  errorObject: errorObject
};

class UserMainDataControls extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      stateToCompare: {},
      errorObject: null,
      isReadyToRender: true
    };
  }
  goToUserMainDataView = (userId) => {
    const path = supUserPathView(userId) + mainDataSuffix;
    this.props.history.push({pathname: path});
    this.props.onUpdateNeedFetchFlagsProp('usersList', true);
  };
  handleResponse = (userMainData, goBack) => {
    this.props.onGetUserMainData(userMainData);
    this.setState({isReadyToRender: true});
    if (goBack) {
      this.props.history.goBack();
    } else {
      this.goToUserMainDataView(userMainData.id);
    }
  };
  getUserById = (goBack) => {
    api.getUserById(this.props.userId)
      .then(response => {
        this.handleResponse(response.data, goBack);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      })
  };
  handleDataBeforeSend = () => {
    // const agent = this.updateParamsInAgentMainData();
    return this.props.userMainData;
  };
  requestUpdate = (user) => {
    api.updateUser(user)
      .then(response => {
        this.handleResponse(response.data);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  requestCreate = (user) => {
    api.createUser(user)
      .then(response => {
        this.handleResponse(response.data, false);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  sendRequest = () => {
    this.setState({isReadyToRender: false});
    const user = this.handleDataBeforeSend();
    if (this.props.userId === null) {
      this.requestCreate(user);
    } else {
      this.requestUpdate(user);
    }
  };
  cancel = () => {
    if (this.props.userId === null) {
      this.props.history.goBack();
    } else {
      this.setState({isReadyToRender: false});
      const goBack = true;
      this.getUserById(goBack);
    }
  };
  componentDidUpdate(prevProps){
    if ( (this.props.userId === this.props.userMainData.id &&
      prevProps.userMainData.id !== this.props.userMainData.id)
      || (!this.props.userId && prevProps.userMainData.id !== this.props.userMainData.id)
    ) {
      this.setState({stateToCompare: JSON.stringify(this.props.userMainData)});
    }
  }
  componentDidMount(){
    this.setState({stateToCompare: JSON.stringify(this.props.userMainData)})
  }
  render() {
    // const userMainData = this.updateParamsInAgentMainData();
    const userMainData = this.props.userMainData;
    //TODO: correct permissions
    const permittedAction = this.props.userId === null ? globalPermissionsStrValues.user.create : globalPermissionsStrValues.user.update;
    const actionIsPermitted = ability.can('useGlobalPermission', permittedAction);
    const submitIsDisabled = this.state.stateToCompare === JSON.stringify(userMainData);
    return(
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <ConnectedItemControls
          actionIsPermitted={actionIsPermitted}
          submitIsDisabled={submitIsDisabled}
          cancelFn={this.cancel}
          sendRequest={this.sendRequest}
        />
      </>

    );
  }
}

const mapStateToProps = state => {
  return {
    userMainData: state.user.mainData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetUserMainData: (user) => dispatch({type: userMainDataActions.USER_MAIN_DATA_SET, value: user}),
    onUpdateNeedFetchFlagsProp: (prop, needFetch) => dispatch({type: needFetchFlagsActions.NEED_FETCH_FLAGS_PROP_UPDATE, value: {
        prop: prop,
        data: needFetch
      }})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMainDataControls);    