//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  organizationId,
  searchCategory,
  searchData,
  searchPropName
} from "../../../../../services/flowTypes/dataFlowTypes";
import './SearchDropdown.css';
import concatIdName from "../../../../../utils/concatIdName";
import concatItemNumber from "../../../../../utils/concatItemNumber";
import text from "../../../../../services/localization/text";
import type {searchDropdownIsShown} from "../../../../../services/flowTypes/componentFlowTypes";
import type {history} from "../../../../../services/flowTypes/appFlowTypes";
import searchRequestActions from "../../../../../store/organization/searchRequest/searchRequestActions";
import {handleSearchResultsUpdate, onGetSearchRequestProp} from "../../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  searchData: searchData,
  searchCategory: searchCategory,
  searchPropName: searchPropName,
  organizationId: organizationId,
  searchDropdownIsShown: searchDropdownIsShown,
  history: history,
  onGetSearchRequestProp: onGetSearchRequestProp,
  handleSearchResultsUpdate: handleSearchResultsUpdate
};
//TODO: add history in props
class SearchDropdown extends React.Component <Props, void> {
  // handleMouseDown = (itemId) => {
    // this.props.history.push({pathname:
    //     orgPath(this.props.organizationId) + "/" + this.props.searchCategory + "/id/" + itemId + "/view" + mainDataSuffix});
  // };
  handleMouseDown = (item) => {
    const text = this.props.searchPropName === 'contractNo' ? item.contractNo :
                  this.props.searchPropName === 'tags' ? item : item.name;
    const searchRequestObj = {
      offset: 0,
      text
    };
    this.props.onGetSearchRequestProp(searchRequestObj);
    this.props.handleSearchResultsUpdate(true);
  };
  defineCategory = () => {
    switch (this.props.searchPropName) {
      case 'agentName':
        return 'agents';
      case 'contractName':
        return 'contracts';
      case 'name':
        return this.props.searchCategory;
      case 'contractNo':
        return 'contracts';
      case 'tags':
        return 'tags';
      default:
        return ''
    }
  };
  render() {
    const category = this.defineCategory();
    const items = this.props.searchData[category] ? this.props.searchData[category].map((item, i) => {
      const className = "SearchDropdown__list-item";
      return (
        <div key={className + i} className={className} onMouseDown={() => this.handleMouseDown(item)}>
          {this.props.searchPropName === 'contractNo' ? concatItemNumber(item.contractNo) :
            this.props.searchPropName === 'tags' ? item :
            concatIdName(item.id, item.name) + (category === 'contracts' ? " " + concatItemNumber(item.contractNo) : "")}
          {/*<span className="SearchDropdown__list-item-forward-ico"/>*/}
        </div>
      );
    }) : [];
    const searchDropdownClassName = (this.props.searchDropdownIsShown && items.length) ? "SearchDropdown"
    : "SearchDropdown SearchDropdown_hide";
    return (
      <div className="SearchDropdown__wrapper">
        <div className={searchDropdownClassName}>
          <div className="SearchDropdown__category-container">
            <div className="SearchDropdown__category">{text[category]}</div>
            <div className="SearchDropdown__list">{items}</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchData: state.organization.searchData,
    searchCategory: state.organization.searchRequest.category,
    searchPropName: state.organization.searchRequest.searchPropName,
    organizationId: state.organization.organizationId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetSearchRequestProp: (searchRequestObj) => dispatch({type: searchRequestActions.SEARCH_REQUEST_PROP_SET,
      value: searchRequestObj})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchDropdown);