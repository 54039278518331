//@flow
import * as React from 'react';
import {connect} from "react-redux";
import NCard from "../../../../../../../../../../components/simple/Containers/NCard/NCard";
import FinTermName from "./finTerm/FinTermName";
import type {
  finTermIndex, finTermParamsLength
} from "../../../../../../../../../../services/flowTypes/componentFlowTypes";
import DeleteFinTerm from "./finTerm/DeleteFinTerm";
import FinTermDate from "./finTerm/FinTermDate";
import FinTermDocument from "./finTerm/FinTermDocument";
import FinTermParamsRow from "./finTerm/FinTermParamsRow/FinTermParamsRow";
import type {
  contractId,
  finTermStatus,
  groupFinTermType
} from "../../../../../../../../../../services/flowTypes/dataFlowTypes";
import AddFinTermParamsRow from "./finTerm/AddFinTermParamsRow";
import FinTermDateValidator from "./finTerm/FinTermDateValidator";
import './FinTerm.css';
import getUniqueKey from "../../../../../../../../../../utils/getUniqueKey";
import {
  groupFinTermTypeHasMinRateArr, minMaxParamsTypeArr,
  onlyOneRowParamsTypeArr
} from "../../../../../../../../../../configData/groupFinTermTypesFeatures";
import FinTermMinRate from "./finTerm/FinTermMinRate";
import MinMaxParamsValidator from "./finTerm/MinMaxParamsValidator";
import FinTermParamsLengthValidator from "./finTerm/FinTermParamsLengthValidator";
import AdaptiveFormContainer
  from "../../../../../../../../../../components/simple/Containers/AdaptiveFormContainer/AdaptiveFormContainer";

type Props = {
  finTermIndex: finTermIndex,
  finTermParamsLength: finTermParamsLength,
  finTermStatus: finTermStatus,
  groupFinTermType: groupFinTermType,
  readonly?: boolean,
  contractId: contractId
};

class FinTerm extends React.Component <Props, void> {
  render() {
    let finTermParamsRows = [];
    for (let i=0; i<this.props.finTermParamsLength; i++) {
      finTermParamsRows.push(
        <FinTermParamsRow
          readonly={this.props.readonly}
          key={getUniqueKey()}
          finTermIndex={this.props.finTermIndex}
          finTermParamsRowIndex={i}
        />
      );
    }
    const finTermParamsContent = (
      <>
        {this.props.groupFinTermType === 'MANUAL' ? null : finTermParamsRows}
        {!onlyOneRowParamsTypeArr.includes(this.props.groupFinTermType) && !this.props.readonly ? (
          <FinTermParamsLengthValidator finTermIndex={this.props.finTermIndex}>
            <AddFinTermParamsRow finTermIndex={this.props.finTermIndex}/>
          </FinTermParamsLengthValidator>
        ) : null}
        {groupFinTermTypeHasMinRateArr.includes(this.props.groupFinTermType) ? (
          <FinTermMinRate readonly={this.props.readonly} finTermIndex={this.props.finTermIndex}
                          groupFinTermType={this.props.groupFinTermType}/>
        ) : null}
      </>
    );
    return (
      this.props.finTermStatus === 'DELETED' ? null : (
        <NCard addClasses={'mb_1du'}>
          <div className="flex_jc-sb ai_c mb_3du">
            <FinTermName finTermIndex={this.props.finTermIndex}/>
            {this.props.readonly ? null : <DeleteFinTerm finTermIndex={this.props.finTermIndex}/>}
          </div>
          <AdaptiveFormContainer fieldsArr={[
            <FinTermDocument readonly={this.props.readonly} finTermIndex={this.props.finTermIndex}
                             contractId={this.props.contractId} key={"finTermDocument"}/>,
            <div className="flex" key={"finTermDates"}>
              <FinTermDate readonly={this.props.readonly} finTermIndex={this.props.finTermIndex} dateName={'startDate'}
                           addClasses={'mr_3du'}/>
              <FinTermDate readonly={this.props.readonly}  finTermIndex={this.props.finTermIndex} dateName={'endDate'}/>
            </div>
          ]} containerMode={"twoHalfCardWidthFields1100"} addClasses={"mb_3du"}/>
          {minMaxParamsTypeArr.includes(this.props.groupFinTermType) ? (
            <MinMaxParamsValidator finTermIndex={this.props.finTermIndex} >
              {finTermParamsContent}
            </MinMaxParamsValidator>
          ) : finTermParamsContent}
          <FinTermDateValidator finTermIndex={this.props.finTermIndex} />
        </NCard>
        )
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    finTermParamsLength: state.organization.item.finTermsEdit[ownProps.finTermIndex].params.length,
    finTermStatus: state.organization.item.finTermsEdit[ownProps.finTermIndex].status,
    groupFinTermType: state.organization.item.groupFinTermsEdit.type
  };
};
export default connect(mapStateToProps, null)(FinTerm);