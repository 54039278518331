//@flow
import * as React from 'react';
import Authorization from "./components/service/Authorization/Authorization";
import type {windowData, appData as appDataType} from "./services/flowTypes/appFlowTypes";
import TestLogin from "./pages/TestLogin/TestLogin";
import ErrorBoundary from "./components/service/ErrorBoundary/ErrorBoundary";
import GetGeneralData from "./components/service/GetGeneralData/GetGeneralData";
import SetPassPage from "./loginPages/SetPassPage/SetPassPage";
import GoogleAuthPage from "./loginPages/GoogleAuthPage/GoogleAuthPage";
import CodeLoginPage from "./loginPages/CodeLoginPage/CodeLoginPage";
import LoginPage from "./loginPages/LoginPage/LoginPage";
import AlertsContainer from "./components/composed/Alerts/AlertsContainer";



type Props = Object;
type State = {
  windowData: windowData
};
// window.SET_PASS_DATA = {csrfToken:{name: 'kdkjlk', value: 'dskfks', headerName: 'kljdfkjkd'}, key: 'kkkeeeyyy', userName: 'Aven Kain'};
// window.GOOGLE_AUTH_DATA = {csrfToken:{name: 'kdkjlk', value: 'dskfks', headerName: 'kljdfkjkd'}, key: 'kkkeeeyyy',
//   userName: 'Aven Kain',
//   qrCodeUrl: qrCodeSample
// };
// window.LOGIN_DATA = {csrfToken:{name: 'kdkjlk', value: 'dskfks', headerName: 'kljdfkjkd'}};
// window.CODE_LOGIN_DATA = {csrfToken:{name: 'kdkjlk', value: 'dskfks', headerName: 'kljdfkjkd'}};
class App extends React.Component <Props, State> {
  constructor(props:Props){
    super(props);
    this.state = {
      windowData: {
        appData: window.APP_DATA,
        // appData: undefined,
        setPassData: window.SET_PASS_DATA,
        resetPassData: window.RESET_PASS_DATA,
        loginData: window.LOGIN_DATA,
        googleAuthData: window.GOOGLE_AUTH_DATA,
        codeLoginData: window.CODE_LOGIN_DATA
      }
    };
  }
  setAppData = (appData:appDataType) => {
    const windowData = {...this.state.windowData};
    windowData.appData = appData;
    this.setState({windowData});
  };
  render() {
    return (
      <>
        <ErrorBoundary>
          <Authorization
            windowData={this.state.windowData}
            isAuthorized={<GetGeneralData/>}
            isSetPassPage={<SetPassPage/>}
            isResetPassPage={null}
            isGoogleAuthPage={<GoogleAuthPage/>}
            isLoginPage={<LoginPage/>}
            isCodeLoginPage={<CodeLoginPage/>}
            isNotAuthorized={<TestLogin setAppData={this.setAppData}/>}
          />
          
        </ErrorBoundary>
        <AlertsContainer/>
      </>
      
      );
  }
}

export default App;
