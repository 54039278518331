//@flow
import * as React from 'react';
import HandlePassPage from "../../components/composed/HandlePassPage/HandlePassPage";

type Props = {};

const SetPassPage = (props: Props) => {
  return (
    <HandlePassPage isSetOrResetPass={'setPass'}/>
  );
};

export default SetPassPage;    