//@flow
import * as React from 'react';
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {organizationId, organizationParams} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {errorObject, isReadyToRender} from "../../../../../../../services/flowTypes/appFlowTypes";
import {
  handleUpdateResponse,
  onSetOrganizationParams,
  reloadOrganizationParams
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import api from "../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import type {needUpdateOrganizationParams} from "../../../../../../../services/flowTypes/componentFlowTypes";
import organizationParamsActions
  from "../../../../../../../store/organization/organizationParams/organizationParamsActions";
import {connect} from "react-redux";

type Props = {
  organizationParams: organizationParams,
  onSetOrganizationParams: onSetOrganizationParams,
  needUpdateOrganizationParams: needUpdateOrganizationParams,
  handleUpdateResponse: handleUpdateResponse,
  organizationId: organizationId,
  reloadOrganizationParams: reloadOrganizationParams
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
};

class OrganizationParamsUpdater extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true
    };
  }
  updateOrganizationParams = () => {
    this.setState({isReadyToRender: false});
    api.updateOrganizationParams(this.props.organizationId, this.props.organizationParams)
      .then(response => {
        this.props.onSetOrganizationParams(response.data);
        this.setState({isReadyToRender: true});
        this.props.handleUpdateResponse();
      })
      .catch(error => {
        this.props.reloadOrganizationParams();
        this.props.handleUpdateResponse();
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  componentDidUpdate(prevProps){
    if (prevProps.needUpdateOrganizationParams !== this.props.needUpdateOrganizationParams && this.props.needUpdateOrganizationParams) {
      this.updateOrganizationParams();
    }
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    organizationParams: state.organization.organizationParams
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetOrganizationParams: (organizationParams) => dispatch({type: organizationParamsActions.ORGANIZATION_PARAMS_SET,
      value: organizationParams})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationParamsUpdater);