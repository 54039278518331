//@flow
import * as React from 'react';
import Text1 from "../../../../../../../components/simple/TextComponents/Text1/Text1";
import text from "../../../../../../../services/localization/text";
import QuestionTooltip from "../../../../../../../components/simple/Containers/QuestionTooltip/QuestionTooltip";
import {actTemplatesEditPageIds} from "../../../../../../../tests/testIds";
import DataListContainer from "../../../../../../../components/simple/Containers/DataListContainer/DataListContainer";
import ActTemplatesFormGroup from "./ActTemplateFormGroup";
import Heading4 from "../../../../../../../components/simple/TextComponents/Heading4/Heading4";
import type {reportFileNamePattern} from "../../../../../../../services/flowTypes/dataFlowTypes";
import PasteStandardFileName from "./PasteStandardFileName";

type Props = {
  readonly?: boolean,
  reportFileNamePatternValue: reportFileNamePattern
};

const ReportFileNamePattern = (props: Props) => {
  const label = (
    <div className="flex">
      <Text1 addClasses={'flex'}>
        {text.generatedDocName}
        <QuestionTooltip isRightBottomPositioned={true} addClasses={'ml_half-du QuestionTooltip_w350'}>
          {text.generatedDocNameHintText1}
          <i>{text.generatedDocNameHintExample}</i>
          {text.generatedDocNameHintText2}
        </QuestionTooltip>
      </Text1>
    </div>
  );
  const component = props.readonly ? (
    <>
      {label}
      <Heading4 addClasses={'Heading4_lh16 mt_1du'}>{props.reportFileNamePatternValue}</Heading4>
    </>
  ) : (
    <>
      {label}
      <ActTemplatesFormGroup formFieldType={'input'} propName={'reportFileNamePattern'}
                             placeholder={text.reportFileNamePatternPlaceholder}
                             idForTest={actTemplatesEditPageIds.inputReportFileNamePattern}/>
      <div className="flex jc_fe">
        <PasteStandardFileName/>
      </div>
    </>
  );
  return (
    <>
      <DataListContainer itemsList={[component]} addClasses={'mb_3du'}/>


    </>
  );
};

export default ReportFileNamePattern;    