//@flow
import * as React from 'react';
import text from "../../services/localization/text";
import FixedHeaderContent from "../../components/simple/Containers/FixedHeader/FixedHeaderContent";
import type {locationType, match} from "../../services/flowTypes/appFlowTypes";
import GenSettingsButtonsRoutes from "../../components/service/Routes/GenSettingsButtonsRoutes";
import "./GenSettings.css";
import GenSettingsMenu from "./genSettings/GenSettingsMenu";

type Props = {
  match: match,
  location: locationType
};

const GenSettings = (props:Props) => {
  return (
    <>
      <FixedHeaderContent title={text.settingsTitle} isSupLevel={true}
                          pathname={props.location.pathname}
                          buttons={<GenSettingsButtonsRoutes/>}/>
      {props.location.pathname.includes("edit") ? null :
      <GenSettingsMenu/>}
    </>
  );
};
export default GenSettings;