//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  errorObject,
  history,
  isReadyToRender,
  locationType,
  match
} from "../../../../../../services/flowTypes/appFlowTypes";
import type {steps} from "../../../../../../services/flowTypes/componentFlowTypes";
import text from "../../../../../../services/localization/text";
import {pathnameSufficesForSteps} from "./AddServiceEditSubHeader";
import type {addService} from "../../../../../../services/flowTypes/dataFlowTypes";
import addServiceEditActions from "../../../../../../store/organization/item/addServiceEdit/addServiceEditActions";
import type {
  onSetAddServiceEdit,
  onUpdateNeedFetchFlagsProp
} from "../../../../../../services/flowTypes/propFnFlowTypes";
import {
  addServicePath,
  addServicesSuffix,
  contrPathView,
  orgPath
} from "../../../../../../services/urlStrings/urlStrings";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import ability from "../../../../../../services/permissions/permissions";
import api from "../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import ConnectedItemControls from "../../../../../../components/composed/ConnectedItemControls/ConnectedItemControls";
import {permissionsStrValues} from "../../../../../../configData/permissionsStrValues";
import needFetchFlagsActions from "../../../../../../store/needFetchFlags/needFetchFlagsActions";

type Props = {
  history: history,
  location: locationType,
  match: match,
  addService: addService,
  onSetAddServiceEdit: onSetAddServiceEdit,
  onUpdateNeedFetchFlagsProp: onUpdateNeedFetchFlagsProp
};
type State = {
  steps: steps,
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};

class AddServiceNavigationButtons extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null,
      steps: []
    };
  }
  goToAddServiceView = (addServiceId) => {
    const path = orgPath(this.props.match.params.organizationId) + contrPathView(this.props.match.params.id) + addServicePath(addServiceId);
    this.props.history.push({pathname: path});
  };
  getAddServiceId = () => {
    return this.props.match.params.addServiceId ? +this.props.match.params.addServiceId : null;
  };
  cancel = () => {
    const newLocationPathname = this.props.match.params.addServiceId ?
      orgPath(this.props.match.params.organizationId) + contrPathView(this.props.match.params.id) + addServicePath(this.props.match.params.addServiceId)
      : orgPath(this.props.match.params.organizationId) + contrPathView(this.props.match.params.id) + addServicesSuffix;
    this.props.history.push(newLocationPathname);
  };
  handleDataBeforeSend = () => {
    return {...this.props.addService, contractId: this.props.match.params.id};
  };
  handleResponse = (responseDataId) => {
    this.setState({isReadyToRender: true});
    this.props.onUpdateNeedFetchFlagsProp('actTemplatesList', true);
    this.goToAddServiceView(responseDataId);
  };
  requestCreate = (addServiceData) => {
    api.createAddService(addServiceData)
      .then(response => {
        this.handleResponse(response.data.id);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  requestUpdate = (addServiceData) => {
    api.updateAddService(addServiceData)
      .then(response => {
        this.handleResponse(response.data.id);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  handleButtonFinishClick = () => {
    this.setState({isReadyToRender: false});
    const addServiceData = this.handleDataBeforeSend();
    if (this.getAddServiceId() === null) {
      this.requestCreate(addServiceData);
    } else {
      this.requestUpdate(addServiceData);
    }
  };
  componentDidMount() {
    const steps = pathnameSufficesForSteps.map(item => {
      return {name: text.addServiceEditSteps[item], pathnameSuffix: item};
    });
    this.setState({steps});
  }
  render() {
    const updateOrCreate = this.getAddServiceId() === null ? 'create' : 'update';
    const permittedAction = this.getAddServiceId() === null ? permissionsStrValues.addService.create
      : permissionsStrValues.addService.update;
    const actionIsPermitted = ability.can('usePermission', permittedAction);
    const handleButtonFinishClick = actionIsPermitted ? this.handleButtonFinishClick : null;
    return (
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <ConnectedItemControls history={this.props.history} steps={this.state.steps} cancelFn={this.cancel}
                               locationPathname={this.props.location.pathname}
                               finishButtonLabel={text.addServiceFinishButtonLabel[updateOrCreate]}
                               actionIsPermitted={actionIsPermitted} isNavigationButtonsMode={true}
                               sendRequest={handleButtonFinishClick}/>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    addService: state.organization.item.addServiceEdit,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSetAddServiceEdit: (addService) => dispatch({type: addServiceEditActions.ADD_SERVICE_EDIT_SET, value: addService}),
    onUpdateNeedFetchFlagsProp: (prop, needFetch) => dispatch({type: needFetchFlagsActions.NEED_FETCH_FLAGS_PROP_UPDATE,
      value: {
        prop: prop,
        data: needFetch
      }})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddServiceNavigationButtons);    