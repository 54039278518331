import moment from "../services/moment/momentInstance";

const isReportDatesCompliedWithFinTerms = (startReportPeriodDate, endReportPeriodDate, finTerms) => {
  let isComplied;
  for (let i=0; i < finTerms.length; i++) {
    const isStartDateComplied = moment(startReportPeriodDate).isSame(finTerms[i].startDate)
      || moment(startReportPeriodDate).isAfter(finTerms[i].startDate);
    const isEndDateComplied = finTerms[i].endDate ? (moment(endReportPeriodDate).isSame(finTerms[i].endDate)
      || moment(endReportPeriodDate).isBefore(finTerms[i].endDate)) : true;
    isComplied = !!isComplied || (isStartDateComplied && isEndDateComplied);
  }
  return isComplied;
};

export default isReportDatesCompliedWithFinTerms;