//@flow
import * as React from 'react';
import agentMainDataActions
  from "../../../../../../../store/organization/item/agentMainData/agentMainDataActions";
import connect from "react-redux/es/connect/connect";
import ability from "../../../../../../../services/permissions/permissions";
import api from "../../../../../../../services/apiInstance/api";
import type {
  agent,
  agentId, itemDataParams, organizationId
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {
  errorObject,
  history,
  isReadyToRender,
  stateToCompare
} from "../../../../../../../services/flowTypes/appFlowTypes";
import ConnectedItemControls
  from "../../../../../../../components/composed/ConnectedItemControls/ConnectedItemControls";
import validationActions from "../../../../../../../store/validation/validationActions";
import {
  onGetAgentMainData,
  onSetDataToValidationState, onUpdateNeedFetchFlagsProp
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import needFetchFlagsActions from "../../../../../../../store/needFetchFlags/needFetchFlagsActions";
import {agentPathView, mainDataSuffix, orgPath} from "../../../../../../../services/urlStrings/urlStrings";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";

type Props = {
  agentId: agentId,
  organizationId: organizationId,
  history: history,
  agentMainData: agent,
  onGetAgentMainData: onGetAgentMainData,
  onSetDataToValidationState: onSetDataToValidationState,
  params: itemDataParams,
  onUpdateNeedFetchFlagsProp: onUpdateNeedFetchFlagsProp
};
type State = {
  stateToCompare: stateToCompare,
  isReadyToRender: isReadyToRender,
  errorObject: errorObject
};

class AgentMainDataControls extends React.Component <Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      stateToCompare: {},
      errorObject: null,
      isReadyToRender: true
    };
  }
  goToAgentMaiDataView = (agentId) => {
    const path = orgPath(this.props.organizationId) + agentPathView(agentId) + mainDataSuffix;
    this.props.history.push({pathname: path});
    this.props.onUpdateNeedFetchFlagsProp('tagsList', true);
    this.props.onUpdateNeedFetchFlagsProp('agentsList', true);
  };
  updateParamsInAgentMainData = () => {
    return {...this.props.agentMainData, params: this.props.params};
  };
  setAgentMainData = (agentMainData) => {
    this.props.onGetAgentMainData(agentMainData);
    this.props.onSetDataToValidationState(agentMainData);
  };
  getAgentById = (goBack) => {
    api.getAgentById(this.props.agentId)
      .then(response => {
        this.setState({isReadyToRender: true});
        this.setAgentMainData(response.data);
        if (goBack) {
          this.props.history.goBack();
        } else {
          this.goToAgentMaiDataView(this.props.agentId);
        }
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      })
  };
  handleDataBeforeSend = () => {
    const agent = this.updateParamsInAgentMainData();
    if (agent.status === 'ACTIVE') {
      if (agent.statusReason && agent.statusDate) {
        delete agent.statusReason;
        delete agent.statusDate;
      }
    }
    if (agent.createDate) {
      delete agent.createDate;
    }
    if (agent.organizationId) {
      delete agent.organizationId;
    }
    return agent;
  };
  requestUpdate = (agent) => {
    api.updateAgent(agent)
      .then(response => {
        const goBack = false;
        this.getAgentById(goBack);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  requestCreate = (agent) => {
    api.createAgent(agent)
      .then(response => {
        this.setState({isReadyToRender: true});
        this.goToAgentMaiDataView(response.data.id);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  sendRequest = () => {
    this.setState({isReadyToRender: false});
    const agent = this.handleDataBeforeSend();
    if (this.props.agentId === null) {
      this.requestCreate(agent);
    } else {
      this.requestUpdate(agent);
    }
  };
  cancel = () => {
    if (this.props.agentId === null) {
      this.props.history.goBack();
    } else {
      this.setState({isReadyToRender: false});
      const goBack = true;
      this.getAgentById(goBack);
    }
  };
  componentDidMount(){
    //for update agent
    if (this.props.agentId === this.props.agentMainData.id) {
      this.setState({stateToCompare: JSON.stringify(this.props.agentMainData)});
    }
    //for new agent
    if (this.props.agentId === null) {
      this.setState({stateToCompare: JSON.stringify(this.props.agentMainData)});
    }
  }
  componentDidUpdate(prevProps, prevState){
    if ( (this.props.agentId === this.props.agentMainData.id &&
      prevProps.agentMainData.id !== this.props.agentMainData.id) ) {
      this.setState({stateToCompare: JSON.stringify(this.props.agentMainData)});
    }
  }
  render(){
    const agentMainData = this.updateParamsInAgentMainData();
    const permittedAction = this.props.agentId === null ? permissionsStrValues.agent.create : permissionsStrValues.agent.update;
    const actionIsPermitted = ability.can('usePermission', permittedAction);
    const submitIsDisabled = this.state.stateToCompare === JSON.stringify(agentMainData);
    return(
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <ConnectedItemControls
          actionIsPermitted={actionIsPermitted}
          submitIsDisabled={submitIsDisabled}
          cancelFn={this.cancel}
          sendRequest={this.sendRequest}
        />
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    agentMainData: state.organization.item.agentMainData,
    params: state.organization.item.itemDataParamsEdit
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetAgentMainData: (agentMainData) => dispatch({
      type: agentMainDataActions.AGENT_MAIN_DATA_SET,
      value: agentMainData}),
    onSetDataToValidationState: (data) => dispatch({
      type: validationActions.VALIDATION_STATE_SET,
      value: data}),
    onUpdateNeedFetchFlagsProp: (prop, needFetch) => dispatch({type: needFetchFlagsActions.NEED_FETCH_FLAGS_PROP_UPDATE, value: {
        prop: prop,
        data: needFetch
      }})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentMainDataControls);
