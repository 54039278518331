//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {totalUsersNumber} from "../../../services/flowTypes/dataFlowTypes";

type Props = {
  total: totalUsersNumber
};

class UsersTotal extends React.Component <Props> {
  render() {
    return (<>{this.props.total}</>);
  }
}

const mapStateToProps = state => {
  return {
    total: state.users.searchResults.total,
  };
};

export default connect(mapStateToProps, null)(UsersTotal);