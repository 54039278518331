//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {history, match} from "../../../../../../services/flowTypes/appFlowTypes";
import gatesParamsSearchActions
  from "../../../../../../store/organization/item/gatesParamsSearch/gatesParamsSearchActions";
import GatesParamsFetcher from "./GatesParamsFetcher";
import ChooseGatesParamsCards from "./ChooseGatesParamsCards";
import type {contract} from "../../../../../../services/flowTypes/dataFlowTypes";

type Props = {
  match: match,
  history: history,
  onInitGatesParamsSearch: Function,
  contractMainData: contract
};

class GatesParams extends React.Component <Props> {
  componentDidMount(): * {
    this.props.onInitGatesParamsSearch({isRequestFromPaynet: false, gateShowMode: 'all'});
  }

  render() {
    const contractId = +this.props.match.params.id;
    return (
      <div className={'GatesParams'}>
        <GatesParamsFetcher contractId={contractId} history={this.props.history}/>
        <ChooseGatesParamsCards ignoreGates={this.props.contractMainData.ignoreGates} contractId={contractId}/>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitGatesParamsSearch: (gatesParamsSearchObj) => dispatch({
      type: gatesParamsSearchActions.GATES_PARAMS_SEARCH_INIT_STATE_SET, value: gatesParamsSearchObj
    })
  };
};
const mapStateToProps = state => {
  return {
    contractMainData: state.organization.item.contractMainData
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GatesParams);
