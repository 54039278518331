//@flow
import * as React from 'react';
import agentMenuItems from "../../../../../../configData/agentMenuItems";
import type {agentId, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import SideLeftContainer from "../../../../../../components/simple/Containers/SideLeftContainer/SideLeftContainer";
import ColumnMenu from "../../../../../../components/simple/Containers/RowMenu/ColumnMenu";

type Props = {
  agentId: agentId,
  organizationId: organizationId
};

const AgentMenu = (props:Props) => {
  const menuItemsData = agentMenuItems(props.organizationId, props.agentId);
  return <SideLeftContainer><ColumnMenu menuItemsData={menuItemsData} addClasses={"AgentMenu"}/></SideLeftContainer>
};
export default AgentMenu;    