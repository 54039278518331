import {testTags} from "../../../../tests/test-data";

export const AgentCardDefProps = {
  agent: {
    id: 19,
    organizationId: 1,
    name: 'Agent Test Name',
    createDate: "2019-03-12",
    status: "ACTIVE",
    tags: testTags,
    params: []
  },
  agentRelated: {
    agentId: 19,
    contractsCount: 3,
    fintermsCount: 5
  },
  handledAgent: {
    linkToView: '/link/to/view',
    createDate: '20.20.2002',
    itemStatusLabel: 'StatusLabel',
    tags: []
  },
  onDelAgentClick: () => {},
  delAgentIsDisabled: false
};

//TODO: add params