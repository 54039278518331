//@flow
import SimplifiedAlertWarning from "./SimplifiedAlertWarning";
import type {
  contractId,
  organizationId
} from "../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../services/localization/text";
import {Link} from "react-router-dom";
import {contrPathView, orgPath, mainDataSuffix} from "../../../../services/urlStrings/urlStrings";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";

type Props = {
  contractId?: contractId,
  organizationId?: organizationId,
  addClasses?: addClasses,
};

const AlertUnverifiedContract = (props: Props) => {
  const linkTo = orgPath(props.organizationId) + contrPathView(props.contractId) + mainDataSuffix;
  return (
      <SimplifiedAlertWarning alertTypeClassNameSuffix={"ERROR"} addClasses={props.addClasses}>
        <div>{text.alertUnverifiedContractFinTerm}</div>
        {props.contractId ? (
            <div className="mt_half-du">
              <Link to={linkTo} className={"td_u_onHover"}>{text.linkToContract}</Link>
            </div>
        ) : null}
      </SimplifiedAlertWarning>
  );
};

export default AlertUnverifiedContract;