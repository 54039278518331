//@flow
import * as React from 'react';
import {connect} from "react-redux";
import text from "../../../../../../../../../../services/localization/text";
import type {
  index,
  modalIsShown
} from "../../../../../../../../../../services/flowTypes/appFlowTypes";
import type {
  objectParamList,
  objectParamId, objectParamLabel
} from "../../../../../../../../../../services/flowTypes/dataFlowTypes";
import {
  initUpdateOrganizationParams,
  onAddItemParam,
  onUpdateItemParam
} from "../../../../../../../../../../services/flowTypes/propFnFlowTypes";
import NFormGroup from "../../../../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import NSelect from "../../../../../../../../../../components/simple/FormComponents/NSelect/NSelect";
import type {
  btn, editOrAdd, itemType, propError,
  selectedOption,
  selectOptions
} from "../../../../../../../../../../services/flowTypes/componentFlowTypes";
import NModal from "../../../../../../../../../../components/simple/Modals/NModal/NModal";
import {organizationParamsPageIds} from "../../../../../../../../../../tests/testIds";
import type {objectParamName} from "../../../../../../../../../../services/flowTypes/dataFlowTypes";
import objectParamTypes from "../../../../../../../../../../configData/objectParamTypes";
import NInput from "../../../../../../../../../../components/simple/FormComponents/NInput/NInput";
import organizationParamsActions
  from "../../../../../../../../../../store/organization/organizationParams/organizationParamsActions";
import Text1 from "../../../../../../../../../../components/simple/TextComponents/Text1/Text1";
import AdaptiveFormContainer
  from "../../../../../../../../../../components/simple/Containers/AdaptiveFormContainer/AdaptiveFormContainer";

type Props = {
  itemType: itemType,
  objectParamList: objectParamList,
  onAddItemParam: onAddItemParam,
  objectParamId?: objectParamId,
  btn: btn,
  editOrAdd: editOrAdd,
  paramIndex?: index,
  onUpdateItemParam: onUpdateItemParam,
  initUpdateOrganizationParams: initUpdateOrganizationParams
};
type State = {
  modalIsShown: modalIsShown,
  objectParamName: objectParamName,
  objectParamLabel: objectParamLabel,
  objectParamTypeOptions: selectOptions,
  selectedObjectParamType: selectedOption,
  propError: propError
};

class EditItemParam extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false,
      objectParamName: '',
      objectParamLabel: '',
      selectedObjectParamType: null,
      objectParamTypeOptions: [],
      propError: ''
    };
  }
  handleInputChange =(e:SyntheticEvent<HTMLInputElement>) => {
    const propError = /^[A-Za-zА-Яа-яЁё]+$/.test(e.currentTarget.value) ?
      '' : text.paramNameError;
    this.setState({objectParamName: e.currentTarget.value, propError});
  };
  handleInputParamLabelChange =(e:SyntheticEvent<HTMLInputElement>) => {
    this.setState({objectParamLabel: e.currentTarget.value});
  };
  showModal = () => {
    const selectedObjectParamType = this.props.editOrAdd === "add" ? null
      : this.state.objectParamTypeOptions.find(item => item.value === this.props.objectParamList[this.props.paramIndex].type);
    const objectParamName = this.props.editOrAdd === "add" ? "" : this.props.objectParamList[this.props.paramIndex].name;
    const objectParamLabel = this.props.editOrAdd === "add" ? "" : this.props.objectParamList[this.props.paramIndex].label;
    const propError = '';
    this.setState({
      modalIsShown: true,
      selectedObjectParamType,
      objectParamName,
      objectParamLabel,
      propError
    });
  };
  hideModal = () => {
    this.setState({
      modalIsShown: false,
    });
  };
  onSelectType = (selectedObjectParamType) => {
    this.setState({selectedObjectParamType});
  };
  addItemParam = () => {
    if (this.state.objectParamName.length && this.state.selectedObjectParamType) {
      const newItemParam = {
        name: this.state.objectParamName,
        label: this.state.objectParamLabel,
        type: this.state.selectedObjectParamType.value
      };
      this.props.onAddItemParam(this.props.itemType, [newItemParam]);
      this.props.initUpdateOrganizationParams();
      this.hideModal();
    } else {
      this.hideModal();
    }
  };
  editItemParam = () => {
    const itemParam = {
      id: this.props.objectParamList[this.props.paramIndex].id,
      name: this.state.objectParamName,
      label: this.state.objectParamLabel,
      type: this.state.selectedObjectParamType.value
    };
    this.props.onUpdateItemParam(this.props.itemType, this.props.paramIndex, itemParam);
    this.props.initUpdateOrganizationParams();
    this.hideModal();
  };
  componentDidMount(){
    const objectParamTypeOptions = objectParamTypes.map((item) => {
      return {value: item, label: text.paramType[item]}
    });
    this.setState({objectParamTypeOptions});
  }
  render() {
    const modalTitle = this.props.editOrAdd === "add" ? text.addItemParamModalTitle : text.editItemParamModalTitle;
    const onModalActionBtnClick = this.props.editOrAdd === "add" ? this.addItemParam : this.editItemParam;
    const modalActionBtnText  = this.props.editOrAdd === "add" ? text.btnAdd : text.btnEdit;
    return (
      <>
        <div className="btn-container" onClick={this.showModal}>
          {this.props.btn}
        </div>
        {this.state.modalIsShown ? (
          <NModal
            modalTitle={modalTitle}
            onModalCancelBtnClick={this.hideModal}
            onModalActionBtnClick={onModalActionBtnClick}
            modalCancelBtnText={text.cancel}
            modalActionBtnText={modalActionBtnText}
            addClasses={'NModal_ov'}
            isModalActionBtnDisabled={this.state.propError.length}
          >
            <AdaptiveFormContainer fieldsArr={[
              <NFormGroup label={text.itemParamName} addClasses={''} propError={this.state.propError} key={"itemParamName"}>
                <NInput id={organizationParamsPageIds.inputParamName} value={this.state.objectParamName}
                        onChange={this.handleInputChange} placeholder={text.inputParamName}/>
              </NFormGroup>,
              <NFormGroup label={text.itemParamLabel} addClasses={''} key={"itemParamLabel"}>
                <NInput id={organizationParamsPageIds.inputParamLabel} value={this.state.objectParamLabel}
                        onChange={this.handleInputParamLabelChange} placeholder={text.inputParamName}/>
              </NFormGroup>
            ]} containerMode={"twoHalfCardWidthFieldsModal"} addClasses={"pt-pb_2du"}/>
            <NFormGroup label={text.itemParamType} addClasses={'w_half-card mb_2du'}>
              <NSelect value={this.state.selectedObjectParamType} onChange={this.onSelectType}
                       placeholder={text.selectObjectType}
                       isSearchable={true}
                       inputId={organizationParamsPageIds.selectInputParamType}
                       options={this.state.objectParamTypeOptions} id={organizationParamsPageIds.selectParamType}/>
            </NFormGroup>
            <Text1 addClasses={'mb_2du'}>{text.paramsHint1} <b>{text.itemParamName}</b> {text.paramsHint2}</Text1>
          </NModal>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    objectParamList: state.organization.organizationParams[ownProps.itemType]
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onAddItemParam: (itemType, paramArr) => dispatch({type: organizationParamsActions.ORGANIZATION_PARAMS_ADD,
      value:{itemType, paramArr} }),
    onUpdateItemParam: (itemType, paramIndex, param) => dispatch({type: organizationParamsActions.ORGANIZATION_PARAMS_UPDATE,
      value: {itemType, paramIndex, param}
    })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditItemParam);