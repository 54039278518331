import {getCurrencyIco} from "./getCurrencyLabel";

const getParamUnitByNameAndCurr = (name, currency) => {
  switch (name) {
    case "ratePercent":
      return "%";
    case "minRate":
    case "rateMin":
    case "rateAbs":
    case "rateAmount":
    case "totalAmount":
      return getCurrencyIco(currency);
    case "minCount":
    case "maxCount":
      return "";
    default:
      return "";
  }
};

export default getParamUnitByNameAndCurr;