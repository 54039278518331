//@flow
import * as React from 'react';
import type {itemType, permittedActionObj} from "../../../services/flowTypes/componentFlowTypes";
import DocumentsList from "./DocumentsList/DocumentsList";
import type {agentId, contractId, organizationId} from "../../../services/flowTypes/dataFlowTypes";
import DocumentFolderList from "./DocumentFolderList/DocumentFolderList";
import itemDocumentsActions from "../../../store/organization/item/itemDocuments/itemDocumentsActions";
import {connect} from "react-redux";
import type {history, locationPathname} from "../../../services/flowTypes/appFlowTypes";
import "./ItemDocuments.css";

type Props = {
  itemType: itemType,
  permittedActionObj: permittedActionObj,
  itemId?: contractId | agentId | organizationId,
  folderId: string,
  pathname: locationPathname,
  history: history
};

type State = {
  isAllFilesShown: boolean
};

class ItemDocuments extends React.Component<State, Props> {
  constructor(props: Props): void {
    super(props);
    this.state = {
        isAllFilesShown: false
    }
  }

  toggleShowAllFiles = (e) => {
    e.preventDefault();
    this.setState({
        isAllFilesShown: !this.state.isAllFilesShown
    });
  };

  render(){
    return (
     <div className={`ItemDocuments ItemDocuments_${this.props.itemType}`}>
       <DocumentFolderList toggleShowAllFiles={this.toggleShowAllFiles} itemType={this.props.itemType}
                           permittedActionObj={this.props.permittedActionObj} isAllFilesShown={this.state.isAllFilesShown}
                           itemId={this.props.itemId} pathname={this.props.pathname} folderId={this.props.folderId}/>
       <DocumentsList itemType={this.props.itemType} itemId={this.props.itemId} permittedActionObj={this.props.permittedActionObj}
                      folderId={this.props.folderId} isAllFilesShown={this.state.isAllFilesShown} pathname={this.props.pathname}/>
     </div>
    );
 };
}

const mapDispatchToProps = dispatch => {
    return {
        onInitItemDocumentsState: () => dispatch({type: itemDocumentsActions.ITEM_DOCUMENTS_STATE_INIT}),
        onUpdateItemDocumentsList: (value) => dispatch({type: itemDocumentsActions.ITEM_DOCUMENTS_LIST_SET, value: value})
    };
};

export default connect(null, mapDispatchToProps)(ItemDocuments);