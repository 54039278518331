//@flow
import searchInOrgContextResponseActions from './searchInOrgContextResponseActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {inOrgContextSearchResponse} from "../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = {
  contracts: {
    total: 0,
    itemsList: []
  },
  agents: {
    total: 0,
    itemsList: []
  },
  documents: {
    total: 0,
    itemsList: []
  }
};

const searchInOrgContextResponseReducer = (state:inOrgContextSearchResponse = initialState, action:reducerAction) => {
  switch (action.type) {
    case searchInOrgContextResponseActions.SEARCH_IN_ORG_CONTEXT_RESPONSE_INIT_STATE_SET:
      return initialState;
    case searchInOrgContextResponseActions.SEARCH_IN_ORG_CONTEXT_RESPONSE_GET:
      return action.value;
    default:
      return state;
  }
};

export default searchInOrgContextResponseReducer;