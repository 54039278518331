//@flow
import * as React from 'react';
import type {id, children, style} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import './NCard.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  isModalDocumentEdit?: boolean,
  children: children,
  style?: style
};

const NCard = (props: Props) => {
  const addClasses = props.isModalDocumentEdit ? props.addClasses + ' NCard_w-420' : props.addClasses;
  return (
    <div id={props.id} className={baseClass("NCard p_3du", addClasses)} style={props.style}>
      {props.children}</div>
  );
};

export default NCard;