//@flow
import * as React from 'react';
import './Sorting.css';
import text from "../../../../../services/localization/ru/text";
import type {sortingOrder, typeSortAgent, typeSortContract,
  searchCategory} from "../../../../../services/flowTypes/dataFlowTypes";
import {contractsPageIds} from "../../../../../tests/testIds";


type Props = {
  sortingOrder:  sortingOrder,
  typeSortAgent: typeSortAgent,
  typeSortContract: typeSortContract,
  handleSortingChange: Function,
  category: searchCategory
};

class Sorting extends React.Component <Props, void> {
    render(){
        return(
            <div className="sorting-wrapper flex pt_1du pb_1du pl-pr_3du mb_1du">
              <div id={contractsPageIds.buttonSortById} onClick={() => {this.props.handleSortingChange('id')}}
                   className={'sorting-link pl_2du ' + (this.props.typeSortContract === 'id' ? 'sorting-link_active ' : '') + this.props.sortingOrder}>{text.sorting.id[this.props.category]}</div>
              {this.props.category === 'agents' ? (
                <div id={contractsPageIds.buttonSortByCreateDate} onClick={() => {this.props.handleSortingChange('createDate')}}
                     className={'sorting-link pl_2du ' + (this.props.typeSortContract === 'createDate' ? 'sorting-link_active ' : '') + this.props.sortingOrder}>{text.sorting.createDate}</div>
              ) : null}
              {this.props.category === 'contracts' ? (
                  <>
                      <div id={contractsPageIds.buttonSortByStartDate} onClick={() => {this.props.handleSortingChange('startDate')}}
                           className={'sorting-link pl_2du ' + (this.props.typeSortContract === 'startDate' ? 'sorting-link_active ' : '') + this.props.sortingOrder}>{text.sorting.startDate}</div>
                      {/*<div onClick={() => {this.props.handleSortingChange('contractNo')}}*/}
                      {/*     className={'sorting-link pl_2du ' + (this.props.typeSortContract === 'contractNo' ? 'sorting-link_active ' : '') + this.props.sortingOrder}>{text.sorting.contractNo}</div>*/}
                  </>
              ) : null}

              {/*<div onClick={() => {this.props.handleSortingChange('name')}}*/}
              {/*     className={'sorting-link pl_2du ' + (this.props.typeSortContract === 'name' ? 'sorting-link_active ' : '') + this.props.sortingOrder}>{text.sorting.name}</div>*/}
            </div>
        );
    };
}
export default Sorting;