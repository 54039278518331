//@flow
import * as React from 'react';
import text from "../../../../services/localization/text";
import Can from "../../../../services/permissions/Can";
import type {
  isDocumentsListFetcherMounted,
  itemType,
  permittedAction, selectOptions
} from "../../../../services/flowTypes/componentFlowTypes";
import type {
  errorObject,
  isReadyToRender,
  modalIsShown
} from "../../../../services/flowTypes/appFlowTypes";
import NModal from "../../../simple/Modals/NModal/NModal";
import type {
  agentId,
  contractId,
  document, documentFolderList, folderId
} from "../../../../services/flowTypes/dataFlowTypes";
import api from "../../../../services/apiInstance/api";
import DocumentsListFetcher from "../DocumentsList/DocumentsListFetcher/DocumentsListFetcher";
import RenderOnReady from "../../../service/RenderOnReady/RenderOnReady";
import apiErrorHandler from "../../../../services/apiInstance/apiErrorHandler";
import {documentsPageIds} from "../../../../tests/testIds";
import {connect} from "react-redux";
import documentFoldersActions from "../../../../store/organization/item/documentFolders/documentFoldersActions";
import type {onUpdateDocumentFoldersList} from "../../../../services/flowTypes/propFnFlowTypes";
import DocumentUploaderContent from "./DocumentUploaderContent";
import BtnNewItem from "../../../simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  permittedAction: permittedAction,
  itemType: itemType,
  itemId: contractId | agentId,
  onUpdateDocumentFoldersList: onUpdateDocumentFoldersList,
  folderList: documentFolderList,
  folderId: folderId
};
type State = {
  modalIsShown: modalIsShown,
  documentsArr: Array<document>,
  isDocumentsListFetcherMounted: isDocumentsListFetcherMounted,
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  promiseUploadDocument: {
    contract: Function,
    agent: Function
  },
  selectOptions: selectOptions
};

class DocumentUploader extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null,
      isDocumentsListFetcherMounted: false,
      modalIsShown: false,
      documentsArr: [],
      selectOptions: [],
      promiseUploadDocument: {
        contract: api.uploadContractDocument,
        agent: api.uploadAgentDocument,
        organizations: api.uploadOrganizationDocument
      }
    };
  }
  onDocumentsArrUpdate = (documentsArr) => {
    this.setState({documentsArr});
  };
  showModal = () => {
    this.setState({modalIsShown: true});
  };
  hideModal = () => {
    this.setState({
      modalIsShown: false,
      documentsArr: []
    });
  };
  setReadyToRender = () => {
    this.setState({
      isReadyToRender: true,
      isDocumentsListFetcherMounted: false,
      modalIsShown: false,
      documentsArr: []
    });
  };
  uploadDoc = () => {
    if (this.state.documentsArr.length) {
      this.setState({isReadyToRender: false});
      const formData = new FormData();
      for (let i=0; i<this.state.documentsArr.length; i++) {
        formData.append('file', this.state.documentsArr[i].file);
        formData.append('fileDescription', this.state.documentsArr[i].fileDescription);
        if(this.state.documentsArr[i].selectedOption && this.state.documentsArr[i].selectedOption.value !== 0){
          formData.append('folderId', this.state.documentsArr[i].selectedOption.value);
        } else {
          formData.append('folderId', '');
        }
      }
      const promise = this.state.promiseUploadDocument[this.props.itemType];
      promise(this.props.itemId, formData)
        .then(response => {
          this.props.onUpdateDocumentFoldersList(response.data);
          this.setState({
            isDocumentsListFetcherMounted: true
          });
        })
        .catch(error => {
          this.setState({
            isReadyToRender: true,
            errorObject: apiErrorHandler(error)
          });
        });
    }
  };
  setSelectOptions = () => {
    let selectOptions = this.props.folderList.map(item => {
      return {
        value: item.id,
        label: item.name
      }
    });
    const firstOption = {label: 'Без папки', value: 0};
    selectOptions.unshift(firstOption);
    this.setState({selectOptions});
  };
  componentDidMount() {
    this.setSelectOptions();
  }

  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if(prevProps.folderList.length !== this.props.folderList.length){
      this.setSelectOptions();
    }
  }
  render() {
    const folderObj = this.props.folderId ?
      this.props.folderList.find(item => item.id === this.props.folderId) : null;
    const isFolderProtected = folderObj ? folderObj.isProtected : false;
    return (
      <>
        {isFolderProtected ? null : (
          <Can do={'usePermission'} on={this.props.permittedAction}>
            <BtnNewItem addClasses={"BtnNewItem_grey"} id={documentsPageIds.buttonAddDocument} onClick={this.showModal}>
              {text.uploadDocument}</BtnNewItem>
          </Can>
        )}
        {this.state.modalIsShown ? (
          <NModal
            addClasses='NModal_ov'
            modalTitle={text.documentsModalTitle}
            modalCancelBtnText={text.cancel}
            modalActionBtnText={text.save}
            onModalCancelBtnClick={this.hideModal}
            onModalActionBtnClick={this.uploadDoc}
          >
            <DocumentUploaderContent
              documentsArr={this.state.documentsArr}
              onDocumentsArrUpdate={this.onDocumentsArrUpdate}
              folderId={this.props.folderId}
              selectOptions={this.state.selectOptions}
              folderList={this.props.folderList}
              itemType={this.props.itemType}
              itemId={this.props.itemId}
              isMultipleUpload={true}
            />
          </NModal>
        ) : null}
        {this.state.isDocumentsListFetcherMounted ? (
          <DocumentsListFetcher itemType={this.props.itemType}
                                setReadyToRender={this.setReadyToRender} itemId={this.props.itemId} />
        ) : null}
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    folderList: state.organization.item.documentFolders
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onUpdateDocumentFoldersList: (value) => dispatch({type: documentFoldersActions.ITEM_FOLDERS_LIST_SET, value: value})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUploader);