//@flow
import * as React from 'react';
import {connect} from "react-redux";
import AddServiceFormGroup from "./AddServiceFormGroup";
import text from "../../../../../../../services/localization/text";
import QuestionTooltip from "../../../../../../../components/simple/Containers/QuestionTooltip/QuestionTooltip";
import {addServiceEditPageIds} from "../../../../../../../tests/testIds";
import type {
  actTemplates,
  contractId,
  isDetachedInvoice,
  payoutPeriod
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {selectOptions} from "../../../../../../../services/flowTypes/componentFlowTypes";
import GetActTemplates from "../../ActTemplatesView/actTemplateView/GetActTemplates";
import Heading4 from "../../../../../../../components/simple/TextComponents/Heading4/Heading4";
import concatIdName from "../../../../../../../utils/concatIdName";
import getNameOrDefName from "../../../../../../../utils/getNameOrDefName";
import Text3 from "../../../../../../../components/simple/TextComponents/Text3/Text3";
import Text4 from "../../../../../../../components/simple/TextComponents/Text4/Text4";

type Props = {
  isDetachedInvoice: isDetachedInvoice,
  actTemplatesView: actTemplates,
  contractId: contractId,
  payoutPeriod: payoutPeriod
};
type State = {
  actTemplateIdOptions: selectOptions
};

class SelectAddServiceActTemplateId extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      actTemplateIdOptions: []
    };
  }
  createActTemplateOptionLabel = (actTemplate, isDisabledOption) => {
    const payoutPeriodLabel = actTemplate.payoutPeriod ?
      text[actTemplate.payoutPeriod] : text.payoutPeriodIsNotSet;
    const opacityValue = isDisabledOption ? '0.5' : '1';
    return (
      <div style={{'opacity': opacityValue}}>
        <Heading4>{concatIdName(actTemplate.id, getNameOrDefName(actTemplate.name, text.actTemplate))}</Heading4>
        <div className="flex">
          <Text3>{payoutPeriodLabel}</Text3>
          {isDisabledOption ? (
            <Text4 addClasses={'Text4_error ml_1du'}>{text.payoutPeriodIsNotCompliedWithAddService}</Text4>
          ) : null}
        </div>
      </div>
    );
  };
  setActTemplateIdOptions = () => {
    const filteredActTemplates = this.props.actTemplatesView ?
      this.props.actTemplatesView.filter(item => item.finTermGroupIds.length) : [];
    const actTemplateIdOptions = filteredActTemplates.map(item => {
      const isDisabledOption = item.payoutPeriod !== this.props.payoutPeriod && this.props.payoutPeriod !== 'ONCE';
      return {
        value: item.id,
        label: this.createActTemplateOptionLabel(item, isDisabledOption),
        isDisabled: isDisabledOption
      };
    });
    this.setState({actTemplateIdOptions});
  };
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if (prevProps.payoutPeriod !== this.props.payoutPeriod || prevProps.actTemplatesView !== this.props.actTemplatesView) {
      this.setActTemplateIdOptions();
    }
  }
  componentDidMount(): * {
    this.setActTemplateIdOptions();
  }
  render() {
    return (this.props.isDetachedInvoice ? null : (
      <GetActTemplates contractId={this.props.contractId}>
        <AddServiceFormGroup propName={'actTemplateId'} formFieldType={'select'}
                             label={text.addServiceFormLabels.actTemplateId}
                             placeholder={text.addServiceFormPlaceholders.actTemplateId} addClasses={'max-w_card'}
                             options={this.state.actTemplateIdOptions}
                             labelTooltip={<QuestionTooltip isHighZIndex={true}>{text.addServiceFormLabelTooltips.actTemplateId}</QuestionTooltip>}
                             selectInputId={addServiceEditPageIds.selectInputActTemplateId}
                             isSearchable={true} noOptionsMessage={text.addServiceFormNoOptionsMessage.actTemplateId}
                             idForTest={addServiceEditPageIds.selectActTemplateId}/>
      </GetActTemplates>
    ));
  }
}

const mapStateToProps = state => {
  return {
    isDetachedInvoice: state.organization.item.addServiceEdit.isDetachedInvoice,
    payoutPeriod: state.organization.item.addServiceEdit.payoutPeriod,
    actTemplatesView: state.organization.item.actTemplatesView,
  };
};
export default connect(mapStateToProps, null)(SelectAddServiceActTemplateId);