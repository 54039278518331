//@flow
import * as React from 'react';
import Checkbox from "../../../../../components/simple/FormComponents/Checkbox/Checkbox";
import {updateReportIds} from "../../../../../services/flowTypes/propFnFlowTypes";
import type {reportId, reportIds} from "../../../../../services/flowTypes/dataFlowTypes";
import type {id} from "../../../../../services/flowTypes/appFlowTypes";

type Props = {
  updateReportIds: updateReportIds,
  checkedReportIds: reportIds,
  reportId: reportId,
  idForTest: id
};

class ReportCardCheckbox extends React.Component <Props> {
  onCheckboxClick = () => {
    const reportIds = [...this.props.checkedReportIds];
    const reportIdIndex = reportIds.findIndex(item => item === this.props.reportId);
    if (reportIdIndex === -1) {
      reportIds.push(this.props.reportId);
    } else {
      reportIds.splice(reportIdIndex, 1);
    }
    this.props.updateReportIds(reportIds);
  };
  render() {
    const checked = this.props.checkedReportIds.includes(this.props.reportId);
    return (
      <Checkbox id={this.props.idForTest} addClasses={'Checkbox_16'} checked={checked} onClick={this.onCheckboxClick}/>
    );
  }
}

export default ReportCardCheckbox;    