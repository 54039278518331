//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  onDeleteSearchRequestProp, onGetLinkedItemsRequestParamsProp,
  onGetSearchRequestProp
} from "../../../../services/flowTypes/propFnFlowTypes";
import type {
  agentStatusList,
  contractStatusList, contractTypes, linkedItemsRequestParams,
  searchRequest
} from "../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../services/localization/text";
import type {isLinkedItemsMode, selectOptions} from "../../../../services/flowTypes/componentFlowTypes";
import searchRequestActions from "../../../../store/organization/searchRequest/searchRequestActions";
import Filters from "../../Filters/Filters";
import linkedItemsRequestParamsActions
  from "../../../../store/organization/linkedItemsRequestParams/linkedItemsRequestParamsActions";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";

type Props = {
  onGetSearchRequestProp: onGetSearchRequestProp,
  searchRequest: searchRequest,
  onDeleteSearchRequestProp: onDeleteSearchRequestProp,
  agentStatusList: agentStatusList,
  contractStatusList: contractStatusList,
  isLinkedItemsMode?: isLinkedItemsMode,
  linkedItemsRequestParams: linkedItemsRequestParams,
  onGetLinkedItemsRequestParamsProp: onGetLinkedItemsRequestParamsProp,
  addClasses?: addClasses,
  contractTypes: contractTypes
};
type State = {
  statusSelectOptions: selectOptions,
  byAgentContractsSelectOptions: selectOptions,
  byAgentFinTermsSelectOptions: selectOptions,
  byComplianceWithPaynetSelectOptions: selectOptions,
  contractTypeSelectOptions: selectOptions
};

const byAgentContractsFilterList = ["WITH_CONTRACTS", "WITHOUT_CONTRACTS"];
const byAgentFinTermsFilterList = [ "WITH_FINTERMS", "WITHOUT_FINTERMS" ];
const byComplianceWithPaynetFilterList = ["WITH_ERRORS", "WITHOUT_ERRORS"];

class SearchPageFilters extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      statusSelectOptions: [],
      byAgentContractsSelectOptions: [],
      byAgentFinTermsSelectOptions: [],
      byComplianceWithPaynetSelectOptions: [],
      contractTypeSelectOptions: []
    };
  }
  handleOnGetSearchPageFiltersPropCall = (filtersProps) => {
    const searchPageFiltersProps = {...filtersProps};
    if (!searchPageFiltersProps.hasOwnProperty('offset')) {
      searchPageFiltersProps.offset = 0;
    }
    const onGetFn = this.props.isLinkedItemsMode ? this.props.onGetLinkedItemsRequestParamsProp
      : this.props.onGetSearchRequestProp;
    onGetFn(searchPageFiltersProps);
  };
  getFiltersList = (allFiltersList) => {
    const searchRequest = this.props.isLinkedItemsMode ? this.props.linkedItemsRequestParams : this.props.searchRequest;
    const filtersList = [];
    const filtersForOptions = [];
    for (let i=0; i < allFiltersList.length; i++) {
      const condition =
        allFiltersList[i].propName === 'dateRange' ?
        searchRequest.hasOwnProperty('startDate') || searchRequest.hasOwnProperty('endDate')
         :  (allFiltersList[i].propName === 'createDateRange' ?
          searchRequest.hasOwnProperty('startCreateDate') || searchRequest.hasOwnProperty('endCreateDate')
        : searchRequest.hasOwnProperty(allFiltersList[i].propName) );
      if (condition) {
        filtersList.push(allFiltersList[i]);
      } else {
        filtersForOptions.push(allFiltersList[i]);
      }
    }
    return {filtersList, filtersForOptions};
  };
  componentDidMount() {
    const statusesListByCategory = {
      agents: this.props.agentStatusList,
      contracts: this.props.contractStatusList
    };
    const searchCategory = this.props.isLinkedItemsMode ?
      this.props.linkedItemsRequestParams.category : this.props.searchRequest.category;
    const optionsByCategory = statusesListByCategory[searchCategory].map(item => {
      const category = searchCategory.slice(0, -1);
      return {
        value: item,
        label: text[category].status[item],
        statusClassName: `StatusLabel StatusLabel_${item}`
      };
    });
    const statusSelectOptions = [
      {value: '', label: text.all, statusClassName: `StatusLabel`},
      ...optionsByCategory
    ];
    const emptyOption = {value: '', label: text.all};
    const byAgentContractsSelectOptions = byAgentContractsFilterList.map(item => {
      return {value: item, label: text.searchPageFiltersLabels[item]};
    });
    byAgentContractsSelectOptions.unshift(emptyOption);
    const byAgentFinTermsSelectOptions = byAgentFinTermsFilterList.map(item => {
      return {value: item, label: text.searchPageFiltersLabels[item]};
    });
    byAgentFinTermsSelectOptions.unshift(emptyOption);
    const byComplianceWithPaynetSelectOptions = byComplianceWithPaynetFilterList.map(item => {
      return {value: item, label: text.searchPageFiltersLabels[item]};
    });
    byComplianceWithPaynetSelectOptions.unshift(emptyOption);
    const contractTypeSelectOptions = this.props.contractTypes.map(item => {
      return {value: item.id, label: item.name}
    });
    contractTypeSelectOptions.unshift(emptyOption);
    this.setState({statusSelectOptions, byAgentContractsSelectOptions, byAgentFinTermsSelectOptions,
      byComplianceWithPaynetSelectOptions, contractTypeSelectOptions});
  }
  render() {
    const statusFilter = {propName: 'status', formFieldType: 'filter-select', options: this.state.statusSelectOptions,
      placeholder: text.status, initValue: ''};
    // const dateRangeFilter = {propName: 'dateRange', formFieldType: 'filter-dateRange', initValue: ''};
    const dateRangeFilter = {propName: 'dateRange', formFieldType: 'filter-wrapped-date-range', initValue: '',
      preferableDateRangePeriodType: 'month'};
    const createDateRangeFilter = {propName: 'createDateRange', formFieldType: 'filter-wrapped-date-range', initValue: '',
      preferableDateRangePeriodType: 'month'};
    const byAgentContractsFilter = {propName: 'byAgentContracts', formFieldType: 'filter-select', options: this.state.byAgentContractsSelectOptions,
      placeholder: text.searchPageFiltersLabels.byAgentContractsPlaceholder, initValue: ''};
    const byAgentFinTermsFilter = {propName: 'byAgentFinTerms', formFieldType: 'filter-select', options: this.state.byAgentFinTermsSelectOptions,
      placeholder: text.searchPageFiltersLabels.byAgentFinTermsPlaceholder, initValue: ''};
    const byComplianceWithPaynetFilter = {propName: 'byComplianceWithPaynet', formFieldType: 'filter-select', options: this.state.byComplianceWithPaynetSelectOptions,
      placeholder: text.searchPageFiltersLabels.byComplianceWithPaynetPlaceholder, initValue: ''};
    const byUnverifiedAddAgreementsFilter = {propName: 'byUnverifiedAddAgreements', formFieldType: 'filter-select', options: this.state.byComplianceWithPaynetSelectOptions,
      placeholder: text.searchPageFiltersLabels.byUnverifiedAddAgreementsPlaceholder, initValue: ''};
    // const contractTypeFilter = {propName: 'contractTypeId', formFieldType: 'filter-select', options: this.state.contractTypeSelectOptions,
    //   placeholder: text.searchPageFiltersLabels.contractTypeId, initValue: ''};
    const filtersListByCat = {
      contracts: this.props.isLinkedItemsMode ? [statusFilter] : [statusFilter, dateRangeFilter, byUnverifiedAddAgreementsFilter, byComplianceWithPaynetFilter,
        // contractTypeFilter
      ],
      agents: this.props.isLinkedItemsMode ? [statusFilter] : [statusFilter, createDateRangeFilter,
        byAgentContractsFilter, byAgentFinTermsFilter
      ]
    };
    const filtersNamesListByCat = {
      contracts: this.props.isLinkedItemsMode
        ? ['status']
        : ['status', 'startDate', 'endDate', 'byUnverifiedAddAgreements', 'byComplianceWithPaynet',
        // 'contractTypeId'
      ],
      agents: this.props.isLinkedItemsMode ? ['status'] : ['status', 'startCreateDate', 'endCreateDate',
        'byAgentContracts', 'byAgentFinTerms'
      ]
    };
    const searchCategory = this.props.isLinkedItemsMode
      ? this.props.linkedItemsRequestParams.category
      : this.props.searchRequest.category;

    const allFiltersList = filtersListByCat[searchCategory];
    const isStaticMode = true;
    const {filtersList, filtersForOptions} = this.getFiltersList(allFiltersList);
    const resetFilters = () => this.props.onDeleteSearchRequestProp(filtersNamesListByCat[searchCategory]);
    const filtersState = this.props.isLinkedItemsMode ? this.props.linkedItemsRequestParams : this.props.searchRequest;
    return (
      <Filters onGetFiltersProp={this.handleOnGetSearchPageFiltersPropCall}
               onDeleteFiltersProp={this.props.onDeleteSearchRequestProp}
               filtersState={filtersState}
               filtersForOptions={filtersForOptions}
               filtersList={filtersList}
               resetFilters={resetFilters}
               addClasses={this.props.addClasses}
               isStaticMode={isStaticMode}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    linkedItemsRequestParams: state.organization.linkedItemsRequestParams,
    searchRequest: state.organization.searchRequest,
    agentStatusList: state.organization.organizationData.statuses.agentStatusList,
    contractStatusList: state.organization.organizationData.statuses.contractStatusList,
    contractTypes: state.organization.organizationData.contractTypes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSearchRequestProp: (searchRequestObj) => dispatch({type: searchRequestActions.SEARCH_REQUEST_PROP_SET,
      value: searchRequestObj}),
    onGetLinkedItemsRequestParamsProp: (searchRequestObj) => dispatch({type: linkedItemsRequestParamsActions.LINKED_ITEMS_REQUEST_PARAMS_PROP_SET,
      value: searchRequestObj}),
    onDeleteSearchRequestProp: (searchRequestPropNameArr) => dispatch({
      type: searchRequestActions.SEARCH_REQUEST_PROP_DELETE,
      value: searchRequestPropNameArr
    })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPageFilters);
