//@flow
import * as React from 'react';
import './Pagination.css';
import type {paginationCount, paginationOffset, totalCountSearchData} from "../../../services/flowTypes/dataFlowTypes";
import {setCount, setOffset} from "../../../services/flowTypes/propFnFlowTypes";
import {paginationIds} from "../../../tests/testIds";
import text from "../../../services/localization/text";
import scrollToTop from "../../../utils/scrollToTop";
import type {isScrollToTopBtnShown} from "../../../services/flowTypes/componentFlowTypes";

type Props = {
  offset: paginationOffset,
  count: paginationCount,
  totalCount: totalCountSearchData,
  setOffset: setOffset,
  setCount: setCount
}
type State = {
  isScrollToTopBtnShown: isScrollToTopBtnShown
};

class Pagination extends React.Component <Props, State> {
  constructor(props:Props){
    super(props);
    this.state = {
      isScrollToTopBtnShown: false
    };
  }
  defineScrollToTopVisibility = () => {
    if (document.body.clientHeight - 50  > window.innerHeight) {
      this.setState({isScrollToTopBtnShown: true});
    } else {
      this.setState({isScrollToTopBtnShown: false});
    }
  };
  componentDidUpdate(prevProps: Props) {
    if (prevProps !== this.props) {
      this.defineScrollToTopVisibility();
    }
  }
  componentDidMount() {
    this.defineScrollToTopVisibility();
  }
  render() {
    let pagesArr = [];
    let lastPage =  0;
    if (this.props.totalCount > this.props.count) {
      for (let i=0; i < this.props.totalCount/this.props.count; i++) {
        pagesArr.push(i+1);
        lastPage++;
      }
    } else if(this.props.totalCount > 0) {
      pagesArr = [1];
    }
    const current = (this.props.offset + this.props.count)/this.props.count;
    pagesArr = pagesArr.filter(item => {
      return item === 1 || item === lastPage || item === current || item === (current - 1) || item === (current + 1)
    });
    const pages = pagesArr.map((item, index) => {
      const className = current === item ?
        "Pagination__page-number Pagination__page-number_active" : "Pagination__page-number";
      let itemComponent = <span key={'page-' + index} id={paginationIds.page(index+1)}
                                className={className}
                                onClick={()=> this.props.setOffset((item-1)*this.props.count)}>{item}</span>;
      let emptyComponent = <span className={'Pagination__page-number Pagination__page-number_empty'} key={'prev-empty-span'}>{"..."}</span>;
      if (item === current - 1 && item !== 1 && item !== 2) {
        itemComponent = [emptyComponent, itemComponent];
      }
      if (item === current + 1 && item !== lastPage && item !== lastPage - 1) {
        itemComponent = [itemComponent, emptyComponent];
      }
      return itemComponent;
    });
    // const backButton = this.props.offset > 0 ? (
    //     <span className={'Pagination__page-number'} onClick={()=> this.props.setOffset( (current - 2)*this.props.count)}
    //           id={paginationIds.buttonBack}>{text.back}</span>
    // ) : null;
    // const forwardButton = current < pages.length ? (
    //     <span className={'Pagination__page-number'} onClick={()=> this.props.setOffset(current*this.props.count)}
    //           id={paginationIds.buttonForward}>{text.forward}</span>
    // ) : null;
    const countValues = [10, 20, 50, 100];
    const count = countValues.map((item) => {
      return this.props.totalCount && (this.props.totalCount < Math.min(...countValues)) ? null :
        <span className={this.props.count === item ? 'Pagination__page-number Pagination__page-number_active' : 'Pagination__page-number'}
              key={'count' + item} id={paginationIds.count(item)}
              onClick={() => this.props.setCount(item)}>{ item }</span>
    });
    const onScrollToTopClick = () => {scrollToTop();};
    return (
      <div className='Pagination pt_3du'>
        <div className="Pagination__pages mb_3du">
          <span className='Pagination__pages-numbers-wrapper'>{pages.length > 1 ? pages : null}</span>
        </div>
        {this.state.isScrollToTopBtnShown ? (
          <div onClick={onScrollToTopClick}
               className="Pagination__page-number Pagination__page-number_scrollToTop">{text.scrollToTop}</div>
        ) : null}
        {this.props.totalCount ? (
          <div className="Pagination__count mb_3du">{count}</div>
        ) : null}
      </div>
    )
  }


}

export default Pagination;