//@flow
import * as React from 'react';
import NInput from "../NInput/NInput";
import type {datePropName, needToInitFocus, propValue} from "../../../../services/flowTypes/componentFlowTypes";
import type {onChange, onFocus, onKeyDown, placeholder} from "../../../../services/flowTypes/appFlowTypes";
import moment from '../../../../services/moment/momentInstance';
import type {resetNeedToInitFocus} from "../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  propValue: propValue,
  onFocus: onFocus,
  onDateInputChange: onChange,
  datePropName: datePropName,
  placeholder?: placeholder,
  onKeyDown?: onKeyDown,
  needToInitFocus?: needToInitFocus,
  resetNeedToInitFocus?: resetNeedToInitFocus
};
type State = {
  dateInputValue: propValue
};

class WrappedDatePickerInput extends React.Component <Props, State> {
  inputDate: { current: null | HTMLInputElement };
  constructor(props: Props) {
    super(props);
    this.state = {
      dateInputValue: ''
    };
    this.inputDate = React.createRef();
  }
  handleFocus = (e:SyntheticEvent<HTMLInputElement>) => {
    this.props.onFocus();
    if (this.state.dateInputValue === '') {
      this.setState({dateInputValue: '__.__.____'}, () => {
        this.inputDate.current.selectionStart = 0;
        this.inputDate.current.selectionEnd = 1;
      });
    }
  };
  convertPropsValueToInputFormat = (date) => {
    return date ? moment(date).format('DD.MM.YYYY') : '';
  }
  convertInputValueToPropsFormat = (date) => {
    return  moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD');
  };
  handleDateInputChange = (e:SyntheticEvent<HTMLInputElement>) => {
    const cursorPosition = e.currentTarget.selectionStart - 1;
    const inputSymbol = e.currentTarget.value.slice(cursorPosition, cursorPosition+1);
    if (/\d/.test(inputSymbol) && e.currentTarget.value.length === 11) {
      const startInputStr = e.currentTarget.value.slice(0, cursorPosition);
      const symbolForSubstitution = e.currentTarget.value.slice(cursorPosition+1, cursorPosition+2);

      const endInputStr = symbolForSubstitution === '.' ?
        symbolForSubstitution + inputSymbol + e.currentTarget.value.slice(cursorPosition+3)
        : inputSymbol + e.currentTarget.value.slice(cursorPosition+2);
      const newInputValue = startInputStr + endInputStr;
      const newInputValueTrimmed = newInputValue.slice(0, 10);
      const newCursorPosition = symbolForSubstitution === '.' ?
        cursorPosition + 2 :
        /^\./.test(e.currentTarget.value.slice(cursorPosition+2)) ? cursorPosition + 2 : cursorPosition + 1;
      this.setState({dateInputValue: newInputValueTrimmed}, ()=>{
        this.inputDate.current.selectionStart = newCursorPosition;
        this.inputDate.current.selectionEnd = newCursorPosition;
      });

    } else {
      this.setState({dateInputValue: this.state.dateInputValue }, ()=>{
        this.inputDate.current.selectionStart = cursorPosition;
        this.inputDate.current.selectionEnd = cursorPosition;
      });
    }

  };
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if (prevProps.propValue !== this.props.propValue
      && this.props.propValue !== this.convertInputValueToPropsFormat(this.state.dateInputValue)) {
      this.setState({dateInputValue: this.convertPropsValueToInputFormat(this.props.propValue)});
    }
    if (prevState.dateInputValue !== this.state.dateInputValue && this.state.dateInputValue.length === 10
      && /\d{2}\.\d{2}\.\d{4}/.test(this.state.dateInputValue)) {
      this.props.onDateInputChange(this.convertInputValueToPropsFormat(this.state.dateInputValue), this.props.datePropName);
    }
    if (prevProps.needToInitFocus !== this.props.needToInitFocus && this.props.needToInitFocus) {
      this.inputDate.current.focus();
      this.props.resetNeedToInitFocus();
    }
  }

  componentDidMount(): * {
    if (this.props.propValue) {
      this.setState({dateInputValue: this.convertPropsValueToInputFormat(this.props.propValue)});
    }
  }

  render() {
    return (
      <NInput value={this.state.dateInputValue} onFocus={this.handleFocus} ref={this.inputDate}
              onKeyDown={this.props.onKeyDown}
              addClasses={'WrappedDatePickerInput'} placeholder={this.props.placeholder}
              onChange={this.handleDateInputChange} id={this.props.startDateId}/>
    );
  }
}

export default WrappedDatePickerInput;    