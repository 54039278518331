//@flow
import * as React from 'react';
import {connect} from "react-redux";
import Pagination from "../../../../components/composed/Pagination/Pagination";
import type {paginationCount, paginationOffset, totalOrganizationsNumber} from "../../../../services/flowTypes/dataFlowTypes";
import organizationsSearchActionTypes from "../../../../store/organizations/organizationsSearch/organizationsSearchActions";
import {onUpdateOrganizationsSearchProp} from "../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  count: paginationCount,
  offset: paginationOffset,
  total: totalOrganizationsNumber,
  onUpdateOrganizationsSearchProp: onUpdateOrganizationsSearchProp
};

class OrganizationsPagination extends React.Component <Props> {
  setOffset = (offset) => {
    const organizationsSearchObj = {offset};
    this.props.onUpdateOrganizationsSearchProp(organizationsSearchObj);
  };
  setCount = (count) => {
    const organizationsSearchObj = { count, offset: 0 };
    this.props.onUpdateOrganizationsSearchProp(organizationsSearchObj);
  };
  render() {
    return (
      <Pagination
        offset={this.props.offset}
        count={this.props.count}
        totalCount={this.props.total}
        setOffset={this.setOffset}
        setCount={this.setCount}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    count: state.organizations.search.count,
    offset: state.organizations.search.offset,
    total: state.organizations.searchResults.total
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateOrganizationsSearchProp: (organizationsSearchObj) => dispatch({type: organizationsSearchActionTypes.ORG_SEARCH_PROP_UPDATE,
      value: organizationsSearchObj})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsPagination);