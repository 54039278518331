//@flow
import * as React from 'react';
import type {organizationId} from "../../../services/flowTypes/dataFlowTypes";
import type {history} from "../../../services/flowTypes/appFlowTypes";

type Props = {
  correctOrganizationId: organizationId,
  history: history
};

class RedirectToCorrectOrgUrl extends React.Component <Props> {
  componentDidUpdate(prevProps:Props){
    if (prevProps.correctOrganizationId !== this.props.correctOrganizationId && this.props.correctOrganizationId !== null) {
      let re = /\/organizations\/\d+\//gi;
      const correctOrgIdUrlPrefix = '/organizations/' + this.props.correctOrganizationId + "/"
      const correctOrgUrl = this.props.history.location.pathname.replace(re, correctOrgIdUrlPrefix);
      this.props.history.push({pathname: correctOrgUrl});
    }
  }
  render() {
    return (this.props.children);
  }
}

export default RedirectToCorrectOrgUrl;    