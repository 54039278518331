//@flow
import * as React from 'react';
import type {children, htmlFor, id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import './NLabel.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  htmlFor?: htmlFor
};

const NLabel = (props: Props) => {
  return (
    <label id={props.id} htmlFor={props.htmlFor} className={baseClass("NLabel", props.addClasses)}>{props.children}</label>
  );
};

export default NLabel;