//@flow
import * as React from 'react';
import './Tooltip.css';
import {baseClass} from "../utilities/baseClass";
import type {children, id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";


type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children
};

const Tooltip = (props: Props) => (
		<div id={props.id} className={baseClass("Tooltip", props.addClasses)}>{props.children}</div>
	);

export default Tooltip;