//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  errorObject, history,
  isReadyToRender,
  locationType,
  match
} from "../../../../../../services/flowTypes/appFlowTypes";
import {onGetSearchData} from "../../../../../../services/flowTypes/propFnFlowTypes";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import ContractsSearchResults from "../../../../../Contracts/contracts/ContractsSearchResults";
import api from "../../../../../../services/apiInstance/api";
import ConnectedPagination
  from "../../../../../../components/composed/SearchPage/SearchBox/ConnectedPagination/ConnectedPagination";
import type {
  contractStatus, organizationId,
  paginationCount,
  paginationOffset
} from "../../../../../../services/flowTypes/dataFlowTypes";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import searchDataActions from "../../../../../../store/organization/searchData/searchDataActions";
import GetDataOnMountAndUpdate
  from "../../../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import type {
  onGetLinkedItemsRequestParamsProp,
  onInitLinkedItemsRequestParamsState
} from "../../../../../../services/flowTypes/propFnFlowTypes";
import SearchPageFilters from "../../../../../../components/composed/SearchPage/SearchBox/SearchPageFilters";
import linkedItemsRequestParamsActions
  from "../../../../../../store/organization/linkedItemsRequestParams/linkedItemsRequestParamsActions";
import handleCorrectOrgIdInResponse from "../../../../../../utils/handleCorrectOrgIdInResponse";
import RedirectToCorrectOrgUrl
  from "../../../../../../components/service/RedirectToCorrectOrgUrl/RedirectToCorrectOrgUrl";
import "./AgentContracts.css";

type Props = {
  match: match,
  count: paginationCount,
  offset: paginationOffset,
  onGetSearchData: onGetSearchData,
  location: locationType,
  onGetLinkedItemsRequestParamsProp: onGetLinkedItemsRequestParamsProp,
  onInitLinkedItemsRequestParamsState: onInitLinkedItemsRequestParamsState,
  status: contractStatus,
  history: history
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  correctOrganizationId: organizationId
};

class AgentContracts extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      correctOrganizationId: null
    };
  }
  handleResponse = (responseData) => {
    this.props.onGetSearchData(responseData);
    this.setState({isReadyToRender: true});
  };
  getAgentContracts = () => {
    const agentId = +this.props.match.params.id;
    api.getAgentContracts(agentId, this.props.count, this.props.offset, this.props.status)
      .then(response => {
        handleCorrectOrgIdInResponse(this, response.data, this.setState, this.handleResponse);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
    this.setState({isReadyToRender: true});
  };
  componentDidUpdate(prevProps){
    if (prevProps.count !== this.props.count || prevProps.offset !== this.props.offset ||
      (this.props.status !== prevProps.status && prevProps.status !== null && this.props.status !== null)) {
      this.getAgentContracts();
    }
  }
  componentDidMount() {
    this.props.onInitLinkedItemsRequestParamsState();
    const searchRequestObj = {
      category: 'contracts'
    };
    this.props.onGetLinkedItemsRequestParamsProp(searchRequestObj);
    this.setState({isReadyToRender: true});
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
        <SearchPageFilters isLinkedItemsMode={true} addClasses={"Filters_sticky"}/>
        {this.props.status !== null ? (
            <RedirectToCorrectOrgUrl correctOrganizationId={this.state.correctOrganizationId} history={this.props.history}>
              <GetDataOnMountAndUpdate itemId={this.props.match.params.id} getDataOnMountAndUpdate={this.getAgentContracts}>
                <div className="AgentContracts">
                  <ContractsSearchResults shouldSearchResultsUpdate={true} isWideMode={true}/>
                  <ConnectedPagination shouldSearchResultsUpdate={true}/>
                </div>
              </GetDataOnMountAndUpdate>
            </RedirectToCorrectOrgUrl>
        ) : null}
      </RenderOnReady>
    );
  }
}

const mapStateToProps = state => {
  return {
    status: state.organization.linkedItemsRequestParams.hasOwnProperty('status') ?
      state.organization.linkedItemsRequestParams.status : null,
    count: state.organization.searchRequest.count,
    offset: state.organization.searchRequest.offset
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetSearchData: (data) => dispatch({type: searchDataActions.SEARCH_DATA_SET, value: data}),
    onGetLinkedItemsRequestParamsProp: (searchRequestObj) => dispatch({type: linkedItemsRequestParamsActions.LINKED_ITEMS_REQUEST_PARAMS_PROP_SET,
      value: searchRequestObj}),
    onInitLinkedItemsRequestParamsState: () => dispatch({type: linkedItemsRequestParamsActions.LINKED_ITEMS_REQUEST_PARAMS_STATE_INIT})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentContracts);