//@flow
import * as React from 'react';
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './DropMenuComponents.css';
import CustomDropDownContainer from "../CustomDropDownContainer/CustomDropDownContainer";
import type {filtersListMenuItem} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  headerComponentFn: Function,
  aboveItemsComponent?: React.Node,
  menuItemsList: Array<filtersListMenuItem>,
  customOptionsContainerStyle?: Object
};

const DropMenuComponents = (props: Props) => {
  const menuItemsList = props.menuItemsList.map((item, i) => {
    return (
      <div key={"menuItem"+i} className="DropMenuComponents__menuLabel">
        <div className="DropMenuComponents__menuLabelArrow">{"<"}</div>
        {item.menuLabel}
        {item.afterMenuLabelComponent}
        <div className="DropMenuComponents__componentContainer">{item.component}</div>
      </div>
    );
  });
  return (
    <CustomDropDownContainer headerComponentFn={props.headerComponentFn} addClasses={props.addClasses}
                             customOptionsContainerStyle={props.customOptionsContainerStyle}>
      {props.aboveItemsComponent}
      {menuItemsList}
    </CustomDropDownContainer>
  );
};

export default DropMenuComponents;