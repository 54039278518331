//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {
  errorObject,
  history,
  isReadyToRender,
  locationType,
  match
} from "../../../../../../services/flowTypes/appFlowTypes";
import api from "../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import type {
  agentsList,
  configFinTermParams,
  currencyOptions,
  groupFinTerm,
  groupFinTermName,
  payoutPeriodOptions,
  sourceOptions,
  typeOptions
} from "../../../../../../services/flowTypes/dataFlowTypes";
import {
  onInitGroupFinTermsEdit,
  onSetDataToValidationState,
  onSetGroupFinTermsEdit
} from "../../../../../../services/flowTypes/propFnFlowTypes";
import groupFinTermsEditActions
  from "../../../../../../store/organization/item/groupFinTermsEdit/groupFinTermsEditActions";
import validationActions from "../../../../../../store/validation/validationActions";
import text from "../../../../../../services/localization/text";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import type {selectOptions} from "../../../../../../services/flowTypes/componentFlowTypes";
import getCurrencyLabel from "../../../../../../utils/getCurrencyLabel";
import SetFinTermsToEdit from "../GroupFinTermsEdit/groupFinTermsEdit/SetFinTermsToEdit/SetFinTermsToEdit";
import {
  initialState as groupFinTermInitState
} from "../../../../../../store/organization/item/groupFinTermsEdit/groupFinTermsEdit";
import SectionDivider from "../../../../../../components/simple/Containers/SectionDivider/SectionDivider";
import SectionHeader from "../../../../../../components/composed/SectionHeader/SectionHeader";
import PropValueView from "../../../../../../components/composed/PropValueView/PropValueView";
import TextOverflowHandler
  from "../../../../../../components/simple/Containers/TextOverflowHandler/TextOverflowHandler";
import GetDataOnMountAndUpdate
  from "../../../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import {formatWithDelimiter} from "../../../../../../utils/formatInputWithDelimiter";
import {groupFinTermEditPageIds} from "../../../../../../tests/testIds";
import clientError404 from "../../../../../../services/apiInstance/clientError404";
import AlertPaynetSync from "../../../../../../components/simple/Containers/AlertCard/AlertPaynetSync";
import {isSourceParamVisible} from "../../../../../../utils/sourceParamsUtil";

type Props = {
  match: match,
  onSetGroupFinTermsEdit: onSetGroupFinTermsEdit,
  onInitGroupFinTermsEdit: onInitGroupFinTermsEdit,
  onSetDataToValidationState: onSetDataToValidationState,
  history: history,
  name: groupFinTermName,
  agentsList: agentsList,
  currencyOptions: currencyOptions,
  payoutPeriodOptions: payoutPeriodOptions,
  sourceOptions: sourceOptions,
  typeOptions: typeOptions,
  configFinTermParams: configFinTermParams,
  groupFinTerm: groupFinTerm,
  location: locationType,
  ignoreGates: boolean
};
type State = {
    errorObject: errorObject,
    isReadyToRender: isReadyToRender,
    agentsOptions: selectOptions,
    currencySwitcherOptions: selectOptions,
    payoutPeriodSwitcherOptions: selectOptions,
    sourceSelectOptions: selectOptions,
    typeSelectOptions: selectOptions
};
export const filterOldPneSourceParams = (source, sourceParams) => {
  if (source === "PNE" || source === "PNE_EU") {
    const deletedPneSourceParamsNames = ["gatewayId", "managerId", "merchantId", "processorId", "projectId", "terminalId"];
    const filteredSourceParams = sourceParams.filter(item => !deletedPneSourceParamsNames.includes(item.name));
    return filteredSourceParams;
  } else {
    return sourceParams;
  }
};

export const prepareGroupFinTermToStore = (groupFinTerm, configFinTermParams) => {
  //TODO: it's duplicated with similar fn in GroupFinTermsView, but corrections on backend has to get rid of this fn
  const sampleRow = configFinTermParams[groupFinTerm.type];
  const finTerms = groupFinTerm.finTerms.map(finTerm => {
    const params = finTerm.params.map(paramsRow => {
      paramsRow.sort((a, b)=>{
        return sampleRow.findIndex(item => item.name === a.name) - sampleRow.findIndex(item => item.name === b.name);
      });
      const newParamsRow = paramsRow.map(param => {
        if (!param.value) {
          param.value = '';
        }
        return param;
      });
      return newParamsRow;
    });
    return {...finTerm, params};
  });
  // const sourceParams = filterOldPneSourceParams(groupFinTerm.source, groupFinTerm.sourceParams);
  // return {...groupFinTerm, finTerms, sourceParams};
  return {...groupFinTerm, finTerms};
};

class FinTermsView extends React.Component <Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isReadyToRender: false,
            errorObject: null,
            agentsOptions: [],
            currencySwitcherOptions: [],
            payoutPeriodSwitcherOptions: [],
            sourceSelectOptions: [],
            typeSelectOptions: [],
        };
    }

    getAgent = (propName) => {
      if (!this.props.groupFinTerm.hasOwnProperty(propName)) {
        return null;
      }
      const agentArr = this.state.agentsOptions.filter((item) => {return item.value === this.props.groupFinTerm[propName]});
      return agentArr.length ? agentArr[0].label : null;
    };

    setGroupFinTermsEdit = (groupFinTerm) => {
        this.props.onSetGroupFinTermsEdit(groupFinTerm);
        this.props.onSetDataToValidationState(groupFinTerm);
        this.setState({isReadyToRender: true});
    };

    getDataForView = () => {
        // this.props.onInitValidationState();
        const contractId = +this.props.match.params.id;
        this.props.onInitGroupFinTermsEdit({contractId});
        const groupId = +this.props.match.params.groupId;
        if (groupId) {
          api.getGroupFinTerms({contractId})
            .then(response => {
              const groupFinTerm = response.data.find(item => item.groupId === groupId);
              if (groupFinTerm) {
                const preparedGroupFinTerm = prepareGroupFinTermToStore(groupFinTerm, this.props.configFinTermParams);
                this.setGroupFinTermsEdit(preparedGroupFinTerm);
                //TODO: consider to add groupFinTerms from response to store
                this.setState({isReadyToRender: true});
              } else {
                this.setState({
                  isReadyToRender: true,
                  errorObject: apiErrorHandler(clientError404)
                });
              }
            })
            .catch((error) => {
              this.setState({
                isReadyToRender: true,
                errorObject: apiErrorHandler(error)
              });
            });
        } else {
            const initState = {...groupFinTermInitState, contractId: contractId};
            this.props.onSetDataToValidationState(initState);
        }
        const agentsOptions = this.props.agentsList.map(item => {
            return {value: item.id, label: item.name}
        });
        const currencySwitcherOptions = this.props.currencyOptions.map(item => {
            return {value: item, label: getCurrencyLabel(item)};
        });
        const payoutPeriodSwitcherOptions = this.props.payoutPeriodOptions.map(item => {
            return {value: item, label: text[item]};
        });
        const sourceSelectOptions = this.props.sourceOptions.map(item => {
            return {value: item, label: text[item]};
        });
        const typeSelectOptions = this.props.typeOptions.map(item => {
            return {value: item, label: text[item]};
        });
        this.setState({
            isReadyToRender: true,
            agentsOptions, currencySwitcherOptions, payoutPeriodSwitcherOptions, sourceSelectOptions, typeSelectOptions});
    };

    render() {
        const groupFinTermId = this.props.match.params.groupId ? +this.props.match.params.groupId : null;
        const payer = this.getAgent("payerId");
        const payee = this.getAgent("payeeId");
        const payerMerchant = this.getAgent("payerMerchantId");
        const payeeMerchant = this.getAgent("payeeMerchantId");
        const typeArr = this.state.typeSelectOptions.filter((item) => {return item.value === this.props.groupFinTerm.type});
        const type = typeArr.length ? typeArr[0].label : null;
        const sourceParams = this.props.groupFinTerm.sourceParams
          .filter(item => isSourceParamVisible(item.name, this.props.ignoreGates))
          .map((item, i) => {
          const value = ['usersExcluded', 'procIdsExcluded', 'mngrMercIdsExcluded', 'mngrIdsExcluded', 'mercIdsExcluded'].indexOf(item.name) > -1
            ? text.usersExcludedValue[item.value]
            : (item.type === 'DELIMITED_LIST' || item.type === 'DELIMITED_TEXT')
              ? formatWithDelimiter(item.value, ',')
              : item.value;
          return (
            <PropValueView key={'sourceParam' + i}
                           label={text.sourceParams.viewLabel[this.props.groupFinTerm.source][item.name]}
                           value={value}
                           addClasses={"mr_2du mb_1du"}
            />
          );
        });
        const gates = this.props.groupFinTerm.gates ? this.props.groupFinTerm.gates.map((item, i) => {
          const terminalIDs = item.terminals.map(terminal => terminal.terminalId);
          return item.isIgnored ? null : (<div className="flex mb_half-du" key={'gate'+i}>
            <PropValueView label={text.gateIdLabel} value={item.gateId} addClasses={"mr_2du"}/>
            <PropValueView label={text.terminalsLabel} value={terminalIDs.join(", ")} addClasses={"mr_2du"}/>
          </div>);
        }) : [];
        return (
          <GetDataOnMountAndUpdate itemId={groupFinTermId} getDataOnMountAndUpdate={this.getDataForView}>
          <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
            {groupFinTermId === this.props.groupFinTerm.groupId ? (
              <div className="FinTermView">
                <NCard addClasses={'mb_3du'}>
                  {this.props.groupFinTerm.isNotCompliedWithPaynetParams ? (
                    <AlertPaynetSync addClasses={"mb_3du"}
                                     agentIdIsNotCompliedWIthPaynetParams={this.props.groupFinTerm.agentIdIsNotCompliedWIthPaynetParams}
                                     organizationId={this.props.match.params.organizationId}/>
                  ) : null}
                  <PropValueView label={text.name} value={this.props.groupFinTerm.name} addClasses={"mb_3du"}
                                 testIdForValue={groupFinTermEditPageIds.groupFinTermName}/>
                  <input type="hidden" id={groupFinTermEditPageIds.inputHiddenGroupFinTermId} value={groupFinTermId}/>
                  <div className="flex_jc-sb mb_1du">
                    <PropValueView label={text.payer} value={
                      <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{payer}</TextOverflowHandler>
                    } addClasses={"mb_1du w_half-card"}/>
                    <PropValueView label={text.payee} value={
                      <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{payee}</TextOverflowHandler>
                    } addClasses={"mb_1du w_half-card"}/>
                  </div>
                  {payerMerchant || payeeMerchant ? (
                    <div className="flex_jc-sb mb_1du">
                      <div className="w_half-card">
                        {payerMerchant ? (
                          <PropValueView label={text.payerMerchantId} value={
                            <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{payerMerchant}</TextOverflowHandler>
                          } addClasses={"mb_1du w_half-card"}/>
                        ) : null}
                      </div>
                      <div className="w_half-card">
                        {payeeMerchant ? (
                          <PropValueView label={text.payeeMerchantId} value={
                            <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{payeeMerchant}</TextOverflowHandler>
                          } addClasses={"mb_1du w_half-card"}/>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  <div className="flex_jc-sb">
                    <div className="w_half-card">
                      <PropValueView label={text.currency} value={this.props.groupFinTerm.currency}/>
                    </div>
                    <div className="w_half-card">
                      <PropValueView label={text.payoutPeriod} value={text[this.props.groupFinTerm.payoutPeriod]}/>
                    </div>
                  </div>
                  <SectionDivider addClasses={'mt_5du mb_5du'}/>
                  <SectionHeader sectionHeaderTitle={text.header1c} addClasses={"mb_3du"}/>
                  <div className="flex_jc-sb mb_1du">
                    <PropValueView label={text.nomenNumber1c} value={this.props.groupFinTerm.nomenNumber1c} addClasses={'w_half-card'}/>
                    <PropValueView label={text.projectName} value={this.props.groupFinTerm.projectName} addClasses={'w_half-card'}/>
                  </div>
                  <div>
                    <PropValueView label={text.ignoreInExport} value={text.ignoreInExportValue(this.props.groupFinTerm.ignoreInExport)}/>
                  </div>


                  <SectionDivider addClasses={'mt_5du mb_5du'}/>
                  <SectionHeader sectionHeaderTitle={text.newSourceParamsSectionTitle} addClasses={"mb_3du"}/>
                  <div className="flex">
                    <PropValueView label={text.source} value={text.sourceNames[this.props.groupFinTerm.source]} addClasses={"mb_2du"}/>
                  </div>
                  <div className="flex mb_1du flex_wrap">
                    {sourceParams}
                  </div>
                  {gates.length ? <>
                    <SectionHeader sectionHeaderTitle={text.finTermGatesSectionTitle} addClasses={"mb_3du"}/>
                    <div className={"mb_2du"}>{gates}</div>
                  </> : null}
                  {this.props.groupFinTerm.customFunction ? (
                    <PropValueView label={text.customFunction} value={this.props.groupFinTerm.customFunction}
                                   addClasses={'mt_2du'}/>
                  ) : null}
                  <div className="flex mt_3du">
                    <PropValueView label={text.terms} value={type} addClasses={"mb_1du"}/>
                  </div>

                </NCard>
                <SetFinTermsToEdit readonly={true}/>
              </div>
            ) : null}
          </RenderOnReady>
          </GetDataOnMountAndUpdate>
        );
    }
}

const mapStateToProps = state => {
  const sourceOptions = state.organization.organizationData.configFinTerms.sourceOptions ?
    state.organization.organizationData.configFinTerms.sourceOptions : [];
    return {
        name: state.organization.item.groupFinTermsEdit.name,
        agentsList: state.organization.organizationData.agentsList,
        currencyOptions: state.organization.organizationData.configFinTerms.currencyOptions,
        payoutPeriodOptions: state.organization.organizationData.configFinTerms.payoutPeriodOptions,
        sourceOptions,
        typeOptions: state.organization.organizationData.configFinTerms.typeOptions,
        configFinTermParams: state.organization.organizationData.configFinTerms.configFinTermParams,
        groupFinTerm: state.organization.item.groupFinTermsEdit,
        ignoreGates: state.organization.item.contractMainData.ignoreGates
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitGroupFinTermsEdit: (initGroupFinTermObj) => dispatch({
            type: groupFinTermsEditActions.GROUP_FIN_TERMS_EDIT_INIT, value: initGroupFinTermObj}),
        onSetGroupFinTermsEdit: (groupFinTerm) => dispatch({
            type: groupFinTermsEditActions.GROUP_FIN_TERMS_EDIT_SET, value: groupFinTerm}),
        onSetDataToValidationState: (data) => dispatch({
            type: validationActions.VALIDATION_STATE_SET,
            value: data}),
        // onInitValidationState: () => dispatch({type: validationActions.VALIDATION_STATE_INIT})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinTermsView);