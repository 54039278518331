//@flow
import * as React from 'react';
import type {isSupLevel, itemType} from "../../../../../../../services/flowTypes/componentFlowTypes";
import NHeading1 from "../../../../../../../components/simple/TextComponents/NHeading1/NHeading1";
import text from "../../../../../../../services/localization/text";
import Can from "../../../../../../../services/permissions/Can";
import ItemParamsList from "./ItemParamsList/ItemParamsList";
import {initUpdateOrganizationParams} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import {organizationParamsPageIds} from "../../../../../../../tests/testIds";
import EditItemParam from "./ItemParamsList/ItemParamCard/EditItemParam/EditItemParam";
import {globalPermissionsStrValues, permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";

type Props = {
  itemType: itemType,
  initUpdateOrganizationParams: initUpdateOrganizationParams,
  isSupLevel?: isSupLevel
};

const ItemParams = (props: Props) => {
  const capItemType = props.itemType.replace(props.itemType[0], props.itemType[0].toUpperCase());
  const addButton = (
    <EditItemParam
      itemType={props.itemType}
      btn={<div className="BtnNewItem pl-pr_2du" id={organizationParamsPageIds.buttonAdd + capItemType + 'Params'}>
        {text.btnAdd}</div>}
      editOrAdd={'add'}
      initUpdateOrganizationParams={props.initUpdateOrganizationParams}
    />
  );
  return (
    <div className="ItemParams mb_3du">
      <div className="flex_jc-sb mb_3du">
        <NHeading1>{text[props.itemType + 'AddParams']}</NHeading1>
        {props.isSupLevel ? (
          <Can do={'useGlobalPermission'} on={globalPermissionsStrValues.admin}>
            {addButton}
          </Can>
        ) : (
          <Can do={'usePermission'} on={permissionsStrValues.organizationParams.update}>
            {addButton}
          </Can>
        )}
      </div>
      <ItemParamsList itemType={props.itemType} initUpdateOrganizationParams={props.initUpdateOrganizationParams}
                      isSupLevel={props.isSupLevel}/>
    </div>
  );
};

export default ItemParams;    