//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id, onClick, disabled} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './BtnDel.css';
import type {disabledReasonTooltipText, isCardButtonMode} from "../../../../services/flowTypes/componentFlowTypes";
import ButtonTooltipContainer from "../../Containers/ButtonTooltipContainer/ButtonTooltipContainer";

type Props = {
  id?: id,
  addClasses?: addClasses,
  onClick: onClick,
  disabled?: disabled,
  isCardButtonMode?: isCardButtonMode,
  isTagExpressionHeaderMode?: boolean,
  isTagExpressionMode?: boolean,
  disabledReasonTooltipText?: disabledReasonTooltipText
};

const BtnDel = (props: Props) => {
  const className = props.isCardButtonMode ? "BtnDel_cardButtonMode" :
    props.isTagExpressionHeaderMode ? "BtnDel_tagExpressionHeaderMode" :
    props.isTagExpressionMode ? "BtnDel_tagExpressionMode" :
    "BtnDel";
  const disabledReasonTooltipText = props.disabled && props.disabledReasonTooltipText ?
    props.disabledReasonTooltipText : '';
  return props.disabled && props.disabledReasonTooltipText ? (
    <ButtonTooltipContainer tooltipContent={disabledReasonTooltipText} addClasses={'ButtonTooltipContainer_BtnDel'}
                            tooltipPosition={'top'}>
      <button type="button" id={props.id} onClick={props.onClick} disabled={props.disabled}
              title={disabledReasonTooltipText}
              className={baseClass(className, props.addClasses)}>

        <span className="BtnDel__ico-container"/>
      </button>
    </ButtonTooltipContainer>
  ) : (
    <button type="button" id={props.id} onClick={props.onClick} disabled={props.disabled}
            title={disabledReasonTooltipText}
         className={baseClass(className, props.addClasses)}>
      <span className="BtnDel__ico-container"/>
    </button>
  );
};

export default BtnDel;