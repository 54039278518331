//@flow
import historyDataActions from './historyDataActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {historyData} from "../../../services/flowTypes/dataFlowTypes";

type State = historyData;
type reducerAction = action;
const initialState = {
  total: 0,
  viewHistory: {
    allNewIsUnviewed: false,
    finTermsNewIsUnviewed: false,
    actTemplatesNewIsUnviewed: false,
  },
  historyItems: null
};

const historyReducer = (state:State = initialState, action:reducerAction) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case historyDataActions.HISTORY_INIT:
      return initialState;
    case historyDataActions.HISTORY_SET:
      return action.value;
    case historyDataActions.HISTORY_VIEW_STATUS_SET:
      newState.viewHistory = {...newState.viewHistory, ...action.value};
      return newState;
    default:
      return state;
  }
};

export default historyReducer;