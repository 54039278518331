//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './SearchRequestLoadingIcon.css';

type Props = {
  id?: id,
  addClasses?: addClasses
};

const SearchRequestLoadingIcon = (props: Props) => {
  return (
    <div id={props.id} className={baseClass("SearchRequestLoadingIcon", props.addClasses)}/>
  );
};

export default SearchRequestLoadingIcon;