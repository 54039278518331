import ability from "../../../services/permissions/permissions";
import {rolesStrValues} from "../../../configData/permissionsStrValues";

export const canUseOnlyForRoles = (onlyForRoles:Array<string>) => {
  let isCan = false;
  for (let i=0; i < onlyForRoles.length; i++) {
    isCan = isCan || ability.can('useRole', onlyForRoles[i]);
  }
  return isCan;
};

const canUseAnyone = () => {
  return (ability.can('useRole', rolesStrValues.lawyer) || ability.can('useRole', rolesStrValues.admin) ||
    ability.can('useRole', rolesStrValues.accountant) || ability.can('useRole', rolesStrValues.manager) ||
    ability.can('useRole', rolesStrValues.chiefAccountant) || ability.can('useRole', rolesStrValues.chiefLawyer));
};
export default canUseAnyone;