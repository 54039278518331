//@flow
import reportManualAdjustmentActions from './reportManualAdjustmentActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {reportManualAdjustedGroupFinTerm} from "../../../../services/flowTypes/dataFlowTypes";
import update from 'immutability-helper';

type State = Array<reportManualAdjustedGroupFinTerm>;
type reducerAction = action;
const initialState = [];

const reportManualAdjustmentReducer = (state:State = initialState, action:reducerAction) => {
  switch (action.type) {
    case reportManualAdjustmentActions.REPORT_FIN_TERMS_EDIT_INIT:
      return initialState;
    case reportManualAdjustmentActions.REPORT_FIN_TERMS_EDIT_SET:
      return action.value;
    case reportManualAdjustmentActions.REPORT_FIN_TERM_PARAM_UPDATE:
      return update(state,
        {[action.value.groupFinTermIndex]: {adjustedParams: {[action.value.paramIndex]: {value: {$set: action.value.propValue}}}}});
    case reportManualAdjustmentActions.REPORT_FIN_TERM_FILE_UPDATE:
      return update(state, {[action.value.groupFinTermIndex]: {file: {$set: action.value.file}}});
    default:
      return state;
  }
};

export default reportManualAdjustmentReducer;