const defineInputMaxLength = (patternArr) => {
  let inputMaxLength = null;
  const maxLengthRegExp = /maxLength/;
  for (let i=0; i < patternArr.length; i++){
    if (patternArr[i].match(maxLengthRegExp)) {
      inputMaxLength = +patternArr[i].replace(maxLengthRegExp, '');
    }
  }
  return inputMaxLength;
};
export default defineInputMaxLength;