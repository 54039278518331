//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../service/RenderOnReady/RenderOnReady";
import alertsActions from "../../../store/alerts/alertsActions";
import type {onAddAlert} from "../../../services/flowTypes/propFnFlowTypes";
import getUniqueKey from "../../../utils/getUniqueKey";
import type {errorObject} from "../../../services/flowTypes/appFlowTypes";

type Props = {
  errorObject: errorObject,
  onAddAlert: onAddAlert
};

class AlertOnError extends React.Component <Props>{
  showAlert = () => {
    if( typeof this.props.errorObject.title === 'string' && (this.props.errorObject.title === 'Error: Network Error'
      || this.props.errorObject.title.indexOf('timeout of 0ms exceeded') > -1)){
      return false; //to avoid alerting
    }
    const newAlert = {
      id: getUniqueKey(),
      type: this.props.errorObject.type,
      errorObject: this.props.errorObject
    };
    this.props.onAddAlert(newAlert);
  };
  componentDidUpdate(prevProps){
    if (this.props.errorObject && prevProps.errorObject !== this.props.errorObject) {
      this.showAlert();
    }
  }
  componentDidMount() {
    this.showAlert();
  }
  render(){
    return (
      <RenderOnReady isReadyToRender={true}/>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAddAlert: (newAlert) => dispatch({
      type: alertsActions.ALERT_ADD, value: newAlert})
  };
};
export default connect(null, mapDispatchToProps)(AlertOnError);