//@flow
import * as React from 'react';
import {connect} from "react-redux";
import text from "../../../../../../../services/localization/text";
import type {groupFinTerm, groupFinTermId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import concatIdName from "../../../../../../../utils/concatIdName";
import getNameOrDefName from "../../../../../../../utils/getNameOrDefName";
import {Link} from "react-router-dom";
import type {history} from "../../../../../../../services/flowTypes/appFlowTypes";
import createHashHistoryObserver from "../../../../../../UiKit/newUiKit/utilities/createHashHistoryObserver";
import type {propName} from "../../../../../../../services/flowTypes/componentFlowTypes";

type Props = {
  finTermGroupIds: Array<groupFinTermId>,
  groupFinTermsView: Array<groupFinTerm>,
  history: history,
  propName: propName
};

class ReportTagExpressionWarning extends React.Component <Props> {
  defineWarningContent = () => {
    switch (this.props.propName) {
      case 'reportTotalCountExpression':
      case 'reportTotalAmountExpression':
        const groupFinTerms = this.props.groupFinTermsView.filter(groupFinTerm => this.props.finTermGroupIds.includes(groupFinTerm.groupId));
        let amountOfFixedTypeGroupFinTerms = 0;
        for (let i=0; i < groupFinTerms.length; i++) {
          if (['FIXED', 'FIXED_NO_PERIOD'].includes(groupFinTerms[i].type)) {
            amountOfFixedTypeGroupFinTerms = amountOfFixedTypeGroupFinTerms + 1;
          }
        }
        return groupFinTerms.length && groupFinTerms.length === amountOfFixedTypeGroupFinTerms ?
          text.reportTagExpressionWarning[this.props.propName] : null;
      case 'reportTotalExpression':
        const minTypeGroupFinTermArr =
          this.props.groupFinTermsView.filter(groupFinTerm => (groupFinTerm.type === 'MIN' && this.props.finTermGroupIds.includes(groupFinTerm.groupId)));

        const minGroupFinTermsLinks = minTypeGroupFinTermArr.map( (groupFinTerm, i) => {
          const linkTo = {hash: "#groupFinTermCard" + groupFinTerm.groupId};
          const groupFinTermLabel = concatIdName(groupFinTerm.groupId, getNameOrDefName(groupFinTerm.name, text.finTerm));
          const separator = minTypeGroupFinTermArr.length - 1 === i ? null : <span>{", "}</span>;
          return (<React.Fragment key={'linkToGroupFinTerm'+i}><Link to={linkTo}>{groupFinTermLabel}</Link>{separator}</React.Fragment>);
        });
        return (minTypeGroupFinTermArr.length ? (
            <>{text.reportTotalExpressionWarning1}
              {minGroupFinTermsLinks}
              {text.reportTotalExpressionWarning2}</>
          ) : null
        );
      default:
        return null;


    }

  };
  componentDidMount(){
    createHashHistoryObserver(this.props.history);
  }
  render() {
    const warningContent = this.defineWarningContent();
    return (
      warningContent ? (
        <div className={`AlertCard__header AlertCard__header_in_ReportTotalExpression AlertCard__header_REPORT-WARNING mt_1du`}>
          <div className="AlertCard__header-ico"/>
          <div className="AlertCard__header-title">{warningContent}
            </div>
        </div>
      ) : null
    );
  }
}

const mapStateToProps = state => {
  return {
    groupFinTermsView: state.organization.item.groupFinTermsView,
    finTermGroupIds: state.organization.item.actTemplatesEdit.finTermGroupIds,
  };
};
export default connect(mapStateToProps, null)(ReportTagExpressionWarning);