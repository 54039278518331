//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {children, errorObject, isReadyToRender} from "../../../../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import api from "../../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../../services/apiInstance/apiErrorHandler";
import type {contractId} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import GetDataOnMountAndUpdate
  from "../../../../../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import addServicesActions from "../../../../../../../../store/organization/item/addServices/addServicesActions";
import type {onGetAddServices} from "../../../../../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  contractId: contractId,
  onGetAddServices: onGetAddServices,
  children: children
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};

class AddServicesFetcher extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: false
    };
  }
  getAddServices = () => {
    this.setState({isReadyToRender: false});
    api.getAddServices(this.props.contractId, "")
      .then(response => {
        this.props.onGetAddServices(response.data);
        this.setState({isReadyToRender: true});
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    return (
      <GetDataOnMountAndUpdate itemId={this.props.contractId} getDataOnMountAndUpdate={this.getAddServices}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
          {this.props.children}
        </RenderOnReady>
      </GetDataOnMountAndUpdate>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetAddServices: (addAgreements) => dispatch({
      type: addServicesActions.ADD_SERVICES_SET, value: addAgreements}),
  };
};

export default connect(null, mapDispatchToProps)(AddServicesFetcher);