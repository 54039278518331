//@flow
import * as React from 'react';
import {baseClass} from "../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {
  children,
  htmlFor,
  id,
  isReadyToRender,
  label,
  placeholder
} from "../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import './../../simple/FormComponents/ParamFormGroup/ParamFormGroup.css';
import type {
  DnDisForbidden,
  labelTooltip,
  onDelParamClick
} from "../../../services/flowTypes/componentFlowTypes";
import ConnectedFormGroup from "../ConnectedFormGroup/ConnectedFormGroup";
import type {
  formFieldType,
  propName,
  propValidationPatternsArr,
  propValue
} from "../../../services/flowTypes/componentFlowTypes";
import {
  onDeleteValidationStateFormProp,
  onGetPropValue,
  onGetValidationStateFormProp
} from "../../../services/flowTypes/propFnFlowTypes";
import BtnDel from "../../simple/Buttons/BtnDel/BtnDel";
import RenderOnReady from "../../service/RenderOnReady/RenderOnReady";
import validationActions from "../../../store/validation/validationActions";
import connect from "react-redux/es/connect/connect";

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  validationState?: any,
  labelTooltip?: labelTooltip,
  label?: label,
  labelForId?: htmlFor,
  onDelParamClick?: onDelParamClick,
  DnDisForbidden?: DnDisForbidden,
  formFieldType: formFieldType,
  propName: propName,
  propValue: propValue,
  onGetPropValue: onGetPropValue,
  propValidationPatternsArr?: propValidationPatternsArr,
  onDeleteValidationStateFormProp: onDeleteValidationStateFormProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  idForTest?: id,
  placeholder?: placeholder,
  btnDelId?: id,
  dataUiTestId?: string
};
type State = {
  isReadyToRender: isReadyToRender
};

//TODO: add type to validationState

class ConnectedParamFormGroup extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false
    };
  }
  componentDidMount(){
    this.props.onGetValidationStateFormProp(this.props.propName, '');
    this.setState({
      isReadyToRender: true
    });
  }
  componentWillUnmount(){
    this.props.onDeleteValidationStateFormProp(this.props.propName);
  }
  render(){
    const className = this.props.DnDisForbidden ? "ParamFormGroup ParamFormGroup_DnDisForbidden" : "ParamFormGroup";
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={null}>
        <div id={this.props.id} className={baseClass(className, this.props.addClasses)}>
          <div className="DnD-ico-container mr_3du"/>
          <ConnectedFormGroup
            formFieldType={this.props.formFieldType}
            propName={this.props.propName}
            propValue={this.props.propValue}
            onGetPropValue={this.props.onGetPropValue}
            label={this.props.label}
            propValidationPatternsArr={this.props.propValidationPatternsArr}
            labelForId={this.props.labelForId}
            labelTooltip={this.props.labelTooltip}
            idForTest={this.props.idForTest}
            placeholder={this.props.placeholder}
            dataUiTestId={this.props.dataUiTestId}
          />
          <BtnDel id={this.props.btnDelId} addClasses={"ml_1du"} onClick={this.props.onDelParamClick}/>
        </div>
      </RenderOnReady>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onDeleteValidationStateFormProp: (prop) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_DELETE, value: prop})
  };
};
export default connect(null, mapDispatchToProps)(ConnectedParamFormGroup);