//@flow
import * as React from 'react';
import text from "../../../../../services/localization/text";
import Can from "../../../../../services/permissions/Can";
import type {
  availableReportAccountStatuses, organizationId, report,
  reportAccountStatus,
  reportIds
} from "../../../../../services/flowTypes/dataFlowTypes";
import ReportAccountStatusModal
  from "../../../pages/Report/pages/ReportMainDataView/UpdateReportAccountStatus/ReportAccounStatusModal/ReportAccountStatusModal";
import api from "../../../../../services/apiInstance/api";
import type {
  isSelectAll,
  updateAvailableReportAccountStatuses
} from "../../../../../services/flowTypes/componentFlowTypes";
import connect from "react-redux/es/connect/connect";
import Checkbox from "../../../../../components/simple/FormComponents/Checkbox/Checkbox";
import {updateReportIds} from "../../../../../services/flowTypes/propFnFlowTypes";
import Text1 from "../../../../../components/simple/TextComponents/Text1/Text1";
import Heading4 from "../../../../../components/simple/TextComponents/Heading4/Heading4";
import createTempLinkToDownloadFile from "../../../../../utils/createTempLinkToDownloadFile";
import apiErrorHandler from "../../../../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../../../../components/service/RenderOnReady/RenderOnReady";
import type {errorObject, isReadyToRender} from "../../../../../services/flowTypes/appFlowTypes";
import {orgPath, reportsSuffix} from "../../../../../services/urlStrings/urlStrings";
import {reportsPageIds} from "../../../../../tests/testIds";
import getUniqueKey from "../../../../../utils/getUniqueKey";
import './BulkUpdateReportAccountStatus.css';
import BtnEditAct from "../../../../../components/simple/Buttons/BtnEditAct/BtnEditAct";
import PayerPayeeFilterFormGroup from "../../ReportsSeparateFilterFormGroup/PayerPayeeFilterFormGroup";
import Download1CExportFileButton from "./Download1CExportFileButton/Download1CExportFileButton";
import {permissionsStrValues} from "../../../../../configData/permissionsStrValues";

type Props = {
  items: Array<report>,
  availableReportAccountStatuses: availableReportAccountStatuses,
  reportIds: reportIds,
  reportAccountStatus: reportAccountStatus,
  updateAvailableReportAccountStatuses: updateAvailableReportAccountStatuses,
  updateReportIds: updateReportIds,
  history: Object,
  organizationId: organizationId
};
type State = {
  isSelectAll: isSelectAll,
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};

class BulkUpdateReportAccountStatus extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSelectAll: false,
      errorObject: null,
      isReadyToRender: true
    };
  }
  download1CExportFile = () => {
    this.setState({isReadyToRender: false});
    const requestBody = this.props.reportIds;
    api.download1CExportFile(requestBody)
      .then(response => {
        this.setState({isReadyToRender: true});
        const fileName = "1CExportFile_" + getUniqueKey() + ".csv";
        createTempLinkToDownloadFile(response.data, fileName);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });

  };
  handleSelectAll = () => {
    if (!this.state.isSelectAll) {
      const reportIds = this.props.items.map(item => item.id);
      this.props.updateReportIds(reportIds);
      this.setState({isSelectAll: true});
    } else {
      this.props.updateReportIds([]);
      this.setState({isSelectAll: false});
    }
  };
  requestFn = (newReportAccountStatusId, handleResponse, handleError) => {
    const requestBody = {
      reportIds: this.props.reportIds,
      currentReportAccountStatusId: this.props.reportAccountStatus,
      newReportAccountStatusId: newReportAccountStatusId.id
    };
    api.bulkUpdateReportState(requestBody)
      .then(response => {
        handleResponse();
        const path = orgPath(':organizationId') + reportsSuffix + '/redirect-to-prepared';
        this.props.history.push({pathname: path});
      })
      .catch(error => {
        handleError(error);
      });
  };
  handleUpdateAvailableReportAccountStatuses = () => {
    const firstReportId = this.props.reportIds[0];
    const availableReportAccountStatuses = firstReportId ?
      this.props.items.find(item => item.id === firstReportId).availableReportAccountStatuses : [];
    this.props.updateAvailableReportAccountStatuses(availableReportAccountStatuses);
  };
  componentDidUpdate(prevProps){
    if (prevProps.reportIds !== this.props.reportIds) {
      this.handleUpdateAvailableReportAccountStatuses();
      //handle case when one of reports has been unselected after selectAll
      if (this.state.isSelectAll && this.props.reportIds.length < this.props.items.length) {
        this.setState({isSelectAll: false});
      }
    }
  }
  componentDidMount(){
    this.handleUpdateAvailableReportAccountStatuses();
  }
  render() {
    const isBtnDisabled = !this.props.reportIds.length;
    return (
      <div className={"BulkUpdateReportAccountStatus NCard NCard__wide-width mt_3du"}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <div className="ChosenNumber-and-UpdateStatusBtn-container">
          <div className={"flex ai_c"} onClick={this.handleSelectAll}>
            <Checkbox id={reportsPageIds.buttonCheckAll} addClasses={'mr_3du'} checked={this.state.isSelectAll}/>
            <Text1 addClasses={'Text1_card-label-color Text1_uppercase Text1_bold mr_1du'}>{text.areChosen}</Text1>
            <Heading4 id={reportsPageIds.divCheckedReportsNumber} addClasses={"Heading4_main-accent-color Heading4_lh16"}>
              {this.props.reportIds.length}</Heading4>
          </div>
          <Can do={'usePermission'} on={permissionsStrValues.report.updateState}>
            <ReportAccountStatusModal
              availableReportAccountStatuses={this.props.availableReportAccountStatuses}
              requestFn={this.requestFn}
              updateStatusBtn={
                <BtnEditAct id={reportsPageIds.buttonBulkUpdateStatus} disabled={isBtnDisabled}
                            addClasses={"BtnEditAct_dashed"}>
                  {text.updateStatus}</BtnEditAct>
              }
            />
          </Can>
        </div>
        <div className="Filters_and_1Cbutton">
          <div className="AgentFilters">
            <PayerPayeeFilterFormGroup propName={'payerId'} />
            <div className="AgentFilters__delimiter"/>
            <PayerPayeeFilterFormGroup propName={'payeeId'} />
          </div>
          <Can do={'usePermission'} on={permissionsStrValues.report.view}>
            <Download1CExportFileButton id={reportsPageIds.buttonBulkDownload1C} onClick={this.download1CExportFile}
                                        disabled={isBtnDisabled}
            />
          </Can>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    items: state.organization.reports.items,
    organizationId: state.organization.organizationId
  };
};

export default connect(mapStateToProps, null)(BulkUpdateReportAccountStatus);