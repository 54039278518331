//@flow
import * as React from 'react';
import SearchPage from "../../components/composed/SearchPage/SearchPage";
import text from "../../services/localization/text";
import {Link} from "react-router-dom";
import type {errorObject, history, isReadyToRender, match} from "../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../components/service/RenderOnReady/RenderOnReady";
import searchRequestActions from "../../store/organization/searchRequest/searchRequestActions";
import connect from "react-redux/es/connect/connect";
import {onGetSearchRequestProp, onInitSearchRequestState} from "../../services/flowTypes/propFnFlowTypes";
import Can from "../../services/permissions/Can";
import {agentsPageIds} from "../../tests/testIds";
import {newAgentSuffix, orgPath} from "../../services/urlStrings/urlStrings";
import {permissionsStrValues} from "../../configData/permissionsStrValues";
import BtnNewItem from "../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  match: match,
  onGetSearchRequestProp: onGetSearchRequestProp,
  onInitSearchRequestState: onInitSearchRequestState,
  history: history
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject
};

class Agents extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null
    };
  }
  componentDidMount(){
    this.props.onInitSearchRequestState();
    const searchRequestObj = {
      offset: 0,
      category: 'agents'
    };
    this.props.onGetSearchRequestProp(searchRequestObj);
    this.setState({isReadyToRender: true});
  }
  render(){
    const organizationId = this.props.match.params.organizationId;
    const pageTitle = text.agents;
    const button = (
      <Can do={'usePermission'} on={permissionsStrValues.agent.create} key={'newAgentBtn'}>
        <Link to={orgPath(organizationId) + newAgentSuffix} data-ui-test-id={agentsPageIds.linkToNewAgent}>
          <BtnNewItem addClasses={"BtnNewItem_grey"} id={agentsPageIds.buttonNewAgent}>{text.newAgent}</BtnNewItem>
        </Link>
      </Can>
    );

    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
        <SearchPage
          pageTitle={pageTitle}
          button={button}
          history={this.props.history}
          pathname={this.props.location.pathname}
        />
      </RenderOnReady>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetSearchRequestProp: (searchRequestObj) => dispatch({type: searchRequestActions.SEARCH_REQUEST_PROP_SET,
      value: searchRequestObj}),
    onInitSearchRequestState: () => dispatch({type: searchRequestActions.SEARCH_REQUEST_STATE_INIT})
  };
};

export default connect(null, mapDispatchToProps)(Agents);