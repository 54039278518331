//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {totalOrganizationsNumber} from "../../../services/flowTypes/dataFlowTypes";

type Props = {
  total: totalOrganizationsNumber
};

class OrganizationsTotal extends React.Component <Props> {
  render() {
    return (<>{this.props.total}</>);
  }
}

const mapStateToProps = state => {
  return {
    total: state.organizations.searchResults.total,
  };
};

export default connect(mapStateToProps, null)(OrganizationsTotal);