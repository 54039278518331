//@flow
import * as React from 'react';
import Textarea from "../../../../../../../../components/simple/FormComponents/Textarea/Textarea";
import type {reportTagExpression} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import type {disabled} from "../../../../../../../../services/flowTypes/appFlowTypes";

type Props = {
  isTextareaShown: boolean,
  reportTagExpression: reportTagExpression,
  expressionIndex: number,
  update?: Function,
  disabled?: disabled
};

class ReportTagExpressionTextarea extends React.Component <Props> {
  handleInputChange = (e:SyntheticEvent<HTMLInputElement>) => {
    this.props.update(e.currentTarget.value);
  };
  render() {
    return this.props.isTextareaShown ?
      (<Textarea value={this.props.reportTagExpression.expression} onChange={this.handleInputChange}
                 disabled={this.props.disabled}/>)
      : null;
  }
}

export default ReportTagExpressionTextarea;    