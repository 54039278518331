//@flow
import * as React from 'react';
import text from "../../services/localization/text";
import MyOrganizationsSearchBox from "./myOrganizations/MyOrganizationsSearchBox/MyOrganizationsSearchBox";
import MyOrganizationsSearchResults from "./myOrganizations/MyOrganizationsSearchResults/MyOrganizationsSearchResults";
import MyOrganizationsTotal from "./myOrganizations/MyOrganizationsTotal";
import MyOrganizationsPagination from "./myOrganizations/MyOrganizationsPagination/MyOrganizationsPagination";
import connect from "react-redux/es/connect/connect";
import myOrganizationsSearchActions
  from "../../store/myOrganizations/myOrganizationsSearch/myOrganizationsSearchActions";
import {onInitMyOrganizationsSearch} from "../../services/flowTypes/propFnFlowTypes";
import type {isReadyToRender} from "../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../components/service/RenderOnReady/RenderOnReady";
import FixedHeaderContent from "../../components/simple/Containers/FixedHeader/FixedHeaderContent";

type Props = {
  onInitMyOrganizationsSearch: onInitMyOrganizationsSearch
};
type State = {
  isReadyToRender: isReadyToRender
};

class MyOrganizations extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false
    };
  }
  componentDidMount(){
    this.props.onInitMyOrganizationsSearch();
    this.setState({isReadyToRender: true});
  }
  render() {
    const headerTitle = <>{text.myOrganizations + " - " }<MyOrganizationsTotal/></>;
    return (
      <div className="MyOrganizations">
        <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
          <FixedHeaderContent title={headerTitle} isSupLevel={true}/>
          <MyOrganizationsSearchBox/>
          <MyOrganizationsSearchResults/>
          <MyOrganizationsPagination/>
        </RenderOnReady>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onInitMyOrganizationsSearch: () => dispatch({type: myOrganizationsSearchActions.MY_ORGANIZATIONS_SEARCH_INIT_STATE_SET})
  };
};
export default connect(null, mapDispatchToProps)(MyOrganizations);