//@flow
import * as React from 'react';
import userMenuItems from "../../../../../../configData/userMenuItems";
import type {userId} from "../../../../../../services/flowTypes/dataFlowTypes";
import RowMenu from "../../../../../../components/simple/Containers/RowMenu/RowMenu";
import ColumnMenu from "../../../../../../components/simple/Containers/RowMenu/ColumnMenu";
import SideLeftContainer from "../../../../../../components/simple/Containers/SideLeftContainer/SideLeftContainer";

type Props = {
  userId: userId
};

const UserMenu = (props:Props) => {
  const menuItemsData = userMenuItems(props.userId);
  return <SideLeftContainer><ColumnMenu menuItemsData={menuItemsData} isGlobalPermission={true} addClasses={"UserMenu"}/></SideLeftContainer>
};
export default UserMenu;