//@flow
import addServiceEditActions from './addServiceEditActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";

type State = {};
type reducerAction = action;
const initialState = {
  status: 'ACTIVE',
  nomenNumber1c: '',
  projectName: '',
  name: '',
  userId: '',
  date: '',
  payerId: '',
  payeeId: '',
  payoutPeriod: '',
  ignoreInExport: false,
  isDetachedInvoice: false,
  actTemplateId: '',
  contractId: '',
  files: [],
  sheets: []
};

const addServiceEditReducer = (state:State = initialState, action:reducerAction) => {
  switch (action.type) {
    case addServiceEditActions.ADD_SERVICE_EDIT_INIT:
      return initialState;
    case addServiceEditActions.ADD_SERVICE_EDIT_SET:
      return action.value;
    case addServiceEditActions.ADD_SERVICE_PROP_SET:
      return {
        ...state,
        [action.value.prop]: action.value.data
      }
    default:
      return state;
  }
};

export default addServiceEditReducer;