import * as React from 'react';
import CheckboxInput from "../CheckboxInput/CheckboxInput";

const onClick = () => {
  console.log('CheckboxInput is clicked');
};
export const checkboxLabel0 = "Option #0";
let children = [];
for (let i=0; i<20; i++) {
  const item = (<CheckboxInput key={`${i}`} checked={i%2} onClick={onClick} checkboxLabel={`Option #${i}`}/>);
  children.push(item);
}
export const CheckListContainerDefProps = {
  children: children
};