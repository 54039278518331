import checkWindowAppData from "./checkWindowAppData";
import ability from "./permissions";

const updateGlobalPermissions = () => {
  checkWindowAppData();
  const globalPermissions = JSON.parse(window.APP_DATA.globalPermissions);
  if (globalPermissions.length) {
    let rules = [];
    for(let i = 0; i < globalPermissions.length; i++){
      rules.push({actions: 'useGlobalPermission', subject: globalPermissions[i]});
    }
    ability.update(rules);
  }
};
export default updateGlobalPermissions;