//@flow
import * as React from 'react';
import {Link} from "react-router-dom";
import {userViewPageIds} from "../../../../../../tests/testIds";
import text from "../../../../../../services/localization/text";
import Can from "../../../../../../services/permissions/Can";
import {mainDataSuffix, supUserPathEdit} from "../../../../../../services/urlStrings/urlStrings";
import type {userId} from "../../../../../../services/flowTypes/dataFlowTypes";
import {globalPermissionsStrValues} from "../../../../../../configData/permissionsStrValues";
import BtnNewItem from "../../../../../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  userId: userId
};

const UserEditButton = (props: Props) => {
  const linkToEdit = supUserPathEdit(props.userId) + mainDataSuffix;
  return (
    <Can do={'useGlobalPermission'} on={globalPermissionsStrValues.user.update}>
      <Link className={"button-wrapper-link"} to={linkToEdit}>
        <BtnNewItem id={userViewPageIds.buttonEdit}>{text.btnEdit}</BtnNewItem>
      </Link>
    </Can>
  );
};

export default UserEditButton;