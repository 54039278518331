//@flow
import * as React from 'react';
import text from "../../services/localization/text";
import reportsSearchActionTypes from "../../store/organization/reportsSearch/reportsSearchActions";
import connect from "react-redux/es/connect/connect";
import {onInitReportsSearch} from "../../services/flowTypes/propFnFlowTypes";
import type {locationType, match, history} from "../../services/flowTypes/appFlowTypes";
import ReportsTotalCount from "./reports/ReportsTotalCount";
import ReportsSearchBox from "./reports/ReportsSearchBox/ReportsSearchBox";
import ReportsSearchResults from "./reports/ReportsSearchResults/ReportsSearchResults";
import ReportsPagination from "./reports/ReportsPagination/ReportsPagination";
import type {
  organizationId,
  reportAccountStatus,
  reportAccountStatusList
} from "../../services/flowTypes/dataFlowTypes";
import type {onUpdateReportsSearchProp} from "../../services/flowTypes/propFnFlowTypes";
import FixedHeaderContent from "../../components/simple/Containers/FixedHeader/FixedHeaderContent";
import RenderOnReady from "../../components/service/RenderOnReady/RenderOnReady";
import ReportsSubMenu from "./reports/ReportsSubMenu/ReportsSubMenu";
import "./reports/Reports.css";

type Props = {
  history: history,
  onInitReportsSearch: onInitReportsSearch,
  onUpdateReportsSearchProp: onUpdateReportsSearchProp,
  match: match,
  reportAccountStatusList: reportAccountStatusList,
  reportAccountStatusFromPath: reportAccountStatus,
  reportsSearchOrganizationId: organizationId,
  organizationId: organizationId,
  location: locationType
};

class Reports extends React.Component <Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false
    };
  }
  getReportAccountStatusId = () => {
    return this.props.match.params.status === 'ALL' || this.props.match.params.status === '' ? 'ALL'
      : this.props.reportAccountStatusList.find(item => item.name === this.props.match.params.status).id;
  };
  getReportAccountStatusName = () => {
    if (this.props.reportAccountStatusFromPath === '') {
      return '';
    }
    return this.props.reportAccountStatusFromPath === 'ALL' ? 'ALL'
      : this.props.reportAccountStatusList.find(item => item.id === this.props.reportAccountStatusFromPath).name;
  };
  setRequestReportAccountStatus = () => {
    const reportsSearchObj = {
      reportAccountStatusFromPath: this.getReportAccountStatusId(),
      organizationId: this.props.organizationId
    };
    if (this.props.match.params.status !== 'ALL') {
      reportsSearchObj.reportStatus = 'SUCCESS';
    }
    this.props.onInitReportsSearch(reportsSearchObj);
    this.setState({isReadyToRender: true});
  };
  componentDidUpdate(prevProps){
    if (prevProps.organizationId !== this.props.organizationId
      || prevProps.match.params.status !== this.props.match.params.status) {
      this.setState({isReadyToRender: false}, this.setRequestReportAccountStatus);
    }
  }
  componentDidMount(){
    const initReportsSearch = this.props.organizationId !== this.props.reportsSearchOrganizationId
      || this.props.match.params.status !== this.getReportAccountStatusName();
    this.setRequestReportAccountStatus(initReportsSearch);

  }
  render() {
    const statusFromParams = this.props.match.params.status;
    const isGetReportsOnMount = this.getReportAccountStatusName() !==  ''
      && this.props.match.params.status !== this.getReportAccountStatusName();
    const title = <>{text.reportsTitle + " – "}<ReportsTotalCount/></>;
    return (
      <div className="Reports w_wide-content">
        <FixedHeaderContent title={title} history={this.props.history}
                            pathname={this.props.location.pathname}
        />
        <ReportsSubMenu/>
        <ReportsSearchBox statusFromParams={statusFromParams} isGetReportsOnMount={isGetReportsOnMount}/>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
          <ReportsSearchResults statusFromParams={statusFromParams} history={this.props.history}/>
        </RenderOnReady>
        <ReportsPagination/>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    reportAccountStatusList: state.organization.organizationData.statuses.reportAccountStatusList,
    reportAccountStatusFromPath: state.organization.reportsSearch.reportAccountStatusFromPath,
    reportsSearchOrganizationId: state.organization.reportsSearch.organizationId,
    organizationId: state.organization.organizationId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onInitReportsSearch: (reportsSearchObj) => dispatch({type: reportsSearchActionTypes.REPORTS_SEARCH_INIT_STATE_SET,
      value: reportsSearchObj}),
    onUpdateReportsSearchProp: (reportsSearchObj) => dispatch({type: reportsSearchActionTypes.REPORTS_SEARCH_PROP_UPDATE,
      value: reportsSearchObj})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);