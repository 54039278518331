//@flow
import * as React from 'react';
import {connect} from "react-redux";
import BtnDel from "../../../../../../../components/simple/Buttons/BtnDel/BtnDel";
import type {
  addService,
  contractStatus
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {errorObject, isReadyToRender, modalIsShown} from "../../../../../../../services/flowTypes/appFlowTypes";
import Can from "../../../../../../../services/permissions/Can";
import {addServicesPageIds} from "../../../../../../../tests/testIds";
import text from "../../../../../../../services/localization/text";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import NModal from "../../../../../../../components/simple/Modals/NModal/NModal";
import api from "../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import type {
  onGetAddServices
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
// import CheckboxInput from "../../../../../../../components/simple/FormComponents/CheckboxInput/CheckboxInput";
// import type {isDeleteWithFile} from "../../../../../../../services/flowTypes/componentFlowTypes";
import addServicesActions from "../../../../../../../store/organization/item/addServices/addServicesActions";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";
import PreventEditDeleteModal from "./PreventEditDeleteModal";
import NHeading2 from "../../../../../../../components/simple/TextComponents/NHeading2/NHeading2";

type Props = {
  addService: addService,
  statusQueryParam: contractStatus,
  onGetAddServices: onGetAddServices,
  addServiceIndex: number
};
type State = {
  modalIsShown: modalIsShown,
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  // isDeleteWithFile: isDeleteWithFile
};


class ButtonDeleteAddService extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null,
      modalIsShown: false,
      // isDeleteWithFile: false
    };
  }
  // onCheckboxClick = () => {
  //   this.setState({isDeleteWithFile: !this.state.isDeleteWithFile});
  // };
  showModal = () => {
    this.setState({
      modalIsShown: true
    });
  };
  hideModal = () => {
    this.setState({
      modalIsShown: false,
      // isDeleteWithFile: false
    });
  };
  deleteAddService = () => {
    // this.props.unmountDocumentsListFetcher();
    api.deleteAddService(this.props.addService, this.props.statusQueryParam)
      .then(response => {
        // this.props.mountDocumentsListFetcher();
        this.props.onGetAddServices(response.data);
        this.setState({
          isReadyToRender: true,
          modalIsShown: false,
          // isDeleteWithFile: false
        });
      })
      .catch(error => {
        this.setState({
          modalIsShown: true,
          // isDeleteWithFile: false,
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    // const fileNamesArr = this.props.addService.files.map(item => item.name);
    // const fileNames = fileNamesArr.join(', ');
    return (
      <>
        <Can do={'usePermission'} on={permissionsStrValues.addService.delete}>
          <BtnDel id={addServicesPageIds.buttonDeleteAddService(this.props.addServiceIndex)}
                  onClick={this.showModal} isCardButtonMode={true}/>
        </Can>
        {this.state.modalIsShown ?
          this.props.addService.hasPreparedAddServiceReport ? (
            <PreventEditDeleteModal onModalCancelBtnClick={this.hideModal} addServiceName={this.props.addService.name}/>
          ) : (
            <NModal modalTitle={text.deleteAddServiceModal.title}
                    modalActionBtnText={text.btnDel} modalCancelBtnText={text.cancel} isModalDocumentEdit={true}
                    onModalActionBtnClick={this.deleteAddService} onModalCancelBtnClick={this.hideModal}>
              <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
              <NHeading2 addClasses={'mt_3du mb_3du'}>
                {text.deleteAddServiceModal.confirmation}
                <span className={"NHeading2 NHeading2__span-link-color"}>{this.props.addService.name}</span>
                {"?"}
              </NHeading2>
              {/*<CheckboxInput checked={this.state.isDeleteWithFile} onClick={this.onCheckboxClick}*/}
              {/*               checkboxLabel={text.deleteAddServiceModal.withFiles(fileNames)}/>*/}
            </NModal>

        ) : null}
      </>

    );
  }
}

const mapStateToProps = state => {
  return {
    statusQueryParam: state.organization.linkedItemsRequestParams.hasOwnProperty('status') ?
      state.organization.linkedItemsRequestParams.status : null
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetAddServices: (addAgreements) => dispatch({
      type: addServicesActions.ADD_SERVICES_SET, value: addAgreements})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ButtonDeleteAddService);