//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {myOrganizations} from "../../../../services/flowTypes/dataFlowTypes";
import MyOrganizationCard from "./MyOrganizationCard/MyOrganizationCard";
import EmptyListV2 from "../../../../components/simple/Containers/EmptyList/EmptyListV2";

type Props = {
  myOrganizations: myOrganizations
};

class MyOrganizationsSearchResults extends React.Component <Props> {
  render() {
    const myOrganizationsCards = this.props.myOrganizations === null ? null :
      this.props.myOrganizations && this.props.myOrganizations.length ?
      this.props.myOrganizations.map((item, index)=> {
        return <MyOrganizationCard key={'MyOrganization' + index} myOrganization={item} organizationIndex={index}/>
      })
      : (<EmptyListV2/>);
    return (
      <div>
        {myOrganizationsCards}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    myOrganizations: state.myOrganizations.searchResults.myOrganizations
  };
};
export default connect(mapStateToProps, null)(MyOrganizationsSearchResults);