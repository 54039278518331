//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {disabled, id, placeholder} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import Select, { components } from 'react-select';
import type {
  isClearable,
  isSearchable, isSelectMenuWide, noOptionsMessage,
  onSelectChange,
  selectedOption, selectOptions
} from "../../../../services/flowTypes/componentFlowTypes";
import TextOverflowHandler from "../../Containers/TextOverflowHandler/TextOverflowHandler";
import FilterSelectIcon from "../../Icons/FilterSelectIcon/FilterSelectIcon";
import './BorderlessSelect.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  isSearchable?: isSearchable,
  value: selectedOption,
  onChange: onSelectChange,
  options: selectOptions,
  placeholder?: placeholder,
  noOptionsMessage?: noOptionsMessage,
  isClearable?: isClearable,
  isDisabled?: disabled,
  inputId?: id,
  isSelectMenuWide?: isSelectMenuWide,
  isOrganizationSwitcher?: boolean
};

const BorderlessSelect = (props: Props) => {
  const isSearchable = props.isSearchable ? props.isSearchable : false;
  const isClearable = props.isClearable ? props.isClearable : false;
  const customSelectStyles = {
    container: (provided, state) => ({
      ...provided,
      display: 'flex'
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0 2px',
      paddingTop: '0',
      paddingBottom: '0',
      fontSize: '14px'
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: 'var(--border-radius)',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      border: '0px solid transparent !important',
      boxShadow: 'unset',
      minHeight: '14px'
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent'
    }),
    menuList: (provided, state) => ({
      ...provided,
      backgroundColor: '#ffffff'
    }),
    menu: (provided, state) => ({
      ...provided,
      left: props.isOrganizationSwitcher ? 'auto' : (props.isSelectMenuWide ? '-50%' : '0'),
      right: props.isOrganizationSwitcher ? '0' : 'auto',
      width: props.isOrganizationSwitcher ? "300px" :   (props.isSelectMenuWide ? '200%' : '100%'),
      boxShadow: "var(--select-box-shadow)"
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: props.isOrganizationSwitcher ? 'var(--main-text-color)' : 'var(--main-accent-color)',
      fontFamily: 'var(--main-font-family)',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '14px',
      maxWidth: '100%',
      position: 'static',
      transform: 'unset',
      textDecoration: props.isOrganizationSwitcher ? 'unset' : 'underline dotted'
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: 'static',
      transform: 'unset'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0'
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? 'var(--main-accent-color)' : 'var(--main-text-color)',
      backgroundColor: state.isFocused ? 'var(--item-back-color)' : '#ffffff',
      fontFamily: 'var(--main-font-family)',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '40px'
    })
  };
  const Option = (props) => {
    const addClasses = props.data.isSticked ? 'Label_isSticked TextOverflowHandler_mw-100' : 'TextOverflowHandler_mw-100';
    const label = typeof props.data.label === 'string' ?
      props.data.statusClassName ?
        <span className={props.data.statusClassName}>{props.data.label}</span> :
        <TextOverflowHandler addClasses={addClasses}>{props.data.label}</TextOverflowHandler>
      : props.data.label;
    const innerProps = {...props.innerProps, id: props.data.value};
    const newProps = {...props, innerProps: innerProps, children: label};
    return (
      <components.Option {...newProps} />
    );
  };
  const DropdownIndicator = (dropdownIndicatorProps) => {
    return (<FilterSelectIcon/>)};
    // return (<BorderlessSelectIcon/>)};
  const className = props.isDisabled ? "BorderlessSelect BorderlessSelect_disabled" : "BorderlessSelect";
  return (
    <div id={props.id} className={baseClass(className, props.addClasses)}>
      <Select
        components={{Option, DropdownIndicator}}
        noOptionsMessage={props.noOptionsMessage}
        placeholder={props.placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        value={props.value}
        onChange={props.onChange}
        options={props.options}
        styles={customSelectStyles}
        inputId={props.inputId}
        isDisabled={props.isDisabled}
        // defaultMenuIsOpen={true}
      />
    </div>
  );
};

export default BorderlessSelect;