//@flow
import * as React from 'react';
import {onEscKeyDown} from "../../../services/flowTypes/propFnFlowTypes";
import type {children} from "../../../services/flowTypes/appFlowTypes";

type Props = {
  onEscKeyDown: onEscKeyDown,
  children: children
};

class CloseOnEscKeyDown extends React.Component <Props> {
  handleEscKeyDown = (e:SyntheticEvent<HTMLElement>) => {
    if (e.keyCode === 27) {
      this.props.onEscKeyDown();
    }
  };
  componentDidMount(){
    document.addEventListener('keydown', this.handleEscKeyDown);
  }
  componentWillUnmount(){
    document.removeEventListener('keydown', this.handleEscKeyDown);
  }
  render() {
    return (
      this.props.children
    );
  }
}

export default CloseOnEscKeyDown;