//@flow
import * as React from 'react';
import type {
  htmlFor,
  id,
  isReadyToRender,
  label,
  placeholder
} from "../../../../../../../../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../../../../../../../../services/flowTypes/uiKitFlowTypes";
import type {
  inputIco, isSearchable,
  labelTooltip, selectOptions
} from "../../../../../../../../../../../services/flowTypes/componentFlowTypes";
import type {
  formFieldType,
  propName,
  propValidationPatternsArr,
  propValue
} from "../../../../../../../../../../../services/flowTypes/componentFlowTypes";
import {
  onDeleteValidationStateFormProp,
  onGetPropValue,
  onGetValidationStateFormProp
} from "../../../../../../../../../../../services/flowTypes/propFnFlowTypes";
import validationActions from "../../../../../../../../../../../store/validation/validationActions";
import connect from "react-redux/es/connect/connect";
import ConnectedFormGroup from "../../../../../../../../../../../components/composed/ConnectedFormGroup/ConnectedFormGroup";
import RenderOnReady from "../../../../../../../../../../../components/service/RenderOnReady/RenderOnReady";

type Props = {
  id?: id,
  addClasses?: addClasses,
  labelTooltip?: labelTooltip,
  label?: label,
  labelForId?: htmlFor,
  formFieldType: formFieldType,
  propName: propName,
  propValue: propValue,
  onGetPropValue: onGetPropValue,
  propValidationPatternsArr?: propValidationPatternsArr,
  onDeleteValidationStateFormProp: onDeleteValidationStateFormProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  idForTest?: id,
  inputIco?: inputIco,
  isSearchable?: isSearchable,
  options?: selectOptions,
  placeholder?: placeholder,
  selectInputId?: id
};
type State = {
  isReadyToRender: isReadyToRender
};

class FinTermFormGroup extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false
    };
  }
  componentDidMount(){
    this.props.onGetValidationStateFormProp(this.props.propName, '');
    this.setState({
      isReadyToRender: true
    });
  }
  componentWillUnmount(){
    this.props.onDeleteValidationStateFormProp(this.props.propName);
  }
  render(){
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={null}>
        <ConnectedFormGroup
          formFieldType={this.props.formFieldType}
          propName={this.props.propName}
          propValue={this.props.propValue}
          onGetPropValue={this.props.onGetPropValue}
          label={this.props.label}
          propValidationPatternsArr={this.props.propValidationPatternsArr}
          labelForId={this.props.labelForId}
          labelTooltip={this.props.labelTooltip}
          idForTest={this.props.idForTest}
          addClasses={this.props.addClasses}
          inputIco={this.props.inputIco}
          isSearchable={this.props.isSearchable}
          options={this.props.options}
          placeholder={this.props.placeholder}
          selectInputId={this.props.selectInputId}
        />
      </RenderOnReady>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onDeleteValidationStateFormProp: (prop) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_DELETE, value: prop})
  };
};
export default connect(null, mapDispatchToProps)(FinTermFormGroup);