//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {contractId, documentsList} from "../../../../../../../services/flowTypes/dataFlowTypes";
import ItemFile from "./ItemFile";
import ItemFileUploader from "./ItemFileUploader";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {isReadyToRender, readOnly} from "../../../../../../../services/flowTypes/appFlowTypes";
import itemFilesActions
  from "../../../../../../../store/organization/item/itemFiles/itemFilesActions";
import type {onSetItemFiles, setDataOnUnmount} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import ContractMainDataFilesLengthValidator from "../contractMainDataEdit/ContractMainDataFilesLengthValidator";
import type {itemFilesType} from "../../../../../../../services/flowTypes/componentFlowTypes";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";

type Props = {
  files: {
    contract: documentsList,
    addService: documentsList
  },
  itemFiles: documentsList,
  contractId: contractId,
  onSetItemFiles: onSetItemFiles,
  readOnly?: readOnly,
  itemFilesType: itemFilesType,
  setDataOnUnmount?: setDataOnUnmount
};
type State = {
  isReadyToRender: isReadyToRender
};

class ItemFiles extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false
    };
  }
  setDataOnUnmount = () => {
    if (this.props.itemFilesType === 'addService') {
      const itemFiles = this.props.itemFiles.map(item => {
        return {...item};
      });
      this.props.setDataOnUnmount(itemFiles);
    }
  };
  setDataOnMount = () => {
    const filesToEdit = this.props.files[this.props.itemFilesType].map(item => {
      return {...item};
    });
    this.props.onSetItemFiles(filesToEdit);
    this.setState({isReadyToRender: true});
  };
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    // it's hack to prevent gap when user goes from AddServiceDocsEdit page to AddServiceAllData page, because in
    // this case data sent to store in setDataOnUnmount is not set in the time when componentDidMount
    // also it's applied when the page with files of updated add service is reloaded
    if (prevProps.files[this.props.itemFilesType] !== this.props.files[this.props.itemFilesType]) {
      this.setDataOnMount();
    }
  }
  componentDidMount() {
    this.setDataOnMount();
  }
  componentWillUnmount(): * {
    this.setDataOnUnmount();
  }

  render() {
    const documents = this.props.itemFiles.map((item, i) => {
      // const addClasses = i === this.props.files.length-1 ? '' : 'mb_1du';
      return (<ItemFile key={'document'+i} document={item}
                            documentIndex={i} readOnly={this.props.readOnly}
                                    permittedActionObj={permissionsStrValues.contractDocument}
                            // folderId={this.props.folderId}
                            contractId={this.props.contractId}
                            />);
    });
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
        {documents}
        {this.props.readOnly ? null : (
          this.props.itemFilesType === 'contract' ? (
            <ContractMainDataFilesLengthValidator>
              <ItemFileUploader contractId={this.props.contractId} itemFilesType={this.props.itemFilesType}
                                            permittedAction={permissionsStrValues.contractDocument.create}/>
            </ContractMainDataFilesLengthValidator>
          ) : (
            <ItemFileUploader contractId={this.props.contractId} itemFilesType={this.props.itemFilesType}
                                          permittedAction={permissionsStrValues.contractDocument.create}/>
          )
        )}
      </RenderOnReady>
    );
  }
}

const mapStateToProps = state => {
  return {
    files: {
      contract: state.organization.item.contractMainData.files,
      addService: state.organization.item.addServiceEdit.files,
    },
    itemFiles: state.organization.item.itemFiles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetItemFiles: (value) => dispatch({type: itemFilesActions.ITEM_FILES_SET, value: value})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemFiles);