//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import type {itemDate} from "../../../../services/flowTypes/dataFlowTypes";
import type {
  itemDateId, openDirection,
  singleDatePickerFocused
} from "../../../../services/flowTypes/componentFlowTypes";
import {onDatesChange} from "../../../../services/flowTypes/propFnFlowTypes";
import moment, {locale} from '../../../../services/moment/momentInstance';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import './FormSingleDatePicker.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  date: itemDate,
  dateId?: itemDateId,
  onDatesChange: onDatesChange,
  openDirection?: openDirection
};
type State = {
  focused: singleDatePickerFocused
};


class FormSingleDatePicker extends React.Component <Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      focused: null
    };
  }
  onDatesChange = (dateObj:Object) => {
    const formattedDate = dateObj ? moment(dateObj).format('YYYY-MM-DD') : "";
    this.props.onDatesChange(formattedDate);
  };
  render(){
    const dateObj = (this.props.date === "" || this.props.date === undefined || this.props.date === "Invalid date") ? null : new Date(this.props.date);
    const monthLang = process.env.LANG === 'ru' ? 'ru-RU' : 'en-GB';
    return (
      <div id={this.props.id} className={baseClass("FormSingleDatePicker", this.props.addClasses)}>
        <DatePicker
          selected={dateObj} // momentPropTypes.momentObj or null
          onChange={this.onDatesChange} // PropTypes.func.isRequired
          showIcon={true}
          monthsShown={2}
          calendarStartDay={1}
          dateFormat="dd.MM.yyyy"
          locale={locale}
          id={this.props.dateId}
          renderCustomHeader={({
                                 monthDate,
                                 customHeaderCount,
                                 decreaseMonth,
                                 increaseMonth,
                               }) => (
              <div>
                <button
                    aria-label="Previous Month"
                    className={
                      "react-datepicker__navigation react-datepicker__navigation--previous"
                    }
                    style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
                    onClick={decreaseMonth}
                >
            <span
                className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                }
            >
              {"<"}
            </span>
                </button>
                <span className="react-datepicker__current-month">
            {monthDate.toLocaleString(monthLang, {
              month: "long",
              year: "numeric",
            })}
          </span>
                <button
                    aria-label="Next Month"
                    className={
                      "react-datepicker__navigation react-datepicker__navigation--next"
                    }
                    style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
                    onClick={increaseMonth}
                >
            <span
                className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                }
            >
              {">"}
            </span>
                </button>
              </div>
          )}
        />
      </div>
    );
  }
}

export default FormSingleDatePicker;
