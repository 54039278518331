//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {children, id, onClick} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './CardBtn.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  onClick: onClick
};

const CardBtn = (props: Props) => {
  return (
    <button type="button" id={props.id} onClick={props.onClick}
         className={baseClass("CardBtn", props.addClasses)}>
      {props.children}</button>
  );
};

export default CardBtn;