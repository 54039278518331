//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {history, match} from "../../../../../../services/flowTypes/appFlowTypes";
import {prepareGroupFinTermToStore} from "../FinTermView/FinTermView";
import groupFinTermsSearchActions
  from "../../../../../../store/organization/item/groupFinTermsSearch/groupFinTermsSearchActions";
import GroupFinTermsFetcher from "./groupFinTermsView/GroupFinTermsFetcher";
import GroupFinTermsFilters from "./groupFinTermsView/GroupFinTermsFilters";
import GroupFinTermsSearchResults from "./groupFinTermsView/GroupFinTermsSearchResults";

type Props = {
  match: match,
  history: history,
  onInitGroupFinTermsSearch: Function
};
type State = {
  deletedGroupFinTermId: null | number
};

export const prepareGroupFinTermsToStore = (groupFinTerms, configFinTermParams) => {
  const groupFinTermsReversed = groupFinTerms.reverse();
  return groupFinTermsReversed.map(item => {
    return prepareGroupFinTermToStore(item, configFinTermParams)
  });
};

class GroupFinTermsView extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      deletedGroupFinTermId: null
    };
  }
  setDeletedGroupFinTermId = (deletedGroupFinTermId) => {
    this.setState({deletedGroupFinTermId});
  };
  componentDidMount(): * {
    const contractId = +this.props.match.params.id;
    this.props.onInitGroupFinTermsSearch({contractId});
  }

  render() {
    return (
      <>
        <GroupFinTermsFetcher history={this.props.history} deletedGroupFinTermId={this.state.deletedGroupFinTermId}/>
        <GroupFinTermsFilters />
        <GroupFinTermsSearchResults setDeletedGroupFinTermId={this.setDeletedGroupFinTermId}/>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitGroupFinTermsSearch: (groupFinTermSearch) => dispatch({
      type: groupFinTermsSearchActions.GROUP_FIN_TERMS_SEARCH_INIT_STATE_SET, value: groupFinTermSearch})
  };
};

export default connect(null, mapDispatchToProps)(GroupFinTermsView);