//@flow
import * as React from 'react';
import type {notificationResponse, organizationId} from "../../../services/flowTypes/dataFlowTypes";
import NCard from "../../simple/Containers/NCard/NCard";
import type {editMode} from "../../../services/flowTypes/componentFlowTypes";
import './NotificationSettingsForm.css';
import text from "../../../services/localization/text";
import NotificationSettingsFormInput from "./NotificationSettingsFormInput";
import NHeading3 from "../../simple/TextComponents/NHeading3/NHeading3";
import SecText from "../../simple/TextComponents/SecText/SecText";

type Props = {
  notificationSettings: notificationResponse,
  editMode?: editMode,
  orgIdToFilter?: organizationId
};

const updatesItemsKeys = ['AGENT_NOTFICATION', 'CONTRACT_NOTIFICATION', 'LINKED_AGENT_NOTIFICATION',
  'LINKED_CONTRACT_NOTIFICATION', 'FINTERM_NOTIFICATION'];
const endDatesItemsKeys = ['CONTRACT_END_DATE_NOTIFICATION', 'ADD_AGREEMENT_END_DATE_NOTIFICATION',
  'FIN_TERM_END_DATE_NOTIFICATION'];

class NotificationSettingsFormSingleOrg extends React.Component <Props> {
  getItems = (notifications, orgNotificationIndex, orgNotifications) => {
    return notifications.map((item, i) => {
      const notificationIndex = orgNotifications.findIndex(orgNotification => orgNotification.notificationCategory === item.notificationCategory);
      return (
        <div className="NotificationSettingsFormSingleOrg__row" key={'updateItems'+i}>
          <div className="NotificationSettingsFormSingleOrg_category">
            {text.notificationSettingsFormHeader[item.notificationCategory]}</div>
          <div className="NotificationSettingsFormSingleOrg_input-container">
            <NotificationSettingsFormInput key={'notification' + i} editMode={this.props.editMode}
                                           orgNotificationIndex={orgNotificationIndex}
                                           notificationIndex={notificationIndex} />
          </div>
        </div>
      );
    })
  };
  defineItems = () => {
    const orgItems = this.props.notificationSettings.find(item => item.orgId === this.props.orgIdToFilter);
    if (!orgItems) {throw new Error('organization notifications are not found')};
    const orgNotificationIndex = this.props.notificationSettings.findIndex(item => item.orgId === this.props.orgIdToFilter);
    const orgNotifications = orgItems ? orgItems.notifications : [];
    const updatesNotifications = orgNotifications.filter(item => updatesItemsKeys.includes(item.notificationCategory));
    const updatesItems = this.getItems(updatesNotifications, orgNotificationIndex, orgNotifications);
    const endDatesNotifications = orgNotifications.filter(item => endDatesItemsKeys.includes(item.notificationCategory));
    const endDatesItems = this.getItems(endDatesNotifications, orgNotificationIndex, orgNotifications);
    return {updatesItems, endDatesItems};
  };

  render() {
    const {updatesItems, endDatesItems} = this.defineItems();
    return (
      <NCard>
        <div className="NotificationSettingsFormSingleOrg" >
          <NHeading3 addClasses={'mb_2du'}>{text.updatesNotificationsTitle}</NHeading3>
          <div className="NotificationSettingsFormSingleOrg__updatesItems">
            {updatesItems}
          </div>
          <NHeading3 addClasses={'mt_4du mb_1du'}>{text.endDatesNotificationsTitle}</NHeading3>
          <SecText addClasses={'mb_2du'}>{text.endDatesNotificationsNote}</SecText>
          <div className="NotificationSettingsFormSingleOrg__endDatesItems" >
            {endDatesItems}
          </div>
        </div>
      </NCard>
    );
  }
}

export default NotificationSettingsFormSingleOrg;