//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './SearchCard.css';
import type {
  formattedContextSnippet,
  linkTo, linkToItemLabel,
  searchCardTitle
} from "../../../../services/flowTypes/componentFlowTypes";
import SecText from "../../TextComponents/SecText/SecText";
import Text1 from "../../TextComponents/Text1/Text1";
import {Link} from "react-router-dom";
import type {handleLinkClick} from "../../../../services/flowTypes/propFnFlowTypes";
import Heading4 from "../../TextComponents/Heading4/Heading4";
import text from "../../../../services/localization/text";
import type {folderName} from "../../../../services/flowTypes/dataFlowTypes";
import TextOverflowHandler from "../TextOverflowHandler/TextOverflowHandler";

type Props = {
  id?: id,
  addClasses?: addClasses,
  linkToItem: linkTo,
  linkToDoc: linkTo,
  linkToFolder: linkTo,
  title: searchCardTitle,
  linkToItemLabel: linkToItemLabel,
  handleLinkClick?: handleLinkClick,
  folderLabel: folderName,
  formattedContextSnippets?: Array<formattedContextSnippet>
};

const DocumentSearchCard = (props: Props) => {
  const formattedContextSnippets = props.formattedContextSnippets.map((item,i)=>{
    return <Text1 addClasses="mt_2du" key={'snippet'+i}>
      <TextOverflowHandler addClasses={'TextOverflowHandler_wrap'} isInputWrapperMode={true} inputValue={item.initialSnippet}>
        {item.formattedSnippet}
    </TextOverflowHandler>
      </Text1>
  });
  return (
    <div id={props.id} className={baseClass("SearchCard", props.addClasses)}>
      <a href={props.linkToDoc} target={'_blank'} rel="noopener noreferrer">
        <Heading4>{props.title}</Heading4>
      </a>
      <Link to={props.linkToItem} onClick={props.handleLinkClick} className={'mb_2du'}>
        <Text1 addClasses={''}>{props.linkToItemLabel}</Text1>
      </Link>
      <SecText>{text.fileLocation}</SecText>
      <Link to={props.linkToFolder} onClick={props.handleLinkClick}>
        <Text1 addClasses={''}>{props.folderLabel}</Text1>
      </Link>
      {props.formattedContextSnippets ? (
        <>
          <SecText addClasses={"mt_2du"}>{text.contextSnippetLabel}</SecText>
          <div className="mt_-2du">{formattedContextSnippets}</div>
        </>
      )  : null}
    </div>
  );
};

export default DocumentSearchCard;
