//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {history, id, locationPathname} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './TabSteps.css';
import type {steps} from "../../../../services/flowTypes/componentFlowTypes";
import type {handleButtonForwardClick, validateBeforeSubmit} from "../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  steps: steps,
  locationPathname: locationPathname,
  validateBeforeSubmit?: validateBeforeSubmit,
  handleButtonForwardClick?: handleButtonForwardClick,
  history?: history
};

const TabSteps = (props: Props) => {
  const locationPathnameSuffixArr = props.locationPathname.split('/');
  const locationPathnameSuffix = locationPathnameSuffixArr[locationPathnameSuffixArr.length-1];
  const locationPathnameBaseArr = locationPathnameSuffixArr.slice(0, -1);
  const locationPathnameBase = locationPathnameBaseArr.join('/');
  const activeIndex = props.steps.findIndex(item => item.pathnameSuffix === locationPathnameSuffix);
  const defineClassName = (itemIndex) => {
    if (itemIndex < activeIndex) {
      return "TabStepsItem TabStepsItem_alreadyActive";
    } else if (itemIndex === activeIndex) {
      return "TabStepsItem TabStepsItem_active";
    } else {
      return "TabStepsItem";
    }
  };
  const tabs = props.steps.map((item, i) => {
    const linkTo = locationPathnameBase + "/" + item.pathnameSuffix;
    const onClick = () => {
      if (props.hasOwnProperty('handleButtonForwardClick') && props.hasOwnProperty('validateBeforeSubmit')) {
        props.handleButtonForwardClick();
        if (!props.validateBeforeSubmit()) {
          props.history.push(linkTo);
        }
      } else {
        props.history.push(linkTo);
      }
    };
    return (<div onClick={onClick} key={'TabStepsItem'+i} id={`tabStep_${item.pathnameSuffix}`}
                 className={defineClassName(i)}>{item.name}</div>)
  });
  return (
    <div id={props.id} className={baseClass("TabSteps", props.addClasses)}>{tabs}</div>
  );
};

export default TabSteps;