//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './SearchCard.css';
import type {
  formattedMatchedParam,
  linkTo,
  searchCardTitle,
  searchCardUnderTitle
} from "../../../../services/flowTypes/componentFlowTypes";
import Heading5 from "../../TextComponents/Heading5/Heading5";
import SecText from "../../TextComponents/SecText/SecText";
import Text1 from "../../TextComponents/Text1/Text1";
import {Link} from "react-router-dom";
import type {handleLinkClick} from "../../../../services/flowTypes/propFnFlowTypes";
import TextOverflowHandler from "../TextOverflowHandler/TextOverflowHandler";
import TagLabel from "../../TextComponents/TagLabel/TagLabel";

type Props = {
  id?: id,
  addClasses?: addClasses,
  linkTo: linkTo,
  formattedTitle: searchCardTitle,
  title: searchCardTitle,
  underTitle?: searchCardUnderTitle,
  formattedUnderTitle?: searchCardUnderTitle,
  formattedMatchedParams: Array<formattedMatchedParam>,
  handleLinkClick?: handleLinkClick,
  formattedTags: Array<formattedTag>
};

const SearchCard = (props: Props) => {
  const matchedParams = props.formattedMatchedParams.map((param, i) => {
    return (
      <div key={'matchedParam'+i} className={'mt_2du'}>
        <SecText addClasses={'mb_half-du'}>{param.name}</SecText>
        <Text1 addClasses={'Text1_fw500'}>{param.value}</Text1>
      </div>
    )
  });
  const tags = props.formattedTags.map((tag, i) => {
    // return (
    //   <div key={'tag'+i} className="SearchCard__tag mr_1du mt_2du">
    //     <Heading5 addClasses={'Heading5_no-underline'}>{tag}</Heading5>
    //   </div>
    // );
    return (
      <TagLabel key={'tag'+i} addClasses="SearchCard__TagLabel mr_1du mt_2du">
        {tag}
      </TagLabel>
    );
  });
  return (
    <div id={props.id} className={baseClass("SearchCard", props.addClasses)}>
      <Link to={props.linkTo} onClick={props.handleLinkClick}>
        <Heading5 addClasses={'Heading5_lh22'}>
          <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'} inputValue={props.title}
                               isInputWrapperMode={true}>{props.formattedTitle}</TextOverflowHandler>
        </Heading5>
      </Link>
      {props.underTitle ? (
        <SecText>
          <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'} inputValue={props.underTitle}
          isInputWrapperMode={true}>{props.formattedUnderTitle}</TextOverflowHandler>
        </SecText>
      ) : null}
      {matchedParams}
      {tags && tags.length ? (
        <div className={'flex flex_wrap'}>{tags}</div>
      ) : null}
    </div>
  );
};

export default SearchCard;