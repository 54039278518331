//@flow
import contractLinkedContractsActions from './contractLinkedContractsActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {contractLinkedContracts} from "../../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
export const initialState = null;

const contractLinkedContractsReducer = (state:contractLinkedContracts = initialState, action:reducerAction) => {
  switch (action.type) {
    case contractLinkedContractsActions.CONTRACT_LINKED_CONTRACTS_INIT:
      return initialState;
    case contractLinkedContractsActions.CONTRACT_LINKED_CONTRACTS_SET:
      return action.value;
    default:
      return state;
  }
};

export default contractLinkedContractsReducer;