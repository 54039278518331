//@flow
import * as React from 'react';
import type {ruleIsRight} from "../../../../services/flowTypes/componentFlowTypes";
import type {children} from "../../../../services/flowTypes/appFlowTypes";
import "./PasswordRuleLabel.css";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";

type Props = {
  ruleIsRight: ruleIsRight,
  children: children,
  addClasses?: addClasses
};

const PasswordRuleLabel = (props: Props) => {
  const className = props.ruleIsRight ? "PasswordRuleLabel PasswordRuleLabel_ruleIsRight" : "PasswordRuleLabel";
  return (
    <div className={baseClass(className, props.addClasses)}>
      <div className="PasswordRuleLabel__ico mr_2du"/>
      <div className="PasswordRuleLabel__text">{props.children}</div>
    </div>
  );
};

export default PasswordRuleLabel;    