//@flow
import * as React from 'react';
import type {
  isScrollToError,
  sourceParams,
  validationIsAllowed
} from "../../../../../../../../../../services/flowTypes/dataFlowTypes";
import type {
  onGetValidationStateFormProp,
  onGetValidationStateProp
} from "../../../../../../../../../../services/flowTypes/propFnFlowTypes";
import type {propError, sourceParamIndex} from "../../../../../../../../../../services/flowTypes/componentFlowTypes";
import NFormGroup from "../../../../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import validationActions from "../../../../../../../../../../store/validation/validationActions";
import type {children} from "../../../../../../../../../../services/flowTypes/appFlowTypes";
import {connect} from "react-redux";
import {useEffect} from "react";
import {groupFinTermSourceParam} from "../../../../../../../../../../services/flowTypes/dataFlowTypes";
import groupFinTermsValidationScheme from "../../../../../../../../../../configData/groupFinTermsValidationScheme";
import validationPatterns from "../../../../../../../../../../services/validationPatterns/validationPatterns";

type Props = {
  sourceParams: sourceParams,
  sourceParam: groupFinTermSourceParam,
  sourceParamIndex: sourceParamIndex,
  onGetValidationStateProp: onGetValidationStateProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  children: children,
  validationIsAllowed: validationIsAllowed,
  isScrollToError: isScrollToError,
  propError: propError
};

const SourceParamValidator = (props: Props) => {
  const {
    children,
    sourceParams,
    sourceParam,
    propError,
    sourceParamIndex,
    onGetValidationStateFormProp
  } = props;

  useEffect(() => {
    const validator = groupFinTermsValidationScheme[sourceParam.name];
    const error = validator ? validator(sourceParam.value, sourceParams) : '';
    onGetValidationStateFormProp(sourceParamIndex, error);
  }, [sourceParams, sourceParam, sourceParamIndex, onGetValidationStateFormProp]);

  return <NFormGroup isScrollToError={props.isScrollToError} propError={propError}>
    {children}
  </NFormGroup>
}

const mapStateToProps = (state, ownProps) => {
  return {
    sourceParams: state.organization.item.sourceParamsEdit,
    sourceParam: state.organization.item.sourceParamsEdit[ownProps.sourceParamIndex],
    propError: state.validation.form[ownProps.sourceParamIndex],
    validationIsAllowed: state.validation.validationIsAllowed,
    isScrollToError: state.validation.isScrollToError
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({
      type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }
    }),
    onGetValidationStateProp: (prop, data) => dispatch({
      type: validationActions.VALIDATION_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }
    })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SourceParamValidator);