//@flow
import * as React from 'react';
import {connect} from "react-redux";
import text from "../../../../../../services/localization/text";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import NHeading1 from "../../../../../../components/simple/TextComponents/NHeading1/NHeading1";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import {
  onInitActTemplatesEdit,
  onSetActTemplatesEdit,
  onSetDataToValidationState
} from "../../../../../../services/flowTypes/propFnFlowTypes";
import actTemplatesEditActions
  from "../../../../../../store/organization/item/actTemplatesEdit/actTemplatesEditActions";
import type {
  actTemplateName, agentIdIsNotCompliedWIthPaynetParams, currency,
  currencyOptions,
  groupFinTerm, isNotCompliedWithPaynetParams, lastValidReportDate, payoutPeriod,
  payoutPeriodOptions
} from "../../../../../../services/flowTypes/dataFlowTypes";
import api from "../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import type {
  errorObject,
  history,
  isReadyToRender,
  locationType,
  match
} from "../../../../../../services/flowTypes/appFlowTypes";
import ActTemplatesFormGroup from "./actTemplatesEdit/ActTemplateFormGroup";
import './ActTemplateEdit.css';
import FinTermsSelectContainer from "./actTemplatesEdit/FinTermsSelect/FinTermsSelectContainer";
import GroupFinTermsViewFetcher
  from "../ActTemplatesView/actTemplateView/GroupFinTermsViewFetcher/GroupFinTermsViewFetcher";
import ActTemplateDocument from "./actTemplatesEdit/ActTemplateDocument";
import validationActions from "../../../../../../store/validation/validationActions";
import {actTemplatesEditPageIds} from "../../../../../../tests/testIds";
import GetDataOnMountAndUpdate
  from "../../../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import CleanValidationStateOnUnmount
  from "../../../../../../components/service/CleanValidationStateOnUnmount/CleanValidationStateOnUnmount";
import ReportTagsExpressions from "./actTemplatesEdit/ReportTagsExpressions";
import AddendumTemplateFile from "./actTemplatesEdit/AddendumTemplateFile";
import clientError404 from "../../../../../../services/apiInstance/clientError404";
import type {selectOptions} from "../../../../../../services/flowTypes/componentFlowTypes";
import getCurrencyLabel from "../../../../../../utils/getCurrencyLabel";
import ForbidAddServiceActTemplatePage from "../ActTemplateView/ForbidAddServiceActTemplatePage";
import AlertPaynetSync from "../../../../../../components/simple/Containers/AlertCard/AlertPaynetSync";
import AdaptiveFormContainer
  from "../../../../../../components/simple/Containers/AdaptiveFormContainer/AdaptiveFormContainer";

type Props = {
  onInitActTemplatesEdit: onInitActTemplatesEdit,
  onSetActTemplatesEdit: onSetActTemplatesEdit,
  name: actTemplateName,
  groupFinTermsView: Array<groupFinTerm>,
  onSetDataToValidationState: onSetDataToValidationState,
  history: history,
  match: match,
  location: locationType,
  currencyOptions: currencyOptions,
  payoutPeriodOptions: payoutPeriodOptions,
  currency: currency,
  payoutPeriod: payoutPeriod,
  addServiceIdsLength: number,
  isNotCompliedWithPaynetParams?: isNotCompliedWithPaynetParams,
  agentIdIsNotCompliedWIthPaynetParams?: agentIdIsNotCompliedWIthPaynetParams,
  lastValidReportDate?: lastValidReportDate
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  currencySwitcherOptions: selectOptions,
  payoutPeriodSwitcherOptions: selectOptions,
};
class ActTemplatesEdit extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      currencySwitcherOptions: [],
      payoutPeriodSwitcherOptions: []
    };
  }
  setEmptyCurrencyAndPayoutPeriod = (actTemplate) => {
    if (actTemplate.hasOwnProperty('currency') && actTemplate.hasOwnProperty('payoutPeriod')) {
      return actTemplate;
    } else if (actTemplate.hasOwnProperty('payoutPeriod')) {
      return {...actTemplate, currency: ''};
    } else {
      return {...actTemplate, currency: '', payoutPeriod: ''};
    }
  };
  setUnverifiedStatus = (actTemplate) => {
    const handledActTemplate = this.setEmptyCurrencyAndPayoutPeriod(actTemplate);
    return {...handledActTemplate, isVerified: false};
  };
  getActTemplateForEdit = () => {
    const currencySwitcherOptions = this.props.currencyOptions.map(item => {
      return {value: item, label: getCurrencyLabel(item)};
    });
    const payoutPeriodSwitcherOptions = this.props.payoutPeriodOptions.map(item => {
      return {value: item, label: text[item]};
    });
    this.props.onInitActTemplatesEdit();
    this.props.onSetDataToValidationState();
    const actTemplateId = +this.props.match.params.actTemplateId;
    if (actTemplateId) {
      const contractId = +this.props.match.params.id;
      api.getActTemplates(contractId)
        .then(response => {
          const actTemplate = response.data.find(item => item.id === actTemplateId);
          if (actTemplate) {
            const actTemplateWithUnverifiedStatus = this.setUnverifiedStatus(actTemplate);
            this.props.onSetActTemplatesEdit(actTemplateWithUnverifiedStatus);
            this.setState({isReadyToRender: true, currencySwitcherOptions, payoutPeriodSwitcherOptions});
          } else {
            this.setState({
              isReadyToRender: true,
              errorObject: apiErrorHandler(clientError404)
            });
          }
        })
        .catch(error => {
          this.setState({
            isReadyToRender: true,
            errorObject: apiErrorHandler(error)
          });
        });
    } else {
      this.setState({isReadyToRender: true, currencySwitcherOptions, payoutPeriodSwitcherOptions});
    }
  };
  render() {
    const actTemplateId = this.props.match.params.actTemplateId ? +this.props.match.params.actTemplateId : null;
    const contractId = +this.props.match.params.id;
    const organizationId = +this.props.match.params.organizationId;
    return (
      <ForbidAddServiceActTemplatePage actTemplateId={actTemplateId} contractId={contractId}
                                       organizationId={organizationId} history={this.props.history}
                                       addServiceIdsLength={this.props.addServiceIdsLength}
      >
        <GroupFinTermsViewFetcher contractId={contractId} >
          <GetDataOnMountAndUpdate
            itemId={this.props.match.params.actTemplateId}
            getDataOnMountAndUpdate={this.getActTemplateForEdit}
            fnOnUnmount={this.props.onInitActTemplatesEdit}
          >
            <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
              <CleanValidationStateOnUnmount/>
              <div className="ActTemplatesEdit">
                <NCard addClasses={'mb_3du'}>
                  {this.props.isNotCompliedWithPaynetParams ? (
                    <AlertPaynetSync addClasses={"mb_3du"} isActTemplateMode={true}
                                     agentIdIsNotCompliedWIthPaynetParams={this.props.agentIdIsNotCompliedWIthPaynetParams}
                                     lastValidReportDate={this.props.lastValidReportDate}
                                     organizationId={organizationId}/>
                  ) : null}
                  <ActTemplatesFormGroup formFieldType={'input'} propName={'name'} label={text.name}
                                         addClasses={'mb_3du'}
                                         idForTest={actTemplatesEditPageIds.inputActTemplateName}/>
                  <AdaptiveFormContainer fieldsArr={[
                    <ActTemplatesFormGroup formFieldType={'select'} propName={'currency'} label={text.currency}
                                           placeholder={text.select.placeholder.currency} addClasses={'pos_rel z_1100'}
                                           options={this.state.currencySwitcherOptions} isSearchable={true}
                                           selectInputId={actTemplatesEditPageIds.selectInputCurrency} key={"currency"}
                                           idForTest={actTemplatesEditPageIds.selectCurrency}/>,
                    <ActTemplatesFormGroup formFieldType={'switcher'} propName={'payoutPeriod'} label={text.payoutPeriod}
                                           options={this.state.payoutPeriodSwitcherOptions}
                                           idForTest={actTemplatesEditPageIds.switcherPayoutPeriod}/>
                  ]} containerMode={"twoHalfCardWidthFields1100"} addClasses={"mb_3du"}/>
                  {actTemplateId ? (
                    <input type="hidden" id={actTemplatesEditPageIds.inputHiddenActTemplateId} value={actTemplateId}/>
                  ) : null}
                  {/*<FileWithTags contractId={contractId}/>*/}
                  <ActTemplatesFormGroup formFieldType={'checkbox-boolean'} propName={'isVerified'} label={text.isVerifiedLabel}
                                         idForTest={actTemplatesEditPageIds.checkboxIsVerified}/>
                </NCard>
                <div className="mb_3du">
                  <NHeading1>{text.finTerms}</NHeading1>
                </div>
                <FinTermsSelectContainer contractId={contractId} organizationId={organizationId} actTemplateId={actTemplateId}/>
                <ReportTagsExpressions history={this.props.history}/>
                <ActTemplateDocument actTemplateId={actTemplateId} contractId={contractId}/>
                <AddendumTemplateFile actTemplateId={actTemplateId} contractId={contractId}/>
              </div>
            </RenderOnReady>
          </GetDataOnMountAndUpdate>
        </GroupFinTermsViewFetcher>
      </ForbidAddServiceActTemplatePage>

    )
  }
}

const mapStateToProps = state => {
  return {
    isNotCompliedWithPaynetParams: state.organization.item.actTemplatesEdit.isNotCompliedWithPaynetParams,
    agentIdIsNotCompliedWIthPaynetParams: state.organization.item.actTemplatesEdit.agentIdIsNotCompliedWIthPaynetParams,
    name: state.organization.item.actTemplatesEdit.name,
    addServiceIdsLength: state.organization.item.actTemplatesEdit.addServiceIds ?
      state.organization.item.actTemplatesEdit.addServiceIds.length : 0,
    currency: state.organization.item.actTemplatesEdit.currency,
    payoutPeriod: state.organization.item.actTemplatesEdit.payoutPeriod,
    lastValidReportDate: state.organization.item.actTemplatesEdit.lastValidReportDate,
    groupFinTermsView: state.organization.item.groupFinTermsView,
    currencyOptions: state.organization.organizationData.configFinTerms.currencyOptions,
    payoutPeriodOptions: state.organization.organizationData.configFinTerms.payoutPeriodOptions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitActTemplatesEdit: () => dispatch({type: actTemplatesEditActions.ACT_TEMPLATES_EDIT_INIT}),
    onSetActTemplatesEdit: (actTemplate) => dispatch({
    type: actTemplatesEditActions.ACT_TEMPLATES_EDIT_SET, value: actTemplate}),
    onSetDataToValidationState: (data) => dispatch({
      type: validationActions.VALIDATION_STATE_SET,
      value: data})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActTemplatesEdit);    