//@flow
import * as React from 'react';
import {connect} from "react-redux";
import searchInOrgContextActions from "../../../../store/organization/searchInOrgContext/searchInOrgContextActions";
import {searchInOrgContextIds} from "../../../../tests/testIds";
import type {onUpdateSheetIsShown} from "../../../../services/flowTypes/propFnFlowTypes";
import HoveredIcon from "../../HoveredIcon/HoveredIcon";
import baseIconSrc from '../../../../assets/search-icon-v5_24.svg';
import hoveredIconSrc from '../../../../assets/search-icon-hover-v5_24.svg';
import './ButtonShowSearchInOrgContextSheet.css';
import text from "../../../../services/localization/text";

type Props = {
  onUpdateSheetIsShown: onUpdateSheetIsShown
};

class ButtonShowSearchInOrgContextSheet extends React.Component <Props> {
  showSearchSheet = () => {
    this.props.onUpdateSheetIsShown(true);
  };
  render() {
    return (
      <div className="ButtonShowSearchInOrgContextSheet__container MainMenuItemContainer" id={searchInOrgContextIds.buttonShowSheet}
           onClick={this.showSearchSheet}>
        <div className="MainMenuItem">
          <div className="MainMenuItem__icon-container">
            <HoveredIcon baseIconSrc={baseIconSrc} hoveredIconSrc={hoveredIconSrc}/>
          </div>
          <div className={"MainMenuItem__linkText"}>{text.searchInOrgContextBtnLabel}</div>
        </div>

      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUpdateSheetIsShown: (sheetIsShown) => dispatch({
      type: searchInOrgContextActions.SHEET_IS_SHOWN_UPDATE,
      value: sheetIsShown})
  };
};

export default connect(null, mapDispatchToProps)(ButtonShowSearchInOrgContextSheet);