//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {id, index, modalIsShown} from "../../../../../../../../../../services/flowTypes/appFlowTypes";
import type {
  objectParamName
} from "../../../../../../../../../../services/flowTypes/dataFlowTypes";
import BtnEditAct from "../../../../../../../../../../components/simple/Buttons/BtnEditAct/BtnEditAct";
import text from "../../../../../../../../../../services/localization/text";
import NModal from "../../../../../../../../../../components/simple/Modals/NModal/NModal";
import {initUpdateOrganizationParams, onDeleteItemParam} from "../../../../../../../../../../services/flowTypes/propFnFlowTypes";
import organizationParamsActions
  from "../../../../../../../../../../store/organization/organizationParams/organizationParamsActions";
import type {itemType} from "../../../../../../../../../../services/flowTypes/componentFlowTypes";
import NHeading2 from "../../../../../../../../../../components/simple/TextComponents/NHeading2/NHeading2";

type Props = {
  itemType: itemType,
  onDeleteItemParam: onDeleteItemParam,
  paramIndex: index,
  objectParamName: objectParamName,
  initUpdateOrganizationParams: initUpdateOrganizationParams,
  idForTest?: id
};
type State = {
  modalIsShown: modalIsShown
};
class DeleteItemParam extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false
    };
  }
  showModal = () => {
    this.setState({
      modalIsShown: true,
    });
  };
  hideModal = () => {
    this.setState({
      modalIsShown: false,
    });
  };
  deleteItemParam = () => {
    this.props.onDeleteItemParam(this.props.itemType, this.props.paramIndex);
    this.props.initUpdateOrganizationParams();
    this.hideModal();
  };
  render() {
    return (
      <>
        <BtnEditAct addClasses={'BtnEditAct_delete mr_2du'}
                    id={this.props.idForTest}
                    onClick={this.showModal}>{text.btnDel}</BtnEditAct>
        {this.state.modalIsShown ? (
          <NModal
            modalTitle={text.deleteUserOrganizationModalTitle}
            onModalCancelBtnClick={this.hideModal}
            onModalActionBtnClick={this.deleteItemParam}
            modalCancelBtnText={text.cancel}
            modalActionBtnText={text.btnDel}
            addClasses={'NModal_ov'}
            isModalDocumentEdit={true}
          >
            <NHeading2 addClasses={'mt_3du mb_3du'}>
              {text.deleteItemParam1}
              <span className={"NHeading2 NHeading2__span-link-color"}>{this.props.objectParamName}</span>
              {text.deleteItemParam2}
            </NHeading2>
          </NModal>
        ) : null}
      </>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onDeleteItemParam: (itemType, paramIndex) => dispatch({type: organizationParamsActions.ORGANIZATION_PARAMS_DELETE,
      value: {itemType, paramIndex}})
  };
};
export default connect(null, mapDispatchToProps)(DeleteItemParam);