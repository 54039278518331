//@flow
import itemDataParamsEditActions from './itemDataParamsEditActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {itemDataParams} from "../../../../services/flowTypes/dataFlowTypes";
import update from 'immutability-helper';

type reducerAction = action;
const initialState = [];

const itemDataParamsEditReducer = (state:itemDataParams = initialState, action:reducerAction) => {
  switch (action.type) {
    case itemDataParamsEditActions.ITEM_DATA_PARAMS_SET:
      return action.value;
    case itemDataParamsEditActions.PARAMS_BLOCK_NAME_UPDATE:
      return update(state, {
        [action.value.paramsBlockIndex]: {blockName: {$set: action.value.blockName}}
      });
    case itemDataParamsEditActions.PARAMS_BLOCK_FIELDS_UPDATE:
      return update(state, {
        [action.value.paramsBlockIndex]: {fields: {$set: action.value.fields}}
      });
    case itemDataParamsEditActions.PARAMS_BLOCK_FIELD_DELETE:
      return update(state, {
        [action.value.paramsBlockIndex]: {fields: {$splice: [[action.value.paramsBlockFieldIndex, 1]]}}
      });
    case itemDataParamsEditActions.PARAMS_BLOCK_DELETE:
      return update(state, {$splice: [[action.value, 1]]});
    case itemDataParamsEditActions.PARAMS_BLOCK_ADD:
      const newBlock = {
        blockName: '',
        fields: []
      };
      return update(state, {$push: [newBlock]});
    case itemDataParamsEditActions.PARAM_VALUE_UPDATE:
      const {paramsBlockIndex, paramsBlockFieldIndex, valueType, propValue} = action.value;
      return update(state, {
        [paramsBlockIndex]: {fields: {[paramsBlockFieldIndex]: {[valueType]: {$set: propValue}}}}
      });
    default:
      return state;
  }
};

export default itemDataParamsEditReducer;