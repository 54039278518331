import {combineReducers, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import generalReducer from "./general/general";
import organizationIdReducer from "./organization/organizationId/organizationId";
import searchDataReducer from "./organization/searchData/searchData";
import organizationDataReducer from "./organization/organizationData/organizationData";
import searchRequestReducer from "./organization/searchRequest/searchRequest";
import reportsReducer from "./organization/reports/reports";
import reportsSearchReducer from "./organization/reportsSearch/reportsSearch";
import organizationsSearchReducer from "./organizations/organizationsSearch/organizationsSearch";
import usersSearchReducer from "./users/usersSearch/usersSearch";
import organizationsSearchResultsReducer from "./organizations/organizationsSearchResults/organizationsSearchResults";
import usersSearchResultsReducer from "./users/usersSearchResults/usersSearchResults";
import contractMainDataReducer from "./organization/item/contractMainData/contractMainData";
import agentMainDataReducer from "./organization/item/agentMainData/agentMainData";
import validationReducer from "./validation/validation";
import contractAgentsReducer from "./organization/item/contractAgents/contractAgents";
import contractLinkedContractsReducer from "./organization/item/contractLinkedContracts/contractLinkedContracts";
import itemDataParamsEditReducer from "./organization/item/itemDataParamsEdit/itemDataParamsEdit";
import itemDataParamsListReducer from "./organization/item/itemDataParamsList/itemDataParamsList";
import groupFinTermsViewReducer from "./organization/item/groupFinTermsView/groupFinTermsView";
import groupFinTermsEditReducer from "./organization/item/groupFinTermsEdit/groupFinTermsEdit";
import finTermsEditReducer from "./organization/item/finTermsEdit/finTermsEdit";
import itemDocumentsReducer from "./organization/item/itemDocuments/itemDocuments";
import sourceParamsEditReducer from "./organization/item/sourceParamsEdit/sourceParamsEdit";
import actTemplatesViewReducer from "./organization/item/actTemplatesView/actTemplatesView";
import actTemplatesEditReducer from "./organization/item/actTemplatesEdit/actTemplatesEdit";
import reportMainDataReducer from "./organization/item/reportMainData/reportMainData";
import needFetchFlagsReducer from "./needFetchFlags/needFetchFlags";
import reportManualAdjustmentReducer from "./organization/item/reportManualAdjustment/reportManualAdjustment";
import userMainDataReducer from "./user/userMainData/userMainData";
import adminReducer from "./admin/admin";
import organizationMainDataReducer from "./organization/organizationMainData/organizationMainData";
import myOrganizationsSearchResultsReducer
  from "./myOrganizations/myOrganizationsSearchResults/myOrganizationsSearchResults";
import myOrganizationsSearchReducer from "./myOrganizations/myOrganizationsSearch/myOrganizationsSearch";
import organizationParamsReducer from "./organization/organizationParams/organizationParams";
import templateParamsReducer from "./organization/item/templateParams/templateParams";
import documentFoldersReducer from "./organization/item/documentFolders/documentFolders";
import alertsReducer from "./alerts/alerts";
import historyDataReducer from "./organization/historyData/historyData";
import historyLogsReducer from "./organization/historyLogs/historyLogs";
import searchInOrgContextReducer from "./organization/searchInOrgContext/searchInOrgContext";
import searchInOrgContextResponseReducer from "./organization/searchInOrgContextResponse/searchInOrgContextResponse";
import linkedItemsRequestParamsReducer from "./organization/linkedItemsRequestParams/linkedItemsRequestParams";
import notificationSettingsReducer from "./notificationSettings/notificationSettings";
import addAgreementsReducer from "./organization/item/addAgreements/addAgreements";
import actTemplateParamsListReducer from "./organization/item/actTemplateParamsList/actTemplateParamsList";
import itemFilesReducer from "./organization/item/itemFiles/itemFiles";
import addServicesReducer from "./organization/item/addServices/addServices";
import addServiceEditReducer from "./organization/item/addServiceEdit/addServiceEdit";
import sheetsReducer from "./organization/item/sheets/sheets";
import historyLocationsReducer from "./historyLocations/historyLocations";
import contractGatesParamsReducer from "./organization/item/agentGatesParams/agentGatesParams";
import groupFinTermsSearchReducer from "./organization/item/groupFinTermsSearch/groupFinTermsSearch";
import gatesParamsSearchReducer from "./organization/item/gatesParamsSearch/gatesParamsSearch";


const rootReducer = combineReducers({
  general: generalReducer,
  needFetchFlags: needFetchFlagsReducer,
  organizations: combineReducers({
    search: organizationsSearchReducer,
    searchResults: organizationsSearchResultsReducer,
  }),
  myOrganizations: combineReducers({
    search: myOrganizationsSearchReducer,
    searchResults: myOrganizationsSearchResultsReducer
  }),
  users: combineReducers({
    search: usersSearchReducer,
    searchResults: usersSearchResultsReducer,
  }),
  user: combineReducers({
    mainData: userMainDataReducer
  }),
  admin: adminReducer,
  notificationSettings: notificationSettingsReducer,
  organization: combineReducers({
    organizationId: organizationIdReducer,
    organizationData: organizationDataReducer,
    searchRequest: searchRequestReducer,
    searchData: searchDataReducer,
    reports: reportsReducer,
    reportsSearch: reportsSearchReducer,
    mainData: organizationMainDataReducer,
    organizationParams: organizationParamsReducer,
    historyData: historyDataReducer,
    historyLogs: historyLogsReducer,
    searchInOrgContext: searchInOrgContextReducer,
    searchInOrgContextResponse: searchInOrgContextResponseReducer,
    linkedItemsRequestParams: linkedItemsRequestParamsReducer,
    item: combineReducers({
      contractMainData: contractMainDataReducer,
      agentMainData: agentMainDataReducer,
      contractAgents: contractAgentsReducer,
      contractLinkedContracts: contractLinkedContractsReducer,
      itemDataParamsEdit: itemDataParamsEditReducer,
      itemDataParamsList: itemDataParamsListReducer,
      groupFinTermsView: groupFinTermsViewReducer,
      groupFinTermsEdit: groupFinTermsEditReducer,
      finTermsEdit: finTermsEditReducer,
      sourceParamsEdit: sourceParamsEditReducer,
      itemDocuments: itemDocumentsReducer,
      actTemplatesView: actTemplatesViewReducer,
      actTemplatesEdit: actTemplatesEditReducer,
      templateParams: templateParamsReducer,
      reportMainData: reportMainDataReducer,
      reportManualAdjustment: reportManualAdjustmentReducer,
      documentFolders: documentFoldersReducer,
      addAgreements: addAgreementsReducer,
      actTemplateParamsList: actTemplateParamsListReducer,
      itemFiles: itemFilesReducer,
      addServices: addServicesReducer,
      addServiceEdit: addServiceEditReducer,
      sheets: sheetsReducer,
      contractGatesParams: contractGatesParamsReducer,
      groupFinTermsSearch: groupFinTermsSearchReducer,
      gatesParamsSearch: gatesParamsSearchReducer
    })
  }),
  validation: validationReducer,
  alerts: alertsReducer,
  historyLocations: historyLocationsReducer
});
const store = createStore(rootReducer, composeWithDevTools());

export default store;