//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  errorObject,
  history,
  isReadyToRender,
  locationType,
  match
} from "../../../../../../services/flowTypes/appFlowTypes";
import text from "../../../../../../services/localization/text";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import {organizationEditPageIds} from "../../../../../../tests/testIds";
import type {isSupLevel, selectOptions} from "../../../../../../services/flowTypes/componentFlowTypes";
import type {
  currencyOptions,
  organizationStatusList,
  sourceOptions
} from "../../../../../../services/flowTypes/dataFlowTypes";
import StatusLabel from "../../../../../../components/simple/TextComponents/StatusLabel/StatusLabel";
import OrganizationMainDataFormGroup from "./organizationMainDataEdit/OrganizationMainDataFormGroup";
import createOption from "../../../../../../utils/createOption";
import CleanValidationStateOnUnmount
  from "../../../../../../components/service/CleanValidationStateOnUnmount/CleanValidationStateOnUnmount";
import api from "../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import concatIdName from "../../../../../../utils/concatIdName";
import AdaptiveFormContainer
  from "../../../../../../components/simple/Containers/AdaptiveFormContainer/AdaptiveFormContainer";

type Props = {
  history: history,
  match: match,
  organizationStatusList: organizationStatusList,
  currencyOptions: currencyOptions,
  sourceOptions: sourceOptions,
  location: locationType,
  isSupLevel?: isSupLevel
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  organizationStatusOptions: selectOptions,
  currencySelectOptions: selectOptions,
  sourceSelectOptions: selectOptions,
  localeOptions: selectOptions,
  linkedAgentSelectOptions: selectOptions
};

class OrganizationMainDataEdit extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null,
      organizationStatusOptions: [],
      currencySelectOptions: [],
      sourceSelectOptions: [],
      linkedAgentSelectOptions: [],
      localeOptions: [{value: 'ru', label: 'ru'}, {value: 'en', label: 'en'}]
    };
  }
  componentDidMount(){
    const organizationId = this.props.isSupLevel ?
      (this.props.match.params.id ? +this.props.match.params.id : null) : +this.props.match.params.organizationId;
    if (organizationId) {
      api.getAgentsList(organizationId)
        .then(response => {
          const linkedAgentSelectOptions = response.data.map(item => {
            return {
              value: item.id, label: concatIdName(item.id, item.name)
            };
          });
          this.setState({
            linkedAgentSelectOptions,
            isReadyToRender: true
          });
        })
        .catch(error => {
          this.setState({
            isReadyToRender: true,
            errorObject: apiErrorHandler(error)
          });
        });
    }
    const organizationStatusOptions = this.props.organizationStatusList.map(item => {
      return {value: item,
        label: (<StatusLabel addClasses={`StatusLabel_${item}`}>{text.organizationStatus[item]}</StatusLabel>)};
    });
    const currencySelectOptions = this.props.currencyOptions.map(item => {
      return createOption(item);
    });
    const sourceSelectOptions = this.props.sourceOptions.map(item => {
      return createOption(item);
    });
    this.setState({organizationStatusOptions, currencySelectOptions, sourceSelectOptions});
  }
  render() {
    const organizationId = this.props.isSupLevel ?
      (this.props.match.params.id ? +this.props.match.params.id : null) : +this.props.match.params.organizationId;
    return (
      <div className="OrganizationMainDataEdit">
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <CleanValidationStateOnUnmount/>
        <NCard addClasses={'mb_3du'}>
          <AdaptiveFormContainer fieldsArr={[
            <OrganizationMainDataFormGroup propName={"name"} label={text.organizationName} formFieldType={'input'}
                                           key={'name'} idForTest={organizationEditPageIds.inputName}/>,
            <div key={"status"}>
              {organizationId ? (
                <OrganizationMainDataFormGroup propName={"status"} label={text.status} formFieldType={'select'}
                                               options={this.state.organizationStatusOptions}
                                               addClasses={'w_half-card'} idForTest={organizationEditPageIds.selectStatus}/>
              ) : null}
            </div>
          ]} containerMode={"twoHalfCardWidthFields"} addClasses={"mb_3du"}/>
          <AdaptiveFormContainer fieldsArr={[
            <OrganizationMainDataFormGroup propName={"organizationCode"} label={text.organizationCode} formFieldType={'input'}
                                           key={'organizationCode'} idForTest={organizationEditPageIds.inputOrganizationCode}/>,
            <OrganizationMainDataFormGroup propName={"locale"} label={text.locale} formFieldType={'select'}
                                           selectInputId={organizationEditPageIds.selectInputLocale}
                                           isSearchable={true}
                                           options={this.state.localeOptions} placeholder={text.localePlaceholder}
                                           key={'locale'} idForTest={organizationEditPageIds.selectLocale}/>
          ]} containerMode={"twoHalfCardWidthFields"} addClasses={"mb_3du"}/>
          <div className="flex_jc-sb mb_3du">
            <OrganizationMainDataFormGroup propName={"linkedAgentId"} label={text.organizationLinkedAgent} formFieldType={'select'}
                                           selectInputId={organizationEditPageIds.selectInputLinkedAgent}
                                           isSearchable={true}
                                           options={this.state.linkedAgentSelectOptions} placeholder={text.linkedAgentIsNotSelected}
                                   addClasses={'w_half-card'} idForTest={organizationEditPageIds.selectLinkedAgent}/>

          </div>
          <OrganizationMainDataFormGroup propName={"sourceNames"} label={text.source}
                                         formFieldType={'tagSelect'} options={this.state.sourceSelectOptions}
                                         creationIsProhibited={true} placeholder={text.sourceNamesPlaceholder}
                                         idForTest={organizationEditPageIds.inputSources} addClasses={'mb_3du'}/>
          <OrganizationMainDataFormGroup propName={"currencyCodes"} label={text.currency}
                                         formFieldType={'tagSelect'} options={this.state.currencySelectOptions}
                                         creationIsProhibited={true} placeholder={text.currenciesPlaceholder}
                                         idForTest={organizationEditPageIds.inputCurrency} addClasses={'mb_3du'}/>
          <OrganizationMainDataFormGroup propName={"contractTypesList"} label={text.contractTypes}
                                         formFieldType={'creatableSelect'} placeholder={text.contractTypesPlaceholder}
                                         isTagLabelEditable={true}
                                         idForTest={organizationEditPageIds.inputContractTypes} addClasses={'mb_3du'}/>
          <OrganizationMainDataFormGroup propName={"agentLinkedTypeList"} label={text.agentLinkedTypes}
                                         formFieldType={'creatableSelect'} placeholder={text.agentLinkedTypesPlaceholder}
                                         isTagLabelEditable={true}
                                         idForTest={organizationEditPageIds.inputAgentLinkedTypes} addClasses={'mb_3du'}/>
          <OrganizationMainDataFormGroup propName={"contractLinkedTypeList"} label={text.contractLinkedTypes}
                                         formFieldType={'creatableSelect'} placeholder={text.contractLinkedTypesPlaceholder}
                                         isTagLabelEditable={true}
                                         idForTest={organizationEditPageIds.inputContractLinkedTypes} addClasses={'mb_3du'}/>
          <OrganizationMainDataFormGroup propName={"addServiceTypeList"} label={text.addServiceTypeList}
                                         formFieldType={'creatableSelect'} placeholder={text.addServiceTypeListPlaceholder}
                                         isTagLabelEditable={true}
                                         idForTest={organizationEditPageIds.inputAddServiceTypes} addClasses={'mb_3du'}/>
          <OrganizationMainDataFormGroup propName={"notedTerminalTypeList"} label={text.notedTerminalTypeList}
                                         formFieldType={'creatableSelect'} placeholder={text.notedTerminalTypeListPlaceholder}
                                         isTagLabelEditable={true}
                                         idForTest={organizationEditPageIds.inputNotedTerminalTypes} addClasses={'mb_3du'}/>
        </NCard>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    organizationStatusList: state.admin.statuses ? state.admin.statuses.organizationStatusList : [],
    currencyOptions: state.admin.statuses ? state.admin.statuses.currencyOptions : [],
    sourceOptions: state.admin.statuses ? state.admin.statuses.sourceOptions : []
  };
};
export default connect(mapStateToProps, null)(OrganizationMainDataEdit);
