//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {finTermIndex} from "../../../../../../../../../../../services/flowTypes/componentFlowTypes";
import type {finTerm, itemDate} from "../../../../../../../../../../../services/flowTypes/dataFlowTypes";
import validationPatterns from "../../../../../../../../../../../services/validationPatterns/validationPatterns";
import groupFinTermsValidationScheme from "../../../../../../../../../../../configData/groupFinTermsValidationScheme";
import {
  onGetValidationStateFormProp,
  onGetValidationStateProp
} from "../../../../../../../../../../../services/flowTypes/propFnFlowTypes";
import validationActions from "../../../../../../../../../../../store/validation/validationActions";

type Props = {
  finTermIndex: finTermIndex,
  startDate: itemDate,
  endDate: itemDate,
  onGetValidationStateProp: onGetValidationStateProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  finTerms: Array<finTerm>
};

class FinTermDateValidator extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  setPropName = (dateName) => {
    return 'finTerm' + this.props.finTermIndex + dateName;
  };
  getNextStartDate = () => {
    let nextStartDate = null;
    for (let i=this.props.finTermIndex-1; i > -1; i--) {
      if (!this.props.finTerms[i].status ) {
        nextStartDate = this.props.finTerms[i].startDate;
        break;
      }
    }
    return nextStartDate;
  };
  validate = (startDate, endDate) => {
    const error1 = validationPatterns[groupFinTermsValidationScheme.finTerms.dates[0]](startDate, endDate);
    const nextStartDate = this.getNextStartDate();
    const error2 = validationPatterns[groupFinTermsValidationScheme.finTerms.dates[1]](endDate, nextStartDate);
    const error = error1.length ? error1 : error2;
    this.props.onGetValidationStateProp('isScrollToError', false);
    this.props.onGetValidationStateFormProp(this.setPropName('endDate'), error);
  };
  componentDidUpdate(){
    this.validate(this.props.startDate, this.props.endDate);
  }
  componentDidMount(){
    this.validate(this.props.startDate, this.props.endDate);
  }
  render() {
    const nextStartDate = this.getNextStartDate();
    return (
      <div className="FinTermDateValidator">{this.props.startDate + this.props.endDate + nextStartDate}</div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    startDate: state.organization.item.finTermsEdit[ownProps.finTermIndex].startDate,
    endDate: state.organization.item.finTermsEdit[ownProps.finTermIndex].endDate,
    finTerms: state.organization.item.finTermsEdit
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetValidationStateProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinTermDateValidator);    