//@flow
import * as React from 'react';
import type {id, children, label, htmlFor} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import './NFormGroup.css';
import type {isRequired, labelTooltip, propError} from "../../../../services/flowTypes/componentFlowTypes";
import NLabel from "../NLabel/NLabel";
import type {isScrollToError} from "../../../../services/flowTypes/dataFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  labelTooltip?: labelTooltip,
  label?: label,
  labelForId?: htmlFor,
  propError?: propError,
  isScrollToError?: isScrollToError,
  isRequired?: isRequired,
  isEmptyLabelMode?: boolean
};

//TODO: add type to validationState

class NFormGroup extends React.Component <Props, void> {
  elRef: any;
  constructor(props:Props){
    super(props);
    this.elRef = React.createRef();
  }
  scrollIfError = () => {
    //TODO: remove EditModal code
    if (this.props.propError && this.props.propError.length) {
      const fixedHeaderHeight = 88 + 12;
      const box = this.elRef.current.getBoundingClientRect();
      const yCoord = box.top + window.pageYOffset;
      if (yCoord < (window.innerHeight - box.height) ) {
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, (yCoord - fixedHeaderHeight) );
      }
    }
  };
  componentDidUpdate(){
    if(this.props.isScrollToError) {
      this.scrollIfError();
    }
  }
  render(){
    const formGroupClassName = this.props.propError ? (
      this.props.propError.length ? "NFormGroup NFormGroup_invalid" : "NFormGroup"
    ) : "NFormGroup";
    const requiredLabel = this.props.isRequired ? '*' : '';
    const labelStr = typeof this.props.label === 'string' ? this.props.label + requiredLabel : this.props.label;
    const label = this.props.label ? (
      <NLabel htmlFor={this.props.labelForId}>{labelStr}</NLabel>
    ) : this.props.isEmptyLabelMode ? (<NLabel addClasses={'NLabel_empty'}>{''}</NLabel>) : null;
    return (
      <div className={baseClass(formGroupClassName, this.props.addClasses)}
           id={this.props.id}
           ref={this.elRef}
      >
        {this.props.labelTooltip ? (
          <div className="NFormGroup__label-with-tooltip">
            {label}
            {this.props.labelTooltip}
          </div>
        ) : (
          label
        )}

        {this.props.children}
        {this.props.propError ? (
          this.props.propError.length ? <span className="input-bottom-error">{this.props.propError}</span> : null
        ) : null}
      </div>
    );
  }
}


export default NFormGroup;