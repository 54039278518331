//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  report,
  reportManualAdjustedGroupFinTerm
} from "../../../../../../../../../../services/flowTypes/dataFlowTypes";
import ConnectedGroupFinTermBlock
  from "../../../../../../../../../../components/composed/ConnectedGroupFinTermBlock/ConnectedGroupFinTermBlock";
import reportManualAdjustmentProps from "../../../../../../../../../../configData/reportManualAdjustmentProps";
import ReportFinTermParam from "../../../../../../../../../../components/composed/ReportFinTermParam/ReportFinTermParam";
import RenderOnReady from "../../../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {isReadyToRender} from "../../../../../../../../../../services/flowTypes/appFlowTypes";
import reportManualAdjustmentActions
  from "../../../../../../../../../../store/organization/item/reportManualAdjustment/reportManualAdjustmentActions";
import {onSetReportFinTermsEdit} from "../../../../../../../../../../services/flowTypes/propFnFlowTypes";
import TotalRow from "../../../../TotalRow/TotalRow";
import AdjustmentBaseDocumentUploader
  from "../../../../../../../../../../components/composed/AdjustmentBaseDocumentUploader/AdjustmentBaseDocumentUploader";
import DataListContainer
  from "../../../../../../../../../../components/simple/Containers/DataListContainer/DataListContainer";

type Props = {
  reportMainData: report,
  onSetReportFinTermsEdit: onSetReportFinTermsEdit,
  reportManualAdjustment: Array<reportManualAdjustedGroupFinTerm>,
  onInitReportFinTermsEdit: Function
};
type State = {
  isReadyToRender: isReadyToRender
};

class ReportManualAdjustmentLayout extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false
    };
  }
  isAdjustedParamsAreTheSameType = () => {
    let isTheSameType = true;
    if (this.props.reportManualAdjustment.length > 1) {
      for (let i=1; i < this.props.reportManualAdjustment.length; i++) {
        isTheSameType = isTheSameType &&
          (this.props.reportManualAdjustment[i-1].adjustedParams.length === this.props.reportManualAdjustment[i].adjustedParams.length);
        if (!isTheSameType) {break}
        for (let j=0; j < this.props.reportManualAdjustment[i].adjustedParams.length; j++) {
          isTheSameType = isTheSameType &&
            (this.props.reportManualAdjustment[i-1].adjustedParams[j].name === this.props.reportManualAdjustment[i].adjustedParams[j].name);
          if (!isTheSameType) {break}
        }
      }
    }
    return isTheSameType;
  };
  defineParam = (paramName) => {
    let total = 0;
    for (let i=0; i < this.props.reportManualAdjustment.length; i++) {
      let totalItem = this.props.reportManualAdjustment[i].adjustedParams.find(item => item.name === paramName).value;
      total = total + (+totalItem * 100000);
    }
    return (total/100000);
  };
  defineGroupFinTerms = () => {
    if (this.props.reportManualAdjustment.length) {
      return this.props.reportMainData.finTermsList.map((groupFinTerm,i) => {
        const rightTopComponent = <AdjustmentBaseDocumentUploader groupFinTermIndex={i}/>;
        const inputs = this.props.reportManualAdjustment[i].adjustedParams.map((param, paramIndex) => {
          return <ReportFinTermParam key={'ReportFinTermParam'+paramIndex} groupFinTermIndex={i} paramIndex={paramIndex}
                                     currency={groupFinTerm.currency} addClasses={'w_quarter-card'}/>
        });
        const bottomComponent = <div className={"flex_jc-sb mt_2du"}>{inputs}</div>;
        return (
          <ConnectedGroupFinTermBlock groupFinTerm={groupFinTerm} key={'groupFinTerm'+i}
                                      rightTopComponent={rightTopComponent}
                                      bottomComponent={bottomComponent}
                                      contractId={this.props.reportMainData.contractId}/>
        );
      });
    } else {
      return null;
    }
  };
  componentDidMount(){
    const reportFinTerms = this.props.reportMainData.finTermsList.map((groupFinTerm) => {
      const reportManualAdjustmentData = {...reportManualAdjustmentProps[groupFinTerm.type]};
      if (groupFinTerm.source === 'PNE') {
        reportManualAdjustmentData.finTermGroupId = groupFinTerm.groupId;
        const adjustedParams = reportManualAdjustmentData.adjustedParams.map((param) => {
          return {...param, value: groupFinTerm[param.name] ? groupFinTerm[param.name] : ""};
        });
        reportManualAdjustmentData.adjustedParams = adjustedParams;
      }
      return reportManualAdjustmentData;
    });
    this.props.onSetReportFinTermsEdit(reportFinTerms);
    this.setState({isReadyToRender: true});
  }
  componentWillUnmount(): * {
    this.props.onInitReportFinTermsEdit();
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
        <div className="pt_3du">
          <DataListContainer itemsList={this.defineGroupFinTerms()}/>
          {this.isAdjustedParamsAreTheSameType() ? (
            <TotalRow
              currency={this.props.reportMainData.finTermsList[0].currency}
              rateAmount={this.defineParam('rateAmount')}
              totalAmount={this.defineParam('totalAmount')}
              count={this.defineParam('count')}
              paramAddClasses={'w_quarter-card'}
            />
          ) : <div className="p_3du"/>}
        </div>
      </RenderOnReady>
    );
  }
}

const mapStateToProps = state => {
  return {
    reportMainData: state.organization.item.reportMainData,
    reportManualAdjustment: state.organization.item.reportManualAdjustment
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitReportFinTermsEdit: () => dispatch({type: reportManualAdjustmentActions.REPORT_FIN_TERMS_EDIT_INIT}),
    onSetReportFinTermsEdit: (value) => dispatch({type: reportManualAdjustmentActions.REPORT_FIN_TERMS_EDIT_SET, value: value})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportManualAdjustmentLayout);    