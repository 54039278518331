//@flow
import * as React from 'react';
import {connect} from "react-redux";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import NHeading2 from "../../../../../../components/simple/TextComponents/NHeading2/NHeading2";
import text from "../../../../../../services/localization/text";
import type {history, match} from "../../../../../../services/flowTypes/appFlowTypes";
import type {addServiceStatus} from "../../../../../../services/flowTypes/dataFlowTypes";
import StatusLabel from "../../../../../../components/simple/TextComponents/StatusLabel/StatusLabel";
import PropValueView from "../../../../../../components/composed/PropValueView/PropValueView";
import GetAddService from "./GetAddService";
import scrollToTop from "../../../../../../utils/scrollToTop";

type Props = {
  match: match,
  history: history,
  status: addServiceStatus
};

class AddServiceView extends React.Component <Props> {
  componentDidMount(){
    scrollToTop();
  }
  render() {
    return (
      <GetAddService history={this.props.history} match={this.props.match}>
        <div className="AddServiceView">
          <NCard addClasses={'NCard__wide-width mb_4du'}>
            <NHeading2 addClasses={'mb_4du'}>{text.addServiceViewSubheader}</NHeading2>
            <PropValueView label={text.addServiceFormLabels.status} value={
              <StatusLabel addClasses={`StatusLabel_${this.props.status} StatusLabel_bold`}>
                {text.addService.status[this.props.status]}
              </StatusLabel>
            }/>
          </NCard>
        </div>
      </GetAddService>
    );
  }
}

const mapStateToProps = state => {
  return {
    status: state.organization.item.addServiceEdit.status,
  };
};
export default connect(mapStateToProps, null)(AddServiceView);