//@flow
import * as React from 'react';
import WrappedDateRangePicker from "./WrappedDateRangePicker";
import type {label, onChange} from "../../../../services/flowTypes/appFlowTypes";
import type {
  dateRangePeriodType,
  filterSelectBtn,
  needToInitFocus,
  propValue
} from "../../../../services/flowTypes/componentFlowTypes";
import DateIcon from "../../Icons/DateIcon";

type Props = {
  propNameLabel?: label,
  filterSelectBtn?: filterSelectBtn,
  propValue: propValue,
  preferableDateRangePeriodType?: dateRangePeriodType,
  onChange: onChange,
  dateRangeCheckboxLabel?: label
};
type State = {
  needToInitFocus: needToInitFocus
};

class FilterWrappedDateRangePicker extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      needToInitFocus: false
    };
  }
  handleClickToInitInputFocus = () => {this.setState({needToInitFocus: true});};
  resetNeedToInitFocus = () => {this.setState({needToInitFocus: false});};
  render() {
    const dateIconComp = (<div className="WrappedDateRangePicker__dateIconContainer" onClick={this.handleClickToInitInputFocus}>
      {DateIcon}
    </div>);
    return (
      <div className="FilterWrappedDateRangePicker">
        <div className="filter-dateRange-propNameLabel" onClick={this.handleClickToInitInputFocus}>
          {this.props.propNameLabel + ":"}</div>
        <WrappedDateRangePicker preferableDateRangePeriodType={this.props.preferableDateRangePeriodType}
                                propValue={this.props.propValue} dateIconComp={dateIconComp}
                                needToInitFocus={this.state.needToInitFocus}
                                resetNeedToInitFocus={this.resetNeedToInitFocus}
                                handleClickToInitInputFocus={this.handleClickToInitInputFocus}
                                onChange={this.props.onChange}/>
        {this.props.dateRangeCheckboxLabel}
        <div className="ml_2px">
          {this.props.filterSelectBtn}
        </div>
      </div>
    );
  }
}

export default FilterWrappedDateRangePicker;    