//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  creationIsProhibited,
  formFieldType, isSearchable, isTagLabelEditable, openDirection,
  propName,
  propValue,
  selectOptions
} from "../../../../../../../services/flowTypes/componentFlowTypes";
import {
  onGetOrganizationMainDataProp,
  onGetValidationStateFormProp
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import type {disabled, id, label, placeholder} from "../../../../../../../services/flowTypes/appFlowTypes";
import ConnectedFormGroup from "../../../../../../../components/composed/ConnectedFormGroup/ConnectedFormGroup";
import organizationMainDataValidationScheme from "../../../../../../../configData/organizationMainDataValidationScheme";
import type {addClasses} from "../../../../../../../services/flowTypes/uiKitFlowTypes";
import validationActions from "../../../../../../../store/validation/validationActions";
import organizationMainDataActions from "../../../../../../../store/organization/organizationMainData/organizationMainDataActions";

type Props = {
  formFieldType: formFieldType,
  propName: propName,
  propValue: propValue,
  onGetOrganizationMainDataProp: onGetOrganizationMainDataProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  label: label,
  options?: selectOptions,
  addClasses?: addClasses,
  idForTest?: id,
  openDirection?: openDirection,
  creationIsProhibited?: creationIsProhibited,
  isSearchable?: isSearchable,
  disabled?: disabled,
  placeholder?: placeholder,
  selectInputId?: id,
  isTagLabelEditable?: isTagLabelEditable
};

class OrganizationMainDataFormGroup extends React.Component <Props, void> {
  onGetPropValue = (prop, data) => {
    this.props.onGetOrganizationMainDataProp(prop, data);
  };
  render() {
    const propValidationPatternsArr = organizationMainDataValidationScheme[this.props.propName] ?
      organizationMainDataValidationScheme[this.props.propName] : null;
    return (
      <ConnectedFormGroup
        formFieldType={this.props.formFieldType}
        propName={this.props.propName}
        propValue={this.props.propValue}
        onGetPropValue={this.onGetPropValue}
        label={this.props.label}
        options={this.props.options}
        propValidationPatternsArr={propValidationPatternsArr}
        addClasses={this.props.addClasses}
        idForTest={this.props.idForTest}
        openDirection={this.props.openDirection}
        creationIsProhibited={this.props.creationIsProhibited}
        disabled={this.props.disabled}
        placeholder={this.props.placeholder}
        selectInputId={this.props.selectInputId}
        isSearchable={this.props.isSearchable}
        isTagLabelEditable={this.props.isTagLabelEditable}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propValue: state.organization.mainData[ownProps.propName]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET,
      value: {
        prop: prop,
        data: data
      }}),
    onGetOrganizationMainDataProp: (prop, data) => dispatch({type: organizationMainDataActions.ORGANIZATION_MAIN_DATA_PROP_SET,
      value: {
        prop: prop,
        data: data
      }})

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationMainDataFormGroup);