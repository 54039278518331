//@flow
import * as React from 'react';
import {connect} from "react-redux";
import validationActions from "../../../store/validation/validationActions";
import validationPatterns from "../../../services/validationPatterns/validationPatterns";
import {
  onGetValidationStateFormProp,
  onGetValidationStateProp
} from "../../../services/flowTypes/propFnFlowTypes";
import type {
  isScrollToError,
  validationIsAllowed
} from "../../../services/flowTypes/dataFlowTypes";
import NFormGroup from "../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import type {children} from "../../../services/flowTypes/appFlowTypes";
import type {arrLength, propError} from "../../../services/flowTypes/componentFlowTypes";
import type {propName, propValidationPatternsArr} from "../../../services/flowTypes/componentFlowTypes";
import type {determineArrLength} from "../../../services/flowTypes/propFnFlowTypes";

type Props = {
  propValidationPatternsArr?: propValidationPatternsArr,
  determineArrLength: determineArrLength,
  propName: propName,
  arrLength: arrLength,
  onGetValidationStateProp: onGetValidationStateProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  children: children,
  validationIsAllowed: validationIsAllowed,
  isScrollToError: isScrollToError,
  propError: propError
};

class ArrLengthValidator extends React.Component <Props, State> {
  validate = () => {
    const arrLength = this.props.determineArrLength();
    const error = validationPatterns[this.props.propValidationPatternsArr](arrLength);
    this.props.onGetValidationStateProp('isScrollToError', false);
    this.props.onGetValidationStateFormProp(this.props.propName, error);
  };
  componentDidUpdate(prevProps){
    if (prevProps.arrLength !== this.props.arrLength) {
      this.validate();
    }
  }
  componentDidMount(){
    this.validate();
  }
  render() {
    const propError = this.props.validationIsAllowed ? this.props.propError : '';
    return (
      <NFormGroup isScrollToError={this.props.isScrollToError} propError={propError}>
        {this.props.children}
      </NFormGroup>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propError: state.validation.form[ownProps.propName],
    validationIsAllowed: state.validation.validationIsAllowed,
    isScrollToError: state.validation.isScrollToError
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetValidationStateProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ArrLengthValidator);