import moment from "moment";
// import 'moment/locale/ru';
// import 'moment/locale/en-gb'
import text from "../localization/text";

// const momentLocale = process.env.LANG === 'ru' ? 'ru' : 'en-gb'

// moment.locale(momentLocale);

const days = [text.datepicker.sunday, text.datepicker.monday, text.datepicker.thursday, text.datepicker.wednesday, text.datepicker.tuesday, text.datepicker.friday, text.datepicker.saturday]
const months = [text.datepicker.january, text.datepicker.february, text.datepicker.march, text.datepicker.april, text.datepicker.may, text.datepicker.june, text.datepicker.july, text.datepicker.august, text.datepicker.september, text.datepicker.october, text.datepicker.november, text.datepicker.december];
export const locale = {
    localize: {
        day: n => days[n],
        month: n => months[n]
    },
    formatLong: {
        date: () => 'mm/dd/yyyy'
    },
    match: () => {}
}

export default moment;
