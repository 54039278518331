//@flow
import * as React from 'react';
import {connect} from "react-redux";
import groupFinTermsValidationScheme from "../../../../../../../../../../../configData/groupFinTermsValidationScheme";
import type {
  finTerm
} from "../../../../../../../../../../../services/flowTypes/dataFlowTypes";
import type {children} from "../../../../../../../../../../../services/flowTypes/appFlowTypes";
import type {finTermIndex} from "../../../../../../../../../../../services/flowTypes/componentFlowTypes";
import ArrLengthValidator
  from "../../../../../../../../../../../components/service/ArrLengthValidator/ArrLengthValidator";

type Props = {
  finTermIndex: finTermIndex,
  finTerms: Array<finTerm>,
  children: children
};

class FinTermParamsLengthValidator extends React.Component <Props> {
  determineArrLength = () => {
    let finTermParamsLengthBoolean = true;
    for (let i=0; i < this.props.finTerms.length; i++) {
      if (! (this.props.finTerms[i].status && this.props.finTerms[i].status === 'DELETED')) {
        finTermParamsLengthBoolean = finTermParamsLengthBoolean && this.props.finTerms[i].params.length;
      }
    }
    return finTermParamsLengthBoolean ? 1 : 0;
  };
  render() {
    return (
      <ArrLengthValidator propName={'finTermParamsLength'} arrLength={this.props.finTerms[this.props.finTermIndex].params.length}
                          propValidationPatternsArr={groupFinTermsValidationScheme.finTerms.finTermParamsLength}
                          determineArrLength={this.determineArrLength}>
        {this.props.children}
      </ArrLengthValidator>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    finTerms: state.organization.item.finTermsEdit
  };
};
export default connect(mapStateToProps, null)(FinTermParamsLengthValidator);