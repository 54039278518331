//@flow
import * as React from 'react';
import {connect} from "react-redux";
import NHeading3 from "../../../../../../../../../../../components/simple/TextComponents/NHeading3/NHeading3";
import concatIdName from "../../../../../../../../../../../utils/concatIdName";
import type {finTermId} from "../../../../../../../../../../../services/flowTypes/dataFlowTypes";
import type {finTermIndex} from "../../../../../../../../../../../services/flowTypes/componentFlowTypes";
import text from "../../../../../../../../../../../services/localization/text";

type Props = {
  id: finTermId,
  finTermIndex: finTermIndex
};

class FinTermName extends React.Component <Props, void> {
  render() {
    const name = this.props.id ? concatIdName(this.props.id, text.finTermTitle) : text.newFinTermTitle;
    return (
      <NHeading3>{name}</NHeading3>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    id: state.organization.item.finTermsEdit[ownProps.finTermIndex] ?
      state.organization.item.finTermsEdit[ownProps.finTermIndex].id : null
  };
};
export default connect(mapStateToProps, null)(FinTermName);