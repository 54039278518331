//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './OnOffIcon.css';

type Props = {
  id?: id,
  isOn: boolean,
  addClasses?: addClasses
};

const OnOffIcon = (props: Props) => {
  const className = props.isOn ? "OnOffIcon OnOffIcon_on" : "OnOffIcon";
  return (
    <div id={props.id} className={baseClass(className, props.addClasses)}/>
  );
};

export default OnOffIcon;