//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {
  formFieldName,
  formFieldValue,
  id, label, onBlur, onChange, onFocus,
  placeholder
} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './FilterInput.css';
import type {filterSelectBtn, inputMaxLength} from "../../../../services/flowTypes/componentFlowTypes";
import NInput from "../NInput/NInput";

type Props = {
  id?: id,
  addClasses?: addClasses,
  placeholder?: placeholder,
  value: formFieldValue,
  name?: formFieldName,
  onChange?: onChange,
  onFocus?: onFocus,
  onBlur?: onBlur,
  inputMaxLength?: inputMaxLength,
  propNameLabel?: label,
  filterSelectBtn?: filterSelectBtn
};

const FilterInput = (props: Props) => {
  return (
    <div className="flex">
      <div id={props.id} className={baseClass("FilterInput", props.addClasses)}>
        <div className="FilterInput__propNameLabel">{props.propNameLabel + ":"}</div>
        <NInput id={props.idForTest} value={props.value} onChange={props.onChange}
                inputMaxLength={props.inputMaxLength} addClasses={props.addClasses} placeholder={props.placeholder}/>
      </div>
      {props.filterSelectBtn ? (
        <div className="ml_2px" >
          {props.filterSelectBtn}
        </div>
      ) : null}
    </div>

  );
};

export default FilterInput;