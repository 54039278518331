//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './HistoryCard.css';
import NHeading3 from "../../TextComponents/NHeading3/NHeading3";
import NCard from "../NCard/NCard";
import type {
  formattedHistoryItem,
  historyCardIndex,
  optionsAreShownOnMount
} from "../../../../services/flowTypes/componentFlowTypes";
import DataListContainer from "../DataListContainer/DataListContainer";
import Text1 from "../../TextComponents/Text1/Text1";
import UnviewedMark from "../../Icons/UnviewedMark/UnviewedMark";
import Heading4 from "../../TextComponents/Heading4/Heading4";
import {Link} from "react-router-dom";
import NDropDownDetails from "../NDropDownDetails/NDropDownDetails";
import TextOverflowHandler from "../TextOverflowHandler/TextOverflowHandler";
import type {historyActionDescription, historyActionId} from "../../../../services/flowTypes/dataFlowTypes";
import HistoryLog from "../../../composed/HistoryView/HistoryLog/HistoryLog";
import preventEventPropagation from "../../../../utils/preventEventPropagation";
import {organizationDataHistoryIds} from "../../../../tests/testIds";

type Props = {
  id?: id,
  addClasses?: addClasses,
  formattedHistoryItem: formattedHistoryItem,
  requestedActionIdList?: Array<historyActionId>,
  historyLogs?: Array<historyActionDescription>,
  historyCardIndex: historyCardIndex,
  optionsAreShownOnMount?: optionsAreShownOnMount
};

const HistoryCard = (props: Props) => {
  const formattedHistoryItemUserActions =
    props.formattedHistoryItem.formattedHistoryItemUserActions.map((userActions, i) => {
    const userActionsClassName = props.formattedHistoryItem.formattedHistoryItemUserActions.length - 1 === i ?
      "" : "mb_3du";
    const formattedActions = userActions.formattedActions.map((action, index) =>{
      const historyActionDescription = props.historyLogs ? props.historyLogs.find(log => log.actionId === action.id) : null;
      const historyLog = historyActionDescription ?
        <HistoryLog historyActionDescription={historyActionDescription} objectType={action.objectType}
                    groupFinTermType={action.groupFinTermType}/>
      : null;
      const fnRequestOnClick = historyActionDescription ? null : action.getDescriptionFn;
      const requestInProcess = props.requestedActionIdList ? props.requestedActionIdList.includes(action.id) : false;
      return (
        <NDropDownDetails
          fnRequestOnClick={fnRequestOnClick} optionsContainerHeightIsAuto={true}
          optionsAreShownOnMount={props.optionsAreShownOnMount}
          requestInProcess={requestInProcess} isDropDownIconHidden={!action.getDescriptionFn}
          key={'formattedAction'+index} addClasses={'NDropDownDetails_in_HistoryCard'}
          testIdForSummaryIco={organizationDataHistoryIds.buttonCallActionLog(props.historyCardIndex, index)}
                          detailsSummary={
              <div className="flex HistoryCard__action-row">
                <UnviewedMark isUnviewed={action.isUnviewed}/>
                <Text1 addClasses={'HistoryCard__1st-col'}>{action.formattedTime}</Text1>
                <Text1 addClasses={'HistoryCard__2nd-col'}>{action.formattedActionDescription}</Text1>
                <div className="HistoryCard__3rd-col">
                  <Link className={'Text1  Text1_history-link'} to={action.formattedLink}
                        id={organizationDataHistoryIds.linkToAction(props.historyCardIndex, index)}
                        onClick={preventEventPropagation}>
                    <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>
                      {action.formattedLinkLabel}
                    </TextOverflowHandler>
                  </Link>
                </div>

              </div>
            }
         detailsOptions={[historyLog]}/>

      );
    });
    return (
      <div key={'userAction'+i} className={userActionsClassName}>
        <Heading4 addClasses={'Heading4_lh16 mb_1du'}>{userActions.userName}</Heading4>
        {formattedActions.length ? (
          <DataListContainer itemsList={formattedActions}/>
        ) : null}
      </div>
    );
  });
  return (
    <NCard id={props.id} addClasses={baseClass("NCard__wide-width HistoryCard", props.addClasses)}>
      <NHeading3 addClasses={'mb_4du'}>{props.formattedHistoryItem.date}</NHeading3>
      {formattedHistoryItemUserActions}
    </NCard>
  );
};

export default HistoryCard;