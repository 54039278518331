//@flow
import * as React from 'react';
import templateParamsActions
  from "../../../../../../../../../store/organization/item/templateParams/templateParamsActions";
import {connect} from "react-redux";
import NModal from "../../../../../../../../../components/simple/Modals/NModal/NModal";
import text from "../../../../../../../../../services/localization/text";
import RenderOnReady from "../../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {
  actTemplateParamName,
  contractId,
  convertedTag,
  converterSourcesCurrencies,
  currency,
  templateParams,
  variableName
} from "../../../../../../../../../services/flowTypes/dataFlowTypes";
import type {
  cancelModal,
  onGetActTemplateProp,
  onGetTemplateParams, onResetActTemplateExpressions
} from "../../../../../../../../../services/flowTypes/propFnFlowTypes";
import type {errorObject, isReadyToRender} from "../../../../../../../../../services/flowTypes/appFlowTypes";
import type {editMode, selectOptions} from "../../../../../../../../../services/flowTypes/componentFlowTypes";
import api from "../../../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../../../services/apiInstance/apiErrorHandler";
import NFormGroup from "../../../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import NSelect from "../../../../../../../../../components/simple/FormComponents/NSelect/NSelect";
import convertationDateList from "../../../../../../../../../configData/convertationDate";
import createOption from "../../../../../../../../../utils/createOption";
import {actTemplatesEditPageIds} from "../../../../../../../../../tests/testIds";
import checkObjValuesForEmpltyStrings from "../../../../../../../../../utils/checkObjValuesForEmptyStrings";
import actTemplatesEditActions
  from "../../../../../../../../../store/organization/item/actTemplatesEdit/actTemplatesEditActions";

type Props = {
  paramName: actTemplateParamName,
  cancelModal: cancelModal,
  variableName: variableName,
  contractId: contractId,
  onGetTemplateParams: onGetTemplateParams,
  templateParams: templateParams,
  editMode?: editMode,
  convertedTag?: convertedTag,
  onGetActTemplateProp: onGetActTemplateProp,
  groupFinTermCurrency: currency,
  onResetActTemplateExpressions: onResetActTemplateExpressions
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  currencyConverterSourceOptions: selectOptions,
  converterSourcesCurrencies: converterSourcesCurrencies,
  convertationDateOptions: selectOptions,
  currencySelectOptions: selectOptions,
  requestObject: {
    currency: string,
    date: string,
    source: string,
    variableName: string
  }
};

class GetConvertedTagModal extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      currencyConverterSourceOptions: [],
      convertationDateOptions: [],
      currencySelectOptions: [],
      converterSourcesCurrencies: [],
      requestObject: {
        currency: '',
        date: '',
        source: '',
        variableName: ''
      },
      showErrors: false
    };
  }
  getSelectOptions = (list, textProp) => {
    const listForOptions = textProp === 'currencyConverterSource' ? list.map(item => item.converterSource) : [...list];
    return listForOptions.map(source => {
      return {value: source, label: text[textProp][source]};
    });
  };
  getCurrencySelectOptions = (converterSourcesCurrencies, source) => {
    const converterSourceCurrenciesObj = converterSourcesCurrencies.find(item => item.converterSource === source);
    if (converterSourceCurrenciesObj) {
      const currencyOptions = converterSourceCurrenciesObj.currencies.filter(item => item !== this.props.groupFinTermCurrency);
      return currencyOptions.map(item => {
        return createOption(item);
      });
    } else {
      throw new Error('Currencies are not found for chosen converter source');
    }
  };
  getConvertedTag = () => {
    if (checkObjValuesForEmpltyStrings(this.state.requestObject)) {
      this.setState({showErrors: true});
    } else {
      this.setState({isReadyToRender: false});
      const requestObject = {...this.state.requestObject, finTermTemplateParams: this.props.templateParams};
      api.getConvertedTag(this.props.contractId, requestObject)
        .then(response => {
          this.props.onGetTemplateParams(response.data);
          this.props.onResetActTemplateExpressions();
          //this function call is hack to change actTemplatesEdit state to able the Save button, this prop is not used in request to update actTemplate
          this.props.onGetActTemplateProp('convertedTagUpdated', true);
          this.setState({
            isReadyToRender: true
          });
          this.props.cancelModal();
        })
        .catch(error => {
          this.setState({
            isReadyToRender: true,
            errorObject: apiErrorHandler(error)
          });
        });
    }
  };
  handleSelectChange = (selectedOption, propName) => {
    const requestObject = propName === 'source' ? {...this.state.requestObject, currency: '',  [propName]: selectedOption.value}
    : {...this.state.requestObject, [propName]: selectedOption.value};
    if (propName === 'source') {
      const currencySelectOptions = this.getCurrencySelectOptions(this.state.converterSourcesCurrencies, selectedOption.value);
      this.setState({requestObject, currencySelectOptions});
    } else {
      this.setState({requestObject});
    }
  };
  componentDidMount(){
    const requestObject = {
        currency: this.props.editMode ? this.props.convertedTag.currency : '',
        date: this.props.editMode ? this.props.convertedTag.date : '',
        source: this.props.editMode ? this.props.convertedTag.source : '',
        variableName: this.props.variableName
    };
    api.getConverterSources()
      .then(response => {
        const filteredCurrencyConverterSourceOptions =
          response.data.filter(item => item.currencies.includes(this.props.groupFinTermCurrency));
        this.setState({
          requestObject,
          currencyConverterSourceOptions: this.getSelectOptions(filteredCurrencyConverterSourceOptions, 'currencyConverterSource'),
          convertationDateOptions: this.getSelectOptions(convertationDateList, 'convertationDate'),
          converterSourcesCurrencies: response.data,
          currencySelectOptions: this.props.editMode ? this.getCurrencySelectOptions(response.data, requestObject.source) : [],
          isReadyToRender: true
        });
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  }
  render(){
    const modalTitle = this.props.editMode ? text.convertedTagEditModeModalTitle
      : text.convertedTagModalTitle;
    const modalSubTitle = text.convertedTagModalSubTitle(this.props.paramName);
    const selectedSource = this.state.currencyConverterSourceOptions.find(item => item.value === this.state.requestObject.source);
    const selectedConvertationDate = this.state.convertationDateOptions.find(item => item.value === this.state.requestObject.date);
    const selectedCurrency = this.state.requestObject.currency === '' ? null : this.state.currencySelectOptions.find(item => item.value === this.state.requestObject.currency);
    const convertationDateError = this.state.showErrors && !this.state.requestObject.date.length ? text.convertationDateError : '';
    const convertationSourceError = this.state.showErrors && !this.state.requestObject.source.length ? text.convertationSourceError : '';
    const convertationCurrencyError = this.state.showErrors && !this.state.requestObject.currency.length ? text.convertationCurrencyError : '';
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
        <NModal
        modalTitle={modalTitle}
        modalSubTitle={modalSubTitle}
        onModalActionBtnClick={this.getConvertedTag}
        onModalCancelBtnClick={this.props.cancelModal}
        modalActionBtnText={text.btnAdd}
        modalCancelBtnText={text.cancel}
        isModalDocumentEdit={true}
        >
          <NFormGroup label={text.convertationDateLabel} addClasses={'mt_4du mb_3du'} propError={convertationDateError}>
            <NSelect value={selectedConvertationDate}
                     onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'date')}
                     options={this.state.convertationDateOptions}
                     id={actTemplatesEditPageIds.selectConvertationDate}
                     inputId={actTemplatesEditPageIds.selectInputConvertationDate}
                     isSearchable={true} placeholder={text.selectConvertationDatePlaceholder}/>

          </NFormGroup>
          <NFormGroup label={text.currencyConverterSourceLabel} addClasses={'mb_3du'} propError={convertationSourceError}>
            <NSelect value={selectedSource}
                     onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'source')}
                     options={this.state.currencyConverterSourceOptions}
                     id={actTemplatesEditPageIds.selectConvertationSource}
                     inputId={actTemplatesEditPageIds.selectInputConvertationSource}
                     isSearchable={true} placeholder={text.selectCurrencyConverterSourcePlaceholder}/>
          </NFormGroup>
          <NFormGroup label={text.currency} addClasses={'mb_3du'} propError={convertationCurrencyError}>
            <NSelect value={selectedCurrency}
                     onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'currency')}
                     options={this.state.currencySelectOptions}
                     id={actTemplatesEditPageIds.selectCurrency}
                     inputId={actTemplatesEditPageIds.selectInputCurrency}
                     noOptionsMessage={() => text.selectConvertorSourceBefore}
                     isSearchable={true} placeholder={text.selectCurrencyPlaceholder}/>
          </NFormGroup>
        </NModal>
      </RenderOnReady>

    );
  }
}
const mapStateToProps = state => {
  return {
    contractId: state.organization.item.contractMainData.id,
    templateParams: state.organization.item.templateParams
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onResetActTemplateExpressions: () => dispatch({type: actTemplatesEditActions.ACT_TEMPLATES_EXPRESSIONS_RESET}),
    onGetTemplateParams: (templateParams) => dispatch({type: templateParamsActions.TEMPLATE_PARAMS_SET, value: templateParams}),
    onGetActTemplateProp: (prop, data) => dispatch({type: actTemplatesEditActions.ACT_TEMPLATES_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GetConvertedTagModal);
