//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import RedirectToCorrectOrgUrl
  from "../../../../../../components/service/RedirectToCorrectOrgUrl/RedirectToCorrectOrgUrl";
import contractGatesParamsActions
  from "../../../../../../store/organization/item/agentGatesParams/contractGatesParamsActions";
import type {errorObject, history, isReadyToRender} from "../../../../../../services/flowTypes/appFlowTypes";
import type {contractId, gatesParamsSearch, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import api from "../../../../../../services/apiInstance/api";
import handleCorrectOrgIdInResponse from "../../../../../../utils/handleCorrectOrgIdInResponse";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import debounce from 'lodash.debounce';

type Props = {
  onInitGatesParams: Function,
  onGetGatesParams: Function,
  history: history,
  gatesParamsSearch: gatesParamsSearch,
  contractId: contractId
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  correctOrganizationId: organizationId
};

class GatesParamsFetcher extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true,
      correctOrganizationId: null
    };
    this.debounceGatesParamsRequest = debounce(this.getGatesParams, 500);
  }
  handleResponse = (responseData) => {
    this.props.onGetGatesParams(responseData);
    this.setState({isReadyToRender: true});
  };
  getGatesParams = () => {
    this.setState({isReadyToRender: false});
    api.getContractGatesParams(this.props.contractId, this.props.gatesParamsSearch)
      .then(response => {
        handleCorrectOrgIdInResponse(this, response.data, this.setState, this.handleResponse);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if (prevProps.gatesParamsSearch !== this.props.gatesParamsSearch) {
      this.debounceGatesParamsRequest();
    }
  }
  componentDidMount(): * {
    this.props.onInitGatesParams();
  }

  render() {
    return (
      <RedirectToCorrectOrgUrl correctOrganizationId={this.state.correctOrganizationId} history={this.props.history}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
      </RedirectToCorrectOrgUrl>
    );
  }
}

const mapStateToProps = state => {
  return {
    gatesParamsSearch: state.organization.item.gatesParamsSearch
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitGatesParams: () => dispatch({type: contractGatesParamsActions.CONTRACT_GATES_PARAMS_INIT}),
    onGetGatesParams: (value) => dispatch({type: contractGatesParamsActions.CONTRACT_GATES_PARAMS_SET, value})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GatesParamsFetcher);    