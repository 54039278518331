//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {children, id, onClick} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './LinkToHistory.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  onClick?: onClick
};

const LinkToHistory = (props: Props) => {
  return (
    <div id={props.id} onClick={props.onClick}
            className={baseClass("LinkToHistory", props.addClasses)}>
      <span className="LinkToHistory__ico-container"/>
    </div>
  );
};

export default LinkToHistory;