//@flow
import * as React from 'react';
import {connect} from "react-redux";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import text from "../../../../../../services/localization/text";
import SectionHeader from "../../../../../../components/composed/SectionHeader/SectionHeader";
import {Link} from "react-router-dom";
import BtnEdit from "../../../../../../components/simple/Buttons/BtnEdit/BtnEdit";
import Can from "../../../../../../services/permissions/Can";
import {
  addServicePath,
  contrPath, contrPathEdit, docsSuffix, newAddService_docsSuffix,
  newAddService_sheetsSuffix,
  newAddService_typeSuffix,
  orgPath, sheetsSuffix, typeSuffix
} from "../../../../../../services/urlStrings/urlStrings";
import type {history, locationType, match} from "../../../../../../services/flowTypes/appFlowTypes";
import {addServiceAllDataPageIds} from "../../../../../../tests/testIds";
import PropValueView from "../../../../../../components/composed/PropValueView/PropValueView";
import type {
  actTemplatesList,
  addService,
  agentsList,
  usersList
} from "../../../../../../services/flowTypes/dataFlowTypes";
import formatDate from "../../../../../../utils/formatDate";
import SectionDivider from "../../../../../../components/simple/Containers/SectionDivider/SectionDivider";
import AddServiceFormGroup from "./addServiceEdit/AddServiceFormGroup";
import concatIdName from "../../../../../../utils/concatIdName";
import AddServiceDocsEdit from "./AddServiceDocsEdit";
import SheetView from "../../../../../../components/composed/Sheet/SheetView";
import getNameOrDefName from "../../../../../../utils/getNameOrDefName";
import {getItemNameByIdFromList} from "../../../../../../utils/getItemByIdFromList";
import ValidateAllDataOnMount from "./addServiceEdit/ValidateAllDataOnMount";
import {permissionsStrValues} from "../../../../../../configData/permissionsStrValues";
import TextOverflowHandler
  from "../../../../../../components/simple/Containers/TextOverflowHandler/TextOverflowHandler";
import type {selectOptions} from "../../../../../../services/flowTypes/componentFlowTypes";

type Props = {
  match: match,
  addService: addService,
  usersList: usersList,
  agentsList: agentsList,
  actTemplatesList: actTemplatesList,
  history: history,
  location: locationType
};
type State = {
  agentsOptions: selectOptions
};

const getAddServiceId = (propsMatchParams) => {
  return propsMatchParams.addServiceId ? +propsMatchParams.addServiceId : null;
};
export const defineAddServiceEditLinks = (propsMatchParams) => {
  const addServiceEditPrefix = orgPath(propsMatchParams.organizationId) + contrPathEdit(propsMatchParams.id) + addServicePath(getAddServiceId(propsMatchParams));
  const newAddServicePrefix = orgPath(propsMatchParams.organizationId) + contrPath(propsMatchParams.id);
  const linkToTypeEdit = getAddServiceId(propsMatchParams) ? addServiceEditPrefix + typeSuffix
    : newAddServicePrefix + newAddService_typeSuffix;
  const linkToSheetsEdit = getAddServiceId(propsMatchParams) ? addServiceEditPrefix + sheetsSuffix
    : newAddServicePrefix + newAddService_sheetsSuffix;
  const linkToDocsEdit = getAddServiceId(propsMatchParams) ? addServiceEditPrefix + docsSuffix
    : newAddServicePrefix + newAddService_docsSuffix;
  return {linkToTypeEdit, linkToSheetsEdit, linkToDocsEdit};
};

class AddServiceAllData extends React.Component <State, Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      agentsOptions: []
    };
  }
  componentDidMount(): * {
    const agentsOptions = this.props.agentsList.map(item => {
      return {value: item.id, label: item.name}
    });
    this.setState({agentsOptions});
  }

  render() {
    const {linkToTypeEdit, linkToSheetsEdit, linkToDocsEdit} = defineAddServiceEditLinks(this.props.match.params);
    const payerArr = this.state.agentsOptions.filter((item) => {return item.value === this.props.addService.payerId});
    const payeeArr = this.state.agentsOptions.filter((item) => {return item.value === this.props.addService.payeeId});
    const payer = payerArr.length ? payerArr[0].label : null;
    const payee = payeeArr.length ? payeeArr[0].label : null;
    const userName = getItemNameByIdFromList(this.props.addService.userId, this.props.usersList, '!User not found');
    const actTemplateObj = this.props.actTemplatesList.find(item => item.id === this.props.addService.actTemplateId);
    const actTemplateIdName = actTemplateObj ? concatIdName(actTemplateObj.id, getNameOrDefName(actTemplateObj.name, text.actTemplate))
      : '!Act Template not found';
    const sheets = this.props.addService.sheets.map((item, i) => {
      return (<SheetView key={'sheet'+i} sheetIndex={i} sheet={item}/>)
    });
    const permissionStr = getAddServiceId(this.props.match.params) ?
      permissionsStrValues.addService.update : permissionsStrValues.addService.create;
    const component = (
      <>
        <NCard addClasses={'NCard__wide-width mb_5du'}>
          <div className="flex_jc-sb">
            <SectionHeader sectionHeaderTitle={text.addServiceDescSubheader} addClasses={"mb_3du"}/>
            {this.props.addService.hasPreparedAddServiceReport ? null : (
              <Can do={'usePermission'} on={permissionStr}>
                <Link to={linkToTypeEdit}
                      id={addServiceAllDataPageIds.buttonEditAddServiceType}>
                  <BtnEdit/>
                </Link>
              </Can>
            )}
          </div>
          <PropValueView label={text.addServiceFormLabels.name} value={this.props.addService.name} addClasses={"mb_2du"}
                         testIdForValue={addServiceAllDataPageIds.headingAddServiceName}/>
          {this.props.location.pathname.includes('/view/') && this.props.addService.id ? (
            <input type="hidden" id={addServiceAllDataPageIds.inputHiddenAddServiceId} value={this.props.addService.id}/>
          ) : null}
          {this.props.location.pathname.includes('/view/') && this.props.addService.internalTemplateId ? (
            <input type="hidden" id={addServiceAllDataPageIds.inputHiddenInternalTemplateId}
                   value={this.props.addService.internalTemplateId}/>
          ) : null}
          <div className="flex mb_1du">
            <PropValueView label={text.payer} value={
              <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{payer}</TextOverflowHandler>
            } addClasses={"mb_1du mr_3du w_half-card"}/>
            <PropValueView label={text.payee} value={
              <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{payee}</TextOverflowHandler>
            } addClasses={"mb_1du w_half-card"}/>
          </div>
          <div className="flex flex_wrap">
            <div className="flex_jc-sb w_half-card mr_3du mb_1du">
              <PropValueView label={text.addServiceFormLabels.userId} value={userName} addClasses={"w_quarter-card"}/>
              <PropValueView label={text.addServiceFormLabels.date} value={formatDate(this.props.addService.date)}
                             addClasses={"w_quarter-card"}/>
            </div>
            <PropValueView label={text.addServiceFormLabels.payoutPeriod} value={text[this.props.addService.payoutPeriod]}
                           addClasses={"w_quarter-card"}/>
          </div>
          <SectionDivider addClasses={'mt_6du mb_6du'}/>
          <SectionHeader sectionHeaderTitle={text.header1c} addClasses={"mb_3du"}/>
          <div className="flex flex_wrap">
            <PropValueView label={text.addServiceFormLabels.nomenNumber1c} value={this.props.addService.nomenNumber1c}
                           addClasses={"mr_3du mb_1du"}/>
            <PropValueView label={text.addServiceFormLabels.projectName} value={this.props.addService.projectName}
                           addClasses={"mr_3du"}/>
          </div>
          <AddServiceFormGroup propName={'ignoreInExport'} formFieldType={'checkbox-boolean'} readOnly={true}
                               label={text.addServiceFormLabels.ignoreInExport}/>
          <SectionDivider addClasses={'mt_6du mb_6du'}/>
          <SectionHeader sectionHeaderTitle={text.addServiceInvoiceSubheader}/>

          <AddServiceFormGroup propName={'isDetachedInvoice'} formFieldType={'checkbox-boolean'} addClasses={'mb_1du'}
                               label={text.addServiceFormLabels.isDetachedInvoice} readOnly={true}/>
          {this.props.addService.isDetachedInvoice ? null : (
            <PropValueView label={text.addServiceFormLabels.actTemplateId} value={actTemplateIdName}/>)
          }
        </NCard>
        <AddServiceDocsEdit readonly={true} contractId={this.props.match.params.id} linkToDocsEdit={linkToDocsEdit}
                            permissionStr={permissionStr}
                            hasPreparedAddServiceReport={this.props.addService.hasPreparedAddServiceReport}
                            organizationId={this.props.match.params.organizationId}/>
        <NCard addClasses={'NCard__wide-width mb_5du'}>
          <div className="flex_jc-sb mb_3du">
            <SectionHeader sectionHeaderTitle={text.addServiceSheetsSubheader}/>
            {this.props.addService.hasPreparedAddServiceReport ? null : (
              <Can do={'usePermission'} on={permissionStr}>
                <Link to={linkToSheetsEdit}
                      id={addServiceAllDataPageIds.buttonEditAddServiceSheets}>
                  <BtnEdit/>
                </Link>
              </Can>
            )}
          </div>
          {sheets}
        </NCard>
      </>
    );
    return (
      this.props.location.pathname.includes('/view/') ? component : (
        <ValidateAllDataOnMount history={this.props.history} match={this.props.match}
                                linkToTypeEdit={linkToTypeEdit} linkToSheetsEdit={linkToSheetsEdit}>
          {component}
        </ValidateAllDataOnMount>
        )
    );
  }
}

const mapStateToProps = state => {
  return {
    agentsList: state.organization.organizationData.agentsList,
    addService: state.organization.item.addServiceEdit,
    usersList: state.organization.organizationData.usersList,
    actTemplatesList: state.organization.organizationData.actTemplatesList,
  };
};
export default connect(mapStateToProps, null)(AddServiceAllData);