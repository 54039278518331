import { AbilityBuilder } from '@casl/ability';
// import {apiClient} from "../apiInstance/DefaultApi";
import updateCsrf from "./updateCsrf";
import checkWindowAppData from "./checkWindowAppData";

export default AbilityBuilder.define(can => {
  checkWindowAppData();
  if (window.APP_DATA) {
    // let permissions = window.APP_DATA.permissions.slice();
    // let role = window.APP_DATA.role;
    // for(let i = 0; i < permissions.length; i++){
    //   can('usePermission', permissions[i]);
    // }
    // can('useRole', role);

    const globalPermissions = JSON.parse(window.APP_DATA.globalPermissions);
    if (globalPermissions.length) {
      for(let i = 0; i < globalPermissions.length; i++){
        can('useGlobalPermission', globalPermissions[i]);
      }
    }
    updateCsrf(window.APP_DATA.csrfToken);
    // apiClient.defaults.headers.common[window.APP_DATA.csrfToken.headerName] = window.APP_DATA.csrfToken.value;
  }

})