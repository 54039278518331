//@flow
import * as React from 'react';
import {Redirect, Route} from "react-router-dom";
import PrivateRoute, {PrivateRouteInRightSidebar} from "./PrivateRoute";
import ability from "../../../services/permissions/permissions";
import Contracts from "../../../pages/Contracts/Contracts";
import NewUiKit from "../../../pages/UiKit/NewUiKit";
import type {organizationId} from "../../../services/flowTypes/dataFlowTypes";
import Agents from "../../../pages/Agents/Agents";
import Contract from "../../../pages/Contracts/pages/Contract/Contract";
import ContractMainDataView
  from "../../../pages/Contracts/pages/Contract/pages/ContractMainDataView/ContractMainDataView";
import ContractMainDataEdit
  from "../../../pages/Contracts/pages/Contract/pages/ContractMainDataEdit/ContractMainDataEdit";
import ContractAgents from "../../../pages/Contracts/pages/Contract/pages/ContractAgents/ContractAgents";
import NewContract from "../../../pages/Contracts/pages/Contract/NewContract";
import Agent from "../../../pages/Agents/pages/Agent/Agent";
import AgentMainDataView from "../../../pages/Agents/pages/Agent/pages/AgentMainDataView/AgentMainDataView";
import AgentMainDataEdit from "../../../pages/Agents/pages/Agent/pages/AgentMainDataEdit/AgentMainDataEdit";
import NewAgent from "../../../pages/Agents/pages/Agent/NewAgent";
import GroupFinTermsView from "../../../pages/Contracts/pages/Contract/pages/GroupFinTermsView/GroupFinTermsView";
import FinTermsView from "../../../pages/Contracts/pages/Contract/pages/FinTermView/FinTermView";
import GroupFinTermsEdit from "../../../pages/Contracts/pages/Contract/pages/GroupFinTermsEdit/GroupFinTermsEdit";
import ContractDocuments from "../../../pages/Contracts/pages/Contract/pages/ContractDocuments/ContractDocuments";
import Documents from "../../../pages/Documents/Documents";
import AgentDocuments from "../../../pages/Agents/pages/Agent/pages/AgentDocuments/AgentDocuments";
import ActTemplatesView from "../../../pages/Contracts/pages/Contract/pages/ActTemplatesView/ActTemplatesView";
import ActTemplateView from "../../../pages/Contracts/pages/Contract/pages/ActTemplateView/ActTemplateView";
import ActTemplatesEdit from "../../../pages/Contracts/pages/Contract/pages/ActTemplatesEdit/ActTemplatesEdit";
import AgentContracts from "../../../pages/Agents/pages/Agent/pages/AgentContracts/AgentContracts";
import Reports from "../../../pages/Reports/Reports";
import Report from "../../../pages/Reports/pages/Report/Report";
import ReportMainDataView from "../../../pages/Reports/pages/Report/pages/ReportMainDataView/ReportMainDataView";
import {
  actTemplatePath,
  actTemplatesSuffix,
  addAgreementsSuffix,
  addServicePath,
  addServicesSuffix,
  adjustmentHistorySuffix,
  agentPath,
  agentPathEdit,
  agentPathView,
  agentsSuffix,
  confirmationSuffix,
  contractsSuffix,
  contrPath,
  contrPathEdit,
  contrPathView,
  docsSuffix,
  documentsSuffix,
  editNotificationsSuffix,
  editOrganizationSettingsSuffix,
  groupFinTermPath,
  groupFinTermsSuffix,
  historySuffix,
  mainDataSuffix,
  newActTemplateSuffix,
  newAddService_confirmationSuffix,
  newAddService_docsSuffix,
  newAddService_sheetsSuffix,
  newAddService_typeSuffix,
  newAddServiceSuffix,
  newAgentSuffix,
  newContractSuffix,
  newGroupFinTermSuffix,
  orgPath,
  orgSettingsSuffix,
  reportPath,
  reportPathView,
  reportsListByStatusPath,
  reportsSuffix,
  sheetsSuffix,
  sourcesGatesParamsSuffix,
  sourcesMainSuffix,
  sourcesSuffix,
  typeSuffix,
  viewAddParamsSettingsSuffix,
  viewNotificationsSuffix,
  viewOrganizationSettingsSuffix
} from "../../../services/urlStrings/urlStrings";
import AdjustmentHistory from "../../../pages/Reports/pages/Report/pages/AdjustmentHistory/AdjustmentHistory";
import canUseAnyone, {canUseOnlyForRoles} from "./canUseAnyone";
import ContractLinkedContracts
  from "../../../pages/Contracts/pages/Contract/pages/ContractLinkedContracts/ContractLinkedContracts";
import OrganizationDataHistoryWithURLSearchParamsHandler
  from "../../../pages/OrganizationDataHistory/OrganizationDataHistoryWithURLSearchParamsHandler";
import OrganizationSettings from "../../../pages/OrganizationSettings/OrganizationSettings";
import NotificationSettings from "../../composed/NotificationSettings/NotificationSettings";
import AddAgreements from "../../../pages/Contracts/pages/Contract/pages/AddAgreements/AddAgreements";
import ContractHistoryWithURLSearchParamsHandler
  from "../../../pages/Contracts/pages/Contract/pages/ContractHistory/ContractHistoryWithURLSearchParamsHandler";
import AgentHistoryWithURLSearchParamsHandler
  from "../../../pages/Agents/pages/Agent/pages/AgentHistory/AgentHistoryWithURLSearchParamsHandler";
import ReportHistoryWithURLSearchParamsHandler
  from "../../../pages/Reports/pages/Report/pages/ReportHistory/ReportHistoryWithURLSearchParamsHandler";
import OrganizationMainDataView
  from "../../../supPages/Organizations/pages/Organization/pages/OrganizationMainDataView/OrganizationMainDataView";
import OrganizationMainDataEdit
  from "../../../supPages/Organizations/pages/Organization/pages/OrganizationMainDataEdit/OrganizationMainDataEdit";
import Organization from "../../../supPages/Organizations/pages/Organization/Organization";
import OrganizationParams
  from "../../../supPages/Organizations/pages/Organization/pages/OrganizationParams/OrganizationParams";
import AddServices from "../../../pages/Contracts/pages/Contract/pages/AddServices/AddServices";
import AddServiceEditSubHeader
  from "../../../pages/Contracts/pages/Contract/pages/AddServiceEdit/AddServiceEditSubHeader";
import AddServiceTypeEdit from "../../../pages/Contracts/pages/Contract/pages/AddServiceEdit/AddServiceTypeEdit";
import AddServiceDocsEdit from "../../../pages/Contracts/pages/Contract/pages/AddServiceEdit/AddServiceDocsEdit";
import AddServiceSheetsEdit from "../../../pages/Contracts/pages/Contract/pages/AddServiceEdit/AddServiceSheetsEdit";
import AddServiceAllData from "../../../pages/Contracts/pages/Contract/pages/AddServiceEdit/AddServiceAllData";
import AddServiceView from "../../../pages/Contracts/pages/Contract/pages/AddServiceView/AddServiceView";
import {permissionsStrValues, rolesStrValues} from "../../../configData/permissionsStrValues";
import GatesParams from "../../../pages/Agents/pages/Agent/pages/GatesParams/GatesParams";
import ContractSourcesView from "../../../pages/Contracts/pages/Contract/pages/ContractSources/ContractSourcesView";
import ContractSourcesSubMenuView from "../../../pages/Contracts/pages/Contract/ContractSourcesSubMenuView";

type Props = {
  organizationId: organizationId
};

class Routes extends React.Component <Props, void> {
  render(){
    return (
      <>
        <Route exact path={orgPath(':organizationId')}
               render={() => (<Redirect to={orgPath(this.props.organizationId) + contractsSuffix} />)} />
        <PrivateRoute exact path={orgPath(':organizationId') + contractsSuffix} component={Contracts}
                      canUseRoute={canUseAnyone()} />
        <PrivateRoute exact path={orgPath(':organizationId') + agentsSuffix} component={Agents}
                      canUseRoute={canUseAnyone()} />
        <Route exact path={orgPath(':organizationId') + reportsSuffix}
               render={() => (<Redirect to={orgPath(this.props.organizationId) + reportsListByStatusPath('ALL')} />)} />
        <PrivateRoute exact path={orgPath(':organizationId') + reportsListByStatusPath(':status')} component={Reports}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.report.view)} />

        <PrivateRoute path={orgPath(':organizationId') + contrPath(':id')} component={Contract}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.contract.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathView(':id') + mainDataSuffix}
                      component={(ContractMainDataView)}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.contract.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathEdit(':id') + mainDataSuffix}
                      component={ContractMainDataEdit}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.contract.update)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + newContractSuffix} component={NewContract}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.contract.create)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + newContractSuffix} component={ContractMainDataEdit}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.contract.create)} />

        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathView(':id') + addAgreementsSuffix}
                      component={AddAgreements}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.addAgreement.view)} />

        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathView(':id') + agentsSuffix}
                      component={ContractAgents} canUseRoute={ability.can('usePermission', permissionsStrValues.contractLinkedAgent.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathView(':id') + contractsSuffix}
                        component={ContractLinkedContracts} canUseRoute={ability.can('usePermission', permissionsStrValues.contractLinkedContract.view)} />

        <PrivateRoute path={orgPath(':organizationId') + agentPath(':id')} component={Agent}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.agent.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + agentPathView(':id') + mainDataSuffix}
                      component={AgentMainDataView}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.agent.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + agentPathEdit(':id') + mainDataSuffix}
                      component={AgentMainDataEdit}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.agent.update)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + newAgentSuffix} component={NewAgent}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.agent.create)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + newAgentSuffix} component={AgentMainDataEdit}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.agent.create)} />

        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + agentPathView(':id') + contractsSuffix}
                      component={AgentContracts} canUseRoute={ability.can('usePermission', permissionsStrValues.agent.view)} />


        <PrivateRouteInRightSidebar
          path={orgPath(':organizationId') + contrPathView(':id') + sourcesSuffix}
          component={ContractSourcesSubMenuView}
          addClasses={"SideRightContainer--submenu-fixed"}
          canUseRoute={ability.can('usePermission', permissionsStrValues.finTerm.view)}
        />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathView(':id') + groupFinTermsSuffix}
                      component={GroupFinTermsView}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.finTerm.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathView(':id') + sourcesGatesParamsSuffix}
                                    component={GatesParams}
                                    canUseRoute={ability.can('usePermission', permissionsStrValues.gatesParams.view)
                                      && canUseOnlyForRoles([rolesStrValues.admin, rolesStrValues.manager])}/>
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathView(':id') + sourcesMainSuffix}
                                    component={ContractSourcesView}
                                    canUseRoute={ability.can('usePermission', permissionsStrValues.contract.update)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathView(':id') + groupFinTermPath(':groupId')}
                      component={FinTermsView}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.finTerm.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathEdit(':id') + groupFinTermPath(':groupId')}
                      component={GroupFinTermsEdit}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.finTerm.update)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPath(':id') + newGroupFinTermSuffix}
                      component={GroupFinTermsEdit}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.finTerm.create)} />

        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathView(':id') + documentsSuffix}
                      component={ContractDocuments}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.contractDocument.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathView(':id') + documentsSuffix + '/folder/:folderId'}
                        component={ContractDocuments}
                        canUseRoute={ability.can('usePermission', permissionsStrValues.contractDocument.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + documentsSuffix}
                      component={Documents}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.organizationDocument.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + documentsSuffix + '/folder/:folderId'}
                        component={Documents}
                        canUseRoute={ability.can('usePermission', permissionsStrValues.organizationDocument.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + agentPathView(':id') + documentsSuffix}
                      component={AgentDocuments}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.agentDocument.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + agentPathView(':id') + documentsSuffix + '/folder/:folderId'}
                      component={AgentDocuments}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.agentDocument.view)} />

        {/*<PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + agentPathView(':id') + gatesParamsSuffix}*/}
        {/*              component={GatesParams}*/}
        {/*              canUseRoute={ability.can('usePermission', permissionsStrValues.gatesParams.view)*/}
        {/*                && canUseOnlyForRoles([rolesStrValues.admin, rolesStrValues.manager])} />*/}

        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathView(':id') + actTemplatesSuffix}
                      component={ActTemplatesView}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.actTemplate.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathEdit(':id') + actTemplatePath(':actTemplateId')}
                      component={ActTemplatesEdit}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.actTemplate.update)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathView(':id') + actTemplatePath(':actTemplateId')}
                      component={ActTemplateView}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.actTemplate.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPath(':id') + newActTemplateSuffix} component={ActTemplatesEdit}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.actTemplate.create)} />

        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathView(':id') + addServicesSuffix}
                      component={AddServices}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.addService.view)} />
        <PrivateRouteInRightSidebar path={orgPath(':organizationId') + contrPath(':id') + newAddServiceSuffix}
                      component={AddServiceEditSubHeader}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.addService.create)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPath(':id') + newAddService_typeSuffix}
                      component={AddServiceTypeEdit}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.addService.create)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPath(':id') + newAddService_docsSuffix}
                      component={AddServiceDocsEdit}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.addService.create)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPath(':id') + newAddService_sheetsSuffix}
                      component={AddServiceSheetsEdit}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.addService.create)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPath(':id') + newAddService_confirmationSuffix}
                      component={AddServiceAllData}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.addService.create)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathView(':id') + addServicePath(':addServiceId')}
                      component={AddServiceView}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.addService.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathView(':id') + addServicePath(':addServiceId')}
                      component={AddServiceAllData}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.addService.view)} />
        <PrivateRouteInRightSidebar path={orgPath(':organizationId') + contrPathEdit(':id') + addServicePath(':addServiceId')}
                      component={AddServiceEditSubHeader}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.addService.update)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathEdit(':id') + addServicePath(':addServiceId') + typeSuffix}
                      component={AddServiceTypeEdit}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.addService.update)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathEdit(':id') + addServicePath(':addServiceId') + docsSuffix}
                      component={AddServiceDocsEdit}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.addService.update)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathEdit(':id') + addServicePath(':addServiceId') + sheetsSuffix}
                      component={AddServiceSheetsEdit}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.addService.update)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathEdit(':id') + addServicePath(':addServiceId') + confirmationSuffix}
                      component={AddServiceAllData}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.addService.update)} />


        <PrivateRoute path={orgPath(':organizationId') + reportPath(':id')} component={Report}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.report.view)} />

        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + reportPathView(':id') + mainDataSuffix}
                      component={ReportMainDataView}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.report.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + reportPathView(':id') + adjustmentHistorySuffix}
                      component={AdjustmentHistory}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.report.view)} />
        <PrivateRouteInRightSidebar path={orgPath(':organizationId') + historySuffix} component={OrganizationDataHistoryWithURLSearchParamsHandler}
                      canUseRoute={canUseAnyone()} />

        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + contrPathView(':id') + historySuffix}
                                    component={ContractHistoryWithURLSearchParamsHandler}
                                    canUseRoute={ability.can('usePermission', permissionsStrValues.contractHistory.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + agentPathView(':id') + historySuffix}
                                    component={AgentHistoryWithURLSearchParamsHandler}
                                    canUseRoute={ability.can('usePermission', permissionsStrValues.agentHistory.view)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + reportPathView(':id') + historySuffix}
                                    component={ReportHistoryWithURLSearchParamsHandler}
                                    canUseRoute={ability.can('usePermission', permissionsStrValues.report.view)} />


        {/*it's route for redirect to prepared status reports after bulk status update*/}
        <Route exact path={orgPath(':organizationId') + reportsSuffix + '/redirect-to-prepared'}
               render={() => (<Redirect to={orgPath(this.props.organizationId) + reportsListByStatusPath('PREPARED')} />)} />
        {/*{process.env.NODE_ENV !== 'production' ? (*/}
          <Route path={orgPath(':organizationId') + "/new-ui-kit"} component={NewUiKit}/>
        {/*) : null}*/}

        <Route exact path={orgPath(':organizationId') + orgSettingsSuffix}
               render={() => (<Redirect to={orgPath(this.props.organizationId) + orgSettingsSuffix + viewNotificationsSuffix} />)} />
        <PrivateRoute path={orgPath(':organizationId') + orgSettingsSuffix} component={OrganizationSettings}
                      canUseRoute={canUseAnyone()} />
        {/*<PrivateRoute path={orgPath(':organizationId') + orgSettingsSuffix + '/view'} component={OrganizationSettingsMenu}*/}
        {/*              canUseRoute={canUseAnyone()} />*/}
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + orgSettingsSuffix + viewNotificationsSuffix}
                      component={NotificationSettings}
                      canUseRoute={canUseAnyone()} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + orgSettingsSuffix + editNotificationsSuffix}
                      component={NotificationSettings}
                      canUseRoute={canUseAnyone()} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + orgSettingsSuffix + viewOrganizationSettingsSuffix}
                      component={Organization}
                      canUseRoute={canUseAnyone()} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + orgSettingsSuffix + editOrganizationSettingsSuffix}
                      component={Organization}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.organization.update)} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + orgSettingsSuffix + viewOrganizationSettingsSuffix}
                      component={OrganizationMainDataView}
                      canUseRoute={canUseOnlyForRoles([rolesStrValues.admin])} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + orgSettingsSuffix + editOrganizationSettingsSuffix}
                      component={OrganizationMainDataEdit}
                      canUseRoute={ability.can('usePermission', permissionsStrValues.organization.update)
                        && canUseOnlyForRoles([rolesStrValues.admin])} />
        <PrivateRouteInRightSidebar exact path={orgPath(':organizationId') + orgSettingsSuffix + viewAddParamsSettingsSuffix}
                      component={OrganizationParams}
                      canUseRoute={canUseOnlyForRoles([rolesStrValues.admin])} />

      </>
    );
  }
}

export default Routes;    