//@flow
import actTemplatesEditActions from './actTemplatesEditActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {
  actTemplate
} from "../../../../services/flowTypes/dataFlowTypes";

type State = actTemplate;
type reducerAction = action;
const initialState = {
  name: '',
  fileName: '',
  // fileId: -1,
  // documentDate: '',
  // createDate: '',
  // updateDate: '',
  finTermGroupIds: [],
  reportTotalExpression: '',
  reportFileNamePattern: '',
  reportTotalCountExpression: '',
  reportTotalAmountExpression: '',
  isVerified: false,
  addendumTemplateFileName: '',
  // addendumTemplateFileId: -1,
  // addendumTemplateFileDate: '',
  // addendumTemplateFile: ''
  currency: '',
  payoutPeriod: '',
  reportTagExpressions: []
};

const actTemplatesEditReducer = (state:State = initialState, action:reducerAction) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case actTemplatesEditActions.ACT_TEMPLATES_EDIT_INIT:
      return initialState;
    case actTemplatesEditActions.ACT_TEMPLATES_EDIT_SET:
      return action.value;
    case actTemplatesEditActions.ACT_TEMPLATES_PROP_SET:
      return {
        ...state,
        [action.value.prop]: action.value.data
      }
    case actTemplatesEditActions.ACT_TEMPLATES_PROP_DELETE:
      delete newState[action.value];
      return newState;
    case actTemplatesEditActions.ACT_TEMPLATES_EXPRESSIONS_RESET:
      newState.reportTotalCountExpression = '';
      newState.reportTotalAmountExpression = '';
      newState.reportTotalExpression = '';
      newState.reportTagExpressions = [];
      return newState;
    default:
      return state;
  }
};

export default actTemplatesEditReducer;