//@flow
import * as React from 'react';
import {connect} from "react-redux";
import groupFinTermsEditActions
  from "../../../../../../../store/organization/item/groupFinTermsEdit/groupFinTermsEditActions";
import type {
  argsForValidationFn,
  formFieldType, isClearable, isOptionOverflowVisible, isSearchable, labelTooltip, noOptionsMessage,
  propName,
  propValue,
  selectOptions
} from "../../../../../../../services/flowTypes/componentFlowTypes";
import {
  onGetGroupFinTermProp,
  onGetValidationStateFormProp, onSetFinTermsEdit, onSetSourceParamsEdit
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import type {disabled, id, label, placeholder} from "../../../../../../../services/flowTypes/appFlowTypes";
import ConnectedFormGroup from "../../../../../../../components/composed/ConnectedFormGroup/ConnectedFormGroup";
import groupFinTermsValidationScheme from "../../../../../../../configData/groupFinTermsValidationScheme";
import type {addClasses} from "../../../../../../../services/flowTypes/uiKitFlowTypes";
import type {configSourceParams} from "../../../../../../../services/flowTypes/dataFlowTypes";
import sourceParamsEditActions
  from "../../../../../../../store/organization/item/sourceParamsEdit/sourceParamsEditActions";
import finTermsEditActions from "../../../../../../../store/organization/item/finTermsEdit/finTermsEditActions";
import {filterOldPneSourceParams} from "../../FinTermView/FinTermView";
import getObjProp from "../../../../../../../utils/getObjProp";
import OutputOrWarn from "../../../../../../../components/simple/Containers/AlertCard/OutputOrWarn";

type Props = {
  formFieldType: formFieldType,
  propName: propName,
  propValue: propValue,
  onGetGroupFinTermProp: onGetGroupFinTermProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  label: label,
  options?: selectOptions,
  addClasses?: addClasses,
  idForTest?: id,
  configSourceParams: configSourceParams,
  onSetSourceParamsEdit: onSetSourceParamsEdit,
  onSetFinTermsEdit: onSetFinTermsEdit,
  disabled?: disabled,
  placeholder?: placeholder,
  isSearchable?: isSearchable,
  selectInputId?: id,
  isOptionOverflowVisible?: isOptionOverflowVisible,
  labelTooltip?: labelTooltip,
  argsForValidationFn?: argsForValidationFn,
  isClearable?: isClearable,
  noOptionsMessage?: noOptionsMessage
};

class GroupFinTermsFormGroup extends React.Component <Props, void> {
  handleSourceChange = (source) => {
    const newSourceParams = this.props.configSourceParams[source].sourceParams.map(item => {
      return {name: item.name, type: item.type, value: '', mask: item.mask};
    });
    const filteredNewSourceParams = filterOldPneSourceParams(source, newSourceParams);
    this.props.onSetSourceParamsEdit(filteredNewSourceParams);
    //clean fin terms and type if source was changed
    this.props.onSetFinTermsEdit([]);
    this.props.onGetGroupFinTermProp('type', '');
  };
  handleTypeChange = (type) => {
    const paramsRow = this.props.configFinTermParams[type];
    const newFinTerm = {
      startDate: '',
      endDate: '',
      addAgreementId: '',
      params: [paramsRow]
    };
    this.props.onSetFinTermsEdit([newFinTerm]);
  };
  onGetPropValue = (prop, data) => {
    if (prop === 'source') {
      this.handleSourceChange(data);
    }
    if (prop === 'type') {
      this.handleTypeChange(data);
    }
    this.props.onGetGroupFinTermProp(prop, data);
  };
  render() {
    const propValidationPatternsArr = groupFinTermsValidationScheme[this.props.propName] ?
      groupFinTermsValidationScheme[this.props.propName] : null;
    return (<OutputOrWarn propName={this.props.propName} propValue={this.props.propValue} component={
        <ConnectedFormGroup
          formFieldType={this.props.formFieldType}
          propName={this.props.propName}
          propValue={this.props.propValue}
          onGetPropValue={this.onGetPropValue}
          label={this.props.label}
          options={this.props.options}
          propValidationPatternsArr={propValidationPatternsArr}
          addClasses={this.props.addClasses}
          idForTest={this.props.idForTest}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          isSearchable={this.props.isSearchable}
          selectInputId={this.props.selectInputId}
          isOptionOverflowVisible={this.props.isOptionOverflowVisible}
          labelTooltip={this.props.labelTooltip}
          argsForValidationFn={this.props.argsForValidationFn}
          isClearable={this.props.isClearable}
          noOptionsMessage={this.props.noOptionsMessage}
        />
    }/>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propValue: getObjProp(state.organization.item.groupFinTermsEdit, ownProps.propName),
    configSourceParams: state.organization.organizationData.configFinTerms.configSourceParams,
    configFinTermParams: state.organization.organizationData.configFinTerms.configFinTermParams
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetGroupFinTermProp: (prop, data) => dispatch({type: groupFinTermsEditActions.GROUP_FIN_TERMS_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onSetSourceParamsEdit: (data) => dispatch({type: sourceParamsEditActions.SOURCE_PARAMS_EDIT_SET, value: data}),
    onSetFinTermsEdit: (data) => dispatch({type: finTermsEditActions.FIN_TERMS_EDIT_SET, value: data})

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupFinTermsFormGroup);