//@flow
/* eslint-disable no-template-curly-in-string */
import * as React from 'react';
import {connect} from "react-redux";
import NCard from "../../../../../../../components/simple/Containers/NCard/NCard";
import type {
  groupFinTerm,
  reportTagExpression,
  reportTotalAmountExpression, reportTotalCountExpression,
  reportTotalExpression, templateParams
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../../services/localization/text";
import ReportTagExpressionWarning from "./ReportTagExpressionWarning";
import type {history} from "../../../../../../../services/flowTypes/appFlowTypes";
import ReportTagExpressionConstructor from "./ReportTagExpressionConstructor/ReportTagExpressionConstructor";
import actTemplatesEditActions
  from "../../../../../../../store/organization/item/actTemplatesEdit/actTemplatesEditActions";
import type {onGetActTemplateProp} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import BtnAddRow from "../../../../../../../components/simple/Buttons/BtnAddRow/BtnAddRow";
import update from 'immutability-helper';
import createOption from "../../../../../../../utils/createOption";
import type {selectOptions} from "../../../../../../../services/flowTypes/componentFlowTypes";

type Props = {
  reportTotalExpression: reportTotalExpression,
  reportTotalAmountExpression: reportTotalAmountExpression,
  reportTotalCountExpression: reportTotalCountExpression,
  readonly: boolean,
  currency: string,
  history: history,
  onGetActTemplateProp: onGetActTemplateProp,
  reportTagExpressions: Array<reportTagExpression>,
  templateParams: templateParams,
  groupFinTerms: Array<groupFinTerm>
};
type State = {
  finTermTagsList: Array<string>,
  finTermTagsSelectOptions: selectOptions
};

export const expressionPartInitValue = "FT";
export const operationTypeLabel = {
  empty: "",
  plus: "+",
  minus: "-",
  multiply: "*",
  // sum: "Σ",
  vat: "%"
};
export const operationTypePlusDefValue = " + " + expressionPartInitValue;
export const operationLabelStr = {
  finterm: "FT",
  fintermSum: "ΣFT",
  tag: "TAG",
  const: "currency",
  vat: "VAT"
};
const operationTypeList = [operationTypeLabel.empty, operationTypeLabel.plus, operationTypeLabel.minus,
  operationTypeLabel.multiply, operationTypeLabel.vat];
const operationTypeSelectOptions = operationTypeList.map(item => {
  if (item === operationTypeLabel.multiply) {
    return {value: operationTypeLabel.multiply, label: "×"};
  } else {
    return createOption(item);
  }
});
const operationLabelList = [operationLabelStr.finterm, operationLabelStr.fintermSum, operationLabelStr.tag, operationLabelStr.const];
const operationLabelSelectOptions = operationLabelList.map(item => {
  return {value: item, label: text.operationLabel[item]};
});
const appliedTagsList = ['txCount', 'totalAmount', 'rateAmount'];
const regexStr = appliedTagsList.join('|');
const regExpForAppliedTags = new RegExp(regexStr, 'i');
const getVariableAliasPart = (propName) => {
  switch (propName) {
    case 'reportTotalExpression':
      return 'rateAmount';
    case 'reportTotalAmountExpression':
      return 'totalAmount';
    case 'reportTotalCountExpression':
      return 'txCount';
    default:
      throw new Error('propName is not mapped to actTemplateParam variableAlias!');
  }
};
const allowedForMultiplyRatePercentDecFinTermTypes = ["BYRATE", "BY_VOLUME_SIMPLE", "BY_TX_COUNT", "BY_TX_COUNT_SIMPLE", "BY_TX_VOLUME"];
const allowedForAbsAmount = ["BY_VOLUME_SIMPLE", "BY_TX_COUNT", "BY_TX_COUNT_SIMPLE"];

class ReportTagsExpressions extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      finTermTagsList: [],
      finTermTagsSelectOptions: []
    };
  }
  addReportTagExpression = () => {
    const lastTagExpressionName = this.props.reportTagExpressions.length ?
      this.props.reportTagExpressions[this.props.reportTagExpressions.length-1].name : '';
    const lastTagExpressionNameNumber = lastTagExpressionName.replace(text.reportTagExpressionName, "");
    const newReportTagName = this.props.reportTagExpressions.length ?
      text.reportTagExpressionName + (+lastTagExpressionNameNumber+1)
      : text.reportTagExpressionName + this.props.reportTagExpressions.length;
    const newReportTagExpression = {
      name: newReportTagName,
      expression: expressionPartInitValue,
      // tag: '${' + newReportTagName + '}'
    };
    const reportTagExpressions = update(this.props.reportTagExpressions, {$push: [newReportTagExpression]});
    this.props.onGetActTemplateProp('reportTagExpressions', reportTagExpressions);
  };
  deleteReportTagExpression = (expressionIndex) => {
    const reportTagExpressions = update(this.props.reportTagExpressions, {$splice: [[expressionIndex, 1]]});
    this.props.onGetActTemplateProp('reportTagExpressions', reportTagExpressions);
  };
  updateReportTagExpression = (expressionIndex, updatedReportTagExpression) => {
    const reportTagExpressions = update(this.props.reportTagExpressions, {[expressionIndex]: {$set: updatedReportTagExpression}});
    this.props.onGetActTemplateProp('reportTagExpressions', reportTagExpressions);
  };
  updateReportTotalExpression = (propName, updatedReportTagExpression) => {
    this.props.onGetActTemplateProp(propName, updatedReportTagExpression.expression);
  };
  setFinTermsTags = () => {
    const finTermTagsList = [];
    const finTermTagsSelectOptions = [];
    const makeOption = (varAlias) => {
      const handledVarAlias = varAlias.replace(/^FT\d*(_\w+)?_/, "");
      const index = finTermTagsSelectOptions.findIndex(item => item.value === handledVarAlias);
      if (index === -1) {
        finTermTagsSelectOptions.push({value: handledVarAlias, label: handledVarAlias});
      }
    };
    for (let i=0; i < this.props.templateParams.length; i++) {
      for (let j=0; j < this.props.templateParams[i].actTemplateParams.length; j++) {
        const trimmedVariableAlias = this.props.templateParams[i].actTemplateParams[j].variableAlias.slice(2, -1);
        if (regExpForAppliedTags.test(trimmedVariableAlias)) {
          const variableAlias = this.props.templateParams[i].actTemplateParams[j].convertedTag ?
            this.props.templateParams[i].actTemplateParams[j].convertedTag.variableAlias.slice(2, -1)
            : trimmedVariableAlias;
          makeOption(variableAlias);
          finTermTagsList.push(variableAlias);
        } else {
          const groupFinTermObj = this.props.groupFinTerms.find(item => item.groupId === this.props.templateParams[i].groupId);
          if (groupFinTermObj) {
            if (allowedForMultiplyRatePercentDecFinTermTypes.includes(groupFinTermObj.type)
              && (trimmedVariableAlias.includes("ratePercentDec"))
            ) {
              makeOption(trimmedVariableAlias);
              finTermTagsList.push(trimmedVariableAlias);
            }
            if (allowedForAbsAmount.includes(groupFinTermObj.type)
              && trimmedVariableAlias.includes("absAmount")
            ) {
              makeOption(trimmedVariableAlias);
              finTermTagsList.push(trimmedVariableAlias);
            }
          } else {
            throw new Error("groupFinTermObj is not found! (ReportTagsExpressions.js)");
          }
        }
      }
    }
    this.setState({finTermTagsList, finTermTagsSelectOptions});
  };
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if (prevProps.templateParams !== this.props.templateParams) {
      this.setFinTermsTags();
    }
  }

  componentDidMount(): * {
    this.setFinTermsTags();
  }

  render() {
    const reportTagsData = [
      {tagLabel: text.reportTotalCountExpression,
        tagToCopyStr: '${report_total_count}', tagExpression: this.props.reportTotalCountExpression,
        propName: 'reportTotalCountExpression',
        warningComponent: (<ReportTagExpressionWarning history={this.props.history} propName={'reportTotalCountExpression'}/>)},
      {tagLabel: text.reportTotalAmountExpression,
        tagToCopyStr: '${report_total_amount}', tagExpression: this.props.reportTotalAmountExpression,
        propName: 'reportTotalAmountExpression',
        warningComponent: (<ReportTagExpressionWarning history={this.props.history} propName={'reportTotalAmountExpression'}/>)},
      {
        tagLabel: text.reportTotalExpression,
        tagToCopyStr: '${report_calculated_total}', tagExpression: this.props.reportTotalExpression,
        propName: 'reportTotalExpression',
        warningComponent: (<ReportTagExpressionWarning history={this.props.history} propName={'reportTotalExpression'}/>)}
    ];
    const newReportTagsData = reportTagsData.map(item => {
      return {
        name: item.tagLabel,
        expression: item.tagExpression,
        tag: item.tagToCopyStr,
        propName: item.propName,
        warningComponent: item.warningComponent
      };
    });
    const reportTagsSliced = newReportTagsData.map(item => item.tag.slice(2, -1));
    const reportTagsSelectOptions = reportTagsSliced.map(item => createOption(item));
    const notFilteredTagExpSelectOptions = this.props.reportTagExpressions.map(expression => createOption(expression.name));

    const tagsExpressions = newReportTagsData.map( (item,i) => {
      const variableAliasPart = getVariableAliasPart(item.propName);
      const finTermTagsSelectOptions = this.state.finTermTagsSelectOptions.filter(option => option.value === variableAliasPart);
      const filteredOperationLabelSelectOptions = operationLabelSelectOptions
        .filter(option => option.value !== operationLabelStr.tag)
        .map(option => {
          if (!this.props.currency) {
            return option
          }
          return option.value.indexOf(operationLabelStr.const) > -1 ? {...option, label: this.props.currency} : option
        });
      return <ReportTagExpressionConstructor key={'reportTagExpression'+i}
                                             reportTagExpression={item}
                                             expressionIndex={i}
                                             updateReportTotalExpression={this.updateReportTotalExpression}
                                             operationTypeSelectOptions={operationTypeSelectOptions}
                                             operationLabelSelectOptions={filteredOperationLabelSelectOptions}
                                             finTermTagsSelectOptions={finTermTagsSelectOptions}
                                             finTermTagsList={this.state.finTermTagsList}
                                             // tagExpSelectOptions={tagExpSelectOptions}
                                             deleteReportTagExpression={null}
                                             currency={this.props.currency}
                                  readonly={this.props.readonly} addClasses={'mb_60'}/>
    });

    const tagsExpressionsNew = this.props.reportTagExpressions.map( (item,i) => {
      const addClasses = this.props.reportTagExpressions.length - 1 === i ? 'mb_5du' : 'mb_60';

      const tagExpSelectOptionIndex = notFilteredTagExpSelectOptions.findIndex(option => option.value === item.name);
      const filteredTagExpSelectOptions = notFilteredTagExpSelectOptions.slice(0, tagExpSelectOptionIndex);
      const tagExpSelectOptions = [...reportTagsSelectOptions, ...filteredTagExpSelectOptions];
      return <ReportTagExpressionConstructor key={'reportTagExpressionNew'+i} reportTagExpression={item}
                                             expressionIndex={i} updateReportTagExpression={this.updateReportTagExpression}
                                             operationTypeSelectOptions={operationTypeSelectOptions}
                                             operationLabelSelectOptions={operationLabelSelectOptions}
                                             finTermTagsSelectOptions={this.state.finTermTagsSelectOptions}
                                             finTermTagsList={this.state.finTermTagsList}
                                             tagExpSelectOptions={tagExpSelectOptions}
                                             deleteReportTagExpression={this.deleteReportTagExpression}
                                             currency={this.props.currency}
                                  readonly={this.props.readonly} addClasses={addClasses}/>
    });
    return (
      <NCard addClasses={'mt_3du mb_3du'}>
        {tagsExpressions}
        {tagsExpressionsNew}
        {this.props.readonly ? null : <BtnAddRow onClick={this.addReportTagExpression}>
          {text.btnAddReportTagExpressionLabel}</BtnAddRow>}
      </NCard>
    );
  }
}

const mapStateToProps = state => {
  return {
    reportTotalExpression: state.organization.item.actTemplatesEdit.reportTotalExpression,
    reportTotalAmountExpression: state.organization.item.actTemplatesEdit.reportTotalAmountExpression,
    reportTotalCountExpression: state.organization.item.actTemplatesEdit.reportTotalCountExpression,
    reportTagExpressions: state.organization.item.actTemplatesEdit.reportTagExpressions,
    currency: state.organization.item.actTemplatesEdit.currency,
    templateParams: state.organization.item.templateParams,
    groupFinTerms: state.organization.item.groupFinTermsView
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetActTemplateProp: (prop, data) => dispatch({type: actTemplatesEditActions.ACT_TEMPLATES_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportTagsExpressions);