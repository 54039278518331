//@flow
import * as React from 'react';
import type {filtersFromParams, history, match} from "../../../../../../services/flowTypes/appFlowTypes";
import type {setFiltersToUrl} from "../../../../../../services/flowTypes/propFnFlowTypes";
import HistoryView from "../../../../../../components/composed/HistoryView/HistoryView";

type Props = {
  filtersFromParams: filtersFromParams,
  setFiltersToUrl: setFiltersToUrl,
  match:match,
  history: history
};

const ContractHistory = (props: Props) => {
  const contractId = +props.match.params.id;
  return (
    <HistoryView filtersFromParams={props.filtersFromParams} setFiltersToUrl={props.setFiltersToUrl}
                 historyType={'contract'} itemId={contractId} routerHistory={props.history}/>
  );
};

export default ContractHistory;    