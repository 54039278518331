//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {alert, id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './AlertCard.css';
import text from "../../../../services/localization/text";
import Text1 from "../../TextComponents/Text1/Text1";
import type {hideOnClick} from "../../../../services/flowTypes/propFnFlowTypes";
import AlertCardErrorBody from "../../../composed/Alerts/AlertCardErrorBody";

type Props = {
  id?: id,
  addClasses?: addClasses,
  alert: alert,
  hideOnClick: hideOnClick
};

const AlertCard = (props: Props) => {
  return (
    <div id={props.id} className={baseClass("AlertCard", props.addClasses)}>
      <div className={`AlertCard__header AlertCard__header_${props.alert.type}`}>
        <div className="AlertCard__header-ico"/>
        <div className="AlertCard__header-title">{text.alertHeader[props.alert.type]}</div>
        <div className="AlertCard__header-close-ico" onClick={props.hideOnClick}/>
      </div>
      <div className="AlertCard__body">
        {props.alert.errorObject ? (
          <AlertCardErrorBody errorObject={props.alert.errorObject}/>
        ) : (
          <Text1>{props.alert.text}</Text1>
        )}
      </div>
    </div>
  );
};

export default AlertCard;