//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {children, history} from "../../../../../../services/flowTypes/appFlowTypes";
import type {actTemplateId, contractId, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import {actTemplatesSuffix, contrPathView, orgPath} from "../../../../../../services/urlStrings/urlStrings";

type Props = {
  children: children,
  finTermGroupIdsLength: number,
  addServiceIdsLength: number,
  history: history,
  organizationId: organizationId,
  contractId: contractId,
  actTemplateId: actTemplateId
};

class ForbidAddServiceActTemplatePage extends React.Component <Props> {
  checkFinTerms = () => {
    if (this.props.actTemplateId && !this.props.finTermGroupIdsLength && this.props.addServiceIdsLength) {
      const path = orgPath(this.props.organizationId) + contrPathView(this.props.contractId) + actTemplatesSuffix;
      this.props.history.push({pathname: path});
    }
  };
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    this.checkFinTerms();
  }
  componentDidMount(): * {
    this.checkFinTerms();
  }
  render() {
    return this.props.children;
  }
}

const mapStateToProps = state => {
  return {
    finTermGroupIdsLength: state.organization.item.actTemplatesEdit.finTermGroupIds.length
  };
};

export default connect(mapStateToProps, null)(ForbidAddServiceActTemplatePage);