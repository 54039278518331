//@flow
import * as React from 'react';
import type {label, onChange} from "../../../../services/flowTypes/appFlowTypes";
import type {
  datePropName, isQuarterMode,
  propValue,
  selectOptions
} from "../../../../services/flowTypes/componentFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import NLabel from "../NLabel/NLabel";
import NSelect from "../NSelect/NSelect";
import text from "../../../../services/localization/text";
import moment from '../../../../services/moment/momentInstance';
import {datePresets, quarterDatePresets} from "../FormDateRangePicker/FormDateRangePicker";
import type {itemDate} from "../../../../services/flowTypes/dataFlowTypes";

type Props = {
  label?: label,
  propValue: propValue,
  onChange: onChange,
  datePropName: datePropName,
  addClasses?: addClasses,
  isQuarterMode?: isQuarterMode
};
type State = {
  monthOptions: selectOptions,
  quarterOptions: selectOptions,
  yearOptions: selectOptions
};

const monthValues = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const quarterValues = [1, 2, 3, 4];

class WrappedMonthPicker extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      monthOptions: [],
      quarterOptions: [],
      yearOptions: []
    };
  }
  defineSelectedQuarterStr = (monthDigitStr, yearDigitStr) => {
    const monthDigitStrIndex = monthValues.findIndex(item => item === monthDigitStr);
    const slicedMonthValues = monthDigitStrIndex !== -1 ? monthValues.slice(monthDigitStrIndex, monthDigitStrIndex + 3) : [];
    const slicedMonthValueLocalized = slicedMonthValues.map(item => text.monthOptionsLabels[item]);
    const monthStr = slicedMonthValueLocalized.join(', ');
    const yearStr = yearDigitStr ? " (" + yearDigitStr + ")" : '';
    return monthStr + yearStr;
  };
  defineValueForSelect = (monthOrYear) => {
    const formatStr = monthOrYear === 'month' ? 'MM' : 'YYYY';
    return this.props.propValue[this.props.datePropName] ?
      moment(this.props.propValue[this.props.datePropName]).format(formatStr) : '';
  };
  handleDateChange = (value:itemDate) => {
    const formattedValue = moment(value).format('YYYY-MM-DD');
    const newValue = this.props.isQuarterMode && this.props.datePropName === 'startDate' ?
      {startDate: formattedValue,
        endDate: moment(value).add(3, 'month').endOf('month').format('YYYY-MM-DD')}
        : {...this.props.propValue, [this.props.datePropName]: formattedValue};
    this.props.onChange(newValue);
  };
  handleSelectChange = (selectedOption, monthOrYear) => {
    const value = selectedOption === null ? null : selectedOption.value;
    const definedYearValue = this.defineValueForSelect('year') ?
      this.defineValueForSelect('year') : '0000';
    const definedMonthValue = this.defineValueForSelect('month') ?
      this.defineValueForSelect('month') : '01';
    const yearMonthStr = monthOrYear === 'month' ?
      ( definedYearValue + "-" + value)
      : (value + "-" +  definedMonthValue);
    const newMonthYearValue = moment(yearMonthStr).format('YYYY-MM-DD');
    const handledNewMonthYearValue = this.props.datePropName === 'startDate' ? newMonthYearValue
      : moment(newMonthYearValue).endOf('month').format('YYYY-MM-DD');
    this.handleDateChange(handledNewMonthYearValue);
  };
  handleQuarterSelectChange = (selectedOption) => {
    const value = selectedOption === null ? null : selectedOption.value;
    const newValue = moment().year(this.defineValueForSelect('year')).quarter(value).startOf('quarter').format('YYYY-MM-DD');
    this.handleDateChange(newValue);
  };
  defineSelectValue = (options, value) => {
    const selectedOption = options ?
      options.find(item => item.value === value) : null;
    return selectedOption ? selectedOption : null;
  };
  setYearOptions = (currentYearStr) => {
    const currentYearNumber = +currentYearStr;
    const yearsList = [currentYearNumber];
    for (let i=1; i < 6; i++) {
      yearsList.unshift(currentYearNumber - i );
      yearsList.push(currentYearNumber + i );
    }
    return yearsList.map(item => {
      const itemStr = '' + item
      return {value: itemStr, label: '' + itemStr};
    });
  };
  componentDidMount(): * {
    const monthOptions = monthValues.map(item => {
      return {value: item, label: text.monthOptionsLabels[item]};
    });
    const quarterOptions = quarterValues.map(item => {
      return {value: item, label: text.quarterOptionsLabels[""+item]};
    });
    const currentYear = moment().format('YYYY');
    const yearOptions = this.setYearOptions(currentYear);
    this.setState({monthOptions, quarterOptions, yearOptions});
  }
  render() {
    const quarterDigitStr = moment(this.props.propValue[this.props.datePropName]).quarter();
    const monthDigitStr = this.defineValueForSelect('month');
    const yearDigitStr = this.defineValueForSelect('year');
    const selectedMonthValue = this.defineSelectValue(this.state.monthOptions, monthDigitStr);
    const selectedQuarterValue = this.defineSelectValue(this.state.quarterOptions, quarterDigitStr);
    const selectedYearValue = this.defineSelectValue(this.state.yearOptions, yearDigitStr);
    const datePresetsOfMonthPicker = this.props.isQuarterMode ? quarterDatePresets : datePresets.slice(2, 4);
    const datePresetsBtns = datePresetsOfMonthPicker.map((item,i) => {
      const dateToChange = this.props.datePropName === 'startDate' ? item.start : item.end;
      const onClick = () => this.handleDateChange(dateToChange);
      return (<div key={this.props.datePropName +'ShortcutBtn' +i} className="TagToCopy tt_uc mr_2du"
                   onClick={onClick}>{item.text}</div>);
    });
    const selectedQuarterStr = this.props.isQuarterMode ? this.defineSelectedQuarterStr(monthDigitStr, yearDigitStr) : '';
    const maxMenuHeight = 170;
    const menuPlacement = this.props.datePropName === 'endDate' ? "top" : "auto";
    return (
      <div className={baseClass("WrappedMonthPicker", this.props.addClasses)}>
        {this.props.isQuarterMode ? null : <NLabel>{this.props.label}</NLabel>}
        <div className="flex w_100 mb_1du">
          {this.props.isQuarterMode ? (
            <NSelect value={selectedQuarterValue} options={this.state.quarterOptions} addClasses={'WrappedMonthPicker__NSelect mr_2du'}
                     onChange={this.handleQuarterSelectChange} maxMenuHeight={maxMenuHeight} menuPlacement={menuPlacement}
                     placeholder={text.selectQuarterPlaceholder}/>
          ) : (
            <NSelect value={selectedMonthValue} options={this.state.monthOptions} addClasses={'WrappedMonthPicker__NSelect mr_2du'}
                     onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'month')}
                     placeholder={text.selectMonthPlaceholder} maxMenuHeight={maxMenuHeight} menuPlacement={menuPlacement}/>
          )}
          <NSelect value={selectedYearValue} options={this.state.yearOptions} addClasses={'WrappedMonthPicker__NSelect'}
                   onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'year')}
                   placeholder={text.selectYearPlaceholder} maxMenuHeight={maxMenuHeight} menuPlacement={menuPlacement}/>
        </div>
        <div className="flex">
          {datePresetsBtns}
        </div>
        {this.props.isQuarterMode ? (
          <div className="flex mt_2du">
            <NLabel addClasses={'mr_1du'}>{text.selectedQuarterLabel}</NLabel>
            <b><NLabel>{selectedQuarterStr}</NLabel></b>
          </div>
        ) : null}
      </div>
    );
  }
}

export default WrappedMonthPicker;    