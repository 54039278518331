//@flow
import * as React from 'react';
import {Link} from "react-router-dom";
import {finTermViewIds} from "../../../../../../tests/testIds";
import text from "../../../../../../services/localization/text";
import Can from "../../../../../../services/permissions/Can";
import {contrPathEdit, groupFinTermPath, orgPath} from "../../../../../../services/urlStrings/urlStrings";
import type {contractId, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import type {match} from "../../../../../../services/flowTypes/appFlowTypes";
import {permissionsStrValues} from "../../../../../../configData/permissionsStrValues";
import BtnNewItem from "../../../../../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  organizationId: organizationId,
  contactId: contractId,
  match: match
};

const GroupFinTermEditButton = (props: Props) => {
  const linkTo = orgPath(props.organizationId) + contrPathEdit(props.contractId) + groupFinTermPath(props.match.params.groupId);
  return (
    <Can do={'usePermission'} on={permissionsStrValues.finTerm.update}>
      <Link className={"button-wrapper-link"} to={linkTo}>
        <BtnNewItem id={finTermViewIds.buttonEdit}>{text.btnEdit}</BtnNewItem>
      </Link>
    </Can>
  );
};

export default GroupFinTermEditButton;    