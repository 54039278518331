const tooltipMoreLinks = {
  groupFinTermTypeTooltip: {
    BY_VOLUME_SIMPLE: '',
    BY_VOLUME_COMPLEX: '',
    BYRATE: '',
    BY_HOURS: '',
    BY_TX_COUNT: '',
    BY_TX_COUNT_SIMPLE: '',
    BY_TX_COUNT_COMPLEX: '',
    FIXED: '',
    FIXED_NO_PERIOD: '',
    MANUAL: '',
    MIN: '',
  },
  groupFinTermSourceTooltip: {
    PNE: '',
    PNE_EU: '',
    REDMINE: '',
    EMPTY: '',
  },
};
export default tooltipMoreLinks;