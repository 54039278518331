//@flow
import * as React from 'react';
import {Link} from "react-router-dom";
import NHeading2 from "../../../../../../../../components/simple/TextComponents/NHeading2/NHeading2";
import BtnDel from "../../../../../../../../components/simple/Buttons/BtnDel/BtnDel";
import GroupFinTermCardInfoRow
  from "../../../../../../../../components/composed/GroupFinTermCardInfoRow/GroupFinTermCardInfoRow";
import NCard from "../../../../../../../../components/simple/Containers/NCard/NCard";
import NDropDownDetails from "../../../../../../../../components/simple/Containers/NDropDownDetails/NDropDownDetails";
import type {addClasses} from "../../../../../../../../services/flowTypes/uiKitFlowTypes";
import ability from "../../../../../../../../services/permissions/permissions";
import type {
  handledGroupFinTerm,
} from "../../../../../../../../services/flowTypes/componentFlowTypes";
import NHeading3 from "../../../../../../../../components/simple/TextComponents/NHeading3/NHeading3";
import TextOverflowHandler
  from "../../../../../../../../components/simple/Containers/TextOverflowHandler/TextOverflowHandler";
import Can from "../../../../../../../../services/permissions/Can";
import BtnEdit from "../../../../../../../../components/simple/Buttons/BtnEdit/BtnEdit";
import LinkToHistory from "../../../../../../../../components/simple/Buttons/LinkToHistory/LinkToHistory";
import Text4 from "../../../../../../../../components/simple/TextComponents/Text4/Text4";
import "./GroupFinTermCard.css";

type Props = {
  addClasses?: addClasses,
  handledGroupFinTerm: handledGroupFinTerm,
  checkboxComponent?: React.Node,
  isEmbeddedMode?: boolean
};

const GroupFinTermCard = (props:Props) => {
  const embeddedClassName = props.isEmbeddedMode ? "NCard_embeddedMode " : "";
  return (
    <NCard addClasses={embeddedClassName + props.addClasses}>
      {props.handledGroupFinTerm.warningComponent}
      <div className="flex_jc-sb ai_c mb_1du">
        {ability.can('usePermission', props.handledGroupFinTerm.permittedActionObj.view) && !props.isEmbeddedMode ? (
          <>
            <Link id={props.handledGroupFinTerm.linkToGroupFinTermId}
                  className={"GroupFinTermCard__name"} to={props.handledGroupFinTerm.linkTo}>
              <NHeading3 addClasses={'NHeading3_link-color'}>
                <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>
                  {props.handledGroupFinTerm.groupFinTermIdName}
                </TextOverflowHandler>
              </NHeading3>
            </Link>
            <div className={'flex'}>
              <Link to={props.handledGroupFinTerm.linkToHistory}
                    id={props.handledGroupFinTerm.linkToGroupFinTermHistoryId} className={'mr_2du'}>
                <LinkToHistory/>
              </Link>
              <Can do={'usePermission'} on={props.handledGroupFinTerm.permittedActionObj.update}>
                <Link to={props.handledGroupFinTerm.linkToEdit}
                      id={props.handledGroupFinTerm.buttonEditGroupFinTermId} className={'mr_2du'}>
                  <BtnEdit/>
                </Link>
              </Can>
              <Can do={'usePermission'} on={props.handledGroupFinTerm.permittedActionObj.update}>
                <BtnDel id={props.handledGroupFinTerm.buttonDeleteGroupFinTermId}
                        onClick={props.handledGroupFinTerm.onBtnDelClick}/>
              </Can>
            </div>
          </>
        ) : (
          props.checkboxComponent ?
            <div className={'flex ai_c'}>
              {props.checkboxComponent}
              <NHeading3>{props.handledGroupFinTerm.groupFinTermIdName}</NHeading3>
            </div> :
          <NHeading2>{props.handledGroupFinTerm.groupFinTermIdName}</NHeading2>
        )}
      </div>
      <GroupFinTermCardInfoRow
        addClasses={"mb_1du"}
        handledGroupFinTerm={props.handledGroupFinTerm}
        // payerName={props.handledGroupFinTerm.payerName}
        // payeeName={props.handledGroupFinTerm.payeeName}
        // currencyLabel={props.handledGroupFinTerm.currencyLabel}
        // payoutPeriodLabel={props.handledGroupFinTerm.payoutPeriodLabel}
        // transactionTypeLabel={props.handledGroupFinTerm.transactionTypeLabel}
        // transactionStatusLabel={props.handledGroupFinTerm.transactionStatusLabel}
        // groupFinTermSourceLabel={props.handledGroupFinTerm.groupFinTermSourceLabel}
      />
      {props.isEmbeddedMode ?
        <Text4 addClasses={`tt_uc`}>{props.handledGroupFinTerm.groupFinTermTypeLabel}</Text4>
        :
        <NDropDownDetails
          detailsSummary={props.handledGroupFinTerm.groupFinTermTypeLabel}
          detailsOptions={props.handledGroupFinTerm.groupFinTermCardParamRows}
        />
      }

    </NCard>
  );
};

export default GroupFinTermCard;