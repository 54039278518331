//@flow
import * as React from 'react';
import text from "../../../../../../../../services/localization/text";
import NModal from "../../../../../../../../components/simple/Modals/NModal/NModal";
import type {
  isActTemplateViewMode,
  propError
} from "../../../../../../../../services/flowTypes/componentFlowTypes";
import type {errorObject, history, isReadyToRender} from "../../../../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import NFormGroup from "../../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import type {
  actTemplateId, addServiceId, contractId, groupFinTerm, groupFinTermId, isNotCompliedWithPaynetParams,
  itemDate, lastValidReportDate,
  organizationId,
  templateParams, validationIsAllowed
} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import api from "../../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../../services/apiInstance/apiErrorHandler";
import {cancelModal} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import {actTemplatesViewPageIds} from "../../../../../../../../tests/testIds";
import {
  contrPathView, groupFinTermPath,
  mainDataSuffix,
  orgPath,
  reportPathView
} from "../../../../../../../../services/urlStrings/urlStrings";
import Text2 from "../../../../../../../../components/simple/TextComponents/Text2/Text2";
import FormSingleDatePicker
  from "../../../../../../../../components/simple/FormComponents/FormSingleDatePicker/FormSingleDatePicker";
import validationPatterns from "../../../../../../../../services/validationPatterns/validationPatterns";
import FormDateRangePicker
  from "../../../../../../../../components/simple/FormComponents/FormDateRangePicker/FormDateRangePicker";
import {getPrevMonthEndDate, getPrevMonthStartDate} from "../../../../../../../../utils/getPrevMonthDates";
import reportsActionTypes from "../../../../../../../../store/organization/reports/reportsActions";
import {connect} from "react-redux";
import type {
  onGetReports,
  onGetTemplateParams,
  onInitReportsSearch
} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import {initialState} from "../../../../../../../../store/organization/reports/reports";
import moment from "../../../../../../../../services/moment/momentInstance";
import templateParamsActions
  from "../../../../../../../../store/organization/item/templateParams/templateParamsActions";
import NInput from "../../../../../../../../components/simple/FormComponents/NInput/NInput";
import CheckboxInput from "../../../../../../../../components/simple/FormComponents/CheckboxInput/CheckboxInput";
import Heading4 from "../../../../../../../../components/simple/TextComponents/Heading4/Heading4";
import getMaskedInputValue from "../../../../../../../../utils/getMaskedInputValue";
import isReportDatesCompliedWithFinTerms from "../../../../../../../../utils/isReportDatesCompliedWithFinTerms";
import reportsSearchActions from "../../../../../../../../store/organization/reportsSearch/reportsSearchActions";
import TextOverflowHandler
  from "../../../../../../../../components/simple/Containers/TextOverflowHandler/TextOverflowHandler";
import AddServicesCheckList from "./AddServicesCheckList";
import {Link} from "react-router-dom";
import Text4 from "../../../../../../../../components/simple/TextComponents/Text4/Text4";
import formatDate from "../../../../../../../../utils/formatDate";

type Props = {
  actTemplateId: actTemplateId,
  finTermGroupIds: Array<groupFinTermId>,
  addServiceIds: Array<addServiceId>,
  organizationId: organizationId,
  history: history,
  cancelModal: cancelModal,
  onGetReports: onGetReports,
  isActTemplateViewMode?: isActTemplateViewMode,
  onGetTemplateParams: onGetTemplateParams,
  templateParams: templateParams,
  contractId: contractId,
  groupFinTermsView: Array<groupFinTerm>,
  onInitReportsSearch: onInitReportsSearch,
  lastValidReportDate?: lastValidReportDate,
  isNotCompliedWithPaynetParams: isNotCompliedWithPaynetParams
};
type State = {
  isReadyToRender: isReadyToRender,
  isWrongDateRange: boolean,
  errorObject: errorObject,
  documentDate: itemDate,
  datePropError: propError,
  manualInputPropErrorArr: Array<propError>,
  startDate: itemDate,
  endDate: itemDate,
  manualRateAmountArr: Array<{name: string, value: string, groupFinTermName: string}>,
  skipManualInputArr: Array<{name: string, value: string}>,
  manualInputValidationIsAllowed: validationIsAllowed,
  addServiceIds: Array<addServiceId>,
  isNotCorrespondToLastValidReportDate: boolean
};
class GenerateReportModal extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      monthOptions: [],
      yearOptions: [],
      isReadyToRender: false,
      errorObject: null,
      documentDate: '',
      startDate:'',
      endDate:'',
      datePropError: '',
      manualInputPropErrorArr: [],
      manualRateAmountArr: [],
      skipManualInputArr: [],
      manualInputValidationIsAllowed: false,
      addServiceIds: [],
      isNotCorrespondToLastValidReportDate: false,
      isWrongDateRange: false,
      generateAttachment: false
    };
  }
  setReportAddServiceIds = (addServiceIds) => this.setState({addServiceIds});
  validateReportDates = () => {
    const actTemplateGroupFinTerms = this.props.groupFinTermsView.filter(item => this.props.finTermGroupIds.includes(item.groupId));
    let isValid = true;
    const groupFinTermIdsForLinks = [];
    for (let i=0; i < actTemplateGroupFinTerms.length; i++) {
      const isDateComplied = isReportDatesCompliedWithFinTerms(this.state.startDate, this.state.endDate, actTemplateGroupFinTerms[i].finTerms)
      isValid = isValid && isDateComplied;
      if (!isDateComplied) {
        groupFinTermIdsForLinks.push(actTemplateGroupFinTerms[i].groupId);
      }
    }
    return {isReportDatesComplied: isValid, groupFinTermIdsForLinks};
  };
  allowValidation = () => {
    if (!this.state.manualInputValidationIsAllowed) {
      this.setState({manualInputValidationIsAllowed: true});
    }
  };
  handleSkipManualInput = (index) => {
    const skipManualInputArr = [...this.state.skipManualInputArr];
    const manualRateAmountArr = [...this.state.manualRateAmountArr];
    const manualInputPropErrorArr = [...this.state.manualInputPropErrorArr];

    if (skipManualInputArr[index].value){
      skipManualInputArr[index].value = false;
      manualInputPropErrorArr[index] = text.rateAmountTagInputError;
      this.setState({skipManualInputArr, manualInputPropErrorArr});
    } else {
      skipManualInputArr[index].value = true;
      manualRateAmountArr[index].value = '';
      manualInputPropErrorArr[index] = '';
      this.setState({skipManualInputArr, manualRateAmountArr, manualInputPropErrorArr});
    }
    this.allowValidation();
  };
  handleRateAmountInputChange = (value, index) => {
    const manualRateAmountArr = [...this.state.manualRateAmountArr];
    const manualInputPropErrorArr = [...this.state.manualInputPropErrorArr];
    const valueWithReplacedComa = getMaskedInputValue(value);
    manualRateAmountArr[index].value = valueWithReplacedComa.replace(/\s/g, '');
    manualInputPropErrorArr[index] = valueWithReplacedComa.length ? '' : text.rateAmountTagInputError;
    this.allowValidation();
    this.setState({manualRateAmountArr, manualInputPropErrorArr});
  };
  handleDateChange = (documentDate:itemDate) => {
    const datePropError = validationPatterns.dateFormat(documentDate);
    this.setState({documentDate, datePropError});
  };
  handleDatesError = (dates) => {
    this.setState({isWrongDateRange: false });
  }
  handleDatesChange = (dates) => {
    this.setState({isWrongDateRange: false });
    const isNotCorrespondToLastValidReportDate = this.props.lastValidReportDate && this.props.isNotCompliedWithPaynetParams ?
      moment(dates.endDate).isAfter(this.props.lastValidReportDate) : false;
    this.setState({startDate: dates.startDate, endDate: dates.endDate, isNotCorrespondToLastValidReportDate});
  };
  getGroupFinTermName = (groupFinTermId) => {
    const groupFinTermObj = this.props.groupFinTermsView.find(item => item.groupId === groupFinTermId);
    return groupFinTermObj ? groupFinTermObj.name : '!GroupFinTerm is not found';
  };
  setManualRateAmountArr = (templateParams) => {
    const manualRateAmountArr = [];
    const skipManualInputArr = [];
    const manualInputPropErrorArr = [];
    for (let i=0; i < templateParams.length; i++) {
      for (let j=0; j < templateParams[i].actTemplateParams.length; j++) {
        if (templateParams[i].actTemplateParams[j].manual
        && templateParams[i].actTemplateParams[j].name === 'rateAmount') {
          const paramName = templateParams[i].actTemplateParams[j].variableName.slice(2,-1);
          const rateAmountObj = {name: paramName, value: '', groupFinTermName: this.getGroupFinTermName(templateParams[i].groupId)};
          manualRateAmountArr.push(rateAmountObj);
          const skipInputObj = {name: paramName, value: false};
          skipManualInputArr.push(skipInputObj);
          manualInputPropErrorArr.push(text.rateAmountTagInputError);
        }
      }
    }
    return {manualRateAmountArr, skipManualInputArr, manualInputPropErrorArr};
  };
  scheduleReport = () => {
    if (this.state.datePropError.length
      || this.state.manualInputPropErrorArr.join('').length
      || this.state.isNotCorrespondToLastValidReportDate) {
      this.setState({manualInputValidationIsAllowed: true});
      return;
    }
    this.setState({isReadyToRender: false});
    const manualRateAmountArr = this.state.manualRateAmountArr.map(item => {
      if (item.groupFinTermName) {
        delete item.groupFinTermName;
        return item;
      } else {
        return item;
      }
    });
    const reportParams = {
      params: manualRateAmountArr,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      documentDate: this.state.documentDate,
      addServiceIds: this.state.addServiceIds,
      generateAttachment: this.state.generateAttachment
    };
    api.scheduleReport(this.props.actTemplateId, reportParams)
        .then(response => {
          this.props.onGetReports(initialState);
          this.props.onInitReportsSearch();
          this.setState({isReadyToRender: true});
          this.props.history.push({pathname: (orgPath(this.props.organizationId) + reportPathView(response.data.id)
                + mainDataSuffix)});
        })
        .catch(error => {
          this.setState({
            isReadyToRender: true,
            errorObject: apiErrorHandler(error)
          });
        });
  };
  componentDidMount(){
    const startDate = moment(getPrevMonthStartDate()).format('YYYY-MM-DD');
    const endDate = moment(getPrevMonthEndDate()).format('YYYY-MM-DD');
    const documentDate = endDate;
    if (!this.props.isActTemplateViewMode) {
      api.getGroupFinTermTemplateParams(this.props.contractId, this.props.finTermGroupIds, this.props.actTemplateId)
        .then(response => {
          this.props.onGetTemplateParams(response.data);
          const {manualRateAmountArr, skipManualInputArr, manualInputPropErrorArr} = this.setManualRateAmountArr(response.data);
          this.setState({
            isReadyToRender: true,
            startDate, endDate,
            documentDate, manualRateAmountArr, skipManualInputArr, manualInputPropErrorArr
          });
        })
        .catch(error => {
          this.setState({
            errorObject: apiErrorHandler(error)
          });
        });
    } else {
      const {manualRateAmountArr, skipManualInputArr, manualInputPropErrorArr} =
        this.setManualRateAmountArr(this.props.templateParams);
      this.setState({
        isReadyToRender: true,
        startDate, endDate,
        documentDate, manualRateAmountArr, skipManualInputArr, manualInputPropErrorArr
      });
    }
  }
  render() {
    const manualRateAmountArr = this.state.manualRateAmountArr.map((item, i)=> {
      const handleInputChange = (e) => this.handleRateAmountInputChange(e.currentTarget.value, i);
      const handleCheckboxChange = () => this.handleSkipManualInput(i);
      const propError = this.state.manualInputValidationIsAllowed ? this.state.manualInputPropErrorArr[i] : '';
      const labelStr = item.name + " | " + item.groupFinTermName;
      const label = <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'} isInputWrapperMode={true} inputValue={labelStr}>
        {labelStr}</TextOverflowHandler>;
      return (
        <div key={'manualInput'+i} className="flex_jc-sb mt_2du">
          <NFormGroup key={'rateAmountInput'+i} label={label} addClasses={'w_half-card mr_4du'}
                      propError={propError}>
            <NInput id={actTemplatesViewPageIds.rateAmountTagInput(i)} value={getMaskedInputValue(item.value)}
                    dataUiTestId={"rateAmountTagInput_" + item.name}
                    onChange={handleInputChange} readOnly={this.state.skipManualInputArr[i].value}
                    placeholder={text.rateAmountTagInput}/>
          </NFormGroup>
          <CheckboxInput checked={this.state.skipManualInputArr[i].value} onClick={handleCheckboxChange}
                         addClasses={'CheckboxInput_1du-to-ico mt_2du w_half-card'}
                         checkboxLabel={text.excludeManualInputLabel}/>
        </div>

      );
    });

    const {isReportDatesComplied, groupFinTermIdsForLinks} = this.validateReportDates();
    const groupFinTermLinks = groupFinTermIdsForLinks.length ? groupFinTermIdsForLinks.map((groupId, i) => {
      const comma = i === groupFinTermIdsForLinks.length-1 ? "" : ",";
      const linkTo = orgPath(this.props.organizationId) + contrPathView(this.props.contractId)
        + groupFinTermPath(groupId);
      return <Text4 key={"groupFinTermLink"+i} addClasses={"Text4_linkColor ml_1du"}>
        <Link to={linkTo}>{"#"+groupId + comma}</Link></Text4>
    }) : [];
    const datesPropError = this.getDatesPropError(isReportDatesComplied);
    return (
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
          <NModal
              addClasses={'NModal_ov'}
              modalTitle={text.generateReportModalTitle}
              onModalActionBtnClick={this.scheduleReport}
              onModalCancelBtnClick={this.props.cancelModal}
              modalActionBtnText={text.btnCreate}
              modalCancelBtnText={text.cancel}
              // isModalActionBtnDisabled={this.state.datePropError.length && this.state.manualInputPropErrorArr.join().length}
          >
            <div className="mb_3du">
              <div>
                <Text2 addClasses={'pt_3du mb_2du'}>{text.dates}</Text2>
                <div className={'mb_4du'}>
                  <NFormGroup addClasses={'NFormGroup--inline-block mr_2du'} label={text.reportPeriod}
                              propError={datesPropError}>
                      <FormDateRangePicker
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          onDatesChange={this.handleDatesChange}
                          onWrongDateRange={this.handleDatesError}
                          startDateId={actTemplatesViewPageIds.startDate}
                          endDateId={actTemplatesViewPageIds.endDate}
                          minimumNights={0}
                      />
                  </NFormGroup>
                  <NFormGroup label={text.actDate} addClasses={'mb_2du NFormGroup--inline-block'}
                              propError={this.state.datePropError}>
                    <FormSingleDatePicker dateId={actTemplatesViewPageIds.dateInputAct} date={this.state.documentDate}
                                          onDatesChange={this.handleDateChange}/>
                  </NFormGroup>
                  <CheckboxInput checked={this.state.generateAttachment} onClick={() => {this.setState({
                    ...this.state,
                    generateAttachment: !this.state.generateAttachment
                  })}}
                                 addClasses={'CheckboxInput_1du-to-ico mt_2du w_half-card'}
                                 checkboxLabel={text.generateAttachment}/>
                  {isReportDatesComplied || this.state.isNotCorrespondToLastValidReportDate ? null : (
                    <div className="flex mb_2du">
                      <Text4>{text.linksToGroupFinTermsLabel}</Text4>
                      {groupFinTermLinks}
                    </div>
                  )}
                  {manualRateAmountArr.length ? (<>
                    <Heading4 addClasses={'mt_1du'}>{text.fillManualInputsTitle}</Heading4>
                    {manualRateAmountArr}
                  </>)  : null}
                  <AddServicesCheckList addServiceIds={this.props.addServiceIds} contractId={this.props.contractId}
                                        organizationId={this.props.organizationId}
                                        reportAddServiceIds={this.state.addServiceIds} startDate={this.state.startDate}
                                        endDate={this.state.endDate} setReportAddServiceIds={this.setReportAddServiceIds}/>
                </div>
              </div>
            </div>
          </NModal>
        </RenderOnReady>

    );
  }

  getDatesPropError(isReportDatesComplied) {
    if (this.state.isWrongDateRange) {
      return text.wrongDateRange
    }
    return this.state.isNotCorrespondToLastValidReportDate ?
      text.reportPeriodIsNotCorrespondToLastValidReportDate(formatDate(this.props.lastValidReportDate)) :
      (isReportDatesComplied ? '' : text.reportPeriodIncompliance);
  }
}
const mapStateToProps = state => {
  return {
    templateParams: state.organization.item.templateParams,
    groupFinTermsView: state.organization.item.groupFinTermsView
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onInitReportsSearch: () => dispatch({type: reportsSearchActions.REPORTS_SEARCH_INIT_STATE_SET}),
    onGetReports: (value) => dispatch({type: reportsActionTypes.GET_REPORTS, value: value}),
    onGetTemplateParams: (templateParams) => dispatch({type: templateParamsActions.TEMPLATE_PARAMS_SET, value: templateParams})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GenerateReportModal);
