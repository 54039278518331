//@flow
import * as React from 'react';
import PropValueView from "../../../../../../../components/composed/PropValueView/PropValueView";
import text from "../../../../../../../services/localization/text";
import getCurrencyLabel from "../../../../../../../utils/getCurrencyLabel";
import type {count, currency, rateAmount, totalAmount} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {addClasses} from "../../../../../../../services/flowTypes/uiKitFlowTypes";

type Props = {
  currency: currency,
  rateAmount: rateAmount,
  totalAmount: totalAmount,
  count: count,
  paramAddClasses?: addClasses
};

const TotalRow = (props: Props) => {
  return (
    <div className="flex_jc-sb p_3du">
      <div className={props.paramAddClasses}>
        <PropValueView label={text.rateAmount} value={
          <>
            <span className={'mr_1du'}>{props.rateAmount}</span>
            {getCurrencyLabel(props.currency)}
          </>
        }/>
      </div>
      <div className={props.paramAddClasses}>
        <PropValueView label={text.count} value={props.count} />
      </div>
      <div className={props.paramAddClasses}>
        <PropValueView label={text.totalAmount} value={
          <>
            <span className={'mr_1du'}>{props.totalAmount}</span>
            {getCurrencyLabel(props.currency)}
          </>
        }/>
      </div>
    </div>
  );
};

export default TotalRow;    