//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {id, index, modalIsShown} from "../../../../../../../../services/flowTypes/appFlowTypes";
import type {organizationName, userId} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import BtnEditAct from "../../../../../../../../components/simple/Buttons/BtnEditAct/BtnEditAct";
import text from "../../../../../../../../services/localization/text";
import NModal from "../../../../../../../../components/simple/Modals/NModal/NModal";
import userMainDataActions from "../../../../../../../../store/user/userMainData/userMainDataActions";
import {initUpdateUser, onDeleteUserPermission} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import NHeading2 from "../../../../../../../../components/simple/TextComponents/NHeading2/NHeading2";

type Props = {
  onDeleteUserPermission: onDeleteUserPermission,
  userOrganizationIndex: index,
  organizationName: organizationName,
  initUpdateUser: initUpdateUser,
  idForTest?: id,
  userId: userId
};
type State = {
  modalIsShown: modalIsShown
};
class DeleteUserOrganization extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false
    };
  }
  showModal = () => {
    this.setState({
      modalIsShown: true,
    });
  };
  hideModal = () => {
    this.setState({
      modalIsShown: false,
    });
  };
  deleteOrganization = () => {
    this.props.onDeleteUserPermission(this.props.userOrganizationIndex);
    if (this.props.userId) {
      this.props.initUpdateUser();
      this.hideModal();
    } else {
      this.hideModal();
    }
  };
  render() {
    return (
      <>
        <BtnEditAct addClasses={'BtnEditAct_delete mr_2du'}
                    id={this.props.idForTest}
                    onClick={this.showModal}>{text.btnDel}</BtnEditAct>
        {this.state.modalIsShown ? (
          <NModal
            modalTitle={text.deleteUserOrganizationModalTitle}
            onModalCancelBtnClick={this.hideModal}
            onModalActionBtnClick={this.deleteOrganization}
            modalCancelBtnText={text.cancel}
            modalActionBtnText={text.btnDel}
            addClasses={'NModal_ov'}
            isModalDocumentEdit={true}
          >
            <NHeading2 addClasses={'mt_3du mb_3du'}>
              {text.deleteUserOrganization1}
              <span className={"NHeading2 NHeading2__span-link-color"}>{this.props.organizationName}</span>
              {text.deleteUserOrganization2}
            </NHeading2>
          </NModal>
          ) : null}
      </>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onDeleteUserPermission: (index) => dispatch({type: userMainDataActions.USER_PERMISSIONS_DELETE, value: index})
  };
};
export default connect(null, mapDispatchToProps)(DeleteUserOrganization);