//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {disabled, id, inputValue, onChange, placeholder} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './../FormTagSelect/FormTagSelect.css';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import type {idNameObject} from "../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../services/localization/text";
import {contractEditPageIds} from "../../../../tests/testIds";
import type {
  creationIsProhibited,
  isTagLabelEditable,
  selectOptions
} from "../../../../services/flowTypes/componentFlowTypes";
import SingleTagInput from "../SingleTagInput/SingleTagInput";

type Props = {
  id?: id,
  addClasses?: addClasses,
  value: Array<idNameObject>,
  onChange?: onChange,
  options?: Array<idNameObject>,
  disabled?: disabled,
  idForTest?: id,
  creationIsProhibited?: creationIsProhibited,
  placeholder?: placeholder,
  isTagLabelEditable?: isTagLabelEditable
};
type State = {
  inputValue: inputValue
};

class FormCreatableSelect extends React.Component <Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      inputValue: ''
    };
  }
  onSingleTagInputBlur = (singleTagInputValue, tagIndex) => {

      const newValue = this.props.value.map((item, index) => {
        if (index === tagIndex && this.props.value.findIndex(item => item.name === singleTagInputValue) === -1) {
          return {...item, name: singleTagInputValue};
        } else {
          return {...item};
        }
      });
      if (this.props.onChange) {
        this.props.onChange(newValue);
      }

  };
  handleChange = (value:selectOptions) => {
    const newValue = value.map(item => {
      if (item.value === item.label) {
        return {name: item.label};
      } else {
        return {id: item.value, name: item.label};
      }
    });
    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  };
  handleInputChange = (inputValue: string) => {
    if (inputValue !== this.state.inputValue) {
      this.setState({ inputValue });
    }

  };
  handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { inputValue } = this.state;
    if (!inputValue) return;
    if (event.key === 'Enter' || event.key === 'Tab') {
      if (this.props.value.findIndex(item => item.name === this.state.inputValue) === -1) {
        this.setState({
          inputValue: ''
        }, () =>{
          const oldValue = this.props.value.slice();
          const newOption = {name: inputValue};
          const newValue = [...oldValue, newOption];
          if (this.props.onChange) {
            this.props.onChange(newValue);
          }
        });
        event.preventDefault();
      }
    }
  };
  render(){
    const customTagSelectStyles = {
      control: (provided, state) => ({
        ...provided,
        borderRadius: '2px',
        width: this.props.disabled ? '100%' : '80%',
        backgroundColor: this.props.disabled ? 'var(--item-back-color)' : 'transparent',
        border: 'unset',
        minHeight: '50px',
        cursor: 'pointer',
        boxShadow: this.props.disabled ? 'unset' : '0 3px 5px 0 rgba(0,0,0,.1)',
        padding: 'var(--distance-unit) 0 0 var(--distance-unit)'
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: 'transparent'
      }),
      menuList: (provided, state) => ({
        ...provided,
        backgroundColor: '#ffffff'
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        padding: '0'
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: 'var(--dark-text-color)',
        fontFamily: 'var(--main-font-family)',
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '40px'
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'var(--main-accent-color)' : 'var(--main-text-color)',
        backgroundColor: state.isFocused ? 'var(--item-back-color)' : '#ffffff',
        fontFamily: 'var(--main-font-family)',
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '40px'
      }),
      multiValue: (provided, state) => ({
        ...provided,
        lineHeight: '34px',
        backgroundColor: 'var(--tag-label-color)',
        borderRadius: '5px',
        margin: '0 var(--distance-unit) var(--distance-unit) 0'
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        lineHeight: '34px',
        color: 'var(--main-accent-color)',
        fontFamily: 'var(--main-font-family)',
        fontSize: '10px',
        fontWeight: '600',
        textTransform: 'uppercase',
        paddingLeft: '20px',
        padding: '0px 15px 0px 20px'
      }),
      multiValueRemove: (provided, state) => ({
        ...provided,
        display: this.props.disabled ? 'none' : 'flex',
        lineHeight: '34px',
        color: state.isHovered ? 'var(--warning-color)' : 'var(--main-accent-color)',
        fontSize: '18px',
        paddingLeft: '13px',
        paddingRight: '13px',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'var(--tag-label-del-ico-hover-color)'
        }
      }),
    };
    const value = this.props.value.map(item =>{
      if (item.id) {
        return {value: item.id, label: item.name};
      } else {
        return {value: item.name, label: item.name};
      }
    });
    const options = this.props.options ? this.props.options : [];
    const MultiValueRemove = (props) => {
      const innerProps = {...props.innerProps, id: contractEditPageIds.buttonRemoveTag(props.data.value)};
      const newProps = {...props, innerProps: innerProps};
      return (
        <components.MultiValueRemove {...newProps}/>
      );
    };
    const MultiValueLabel = (props) => {
      const tagIndex = this.props.value.findIndex(item => {
        if (item.id) {
          return item.id === props.data.value && item.name === props.data.label;
        } else {
          return item.name === props.data.label;
        }
      });
      const children = this.props.isTagLabelEditable ?
        <SingleTagInput readOnly={this.props.disabled} value={props.children} tagIndex={tagIndex}
                        onBlur={this.onSingleTagInputBlur} />
      : props.children;
      const newProps = {...props, children: children};
      return (
        <components.MultiValueLabel {...newProps}/>
      );
    };
    const ClearIndicator = (props) => {
      const { innerProps: { ref, ...restInnerProps } } = props;
      const id = this.props.idForTest ? this.props.idForTest + 'BtnRemoveAll' : 'BtnRemoveAll';
      return (
        <div {...restInnerProps} ref={ref} >
          <span className="FormTagSelect__clear-all-container">
            <span className="FormTagSelect__clear-all" id={id}>{text.clearAll}</span>
          </span>
        </div>
      );
    };
    const dropdownIndicatorClassName = this.props.disabled ? "FormTagSelect__enter-ico FormTagSelect__enter-ico_hidden"
      : "FormTagSelect__enter-ico";
    const transformedComponents = {
      DropdownIndicator: ()=> {return (<span className={dropdownIndicatorClassName + " mr_2du"}/>)},
      ClearIndicator: ClearIndicator,
      MultiValueRemove: MultiValueRemove,
      MultiValueLabel: MultiValueLabel
    };
    const creationIsProhibited = this.props.creationIsProhibited ? this.props.creationIsProhibited : false;
    return (
      <div id={this.props.id} className={baseClass("FormTagSelect", this.props.addClasses)}>
        {creationIsProhibited ? (
          <Select
            components={transformedComponents}
            isDisabled={this.props.disabled}
            inputValue={this.state.inputValue}
            isClearable
            isMulti
            onChange={this.handleChange}
            onInputChange={this.handleInputChange}
            placeholder={this.props.placeholder}
            value={value}
            options={options}
            styles={customTagSelectStyles}
            inputId={this.props.idForTest}
          />
        ) : (
          <CreatableSelect
            components={transformedComponents}
            isDisabled={this.props.disabled}
            inputValue={this.state.inputValue}
            isClearable
            isMulti
            onChange={this.handleChange}
            onInputChange={this.handleInputChange}
            onKeyDown={this.handleKeyDown}
            placeholder={this.props.placeholder}
            value={value}
            options={options}
            styles={customTagSelectStyles}
            noOptionsMessage={()=>null}
            inputId={this.props.idForTest}
          />
        )}
      </div>
    );
  }
}

export default FormCreatableSelect;