//@flow
import validationActions from './validationActions';
import type {action} from "../../services/flowTypes/appFlowTypes";
import type {isScrollToError, validationForm, validationIsAllowed} from "../../services/flowTypes/dataFlowTypes";
import update from 'immutability-helper';

type State = {
  form: validationForm,
  validationIsAllowed: validationIsAllowed,
  isScrollToError: isScrollToError
};
type reducerAction = action;
const initialState = {
  form: {},
  validationIsAllowed: false,
  isScrollToError: false
};

const validationReducer = (state:State = initialState, action:reducerAction) => {
  switch (action.type) {
    // case validationActions.VALIDATION_STATE_INIT:
    //   return initialState;
    case validationActions.VALIDATION_STATE_SET:
      // const cleanedState = Object.assign({}, initialState);
      const cleanedState = {form: {}, validationIsAllowed: false, isScrollToError: false};
      for (let objProp in action.value) {
        const typeOfObjPropValue = typeof action.value[objProp];
        if (typeOfObjPropValue === 'number' || typeOfObjPropValue === 'string') {
          cleanedState.form[objProp] = '';
        }
      }
      return cleanedState;
    case validationActions.VALIDATION_STATE_FORM_PROP_SET:
      return update(state, {form: {[action.value.prop]: {$set: action.value.data}}});
    case validationActions.VALIDATION_STATE_FORM_PROP_DELETE:
      return update(state, {form: {$unset: [action.value]}});
    case validationActions.VALIDATION_STATE_PROP_SET:
      return update(state, {[action.value.prop]: {$set: action.value.data}});
    default:
      return state;
  }
};

export default validationReducer;