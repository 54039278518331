//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {inputFileKey} from "../../../services/flowTypes/appFlowTypes";
import type {groupFinTermIndex} from "../../../services/flowTypes/componentFlowTypes";
import text from "../../../services/localization/text";
import {reportViewPageIds} from "../../../tests/testIds";
import InputFile from "../../simple/FormComponents/InputFile/InputFile";
import reportManualAdjustmentActions
  from "../../../store/organization/item/reportManualAdjustment/reportManualAdjustmentActions";
import {onUpdateReportFinTermFile} from "../../../services/flowTypes/propFnFlowTypes";
import getUniqueKey from "../../../utils/getUniqueKey";

type Props = {
  groupFinTermIndex: groupFinTermIndex,
  onUpdateReportFinTermFile: onUpdateReportFinTermFile,
  file: any
};
type State = {
  inputFileKey: inputFileKey
};

class AdjustmentBaseDocumentUploader extends React.Component <Props, State> {
  inputFile: any;
  constructor(props: Props) {
    super(props);
    this.inputFile = React.createRef();
    this.state = {
      inputFileKey: 'defaultInputFileKey'
    };
  }
  addDoc = (e) => {
    e.preventDefault();
    if (this.inputFile.current.files[0]) {
      this.setState({
        inputFileKey: getUniqueKey()
      });
      const file = this.inputFile.current.files[0];
      this.props.onUpdateReportFinTermFile(this.props.groupFinTermIndex, file);
    }
  };
  render() {
    const label = this.props.file ? this.props.file.name : text.uploadDoc;
    return (
      <InputFile id={reportViewPageIds.buttonUploadNewBaseDocument(this.props.groupFinTermIndex)} ref={this.inputFile}
                 key={this.state.inputFileKey}
                 onInputFileChange={this.addDoc} label={
        <div className={'BtnEditAct'}>{label}</div>
      }/>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    file: state.organization.item.reportManualAdjustment[ownProps.groupFinTermIndex].file,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onUpdateReportFinTermFile: (groupFinTermIndex, file) => dispatch({
      type: reportManualAdjustmentActions.REPORT_FIN_TERM_FILE_UPDATE,
      value: { groupFinTermIndex, file} })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdjustmentBaseDocumentUploader);    