//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  agentId,
  agentLinkedTypeList, agentName, agentStatus,
  contractAgents,
  contractId, linkDate, linkDescription, linkTypeId
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../../services/localization/text";
import ability from "../../../../../../../services/permissions/permissions";
import {contractAgentsPageIds} from "../../../../../../../tests/testIds";
import type {errorObject, isReadyToRender, modalIsShown} from "../../../../../../../services/flowTypes/appFlowTypes";
import EditLinkedItemModal from "../../../../../../../components/composed/EditLinkedItemModal/EditLinkedItemModal";
import api from "../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import contractAgentsActions from "../../../../../../../store/organization/item/contractAgents/contractAgentsActions";
import type {onGetContractAgents} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import DeleteAgentModal from "./DeleteAgentModal";
import ConnectedAgentCard from "../../../../../../Agents/agents/AgentCard/ConnectedAgentCard";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";
import "./ContactAgents.css";
import EmptyListV2 from "../../../../../../../components/simple/Containers/EmptyList/EmptyListV2";

type Props = {
  contractAgents: contractAgents,
  agentLinkedTypeList: agentLinkedTypeList,
  onGetContractAgents: onGetContractAgents,
  contractId: contractId
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  modalIsShown: modalIsShown,
  id: agentId,
  linkDate: linkDate,
  typeId: linkTypeId,
  description: linkDescription,
  deleteModalIsShown: modalIsShown,
  agentIdToDelete: agentId,
  agentNameToDelete: agentName,
  status: agentStatus
};
const initialState = {
  modalIsShown: false,
  id: null,
  linkDate: '',
  typeId: null,
  description: ''
};
class ConnectedContractAgentsList extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null,
      modalIsShown: false,
      id: null,
      linkDate: '',
      typeId: null,
      description: '',
      deleteModalIsShown: false,
      agentIdToDelete: null,
      agentNameToDelete: ''
    };
  }
  hideDeleteModal = () => {
    this.setState({
      deleteModalIsShown: false,
      agentIdToDelete: null,
      agentNameToDelete: ''
    });
  };
  showDeleteModal = (agentIdToDelete, agentNameToDelete) => {
    this.setState({
      deleteModalIsShown: true,
      agentIdToDelete, agentNameToDelete
    });
  };
  hideModal = () => {
    const {modalIsShown, id, linkDate, typeId, description} = initialState;
    this.setState({modalIsShown, id, linkDate, typeId, description});
  };
  editLinkedAgent = (requestObj) => {
    this.setState({isReadyToRender: false});
    const updatedAgent = {...requestObj, date: requestObj.linkDate};
    delete updatedAgent.linkDate;
    api.updateContractAgent(this.props.contractId, updatedAgent, this.props.status)
      .then(response => {
        this.props.onGetContractAgents(response.data);
        this.setState({isReadyToRender: true});
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  showEditModal = (linkedAgent) => {
    const id = linkedAgent.id;
    const linkDate = linkedAgent.linkDate ? linkedAgent.linkDate : '';
    const typeId = linkedAgent.typeId ? linkedAgent.typeId : null;
    const description = linkedAgent.description ? linkedAgent.description : '';
    this.setState({modalIsShown: true, id, linkDate, typeId, description});
  };
  render() {
    const relatedAgents = this.props.contractAgents === null ? null :
      this.props.contractAgents && this.props.contractAgents.length ? this.props.contractAgents.map((item, i) => {
      const onDelAgentClick = ability.can('usePermission', permissionsStrValues.contractLinkedAgent.delete) ?
        () => this.showDeleteModal(item.id, item.name) : null;
      const onEditBtnClick = ability.can('usePermission', permissionsStrValues.contractLinkedAgent.update) ?
        () => this.showEditModal(item) : null;
      const agentRelated = {
        fintermsCount: item.fintermsCount,
        contractsCount: item.contractsCount
      };
      return (<ConnectedAgentCard key={'AgentCard'+i}
                         linkId={contractAgentsPageIds.linkToAgent(i)}
                         buttonDeleteAgentId={contractAgentsPageIds.buttonDeleteAgent(i)}
                         btnEditId={contractAgentsPageIds.buttonEditAgent(i)}
                         agent={item} agentRelated={agentRelated}
                         onDelAgentClick={onDelAgentClick}
                         delAgentIsDisabled={item.fintermsCount}
                         onEditBtnClick={onEditBtnClick} isWideMode={true}
                         isLinkedItemsMode={true}
                         btnDelDisabledReasonTooltipText={item.fintermsCount ? text.contractAgentsDisabledDeleteTooltip : ''}
                         addClasses={'mb_1du'}/>)
    }) : <EmptyListV2/>;
    return (
      <div className="ConnectedContractAgentsList">
        {relatedAgents}
        {this.state.modalIsShown ? (
          <EditLinkedItemModal
            modalIsShown={this.state.modalIsShown}
            hideModal={this.hideModal}
            itemLinkedTypeList={this.props.agentLinkedTypeList}
            onEditLinkedItemClick={this.editLinkedAgent}
            id={this.state.id}
            linkDate={this.state.linkDate}
            typeId={this.state.typeId}
            description={this.state.description}
            itemType={'agent'}
          />
        ) : null}
        <DeleteAgentModal
          contractId={this.props.contractId}
          agentIdToDelete={this.state.agentIdToDelete}
          modalIsShown={this.state.deleteModalIsShown}
          cancelModal={this.hideDeleteModal}
          agentNameToDelete={this.state.agentNameToDelete}
          status={this.props.status}
          isContractAgentMode={true}
        />
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contractAgents: state.organization.item.contractAgents,
    agentLinkedTypeList: state.organization.organizationData.statuses.agentLinkedTypeList
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetContractAgents: (contractAgents) => dispatch({type: contractAgentsActions.CONTRACT_AGENTS_SET, value: contractAgents})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedContractAgentsList);