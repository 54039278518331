//@flow
import myOrganizationsSearchResultsActions from './myOrganizationsSearchResultsActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {myOrganizationsSearchResults} from "../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = {
  total: 0,
  myOrganizations: null
};

const myOrganizationsSearchResultsReducer = (state:myOrganizationsSearchResults = initialState, action:reducerAction) => {
  switch (action.type) {
    case myOrganizationsSearchResultsActions.SET_MY_ORGANIZATIONS_INIT_STATE:
      return initialState;
    case myOrganizationsSearchResultsActions.GET_MY_ORGANIZATIONS:
      return action.value;
    default:
      return state;
  }
};

export default myOrganizationsSearchResultsReducer;