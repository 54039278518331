import getUniqueKey from "../../utils/getUniqueKey";
import text from "../localization/text";

export const createAlert = (alertData) => {
  return {
    id: getUniqueKey(),
    type: alertData.type,
    text: text.alertsList[alertData.name]
  };
};

// some alerts with defined dynamically type aren't included to initAlertsList. They're generated in components
const initAlertsList = [
  {name: 'onSubmitEnabled', type: 'NOTIFICATION'},
  {name: 'onActTemplateDocument', type: 'NOTIFICATION'},
  {name: 'onAddendumTemplateFile', type: 'NOTIFICATION'},
  {name: 'onEndDateIncompliance', type: 'WARNING'},
  {name: 'onFinTermDateIncompliance', type: 'WARNING'},
  {name: 'onWrongFileName', type: 'WARNING'}
];

const alertsList = {};

for (let i=0; i<initAlertsList.length; i++) {
  alertsList[initAlertsList[i].name] = createAlert(initAlertsList[i]);
}

export default alertsList;