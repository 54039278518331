import moment from '../services/moment/momentInstance';

export const getPrevMonthStartDate = () => {
  return moment().subtract(1, 'month').startOf('month');
};
export const getPrevMonthEndDate = () => {
  return moment().subtract(1, 'month').endOf('month');
};

export const getPrevQuarterStartDate = () => {
  return moment().subtract(1, 'quarter').startOf('quarter');
};
export const getPrevQuarterEndDate = () => {
  return moment().subtract(1, 'quarter').endOf('quarter');
};