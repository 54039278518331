//@flow
import * as React from 'react';
import NModal from "../../../../../../../../components/simple/Modals/NModal/NModal";
import text from "../../../../../../../../services/localization/text";
import {cancelModal} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import ArrowIcon from "../../../../../../../../components/simple/Icons/ArrowIcon/ArrowIcon";
import type {errorObject, isReadyToRender} from "../../../../../../../../services/flowTypes/appFlowTypes";
import apiErrorHandler from "../../../../../../../../services/apiInstance/apiErrorHandler";
import api from "../../../../../../../../services/apiInstance/api";
import RenderOnReady from "../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import withHandledGroupFinTermData from "../GroupFinTermCard/withHandledGroupFinTermData";
import type {handledGroupFinTerm} from "../../../../../../../../services/flowTypes/componentFlowTypes";
import NHeading2 from "../../../../../../../../components/simple/TextComponents/NHeading2/NHeading2";

type Props = {
  cancelModal: cancelModal,
  setDeletedGroupFinTermId: Function,
  handledGroupFinTerm: handledGroupFinTerm
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};

class DeleteGroupFinTermModal extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true
    };
  }
  onDeleteGroupFinTerm = () => {
    this.setState({isReadyToRender: false});
    api.deleteGroupFinTerm(this.props.handledGroupFinTerm.groupId)
      .then(response => {
        this.props.setDeletedGroupFinTermId(response.data.id);
        this.setState({isReadyToRender: true});
        this.props.cancelModal();
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      })
  };
  render() {
    return (
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <NModal
          modalTitle={text.deleteFinTermModalTitle}
          onModalActionBtnClick={this.onDeleteGroupFinTerm}
          onModalCancelBtnClick={this.props.cancelModal}
          modalActionBtnText={text.btnDel}
          modalCancelBtnText={text.cancel}
          isModalDocumentEdit={true}
        >
          <NHeading2 addClasses={'mt_3du'}>
            {text.btnDel + " "}
            <span className={"NHeading2 NHeading2__span-link-color"}>{this.props.handledGroupFinTerm.groupFinTermIdName}</span>
          </NHeading2>
          <NHeading2 addClasses={'flex mb_2du'}>
            {this.props.handledGroupFinTerm.payerName }
            <ArrowIcon addClasses={'ArrowIcon_in-NHeading1'}/>
            {this.props.handledGroupFinTerm.payeeName}
            {"?"}
          </NHeading2>
        </NModal>
      </>
    );
  }
}

const ConnectedDeleteGroupFinTermModal = withHandledGroupFinTermData(DeleteGroupFinTermModal)
export default ConnectedDeleteGroupFinTermModal;