//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {errorObject, isReadyToRender} from "../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../components/service/RenderOnReady/RenderOnReady";
import api from "../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../services/apiInstance/apiErrorHandler";
import type {usersSearch} from "../../../../../services/flowTypes/dataFlowTypes";
import {handleResponse, onGetUsers, showSpinner} from "../../../../../services/flowTypes/propFnFlowTypes";
import usersSearchResultsActions
  from "../../../../../store/users/usersSearchResults/usersSearchResultsActions";
import type {searchIcoIsClicked} from "../../../../../services/flowTypes/componentFlowTypes";
import debounce from 'lodash.debounce';

type Props = {
  usersSearch: usersSearch,
  onGetUsers: onGetUsers,
  showSpinner: showSpinner,
  handleResponse: handleResponse,
  searchIcoIsClicked: searchIcoIsClicked
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};
class UsersFetcher extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true
    };
    this.debounceUsersRequest = debounce(this.getUsers, 500);
  }
  getUsersSearchData = () => {
    const usersSearchData = {...this.props.usersSearch};
    usersSearchData.status = this.props.usersSearch.status === "ALL" ? "" : this.props.usersSearch.status;
    usersSearchData.role = this.props.usersSearch.role === "ALL" ? "" : this.props.usersSearch.role;
    usersSearchData.organizationId = this.props.usersSearch.organizationId === "ALL" ?
      "" : this.props.usersSearch.organizationId;
    return usersSearchData;
  };
  getUsers = () => {
    this.props.showSpinner();
    const usersSearchData = this.getUsersSearchData();
    api.searchUsers(usersSearchData)
      .then((response) => {
        this.props.onGetUsers(response.data);
        this.props.handleResponse();
        this.setState({isReadyToRender: true});
      })
      .catch((error) => {
        this.setState({
          errorObject: apiErrorHandler(error)
        });
        this.props.handleResponse();
      });
  };
  componentDidUpdate(prevProps){
    if ( (prevProps.usersSearch !== this.props.usersSearch)
      || (prevProps.searchIcoIsClicked !== this.props.searchIcoIsClicked
        && this.props.searchIcoIsClicked) ) {
      this.debounceUsersRequest();
    }
  }
  componentDidMount(){
    this.getUsers();
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    usersSearch: state.users.search,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetUsers: (value) => dispatch({type: usersSearchResultsActions.GET_USERS, value: value})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersFetcher);