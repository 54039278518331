//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {history, isReadyToRender, locationType} from "../../../../../../services/flowTypes/appFlowTypes";
import api from "../../../../../../services/apiInstance/api";
import ConnectedContractLinkedContractsList from "./contractLinkedContracts/ConnectedContractLinkedContractsList";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import type {errorObject, match} from "../../../../../../services/flowTypes/appFlowTypes";
import {onGetContractLinkedContracts, onInitContractLinkedContracts} from "../../../../../../services/flowTypes/propFnFlowTypes";
import GetDataOnMountAndUpdate
  from "../../../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import contractLinkedContractsActions
  from "../../../../../../store/organization/item/contractLinkedContracts/contractLinkedContractsActions";
import type {contractStatus, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import type {
  onGetLinkedItemsRequestParamsProp,
  onInitLinkedItemsRequestParamsState
} from "../../../../../../services/flowTypes/propFnFlowTypes";
import SearchPageFilters from "../../../../../../components/composed/SearchPage/SearchBox/SearchPageFilters";
import linkedItemsRequestParamsActions
  from "../../../../../../store/organization/linkedItemsRequestParams/linkedItemsRequestParamsActions";
import handleCorrectOrgIdInResponse from "../../../../../../utils/handleCorrectOrgIdInResponse";
import RedirectToCorrectOrgUrl
  from "../../../../../../components/service/RedirectToCorrectOrgUrl/RedirectToCorrectOrgUrl";

type Props = {
  match: match,
  location: locationType,
  onGetContractLinkedContracts: onGetContractLinkedContracts,
  onInitContractLinkedContracts: onInitContractLinkedContracts,
  history: history,
  onGetLinkedItemsRequestParamsProp: onGetLinkedItemsRequestParamsProp,
  onInitLinkedItemsRequestParamsState: onInitLinkedItemsRequestParamsState,
  status: contractStatus
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  correctOrganizationId: organizationId
};

class ContractLinkedContracts extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      correctOrganizationId: null
    };
  }
  handleResponse = (responseData) => {
    this.props.onGetContractLinkedContracts(responseData);
    this.setState({isReadyToRender: true});
  };
  getContractsLinkedContracts = () => {
    this.props.onInitContractLinkedContracts();
    const contractId = +this.props.match.params.id;
    api.getContractLinkedContracts(contractId, this.props.status)
      .then(response => {
        handleCorrectOrgIdInResponse(this, response.data, this.setState, this.handleResponse);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  editLinkedContract = (linkedContract) => {
    this.setState({isReadyToRender: false});
    this.props.onInitContractLinkedContracts();
    const contractId = +this.props.match.params.id;
    api.editContractLinkedContract(contractId, linkedContract, this.props.status)
        .then(response => {
          this.props.onGetContractLinkedContracts(response.data);
          this.setState({isReadyToRender: true});
        })
        .catch(error => {
          this.setState({
            isReadyToRender: true,
            errorObject: apiErrorHandler(error)
          });
        });
  };
  componentDidUpdate(prevProps: Props) {
    if (this.props.status !== prevProps.status && prevProps.status !== null && this.props.status !== null) {
      this.getContractsLinkedContracts();
    }
  }
  componentDidMount() {
    this.props.onInitLinkedItemsRequestParamsState();
    const searchRequestObj = {
      category: 'contracts'
    };
    this.props.onGetLinkedItemsRequestParamsProp(searchRequestObj);
    this.setState({isReadyToRender: true});
  }
  render() {
    const contractId = this.props.match.params.id;
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
        <SearchPageFilters isLinkedItemsMode={true} addClasses={"Filters_sticky"}/>
        {this.props.status !== null ? (
          <RedirectToCorrectOrgUrl correctOrganizationId={this.state.correctOrganizationId} history={this.props.history}>
            <GetDataOnMountAndUpdate itemId={contractId} getDataOnMountAndUpdate={this.getContractsLinkedContracts}>
              <ConnectedContractLinkedContractsList contractId={contractId} status={this.props.status}
                                                    editLinkedContract={this.editLinkedContract}/>
            </GetDataOnMountAndUpdate>
          </RedirectToCorrectOrgUrl>
        ) : null}
      </RenderOnReady>
    );
  }
}

const mapStateToProps = state => {
  return {
    status: state.organization.linkedItemsRequestParams.hasOwnProperty('status') ?
      state.organization.linkedItemsRequestParams.status : null,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetContractLinkedContracts: (contractLinkedContracts) => dispatch({
      type: contractLinkedContractsActions.CONTRACT_LINKED_CONTRACTS_SET, value: contractLinkedContracts}),
    onInitContractLinkedContracts: () => dispatch({type: contractLinkedContractsActions.CONTRACT_LINKED_CONTRACTS_INIT}),
    onGetLinkedItemsRequestParamsProp: (searchRequestObj) => dispatch({type: linkedItemsRequestParamsActions.LINKED_ITEMS_REQUEST_PARAMS_PROP_SET,
      value: searchRequestObj}),
    onInitLinkedItemsRequestParamsState: () => dispatch({type: linkedItemsRequestParamsActions.LINKED_ITEMS_REQUEST_PARAMS_STATE_INIT})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractLinkedContracts);