//@flow
import * as React from 'react';
import NModal from "../../../../../../../../components/simple/Modals/NModal/NModal";
import text from "../../../../../../../../services/localization/text";
import NFormGroup from "../../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import NSelect from "../../../../../../../../components/simple/FormComponents/NSelect/NSelect";
import type {errorObject, isReadyToRender, modalIsShown} from "../../../../../../../../services/flowTypes/appFlowTypes";
import type {
  availableReportAccountStatuses, currentDocNum, itemDate,
  reportAccountStatus,
  reportAccountStatusList
} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import type {
  documentDate, propError,
  requestFn,
  selectOptions,
  updateStatusBtn
} from "../../../../../../../../services/flowTypes/componentFlowTypes";
import apiErrorHandler from "../../../../../../../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import connect from "react-redux/es/connect/connect";
import {reportViewPageIds} from "../../../../../../../../tests/testIds";
import FormSingleDatePicker
  from "../../../../../../../../components/simple/FormComponents/FormSingleDatePicker/FormSingleDatePicker";
import validationPatterns from "../../../../../../../../services/validationPatterns/validationPatterns";
import NInput from "../../../../../../../../components/simple/FormComponents/NInput/NInput";
import CheckboxInput from "../../../../../../../../components/simple/FormComponents/CheckboxInput/CheckboxInput";

type Props = {
  reportAccountStatusList: reportAccountStatusList,
  availableReportAccountStatuses: availableReportAccountStatuses,
  requestFn: requestFn,
  updateStatusBtn: updateStatusBtn,
  endReportPeriodDate: itemDate
};
type State = {
  modalIsShown: modalIsShown,
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  newReportAccountStatus: reportAccountStatus,
  reportAccountStatusOptions: selectOptions,
  documentDate: documentDate,
  propError: propError,
  currentDocNum: currentDocNum,
  isCurrentDocNumInputShown: boolean
};

class ReportAccountStatusModal extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      modalIsShown: false,
      newReportAccountStatus: -1,
      reportAccountStatusOptions: [],
      documentDate: '',
      propError: '',
      currentDocNum: '',
      isCurrentDocNumInputShown: false
    };
  }
  handleBooleanCheckboxInputChange = () => {
    if (this.state.isCurrentDocNumInputShown) {
      this.setState({currentDocNum: '', isCurrentDocNumInputShown: false});
    } else {
      this.setState({isCurrentDocNumInputShown: true});
    }
  };
  handleCurrentDocNumInputChange = (e:SyntheticEvent<HTMLInputElement>) => {
    this.setState({currentDocNum: e.currentTarget.value});
  };
  handleDateChange = (documentDate) => {
    const propError = validationPatterns.dateFormat(documentDate);
    this.setState({documentDate, propError});
  };
  showModal = () => {
    this.setState({
      modalIsShown: true,
      errorObject: null
    });
  };
  hideModal = () => {
    this.setState({modalIsShown: false});
  };
  handleResponse = () => {
    this.setState({
      isReadyToRender: true,
      modalIsShown: false
    });
  };
  handleError = (error) => {
    this.setState({
      isReadyToRender: true,
      errorObject: apiErrorHandler(error),
      modalIsShown: false
    });
  };
  updateStatus = () => {
    if (this.state.reportAccountStatusOptions.length) {
      this.setState({isReadyToRender: false});
      const newReportAccountStatusObj = {
        id: this.state.newReportAccountStatus,
        currentDocNum: this.state.currentDocNum,
        documentDate: this.state.documentDate
      };
      this.props.requestFn(newReportAccountStatusObj, this.handleResponse, this.handleError);
    } else {
      this.hideModal();
    }
  };
  handleStatusChange = (selectedOption) => {
    this.setState({newReportAccountStatus: selectedOption.value});
  };
  setReportAccountStatusOptions = () => {
    const reportAccountStatusList = this.props.reportAccountStatusList.filter(item => {
      return this.props.availableReportAccountStatuses.includes(item.id);
    });
    const reportAccountStatusOptions = reportAccountStatusList.map(item => {
      return {value: item.id, label: text.reportAccountStatus[item.name]};
    });
    const newReportAccountStatus = reportAccountStatusOptions.length ? reportAccountStatusOptions[0].value : null;
    this.setState({isReadyToRender: true, documentDate: this.props.endReportPeriodDate, reportAccountStatusOptions, newReportAccountStatus});
  };
  componentDidUpdate(prevProps){
    if (prevProps.availableReportAccountStatuses !== this.props.availableReportAccountStatuses) {
      this.setReportAccountStatusOptions();
    }
  }
  componentDidMount(){
    this.setReportAccountStatusOptions();
  }
  render() {
    const selectValue = this.state.newReportAccountStatus === -1 ? null
      : this.state.reportAccountStatusOptions.find(item => item.value === this.state.newReportAccountStatus);
    const selectPlaceholder = this.state.reportAccountStatusOptions.length ? null : text.noStatusesToUpdate;
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
        <div onClick={this.showModal}>
          {this.props.updateStatusBtn}
        </div>
        {this.state.modalIsShown ? (
          <NModal
            addClasses={'NModal_ov'}
            modalTitle={text.updateStatusTitle}
            modalCancelBtnText={text.cancel}
            modalActionBtnText={text.save}
            onModalCancelBtnClick={this.hideModal}
            onModalActionBtnClick={this.updateStatus}
            isModalActionBtnDisabled={this.state.propError.length}
            isModalDocumentEdit={true}
          >
            <>
              <div className="">
                <div className="w_half-card">
                  <NFormGroup label={text.newStatus} addClasses={'pt_2du mb_2du'}>
                    <NSelect value={selectValue} onChange={this.handleStatusChange}
                             options={this.state.reportAccountStatusOptions}
                             isDisabled={!this.state.reportAccountStatusOptions.length}
                             id={reportViewPageIds.selectUpdateStatus}
                             placeholder={selectPlaceholder}/>
                  </NFormGroup>
                </div>
                {this.state.newReportAccountStatus === 2 ? (
                  <div className="w_half-card">
                    <div className="mb_2du">
                      <CheckboxInput checked={this.state.isCurrentDocNumInputShown} addClasses={""}
                                     onClick={this.handleBooleanCheckboxInputChange}
                                     id={reportViewPageIds.checkboxShowCurrentDocNum}
                                     checkboxLabel={text.currentDocNumCheckboxLabel}/>
                      {this.state.isCurrentDocNumInputShown ? (
                        <NFormGroup label={text.currentDocNumLabel} addClasses={"mb_4du"}>
                          <NInput id={reportViewPageIds.inputCurrentDocNum} value={this.state.currentDocNum}
                                  onChange={this.handleCurrentDocNumInputChange}/>
                        </NFormGroup>
                      ) : null}
                    </div>
                    <NFormGroup label={text.actDate} addClasses={''} propError={this.state.propError}>
                      <FormSingleDatePicker dateId={reportViewPageIds.dateInputAct} date={this.state.documentDate}
                                            onDatesChange={this.handleDateChange}/>
                    </NFormGroup>
                  </div>
                ) : null}
              </div>

              <div className="SectionHeader mb_4du"/>
            </>
          </NModal>
        ) : null}

      </RenderOnReady>
    );
  }
}
const mapStateToProps = state => {
  return {
    reportAccountStatusList: state.organization.organizationData.statuses.reportAccountStatusList
  };
};

export default connect(mapStateToProps, null)(ReportAccountStatusModal);