//@flow
import * as React from 'react';
import type {children, id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import './NHeading2.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  title?: string
};

const NHeading2 = (props: Props) => {
  return (
    <h2 id={props.id} className={baseClass("NHeading2", props.addClasses)} title={props.title}>{props.children}</h2>
  );
};

export default NHeading2;