//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {children, id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './CustomDropDownContainer.css';
import type {isFocused} from "../../../../services/flowTypes/componentFlowTypes";
import CloseOnClickOutside from "../../../service/CloseOnClickOutside/CloseOnClickOutside";

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  headerComponentFn: Function,
  customOptionsContainerStyle?: Object
};
type State = {
  isFocused: isFocused
};

class CustomDropDownContainer extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isFocused: false
    };
  }
  handleFocus = (e:SyntheticEvent<HTMLElement>) => {
    if (!this.state.isFocused) {
      this.setState({isFocused: true});
    }
  };
  toggleFocus = () => {
    this.setState({isFocused: !this.state.isFocused});
  };
  onClickOutside = () => {this.setState({isFocused: false})};

  render() {
    const className = this.state.isFocused ? "CustomDropDownContainer CustomDropDownContainer_focused" : "CustomDropDownContainer";
    const headerProps = {isFocused: this.state.isFocused};
    return (
      <CloseOnClickOutside onClickOutside={this.onClickOutside} isActive={this.state.isFocused}>
        <div className={baseClass(className, this.props.addClasses)} onClick={this.handleFocus} id={this.props.idForTest}>
          <div className="CustomDropDownContainer__header-container" onClick={this.toggleFocus}>
            {this.props.headerComponentFn(headerProps)}
          </div>
          <div className="CustomDropDownContainer__options-relative-container">
            <div className="CustomDropDownContainer__options-container" style={this.props.customOptionsContainerStyle}>
              {this.props.children}
            </div>
          </div>
        </div>
      </CloseOnClickOutside>
    );
  }
}

export default CustomDropDownContainer;