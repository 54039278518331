//@flow
import * as React from 'react';
import text from "../../../../../../../services/localization/text";
import connect from "react-redux/es/connect/connect";
import type {
  availableReportAccountStatuses, itemDate,
  reportAccountStatusList, reportId
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import reportMainDataActions from "../../../../../../../store/organization/item/reportMainData/reportMainDataActions";
import api from "../../../../../../../services/apiInstance/api";
import {onGetReportMainData} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import ReportAccountStatusModal from "./ReportAccounStatusModal/ReportAccountStatusModal";
import {reportViewPageIds} from "../../../../../../../tests/testIds";
import reportsSearchActions from "../../../../../../../store/organization/reportsSearch/reportsSearchActions";
import reportsActionTypes from "../../../../../../../store/organization/reports/reportsActions";
import type {onGetReports, onInitReportsSearch} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import {initialState} from "../../../../../../../store/organization/reports/reports";

type Props = {
  reportAccountStatusList: reportAccountStatusList,
  availableReportAccountStatuses: availableReportAccountStatuses,
  onGetReportMainData: onGetReportMainData,
  reportId: reportId,
  endReportPeriodDate: itemDate,
  onGetReports: onGetReports,
  onInitReportsSearch: onInitReportsSearch
};
class UpdateReportAccountStatus extends React.Component <Props> {
  requestFn = (newReportAccountStatusObj, handleResponse, handleError) => {
    api.updateReportAccountStatus(this.props.reportId, newReportAccountStatusObj)
      .then(response => {
        this.props.onGetReportMainData(response.data);
        //to refresh reports list
        this.props.onGetReports(initialState);
        this.props.onInitReportsSearch();

        handleResponse();
      })
      .catch(error => {
        handleError(error);
      });
  };
  render() {
    return (
      <ReportAccountStatusModal
        availableReportAccountStatuses={this.props.availableReportAccountStatuses}
        requestFn={this.requestFn}
        endReportPeriodDate={this.props.endReportPeriodDate}
        updateStatusBtn={<div id={reportViewPageIds.buttonUpdateStatus} className="BtnEditAct">{text.updateStatus}</div>}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitReportsSearch: () => dispatch({type: reportsSearchActions.REPORTS_SEARCH_INIT_STATE_SET}),
    onGetReports: (value) => dispatch({type: reportsActionTypes.GET_REPORTS, value: value}),
    onGetReportMainData: (reportMainData) => dispatch({
      type: reportMainDataActions.REPORT_MAIN_DATA_SET,
      value: reportMainData})
  };
};

export default connect(null, mapDispatchToProps)(UpdateReportAccountStatus);