//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  argsForValidationFn,
  formFieldType, isSearchable, labelTooltip, noOptionsMessage, openDirection,
  propName,
  propValue,
  selectOptions
} from "../../../../../../../services/flowTypes/componentFlowTypes";
import {
  onGetAddServiceProp
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import type {id, label, placeholder, readOnly} from "../../../../../../../services/flowTypes/appFlowTypes";
import ConnectedFormGroup from "../../../../../../../components/composed/ConnectedFormGroup/ConnectedFormGroup";
import type {addClasses} from "../../../../../../../services/flowTypes/uiKitFlowTypes";
import addServiceEditActions from "../../../../../../../store/organization/item/addServiceEdit/addServiceEditActions";
import addServiceValidationScheme from "../../../../../../../configData/addServiceValidationScheme";
import validationActions from "../../../../../../../store/validation/validationActions";
import type {onGetValidationStateFormProp} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import {newSheet} from "../AddServiceSheetsEdit";

type Props = {
  formFieldType: formFieldType,
  propName: propName,
  propValue: propValue,
  onGetAddServiceProp: onGetAddServiceProp,
  label: label,
  options?: selectOptions,
  addClasses?: addClasses,
  placeholder?: placeholder,
  noOptionsMessage?: noOptionsMessage,
  idForTest?: id,
  isSearchable?: isSearchable,
  selectInputId?: id,
  openDirection?: openDirection,
  labelTooltip?: labelTooltip,
  readOnly?: readOnly,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  argsForValidationFn?: argsForValidationFn
};

class AddServiceFormGroup extends React.Component <Props, void> {
  onGetPropValue = (prop, data) => {
    this.props.onGetAddServiceProp(prop, data);
    if (this.props.propName === 'isDetachedInvoice') {
      this.props.onGetAddServiceProp('sheets', [newSheet]);
      this.props.onGetAddServiceProp('actTemplateId', '');
      this.props.onGetValidationStateFormProp('actTemplateId', '');
    }
    if (this.props.propName === 'payoutPeriod') {
      this.props.onGetAddServiceProp('actTemplateId', '');
    }
  };
  setCurrentUserId = () => {
    if (this.props.propName === 'userId' && this.props.propValue === '') {
      const currentUserName = window.APP_DATA.userName;
      const currentUserObj = this.props.options.find(item => item.label === currentUserName);
      if (currentUserObj) {
        this.props.onGetAddServiceProp('userId', currentUserObj.value);
      }
    }
  };
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if (prevProps.options !== this.props.options && this.props.propName === 'userId' && this.props.propValue === '') {
      this.setCurrentUserId();
    }
  }
  componentDidMount(): * {
    this.setCurrentUserId();
  }
  render() {
    const propValidationPatternsArr = addServiceValidationScheme[this.props.propName] ?
      addServiceValidationScheme[this.props.propName] : null;
    return (
      <ConnectedFormGroup
        formFieldType={this.props.formFieldType}
        propName={this.props.propName}
        propValue={this.props.propValue}
        onGetPropValue={this.onGetPropValue}
        label={this.props.label}
        options={this.props.options}
        propValidationPatternsArr={propValidationPatternsArr}
        addClasses={this.props.addClasses}
        idForTest={this.props.idForTest}
        placeholder={this.props.placeholder}
        noOptionsMessage={this.props.noOptionsMessage}
        isSearchable={this.props.isSearchable}
        selectInputId={this.props.selectInputId}
        openDirection={this.props.openDirection}
        labelTooltip={this.props.labelTooltip}
        readOnly={this.props.readOnly}
        argsForValidationFn={this.props.argsForValidationFn}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propValue: state.organization.item.addServiceEdit[ownProps.propName]
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetAddServiceProp: (prop, data) => dispatch({type: addServiceEditActions.ADD_SERVICE_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddServiceFormGroup);