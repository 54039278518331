//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {general} from "../../../services/flowTypes/dataFlowTypes";
import {onGetGeneralDataProp} from "../../../services/flowTypes/propFnFlowTypes";
import GetPrerenderData from "../GetPrerenderData/GetPrerenderData";
import api from "../../../services/apiInstance/api";
import generalActions from "../../../store/general/generalActions";
import LayoutRoutes from "../Routes/LayoutRoutes";
import GetAdminData from "../GetAdminData/GetAdminData";
import ability from "../../../services/permissions/permissions";
import {globalPermissionsStrValues} from "../../../configData/permissionsStrValues";

type Props = {
  general: general,
  onGetGeneralDataProp: onGetGeneralDataProp
};

class GetGeneralData extends React.Component <Props> {
  render() {
    const fetchFnArr = [
      {
        fetch: api.getOrganizationsList, propName: 'organizationsList'
      },
    ];
    return (
      <GetPrerenderData fetchFnArr={fetchFnArr} storeDataObj={this.props.general} setFn={this.props.onGetGeneralDataProp}>
        {ability.can('useGlobalPermission', globalPermissionsStrValues.admin) ? (
          <GetAdminData>
            <LayoutRoutes/>
          </GetAdminData>
        ) : (
          <LayoutRoutes/>
        )}

      </GetPrerenderData>
    );
  }
}
const mapStateToProps = state => {
  return {
    general: state.general
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetGeneralDataProp: (prop, data) => dispatch({type: generalActions.GENERAL_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GetGeneralData);    