//@flow
import * as React from 'react';
import {baseClass} from "../../../pages/UiKit/newUiKit/utilities/baseClass";
import {Link} from "react-router-dom";
import concatIdName from "../../../utils/concatIdName";
import SecText from "../../simple/TextComponents/SecText/SecText";
import Heading4 from "../../simple/TextComponents/Heading4/Heading4";
import StatusLabel from "../../simple/TextComponents/StatusLabel/StatusLabel";
import text from "../../../services/localization/text";
import TileIcon from "../../simple/Containers/TileIcon/TileIcon";
import './ListItemCard.css';
import type {disabled, id} from "../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import type {
  cardTiles, disabledReasonTooltipText,
  isAllRelatedItemsShown, isLinkedItemsMode,
  isWideMode,
  linkTo
} from "../../../services/flowTypes/componentFlowTypes";
import type {
    agentId,
    agentName,
    contractId,
    contractName,
    itemDate,
    linkDate, linkDescription
} from "../../../services/flowTypes/dataFlowTypes";
import TextOverflowHandler from "../../simple/Containers/TextOverflowHandler/TextOverflowHandler";
import type {onDeleteBtnClick, onEditBtnClick} from "../../../services/flowTypes/propFnFlowTypes";
import BtnDel from "../../simple/Buttons/BtnDel/BtnDel";
import BtnEdit from "../../simple/Buttons/BtnEdit/BtnEdit";
import BtnEditAct from "../../simple/Buttons/BtnEditAct/BtnEditAct";
import Text1 from "../../simple/TextComponents/Text1/Text1";
import formatDate from "../../../utils/formatDate";

type Props = {
    id?: id,
    addClasses?: addClasses,
    linkId?: id,
    linkTo?: linkTo,
    itemId: contractId | agentId,
    itemName: contractName | agentName,
    createDate?: itemDate,
    validityTerm?: string,
    underHeading?: string,
    itemType?: string,
    itemStatus?: string,
    itemStatusLabel?: string,
    tags?: Array<React.Node>,
    relatedItemsTitle?: string,
    relatedItems?: Array<string>,
    cardTiles?: cardTiles,
    onDeleteBtnClick?: onDeleteBtnClick,
    onEditBtnClick?: onEditBtnClick,
    btnDelId?: id,
    btnEditId?: id,
    btnDelIsDisabled?: disabled,
    linkDate?: linkDate,
    linkType?: string,
    linkDescription?: linkDescription,
    cardHeadingId?: id,
    isWideMode?: isWideMode,
    isLinkedItemsMode?: isLinkedItemsMode,
    btnDelDisabledReasonTooltipText?: disabledReasonTooltipText,
    warningComponent?: React.Node,
    preNameComponent? : React.Node
};
type State = {
  isAllRelatedItemsShown: isAllRelatedItemsShown
};

class ListItemCard extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isAllRelatedItemsShown: false
    };
  }
  showAllRelatedItems = () => {
    this.setState({isAllRelatedItemsShown: true});
  };
  render() {
      const relatedItems = this.props.relatedItems ? this.props.relatedItems.map((relatedItem, index) => {
          return (<Heading4 key={"relatedItem-"+index}>
              <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{relatedItem}</TextOverflowHandler>
          </Heading4>)
      }) : null;
      const cardTiles = this.props.cardTiles ? this.props.cardTiles.map((cardTile, i) => {
          return (<TileIcon key={'cardTile'+i} tileIconType={cardTile.tileIconType}>{cardTile.numberLabel}</TileIcon>);
      }) : null;
      const className = this.props.isWideMode ? "NCard ListItemCard ListItemCard_wide" : "NCard ListItemCard";
      // const isLinkedItem = this.props.linkDate || this.props.linkType || this.props.linkDescription;
      const linkDate = this.props.linkDate ? formatDate(this.props.linkDate) : text.linkDateNotSet;
      const linkType = this.props.linkType ? this.props.linkType : text.linkTypeNotSet;
      const linkDescription = this.props.linkDescription ? this.props.linkDescription : '-';
      const classNameWithCardType = this.props.isLinkedItemsMode ? className + " ListItemCard_isLinkedItem" : className;
      const dataUiTestId =  this.props.itemId ? `itemCard_id_${this.props.itemId}` : '';
      return (
        <div id={this.props.id} className={baseClass(classNameWithCardType, this.props.addClasses)}>
            <div className="ListItemCard__left p_3du">
              {this.props.warningComponent}
                <div className="ListItemCard__above-title">
                  <div className="ListItemCard__above-title-left">
                    {this.props.itemStatus ? (
                      <StatusLabel addClasses={`StatusLabel_${this.props.itemStatus}`}>
                        {this.props.itemStatusLabel}</StatusLabel>
                    ) : null}
                    {this.props.isLinkedItemsMode ? this.props.validityTerm ? (
                      <SecText>{this.props.validityTerm}</SecText>
                    ) : null : (this.props.underHeading ? (<SecText>{this.props.underHeading}</SecText>) : null)}
                  </div>
                  <div className="ListItemCard__above-title-right">
                    {this.props.isLinkedItemsMode ?
                      (<Heading4>{linkDate}</Heading4>)
                      : (this.props.validityTerm ? (
                        <Heading4>{this.props.validityTerm}</Heading4>
                      ) : null)}
                    {this.props.isLinkedItemsMode ? (<SecText>{linkType}</SecText>) :
                      (this.props.itemType ? (<SecText>{this.props.itemType}</SecText>) : null)}
                  </div>
                </div>
                <div className="ListItemCard__name mt_1du flex ai_c">
                    {this.props.preNameComponent}
                    <div className="TextOverflowHandler">
                    {this.props.linkTo ? (
                      <Link id={this.props.linkId} to={this.props.linkTo} data-ui-test-id={dataUiTestId}>
                          <Heading4 addClasses={'Heading4_link-on-hover Heading4_fwb Heading4_lh20'} id={this.props.cardHeadingId}>
                            <TextOverflowHandler addClasses={"TextOverflowHandler_mw-100"}>
                              {concatIdName(this.props.itemId, this.props.itemName)}
                            </TextOverflowHandler>
                          </Heading4>
                      </Link>
                    ) : (
                      <Heading4 id={this.props.cardHeadingId} addClasses={'Heading4_fwb Heading4_lh20'}>
                        <TextOverflowHandler>
                          {concatIdName(this.props.itemId, this.props.itemName)}
                        </TextOverflowHandler>
                      </Heading4>
                    )}
                    </div>
                </div>
                {this.props.isLinkedItemsMode ? <Text1 addClasses={'Text1_card-label-color Text1_italic'}>{linkDescription}</Text1> : null}
                {this.props.tags && this.props.tags.length ? (
                  <div className="ListItemCard__tags">{this.props.tags}</div>
                ) : null}

                {/*<div className="flex_jc-sb for-tags-right-border">*/}
                {/*    <div className="ListItemCard__left-2nd-row">*/}
                {/*        <div className="flex">*/}
                {/*          {this.props.createDate ? (*/}
                {/*            <div className="mr_3du">*/}
                {/*              <SecText>{text.createDate}</SecText>*/}
                {/*              <Heading4>{this.props.createDate}</Heading4>*/}
                {/*            </div>*/}
                {/*          ) : null}*/}

                {/*        </div>*/}


                {/*    </div>*/}
                {/*    */}
                {/*</div>*/}

                {/*{(this.props.linkDate || this.props.linkType || this.props.linkDescription) ? (*/}
                {/*  <div className="ListItemCard__left-3rd-row flex">*/}
                {/*      <div className={'mr_3du'}>*/}
                {/*          <SecText addClasses={'ws_n'}>{text.contract.linkType}</SecText>*/}
                {/*          <Heading4>{this.props.linkType ? this.props.linkType : '-'}</Heading4>*/}
                {/*      </div>*/}
                {/*      <div className={'mr_3du'}>*/}
                {/*          <SecText>{text.linkDate}</SecText>*/}
                {/*          <Heading4>{this.props.linkDate ? this.props.linkDate : '-'}</Heading4>*/}
                {/*      </div>*/}
                {/*      <div>*/}
                {/*          <SecText>{text.description}</SecText>*/}
                {/*          <Heading4>{this.props.linkDescription ? this.props.linkDescription : '-'}</Heading4>*/}
                {/*      </div>*/}
                {/*  </div>*/}
                {/*) : null}*/}

            </div>



            <div className="ListItemCard__right">
              {this.props.relatedItemsTitle ?
              <div className="ListItemCard__related-items">
                 <SecText>{text.agents}</SecText>
                {relatedItems && relatedItems.length > 3 ? (
                  this.state.isAllRelatedItemsShown ?
                    (<div className={'ListItemCard__related-items-list'}>{relatedItems}</div>)
                    : (
                      <div className={'ListItemCard__related-items-list'}>
                        {relatedItems.slice(0,3)}
                        <BtnEditAct onClick={this.showAllRelatedItems} addClasses={'BtnEditAct_dashed mt_1du'}>
                          {text.showOther + " " + (relatedItems.length - 3)}</BtnEditAct>
                      </div>
                    )
                ) : (<div className={'ListItemCard__related-items-list'}>{relatedItems}</div>)}
              </div> : null}

              {cardTiles ? (
                <div className="ListItemCard__tiles">
                  <div>
                    {cardTiles}
                  </div>
                </div>
              ) : null}

              {this.props.onDeleteBtnClick || this.props.onEditBtnClick ? (
                  <div className="ListItemCard__buttons">
                      {this.props.onEditBtnClick ?
                        (<BtnEdit addClasses={'mr_1du'} id={this.props.btnEditId} onClick={this.props.onEditBtnClick}
                                  isCardButtonMode={true}/>)
                        : null}
                      {this.props.onDeleteBtnClick ?
                        (<BtnDel id={this.props.btnDelId} onClick={this.props.onDeleteBtnClick}
                                 disabledReasonTooltipText={this.props.btnDelDisabledReasonTooltipText}
                                 disabled={this.props.btnDelIsDisabled} isCardButtonMode={true}/>)
                        : null}
                  </div>
                ) : null}


            </div>
        </div>
      );
    }
}

export default ListItemCard;
