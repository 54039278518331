//@flow
import * as React from 'react';
import type {modalIsShown} from "../../../../../../services/flowTypes/appFlowTypes";
import text from "../../../../../../services/localization/text";
import NModal from "../../../../../../components/simple/Modals/NModal/NModal";
import type {
  notedTerminalType,
  notedTerminalTypeId, organizationId, terminal,
  terminalComment, usersList
} from "../../../../../../services/flowTypes/dataFlowTypes";
import type {selectOptions} from "../../../../../../services/flowTypes/componentFlowTypes";
import {connect} from "react-redux";
import NSelect from "../../../../../../components/simple/FormComponents/NSelect/NSelect";
import NFormGroup from "../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import Textarea from "../../../../../../components/simple/FormComponents/Textarea/Textarea";
import formatDate from "../../../../../../utils/formatDate";
import Heading4 from "../../../../../../components/simple/TextComponents/Heading4/Heading4";
import Text1 from "../../../../../../components/simple/TextComponents/Text1/Text1";
import {Link} from "react-router-dom";
import {
  editOrganizationSettingsSuffix,
  orgPath,
  orgSettingsSuffix
} from "../../../../../../services/urlStrings/urlStrings";
import ModalDeleteBtn from "../../../../../../components/simple/Buttons/ModalDeleteBtn/ModalDeleteBtn";
import NHeading1 from "../../../../../../components/simple/TextComponents/NHeading1/NHeading1";
import ConfirmActionModal from "../../../../../../components/simple/Modals/ConfirmActionModal/ConfirmActionModal";
import BtnEditAct from "../../../../../../components/simple/Buttons/BtnEditAct/BtnEditAct";

type Props = {
  terminal: terminal,
  notedTerminalTypeList: Array<notedTerminalType>,
  updateTerminal: Function,
  terminalIndex: number,
  usersList: usersList,
  organizationId: organizationId
};
type State = {
  modalIsShown: modalIsShown,
  comment: terminalComment,
  notedTerminalTypeId: notedTerminalTypeId,
  notedTerminalTypeOptions: selectOptions
};

class GatesParamsCardComment extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false,
      comment: '',
      notedTerminalTypeId: null,
      notedTerminalTypeOptions: []
    };
  }
  showModal = () => {
    this.setState({
      modalIsShown: true,
      comment: this.props.terminal.comment,
      notedTerminalTypeId: this.props.terminal.notedTerminalTypeId
    });
  };
  onCancelBtnClick = () => {
    this.setState({
      modalIsShown: false,
      comment: '',
      notedTerminalTypeId: null
    });
  };
  onSaveBtnClick = () => {
    const terminal = {...this.props.terminal,
      comment: this.state.comment,
      notedTerminalTypeId: this.state.notedTerminalTypeId,
      isCommentUpdated: true
    };
    delete terminal.commentDate;
    delete terminal.commentUserId;
    this.props.updateTerminal(terminal, this.props.terminalIndex);
    this.onCancelBtnClick();
  };
  handleModalDeleteBtnClick = () => {
    this.handleDeleteBtnClick(this.onCancelBtnClick);
  };
  handleDeleteBtnClick = (callbackFn) => {
    const terminal = {...this.props.terminal,
      comment: "",
      notedTerminalTypeId: null,
      isCommentUpdated: true
    };
    delete terminal.commentDate;
    delete terminal.commentUserId;
    this.props.updateTerminal(terminal, this.props.terminalIndex);
    if (callbackFn) {
      callbackFn();
    }
  };
  handleCommentChange = (e:SyntheticEvent<HTMLInputElement>) => {
    this.setState({comment: e.currentTarget.value});
  };
  handleNotedTerminalTypeChange = (selectedOption) => {
    const notedTerminalTypeId = selectedOption === null ? null : selectedOption.value
    this.setState({notedTerminalTypeId});
  };
  componentDidMount(): * {
    const notedTerminalTypeOptions = this.props.notedTerminalTypeList.map(item => {
      return {value: item.id, label: item.name};
    });
    this.setState({notedTerminalTypeOptions});
  }

  render() {
    const selectedOption = this.state.notedTerminalTypeOptions.find(item => item.value === this.state.notedTerminalTypeId);
    const notedTerminalObj = this.props.notedTerminalTypeList.find(item => item.id === this.props.terminal.notedTerminalTypeId);
    const notedTerminalLabel = notedTerminalObj ? notedTerminalObj.name : "notedTerminalName is not found";
    const commentUserObj = this.props.usersList.find(item => item.id === this.props.terminal.commentUserId);
    const commentUserName = commentUserObj ? commentUserObj.name : "User name is not found!";
    const linkToOrgSettings = orgPath(this.props.organizationId) + orgSettingsSuffix + editOrganizationSettingsSuffix;
    const isModalActionBtnDisabled = !(this.state.notedTerminalTypeId || this.state.comment);
    const modalFullWidthBtn = isModalActionBtnDisabled ? null :
      <ModalDeleteBtn onClick={this.handleModalDeleteBtnClick} addClasses={"mb_2du"}>{text.deleteBtnLabel}</ModalDeleteBtn>;
    return (
      <>
        <div className="GatesParamsCardComment">
          {this.props.terminal.comment || this.props.terminal.notedTerminalTypeId ?
            <div className={"GatesParamsCardComment__title"}>
              <div className="GatesParamsCardComment__ico mr_1du"/>
              <Text1 addClasses={"Text1_italic Text1_lh20 Text1_card-label-color Text1_underline-and-link"}
                     onClick={this.showModal}>
                {notedTerminalObj ? notedTerminalLabel : text.gatesParamsCardComment.commentLabel}</Text1>
              <div className="GatesParamsCardComment__tooltip">
                <div className="flex_jc-sb">
                  <Heading4 addClasses={"Heading4_lh20 Heading4_fsi mb_1du"}>
                    {notedTerminalObj ? notedTerminalLabel : text.gatesParamsCardComment.commentLabel}</Heading4>
                  <ConfirmActionModal addClasses={""} initActionBtn={
                    <BtnEditAct addClasses={"BtnEditAct_delete BtnEditAct_lh20 mb_1du"}>{text.deleteBtnLabel}</BtnEditAct>
                  }
                                      onModalActionBtnClick={this.handleDeleteBtnClick}
                                      >
                    <NHeading1 addClasses={"mt_2du mb_2du ta_c"}>{text.confirmDeleteCommentAndNotedType}</NHeading1>
                  </ConfirmActionModal>
                </div>
                {this.props.terminal.comment ? (
                  <Text1 addClasses={"Text1_italic Text1_lh20"}>{this.props.terminal.comment}</Text1>
                ) : null}
                <div className="flex_jc-sb">
                  {commentUserObj ? (
                    <Text1 addClasses={"Text1_italic Text1_lh20 Text1_card-label-color mt_1du"}>{commentUserName}</Text1>
                  ) :null}
                  {this.props.terminal.commentDate ? (
                    <Text1 addClasses={"Text1_italic Text1_lh20 Text1_card-label-color mt_1du"}>{formatDate(this.props.terminal.commentDate)}</Text1>
                  ) :null}
                </div>
                <div className="GatesParamsCardComment__tooltipAngle"/>
              </div>
            </div>
            :
            <div className="GatesParamsCardComment__addBtn" onClick={this.showModal}/>
          }
        </div>
        {this.state.modalIsShown ? (
          <NModal
            addClasses='NModal_ov'
            isModalDocumentEdit={true}
            modalTitle={text.gatesParamsCardComment.modalTitle}
            modalCancelBtnText={text.gatesParamsCardComment.modalCancelBtnText}
            modalActionBtnText={text.gatesParamsCardComment.modalActionBtnText}
            onModalCancelBtnClick={this.onCancelBtnClick}
            onModalActionBtnClick={this.onSaveBtnClick}
            isModalActionBtnDisabled={isModalActionBtnDisabled}
            modalFullWidthBtn={modalFullWidthBtn}
          >
            <NFormGroup label={text.gatesParamsCardComment.notedTerminalTypeIdLabel} addClasses={'mb_3du'}>
              <NSelect value={selectedOption}
                       placeholder={text.gatesParamsCardComment.notedTerminalTypeIdLabelPlaceholder}
                       isSearchable={true}
                       isClearable={true}
                       onChange={this.handleNotedTerminalTypeChange}
                       options={this.state.notedTerminalTypeOptions}
                       // noOptionsMessage={()=>text.gatesParamsCardComment.notedTerminalTypeIdNoOptions}
                       noOptionsMessage={()=> {
                          return <div className={"flex"}>
                            {text.gatesParamsCardComment.notedTerminalTypeIdNoOptions}
                            <Link to={linkToOrgSettings} className={"ml_1du"} target={"_blank"} rel={"nofollow noopener"}>
                              {text.gatesParamsCardComment.notedTerminalTypeIdNoOptionsLinkLabel}</Link>
                          </div>
                        }
                       }
              />
            </NFormGroup>
            <NFormGroup label={text.gatesParamsCardComment.commentLabel} addClasses={'mb_3du'}>
              <Textarea value={this.state.comment} onChange={this.handleCommentChange}/>
            </NFormGroup>
          </NModal>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    organizationId: state.organization.organizationId,
    notedTerminalTypeList: state.organization.organizationData.statuses.notedTerminalTypeList,
    usersList: state.organization.organizationData.usersList
  };
};
export default connect(mapStateToProps, null)(GatesParamsCardComment);