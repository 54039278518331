//@flow
import * as React from 'react';
import connect from "react-redux/es/connect/connect";
import text from "../../../../../../services/localization/text";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import SectionHeader from "../../../../../../components/composed/SectionHeader/SectionHeader";
import AgentMainDataFormGroup from "./agentMainDataEdit/AgentMainDataFormGroup";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {history, isReadyToRender, locationType, match} from "../../../../../../services/flowTypes/appFlowTypes";
import type {selectOptions, validationFormIsSet} from "../../../../../../services/flowTypes/componentFlowTypes";
import type {
  agentStatus,
  agentStatusList,
  isNotCompliedWithPaynetParams,
  tagsList
} from "../../../../../../services/flowTypes/dataFlowTypes";
import AgentMainDataParamsEdit from "./agentMainDataEdit/AgentMainDataParamsEdit";
import {agentEditPageIds} from "../../../../../../tests/testIds";
import createOption from "../../../../../../utils/createOption";
import SectionDivider from "../../../../../../components/simple/Containers/SectionDivider/SectionDivider";
import ItemDataValidationSet from "../../../../../../components/service/ItemDataValidationSet";
import AlertPaynetSync from "../../../../../../components/simple/Containers/AlertCard/AlertPaynetSync";

type Props = {
  tagsList: tagsList,
  status: agentStatus,
  history: history,
  match: match,
  agentStatusList: agentStatusList,
  location: locationType,
  isNotCompliedWithPaynetParams: isNotCompliedWithPaynetParams
};
type State = {
  isReadyToRender: isReadyToRender,
  tagsOptions: selectOptions,
  validationFormIsSet: validationFormIsSet,
  agentStatusOptions: selectOptions
};
class AgentMainDataEdit extends React.Component <Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      tagsOptions: [],
      validationFormIsSet: false,
      agentStatusOptions: []
    };
  }
  setValidationFormIsSet = () => {
    this.setState({validationFormIsSet: true});
  };
  componentDidMount(){
    const tagsOptions = this.props.tagsList.map(item =>{
      return createOption(item);
    });
    const agentStatusOptions = this.props.agentStatusList.map(item => {
      return {value: item, label: text.agent.status[item], statusClassName: `StatusLabel StatusLabel_${item}`};
    });
    this.setState({
      tagsOptions,
      agentStatusOptions,
      isReadyToRender: true
    });
  }
  render(){
    const agentId = this.props.match.params.id ? +this.props.match.params.id : null;
    const organizationId = +this.props.match.params.organizationId;
    const isReadyToRender = this.state.isReadyToRender && this.state.validationFormIsSet;
    return (
      <>
        <ItemDataValidationSet setValidationFormIsSet={this.setValidationFormIsSet} propItemId={agentId}
                               itemMainDataType={'agentMainData'}/>
        <RenderOnReady isReadyToRender={isReadyToRender}>
          <div className={"AgentMainDataEdit"}>
            <NCard>
              {this.props.isNotCompliedWithPaynetParams ? (
                <AlertPaynetSync addClasses={"mb_3du"}/>
              ) : null}
              <div className="flex_jc-sb mb_3du">
                <AgentMainDataFormGroup propName={"name"} label={text.name} formFieldType={'input'}
                                        addClasses={'w_half-card'} idForTest={agentEditPageIds.inputName}/>
                {this.props.status ? (
                  <AgentMainDataFormGroup propName={"status"} label={text.status} formFieldType={'select'}
                                          options={this.state.agentStatusOptions} addClasses={'w_half-card'}
                                          idForTest={agentEditPageIds.selectStatus}/>
                ) : null}
              </div>
              {this.props.status === 'INACTIVE' ? (
                <div className="flex_jc-sb mb_3du">
                  <AgentMainDataFormGroup propName={"statusDate"} label={text.terminationDate} formFieldType={'date'}
                                          addClasses={'w_quarter-card'} idForTest={agentEditPageIds.dateInputStatus}
                                          openDirection={'down'}/>
                  <AgentMainDataFormGroup propName={"statusReason"} label={text.terminationReason} formFieldType={'input'}
                                          addClasses={'w_three-quarter-card'} idForTest={agentEditPageIds.inputStatusReason}/>
                </div>
              ) : null}
              <AgentMainDataFormGroup propName={"tags"} label={text.tags} formFieldType={'tagSelect'}
                                      options={this.state.tagsOptions} addClasses={'mb_3du'}
                                      placeholder={text.tagsPlaceholder}
                                      idForTest={agentEditPageIds.inputTags}/>

              <SectionDivider addClasses={'mt_5du mb_5du'}/>
              <SectionHeader sectionHeaderTitle={text.header1c} addClasses={"mb_3du"}/>
              <AgentMainDataFormGroup propName={"agentCode"} label={text.agentCode} formFieldType={'input'}
                                      addClasses={'w_half-card mb_3du'} idForTest={agentEditPageIds.inputAgentCode}/>
              <AgentMainDataFormGroup propName={"inn1c"} label={text.inn1c} formFieldType={'input'}
                                      addClasses={'w_half-card mb_3du'} idForTest={agentEditPageIds.inputInn1c}/>
              <AgentMainDataFormGroup propName={"name1c"} label={text.name1c} formFieldType={'input'}
                                      addClasses={'w_half-card mb_3du'} idForTest={agentEditPageIds.inputName1c}/>

              <AgentMainDataParamsEdit organizationId={organizationId}/>
            </NCard>
          </div>
        </RenderOnReady>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    status: state.organization.item.agentMainData.status,
    isNotCompliedWithPaynetParams: state.organization.item.agentMainData.isNotCompliedWithPaynetParams,
    tagsList: state.organization.organizationData.tagsList,
    agentStatusList: state.organization.organizationData.statuses.agentStatusList
  };
};

export default connect(mapStateToProps, null)(AgentMainDataEdit);
