//@flow
import searchDataActions from './searchDataActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {searchData} from "../../../services/flowTypes/dataFlowTypes";

type State = searchData;
type reducerAction = action;
const initialState = {
  agents: null,
  agentsRelated: [],
  contracts: null,
  contractsRelated: [],
  totalCount: 0,
  tags: []
};

const searchDataReducer = (state:State = initialState, action:reducerAction) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case searchDataActions.SEARCH_DATA_INIT:
      return initialState;
    case searchDataActions.SEARCH_DATA_SET:
      newState = action.value;
      return newState;
    default:
      return state;
  }
};

export default searchDataReducer;