//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  actTemplate,
  actTemplates, organizationId
} from "../../../../../../services/flowTypes/dataFlowTypes";
import type {
  errorObject,
  history,
  isReadyToRender, locationType, match,
  modalIsShown
} from "../../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import {onInitActTemplatesView, onSetActTemplatesView} from "../../../../../../services/flowTypes/propFnFlowTypes";
import actTemplatesViewActions
  from "../../../../../../store/organization/item/actTemplatesView/actTemplatesViewActions";
import api from "../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import GroupFinTermsViewFetcher from "./actTemplateView/GroupFinTermsViewFetcher/GroupFinTermsViewFetcher";
import ActTemplateCard from "./actTemplateView/ActTemplateCard/ActTemplateCard";
import DeleteActTemplateModal from "./actTemplateView/DeleteActTemplateModal/DeleteActTemplateModal";
import GetDataOnMountAndUpdate
  from "../../../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import handleCorrectOrgIdInResponse from "../../../../../../utils/handleCorrectOrgIdInResponse";
import RedirectToCorrectOrgUrl
  from "../../../../../../components/service/RedirectToCorrectOrgUrl/RedirectToCorrectOrgUrl";
import AddServicesFetcher from "./actTemplateView/AddServicesFetcher/AddServicesFetcher";
import EmptyListV2 from "../../../../../../components/simple/Containers/EmptyList/EmptyListV2";

type Props = {
  actTemplatesView: actTemplates,
  match: match,
  onInitActTemplatesView: onInitActTemplatesView,
  onSetActTemplatesView: onSetActTemplatesView,
  history: history,
  location: locationType
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  deleteModalIsShown: modalIsShown,
  actTemplateDelete: actTemplate,
  correctOrganizationId: organizationId
};

class ActTemplatesView extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      deleteModalIsShown: false,
      actTemplateDelete: null,
      correctOrganizationId: null
    };
  }
  onBtnDelClick = (actTemplateDelete) => {
    this.setState({deleteModalIsShown: true, actTemplateDelete});
  };
  hideDeleteModal = () => {
    this.setState({
      deleteModalIsShown: false,
      actTemplateDelete: null
    });
  };
  handleResponse = (responseData) => {
    this.props.onSetActTemplatesView(responseData);
    this.setState({isReadyToRender: true});
  };
  getActTemplates = () => {
    if (this.state.isReadyToRender) {
      this.setState({isReadyToRender: false});
    }
    const contractId = +this.props.match.params.id;
    api.getActTemplates(contractId)
      .then(response => {
        handleCorrectOrgIdInResponse(this, response.data, this.setState, this.handleResponse);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  getActTemplatesCards = () => {
    const contractId = +this.props.match.params.id;
    return this.props.actTemplatesView.map((actTemplate, i)=> {
      const onBtnDelClick = () => this.onBtnDelClick(actTemplate);
      return (<ActTemplateCard key={'ActTemplateCard'+i} onBtnDelClick={onBtnDelClick} actTemplate={actTemplate}
                               actTemplateIndex={i} history={this.props.history}
                               contractId={contractId}/>)
    });
  };
  componentDidMount(){
    this.props.onInitActTemplatesView();
  }
  render() {
    const contractId = +this.props.match.params.id;
    return (
      <RedirectToCorrectOrgUrl correctOrganizationId={this.state.correctOrganizationId} history={this.props.history}>
        <GroupFinTermsViewFetcher contractId={contractId} >
          <AddServicesFetcher contractId={contractId}>
            <GetDataOnMountAndUpdate itemId={contractId} getDataOnMountAndUpdate={this.getActTemplates}>
              <div className="ActTemplatesView">
                <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
                {this.props.actTemplatesView === null ? null :
                  this.props.actTemplatesView && this.props.actTemplatesView.length ? this.getActTemplatesCards() : (
                  <EmptyListV2/>
                )}
                {this.state.deleteModalIsShown ? (
                  <DeleteActTemplateModal cancelModal={this.hideDeleteModal} actTemplateDelete={this.state.actTemplateDelete}
                                          getActTemplates={this.getActTemplates}/>
                ) : null}
              </div>
            </GetDataOnMountAndUpdate>
          </AddServicesFetcher>
        </GroupFinTermsViewFetcher>
      </RedirectToCorrectOrgUrl>
    );
  }
}

const mapStateToProps = state => {
  return {
    actTemplatesView: state.organization.item.actTemplatesView
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitActTemplatesView: () => dispatch({type: actTemplatesViewActions.ACT_TEMPLATES_VIEW_INIT}),
    onSetActTemplatesView: (actTemplates) => dispatch({
      type: actTemplatesViewActions.ACT_TEMPLATES_VIEW_SET, value: actTemplates})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActTemplatesView);    