//@flow
import * as React from 'react';
import {connect} from "react-redux";
import text from "../../../../../../../../../services/localization/text";
import type {
  configFinTermParams, contractId,
  finTermsLength,
  groupFinTermType
} from "../../../../../../../../../services/flowTypes/dataFlowTypes";
import {
  onAddFinTerm
} from "../../../../../../../../../services/flowTypes/propFnFlowTypes";
import finTermsEditActions from "../../../../../../../../../store/organization/item/finTermsEdit/finTermsEditActions";
import FullSizeButton from "../../../../../../../../../components/simple/Buttons/FullSizeButton/FullSizeButton";
import FinTerm from "./FinTerm/FinTerm";
import type {errorObject, isReadyToRender} from "../../../../../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import {groupFinTermEditPageIds} from "../../../../../../../../../tests/testIds";
import getUniqueKey from "../../../../../../../../../utils/getUniqueKey";
import {groupFinTermTypeHasMinRateArr} from "../../../../../../../../../configData/groupFinTermTypesFeatures";
import FinTermsLengthValidator from "./FinTermsLengthValidator";
import QuestionTooltip from "../../../../../../../../../components/simple/Containers/QuestionTooltip/QuestionTooltip";
import addAgreementsActions
  from "../../../../../../../../../store/organization/item/addAgreements/addAgreementsActions";
import type {
  onGetAddAgreements,
  onInitAddAgreements
} from "../../../../../../../../../services/flowTypes/propFnFlowTypes";
import GetDataOnMountAndUpdate
  from "../../../../../../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import api from "../../../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../../../services/apiInstance/apiErrorHandler";

type Props = {
  finTermsLength: finTermsLength,
  onAddFinTerm: onAddFinTerm,
  type: groupFinTermType,
  configFinTermParams: configFinTermParams,
  contractId: contractId,
  readonly?: boolean,
  onGetAddAgreements: onGetAddAgreements,
  onInitAddAgreements: onInitAddAgreements
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject
};

class FinTermsEdit extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null
    };
  }
  addFinTerm = () => {
    const paramsRow = this.props.configFinTermParams[this.props.type];
    const newFinTerm = {
      startDate: '',
      endDate: '',
      addAgreementId: '',
      params: [paramsRow]
    };
    if (groupFinTermTypeHasMinRateArr.includes(this.props.type)) {
      newFinTerm.minRate = '';
    }
    this.props.onAddFinTerm(newFinTerm);
  };
  getAddAgreements = () => {
    this.props.onInitAddAgreements();
    if (this.props.contractId) {
      api.getAddAgreements(this.props.contractId, '')
        .then(response => {
          this.props.onGetAddAgreements(response.data);
          this.setState({isReadyToRender: true});
        })
        .catch(error => {
          this.setState({
            isReadyToRender: true,
            errorObject: apiErrorHandler(error)
          });
        });
    }
  };
  render() {
    let finTerms = [];
    for (let i=0; i < this.props.finTermsLength; i++ ) {
      finTerms.push(
        <FinTerm readonly={this.props.readonly} key={getUniqueKey()} finTermIndex={i}
                 contractId={this.props.contractId}
        />
      );
    }
    return (
      <GetDataOnMountAndUpdate itemId={this.props.contractId} getDataOnMountAndUpdate={this.getAddAgreements}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
          <div className="FinTermsEdit">
            {this.props.type.length && !this.props.readonly ? (
              <FinTermsLengthValidator>
                <FullSizeButton id={groupFinTermEditPageIds.buttonAddFinTerm} addClasses={'mb_1du'}
                                onClick={this.addFinTerm}>
                  <div className="flex jc_c">
                    {text.addFinTerm}
                    <QuestionTooltip addClasses={'ml_1du'}>{text.addFinTermTooltip}</QuestionTooltip>
                  </div>

                </FullSizeButton>
              </FinTermsLengthValidator>
            ) : null}
            {finTerms}

          </div>
        </RenderOnReady>
      </GetDataOnMountAndUpdate>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    finTermsLength: state.organization.item.finTermsEdit.length,
    type: state.organization.item.groupFinTermsEdit.type,
    configFinTermParams: state.organization.organizationData.configFinTerms.configFinTermParams,
    contractId: state.organization.item.contractMainData.id
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onAddFinTerm: (newFinTerm) => dispatch({type: finTermsEditActions.FIN_TERM_ADD, value: newFinTerm}),
    onGetAddAgreements: (addAgreements) => dispatch({
      type: addAgreementsActions.ADD_AGREEMENTS_SET, value: addAgreements}),
    onInitAddAgreements: () => dispatch({type: addAgreementsActions.ADD_AGREEMENTS_INIT}),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FinTermsEdit);