//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  onDeleteOrganizationsSearchProp,
  onUpdateOrganizationsSearchProp
} from "../../../../services/flowTypes/propFnFlowTypes";
import type {
  organizationsSearch,
  organizationStatusList
} from "../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../services/localization/text";
import type {selectOptions} from "../../../../services/flowTypes/componentFlowTypes";
import Filters from "../../../../components/composed/Filters/Filters";
import organizationsSearchActionTypes
  from "../../../../store/organizations/organizationsSearch/organizationsSearchActions";

type Props = {
  onUpdateOrganizationsSearchProp: onUpdateOrganizationsSearchProp,
  organizationStatusList: organizationStatusList,
  onDeleteOrganizationsSearchProp: onDeleteOrganizationsSearchProp,
  search: organizationsSearch
};
type State = {
  statusOptions: selectOptions
};

class OrganizationsFilters extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      statusOptions: []
    };
  }
  handleOnGetOrganizationsFiltersPropCall = (filtersProps) => {
    const organizationsFiltersProps = {...filtersProps};
    if (!organizationsFiltersProps.hasOwnProperty('offset')) {
      organizationsFiltersProps.offset = 0;
    }
    this.props.onUpdateOrganizationsSearchProp(organizationsFiltersProps);
  };
  getFiltersList = (allFiltersList) => {
    const filtersList = [];
    const filtersForOptions = [];
    for (let i=0; i < allFiltersList.length; i++) {
      const condition = this.props.search.hasOwnProperty(allFiltersList[i].propName);
      if (condition) {
        filtersList.push(allFiltersList[i]);
      } else {
        filtersForOptions.push(allFiltersList[i]);
      }
    }
    return {filtersList, filtersForOptions};
  };
  componentDidMount() {
    const defaultOption =  {
      value: 'ALL',
      label: text.organizationStatus['ALL'],
      statusClassName: `StatusLabel StatusLabel_ALL`
    };
    const statusOptions = this.props.organizationStatusList.map(item => {
      return {
        value: item,
        label: text.organizationStatus[item],
        statusClassName: `StatusLabel StatusLabel_${item}`
      };
    });
    statusOptions.unshift(defaultOption);
    this.setState({statusOptions})
  }
  render() {
    const allFiltersList = [
      {propName: 'status', formFieldType: 'filter-select', options: this.state.statusOptions, initValue: 'ACTIVE'}
    ];
    const {filtersList, filtersForOptions} = this.getFiltersList(allFiltersList);
    const resetFilters = () => this.props.onDeleteOrganizationsSearchProp(['status']);
    return (
      <Filters onGetFiltersProp={this.handleOnGetOrganizationsFiltersPropCall}
               onDeleteFiltersProp={this.props.onDeleteOrganizationsSearchProp}
               filtersState={this.props.search}
               filtersForOptions={filtersForOptions}
               filtersList={filtersList}
               resetFilters={resetFilters}
               addClasses={'mt_2du'}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    organizationStatusList: state.admin.statuses.organizationStatusList,
    search: state.organizations.search
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateOrganizationsSearchProp: (organizationsSearchObj) => dispatch({
      type: organizationsSearchActionTypes.ORG_SEARCH_PROP_UPDATE,
      value: organizationsSearchObj}),
    onDeleteOrganizationsSearchProp: (organizationsPropNameArr) => dispatch({
      type: organizationsSearchActionTypes.ORG_SEARCH_PROP_DELETE,
      value: organizationsPropNameArr
    })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsFilters);
