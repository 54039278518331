//@flow
import * as React from 'react';
import './HoveredIcon.css';
import type {activeIconSrc, baseIconSrc, hoveredIconSrc} from "../../../services/flowTypes/appFlowTypes";

type Props = {
  baseIconSrc: baseIconSrc,
  hoveredIconSrc: hoveredIconSrc,
  activeIconSrc?: activeIconSrc
};

const HoveredIcon = (props: Props) => {
  return (
    <>
      <img className="button__icon" src={props.baseIconSrc} alt="icon"/>
      <img className="button__icon button__icon_hover" src={props.hoveredIconSrc} alt="icon hover"/>
      {props.activeIconSrc ? (
        <img className="button__icon button__icon_active" src={props.activeIconSrc} alt="icon active"/>
      ) : null}
    </>
  );
};

export default HoveredIcon;