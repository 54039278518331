//@flow
import * as React from 'react';
import {connect} from "react-redux";
import {
  onSetDataToValidationState,
  setValidationFormIsSet
} from "../../services/flowTypes/propFnFlowTypes";
import type {contract, contractId} from "../../services/flowTypes/dataFlowTypes";
import validationActions from "../../store/validation/validationActions";
import type {isToSetValidation} from "../../services/flowTypes/componentFlowTypes";
import CleanValidationStateOnUnmount
  from "./CleanValidationStateOnUnmount/CleanValidationStateOnUnmount";
import type {agent, agentId} from "../../services/flowTypes/dataFlowTypes";
import type {itemMainDataType} from "../../services/flowTypes/componentFlowTypes";
import RenderOnReady from "./RenderOnReady/RenderOnReady";

type Props = {
  setValidationFormIsSet: setValidationFormIsSet,
  itemData: contract | agent,
  onSetDataToValidationState: onSetDataToValidationState,
  propItemId: contractId | agentId,
  itemMainDataType: itemMainDataType
};
type State = {
  isToSetValidation: isToSetValidation
};

class ItemDataValidationSet extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isToSetValidation: false
    };
  }
  defineIsToSetValidation = () => {
    return this.props.propItemId ? (this.props.propItemId === this.props.itemData.id) : true;
  };
  setValidation = () => {
    this.setState({isToSetValidation: true});
    this.props.onSetDataToValidationState(this.props.itemData);
    this.props.setValidationFormIsSet();
  };
  componentDidUpdate(){
    if (!this.state.isToSetValidation && this.defineIsToSetValidation()) {
      this.setValidation();
    }
  }
  componentDidMount(){
    if (this.defineIsToSetValidation()) {
      this.setValidation();
    }
  }
  render() {
    return (
      <>
        <RenderOnReady isReadyToRender={true}/>
        <CleanValidationStateOnUnmount/>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    itemData: state.organization.item[ownProps.itemMainDataType]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetDataToValidationState: (data) => dispatch({
      type: validationActions.VALIDATION_STATE_SET,
      value: data})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemDataValidationSet);