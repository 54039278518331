//@flow
import * as React from 'react';
import DataListContainer
  from "../../../../../../../../components/simple/Containers/DataListContainer/DataListContainer";
import TagWithClickToCopy
  from "../../../../../../../../components/simple/TextComponents/TagWithClickToCopy/TagWithClickToCopy";
import {actTemplatesEditPageIds} from "../../../../../../../../tests/testIds";
import type {selectOptions} from "../../../../../../../../services/flowTypes/componentFlowTypes";
import Heading5 from "../../../../../../../../components/simple/TextComponents/Heading5/Heading5";
import "./ReportTagExpressionConstructor.css";
import FullSizeButton from "../../../../../../../../components/simple/Buttons/FullSizeButton/FullSizeButton";
import type {reportTagExpression} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import {baseClass} from "../../../../../../../UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../../../../../../services/flowTypes/uiKitFlowTypes";
import ReportTagExpressionRow from "./ReportTagExpressionRow";
import update from 'immutability-helper';
import {operationTypePlusDefValue} from "../ReportTagsExpressions";
import DragAndDrop from "../../../../../../../../components/composed/DragAndDrop/DragAndDrop";
import ReportTagExpressionTextarea from "./ReportTagExpressionTextarea";
import BtnDel from "../../../../../../../../components/simple/Buttons/BtnDel/BtnDel";
import BtnEdit from "../../../../../../../../components/simple/Buttons/BtnEdit/BtnEdit";
import SimplifiedAlertWarning
  from "../../../../../../../../components/simple/Containers/AlertCard/SimplifiedAlertWarning";
import text from "../../../../../../../../services/localization/text";

type Props = {
  reportTagExpression: reportTagExpression,
  readonly: boolean,
  currency: string,
  expressionIndex: number,
  updateReportTagExpression?: Function,
  updateReportTotalExpression?: Function,
  addClasses: addClasses,
  operationTypeSelectOptions: selectOptions,
  operationLabelSelectOptions: selectOptions,
  finTermTagsList: Array<string>,
  finTermTagsSelectOptions: selectOptions,
  tagExpSelectOptions?: selectOptions,
  deleteReportTagExpression?: Function
};
type State = {
  isTextareaShown: boolean
};
const getFinTermNumbersFromExpression = (expressionPart) => {
  const matchedFinTermNumbers = expressionPart.match(/\d+(_\w{0,3})?(?=_)/g);
  return matchedFinTermNumbers ? matchedFinTermNumbers : [];
};

class ReportTagExpressionConstructor extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isTextareaShown: false
    };
  }
  update = (updatedExpression) => {
    const trimmedExpression = updatedExpression.replace(/\s{2,}/g, " ");
    const updatedReportTagExpression = {...this.props.reportTagExpression, expression: trimmedExpression};
    if (this.props.reportTagExpression.propName) {
      this.props.updateReportTotalExpression(this.props.reportTagExpression.propName, updatedReportTagExpression);
    } else {
      this.props.updateReportTagExpression(this.props.expressionIndex, updatedReportTagExpression);
    }
  };
  toggleTextarea = () => {this.setState({isTextareaShown: !this.state.isTextareaShown})};
  handleDeleteExpressionClick = () => {this.props.deleteReportTagExpression(this.props.expressionIndex);};
  setNewOrder = (rows) => {
    const updatedRows = [...rows];
    const updatedExpression = this.constructExpression(updatedRows);
    this.update(updatedExpression);
  };
  handleAddRow = () => {
    const {expression} = this.props.reportTagExpression;
    const updatedExpression = /[+\-*]/.test(expression) && !/\)\s*$/.test(expression)  ?
      "(" + expression + ")" + operationTypePlusDefValue
      : expression + operationTypePlusDefValue;
    this.update(updatedExpression);
  };
  deleteRow = (rowIndex) => {
    const rows = this.deconstructExpression(this.props.reportTagExpression.expression);
    const updatedRows = update(rows, {$splice: [[rowIndex, 1]]});
    const updatedExpression = this.constructExpression(updatedRows);
    this.update(updatedExpression);
  };
  updateExpression = (rowIndex, expressionPart) => {
    const rows = this.deconstructExpression(this.props.reportTagExpression.expression);
    const updatedRows = update(rows, {[rowIndex]: {$set: expressionPart}});
    const updatedExpression = this.constructExpression(updatedRows);
    this.update(updatedExpression);
  };
  constructExpression = (rows) => {
    let expression = "";
    for (let i=0; i < rows.length; i++) {
      if (i===0) {
        const isConsecutiveSum = /^\s*Σ/.test(rows[i]) || /\w+\s*\+\s*\w+/.test(rows[i]);
        expression = isConsecutiveSum ? "(" + rows[i] + ")" : rows[i];
      } else if (i===1){
        expression = expression + rows[i];
      } else {
        expression = "(" + expression + ")" + rows[i];
      }
    }
    return expression;
  };
  isEqualNumberOfBrackets = (expr) => {
    const errorStr = text.reportTagExpressionsError.unequalBracketsNumber;
    const openBracketMatch = expr.match(/\(/g);
    const closeBracketMatch = expr.match(/\)/g);
    if (openBracketMatch === null && closeBracketMatch === null) {
      return "";
    } else if ( (openBracketMatch !== null && closeBracketMatch === null) || (openBracketMatch === null && closeBracketMatch !== null) ) {
      return errorStr;
    } else {
      return openBracketMatch.length === closeBracketMatch.length ? "" : errorStr;
    }
  };
  isMoreThanOneParenthesisFolded = (expr) => {
    // return (/\((.*\(.*\).*){2,}\)/).test(expr) ? text.reportTagExpressionsError.moreThanOneParenthesis : "";
    return (/\(.*?\(.+?\).+?\(/).test(expr) ? text.reportTagExpressionsError.moreThanOneParenthesis : "";
  };
  isParenthesisIsNotAtStart = (expr) => {
    return (/[^(\s].*\(/).test(expr) ? text.reportTagExpressionsError.parenthesisIsNotAfterOpenBracket : "";
  };
  isFirstOrderComputationIsNotAtStart = (expr) => {
    return (/(-|\+).*\*/).test(expr) ? text.reportTagExpressionsError.firstOrderComputationIsNotAfterOpenBracket : "";
  };
  isNumbersNotInPlace = (expr) => {
    // return (/(^(\s*\(*)*\d)|(\s*[+-]\s*\d)/).test(expr) ? text.reportTagExpressionsError.numberIsInUnsupportedOperation : "";
    return (/(\(|[+-]|^)\s*\d/).test(expr) ? text.reportTagExpressionsError.numberIsInUnsupportedOperation : "";
  };
  // isMultipliedByTag = (expr) => {
  //   return (/\*\s*[A-Za-z]/).test(expr) ? text.reportTagExpressionsError.tagIsMultiplier : "";
  // };
  isMultipliedByNotHandledNumber = (expr) => {
    return (/(\*)(?!((\s*\d*\.?\d*\/100)|(\s\D+)))/).test(expr) ? text.reportTagExpressionsError.wrongNumberMultiplier : "";
  };
  splitPartOfRows = (str) => {
    return str.split(/\s*(?=[*+\-;])/)
      .map(item => {
        // случай когда используются функции типа + func('123')
        const reg = /(\s*[ *+-]*\s*\w+\(['"]\d*\.?\d*['"]\))/g
        const match = item.match(reg)
        if (match && match[0]) {
          return match[0]
        } else {
          return item.replaceAll("(", "").replaceAll(")", "").trim()
        }
      });
  }
  deconstructExpression = (expression) => {
    // const expression = "FT1_rateAmount + FT3_USD_rateAmount + FT4_rateAmount + (FT3_USD_rateAmount - FT1_totalAmount) + FT1_txCount - (FT2_rateAmount - FT4_rateAmount)";  //!!!!!! how to handle this case????
    // const expression = "(FT2_rateAmount * 20 - FT3_USD_rateAmount)";
    // const expression = "(((FT1_rateAmount + FT2_rateAmount) - FT3_USD_rateAmount) +FT4_rateAmount) * 0.02/100";
    // const expression = "((((FT1_rateAmount + FT2_rateAmount) - FT3_USD_rateAmount) +FT4_rateAmount) * 0.02/100)";
    // const expression = "(FT1_totalAmount - ((FT1_rateAmount - FT2_rateAmount) + (FT3_rateAmount+ FT4_rateAmount)) -FT1_txCount)";
    // const expression = "FT1_totalAmount + FT3_totalAmount + FT4_totalAmount + FT5_totalAmount + FT7_totalAmount " +
    //   "+ FT9_totalAmount + FT10_totalAmount + FT12_totalAmount + FT13_totalAmount";
    // const expression = "(((FT1_txCount - FT2_rateAmount)))";
    // const expression = "(((FT1_txCount - FT2_rateAmount)))";
    const expressionToCheck = expression.replaceAll(/[+-]?\s*\w+\(['"]\d*\.?\d*['"]\)/g, '')
    const numberIsNotInPlaceError = this.isNumbersNotInPlace(expressionToCheck);
    if (numberIsNotInPlaceError) {
      return numberIsNotInPlaceError;
    }
    const unequalBracketsNumberError = this.isEqualNumberOfBrackets(expression);
    if (unequalBracketsNumberError) {
      return unequalBracketsNumberError;
    }
    // const tagCantBeMultiplierError = this.isMultipliedByTag(expression);
    // if (tagCantBeMultiplierError) {
    //   return tagCantBeMultiplierError;
    // }
    const multiplierError = this.isMultipliedByNotHandledNumber(expression);
    if (multiplierError) {
      return multiplierError;
    }

    if (expression === "") {
      return [""]
    } else {
      if (expression.match(/\(|\)/g) === null) {
        const firstOrderComputationIsNotAtStartError = this.isFirstOrderComputationIsNotAtStart(expressionToCheck);
        if (firstOrderComputationIsNotAtStartError) {
          return firstOrderComputationIsNotAtStartError;
        }
        return this.splitPartOfRows(expression);
      } else {
        const moreThanOneParenthesisFoldedError = this.isMoreThanOneParenthesisFolded(expressionToCheck);
        if (moreThanOneParenthesisFoldedError) {
          return moreThanOneParenthesisFoldedError;
        }
        const parenthesisIsNotAtStartError = this.isParenthesisIsNotAtStart(expressionToCheck);
        if (parenthesisIsNotAtStartError) {
          return parenthesisIsNotAtStartError;
        }
        return this.splitPartOfRows(expression);
      }
    }
  };
  render(): React$Node {
    const tagToCopy = this.props.reportTagExpression.tag ? this.props.reportTagExpression.tag
      : '${' + this.props.reportTagExpression.name + '}';
    const isEditHidden = true;
    const header = (
      <div className="">
        <div className="flex_jc-sb">
          <Heading5 addClasses={''}>
            {this.props.reportTagExpression.name}
          </Heading5>
          <div className="flex">
            <TagWithClickToCopy id={actTemplatesEditPageIds.buttonToCopyTag(this.props.reportTagExpression.name)}
                                addClasses={"TagToCopy_fs14 TagToCopy_fwn"}>
              {tagToCopy}</TagWithClickToCopy>
            {isEditHidden ? null : (
              <BtnEdit addClasses={"ml_2du"} isTagExpressionHeaderMode={true} onClick={this.toggleTextarea}
                       isDropdownShown={this.state.isTextareaShown}/>
            )}
            {this.props.deleteReportTagExpression && !this.props.readonly ? (
              <BtnDel addClasses={"ml_2du"} isTagExpressionHeaderMode={true} onClick={this.handleDeleteExpressionClick}/>
            ) : null}
          </div>
        </div>
        <ReportTagExpressionTextarea isTextareaShown={this.state.isTextareaShown}
                                     reportTagExpression={this.props.reportTagExpression}
                                     expressionIndex={this.props.expressionIndex}
                                     update={this.update}
                                     disabled={this.props.readonly}
                                     />
        {this.props.reportTagExpression.warningComponent ? this.props.reportTagExpression.warningComponent : null}
      </div>
    );

    const rows = this.deconstructExpression(this.props.reportTagExpression.expression);
    const reportTagExpressionRows = typeof rows === 'string' ?
      <SimplifiedAlertWarning alertTypeClassNameSuffix={"ERROR"}>{rows}</SimplifiedAlertWarning>
      :
      rows.map((item, i) => {
        const filteredRows = rows.filter(rowsItem => rowsItem !== item);
        const finTermNumbersApplied = [];
        for (let j=0; j < filteredRows.length; j++) {
          finTermNumbersApplied.push(...getFinTermNumbersFromExpression(filteredRows[j]));
        }
        const finTermNumberToExclude = this.props.reportTagExpression.propName ? finTermNumbersApplied : [];
      return (<ReportTagExpressionRow key={'row'+i}
                                      updateExpression={this.updateExpression}
                                      expressionPart={item}
                                      rowIndex={i}
                                      currency={this.props.currency}
                                      operationTypeSelectOptions={this.props.operationTypeSelectOptions}
                                      operationLabelSelectOptions={this.props.operationLabelSelectOptions}
                                      finTermTagsList={this.props.finTermTagsList}
                                      finTermTagsSelectOptions={this.props.finTermTagsSelectOptions}
                                      tagExpSelectOptions={this.props.tagExpSelectOptions}
                                      deleteRow={this.deleteRow}
                                      readonly={this.props.readonly}
                                      finTermNumberToExclude={finTermNumberToExclude}
      />)
    });
    return (
      <div className={baseClass("ReportTagExpressionConstructor", this.props.addClasses)}>
        <DataListContainer itemsList={[header]} addClasses={"mb_2du"}/>
        {typeof rows === 'string' || this.props.readonly ? reportTagExpressionRows : (
          <DragAndDrop dragAndDropInitArr={rows} setNewOrder={this.setNewOrder}
                       dragAndDropItems={reportTagExpressionRows}/>
        )}
        {typeof rows === 'string' || this.props.readonly ? null : (
          <FullSizeButton addClasses={'FullSizeButton_h40 mt_1du'} onClick={this.handleAddRow}>
            {'+'}</FullSizeButton>
        )}
      </div>
    );
  }
}

export default ReportTagExpressionConstructor;    