//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {disabled, id, onClick} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './TurnOnOffBtn.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  isOn: boolean,
  disabled?: disabled,
  onClick: onClick
};

const TurnOnOffBtn = (props: Props) => {
  const className = props.isOn ? "TurnOnOffBtn TurnOnOffBtn_isOn" : "TurnOnOffBtn";
  return (
    <button type="button" id={props.id} onClick={props.onClick}
            disabled={props.disabled}
            className={baseClass(className, props.addClasses)}>
      <span className={"TurnOnOffBtn__circle"}/>
    </button>
  );
};

export default TurnOnOffBtn;