//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  actTemplateId,
  addServiceSheet, historyLocations
} from "../../../../../../services/flowTypes/dataFlowTypes";
import sheetsActions from "../../../../../../store/organization/item/sheets/sheetsActions";
import type {
  onGetValidationStateProp,
  onSetSheets
} from "../../../../../../services/flowTypes/propFnFlowTypes";
import type {history, match} from "../../../../../../services/flowTypes/appFlowTypes";
import getUniqueKey from "../../../../../../utils/getUniqueKey";
import Sheet from "../../../../../../components/composed/Sheet/Sheet";
import GetSheetsOnUnmount from "./addServiceEdit/GetSheetsOnUnmount";
import AddSheetButton from "../../../../../../components/composed/Sheet/AddSheetButton";
import validationActions from "../../../../../../store/validation/validationActions";
import ValidateAllDataOnMount from "./addServiceEdit/ValidateAllDataOnMount";
import {defineAddServiceEditLinks} from "./AddServiceAllData";
import GetActTemplates from "../ActTemplatesView/actTemplateView/GetActTemplates";

type Props = {
  sheetsLength: number,
  addServiceSheets: Array<addServiceSheet>,
  onSetSheets: onSetSheets,
  match: match,
  onGetValidationStateProp: onGetValidationStateProp,
  historyLocations: historyLocations,
  history: history,
  actTemplateId: actTemplateId
};

export const newCalculatedItem = {
  addServiceTypeId: '',
  price: '',
  number: '1',
  amount: ''
};
export const newSheet = {
  currency: '',
  totalAmount: '',
  calculatedItems: [newCalculatedItem]
};
export const getImmutableCopyOfSheets = (sheets) => {
  return sheets.map(item => {
    const newCalculatedItems = item.calculatedItems.map(calculatedItem => { return {...calculatedItem}});
    return {...item, calculatedItems: newCalculatedItems};
  });
};

class AddServiceSheetsEdit extends React.Component <Props> {
  defineIsUserCameFromPrevPages = () => {
    const lastElIndex = this.props.historyLocations.length - 1;
    return this.props.historyLocations[lastElIndex].pathname.includes('/new-add-service/type')
    || this.props.historyLocations[lastElIndex].pathname.includes('/new-add-service/doc');
  };
  componentDidMount(): * {
    if (this.defineIsUserCameFromPrevPages()) {
      this.props.onGetValidationStateProp('validationIsAllowed', false);
    }
    if (this.props.match.params.addServiceId) {
      this.props.onSetSheets(getImmutableCopyOfSheets(this.props.addServiceSheets));
    } else {
      if (this.props.addServiceSheets.length) {
        this.props.onSetSheets(getImmutableCopyOfSheets(this.props.addServiceSheets));
      } else {
        this.props.onSetSheets(getImmutableCopyOfSheets([newSheet]));
      }
    }
  }
  render() {
    let sheets = [];
    for (let i=0; i < this.props.sheetsLength; i++) {
      sheets.push(<Sheet key={getUniqueKey('sheet'+i)} sheetIndex={i}/>);
    }

    const {linkToTypeEdit} = defineAddServiceEditLinks(this.props.match.params);
    return (
      <GetActTemplates contractId={this.props.match.params.id}>
        <GetSheetsOnUnmount>
          <ValidateAllDataOnMount history={this.props.history} match={this.props.match}
                                  linkToTypeEdit={linkToTypeEdit}>
            {sheets}
          </ValidateAllDataOnMount>
        </GetSheetsOnUnmount>
        {this.props.actTemplateId ? null : (
          <AddSheetButton/>
        )}
      </GetActTemplates>
    ) ;
  }
}

const mapStateToProps = state => {
  return {
    sheetsLength: state.organization.item.sheets.length,
    addServiceSheets: state.organization.item.addServiceEdit.sheets,
    historyLocations: state.historyLocations,
    actTemplateId: state.organization.item.addServiceEdit.actTemplateId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSetSheets: (sheets) => dispatch({type: sheetsActions.SHEETS_SET, value: sheets}),
    onGetValidationStateProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddServiceSheetsEdit);    