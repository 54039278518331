//@flow
import * as React from 'react';
import {connect} from "react-redux";
import ConnectedFormGroup from "../../../../../../../../../../../components/composed/ConnectedFormGroup/ConnectedFormGroup";
import type {
  finTermIndex, inputIco, labelTooltip,
  propName,
  propValue
} from "../../../../../../../../../../../services/flowTypes/componentFlowTypes";
import type {isReadyToRender} from "../../../../../../../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import finTermsEditActions from "../../../../../../../../../../../store/organization/item/finTermsEdit/finTermsEditActions";
import {onUpdateFinTermProp} from "../../../../../../../../../../../services/flowTypes/propFnFlowTypes";
import text from "../../../../../../../../../../../services/localization/text";
import type {addClasses} from "../../../../../../../../../../../services/flowTypes/uiKitFlowTypes";
import {groupFinTermEditPageIds} from "../../../../../../../../../../../tests/testIds";
import PropValueView from "../../../../../../../../../../../components/composed/PropValueView/PropValueView";
import type {
  currency, groupFinTermType
} from "../../../../../../../../../../../services/flowTypes/dataFlowTypes";
import getMaskedInputValue from "../../../../../../../../../../../utils/getMaskedInputValue";
import getParamUnitByNameAndCurr from "../../../../../../../../../../../utils/getParamUnitByNameAndCurr";
import QuestionTooltip
  from "../../../../../../../../../../../components/simple/Containers/QuestionTooltip/QuestionTooltip";

type Props = {
  propValue: propValue,
  finTermIndex: finTermIndex,
  onUpdateFinTermProp: onUpdateFinTermProp,
  addClasses?: addClasses,
  currency: currency,
  readonly?: boolean,
  groupFinTermType: groupFinTermType
};
type State = {
  isReadyToRender: isReadyToRender,
  propName: propName,
  inputIco: inputIco,
  labelTooltip?: labelTooltip
};
class FinTermMinRate extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      propName: '',
      inputIco: null,
      labelTooltip: null
    };
  }
  onGetPropValue = (propName, propValue) => {
    const trimmedValue = propValue.replace(/\s/g, '');
    this.props.onUpdateFinTermProp(this.props.finTermIndex, 'minRate', trimmedValue);
  };
  setPropName = () => {
    return 'finTerm' + this.props.finTermIndex + 'minRate';
  };
  componentDidMount(){
    const labelTooltipText = text.finTermParamTooltip[this.props.groupFinTermType]['minRate'];
    const labelTooltip = labelTooltipText ? (
      <QuestionTooltip isHighZIndex={true}>{labelTooltipText}</QuestionTooltip>
    ) : null;
    this.setState({
      propName: this.setPropName(),
      inputIco: (<div className="FinTermParamIco">{getParamUnitByNameAndCurr('minRate', this.props.currency)}</div>),
      isReadyToRender: true,
      labelTooltip
    });
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={null}>
        <div className={this.props.readonly ? 'FinTermParamsRow flex_jc-sb mt_1du' : 'FinTermParamsRow flex mt_1du'}>
          {this.props.readonly ?
            <PropValueView label={text.finTermMinRate} value={this.props.propValue}/>
            :
            <ConnectedFormGroup
              formFieldType={'input'}
              propName={this.state.propName}
              propValue={getMaskedInputValue(this.props.propValue)}
              onGetPropValue={this.onGetPropValue}
              label={text.finTermMinRate}
              idForTest={groupFinTermEditPageIds.inputMinRate(this.props.finTermIndex)}
              inputIco={this.state.inputIco}
              labelTooltip={this.state.labelTooltip}
            />
          }
        </div>
      </RenderOnReady>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const minRate = state.organization.item.finTermsEdit[ownProps.finTermIndex].minRate;
  return {
    propValue: minRate ? minRate : '',
    currency: state.organization.item.groupFinTermsEdit.currency
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateFinTermProp: (finTermIndex, finTermPropName, propValue) => dispatch({
      type: finTermsEditActions.FIN_TERM_PROP_UPDATE,
      value: { finTermIndex , finTermPropName, propValue } })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinTermMinRate);