//@flow
import text from "../services/localization/text";
import {
  agentPathView,
  contractsSuffix,
  documentsSuffix,
  historySuffix,
  mainDataSuffix,
  orgPath
} from "../services/urlStrings/urlStrings";
import type {agentId as agentIdType, organizationId as organizationIdType} from "../services/flowTypes/dataFlowTypes";
import {permissionsStrValues} from "./permissionsStrValues";

const agentMenuItems = (organizationId:organizationIdType, agentId:agentIdType) => {
  const agentViewPath = orgPath(organizationId) + agentPathView(agentId);
  return [
    {
      linkTo: agentViewPath + mainDataSuffix,
      linkName: text.mainData,
      permittedAction: permissionsStrValues.agent.view
    },
    {
      linkTo: agentViewPath + contractsSuffix,
      linkName: text.relatedContracts,
      permittedAction: permissionsStrValues.agent.view
    },
    {
      linkTo: agentViewPath + documentsSuffix,
      linkName: text.documents,
      permittedAction: permissionsStrValues.agentDocument.view
    },
    {
      linkTo: agentViewPath + historySuffix,
      linkName: text.history,
      permittedAction: permissionsStrValues.agentHistory.view
    }
  ];
};

export default agentMenuItems;