//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  disabled,
  errorObject,
  isReadyToRender,
  modalIsShown
} from "../../../../../../services/flowTypes/appFlowTypes";
import Text1 from "../../../../../../components/simple/TextComponents/Text1/Text1";
import text from "../../../../../../services/localization/text";
import NModal from "../../../../../../components/simple/Modals/NModal/NModal";
import type {
  contractFinTermsList, contractId, gatesParams, gatesParamsResponse,
  groupFinTermId,
  groupFinTermName, groupFinTermSource, organizationId, terminal
} from "../../../../../../services/flowTypes/dataFlowTypes";
import Heading4 from "../../../../../../components/simple/TextComponents/Heading4/Heading4";
import Heading5 from "../../../../../../components/simple/TextComponents/Heading5/Heading5";
import clone from "../../../../../../utils/cloneObject";
import concatIdName from "../../../../../../utils/concatIdName";
import ConnectedGroupFinTermCard
  from "../../../../../Contracts/pages/Contract/pages/GroupFinTermsView/groupFinTermsView/GroupFinTermCard/ConnectedGroupFinTermCard";
import Checkbox from "../../../../../../components/simple/FormComponents/Checkbox/Checkbox";
import update from 'immutability-helper';
import TextOverflowHandler
  from "../../../../../../components/simple/Containers/TextOverflowHandler/TextOverflowHandler";
import groupFinTermsSearchActions
  from "../../../../../../store/organization/item/groupFinTermsSearch/groupFinTermsSearchActions";
import ContractFinTermsFetcher from "./ContractFinTermsFetcher";
import GroupFinTermsFilters
  from "../../../../../Contracts/pages/Contract/pages/GroupFinTermsView/groupFinTermsView/GroupFinTermsFilters";
import TextActionButton from "../../../../../../components/simple/Buttons/TextActionButton/TextActionButton";
import Text3 from "../../../../../../components/simple/TextComponents/Text3/Text3";
import ContentPlaceholder from "../../../../../../components/simple/Containers/ContentPlaceholder/ContentPlaceholder";
import {Link} from "react-router-dom";
import {contrPathView, groupFinTermPath, orgPath} from "../../../../../../services/urlStrings/urlStrings";

type Props = {
  organizationId: organizationId,
  contractId: contractId,
  terminal?: terminal,
  source: groupFinTermSource,
  updateContractParams: Function,
  gatesParamsResponse: gatesParamsResponse,
  gatesParamsItemIndex?: number,
  terminalIndex?: number,
  isAllGateTerminalsMode?: boolean,
  isInfoPanelMode?: boolean,
  disabled?: disabled,
  chosenGates?: gatesParams,
  onInitGroupFinTermsSearch: Function
};
type State = {
  modalIsShown: modalIsShown,
  isFirstFetchAfterModalHasShown: boolean,
  contractFinTermsList: contractFinTermsList,
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  chosenGroupFinTerms: Array<{
    groupFinTermId:groupFinTermId,
    groupFinTermName: groupFinTermName
  }>,
  isShowOnlyChosen: boolean
};

class GatesParamsCardGroupFinTerms extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isFirstFetchAfterModalHasShown: false,
      modalIsShown: false,
      contractFinTermsList: [],
      chosenGroupFinTerms: [],
      isShowOnlyChosen: false,
      isReadyToRender: false
    };
  }
  handleCheckboxClick = (groupFinTermNameIdObj) => {
    let chosenGroupFinTerms = [];
    const foundItem = this.state.chosenGroupFinTerms.find(item => item.groupFinTermId === groupFinTermNameIdObj.groupFinTermId);
    if (foundItem) {
      chosenGroupFinTerms = this.state.chosenGroupFinTerms.filter(item => item !== foundItem);
    } else {
      chosenGroupFinTerms = clone(this.state.chosenGroupFinTerms);
      chosenGroupFinTerms.push(groupFinTermNameIdObj);
      chosenGroupFinTerms = chosenGroupFinTerms.sort((a, b) => a.groupFinTermId - b.groupFinTermId);
    }
    this.setState({chosenGroupFinTerms});
  };
  handleResponse = (responseData) => {
    if (this.state.isFirstFetchAfterModalHasShown) {
      const chosenGroupFinTerms = this.props.isAllGateTerminalsMode || this.props.isInfoPanelMode ? []
        : clone(this.props.terminal.groupFinTerms);
      this.setState({
         isReadyToRender: true,
         contractFinTermsList: responseData,
         chosenGroupFinTerms,
        isFirstFetchAfterModalHasShown: false
      });
    } else {
      this.setState({
        chosenGroupFinTerms: [],
        contractFinTermsList: responseData});
    }
  };
  showModal = () => {
    this.setState({
      modalIsShown: true
    });
  };
  hideModal = () => {
    this.setState({
      isReadyToRender: false,
      isShowOnlyChosen: false,
      modalIsShown: false,
      contractFinTermsList: [],
      chosenGroupFinTerms: []
    })};
  setChosenGroupFinTermsForChosenGates = () => {
    return this.props.gatesParamsResponse.gatesParams.map((item) => {
      const indexInChosenGate = this.props.chosenGates.findIndex(el => el.gateId === item.gateId);
      if (indexInChosenGate === -1) {
        return clone(item);
      } else {
        const itemTerminals = item.terminals.map((terminal) => {
          if (terminal.groupFinTerms.length) {
            return clone(terminal);
          } else {
            return {...terminal, groupFinTerms: this.state.chosenGroupFinTerms}
          }

        });
        return  {...item, terminals: itemTerminals};
      }
    });
  };
  onModalActionBtnClick = () => {
    const gatesParamsResponse = this.props.isAllGateTerminalsMode ?
      update(this.props.gatesParamsResponse, {gatesParams: {[this.props.gatesParamsItemIndex]:
            {terminals: {$set: this.props.gatesParamsResponse.gatesParams[this.props.gatesParamsItemIndex].terminals.map(
                  item => { return {...item, groupFinTerms: this.state.chosenGroupFinTerms}}
                )}}}})
      :
      this.props.isInfoPanelMode ?
        update(this.props.gatesParamsResponse, {gatesParams: {$set: this.setChosenGroupFinTermsForChosenGates()}})
        :
      update(this.props.gatesParamsResponse, {gatesParams: {[this.props.gatesParamsItemIndex]:
            {terminals: {[this.props.terminalIndex]: {groupFinTerms: {$set: this.state.chosenGroupFinTerms}}}}}});
    this.props.updateContractParams(gatesParamsResponse, this.hideModal);
  };
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if (prevState.modalIsShown !== this.state.modalIsShown && this.state.modalIsShown) {
      this.props.onInitGroupFinTermsSearch({source: this.props.source});
      this.setState({isFirstFetchAfterModalHasShown: true});
    }
  }
  render() {
    const chosenGroupFinTermIds = this.state.chosenGroupFinTerms.map((item, i) => {
      return "#" + item.groupFinTermId + " ";
    });
    const contractFinTerms = this.state.contractFinTermsList.map((item, i) => {
      const filteredGroupFinTerms = this.state.isShowOnlyChosen ? item.groupFinTerms.filter(groupFinTerm => {
        return !!this.state.chosenGroupFinTerms.find(chosenItem => chosenItem.groupFinTermId === groupFinTerm.groupId);
        // return this.state.chosenGroupFinTerms.includes(groupFinTerm.groupId);
      }) : item.groupFinTerms;
      const groupFinTerms = filteredGroupFinTerms.map((groupFinTerm, index) => {
        const groupFinTermNameIdObj = {groupFinTermName: groupFinTerm.name, groupFinTermId: groupFinTerm.groupId};
        const checked = !!this.state.chosenGroupFinTerms.find(chosenItem => chosenItem.groupFinTermId === groupFinTerm.groupId);
        const checkboxComponent = <Checkbox checked={checked}
                                            onClick={e => this.handleCheckboxClick(groupFinTermNameIdObj)}
        addClasses={"mr_2du"}/>;
        return (<ConnectedGroupFinTermCard key={'groupFinTerm'+i+index} groupFinTerm={groupFinTerm} groupFinTermIndex={index}
                                           onBtnDelClick={null} addClasses={'mb_1du'} isEmbeddedMode={true}
                                           checkboxComponent={checkboxComponent}/>);
      });
      return <div key={"contractFinTermsItem"+i}>
        <Heading5 addClasses={"Heading5_no-underline mb_1du mt_4du"}>
          {concatIdName(item.contractId, item.contractName)}
        </Heading5>
        {groupFinTerms}
      </div>;
    });
    const modalTitle = this.props.isAllGateTerminalsMode ?
      text.selectFinTermsForGateModalTitle(this.props.gatesParamsResponse.gatesParams[this.props.gatesParamsItemIndex].gateId)
      :
        this.props.isInfoPanelMode ?
          text.selectFinTermsForChosenGatesModalTitle(this.props.chosenGates.map(chosenGate => chosenGate.gateId).join(", "))
        : text.selectFinTermModalTitle(this.props.terminal.terminalId);
    const onFinTermsClick = this.props.disabled ? null : this.showModal;
    const isSelectFinTermButtonDisabledClassName = this.props.disabled ? "Text1_link Text1_lh20 Text1_link_disabled"
      : "Text1_link Text1_lh20";
    const placeholders = [<ContentPlaceholder key={'cp1'} addClasses={"ContentPlaceholder_98 mb_1du"}/>,
      <ContentPlaceholder key={'cp2'} addClasses={"ContentPlaceholder_98 mb_1du"}/>,
      <ContentPlaceholder key={'cp3'} addClasses={"ContentPlaceholder_98 mb_1du"}/>];
    return (
      <>
        {this.props.isInfoPanelMode ?
            <TextActionButton onClick={this.showModal} disabled={this.props.disabled} addClasses={"TextActionButton_in_InfoPanel"}>
              {text.sortAndFilterInfoPanel.selectFinTermBtnLabel}</TextActionButton>
            :
          <div className="GatesParamsCard__groupFinTerms" >
            {this.props.terminal.groupFinTerms.length ?
              (<>
                <div className="GatesParamsCard__groupFinTermsLinks">
                  {this.props.terminal.groupFinTerms.map((groupFinTerm, index)=>{
                    const className = groupFinTerm.isFtgFound ? "GatesParamsCard__groupFinTerm"
                      : "GatesParamsCard__groupFinTerm GatesParamsCard__groupFinTerm_notFound";
                    return <div className={className} key={"groupFinTerm"+index}>
                      <TextOverflowHandler addClasses={"TextOverflowHandler_mw-100"} isInputWrapperMode={true}
                                           inputValue={concatIdName(groupFinTerm.groupFinTermId, groupFinTerm.groupFinTermName)}>
                        <Link to={orgPath(this.props.organizationId) + contrPathView(groupFinTerm.contractId)
                        + groupFinTermPath(groupFinTerm.groupFinTermId)}
                              target={'_blank'} rel={"nofollow noopener noreferrer"}
                          className={"Text1 Text1_history-link Text1_lh20"}>
                          {concatIdName(groupFinTerm.groupFinTermId, groupFinTerm.groupFinTermName)}</Link>
                      </TextOverflowHandler>
                    </div>
                  })}
                </div>
                <div className="GatesParamsCard__groupFinTermsEditBtn">
                  <Text1 addClasses={"Text1_link Text1_lh20"} onClick={onFinTermsClick}>
                    {text.editFinTermBtnLabel}</Text1>
                </div>
              </>)

              : <Text1 addClasses={isSelectFinTermButtonDisabledClassName} onClick={onFinTermsClick}>
                {text.selectFinTermBtnLabel}</Text1>}
          </div>
        }
        {this.state.modalIsShown ? (
          <NModal modalTitle={modalTitle}
                  modalActionBtnText={text.applyBtnLabel} modalCancelBtnText={text.cancel}
                  onModalActionBtnClick={this.onModalActionBtnClick} onModalCancelBtnClick={this.hideModal}>
            <ContractFinTermsFetcher handleResponse={this.handleResponse} contractId={this.props.contractId}/>
            <GroupFinTermsFilters isSourceFilterHidden={true} isModalMode={true}/>
            <div className="flex_jc-sb">
              <Heading5 addClasses={"Heading5_no-underline Heading5_lh24"}>
                {text.areChosen + " " + this.state.chosenGroupFinTerms.length}</Heading5>
              <div className="flex">
                <Text3 addClasses={"Text3__btn Text3__lh24 mr_2du"}
                       onClick={()=>{this.setState({isShowOnlyChosen: true})}}>{text.onlyChosenBtnLabel}</Text3>
                <Text3 addClasses={"Text3__btn Text3__lh24"}
                       onClick={()=>{this.setState({isShowOnlyChosen: false})}}>{text.allBtnLabel}</Text3>
              </div>
            </div>
            <Heading4 addClasses={"mb_1du Heading4_lh22 Heading4_main-accent-color min-h_22"}>{chosenGroupFinTermIds}</Heading4>

            <div className="SelectFinTermButtonModal__finTerms">
              {this.state.isReadyToRender ? contractFinTerms : placeholders }
            </div>
          </NModal>
        ) : null}
      </>
    );
  };
}
const mapStateToProps = state => {
  return {
    organizationId: state.organization.organizationId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onInitGroupFinTermsSearch: (groupFinTermSearch) => dispatch({
      type: groupFinTermsSearchActions.GROUP_FIN_TERMS_SEARCH_INIT_STATE_SET, value: groupFinTermSearch})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GatesParamsCardGroupFinTerms);
