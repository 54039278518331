//@flow
import * as React from 'react';
import type {
  agent,
  agentRelated,
  contract,
  contractRelated,
  searchCategory
} from "../../../services/flowTypes/dataFlowTypes";
import ConnectedAddContractRow from "./ConnectedAddContractRow";
import ConnectedAddAgentRow from "./ConnectedAddAgentRow";

type Props = {
  category: searchCategory,
  item: contract | agent,
  itemRelated: contractRelated | agentRelated
};

class AddItemRow extends React.Component <Props> {
  defineComponent = () => {
    switch (this.props.category) {
      case "agents":
        return <ConnectedAddAgentRow agent={this.props.item} agentRelated={this.props.itemRelated}/>;
      case "contracts":
        return <ConnectedAddContractRow contract={this.props.item} contractRelated={this.props.itemRelated}/>;
      default:
        return null;
    }
  };
  render(){
    return this.defineComponent();
  }
}

export default AddItemRow;    