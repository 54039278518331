//@flow
import * as React from 'react';
import connect from "react-redux/es/connect/connect";
import type {organizationId} from "../services/flowTypes/dataFlowTypes";
import BaseLayout from "../components/composed/BaseLayout/BaseLayout";
import OrgRoute from "../components/service/Routes/OrgRoute";
import type {history} from "../services/flowTypes/appFlowTypes";
import SearchInOrgContext from "../components/composed/SearchInOrgContext/SearchInOrgContext";
import ButtonShowSearchInOrgContextSheet
  from "../components/composed/SearchInOrgContext/searchInOrgContext/ButtonShowSearchInOrgContextSheet";
import MainMenu from "../components/composed/MainMenu/MainMenu";


type Props = {
  organizationId: organizationId,
  history: history
};

class Layout extends React.Component <Props, void> {
  render(){
    return (
      <BaseLayout
        searchButton={<ButtonShowSearchInOrgContextSheet/>}
        leftSideBarContent={<MainMenu organizationId={this.props.organizationId}/>}
        layoutContent={
          <>
            <OrgRoute/>
            <SearchInOrgContext/>
          </>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organizationId: state.organization.organizationId
  }
};
export default connect(mapStateToProps, null)(Layout);