//@flow
import * as React from 'react';
import type {children, id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import './NHeading1.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children
};

const NHeading1 = (props: Props) => {
  return (
    <div id={props.id} className={baseClass("NHeading1", props.addClasses)}>{props.children}</div>
  );
};

export default NHeading1;