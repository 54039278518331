//@flow
import * as React from 'react';
import scrollToTop from "../../utils/scrollToTop";
import text from "../../services/localization/text";
import FixedHeaderContent from "../../components/simple/Containers/FixedHeader/FixedHeaderContent";
import type {locationType, history} from "../../services/flowTypes/appFlowTypes";
import SettingsButtonsRoutes from "../../components/service/Routes/SettingsButtonsRoutes";
import "./OrganizationSettings.css";
import OrganizationSettingsMenu from "./organizationSettings/OrganizationSettingsMenu";
import FixedSubHeader from "../../components/simple/Containers/FixedHeader/FixedSubHeader";
import {connect} from "react-redux";
import type {organizationsList} from "../../services/flowTypes/dataFlowTypes";

type Props = {
  location: locationType,
  history: history,
  organizationsList: organizationsList
};


class OrganizationSettings extends React.Component <Props> {
  componentDidMount(){
    scrollToTop();
  }
  render(){
    const organizationId = +this.props.match.params.organizationId;
    const nameObj = this.props.organizationsList.find(item => item.id === organizationId);
    const name = nameObj ? nameObj.name : "Name not found!";
    return (
      <>
        <FixedHeaderContent title={text.settingsTitle} history={this.props.history}/>
        <FixedSubHeader title={name}
                        buttons={<SettingsButtonsRoutes/>}
                        history={this.props.history}/>
        {this.props.location.pathname.includes("edit") ? null :
        <OrganizationSettingsMenu organizationId={organizationId}/>}
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    organizationsList: state.general.organizationsList
  };
};
export default connect(mapStateToProps, null)(OrganizationSettings);