//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../../../components/service/RenderOnReady/RenderOnReady";
import type {isReadyToRender, history} from "../../../../services/flowTypes/appFlowTypes";
import text from "../../../../services/localization/text";
import agentMainDataActions from "../../../../store/organization/item/agentMainData/agentMainDataActions";
import {onInitAgentMainData} from "../../../../services/flowTypes/propFnFlowTypes";
import AgentButtonsRoutes from "../../../../components/service/Routes/AgentButtonsRoutes";
import FixedHeaderContent from "../../../../components/simple/Containers/FixedHeader/FixedHeaderContent";
import type {organizationId} from "../../../../services/flowTypes/dataFlowTypes";
import FixedSubHeader from "../../../../components/simple/Containers/FixedHeader/FixedSubHeader";

type Props = {
  history: history,
  organizationId: organizationId,
  onInitAgentMainData: onInitAgentMainData
};
type State = {
  isReadyToRender: isReadyToRender
};

class NewAgent extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false
    };
  }

  componentDidMount() {
    this.props.onInitAgentMainData();
    this.setState({isReadyToRender: true});
  }

  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
        <FixedHeaderContent title={text.agents} history={this.props.history}/>
        <FixedSubHeader title={text.newAgent}
                        buttons={<AgentButtonsRoutes organizationId={this.props.organizationId}
                                                     agentId={null}/>}
                        history={this.props.history}/>
      </RenderOnReady>
    );
  }
}

const mapStateToProps = state => {
  return {
    organizationId: state.organization.organizationId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onInitAgentMainData: () => dispatch({type: agentMainDataActions.AGENT_MAIN_DATA_INIT})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewAgent);