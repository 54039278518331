const itemDataParamsEditActions = {
  ITEM_DATA_PARAMS_SET: 'ITEM_DATA_PARAMS_SET',
  PARAMS_BLOCK_NAME_UPDATE: 'PARAMS_BLOCK_NAME_UPDATE',
  PARAM_VALUE_UPDATE: 'PARAM_VALUE_UPDATE',
  PARAMS_BLOCK_FIELDS_UPDATE: 'PARAMS_BLOCK_FIELDS_UPDATE',
  PARAMS_BLOCK_DELETE: 'PARAMS_BLOCK_DELETE',
  PARAMS_BLOCK_FIELD_DELETE: 'PARAMS_BLOCK_FIELD_DELETE',
  PARAMS_BLOCK_ADD: 'PARAMS_BLOCK_ADD'
};
export default itemDataParamsEditActions;
