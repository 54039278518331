import LocalizedStrings from 'react-localization';
import textRu from './ru/text';
import textEn from './en/text';

let text = new LocalizedStrings({
  ru: textRu,
  en: textEn
});
text.setLanguage(process.env.REACT_APP_LANG ? `${process.env.REACT_APP_LANG}` : "ru");
export default text;
