//@flow
import * as React from 'react';
import {
  editNotificationsSuffix, editOrganizationSettingsSuffix, orgPath, orgSettingsSuffix,
  viewNotificationsSuffix, viewOrganizationSettingsSuffix
} from "../../../services/urlStrings/urlStrings";
import PrivateRoute from "./PrivateRoute";
import NotificationSettingsEditButton from "../../composed/NotificationSettings/NotificationSettingsEditButton";
import NotificationSettingsControls from "../../composed/NotificationSettings/NotificationSettingsControls";
import canUseAnyone from "./canUseAnyone";
import OrganizationEditButton
  from "../../../supPages/Organizations/pages/Organization/pages/OrganizationMainDataView/OrganizationEditButton";
import ability from "../../../services/permissions/permissions";
import OrganizationMainDataControls
  from "../../../supPages/Organizations/pages/Organization/pages/OrganizationMainDataEdit/organizationMainDataEdit/OrganizationMainDataControls";
import {permissionsStrValues} from "../../../configData/permissionsStrValues";

type Props = {};

const SettingsButtonsRoutes = (props: Props) => {
  return (
    <>
      <PrivateRoute exact path={orgPath(':organizationId') + orgSettingsSuffix + viewNotificationsSuffix}
                    component={NotificationSettingsEditButton}
                    canUseRoute={canUseAnyone()} />
      <PrivateRoute exact path={orgPath(':organizationId') + orgSettingsSuffix + editNotificationsSuffix}
                    component={NotificationSettingsControls}
                    canUseRoute={canUseAnyone()} />
      <PrivateRoute exact path={orgPath(':organizationId') + orgSettingsSuffix + viewOrganizationSettingsSuffix}
                    component={OrganizationEditButton}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.organization.update)} />
      <PrivateRoute exact path={orgPath(':organizationId') + orgSettingsSuffix + editOrganizationSettingsSuffix}
                    component={OrganizationMainDataControls}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.organization.update)} />
    </>
  );
};

export default SettingsButtonsRoutes;