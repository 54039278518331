//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './FilterFlags.css';
import type {filtersState, flagsUnviewedMarks, selectOptions} from "../../../../services/flowTypes/componentFlowTypes";
import text from "../../../../services/localization/text";
import type {onGetFiltersProp} from "../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  flagsList: selectOptions,
  onGetFiltersProp: onGetFiltersProp,
  flagsUnviewedMarks?: flagsUnviewedMarks,
  filtersState: filtersState
};

const FilterFlags = (props: Props) => {
  let isAllTrue = true;
  const filterFlags = props.flagsList.map(flag => {
    const flagValue = typeof props.filtersState[flag] === 'string' ? props.filtersState[flag] === 'true'
      : props.filtersState[flag];
    isAllTrue = isAllTrue && !flagValue;
    return {
      label: flag,
      value: flagValue
    };
  });
  filterFlags.unshift({label: 'ALL', value: isAllTrue});

  const flags = filterFlags.map((flag, i) => {
    const className = flag.value ? "FilterFlags__item FilterFlags__item_active" : "FilterFlags__item";
    const onClick = () => {
      const filtersProps = {};
      for (let i=0; i < props.flagsList.length; i++) {
        filtersProps[props.flagsList[i]] = false;
      }
      if (flag.label !== 'ALL') {
        filtersProps[flag.label] = true;
      }
      props.onGetFiltersProp(filtersProps);
    };
    const unviewedMarkObj = props.flagsUnviewedMarks.find(markObj => markObj.flag === flag.label);
    const unviewedMark = unviewedMarkObj ? unviewedMarkObj.component : null;
    return (
      <div key={'flag'+i} id={"filterFlag_" + flag.label} className={className}
           onClick={onClick}>{unviewedMark}{text.filterFlagsLabels[flag.label]}</div>
    );
  });
  return (
    <div id={props.id} className={baseClass("FilterFlags", props.addClasses)}>
      {flags}
    </div>
  );
};

export default FilterFlags;