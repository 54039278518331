import {
  mainDataSuffix, paramsSuffix, supOrgPathView
} from "../services/urlStrings/urlStrings";
import text from "../services/localization/text";
import {globalPermissionsStrValues} from "./permissionsStrValues";

const organizationMenuItems = (organizationId) => {
  return [
    {
      linkTo: supOrgPathView(organizationId) + mainDataSuffix,
      linkName: text.mainData,
      permittedAction: globalPermissionsStrValues.admin
    },
    {
      linkTo: supOrgPathView(organizationId) + paramsSuffix,
      linkName: text.addParams,
      permittedAction: globalPermissionsStrValues.admin
    }
  ];
};
export default organizationMenuItems;