//@flow
import * as React from 'react';
import './UserBar.css';
import TextOverflowHandler from "../../../simple/Containers/TextOverflowHandler/TextOverflowHandler";
import LogoutContainer from "../../LogoutContainer/LogoutContainer";

type Props = {};

const UserBar = (props: Props) => {
  const userName = window.APP_DATA.userName;
  return (
    <div className="UserBar">
      <div className="UserBar__userName-container">
        <LogoutContainer addClasses={"UserBar__logoutContainer"}/>
        <div className={'UserBar__userName'}>
          <TextOverflowHandler addClasses={"TextOverflowHandler_mw-100"}>{userName}</TextOverflowHandler>
        </div>
      </div>

    </div>
  );
};

export default UserBar;    