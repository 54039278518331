//@flow
import * as React from 'react';
import {connect} from "react-redux";
import api from "../../../../../../../services/apiInstance/api";
import handleCorrectOrgIdInResponse from "../../../../../../../utils/handleCorrectOrgIdInResponse";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import RedirectToCorrectOrgUrl
  from "../../../../../../../components/service/RedirectToCorrectOrgUrl/RedirectToCorrectOrgUrl";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {errorObject, history, isReadyToRender} from "../../../../../../../services/flowTypes/appFlowTypes";
import type {
  configFinTermParams,
  groupFinTermsSearch,
  organizationId
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import groupFinTermsViewActions
  from "../../../../../../../store/organization/item/groupFinTermsView/groupFinTermsViewActions";
import type {
  onInitGroupFinTermsView,
  onSetGroupFinTermsView
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import {prepareGroupFinTermsToStore} from "../GroupFinTermsView";
import debounce from 'lodash.debounce';

type Props = {
  configFinTermParams: configFinTermParams,
  groupFinTermsSearch: groupFinTermsSearch,
  history: history,
  onInitGroupFinTermsView: onInitGroupFinTermsView,
  onSetGroupFinTermsView: onSetGroupFinTermsView,
  deletedGroupFinTermId: number
};

type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  correctOrganizationId: organizationId
};

class GroupFinTermsFetcher extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true,
      correctOrganizationId: null
    };
    this.debounceGroupFinTermsRequest = debounce(this.getGroupFinTerms, 500);
  }
  handleResponse = (responseData) => {
    const groupFinTerms = prepareGroupFinTermsToStore(responseData, this.props.configFinTermParams);
    this.props.onSetGroupFinTermsView(groupFinTerms);
    this.setState({isReadyToRender: true});
  };
  getGroupFinTerms = () => {
    this.setState({isReadyToRender: false});
    api.getGroupFinTerms(this.props.groupFinTermsSearch)
      .then(response => {
        handleCorrectOrgIdInResponse(this, response.data, this.setState, this.handleResponse);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  componentDidUpdate(prevProps){
    if (prevProps.groupFinTermsSearch !== this.props.groupFinTermsSearch
    || prevProps.deletedGroupFinTermId !== this.props.deletedGroupFinTermId) {
      this.debounceGroupFinTermsRequest();
    }
  }
  componentDidMount(): * {
    this.props.onInitGroupFinTermsView();
  }

  render() {
    return (
      <RedirectToCorrectOrgUrl correctOrganizationId={this.state.correctOrganizationId} history={this.props.history}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
      </RedirectToCorrectOrgUrl>
    );
  }
}

const mapStateToProps = state => {
  return {
    configFinTermParams: state.organization.organizationData.configFinTerms.configFinTermParams,
    groupFinTermsSearch: state.organization.item.groupFinTermsSearch
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitGroupFinTermsView: () => dispatch({type: groupFinTermsViewActions.GROUP_FIN_TERMS_VIEW_INIT}),
    onSetGroupFinTermsView: (groupFinTerms) => dispatch({
      type: groupFinTermsViewActions.GROUP_FIN_TERMS_VIEW_SET, value: groupFinTerms})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupFinTermsFetcher);    