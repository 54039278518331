//@flow
import * as React from 'react';
import {connect} from "react-redux";
import {agentGatesParamsPageIds} from "../../../../../../tests/testIds";
import text from "../../../../../../services/localization/text";
import Can from "../../../../../../services/permissions/Can";
import {permissionsStrValues} from "../../../../../../configData/permissionsStrValues";
import contractGatesParamsActions
  from "../../../../../../store/organization/item/agentGatesParams/contractGatesParamsActions";
import type {agentId, itemDate} from "../../../../../../services/flowTypes/dataFlowTypes";
import type {errorObject, isReadyToRender, match} from "../../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import api from "../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import Text1 from "../../../../../../components/simple/TextComponents/Text1/Text1";
import formatDate from "../../../../../../utils/formatDate";
import formatTime from "../../../../../../utils/formatTime";
import BtnNewItem from "../../../../../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  match: match,
  onInitGatesParams: Function,
  onGetGatesParams: Function,
  agentId: agentId,
  requestPaynetDate: itemDate
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  isBtnDisabled: boolean
};
class RequestPaynetGatesParamsButton extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null,
      isBtnDisabled: false
    };
  }
  handleBtnDisable = () => {
    setTimeout(()=>{
      this.setState({isBtnDisabled: false});
    }, 300000);
  };
  requestPaynetGatesParams = () => {
    this.setState({isReadyToRender: false});
    setTimeout(()=>{
      this.props.onInitGatesParams();
      api.getContractGatesParams(this.props.agentId, {isRequestFromPaynet: true})
        .then(response => {
          this.props.onGetGatesParams(response.data);
          this.setState({
            isBtnDisabled: true,
            isReadyToRender: true
          });
          this.handleBtnDisable();
        })
        .catch(error => {
          this.setState({
            isReadyToRender: true,
            errorObject: apiErrorHandler(error)
          });
        });
    }, 5000);
  };
  render() {
    return (
      <div className={"flex"}>
        <Text1 addClasses={"Text1_asBtnNewItem Text1_card-label-color"}>
          {this.props.requestPaynetDate ? (formatDate(this.props.requestPaynetDate)
        + " " + formatTime(this.props.requestPaynetDate)) : text.requestPaynetDateEmpty}</Text1>
        <Can do={'usePermission'} on={permissionsStrValues.gatesParams.view}>
          <BtnNewItem id={agentGatesParamsPageIds.buttonRequestPaynetGatesParams} disabled={this.state.isBtnDisabled}
                         onClick={this.requestPaynetGatesParams}>
            {text.requestPaynetGatesParamsButtonLabel}</BtnNewItem>
        </Can>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    requestPaynetDate: state.organization.item.agentGatesParams.requestPaynetDate
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onInitGatesParams: () => dispatch({type: contractGatesParamsActions.CONTRACT_GATES_PARAMS_INIT}),
    onGetGatesParams: (value) => dispatch({type: contractGatesParamsActions.CONTRACT_GATES_PARAMS_SET, value})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RequestPaynetGatesParamsButton);