//@flow
import * as React from 'react';
import {connect} from "react-redux";
import text from "../../../../../../../../services/localization/text";
import NLabel from "../../../../../../../../components/simple/FormComponents/NLabel/NLabel";
import FormTagSelect from "../../../../../../../../components/simple/FormComponents/FormTagSelect/FormTagSelect";
import type {isIgnored, tagsList, terminal} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import groupFinTermsEditActions
  from "../../../../../../../../store/organization/item/groupFinTermsEdit/groupFinTermsEditActions";
import {groupFinTermEditPageIds} from "../../../../../../../../tests/testIds";
import BtnIgnore from "../../../../../../../../components/simple/Buttons/BtnIgnore/BtnIgnore";
import concatIdName from "../../../../../../../../utils/concatIdName";

type Props = {
  finTermGateIndex: number,
  terminals: terminal[],
  onSetGroupFinTermTerminals: Function,
  terminalList: terminal[],
  isIgnored: isIgnored
};
type State = {
  terminalsOptions: Array<>
};

class Terminals extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      terminalsOptions: []
    };
  }
  handleBtnIgnoreClick = () => {
    this.props.onSetGroupFinTermTerminals("isIgnored", this.props.finTermGateIndex, !this.props.isIgnored);
  };
  handleTerminalSelectChange = (value:tagsList) => {
    const terminals = this.props.terminalList.filter(item => value.includes(item.terminalId));
    this.props.onSetGroupFinTermTerminals("terminals", this.props.finTermGateIndex, terminals);
  };
  componentDidMount(): * {
    const terminalsOptions = this.props.terminalList.map((item,i) => {
      return {
        label: <div className={"Terminals__option"} key={i}>
          <div className="Terminals__optionTerminalId">{item.terminalId}</div>
          <div className="Terminals__optionGroupFinTerms">
            {item.groupFinTerms.map((groupFinTerm, index) => {
              return <div className="Terminals__optionGroupFinTerm" key={i+"Terminals__optionGroupFinTerm"+index}>
                {groupFinTerm.groupFinTermId === -1 ?  text.terminalIsNotLinkedToFinTerm :
                  concatIdName(groupFinTerm.groupFinTermId, groupFinTerm.groupFinTermName)}</div>
            })}
          </div>
        </div>,
        value: item.terminalId
      }
    });
    this.setState({terminalsOptions});
  }

  render() {
    const opacity = this.props.isIgnored ? "0.3" : "1";
    const value = this.props.terminals.map(item => item.terminalId);
    const noOptionsMessage = () => text.terminalsNoOptionsMessage;
    const menuHeader = (<div className={"Terminals__option Terminals__option_p0_12"} >
      <div className="Terminals__optionTerminalId">{text.terminalsOptionsMenuTerminalCaption}</div>
      <div className="Terminals__optionGroupFinTerms">{text.terminalsOptionsMenuGroupFinTermCaption}</div>
    </div>);
    return (
      <div className="flex_jc-sb">
        <div className={"Terminals__tagSelectContainer"} style={{opacity}}>
          <NLabel>{text.terminalsLabel}</NLabel>
          <FormTagSelect value={value} onChange={this.handleTerminalSelectChange}
                         creationIsProhibited={true} placeholder={text.terminalsPlaceholder}
                         options={this.state.terminalsOptions} disabled={this.props.isIgnored}
                         noOptionsMessage={noOptionsMessage} menuHeader={menuHeader}
                         idForTest={groupFinTermEditPageIds.selectGateTerminals(this.props.finTermGateIndex)}/>
        </div>
        <BtnIgnore onClick={this.handleBtnIgnoreClick} isIgnored={this.props.isIgnored} addClasses={"mt_2du"}/>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    terminals: state.organization.item.groupFinTermsEdit.gates[ownProps.finTermGateIndex].terminals,
    isIgnored: state.organization.item.groupFinTermsEdit.gates[ownProps.finTermGateIndex].isIgnored
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSetGroupFinTermTerminals: (paramName, finTermGateIndex, terminals) =>
      dispatch({type: groupFinTermsEditActions.GROUP_FIN_TERMS_GATE_PARAM_SET,
      value: { paramName, finTermGateIndex, terminals} })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Terminals);    