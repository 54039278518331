//@flow
import * as React from 'react';
import type {disabled, id, onClick} from "../../../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../../UiKit/newUiKit/utilities/baseClass";
import './Download1CExportFileButton.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  onClick?: onClick,
  disabled?: disabled
};

const Download1CExportFileButton = (props: Props) => {
  return (
    <button type={'button'} id={props.id} onClick={props.onClick} disabled={props.disabled}
            className={baseClass("Download1CExportFileButton", props.addClasses)}>
      {props.children}</button>
  );
};

export default Download1CExportFileButton;
