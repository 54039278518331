//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './DataListContainer.css';
import type {itemsList} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  itemsList: itemsList
};

const DataListContainer = (props: Props) => {
  const itemsList = props.itemsList.map((item, i) => {
    const className = "DataListContainer__item";
    return (<div key={className + i} className={className}>{item}</div>);
  });

  return (
    <div id={props.id} className={baseClass("DataListContainer", props.addClasses)}
         >{itemsList}</div>
  );

};

export default DataListContainer;