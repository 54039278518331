//@flow
import needFetchFlagsActions from './needFetchFlagsActions';
import type {action} from "../../services/flowTypes/appFlowTypes";
import type {needFetchFlags} from "../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = {
  contractTypes: true,
  agentsList: true,
  usersList: true,
  tagsList: true,
  organizationsList: true,
  configFinTerms: true,
  statuses: true,
  contractsList: true,
  actTemplatesList: true,
  groupFinTermsList: true
};

const needFetchFlagsReducer = (state:needFetchFlags = initialState, action:reducerAction) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case needFetchFlagsActions.NEED_FETCH_FLAGS_PROP_UPDATE:
      newState[action.value.prop] = action.value.data;
      return newState;
    default:
      return state;
  }
};

export default needFetchFlagsReducer;