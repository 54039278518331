//@flow
import * as React from 'react';
import type {isFocused, menuItemsData} from "../../../../services/flowTypes/componentFlowTypes";
import CloseOnClickOutside from "../../../service/CloseOnClickOutside/CloseOnClickOutside";
import RowMenuOverflowBtn from "./RowMenuOverflowBtn";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {NavLink} from "react-router-dom";

type Props = {
  addClasses?: addClasses,
  overflowItemsData: menuItemsData
};
type State = {
  isFocused: isFocused
};


class RowMenuOverflow extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isFocused: false
    };
  }
  toggleFocus = () => {
    this.setState({isFocused: !this.state.isFocused});
  };
  onClickOutside = () => {this.setState({isFocused: false})};
  render() {
    const className = this.state.isFocused ? "RowMenuOverflow RowMenuOverflow_active" : "RowMenuOverflow";
    const options = this.props.overflowItemsData.map((item, i) => {
      const itemTo = item.linkTo ? item.linkTo.replace(/^.+\//, "") : "";
      return <NavLink activeClassName={"RowMenuOverflow__option_active"} className={"RowMenuOverflow__option"}
                      key={"RowMenuOverflow__option"+ i}
               data-ui-test-id={"rowMenuLink_" + itemTo}
               to={item.linkTo}>
        {item.linkName}
      </NavLink>
    });
    return (
      <CloseOnClickOutside onClickOutside={this.onClickOutside} isActive={this.state.isFocused}>
        <div className={baseClass(className, this.props.addClasses)}>
          <RowMenuOverflowBtn isActive={this.state.isFocused} onClick={this.toggleFocus}/>
          <div className="RowMenuOverflow__options">
            {options}
          </div>
        </div>
      </CloseOnClickOutside>
    );
  }
}

export default RowMenuOverflow;    