//@flow
import * as React from 'react';
import {connect} from "react-redux";
import {
  onDeleteBlock,
  onUpdateBlockName
} from "../../../../../services/flowTypes/propFnFlowTypes";
import type {blockName} from "../../../../../services/flowTypes/dataFlowTypes";
import type {
  paramsBlockIndex,
  propName,
  propValidationPatternsArr
} from "../../../../../services/flowTypes/componentFlowTypes";
import itemDataParamsEditActions
  from "../../../../../store/organization/item/itemDataParamsEdit/itemDataParamsEditActions";
import type {isReadyToRender} from "../../../../../services/flowTypes/appFlowTypes";
import ConnectedParamFormGroup from "../../../ConnnectedParamFormGroup/ConnectedParamFormGroup";
import RenderOnReady from "../../../../service/RenderOnReady/RenderOnReady";
import contractMainDataValidationScheme from "../../../../../configData/contractMainDataValidationScheme";
import {contractEditPageIds} from "../../../../../tests/testIds";
import text from "../../../../../services/localization/text";

type Props = {
  onDeleteBlock: onDeleteBlock,
  blockName: blockName,
  paramsBlockIndex: paramsBlockIndex,
  onUpdateBlockName: onUpdateBlockName
};
type State = {
  isReadyToRender: isReadyToRender,
  propName: propName,
  propValidationPatternsArr: propValidationPatternsArr
};
class ParamsBlockName extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      propName: '',
      propValidationPatternsArr: []
    };
  }
  setPropName = () => {
    return 'blockName' + this.props.paramsBlockIndex;
  };
  onGetPropValue = (propName, propValue) => {
    this.props.onUpdateBlockName(this.props.paramsBlockIndex, propValue);
  };
  componentDidMount(){
    this.setState({
      propName: this.setPropName(),
      propValidationPatternsArr: contractMainDataValidationScheme.params.blockName,
      isReadyToRender: true
    });
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={null}>
        <ConnectedParamFormGroup
          DnDisForbidden={true}
          formFieldType={'input'}
          propName={this.state.propName}
          propValue={this.props.blockName}
          onGetPropValue={this.onGetPropValue}
          propValidationPatternsArr={this.state.propValidationPatternsArr}
          addClasses={'mb_3du'}
          onDelParamClick={this.props.onDeleteBlock}
          idForTest={contractEditPageIds.inputBlockName(this.props.paramsBlockIndex)}
          placeholder={text.blockName}
          btnDelId={contractEditPageIds.buttonDeleteBlock(this.props.paramsBlockIndex)}
        />
      </RenderOnReady>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    blockName: state.organization.item.itemDataParamsEdit[ownProps.paramsBlockIndex].blockName,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onUpdateBlockName: (paramsBlockIndex, blockName) => dispatch({
      type: itemDataParamsEditActions.PARAMS_BLOCK_NAME_UPDATE,
      value: { paramsBlockIndex, blockName  }
    })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ParamsBlockName);    