//@flow
import * as React from 'react';
import type {
  HeaderContentTitle,
  shouldSearchResultsUpdate
} from "../../../services/flowTypes/componentFlowTypes";
import type {
  button,
  errorObject,
  history,
  inputIsFocused, isReadyToRender, locationPathname,
  spinnerIsShown
} from "../../../services/flowTypes/appFlowTypes";
import TotalCount from "./TotalCount/TotalCount";
import type {searchRequest} from "../../../services/flowTypes/dataFlowTypes";
import {onGetSearchData, onGetSearchRequestDates} from "../../../services/flowTypes/propFnFlowTypes";
import apiErrorHandler from "../../../services/apiInstance/apiErrorHandler";
import ContractsSearchResults from "../../../pages/Contracts/contracts/ContractsSearchResults";
import AgentsSearchResults from "../../../pages/Agents/agents/AgentsSearchResults";
import ConnectedPagination from "./SearchBox/ConnectedPagination/ConnectedPagination";
import searchDataActions from "../../../store/organization/searchData/searchDataActions";
import connect from "react-redux/es/connect/connect";
import './SearchPage.css';
import AlertOnError from "../Alerts/AlertOnError";
import RenderOnReady from "../../service/RenderOnReady/RenderOnReady";
import SearchBox from "./SearchBox/SearchBox";
import SearchResultsFetcher from "./SearchBox/SearchResultsFetcher/SearchResultsFetcher";
import FixedHeaderContent from "../../simple/Containers/FixedHeader/FixedHeaderContent";

type Props = {
  pageTitle: HeaderContentTitle,
  button: button,
  pathname: locationPathname,
  searchRequest: searchRequest,
  onGetSearchData: onGetSearchData,
  onGetSearchRequestDates: onGetSearchRequestDates,
  history: history
};
type State = {
  spinnerIsShown: spinnerIsShown,
  inputIsFocused: inputIsFocused,
  shouldSearchResultsUpdate: shouldSearchResultsUpdate,
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};

class SearchPage extends React.Component <Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      spinnerIsShown: false,
      inputIsFocused: false,
      shouldSearchResultsUpdate: true,
      errorObject: null,
      isReadyToRender: false
    };
  }
  onBlur = () => {this.setState({inputIsFocused: false})};
  onFocus = () => {this.setState({inputIsFocused: true})};
  handleSearchResultsUpdate = (shouldSearchResultsUpdate) => {
    this.setState({shouldSearchResultsUpdate});
  };
  showSpinner = () => {
    this.setState({spinnerIsShown: true});
  };
  hideSpinner = () => {
    this.setState({
      spinnerIsShown: false,
      isReadyToRender: true
    });
  };
  handleError = (error) => {
    this.setState({
      isReadyToRender: true,
      errorObject: apiErrorHandler(error)
    });
  };
  // getSearchResults = () => {
  //   this.setState({spinnerIsShown: true});
  //   api.searchAdvanced(this.props.searchRequest).then(response => {
  //       this.props.onGetSearchData(response.data);
  //       this.setState({
  //         spinnerIsShown: false,
  //         isReadyToRender: true
  //       });
  //     }).catch(error => {
  //       this.setState({
  //         isReadyToRender: true,
  //         errorObject: apiErrorHandler(error)
  //       });
  //       console.log(error);
  //     });
  // };
  // componentDidUpdate(prevProps){
  //   if (prevProps.searchRequest !== this.props.searchRequest) {
  //     this.getSearchResults();
  //   }
  // }
  // componentDidMount(){
  //   this.getSearchResults();
  // }
  render(){
    const searchDropdownIsShown = this.state.inputIsFocused && this.props.searchRequest.text.length > 0;
    const title = <>{this.props.pageTitle + " – "}<TotalCount shouldSearchResultsUpdate={this.state.shouldSearchResultsUpdate}/></>;
    return (
      <div className={'SearchPage w_wide-content'}>
        <SearchResultsFetcher showSpinner={this.showSpinner} handleError={this.handleError} hideSpinner={this.hideSpinner}/>
        <FixedHeaderContent title={title}
                            pathname={this.props.pathname}
                            buttons={[this.props.button]}
                            history={this.props.history}
        />
        <SearchBox
          spinnerIsShown={this.state.spinnerIsShown} inputIsFocused={this.state.inputIsFocused}
          shouldSearchResultsUpdate={this.state.shouldSearchResultsUpdate}
          handleSearchResultsUpdate={this.handleSearchResultsUpdate}
          onFocus={this.onFocus} onBlur={this.onBlur} searchDropdownIsShown={searchDropdownIsShown}
          history={this.props.history}/>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender}>

          {this.props.searchRequest.category === 'contracts' ? (
            <ContractsSearchResults shouldSearchResultsUpdate={this.state.shouldSearchResultsUpdate} isWideMode={true}/>
          ) : null}
          {this.props.searchRequest.category === 'agents' ? (
            <AgentsSearchResults shouldSearchResultsUpdate={this.state.shouldSearchResultsUpdate}
                                 history={this.props.history}/>
          ) : null}
          <ConnectedPagination shouldSearchResultsUpdate={this.state.shouldSearchResultsUpdate}/>
          {this.state.errorObject ? (<AlertOnError errorObject={this.state.errorObject}/>) : null}
        </RenderOnReady>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchRequest: state.organization.searchRequest,
    offset: state.organization.searchRequest.offset
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetSearchData: (data) => dispatch({type: searchDataActions.SEARCH_DATA_SET, value: data})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);