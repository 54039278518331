import * as React from 'react';
import NInput from "../NInput/NInput";
import {testChildString, testFormFieldValue} from "../../../../tests/test-data";

//TODO: add to uiKit view labelTooltip, label required and error?

const onChange = (e) => {
  return e.target.value;
};
export const NFormGroupDefProps = {
  children: <NInput value={testFormFieldValue} onChange={onChange}/>,
  label: testChildString
};