//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './CustomDropDownSelect.css';
import type {
  isFocused,
  selectedOption as selectedOptionType,
  selectOptions
} from "../../../../services/flowTypes/componentFlowTypes";
import CloseOnClickOutside from "../../../service/CloseOnClickOutside/CloseOnClickOutside";
import type {onDropDownSelectChange} from "../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  addClasses?: addClasses,
  options: selectOptions,
  selectedOption: selectedOptionType,
  onDropDownSelectChange: onDropDownSelectChange,
  idForTest?: id,
  headerComponentFn: Function,
  customOptionsContainerStyle?: Object
};
type State = {
  isFocused: isFocused
};

class CustomDropDownSelect extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isFocused: false
    };
  }
  handleFocus = (e:SyntheticEvent<HTMLElement>) => {
    if (!this.state.isFocused) {
      this.setState({isFocused: true});
    }
  };
  toggleFocus = () => {
    this.setState({isFocused: !this.state.isFocused});
  };
  handleSelect = (e:SyntheticEvent<HTMLElement>, selectedOption:selectedOptionType) => {
    this.setState({isFocused: false});
    this.props.onDropDownSelectChange(selectedOption);
  };
  onClickOutside = () => {this.setState({isFocused: false})};
  render() {
    const className = this.state.isFocused ? "CustomDropDownSelect CustomDropDownSelect_focused" : "CustomDropDownSelect";
    const options = this.props.options.map((option, index) => {
      const optionClassName = this.props.selectedOption && this.props.selectedOption.value === option.value ?
        "CustomDropDownSelect__option CustomDropDownSelect__option_active"
        : "CustomDropDownSelect__option";
      const onClick = this.props.selectedOption && this.props.selectedOption.value === option.value ?
        () => this.setState({isFocused: false})
        : (e) => this.handleSelect(e, option);
      return (
        <div className={optionClassName} key={'CustomDropDownSelectOption'+index} onClick={onClick} id={option.value}>
          {option.label}
        </div>
      );
    });
    const headerProps = {isFocused: this.state.isFocused};
    return (
      <CloseOnClickOutside onClickOutside={this.onClickOutside} isActive={this.state.isFocused}>
        <div className={baseClass(className, this.props.addClasses)} onClick={this.handleFocus} id={this.props.idForTest}>
          <div className="CustomDropDownSelect__header-container" onClick={this.toggleFocus}>
            {this.props.headerComponentFn(headerProps)}
          </div>
          <div className="CustomDropDownSelect__options-relative-container">
            <div className="CustomDropDownSelect__options-container" style={this.props.customOptionsContainerStyle}>
              {options}
            </div>
          </div>
        </div>
      </CloseOnClickOutside>
    );
  }
}

export default CustomDropDownSelect;