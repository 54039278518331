//@flow
import * as React from 'react';
import RenderOnReady from "../../../../components/service/RenderOnReady/RenderOnReady";
import type {
  errorObject,
  history,
  isReadyToRender,
  locationType,
  match
} from "../../../../services/flowTypes/appFlowTypes";
import type {
  organizationId,
  reportStatus
} from "../../../../services/flowTypes/dataFlowTypes";
import connect from "react-redux/es/connect/connect";
import reportMainDataActions from "../../../../store/organization/item/reportMainData/reportMainDataActions";
import {onGetReportMainData, onInitReportMainData} from "../../../../services/flowTypes/propFnFlowTypes";
import concatIdName from "../../../../utils/concatIdName";
import api from "../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../services/apiInstance/apiErrorHandler";
import {reportViewPageIds} from "../../../../tests/testIds";
import text from "../../../../services/localization/text";
import FixedHeaderContent from "../../../../components/simple/Containers/FixedHeader/FixedHeaderContent";
import handleCorrectOrgIdInResponse from "../../../../utils/handleCorrectOrgIdInResponse";
import RedirectToCorrectOrgUrl from "../../../../components/service/RedirectToCorrectOrgUrl/RedirectToCorrectOrgUrl";
import FixedSubHeader from "../../../../components/simple/Containers/FixedHeader/FixedSubHeader";
import ReportMenu from "./report/ReportMenu/ReportMenu";

type Props = {
  match: match,
  onGetReportMainData: onGetReportMainData,
  reportStatus: reportStatus,
  onInitReportMainData: onInitReportMainData,
  location: locationType,
  history: history
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  correctOrganizationId: organizationId
};
let timerID;
class Report extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      correctOrganizationId: null
    };
  }
  handleResponse = (responseData) => {
    this.props.onGetReportMainData(responseData);
    this.setState({isReadyToRender: true});
  };
  getReport = () => {
    const reportId = +this.props.match.params.id;
    api.getReportById(reportId)
      .then(response => {
        handleCorrectOrgIdInResponse(this, response.data, this.setState, this.handleResponse);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  componentDidUpdate(prevProps){
    if (prevProps.reportStatus !== this.props.reportStatus
      && (this.props.reportStatus === 'SUCCESS' || this.props.reportStatus === 'FAILED') ) {
      clearInterval(timerID);
    }
    if (prevProps.reportStatus !== this.props.reportStatus
      && (this.props.reportStatus === 'SCHEDULED_FOR_ADJUST') ) {
      timerID = setInterval(this.getReport, 10000);
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.getReport();
    }
  }
  componentDidMount(){
    this.getReport();
    if (!this.props.reportStatus) {
      timerID = setInterval(this.getReport, 10000);
    }
  }
  componentWillUnmount(){
    this.props.onInitReportMainData();
    clearInterval(timerID);
  }
  render(){
    const organizationId = this.props.match.params.organizationId;
    const reportId = +this.props.match.params.id;
    const title = concatIdName(reportId, text.reportLabel);
    return (
      <RedirectToCorrectOrgUrl correctOrganizationId={this.state.correctOrganizationId} history={this.props.history}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
          <FixedHeaderContent title={text.reportsTitle} titleId={reportViewPageIds.headingReportTitle}
                              history={this.props.history}/>
          <FixedSubHeader title={title}
                          history={this.props.history} buttons={[]}/>
          <ReportMenu reportId={reportId} organizationId={organizationId}/>
        </RenderOnReady>
      </RedirectToCorrectOrgUrl>
    );
  }
}
const mapStateToProps = state => {
  return {
    reportStatus: state.organization.item.reportMainData.reportStatus
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onInitReportMainData: () => dispatch({
      type: reportMainDataActions.REPORT_MAIN_DATA_INIT}),
    onGetReportMainData: (reportMainData) => dispatch({
      type: reportMainDataActions.REPORT_MAIN_DATA_SET,
      value: reportMainData})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Report);