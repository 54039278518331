export const DataListItemString1 = "DataListContainer Item 1";
export const DataListItemString2 = "DataListContainer Item 2";
export const DataListItemString3 = "DataListContainer Item 3";

export const DataListContainerDefProps = {
  itemsList: [
    DataListItemString1,
    DataListItemString2,
    DataListItemString3
  ]
};