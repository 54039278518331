//@flow
import * as React from 'react';
import ItemDocuments from "../../components/composed/ItemDocuments/ItemDocuments";
import type {match, history, locationPathname} from "../../services/flowTypes/appFlowTypes";
import text from "../../services/localization/text";
import DocumentUploader from "../../components/composed/ItemDocuments/DocumentUploader/DocumentUploader";
import FixedHeaderContent from "../../components/simple/Containers/FixedHeader/FixedHeaderContent";
import {permissionsStrValues} from "../../configData/permissionsStrValues";

type Props = {
  match: match,
  history: history,
  location: locationPathname
};

class Documents extends React.Component <Props> {
  render(){
    const itemType= 'organizations';
    const itemId = +this.props.match.params.organizationId;
    const folderId = +this.props.match.params.folderId;
    const button = <DocumentUploader permittedAction={permissionsStrValues.organizationDocument.create} itemType={itemType}
                                     folderId={folderId} itemId={itemId} key={'uploadButton'}/>;
    return (
      <>
        <FixedHeaderContent title={text.documents}
                            pathname={this.props.location.pathname}
                            buttons={[button]}
                            history={this.props.history}
        />
        <ItemDocuments itemType={itemType} permittedActionObj={permissionsStrValues.organizationDocument} itemId={itemId}
                       folderId={folderId} pathname={this.props.location.pathname}/>
      </>
    );
  }
}
export default Documents;