//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id, onClick, disabled} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './ConvertTagBtn.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  onClick: onClick,
  disabled?: disabled
};

const ConvertTagBtn = (props: Props) => {
  return (
    <button type="button" id={props.id} onClick={props.onClick} disabled={props.disabled}
         className={baseClass("ConvertTagBtn", props.addClasses)}>
      <span className="ConvertTagBtn__ico-container"/>
    </button>
  );
};

export default ConvertTagBtn;