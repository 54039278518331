//@flow
import * as React from 'react';
import {connect} from "react-redux";
import NCard from "../../../../../../../../../components/simple/Containers/NCard/NCard";
import Text1 from "../../../../../../../../../components/simple/TextComponents/Text1/Text1";
import text from "../../../../../../../../../services/localization/text";
import NFormGroup from "../../../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import NSelect from "../../../../../../../../../components/simple/FormComponents/NSelect/NSelect";
import {actTemplatesEditPageIds} from "../../../../../../../../../tests/testIds";
import type {
  groupFinTermId,
  isScrollToError,
  validationIsAllowed
} from "../../../../../../../../../services/flowTypes/dataFlowTypes";
import type {selectOptions} from "../../../../../../../../../services/flowTypes/componentFlowTypes";

type Props = {
  validationForm: Object,
  isScrollToError: isScrollToError,
  validationIsAllowed: validationIsAllowed,
  finTermGroupIds: Array<groupFinTermId>,
  handleOptionsAndFinTermGroupIds: Function,
  selectGroupFinTermsOptions: selectOptions
};

class FinTermsSelect extends React.Component <Props> {
  handleSelectGroupFinTerm = (selectedOption) => {
    const notSortedFinTermGroupIds = this.props.finTermGroupIds.slice();
    notSortedFinTermGroupIds.push(selectedOption.value);
    const finTermGroupIds = notSortedFinTermGroupIds.sort((a, b) => { return a - b;});
    this.props.handleOptionsAndFinTermGroupIds(finTermGroupIds);
  };
  render() {
    const noOptionsMessage = () => text.allConditionsAreSelected;
    const propError = this.props.validationIsAllowed ?
      (this.props.validationForm.finTermGroupIds ? this.props.validationForm.finTermGroupIds : '')
      : '';
    return (
      <NCard addClasses={'mb_1du pos_rel z_1000'}>
          <Text1 addClasses={'mb_3du'}>{text.finTermsExplanation}</Text1>
          <NFormGroup propError={propError} isScrollToError={this.props.isScrollToError}>
              <NSelect value={null} onChange={this.handleSelectGroupFinTerm}
                       options={this.props.selectGroupFinTermsOptions}
                       placeholder={text.selectConditions} noOptionsMessage={noOptionsMessage}
                       isSearchable={true}
                       inputId={actTemplatesEditPageIds.selectInputGroupFinTerms}
                       id={actTemplatesEditPageIds.selectGroupFinTerms}/>
          </NFormGroup>
      </NCard>);
  }
}

const mapStateToProps = state => {
  return {
      validationForm: state.validation.form,
      isScrollToError: state.validation.isScrollToError,
      validationIsAllowed: state.validation.validationIsAllowed
  };
};
export default connect(mapStateToProps, null)(FinTermsSelect);