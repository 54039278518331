//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id, onClick} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './BtnEdit.css';
import type {isCardButtonMode} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  onClick?: onClick,
  isCardButtonMode?: isCardButtonMode,
  isTagExpressionHeaderMode?: boolean,
  isDropdownShown?: boolean
};

const BtnEdit = (props: Props) => {
  const className = props.isCardButtonMode ? "BtnEdit_cardButtonMode" :
    props.isTagExpressionHeaderMode ?
      (props.isDropdownShown ? "BtnEdit_tagExpressionMode BtnEdit_tagExpressionMode_dropDownShown" : "BtnEdit_tagExpressionMode")  :
    "BtnEdit";
  return (
    <button type="button" id={props.id} onClick={props.onClick}
         className={baseClass(className, props.addClasses)}>
      <span className="BtnEdit__ico-container"/>
    </button>
  );
};

export default BtnEdit;