//@flow
import organizationIdActions from './organizationIdActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {organizationId} from "../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = '';

const organizationIdReducer = (state:organizationId = initialState, action:reducerAction) => {
  // const newState = Object.assign({}, state);
  switch (action.type) {
    case organizationIdActions.ORGANIZATION_ID_SET:
      return action.value;
    default:
      return state;
  }
};

export default organizationIdReducer;