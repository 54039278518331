//@flow
import reportMainDataActions from './reportMainDataActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {report} from "../../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = {};

const reportMainDataReducer = (state:report = initialState, action:reducerAction) => {
  switch (action.type) {
    case reportMainDataActions.REPORT_MAIN_DATA_INIT:
      return initialState;
    case reportMainDataActions.REPORT_MAIN_DATA_SET:
      return action.value;
    default:
      return state;
  }
};

export default reportMainDataReducer;