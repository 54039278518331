//@flow
import * as React from 'react';
import {connect} from "react-redux";
import itemDataParamsEditActions
  from "../../../../store/organization/item/itemDataParamsEdit/itemDataParamsEditActions";
import {onDeleteBlock, onDeleteBlockField, onUpdateBlockFields} from "../../../../services/flowTypes/propFnFlowTypes";
import type {fieldsLength, paramsBlockIndex} from "../../../../services/flowTypes/componentFlowTypes";
import ParamsBlockField from "./ParamsBlockField/ParamsBlockField";
import ParamsBlockName from "./ParamsBlockName/ParamsBlockName";
import ParamsBlockFieldsHandler from "./ParamsBlockFieldsHandler/ParamsBlockFieldsHandler";
import getUniqueKey from "../../../../utils/getUniqueKey";

type Props = {
  paramsBlockIndex: paramsBlockIndex,
  onUpdateBlockFields: onUpdateBlockFields,
  onDeleteBlock: onDeleteBlock,
  fieldsLength: fieldsLength,
  onDeleteBlockField: onDeleteBlockField
};

class ParamsBlock extends React.Component <Props, void> {
  render() {
    let paramsBlockFields = [];
    for (let i=0; i<this.props.fieldsLength; i++ ) {
      const onDeleteField = () => this.props.onDeleteBlockField(this.props.paramsBlockIndex, i);
      paramsBlockFields.push(<ParamsBlockField
        key={getUniqueKey('ParamsBlock'+ this.props.paramsBlockIndex + 'Field')}
        paramsBlockIndex={this.props.paramsBlockIndex}
        paramsBlockFieldIndex={i}
        onDeleteField={onDeleteField}
      />)
    }
    return (
      <div className="ParamsBlock mt_4du mb_3du">
        <ParamsBlockName onDeleteBlock={this.props.onDeleteBlock} paramsBlockIndex={this.props.paramsBlockIndex}/>
        <ParamsBlockFieldsHandler paramsBlockIndex={this.props.paramsBlockIndex}
                                  paramsBlockFieldsComponents={paramsBlockFields}/>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    fieldsLength: state.organization.item.itemDataParamsEdit[ownProps.paramsBlockIndex].fields.length
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onDeleteBlockField: (paramsBlockIndex, paramsBlockFieldIndex) => dispatch({
      type: itemDataParamsEditActions.PARAMS_BLOCK_FIELD_DELETE,
      value: { paramsBlockIndex, paramsBlockFieldIndex }
    })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ParamsBlock);