//@flow
import * as React from 'react';
import * as ReactDOM from "react-dom";
import type {sheetIsShown} from "../../../services/flowTypes/appFlowTypes";
import {connect} from "react-redux";
import searchInOrgContextActions from "../../../store/organization/searchInOrgContext/searchInOrgContextActions";
import type {
  onSetSearchInOrgContextInitState,
  onSetSearchInOrgContextResponseInitState
} from "../../../services/flowTypes/propFnFlowTypes";
import './SearchInOrgContext.css';
import SearchInOrgContextInput from "./searchInOrgContext/SearchInOrgContextInput";
import ButtonHideSearchInOrgContextSheet from "./searchInOrgContext/ButtonHideSearchInOrgContextSheet";
import SearchInOrgContextFetcher from "./searchInOrgContext/SearchInOrgContextFetcher";
import searchInOrgContextResponseActions
  from "../../../store/organization/searchInOrgContextResponse/searchInOrgContextResponseActions";
import SearchInOrgContextResults from "./searchInOrgContext/SearchInOrgContextResults";
import ForbidScrollUnder from "../../service/ForbidScrollUnder/ForbidScrollUnder";
import type {requestInProcess} from "../../../services/flowTypes/componentFlowTypes";

type Props = {
  sheetIsShown: sheetIsShown,
  onSetSearchInOrgContextInitState: onSetSearchInOrgContextInitState,
  onSetSearchInOrgContextResponseInitState: onSetSearchInOrgContextResponseInitState
};
type State = {
  requestInProcess: requestInProcess
};

class SearchInOrgContext extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      requestInProcess: false
    };
  }
  onStartRequest = () => {this.setState({requestInProcess: true})};
  onFinishRequest = () => {this.setState({requestInProcess: false})};
  initStoreState = () => {
    this.props.onSetSearchInOrgContextInitState();
    this.props.onSetSearchInOrgContextResponseInitState();
  };
  setInitSearchInOrgContextState = () => {
    this.initStoreState();
  };
  componentDidUpdate(prevProps) {
    if (prevProps.sheetIsShown && !this.props.sheetIsShown) {
      this.initStoreState();
    }
  }
  componentDidMount(){
    this.setInitSearchInOrgContextState();
  }
  render(){
    const SearchSheet = (
      <ForbidScrollUnder>
        <div className="SearchInOrgContext SearchSheet">
          <div className="SearchSheet__header-back"/>
          <div className="SearchSheet__content">
            <div className="flex_jc-sb">
              <SearchInOrgContextInput/>
              <ButtonHideSearchInOrgContextSheet/>
            </div>
            <SearchInOrgContextFetcher onStartRequest={this.onStartRequest} onFinishRequest={this.onFinishRequest}/>
            <SearchInOrgContextResults requestInProcess={this.state.requestInProcess}/>
          </div>
        </div>
      </ForbidScrollUnder>
      );
    let modal = document.getElementById('modal');
    return modal && this.props.sheetIsShown ?
      ReactDOM.createPortal(SearchSheet, modal)
      : null;
  }

}
const mapStateToProps = state => {
  return {
    sheetIsShown: state.organization.searchInOrgContext.sheetIsShown
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSetSearchInOrgContextInitState: () => dispatch({
      type: searchInOrgContextActions.SEARCH_IN_ORG_CONTEXT_INIT_STATE_SET}),
    onSetSearchInOrgContextResponseInitState: () => dispatch({
      type: searchInOrgContextResponseActions.SEARCH_IN_ORG_CONTEXT_RESPONSE_INIT_STATE_SET}),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchInOrgContext);

