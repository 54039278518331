//@flow
import * as React from 'react';
import type {
  actTemplateId,
  agentId,
  contractId,
  document,
  organizationId
} from "../../../../../services/flowTypes/dataFlowTypes";
import {baseClass} from "../../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../../../services/flowTypes/uiKitFlowTypes";
import './Document.css';
import formatDate from "../../../../../utils/formatDate";
import formatTime from "../../../../../utils/formatTime";
import text from "../../../../../services/localization/text";
import type {
  descriptionEdit,
  documentBtns, isActTemplateDocument, isAddendumTemplateFile,
  isDocumentDescriptionEdit, isLinkAppearance, isUpload,
  itemType, linkIdToDownload
} from "../../../../../services/flowTypes/componentFlowTypes";
import api from "../../../../../services/apiInstance/api";
import type {errorObject, isReadyToRender} from "../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../service/RenderOnReady/RenderOnReady";
import apiErrorHandler from "../../../../../services/apiInstance/apiErrorHandler";
import createTempLinkToDownloadFile from "../../../../../utils/createTempLinkToDownloadFile";
import getDocumentType from "../../../../../utils/getDocumentType";
import getDocumentSize from "../../../../../utils/getDocumentSize";
import TextOverflowHandler from "../../../../simple/Containers/TextOverflowHandler/TextOverflowHandler";
import {connect} from "react-redux";
import DocumentIndexStatusIcon from "../../../../simple/Icons/DocumentIndexStatusIcon/DocumentIndexStatusIcon";
import Text4 from "../../../../simple/TextComponents/Text4/Text4";
import HoveredIcon from "../../../HoveredIcon/HoveredIcon";
import fileIconSrc from "../../../../../assets/file-icon.svg";
import fileHoveredIconSrc from "../../../../../assets/file-hovered-icon.svg";
import {Link} from "react-router-dom";

type Props = {
  document: document,
  addClasses?: addClasses,
  itemType?: itemType,
  itemId: contractId | agentId | actTemplateId,
  documentBtns?: documentBtns,
  isDocumentDescriptionEdit?: isDocumentDescriptionEdit,
  descriptionAndFolderEdit?: descriptionEdit,
  isUpload?: isUpload,
  isActTemplateDocument?: isActTemplateDocument,
  isAddendumTemplateFile?: isAddendumTemplateFile,
  linkIdToDownload?: linkIdToDownload,
  isDocumentEdit?: boolean,
  isLinkAppearance?: isLinkAppearance,
  organizationId: organizationId,
  pathname: string
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};

class Document extends React.Component <Props, State> {
  constructor(props:Props){
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null
    };
  }
  definePromise = () => {
    if (this.props.itemType === 'contract') {
      return api.getContractDocument;
    }
    if (this.props.itemType === 'agent') {
      return api.getAgentDocument;
    }
    if (this.props.itemType === 'organizations') {
      return api.getOrganizationDocument;
    }
    if (this.props.isActTemplateDocument) {
      return api.getActTemplateDocument;
    }
    if (this.props.isAddendumTemplateFile) {
      return api.getAddendumTemplateFile;
    }
  };
  getDocument = () => {
    const promise = this.definePromise();
    if (promise) {
      this.setState({isReadyToRender: false});
      if (this.props.isActTemplateDocument || this.props.isAddendumTemplateFile) {
        promise(this.props.itemId)
          .then(response => {
            this.setState({isReadyToRender: true});
            createTempLinkToDownloadFile(response.data, this.props.document.name);
          })
          .catch(error => {
            this.setState({
              isReadyToRender: true,
              errorObject: apiErrorHandler(error)
            });
          });
      } else {
        promise(this.props.itemId, this.props.document.fileId, this.props.document.name)
          .then(response => {
            this.setState({isReadyToRender: true});
            createTempLinkToDownloadFile(response.data, this.props.document.name);
          })
          .catch(error => {
            this.setState({
              isReadyToRender: true,
              errorObject: apiErrorHandler(error)
            });
          });
      }
    } else {
      console.error('Promise is not defined. Internal client error!');
    }


  };
  render(){
    const folderUrl = this.props.document.folderId && this.props.pathname ?
      this.props.pathname.includes('/folder/') ? this.props.pathname : this.props.pathname + "/folder/" + this.props.document.folderId
      : '';
    const getDocOnClick = this.props.isUpload ? null : this.getDocument;
    const urlencodedDocumentName = encodeURIComponent(this.props.document.name);
    const linkToDownloadDoc = this.props.isActTemplateDocument ?
        `/actTemplate/${this.props.itemId}/downloadFile?o_id=${this.props.organizationId}` :
      this.props.isAddendumTemplateFile ? `/actTemplate/${this.props.itemId}/downloadAddendumTemplateFile?o_id=${this.props.organizationId}`
            : `/${this.props.itemType}/${this.props.itemId}/downloadDocument/${this.props.document.fileId}/${urlencodedDocumentName}?o_id=${this.props.organizationId}`;
    return (
      this.props.isLinkAppearance ? (
        // <BtnEditAct onClick={getDocOnClick}>{this.props.document.name}</BtnEditAct>
          <a className={'BtnEditAct'} target={"_blank"} rel="noopener noreferrer"
             href={linkToDownloadDoc}>
            <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>
              {this.props.document.name}</TextOverflowHandler>
          </a>
      ) : (
        <>
          {this.props.isDocumentEdit ? this.props.editModal : null}
          <div className={baseClass("Document", this.props.addClasses)}>
            <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
            <div className="Document__left">
              <div className="Document__icon-container">
                <div className={"mb_1du doc__ico"} onClick={getDocOnClick}>
                  <HoveredIcon baseIconSrc={fileIconSrc} hoveredIconSrc={fileHoveredIconSrc}/>
                  <div className="doc__ico-content">{getDocumentType(this.props.document.name)}</div>
                </div>
              </div>
              <div className="Document__name-and-data">
                <div className="Document__name-container flex">
                  <a id={this.props.linkIdToDownload} className={'Heading4 Document__name'} target={"_blank"} rel="noopener noreferrer"
                     href={linkToDownloadDoc}>
                    <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>
                      {this.props.document.name}</TextOverflowHandler>
                  </a>

                </div>
                <div className="Document__description-container">
                  {this.props.isDocumentDescriptionEdit ? (
                    this.props.descriptionAndFolderEdit
                  ) : (
                    <Text4>{this.props.document.fileDescription}</Text4>
                  )}
                </div>
                <div className="Document__size-and-index">
                  {this.props.document.hasOwnProperty('size') ?
                    <Text4 addClasses='doc__size Text4_fs10'>{getDocumentSize(this.props.document.size)}</Text4> : null}
                  {this.props.document.indexStatus ? (
                    <DocumentIndexStatusIcon indexStatus={this.props.document.indexStatus} addClasses={'mr_2du'}
                                             indexStatusMessage={this.props.document.indexStatusMessage}/>
                  ) : null}
                  {this.props.document.folderId && this.props.pathname ? (
                    <div className="Document__linkToFolder">
                      <div className="Document__linkToFolderIco"/>
                      <Link className={'Text4 Text4_linkColorOnHover'} to={folderUrl}>
                        <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{this.props.document.folderName}</TextOverflowHandler>
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>

            </div>
            <div className="Document__right">
              <Text4 addClasses={'Document__date'}>
                {(this.props.isActTemplateDocument || this.props.isAddendumTemplateFile) ? (
                  (this.props.document.createDate ? formatDate(this.props.document.createDate) : 'N/A')
                ) : (
                  formatDate(this.props.document.createDate)
                  + ' ' + text.prepositionAtTime + ' ' + formatTime(this.props.document.createDate)
                )}
              </Text4>
              {this.props.documentBtns}
            </div>


            {this.props.document.isFileDeleted ? (
              <div className="Document__isDeletedMessage">{text.fileWillBeDeleted}</div>
            ) : null}
          </div>
        </>
        )

    );
  }
}

const mapStateToProps = state => {
  return {
    organizationId: state.organization.organizationId
  };
};

export default connect(mapStateToProps, null)(Document);
