//@flow
import * as React from 'react';
import type {filtersFromParams, history, match} from "../../../../../../services/flowTypes/appFlowTypes";
import HistoryView from "../../../../../../components/composed/HistoryView/HistoryView";
import type {setFiltersToUrl} from "../../../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  filtersFromParams: filtersFromParams,
  setFiltersToUrl: setFiltersToUrl,
  match:match,
  history: history
};

const AgentHistory = (props: Props) => {
  const agentId = +props.match.params.id;
  return (
    <HistoryView filtersFromParams={props.filtersFromParams} setFiltersToUrl={props.setFiltersToUrl}
                 historyType={'agent'} itemId={agentId} routerHistory={props.history}/>
  );
};

export default AgentHistory;    