//@flow
import * as React from 'react';
import text from "../../../services/localization/text";
import type {searchCategory} from "../../../services/flowTypes/dataFlowTypes";

type Props = {
  category: searchCategory
};

class AddItemRowHeading extends React.Component <Props> {
  defineHeading = () => {
    switch (this.props.category) {
      case "agents":
        return (
          <div className="AddItemRowHeading">
            <div className="AddAgentRow__name">{text.addItemRowHeading.name}
            </div>
            <div className="AddAgentRow__createDate">{text.addItemRowHeading.createDate}</div>
            <div className="AddAgentRow__statusLabel">{text.addItemRowHeading.status}</div>
            <div className="AddAgentRow__statusDate">{text.addItemRowHeading.statusDate}</div>
          </div>
        );
      case "contracts":
        return (
          <div className="AddItemRowHeading">
            <div className="AddContractRow__name">{text.addItemRowHeading.name}</div>
            <div className="AddContractRow__contractNo">{text.addItemRowHeading.contractNo}</div>
            <div className="AddContractRow__validityTerm">{text.addItemRowHeading.validityTerm}</div>
            <div className="AddContractRow__contractTypeName">{text.addItemRowHeading.contractType}</div>
            <div className="AddContractRow__statusLabel">{text.addItemRowHeading.status}</div>
            <div className="AddContractRow__statusDate">
              {text.addItemRowHeading.statusDate}
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  render() {
    return this.defineHeading();
  }
}

export default AddItemRowHeading;    