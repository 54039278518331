//@flow
import searchRequestActions from './searchRequestActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {searchRequest} from "../../../services/flowTypes/dataFlowTypes";

type State = searchRequest;
type reducerAction = action;
const initialState = {
  category: '',
  // selectedTags: [],
  // startDate: '',
  // endDate: '',
  // status: '',
  text: '',
  count: 20,
  offset: 0,
  searchPropName: 'name',
  // startCreateDate: '',
  // endCreateDate: '',
  sortingOrder: 'up',
  typeSortContract: 'id',
  typeSortAgent: 'id'
};

const searchRequestReducer = (state:State = initialState, action:reducerAction) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case searchRequestActions.SEARCH_REQUEST_STATE_INIT:
      return initialState;
    case searchRequestActions.SEARCH_REQUEST_PROP_SET:
      return {...newState, ...action.value};
    case searchRequestActions.SEARCH_REQUEST_PROP_DELETE:
      for (let i=0; i < action.value.length; i++) {
        delete newState[action.value[i]];
      }
      return newState;
    default:
      return state;
  }
};

export default searchRequestReducer;