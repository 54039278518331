//@flow
import linkedItemsRequestParamsActions from './linkedItemsRequestParamsActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {linkedItemsRequestParams} from "../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = {
  category: ''
};

const linkedItemsRequestParamsReducer = (state:linkedItemsRequestParams = initialState, action:reducerAction) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case linkedItemsRequestParamsActions.LINKED_ITEMS_REQUEST_PARAMS_STATE_INIT:
      return initialState;
    case linkedItemsRequestParamsActions.LINKED_ITEMS_REQUEST_PARAMS_PROP_SET:
      return {...newState, ...action.value};
    default:
      return state;
  }
};

export default linkedItemsRequestParamsReducer;