//@flow
import * as React from 'react';
import DocumentUploader from "../../../../../../components/composed/ItemDocuments/DocumentUploader/DocumentUploader";
import type {contractId} from "../../../../../../services/flowTypes/dataFlowTypes";
import type {match} from "../../../../../../services/flowTypes/appFlowTypes";
import {permissionsStrValues} from "../../../../../../configData/permissionsStrValues";

type Props = {
  contractId: contractId,
  match: match
};

const UploadContractDocumentButton = (props: Props) => {
  const folderId = +props.match.params.folderId;
  return (
    <DocumentUploader permittedAction={permissionsStrValues.contractDocument.create} itemType={'contract'}
                      folderId={folderId} itemId={props.contractId}/>
  );
};

export default UploadContractDocumentButton;    