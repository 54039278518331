//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  formFieldType, inputDelimiter, isSearchable, labelTooltip,
  propName,
  propValue, selectOptions, sourceParamIndex
} from "../../../../../../../../../../services/flowTypes/componentFlowTypes";
import {
  onUpdateSourceParam
} from "../../../../../../../../../../services/flowTypes/propFnFlowTypes";
import type {id, label, placeholder} from "../../../../../../../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../../../../../../../services/flowTypes/uiKitFlowTypes";
import ConnectedFormGroup
  from "../../../../../../../../../../components/composed/ConnectedFormGroup/ConnectedFormGroup";
import sourceParamsEditActions
  from "../../../../../../../../../../store/organization/item/sourceParamsEdit/sourceParamsEditActions";
import type {paramMask} from "../../../../../../../../../../services/flowTypes/dataFlowTypes";
import validationActions from "../../../../../../../../../../store/validation/validationActions";
import type {
  onDeleteValidationStateFormProp,
  onGetValidationStateFormProp
} from "../../../../../../../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  sourceParamIndex: sourceParamIndex,
  formFieldType: formFieldType,
  propName: propName,
  propValue: propValue,
  onUpdateSourceParam: onUpdateSourceParam,
  onDeleteValidationStateFormProp: onDeleteValidationStateFormProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  label: label,
  options?: selectOptions,
  addClasses?: addClasses,
  idForTest?: id,
  placeholder?: placeholder,
  inputDelimiter?: inputDelimiter,
  mask?: paramMask,
  selectInputId?: id,
  labelTooltip?: labelTooltip,
  isSearchable?: isSearchable
};

class SourceParamFormGroup extends React.Component <Props, void> {
  onGetPropValue = (prop, data) => {
    this.props.onUpdateSourceParam(this.props.sourceParamIndex, data);
  };
  componentWillUnmount(){
    this.props.onDeleteValidationStateFormProp(this.props.propName);
  }
  render() {
    return (
      <ConnectedFormGroup
        formFieldType={this.props.formFieldType}
        propName={this.props.propName}
        propValue={this.props.propValue}
        onGetPropValue={this.onGetPropValue}
        creationIsProhibited={true}
        label={this.props.label}
        options={this.props.options}
        addClasses={this.props.addClasses}
        idForTest={this.props.idForTest}
        placeholder={this.props.placeholder}
        inputDelimiter={this.props.inputDelimiter}
        mask={this.props.mask}
        selectInputId={this.props.selectInputId}
        isSearchable={this.props.isSearchable}
        labelTooltip={this.props.labelTooltip}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propValue: state.organization.item.sourceParamsEdit[ownProps.sourceParamIndex].value
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateSourceParam: (sourceParamIndex, sourceParamValue) => dispatch({
      type: sourceParamsEditActions.SOURCE_PARAM_UPDATE,
      value: {sourceParamIndex, sourceParamValue}}),
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onDeleteValidationStateFormProp: (prop) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_DELETE, value: prop})

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceParamFormGroup);