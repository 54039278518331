//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {alerts} from "../../../services/flowTypes/appFlowTypes";
import ConnectedAlertCard from "./ConnectedAlertCard";
import './Alerts.css';
import type {onDeleteAlert} from "../../../services/flowTypes/propFnFlowTypes";

type Props = {
  alerts: alerts,
  onDeleteAlert: onDeleteAlert
};

class Alerts extends React.Component <Props>{
  render(){
    const needToReduceAlerts = this.props.alerts.length > 2;
    const requiredAlertsLength = this.props.alerts.length - 2;
    const alerts = this.props.alerts.map((alert, i) => {
      const isInitSelfDelete = needToReduceAlerts && i < requiredAlertsLength;
      return (<ConnectedAlertCard key={'alert'+alert.id} alert={alert} alertIndex={i} isInitSelfDelete={isInitSelfDelete}/>)
    });
    return (
      <div className="Alerts">
        {alerts}
      </div>
    );
  }

}
const mapStateToProps = state => {
  return {
    alerts: state.alerts
  };
};
export default connect(mapStateToProps, null)(Alerts);