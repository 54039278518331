//@flow
import itemDataParamsListActions from './itemDataParamsListActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {objectParamList} from "../../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = [];

const itemDataParamsListReducer = (state:objectParamList = initialState, action:reducerAction) => {
  switch (action.type) {
    case itemDataParamsListActions.ITEM_DATA_PARAMS_LIST_SET:
      return action.value;
    default:
      return state;
  }
};

export default itemDataParamsListReducer;