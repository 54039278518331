//@flow
import * as React from 'react';
import PropValueView from "../../../../../../../components/composed/PropValueView/PropValueView";
import type {groupFinTerm} from "../../../../../../../services/flowTypes/dataFlowTypes";
import getCurrencyLabel from "../../../../../../../utils/getCurrencyLabel";
import ConvertedTagLabels
  from "../../../../../../Contracts/pages/Contract/pages/ActTemplatesEdit/actTemplatesEdit/FinTermsSelect/ActTemplateFinTermsCards/ConvertedTagLabels";
import {reportViewPageIds} from "../../../../../../../tests/testIds";

type Props = {
  groupFinTerm: groupFinTerm
};

const ConvertedDataRow = (props:Props) => {
  return (
    props.groupFinTerm.source === 'REDMINE' ? (
      (props.groupFinTerm.convertedRateAmount) ? (
        <div className="flex jc_fe">
          <div>
            <PropValueView addClasses={'mb_half-du'} value={<>
              <span className={'mr_1du'} id={reportViewPageIds.spanConvertedRateAmount(props.groupFinTerm.groupId)}>
                {props.groupFinTerm.convertedRateAmount.value}</span> {getCurrencyLabel(props.groupFinTerm.convertedRateAmount.currency)}
            </>}/>
            <ConvertedTagLabels convertedTag={props.groupFinTerm.convertedRateAmount}/>
          </div>
        </div>
      ) : null
    ) : (
      (props.groupFinTerm.convertedRateAmount || props.groupFinTerm.convertedTotalAmount) ? (
        <div className="flex_jc-sb">
          <div className={'w_half-card'}>
            {props.groupFinTerm.convertedRateAmount ? (
              <>
                <PropValueView  addClasses={'mb_half-du'} value={
                  <>
                    <span className={'mr_1du'} id={reportViewPageIds.spanConvertedRateAmount(props.groupFinTerm.groupId)}>
                      {props.groupFinTerm.convertedRateAmount.value}</span> {getCurrencyLabel(props.groupFinTerm.convertedRateAmount.currency)}
                  </>
                }/>
                <ConvertedTagLabels convertedTag={props.groupFinTerm.convertedRateAmount}/>
              </>
            ) : null}
          </div>
          {props.groupFinTerm.convertedTotalAmount ? (
            <div>
              <PropValueView addClasses={'mb_half-du ta_r'} value={<>
                <span className={'mr_1du'} id={reportViewPageIds.spanConvertedTotalAmount(props.groupFinTerm.groupId)}>
                  {props.groupFinTerm.convertedTotalAmount.value}</span> {getCurrencyLabel(props.groupFinTerm.convertedTotalAmount.currency)}
              </>}/>
              <ConvertedTagLabels convertedTag={props.groupFinTerm.convertedTotalAmount}/>
            </div>
          ) : null}
        </div>
      ) : null)
  );
};
export default ConvertedDataRow;
