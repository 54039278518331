//@flow
import * as React from 'react';
import {connect} from "react-redux";
import NFormGroup from "../../../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import validationActions from "../../../../../../../../../store/validation/validationActions";
import type {
  groupFinTermIndex,
  propError
} from "../../../../../../../../../services/flowTypes/componentFlowTypes";
import type {
  onDeleteValidationStateFormProp,
  onGetValidationStateFormProp,
  onGetValidationStateProp
} from "../../../../../../../../../services/flowTypes/propFnFlowTypes";
import type {children} from "../../../../../../../../../services/flowTypes/appFlowTypes";
import type {
  actTemplateParam, groupFinTerm, groupFinTermId,
  isScrollToError, templateParams,
  validationIsAllowed
} from "../../../../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../../../../services/localization/text";

type Props = {
  groupFinTermIndex: groupFinTermIndex,
  onGetValidationStateProp: onGetValidationStateProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  onDeleteValidationStateFormProp: onDeleteValidationStateFormProp,
  children: children,
  validationIsAllowed: validationIsAllowed,
  isScrollToError: isScrollToError,
  propError: propError,
  actTemplateParam: actTemplateParam,
  groupFinTermId: groupFinTermId,
  groupFinTerms: Array<groupFinTerm>,
  templateParams: templateParams
};

class CurrencyConversionValidator extends React.Component <Props> {
  areConversionSourcesMatched = () => {
    const conversionSources = [];
    for (let i=0; i < this.props.templateParams.length; i++) {
      for (let j=0; j<this.props.templateParams[i].actTemplateParams.length; j++) {
        if (this.props.templateParams[i].actTemplateParams[j].convertedTag) {
          conversionSources.push(this.props.templateParams[i].actTemplateParams[j].convertedTag.source);
        }
      }
    }
    let areMatched = true;
    if (conversionSources.length > 1) {
      for (let m=0; m < conversionSources.length-1; m++) {
        areMatched = areMatched && conversionSources[m] === conversionSources[m+1];
      }
    }
    return areMatched;
  };
  defineError = () => {
    // !calcCurrency is deleted, but this code has been leaved commented, because it is may be applied for validation of currency of groupFinTerm

    // const groupFinTermObj = this.props.groupFinTerms.find(item => item.groupId === this.props.groupFinTermId);
    // const calcCurrency = groupFinTermObj ? groupFinTermObj.calcCurrency : null;
    // if (calcCurrency && this.props.actTemplateParam.convertible && !this.props.actTemplateParam.convertedTag) {
    //   return text.error.convertedTagIsNotAdded;
    // } else if (calcCurrency && this.props.actTemplateParam.convertible && this.props.actTemplateParam.convertedTag) {
    //   if (this.props.actTemplateParam.convertedTag.currency !== calcCurrency) {
    //     return text.error.conversionCurrencyIsNotMatched;
    //   }
    //   if (!this.areConversionSourcesMatched()) {
    //     return  text.error.conversionSourceIsNotMatched;
    //   }
    //   if (this.props.actTemplateParam.convertedTag.date !== 'TX_DATE') {
    //     return text.error.conversionDateIsNotMatched;
    //   }
    //   return ''
    // } else {
    //   return '';
    // }

    if (this.props.actTemplateParam.convertible && this.props.actTemplateParam.convertedTag) {
      // if (this.props.actTemplateParam.convertedTag.currency !== calcCurrency) {
      //   return text.error.conversionCurrencyIsNotMatched;
      // }
      // if (!this.areConversionSourcesMatched()) { // turned off because Vitia asked 25.11.21 in private chat
      //   return  text.error.conversionSourceIsNotMatched;
      // }
      if (this.props.actTemplateParam.convertedTag.date !== 'TX_DATE') {
        return text.error.conversionDateIsNotMatched;
      }
      return ''
    } else {
      return '';
    }
  };
  validate = () => {
    // const error = this.defineError();
    const error = '';
    this.props.onGetValidationStateProp('isScrollToError', false);
    this.props.onGetValidationStateFormProp(this.props.actTemplateParam.name + 'ConversionCurrency'+this.props.groupFinTermIndex, error);
  };
  componentDidUpdate(prevProps){
    if (prevProps.groupFinTermIndex !== this.props.groupFinTermIndex
      || prevProps.actTemplateParam !== this.props.actTemplateParam) {
      this.validate();
    }
  }
  componentDidMount(){
    this.validate();
  }
  componentWillUnmount(){
    this.props.onDeleteValidationStateFormProp(this.props.actTemplateParam.name + 'ConversionCurrency'+this.props.groupFinTermIndex);
  }
  render() {
    const propError = this.props.validationIsAllowed ? this.props.propError : '';
    return (
      <NFormGroup isScrollToError={this.props.isScrollToError} propError={propError} addClasses={'flex w_100'}>
        {this.props.children}
      </NFormGroup>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propError: state.validation.form[ownProps.actTemplateParam.name + 'ConversionCurrency'+ownProps.groupFinTermIndex],
    validationIsAllowed: state.validation.validationIsAllowed,
    isScrollToError: state.validation.isScrollToError,
    groupFinTerms: state.organization.item.groupFinTermsView,
    templateParams: state.organization.item.templateParams
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetValidationStateProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onDeleteValidationStateFormProp: (prop) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_DELETE, value: prop})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyConversionValidator);