//@flow
import * as React from 'react';
import {connect} from "react-redux";
import api from "../../../services/apiInstance/api";
import type {children} from "../../../services/flowTypes/appFlowTypes";
import GetPrerenderData from "../GetPrerenderData/GetPrerenderData";
import type {admin} from "../../../services/flowTypes/dataFlowTypes";
import {onGetAdminDataProp} from "../../../services/flowTypes/propFnFlowTypes";
import adminActions from "../../../store/admin/adminActions";

type Props = {
  children: children,
  admin: admin,
  onGetAdminDataProp: onGetAdminDataProp
};

class GetAdminData extends React.Component <Props> {
  render() {
    const fetchFnArr = [
      {
        fetch: api.getAllOrganizationsLists, propName: 'allOrganizationsList'
      },
      {
        fetch: api.getRolesLists, propName: 'rolesList'
      },
      {
        fetch: api.getGlobalPermissionsLists, propName: 'globalPermissionsList'
      },
      {
        fetch: api.getAdminStatuses, propName: 'statuses'
      }
    ];
    return (
      <GetPrerenderData fetchFnArr={fetchFnArr} storeDataObj={this.props.admin} setFn={this.props.onGetAdminDataProp}>
        {this.props.children}
      </GetPrerenderData>
    );
  }
}

const mapStateToProps = state => {
  return {
    admin: state.admin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAdminDataProp: (prop, data) => dispatch({type: adminActions.ADMIN_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetAdminData);    