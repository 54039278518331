//@flow
import * as React from 'react';
import type {notificationResponse, organizationId} from "../../../services/flowTypes/dataFlowTypes";
import NCard from "../../simple/Containers/NCard/NCard";
import type {editMode, isSupLevel} from "../../../services/flowTypes/componentFlowTypes";
import './NotificationSettingsForm.css';
import text from "../../../services/localization/text";
import TextOverflowHandler from "../../simple/Containers/TextOverflowHandler/TextOverflowHandler";
import NotificationSettingsOrgLabel from "./NotificationSettingsOrgLabel";
import NotificationSettingsFormInput from "./NotificationSettingsFormInput";
import NotificationSettingsSetAllForOrgBtn from "./NotificationSettingsSetAllForOrgBtn";

type Props = {
  notificationSettings: notificationResponse,
  editMode?: editMode,
  isSupLevel: isSupLevel,
  orgIdToFilter?: organizationId
};

class NotificationSettingsForm extends React.Component <Props> {
  defineOrgList = () => {
    const orgList = [];
    const blankDiv = (<div key={'blankDiv'} className={'NotificationSettingsForm__header-item-org'}>
      {text.notificationSettingsFormHeader.organizationHeader}
    </div>);
    orgList.push(blankDiv);
    const orgNumber = this.props.notificationSettings.length;
    for (let i=0; i < orgNumber; i++) {
      if (!this.props.orgIdToFilter || this.props.notificationSettings[i].orgId === this.props.orgIdToFilter) {
        const orgLabel = (<NotificationSettingsOrgLabel key={'orgLabel'+i}
                                organizationId={this.props.notificationSettings[i].orgId}/>);
        orgList.push(orgLabel);
      }
    }
    if (this.props.isSupLevel) {
      const orgAllLabel = (<div key={'orgAllLabel'} className="NotificationSettingsOrgLabel">{text.notificationForAllOrgsLabel}</div>);
      orgList.push(orgAllLabel);
    }
    return orgList;
  };
  defineGridTemplateColumns = (inputColumnNumber) => {
    const inputColumnWidth = window.innerWidth > 1050 ? 50 : 24;
    const inputColumnWidthCss = inputColumnWidth + 'px';
    let gridTemplateColumns = "auto " + (inputColumnNumber + 1) * inputColumnWidth + 'px';
    let gridTemplateColumnsRight = "";
    for (let i=0; i < inputColumnNumber+1; i++) {
      gridTemplateColumnsRight = gridTemplateColumnsRight + " " + inputColumnWidthCss;
    }
    return {gridTemplateColumns, gridTemplateColumnsRight};
  };
  defineHeaderItems = (inputColumnNumber) => {
    const headerItems = [];
    for (let i=0; i < inputColumnNumber; i++) {
      const notificationCategory = this.props.notificationSettings[0].notifications[i].notificationCategory;
      const localizedStr = text.notificationSettingsFormHeader[notificationCategory];
      const headerItem = <div key={'header-item-'+i} className="NotificationSettingsForm__header-item-container">
          <div className="NotificationSettingsForm__header-item">
            <TextOverflowHandler>{localizedStr ? localizedStr : notificationCategory}</TextOverflowHandler>
          </div>
      </div>;
      headerItems.push(headerItem);
    }
    const categoryAllColumnHeader = (<div key={'header-item-all'} className="NotificationSettingsForm__header-item-container">
      <div className="NotificationSettingsForm__header-item">
        <TextOverflowHandler>{text.notificationSettingsFormHeader.ALL}</TextOverflowHandler>
      </div>
    </div>);
    headerItems.push(categoryAllColumnHeader);
    return headerItems;
  };
  defineNotificationSettingsItems = () => {
    let notificationSettingsItems = [];
    let setAllCatBtns = [];
    for (let i=0; i < this.props.notificationSettings.length; i++) {
      let allCategoryVal = true;
      if (!this.props.orgIdToFilter || this.props.orgIdToFilter === this.props.notificationSettings[i].orgId) {
        for (let j=0; j < this.props.notificationSettings[i].notifications.length; j++) {
          const turnedOn = this.props.notificationSettings[i].notifications[j].turnedOn
          setAllCatBtns[j] = i===0 ? turnedOn :  setAllCatBtns[j] && turnedOn;
          const item = (<NotificationSettingsFormInput key={'row' + i + 'item' + j} editMode={this.props.editMode}
                                                       orgNotificationIndex={i} notificationIndex={j} />);
          notificationSettingsItems.push(item);
          allCategoryVal = allCategoryVal && turnedOn;
        }
        const setAllForOrgBtn = (<NotificationSettingsSetAllForOrgBtn turnedOn={allCategoryVal} key={'setAllForOrgBtn'+i}
                                                                      editMode={this.props.editMode} orgNotificationIndex={i}/>);
        notificationSettingsItems.push(setAllForOrgBtn);
      }
    }
    if (this.props.isSupLevel) {
      let allCategoryVal = true;
      for (let i=0; i<setAllCatBtns.length; i++){
        const setAllForCategoryBtn = (<NotificationSettingsSetAllForOrgBtn turnedOn={setAllCatBtns[i]}
                                                                           key={'setAllForCategoryBtn'+i}
                                                                           editMode={this.props.editMode}
                                                                           notificationIndex={i}/>);
        notificationSettingsItems.push(setAllForCategoryBtn);
        allCategoryVal = allCategoryVal && setAllCatBtns[i];
      }
      const setAllForOrgBtn = (<NotificationSettingsSetAllForOrgBtn turnedOn={allCategoryVal} key={'setAllForAllOrgBtn'}
                                                                    editMode={this.props.editMode} />);
      notificationSettingsItems.push(setAllForOrgBtn);
    }
    return notificationSettingsItems;
  };
  render() {
    if (!this.props.notificationSettings || this.props.notificationSettings.length === 0) {
      return <NCard>{text.noData}</NCard>
    }
    const inputColumnNumber = this.props.notificationSettings[0].notifications.length;
    const {gridTemplateColumns, gridTemplateColumnsRight} = this.defineGridTemplateColumns(inputColumnNumber);
    const headerItems = this.defineHeaderItems(inputColumnNumber);
    const orgList = this.defineOrgList();
    const notificationSettingsItems = this.defineNotificationSettingsItems();
    return (
      <NCard>
        <div className="NotificationSettingsForm" style={{gridTemplateColumns: gridTemplateColumns}}>
          <div className="NotificationSettingsForm__left">
            {orgList}
          </div>
          <div className="NotificationSettingsForm__right" style={{gridTemplateColumns: gridTemplateColumnsRight}}>
            {headerItems}
            {notificationSettingsItems}
          </div>
        </div>
      </NCard>
    );
  }
}

export default NotificationSettingsForm;