//@flow
import * as React from 'react';
import type {id} from "../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import './VerifiedStatusLabel.css';
import text from "../../../services/localization/text";
import ButtonTooltipContainer from "../../simple/Containers/ButtonTooltipContainer/ButtonTooltipContainer";
import StatusLabel from "../../simple/TextComponents/StatusLabel/StatusLabel";
import type {isVerified, itemDate, userId, usersList} from "../../../services/flowTypes/dataFlowTypes";
import {connect} from "react-redux";
import formatDate from "../../../utils/formatDate";
import formatTime from "../../../utils/formatTime";

type Props = {
  id?: id,
  addClasses?: addClasses,
  isVerified: isVerified,
  isVerifiedByUserId: userId,
  isVerifiedAtDate: itemDate,
  usersList: usersList
};

class VerifiedStatusLabel extends React.Component <Props> {
  render() {
    const verifiedStatus = this.props.isVerified ? 'VERIFIED' : 'UNVERIFIED';
    const verifiedStatusText = text.verifiedStatusText[verifiedStatus];
    const user = this.props.usersList.find(item => item.id === this.props.isVerifiedByUserId);
    const userName = user ? user.name : 'No user data';
    const dateTime = this.props.isVerifiedAtDate ?
      formatDate(this.props.isVerifiedAtDate) + " " + formatTime(this.props.isVerifiedAtDate)
      : 'No time data';
    const tooltipContent = (<>
      <div className={'ws_n'}>{userName}</div>
      <div className={'ws_n'}>{dateTime}</div>
    </>);
    return (
      <div className={this.props.addClasses}>
        <ButtonTooltipContainer tooltipContent={tooltipContent} tooltipPosition={'16-from-bottom'}
                                addClasses={'ButtonTooltipContainer_medium'}>
          <StatusLabel id={this.props.id} addClasses={`StatusLabel_${verifiedStatus}`}>
            {verifiedStatusText}</StatusLabel>
        </ButtonTooltipContainer>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    usersList: state.organization.organizationData.usersList
  };
};
export default connect(mapStateToProps)(VerifiedStatusLabel);