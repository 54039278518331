//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  finTermIndex,
  propName,
  propValue,
  selectOptions
} from "../../../../../../../../../../../services/flowTypes/componentFlowTypes";
import type {isReadyToRender} from "../../../../../../../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import finTermsEditActions from "../../../../../../../../../../../store/organization/item/finTermsEdit/finTermsEditActions";
import {onUpdateFinTermProp} from "../../../../../../../../../../../services/flowTypes/propFnFlowTypes";
import text from "../../../../../../../../../../../services/localization/text";
import type {addClasses} from "../../../../../../../../../../../services/flowTypes/uiKitFlowTypes";
import {groupFinTermEditPageIds} from "../../../../../../../../../../../tests/testIds";
import PropValueView from "../../../../../../../../../../../components/composed/PropValueView/PropValueView";
import type {
  addAgreementsListResponse, contract,
  contractId,
  documentsList
} from "../../../../../../../../../../../services/flowTypes/dataFlowTypes";
import concatIdName from "../../../../../../../../../../../utils/concatIdName";
import formatDate from "../../../../../../../../../../../utils/formatDate";
import Heading4 from "../../../../../../../../../../../components/simple/TextComponents/Heading4/Heading4";
import GroupFinTermCardLabel
  from "../../../../../../../../../../../components/simple/TextComponents/GroupFinTermCardLabel/GroupFinTermCardLabel";
import FinTermFormGroup from "./FinTermFormGroup";
import groupFinTermsValidationScheme from "../../../../../../../../../../../configData/groupFinTermsValidationScheme";

type Props = {
  propValue: propValue,
  finTermIndex: finTermIndex,
  onUpdateFinTermProp: onUpdateFinTermProp,
  documentsListOptions: selectOptions,
  addClasses?: addClasses,
  readonly?: boolean,
  contractId: contractId,
  documentsList: documentsList,
  addAgreements: addAgreementsListResponse,
  contractMainData: contract
};
type State = {
  isReadyToRender: isReadyToRender,
  propName: propName,
  addAgreementsOptions: selectOptions
};

export const defineFinTermDatesObj = (addAgreementId, contractMainData, addAgreements) => {
  const datesObj = {
    startDate: '',
    endDate: ''
  };
  if (addAgreementId === 'mainContract') {
    datesObj.startDate = contractMainData.startDate;
    datesObj.endDate = contractMainData.endDate;
  } else {
    const addAgreementObj = addAgreements.find(item => item.id + "" === addAgreementId);
    if (addAgreementObj) {
      datesObj.startDate = addAgreementObj.startDate;
      datesObj.endDate = addAgreementObj.endDate;
    }
  }
  return datesObj;
};

class FinTermDocument extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      propName: '',
      addAgreementsOptions: []
    };
  }
  onGetPropValue = (propName, propValue) => {
    this.props.onUpdateFinTermProp(this.props.finTermIndex, 'addAgreementId', propValue);
    const datesObj = defineFinTermDatesObj(propValue, this.props.contractMainData, this.props.addAgreements);
    this.props.onUpdateFinTermProp(this.props.finTermIndex, 'startDate', datesObj.startDate);
    this.props.onUpdateFinTermProp(this.props.finTermIndex, 'endDate', datesObj.endDate);
  };
  setPropName = () => {
    return 'finTerm' + this.props.finTermIndex + 'addAgreementId';
  };
  createAddAgreementOption = (addAgreement, index) => {
    const label = index === 'mainContract' ? addAgreement.name : addAgreement.number + " " + addAgreement.name;
    return (<div className="AddAgreementOption" key={'add-agreement-option-'+index}>
      <Heading4>{label}</Heading4>
      <GroupFinTermCardLabel>
        {formatDate(addAgreement.startDate) + " - " + (addAgreement.endDate ? formatDate(addAgreement.endDate) : text.endDateNotSet)}</GroupFinTermCardLabel>
    </div>);
  };
  // setDefaultValue = () => {
  //   if (this.props.propValue === '') {
  //     this.onGetPropValue('', 'mainContract');
  //   }
  // };
  componentDidMount(){
    const addAgreementsOptions = this.props.addAgreements.map((item, index) => {
      return {value: item.id + "", label: this.createAddAgreementOption(item, index)}
    });
    const mainContractOption = {value: 'mainContract', label: this.createAddAgreementOption(this.props.contractMainData, 'mainContract')};
    addAgreementsOptions.unshift(mainContractOption);
    this.setState({
      propName: this.setPropName(),
      isReadyToRender: true,
      addAgreementsOptions
    });
  }
  render() {
    const addAgreementObj = this.props.addAgreements.find(item => item.id + "" === this.props.propValue);
    const addAgreementIdName = this.props.propValue === 'mainContract' ?
      concatIdName(this.props.contractMainData.id, this.props.contractMainData.name)
      :  addAgreementObj ? concatIdName(addAgreementObj.id, addAgreementObj.name) : '';
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={null}>
        {this.props.readonly ?
            <PropValueView label={text.finTermDocument} value={addAgreementIdName}/>
            :
            <FinTermFormGroup
                isSearchable={true}
                formFieldType={'select'}
                propName={this.state.propName}
                propValue={this.props.propValue}
                onGetPropValue={this.onGetPropValue}
                label={text.finTermDocument}
                options={this.state.addAgreementsOptions}
                addClasses={this.props.addClasses}
                placeholder={text.selectAddAgreementOrMainContract}
                idForTest={groupFinTermEditPageIds.selectAddAgreementOrContract(this.props.finTermIndex)}
                selectInputId={groupFinTermEditPageIds.selectInputAddAgreementOrContract(this.props.finTermIndex)}
                propValidationPatternsArr={groupFinTermsValidationScheme.finTerms.addAgreementId}
            />
        }
      </RenderOnReady>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propValue: state.organization.item.finTermsEdit[ownProps.finTermIndex].addAgreementId,
    documentsList: state.organization.item.itemDocuments,
    addAgreements: state.organization.item.addAgreements,
    contractMainData: state.organization.item.contractMainData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateFinTermProp: (finTermIndex, finTermPropName, propValue) => dispatch({
      type: finTermsEditActions.FIN_TERM_PROP_UPDATE,
      value: { finTermIndex , finTermPropName, propValue } })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinTermDocument);    