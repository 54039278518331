import {supMyOrgsPath} from "../services/urlStrings/urlStrings";
import orgBaseIconSrc from "../assets/menu-my-organizations-icon.svg";
import orgHoveredIconSrc from "../assets/menu-my-organizations-hover-icon.svg";
// import orgActiveIconSrc from "../assets/contracts-menu-icons-active-v4.svg";
import text from "../services/localization/text";

const supMainMenuItems = [
  {to: supMyOrgsPath, baseIconSrc: orgBaseIconSrc, hoveredIconSrc: orgHoveredIconSrc, activeIconSrc: orgHoveredIconSrc,
    text: text.myOrganizations}

];
export default supMainMenuItems;