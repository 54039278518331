const createTempLinkToDownloadFile = (data, documentName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', documentName);
  if(document.body) {
    document.body.appendChild(link);
  }
  link.click();
  if(document.body){
    document.body.removeChild(link);
  }
};
export default createTempLinkToDownloadFile;