//@flow
import * as React from 'react';
import connect from "react-redux/es/connect/connect";
import text from "../../../../../../services/localization/text";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import SectionHeader from "../../../../../../components/composed/SectionHeader/SectionHeader";
import ContractMainDataFormGroup from "./contractMainDataEdit/ContractMainDataFormGroup";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {history, isReadyToRender, locationType, match} from "../../../../../../services/flowTypes/appFlowTypes";
import type {selectOptions, validationFormIsSet} from "../../../../../../services/flowTypes/componentFlowTypes";
import type {
  agentIdIsNotCompliedWIthPaynetParams,
  contractStatus,
  contractStatusList,
  contractTypes,
  hasUnverifiedAddAgreements,
  isCheckedAndVerified,
  isNotCompliedWithPaynetParams,
  itemDate,
  tagsList
} from "../../../../../../services/flowTypes/dataFlowTypes";
import ContractMainDataParamsEdit from "./contractMainDataEdit/ContractMainDataParamsEdit";
import {contractEditPageIds} from "../../../../../../tests/testIds";
import createOption from "../../../../../../utils/createOption";
import SectionDivider from "../../../../../../components/simple/Containers/SectionDivider/SectionDivider";
import scrollToTop from "../../../../../../utils/scrollToTop";
import AlertOnEndDateIncompliance from "../../../../../../components/composed/Alerts/AlertOnEndDateIncompliance";
import ContractMainDataFiles from "./ItemFiles/ItemFiles";
import ItemDataValidationSet from "../../../../../../components/service/ItemDataValidationSet";
import AlertPaynetSync from "../../../../../../components/simple/Containers/AlertCard/AlertPaynetSync";
import AdaptiveFormContainer
  from "../../../../../../components/simple/Containers/AdaptiveFormContainer/AdaptiveFormContainer";
import AlertUnverifiedAddAgreements
  from "../../../../../../components/simple/Containers/AlertCard/AlertUnverifiedAddAgreements";
import ContractFinTermVerification from "./contractMainDataEdit/ContractFinTermVerification";

type Props = {
  tagsList: tagsList,
  contractTypes: contractTypes,
  status: contractStatus,
  endDate: itemDate,
  history: history,
  match: match,
  contractStatusList: contractStatusList,
  location: locationType,
  isNotCompliedWithPaynetParams?: isNotCompliedWithPaynetParams,
  agentIdIsNotCompliedWIthPaynetParams?: agentIdIsNotCompliedWIthPaynetParams,
  hasUnverifiedAddAgreements: hasUnverifiedAddAgreements,
  isCheckedAndVerified: isCheckedAndVerified
};
type State = {
  isReadyToRender: isReadyToRender,
  contractTypesOptions: selectOptions,
  tagsOptions: selectOptions,
  validationFormIsSet: validationFormIsSet,
  contractStatusOptions: selectOptions
};

class ContractMainDataEdit extends React.Component <Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      contractTypesOptions: [],
      tagsOptions: [],
      validationFormIsSet: false,
      contractStatusOptions: []
    };
  }
  setValidationFormIsSet = () => {
    this.setState({validationFormIsSet: true});
  };
  componentDidMount(){
    scrollToTop();
    const contractTypesOptions = this.props.contractTypes.map(item => {
      return {value: item.id, label: item.name}
    });
    const tagsOptions = this.props.tagsList.map(item =>{
      return createOption(item);
    });
    const contractStatusOptions = this.props.contractStatusList.map(item => {
      return {value: item, label: text.contract.status[item], statusClassName: `StatusLabel StatusLabel_${item}`};
    });
    this.setState({
      contractTypesOptions, tagsOptions, contractStatusOptions,
      isReadyToRender: true
    });
  }
  render(){
    const contractId = this.props.match.params.id ? +this.props.match.params.id : null;
    const organizationId = +this.props.match.params.organizationId;
    const isReadyToRender = this.state.isReadyToRender && this.state.validationFormIsSet;
    return (
      <>
        <ItemDataValidationSet itemMainDataType={'contractMainData'} propItemId={contractId}
                               setValidationFormIsSet={this.setValidationFormIsSet} />
        <RenderOnReady isReadyToRender={isReadyToRender}>
          <div className={"ContractMainDataEdit"}>
            <NCard>
              {this.props.isNotCompliedWithPaynetParams ? (
                <AlertPaynetSync addClasses={"mb_3du"}
                                 agentIdIsNotCompliedWIthPaynetParams={this.props.agentIdIsNotCompliedWIthPaynetParams}
                                 organizationId={organizationId}/>
              ) : null}
              {this.props.hasUnverifiedAddAgreements ? (
                <AlertUnverifiedAddAgreements addClasses={"mb_3du"} contractId={contractId}
                                              organizationId={organizationId}/>
              ) : null}
              <ContractMainDataFormGroup propName={"name"} label={text.name} formFieldType={'input'}
                                         addClasses={'mb_3du'} idForTest={contractEditPageIds.inputName}/>
              <AdaptiveFormContainer fieldsArr={[
                <ContractMainDataFormGroup propName={"typeId"} label={text.type} formFieldType={'select'}
                                           placeholder={text.selectContractType} isSearchable={true}
                                           selectInputId={contractEditPageIds.selectInputType}
                                           noOptionsMessage={()=> { return text.noAvailableContractTypes}}
                                           options={this.state.contractTypesOptions}
                                           idForTest={contractEditPageIds.selectType} key={"typeId"}/>,
                <ContractMainDataFormGroup propName={"contractNo"} label={text.number} formFieldType={'input'}
                                           idForTest={contractEditPageIds.inputNumber}
                                           key={"contractNo"}
                />
              ]} containerMode={"twoHalfCardWidthFields"} addClasses={'mb_3du'}/>
              <ContractMainDataFormGroup propName={"comment"} label={text.comment} formFieldType={'textarea'}
                                         addClasses={'mb_3du'} idForTest={contractEditPageIds.textareaComment}/>
              <ContractMainDataFormGroup propName={"tags"}
                                         label={text.tags}
                                         formFieldType={'tagSelect'}
                                         options={this.state.tagsOptions}
                                         addClasses={'mb_3du'}
                                         placeholder={text.tagsPlaceholder}
                                         idForTest={contractEditPageIds.inputTags}
              />
              <SectionDivider addClasses={'mt_5du mb_5du'}/>
              <SectionHeader sectionHeaderTitle={text.validityTerm} addClasses={"mb_3du"}/>
              <AdaptiveFormContainer fieldsArr={[
                <ContractMainDataFormGroup propName={"startDate"}
                                           label={text.start}
                                           formFieldType={'date'}
                                           key={"startDate"}
                                           addClasses={'w_quarter-card'}
                                           idForTest={contractEditPageIds.dateInputStart}
                />,
                <ContractMainDataFormGroup propName={"endDate"}
                                           label={text.end}
                                           formFieldType={'date'}
                                           key={"endDate"}
                                           addClasses={'w_quarter-card'}
                                           idForTest={contractEditPageIds.dateInputEnd}
                />,
                (this.props.status ? (
                    <ContractMainDataFormGroup propName={"status"} label={text.status} formFieldType={'select'} key={"status"}
                                               options={this.state.contractStatusOptions} addClasses={'w_quarter-card'}
                                               idForTest={contractEditPageIds.selectStatus}/>
                  ) : null)
              ]} containerMode={"threeQuarterCardWidth"}/>
              <AlertOnEndDateIncompliance endDate={this.props.endDate}/>
              {this.props.status === 'TERMINATED' ? (
                <AdaptiveFormContainer fieldsArr={[
                  <ContractMainDataFormGroup propName={"statusDate"}
                                             label={text.terminationDate}
                                             formFieldType={'date'}
                                             key={"statusDate"}
                                             addClasses={'w_quarter-card'}
                                             idForTest={contractEditPageIds.dateInputStatus}
                  />,
                  <ContractMainDataFormGroup propName={"statusReason"}
                                             label={text.terminationReason}
                                             formFieldType={'input'}
                                             key={"statusReason"}
                                             addClasses={'w_half-card'}
                                             idForTest={contractEditPageIds.inputStatusReason}
                  />
                ]} containerMode={"threeQuarterCardWidth"}/>
              ) : null}
              <ContractMainDataFiles contractId={contractId} itemFilesType={'contract'}/>
              <SectionDivider addClasses={'mt_5du mb_5du'}/>
              <SectionHeader sectionHeaderTitle={text.header1c} addClasses={"mb_3du"}/>
              <div className="flex_jc-sb mb_3du">
                <ContractMainDataFormGroup propName={"contractCode1c"} label={text.contractCode1c} formFieldType={'input'}
                                           addClasses={'w_half-card'} idForTest={contractEditPageIds.contractCode1c}/>
              </div>
              <ContractMainDataParamsEdit organizationId={organizationId}/>
              <SectionDivider addClasses={'mt_5du mb_5du'}/>
              <div className="mb_3du">
                <ContractFinTermVerification />
              </div>
            </NCard>
          </div>
        </RenderOnReady>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    isCheckedAndVerified: state.organization.item.contractMainData.isCheckedAndVerified,
    isNotCompliedWithPaynetParams: state.organization.item.contractMainData.isNotCompliedWithPaynetParams,
    hasUnverifiedAddAgreements: state.organization.item.contractMainData.hasUnverifiedAddAgreements,
    agentIdIsNotCompliedWIthPaynetParams: state.organization.item.contractMainData.agentIdIsNotCompliedWIthPaynetParams,
    status: state.organization.item.contractMainData.status,
    endDate: state.organization.item.contractMainData.endDate,
    contractTypes: state.organization.organizationData.contractTypes,
    tagsList: state.organization.organizationData.tagsList,
    contractStatusList: state.organization.organizationData.statuses.contractStatusList
  };
};
export default connect(mapStateToProps, null)(ContractMainDataEdit);