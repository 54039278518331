import TextActionButton from "../../Buttons/TextActionButton/TextActionButton";
import DropDownItems from "../DropDownSelect/DropDownItems";
import {selectedOption, selectOptions} from "../../../../services/flowTypes/componentFlowTypes";
import {useState} from "react";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import './BtnActions.css';

type Props = {
  actions: selectOptions,
  title: React.Node,
  addClasses?: addClasses,
  onAction: (option: selectedOption)=> void
}

const BtnActions = (
  {
    actions,
    title,
    addClasses,
    onAction
  }: Props
) => {
  const [isFocused, setIsFocused] = useState(false)
  return <div className={baseClass("BtnActions ", addClasses)}>
    <TextActionButton disabled={actions.length === 0} addClasses={"BtnNewItem BtnNewItem_grey"} onClick={() => {
      setIsFocused(prevState => !prevState)
    }}>
      {title}
    </TextActionButton>
    <div className="BtnActionsDropdown">
      <DropDownItems
        isFocused={isFocused}
        removeFocus={() => [
          setIsFocused(false)
        ]}
        selectOptions={actions}
        onDropDownSelectChange={onAction}
      />
    </div>
  </div>
}

export default BtnActions