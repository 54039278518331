//@flow
import * as React from 'react';
import {myOrganizationsPageIds} from "../../../../../tests/testIds";
import type {organizationIndex} from "../../../../../services/flowTypes/componentFlowTypes";
import {agentsSuffix, orgPath} from "../../../../../services/urlStrings/urlStrings";
import type {myOrganization} from "../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../services/localization/text";
import ListItemCard from "../../../../../components/composed/ListItemCard/ListItemCard";
import formatDate from "../../../../../utils/formatDate";

type Props = {
  organizationIndex: organizationIndex,
  myOrganization: myOrganization
};

const MyOrganizationCard = (props: Props) => {
  const linkToOrganization = orgPath(props.myOrganization.id) + agentsSuffix;
  const cardTiles = [
    {tileIconType: 'contracts', numberLabel: props.myOrganization.contractsCount},
    {tileIconType: 'agents', numberLabel: props.myOrganization.agentsCount}
  ];
  const createDate = formatDate(props.myOrganization.createDate);
  return (
    <ListItemCard
      itemId={props.myOrganization.id}
      itemName={props.myOrganization.name}
      addClasses={'MyOrganizationCard mb_1du'}
      linkTo={linkToOrganization}
      linkId={myOrganizationsPageIds.linkToOrganization(props.organizationIndex)}
      itemStatus={props.myOrganization.status}
      itemStatusLabel={text.organizationStatus[props.myOrganization.status]}
      // createDate={createDate}
      validityTerm={createDate}
      cardTiles={cardTiles}
      cardHeadingId={myOrganizationsPageIds.headingCardOrganizationId(props.organizationIndex)}
      isWideMode={true}
    />
  );
};

export default MyOrganizationCard;    