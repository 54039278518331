//@flow
import * as React from 'react';
import {addAgreementsPageIds} from "../../../../../../../tests/testIds";
import text from "../../../../../../../services/localization/text";
import Can from "../../../../../../../services/permissions/Can";
import type {isReadyToRender, modalIsShown} from "../../../../../../../services/flowTypes/appFlowTypes";
import EditAddAgreementModal from "./EditAddAgreementModal";
import type {contractId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import DocumentsListFetcher
  from "../../../../../../../components/composed/ItemDocuments/DocumentsList/DocumentsListFetcher/DocumentsListFetcher";
import type {isDocumentsListFetcherMounted} from "../../../../../../../services/flowTypes/componentFlowTypes";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";
import BtnNewItem from "../../../../../../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  contractId: contractId
};
type State = {
  modalIsShown: modalIsShown,
  isDocumentsListFetcherMounted: isDocumentsListFetcherMounted,
  isReadyToRender: isReadyToRender
};

class ButtonNewAddAgreement extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false,
      isReadyToRender: false,
      isDocumentsListFetcherMounted: true
    };
  }
  setReadyToRender = () => {
    this.setState({isReadyToRender: true});
  };
  unmountDocumentsListFetcher = () => {
    this.setState({
      isReadyToRender: false,
      isDocumentsListFetcherMounted: false
    });
  };
  mountDocumentsListFetcher = () => {
    this.setState({
      isReadyToRender: false,
      isDocumentsListFetcherMounted: true
    });
  };
  showModal = () => {
    this.setState({
      modalIsShown: true
    });
  };
  hideModal = () => {
    this.setState({
      modalIsShown: false
    });
  };
  render() {
    return (
      <>
        <Can do={'usePermission'} on={permissionsStrValues.addAgreement.create}>
          <BtnNewItem id={addAgreementsPageIds.buttonNewAddAgreement} onClick={this.showModal}>{text.btnAdd}</BtnNewItem>
        </Can>
        {this.state.modalIsShown ? (
          <EditAddAgreementModal isCreateMode={true} hideModal={this.hideModal} contractId={this.props.contractId}
                                 mountDocumentsListFetcher={this.mountDocumentsListFetcher}
                                 unmountDocumentsListFetcher={this.unmountDocumentsListFetcher}
                                 addAgreementIndex={null}/>
        ) : null}
        {this.state.isDocumentsListFetcherMounted ? (
        <DocumentsListFetcher
          itemType={'contract'}
          setReadyToRender={this.setReadyToRender}
          itemId={this.props.contractId}
          isAllFilesShown={true}
        />
        ) : null}
      </>
    );
  }
}

export default ButtonNewAddAgreement;    