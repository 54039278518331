//@flow
import * as React from 'react';
import {connect} from "react-redux";
import PreventDeleteGroupFinTermModal from "./PreventDeleteGroupFinTermModal/PreventDeleteGroupFinTermModal";
import ConnectedDeleteGroupFinTermModal from "./DeleteGroupFinTermModal/DeleteGroupFinTermModal";
import ability from "../../../../../../../services/permissions/permissions";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";
import ConnectedGroupFinTermCard from "./GroupFinTermCard/ConnectedGroupFinTermCard";
import type {groupFinTerm} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {modalIsShown} from "../../../../../../../services/flowTypes/appFlowTypes";
import EmptyListV2 from "../../../../../../../components/simple/Containers/EmptyList/EmptyListV2";

type Props = {
  groupFinTerms: Array<groupFinTerm>,
  setDeletedGroupFinTermId: Function
};
type State = {
  modalIsShown: modalIsShown,
  groupFinTermDelete: groupFinTerm,
};

class GroupFinTermsSearchResults extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false,
      groupFinTermDelete: null
    };
  }
  showModal = (groupFinTerm) => {
    this.setState({
      modalIsShown: true,
      groupFinTermDelete: groupFinTerm
    });
  };
  cancelModal = () => {
    this.setState({
      modalIsShown: false,
      groupFinTermDelete: null
    });
  };
  render() {
    const groupFinTerms = this.props.groupFinTerms === null ? null :
      this.props.groupFinTerms && this.props.groupFinTerms.length ? this.props.groupFinTerms.map((groupFinTerm, i) => {
      const onBtnDelClick = ability.can('usePermission', permissionsStrValues.finTerm.update) ?
        () => this.showModal(groupFinTerm) : null;
      return (
        <ConnectedGroupFinTermCard key={'groupFinTerm'+i} groupFinTerm={groupFinTerm} groupFinTermIndex={i}
                                   onBtnDelClick={onBtnDelClick} addClasses={'mb_1du'} />
      );
    }) : <EmptyListV2/>;
    return (
      <div className="GroupFinTermsSearchResults">
        {groupFinTerms}
        {this.state.modalIsShown ? (
          this.state.groupFinTermDelete.hasReportTemplates ? (
            <PreventDeleteGroupFinTermModal
              cancelModal={this.cancelModal}
              groupFinTerm={this.state.groupFinTermDelete}
            />
          ) : (
            <ConnectedDeleteGroupFinTermModal
              cancelModal={this.cancelModal}
              groupFinTerm={this.state.groupFinTermDelete}
              setDeletedGroupFinTermId={this.props.setDeletedGroupFinTermId}
            />
          )
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    groupFinTerms: state.organization.item.groupFinTermsView,
  };
};
export default connect(mapStateToProps, null)(GroupFinTermsSearchResults);