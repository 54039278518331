//@flow
import * as React from 'react';
import type {
  inputIsFocused, inputRefType,
  onBlur,
  onChange,
  onFocus, onKeyDown, placeholder,
  spinnerIsShown
} from "../../../../../../services/flowTypes/appFlowTypes";
import {handleSearchIcoClick} from "../../../../../../services/flowTypes/propFnFlowTypes";
import {baseClass} from "../../../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../../../../services/flowTypes/uiKitFlowTypes";
import './SearchInput.css';
import type {searchRequestText, tagsList} from "../../../../../../services/flowTypes/dataFlowTypes";
import {contractsPageIds} from "../../../../../../tests/testIds";
import type {onSearchTagDeleteClick} from "../../../../../../services/flowTypes/componentFlowTypes";
import BtnSearchIco from "../../../../../simple/Buttons/BtnSearchIco/BtnSearchIco";


type Props = {
  placeholder: placeholder,
  addClasses?: addClasses,
  spinnerIsShown: spinnerIsShown,
  inputIsFocused: inputIsFocused,
  handleSearchIcoClick: handleSearchIcoClick,
  onFocus: onFocus,
  onBlur: onBlur,
  onChange: onChange,
  onKeyDown: onKeyDown,
  text: searchRequestText,
  tagsList?: tagsList,
  onSearchTagDeleteClick?: onSearchTagDeleteClick
};


const SearchInput = React.forwardRef<Props, HTMLInputElement>((props:Props, ref:inputRefType) => {
  const onSearchInputContainerClick = () => (ref && ref.current) ? ref.current.focus() : null;
  const searchSpinnerClassName = props.spinnerIsShown ? "SearchInput__spinner"
    : "SearchInput__spinner SearchInput__spinner_hide";
  const tags = (props.tagsList && props.tagsList.length) ? props.tagsList.map((tag, i) => {
    const onDeleteClick = props.onSearchTagDeleteClick !== undefined ? () => props.onSearchTagDeleteClick(i) : null;
    return <div key={'tag'+i} className={"SearchInput__tag"}>
      <div className="SearchInput__tag-label">
        {tag}
      </div>
      <div className="SearchInput__tag-delete" onClick={onDeleteClick} id={contractsPageIds.buttonDeleteTag(i)}>x</div>
      </div>
  }) : null;
  const placeholder = (props.tagsList && props.tagsList.length) ? null : (
    props.placeholder ? props.placeholder : null
  );
  const inputClassName = (props.tagsList && props.tagsList.length && !props.text.length)
    ? "SearchInput__input" : "SearchInput__input  SearchInput__input_full-width";
  return (
    <div onClick={onSearchInputContainerClick} className={baseClass("SearchInput", props.addClasses)}>
      <div className={searchSpinnerClassName}/>
      {(props.tagsList && props.tagsList.length) ? (tags) : null}
      <input type="text" className={inputClassName} id={contractsPageIds.inputSearch}
             name="text"
             ref={ref}
             value={props.text}
             onChange={props.onChange}
             onBlur={props.onBlur}
             onFocus={props.onFocus}
             onKeyDown={props.onKeyDown}
             placeholder={placeholder}/>
      <BtnSearchIco addClasses={'SearchInput__search-ico'} isFocused={props.inputIsFocused} onClick={props.handleSearchIcoClick}
                    id={contractsPageIds.buttonSearch}/>
    </div>
  );
});

export default SearchInput;