//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  isCreateMode,
  selectOptions
} from "../../../../../../../services/flowTypes/componentFlowTypes";
import NModal from "../../../../../../../components/simple/Modals/NModal/NModal";
import text from "../../../../../../../services/localization/text";
import type {
  hideModal, mountDocumentsListFetcher,
  onGetAddAgreements, onGetContractMainData,
  unmountDocumentsListFetcher
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import NFormGroup from "../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import NInput from "../../../../../../../components/simple/FormComponents/NInput/NInput";
import {addAgreementsPageIds} from "../../../../../../../tests/testIds";
import type {
  addAgreement,
  addAgreementDescription,
  addAgreementId,
  addAgreementName,
  addAgreementNumber,
  contractId,
  contractStatus,
  contractStatusList,
  document,
  documentsList,
  fileId, isNotVerifiedWithFinTerms, isNotVerifiedWithFinTermsComment,
  itemDate,
  validationIsAllowed
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import FormSingleDatePicker
  from "../../../../../../../components/simple/FormComponents/FormSingleDatePicker/FormSingleDatePicker";
import Textarea from "../../../../../../../components/simple/FormComponents/Textarea/Textarea";
import NSwitcher from "../../../../../../../components/simple/FormComponents/NSwitcher/NSwitcher";
import type {errorObject, isReadyToRender} from "../../../../../../../services/flowTypes/appFlowTypes";
import DocumentUploaderContent
  from "../../../../../../../components/composed/ItemDocuments/DocumentUploader/DocumentUploaderContent";
import NSelect from "../../../../../../../components/simple/FormComponents/NSelect/NSelect";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import api from "../../../../../../../services/apiInstance/api";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import addAgreementsActions from "../../../../../../../store/organization/item/addAgreements/addAgreementsActions";
import validationPatterns from "../../../../../../../services/validationPatterns/validationPatterns";
import AlertOnEndDateIncompliance from "../../../../../../../components/composed/Alerts/AlertOnEndDateIncompliance";
import AddAgreementDocument from "./AddAgreementDocument";
import contractMainDataActions
  from "../../../../../../../store/organization/item/contractMainData/contractMainDataActions";
import {isCheckedAndVerified} from "../../../../../../../services/flowTypes/dataFlowTypes";
import CheckboxInput from "../../../../../../../components/simple/FormComponents/CheckboxInput/CheckboxInput";
import AdaptiveFormContainer
  from "../../../../../../../components/simple/Containers/AdaptiveFormContainer/AdaptiveFormContainer";

type Props = {
  isCreateMode: isCreateMode,
  hideModal: hideModal,
  contractId: contractId,
  documentsList: documentsList,
  addAgreementIndex: number | string,
  contractStatusList: contractStatusList,
  statusQueryParam: contractStatus,
  addAgreement?: addAgreement,
  onGetAddAgreements: onGetAddAgreements,
  mountDocumentsListFetcher: mountDocumentsListFetcher,
  unmountDocumentsListFetcher: unmountDocumentsListFetcher,
  onGetContractMainData: onGetContractMainData
};
type State = {
  tempFileId: fileId,
  fileId: fileId,
  id: addAgreementId,
  name: addAgreementName,
  number: addAgreementNumber,
  description: addAgreementDescription,
  startDate: itemDate,
  endDate: itemDate,
  addStatus: contractStatus,
  switcherValue: propValue,
  documentsArr: Array<document>,
  documentsOptions: selectOptions,
  contractStatusOptions: selectOptions,
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  formErrors: Object,
  validationIsAllowed: validationIsAllowed,
  isNotVerifiedWithFinTerms: isNotVerifiedWithFinTerms,
  isNotVerifiedWithFinTermsComment: isNotVerifiedWithFinTermsComment,
  isCheckedAndVerified: isCheckedAndVerified
};
const requiredPropsNames = ['name', 'number', 'startDate', 'addStatus', 'isNotVerifiedWithFinTerms'];
const isNotVerifiedWithFinTermsOptions = [
  {value: "yes", label: text.isNotVerifiedWithFinTermsOptions.yes},
  {value: "no", label: text.isNotVerifiedWithFinTermsOptions.no}
];
const defineBooleanIsNotVerifiedWithFinTerms = (strValue) => {
  switch (strValue) {
    case "yes":
      return true;
    case "no":
      return false;
    default:
      return null;
  }
};

class EditAddAgreementModal extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null,
      id: null,
      tempFileId: '',
      fileId: null,
      name: '',
      number: '',
      description: '',
      startDate: '',
      endDate: '',
      addStatus: '',
      switcherValue: 'newFile',
      documentsArr: [],
      documentsOptions: [],
      contractStatusOptions: [],
      formErrors: {},
      validationIsAllowed: false
    };
  }
  onDocumentsArrUpdate = (documentsArr) => {
     // !!!!single file upload mode applied, so there is only one tempFileId
    if (documentsArr.length) {
      if (!this.state.documentsArr.length
        || documentsArr[0].file.lastModified !== this.state.documentsArr[0].file.lastModified) {
        this.setState({isReadyToRender: false});
        let documentsArrLength = documentsArr.length;
        for (let i=0; i<documentsArr.length; i++) {
          /* eslint-disable no-loop-func */
          const formData = new FormData();
          formData.append('file', documentsArr[i].file);
          api.uploadTempFile(formData)
            .then(response => {
              this.setState({tempFileId: response.data.id, documentsArr}); // !!!!single file upload mode applied, so there is only one tempFileId
              documentsArrLength = documentsArrLength - 1;

              if (documentsArrLength === 0) {
                this.setState({isReadyToRender: true});
              }
            })
            .catch(error => {
              this.setState({
                isReadyToRender: true,
                errorObject: apiErrorHandler(error)
              });
            });
        }
      } else {
        this.setState({documentsArr});
      }
    } else {
      this.setState({tempFileId: '', documentsArr});
    }
  };
  handleInputChange = (name, value) => {
    let newStateProps = {[name]: value};
    if (this.state.formErrors.hasOwnProperty(name)) {
      const formErrors = {...this.state.formErrors, [name]: validationPatterns.required(value)};
      newStateProps = {...newStateProps, formErrors};
    }
    this.setState({...newStateProps});
  };
  createAddAgreement = () => {
    this.setState({
      isReadyToRender: false,
    });
    const isNotVerifiedWithFinTerms = defineBooleanIsNotVerifiedWithFinTerms(this.state.isNotVerifiedWithFinTerms);
    const requestBody = {
      name: this.state.name,
      number: this.state.number,
      description: this.state.description,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      addStatus: this.state.addStatus,
      contractId: this.props.contractId,
      isNotVerifiedWithFinTerms,
      isNotVerifiedWithFinTermsComment: this.state.isNotVerifiedWithFinTermsComment,
      isCheckedAndVerified: !isNotVerifiedWithFinTerms
    };
    if (this.state.switcherValue === 'newFile') {
      requestBody.tempFileId = this.state.tempFileId;
      requestBody.tempFileDescription = this.state.documentsArr[0].fileDescription;
    } else {
      requestBody.fileId = this.state.fileId;
    }

    api.createAddAgreement(requestBody, this.props.statusQueryParam)
      .then(response => {
        this.props.onGetAddAgreements(response.data);
        this.handleResponse();
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  updateAddAgreement = () => {
    this.setState({
      isReadyToRender: false,
    });
    const requestBody = {
      contractId: this.props.contractId,
      id: this.state.id,
      name: this.state.name,
      number: this.state.number,
      description: this.state.description,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      addStatus: this.state.addStatus,
      fileId: this.state.fileId,
      isNotVerifiedWithFinTerms: defineBooleanIsNotVerifiedWithFinTerms(this.state.isNotVerifiedWithFinTerms),
      isNotVerifiedWithFinTermsComment: this.state.isNotVerifiedWithFinTermsComment,
      isCheckedAndVerified: this.state.isCheckedAndVerified
    };
    if (this.state.switcherValue === 'newFile' && this.state.tempFileId !== '') {
      requestBody.tempFileId = this.state.tempFileId;
      requestBody.tempFileDescription = this.state.documentsArr[0].fileDescription;
    }

    api.updateAddAgreement(requestBody, this.props.statusQueryParam)
      .then(response => {
        this.props.onGetAddAgreements(response.data);
        this.handleResponse();
      })
      .catch(error => {
        console.log(error.response);
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
        // this.props.hideModal();
      });
  };
  handleResponse = () => {
    api.getContractById(this.props.contractId)
      .then(response => {
        this.props.onGetContractMainData(response.data);
        this.setState({
          isReadyToRender: true,
        });
        this.props.hideModal();
        this.props.unmountDocumentsListFetcher();
        this.props.mountDocumentsListFetcher();
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
        this.props.hideModal();
      });
  };
  checkAllRequiredProps = () => {
    let isValid = true;
    for (let i=0; i<requiredPropsNames.length; i++) {
      isValid = isValid && !this.state.formErrors[requiredPropsNames[i]].length;
    }
    if (this.props.isCreateMode) {
      if (this.state.switcherValue === 'newFile') {
        isValid = isValid && this.state.tempFileId !== '';
      } else {
        isValid = isValid && this.state.fileId;
      }
    }
    return isValid;
  };
  onModalActionBtnClick = () => {
    if (!this.checkAllRequiredProps()) {
      this.setState({validationIsAllowed: true});
      return;
    }
    this.props.isCreateMode ? this.createAddAgreement() : this.updateAddAgreement();
  };
  definePropError = (propName) => {
    if (propName === 'fileProp') {
      return this.state.validationIsAllowed && this.props.isCreateMode ?
        this.state.switcherValue === 'newFile' ?
          this.state.tempFileId === '' ? text.fileIsNotChosen : ''
          : this.state.fileId ? '' : text.fileIsNotChosen
        : '';
    }
    return this.state.validationIsAllowed ?
      this.state.formErrors.hasOwnProperty(propName) ? this.state.formErrors[propName] : ''
      : '';
  };
  assignFormErrors = (stateProps) => {
    const formErrors = {};
    for (let i=0; i < requiredPropsNames.length; i++) {
      if (stateProps.hasOwnProperty(requiredPropsNames[i])) {
        formErrors[requiredPropsNames[i]] = validationPatterns.required(stateProps[requiredPropsNames[i]]);
      }
    }
    return formErrors;
  };
  onMount = (documentsList) => {
    let newStateProps = {};
    if (!this.props.isCreateMode) {
      newStateProps = {...this.props.addAgreement};
      if (this.props.addAgreement.hasOwnProperty('isNotVerifiedWithFinTerms')) {
        newStateProps.isNotVerifiedWithFinTerms = this.props.addAgreement.isNotVerifiedWithFinTerms ? "yes" : "no";
      }
      newStateProps.formErrors = this.assignFormErrors(newStateProps);
    } else {
      newStateProps.isNotVerifiedWithFinTerms = '';
      newStateProps.isNotVerifiedWithFinTermsComment = '';
      newStateProps.formErrors = this.assignFormErrors({...this.state, isNotVerifiedWithFinTerms: ''});
    }
    const documentsOptions = documentsList ? documentsList.map(item => {
      return {value: item.fileId, label: item.name};
    }) : [];
    const contractStatusOptions = this.props.contractStatusList.map(item => {
      return {value: item, label: text.addAgreement.status[item], statusClassName: `StatusLabel StatusLabel_${item}`};
    });
    newStateProps = {...newStateProps, contractStatusOptions, documentsOptions};
    this.setState({...newStateProps});
  };
  componentDidMount() {
    api.getContractDocumentsList(this.props.contractId)
      .then(response => {
        this.onMount(response.data);
      })
      .catch(error => {
        this.setState({
          errorObject: apiErrorHandler(error)
        });
      });
  }
  render() {
    console.log(this.state);
    const modalTitle = this.props.isCreateMode ? text.createAddAgreementModalTitle : text.editAddAgreementModalTitle;
    const modalActionBtnText = this.props.isCreateMode ? text.btnAdd : text.save;
    const switcherOptions = [{label: text.newAddAgreementFile, value: 'newFile'}, {label: text.fromDocumentsList, value: 'fromList'}];
    const switcherValue = switcherOptions.find(item => item.value === this.state.switcherValue);
    const documentsListSelectValue = this.state.documentsOptions.find(item => item.value === this.state.fileId);
    const addStatusValue = this.state.contractStatusOptions.find(item => item.value === this.state.addStatus);
    const isNotVerifiedWithFinTermsValue = isNotVerifiedWithFinTermsOptions.find(item => item.value === this.state.isNotVerifiedWithFinTerms);
    return (
      <NModal modalTitle={modalTitle}
              // isModalDocumentEdit={true}
              modalActionBtnText={modalActionBtnText} modalCancelBtnText={text.cancel}
              onModalActionBtnClick={this.onModalActionBtnClick} onModalCancelBtnClick={this.props.hideModal}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <AdaptiveFormContainer fieldsArr={[
          <NFormGroup label={text.addAgreementName} addClasses={''} key={"name"}
                      isRequired={true} propError={this.definePropError('name')}>
            <NInput value={this.state.name} onChange={(e)=>this.handleInputChange('name', e.currentTarget.value)}
                    id={addAgreementsPageIds.modalAddAgreementName(this.props.addAgreementIndex)}/>
          </NFormGroup>,
          <NFormGroup label={text.number} addClasses={''} key={"number"}
                      isRequired={true} propError={this.definePropError('number')}>
            <NInput value={this.state.number} onChange={(e)=>this.handleInputChange('number', e.currentTarget.value)}
                    id={addAgreementsPageIds.modalAddAgreementNumber(this.props.addAgreementIndex)}/>
          </NFormGroup>
        ]} containerMode={"twoHalfCardWidthFieldsModal"} addClasses={"mb_3du mt_4du"}/>
        <NFormGroup label={text.description} addClasses={'mb_3du'}>
          <Textarea value={this.state.description}
                    onChange={(e) => this.handleInputChange('description', e.currentTarget.value)}
                    id={addAgreementsPageIds.modalAddAgreementDescription(this.props.addAgreementIndex)}/>
        </NFormGroup>
        <AdaptiveFormContainer fieldsArr={[
          <NFormGroup label={text.status} addClasses={''} key={"addStatus"}
                      isRequired={true} propError={this.definePropError('addStatus')}>
            <NSelect options={this.state.contractStatusOptions}
                     onChange={(option)=>this.handleInputChange('addStatus', option.value)}
                     placeholder={text.selectStatusPlaceholder}
                     value={addStatusValue}/>
          </NFormGroup>,
          <NFormGroup label={text.addAgreementsDates} addClasses={''} key={"dates"}
                      isRequired={true} propError={this.definePropError('startDate')}>
            <div className="flex">
              <FormSingleDatePicker date={this.state.startDate} addClasses={'mr_2du'}
                                    onDatesChange={(value)=>this.handleInputChange('startDate', value)}
                                    dateId={addAgreementsPageIds.modalAddAgreementStartDate(this.props.addAgreementIndex)}/>
              <FormSingleDatePicker date={this.state.endDate}
                                    onDatesChange={(value)=>this.handleInputChange('endDate', value)}
                                    dateId={addAgreementsPageIds.modalAddAgreementEndDate(this.props.addAgreementIndex)}/>
              <AlertOnEndDateIncompliance endDate={this.state.endDate}/>
            </div>
          </NFormGroup>
        ]} containerMode={"twoHalfCardWidthFieldsModal"} addClasses={"mb_3du"}/>

        <NFormGroup label={text.addAgreementFileLabel} addClasses={'mb_1du'}>
          <NSwitcher options={switcherOptions} onChange={(option)=>this.handleInputChange('switcherValue', option.value)}
                     value={switcherValue}/>
        </NFormGroup>
        {this.state.switcherValue === 'newFile' ? (
          <>
            <NFormGroup isEmptyLabelMode={true} propError={this.definePropError('fileProp')}></NFormGroup>
            <DocumentUploaderContent
              isAddAgreementMode={true}
              documentsArr={this.state.documentsArr} onDocumentsArrUpdate={this.onDocumentsArrUpdate}
              itemType={'contract'} itemId={this.props.contractId} isMultipleUpload={false}/>
            {!this.props.isCreateMode && !this.state.documentsArr.length ? (
              <AddAgreementDocument contractId={this.props.contractId} fileId={this.props.addAgreement.fileId}
                                    cardIndex={this.props.addAgreementIndex} isEditModalMode={true}/>
            ) : null}
          </>
        ) : (
          <NFormGroup label={text.fileFromDocumentsListLabel} addClasses={'mt_3du'}
                      propError={this.definePropError('fileProp')}>
            <NSelect options={this.state.documentsOptions} onChange={(option)=>this.handleInputChange('fileId', option.value)}
                     value={documentsListSelectValue} placeholder={text.selectDocument}/>
          </NFormGroup>
        )}
        <div className="mb_3du">
          {this.state.hasOwnProperty("isNotVerifiedWithFinTerms") ? <>
            {/*<CheckboxInput checked={this.state.isNotVerifiedWithFinTerms}*/}
            {/*               onClick={()=>this.setState({isNotVerifiedWithFinTerms: !this.state.isNotVerifiedWithFinTerms})}*/}
            {/*               checkboxLabel={text.isNotVerifiedWithFinTerms} />*/}
            <NFormGroup label={text.isNotVerifiedWithFinTerms} addClasses={'mt_3du mb_3du'}
                        propError={this.definePropError('isNotVerifiedWithFinTerms')}>
              <NSelect options={isNotVerifiedWithFinTermsOptions} onChange={(option)=>this.handleInputChange('isNotVerifiedWithFinTerms', option.value)}
                       value={isNotVerifiedWithFinTermsValue} placeholder={text.isNotVerifiedWithFinTermsPlaceholder}
                        isDisabled={!this.props.isCreateMode}/>
            </NFormGroup>
            {this.state.isNotVerifiedWithFinTerms === "yes" ? (
              <NFormGroup label={text.isNotVerifiedWithFinTermsComment}>
                <Textarea value={this.state.isNotVerifiedWithFinTermsComment}
                          onChange={(e) => this.handleInputChange('isNotVerifiedWithFinTermsComment', e.currentTarget.value)}
                          disabled={!this.props.isCreateMode}
                />
              </NFormGroup>
            ) : null}
            {this.state.hasOwnProperty('isCheckedAndVerified') ? (
              <CheckboxInput checked={this.state.isCheckedAndVerified} addClasses={"mt_3du"}
                             onClick={()=>this.setState({isCheckedAndVerified: !this.state.isCheckedAndVerified})}
                             checkboxLabel={text.isCheckedAndVerified} />
            ) : null}
          </> : null}
        </div>
      </NModal>
    );
  }
}
const mapStateToProps = state => {
  return {
    documentsList: state.organization.item.itemDocuments,
    contractStatusList: state.organization.organizationData.statuses.contractStatusList,
    statusQueryParam: state.organization.linkedItemsRequestParams.hasOwnProperty('status') ?
      state.organization.linkedItemsRequestParams.status : null,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetAddAgreements: (addAgreements) => dispatch({
      type: addAgreementsActions.ADD_AGREEMENTS_SET, value: addAgreements}),
    onGetContractMainData: (contractMainData) => dispatch({
      type: contractMainDataActions.CONTRACT_MAIN_DATA_SET,
      value: contractMainData})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditAddAgreementModal);
