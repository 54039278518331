//@flow
import * as React from 'react';
import './StepsNavigationButtons.css';
import {stepsNavigationButtonsIds} from "../../../tests/testIds";
import text from "../../../services/localization/text";
import type {
  cancelFn,
  currentStepIndex, finishButtonLabel,
  handleButtonFinishClick,
  steps
} from "../../../services/flowTypes/componentFlowTypes";
import type {history, locationPathname} from "../../../services/flowTypes/appFlowTypes";
import BackButtonIcon from "../../simple/Icons/BackButtonIcon/BackButtonIcon";
import ForwardButtonIcon from "../../simple/Icons/ForwardButtonIcon/ForwardButtonIcon";
import type {handleButtonForwardClick, validateBeforeSubmit} from "../../../services/flowTypes/propFnFlowTypes";
import BtnNewItem from "../../simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  cancelFn: cancelFn,
  history: history,
  steps: steps,
  locationPathname: locationPathname,
  handleButtonFinishClick: handleButtonFinishClick,
  finishButtonLabel: finishButtonLabel,
  validateBeforeSubmit?: validateBeforeSubmit,
  handleButtonForwardClick?: handleButtonForwardClick
};
type State = {
  currentStepIndex: currentStepIndex
};

class StepsNavigationButtons extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentStepIndex: -1
    };
  }
  onButtonBackClick = () => {
    this.props.history.goBack();
  };
  goForward = () => {
    const regex = new RegExp(this.props.steps[this.state.currentStepIndex].pathnameSuffix+ '$');
    const newLocationPathname = this.props.locationPathname.replace(regex, this.props.steps[this.state.currentStepIndex+1].pathnameSuffix);
    this.props.history.push(newLocationPathname);
  };
  onButtonForwardClick = () => {
    if (this.props.hasOwnProperty('handleButtonForwardClick') && this.props.hasOwnProperty('validateBeforeSubmit')) {
      this.props.handleButtonForwardClick();
      if (!this.props.validateBeforeSubmit()) {
        this.goForward();
      }
    } else {
      this.goForward();
    }
  };
  setCurrentStepIndex = () => {
    const currentStepIndex = this.props.steps.findIndex(item => {
      const regex = new RegExp(item.pathnameSuffix+ '$');
      return regex.test(this.props.locationPathname);
    });
    this.setState({currentStepIndex});
  };
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if (this.props.locationPathname !== prevProps.locationPathname || this.props.steps !== prevProps.steps) {
      this.setCurrentStepIndex();
    }
  }
  componentDidMount(){
    this.setCurrentStepIndex();
  }
  render() {
    return (
      <div className="StepsNavigationButtons">
        <BtnNewItem onClick={this.props.cancelFn} addClasses={"BtnNewItem_transparent mr_1du"}
                       id={stepsNavigationButtonsIds.buttonCancel}>
          {text.stepsNavigationButtons.cancel}
        </BtnNewItem>
        <BtnNewItem onClick={this.onButtonBackClick} addClasses={"BtnNewItem_grey mr_1du flex ai_c"}
                       id={stepsNavigationButtonsIds.buttonBack}>
          <BackButtonIcon addClasses={'mr_1du'}/>{text.stepsNavigationButtons.back}
        </BtnNewItem>
        {this.state.currentStepIndex === this.props.steps.length-1 ? (
          <BtnNewItem onClick={this.props.handleButtonFinishClick}
                         id={stepsNavigationButtonsIds.buttonFinish}>
            {this.props.finishButtonLabel}
          </BtnNewItem>
        ) : (
          <BtnNewItem onClick={this.onButtonForwardClick} addClasses={"flex ai_c"}
                         id={stepsNavigationButtonsIds.buttonForward}>
            {text.stepsNavigationButtons.forward}<ForwardButtonIcon addClasses={'ml_1du'}/>
          </BtnNewItem>
        )}
      </div>
    );
  }
}

export default StepsNavigationButtons;    