//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  organizationId,
  searchedAgentResponse,
  searchedContractResponse,
  searchRequestText
} from "../../../../services/flowTypes/dataFlowTypes";
import SearchCard from "../../../simple/Containers/SearchCard/SearchCard";
import concatIdName from "../../../../utils/concatIdName";
import type {itemType} from "../../../../services/flowTypes/componentFlowTypes";
import searchInOrgContextActions from "../../../../store/organization/searchInOrgContext/searchInOrgContextActions";
import type {onUpdateSheetIsShown} from "../../../../services/flowTypes/propFnFlowTypes";
import text from "../../../../services/localization/text";
import getItemPathView from "../../../../utils/getItemPathView";
import formatWithSearchTextIllumination from "../../../../utils/formatWithSearchTextIllumination";

type Props = {
  text: searchRequestText,
  itemType: itemType,
  searchedItem: searchedAgentResponse | searchedContractResponse,
  organizationId: organizationId,
  onUpdateSheetIsShown: onUpdateSheetIsShown
};

class SearchInOrgContextCard extends React.Component <Props> {
  handleLinkClick = () => {
    this.props.onUpdateSheetIsShown(false);
  };
  defineItemTypeForParamName = (itemType) => {
    switch (itemType) {
      case 'agent':
        return 'AGENT';
      case 'contract':
        return 'CONTRACT';
      default:
        throw new Error('undefined itemType in SearchInOrgContextCard');
    }
  };
  defineParamName = (paramName) => {
    const definedParamName = text.historyLog[this.defineItemTypeForParamName(this.props.itemType)][paramName];
    if (definedParamName) {
      return definedParamName;
    } else {
      return paramName;
    }
  };
  render() {
    const title = concatIdName(this.props.searchedItem.id, this.props.searchedItem.name);
    const formattedTitle = formatWithSearchTextIllumination(this.props.text, title);
    const underTitle = this.props.searchedItem.contractNo ?
      this.props.searchedItem.contractNo + (this.props.searchedItem.typeLabel ? " " + this.props.searchedItem.typeLabel : "")
      : null;
    const formattedUnderTitle = underTitle ? formatWithSearchTextIllumination(this.props.text, underTitle) : null;
    const linkTo = getItemPathView(this.props.organizationId, this.props.itemType, this.props.searchedItem.id);
    const formattedMatchedParams = this.props.searchedItem.matchedParams.map(param => {
      return {name: this.defineParamName(param.name), value: formatWithSearchTextIllumination(this.props.text, param.value)}
    });
    const formattedTags = this.props.searchedItem.tags.map(tag => formatWithSearchTextIllumination(this.props.text, tag));
    return (
      <SearchCard linkTo={linkTo} title={title} formattedTitle={formattedTitle} underTitle={underTitle}
                  formattedUnderTitle={formattedUnderTitle}
                  formattedMatchedParams={formattedMatchedParams} handleLinkClick={this.handleLinkClick}
                  formattedTags={formattedTags} addClasses={'mb_1du'}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    text: state.organization.searchInOrgContext.requestBody.text,
    organizationId: state.organization.organizationId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onUpdateSheetIsShown: (sheetIsShown) => dispatch({
      type: searchInOrgContextActions.SHEET_IS_SHOWN_UPDATE,
      value: sheetIsShown})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchInOrgContextCard);