//@flow
import * as React from 'react';
import {connect} from "react-redux";
import groupFinTermsValidationScheme from "../../../../../../../../../configData/groupFinTermsValidationScheme";
import {
  onGetValidationStateFormProp,
  onGetValidationStateProp
} from "../../../../../../../../../services/flowTypes/propFnFlowTypes";
import type {
  finTerm,
  isScrollToError,
  validationIsAllowed
} from "../../../../../../../../../services/flowTypes/dataFlowTypes";
import type {children} from "../../../../../../../../../services/flowTypes/appFlowTypes";
import type {propError} from "../../../../../../../../../services/flowTypes/componentFlowTypes";
import ArrLengthValidator from "../../../../../../../../../components/service/ArrLengthValidator/ArrLengthValidator";

type Props = {
  finTerms: Array<finTerm>,
  onGetValidationStateProp: onGetValidationStateProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  children: children,
  validationIsAllowed: validationIsAllowed,
  isScrollToError: isScrollToError,
  propError: propError
};

class FinTermsLengthValidator extends React.Component <Props, State> {
  determineArrLength = () => {
    let finTermsLength = 0;
    for (let i=0; i < this.props.finTerms.length; i++) {
      if (! (this.props.finTerms[i].status && this.props.finTerms[i].status === 'DELETED')) {
        finTermsLength++;
      }
    }
    return finTermsLength;
  };
  render() {
    return (
      <ArrLengthValidator propName={'finTermsLength'} arrLength={this.props.finTerms.length}
                          propValidationPatternsArr={groupFinTermsValidationScheme.finTermsLength}
                          determineArrLength={this.determineArrLength}>
        {this.props.children}
      </ArrLengthValidator>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    finTerms: state.organization.item.finTermsEdit
  };
};
export default connect(mapStateToProps, null)(FinTermsLengthValidator);