//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {addServiceCalculatedItem, addServiceType, currency} from "../../../services/flowTypes/dataFlowTypes";
import {getCurrencyIco} from "../../../utils/getCurrencyLabel";
import TextOverflowHandler from "../../simple/Containers/TextOverflowHandler/TextOverflowHandler";
import Text1 from "../../simple/TextComponents/Text1/Text1";
import Heading4 from "../../simple/TextComponents/Heading4/Heading4";
import getMaskedInputValue from "../../../utils/getMaskedInputValue";

type Props = {
  calculatedItemIndex: number,
  calculatedItem: addServiceCalculatedItem,
  currency: currency,
  addServiceTypeList: Array<addServiceType>
};

class SheetCalculatedItemView extends React.Component <Props> {
  render() {
    const className = this.props.calculatedItemIndex % 2 === 0 ? "SheetCalculatedItemView  SheetCalculatedItemView_even"
      : "SheetCalculatedItemView";
    const addServiceTypeObj = this.props.addServiceTypeList.find(item => item.id === this.props.calculatedItem.addServiceTypeId);
    const addServiceName = addServiceTypeObj ? addServiceTypeObj.name : '!Add service type not found';
    return (
      <div className={className}>
        <div className="SheetCalculatedItemView__addServiceTypeId">
          <TextOverflowHandler isInputWrapperMode={true} inputValue={addServiceName}>
            <Text1 addClasses={'Text1_lh40'}>{addServiceName}</Text1></TextOverflowHandler>
        </div>
        <div className="SheetCalculatedItemView__price">
          <Text1 addClasses={'Text1_lh40'}>{getMaskedInputValue(this.props.calculatedItem.price)}</Text1>
          <Heading4 addClasses={'Heading4_lh40 ml_1du'}>{getCurrencyIco(this.props.currency)}</Heading4>
        </div>
        <div className="SheetCalculatedItemView__number">
          <Text1 addClasses={'Text1_lh40 ta_r'}>{getMaskedInputValue(this.props.calculatedItem.number)}</Text1>
        </div>
        <div className="SheetCalculatedItemView__amount">
          <Heading4 addClasses={'Heading4_lh40 ta_r'}>
            {getMaskedInputValue(this.props.calculatedItem.amount)}
            <span className={'ml_1du'}>{getCurrencyIco(this.props.currency)}</span>
          </Heading4>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    addServiceTypeList: state.organization.organizationData.statuses.addServiceTypeList
  };
};
export default connect(mapStateToProps, null)(SheetCalculatedItemView);