//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../RenderOnReady/RenderOnReady";
import organizationIdActions from "../../../store/organization/organizationId/organizationIdActions";
import {onSetOrganizationId} from "../../../services/flowTypes/propFnFlowTypes";

type Props = {
  onSetOrganizationId: onSetOrganizationId
};

class UnsetOrganizationId extends React.Component <Props> {
  componentDidMount(){
    this.props.onSetOrganizationId('');
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={true}/>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onSetOrganizationId: (organizationId) => dispatch({type: organizationIdActions.ORGANIZATION_ID_SET,
      value: organizationId})
  };
};
export default connect(null, mapDispatchToProps)(UnsetOrganizationId);