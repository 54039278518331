//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  organizationId,
  searchedDocumentResponse,
  searchRequestText
} from "../../../../services/flowTypes/dataFlowTypes";
import concatIdName from "../../../../utils/concatIdName";
import searchInOrgContextActions from "../../../../store/organization/searchInOrgContext/searchInOrgContextActions";
import type {onUpdateSheetIsShown} from "../../../../services/flowTypes/propFnFlowTypes";
import getItemPathView, {getItemPathDocumentsView} from "../../../../utils/getItemPathView";
import formatWithSearchTextIllumination from "../../../../utils/formatWithSearchTextIllumination";
import DocumentSearchCard from "../../../simple/Containers/SearchCard/DocumentSearchCard";
import text from "../../../../services/localization/text";

type Props = {
  text: searchRequestText,
  searchedDocumentResponse: searchedDocumentResponse,
  organizationId: organizationId,
  onUpdateSheetIsShown: onUpdateSheetIsShown
};

class SearchInOrgContextDocumentCard extends React.Component <Props> {
  handleLinkClick = () => {
    this.props.onUpdateSheetIsShown(false);
  };
  render() {
    const title = this.props.searchedDocumentResponse.fileName;
    const formattedTitle = formatWithSearchTextIllumination(this.props.text, title);
    const itemType = this.props.searchedDocumentResponse.itemType.toLowerCase();
    const linkToDoc = `/${itemType}/${this.props.searchedDocumentResponse.itemId}/downloadDocument/${this.props.searchedDocumentResponse.fileId}/${this.props.searchedDocumentResponse.fileName}?o_id=${this.props.organizationId}`;
    const linkToItem = getItemPathView(this.props.organizationId, itemType, this.props.searchedDocumentResponse.itemId);
    const linkToItemLabel = concatIdName(this.props.searchedDocumentResponse.itemId, this.props.searchedDocumentResponse.itemName);
    const folderLabel = this.props.searchedDocumentResponse.folderName ? this.props.searchedDocumentResponse.folderName : text.withoutFolder;
    const documentsPath = getItemPathDocumentsView(this.props.organizationId, itemType, this.props.searchedDocumentResponse.itemId);
    const linkToFolder = this.props.searchedDocumentResponse.folderName ?
      documentsPath + '/folder/' + this.props.searchedDocumentResponse.folderId
       : documentsPath;
    const formattedContextSnippets = this.props.searchedDocumentResponse.contextSnippets.map(item => {
      return {
        formattedSnippet: formatWithSearchTextIllumination(this.props.text, item),
        initialSnippet: item
      };
    });
    return (
      <DocumentSearchCard linkToItem={linkToItem} title={formattedTitle} linkToDoc={linkToDoc}
                          linkToItemLabel={linkToItemLabel} handleLinkClick={this.handleLinkClick}
                          folderLabel={folderLabel} linkToFolder={linkToFolder} addClasses={'mb_1du'}
                          formattedContextSnippets={formattedContextSnippets}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    text: state.organization.searchInOrgContext.requestBody.text,
    organizationId: state.organization.organizationId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onUpdateSheetIsShown: (sheetIsShown) => dispatch({
      type: searchInOrgContextActions.SHEET_IS_SHOWN_UPDATE,
      value: sheetIsShown})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchInOrgContextDocumentCard);