import text from "../localization/text";
import moment from "moment";
import getMinMaxParamNumberValue from "../../utils/getMinMaxParamNumberValue";
import escapeRegExp from "../../utils/escapeRegExp";

const required = (value) => {
  if (value === undefined || value === null || !value.toString().trim().length) {
    return text.error.required;
  } else {
    return '';
  }
};
const selectOnlyOneValue = () => {
  return text.error.selectOnlyOneValue
}
const dateFormat = (value) => {
  if (value === '') {
    return text.error.dateFormat;
  } else {
    return '';
  }
};

const maxLength = (limitLength, value) => {
  const errorTextKey = 'maxLength' + limitLength;
  if (value && value.toString().trim().length > limitLength) {
    return text.error[errorTextKey];
  } else {
    return '';
  }
};
const maxLength31 = (value) => maxLength(31, value);
const maxLength128 = (value) => maxLength(128, value);
const maxLength150 = (value) => maxLength(150, value);
const maxLength256 = (value) => maxLength(256, value);
const maxLength1000 = (value) => maxLength(1000, value);
const maxLength2048 = (value) => maxLength(2048, value);

const oneInArrRequired = (arr, textError) => {
  const joinedValuesLength = arr.join('').length;
  if (joinedValuesLength) {
    return '';
  } else {
    return textError;
  }
};
const oneInRatesArrRequired = (arr) => {
  return oneInArrRequired(arr, text.error.oneInRatesArrRequired);
};
const oneInParamsArrRequired = (arr) => {
  return oneInArrRequired(arr, text.error.oneInParamsArrRequired);
};
const oneFinTermRequired = (arr) => {
  const joinedValuesLength = arr.join('').length;
  if (joinedValuesLength) {
    return '';
  } else {
    return text.error.oneFinTermRequired;
  }
};
const notEmptyContractMainDataFilesArr = (length) => {
  return notEmptyLength(length, text.error.notEmptyContractMainDataFilesArr)
};
const notEmptyFinTermsArr = (length) => {
  return notEmptyLength(length, text.error.notEmptyFinTermsArr)
};
const notEmptyFinTermParamsArr = (length) => {
  return notEmptyLength(length, text.error.notEmptyFinTermParamsArr)
};
const notEmptyTerminalsArr = (length) => {
  return notEmptyLength(length, text.error.notEmptyTerminalsArr)
};
const notEmptySheetsArr = (length) => {
  return notEmptyLength(length, text.error.notEmptySheetsArr)
};
const notEmptyCalculatedItemsArr = (length) => {
  return notEmptyLength(length, text.error.notEmptyCalculatedItemsArr)
};
const notEmptyLength = (length, textError) => {
  if (length) {
    return '';
  } else {
    return textError;
  }
};
const sequenceDates = (startDate, endDate) => {
  if (endDate && endDate.length) {
    const isRightSequence = ( moment(startDate).isBefore(endDate, 'day')
      || moment(startDate).isSame(endDate, 'day') );
    if (isRightSequence) {
      return true;
    } else {
      return false
    }
  } else {
    return true;
  }
};
const compliedWithContractDates_withArgs = (date, startDate, endDate) => {
  return sequenceDates(startDate, date) && sequenceDates(date, endDate) ? "" : text.error.complianceWithContractDates;

};
const sequenceDatesInFinTerm = (startDate, endDate) => {
  return sequenceDates(startDate, endDate) ? '' : text.error.sequenceDates;
};
const sequenceDatesAmongFinTerms = (endDate, nextStartDate) => {
  if (nextStartDate && nextStartDate.length) {
    const isRightSequence = moment(endDate).isBefore(nextStartDate, 'day');
    if (isRightSequence) {
      return '';
    } else {
      return text.error.sequenceDates
    }
  } else {
    return '';
  }
};
const notEmptyUserMainDataOrganizationsArr = (params) => {
  if (!params || params.length === 0) {
    return text.error.userMainDataOrganizationLength
  }
  return ''
}
const minMaxCompliance = (params) => {
  const minMaxParams = params.map(paramsRow => {
    return {
      min: getMinMaxParamNumberValue(paramsRow, 'min'),
      max: getMinMaxParamNumberValue(paramsRow, 'max')
    };
  });
  for (let i=0; i < minMaxParams.length; i++) {
    if (minMaxParams[i].min !== 'Empty string' && minMaxParams[i].max !== 'Empty string') {
      if (!(minMaxParams[i].min < minMaxParams[i].max) ) {
        return text.error.minMaxCompliance;
      }
    } else {
      if (minMaxParams[i].min === 'Empty string' || (minMaxParams[i].max === 'Empty string' && i !== minMaxParams.length-1 )) {
        return text.error.minMaxCompliance;
      }
    }
    if (i < minMaxParams.length-1) {
      if (minMaxParams[i].max !== 'Empty string' && minMaxParams[i+1].min !== 'Empty string') {
        if (! (minMaxParams[i].max < minMaxParams[i+1].min)) {
          return text.error.minMaxCompliance;
        }
      } else {
        return text.error.minMaxCompliance;
      }

    }
  }
  return '';
};

const email = (value)  => {
  if (!value.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )) {
    return text.error.email
  }
  return ''
}

const groupFinTermNameMatchIsProhibited_withArgs = (value, arrToTest) => matchIsProhibited(value, arrToTest, text.error.groupFinTermNameMatchIsProhibited_withArgs);
const matchIsProhibited = (value, arrToTest, errorStr) => {
  const escapedValue = escapeRegExp(value);
  const regexStr = "^" + escapedValue + "$";
  const regExpToTest = new RegExp(regexStr, 'i');
  let isMatched = false;
  for (let i=0; i < arrToTest.length; i++) {
    isMatched = isMatched || regExpToTest.test(arrToTest[i]);
  }
  if (isMatched) {
    return errorStr;
  } else {
    return '';
  }
};

const payoutPeriodCompliance = (groupFinTermPayoutPeriod, actTemplatePayoutPeriod) => {
  if (groupFinTermPayoutPeriod === actTemplatePayoutPeriod) {
    return ''
  } else {
    return text.error.payoutPeriodCompliance;
  }
};

const forbiddenFileNameSymbols = (fileName) => {
  if (/[%/;\\]/.test(fileName)) {
    return text.error.forbiddenFileNameSymbols;
  } else {
    return '';
  }
};


const validationPatterns = {
  required: required,
  email: email,
  selectOnlyOneValue: selectOnlyOneValue,
  dateFormat: dateFormat,
  maxLength31: maxLength31,
  maxLength128: maxLength128,
  maxLength150: maxLength150,
  maxLength256: maxLength256,
  maxLength1000: maxLength1000,
  maxLength2048: maxLength2048,
  oneInRatesArrRequired: oneInRatesArrRequired,
  oneInParamsArrRequired: oneInParamsArrRequired,
  sequenceDatesInFinTerm: sequenceDatesInFinTerm,
  sequenceDatesAmongFinTerms: sequenceDatesAmongFinTerms,
  oneFinTermRequired: oneFinTermRequired,
  notEmptyFinTermsArr: notEmptyFinTermsArr,
  notEmptyFinTermParamsArr: notEmptyFinTermParamsArr,
  notEmptyTerminalsArr: notEmptyTerminalsArr,
  notEmptyContractMainDataFilesArr: notEmptyContractMainDataFilesArr,
  minMaxCompliance: minMaxCompliance,
  groupFinTermNameMatchIsProhibited_withArgs: groupFinTermNameMatchIsProhibited_withArgs,
  payoutPeriodCompliance: payoutPeriodCompliance,
  notEmptySheetsArr: notEmptySheetsArr,
  notEmptyCalculatedItemsArr: notEmptyCalculatedItemsArr,
  compliedWithContractDates_withArgs: compliedWithContractDates_withArgs,
  forbiddenFileNameSymbols: forbiddenFileNameSymbols,
  notEmptyUserMainDataOrganizationsArr: notEmptyUserMainDataOrganizationsArr,
};

export default validationPatterns;