//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {organizationId, organizationsList} from "../../../services/flowTypes/dataFlowTypes";
import concatIdName from "../../../utils/concatIdName";

type Props = {
  organizationId: organizationId,
  organizationsList: organizationsList
};

class NotificationSettingsOrgLabel extends React.Component <Props> {
  render() {
    const orgObj = this.props.organizationsList.find(item => item.id === this.props.organizationId);
    const orgName = orgObj ? orgObj.name : 'O';
    return (
      <div className="NotificationSettingsOrgLabel">{concatIdName(this.props.organizationId, orgName)}</div>
    );
  }
}

const mapStateToProps = state => {
  return {
    organizationsList: state.general.organizationsList
  };
};
export default connect(mapStateToProps)(NotificationSettingsOrgLabel);