//@flow
import * as React from 'react';
import {connect} from "react-redux";
import {groupFinTermEditPageIds} from "../../../../../../../tests/testIds";
import text from "../../../../../../../services/localization/text";
import GroupFinTermsFormGroup from "./GroupFinTermsFormGroup";
import type {
  groupFinTermId, groupFinTermSource,
  typeOptions
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import QuestionTooltip from "../../../../../../../components/simple/Containers/QuestionTooltip/QuestionTooltip";
import tooltipMoreLinks from "../../../../../../../configData/tooltipMoreLinks";

type Props = {
  groupFinTermId: groupFinTermId,
  source: groupFinTermSource,
  typeOptions: typeOptions
};

export const defineTooltipForSelectOptions = (index, generalKey, item, arrayLength) => {
  const isRightPositioned = index > 2 || index === (arrayLength - 1);
  const labelTooltipText = text[generalKey][item];
  const linkTo = tooltipMoreLinks[generalKey][item];
  const moreLinkTo = linkTo ? linkTo: null;
  return  labelTooltipText ?
    (<QuestionTooltip addClasses={'QuestionTooltip_w350 ml_1du'} isRightBottomPositioned={!isRightPositioned}
                      isHighZIndex={true} moreLinkTo={moreLinkTo}
                      isRightPositioned={isRightPositioned}>{labelTooltipText}</QuestionTooltip>) : null;
};

class SelectGroupFinTermsType extends React.Component <Props> {
  render(){
    const typeSelectOptions = this.props.typeOptions ? this.props.typeOptions.map( (item, index) => {
      const localizedLabel = text[item] ? text[item] : item;
      return {value: item, label:
          (<div className={'flex ai_c'}>
            {localizedLabel}
            {defineTooltipForSelectOptions(index, 'groupFinTermTypeTooltip', item, this.props.typeOptions.length)}
          </div>)};
    }) : [];
    return (
      <GroupFinTermsFormGroup formFieldType={'select-full-card'}
                              propName={'type'}
                              isOptionOverflowVisible={true}
                              options={typeSelectOptions}
                              addClasses={'mb_3du'}
                              isSearchable={true}
                              selectInputId={groupFinTermEditPageIds.selectInputType}
                              disabled={this.props.groupFinTermId}
                              idForTest={groupFinTermEditPageIds.selectType}
                              noOptionsMessage={text.noOptionsMessageForGroupFinTermType}
                              placeholder={text.select.placeholder.type}/>
    );
  }
}

const mapStateToProps = (state) => {
  const source = state.organization.item.groupFinTermsEdit.source;
  const typeOptions = state.organization.organizationData.configFinTerms.configSourceParams[source] ?
    state.organization.organizationData.configFinTerms.configSourceParams[source].typeOptions : [];
  return {
    typeOptions
  };
};
export default connect(mapStateToProps, null)(SelectGroupFinTermsType);
