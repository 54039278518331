//@flow
import * as React from 'react';
import {connect} from "react-redux";
import EmptyList from "../../../../../../../components/simple/Containers/EmptyList/EmptyList";
import text from "../../../../../../../services/localization/text";
import type {
  globalPermissions,
  organizationsList, userId,
  userPermissions
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import NCard from "../../../../../../../components/simple/Containers/NCard/NCard";
import Heading5 from "../../../../../../../components/simple/TextComponents/Heading5/Heading5";
import Text1 from "../../../../../../../components/simple/TextComponents/Text1/Text1";
import {userOrganizationsPageIds} from "../../../../../../../tests/testIds";
import EditUserOrganizations from "../EditUserOrganizations/EditUserOrganizations";
import Can from "../../../../../../../services/permissions/Can";
import DeleteUserOrganization from "./DeleteUserOrganization/DeleteUserOrganization";
import type {initUpdateUser} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import {globalPermissionsStrValues} from "../../../../../../../configData/permissionsStrValues";

type Props = {
  userPermissions: userPermissions,
  allOrganizationsList: organizationsList,
  userId: userId,
  globalPermissions: globalPermissions,
  initUpdateUser?: initUpdateUser
};
class ConnectedUserOrganizationsList extends React.Component <Props> {
  render() {
    const userOrganizations = this.props.userPermissions.map((item, i) => {
      const organization = this.props.allOrganizationsList.find(org => org.id === item.organizationId);
      const organizationName = organization ? organization.name : '';
      return (
        <NCard key={'userOrganization'+i} addClasses={'mb_1du'}>
          <Heading5 addClasses={'Heading5_no-underline mb_1du'}>{organizationName}</Heading5>
          <div className="flex_jc-sb">
            <Text1>{text.role[item.role]}</Text1>
            {this.props.globalPermissions.includes(globalPermissionsStrValues.admin) ? null : (
              <div className="flex">
                {this.props.userPermissions.length === 1 ? null : (
                  <Can do={'useGlobalPermission'} on={globalPermissionsStrValues.user.update}>
                    <DeleteUserOrganization userOrganizationIndex={i} organizationName={organizationName}
                                            idForTest={userOrganizationsPageIds.linkToDelete(i)}
                                            userId={this.props.userId}
                                            initUpdateUser={this.props.initUpdateUser}/>
                  </Can>
                )}
                <Can do={'useGlobalPermission'} on={globalPermissionsStrValues.user.update}>
                  <EditUserOrganizations
                    userId={this.props.userId}
                    initUpdateUser={this.props.initUpdateUser}
                    btn={<div className="BtnEditAct" id={userOrganizationsPageIds.linkToEdit(i)}>{text.btnEdit}</div>}
                    editOrAdd={'edit'}
                    cardIndex={i}
                  />
                </Can>
              </div>
            )}
          </div>
        </NCard>
      );
    });
    return (
      <div className="ConnectedContractAgentsList">
        {userOrganizations.length ? userOrganizations : (<EmptyList emptyListLabel={text.emptyListLabel.userOrganizations}/>)}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allOrganizationsList: state.admin.allOrganizationsList,
    userPermissions: state.user.mainData.userPermissions,
    globalPermissions: state.user.mainData.globalPermissions
  };
};
export default connect(mapStateToProps, null)(ConnectedUserOrganizationsList);