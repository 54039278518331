//@flow
import * as React from 'react';
import {connect} from "react-redux";
import GenerateReportModal from "./GenerateReportModal";
import type {actTemplateIndex, isButtonInCard} from "../../../../../../../../services/flowTypes/componentFlowTypes";
import TextActionButton from "../../../../../../../../components/simple/Buttons/TextActionButton/TextActionButton";
import {actTemplatesViewPageIds} from "../../../../../../../../tests/testIds";
import text from "../../../../../../../../services/localization/text";
import Can from "../../../../../../../../services/permissions/Can";
import type {
  actTemplate,
  actTemplateId, addServiceId,
  contractId,
  groupFinTermId, isNotCompliedWithPaynetParams, lastValidReportDate,
  organizationId
} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import type {history, modalIsShown} from "../../../../../../../../services/flowTypes/appFlowTypes";
import {permissionsStrValues} from "../../../../../../../../configData/permissionsStrValues";
import BtnNewItem from "../../../../../../../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  isButtonInCard?: isButtonInCard,
  organizationId: organizationId,
  contractId: contractId,
  history: history,
  actTemplate: actTemplate,
  finTermGroupIds?: Array<groupFinTermId>,
  addServiceIds?: Array<addServiceId>,
  actTemplateId?: actTemplateId,
  actTemplateIndex?: actTemplateIndex,
  isGenerationProhibited?: boolean,
  lastValidReportDate?: lastValidReportDate,
  isNotCompliedWithPaynetParams?: isNotCompliedWithPaynetParams
};
type State = {
  generateReportModalIsShown: modalIsShown
};

class GenerateReportButton extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      generateReportModalIsShown: false
    };
  }
  onGenerateReportClick = () => {
    this.setState({
      generateReportModalIsShown: true
    });
  };
  hideGenerateReportModal = () => {
    this.setState({
      generateReportModalIsShown: false
    });
  };
  definePropsToGenerateReport = () => {
    if (this.props.match && this.props.match.params && this.props.match.params.actTemplateId) {
      if (+this.props.match.params.actTemplateId === this.props.actTemplate.id) {
        return {
          actTemplateId: this.props.actTemplate.id,
          finTermGroupIds: this.props.actTemplate.finTermGroupIds,
          addServiceIds: this.props.actTemplate.addServiceIds,
          lastValidReportDate: this.props.actTemplate.lastValidReportDate,
          isNotCompliedWithPaynetParams: this.props.actTemplate.isNotCompliedWithPaynetParams
        };
      } else {
        return {
          actTemplateId: null,
          finTermGroupIds: null,
          addServiceIds: null,
          lastValidReportDate: null,
          isNotCompliedWithPaynetParams: null
        };
      }
    } else {
      return {
        actTemplateId: this.props.actTemplateId,
        finTermGroupIds: this.props.finTermGroupIds,
        addServiceIds: this.props.addServiceIds,
        lastValidReportDate: this.props.lastValidReportDate,
        isNotCompliedWithPaynetParams: this.props.isNotCompliedWithPaynetParams
      };
    }
  };
  render() {
    const {actTemplateId, finTermGroupIds, addServiceIds, lastValidReportDate, isNotCompliedWithPaynetParams}
      = this.definePropsToGenerateReport();
    const isMainHeaderBtnDisabled = this.props.actTemplate.hasUnverifiedAddAgreements ? true
      : (this.props.actTemplate.lastValidReportDate ? false : this.props.actTemplate.isNotCompliedWithPaynetParams);
    // const lastValidReportDate = this.props.isButtonInCard ?
    //   this.props.lastValidReportDate : this.props.actTemplate.lastValidReportDate;
    return (actTemplateId ?
      <>
        {this.props.isButtonInCard ? (
          <Can do={'usePermission'} on={permissionsStrValues.report.create}>
            <TextActionButton onClick={this.onGenerateReportClick}
                              dataUiTestId={actTemplatesViewPageIds.buttonGenerateReport_(this.props.actTemplateId)}
                              id={actTemplatesViewPageIds.buttonGenerateReport(this.props.actTemplateIndex)}
                              disabled={this.props.isGenerationProhibited}
            >
              {text.generateReport}
            </TextActionButton>
          </Can>
        ) : (
        <Can do={'usePermission'} on={permissionsStrValues.report.create}>
          <BtnNewItem addClasses='BtnNewItem_grey mr_1du' onClick={this.onGenerateReportClick}
                      id={actTemplatesViewPageIds.buttonGenerateReport('')}
                         disabled={isMainHeaderBtnDisabled}
          >
            {text.generateReport}</BtnNewItem>
        </Can>
        )}
        {this.state.generateReportModalIsShown ? (
          <GenerateReportModal actTemplateId={actTemplateId} organizationId={this.props.organizationId}
                               contractId={this.props.contractId} isActTemplateViewMode={!this.props.isButtonInCard}
                               finTermGroupIds={finTermGroupIds} addServiceIds={addServiceIds}
                               lastValidReportDate={lastValidReportDate}
                               isNotCompliedWithPaynetParams={isNotCompliedWithPaynetParams}
                               history={this.props.history} cancelModal={this.hideGenerateReportModal}/>
        ) : null}
      </> : null
    );
  }
}

const mapStateToProps = state => {
  return {
    actTemplate: state.organization.item.actTemplatesEdit
  };
};
export default connect(mapStateToProps, null)(GenerateReportButton);