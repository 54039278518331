//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {history, isReadyToRender, locationType} from "../../../../../../services/flowTypes/appFlowTypes";
import api from "../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import type {errorObject, match} from "../../../../../../services/flowTypes/appFlowTypes";
import {onGetAddServices, onInitAddServices} from "../../../../../../services/flowTypes/propFnFlowTypes";
import GetDataOnMountAndUpdate
  from "../../../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import type {contractStatus, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import type {
  onGetLinkedItemsRequestParamsProp,
  onInitLinkedItemsRequestParamsState
} from "../../../../../../services/flowTypes/propFnFlowTypes";
import linkedItemsRequestParamsActions
  from "../../../../../../store/organization/linkedItemsRequestParams/linkedItemsRequestParamsActions";
import AddServicesFilters from "./addServices/AddServicesFilters";
import AddServicesList from "./addServices/AddServicesList";
import handleCorrectOrgIdInResponse from "../../../../../../utils/handleCorrectOrgIdInResponse";
import RedirectToCorrectOrgUrl
  from "../../../../../../components/service/RedirectToCorrectOrgUrl/RedirectToCorrectOrgUrl";
import addServicesActions from "../../../../../../store/organization/item/addServices/addServicesActions";

type Props = {
  match: match,
  location: locationType,
  onGetAddServices: onGetAddServices,
  onInitAddServices: onInitAddServices,
  history: history,
  onGetLinkedItemsRequestParamsProp: onGetLinkedItemsRequestParamsProp,
  onInitLinkedItemsRequestParamsState: onInitLinkedItemsRequestParamsState,
  status: contractStatus,

};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject,
  correctOrganizationId: organizationId
};

class AddServices extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      correctOrganizationId: null
    };
  }
  handleResponse = (responseData) => {
    this.props.onGetAddServices(responseData);
    this.setState({isReadyToRender: true});
  };
  getAddServices = () => {
    this.props.onInitAddServices();
    const contractId = +this.props.match.params.id;
    api.getAddServices(contractId, this.props.status)
      .then(response => {
        handleCorrectOrgIdInResponse(this, response.data, this.setState, this.handleResponse);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  componentDidUpdate(prevProps: Props) {
    if (this.props.status !== prevProps.status && prevProps.status !== null && this.props.status !== null) {
      this.getAddServices();
    }
  }
  componentDidMount() {
    this.props.onInitLinkedItemsRequestParamsState();
    this.setState({isReadyToRender: true});
  }
  render() {
    const contractId = this.props.match.params.id;
    const organizationId = this.props.match.params.organizationId;
    return (
      <RedirectToCorrectOrgUrl correctOrganizationId={this.state.correctOrganizationId} history={this.props.history}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
          <AddServicesFilters/>
          {this.props.status !== null ? (
            <GetDataOnMountAndUpdate itemId={contractId} getDataOnMountAndUpdate={this.getAddServices}>
              <AddServicesList organizationId={organizationId}
                contractId={contractId}/>
            </GetDataOnMountAndUpdate>
          ) : null}
        </RenderOnReady>
      </RedirectToCorrectOrgUrl>
    );
  }
}

const mapStateToProps = state => {
  return {
    status: state.organization.linkedItemsRequestParams.hasOwnProperty('status') ?
      state.organization.linkedItemsRequestParams.status : null,

  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetAddServices: (addAgreements) => dispatch({
      type: addServicesActions.ADD_SERVICES_SET, value: addAgreements}),
    onInitAddServices: () => dispatch({type: addServicesActions.ADD_SERVICES_INIT}),
    onGetLinkedItemsRequestParamsProp: (searchRequestObj) => dispatch({type: linkedItemsRequestParamsActions.LINKED_ITEMS_REQUEST_PARAMS_PROP_SET,
      value: searchRequestObj}),
    onInitLinkedItemsRequestParamsState: () => dispatch({type: linkedItemsRequestParamsActions.LINKED_ITEMS_REQUEST_PARAMS_STATE_INIT})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddServices);