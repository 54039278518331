//@flow
import * as React from 'react';
import './Pre.css';
import {baseClass} from "../utilities/baseClass";
import type {id, children} from "../../../flowTypes/appFlowTypes";
import type {addClasses} from "../../../flowTypes/uiKitFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children
};

const Pre = (props: Props) => (
		<pre id={props.id} className={baseClass("Pre", props.addClasses)}>{props.children}</pre>
	);

export default Pre;