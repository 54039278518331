//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './HistoryArrowIcon.css';

type Props = {
  addClasses?: addClasses
};

const HistoryArrowIcon = (props: Props) => {
  return (<div className={baseClass("HistoryArrowIcon mr_2du ml_2du", props.addClasses)}/>);
};

export default HistoryArrowIcon;    