//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import type {
  optionsAreShown,
  optionsContainerHeight,
  dropDownOptions,
  dropDownTitle,
  elOverflow,
  clickTitleHandler,
  optionsAreShownOnMount,
  optionsContainerHeightIsAuto
} from "../../../../services/flowTypes/componentFlowTypes";
import './DropDown.css';
import {Collapse} from 'react-collapse';

type Props = {
  id?: id,
  addClasses?: addClasses,
  dropDownTitle: dropDownTitle,
  dropDownOptions: dropDownOptions,
  clickTitleHandler?: clickTitleHandler,
  optionsAreShownOnMount?: optionsAreShownOnMount,
  optionsContainerHeightIsAuto?: optionsContainerHeightIsAuto,
  isDropDownTitleWithIco?: boolean
};
type State = {
  optionsAreShown: optionsAreShown,
  optionsContainerHeight: optionsContainerHeight,
  elOverflow: elOverflow
};

class DropDown extends React.Component <Props, State> {
  optionsContainerRef: { current: null | HTMLDivElement };
  constructor(props: Props) {
    super(props);
    this.state = {
      optionsAreShown: false,
      // optionsContainerHeight: "auto",
      // elOverflow: 'visible'
    };
    this.optionsContainerRef = React.createRef();
  }
  toggleDropDown = () => {
    if (this.props.clickTitleHandler) {
      this.props.clickTitleHandler();
    }
    this.setState({
      optionsAreShown: !this.state.optionsAreShown,
      // elOverflow: 'hidden'
    });
  };
  componentDidMount(){
    // const el = this.optionsContainerRef.current;
    // if (el) {
    //   const elHeight = window.getComputedStyle(el).getPropertyValue("height");
    //   this.setState({
    //     optionsContainerHeight: elHeight,
    //     optionsAreShown: this.props.optionsAreShownOnMount,
    //     elOverflow: this.props.optionsAreShownOnMount ? 'visible' : 'hidden'
    //   });
    // }
    if (this.props.optionsAreShownOnMount) {
      this.setState({
        optionsAreShown: this.props.optionsAreShownOnMount
      });
    }
  }
  // componentDidUpdate(prevProps:Props, prevState:State){
  //   if (this.state.optionsAreShown && prevState.optionsAreShown !== this.state.optionsAreShown) {
  //     setTimeout(()=>{
  //       this.setState({elOverflow: 'visible'})
  //     }, 10);
  //   }
  // }
  render() {
    // const optionsContainerHeight = this.props.optionsContainerHeightIsAuto ? "auto" : this.state.optionsContainerHeight;
    // const elHeight = this.state.optionsAreShown ? optionsContainerHeight : "0";
    // const dropDownOptionsClassName = this.state.optionsAreShown ?
    //   "DropDownOptions DropDownOptions_shown" : "DropDownOptions";
    const dropDownTitleClassName = this.state.optionsAreShown ?
      "DropDownTitle DropDownTitle_optionsAreShown" : "DropDownTitle";
    return (
      <div id={this.props.id} className={baseClass("DropDown", this.props.addClasses)}>
        <div className={dropDownTitleClassName} onClick={this.toggleDropDown}>
          {this.props.dropDownTitle}
          {this.props.isDropDownTitleWithIco ? (<div className="NDropDownDetails__ico"/>) : null}
        </div>
        {/*<div style={{height: elHeight, overflow: this.state.elOverflow}} ref={this.optionsContainerRef}*/}
        {/*     className={dropDownOptionsClassName}>*/}
        {/*  {this.props.dropDownOptions}*/}
        {/*</div>*/}
        <Collapse isOpened={this.state.optionsAreShown}>
          {this.props.dropDownOptions}
        </Collapse>
      </div>
    );
  }
}
export default DropDown;