//@flow
import * as React from 'react';
import type {errorObject, isReadyToRender, modalIsShown} from "../../../../../../../services/flowTypes/appFlowTypes";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {
  cancelModal,
  onGetContractAgents,
  onGetSearchData
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import type {agentId, agentName, agentStatus, contractId, searchRequest} from "../../../../../../../services/flowTypes/dataFlowTypes";
import contractAgentsActions from "../../../../../../../store/organization/item/contractAgents/contractAgentsActions";
import {connect} from "react-redux";
import api from "../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import NModal from "../../../../../../../components/simple/Modals/NModal/NModal";
import text from "../../../../../../../services/localization/text";
import concatIdName from "../../../../../../../utils/concatIdName";
import NHeading2 from "../../../../../../../components/simple/TextComponents/NHeading2/NHeading2";
import searchDataActions from "../../../../../../../store/organization/searchData/searchDataActions";

type Props = {
  searchRequest: searchRequest,
  isContractAgentMode?: boolean,
  modalIsShown: modalIsShown,
  cancelModal: cancelModal,
  agentIdToDelete: agentId,
  agentNameToDelete: agentName,
  contractId?: contractId,
  onGetContractAgents: onGetContractAgents,
  status?: agentStatus,
  onGetSearchData: onGetSearchData
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};

class DeleteAgentModal extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true
    };
  }
  deleteContractAgent = () => {
    this.setState({isReadyToRender: false});
    api.deleteContractAgent(this.props.contractId, this.props.agentIdToDelete, this.props.status)
      .then(response => {
        this.props.onGetContractAgents(response.data);
        this.setState({isReadyToRender: true});
        this.props.cancelModal();
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  deleteAgent = () => {
    this.setState({isReadyToRender: false});
    api.deleteAgent(this.props.agentIdToDelete, this.props.searchRequest)
      .then(response => {
        this.props.onGetSearchData(response.data);
        this.setState({isReadyToRender: true});
        this.props.cancelModal();
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    const agentName = concatIdName(this.props.agentIdToDelete, this.props.agentNameToDelete);
    const modalContent = this.props.isContractAgentMode ?
      (
        <NHeading2 addClasses={'mt_3du mb_3du'}>
          {text.deleteContractAgentModalText1}
          <span className={"NHeading2 NHeading2__span-link-color"}>{agentName}</span>
          {text.deleteContractAgentModalText2}
        </NHeading2>
      ) : (
        <NHeading2 addClasses={'mt_3du mb_3du'}>
          {text.deleteContractAgentModalText1}
          <span className={"NHeading2 NHeading2__span-link-color"}>{agentName}</span>
        </NHeading2>
      );
    const onModalActionBtnClick = this.props.isContractAgentMode ? this.deleteContractAgent : this.deleteAgent;
    return (
      this.props.modalIsShown ? (
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <NModal
          modalTitle={text.deleteContractAgentModal}
          onModalActionBtnClick={onModalActionBtnClick}
          onModalCancelBtnClick={this.props.cancelModal}
          modalActionBtnText={text.btnDel}
          modalCancelBtnText={text.cancel}
          isModalDocumentEdit={true}
        >
          {modalContent}
        </NModal>
      </>) : null
    );
  }
}
const mapStateToProps = state => {
  return {
    searchRequest: state.organization.searchRequest
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetSearchData: (data) => dispatch({type: searchDataActions.SEARCH_DATA_SET, value: data}),
    onGetContractAgents: (contractAgents) => dispatch({type: contractAgentsActions.CONTRACT_AGENTS_SET, value: contractAgents})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAgentModal);