const finTermsEditActions = {
  FIN_TERMS_EDIT_SET: 'FIN_TERMS_EDIT_SET',
  FIN_TERM_DELETE: 'FIN_TERM_DELETE',
  FIN_TERM_STATUS_DELETE_SET: 'FIN_TERM_STATUS_DELETE_SET',
  FIN_TERM_ADD: 'FIN_TERM_ADD',
  FIN_TERM_PROP_UPDATE: 'FIN_TERM_PROP_UPDATE',
  FIN_TERM_PARAM_UPDATE: 'FIN_TERM_PARAM_UPDATE',
  FIN_TERM_PARAMS_ROW_ADD: 'FIN_TERM_PARAMS_ROW_ADD',
  FIN_TERM_PARAMS_ROW_DELETE: 'FIN_TERM_PARAMS_ROW_DELETE'

};
export default finTermsEditActions;
