//@flow
import * as React from 'react';
import {connect} from "react-redux";
import text from "../../../../../../../../../services/localization/text";
import NModal from "../../../../../../../../../components/simple/Modals/NModal/NModal";
import {hideModal, onGetReportMainData} from "../../../../../../../../../services/flowTypes/propFnFlowTypes";
import ReportManualAdjustmentLayout from "./ReportManualAdjustmentLayout/ReportManualAdjustmentLayout";
import api from "../../../../../../../../../services/apiInstance/api";
import reportMainDataActions
  from "../../../../../../../../../store/organization/item/reportMainData/reportMainDataActions";
import type {
  adjustmentId,
  reportId,
  reportManualAdjustedGroupFinTerm
} from "../../../../../../../../../services/flowTypes/dataFlowTypes";
import apiErrorHandler from "../../../../../../../../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {errorObject, isReadyToRender} from "../../../../../../../../../services/flowTypes/appFlowTypes";
import type {fileIndex} from "../../../../../../../../../services/flowTypes/componentFlowTypes";

type Props = {
  hideModal: hideModal,
  onGetReportMainData: onGetReportMainData,
  reportId: reportId,
  reportManualAdjustment: Array<reportManualAdjustedGroupFinTerm>
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  fileArr: Array<any>,
  fileIndex: fileIndex,
  adjustmentId: adjustmentId
};


class ReportManualAdjustmentModal extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true,
      fileIndex: 0,
      fileArr: [],
      adjustmentId: -1
    };
  }
  handleResponse = (response) => {
    console.log(this.state.fileArr.length, this.state.fileIndex);
    if (this.state.fileArr.length > this.state.fileIndex) {
      this.sendAdjustedFile();
    } else {
      this.props.onGetReportMainData(response.data);
      this.props.hideModal();
      this.setState({
        isReadyToRender: true,
        adjustmentId: -1,
        fileIndex: 0,
        fileArr: []
      });
    }
  };
  defineRequestBody = () => {
    const fileArr = [];
    const requestBody = this.props.reportManualAdjustment.map(item => {
      const adjustedItem = {...item};
      if (adjustedItem.file) {
        fileArr.push({
          file: adjustedItem.file,
          finTermGroupId: adjustedItem.finTermGroupId
        });
      }
      delete adjustedItem.file;
      return adjustedItem;
    });
    this.setState({fileArr});
    return requestBody;
  };
  sendAdjustedFile = () => {
    const formData = new FormData();
    formData.append('file', this.state.fileArr[this.state.fileIndex].file);
    formData.append('finTermGroupId', this.state.fileArr[this.state.fileIndex].finTermGroupId);
    formData.append('adjustmentId', this.state.adjustmentId);
    api.uploadAdjustmentBaseDocument(this.props.reportId, formData)
      .then(response => {
        this.setState({fileIndex: this.state.fileIndex + 1}, () => {
          this.handleResponse(response);
        });
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  manualAdjust = () => {
    this.setState({isReadyToRender: false});
    api.manualAdjustReport(this.props.reportId, this.defineRequestBody())
      .then(response => {
        this.setState({adjustmentId: response.data.adjustmentId}, () => {
          this.handleResponse(response);
        });
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    return (
      <NModal
        modalTitle={text.manualAdjustment}
        modalCancelBtnText={text.cancel}
        modalActionBtnText={text.save}
        onModalCancelBtnClick={this.props.hideModal}
        onModalActionBtnClick={this.manualAdjust}
      >
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <ReportManualAdjustmentLayout/>
      </NModal>
    );
  }
}

const mapStateToProps = state => {
  return {
    reportManualAdjustment: state.organization.item.reportManualAdjustment
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetReportMainData: (reportMainData) => dispatch({
      type: reportMainDataActions.REPORT_MAIN_DATA_SET,
      value: reportMainData})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportManualAdjustmentModal);    