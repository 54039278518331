//@flow
import * as React from 'react';
import connect from "react-redux/es/connect/connect";
import ability from "../../../../../../../services/permissions/permissions";
import api from "../../../../../../../services/apiInstance/api";
import type {
  contractId, finTerm,
  actTemplate,
  organizationId, sourceParams, templateParams
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {
  errorObject,
  history,
  isReadyToRender, match
} from "../../../../../../../services/flowTypes/appFlowTypes";
import ConnectedItemControls
  from "../../../../../../../components/composed/ConnectedItemControls/ConnectedItemControls";
import validationActions from "../../../../../../../store/validation/validationActions";
import {
  onSetActTemplatesView,
  onSetDataToValidationState, onUpdateNeedFetchFlagsProp
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import actTemplatesViewActions
  from "../../../../../../../store/organization/item/actTemplatesView/actTemplatesViewActions";
import text from "../../../../../../../services/localization/text";
import getNameOrDefName from "../../../../../../../utils/getNameOrDefName";
import needFetchFlagsActions from "../../../../../../../store/needFetchFlags/needFetchFlagsActions";
import {
  actTemplatePath,
  contrPathView,
  orgPath
} from "../../../../../../../services/urlStrings/urlStrings";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";

type Props = {
  organizationId: organizationId,
  contractId: contractId,
  history: history,
  actTemplate: actTemplate,
  onSetActTemplatesView: onSetActTemplatesView,
  onSetDataToValidationState: onSetDataToValidationState,
  sourceParams: sourceParams,
  finTerms: Array<finTerm>,
  onUpdateNeedFetchFlagsProp: onUpdateNeedFetchFlagsProp,
  templateParams: templateParams,
  match: match
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  // stateToCompare: stateToCompare
};

class ActTemplatesControls extends React.Component <Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true,
      // stateToCompare: {},
      templateParamsToCompare: []
    };
  }
  getActTemplateId = () => {
    return this.props.match.params.actTemplateId ? +this.props.match.params.actTemplateId : null;
  };
  getReportFileNamePattern = () => {
    if (this.props.actTemplate.hasOwnProperty('reportFileNamePattern')) {
      return this.props.actTemplate.reportFileNamePattern;
    } else {
      return '';
    }
  };
  goToActTemplateView = (actTemplateId) => {
    const path = orgPath(this.props.organizationId) + contrPathView(this.props.contractId) + actTemplatePath(actTemplateId);
    this.props.history.push({pathname: path});
    this.props.onUpdateNeedFetchFlagsProp('actTemplatesList', true);
  };
  handleTagExpressions = (actTemplate) => {
    const handledActTemplate = {...actTemplate};
    const totalExpressionNameList = ['reportTotalCountExpression', 'reportTotalAmountExpression', 'reportTotalExpression'];
    const emptyExpression = (expr) => {
      return expr === "(ΣFT)" || expr === "FT" ? "" : expr;
    };
    for (let i=0; i < totalExpressionNameList.length; i++) {
      handledActTemplate[totalExpressionNameList[i]] = emptyExpression(handledActTemplate[totalExpressionNameList[i]]);
    }
    for (let i=0; i < handledActTemplate.reportTagExpressions.length; i++) {
      handledActTemplate.reportTagExpressions[i].expression = emptyExpression(handledActTemplate.reportTagExpressions[i].expression);
    }
    return handledActTemplate;
  };
  handleDataBeforeSend = () => {
    const actTemplate = {...this.props.actTemplate};
    actTemplate.name = getNameOrDefName(actTemplate.name, text.actTemplate);
    actTemplate.reportFileNamePattern = this.getReportFileNamePattern();
    actTemplate.finTermsTemplateParams = JSON.stringify(this.props.templateParams);
    if (this.getActTemplateId() === null) {
      actTemplate.contractId = this.props.contractId;
      delete actTemplate.fileName;
      delete actTemplate.addendumTemplateFileName;
    }
    delete actTemplate.convertedTagUpdated;
    const actTemplateWithHandledTagExpressions = this.handleTagExpressions(actTemplate);
    return actTemplateWithHandledTagExpressions;
  };
  requestUpdate = (actTemplateData) => {
    api.updateActTemplate(actTemplateData)
      .then(response => {
        this.props.onSetActTemplatesView([]);
        this.goToActTemplateView(response.data.id);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  requestCreate = (actTemplateData) => {
    api.createActTemplate(actTemplateData)
      .then(response => {
        this.setState({isReadyToRender: true});
        this.goToActTemplateView(response.data.id);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  sendRequest = () => {
    this.setState({isReadyToRender: false});
    const actTemplateData = this.handleDataBeforeSend();
    if (this.getActTemplateId() === null) {
      this.requestCreate(actTemplateData);
    } else {
      this.requestUpdate(actTemplateData);
    }
  };
  cancel = () => {
    this.props.history.goBack();
  };
  componentDidMount(){
    //for update actTemplate
    // if (this.getActTemplateId() === this.props.actTemplate.id) {
    //   this.setState({stateToCompare: JSON.stringify(this.props.actTemplate)});
    // }
    //for new actTemplate
    // if (this.getActTemplateId() === null) {
    //   this.setState({stateToCompare: JSON.stringify(this.props.actTemplate)});
    // }
  }
  componentDidUpdate(prevProps, prevState){
    // if ( (this.getActTemplateId() === this.props.actTemplate.id &&
    //   prevProps.actTemplate.id !== this.props.actTemplate.id) ) {
    //   this.setState({stateToCompare: JSON.stringify(this.props.actTemplate)});
    // }
  }
  render(){
    const permittedAction = this.getActTemplateId() === null ? permissionsStrValues.actTemplate.create
      : permissionsStrValues.actTemplate.update;
    const actionIsPermitted = ability.can('usePermission', permittedAction);
    // const submitIsDisabled = this.state.stateToCompare === JSON.stringify(this.props.actTemplate);
    const submitIsDisabled = false;
    return(
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <ConnectedItemControls
          actionIsPermitted={actionIsPermitted}
          submitIsDisabled={submitIsDisabled}
          cancelFn={this.cancel}
          sendRequest={this.sendRequest}
        />
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    actTemplate: state.organization.item.actTemplatesEdit,
    templateParams: state.organization.item.templateParams
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSetActTemplatesView: (actTemplates) => dispatch({
      type: actTemplatesViewActions.ACT_TEMPLATES_VIEW_SET, value: actTemplates}),
    onSetDataToValidationState: (data) => dispatch({
      type: validationActions.VALIDATION_STATE_SET,
      value: data}),
    onUpdateNeedFetchFlagsProp: (prop, needFetch) => dispatch({type: needFetchFlagsActions.NEED_FETCH_FLAGS_PROP_UPDATE,
      value: {
        prop: prop,
        data: needFetch
      }})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ActTemplatesControls);