//@flow
/* eslint-disable no-template-curly-in-string */
import * as React from 'react';
import {connect} from "react-redux";
import actTemplatesEditActions
  from "../../../../../../../store/organization/item/actTemplatesEdit/actTemplatesEditActions";
import type {onGetActTemplateProp} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import text from "../../../../../../../services/localization/text";

type Props = {
  onGetActTemplateProp: onGetActTemplateProp
};
const standardReportFileName = 'Акт_${docNum}_от_${document_date}_';

class PasteStandardFileName extends React.Component <Props, State> {
  onClick = () => {
    this.props.onGetActTemplateProp('reportFileNamePattern', standardReportFileName);
  };
  render() {
    return (<div className="TagToCopy flex d_ib" title={standardReportFileName}
                 onClick={this.onClick}>{text.pasteExampleFromHint}</div>);
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetActTemplateProp: (prop, data) => dispatch({type: actTemplatesEditActions.ACT_TEMPLATES_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};

export default connect(null, mapDispatchToProps)(PasteStandardFileName);