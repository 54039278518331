//@flow
import * as React from 'react';
import {connect} from "react-redux";
import Filters from "../../../../../../components/composed/Filters/Filters";
import gatesParamsSearchActions
  from "../../../../../../store/organization/item/gatesParamsSearch/gatesParamsSearchActions";
import type {
  configSourceParams,
  contractsList,
  currencyOptions,
  gatesParamsSearch,
  payoutPeriodOptions,
  sourceOptions
} from "../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../services/localization/text";
import type {selectOptions} from "../../../../../../services/flowTypes/componentFlowTypes";
import getCurrencyLabel from "../../../../../../utils/getCurrencyLabel";
import {
  getOptionsForPNESourceParam
} from "../../../../../Contracts/pages/Contract/pages/GroupFinTermsView/groupFinTermsView/GroupFinTermsFilters";
import {
  paynetSources
} from "../../../../../Contracts/pages/Contract/pages/ContractMainDataEdit/contractMainDataEdit/PaynetParams";

type Props = {
  onUpdateGatesParamsSearchProp: Function,
  onDeleteGatesParamsSearchProp: Function,
  gatesParamsSearch: gatesParamsSearch,
  contractsList: contractsList,
  currencyOptions: currencyOptions,
  payoutPeriodOptions: payoutPeriodOptions,
  sourceOptions: sourceOptions,
  configSourceParams: configSourceParams
};
type State = {
  gateShowModeOptions: selectOptions,
  gateSourceOptions: selectOptions,
  gateStatusOptions: selectOptions,
  contractIdOptions: selectOptions,
  currencyOptions: selectOptions,
  payoutPeriodOptions: selectOptions,
  sourceOptions: selectOptions,
  cardTypeOptions: selectOptions,
  transactionStatusOptions: selectOptions,
  transactionTypeOptions: selectOptions,
  terminalStatus: selectOptions
};

const gatesParamsFiltersPropsList = ['gateShowMode', 'terminalStatus',
  // 'gateSource',
  'gateCurrency', 'gateStatus', 'gateId','terminalId', 'gateName',
  "groupFinTermName", "payerId", "payeeId", "payerMerchantId", "payeeMerchantId",
  "currency", "payoutPeriod", "cardType", "transactionStatus", "transactionType", "source"];
const gateStatusList = ["E", "D"];
const terminalStatusList = ["E", "D"];
const gateShowModeList = ["withIgnores", "withErrors", "withoutErrors", "all"];

class GatesParamsFilters extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      gateShowModeOptions: [],
      gateSourceOptions: [],
      gateStatusOptions: [],
      contractIdOptions: [],
      currencyOptions: [],
      payoutPeriodOptions: [],
      sourceOptions: [],
      cardTypeOptions: [],
      transactionStatusOptions: [],
      transactionTypeOptions: [],
      terminalStatusOptions: []
    };
  }
  getFiltersList = (allFiltersList) => {
    const filtersList = [];
    const filtersForOptions = [];
    for (let i=0; i < allFiltersList.length; i++) {
      const condition = this.props.gatesParamsSearch.hasOwnProperty(allFiltersList[i].propName);
      if (condition) {
        filtersList.push(allFiltersList[i]);
      } else {
        filtersForOptions.push(allFiltersList[i]);
      }
    }
    return {filtersList, filtersForOptions};
  };
  componentDidMount(): * {
    const gateShowModeOptions = gateShowModeList.map(item => {
      return {value: item, label: text.gateShowModeOptions[item]};
    });
    const filteredPaynetSources = paynetSources.filter(item => this.props.sourceOptions.includes(item));
    const gateSourceOptions = filteredPaynetSources.map(item => {
      return {value: item, label: text[item]};
    });
    const gateStatusOptions = gateStatusList.map(item => {
      return {value: item, label: text.gateStatusLabel[item]};
    });
    const terminalStatusOptions = terminalStatusList.map(item => {
      return {value: item, label: text.terminalStatusLabel[item]};
    });
    const contractIdOptions = this.props.contractsList.map(item => {
      return {value: item.id, label: item.name}
    });
    const currencyOptions = this.props.currencyOptions.map(item => {
      return {value: item, label: getCurrencyLabel(item)};
    });
    const payoutPeriodOptions = this.props.payoutPeriodOptions.map(item => {
      return {value: item, label: text[item]};
    });
    const sourceOptions = this.props.sourceOptions.map(item => {
      return {value: item, label: text[item]};
    });
    const cardTypeOptions = getOptionsForPNESourceParam("cardType", this.props.configSourceParams);
    const transactionStatusOptions = getOptionsForPNESourceParam("transactionStatus", this.props.configSourceParams);
    const transactionTypeOptions = getOptionsForPNESourceParam("transactionType", this.props.configSourceParams);
    this.setState({
      gateShowModeOptions,
      gateSourceOptions,
      gateStatusOptions,
      contractIdOptions,
      currencyOptions,
      payoutPeriodOptions,
      sourceOptions,
      cardTypeOptions,
      transactionStatusOptions,
      transactionTypeOptions,
      terminalStatusOptions
    });
  }
  render() {
    const allFiltersList = [
      {propName: 'gateShowMode', formFieldType: 'filter-select', options: this.state.gateShowModeOptions, initValue: null, placeholder: text.filtersLabels.gateShowMode},
      {propName: 'gateCurrency', formFieldType: 'filter-select', options: this.state.currencyOptions, initValue: null, placeholder: text.filtersLabels.gateCurrency},
      {propName: 'payerMerchantId', formFieldType: 'filter-select', options: this.state.contractIdOptions, initValue: null, placeholder: text.filtersLabels.payerMerchantId},
      {propName: 'payeeMerchantId', formFieldType: 'filter-select', options: this.state.contractIdOptions, initValue: null, placeholder: text.filtersLabels.payeeMerchantId},
      {propName: 'payerId', formFieldType: 'filter-select', options: this.state.contractIdOptions, initValue: null, placeholder: text.filtersLabels.payerId},
      {propName: 'payeeId', formFieldType: 'filter-select', options: this.state.contractIdOptions, initValue: null, placeholder: text.filtersLabels.payeeId},
      {propName: 'cardType', formFieldType: 'filter-select', options: this.state.cardTypeOptions, initValue: null, placeholder: text.filtersLabels.cardType},
      {propName: 'transactionStatus', formFieldType: 'filter-select', options: this.state.transactionStatusOptions, initValue: null, placeholder: text.filtersLabels.transactionStatus},
      {propName: 'transactionType', formFieldType: 'filter-select', options: this.state.transactionTypeOptions, initValue: null, placeholder: text.filtersLabels.transactionType},
      {propName: 'source', formFieldType: 'filter-select', options: this.state.sourceOptions, initValue: null, placeholder: text.filtersLabels.source},
      {propName: 'gateId', formFieldType: 'filter-input', initValue: '', placeholder: text.gatesParamsFilters.gateIdPlaceholder},
      {propName: 'gateName', formFieldType: 'filter-input', initValue: '', placeholder: text.gatesParamsFilters.gateNamePlaceholder},
      {propName: 'terminalId', formFieldType: 'filter-input', initValue: '', placeholder: text.gatesParamsFilters.terminalIdPlaceholder},
      {propName: 'groupFinTermName', formFieldType: 'filter-input', initValue: '', placeholder: text.groupFinTermsFilters.groupFinTermNamePlaceholder},
      {propName: 'currency', formFieldType: 'filter-select', options: this.state.currencyOptions, initValue: null, placeholder: text.filtersLabels.currency},
      {propName: 'gateStatus', formFieldType: 'filter-select', options: this.state.gateStatusOptions, initValue: null, placeholder: text.filtersLabels.gateStatus},
      {propName: 'terminalStatus', formFieldType: 'filter-select', options: this.state.terminalStatusOptions, initValue: null, placeholder: text.filtersLabels.terminalStatus},
      {propName: 'payoutPeriod', formFieldType: 'filter-select', options: this.state.payoutPeriodOptions, initValue: null, placeholder: text.filtersLabels.payoutPeriod},
      // {propName: 'gateSource', formFieldType: 'filter-select', options: this.state.gateSourceOptions, initValue: null, placeholder: text.filtersLabels.gateSource}
    ];
    const {filtersList, filtersForOptions} = this.getFiltersList(allFiltersList);
    const resetFilters = () => this.props.onDeleteGatesParamsSearchProp(gatesParamsFiltersPropsList);
    // const addClasses = this.props.isModalMode ? "" : "Filters_sticky";
    return (
      <Filters onGetFiltersProp={this.props.onUpdateGatesParamsSearchProp}
               onDeleteFiltersProp={this.props.onDeleteGatesParamsSearchProp}
               filtersState={this.props.gatesParamsSearch}
               filtersForOptions={filtersForOptions}
               filtersList={filtersList}
               resetFilters={resetFilters}
               // addClasses={addClasses}
      />
    );
  }
}

const mapStateToProps = state => {
  const sourceOptions = state.organization.organizationData.configFinTerms.sourceOptions ?
    state.organization.organizationData.configFinTerms.sourceOptions : [];
  return {
    gatesParamsSearch: state.organization.item.gatesParamsSearch,
    contractsList: state.organization.organizationData.contractsList,
    currencyOptions: state.organization.organizationData.configFinTerms.currencyOptions,
    payoutPeriodOptions: state.organization.organizationData.configFinTerms.payoutPeriodOptions,
    sourceOptions,
    configSourceParams: state.organization.organizationData.configFinTerms.configSourceParams,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateGatesParamsSearchProp: (gatesParamsSearchObj) => dispatch({
      type: gatesParamsSearchActions.GATES_PARAMS_SEARCH_PROP_UPDATE, value: gatesParamsSearchObj}),
    onDeleteGatesParamsSearchProp: (gatesParamsSearchPropNameArr) => dispatch({
      type: gatesParamsSearchActions.GATES_PARAMS_SEARCH_PROP_DELETE, value: gatesParamsSearchPropNameArr}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GatesParamsFilters);
