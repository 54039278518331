export default function getMaskedInputValue(value){
  let transformedValue = value ? value : '';
  transformedValue = typeof transformedValue === 'number' ?  '' + transformedValue : transformedValue;
  transformedValue = transformedValue.replace(/,/g, '.' );
  transformedValue = transformedValue.replace(/\s/g, '' );
  transformedValue = transformedValue.replace(/[^\d.]+/g, '' );
  const dotIndex = transformedValue.indexOf('.');
  const intValLength = dotIndex !== -1 ? dotIndex : transformedValue.length;
  if (intValLength > 3) {
    const remainder = intValLength % 3;
    let counter = remainder;
    let counterNext = 0;
    let arr = [];
    if (counter !== 0) {
      let subValue = transformedValue.substring(0, counter) + " ";
      arr.push(subValue);
    }
    while (counter < (intValLength - 3)) {
      counterNext = counter + 3;
      let subValue = transformedValue.substring(counter, counterNext) + " ";
      arr.push(subValue);
      counter = counterNext;
    }
    let subValue = transformedValue.substring((intValLength - 3));
    arr.push(subValue);
    transformedValue = '';
    for (let i=0; i < arr.length; i++) {
      transformedValue = transformedValue + arr[i];
    }
    return transformedValue;
  }

  return transformedValue;
}