//@flow
import * as React from 'react';
import {Link} from "react-router-dom";
import {userViewPageIds} from "../../../../../../tests/testIds";
import text from "../../../../../../services/localization/text";
import Can from "../../../../../../services/permissions/Can";
import {
  editOrganizationSettingsSuffix,
  mainDataSuffix,
  orgPath,
  orgSettingsSuffix,
  supOrgPathEdit
} from "../../../../../../services/urlStrings/urlStrings";
import type {organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import type {isSupLevel} from "../../../../../../services/flowTypes/componentFlowTypes";
import {globalPermissionsStrValues, permissionsStrValues} from "../../../../../../configData/permissionsStrValues";
import BtnNewItem from "../../../../../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  organizationId: organizationId,
  isSupLevel?: isSupLevel
};

const OrganizationEditButton = (props: Props) => {
  const organizationId = props.isSupLevel ? props.organizationId : +props.match.params.organizationId;
  const linkToEdit = props.isSupLevel ? supOrgPathEdit(organizationId) + mainDataSuffix
    : orgPath(organizationId) + orgSettingsSuffix + editOrganizationSettingsSuffix;
  const button = (
    <Link className={"button-wrapper-link"} to={linkToEdit}>
      <BtnNewItem addClasses={"BtnNewItem_grey"} id={userViewPageIds.buttonEdit}>{text.btnEdit}</BtnNewItem>
    </Link>
  );
  return (
    props.isSupLevel ? (
      <Can do={'useGlobalPermission'} on={globalPermissionsStrValues.admin}>
        {button}
      </Can>
    ) : (
      <Can do={'usePermission'} on={permissionsStrValues.organization.update}>
        {button}
      </Can>
    )

  );
};

export default OrganizationEditButton;    