//@flow
import * as React from 'react';
import contractMenuItems from "../../../../../../configData/contractMenuItems";
import RowMenu from "../../../../../../components/simple/Containers/RowMenu/RowMenu";
import type {contractId, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import ColumnMenu from "../../../../../../components/simple/Containers/RowMenu/ColumnMenu";
import SideLeftContainer from "../../../../../../components/simple/Containers/SideLeftContainer/SideLeftContainer";

type Props = {
  contractId: contractId,
  organizationId: organizationId
};

const ContractMenu = (props:Props) => {
  const menuItemsData = contractMenuItems(props.organizationId, props.contractId);
  return <SideLeftContainer><ColumnMenu menuItemsData={menuItemsData} addClasses={"ContractMenu"}/></SideLeftContainer>
};

export default ContractMenu;