//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  addServicesList,
  contractId,
  organizationId,
  usersList
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import AddServicesCard from "./AddServicesCard";
import EmptyListV2 from "../../../../../../../components/simple/Containers/EmptyList/EmptyListV2";

type Props = {
  addServices: addServicesList,
  contractId: contractId,
  usersList: usersList,
  organizationId: organizationId
};

class AddServicesList extends React.Component <Props> {
  render() {
    const addServicesCards = this.props.addServices === null ? null :
      this.props.addServices && this.props.addServices.length ? this.props.addServices.map((item, i)=> {
      return (<AddServicesCard key={'addService'+i} addService={item} addServiceIndex={i}
                               addClasses={'mb_1du'} usersList={this.props.usersList}
                               organizationId={this.props.organizationId}  contractId={this.props.contractId}/>);
    }) : <EmptyListV2/>;
    return (
      <div className="AddServices">
        {addServicesCards}
      </div>);
  }
}

const mapStateToProps = state => {
  return {
    addServices: state.organization.item.addServices,
    usersList: state.organization.organizationData.usersList
  };
};
export default connect(mapStateToProps, null)(AddServicesList);