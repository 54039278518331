//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {organization} from "../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../services/localization/text";
import PropValueView from "../../../../../../components/composed/PropValueView/PropValueView";
import StatusLabel from "../../../../../../components/simple/TextComponents/StatusLabel/StatusLabel";
import formatDate from "../../../../../../utils/formatDate";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import FormCreatableSelect
  from "../../../../../../components/simple/FormComponents/FormCreatableSelect/FormCreatableSelect";
import NFormGroup from "../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import type {locationType} from "../../../../../../services/flowTypes/appFlowTypes";
import OrganizationLinkedAgentValue from "./OrganizationLinkedAgentValue";
import "./OrganizationMainDataView.css";

type Props = {
  mainData: organization,
  location: locationType
};

class OrganizationMainDataView extends React.Component <Props> {
  render() {
    return (
        <NCard>
          <div className="flex_jc-sb mb_3du">
            <PropValueView label={text.name} value={this.props.mainData.name} addClasses={"w_half-card"}/>
            <PropValueView label={text.status}
                           value={
                             <StatusLabel addClasses={`StatusLabel_${this.props.mainData.status} StatusLabel_bold mr_2du`}>
                               {text.organizationStatus[this.props.mainData.status]}
                             </StatusLabel>
                           }
                           addClasses={"w_half-card"}/>
          </div>
          <div className="flex_jc-sb mb_3du">
            <PropValueView label={text.createDate} value={formatDate(this.props.mainData.createDate)} addClasses={"w_half-card"}/>
            <PropValueView label={text.organizationLinkedAgent}
                           value={<OrganizationLinkedAgentValue linkedAgentId={this.props.mainData.linkedAgentId}
                                                                organizationId={this.props.mainData.id}/>}
                           addClasses={"w_half-card"}/>
          </div>
          <div className="flex_jc-sb mb_3du">
            <PropValueView label={text.organizationCode} value={this.props.mainData.organizationCode} addClasses={"w_half-card"}/>
            <PropValueView label={text.locale} value={this.props.mainData.locale} addClasses={"w_half-card"}/>
          </div>
          <div className="flex_jc-sb mb_3du">
            <PropValueView label={text.source} value={this.props.mainData.sourceNames.join(', ')}
                           addClasses={"w_half-card"}/>
            <PropValueView label={text.currency} value={this.props.mainData.currencyCodes.join(', ')}
                           addClasses={"w_half-card"}/>
          </div>
          {this.props.mainData.contractTypesList.length ? (
            <NFormGroup label={text.contractTypes} addClasses={'mb_3du'}>
              <FormCreatableSelect disabled={true}  value={this.props.mainData.contractTypesList}/>
            </NFormGroup>
          ) : (
            <PropValueView label={text.contractTypes} value={''} addClasses={'mb_3du'}/>
          )}
          {this.props.mainData.agentLinkedTypeList.length ? (
            <NFormGroup label={text.agentLinkedTypes} addClasses={'mb_3du'}>
              <FormCreatableSelect disabled={true}  value={this.props.mainData.agentLinkedTypeList}/>
            </NFormGroup>
          ) : (
            <PropValueView label={text.agentLinkedTypes} value={''} addClasses={'mb_3du'}/>
          )}
          {this.props.mainData.contractLinkedTypeList.length ? (
            <NFormGroup label={text.contractLinkedTypes} addClasses={'mb_3du'}>
              <FormCreatableSelect disabled={true}  value={this.props.mainData.contractLinkedTypeList}/>
            </NFormGroup>
          ) : (
            <PropValueView label={text.contractLinkedTypes} value={''} addClasses={'mb_3du'}/>
          )}
          {this.props.mainData.addServiceTypeList.length ? (
            <NFormGroup label={text.addServiceTypeList} addClasses={'mb_3du'}>
              <FormCreatableSelect disabled={true}  value={this.props.mainData.addServiceTypeList}/>
            </NFormGroup>
          ) : (
            <PropValueView label={text.addServiceTypeList} value={''}  addClasses={'mb_3du'}/>
          )}
          {this.props.mainData.notedTerminalTypeList.length ? (
            <NFormGroup label={text.notedTerminalTypeList}>
              <FormCreatableSelect disabled={true}  value={this.props.mainData.notedTerminalTypeList}/>
            </NFormGroup>
          ) : (
            <PropValueView label={text.notedTerminalTypeList} value={''} />
          )}
        </NCard>
    );
  }
}

const mapStateToProps = state => {
  return {
    mainData: state.organization.mainData
  };
};
export default connect(mapStateToProps, null)(OrganizationMainDataView);