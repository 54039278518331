//@flow
import * as React from 'react';
import {Link} from "react-router-dom";
import {actTemplateViewIds} from "../../../../../../tests/testIds";
import text from "../../../../../../services/localization/text";
import Can from "../../../../../../services/permissions/Can";
import {actTemplatePath, contrPathEdit, orgPath} from "../../../../../../services/urlStrings/urlStrings";
import type {contractId, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import type {match} from "../../../../../../services/flowTypes/appFlowTypes";
import {permissionsStrValues} from "../../../../../../configData/permissionsStrValues";
import BtnNewItem from "../../../../../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  organizationId: organizationId,
  contractId: contractId,
  match: match
};

const ActTemplateEditButton = (props: Props) => {
  const actTemplateId = props.match.params.actTemplateId ? +props.match.params.actTemplateId : null;
  const linkToEdit = orgPath(props.organizationId) + contrPathEdit(props.contractId) + actTemplatePath(actTemplateId);
  return (
    <Can do={'usePermission'} on={permissionsStrValues.actTemplate.update}>
      <Link className={"button-wrapper-link"} to={linkToEdit}>
        <BtnNewItem id={actTemplateViewIds.buttonEdit}>{text.btnEdit}</BtnNewItem>
      </Link>
    </Can>
  );
};

export default ActTemplateEditButton;    