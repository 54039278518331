import {groupFinTermTypeHasRateAbsAnotherNameArr} from "../configData/groupFinTermTypesFeatures";

const getRateAbsLocaleKey = (groupFinTermType) => {
  if (groupFinTermTypeHasRateAbsAnotherNameArr.includes(groupFinTermType)) {
    return 'rateAbsForTxTypes';
  } else if (groupFinTermType === 'MIN') {
    return 'rateAbsForMinType';
  } else {
    return 'rateAbs';
  }
};

const getFinTermParamRateLocaleKey = (paramName, groupFinTermType) => {
  if (paramName === 'rateAbs') {
    return getRateAbsLocaleKey(groupFinTermType);
  } else {
    return paramName;
  }
};
export default getFinTermParamRateLocaleKey;