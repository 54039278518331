//@flow
import notificationSettingsActions from './notificationSettingsActions';
import type {action} from "../../services/flowTypes/appFlowTypes";
import type {notificationResponse} from "../../services/flowTypes/dataFlowTypes";
import update from 'immutability-helper';

type reducerAction = action;
const initialState = [];

const notificationSettingsReducer = (state:notificationResponse = initialState, action:reducerAction) => {
  switch (action.type) {
    case notificationSettingsActions.NOTIFICATION_SETTINGS_UPDATE:
      return action.value;
    case notificationSettingsActions.NOTIFICATION_SETTINGS_PROP_UPDATE:
      return update(state, {
        [action.value.orgNotificationIndex]: {notifications: {[action.value.notificationIndex]: {$set: action.value.notification}}}
      });
    case notificationSettingsActions.NOTIFICATION_SETTINGS_PROPS_OF_ORG_UPDATE:
      const updatedNotifications = [...state[action.value.orgNotificationIndex].notifications];
      for (let i=0; i<updatedNotifications.length; i++){
        updatedNotifications[i].turnedOn = action.value.turnedOn;
      }
      return update(state, {
        [action.value.orgNotificationIndex]: {notifications: {$set: updatedNotifications}}
      });
    case notificationSettingsActions.NOTIFICATION_SETTINGS_PROPS_OF_CATEGORY_UPDATE:
      const updatedNotificationSettings = [...state];
      for (let j=0; j<updatedNotificationSettings.length;j++){
        for (let i=0; i<updatedNotificationSettings[j].notifications.length; i++){
          if (i===action.value.notificationIndex) {
            updatedNotificationSettings[j].notifications[i].turnedOn = action.value.turnedOn;
          }
        }
      }
      return update(state, {$set: updatedNotificationSettings});
    case notificationSettingsActions.NOTIFICATION_SETTINGS_PROPS_OF_ALL_UPDATE:
      const updatedAllNotificationSettings = [...state];
      for (let j=0; j<updatedAllNotificationSettings.length;j++){
        for (let i=0; i<updatedAllNotificationSettings[j].notifications.length; i++){
            updatedAllNotificationSettings[j].notifications[i].turnedOn = action.value.turnedOn;
        }
      }
      return update(state, {$set: updatedAllNotificationSettings});
    default:
      return state;
  }
};

export default notificationSettingsReducer;