//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  searchCategory,
  sortingOrder, typeSortAgent, typeSortContract
} from "../../../../../services/flowTypes/dataFlowTypes";
import searchRequestActions from "../../../../../store/organization/searchRequest/searchRequestActions";
import {
  handleSearchResultsUpdate,
  onGetSearchRequestProp
} from "../../../../../services/flowTypes/propFnFlowTypes";
import Sorting from "../Sorting/Sorting";

type Props = {
  onGetSearchRequestProp: onGetSearchRequestProp,
  handleSearchResultsUpdate: handleSearchResultsUpdate,
  sortingOrder:  sortingOrder,
  typeSortAgent: typeSortAgent,
  typeSortContract: typeSortContract,
  category: searchCategory
};

class ConnectedSorting extends React.Component <Props> {
  handleSortingChange = (type) => {
    let sortingOrder;
    if(type === this.props.typeSortContract && this.props.sortingOrder === 'up'){
      sortingOrder = 'down';
    } else if (type === this.props.typeSortContract && this.props.sortingOrder === 'down'){
      sortingOrder = 'up';
    } else {
      sortingOrder = this.props.sortingOrder;
    }
    const searchRequestObj = {
      offset: 0,
      sortingOrder:  sortingOrder,
      typeSortAgent: type,
      typeSortContract: type
    };
    this.props.onGetSearchRequestProp(searchRequestObj);
    this.props.handleSearchResultsUpdate(true);
  };

  render() {
    return (
      <Sorting
        handleSortingChange={this.handleSortingChange}
        category={this.props.category}
        sortingOrder={this.props.sortingOrder}
        typeSortAgent={this.props.typeSortAgent}
        typeSortContract={this.props.typeSortContract}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    sortingOrder: state.organization.searchRequest.sortingOrder,
    typeSortContract: state.organization.searchRequest.typeSortContract,
    typeSortAgent: state.organization.searchRequest.typeSortAgent,
    category: state.organization.searchRequest.category
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSearchRequestProp: (searchRequestObj) => dispatch({type: searchRequestActions.SEARCH_REQUEST_PROP_SET,
      value: searchRequestObj})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedSorting);