//@flow
import * as React from 'react';
import NewUiKitMenu from "./newUiKit/NewUiKitMenu";
import NewUiKitContent from "./newUiKit/NewUiKitContent";
import './NewUiKit.css';
import type {history} from "../../services/flowTypes/appFlowTypes";

type Props = {
  history: history
};

const NewUiKit = (props: Props) => {
  return (
    <div className={"UiKit"}>
      <NewUiKitMenu history={props.history}/>
      <NewUiKitContent/>
    </div>
  );
};

export default NewUiKit;    