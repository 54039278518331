//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {disabled, id, placeholder} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import Select, { components } from 'react-select';
import type {
  isOptionOverflowVisible,
  isSearchable, noOptionsMessage,
  onSelectChange,
  selectedOption, selectOptions
} from "../../../../services/flowTypes/componentFlowTypes";
import TextOverflowHandler from "../../Containers/TextOverflowHandler/TextOverflowHandler";

type Props = {
  id?: id,
  addClasses?: addClasses,
  isSearchable?: isSearchable,
  value: selectedOption,
  onChange: onSelectChange,
  options: selectOptions,
  isDisabled?: disabled,
  inputId?: id,
  placeholder?: placeholder,
  isOptionOverflowVisible?: isOptionOverflowVisible,
  noOptionsMessage?: noOptionsMessage
};
//TODO: style ico according to ui kit, delete css file, handle test
const SelectFullCard = (props: Props) => {
  const isSearchable = props.isSearchable ? props.isSearchable : false;
  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: 'var(--card-border-radius)',
      backgroundColor: '#ffffff',
      // boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
      border: '1px solid var(--sidebar-border-color)',
      cursor: 'pointer',
      minHeight: 'var(--full-card-select-height)',
      paddingLeft: 'calc(2*var(--distance-unit))',
      paddingRight: 'calc(2*var(--distance-unit))',
      opacity: props.isDisabled ? '.5' : '1'
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent'
    }),
    menuList: (provided, state) => ({
      ...provided,
      backgroundColor: '#ffffff'
    }),
    menu: (provided, state) => ({
      ...provided,
      position: 'relative',
      boxShadow: "var(--select-box-shadow)"
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: props.isOptionOverflowVisible ? 'visible' : 'hidden'
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'var(--dark-text-color)',
      fontFamily: 'var(--main-font-family)',
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '40px',
      overflow: props.isOptionOverflowVisible ? 'visible' : 'hidden'
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? 'var(--main-accent-color)' : 'var(--main-text-color)',
      backgroundColor: state.isFocused ? 'var(--item-back-color)' : '#ffffff',
      fontFamily: 'var(--main-font-family)',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '40px'
    })
  };
  const Option = (optionProps) => {
    const label = props.isOptionOverflowVisible ? optionProps.data.label :
      <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{optionProps.data.label}</TextOverflowHandler>;
    const innerProps = {...optionProps.innerProps, id: optionProps.data.value,
      "data-ui-test-id": props.id + "_" + optionProps.data.value};
    const newProps = {...optionProps, innerProps: innerProps, children: label};
    return (
      <components.Option {...newProps} />
    );
  };
  return (
    <div id={props.id} className={baseClass("SelectFullCard", props.addClasses)}>
      <Select
        components={{Option}}
        isSearchable={isSearchable}
        value={props.value}
        onChange={props.onChange}
        options={props.options}
        styles={customSelectStyles}
        isDisabled={props.isDisabled}
        placeholder={props.placeholder}
        inputId={props.inputId}
        noOptionsMessage={props.noOptionsMessage}
        // defaultMenuIsOpen={true}
      />
    </div>
  );
};

export default SelectFullCard;