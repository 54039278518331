//@flow
import organizationDataActions from './organizationDataActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {
  actTemplatesList,
  agentsList,
  configFinTerms, contractsList,
  contractTypes, groupFinTermsList, statusesLists,
  tagsList,
  usersList
} from "../../../services/flowTypes/dataFlowTypes";

type State = {
  contractTypes: contractTypes,
  agentsList: agentsList,
  usersList: usersList,
  tagsList: tagsList,
  actTemplatesList: actTemplatesList,
  configFinTerms: configFinTerms,
  statuses: statusesLists,
  contractsList: contractsList,
  groupFinTermsList: groupFinTermsList
};
type reducerAction = action;
const initialState = {
  contractTypes: null,
  agentsList: null,
  contractsList: null,
  actTemplatesList: null,
  usersList: null,
  tagsList: null,
  configFinTerms: null,
  statuses: null,
  groupFinTermsList: null
};

const organizationDataReducer = (state:State = initialState, action:reducerAction) => {
  switch (action.type) {
    case organizationDataActions.ORGANIZATION_DATA_PROP_SET:
      return {
        ...state,
        [action.value.prop]: action.value.data
      }
    default:
      return state;
  }
};

export default organizationDataReducer;