//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  configFinTermParams,
  groupFinTermType
} from "../../../../../../../../../../../../../services/flowTypes/dataFlowTypes";
import type {isReadyToRender} from "../../../../../../../../../../../../../services/flowTypes/appFlowTypes";
import type {
  finTermIndex, finTermParamsRowIndex,
  rateParamIndex
} from "../../../../../../../../../../../../../services/flowTypes/componentFlowTypes";
import RenderOnReady from "../../../../../../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import RateParamsValidator from "./RateParamsValidator";

type Props = {
  finTermIndex: finTermIndex,
  finTermParamsRowIndex: finTermParamsRowIndex,
  type: groupFinTermType,
  configFinTermParams: configFinTermParams,
};
type State = {
  isReadyToRender: isReadyToRender,
  ratePercentIndex: rateParamIndex,
  rateMinIndex: rateParamIndex,
  rateAbsIndex: rateParamIndex
};

class RateParamsValidatorWrapper extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      ratePercentIndex: null,
      rateMinIndex: null,
      rateAbsIndex: null
    };
  }
  defineRateParamIndex = (paramName) => {
    return (this.props.type && this.props.type.length) ?
      this.props.configFinTermParams[this.props.type].findIndex(item => item.name === paramName) : null;
  };
  componentDidMount(){
    this.setState({
      isReadyToRender: true,
      ratePercentIndex: this.defineRateParamIndex('ratePercent'),
      rateMinIndex: this.defineRateParamIndex('rateMin'),
      rateAbsIndex: this.defineRateParamIndex('rateAbs'),
    });
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
        <RateParamsValidator
          finTermIndex={this.props.finTermIndex}
          finTermParamsRowIndex={this.props.finTermParamsRowIndex}
          ratePercentIndex={this.state.ratePercentIndex}
          rateMinIndex={this.state.rateMinIndex}
          rateAbsIndex={this.state.rateAbsIndex}
        />
      </RenderOnReady>
    );
  }
}

const mapStateToProps = state => {
  return {
    type: state.organization.item.groupFinTermsEdit.type,
    configFinTermParams: state.organization.organizationData.configFinTerms.configFinTermParams,
  };
};
export default connect(mapStateToProps, null)(RateParamsValidatorWrapper);