//@flow
import * as React from 'react';
import {connect} from "react-redux";
import organizationIdActions from "../../../store/organization/organizationId/organizationIdActions";
import {apiClient} from "../../../services/apiInstance/DefaultApi";
import type {isReadyToRender, match} from "../../../services/flowTypes/appFlowTypes";
import {onInitReports, onInitSearchData, onSetOrganizationId} from "../../../services/flowTypes/propFnFlowTypes";
import type {organizationId} from "../../../services/flowTypes/dataFlowTypes";
import updateAbilities from "../../../services/permissions/updateAbilities";
import GetOrganizationData from "../GetPrerenderData/GetOrganizationData";
import searchDataActions from "../../../store/organization/searchData/searchDataActions";
import reportsActions from "../../../store/organization/reports/reportsActions";
import reportsSearchActions from "../../../store/organization/reportsSearch/reportsSearchActions";
import type {onInitHistory, onInitReportsSearch} from "../../../services/flowTypes/propFnFlowTypes";
import historyDataActions from "../../../store/organization/historyData/historyDataActions";

type Props = {
  match: match,
  onSetOrganizationId: onSetOrganizationId,
  organizationId: organizationId,
  onInitSearchData: onInitSearchData,
  onInitReports: onInitReports,
  onInitReportsSearch: onInitReportsSearch,
  onInitHistory: onInitHistory
};
type State = {
  isReadyToRender: isReadyToRender
};

class SetOrganizationIdByRoute extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false
    };
  }
  handleOrganizationId = () => {
    const routeOrganizationId = +this.props.match.params.organizationId;
    if (routeOrganizationId !== this.props.organizationId) {
      apiClient.defaults.headers['X-Organization-Id'] = routeOrganizationId;
      updateAbilities(routeOrganizationId);
      this.props.onSetOrganizationId(routeOrganizationId);
      this.props.onInitSearchData();
      this.props.onInitReports();
      this.props.onInitReportsSearch();
      this.props.onInitHistory();
    }
  };
  componentDidUpdate(){
    this.handleOrganizationId();
  }
  componentDidMount(){
    this.handleOrganizationId();
  }
  render() {
    const routeOrganizationId = +this.props.match.params.organizationId;
    return (
      routeOrganizationId === this.props.organizationId ? <GetOrganizationData/> : null
    );
  }
}
const mapStateToProps = state => {
  return {
    organizationId: state.organization.organizationId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSetOrganizationId: (organizationId) => dispatch({type: organizationIdActions.ORGANIZATION_ID_SET,
      value: organizationId}),
    onInitSearchData: () => dispatch({type: searchDataActions.SEARCH_DATA_INIT}),
    onInitReports: () => dispatch({type: reportsActions.SET_REPORTS_INIT_STATE}),
    onInitReportsSearch: () => dispatch({type: reportsSearchActions.REPORTS_SEARCH_INIT_STATE_SET}),
    onInitHistory: () => dispatch({type: historyDataActions.HISTORY_INIT})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SetOrganizationIdByRoute);    