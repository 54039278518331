//@flow
import * as React from 'react';
import {connect} from "react-redux";
import BtnAddRow from "../../../../../../../../../../../components/simple/Buttons/BtnAddRow/BtnAddRow";
import type {finTermIndex} from "../../../../../../../../../../../services/flowTypes/componentFlowTypes";
import {onAddFinTermParamsRow} from "../../../../../../../../../../../services/flowTypes/propFnFlowTypes";
import type {configFinTermParams, groupFinTermType} from "../../../../../../../../../../../services/flowTypes/dataFlowTypes";
import finTermsEditActions from "../../../../../../../../../../../store/organization/item/finTermsEdit/finTermsEditActions";
import {groupFinTermEditPageIds} from "../../../../../../../../../../../tests/testIds";

type Props = {
  finTermIndex: finTermIndex,
  onAddFinTermParamsRow: onAddFinTermParamsRow,
  type: groupFinTermType,
  configFinTermParams: configFinTermParams
};

class AddFinTermParamsRow extends React.Component <Props, State> {
  render() {
    const paramsRow = this.props.configFinTermParams[this.props.type];
    const onClick = () => this.props.onAddFinTermParamsRow(this.props.finTermIndex, paramsRow);
    return (
      <BtnAddRow id={groupFinTermEditPageIds.buttonAddFinTermRow(this.props.finTermIndex)} onClick={onClick}>+</BtnAddRow>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    type: state.organization.item.groupFinTermsEdit.type,
    configFinTermParams: state.organization.organizationData.configFinTerms.configFinTermParams
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onAddFinTermParamsRow: (finTermIndex, paramsRow) => dispatch({
      type: finTermsEditActions.FIN_TERM_PARAMS_ROW_ADD,
      value: {finTermIndex, paramsRow}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFinTermParamsRow);    