//@flow
import * as React from 'react';
import * as ReactDOM from "react-dom";
import Alerts from "./Alerts";

type Props = {};

class AlertsContainer extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const AlertsContainerComponent = (
      <div className="AlertsContainer">
        <Alerts/>
      </div>
    );
    let alertsContainer = document.getElementById('alertsContainer');
    return alertsContainer ?
      ReactDOM.createPortal(AlertsContainerComponent, alertsContainer)
      : null;
  }
}

export default AlertsContainer;    