import {groupFinTermSourceParam} from "../services/flowTypes/dataFlowTypes";
import text from "../services/localization/text";

const rateAbsRequiredTypes = ['BY_TX_COUNT', 'BY_TX_COUNT_SIMPLE', 'BY_TX_COUNT_COMPLEX', 'FIXED', 'FIXED_NO_PERIOD', 'MIN'];
const groupFinTermsValidationScheme = {
  name: ['groupFinTermNameMatchIsProhibited_withArgs'],
  payerId: ['required'],
  payeeId: ['required'],
  type: ['required'],
  source: ['required'],
  currency: ['required'],
  payoutPeriod: ['required'],
  sourceParams: ['oneInParamsArrRequired'],
  finTermsLength: ['notEmptyFinTermsArr'],
  merchantIds: (value, params: groupFinTermSourceParam[]) => {
    const excluded = params.filter(({name, value}) => ["mercIdsExcluded", "mngrMercIdsExcluded"].includes(name) && value === "true");
    if (value === "" && excluded.length > 0) {
      return text.error.required
    }
    return ''
  },
  managerIds: (value, params: groupFinTermSourceParam[]) => {
    const excluded = params.filter(({name, value}) => ["mngrIdsExcluded", "mngrMercIdsExcluded"].includes(name) && value === "true");
    if (value === "" && excluded.length > 0) {
      return text.error.required
    }
    return ''
  },
  processorIds: (value, params: groupFinTermSourceParam[]) => {
    const excluded = params.find(({name}) => name === "procIdsExcluded");
    if (value === "" && excluded && excluded.value === "true") {
      return text.error.required
    }
    return ''
  },
  mngrMercIdsExcluded: (value, params: groupFinTermSourceParam[]) => {
    const excluded = params.find(({name}) => name === "mercIdsExcluded");
    if (value === "true" && excluded && excluded.value === "true") {
      return text.error.selectOnlyOneValue
    }
    return ''
  },
  mercIdsExcluded: (value, params: groupFinTermSourceParam[]) => {
    const excluded = params.find(({name}) => name === "mngrMercIdsExcluded");
    if (value === "true" && excluded && excluded.value === "true") {
      return text.error.selectOnlyOneValue
    }
    return ''
  },
  finTerms: {
    addAgreementId: ['required'],
    startDate: ['dateFormat', 'required'],
    endDate: ['dateFormat'],
    dates: ['sequenceDatesInFinTerm', 'sequenceDatesAmongFinTerms'],
    finTermParamsLength: ['notEmptyFinTermParamsArr'],
    params: {
      paramsRow: ['oneInRatesArrRequired'],
      minAmount: ['required'],
      rateHours: ['required'],
      minCount: ['required'],
      rateAbs: (groupFinTermType) => {return rateAbsRequiredTypes.includes(groupFinTermType) ?  ['required'] : null;},
      minMaxParams: ['minMaxCompliance']
    }
  },
  gates: {
    terminalsLength: ['notEmptyTerminalsArr']
  }
};

export default groupFinTermsValidationScheme;