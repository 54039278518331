//@flow
import * as React from 'react';
import {baseClass} from "../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import './GroupFinTermCardParamsRow.css';
import type {finTerm, groupFinTermType} from "../../../services/flowTypes/dataFlowTypes";
import formatDate from "../../../utils/formatDate";
import getMaskedInputValue from "../../../utils/getMaskedInputValue";
import text from "../../../services/localization/text";

type Props = {
  id?: id,
  addClasses?: addClasses,
  finTerm: finTerm,
  type: groupFinTermType
};

const GroupFinTermCardParamsRow = (props: Props) => {
  let ranges = props.finTerm.params.map((range, index) => {
    const ratePercent = range.find((rangeItem) => rangeItem.name === 'ratePercent');
    const rateMin = range.find((rangeItem) => rangeItem.name === 'rateMin');
    const rateAbs = range.find((rangeItem) => rangeItem.name === 'rateAbs');
    const minAmount = range.find((rangeItem) => rangeItem.name === 'minAmount');
    const maxAmount = range.find((rangeItem) => rangeItem.name === 'maxAmount');
    const minCount = range.find((rangeItem) => rangeItem.name === 'minCount');
    const maxCount = range.find((rangeItem) => rangeItem.name === 'maxCount');
    const rateHours = range.find((rangeItem) => rangeItem.name === 'rateHours');
    const classNameMb = props.finTerm.params.length === index + 1 ? '' : 'mb_2du';
    return (
      <div className={"GroupFinTermCardParamsRow__range " + classNameMb} key={'condition' + index}>
        <div className="GroupFinTermCardParamsRow__percent Text1">
          {(ratePercent && ratePercent.value) ? (ratePercent.value + '%') : ''}
          {ratePercent && ratePercent.value &&
          ((rateMin && rateMin.value)
            || (rateAbs && rateAbs.value)) ? ', ' : ''}
          {(rateMin && rateMin.value) ? (rateMin.value + ' MIN') : ''}
          {((rateMin && rateMin.value) && (rateAbs && rateAbs.value)) ? ', ' : ''}
          {(rateAbs && rateAbs.value) ? (rateAbs.value + ' ABS') : ''}
          {(rateHours && rateHours.value) ? (rateHours.value + '/' + text.hour) : ''}
        </div>

        {props.type === "BY_VOLUME_COMPLEX" ||
        props.type === "BY_VOLUME_SIMPLE" ? (
          <div className="GroupFinTermCardParamsRow__range-amount">
            <div className="GroupFinTermCardParamsRow__range-amount-left Text1">{minAmount ? getMaskedInputValue(minAmount.value) : ''}</div>
            <div className="GroupFinTermCardParamsRow__range-amount-dash Text1">{" - "}</div>
            <div className="GroupFinTermCardParamsRow__range-amount-right Text1">{maxAmount ? getMaskedInputValue(maxAmount.value) : ''}</div>
          </div>
        ) : null}

        {props.type === "BY_TX_COUNT_SIMPLE" ||
        props.type === "BY_TX_COUNT_COMPLEX" ? (
          <div className="GroupFinTermCardParamsRow__range-amount">
            <div className="GroupFinTermCardParamsRow__range-amount-left Text1">{minCount ? getMaskedInputValue(minCount.value) : ''}</div>
            <div className="GroupFinTermCardParamsRow__range-amount-dash Text1">{" - "}</div>
            <div className="GroupFinTermCardParamsRow__range-amount-right Text1">{maxCount ? getMaskedInputValue(maxCount.value) : ''}</div>
          </div>
        ) : null}

      </div>

    );
  });
  return (
    <div id={props.id} className={baseClass("GroupFinTermCardParamsRow", props.addClasses)}>
      <div>
        <div className="GroupFinTermCardParamsRow__dates flex">
          <div className="GroupFinTermCardParamsRow__date Text1">{formatDate(props.finTerm.startDate)}</div>
          {props.finTerm.endDate ? (
            <>
              <div className="GroupFinTermCardParamsRow__date-dash Text1">{" - "}</div>
              <div className="GroupFinTermCardParamsRow__date Text1">{formatDate(props.finTerm.endDate)}</div>
            </>
          ) : null}
        </div>
        {props.finTerm.minRate ?
          <div className="GroupFinTermCardParamsRow__dates Text1">{"(MinRate: " + props.finTerm.minRate + ")"}</div>
          : null}
      </div>

      <div className="GroupFinTermCardParamsRow__ranges">
        {ranges}
      </div>
    </div>
  );
};

export default GroupFinTermCardParamsRow;