const sheetsActions = {
  SHEETS_SET: 'SHEETS_SET',
  SHEET_UPDATE: 'SHEET_UPDATE',
  SHEET_ADD: 'SHEET_ADD',
  SHEET_DELETE: 'SHEET_DELETE',
  SHEETS_PROP_UPDATE: 'SHEETS_PROP_UPDATE',
  SHEETS_CALCULATED_ITEM_PROP_UPDATE: 'SHEETS_CALCULATED_ITEM_PROP_UPDATE',
  SHEETS_CALCULATED_ITEMS_UPDATE: 'SHEETS_CALCULATED_ITEMS_UPDATE',
  SHEETS_CALCULATED_ITEM_DELETE: 'SHEETS_CALCULATED_ITEM_DELETE',
  SHEETS_CALCULATED_ITEM_ADD: 'SHEETS_CALCULATED_ITEM_ADD',
};
export default sheetsActions;
