//@flow
import * as React from 'react';
import NModal from "../../simple/Modals/NModal/NModal";
import text from "../../../services/localization/text";
import NFormGroup from "../../simple/FormComponents/NFormGroup/NFormGroup";
import FormSingleDatePicker from "../../simple/FormComponents/FormSingleDatePicker/FormSingleDatePicker";
import NInput from "../../simple/FormComponents/NInput/NInput";
import type {modalIsShown} from "../../../services/flowTypes/appFlowTypes";
import type {onEditLinkedItemClick, hideModal} from "../../../services/flowTypes/propFnFlowTypes";
import type {
  agentId,
  contractId,
  linkDescription, linkTypeId, linkDate, contractLinkedTypeList, agentLinkedTypeList
} from "../../../services/flowTypes/dataFlowTypes";
import NSelect from "../../simple/FormComponents/NSelect/NSelect";
import type {itemType, selectOptions} from "../../../services/flowTypes/componentFlowTypes";
import {editLinkedItemModalIds} from "../../../tests/testIds";

type Props = {
  modalIsShown: modalIsShown,
  hideModal: hideModal,
  itemLinkedTypeList: contractLinkedTypeList | agentLinkedTypeList,
  onEditLinkedItemClick: onEditLinkedItemClick,
  id: contractId | agentId,
  linkDate: linkDate,
  typeId: linkTypeId,
  description: linkDescription,
  itemType: itemType
};

type State = {
  id: contractId | agentId,
  linkDate: linkDate,
  typeId: linkTypeId,
  description: linkDescription,
  linkTypeOptions: selectOptions
};

class EditLinkedItemModal extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      id: null,
      linkDate: '',
      typeId: null,
      description: '',
      linkTypeOptions: []
    };
  }
  hideModal = () => {
    this.props.hideModal(false);
  };
  handleDateChange = (date: string) => {
    this.setState({linkDate: date});
  };
  handleLinkTypeChange = (selectedOption) => {
    this.setState({typeId: selectedOption.value});
  };
  handleDescriptionChange = (e) => {
    this.setState({description: e.target.value});
  };
  handleFormSubmit = () => {
    let requestObj = {
      id: this.state.id,
      linkDate: this.state.linkDate,
      typeId: this.state.typeId,
      description: this.state.description
    };
    this.props.onEditLinkedItemClick(requestObj)
    this.hideModal();
  };
  componentDidMount() {
    const {linkDate, typeId, description, id} = this.props;
    const linkTypeOptions = this.props.itemLinkedTypeList.map(item => {
      return {value: item.id, label: item.name};
    });
    this.setState({linkTypeOptions, linkDate, typeId, description, id});
  }

  render(){
    const selectedType = this.state.linkTypeOptions.find(item => item.value ===  this.state.typeId);
    return (
      this.props.modalIsShown ? (
        <NModal
          addClasses={'NModal_ov'}
          modalTitle={text[this.props.itemType].editLinkedItem}
          onModalCancelBtnClick={this.hideModal}
          onModalActionBtnClick={this.handleFormSubmit}
          modalCancelBtnText={text.cancel}
          modalActionBtnText={text.save}
        >
          <NFormGroup label={text.date} addClasses={'w_half-card mt_3du mb_3du'}>
            <FormSingleDatePicker date={this.state.linkDate} openDirection={'down'}
                                  dateId={editLinkedItemModalIds.dateInputLinkDate}
                                  onDatesChange={this.handleDateChange}/>
          </NFormGroup>
          <NFormGroup label={text.relationType} addClasses={'w_half-card mb_3du'}>
            <NSelect value={selectedType} onChange={this.handleLinkTypeChange} options={this.state.linkTypeOptions}
                     id={editLinkedItemModalIds.selectLinkType} inputId={editLinkedItemModalIds.selectInputLinkType}
                     isSearchable={true} placeholder={text.linkTypePlaceholder}/>
          </NFormGroup>
          <NFormGroup label={text.description} addClasses={'w_half-card mb_6du'}>
            <NInput value={this.state.description} id={editLinkedItemModalIds.inputDescription}
                    onChange={this.handleDescriptionChange} />
          </NFormGroup>
        </NModal>
      ) : null
    );
  }
}

export default EditLinkedItemModal;