//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import type {
  errorObject,
  history,
  isReadyToRender,
  locationType,
  match
} from "../../../../../../services/flowTypes/appFlowTypes";
import api from "../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import type {
  agentIdIsNotCompliedWIthPaynetParams,
  configFinTermParams,
  currencyOptions,
  groupFinTermName, isNotCompliedWithPaynetParams, payoutPeriodOptions, sourceOptions
} from "../../../../../../services/flowTypes/dataFlowTypes";
import {
  onInitGroupFinTermsEdit,
  onSetDataToValidationState,
  onSetGroupFinTermsEdit
} from "../../../../../../services/flowTypes/propFnFlowTypes";
import groupFinTermsEditActions
  from "../../../../../../store/organization/item/groupFinTermsEdit/groupFinTermsEditActions";
import validationActions from "../../../../../../store/validation/validationActions";
import NHeading1 from "../../../../../../components/simple/TextComponents/NHeading1/NHeading1";
import text from "../../../../../../services/localization/text";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import GroupFinTermsFormGroup from "./groupFinTermsEdit/GroupFinTermsFormGroup";
import type {selectOptions} from "../../../../../../services/flowTypes/componentFlowTypes";
import getCurrencyLabel from "../../../../../../utils/getCurrencyLabel";
import SetFinTermsToEdit from "./groupFinTermsEdit/SetFinTermsToEdit/SetFinTermsToEdit";
import SetSourceParamsToEdit from "./groupFinTermsEdit/SetSourceParamsToEdit/SetSourceParamsToEdit";
import {initialState as groupFinTermInitState } from "../../../../../../store/organization/item/groupFinTermsEdit/groupFinTermsEdit";
import {groupFinTermEditPageIds} from "../../../../../../tests/testIds";
import SectionDivider from "../../../../../../components/simple/Containers/SectionDivider/SectionDivider";
import SectionHeader from "../../../../../../components/composed/SectionHeader/SectionHeader";
import GetDataOnMountAndUpdate
  from "../../../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import SelectGroupFinTermsType, {defineTooltipForSelectOptions} from "./groupFinTermsEdit/SelectGroupFinTermsType";
import CleanValidationStateOnUnmount
  from "../../../../../../components/service/CleanValidationStateOnUnmount/CleanValidationStateOnUnmount";
import QuestionTooltip from "../../../../../../components/simple/Containers/QuestionTooltip/QuestionTooltip";
import {prepareGroupFinTermToStore} from "../FinTermView/FinTermView";
import groupFinTermsViewActions
  from "../../../../../../store/organization/item/groupFinTermsView/groupFinTermsViewActions";
import type {onSetGroupFinTermsView} from "../../../../../../services/flowTypes/propFnFlowTypes";
import {prepareGroupFinTermsToStore} from "../GroupFinTermsView/GroupFinTermsView";
import clientError404 from "../../../../../../services/apiInstance/clientError404";
import FinTermGates from "./groupFinTermsEdit/FinTermGates/FinTermGates";
import AlertPaynetSync from "../../../../../../components/simple/Containers/AlertCard/AlertPaynetSync";
import CustomFunction from "./groupFinTermsEdit/CustomFunction";
import PayerPayeeMerchant from "./groupFinTermsEdit/PayerPayeeMerchant";
import AdaptiveFormContainer
  from "../../../../../../components/simple/Containers/AdaptiveFormContainer/AdaptiveFormContainer";

type Props = {
  match: match,
  onSetGroupFinTermsEdit: onSetGroupFinTermsEdit,
  onInitGroupFinTermsEdit: onInitGroupFinTermsEdit,
  onSetDataToValidationState: onSetDataToValidationState,
  history: history,
  name: groupFinTermName,
  // agentsList: agentsList,
  currencyOptions: currencyOptions,
  payoutPeriodOptions: payoutPeriodOptions,
  sourceOptions: sourceOptions,
  configFinTermParams: configFinTermParams,
  location: locationType,
  onSetGroupFinTermsView: onSetGroupFinTermsView,
  isNotCompliedWithPaynetParams?: isNotCompliedWithPaynetParams,
  agentIdIsNotCompliedWIthPaynetParams?: agentIdIsNotCompliedWIthPaynetParams
  // onInitValidationState: onInitValidationState
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  agentsOptions: selectOptions,
  currencySwitcherOptions: selectOptions,
  payoutPeriodSwitcherOptions: selectOptions,
  sourceSelectOptions: selectOptions,
  groupFinTermNameArr: Array<groupFinTermName>
};

class GroupFinTermsEdit extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null,
      // agentsOptions: [],
      currencySwitcherOptions: [],
      payoutPeriodSwitcherOptions: [],
      sourceSelectOptions: [],
      groupFinTermNameArr: []
    };
  }
  setGroupFinTermsEdit = (groupFinTerm) => {
    this.props.onSetGroupFinTermsEdit(groupFinTerm);
    this.props.onSetDataToValidationState(groupFinTerm);
    this.setState({isReadyToRender: true});
  };
  makeGroupFinTermNameArr = (groupFinTerms) => {
    return groupFinTerms.map(item => {return item.name;});
  };
  getDataForView = () => {
    this.setState({isReadyToRender: false});
    // this.props.onInitValidationState();
    const contractId = +this.props.match.params.id;
    const currencySwitcherOptions = this.props.currencyOptions.map(item => {
      return {value: item, label: getCurrencyLabel(item)};
    });
    const payoutPeriodSwitcherOptions = this.props.payoutPeriodOptions.map(item => {
      return {value: item, label: text[item]};
    });
    let groupFinTermNameArr = [];
    // const agentsList = this.props.agentsList.filter(item => item.status === 'ACTIVE');
    // const agentsOptions = agentsList.map(item => {
    //   return {value: item.id, label: item.name}
    // });

    const sourceSelectOptions = this.props.sourceOptions.map((item, index) => {
      return {value: item, label:
          (<div className={'flex ai_c'}>
            {text[item]}
            {defineTooltipForSelectOptions(index, 'groupFinTermSourceTooltip',item, this.props.sourceOptions.length)}
          </div>)};
    });

    const initGroupFinTermObj = {
      contractId,
      // currency: currencySwitcherOptions.length ? currencySwitcherOptions[0].value : '',
      payoutPeriod: payoutPeriodSwitcherOptions.length ? payoutPeriodSwitcherOptions[0].value : ''
    };
    this.props.onInitGroupFinTermsEdit(initGroupFinTermObj);
    const groupId = +this.props.match.params.groupId;

    api.getGroupFinTerms({contractId})
      .then(response => {
        const groupFinTerms = prepareGroupFinTermsToStore(response.data, this.props.configFinTermParams);
        this.props.onSetGroupFinTermsView(groupFinTerms);
        groupFinTermNameArr = this.makeGroupFinTermNameArr(groupFinTerms);

        if (groupId) {
          const groupFinTerm = response.data.find(item => item.groupId === groupId);
          if (groupFinTerm) {
            const preparedGroupFinTerm = prepareGroupFinTermToStore(groupFinTerm, this.props.configFinTermParams);
            this.setGroupFinTermsEdit(preparedGroupFinTerm);
          } else {
            this.setState({
              isReadyToRender: true,
              errorObject: apiErrorHandler(clientError404)
            });
          }
        } else {
          const initState = {
            ...groupFinTermInitState,
            ...initGroupFinTermObj
          };
          this.props.onSetDataToValidationState(initState);
        }

        this.setState({
          isReadyToRender: true,
          // agentsOptions,
          currencySwitcherOptions,
          payoutPeriodSwitcherOptions,
          sourceSelectOptions,
          groupFinTermNameArr
        });
      })
      .catch((error) => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    const groupFinTermId = this.props.match.params.groupId ? +this.props.match.params.groupId : null;
    const contractId = this.props.match.params.id ? +this.props.match.params.id : null;
    return (
      <GetDataOnMountAndUpdate itemId={groupFinTermId} getDataOnMountAndUpdate={this.getDataForView}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>
          <CleanValidationStateOnUnmount/>
          <div className="GroupFinTermsEdit w_content">
            <NCard addClasses={'mb_3du'}>
              {this.props.isNotCompliedWithPaynetParams ? (
                <AlertPaynetSync addClasses={"mb_3du"}
                                 agentIdIsNotCompliedWIthPaynetParams={this.props.agentIdIsNotCompliedWIthPaynetParams}
                                 organizationId={this.props.match.params.organizationId}/>
              ) : null}
              <GroupFinTermsFormGroup formFieldType={'input'} propName={'name'} label={text.name} addClasses={'mb_3du'}
                                      argsForValidationFn={[this.state.groupFinTermNameArr]}
                                      idForTest={groupFinTermEditPageIds.inputName}/>
              {groupFinTermId ? (
                <input type="hidden" id={groupFinTermEditPageIds.inputHiddenGroupFinTermId} value={groupFinTermId}/>
              ) : null}

              <PayerPayeeMerchant/>

              <AdaptiveFormContainer fieldsArr={[
                <GroupFinTermsFormGroup formFieldType={'select'} propName={'currency'} label={text.currency}
                                        placeholder={text.select.placeholder.currency} key={"currency"}
                                        options={this.state.currencySwitcherOptions} isSearchable={true}
                                        selectInputId={groupFinTermEditPageIds.selectInputCurrency}
                                        labelTooltip={<QuestionTooltip isHighZIndex={true}>{text.currencyTooltip}</QuestionTooltip>}
                                        idForTest={groupFinTermEditPageIds.selectCurrency}/>,
                <GroupFinTermsFormGroup formFieldType={'switcher'} propName={'payoutPeriod'} label={text.payoutPeriod}
                                        options={this.state.payoutPeriodSwitcherOptions} key={"payoutPeriod"}
                                        labelTooltip={<QuestionTooltip>{text.payoutPeriodTooltip}</QuestionTooltip>}
                                        idForTest={groupFinTermEditPageIds.switcherPayoutPeriod}/>
              ]} containerMode={"twoHalfCardWidthFields1100"} addClasses={"mb_3du"}/>

              <SectionDivider addClasses={'mt_5du mb_5du'}/>
              <SectionHeader sectionHeaderTitle={
                <div className="flex ai_c">{text.header1c}
                  <QuestionTooltip isHighZIndex={true}>{text.param1CTooltip}</QuestionTooltip>
                </div>
                } addClasses={"mb_3du"}/>
              <AdaptiveFormContainer fieldsArr={[
                <GroupFinTermsFormGroup formFieldType={'input'} propName={'nomenNumber1c'} label={text.nomenNumber1c}
                                        idForTest={groupFinTermEditPageIds.nomenNumber1c} key={"nomenNumber1c"}/>,
                <GroupFinTermsFormGroup formFieldType={'input'} propName={'projectName'} label={text.projectName}
                                        idForTest={groupFinTermEditPageIds.projectName} key={"projectName"}/>
              ]} containerMode={"twoHalfCardWidthFields1100"} addClasses={"mb_1du"}/>
              <div>
                <GroupFinTermsFormGroup formFieldType={'checkbox-boolean'} propName={'ignoreInExport'} label={
                  <div className="flex">{text.ignoreInExport}
                    <QuestionTooltip addClasses={'ml_1du'} isHighZIndex={true}>{text.ignoreInExportTooltip}</QuestionTooltip>
                  </div>
                  }
                                        idForTest={groupFinTermEditPageIds.checkboxIgnoreInExport}/>
              </div>
            </NCard>
            <NCard addClasses={'mb_3du'}>
              <SectionHeader
                sectionHeaderTitle={text.newSourceParamsSectionTitle}
                addClasses={"mb_3du"}
              />
              <GroupFinTermsFormGroup formFieldType={'switcher'}
                                      propName={'source'}
                                      label={text.source}
                                      options={this.state.sourceSelectOptions}
                                      addClasses={'mb_3du'}
                                      disabled={groupFinTermId}
                                      idForTest={groupFinTermEditPageIds.selectSource}
              />
              <SetSourceParamsToEdit />
            </NCard>
            {/*<GroupFinTermsFormGroup formFieldType={'select-full-card'} propName={'source'}*/}
            {/*                        isOptionOverflowVisible={true}*/}
            {/*                        options={this.state.sourceSelectOptions} addClasses={'mb_3du'}*/}
            {/*                        placeholder={text.select.placeholder.source}*/}
            {/*                        disabled={groupFinTermId} isSearchable={true}*/}
            {/*                        selectInputId={groupFinTermEditPageIds.selectInputSource}*/}
            {/*                        idForTest={groupFinTermEditPageIds.selectSource}/>*/}

            <FinTermGates
              groupFinTermId={groupFinTermId}
              organizationId={+this.props.match.params.organizationId}
            />
            <CustomFunction contractId={contractId} />
            <div className="mt_3du mb_3du">
              <NHeading1>{text.terms}</NHeading1>
            </div>
            <SelectGroupFinTermsType groupFinTermId={groupFinTermId}/>
            <SetFinTermsToEdit/>
          </div>
        </RenderOnReady>
      </GetDataOnMountAndUpdate>
    );
  }
}

const mapStateToProps = state => {
  const sourceOptions = state.organization.organizationData.configFinTerms.sourceOptions ?
    state.organization.organizationData.configFinTerms.sourceOptions : [];
  return {
    name: state.organization.item.groupFinTermsEdit.name,
    isNotCompliedWithPaynetParams: state.organization.item.groupFinTermsEdit.isNotCompliedWithPaynetParams,
    agentIdIsNotCompliedWIthPaynetParams: state.organization.item.groupFinTermsEdit.agentIdIsNotCompliedWIthPaynetParams,
    // agentsList: state.organization.organizationData.agentsList,
    currencyOptions: state.organization.organizationData.configFinTerms.currencyOptions,
    payoutPeriodOptions: state.organization.organizationData.configFinTerms.payoutPeriodOptions,
    sourceOptions,
    configFinTermParams: state.organization.organizationData.configFinTerms.configFinTermParams
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitGroupFinTermsEdit: (initGroupFinTermObj) => dispatch({
      type: groupFinTermsEditActions.GROUP_FIN_TERMS_EDIT_INIT,
      value: initGroupFinTermObj
    }),
    onSetGroupFinTermsEdit: (groupFinTerm) => dispatch({
      type: groupFinTermsEditActions.GROUP_FIN_TERMS_EDIT_SET,
      value: groupFinTerm
    }),
    onSetDataToValidationState: (data) => dispatch({
      type: validationActions.VALIDATION_STATE_SET,
      value: data}),
    onSetGroupFinTermsView: (groupFinTerms) => dispatch({
      type: groupFinTermsViewActions.GROUP_FIN_TERMS_VIEW_SET,
      value: groupFinTerms
    })
    // onInitValidationState: () => dispatch({type: validationActions.VALIDATION_STATE_INIT})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupFinTermsEdit);    