//@flow
import * as React from 'react';
import {useState} from 'react';
import {connect} from "react-redux";
import getObjProp from "../../../../../../../utils/getObjProp";
import type {
  contract,
  managerIds,
  merchantParams,
  processorIds
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import {
  contractSourceValue,
  groupFinTermSource,
  paynetSource,
  sourceOptions
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../../services/localization/text";
import SectionHeader from "../../../../../../../components/composed/SectionHeader/SectionHeader";
import {agentEditPageIds} from "../../../../../../../tests/testIds";
import type {
  onGetContractMainData,
  onGetContractMainDataProp
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import NCard from "../../../../../../../components/simple/Containers/NCard/NCard";
import PaynetParamsItem2 from "./PaynetParamsItem2";
import contractMainDataActions
  from "../../../../../../../store/organization/item/contractMainData/contractMainDataActions";
import {safeArray} from "../../../../../../../utils/safe";
import BtnDel from "../../../../../../../components/simple/Buttons/BtnDel/BtnDel";
import {getUniqueStrings} from "../../../../../../../utils/array";
import ConfirmActionModal from "../../../../../../../components/simple/Modals/ConfirmActionModal/ConfirmActionModal";
import Text2 from "../../../../../../../components/simple/TextComponents/Text2/Text2";

type Props = {
  merchantParams: merchantParams,
  managerIds: managerIds,
  processorIds: processorIds,
  isViewMode?: boolean,
  sources: paynetSource[],
  contractMainData: contract,
  onGetContractMainDataProp: onGetContractMainDataProp,
  onGetContractMainData: onGetContractMainData
};

export const paynetSources = ["PNE", "PNE_EU"];
/* Для удобства отправки на сервер, нужна така мапа, так как контракте даные для источников приходят в таком виде
contractMainData = {
  ...
  "merchantParams": [
      {
          "source": "PNE",
          "merchantId": [
              "33"
          ]
      }
  ],
  "managerIds": [],
  "processorIds": [],
}
*/

const updateContractData = (contract: contract, value: contractSourceValue) => {
  let prevState = safeArray(contract[value.config.parent]);
  return [
    {
      source: value.source,
      [value.config.name]: value.value
    },
    ...prevState.filter(({source}) => source !== value.source)
  ]
}

const removeSourceFromData: contract = (contract: contract, source: string): contract => {
  return {
    ...contract,
    merchantParams: contract.merchantParams.filter(s => s.source !== source),
    managerIds: contract.managerIds.filter(s => s.source !== source),
    processorIds: contract.processorIds.filter(s => s.source !== source),
  }
}

const paynetSourcesConfig = {
  merchantId: {
    name: 'merchantId',
    parent: 'merchantParams'
  },
  managerId: {
    name: 'managerId',
    parent: 'managerIds'
  },
  processorId: {
    name: 'processorId',
    parent: 'processorIds'
  }
}

const existSources = (sourceOptions: string[], sources: { source: groupFinTermSource }[]) => {
  return getUniqueStrings(sources
    .filter(s => sourceOptions.includes(s.source))
    .map(s => s.source))

}

const normalizeModel = (contractSources: sourceOptions,
                        merchantParams: merchantParams,
                        managerIds: managerIds,
                        processorIds: processorIds
): paynetSource[] => {
  const sources = existSources(contractSources, [...merchantParams, ...managerIds, ...processorIds])
  return sources
    .filter(s => paynetSources.indexOf(s) > -1)
    .sort()
    .map(source => {
      let merchant = safeArray(merchantParams).find(s => s.source && s.source.toUpperCase() === source);
      let manager = safeArray(managerIds).find(s => s.source && s.source.toUpperCase() === source);
      let processor = safeArray(processorIds).find(s => s.source && s.source.toUpperCase() === source);

      return {
        source,
        merchantId: merchant ? merchant.merchantId : [],
        managerId: manager ? manager.managerId : [],
        processorId: processor ? processor.processorId : [],
      };
    });
}


const PaynetParams = (props: Props) => {
  const {isViewMode, sources, onGetContractMainDataProp, onGetContractMainData, contractMainData} = props;

  const onContractMainDataPropChange = (value: contractSourceValue) => {
    const prop = value.config.parent;
    const data = updateContractData(contractMainData, value);

    onGetContractMainDataProp(prop, data)
  }

  const onRemoveSource = (source: string) => {
    const newState = removeSourceFromData(contractMainData, source);
    onGetContractMainData(newState)
  }
  const [showConfirmOnDelete, setShowConfirmOnDelete] = useState('')
  return <>
    {sources
      .map((s, i) =>
        <NCard addClasses="NCard_items" key={s.source}>
          <SectionHeader
            sectionHeaderOverTitle={text.source}
            sectionHeaderTitle={text[s.source]}
            addClasses={"mb_3du SectionHeader_border-bottom"}
            sectionHeaderBtn={
              <ConfirmActionModal
                contentAddClasses={"modal_width_sm"}
                modalTitle={text.deleteDocumentModalTitle}
                initActionBtn={
                  <BtnDel onClick={() => {
                    setShowConfirmOnDelete(s.source)
                  }}/>
                }
                onCancel={() => {
                  setShowConfirmOnDelete('')
                }}
                onModalActionBtnClick={() => {
                  onRemoveSource(s.source)
                  setShowConfirmOnDelete('')
                }}
                disabledBtn={showConfirmOnDelete === s.source}
              >
                <div className="mt_3du mb_3du">
                  <Text2>
                    {text.sourceParams.confirmDelete} <b>{s.source}</b> ?
                  </Text2>
                  <Text2>
                    {text.sourceParams.confirmDeleteDescription}
                  </Text2>
                </div>
              </ConfirmActionModal>
            }
          />
          <PaynetParamsItem2 isViewMode={isViewMode}
                             paynetParamConfig={paynetSourcesConfig.merchantId}
                             paynetSource={s}
                             selectPlaceholder={text.merchantIdPlaceholder}
                             selectPaynetParamTestId={agentEditPageIds.inputMerchantIds(i)}
                             onGetPaynetParam={onContractMainDataPropChange}
          />
          <PaynetParamsItem2 isViewMode={isViewMode}
                             paynetParamConfig={paynetSourcesConfig.managerId}
                             paynetSource={s}
                             selectPlaceholder={text.managerIdPlaceholder}
                             selectPaynetParamTestId={agentEditPageIds.inputManagerIds(i)}
                             onGetPaynetParam={onContractMainDataPropChange}
          />
          <PaynetParamsItem2 isViewMode={isViewMode}
                             paynetParamConfig={paynetSourcesConfig.processorId}
                             paynetSource={s}
                             selectPlaceholder={text.processorIdPlaceholder}
                             selectPaynetParamTestId={agentEditPageIds.inputProcessorIds(i)}
                             onGetPaynetParam={onContractMainDataPropChange}
          />
        </NCard>
      )}
  </>;
};


const mapStateToProps = state => {
  const contractSources = state.organization.organizationData.configFinTerms.sourceOptions
    ? state.organization.organizationData.configFinTerms.sourceOptions
    : [];

  const contractMainData = state.organization.item.contractMainData;
  const merchantParams = getObjProp(contractMainData, "merchantParams");
  const managerIds = getObjProp(contractMainData, "managerIds");
  const processorIds = getObjProp(contractMainData, "processorIds")

  const sources = normalizeModel(contractSources, merchantParams, managerIds, processorIds)

  return {
    merchantParams,
    managerIds,
    processorIds,
    sources,
    contractMainData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetContractMainDataProp: (prop, data) => {
      dispatch({
        type: contractMainDataActions.CONTRACT_MAIN_DATA_PROP_SET, value: {
          prop: prop,
          data: data
        }
      })
    },
    onGetContractMainData: (contractMainData) => dispatch({
      type: contractMainDataActions.CONTRACT_MAIN_DATA_SET,
      value: contractMainData
    })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaynetParams);
