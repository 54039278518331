import {supOrgsPath, supSettPath, supUsersPath, viewNotificationsSuffix} from "../services/urlStrings/urlStrings";
import text from "../services/localization/text";
import {globalPermissionsStrValues} from "./permissionsStrValues";
import settingsBaseIconSrc from "../assets/settings-menu-icon-v4.svg";
import settingsHoveredIconSrc from "../assets/settings-menu-icon-hover-v4.svg";
import usersBaseIconSrc from "../assets/menu-users-icon.svg";
import usersHoveredIconSrc from "../assets/menu-users-hover-icon.svg";
import organizationsBaseIconSrc from "../assets/menu-all-organizations-icon.svg";
import organizationsHoveredIconSrc from "../assets/menu-all-organizations-hover-icon.svg";

const adminMenuItems = [
  {to: supOrgsPath, text: text.allOrganizationsLabel,
    checkPermission: {
      action: 'useGlobalPermission',
      subject: globalPermissionsStrValues.admin
    },
    baseIconSrc: organizationsBaseIconSrc,
    hoveredIconSrc: organizationsHoveredIconSrc,
    activeIconSrc: organizationsHoveredIconSrc,
    itemType: 'organization'
  },
  {to: supUsersPath, text: text.users,
    checkPermission: {
      action: 'useGlobalPermission',
      subject: globalPermissionsStrValues.user.view
    },
    baseIconSrc: usersBaseIconSrc,
    hoveredIconSrc: usersHoveredIconSrc,
    activeIconSrc: usersHoveredIconSrc,
    itemType: 'user'
  },
  {
    to: supSettPath + viewNotificationsSuffix,
    text: text.settingsTitle,
    isPermitted: true,
    baseIconSrc: settingsBaseIconSrc,
    hoveredIconSrc: settingsHoveredIconSrc,
    activeIconSrc: settingsHoveredIconSrc,
    itemType: 'settings'
  }

];
export default adminMenuItems;