//@flow
import * as React from 'react';
import type {passwordRules} from "../../../services/flowTypes/componentFlowTypes";
import PasswordRuleLabel from "./PasswordRuleLabel/PasswordRuleLabel";
import type {passValue} from "../../../services/flowTypes/appFlowTypes";
import text from "../../../services/localization/text";
import NHeading3 from "../../../components/simple/TextComponents/NHeading3/NHeading3";

type Props = {
  passwordRules: passwordRules,
  passValue: passValue
};

const PasswordHandler = (props: Props) => {
  const labels = props.passwordRules.map((item, i) => {
    const ruleIsRight = item.fn(props.passValue);
    return (<PasswordRuleLabel key={'PasswordRuleLabel'+i}
                               addClasses={'mb_1du'}
                               ruleIsRight={ruleIsRight}>{text.passwordRules[item.name]}</PasswordRuleLabel>);
  });
  return (
    <div className="PasswordHandler">
      <NHeading3 addClasses={'mb_3du'}>{text.passwordParams}</NHeading3>
      {labels}
    </div>
  );
};

export default PasswordHandler;    