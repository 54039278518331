//@flow
import * as React from 'react';
import {connect} from "react-redux";
import searchInOrgContextActions from "../../../../store/organization/searchInOrgContext/searchInOrgContextActions";
import {searchInOrgContextIds} from "../../../../tests/testIds";
import type {onUpdateSheetIsShown} from "../../../../services/flowTypes/propFnFlowTypes";
import BtnDel from "../../../simple/Buttons/BtnDel/BtnDel";
import CloseOnEscKeyDown from "../../../service/CloseOnEscKeyDown/CloseOnEscKeyDown";

type Props = {
  onUpdateSheetIsShown: onUpdateSheetIsShown
};

class ButtonHideSearchInOrgContextSheet extends React.Component <Props> {
  hideSearchSheet = () => {
    this.props.onUpdateSheetIsShown(false);
  };
  render() {
    return (
      <CloseOnEscKeyDown onEscKeyDown={this.hideSearchSheet} >
        <BtnDel id={searchInOrgContextIds.buttonHideSheet} onClick={this.hideSearchSheet} addClasses={'BtnDel_grey-ico'}/>
      </CloseOnEscKeyDown>

    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUpdateSheetIsShown: (sheetIsShown) => dispatch({
      type: searchInOrgContextActions.SHEET_IS_SHOWN_UPDATE,
      value: sheetIsShown})
  };
};

export default connect(null, mapDispatchToProps)(ButtonHideSearchInOrgContextSheet);