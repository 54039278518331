//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './Breadcrumbs.css';
import {Link} from "react-router-dom";
import TextOverflowHandler from "../TextOverflowHandler/TextOverflowHandler";

export type breadcrumbsMaxWidth = number;

type breadcrumbsItem = {
  link: string,
  label: string | React.Node
};

type Props = {
  id?: id,
  addClasses?: addClasses,
  breadcrumbs: Array<breadcrumbsItem>,
  breadcrumbsMaxWidth?: breadcrumbsMaxWidth
};

const Breadcrumbs = (props: Props) => {
  const breadcrumbsMaxWidth = props.breadcrumbsMaxWidth && typeof props.breadcrumbsMaxWidth === 'number' ?
    props.breadcrumbsMaxWidth : 832;
  const breadcrumbsItemMaxWidth = props.breadcrumbs.length ? breadcrumbsMaxWidth / props.breadcrumbs.length : null;
  const style = breadcrumbsItemMaxWidth ? {maxWidth: breadcrumbsItemMaxWidth + 'px'} : {};
  const items = props.breadcrumbs.map((item,i) => {
    const isCurrent = i + 1 === props.breadcrumbs.length;
    return (
      isCurrent ? (
        <div key={'breadcrumb'+i} className="Breadcrumbs__item Breadcrumbs__item_current" style={style}>
          <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>{item.label}</TextOverflowHandler>
        </div>
      ) : (
        <Link key={'breadcrumb'+i} to={item.link} className={'Breadcrumbs__item'}
          style={style}>
            <TextOverflowHandler >{item.label}</TextOverflowHandler>
            <div className="mr_half-du ml_half-du">/</div>
        </Link>
      )
      )
  });
  return (
    <div id={props.id} className={baseClass("Breadcrumbs", props.addClasses)}>{items}</div>
  );
};

export default Breadcrumbs;
