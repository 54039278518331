import {
  orgPath,
  orgSettingsSuffix, viewAddParamsSettingsSuffix, viewNotificationsSuffix, viewOrganizationSettingsSuffix
} from "../services/urlStrings/urlStrings";
import text from "../services/localization/text";
import type {organizationId as organizationIdType} from "../services/flowTypes/dataFlowTypes";
import {permissionsStrValues, rolesStrValues} from "./permissionsStrValues";

const organizationSettingsMenuItems = (organizationId:organizationIdType) => {
  return [
    {
      linkTo: orgPath(organizationId) + orgSettingsSuffix + viewNotificationsSuffix,
      linkName: text.notificationMenuTitle,
      isPermitted: true
    },
    {
      linkTo: orgPath(organizationId) + orgSettingsSuffix + viewOrganizationSettingsSuffix,
      linkName: text.organizationSettingsMenuTitle,
      permittedAction: permissionsStrValues.organization.view,
      onlyForRoles: [rolesStrValues.admin]
    },
    {
      linkTo: orgPath(organizationId) + orgSettingsSuffix + viewAddParamsSettingsSuffix,
      linkName: text.addParams,
      permittedAction: permissionsStrValues.organizationParams.view,
      onlyForRoles: [rolesStrValues.admin]
    }
  ];
};
export default organizationSettingsMenuItems;