//@flow
import * as React from 'react';
import type {handledAgent} from "../../../services/flowTypes/componentFlowTypes";
import type {agent} from "../../../services/flowTypes/dataFlowTypes";
import TextOverflowHandler from "../../simple/Containers/TextOverflowHandler/TextOverflowHandler";

type Props = {
  handledAgent: handledAgent,
  agent: agent
};

const AddAgentRow = (props: Props) => {
  return (
    <div className="flex">
      <div className="AddAgentRow__name">
        <TextOverflowHandler>{props.handledAgent.idName}</TextOverflowHandler>
      </div>
      <div className="AddAgentRow__createDate">{props.handledAgent.createDate}</div>
      <div className="AddAgentRow__statusLabel">
        <div className={`StatusLabel StatusLabel_${props.agent.status}`}>
          {props.handledAgent.itemStatusLabel}
        </div>
      </div>
      <div className="AddAgentRow__statusDate">{props.handledAgent.statusDate}</div>
    </div>
  );
};

export default AddAgentRow;