//@flow
import * as React from 'react';
import './ImportComponentWithClickToCopy.css';
import {baseClass} from "../utilities/baseClass";
import Pre from "../Pre/Pre";
import Tooltip from "../Tooltip/Tooltip";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import withClickToCopy from "../../../../components/hoc/withClickToCopy";
import type {contentIsCopied, showContentIsCopied} from "../../../../services/flowTypes/componentFlowTypes";
import type {copyOnClick} from "../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  addClasses?: addClasses,
  id?: id,
  children: string,
	contentIsCopied: contentIsCopied,
	showContentIsCopied: showContentIsCopied,
	copyOnClick: copyOnClick
};

const ImportComponent = (props:Props) => {
	const codeIsCopiedClass = props.contentIsCopied ? " Pre_codeIsCopied" : "";
	const showTooltipClass = props.showContentIsCopied ? " Tooltip_animatedOpacity" : "";


	return (
		<div id={props.id} className={baseClass("ImportComponent  pt_3du", props.addClasses)}>
			<div onClick={props.copyOnClick} className="BtnEditAct mr_2du">Copy</div>
			<Pre addClasses={"Pre_in_ImportComponent" + codeIsCopiedClass}>
				{props.children}
				<Tooltip addClasses={showTooltipClass}>Code is copied</Tooltip>
			</Pre>
		</div>

	);
};
 
const ImportComponentWithClickToCopy = withClickToCopy(ImportComponent);
export default ImportComponentWithClickToCopy;