//@flow
import * as React from 'react';
import "./EmptyListV2.css";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import text from "../../../../services/localization/text";

type Props = {
  addClasses?: addClasses,
  insertedLabelComponent?: React.Node
};

const EmptyListV2 = (props: Props) => {
  return (
    <div className={baseClass("EmptyListV2", props.addClasses)}>
      <div className="EmptyListV2__img"/>
      <div className="EmptyListV2__label">{text.emptyListV2Label}</div>
      <div className="EmptyListV2__insertedLabelContainer">
        {props.insertedLabelComponent}
      </div>
    </div>
  );
};

export default EmptyListV2;    