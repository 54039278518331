const reportManualAdjustmentPropsItem = {
  file: null,
  finTermGroupId: -1,
  adjustedParams: [
    {name: 'rateAmount', value: ''},
    {name: 'count', value: -1},
    {name: 'totalAmount', value: ''}
  ]
};
const reportManualAdjustmentPropsItemVer2 = {
  file: null,
  finTermGroupId: -1,
  adjustedParams: [
    {name: 'rateAmount', value: ''}
  ]
};
const reportManualAdjustmentPropsItemVer3 = {
  file: null,
  finTermGroupId: -1,
  adjustedParams: [
    {name: 'rate', value: ''},
    {name: 'hours', value: -1},
    {name: 'amount', value: ''}
  ]
};
const reportManualAdjustmentProps = {
  BY_VOLUME_SIMPLE: reportManualAdjustmentPropsItem,
  BY_VOLUME_COMPLEX: reportManualAdjustmentPropsItem,
  BYRATE: reportManualAdjustmentPropsItem,
  BY_HOURS: reportManualAdjustmentPropsItemVer3,
  BY_TX_COUNT: reportManualAdjustmentPropsItem,
  BY_TX_COUNT_SIMPLE: reportManualAdjustmentPropsItem,
  BY_TX_COUNT_COMPLEX: reportManualAdjustmentPropsItem,
  FIXED: reportManualAdjustmentPropsItemVer2,
  FIXED_NO_PERIOD: reportManualAdjustmentPropsItemVer2,
  MANUAL: reportManualAdjustmentPropsItemVer2,
  MIN: reportManualAdjustmentPropsItemVer2
};
export default reportManualAdjustmentProps;