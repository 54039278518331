//@flow
import sheetsActions from './sheetsActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {addServiceSheet} from "../../../../services/flowTypes/dataFlowTypes";
import update from "immutability-helper";

type reducerAction = action;
const initialState = [];

const sheetsReducer = (state:Array<addServiceSheet> = initialState, action:reducerAction) => {
  switch (action.type) {
    case sheetsActions.SHEETS_SET:
      return action.value;
    case sheetsActions.SHEET_UPDATE:
      return update(state, {
        [action.value.sheetIndex]: {$set: action.value.propValue}
      });
    case sheetsActions.SHEET_ADD:
      return update(state, {$push: [action.value]});
    case sheetsActions.SHEET_DELETE:
      return update(state, {$splice: [[action.value, 1]]});
    case sheetsActions.SHEETS_PROP_UPDATE:
      return update(state, {
        [action.value.sheetIndex]: {[action.value.propName]: {$set: action.value.propValue}}
      });
    case sheetsActions.SHEETS_CALCULATED_ITEM_PROP_UPDATE:
      const {sheetIndex, calculatedItemIndex, propName, propValue} = action.value;
      return update(state, {
        [sheetIndex]: {calculatedItems: {[calculatedItemIndex]: {[propName]: {$set: propValue}}}}
      });
    case sheetsActions.SHEETS_CALCULATED_ITEM_DELETE:
      return update(state, {[action.value.sheetIndex]: {calculatedItems: {$splice: [[action.value.calculatedItemIndex, 1]]}}});
    case sheetsActions.SHEETS_CALCULATED_ITEM_ADD:
      return update(state, {[action.value.sheetIndex]: {calculatedItems: {$push: [action.value.calculatedItem]}}});
    case sheetsActions.SHEETS_CALCULATED_ITEMS_UPDATE:
      return update(state, {
        [action.value.sheetIndex]: {calculatedItems: {$set: action.value.propValue}}
      });
    default:
      return state;
  }
};

export default sheetsReducer;