//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {editMode} from "../../../services/flowTypes/componentFlowTypes";
import type {notificationCategory, turnedOn} from "../../../services/flowTypes/dataFlowTypes";
import text from "../../../services/localization/text";
import notificationSettingsActions from "../../../store/notificationSettings/notificationSettingsActions";
import type {onUpdateNotificationSettingsProp} from "../../../services/flowTypes/propFnFlowTypes";
import Checkbox from "../../simple/FormComponents/Checkbox/Checkbox";
import notificationOnIconSrc from '../../../assets/notification-on-icon.svg';
import notificationOffIconSrc from '../../../assets/notification-off-icon.svg';

type Props = {
  editMode: editMode,
  onUpdateNotificationSettingsProp: onUpdateNotificationSettingsProp,
  orgNotificationIndex: number,
  notificationIndex: number,
  turnedOn: turnedOn,
  notificationCategory: notificationCategory
};

class NotificationSettingsFormInput extends React.Component <Props> {
  handleCheckboxClick = () => {
    const actionValue = {
      orgNotificationIndex: this.props.orgNotificationIndex,
      notificationIndex: this.props.notificationIndex,
      notification: {
        notificationCategory: this.props.notificationCategory,
        turnedOn: !this.props.turnedOn
      }
    };
    this.props.onUpdateNotificationSettingsProp(actionValue);
  };
  render() {
    const inputValue = this.props.turnedOn ? text.notificationSettingsInputValue.yes
      : text.notificationSettingsInputValue.no;
    const imgSrc = this.props.turnedOn ? notificationOnIconSrc : notificationOffIconSrc;
    return (
      this.props.editMode ? (
        <div className="NotificationSettingsForm__input-value">
          <Checkbox addClasses={'Checkbox_16'} onClick={this.handleCheckboxClick}
                    checked={this.props.turnedOn}/>
        </div>
      ) : (
        <div className={"NotificationSettingsForm__input-value NotificationSettingsForm__input-value_view-mode"}>
          <img src={imgSrc} alt={inputValue}/>
        </div>
      )
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    turnedOn: state.notificationSettings[ownProps.orgNotificationIndex].notifications[ownProps.notificationIndex].turnedOn,
    notificationCategory:
    state.notificationSettings[ownProps.orgNotificationIndex].notifications[ownProps.notificationIndex].notificationCategory
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateNotificationSettingsProp: (value) => dispatch({type: notificationSettingsActions.NOTIFICATION_SETTINGS_PROP_UPDATE, value})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsFormInput);