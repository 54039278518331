//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {setFiltersToUrl} from "../../../services/flowTypes/propFnFlowTypes";
import type {viewHistory} from "../../../services/flowTypes/dataFlowTypes";
import UnviewedMark from "../../simple/Icons/UnviewedMark/UnviewedMark";
import FilterFlags from "../../simple/FormComponents/FilterFlags/FilterFlags";
import SetAllViewedBtn from "./SetAllViewedBtn";
import type {filtersFromParams} from "../../../services/flowTypes/appFlowTypes";

type Props = {
  viewHistory: viewHistory,
  filtersFromParams: filtersFromParams,
  setFiltersToUrl: setFiltersToUrl
};

class HistoryFilterFlags extends React.Component <Props> {
  handleOnGetHistoryFiltersPropCall = (filtersProps) => {
    const historyFiltersProps = {...filtersProps};
    if (!historyFiltersProps.hasOwnProperty('offset')) {
      historyFiltersProps.offset = 0;
    }
    const filtersFromParams = {...this.props.filtersFromParams, ...historyFiltersProps};
    if (filtersFromParams.hasOwnProperty('logId')) {
      delete filtersFromParams.logId;
    }
    this.props.setFiltersToUrl(filtersFromParams);
  };
  componentDidMount() {
    //to init props for first history request
    const historyFiltersProps = {
      onlyFinTerms: false,
      onlyActTemplates: false,
      count: 20,
      offset: 0
    };
    const filtersFromParams = {...this.props.filtersFromParams, ...historyFiltersProps};
    this.props.setFiltersToUrl(filtersFromParams);
  }
  render() {
    const filterFlags =  ['onlyFinTerms', 'onlyActTemplates'];
    const flagsUnviewedMarks = [
      {flag: 'ALL', component: (<UnviewedMark isUnviewed={this.props.viewHistory.allNewIsUnviewed}/>)},
      {flag: 'onlyFinTerms', component: (<UnviewedMark isUnviewed={this.props.viewHistory.finTermsNewIsUnviewed}/>)},
      {flag: 'onlyActTemplates', component: (<UnviewedMark isUnviewed={this.props.viewHistory.actTemplatesNewIsUnviewed}/>)}
    ];
    return (
      <div className="FilterFlags__container">
        <FilterFlags flagsList={filterFlags} onGetFiltersProp={this.handleOnGetHistoryFiltersPropCall}
                     filtersState={this.props.filtersFromParams}
                     flagsUnviewedMarks={flagsUnviewedMarks}/>
        <SetAllViewedBtn filtersFromParams={this.props.filtersFromParams} addClasses={'ml_2du'}/>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    viewHistory: state.organization.historyData.viewHistory
  };
};
export default connect(mapStateToProps, null)(HistoryFilterFlags);