import {selectOptions} from "../../../../services/flowTypes/componentFlowTypes";
import CloseOnClickOutside from "../../../service/CloseOnClickOutside/CloseOnClickOutside";
import {onDropDownSelectChange} from "../../../../services/flowTypes/propFnFlowTypes";

const DropDownItems = (props: {
  selectOptions: selectOptions,
  onDropDownSelectChange: onDropDownSelectChange,
  isFocused: boolean,
  removeFocus: ()=>void
}) => {

  return (<CloseOnClickOutside
    onClickOutside={props.removeFocus}
    isActive={props.isFocused}
  >
    <div
      className={props.isFocused ? "DropDownSelect DropDownSelect_focused" : "DropDownSelect"}
      onClick={props.removeFocus}
    >
      {props.selectOptions.map((option, index) => {
        const optionClassName = "DropDownSelect__option";
        const onClick = () => {
          props.onDropDownSelectChange(option)
        }
        return (<div
          className={optionClassName}
          key={option.value}
          onClick={onClick}
          id={option.value}
        >
          {option.label}
        </div>);
      })}
    </div>
  </CloseOnClickOutside>);
}

export default DropDownItems
