//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './ContractCard.css';
import type {
  contract,
  contractRelated
} from "../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../services/localization/text";
import type {handledContract, isWideMode, linkId} from "../../../../services/flowTypes/componentFlowTypes";
import ListItemCard from "../../../../components/composed/ListItemCard/ListItemCard";

type Props = {
  id?: id,
  addClasses?: addClasses,
  handledContract: handledContract,
  contract: contract,
  contractRelated: contractRelated,
  linkId?: linkId,
  isWideMode?: isWideMode
};

class ContractCard extends React.Component <Props,void> {
  render(){
    const cardTiles = [
      {tileIconType: 'finterms', numberLabel: this.props.contractRelated.finTerms.length},
      {tileIconType: 'documents', numberLabel: this.props.contractRelated.documents.length},
      {tileIconType: 'templates', numberLabel: this.props.contractRelated.actTemplates.length}
    ];
    const className="ContractCard" +  (this.props.contract.isCheckedAndVerified ? "" : " NCard_error");
    const checkAndVerifiedIcon = this.props.contract.hasOwnProperty('isCheckedAndVerified')
        ? (this.props.contract.isCheckedAndVerified ? (
            <div className={"AddAgreementCard__isVerifiedIco mr_1du"}  title={this.props.contract.isNotVerifiedWithFinTermsComment}/>
        ) : (
            <div className={"AddAgreementCard__isNotVerifiedIco mr_1du"}  title={this.props.contract.isNotVerifiedWithFinTermsComment}/>
        ))
        : null
    return (<ListItemCard
        itemId={this.props.contract.id}
        itemName={this.props.contract.name}
        id={this.props.id}
        addClasses={baseClass(className, this.props.addClasses)}
        linkId={this.props.linkId}
        linkTo={this.props.handledContract.linkToView}
        // createDate={createDate}
        underHeading={this.props.contract.contractNo}
        validityTerm={this.props.handledContract.validityTerm}
        itemType={this.props.handledContract.contractTypeName}
        itemStatus={this.props.contract.status}
        itemStatusLabel={this.props.handledContract.itemStatusLabel}
        tags={this.props.handledContract.tags}
        relatedItemsTitle={text.agents}
        relatedItems={this.props.handledContract.agents}
        cardTiles={cardTiles}
        isWideMode={this.props.isWideMode}
        preNameComponent={checkAndVerifiedIcon}
    />);
  }
}
export default ContractCard;