//@flow
import reportsActions from './reportsActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {reports} from "../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
export const initialState = {
  total: 0,
  items: null
};

const reportsReducer = (state:reports = initialState, action:reducerAction) => {
  switch (action.type) {
    case reportsActions.SET_REPORTS_INIT_STATE:
      return initialState;
    case reportsActions.GET_REPORTS:
      return action.value;
    default:
      return state;
  }
};

export default reportsReducer;