//@flow
import * as React from 'react';
import type {propValue} from "../../../../services/flowTypes/componentFlowTypes";
import SimplifiedAlertWarning from "./SimplifiedAlertWarning";

type Props = {
  propName: string,
  propValue: propValue,
  component: React.Node
};

const OutputOrWarn = (props: Props) => {
  return props.propValue  === "Is not found!" ?
    (<SimplifiedAlertWarning>{`${props.propName} is not found!`}</SimplifiedAlertWarning>) : props.component;
};

export default OutputOrWarn;    