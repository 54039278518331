//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  contractStatusList
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../../services/localization/text";
import type {selectOptions} from "../../../../../../../services/flowTypes/componentFlowTypes";
import SelectStatusFilter from "./SelectStatusFilter";

type Props = {
  contractStatusList: contractStatusList
};
type State = {
  statusSelectOptions: selectOptions
};

class AddAgreementsFilters extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      statusSelectOptions: [],
    };
  }
  componentDidMount() {
    const optionsByCategory = this.props.contractStatusList.map(item => {
      return {
        value: item,
        label: text.addAgreement.status[item],
        statusClassName: `StatusLabel StatusLabel_${item}`
      };
    });
    const statusSelectOptions = [
      {value: '', label: text.all, statusClassName: `StatusLabel`},
      ...optionsByCategory
    ];
    this.setState({statusSelectOptions});
  }
  render() {
    return (
      <SelectStatusFilter statusSelectOptions={this.state.statusSelectOptions}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    contractStatusList: state.organization.organizationData.statuses.contractStatusList
  };
};


export default connect(mapStateToProps, null)(AddAgreementsFilters);