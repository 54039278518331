//@flow
import * as React from 'react';
import SimplifiedAlertWarning from "./SimplifiedAlertWarning";
import type {agentIdIsNotCompliedWIthPaynetParams, organizationId} from "../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../services/localization/text";
import {Link} from "react-router-dom";
import {agentPathView, mainDataSuffix, orgPath} from "../../../../services/urlStrings/urlStrings";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {lastValidReportDate} from "../../../../services/flowTypes/dataFlowTypes";
import formatDate from "../../../../utils/formatDate";

type Props = {
  agentIdIsNotCompliedWIthPaynetParams?: agentIdIsNotCompliedWIthPaynetParams,
  isActTemplateMode?: boolean,
  organizationId?: organizationId,
  addClasses?: addClasses,
  lastValidReportDate?: lastValidReportDate
};

const AlertPaynetSync = (props: Props) => {
  const linkTo = orgPath(props.organizationId) + agentPathView(props.agentIdIsNotCompliedWIthPaynetParams) + mainDataSuffix;
  return (
    <SimplifiedAlertWarning alertTypeClassNameSuffix={"ERROR"} addClasses={props.addClasses}>
      <div>{text.alertPaynetSyncLabel}</div>
      {props.isActTemplateMode ? (
        props.lastValidReportDate ? (
          <div className={"mt_half-du"}>{text.alertPaynetSyncReportGenerationIsAllowed(formatDate(props.lastValidReportDate))}</div>
        ) : (
          <div className={"mt_half-du"}>{text.alertPaynetSyncReportGenerationIsProhibited}</div>
          )
      ) : null}
      {props.agentIdIsNotCompliedWIthPaynetParams ? (
        <div className="mt_half-du">
          <Link to={linkTo} className={"td_u_onHover"}>{text.linkToNotCompliedAgent}</Link>
        </div>
      ) : null}
    </SimplifiedAlertWarning>
  );
};

export default AlertPaynetSync;    