//@flow
import * as React from 'react';
import {connect} from "react-redux";
import NLabel from "../../../../../../../../components/simple/FormComponents/NLabel/NLabel";
import text from "../../../../../../../../services/localization/text";
import type {contractGateListItem, gateId, isIgnored} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import NHeading3 from "../../../../../../../../components/simple/TextComponents/NHeading3/NHeading3";
import Terminals from "./Terminals";
import "./FinTermGate.css";
import TerminalsLengthValidator from "./TerminalsLengthValidator";

type Props = {
  finTermGateIndex: number,
  gateId: gateId,
  contractGateListItem: contractGateListItem,
  isIgnored: isIgnored
};

class FinTermGate extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const opacity = this.props.isIgnored ? "0.3" : "1";
    return (
      <>
        <div className="FinTermGate__bottomBorder"/>
        <div className={"w_100 flex_jc-sb"}>
          <div style={{opacity}}>
            <NLabel>{text.gateIdLabel}</NLabel>
            <NHeading3 addClasses={"NHeading3_lh50"}>{this.props.gateId}</NHeading3>
          </div>
          <TerminalsLengthValidator finTermGateIndex={this.props.finTermGateIndex}>
            <Terminals finTermGateIndex={this.props.finTermGateIndex} terminalList={this.props.contractGateListItem.terminals}/>
          </TerminalsLengthValidator>
        </div>
      </>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    gateId: state.organization.item.groupFinTermsEdit.gates[ownProps.finTermGateIndex].gateId,
    isIgnored: state.organization.item.groupFinTermsEdit.gates[ownProps.finTermGateIndex].isIgnored
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     onTest: (value) => dispatch({type:, value:})
//   };
// };

export default connect(mapStateToProps, null)(FinTermGate);