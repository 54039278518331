//@flow
import * as React from 'react';
import type {spinnerIsShown} from "../../../../services/flowTypes/appFlowTypes";
import type {searchIcoIsClicked} from "../../../../services/flowTypes/componentFlowTypes";
import MyOrganizationsFetcher from "./MyOrganizationsFetcher/MyOrganizationsFetcher";
import ConnectedMyOrganizationsSearchInput
  from "./ConnectedMyOrganizationsSearchInput/ConnectedMyOrganizationsSearchInput";
import Can from "../../../../services/permissions/Can";
import MyOrganizationsFilters from "./MyOrganizationsFilters";
import {globalPermissionsStrValues} from "../../../../configData/permissionsStrValues";

type Props = {};
type State = {
  spinnerIsShown: spinnerIsShown,
  searchIcoIsClicked: searchIcoIsClicked
};

class MyOrganizationsSearchBox extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      spinnerIsShown: false,
      searchIcoIsClicked: false
    };
  }
  handleResponse = () => {
    this.setState({
      spinnerIsShown: false,
      searchIcoIsClicked: false
    });
  };
  showSpinner = () => {this.setState({spinnerIsShown: true})};
  handleSearchIcoClick = () => {this.setState({searchIcoIsClicked: true})};
  render() {
    return (
      <>
        <MyOrganizationsFetcher
          showSpinner={this.showSpinner}
          handleResponse={this.handleResponse}
          searchIcoIsClicked={this.state.searchIcoIsClicked}
        />
        <ConnectedMyOrganizationsSearchInput
          handleSearchIcoClick={this.handleSearchIcoClick}
          spinnerIsShown={this.state.spinnerIsShown}
        />
        <div className="mt_2du">
          <Can do={'useGlobalPermission'} on={globalPermissionsStrValues.admin}>
            <MyOrganizationsFilters/>
          </Can>
        </div>
      </>
    );
  }
}

export default MyOrganizationsSearchBox;