//@flow
import * as React from 'react';
import './DragAndDrop.css';
import type {
  dragAndDropInitArrFlowType,
  dragAndDropItems,
  draggedIndex, overDraggedIndex,
  setNewOrder
} from "../../../services/flowTypes/componentFlowTypes";

type Props = {
  dragAndDropInitArr: dragAndDropInitArrFlowType,
  setNewOrder:setNewOrder,
  dragAndDropItems: dragAndDropItems,
};
type State = {
  draggedIndex: draggedIndex,
  overDraggedIndex: overDraggedIndex
};

class DragAndDrop extends React.Component <Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      draggedIndex: -1,
      overDraggedIndex: -1
    };
  }
  onDragStart = (e:SyntheticDragEvent<HTMLElement>, index:number) => {
    e.dataTransfer.setData("text", index);
    this.setState({draggedIndex: index});
  };
  onDragOver = (e:SyntheticDragEvent<HTMLElement>, index:number) => {
    e.preventDefault();
    this.setState({overDraggedIndex: index});
  };
  onDrop = (e:SyntheticDragEvent<HTMLElement>, index:number) => {
    const movedIndex = +e.dataTransfer.getData("text");
    const newArr = this.changePos(this.props.dragAndDropInitArr, index, movedIndex);
    this.props.setNewOrder(newArr);
    this.setState({
      draggedIndex: -1,
      overDraggedIndex: -1
    });
  };
  changePos = (dragAndDropInitArr:dragAndDropInitArrFlowType, dropToIndex:number, dragFromIndex:number) => {
    let tempArr = dragAndDropInitArr.slice();
    const tempArrItem = {tempProp: ''};
    const draggedItem = tempArr.find((item, index) => {
      return index === dragFromIndex;
    });
    tempArr.splice(dragFromIndex, 1, tempArrItem);
    if (draggedItem) {
      if (dragFromIndex > dropToIndex) {
        tempArr.splice(dropToIndex, 0, draggedItem);
      } else {
        tempArr.splice(dropToIndex+1, 0, draggedItem);
      }
    }
    const tempArrItemIndex = tempArr.findIndex(item => item === tempArrItem);
    tempArr.splice(tempArrItemIndex, 1);
    return tempArr;
  };

  render() {
    const dragAndDropWrappedItems = this.props.dragAndDropItems ? this.props.dragAndDropItems.map((item, index) =>{
      const draggedClassName = this.state.draggedIndex === index ? 'DnD-item-container DnD-item-container_dragged' : 'DnD-item-container';
      const overDraggedClassName = this.state.overDraggedIndex === index ? "isOverDragged" : "";
      return (
        <div key={"d-&-d" + index} className={overDraggedClassName}
             onDragOver={(e) => this.onDragOver(e, index)} onDrop={e => this.onDrop(e, index)} >
          <div draggable={true} className={draggedClassName} onDragStart={(e) => this.onDragStart(e, index)}>
            {item}
          </div>
        </div>
      );
    }) : null;
    return (
      <>
        {dragAndDropWrappedItems}
      </>
    );
  }
}

export default DragAndDrop;
