//@flow
import * as React from 'react';
import RenderOnReady from "../../../../components/service/RenderOnReady/RenderOnReady";
import type {errorObject, isReadyToRender, locationType, match, history} from "../../../../services/flowTypes/appFlowTypes";
import type {userId, userName} from "../../../../services/flowTypes/dataFlowTypes";
import connect from "react-redux/es/connect/connect";
import userMainDataActions from "../../../../store/user/userMainData/userMainDataActions";
import {onGetUserMainData} from "../../../../services/flowTypes/propFnFlowTypes";
import concatIdName from "../../../../utils/concatIdName";
import api from "../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../services/apiInstance/apiErrorHandler";
import GetDataOnMountAndUpdate from "../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import FixedHeaderContent from "../../../../components/simple/Containers/FixedHeader/FixedHeaderContent";
import UserButtonsRoutes from "../../../../components/service/Routes/UserButtonsRoutes";
import {mainDataSuffix, supUserPathView} from "../../../../services/urlStrings/urlStrings";
import text from "../../../../services/localization/text";
import FixedSubHeader from "../../../../components/simple/Containers/FixedHeader/FixedSubHeader";
import UserMenu from "./user/UserMenu/UserMenu";

type Props = {
  match: match,
  location: locationType,
  onGetUserMainData: onGetUserMainData,
  userId: userId,
  name: userName,
  history: history
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};

class User extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null
    };
  }
  defineTitles = () => {
    if (!this.props.userId) {
      return {headerTitle: "", headerTitleLinkTo: "", subHeaderTitle: ""};
    }
    const userTitle = concatIdName(this.props.userId, this.props.name);
    const userTitleLinkTo =  supUserPathView(this.props.userId) + mainDataSuffix;

    return this.props.location.pathname.includes("edit") ?
      {headerTitle: userTitle, headerTitleLinkTo: userTitleLinkTo, subHeaderTitle: text.editTitleLabel}
      :  {headerTitle: text.users, headerTitleLinkTo: null, subHeaderTitle: userTitle}
      ;
  };
  setUserMainData = (userMainData) => {
    this.props.onGetUserMainData(userMainData);
    this.setState({isReadyToRender: true});
  };
  getUserMainData = () => {
    const userId = +this.props.match.params.id;
    api.getUserById(userId)
      .then(response => {
        this.setUserMainData(response.data);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render(){
    const {headerTitle, headerTitleLinkTo, subHeaderTitle} = this.defineTitles();
    return (
      <GetDataOnMountAndUpdate itemId={this.props.match.params.id} getDataOnMountAndUpdate={this.getUserMainData}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>

            <FixedHeaderContent title={headerTitle} titleLinkTo={headerTitleLinkTo} history={this.props.history}
                                isSupLevel={true}/>
            <FixedSubHeader title={subHeaderTitle}
                            buttons={<UserButtonsRoutes userId={this.props.userId}/>}
                            history={this.props.history}/>
            {this.props.location.pathname.includes("edit") || this.props.location.pathname.includes("/new-") ?
              null : (
                <UserMenu userId={this.props.userId}/>
              )}
        </RenderOnReady>
      </GetDataOnMountAndUpdate>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.user.mainData.id,
    name: state.user.mainData.name
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetUserMainData: (userMainData) => dispatch({
      type: userMainDataActions.USER_MAIN_DATA_SET,
      value: userMainData})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(User);