//@flow
import sourceParamsEditActions from './sourceParamsEditActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {sourceParams} from "../../../../services/flowTypes/dataFlowTypes";
import update from 'immutability-helper';

type State = sourceParams;
type reducerAction = action;
const initialState = [];

const sourceParamsEditReducer = (state:State = initialState, action:reducerAction) => {
  switch (action.type) {
    case sourceParamsEditActions.SOURCE_PARAMS_EDIT_SET:
      return action.value;
    case sourceParamsEditActions.SOURCE_PARAM_UPDATE:
      return update(state, {[action.value.sourceParamIndex]: {value: {$set: action.value.sourceParamValue}}});
    default:
      return state;
  }
};

export default sourceParamsEditReducer;