//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  isScrollToError,
  organizationsList, userPermissions,
} from "../../../../../../services/flowTypes/dataFlowTypes";
import type {
  onGetValidationStateFormProp,
  onGetValidationStateProp
} from "../../../../../../services/flowTypes/propFnFlowTypes";
import type {children, history, locationPathname, match} from "../../../../../../services/flowTypes/appFlowTypes";
import type {propError} from "../../../../../../services/flowTypes/componentFlowTypes";
import NFormGroup from "../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import validationActions from "../../../../../../store/validation/validationActions";
import userMainDataValidationScheme from "../../../../../../configData/userMainDataValidationScheme";
import validationPatterns from "../../../../../../services/validationPatterns/validationPatterns";
import UserOrganizations from "../UserOrganizations/UserOrganizations";

type Props = {
  match: match,
  location: locationPathname,
  history: history,
  userPermissions: userPermissions,
  allOrganizationsList: organizationsList,
  onGetValidationStateProp: onGetValidationStateProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  children: children,
  isScrollToError: isScrollToError,
  propError: propError
};

class UserMainDataRequiredOrganizationValidator extends React.Component <Props, State> {
  validate = () => {
    const error = validationPatterns[userMainDataValidationScheme.userMainDataOrganizationLength](this.props.userPermissions);
    this.props.onGetValidationStateProp('isScrollToError', true);
    this.props.onGetValidationStateFormProp('userMainDataOrganizationLength', error);
  };
  componentDidUpdate(prevProps){
    if (prevProps.userPermissions !== this.props.userPermissions) {
      this.validate();
    }
  }
  componentDidMount(){
    this.validate();
  }
  render() {
    return (
      <NFormGroup isScrollToError={this.props.isScrollToError} propError={this.props.propError}>
        <UserOrganizations {...this.props}/>
      </NFormGroup>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allOrganizationsList: state.admin.allOrganizationsList,
    userPermissions: state.user.mainData.userPermissions,
    propError: state.validation.form.userMainDataOrganizationLength,
    isScrollToError: state.validation.isScrollToError
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetValidationStateProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserMainDataRequiredOrganizationValidator);