//@flow
import * as React from 'react';
import "./FilterAndActInfoPanel.css";
import text from "../../../services/localization/text";
import Heading4 from "../../simple/TextComponents/Heading4/Heading4";
import {baseClass} from "../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";

type Props = {
  addClasses: addClasses,
  totalAmount: number,
  filteredAmount: number,
  chosenAmount: number,
  filterBtnArray?: Array<React.Node>,
  checkboxComp: React.Node,
  chosenActionBtnArray: Array<React.Node>
};

class FilterAndActInfoPanel extends React.Component <Props> {
  render() {
    const chosenLabel = text.infoPanelLabel.chosen + " " +
      this.props.chosenAmount + " " + text.infoPanelLabel.of + " " + this.props.filteredAmount;
    const filteredLabel = text.infoPanelLabel.found + " " +
      this.props.filteredAmount + " " + text.infoPanelLabel.of + " " + this.props.totalAmount;
    return (
      <div className={baseClass("FilterAndActInfoPanel__container", this.props.addClasses)}>
        <div className="FilterAndActInfoPanel">
          <div className="FilterAndActInfoPanel__left">
            {this.props.checkboxComp}
            {this.props.filterBtnArray}
            <Heading4 addClasses={"Heading4_lh16 ml_1du"} style={{opacity: this.props.chosenAmount ? "1" : ".5" }}>
              {this.props.chosenAmount ? chosenLabel :
                (this.props.totalAmount === undefined ? "" : filteredLabel)
                }</Heading4>
          </div>
          {this.props.chosenAmount ? (
            <div className="FilterAndActInfoPanel__right">
              <Heading4 addClasses={"Heading4_lh16 mr_1du"}>{text.infoPanelLabel.actions}</Heading4>
              <div className="FilterAndActInfoPanel__rightBtns">
                {this.props.chosenActionBtnArray}
              </div>
            </div>
          ) : null}
        </div>
        <div className="FilterAndActInfoPanel__whiteBottom"/>
      </div>
    );
  }
}

export default FilterAndActInfoPanel;