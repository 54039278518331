//@flow
import * as React from 'react';
import {connect} from "react-redux";
import TagLabel from "../../simple/TextComponents/TagLabel/TagLabel";
import type {children, id, onClick} from "../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import searchRequestActions from "../../../store/organization/searchRequest/searchRequestActions";
import {onGetSearchRequestProp} from "../../../services/flowTypes/propFnFlowTypes";
import scrollToTop from "../../../utils/scrollToTop";
import type {tagsList} from "../../../services/flowTypes/dataFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  onClick: onClick,
  onGetSearchRequestProp: onGetSearchRequestProp,
  selectedTags: tagsList
};

class ConnectedTagLabel extends React.Component <Props> {
  onClickTag = () => {
    if (!this.props.selectedTags.includes(this.props.children)) {
      const selectedTags =  [...this.props.selectedTags];
      selectedTags.push(this.props.children);
      const searchRequestObj = {
        offset: 0, selectedTags
      };
      this.props.onGetSearchRequestProp(searchRequestObj);
    }
    scrollToTop();
  };
  render() {
    return (
      <TagLabel onClick={this.onClickTag} id={this.props.id} addClasses={this.props.addClasses}>{this.props.children}</TagLabel>
    );
  }
}
const mapStateToProps = state => {
  return {
    selectedTags: state.organization.searchRequest.selectedTags
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSearchRequestProp: (searchRequestObj) => dispatch({type: searchRequestActions.SEARCH_REQUEST_PROP_SET,
      value: searchRequestObj})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedTagLabel);