//@flow
import itemFilesActions from './itemFilesActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {documentsList} from "../../../../services/flowTypes/dataFlowTypes";
import update from 'immutability-helper';

type reducerAction = action;
const initialState = [];

const itemFilesReducer = (state:documentsList = initialState, action:reducerAction) => {
  switch (action.type) {
    case itemFilesActions.ITEM_FILES_SET:
      return action.value;
    case itemFilesActions.ITEM_FILES_ADD:
      return update(state, {$push: [action.value]});
    case itemFilesActions.ITEM_FILES_UPDATE:
      return update(state, {[action.value.fileIndex]: {[action.value.paramName]: {$set: action.value.paramValue}}});
    case itemFilesActions.ITEM_FILES_SUBSTITUTE:
      return update(state, {[action.value.fileIndex]: {$set: action.value.fileObject}});
    case itemFilesActions.ITEM_FILES_DELETE:
      return update(state, {$splice: [[action.value, 1]]});
    default:
      return state;
  }
};

export default itemFilesReducer;