//@flow
import * as React from 'react';
import Text1 from "../../../../../../../../../components/simple/TextComponents/Text1/Text1";
import {actTemplatesEditPageIds} from "../../../../../../../../../tests/testIds";
import type {
  templateParams,
  currency,
  groupFinTermId
} from "../../../../../../../../../services/flowTypes/dataFlowTypes";
import GetConvertedTagBtn from "./GetConvertedTagBtn";
import text from "../../../../../../../../../services/localization/text";
import DataListContainer
  from "../../../../../../../../../components/simple/Containers/DataListContainer/DataListContainer";
import TagWithClickToCopy from "../../../../../../../../../components/simple/TextComponents/TagWithClickToCopy/TagWithClickToCopy";
import ConvertedTagLabels from "./ConvertedTagLabels";
import ManualInputTag from "./ManualInputTag";
import DeleteConvertedTagBtn from "./DeleteConvertedTagBtn";
import CurrencyConversionValidator from "./CurrencyConversionValidator";
import Heading5 from "../../../../../../../../../components/simple/TextComponents/Heading5/Heading5";
import {connect} from "react-redux";
import TagAppliedInExpressionIcon from "./TagAppliedInExpressionIcon";

type Props = {
  templateParams: templateParams,
  groupFinTermId: groupFinTermId,
  groupFinTermCurrency: currency,
  readonly?: boolean
};


class GroupFinTermTemplateParams extends React.Component <Props> {
  render(){
    const groupFinTermIndex = this.props.templateParams.findIndex(item => item.groupId === this.props.groupFinTermId);
    const groupFinTermTemplateParams = groupFinTermIndex !== -1 ?
      this.props.templateParams[groupFinTermIndex].actTemplateParams : null;

    const templateParams = groupFinTermTemplateParams && groupFinTermTemplateParams.length ?
      groupFinTermTemplateParams.map((param, index) => {
      const paramRow = (
        <>
          <div className="GroupFinTermTemplateParamsRow">
            <Text1 addClasses={'dynamic-param-row__1st-col'}>{text.groupFinTermTemplateParams[param.name]}</Text1>
            <div className="dynamic-param-row__2nd-col">
              <TagWithClickToCopy checkedComponent={<TagAppliedInExpressionIcon tag={param.variableName}/>}
                                  id={actTemplatesEditPageIds.tagGroupFinTermTemplateParam(param.name, groupFinTermIndex)}>
                {param.variableName}</TagWithClickToCopy>
            </div>
            <div className="dynamic-param-row__3rd-col flex_jc-sb">
              <TagWithClickToCopy checkedComponent={<TagAppliedInExpressionIcon tag={param.variableAlias}/>}
                                  id={actTemplatesEditPageIds.tagGroupFinTermTemplateParamAlias(param.name, groupFinTermIndex)}>
                {param.variableAlias}</TagWithClickToCopy>
            </div>
          </div>
          {param.name === 'manualInput' ? (
            <ManualInputTag groupFinTermParam={param} groupFinTermId={this.props.groupFinTermId}
                            groupFinTermTemplateParamIndex={index} readonly={this.props.readonly}/>
          ) : null}
        </>
      );
      const convertTagBtn = this.props.readonly ? null : (
        param.convertible ? (
          <GetConvertedTagBtn groupFinTermCurrency={this.props.groupFinTermCurrency} paramName={param.name}
                              key={param.name + index + 'convertBtn'} addClasses={'mb_half-du'}
                              id={actTemplatesEditPageIds.buttonGetConvertedTag(param.name, groupFinTermIndex)}
                              variableName={param.variableName}/>
        ) : null
      );
      const paramRowArr = [paramRow];
      const paramBtns = [convertTagBtn];
      if (param.convertedTag) {
        const editConvertTag = this.props.readonly ? null : (
          <GetConvertedTagBtn groupFinTermCurrency={this.props.groupFinTermCurrency} paramName={param.name} editMode={true}
                              convertedTag={param.convertedTag}
                              id={actTemplatesEditPageIds.buttonEditConvertedTag(param.name, groupFinTermIndex)}
                              variableName={param.variableName}/>
        );
        const convertedTagRow = (
          <div className="GroupFinTermTemplateParamsRow">
            <div className="dynamic-param-row__1st-col mt_-1du mb_-1du">
              <ConvertedTagLabels convertedTag={param.convertedTag}/>
            </div>
            <div className="dynamic-param-row__2nd-col">
              <TagWithClickToCopy checkedComponent={<TagAppliedInExpressionIcon tag={param.convertedTag.variableName}/>}
                                  id={actTemplatesEditPageIds.convertedTagGroupFinTermTemplateParam(param.name, groupFinTermIndex)}>
                {param.convertedTag.variableName}</TagWithClickToCopy>
            </div>
            <div className="dynamic-param-row__3rd-col_shorten flex_jc-sb">
              <TagWithClickToCopy checkedComponent={<TagAppliedInExpressionIcon tag={param.convertedTag.variableAlias}/>}
                                  id={actTemplatesEditPageIds.convertedTagGroupFinTermTemplateParamAlias(param.name, groupFinTermIndex)}>
                {param.convertedTag.variableAlias}</TagWithClickToCopy>

            </div>
            <div className="EditConvertedTag__rel-container">
              <div className="EditConvertedTag__abs-container">
                {editConvertTag}
              </div>
            </div>

          </div>
        );
        const deleteConvertTag = this.props.readonly ? null : (
          <DeleteConvertedTagBtn variableName={param.variableName} addClasses={'mb_half-du'}
                                 key={param.name + index + 'deleteConvertBtn'} groupFinTermId={this.props.groupFinTermId}
                                 id={actTemplatesEditPageIds.buttonDeleteConvertedTag(param.name, groupFinTermIndex)}/>
        );
        paramRowArr.push(convertedTagRow);
        paramBtns.push(deleteConvertTag);
      }
      return !this.props.readonly && param.convertible ? (
        <div key={'groupFinTermTemplateParamRow' + index} className="GroupFinTermTemplateParamRowWithBtn">
          <CurrencyConversionValidator groupFinTermIndex={groupFinTermIndex} groupFinTermId={this.props.groupFinTermId}
                                       actTemplateParam={param}>
            <DataListContainer  itemsList={paramRowArr}
                                addClasses={'mb_half-du'}/>
            <div className={'ml_half-du'}>
              {paramBtns}
            </div>
          </CurrencyConversionValidator>
        </div>
      ) : (
        <DataListContainer key={'groupFinTermTemplateParamRow' + index} itemsList={paramRowArr}
                           addClasses={'mb_half-du'}/>
      );

    }) : null;
    return (
      <>
        <Heading5 addClasses={'Heading5_no-underline mt_4du mb_2du'}>{text.tags}</Heading5>
        <div className="SectionDivider mb_3du"></div>
        {templateParams}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    templateParams: state.organization.item.templateParams

  };
};
export default connect(mapStateToProps, null)(GroupFinTermTemplateParams);