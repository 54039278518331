//@flow
import * as React from 'react';
import {connect} from "react-redux";
import Pagination from "../../../../components/composed/Pagination/Pagination";
import type {paginationCount, paginationOffset, totalOrganizationsNumber} from "../../../../services/flowTypes/dataFlowTypes";
import {onUpdateMyOrganizationsSearchProp} from "../../../../services/flowTypes/propFnFlowTypes";
import myOrganizationsSearchActions
  from "../../../../store/myOrganizations/myOrganizationsSearch/myOrganizationsSearchActions";

type Props = {
  count: paginationCount,
  offset: paginationOffset,
  total: totalOrganizationsNumber,
  onUpdateMyOrganizationsSearchProp: onUpdateMyOrganizationsSearchProp
};

class MyOrganizationsPagination extends React.Component <Props> {
  setOffset = (offset) => {
    const myOrganizationsSearchObj = {offset};
    this.props.onUpdateMyOrganizationsSearchProp(myOrganizationsSearchObj);
  };
  setCount = (count) => {
    const myOrganizationsSearchObj = {count, offset: 0 };
    this.props.onUpdateMyOrganizationsSearchProp(myOrganizationsSearchObj);
  };
  render() {
    return (
      <Pagination
        offset={this.props.offset}
        count={this.props.count}
        totalCount={this.props.total}
        setOffset={this.setOffset}
        setCount={this.setCount}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    count: state.myOrganizations.search.count,
    offset: state.myOrganizations.search.offset,
    total: state.myOrganizations.searchResults.total
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateMyOrganizationsSearchProp: (myOrganizationsSearchObj) => dispatch({
      type: myOrganizationsSearchActions.MY_ORGANIZATIONS_SEARCH_PROP_UPDATE,
      value: myOrganizationsSearchObj})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOrganizationsPagination);