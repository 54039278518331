//@flow
import * as React from 'react';
import {connect} from "react-redux";
import ability from "../../../../../../../services/permissions/permissions";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import ConnectedItemControls
  from "../../../../../../../components/composed/ConnectedItemControls/ConnectedItemControls";
import type {organization, organizationId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {
  errorObject,
  history,
  isReadyToRender,
  stateToCompare
} from "../../../../../../../services/flowTypes/appFlowTypes";
import api from "../../../../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import {onGetOrganizationMainData, onUpdateNeedFetchFlagsProp} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import {
  mainDataSuffix, orgPath, orgSettingsSuffix, supOrgPathView, viewOrganizationSettingsSuffix
} from "../../../../../../../services/urlStrings/urlStrings";
import needFetchFlagsActions from "../../../../../../../store/needFetchFlags/needFetchFlagsActions";
import organizationMainDataActions from "../../../../../../../store/organization/organizationMainData/organizationMainDataActions";
import type {isSupLevel} from "../../../../../../../services/flowTypes/componentFlowTypes";
import {globalPermissionsStrValues, permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";

type Props = {
  organizationId: organizationId,
  history: history,
  organizationMainData: organization,
  onGetOrganizationMainData: onGetOrganizationMainData,
  onUpdateNeedFetchFlagsProp: onUpdateNeedFetchFlagsProp,
  isSupLevel?: isSupLevel
};
type State = {
  stateToCompare: stateToCompare,
  isReadyToRender: isReadyToRender,
  errorObject: errorObject
};

class OrganizationMainDataControls extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      stateToCompare: {},
      errorObject: null,
      isReadyToRender: true
    };
  }
  goToOrganizationMainDataView = (organizationId, needToReload) => {
    const path = this.props.isSupLevel ? supOrgPathView(organizationId) + mainDataSuffix :
      orgPath(organizationId) + orgSettingsSuffix + viewOrganizationSettingsSuffix;
    this.props.history.push({pathname: path});
    if (needToReload || !this.props.isSupLevel) {
      //to get permissions of new organization
      window.location.reload();
    } else {
      // TODO: add to this fn list the list of my organizations (or it may be not required)
      this.props.onUpdateNeedFetchFlagsProp('organizationsList', true);
      this.props.onUpdateNeedFetchFlagsProp('allOrganizationsList', true);
    }
  };
  handleResponse = (organizationMainData, goBack, needToReload) => {
    this.props.onGetOrganizationMainData(organizationMainData);
    this.setState({isReadyToRender: true});
    if (goBack) {
      this.props.history.goBack();
    } else {
      this.goToOrganizationMainDataView(organizationMainData.id, needToReload);
    }
  };
  getOrganizationById = (goBack) => {
    const organizationId = this.props.isSupLevel ? this.props.organizationId : +this.props.match.params.organizationId;
    api.getOrganizationById(organizationId)
      .then(response => {
        this.handleResponse(response.data, goBack);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      })
  };
  handleDataBeforeSend = () => {
    // const agent = this.updateParamsInAgentMainData();
    return this.props.organizationMainData;
  };
  requestUpdate = (organization) => {
    api.updateOrganization(organization)
      .then(response => {
        this.handleResponse(response.data);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  requestCreate = (organization) => {
    api.createOrganization(organization)
      .then(response => {
        this.handleResponse(response.data, false, true);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  sendRequest = () => {
    this.setState({isReadyToRender: false});
    const organization = this.handleDataBeforeSend();
    if (this.props.isSupLevel && this.props.organizationId === null) {
      this.requestCreate(organization);
    } else {
      this.requestUpdate(organization);
    }
  };
  cancel = () => {
    if (this.props.isSupLevel && this.props.organizationId === null) {
      this.props.history.goBack();
    } else {
      this.setState({isReadyToRender: false});
      const goBack = true;
      this.getOrganizationById(goBack);
    }
  };
  componentDidUpdate(prevProps){
    const organizationId = this.props.isSupLevel ? this.props.organizationId : +this.props.match.params.organizationId;
    if ( (organizationId === this.props.organizationMainData.id &&
      prevProps.organizationMainData.id !== this.props.organizationMainData.id)
      || (!organizationId && prevProps.organizationMainData.id !== this.props.organizationMainData.id)
    ) {
      this.setState({stateToCompare: JSON.stringify(this.props.organizationMainData)});
    }
  }
  componentDidMount(){
    this.setState({stateToCompare: JSON.stringify(this.props.organizationMainData)})
  }
  render() {
    // const organizationMainData = this.updateParamsInAgentMainData();
    const organizationMainData = this.props.organizationMainData;
    const actionIsPermitted = this.props.isSupLevel ? ability.can('useGlobalPermission', globalPermissionsStrValues.admin)
      : ability.can('usePermission', permissionsStrValues.organization.update);
    const submitIsDisabled = this.state.stateToCompare === JSON.stringify(organizationMainData);
    return(
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <ConnectedItemControls
          actionIsPermitted={actionIsPermitted}
          submitIsDisabled={submitIsDisabled}
          cancelFn={this.cancel}
          sendRequest={this.sendRequest}
        />
      </>

    );
  }
}

const mapStateToProps = state => {
  return {
    organizationMainData: state.organization.mainData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetOrganizationMainData: (organization) => dispatch({type: organizationMainDataActions.ORGANIZATION_MAIN_DATA_SET,
      value: organization}),
    onUpdateNeedFetchFlagsProp: (prop, needFetch) => dispatch({type: needFetchFlagsActions.NEED_FETCH_FLAGS_PROP_UPDATE,
      value: {
        prop: prop,
        data: needFetch
      }})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationMainDataControls);