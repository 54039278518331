//@flow
import * as React from 'react';
import {connect} from "react-redux";
import text from "../../../services/localization/text";
import SmallTextBtn from "../../simple/Buttons/SmallTextBtn/SmallTextBtn";
import api from "../../../services/apiInstance/api";
import apiErrorHandler from "../../../services/apiInstance/apiErrorHandler";
import type {errorObject, filtersFromParams, isReadyToRender} from "../../../services/flowTypes/appFlowTypes";
import historyDataActions from "../../../store/organization/historyData/historyDataActions";
import type {onGetHistory} from "../../../services/flowTypes/propFnFlowTypes";
import {baseClass} from "../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import {handleHistoryFilters} from "./HistoryFetcher";
import {filterIds} from "../../../tests/testIds";

type Props = {
  onGetHistory: onGetHistory,
  addClasses: addClasses,
  filtersFromParams: filtersFromParams
};

type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};

class SetAllViewedBtn extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true
    };
  }
  setAllViewed = () => {
    this.setState({isReadyToRender: false});
    const historyFilters = handleHistoryFilters(this.props.filtersFromParams);
    api.setAllAsViewed(historyFilters)
      .then((response) => {
        this.props.onGetHistory(response.data);
        this.setState({isReadyToRender: true});
      })
      .catch((error) => {
        this.setState({
          errorObject: apiErrorHandler(error),
          isReadyToRender: true
        });
      });
  };
  render() {
    return (
      <SmallTextBtn onClick={this.setAllViewed} id={filterIds.buttonSetAllViewed}
                    addClasses={baseClass('SmallTextBtn_clean SmallTextBtn_lh40 ' + this.props.addClasses)}>
        {text.allRead}</SmallTextBtn>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetHistory: (value) => dispatch({type: historyDataActions.HISTORY_SET, value})
  };
};
export default connect(null, mapDispatchToProps)(SetAllViewedBtn);