const getParamValue = (param) => {
  switch (param.type) {
    case 'TEXT':
    case 'TEXT_AREA':
      return param.textValue;
    case 'DATE':
      return param.dateValue;
    default:
      console.error('Unknown type of param!');
  }
};
export default getParamValue;