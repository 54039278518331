//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {children} from "../../../services/flowTypes/appFlowTypes";
import catchedErrorHandler from "../../../utils/catchedErrorHandler";
// import AlertOnError from "../../composed/Alerts/AlertOnError";
import alertsActions from "../../../store/alerts/alertsActions";
import type {onAddAlert} from "../../../services/flowTypes/propFnFlowTypes";
import getUniqueKey from "../../../utils/getUniqueKey";

type Props = {
  children: children,
  onAddAlert: onAddAlert
};
// type State = {
//   hasError: hasError,
//   errorObject: errorObject
// };

class ErrorBoundary extends React.Component <Props> {
  // constructor(props:Props) {
  //   super(props);
  //   this.state = {
  //     hasError: false,
  //     errorObject: null
  //   };
  // }
  // static getDerivedStateFromError(error) {
  //   console.log('gDSFE', error);
  //   return {
  //     hasError: true,
  //     errorObject: catchedErrorHandler(error)
  //   };
  // }

  componentDidCatch(error:Object, errorInfo:Object) {
    console.log('cDC', error, errorInfo);
    const newAlert = {
      id: getUniqueKey(),
      type: 'ERROR',
      errorObject: catchedErrorHandler(error, errorInfo)
    };
    this.props.onAddAlert(newAlert);
  }
  render() {
    return this.props.children;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAddAlert: (newAlert) => dispatch({
      type: alertsActions.ALERT_ADD, value: newAlert})
  };
};
export default connect(null, mapDispatchToProps)(ErrorBoundary);