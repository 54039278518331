//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './NDropDownDetails.css';
import type {
  detailsOptions,
  detailsSummary, isDropDownIconHidden, optionsAreShownOnMount, optionsContainerHeightIsAuto, requestInProcess
} from "../../../../services/flowTypes/componentFlowTypes";
import DataListContainer from "../DataListContainer/DataListContainer";
import DropDown from "../DropDown/DropDown";
import type {fnRequestOnClick} from "../../../../services/flowTypes/propFnFlowTypes";
import LoadingIcon from "../../Icons/LoadingIcon/LoadingIcon";

type Props = {
  id?: id,
  addClasses?: addClasses,
  detailsSummary: detailsSummary,
  detailsOptions: detailsOptions,
  fnRequestOnClick?: fnRequestOnClick,
  requestInProcess?: requestInProcess,
  optionsContainerHeightIsAuto?: optionsContainerHeightIsAuto,
  isDropDownIconHidden?: isDropDownIconHidden,
  testIdForSummaryIco?: id,
  optionsAreShownOnMount?: optionsAreShownOnMount
};

const NDropDownDetails = (props:Props) => {
  const detailsOptions = props.detailsOptions ? props.detailsOptions.map((item, index) => {
    return (<div key={'ddd-' + index} className="NDropDownDetails__option">{item}</div>)
  }) : null;
  const Icon = props.fnRequestOnClick ?
    props.requestInProcess ? (<LoadingIcon addClasses={''}/>) :
      (<div id={props.testIdForSummaryIco} className="NDropDownDetails__ico"/>)
    : (props.isDropDownIconHidden ? null : <div id={props.testIdForSummaryIco} className="NDropDownDetails__ico"/>);
  return (
    <div id={props.id} className={baseClass("NDropDownDetails", props.addClasses)}>
      <DropDown
        clickTitleHandler={props.fnRequestOnClick}
        optionsAreShownOnMount={props.optionsAreShownOnMount}
        optionsContainerHeightIsAuto={props.optionsContainerHeightIsAuto}
        dropDownTitle={
        (<div className={"NDropDownDetails__summary-container"}>
          {props.detailsSummary}
          {Icon}
        </div>)
      } dropDownOptions={
        <DataListContainer addClasses={"NDropDownDetails__detailsOptions"} itemsList={detailsOptions}/>
      }/>
    </div>
  );
};

export default NDropDownDetails;