//@flow
import * as React from 'react';
import type {user} from "../../../../../services/flowTypes/dataFlowTypes";
import {mainDataSuffix, supUserPathView} from "../../../../../services/urlStrings/urlStrings";
import {usersPageIds} from "../../../../../tests/testIds";
import type {userIndex} from "../../../../../services/flowTypes/componentFlowTypes";
import text from "../../../../../services/localization/text";
import ListItemCard from "../../../../../components/composed/ListItemCard/ListItemCard";
import formatDate from "../../../../../utils/formatDate";

type Props = {
  user: user,
  userIndex: userIndex
};

const UserCard = (props: Props) => {
  const linkToUser = supUserPathView(props.user.id) + mainDataSuffix;
  const cardTiles = [
    {tileIconType: 'organizations', numberLabel: props.user.userPermissions.length}
  ];
  const createDate = formatDate(props.user.createDate);
  return (
    <ListItemCard
      itemId={props.user.id}
      itemName={props.user.name}
      linkTo={linkToUser}
      linkId={usersPageIds.linkToUser(props.userIndex)}
      // createDate={createDate}
      validityTerm={createDate}
      cardHeadingId={usersPageIds.headingCardUserId(props.userIndex)}
      addClasses={'UserCard mb_1du'}
      itemStatus={props.user.status}
      itemStatusLabel={text.userStatus[props.user.status]}
      cardTiles={cardTiles}
      isWideMode={true}
    />
  );
};

export default UserCard;