//@flow
import * as React from 'react';
import type {
  isGetReportsOnMount
} from "../../../../services/flowTypes/componentFlowTypes";
import ReportsFetcher from "./ReportsFetcher/ReportsFetcher";
import type {reportAccountStatusName} from "../../../../services/flowTypes/dataFlowTypes";
import ReportsFilters from "./ReportsFilters";

type Props = {
  statusFromParams: reportAccountStatusName,
  isGetReportsOnMount: isGetReportsOnMount
};

class ReportsSearchBox extends React.Component <Props> {
  render() {
    return (
      <>
        <ReportsFetcher
          statusFromParams={this.props.statusFromParams}
          isGetReportsOnMount={this.props.isGetReportsOnMount}
        />
        <ReportsFilters statusFromParams={this.props.statusFromParams}/>
      </>

    );
  }
}

export default ReportsSearchBox;