//@flow
import * as React from 'react';
import type {children} from "../../../../services/flowTypes/appFlowTypes";
import ButtonTooltip from "./ButtonTooltip/ButtonTooltip";
import type {tooltipContent, tooltipPosition} from "../../../../services/flowTypes/componentFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";

type Props = {
  children: children,
  tooltipContent: tooltipContent,
  tooltipPosition: tooltipPosition,
  addClasses?: addClasses
};

const ButtonTooltipContainer = (props:Props) => {
  return (
    <div className={baseClass("ButtonTooltipContainer", props.addClasses)}>
      {props.children}
      <ButtonTooltip tooltipPosition={props.tooltipPosition}>{props.tooltipContent}</ButtonTooltip>
    </div>
  )

}

export default ButtonTooltipContainer;    