//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {historyItems, logIdFromUrl, organizationId} from "../../../services/flowTypes/dataFlowTypes";
import ConnectedHistoryCard from "./ConnectedHistoryCard";
import NHeading3 from "../../simple/TextComponents/NHeading3/NHeading3";
import text from "../../../services/localization/text";
import getUniqueKey from "../../../utils/getUniqueKey";
import EmptyListV2 from "../../simple/Containers/EmptyList/EmptyListV2";

type Props = {
  historyItems: historyItems,
  organizationId: organizationId,
  logIdFromUrl?: logIdFromUrl
};

class HistoryList extends React.Component <Props> {
  render() {
    const unviewedCards = [];
    const viewedCards = [];
    if (this.props.historyItems) {
      for (let i=0; i < this.props.historyItems.length; i++) {
        const card = (<ConnectedHistoryCard key={getUniqueKey('historyItem')} historyItem={this.props.historyItems[i]}
                                            historyCardIndex={i} logIdFromUrl={this.props.logIdFromUrl}
                                            organizationId={this.props.organizationId} />);
        if (this.props.historyItems[i].userActions[0].actions[0].isUnviewed) {
          unviewedCards.push(card);
        } else {
          viewedCards.push(card);
        }
      }
    }
    return (
      this.props.historyItems === null ? null :
      !unviewedCards.length && !viewedCards.length ? <EmptyListV2/> :
      <>
        {unviewedCards.length > 0 ? (
          <div className="flex">
            <NHeading3 addClasses={'NHeading3_lh40 mr_2du'}>{text.unviewed}</NHeading3>
          </div>
        ) : null}
        {unviewedCards}
        {viewedCards.length > 0 && unviewedCards.length > 0 ? (
          <div className="flex pt_3du">
            <NHeading3 addClasses={'NHeading3_lh40 mr_2du'}>{text.viewed}</NHeading3>
          </div>
        ) : null}
        {viewedCards}
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    historyItems: state.organization.historyData.historyItems,
    organizationId: state.organization.organizationId
  };
};
export default connect(mapStateToProps, null)(HistoryList);