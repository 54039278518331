import * as React from 'react';

const formatWithSearchTextIllumination = (inputValue, stringValue) => {
  let regExpStrToMatch = '';
  try {
    regExpStrToMatch = new RegExp(inputValue, 'gi');
  }
  finally {
    const firstIndex = stringValue.search(regExpStrToMatch);
    if (firstIndex === -1) {
      return stringValue;
    } else {
      const lastIndex = firstIndex + inputValue.length;
      let formattedValueArr = [];
      const firstPart = stringValue.slice(0, firstIndex);
      const matchedPart = stringValue.slice(firstIndex, lastIndex);
      const lastPart = stringValue.slice(lastIndex, stringValue.length);
      formattedValueArr.push(<span key={'firstPart'}>{firstPart}</span>);
      formattedValueArr.push(<span key={'matchedPart'} className="matched-part">{matchedPart}</span>);
      formattedValueArr.push(<span key={'lastPart'}>{lastPart}</span>);
      return formattedValueArr;
    }
  }
};
export default formatWithSearchTextIllumination;