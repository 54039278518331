//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  onDeleteReportsSearchProp,
  onUpdateReportsSearchProp
} from "../../../../services/flowTypes/propFnFlowTypes";
import type {
  actTemplatesList,
  agentsList, contractsList, reportAccountStatusList, reportAccountStatusName, reportsSearch, reportStatusList,
  usersList
} from "../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../services/localization/text";
import type {selectOptions} from "../../../../services/flowTypes/componentFlowTypes";
import reportsSearchActionTypes from "../../../../store/organization/reportsSearch/reportsSearchActions";
import concatIdName from "../../../../utils/concatIdName";
import getNameOrDefName from "../../../../utils/getNameOrDefName";
import Filters from "../../../../components/composed/Filters/Filters";

type Props = {
  statusFromParams: reportAccountStatusName,
  onUpdateReportsSearchProp: onUpdateReportsSearchProp,
  onDeleteReportsSearchProp: onDeleteReportsSearchProp,
  reportStatusList: reportStatusList,
  reportAccountStatusList: reportAccountStatusList,
  usersList: usersList,
  contractsList: contractsList,
  agentsList: agentsList,
  actTemplatesList: actTemplatesList,
  reportsSearch: reportsSearch
};
type State = {
  reportStatusOptions: selectOptions,
  reportAccountStatusOptions: selectOptions,
  userIdOptions: selectOptions,
  contractIdOptions: selectOptions,
  actTemplateIdOptions: selectOptions,
  agentIdOptions: selectOptions
};

const reportFiltersPropsList = ['reportStatus', 'reportAccountStatus', 'startCreateDate', 'endCreateDate',
  'startReportPeriodDate', 'endReportPeriodDate', 'userId', 'contractId', 'actTemplateId', 'payerId', 'payeeId', 'text'];

class ReportsFilters extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      reportStatusOptions: [],
      reportAccountStatusOptions: [],
      userIdOptions: [],
      contractIdOptions: [],
      actTemplateIdOptions: [],
      agentIdOptions: []
    };
  }
  handleOnGetReportsFiltersPropCall = (filtersProps) => {
    const reportsFiltersProps = {...filtersProps};
    if (!reportsFiltersProps.hasOwnProperty('offset')) {
      reportsFiltersProps.offset = 0;
    }
    this.props.onUpdateReportsSearchProp(reportsFiltersProps);
  };
  getFiltersList = (allFiltersList) => {
    const filtersList = [];
    const filtersForOptions = [];
    for (let i=0; i < allFiltersList.length; i++) {
      const condition =
        allFiltersList[i].propName === 'reportPeriodDateRange' ?
          this.props.reportsSearch.hasOwnProperty('startReportPeriodDate')
          || this.props.reportsSearch.hasOwnProperty('endReportPeriodDate')
          :  (allFiltersList[i].propName === 'createDateRange' ?
          this.props.reportsSearch.hasOwnProperty('startCreateDate')
          || this.props.reportsSearch.hasOwnProperty('endCreateDate')
          : this.props.reportsSearch.hasOwnProperty(allFiltersList[i].propName) );
      if (condition) {
        filtersList.push(allFiltersList[i]);
      } else {
        filtersForOptions.push(allFiltersList[i]);
      }
    }
    return {filtersList, filtersForOptions};
  };
  componentDidMount() {
    const defaultOption =  {value: 'ALL', label: text.reportStatus['ALL']};
    const reportStatusOptions = this.props.reportStatusList.map(item => {
      return {
        value: item,
        label: text.reportStatus[item],
        statusClassName: `report-status_${item}`
      };
    });
    const reportAccountStatusOptions = this.props.reportAccountStatusList.map(item => {
      return {
        value: item.id,
        label: text.reportAccountStatus[item.name],
        statusClassName: `report-status_${item.name}`
      };
    });
    const userIdOptions = this.props.usersList.map(item => {
      return {value: item.id, label: item.name}
    });
    const contractIdOptions = this.props.contractsList.map(item => {
      return {value: item.id, label: concatIdName(item.id, item.name)}
    });
    const agentIdOptions = this.props.agentsList.map(item => {
      return {value: item.id, label: item.name}
    });
    const actTemplateIdOptions = this.props.actTemplatesList.map(item => {
      return {value: item.id, label: concatIdName(item.id, getNameOrDefName(item.name, text.actTemplate))}
    });
    reportStatusOptions.unshift(defaultOption);
    reportAccountStatusOptions.unshift(defaultOption);
    userIdOptions.unshift(defaultOption);
    contractIdOptions.unshift(defaultOption);
    agentIdOptions.unshift(defaultOption);
    actTemplateIdOptions.unshift(defaultOption);
    this.setState({
      reportStatusOptions,
      reportAccountStatusOptions,
      userIdOptions,
      contractIdOptions, agentIdOptions, actTemplateIdOptions});
  }
  render() {
    const reportStatusFilter = {propName: 'reportStatus', formFieldType: 'filter-select',
      options: this.state.reportStatusOptions, initValue: 'ALL'};
    const reportAccountStatusFilter = {propName: 'reportAccountStatus', formFieldType: 'filter-select',
      options: this.state.reportAccountStatusOptions, initValue: 'ALL'};
    const payerIdFilter = {propName: 'payerId', formFieldType: 'filter-select', options: this.state.agentIdOptions, initValue: 'ALL'};
    const payeeIdFilter = {propName: 'payeeId', formFieldType: 'filter-select', options: this.state.agentIdOptions, initValue: 'ALL'};
    const textFilter = {propName: 'text', formFieldType: 'filter-input', initValue: '', placeholder: text.textReportFilterPlaceholder};
    const allFiltersList = [
      {propName: 'createDateRange', formFieldType: 'filter-wrapped-date-range', initValue: ''},
      {propName: 'reportPeriodDateRange', formFieldType: 'filter-wrapped-date-range', initValue: '',
        withCheckbox: true, checkboxPropName: 'strictPeriod', checkboxInitValue: false},
      {propName: 'userId', formFieldType: 'filter-select', options: this.state.userIdOptions, initValue: 'ALL'},
      {propName: 'contractId', formFieldType: 'filter-select', options: this.state.contractIdOptions, initValue: 'ALL'},
      {propName: 'actTemplateId', formFieldType: 'filter-select', options: this.state.actTemplateIdOptions, initValue: 'ALL'}
    ];
    if (this.props.statusFromParams === "ALL") {
      allFiltersList.unshift(reportStatusFilter, reportAccountStatusFilter);
    }
    if (this.props.statusFromParams !== "DRAFT") {
      allFiltersList.push(textFilter);
    }
    if (this.props.statusFromParams !== "PREPARED" && this.props.statusFromParams !== "ADJUSTED") {
      allFiltersList.push(payerIdFilter, payeeIdFilter);
    }
    const {filtersList, filtersForOptions} = this.getFiltersList(allFiltersList);
    const resetFilters = () => this.props.onDeleteReportsSearchProp(reportFiltersPropsList);
    return (
      <Filters onGetFiltersProp={this.handleOnGetReportsFiltersPropCall}
               onDeleteFiltersProp={this.props.onDeleteReportsSearchProp}
               filtersState={this.props.reportsSearch}
               filtersForOptions={filtersForOptions}
               filtersList={filtersList}
               resetFilters={resetFilters}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    reportStatusList: state.organization.organizationData.statuses.reportStatusList,
    reportAccountStatusList: state.organization.organizationData.statuses.reportAccountStatusList,
    usersList: state.organization.organizationData.usersList,
    agentsList: state.organization.organizationData.agentsList,
    contractsList: state.organization.organizationData.contractsList,
    actTemplatesList: state.organization.organizationData.actTemplatesList,
    reportAccountStatus: state.organization.reportsSearch.reportAccountStatus,
    reportsSearch: state.organization.reportsSearch
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateReportsSearchProp: (reportsSearchObj) => dispatch({type: reportsSearchActionTypes.REPORTS_SEARCH_PROP_UPDATE,
      value: reportsSearchObj}),
    onDeleteReportsSearchProp: (reportsSearchPropNameArr) => dispatch({
      type: reportsSearchActionTypes.REPORTS_SEARCH_PROP_DELETE,
      value: reportsSearchPropNameArr
    })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsFilters);