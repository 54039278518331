//@flow
import * as React from 'react';
import text from "../../../../../../../services/localization/text";
import type {finTerm, itemDate} from "../../../../../../../services/flowTypes/dataFlowTypes";
import isReportDatesCompliedWithFinTerms from "../../../../../../../utils/isReportDatesCompliedWithFinTerms";

type Props = {
  startReportPeriodDate: itemDate,
  endReportPeriodDate: itemDate,
  finTerms: Array<finTerm>
};

const ReportGroupFinTermWarning = (props: Props) => {
  return (
    isReportDatesCompliedWithFinTerms(props.startReportPeriodDate, props.endReportPeriodDate, props.finTerms) ? null : (
      <div className={`AlertCard__header AlertCard__header_in_ReportTotalExpression AlertCard__header_REPORT-WARNING mt_1du`}>
        <div className="AlertCard__header-ico"/>
        <div className="AlertCard__header-title">
          {text.reportPeriodIncompliance}
        </div>
      </div>
      )
  );
};

export default ReportGroupFinTermWarning;    