//@flow
import * as React from 'react';
import {Link} from "react-router-dom";
import {contractViewPageIds} from "../../../../../../tests/testIds";
import text from "../../../../../../services/localization/text";
import Can from "../../../../../../services/permissions/Can";
import {contrPathEdit, mainDataSuffix, orgPath} from "../../../../../../services/urlStrings/urlStrings";
import type {contractId, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import {permissionsStrValues} from "../../../../../../configData/permissionsStrValues";
import BtnNewItem from "../../../../../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  organizationId: organizationId,
  contractId: contractId
};

const ContractMainDataEditButton = (props: Props) => {
  const organizationId = props.organizationId;
  const linkToEdit = orgPath(organizationId) + contrPathEdit(props.contractId) + mainDataSuffix;
  return (
    <Can do={'usePermission'} on={permissionsStrValues.contract.update}>
      <Link className={"button-wrapper-link"} to={linkToEdit}>
        <BtnNewItem id={contractViewPageIds.buttonEdit}>{text.btnEdit}</BtnNewItem>
      </Link>
    </Can>
  );
};

export default ContractMainDataEditButton;    