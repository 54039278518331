//@flow
import * as React from 'react';
import text from "../../../../../../../services/localization/text";
import NModal from "../../../../../../../components/simple/Modals/NModal/NModal";
import type {
  actTemplateParamsList
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import NHeading2 from "../../../../../../../components/simple/TextComponents/NHeading2/NHeading2";
import Text1 from "../../../../../../../components/simple/TextComponents/Text1/Text1";
import SecText from "../../../../../../../components/simple/TextComponents/SecText/SecText";
import NInput from "../../../../../../../components/simple/FormComponents/NInput/NInput";
import './ViewTemplateParams.css';
import TextOverflowHandler
  from "../../../../../../../components/simple/Containers/TextOverflowHandler/TextOverflowHandler";
import Heading4 from "../../../../../../../components/simple/TextComponents/Heading4/Heading4";
import {actTemplatesEditPageIds} from "../../../../../../../tests/testIds";
import type {hideModal} from "../../../../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  actTemplateParamsList: actTemplateParamsList,
  hideModal: hideModal
};

class ViewTemplateParams extends React.Component <Props> {
  selectInputTextOnFocus = (e:SyntheticFocusEvent<HTMLInputElement>) => {
    e.currentTarget.select();
  };

  render(){
    const templateParam = (param, blockIndex, paramIndex, subBlockIndex) => {
      const backColorClassName = param.paramDescription ? 'ViewTemplateParams__color-back flex'
        : paramIndex%2 === 1 ? 'ViewTemplateParams__color-back flex' : 'flex';
      const thirdColClassName = param.paramDescription ?
        "ViewTemplateParams__3rd-col ViewTemplateParams__3rd-col_wide ViewTemplateParams__NInput"
        : "ViewTemplateParams__3rd-col ViewTemplateParams__NInput";
      return (<div key={'param'+blockIndex+paramIndex} className={''}>
        {param.paramDescription ? (
          <SecText addClasses={''}>
            <TextOverflowHandler>{param.paramDescription}</TextOverflowHandler>
          </SecText>
        ) : null}
        <div className={backColorClassName}>
          <Text1 addClasses={'ViewTemplateParams__1st-col Text1_lh24'}>
            <TextOverflowHandler>{param.paramName}</TextOverflowHandler>
          </Text1>
          <SecText addClasses={'ViewTemplateParams__2nd-col SecText_lh24'}>
            <TextOverflowHandler>{param.paramExample}</TextOverflowHandler>
          </SecText>
          <TextOverflowHandler isInputWrapperMode={true} inputValue={param.paramTag}>
            <NInput addClasses={thirdColClassName} value={param.paramTag}
                    id={actTemplatesEditPageIds.inputTemplateParamTag(blockIndex, paramIndex, subBlockIndex)}
                                       readOnly={true} onFocus={this.selectInputTextOnFocus}/>
          </TextOverflowHandler>
          {param.paramAliases ? (
              <TextOverflowHandler isInputWrapperMode={true} inputValue={param.paramAliases}>
                <NInput addClasses="ViewTemplateParams__4th-col ViewTemplateParams__NInput" value={param.paramAliases}
                        id={actTemplatesEditPageIds.inputTemplateParamTagAlias(blockIndex, paramIndex, subBlockIndex)}
                        readOnly={true} onFocus={this.selectInputTextOnFocus}/>
              </TextOverflowHandler>
          ) : null}
        </div>
      </div>);
    };
    const actTemplateParamsList = this.props.actTemplateParamsList.map((block, i) => {
      const templateParams = block.blockBody.paramsList.map((param, paramIndex) => {
        return templateParam(param,i,paramIndex);
      });
      const subBlocks = block.blockBody.subBlocksList && block.blockBody.subBlocksList.length ?
        block.blockBody.subBlocksList.map((subBlock, subBlockIndex) => {
          const templateParams = subBlock.paramsList.map((param, paramIndex) => {
            return templateParam(param,i,paramIndex, subBlockIndex);
          });
          return (<div key={'subBlock'+i+subBlockIndex}>
            <Heading4 addClasses={'mt_1du'}>{subBlock.subBlockName}</Heading4>
            {templateParams}
          </div>)
        }) : null;
      return (
        <div key={'templateParamsBlock'+i} className={'ViewTemplateParams__block'}>
          <NHeading2 addClasses={'ta_c pt-pb_2du'}>{block.blockName}</NHeading2>
          {templateParams}
          {subBlocks}
        </div>
      );
    });
    return (
      <NModal
        addClasses={'NModal_ViewTemplateParams'}
        modalTitle={text.viewTemplateParamsModalTitle}
        onModalCancelBtnClick={this.props.hideModal}
        modalCancelBtnText={text.cancel}
      >
        <div className="ViewTemplateParams__table-header mt_2du">
          <div className="ViewTemplateParams__1st-col">{text.templateParamsHeader.paramName}</div>
          <div className="ViewTemplateParams__2nd-col">{text.templateParamsHeader.paramExample}</div>
          <div className="ViewTemplateParams__3rd-col">{text.templateParamsHeader.paramTag}</div>
          <div className="ViewTemplateParams__4th-col">{text.templateParamsHeader.paramAliases}</div>
        </div>
        {actTemplateParamsList}
      </NModal>
    );
  }
}
export default ViewTemplateParams;