//@flow
import groupFinTermsEditActions from './groupFinTermsEditActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {groupFinTerm} from "../../../../services/flowTypes/dataFlowTypes";
import update from 'immutability-helper';

type State = groupFinTerm;
type reducerAction = action;
export const initialState = {
  name: '',
  payerId: '',
  payeeId: '',
  type: '',
  nomenNumber1c: '',
  projectName: '',
  ignoreInExport: false,
  currency: '',
  payoutPeriod: '',
  source: '',
  sourceParams: [],
  gates: [],
  finTerms: [],
  customFunction: null
};

const groupFinTermsEditReducer = (state:State = initialState, action:reducerAction) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case groupFinTermsEditActions.GROUP_FIN_TERMS_EDIT_INIT:
      return {...initialState, ...action.value};
    case groupFinTermsEditActions.GROUP_FIN_TERMS_EDIT_SET:
      return action.value;
    case groupFinTermsEditActions.GROUP_FIN_TERMS_PROP_SET:
      return {
        ...state,
        [action.value.prop]: action.value.data
      }
    case groupFinTermsEditActions.GROUP_FIN_TERMS_GATE_PARAM_SET:
      return update(state, {gates: {[action.value.finTermGateIndex]:{[action.value.paramName]: {$set: action.value.terminals}}}});
    default:
      return state;
  }
};

export default groupFinTermsEditReducer;