//@flow
import * as React from 'react';
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import text from "../../../../../../services/localization/text";
import SectionHeader from "../../../../../../components/composed/SectionHeader/SectionHeader";
import ItemFiles from "../ContractMainDataEdit/ItemFiles/ItemFiles";
import type {match, readOnly} from "../../../../../../services/flowTypes/appFlowTypes";
import addServiceEditActions from "../../../../../../store/organization/item/addServiceEdit/addServiceEditActions";
import {connect} from "react-redux";
import type {onGetAddServiceProp} from "../../../../../../services/flowTypes/propFnFlowTypes";
import type {
  contractId,
  hasPreparedAddServiceReport,
  organizationId
} from "../../../../../../services/flowTypes/dataFlowTypes";
import {Link} from "react-router-dom";
import {addServiceAllDataPageIds} from "../../../../../../tests/testIds";
import BtnEdit from "../../../../../../components/simple/Buttons/BtnEdit/BtnEdit";
import Can from "../../../../../../services/permissions/Can";
import type {linkToDocsEdit, permissionStr} from "../../../../../../services/flowTypes/componentFlowTypes";

type Props = {
  match?: match,
  onGetAddServiceProp: onGetAddServiceProp,
  contractId?: contractId,
  readonly?: readOnly,
  organizationId?: organizationId,
  linkToDocsEdit?: linkToDocsEdit,
  permissionStr?: permissionStr,
  hasPreparedAddServiceReport: hasPreparedAddServiceReport
};

class AddServiceDocsEdit extends React.Component <Props> {
  setDataOnUnmount = (data) => {
    this.props.onGetAddServiceProp('files', data);
  };
  render(): React$Node {
    const contractId = this.props.readonly ? this.props.contractId : this.props.match.params.id;
    return (
      <NCard addClasses={'NCard__wide-width mb_5du'}>
        <div className="flex_jc-sb">
          <SectionHeader sectionHeaderTitle={text.addServiceDocsSubheader} addClasses={"mb_3du"}/>
          {this.props.readonly && !this.props.hasPreparedAddServiceReport ? (
            <Can do={'usePermission'} on={this.props.permissionStr}>
              <Link to={this.props.linkToDocsEdit}
                    id={addServiceAllDataPageIds.buttonEditAddServiceDocs}>
                <BtnEdit/>
              </Link>
            </Can>
          ) : null}
        </div>
        <ItemFiles itemFilesType={'addService'} contractId={contractId} readOnly={this.props.readonly}
                   setDataOnUnmount={this.setDataOnUnmount}/>
      </NCard>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetAddServiceProp: (prop, data) => dispatch({type: addServiceEditActions.ADD_SERVICE_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};
export default connect(null, mapDispatchToProps)(AddServiceDocsEdit);