//@flow
import * as React from 'react';
import type {selectOptions} from "../../../../../../../../services/flowTypes/componentFlowTypes";
import {
  expressionPartInitValue,
  operationLabelStr,
  operationTypeLabel, operationTypePlusDefValue
} from "../ReportTagsExpressions";
import text from "../../../../../../../../services/localization/text";
import NInput from "../../../../../../../../components/simple/FormComponents/NInput/NInput";
import BtnDel from "../../../../../../../../components/simple/Buttons/BtnDel/BtnDel";
import IconlessSelect from "../../../../../../../../components/simple/FormComponents/IconlessSelect/IconlessSelect";

type Props = {
  expressionPart: string,
  operationTypeSelectOptions: selectOptions,
  operationLabelSelectOptions: selectOptions,
  rowIndex: number,
  updateExpression: Function,
  finTermTagsList: Array<string>,
  finTermTagsSelectOptions: selectOptions,
  tagExpSelectOptions: selectOptions,
  deleteRow: Function,
  readonly: boolean,
  currency: string,
  finTermNumberToExclude: Array<string>
};

const getOperationLabelValue = (expressionPart) => {
  const operationLabelArr = expressionPart.match(/\bFT\d*|\*.*\/100|\b\w+\b|\bcurrency/g);

  if (operationLabelArr === null) {
    return operationLabelStr.tag;
  }

  if (operationLabelArr[0].includes(operationLabelStr.finterm)) {
    let finTermSum = expressionPart.includes(operationLabelStr.fintermSum) || (operationLabelArr[1] && operationLabelArr[1].includes(operationLabelStr.finterm));
    return finTermSum
      ? operationLabelStr.fintermSum
      : operationLabelStr.finterm;
  } else if (operationLabelArr[0].includes("*")) {
    return operationLabelStr.vat;
  } else if(operationLabelArr[0].includes(operationLabelStr.const)) {
    return operationLabelStr.const
  } else {
    return operationLabelStr.tag
  }
};
const getOperationTypeValue = (expressionPart, isForExpressionPart) => {
  if (/\*.*\/100/.test(expressionPart)) {
    return isForExpressionPart ? " * " : operationTypeLabel.vat;
  }

  const operationTypeArr = expressionPart.match(/[+\-*]/g);

  return operationTypeArr === null || /^\s*\w/.test(expressionPart)
    ? operationTypeLabel.empty
    : operationTypeArr[0] === "*"
      ? (isForExpressionPart ? " * "                           : operationTypeLabel.multiply )
      : (isForExpressionPart ? " " + operationTypeArr[0] + " " : operationTypeArr[0]         );
};
const ftTagRegExpStart = "FT\\d*(_\\w+)?_";
const getTagTypeValue = (expressionPart, finTermTagsSelectOptions) => {
  for (let i=0; i < finTermTagsSelectOptions.length; i++) {
    const regExp = new RegExp(ftTagRegExpStart + finTermTagsSelectOptions[i].value);
    if (regExp.test(expressionPart)) {
      return finTermTagsSelectOptions[i].value;
    }
  }
};
const getFinTermNumberValue = (expressionPart, tagType, isLastNumber) => {
  const regExp = new RegExp(ftTagRegExpStart + tagType, "g");
  const matchedTagArr = expressionPart.match(regExp);
  if (matchedTagArr !== null) {
    const lastElIndex = matchedTagArr.length - 1;
    return isLastNumber?
      (lastElIndex === 0 ? "" : matchedTagArr[lastElIndex].replace("FT", "").replace("_"+tagType, "") )
      : matchedTagArr[0].replace("FT", "").replace("_"+tagType, "");
  } else {
    return "";
  }
};
const getTagExpValue = (expressionPart) => {
  const matchedTagArr = expressionPart.match(/report_\w+/g);
  if (matchedTagArr !== null) {
    return matchedTagArr[0];
  } else {
    return "";
  }
};
const placeholderVatValue = "0.0";
const getVatValue = (expressionPart) => {
  const matchedTagArr = expressionPart.match(/\*\s*\d*\.?\d*\/100/g);
  if (matchedTagArr !== null) {
    return matchedTagArr[0].replace(/\*\s*/, "").replace(/\/100/, "");
  } else {
    return placeholderVatValue;
  }
};
const vatValueHandler = (vatValue) => `${vatValue}/100`;
const setVatValueForExpression = (vatValue) => {
  let transformedValue = vatValue ? vatValue : placeholderVatValue;
  transformedValue = transformedValue.replace(/,/g, '.' );
  transformedValue = transformedValue.replace(/\s/g, '' );
  transformedValue = transformedValue.replace(/[^\d.]+/g, '' );
  if ( !(/\./).test(transformedValue) ) {
    transformedValue = transformedValue + ".0";
  }
  if ( (/\.$/).test(transformedValue) ) {
    transformedValue = transformedValue + "0";
  }
  if ( (/^\./).test(transformedValue) ) {
    transformedValue = "0" + transformedValue;
  }
  return vatValueHandler(transformedValue);
};

const placeholderCurrencyValue = "0";
const getCurrencyValue = (expressionPart) => {
  const matchedTagArr = expressionPart.match(/currency\('(\d*\.?\d*)'\)/);
  if (matchedTagArr !== null && matchedTagArr[1]) {
    return matchedTagArr[1]
  } else {
    return placeholderCurrencyValue;
  }
};
const setCurrencyValueForExpression = (input) => {
  // Заменяем все запятые на точки
  const sanitizedInput = input.replace(/,/g, '.');
  // Убираем все символы, кроме цифр и точек, и оставляем только одну точку
  let transformedValue = sanitizedInput.replace(/[^0-9.]/g, '').replace(/\.(?=.*\.)|[^0-9.]/g, '');
  if (transformedValue.charAt(0) === '0' && transformedValue.length > 1) {
    transformedValue = transformedValue.slice(1);
  }
  if (transformedValue.charAt(0) === '.' && transformedValue.length > 1) {
    transformedValue = "0" + transformedValue;
  }

  return `currency('${transformedValue}')`;
};
const getNumberFromFinTermNumber = (finTermNumber:string) => {
  const trimmedFinTermNumber = finTermNumber.replace(/_\w+/, "");
  return +trimmedFinTermNumber;
};
const areFinTermNumbersConsecutive = (array, index) => {
  const currentFinTermNumber = getNumberFromFinTermNumber(array[index]);
  const nextFinTermNumber = array[index+1] ? getNumberFromFinTermNumber(array[index+1]) : null;
  return currentFinTermNumber + 1 === nextFinTermNumber;
};
const createFinTermNumberOption = (value) => {
  return {value, label: value.replace("_", " ")};
};


class ReportTagExpressionRow extends React.Component <Props> {
  handleDeleteClick = () => {this.props.deleteRow(this.props.rowIndex)};
  setToFinTermNumberSelectOptions = (finTermNumberSelectOptions, fromFinTermNumberSelectedOption) => {
    const index = finTermNumberSelectOptions.findIndex(item => item.value === fromFinTermNumberSelectedOption.value);
    const arrayForCheck = finTermNumberSelectOptions.map(item => item.value);
    if (index === - 1) {
      return [];
    } else {
      const toFinTermNumberSelectOptions = [];
      for (let i=index; i < finTermNumberSelectOptions.length; i++) {
        if (areFinTermNumbersConsecutive(arrayForCheck, i)) {
          toFinTermNumberSelectOptions.push(finTermNumberSelectOptions[i+1]);
        } else {
          break;
        }
      }
      return toFinTermNumberSelectOptions;
    }
  };
  setFinTermNumberSelectOptions = (tagType, isForFromFinTermNumber) => {
    const regExp = new RegExp(ftTagRegExpStart + tagType);
    const finTermNumbers = this.props.finTermTagsList.filter(item => regExp.test(item));
    const finTermNumbersTrimmed = finTermNumbers.map(item => item.replace("FT", "").replace("_"+tagType, ""));
    const finTermNumberToExclude = ["ratePercentDec", "rateAbsAmount"].includes(tagType) ? [] : this.props.finTermNumberToExclude;
    const finTermNumbersNotExcluded = finTermNumbersTrimmed.filter(item => !finTermNumberToExclude.includes(item));
    let prelastOptionIndex = -1;
    const filteredFinTermNumbers = finTermNumbersNotExcluded.filter( (item, i, array) => {
      const isTrue = areFinTermNumbersConsecutive(array, i);
      prelastOptionIndex = isTrue ? i : prelastOptionIndex;
      return isTrue;
    });
    // const lastOption = prelastOptionIndex !== -1 ?
    //   createFinTermNumberOption(finTermNumbersNotExcluded[prelastOptionIndex+1]) : null;
    return isForFromFinTermNumber ?
      {fromFinTermNumberSelectOptions: filteredFinTermNumbers.map(item => createFinTermNumberOption(item))}
      : {finTermNumberSelectOptions: finTermNumbersNotExcluded.map(item => createFinTermNumberOption(item))}
  };
  onFromFinTermNumberSelect = (selectedOption) => {
    this.handleFinTermNumberSelect(selectedOption, operationLabelStr.fintermSum);
  };
  handleToFinTermNumberSelect = (selectedOption, fromFinTermNumber, toFinTermNumberSelectOptions) => {
    const tagType = getTagTypeValue(this.props.expressionPart, this.props.finTermTagsSelectOptions);
    if (selectedOption.value !== getFinTermNumberValue(this.props.expressionPart, tagType, true)) {
      let expressionPart = getOperationTypeValue(this.props.expressionPart, true)
        + " FT" + fromFinTermNumber + "_" + tagType;
      const selectedOptionIndex = toFinTermNumberSelectOptions.findIndex(option => option.value === selectedOption.value);
      for (let i=0; i < selectedOptionIndex+1; i++) {
        expressionPart = expressionPart + " + FT" + toFinTermNumberSelectOptions[i].value + "_" + tagType;
      }
      this.props.updateExpression(this.props.rowIndex, expressionPart);
    }
  };
  onFinTermNumberSelect = (selectedOption) => {
    this.handleFinTermNumberSelect(selectedOption, " FT");
  };
  handleFinTermNumberSelect = (selectedOption, operationLabel) => {
    const tagType = getTagTypeValue(this.props.expressionPart, this.props.finTermTagsSelectOptions);
    if (selectedOption.value !== getFinTermNumberValue(this.props.expressionPart, tagType)) {
      const expressionPart = getOperationTypeValue(this.props.expressionPart, true)
        + operationLabel + selectedOption.value + "_" + tagType;
      this.props.updateExpression(this.props.rowIndex, expressionPart);
    }
  };
  handleTagExpSelect = (selectedOption) => {
    if (selectedOption.value !== getTagExpValue(this.props.expressionPart)) {
      const expressionPart = getOperationTypeValue(this.props.expressionPart, true) + selectedOption.value;
      this.props.updateExpression(this.props.rowIndex, expressionPart);
    }
  };
  handleTagTypeSelect = (selectedOption) => {
    if (selectedOption.value !== getTagTypeValue(this.props.expressionPart, this.props.finTermTagsSelectOptions)) {
      const operationLabel = getOperationLabelValue(this.props.expressionPart);
      const expressionPart = getOperationTypeValue(this.props.expressionPart, true)
        + operationLabel + "_" + selectedOption.value;
      this.props.updateExpression(this.props.rowIndex, expressionPart);
    }
  };
  handleOperationLabelSelect = (selectedOption) => {
    if (selectedOption.value !== getOperationLabelValue(this.props.expressionPart)) {
      const expressionPart = getOperationTypeValue(this.props.expressionPart, true) + selectedOption.value;
      this.props.updateExpression(this.props.rowIndex, expressionPart);
    }
  };
  handleOperationTypeSelect = (selectedOption) => {
    if (selectedOption.value !== getOperationTypeValue(this.props.expressionPart)) {
      let expressionPart = "";
      switch (selectedOption.value) {
        case operationTypeLabel.empty:
          expressionPart = expressionPartInitValue;
          break;
        case operationTypeLabel.plus:
          expressionPart = operationTypePlusDefValue;
          break;
        case operationTypeLabel.minus:
          expressionPart = " - " + expressionPartInitValue;
          break;
        case operationTypeLabel.multiply:
          expressionPart = " * " + expressionPartInitValue;
          break;
        case operationTypeLabel.vat:
          expressionPart = " * 0.0/100";
          break;
        default:
          expressionPart = expressionPartInitValue;
      }
      this.props.updateExpression(this.props.rowIndex, expressionPart);
    }
  };
  handleVatValueChange = (e:SyntheticEvent<HTMLInputElement>) => {
    const expressionPart =
      getOperationTypeValue(this.props.expressionPart, true)
      + getTagExpValue(this.props.expressionPart)
      + setVatValueForExpression(e.currentTarget.value);
    this.props.updateExpression(this.props.rowIndex, expressionPart);
  };
  handleConstValueChange = (e:SyntheticEvent<HTMLInputElement>) => {
    const expressionPart =
      getOperationTypeValue(this.props.expressionPart, true)
      + getTagExpValue(this.props.expressionPart)
      + setCurrencyValueForExpression(e.currentTarget.value);
    this.props.updateExpression(this.props.rowIndex, expressionPart);
  };
  defineRowParts = () => {
    const tagType = getTagTypeValue(this.props.expressionPart, this.props.finTermTagsSelectOptions);
    return {
      number: this.props.rowIndex+1,
      operationType: getOperationTypeValue(this.props.expressionPart),
      operationLabel: getOperationLabelValue(this.props.expressionPart),
      tagType,
      finTermNumber: getFinTermNumberValue(this.props.expressionPart, tagType),
      finTermNumbers: {
        from: getFinTermNumberValue(this.props.expressionPart, tagType),
        to: getFinTermNumberValue(this.props.expressionPart, tagType, true)
      },
      tagExp: getTagExpValue(this.props.expressionPart),
      vatValue: getVatValue(this.props.expressionPart),
      constValue: getCurrencyValue(this.props.expressionPart)
    };
  };
  render() {
    const {number, operationType, operationLabel, tagType, finTermNumber, finTermNumbers, tagExp, vatValue, constValue} = this.defineRowParts();
    const operationTypeSelectOptions = this.props.rowIndex === 0 ?
      this.props.operationTypeSelectOptions.filter(item => item.value === operationTypeLabel.empty)
      : this.props.operationTypeSelectOptions.filter(item => item.value !== operationTypeLabel.empty);
    const operationTypeSelectedOption = operationTypeSelectOptions.find(item => item.value === operationType);
    const operationLabelSelectOptions = operationType.includes(operationTypeLabel.minus)
    || operationType.includes(operationTypeLabel.multiply) ?
      this.props.operationLabelSelectOptions.filter(item => item.value !== operationLabelStr.fintermSum)
      : this.props.operationLabelSelectOptions;
    let operationLabelElement = this.props.currency && operationLabel.indexOf(operationLabelStr.const) > -1? this.props.currency: text.operationLabel[operationLabel];
    const operationLabelSelectedOption = {value: operationLabel, label: operationLabelElement};
    const tagTypeSelectedOption = tagType ? {value: tagType, label: tagType} : null;
    const {finTermNumberSelectOptions} = this.setFinTermNumberSelectOptions(tagType);
    const finTermNumberSelectedOption = finTermNumber ? createFinTermNumberOption(finTermNumber) : null;
    const {fromFinTermNumberSelectOptions} = this.setFinTermNumberSelectOptions(tagType, true);
    const fromFinTermNumberSelectedOption = finTermNumbers.from ? createFinTermNumberOption(finTermNumbers.from) : null;
    const toFinTermNumberSelectOptions = finTermNumbers.from ?
      this.setToFinTermNumberSelectOptions(finTermNumberSelectOptions,fromFinTermNumberSelectedOption) : [];
    const toFinTermNumberSelectedOption = finTermNumbers.to ? createFinTermNumberOption(finTermNumbers.to) : null;
    const onToFinTermNumberChange = (selectedOption) => this.handleToFinTermNumberSelect(selectedOption, finTermNumbers.from, toFinTermNumberSelectOptions);
    const tagExpSelectedOption = tagExp ? {value: tagExp, label: tagExp} : null;
    const disabledClassName = this.props.readonly ? " isDisabled" : "";
    return (
      <div className="ReportTagExpressionRow">
        <div className="ReportTagExpressionRow__number">{number + "."}</div>
        <div className={"ReportTagExpressionRow__DandD-btn" + disabledClassName}>
          <div className="DnD-ico-container"/>
        </div>
        <div className="ReportTagExpressionRow__operationType">
          <IconlessSelect value={operationTypeSelectedOption} onChange={this.handleOperationTypeSelect}
                          options={operationTypeSelectOptions} isSingleValueMainAccentColor={true}
                          isDisabled={this.props.readonly}/>
        </div>

        <div className="ReportTagExpressionRow__label" >
          {operationLabel === operationLabelStr.vat ? (
            <div className="ReportTagExpressionRow__labelValue" >{operationLabel}</div>
          ) : (
            <IconlessSelect value={operationLabelSelectedOption} onChange={this.handleOperationLabelSelect}
                     options={operationLabelSelectOptions} isSingleValueBold12Black={true}
                            isDisabled={this.props.readonly}/>
          )}
        </div>

        {this.createControlPart(operationType, constValue, vatValue, operationLabel, tagExpSelectedOption, tagTypeSelectedOption, fromFinTermNumberSelectedOption, fromFinTermNumberSelectOptions, finTermNumbers, toFinTermNumberSelectedOption, onToFinTermNumberChange, toFinTermNumberSelectOptions, finTermNumberSelectedOption, finTermNumberSelectOptions)}

        <div className="ReportTagExpressionRow__delBtn">
          {this.props.readonly ? null : (
            <BtnDel isTagExpressionMode={true} onClick={this.handleDeleteClick}/>
          )}
        </div>
      </div>
    );
  }

  createControlPart(operationType, constValue, vatValue, operationLabel, tagExpSelectedOption, tagTypeSelectedOption, fromFinTermNumberSelectedOption, fromFinTermNumberSelectOptions, finTermNumbers, toFinTermNumberSelectedOption, onToFinTermNumberChange, toFinTermNumberSelectOptions, finTermNumberSelectedOption, finTermNumberSelectOptions) {

    if (operationType === "%") {
      return <div className="ReportTagExpressionRow__VAT-input">
        <NInput value={vatValue} onChange={this.handleVatValueChange} readOnly={this.props.readonly}/>
      </div>
    }

    if (operationLabel === operationLabelStr.const) {
      return <div className="ReportTagExpressionRow__number-input">
        <NInput value={constValue} onChange={this.handleConstValueChange} readOnly={this.props.readonly}/>
      </div>
    }

    if (operationLabel === operationLabelStr.tag) {
      return <div className="ReportTagExpressionRow__tagExpSelect">
        <IconlessSelect value={tagExpSelectedOption} onChange={this.handleTagExpSelect}
                        options={this.props.tagExpSelectOptions} placeholder={text.selectTagPlaceholder}
                        isDisabled={this.props.readonly}/>
      </div>
    } else {
      return <>
        <div className="ReportTagExpressionRow__tagType">
          <IconlessSelect value={tagTypeSelectedOption} onChange={this.handleTagTypeSelect}
                          options={this.props.finTermTagsSelectOptions} placeholder={text.selectTagPlaceholder}
                          isDisabled={this.props.readonly}/>
        </div>
        {operationLabel === operationLabelStr.fintermSum ? (
          <div className="  ReportTagExpressionRow__finTermsNumbers flex">
            <div className="ReportTagExpressionRow__finTermsNumbersFrom">
              <IconlessSelect value={fromFinTermNumberSelectedOption} onChange={this.onFromFinTermNumberSelect}
                              options={fromFinTermNumberSelectOptions}
                              placeholder={text.selectReportTagExpressionFinTermPlaceholder}
                              isDisabled={this.props.readonly}/>
            </div>
            <div className="ReportTagExpressionRow__finTermsNumbersArrow"></div>
            <div className="ReportTagExpressionRow__finTermsNumbersTo">
              {finTermNumbers.from ? (
                <IconlessSelect value={toFinTermNumberSelectedOption} onChange={onToFinTermNumberChange}
                                options={toFinTermNumberSelectOptions}
                                placeholder={text.selectReportTagExpressionFinTermPlaceholder}
                                isDisabled={this.props.readonly}/>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="ReportTagExpressionRow__finTermsNumber">
            <IconlessSelect value={finTermNumberSelectedOption} onChange={this.onFinTermNumberSelect}
                            options={finTermNumberSelectOptions}
                            placeholder={text.selectReportTagExpressionFinTermPlaceholder}
                            isDisabled={this.props.readonly}/>
          </div>
        )}
      </>
    }
  }
}
export default ReportTagExpressionRow;