//@flow
import * as React from 'react';
import SecText from "../../../../../../components/simple/TextComponents/SecText/SecText";
import text from "../../../../../../services/localization/text";
import ability from "../../../../../../services/permissions/permissions";
import {Link} from "react-router-dom";
import Text1 from "../../../../../../components/simple/TextComponents/Text1/Text1";
import TextOverflowHandler
  from "../../../../../../components/simple/Containers/TextOverflowHandler/TextOverflowHandler";
import concatIdName from "../../../../../../utils/concatIdName";
import getNameOrDefName from "../../../../../../utils/getNameOrDefName";
import type {
  actTemplateId,
  actTemplateName,
  contractId,
  organizationId
} from "../../../../../../services/flowTypes/dataFlowTypes";
import type {isLabelHidden, parentComponentType} from "../../../../../../services/flowTypes/componentFlowTypes";
import NLabel from "../../../../../../components/simple/FormComponents/NLabel/NLabel";
import {actTemplatePath, contrPathView, orgPath} from "../../../../../../services/urlStrings/urlStrings";
import {permissionsStrValues} from "../../../../../../configData/permissionsStrValues";

type Props = {
  organizationId: organizationId,
  contractId: contractId,
  actTemplateId: actTemplateId,
  actTemplateName: actTemplateName,
  parentComponentType: parentComponentType,
  isLabelHidden?: isLabelHidden
};

const ReportActTemplateLink = (props: Props) => {
  const linkToActTemplate = orgPath(props.organizationId) + contrPathView(props.contractId)
    + actTemplatePath(props.actTemplateId);
  const ActTemplateTitle = (
    <TextOverflowHandler>
      {concatIdName(props.actTemplateId,
        getNameOrDefName(props.actTemplateName, text.actTemplate))}
    </TextOverflowHandler>
  );
  const Label = () => {
    switch (props.parentComponentType) {
      case 'card':
        return (<SecText addClasses={'SecText_lh16 mb_1du'}>{text.actTemplate}</SecText>);
      case 'page':
        return (<NLabel addClasses={'mb_half-du'}>{text.actTemplate}</NLabel>);
      default:
        console.error('props.parentComponentType does not correspond switch cases!');
    }
  };
  const reportActTemplateLinkIcon = <div className="ReportActTemplateLink__icon"/>;
  return (
    <>
      {props.isLabelHidden ? null : Label()}
      {ability.can('usePermission', permissionsStrValues.actTemplate.view) ? (
        <Link to={linkToActTemplate} >
          <Text1 addClasses={'flex Text1_report-contract-link-color'}>{reportActTemplateLinkIcon}{ActTemplateTitle}</Text1>
        </Link>
      ) : (
        <Text1 addClasses={'flex Text1_report-contract-link-color'}>{reportActTemplateLinkIcon}{ActTemplateTitle}</Text1>
      )}
    </>
  );
};

export default ReportActTemplateLink;    