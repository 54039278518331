//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id, inputRefType, label} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './InputFile.css';
import type {isMultiple, onInputFileChange} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  id: id,
  addClasses?: addClasses,
  onInputFileChange: onInputFileChange,
  label: label,
  isMultiple?: isMultiple
};
//CAUTION!!!: button can't be used as wrapper, because of default onClick props
const InputFile = React.forwardRef<Props, HTMLInputElement>((props:Props, ref:inputRefType) => {
  return (
    <div className={baseClass("InputFile", props.addClasses)}>
      <input id={props.id} className="InputFile__input" name="file"
             onChange={props.onInputFileChange} type="file" ref={ref} multiple={props.isMultiple}/>
      <label htmlFor={props.id} className="InputFile__label">
        {props.label}
      </label>
    </div>
  );
});

export default InputFile;