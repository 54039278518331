//@flow
import * as React from 'react';
import type {
  layoutContent,
  leftSideBarContent, searchButton
} from "../../../services/flowTypes/componentFlowTypes";
import './BaseLayout.css';
import LogoContainer from "../LogoContainer/LogoContainer";
import UserBar from "./UserBar/UserBar";
import {useState} from "react";

type Props = {
  leftSideBarContent: leftSideBarContent,
  layoutContent: layoutContent,
  searchButton?: searchButton
};

const BaseLayout = (props: Props) => {
  const [layoutStyle, setLayoutStyle] = useState(localStorage.getItem("Layout"))
  return (
    <div className={"Layout" + (layoutStyle === null ? "" : " " + layoutStyle)} id="Layout">
      <div className={"LeftSideBar"}>
        <div>
          <div className="LeftSideBar__top">
            <LogoContainer/>
          </div>
          <div className={"MainMenuItem__bottom-line"}></div>
          <div className="LefSideBar__toggle_view" onClick={()=>{
            if (layoutStyle) {
              localStorage.removeItem("Layout")
              setLayoutStyle(null)
            } else {
              localStorage.setItem("Layout", "Layout__Compact")
              setLayoutStyle("Layout__Compact")
            }
          }}>
          </div>
          <div className="LefSideBar__content-container">
            {props.leftSideBarContent}
          </div>
          {props.searchButton}
        </div>
        <UserBar/>
      </div>
      <div className={"Content"}>
        {props.layoutContent}
      </div>
    </div>
  );
};
export default BaseLayout;