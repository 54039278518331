//@flow
import organizationMainDataActions from './organizationMainDataActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {organization} from "../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = {
  name: '',
  organizationCode: '',
  locale: '',
  linkedAgentId: '',
  sourceNames: [],
  currencyCodes: [],
  contractTypesList: [],
  agentLinkedTypeList: [],
  contractLinkedTypeList: [],
  addServiceTypeList: [],
  notedTerminalTypeList: []
};

const organizationMainDataReducer = (state:organization = initialState, action:reducerAction) => {
  switch (action.type) {
    case organizationMainDataActions.ORGANIZATION_MAIN_DATA_SET:
      return action.value;
    case organizationMainDataActions.ORGANIZATION_MAIN_DATA_INIT:
      return initialState;
    case organizationMainDataActions.ORGANIZATION_MAIN_DATA_PROP_SET:
      return {
        ...state,
        [action.value.prop]: action.value.data
      }
    default:
      return state;
  }
};

export default organizationMainDataReducer;