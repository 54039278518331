//@flow
import * as React from 'react';
import {connect} from "react-redux";
import Can from "../../../../../../../services/permissions/Can";
import type {
  errorObject,
  history,
  isReadyToRender,
  modalIsShown
} from "../../../../../../../services/flowTypes/appFlowTypes";
import type {
  contractsList,
  contractId, contractStatus
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import contractLinkedContractsActions from "../../../../../../../store/organization/item/contractLinkedContracts/contractLinkedContractsActions";
import api from "../../../../../../../services/apiInstance/api";
import text from "../../../../../../../services/localization/text";
import {onGetContractLinkedContracts, onGetContractAgents} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import {contractLinkedContractsPageIds} from "../../../../../../../tests/testIds";
import AddItemsModal from "../../../../../../../components/composed/AddItemsModal/AddItemsModal";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";
import BtnNewItem from "../../../../../../../components/simple/Buttons/BtnNewItem/BtnNewItem";

type Props = {
  contractId: contractId,
  onGetContractLinkedContracts: onGetContractLinkedContracts,
  contractsForLinking: contractsList,
  onGetContractAgents: onGetContractAgents,
  history: history,
  status: contractStatus
};
type State = {
  modalIsShown: modalIsShown,
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};

class ButtonAddContracts extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false,
      errorObject: null,
      isReadyToRender: true
    };
  }
  showModal = () => {
    this.setState({
      modalIsShown: true
    });
  };
  hideModal = () => {
    this.setState({
      isReadyToRender: true,
      modalIsShown: false
    });
  };
  getCheckedListItems = (listItemsIds) => {
    this.setState({isReadyToRender: false});
    api.addContractLinkedContracts(this.props.contractId, listItemsIds, this.props.status)
      .then(response => {
        this.props.onGetContractLinkedContracts(response.data);
        this.hideModal();
      })
      .catch(error => {
        this.hideModal();
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render() {
    const linkedItemsIds = this.props.contractLinkedContracts ?
      this.props.contractLinkedContracts.map(item => item.id) : [];
    return (
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <Can do={'usePermission'} on={permissionsStrValues.contractLinkedContract.create}>
          <BtnNewItem id={contractLinkedContractsPageIds.buttonAddLinkedContract} onClick={this.showModal}>{text.btnAdd}</BtnNewItem>
        </Can>
        {this.state.modalIsShown ? (
          <AddItemsModal
                          getCheckedListItems={this.getCheckedListItems} linkedItemsIds={linkedItemsIds}
                          history={this.props.history} category={'contracts'} searchPropName={'contractNo'}
                          hideModal={this.hideModal} modalTitle={text.contractLinkedContractsModalTitle}/>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    status: state.organization.linkedItemsRequestParams.hasOwnProperty('status') ?
      state.organization.linkedItemsRequestParams.status : null,
    contractLinkedContracts: state.organization.item.contractLinkedContracts
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetContractLinkedContracts: (contractLinkedContracts) => dispatch({
      type: contractLinkedContractsActions.CONTRACT_LINKED_CONTRACTS_SET, value: contractLinkedContracts})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ButtonAddContracts);