//@flow
import * as React from 'react';
import {connect} from "react-redux";
import Heading4 from "../../../../../../../../../components/simple/TextComponents/Heading4/Heading4";
import templateParamsActions
  from "../../../../../../../../../store/organization/item/templateParams/templateParamsActions";
import type {
  onUpdateTemplateParamsTextTag
} from "../../../../../../../../../services/flowTypes/propFnFlowTypes";
import Textarea from "../../../../../../../../../components/simple/FormComponents/Textarea/Textarea";
import type {actTemplateParam, groupFinTermId} from "../../../../../../../../../services/flowTypes/dataFlowTypes";
import {actTemplatesEditPageIds} from "../../../../../../../../../tests/testIds";

type Props = {
  readonly?: boolean,
  onUpdateTemplateParamsTextTag: onUpdateTemplateParamsTextTag,
  groupFinTermParam: actTemplateParam,
  groupFinTermId: groupFinTermId
};

class ManualInputTag extends React.Component <Props> {
  handleInputChange = (e:SyntheticEvent<HTMLInputElement>) => {
    const actionData = {
      groupFinTermId: this.props.groupFinTermId,
      textValue: e.currentTarget.value
    };
    this.props.onUpdateTemplateParamsTextTag(actionData);
  };
  render() {
    const manualTextTagValue = this.props.groupFinTermParam.textValue
    && this.props.groupFinTermParam.textValue.length ?
      this.props.groupFinTermParam.textValue : 'N/A';

    return this.props.readonly ? (
        <Heading4 addClasses={'Heading4_lh16 mt_1du'}>{manualTextTagValue}</Heading4>
      ) : (
        <Textarea id={actTemplatesEditPageIds.textareaManualInput(this.props.groupFinTermId)}
          value={this.props.groupFinTermParam.textValue} onChange={this.handleInputChange}
        />
      )
    ;
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onUpdateTemplateParamsTextTag: (actionData) => dispatch({
      type: templateParamsActions.TEMPLATE_PARAMS_TEXT_TAG_UPDATE,
      value: actionData})
  };
};

export default connect(null, mapDispatchToProps)(ManualInputTag);