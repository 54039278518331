//@flow
import * as React from 'react';
import type {
  bottomComponent,
  handledGroupFinTerm, isSelectOptionMode,
  rightTopComponent, warningComponent
} from "../../../services/flowTypes/componentFlowTypes";
import GroupFinTermCardInfoRow from "../GroupFinTermCardInfoRow/GroupFinTermCardInfoRow";
import {baseClass} from "../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import ability from "../../../services/permissions/permissions";
import {Link} from "react-router-dom";
import TextOverflowHandler from "../../simple/Containers/TextOverflowHandler/TextOverflowHandler";
import Heading4 from "../../simple/TextComponents/Heading4/Heading4";
import NDropDownDetails from "../../simple/Containers/NDropDownDetails/NDropDownDetails";
import './GroupFinTermBlock.css';

type Props = {
  rightTopComponent?: rightTopComponent,
  addClasses?: addClasses,
  bottomComponent?: bottomComponent,
  warningComponent?: warningComponent,
  handledGroupFinTerm: handledGroupFinTerm,
  isSelectOptionMode?: isSelectOptionMode
};
//TODO: add test
const GroupFinTermBlock = (props: Props) => {
  return (
    <div className={baseClass("GroupFinTermBlock", props.addClasses)}>
      <div className="GroupFinTermBlock__section">
        <div className="GroupFinTermBlock__top-row">
          {ability.can('usePermission', props.handledGroupFinTerm.permittedActionObj.view) && !props.isSelectOptionMode ? (
            <>
              <Link id={props.handledGroupFinTerm.linkToGroupFinTermId}
                    to={props.handledGroupFinTerm.linkTo}>
                <Heading4 addClasses={'Heading4_link-color mb_1du w_half-card'}>
                  <TextOverflowHandler addClasses={'TextOverflowHandler_mw-100'}>
                    {props.handledGroupFinTerm.groupFinTermIdName}
                  </TextOverflowHandler>
                </Heading4>
              </Link>
            </>
          ) : (
            <Heading4 addClasses={'mb_1du w_half-card'}>{props.handledGroupFinTerm.groupFinTermIdName}</Heading4>
          )}
          {props.rightTopComponent}
        </div>
        <GroupFinTermCardInfoRow handledGroupFinTerm={props.handledGroupFinTerm}
                                 addClasses={''}
        />
        {props.warningComponent ? props.warningComponent : null}
      </div>
      {props.isSelectOptionMode ? null : (
        <NDropDownDetails addClasses={'mtb_2px'}
                          detailsSummary={props.handledGroupFinTerm.groupFinTermTypeLabel}
                          detailsOptions={props.handledGroupFinTerm.groupFinTermCardParamRows}
        />
      )}
      {props.bottomComponent ? (
        <div className="GroupFinTermBlock__section">
          {props.bottomComponent}
        </div>
      ) : null}
    </div>
  );
};

export default GroupFinTermBlock;    