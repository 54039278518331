//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './DocumentIndexStatusIcon.css';
import type {indexStatus, indexStatusMessage} from "../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../services/localization/text";
import ButtonTooltipContainer from "../../Containers/ButtonTooltipContainer/ButtonTooltipContainer";

type Props = {
  id?: id,
  addClasses?: addClasses,
  indexStatus: indexStatus,
  indexStatusMessage?: indexStatusMessage
};

const DocumentIndexStatusIcon = (props: Props) => {
  const indexStatusTooltip = text.documentIndexStatusTooltip[props.indexStatus];
  const indexStatusMessageTooltip = props.indexStatusMessage ?  "\n IndexStatusMessage: " + props.indexStatusMessage
  : "";
  const tooltipContent = indexStatusTooltip + indexStatusMessageTooltip;
  const className = "DocumentIndexStatusIcon DocumentIndexStatusIcon_" + props.indexStatus;
  return (

      <div id={props.id} className={baseClass(className, props.addClasses)}>
        <ButtonTooltipContainer tooltipContent={tooltipContent} tooltipPosition={'16-from-bottom'} addClasses={'ButtonTooltipContainer_medium'}>
          <div className="DocumentIndexStatusIcon__icon"/>
        </ButtonTooltipContainer>
      </div>

  );
};

export default DocumentIndexStatusIcon;