//@flow
import * as React from 'react';
import type {spinnerIsHidden, children, errorObject, isReadyToRender} from "../../../services/flowTypes/appFlowTypes";
import Spinner from "../../simple/Modals/Spinner/Spinner";
import AlertOnError from "../../composed/Alerts/AlertOnError";

//TODO: add unit test

type Props = {
  isReadyToRender: isReadyToRender,
  children?: children,
  errorObject?: errorObject,
  spinnerIsHidden?: spinnerIsHidden
};

const RenderOnReady = (props: Props) => {
  return (
    <>
      {props.errorObject ? (
          <>
            {props.children}
            <AlertOnError errorObject={props.errorObject}/>
          </>

        )
        : (props.isReadyToRender || props.spinnerIsHidden) ? (
            props.children
          ) : (
           <Spinner/>
        )}
    </>
  );
};

export default RenderOnReady;    