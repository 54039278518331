//@flow
import * as React from 'react';
import type {buttonType, children, disabled, id, onClick} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import './BtnNewItem.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  onClick?: onClick,
  disabled?: disabled,
  buttonType?: buttonType
};

const BtnNewItem = (props: Props) => {
  const buttonType = props.buttonType ? props.buttonType : 'button';
  return (
    <button type={buttonType} id={props.id} onClick={props.onClick} disabled={props.disabled}
            className={baseClass("BtnNewItem pl-pr_2du", props.addClasses)}>
      {props.children}</button>
  );
};

export default BtnNewItem;