import * as React from 'react';
import BtnNewItem from "../../simple/Buttons/BtnNewItem/BtnNewItem";

const buttonText = "Header Button";

export const HeaderContentDefProps = {
  title: 'Header Content Title',
  info: 'Header info note',
  buttonText: buttonText,
  button: <BtnNewItem onClick={null}>{buttonText}</BtnNewItem>
};