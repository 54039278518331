//@flow
import actTemplateParamsListActions from './actTemplateParamsListActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {actTemplateParamsList} from "../../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = [];

const actTemplateParamsListReducer = (state:actTemplateParamsList = initialState, action:reducerAction) => {
  switch (action.type) {
    case actTemplateParamsListActions.ACT_TEMPLATE_PARAMS_LIST_INIT:
      return initialState;
    case actTemplateParamsListActions.ACT_TEMPLATE_PARAMS_LIST_SET:
      return action.value;
    default:
      return state;
  }
};

export default actTemplateParamsListReducer;