//@flow
import * as React from 'react';
import RenderOnReady from "../RenderOnReady/RenderOnReady";
import type {
  errorObject,
  fetchFnObj,
  isReadyToRender
} from "../../../services/flowTypes/appFlowTypes";
import apiErrorHandler from "../../../services/apiInstance/apiErrorHandler";
import connect from "react-redux/es/connect/connect";
import {onUpdateNeedFetchFlagsProp} from "../../../services/flowTypes/propFnFlowTypes";
import needFetchFlagsActions from "../../../store/needFetchFlags/needFetchFlagsActions";
import type {needFetch} from "../../../services/flowTypes/dataFlowTypes";
import type {setFn} from "../../../services/flowTypes/componentFlowTypes";

type Props = {
  fetchFnObj: fetchFnObj,
  needFetch: needFetch,
  onUpdateNeedFetchFlagsProp: onUpdateNeedFetchFlagsProp,
  setFn: setFn
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject
};

//TODO: test how many times cDU is called

class FetchPrerenderData extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null
    };
  }
  fetch = () => {
    const promise = this.props.fetchFnObj.fetchArg ?
      this.props.fetchFnObj.fetch(this.props.fetchFnObj.fetchArg) : this.props.fetchFnObj.fetch();
    promise.then(response => {
      const data = this.props.fetchFnObj.fetchingTagsList ? response.data.tags : response.data;
      this.props.setFn(this.props.fetchFnObj.propName, data);
      this.props.onUpdateNeedFetchFlagsProp(this.props.fetchFnObj.propName, false);
      this.setState({isReadyToRender: true});
    }).catch(error => {
      this.setState({
        isReadyToRender: true,
        errorObject: apiErrorHandler(error)
      });
    });
  };
  componentDidMount(){
    //call fn with requests
    this.fetch();
  }
  componentDidUpdate(prevProps){
    if (this.props.needFetch && prevProps.needFetch !== this.props.needFetch) {
      // console.log(this.props.needFetch);
      this.fetch();
    }
  }
  render() {
    // console.log('render FPD');

    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    needFetch: state.needFetchFlags[ownProps.fetchFnObj.propName]
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onUpdateNeedFetchFlagsProp: (prop, needFetch) => dispatch({type: needFetchFlagsActions.NEED_FETCH_FLAGS_PROP_UPDATE, value: {
        prop: prop,
        data: needFetch
      }})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FetchPrerenderData);