//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {checked, disabled, id, onClick, readOnly} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './CheckboxInput.css';
import Checkbox from "../Checkbox/Checkbox";
import type {checkboxLabel} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  checked: checked,
  onClick: onClick,
  checkboxLabel: checkboxLabel,
  disabled?: disabled,
  readOnlyGray?: disabled,
  readOnly?: readOnly,
  dataUiTestId?: string
};

const CheckboxInput = (props: Props) => {
  const onClick = props.disabled || props.readOnly ? null : props.onClick;
  const readOnlyClassName = props.readOnly ? " CheckboxInput_readOnly" : "";
  const disabledClassName = props.disabled ? " CheckboxInput_disabled" : "";
  const readOnlyGrayClassName = props.readOnlyGray ? " CheckboxInput_readOnly CheckboxInput_readOnly_gray" : "";
  const className = "CheckboxInput" + readOnlyClassName + disabledClassName + readOnlyGrayClassName;
  return (
    <div id={props.id} className={baseClass(className, props.addClasses)} onClick={onClick}
         data-ui-test-id={props.dataUiTestId}>
      <Checkbox checked={props.checked}/>
      <div className="CheckboxInput__label">{props.checkboxLabel}</div>
    </div>
  );
};

export default CheckboxInput;
