//@flow
import * as React from 'react';
import {connect} from "react-redux";
import ConnectedFormGroup from "../../../../../../../../../../../components/composed/ConnectedFormGroup/ConnectedFormGroup";
import type {isReadyToRender} from "../../../../../../../../../../../services/flowTypes/appFlowTypes";
import type {
  dateName,
  finTermIndex,
  propName,
  propValidationPatternsArr, propValue
} from "../../../../../../../../../../../services/flowTypes/componentFlowTypes";
import groupFinTermsValidationScheme from "../../../../../../../../../../../configData/groupFinTermsValidationScheme";
import {onUpdateFinTermProp} from "../../../../../../../../../../../services/flowTypes/propFnFlowTypes";
import finTermsEditActions from "../../../../../../../../../../../store/organization/item/finTermsEdit/finTermsEditActions";
import text from "../../../../../../../../../../../services/localization/text";
import FinTermFormGroup from "./FinTermFormGroup";
import RenderOnReady from "../../../../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import PropValueView from "../../../../../../../../../../../components/composed/PropValueView/PropValueView";
import alertsActions from "../../../../../../../../../../../store/alerts/alertsActions";
import type {onAddAlert} from "../../../../../../../../../../../services/flowTypes/propFnFlowTypes";
import alertsList from "../../../../../../../../../../../services/alertsList/alertsList";
import type {
  addAgreementId,
  addAgreementsListResponse,
  contract
} from "../../../../../../../../../../../services/flowTypes/dataFlowTypes";
import {defineFinTermDatesObj} from "./FinTermDocument";
import type {addClasses} from "../../../../../../../../../../../services/flowTypes/uiKitFlowTypes";

type Props = {
  finTermIndex: finTermIndex,
  dateName: dateName,
  onUpdateFinTermProp: onUpdateFinTermProp,
  propValue: propValue,
  readonly?: boolean,
  onAddAlert: onAddAlert,
  addAgreements: addAgreementsListResponse,
  contractMainData: contract,
  addAgreementId: addAgreementId,
  addClasses?: addClasses
};
type State = {
  isReadyToRender: isReadyToRender,
  propName: propName,
  propValidationPatternsArr: propValidationPatternsArr
};
class FinTermDate extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      propName: '',
      propValidationPatternsArr: []
    };
  }
  checkDateCompliance = (propName, propValue) => {
    const datesObj = defineFinTermDatesObj(this.props.addAgreementId, this.props.contractMainData, this.props.addAgreements);
    if (propValue.length && propValue !== datesObj[this.props.dateName]) {
      this.props.onAddAlert(alertsList.onFinTermDateIncompliance);
    }
  };
  setPropName = () => {
    return 'finTerm' + this.props.finTermIndex + this.props.dateName;
  };
  onGetPropValue = (propName, propValue) => {
    this.checkDateCompliance(propName, propValue);
    this.props.onUpdateFinTermProp(this.props.finTermIndex, this.props.dateName, propValue);
  };
  componentDidMount(){
    this.setState({
      propName: this.setPropName(),
      propValidationPatternsArr: groupFinTermsValidationScheme.finTerms[this.props.dateName],
      isReadyToRender: true
    });
  }
  render() {
    const propValidationPatternsArr = this.state.propValidationPatternsArr ? this.state.propValidationPatternsArr : null;
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={null}>
        {this.props.readonly ?
            <PropValueView label={text[this.props.dateName]} value={this.props.propValue} addClasses={"w_quarter-card mb_3du"}/> :
            <>
              {propValidationPatternsArr ? (
                    <FinTermFormGroup
                        formFieldType={'date'}
                        propName={this.state.propName}
                        propValue={this.props.propValue}
                        onGetPropValue={this.onGetPropValue}
                        label={text[this.props.dateName]}
                        propValidationPatternsArr={propValidationPatternsArr}
                        idForTest={this.props.dateName + this.props.finTermIndex}
                        addClasses={this.props.addClasses}
                    />
                ) : (
                    <ConnectedFormGroup
                        formFieldType={'date'}
                        propName={this.state.propName}
                        propValue={this.props.propValue}
                        onGetPropValue={this.onGetPropValue}
                        label={text[this.props.dateName]}
                        idForTest={this.props.dateName + this.props.finTermIndex}
                        addClasses={this.props.addClasses}
                    />
                )}
              </>
        }
      </RenderOnReady>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propValue: state.organization.item.finTermsEdit[ownProps.finTermIndex][ownProps.dateName],
    addAgreementId: state.organization.item.finTermsEdit[ownProps.finTermIndex].addAgreementId,
    addAgreements: state.organization.item.addAgreements,
    contractMainData: state.organization.item.contractMainData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateFinTermProp: (finTermIndex, finTermPropName, propValue) => dispatch({
      type: finTermsEditActions.FIN_TERM_PROP_UPDATE,
      value: { finTermIndex , finTermPropName, propValue } }),
    onAddAlert: (newAlert) => dispatch({
      type: alertsActions.ALERT_ADD, value: newAlert})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinTermDate);    