//@flow
import * as React from 'react';
import {connect} from "react-redux";
import EditModeIcon from "../../../../../../components/simple/Icons/EditModeIcon/EditModeIcon";
import NHeading2 from "../../../../../../components/simple/TextComponents/NHeading2/NHeading2";
import text from "../../../../../../services/localization/text";
import type {history, locationType, match} from "../../../../../../services/flowTypes/appFlowTypes";
import type {steps} from "../../../../../../services/flowTypes/componentFlowTypes";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import GetAddService from "../AddServiceView/GetAddService";
import addServiceEditActions from "../../../../../../store/organization/item/addServiceEdit/addServiceEditActions";
import type {onInitAddServiceEdit} from "../../../../../../services/flowTypes/propFnFlowTypes";
import ItemDataValidationSet from "../../../../../../components/service/ItemDataValidationSet";
import ConnectedItemControls from "../../../../../../components/composed/ConnectedItemControls/ConnectedItemControls";
import "./AddServcieEdit.css";

type Props = {
  match: match,
  history: history,
  location: locationType,
  onInitAddServiceEdit: onInitAddServiceEdit
};
type State = {
  steps: steps
};

export const pathnameSufficesForSteps = ['type', 'docs', 'sheets', 'confirmation'];

class AddServiceEditSubHeader extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      steps: []
    };
  }
  componentDidMount() {
    if (!this.props.match.params.addServiceId) {
      this.props.onInitAddServiceEdit();
    }
    const steps = pathnameSufficesForSteps.map(item => {
      return {name: text.addServiceEditSteps[item], pathnameSuffix: item};
    });
    this.setState({steps});
  }
  render() {
    const headingStr = this.props.match.params.addServiceId ? text.editModeStr : text.newAddServiceHeading;
    const addServiceId = this.props.match.params.addServiceId ? +this.props.match.params.addServiceId : null;
    const component = (
      <>
        <ItemDataValidationSet itemMainDataType={'addServiceEdit'} propItemId={addServiceId} setValidationFormIsSet={()=>{}}/>
        <div className="AddServiceEditSubHeader mb_4du">
          <NCard addClasses={'NCard__wide-width'}>
            <div className="flex mb_4du">
              <EditModeIcon addClasses={'mr_2du'}/>
              <NHeading2>{headingStr}</NHeading2>
            </div>
            <ConnectedItemControls isTabStepsMode={true} history={this.props.history} steps={this.state.steps}
                                   locationPathname={this.props.location.pathname}/>
          </NCard>
        </div>
      </>
    );
    return (
      this.props.match.params.addServiceId ? (
        <GetAddService match={this.props.match} history={this.props.history}>
          {component}
        </GetAddService>
      ) : <>{component}</>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitAddServiceEdit: () => dispatch({type: addServiceEditActions.ADD_SERVICE_EDIT_INIT})
  };
};
export default connect(null, mapDispatchToProps)(AddServiceEditSubHeader);