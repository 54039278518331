//@flow
import * as React from 'react';
import text from "../../../../../../../services/localization/text";
import NHeading1 from "../../../../../../../components/simple/TextComponents/NHeading1/NHeading1";
import NModal from "../../../../../../../components/simple/Modals/NModal/NModal";
import type {onModalCancelBtnClick} from "../../../../../../../services/flowTypes/componentFlowTypes";
import type {addServiceName} from "../../../../../../../services/flowTypes/dataFlowTypes";

type Props = {
  onModalCancelBtnClick: onModalCancelBtnClick,
  addServiceName: addServiceName
};

const PreventEditDeleteModal = (props: Props) => {
  return (
    <NModal modalTitle={text.deleteAddServiceModal.preventDeleteTitle}
            onModalCancelBtnClick={props.onModalCancelBtnClick}>
      <NHeading1 addClasses={'mt_3du ta_c mb_2du'}>
        {text.deleteAddServiceModal.preventDeleteText(props.addServiceName)}
      </NHeading1>
    </NModal>
  );
};

export default PreventEditDeleteModal;    