//@flow
import * as React from 'react';
import NModal from "../../../../../../../../components/simple/Modals/NModal/NModal";
import text from "../../../../../../../../services/localization/text";
import {
  cancelModal,
  getActTemplates
} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import type {
  actTemplate
} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import concatIdName from "../../../../../../../../utils/concatIdName";
import type {errorObject, isReadyToRender} from "../../../../../../../../services/flowTypes/appFlowTypes";
import apiErrorHandler from "../../../../../../../../services/apiInstance/apiErrorHandler";
import api from "../../../../../../../../services/apiInstance/api";
import RenderOnReady from "../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import getNameOrDefName from "../../../../../../../../utils/getNameOrDefName";
import NHeading2 from "../../../../../../../../components/simple/TextComponents/NHeading2/NHeading2";

type Props = {
  cancelModal: cancelModal,
  actTemplateDelete: actTemplate,
  getActTemplates: getActTemplates
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};

class DeleteActTemplateModal extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true
    };
  }
  onDeleteActTemplate = () => {
    this.setState({isReadyToRender: false});
    api.deleteActTemplate(this.props.actTemplateDelete.id)
      .then(response => {
        this.props.getActTemplates();
        this.setState({isReadyToRender: true});
        this.props.cancelModal();
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      })
  };
  render() {
    const actTemplateName = getNameOrDefName(this.props.actTemplateDelete.name, text.actTemplate);
    return (
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <NModal
          modalTitle={text.deleteActTemplateModal}
          onModalActionBtnClick={this.onDeleteActTemplate}
          onModalCancelBtnClick={this.props.cancelModal}
          modalActionBtnText={text.btnDel}
          modalCancelBtnText={text.cancel}
          isModalDocumentEdit={true}
        >
          <NHeading2 addClasses={'mt_3du mb_3du'}>
            {text.btnDel + " "}
            <span className={"NHeading2 NHeading2__span-link-color"}>{concatIdName(this.props.actTemplateDelete.id, actTemplateName)}</span>
            {"?"}
          </NHeading2>
        </NModal>
      </>
    );
  }
}

export default DeleteActTemplateModal;