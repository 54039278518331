//@flow
import * as React from 'react';
import {addAgreementsPageIds} from "../../../../../../../tests/testIds";
import Can from "../../../../../../../services/permissions/Can";
import type {modalIsShown} from "../../../../../../../services/flowTypes/appFlowTypes";
import EditAddAgreementModal from "./EditAddAgreementModal";
import type {addAgreement, contractId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import BtnEdit from "../../../../../../../components/simple/Buttons/BtnEdit/BtnEdit";
import type {
  mountDocumentsListFetcher,
  unmountDocumentsListFetcher
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import {permissionsStrValues} from "../../../../../../../configData/permissionsStrValues";

type Props = {
  addAgreement: addAgreement,
  contractId: contractId,
  addAgreementIndex: number,
  mountDocumentsListFetcher: mountDocumentsListFetcher,
  unmountDocumentsListFetcher: unmountDocumentsListFetcher
};
type State = {
  modalIsShown: modalIsShown
};

class ButtonEditAddAgreement extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false
    };
  }
  showModal = () => {
    this.setState({
      modalIsShown: true
    });
  };
  hideModal = () => {
    this.setState({
      modalIsShown: false
    });
  };
  render() {
    return (
      <>
        <Can do={'usePermission'} on={permissionsStrValues.addAgreement.update}>
          <BtnEdit id={addAgreementsPageIds.buttonEditAddAgreement(this.props.addAgreementIndex)}
                   onClick={this.showModal} isCardButtonMode={true} />
        </Can>
        {this.state.modalIsShown ? (
          <EditAddAgreementModal hideModal={this.hideModal} contractId={this.props.contractId}
                                 mountDocumentsListFetcher={this.props.mountDocumentsListFetcher}
                                 unmountDocumentsListFetcher={this.props.unmountDocumentsListFetcher}
                                 addAgreement={this.props.addAgreement}
                                 addAgreementIndex={this.props.addAgreementIndex}/>
        ) : null}
      </>
    );
  }
}

export default ButtonEditAddAgreement;