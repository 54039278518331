//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {formAction, id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './LoginPagesLayout.css';
import NCard from "../NCard/NCard";
import Logo from "../../../composed/LogoContainer/Logo/Logo";
import NHeading1 from "../../TextComponents/NHeading1/NHeading1";
import type {
  loginPageLayoutBtn,
  loginPageLayoutContent,
  loginPageLayoutSubTitle,
  loginPageLayoutTitle
} from "../../../../services/flowTypes/componentFlowTypes";
import Text1 from "../../TextComponents/Text1/Text1";
import SectionDivider from "../SectionDivider/SectionDivider";

type Props = {
  id?: id,
  addClasses?: addClasses,
  loginPageLayoutTitle: loginPageLayoutTitle,
  loginPageLayoutSubTitle: loginPageLayoutSubTitle,
  loginPageLayoutContent: loginPageLayoutContent,
  loginPageLayoutBtn: loginPageLayoutBtn,
  formAction: formAction
};

const LoginPagesLayout = (props: Props) => {
  return (
    <div id={props.id} className={baseClass("LoginPagesLayout", props.addClasses)}>
      <NCard addClasses={'p_6du_4du_5du'}>
        <div className={'mb_3du'}>
          <Logo/>
        </div>
        <NHeading1 addClasses={'mb_half-du'}>{props.loginPageLayoutTitle}</NHeading1>
        <Text1>{props.loginPageLayoutSubTitle}</Text1>
        <SectionDivider addClasses={'mt_5du mb_5du'}/>
        <form method="post" action={props.formAction}>
          {props.loginPageLayoutContent}
          <SectionDivider addClasses={'mt_5du mb_5du'}/>
          {props.loginPageLayoutBtn}
        </form>
      </NCard>
    </div>
  );
};

export default LoginPagesLayout;