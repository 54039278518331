//@flow
import * as React from 'react';
import type {children, id} from "../../../services/flowTypes/appFlowTypes";
import {getDataOnMountAndUpdate} from "../../../services/flowTypes/propFnFlowTypes";

type Props = {
  children: children,
  itemId: id,
  getDataOnMountAndUpdate: getDataOnMountAndUpdate,
  fnOnUnmount?: Function
};

class GetDataOnMountAndUpdate extends React.Component <Props> {
  componentWillUnmount(): * {
    if (this.props.fnOnUnmount) {
      this.props.fnOnUnmount();
    }
  }
  componentDidUpdate(prevProps:Props){
    if (prevProps.itemId !== this.props.itemId) {
      this.props.getDataOnMountAndUpdate();
    }
  }
  componentDidMount(){
    this.props.getDataOnMountAndUpdate();
  }
  render() {
    return (this.props.children);
  }
}

export default GetDataOnMountAndUpdate;    