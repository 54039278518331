//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {
  disabled,
  formFieldName,
  formFieldValue,
  id, onBlur, onChange, onFocus,
  placeholder
} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './Textarea.css';
import type {inputMaxLength} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  placeholder?: placeholder,
  value: formFieldValue,
  name?: formFieldName,
  onChange: onChange,
  onFocus?: onFocus,
  onBlur?: onBlur,
  inputMaxLength?: inputMaxLength,
  disabled?: disabled
};

const Textarea = (props: Props) => {
  return (
    <textarea id={props.id} className={baseClass("Textarea", props.addClasses)}
              placeholder={props.placeholder} name={props.name} value={props.value}
              onChange={props.onChange} onFocus={props.onFocus} onBlur={props.onBlur}
              maxLength={props.inputMaxLength} data-ui-test-id={props.dataUiTestId}
              disabled={props.disabled}
    />
  );
};

export default Textarea;