//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {children, disabled, formAction, id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './LoginFormContainer.css';
import Logo from "../../../composed/LogoContainer/Logo/Logo";
import type {propError} from "../../../../services/flowTypes/componentFlowTypes";
import text from "../../../../services/localization/text";
import BtnNewItem from "../../Buttons/BtnNewItem/BtnNewItem";
import {loginPages} from "../../../../tests/testIds";

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  formAction: formAction,
  propError: propError,
  submitIsDisabled: disabled
};

const LoginFormContainer = (props: Props) => {
  return (
    <div id={props.id} className={baseClass("LoginFormContainer", props.addClasses)}>
      <form method="post" action={props.formAction} className="LoginFormContainer__form">
        <div className="flex mb_5du jc_c">
          <Logo/>
        </div>
        <div className="LoginFormContainer__error" id={loginPages.loginError}>
          {props.propError}
        </div>
        {props.children}
        <BtnNewItem disabled={props.submitIsDisabled} buttonType={'submit'} addClasses={'w_100'}
                    id={loginPages.btnLogin}>{text.logIn}</BtnNewItem>
      </form>
    </div>
  );
};

export default LoginFormContainer;