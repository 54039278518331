//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {errorObject, isReadyToRender} from "../../../services/flowTypes/appFlowTypes";
import type {addServiceSheet} from "../../../services/flowTypes/dataFlowTypes";
import type {propValue, sheetIndex} from "../../../services/flowTypes/componentFlowTypes";
import api from "../../../services/apiInstance/api";
import apiErrorHandler from "../../../services/apiInstance/apiErrorHandler";
import sheetsActions from "../../../store/organization/item/sheets/sheetsActions";
import type {onUpdateSheet} from "../../../services/flowTypes/propFnFlowTypes";
import RenderOnReady from "../../service/RenderOnReady/RenderOnReady";
import debounce from 'lodash.debounce';

type Props = {
  sheet: addServiceSheet,
  sheetIndex: sheetIndex,
  propValue: propValue,
  onUpdateSheet: onUpdateSheet
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject
};

class SheetCalculationUpdater extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null
    };
    this.debounceCalculationRequest = debounce(this.updateSheetCalculation, 500);
  }
  handleAbsentAddServiceTypeId = (sheet) => {
    const calculatedItems = sheet.calculatedItems.map(item => {
      if (item.hasOwnProperty('addServiceTypeId')) {
        return {...item};
      } else {
        return {...item, addServiceTypeId: ''};
      }
    });
    return {...sheet, calculatedItems};
  };
  updateSheetCalculation = () => {
    this.setState({isReadyToRender: false});
    api.calculateAddServiceSheet(this.props.sheet)
      .then(response => {
        const handledSheet = this.handleAbsentAddServiceTypeId(response.data);
        this.props.onUpdateSheet(this.props.sheetIndex, handledSheet);
        this.setState({isReadyToRender: true});
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if (this.props.propValue !== prevProps.propValue) {
      this.debounceCalculationRequest();
    }
  }
  render() {
    return <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    sheet: state.organization.item.sheets[ownProps.sheetIndex]
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onUpdateSheet: (sheetIndex, propValue) => dispatch({type: sheetsActions.SHEET_UPDATE,
      value: {sheetIndex, propValue}})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SheetCalculationUpdater);    