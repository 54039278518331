//@flow
import * as React from 'react';
import text from "../../../../services/localization/text";
import connect from "react-redux/es/connect/connect";
import userMainDataActions from "../../../../store/user/userMainData/userMainDataActions";
import {onInitUserMainData} from "../../../../services/flowTypes/propFnFlowTypes";
import UserButtonsRoutes from "../../../../components/service/Routes/UserButtonsRoutes";
import FixedHeaderContent from "../../../../components/simple/Containers/FixedHeader/FixedHeaderContent";
import type {locationType, history} from "../../../../services/flowTypes/appFlowTypes";
import FixedSubHeader from "../../../../components/simple/Containers/FixedHeader/FixedSubHeader";

type Props = {
  onInitUserMainData: onInitUserMainData,
  location: locationType,
  history: history
};

class NewUser extends React.Component <Props> {
  componentDidMount(){
    this.props.onInitUserMainData();
  }
  render() {
    return (
      <>
        <FixedHeaderContent title={text.users} history={this.props.history} isSupLevel={true}/>
        <FixedSubHeader title={text.newUser}
                        buttons={<UserButtonsRoutes userId={null}/>}
                        history={this.props.history}/>
      </>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onInitUserMainData: () => dispatch({type: userMainDataActions.USER_MAIN_DATA_INIT})
  };
};
export default connect(null, mapDispatchToProps)(NewUser);