import {byTxFinTermParamTooltips, byVolumeParamTooltips} from "./commonStrings";

/* eslint-disable no-template-curly-in-string */


const textEn = {
    logoStart: 'Flow',
    logoEnd: 'Doc',
    admin: 'Administrator',
    lawyer: 'Legal counsel',
    accountant: 'Accountant',
    logIn: 'Log In',
    logOut: 'Log Out',

    organization: 'Organization',
    sbc: 'SBC Technhologies',

    contracts: 'Contracts',
    agents: 'Agents',
    reports: 'Reports',

    newContract: 'New contract',
    newAgent: 'New counterparty',
    newGroupFinTerm: 'New term',
    newGroupFinTerms: 'New terms',
    upload: 'Upload',
    chooseFile: 'Select a file',
    uploadDocument: 'Upload a document',
    substituteDocument: 'Substitute a document',
    newActTemplate: 'Create a template',
    contractLabel: 'Contract',
    agentLabel: 'Counterparty',
    reportLabel: 'Report',

    advancedSearchHeader: 'Advanced search',
    btnDel: 'Delete',
    btnEdit: 'Edit',
    btnAdd: 'Add',
    checkedFields: 'Fields select for addition:',
    nullify: 'Nullify',

    status: 'Status',
    validityTerm: 'Validity period',
    all: 'All',
    start: 'Start',
    end: 'End',
    startDate: 'Start',
    endDate: 'End',
    date: 'Date',
    back: 'Back',
    forward: 'Forward',
    reportStatusLabel: 'Execution status',
    reportAccountStatusLabel: 'Report status',
    linkDate: 'Link date',
    endDateNotSet: 'Not specified',
    linkDateNotSet: 'Not specified',
    linkTypeNotSet: 'Not specified',

    type: 'Type',
    number: 'Number',
    createDate: 'Date of creation',
    name: 'Name',
    signingDate: 'Date of signing',
    comment: 'Comment',
    projectName: 'Nomenclature group code (Project/Direction)',
    ignoreInExport: 'Ignore when generating the data export file',
    terminationDate: 'Date of termination',
    terminationReason: 'Reason for termination',
    agentCode: 'Counterparty code',
    tags: 'Tags',
    tagsPlaceholder: 'Input tag or select from the list',
    merchantIdPlaceholder: 'Input merchant ID',
    managerIdPlaceholder: 'Input manager ID',
    processorIdPlaceholder: 'Input processor ID',
    clearAll: 'Clear',
    addParamsTitle: 'Additional information',
    addBlock: '+ Add block',
    addField: 'Add field',
    addFieldsModalTitle: 'Field addition',
    noFieldsToAdd: 'No fields available',
    header1c: 'Parameters for 1C',
    contractCode1c: 'Contract code',
    inn1c: 'Counterparty’s TIN',
    name1c: 'Counterparty’s name',
    nomenNumber1c: 'Act nomenclature code',
    selectContractType: 'Select contract type',
    noAvailableContractTypes: 'Contract types not specified',
    reportTotalExpression: 'Total amount',
    reportTotalCountExpression: 'Completed operations count',
    reportTotalAmountExpression: 'Completed operations amount',

    dataSources: 'Data sources',
    noData: 'No data',

    save: 'Save',
    cancel: 'Cancel',

    ONCE: 'Once',
    MONTHLY: 'Monthly',
    QUARTERLY: 'Quarterly',
    ANNUAL: 'Annually',
    BY_VOLUME_SIMPLE: 'Basic volume-based rate',
    BY_VOLUME_COMPLEX: 'Tiered volume-based rate',
    BY_TX_VOLUME: 'Transactional calculation',
    BYRATE: 'Fixed percentage rate',
    BY_HOURS: 'Hourly rate',
    BY_TX_COUNT: 'Basic transaction quantity-based rate',
    BY_TX_COUNT_SIMPLE: 'Basic rate based on transaction quantity volume',
    BY_TX_COUNT_COMPLEX: 'Tiered rate based on transaction quantity volume',
    FIXED: 'Fixed amount per period',
    FIXED_NO_PERIOD: 'Fixed amount',
    MANUAL: 'Manual input',
    MIN: 'Minimum fixed amount per period',
    PNE: 'PPG (RU)',
    PNE_EU: 'PPG (EU)',
    REDMINE: 'Redmine',
    EMPTY: 'No source',
    sourceNames: {
        MANUAL: 'Manual input',
        PNE: 'PPG (RU)',
        PNE_EU: 'PPG (EU)',
        REDMINE: 'Redmine',
        EMPTY: 'No source',
    },

    mainData: 'Main data',
    addAgreements: 'Additional agreements',
    addAgreementsTitle: 'Additional agreements',
    finTerms: 'Financial terms',
    documents: 'Documents',
    actTemplates: 'Act templates',
    history: 'Revision history',
    historyTitle: 'Title history',
    relatedAgents: 'Related counterparties',
    relatedContracts: 'Related contracts',
    userHistory: 'Activity history',

    finTerm: 'Financial term',
    deleteFinTermModalTitle: 'Deletion',
    preventDeleteFinTermModalTitle: 'Deletion',
    preventDeleteFinTermModal: {
        warningContainerTitle: 'Deletion not possible!',
        warningContainerText: 'Financial term linked to the active act templates',
        modalText: 'To delete this financial term, contact the administrator'
    },
    payer: 'Payer',
    payee: 'Payee',
    payerMerchantId: 'Payer’s merchant',
    payeeMerchantId: 'Payee’s merchant',
    currency: 'Currency',
    payoutPeriod: 'Payout period',
    source: 'Data source',
    addFinTerm: '+ Add term',
    finTermTitle: 'Term by date',
    newFinTermTitle: 'New term by date',
    finTermDocument: 'Main/additional agreement',
    finTermMinRate: 'Minimum rate from the total amount',
    noDocuments: 'Documents not uploaded',
    selectDocument: 'Select document',
    ratePercent: 'Percentage',
    rateMin: 'Minimum',
    rateAbs: 'Absolute',
    rateAbsForTxTypes: 'Price',
    rateAbsForMinType: 'Minimum',
    minAmount: 'Amount starting from',
    maxAmount: 'Amount up to',
    minCount: 'Quantity starting from',
    maxCount: 'Quantity up to',
    rateHours: 'Hourly rate',
    terminalId: 'Terminal IDs',
    merchantId: 'Merchant IDs',
    gatewayId: 'Gateway IDs',
    transactionStatus: 'Transaction status',
    transactionType: 'Transaction type',
    processorId: 'Processor IDs',
    cardType: 'Card type',
    managerId: 'Manager IDs',

    projectId: 'Project ID',
    trackers: 'Tracker names',
    userIds: 'User IDs',
    usersExcluded: 'Exclude specified user IDs',
    usersExcludedValue: {
        true: 'Yes',
        false: 'No'
    },
    ignoreInExportValue: (value) => value ? 'Yes' : 'No',
    sourceParams: {
        confirmDelete: 'Delete:',
        confirmDeleteDescription: 'All data will be lost',
        pasteDateFromContractSource: 'Paste data from contract',
        pastedDateFromContractSource: 'Data pasted from contract',
        viewLabel: {
            PNE: {
                terminalId: 'Terminal IDs',
                merchantId: 'Merchant IDs',
                gatewayId: 'Gateway IDs',
                processorId: 'Processor IDs',
                transactionStatus: 'Transaction status',
                transactionType: 'Transaction type',
                cardType: 'Card type',
                managerId: 'Manager IDs',
                projectId: 'Project IDs',
                merchantIds: 'Merchant IDs',
                managerIds: 'Manager IDs',
                processorIds: 'Processor IDs',
                direction: 'Direction type',
                procIdsExcluded: 'Exclude specified processor IDs',
                mngrMercIdsExcluded: 'Exclude specified manager merchant IDs',
                mngrIdsExcluded: 'Exclude specified manager IDs',
                mercIdsExcluded: 'Exclude specified merchant IDs',
            },
            PNE_EU: {
                terminalId: 'Terminal IDs',
                merchantId: 'Merchant IDs',
                gatewayId: 'Gateway IDs',
                processorId: 'Processor IDs',
                transactionStatus: 'Transaction status',
                transactionType: 'Transaction type',
                cardType: 'Card type',
                managerId: 'Manager IDs',
                projectId: 'Project IDs',
                merchantIds: 'Merchant IDs',
                managerIds: 'Manager IDs',
                processorIds: 'Processor IDs',
                direction: 'Direction type',
                procIdsExcluded: 'Exclude specified processor IDs',
                mngrMercIdsExcluded: 'Exclude specified manager merchant IDs',
                mngrIdsExcluded: 'Exclude specified manager IDs',
                mercIdsExcluded: 'Exclude specified merchant IDs',
            },
            REDMINE: {
                projectId: 'Project ID',
                trackers: 'Tracker names',
                userIds: 'User IDs',
                usersExcluded: 'Exclude specified user IDs',
            }
        },
        editLabel: {
            PNE: {
                terminalId: 'Terminal IDs (separated by commas)',
                merchantId: 'Merchant IDs (separated by commas)',
                merchantIds: 'Merchant IDs (separated by commas)',
                gatewayId: 'Gateway IDs (separated by commas)',
                processorId: 'Processor IDs (separated by commas)',
                processorIds: 'Processor IDs (separated by commas)',
                transactionStatus: 'Transaction status',
                transactionType: 'Transaction type',
                cardType: 'Card type',
                managerId: 'Manager IDs (separated by commas)',
                managerIds: 'Manager IDs (separated by commas)',
                projectId: 'Project IDs (separated by commas)',
                projectIds: 'Project IDs (separated by commas)',
                direction: 'Direction type',
                procIdsExcluded: 'Exclude specified processor IDs',
                mngrMercIdsExcluded: 'Exclude specified manager merchant IDs',
                mngrIdsExcluded: 'Exclude specified manager IDs',
                mercIdsExcluded: 'Exclude specified merchant IDs',
            },
            PNE_EU: {
                terminalId: 'Terminal IDs (separated by commas)',
                merchantId: 'Merchant IDs (separated by commas)',
                merchantIds: 'Merchant IDs (separated by commas)',
                gatewayId: 'Gateway IDs (separated by commas)',
                processorId: 'Processor IDs (separated by commas)',
                processorIds: 'Processor IDs (separated by commas)',
                transactionStatus: 'Transaction status',
                transactionType: 'Transaction type',
                cardType: 'Card type',
                managerId: 'Manager IDs (separated by commas)',
                managerIds: 'Manager IDs (separated by commas)',
                projectId: 'Project IDs (separated by commas)',
                projectIds: 'Project IDs (separated by commas)',
                direction: 'Direction type',
                procIdsExcluded: 'Exclude specified processor IDs',
                mngrMercIdsExcluded: 'Exclude specified manager merchant IDs',
                mngrIdsExcluded: 'Exclude specified manager IDs',
                mercIdsExcluded: 'Exclude specified merchant IDs',
            },
            REDMINE: {
                projectId: 'Project ID',
                trackers: 'Tracker names (separated by commas)',
                userIds: 'User IDs (separated by commas)',
                usersExcluded: 'Exclude specified user IDs',
            }
        }
    },
    hours: 'Number of hours',
    select: {
        placeholder: {
            transactionType: 'Select transaction type',
            transactionStatus: 'Select transaction status',
            cardType: 'Select card type',
            payer: 'Select payer',
            payee: 'Select payee',
            payerMerchant: 'Select payer’s merchant',
            payeeMerchant: 'Select payee’s merchant',
            source: 'Select data source',
            type: 'Select term type',
            currency: 'Select currency',
            customFunction: 'Select custom function',
            direction: 'Select direction type',
            merchantIds: 'Input merchant IDs',
            managerIds: 'Input manager IDs',
            processorIds: 'Input processor IDs',
        }
    },
    terms: 'Terms',
    finTermDocumentTooltip: 'Select from the list of downloaded contract documents the one on which the financial term is based',
    hour: 'Hour',
    relatedAgentsModalTitle: 'Link counterparts to the contract',
    contractLinkedContractsModalTitle: 'Link contracts to the contract',

    documentsModalTitle: 'Document upload',
    documentIsUploaded: 'Uploaded',
    prepositionAtTime: 'At',
    description: 'Description',
    deleteDocumentModalTitle: 'Deletion',
    volumeLimit: 'File uploads of up to 20 MB are allowed.',
    allowedDocTypes: 'Supported file formats:',
    allowedDocTypesList: 'TXT, PDF , XLSX, DOCX.',

    generateReport: 'Generate an act',
    wasCreated: 'Created',
    wasChanged: 'Changed',
    baseDoc: 'Template',
    addendumTemplateFileLabel: 'App template',
    deleteActTemplateModal: 'Deletion',
    generateReportModalTitle: 'Report generation',
    generateReportModalSubTitle: 'Select a period to generate the report',
    btnCreate: 'Create',
    btnProceed: 'Proceed',
    month: 'Month',
    year: 'Year',
    actTemplate: 'Act template',
    newActTemplateTitle: 'New act template',
    fileWithTags1: 'The system',
    fileWithTags2: 'will automatically replace',
    fileWithTags3: ' them with relevant contract details and counterparts linked to it.',
    fileWithTagsLink: 'File with tags.xlsx',
    finTermsExplanation: 'Select or add financial terms to generate a list of tags required to calculate the total amount for the selected period. Paste them into the template.',
    selectConditions: 'Select terms',
    allConditionsAreSelected: 'No terms',
    details: 'Details',
    uploadActTemplateFile1: 'Download your ',
    uploadActTemplateFile2: 'act template',
    uploadActTemplateFile2addendum: 'app template',
    uploadActTemplateFile3: ' that uses special characters - ',
    uploadActTemplateFile4: 'tags',
    uploadAddendumTemplateFileText: 'To upload a template, switch to editing mode.',
    replaceFile: 'Replace file',
    chooseActTemplateDoc: 'Select act template',
    chooseAddendumTemplateFile: 'Select app template',

    sorting: {
        createDate: 'Date of creation',
            startDate: 'Date of conclusion',
            contractNo: 'Contract number',
            id: {
            contracts: 'Contract ID',
                agents: 'Counterparty ID'
        },
        name: 'Name'
    },
    contract: {
        status: {
            ACTIVE: 'Active',
                TERMINATED: 'Terminated',
        },
        linkType: 'Link type',
        editLinkedItem: 'Edit linked contract',
        isCheckedAndVerified: 'Changes in financial terms verified'
    },
    addAgreement: {
        status: {
            ACTIVE: 'Active',
                TERMINATED: 'Terminated',
        }
    },
    agent: {
        status: {
            ACTIVE: 'Active',
                INACTIVE: 'Inactive',
        },
        editLinkedItem: 'Edit linked counterparty',
        ignoreGates: "Ignore gate parameters"
    },
    addService: {
        status: {
            DRAFT: 'Draft',
                UPLOADED: 'Uploaded',
                ACTIVE: 'Active',
                INACTIVE: 'Inactive',
        }
    },

    search: {
        placeholder: {
            contracts: 'Search contract by name and tags',
                agents: 'Search counterparty by name and tags',
                general: 'Search'
        },
        totalCount: 'Found'
    },

    emptyListTitle:'There is nothing here',
    emptyListTitleSearchResultsMode: 'Nothing found',
    emptyListMsgEnd:', by pressing the button',
    emptyListLabel: {
    finTermsView: 'Add a nem financial term',
        contractAgents: 'Add a counterparty to a contract',
        itemDocuments: 'Select files or documents that need to be uploaded',
        actTemplatesView: 'Add a new template',
        userOrganizations: 'Add an organization',
        itemParams: 'Add a new parameter',
        addAgreements: 'Add an extra agremement',
        addServices: 'Add an extra service',
        agentGatesParams: '"Click the button after making sure that the Merchant ID is specified in ',
        agentGatesParamsLink: 'Main data',
        searchResultsMode: 'Try changing the search parameters'
},
    noRelatedContracts: 'No linked contracts were found',
    error: {
    required: 'The field is not filled in',
        dateFormat: 'Date format - DD.MM.YYYY',
        email: 'Invalid Email',
        userMainDataOrganizationLength: 'Organizations are not selected',
        maxLength31: 'The field allows a maximum of 31 characters',
        maxLength128: 'The field allows a maximum of 128 characters',
        maxLength150: 'The field allows a maximum of 150 characters',
        maxLength256: 'The field allows a maximum of 256 characters',
        maxLength1000: 'The field allows a maximum of 1000 characters',
        maxLength2048: 'The field allows a maximum of 2048 characters',
        oneInRatesArrRequired: 'One of the commission fields must be filled in',
        oneInParamsArrRequired: 'One of the source parameter fields must be filled in',
        sequenceDates: 'Dates do not match',
        complianceWithContractDates: 'Mismatch with contract dates',
        oneFinTermRequired: 'Financial terms not selected',
        notEmptyFinTermsArr: 'Terms by date not specified',
        notEmptyContractMainDataFilesArr: 'Main documents not uploaded',
        notEmptyFinTermParamsArr: 'Commissions not added',
        noActTemplateFile: 'File not selected',
        selectOnlyOneValue: 'Select only one value',
        minMaxCompliance: 'Values within the ranges do not match',
        groupFinTermNameMatchIsProhibited_withArgs: 'A financial term with this name already exists',
        convertedTagIsNotAdded: 'Currency conversion tag not added',
        conversionCurrencyIsNotMatched: 'The conversion currency does not match the calculation currency',
        conversionSourceIsNotMatched: 'Conversion sources do not match',
        conversionDateIsNotMatched: 'The conversion date should be defined as the transaction date.',
        payoutPeriodCompliance: 'The payout period in the financial terms does not match the one in the template',
        notEmptySheetsArr: 'List of services with currency not added',
        notEmptyCalculatedItemsArr: 'List of services not added',
        notEmptyTerminalsArr: 'Terminals not selected',
        forbiddenFileNameSymbols: 'Forbidden characters in the filename'
},
    errorModalTitle: 'An error has occurred',
    errorMsgTitle: 'Error',
    warningModalTitle: 'Warning',
    initiator: 'Initiator',
    selectUser: 'User',
    allUsers: 'All users',
    historyActionTypes: {
    UPLOAD_FILE: 'File uploaded',
        DELETE_FILE: 'File deleted',
        UNDELETE_FILE: 'File restored',
        UPDATE_FILE: 'File updated',
        CREATE: 'Created',
        UPDATE: 'Updated',
        CREATE_FINTERMS: 'Financial terms uploaded',
        UPDATE_FINTERMS: 'Financial terms updated',
        DELETE_FINTERMS: 'Financial terms deleted',
        UPLOAD_ACT_TEMPLATE: 'Act templated uploaded',
        UPDATE_ACT_TEMPLATE: 'Act templated updated',
        DELETE_ACT_TEMPLATE: 'Act templated deleted',
        UPDATE_REPORT_STATUS: 'Report status updated',
        AUTO_ADJUST_REPORT: 'The report has been automatically adjusted.',
        USER_LOGIN: 'User logged into the app',
        USER_SET_PASSWORD: 'User set up a password',
        USER_SET_TWO_FACTOR_AUTH_KEY: 'User set up an authentication code',
        CREATE_ADD_AGREEMENT: 'Additional agreement created',
        UPDATE_ADD_AGREEMENT: 'Additional agreement updated',
        DELETE_ADD_AGREEMENT: 'Additional agreement deleted',
        CREATE_ADD_SERVICE: 'Additional service created',
        UPDATE_ADD_SERVICE: 'Additional service updated',
        DELETE_ADD_SERVICE: 'Additional service deleted',
},
    historyObjectTypes: {
        CONTRACT: 'Contract',
            AGENT: 'Counterparty',
            REPORT: 'Report',
            USER: 'User',
            ORGANIZATION: 'Organization'
    },
    historyObjectAction: {
        CONTRACT: {
            CREATE: 'Contract created',
                UPDATE: 'Contract updated',
        },
        AGENT: {
            CREATE: 'Counterparty created',
                UPDATE: 'Counterparty updated',
        },
        REPORT: {
            CREATE: 'Report created',
                UPDATE: 'Report updated'
        },
        USER: {
            CREATE: 'User created',
                UPDATE: 'User updated'
        },
        ORGANIZATION: {
            CREATE: 'Organization created',
                UPDATE: 'Organization updated'
        }
    },

    reportsTitle: 'Reports',
    reportsSearchPlaceholder: 'Act number',
    reportPeriodDate: 'Report period',
    reportCreateDate: 'Date of creation',
    noReports: 'The list of reports will be displayed here',
    reportPeriod: 'Report period',
    reportNumber: 'Act number:',
    exportFileLabel: 'Data export file',
    addServiceReportFileLabel: 'Additional services',
    downloadReport: 'Download report',
    currentStatus: 'Current status',
    updateStatus: 'Update status',
    updateStatusTitle: 'Status update',
    newStatus: 'New status',
    actDate: 'Act date',
    lastStatusUpdate: 'Last status update',
    noStatusesToUpdate: 'Status update not possible',

    rateAmount: 'Commission amount',
    totalAmount: 'Turnover amount',
    count: 'Transaction count',
    reportTotal: 'Total amount',

    reportFinTermParamLabel: {
        rateAmount: 'Commission amount',
        totalAmount: 'Turnover amount',
        count: 'Transaction count',
        rate: "Rate",
        hours: "Hours",
        amount: "Total amount"
    },

    reportsSearch2: 'Start',
    reportsSearch3: 'End',
    reportsSearch4: 'Status',
    reportsSearch5: 'Date',
    today: 'Today',
    yesterday: 'Yesterday',
    currentMonth: 'Current month',
    prevMonth: 'Previous month',
    currentQuarter: 'Current quarter',
    prevQuarter: 'Previous quarter',
    reportsSearchResultsItem2: 'Foundation',
    reportStatus: {
    ALL: "All",
        SUCCESS: "Successful",
        CREATED: "Waiting",
        SCHEDULED_FOR_ADJUST : "Waiting for adjustments",
        PROCESSING: "Processing",
        FAILED: "Error"
    },
    reportStatusInProcess: 'In preparation',
    reportAccountStatus: {
    ALL: "All",
        DRAFT: "Draft",
        PREPARED: "Prepared",
        UPLOADED: "Uploaded",
        CANCELLED: "Canceled",
        VERIFIED: "Verified",
        PAID_PARTIALLY: "Paid partially",
        PAID: "Paid",
        ADJUSTED: "Adjusted",
    },
    blockName: 'Block name',

    tileIconTooltip: {
    finterms: 'Financial terms',
        documents: 'Documents',
        templates: 'Templates',
        contracts: 'Contracts',
        agents: 'Counterparties',
        organizations: 'Organizations',
    },

    declension: {
        condition: {
            single: 'condition',
                few: 'conditions',
                many: 'conditions'
        },
        organization: {
            single: 'organization',
                few: 'organizations',
                many: 'organizations'
        },
        contract: {
            single: 'contract',
                few: 'contracts',
                many: 'contracts'
        },
        agent: {
            single: 'agent',
                few: 'agents',
                many: 'agents'
        },
        addService: {
            single: 'service',
                few: 'services',
                many: 'services'
        },

    },

    errorCode: {
        200: "Not possible to delete a financial term group",
        300: "Report generation error",
        400: "Invalid request",
        401: "Access denied",
        404: 'No results found at the requested address.',
        413: `Uploaded file size exceeds the maximum limit.`,
        419: "Duplicate data: email",
        420: "Duplicate data",
        421: "Invalid function parameter type",
        422: `The parameter value does not meet the specified requirements`,
        500: `Server error`,
        505: `Organization ID is required`,
        3001: (param) => `Wrong object type: ${param}`,
        3002: (param) => `Language ${param} not supported`,
        3003: (param) => `Currency ${param} not supported`,
        3004: (param) => `Amount ${param} cannot be blank or negative`,
        3005: (param) => `Expression ${param} is incorrect`,
        3006: `Closing bracket at the end of the file is missing`,
        3007: `The expression cannot be blank`,
        3008: (param) => `Wrong name of function ${param}`,
        3009: (param) => `Wrong function parameter number: ${param}`,
        3010: (param) => `Wrong function parameter type: ${param}`,
        3011: (param) => `Wring number format: ${param}`,
        3012: (param) => `Wrong number format: ${param}`,
        3013: `Error while processing the report`,
        3014: (param) => `Not possible to determine the template type: ${param}`,
        3015: `neither of the parameters (Terminal ID, Gateway ID, Processor ID) is specified`,
        3016: (param) => `Not possible to convert the report to pdf format: ${param}`,
        3017: "Report filename missing",
        3018: "Outdated doc format not supported, use docx format instead",
        3019: "Outdated xls format not supported, use xlsx format instead",
        3020: `Wrong template file type`,
        3021: (param) => `Report file format not supported: ${param}`,
        3022: (param) => `You are attempting to carry out a transaction with different currencies: ${param}`,
        4001: (param) => `A report for the specified period has already been prepared #${param}`,
        5001: "Database integrity violation",
        5002: (param) => `Not possible to delete the contract type/link type due to existing entities using it: ${param}`,
        5003: (param) => `Not possible to delete the financial term source due to are financial terms linked to it: ${param}`,
        5004: 'The contract validity period does not match the financial term validity period',
        5005: 'The contract validity period does not match the additional agreement validity period',
        5006: 'The additional agreement validity period does not match at least one of the financial term’s validity periods',
        5007: (param) => `Counterparties’ data (IDs: ${param}) does not match PNE data`,
        5010: (param) => `End date for fin term ${param[0]} is ${param[1]}, start date for fin term ${param[2]} is ${param[3]}, expected difference of 1 day and more`,
        5011: (param) => `FinTermCreateRequest type does not match converter type: expected ${param[0]}, found ${param[1]}`,
        5050: 'At least one active finterm is required',
    },
    goToPreparedReport: 'Go to the prepared report',
    goToMainPage: 'Return to the main page',

    reportsSubMenu: {
    ALL: "All",
        DRAFT: "Drafts",
        PREPARED: "Prepared",
        ADJUSTED: "Adjusted",
        UPLOADED: "Uploaded",
        CANCELLED: "Canceled",
        VERIFIED: "Verified",
        PAID_PARTIALLY: "Paid partially",
        PAID: "Paid"
    },
    areChosen: "Selected:",
        download1CExportFile: "Upload 1C file",
        adjustment: "Adjustment",
        autoAdjustment: "Automatic adjustment",
        manualAdjustment: "Manual adjustment",
        uploadDoc: "Upload document",
        adjustmentHistory: "Adjustment history",
        adjustmentType: {
            AUTO_ADJUST: 'Adjusted automatically',
            MANUAL_ADJUST: 'Adjusted manually'
    },

    organizations: 'Organizations',
    users: 'Accounts',
    organizationsSearchPlaceholder: 'Search',
    organizationStatus: {
      ALL: "All",
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
    },
    noOrganizations: 'The list of organizations will be displayed here',

    userStatus: {
        ALL: "All",
        CREATED: 'Created',
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        LOCKED: 'Locked'
    },
    role: {
        ADMIN: 'Administrator',
        LAWYER: 'Legal counsel',
        ACCOUNTANT: 'Accountant',
        MANAGER: 'Manager',
        CHIEF_ACCOUNTANT: 'Chief accountant',
        CHIEF_LAWYER: 'Chief legal counsel'
    },
    roleLabel: 'Role',

    usersSearchPlaceholder: 'Search',
    noUsers: 'The list of users will be displayed here',
    newUser: 'New user',
    email: 'E-mail',
    phone: 'Phone number',
    userName: 'Username',
    addOrganizationsToUserModal: 'Add organizations to the user',
    editUserRoleModalTitle: 'Edit user role',
    selectOrganization: 'Select organization',
    selectRole: 'Select role',
    noOrganizationsAvailable: 'No organizations available',
    userOrganizationsLabel: 'Organizations in total',
    lastSeen: 'Latest activity',
    sendLinkToActivate: 'Send an activation link',
    sendActivationLinkModalTitle: 'Send an account activation link',
    btnSend: 'Send',

    modalSubTitle: {
    setPass: 'Enter password',
        resetPass: 'Enter new password'
    },
    passLabel: 'Enter password',
    repeatPassLabel: 'Repeat password',
    globalPermissionsLabel: 'Administrator rights',
    globalPermissionsPlaceholder: 'Set administrator rights',
    linkIsSent: 'Link sent',
    myOrganizations: 'My organizations',

    helloUser: (userName) => `Welcome, ${userName}!`,
    needToSetPass: 'You need to set a password to use the system',
    subTitleGoogleAuth: 'Two-factor authentication setup',
    requiredSteps: 'Required steps',
    googleAuthStep1: '1. Install the Google Authenticator app',
    googleAuthStep2: '2. Scan the QR code in the app',
    googleAuthStep3: '3. Enter the confirmation code from the app',
    authCodeLabel: 'Confirmation code',
    btnProve: 'Confirm',
    wrongAuthCode: 'Wrong confirmation code',
    passwordParams: 'Password parameters',
    passwordRules: {
    passMinLength: 'Minimum 8 characters',
        containsLatinUppercase: 'Latin capital letters (from A to Z)',
        containsLatinLowercase: 'Latin lowercase letters (from a to z)',
        containsNumber: 'Numbers (from 0 to 9)',
        freeOfSpaces: 'Does not contain spaces'
    },
    passwordsAreIdentical: 'The passwords match',
    loginLabel: 'Login',
    passwordLabel: 'Password',
    loginPassError: 'Unknown login or password',

    organizationCode: 'Organization code',
    locale: 'Language',
    organizationName: 'Organization name',
    newOrganization: 'New organization',
    deleteUserOrganizationModalTitle: 'Deletion',
    deleteUserOrganization1: `Delete `,
    deleteUserOrganization2: ` from the user’s list of organizations?`,
    addParams: 'Additional parameters',
    contractAddParams: 'Additional contract parameters',
    agentAddParams: 'Additional counterparty parameters',
    paramType: {
        TEXT: 'Text field',
        TEXT_AREA: 'Large text field',
        DATE: 'Date'
    },
    deleteItemParamModalTitle: 'Deletion of parameters from the list',
    deleteItemParam1: `Delete `,
    deleteItemParam2: ` from the list of additional parameters for this organization?`,
    editItemParamModalTitle: 'Parameter editing',
    addItemParamModalTitle: 'Parameter addition',
    itemParamName: 'Parameter name',
    itemParamType: 'Parameter value input type',
    selectObjectType: 'Select type',
    inputParamName: 'Enter parameter name',
    itemParamLabel: 'Enter parameter name to be displayed',
    contractTypes: 'Contract types',
    sourceNamesPlaceholder: 'Select data sources',
    currenciesPlaceholder: 'Select currencies',
    contractTypesPlaceholder: 'Input contract type or select from the list',
    localePlaceholder: 'Select language',
    noOptionsMessageForGroupFinTermType: () => 'The list of term types will be available once the data source is selected',

    adjustmentCaution: 'If you make adjustments, current data will be lost without the possibility of recovery. Are you sure you want to proceed?',
    author: 'Author',
    reportNum1C: 'Act number in 1С',
    idReport: 'Act Id',

    reportTotalHint1: 'Set up calculations for the selected financial terms using formulas, for example:',
    reportTotalHintAdd: 'additions',
    reportTotalHintSubtract: 'substractions',
    reportTotalHintMultiply: 'multiplications',
    reportTotalHintDivide: 'divisions',
    reportTotalHint2: 'you can also specify just numbers (for example, ',
    reportTotalHint3: 'Simultaneous implementation of multiple terms is allowed',
    paramsHint1: 'In field entries, ',
    paramsHint2: 'only letters are allowed! For phrases, use CamelCase (for example, OrganizationName, DocumentAmountWithoutVAT)',
    paramNameError: 'The parameter name does not meet the requirements specified below',
    btnView: 'View',
    btnViewTags: 'View tags',
    viewTemplateParamsModalTitle: 'Tags for the template',
    searchPropName: {
        name: 'Name',
            tags: 'Tags',
            contractNo: 'Contract number',
            agentName: 'Linked counterparty',
            contractName: 'Linked contract'
    },

    agentLinkedTypes: 'Counterparty link types',
    contractLinkedTypes: 'Contract link types',
    agentLinkedTypesPlaceholder: 'Enter counterparty link type or select from the list',
    contractLinkedTypesPlaceholder: 'Enter contract link type or select from the list',
    linkTypePlaceholder: 'Select link type',
    addServiceTypeList: 'Additional service types',
    addServiceTypeListPlaceholder: 'Enter additional service type',
    notedTerminalTypeList: 'Terminal types',
    notedTerminalTypeListPlaceholder: 'Enter terminal type',
    folderSelectInModalLabel: "Catalog",
    folder:{
    name: 'Name',
        folderName:'Catalog name',
        folder: 'Catalog',
        add: 'Add catalog',
        delete: 'Deletion',
        deleteText1: 'Are you sure you want to delete a catalog?',
        deleteText2: 'and',
        deleteText3: 'documents?',
        rename: 'Rename catalog',
        folders: 'Folders'
    },
    document:{
        name: 'Name',
            edit: 'Editing',
    },
    files:'Files',
    filesOutOfFolders:'Files out of folders',
    deleteContractAgentModal: 'Deletion',
    deleteLinkedContractModal: 'Deletion',
    deleteContractAgentModalText1: 'Delete',
    deleteContractAgentModalText2: `from the list of linked counterparties?`,
    deleteLinkedContractModalText1: 'Delete',
    deleteLinkedContractModalText2: ` from the list of linked contracts?`,
    errorFileFormat: {
        oldDoc: 'Old file format, use docx',
            oldXls: 'Old file format, use xlsx',
            unknownFileFormat: 'Unkown file format'
    },
    allFiles: 'All files in list format',
    filesAndFolders: 'Folders and files',
    breadcrumbs: {
    supLevelCategory: {
        organizations: 'Organizations',
            users: 'Accounts',
            settings: 'Settings'
    },
    itemCategory: {
        contracts: 'Contracts',
            agents: 'Counterparties',
            reports: 'Reports',
            documents: 'Documents',
            settings: 'Settings',
            history: 'History'
    },
    newItem: {
        contract: 'New contract',
            agent: 'New counterparty',
            user: 'New user',
            organization: 'New organization'
    },
    newSubItem: {
        groupFinTerm: 'New terms',
            actTemplate: 'New act template',
            addService: 'New additional service'
    },
    supLevelSubpage: {
        userOrganizations: 'Organizations',
            history: 'Activity history',
            notifications: 'Notifications',
            params: 'Additional parameters'
    },
    itemSubpage: {
        contracts: 'Linked contracts',
            agents: 'Linked counterparties',
            history: 'History of changes',
            adjustmentHistory: 'Adjustment history',
            groupFinTerms: 'Financial terms',
            documents: 'Documents',
            actTemplates: 'Act templates',
            notifications: 'Notifications',
            addAgreements: 'Additional agreements',
            organization: 'Organization',
            'add-params': 'Additional parameters',
            addServices: 'Additional services',
            gatesParams: 'Gateway parameters'
        },
    },
    templateParamsHeader: {
        paramName: "Field name",
            paramExample: "Value example",
            paramTag: "Variable name for use in report templates",
            paramAliases: "Variable name options for use in report templates"
    },
    relationType: 'Link type',
    convertedTagModalTitle: `Currency addition`,
    convertedTagEditModeModalTitle: `Currency editing`,
    convertedTagModalSubTitle: (paramName) => `for ${paramName}`,
    currencyConverterSource: {
    ECB: 'European Central Bank',
        CBR: 'Central Bank of Russian Federation',
        REUTERS: 'Reuters',
        CURRENCY_LAYER: 'Currencylayer',
        OPEN_EXCHANGE: 'Open Exchange'
    },
    convertationDate: {
        DOCUMENT_DATE: 'Act date',
        TX_DATE: 'Transaction date'
    },
    currencyConverterSourceLabel: 'Conversion source',
    selectCurrencyPlaceholder: 'Select currency',
    selectConvertorSourceBefore: 'Select conversion source before choosing the currency',
    selectCurrencyConverterSourcePlaceholder: 'Select conversion source',
    selectConvertationDatePlaceholder: 'Select conversion date',
    convertationDateLabel: 'Conversion date',
    convertationDateError: 'Select conversion date',
    convertationSourceError: 'Select conversion source',
    convertationCurrencyError: 'Select currency',
    clickToCopy: 'Click to copy',
    contentIsCopied: 'Copied to clipboard',

    alertHeader: {
        ERROR: 'Error!',
        WARNING: 'Attention!',
        SUCCESS: 'Ready!',
        NOTIFICATION: 'Notification'
    },
    groupFinTermTemplateParams: {
        rateAbsAmount: 'Calculation based on ABS rate',
        ratePercentDec: 'Calculation based on the percentage rate',
        transactionType: 'Transaction type',
        rateDescription: 'Commission',
        totalAmount: 'Transaction amount',
        rateAmount: 'Calculation amount by rate',
        transactionsCount: 'Transaction count',
        hours: 'Number of hours',
        rate: 'Hourly rate',
        tracker: 'Tracker',
        manualInput: 'Manual input'
    },
    reportSourceDoc: 'Source data',
    reportAddendumDoc: 'App',
    calculatedResultFile: 'All apps',
    calculatedResultAddendumFile: 'App by template',
    alertsList: {
        onSubmitEnabled: 'You have made changes to the data. Don\'t forget to save them!',
        onActTemplateDocument: 'The file is selected and will be saved when saving the template!',
        onAddendumTemplateFile: 'The file is selected and will be saved when saving the template!',
        onEndDateIncompliance: 'The selected end date has passed. After saving, the status will be automatically changed to inactive!',
        onFinTermDateIncompliance: 'Attention! The manual adjustment of the financial terms’ validity period does not match the one specified in the primary/additional agreement',
        onWrongFileName: `The filename contains the prohibited % symbol, so the file cannot be uploaded. Please, rename the file!`
    },
    reportTagExpressionPlaceholder: {
        reportTotalAmountExpression: 'Enter an expression for the Total Amount of completed transactions',
        reportTotalCountExpression: 'Enter an expression for the Number of completed transactions',
        reportTotalExpression: 'Enter an expression for the Total Amount',
    },
    filtersLabels: {
        userId: 'Initiator',
        dateRange: 'Date of conclusion',
        createDateRange: 'Date of creation',
        status: 'Status',
        reportStatus: 'Completion status',
        reportAccountStatus: 'Report status',
        reportPeriodDateRange: 'Reporrting period',
        contractId: 'Contract',
        actTemplateId: 'Act template',
        payerId: 'Payer',
        payeeId: 'Payee',
        organizationId: 'Organization',
        role: 'Role',
        finTermGroupId: 'Financial term',
        count: 'Display the first ones',
        text: 'Act number',
        groupFinTermName: "Financial term name",
        payerMerchantId: "Payer’s merchant",
        payeeMerchantId: "Payee’s merchant",
        currency: "Financial term currency",
        payoutPeriod: "Payout period",
        cardType: "Card type",
        transactionStatus: "Transaction status",
        transactionType: "Transaction type",
        source: "Source",
        gateShowMode: "Gateway selection",
        gateSource: "Gateway source",
        gateCurrency: "Gateway currency",
        gateStatus: "Gateway status",
        terminalStatus: "Terminal status",
        gateId: "Gateway ID",
        gateName: "Gateway name",
        terminalId: "Terminal ID",
        byAgentContracts: "By contract availability",
        byAgentFinTerms: "By financial term availability",
        byComplianceWithPaynet: "By error presence",
        byUnverifiedAddAgreements: "By additional service availability",
        contractTypeId: "By contract type"
    },
    filterFlagsLabels: {
        ALL: 'All ',
        onlyFinTerms: 'Financial terms',
        onlyActTemplates: 'Templates',
        flag1: 'Flag 1',
        flag2: 'Flag 2',
        flag3: 'Flag 3'
    },
    historyLogSectionTitle: {
        updated: 'Updated',
        created: 'Created',
        deleted: 'Deleted'
    },
    selectFilterPlaceholder: 'Filter',
        historyLog: {
        CONTRACT: {
            //contractMainData
            typeId: 'Type',
            contractNo: 'Number',
            name: 'Name',
            comment: 'Comment',
            startDate: 'Start',
            endDate: 'End',
            contractCode1c: 'Contract code',
            tags: 'Tags',
            status: 'Status',
            //fin terms
            payerId: 'Payer',
            payeeId: 'Payee',
            payerMercAgentId: 'Payer’s merchant',
            payeeMercAgentId: 'Payee’s merchant',
            type: 'Terms',
            nomenNumber1c: 'Act nomenclature code',
            projectName: 'Nomenclature group code (Project/Direction)',
            ignoreInExport: 'Ignore when generating the data export file',
            currency: 'Currency',
            payoutPeriod: 'Payout period',
            source: 'Data source',
            'sourceParam/gatewayId/textValue': 'Gateway IDs',
            'sourceParam/merchantId/textValue': 'Merchant IDs',
            'sourceParam/processorId/textValue': 'Processor IDs',
            'sourceParam/terminalId/textValue': 'Terminal IDs',
            'sourceParam/transactionStatus/textValue': 'Transaction status',
            'sourceParam/transactionType/textValue': 'Transaction type',
            'sourceParam/projectId/textValue': 'Project ID',
            'sourceParam/trackers/textValue': 'Trackers’ name',
            'sourceParam/userIds/textValue': 'User IDs',
            'sourceParam/usersExcluded/textValue': 'Exclude the specified user IDs',
            'sourceParam/cardType/textValue': 'Card type',
            'sourceParam/managerId/textValue': 'Manager IDs',
            'sourceParam/direction/textValue': 'Direction type',
            gateId: 'Gateway ID',
            terminalIds: 'Terminal IDs',
            finTerm: 'Term by date',
            row: 'Range',
            ratePercent: 'Percentage',
            rateMin: 'Minimum',
            rateAbs: 'Absolute',
            rateAbsForTxTypes: 'Price',
            rateAbsForMinType: 'Minimum',
            minAmount: 'Amount starting from',
            maxAmount: 'Amount up to',
            minCount: 'Qunatity from',
            maxCount: 'Quantity up to',
            rateHours: 'Hourly rate',
            documentName: 'Confirmation document',
            groupId: 'Financial term ID',
            id: 'ID',
            reportFileNamePattern: 'Name of the generated document',
            finTermGroupIds: 'Financial terms (IDs)',
            addAgreementId: 'Main/additional agreement (ID)',
            reportTotalExpression: 'Total amount',
            manualInput: 'Manual input',
            groupFinTerm: 'Financial term',
            customFunction: 'Custom function',
            //addAgreement
            fileId: 'File ID',
            number: 'Number',
            description: 'Description',
            contractId: 'Contract ID',
            isNotVerifiedWithFinTerms: 'Not verified. There is a change in financial terms',
            isNotVerifiedWithFinTermsComment: 'Comment on financial term changes',
            isCheckedAndVerified: "Additional agreement verified",
            //addService
            userId: "Person in charge",
            date: "Date of provision",
            isDetachedInvoice: "Ignore when generating the data export file",
            actTemplateId: "Act template",
            reportTemplateId: "Act template",
            addServiceSheetLabel: "List of services",
            addServiceCalculatedItemLabel: "Service",
            addServiceSheet: {
            addServiceTypeId: 'Name',
                price: 'Price',
                count: 'Quantity',
                amount: 'Amount'
        },
        fileName: 'Filename'


    },
    AGENT: {
        name: 'Name',
        tags: 'Tags',
        status: 'Status',
        statusReason: 'Reason for termination',
        agentCode: 'Counterparty code',
        inn1c: 'Counterparty’s TIN',
        name1c: 'Counterparty name',
        merchantId: 'Merchant IDs',
        managerId: 'Manager IDs',
        processorId: 'Processor IDs',
        fileName: 'Filename',

        gateId: 'Gateway ID',
        terminalId: 'Terminal ID',
        gateTerminalProps: {
        groupFinTerms: "Financial terms IDs",
            isIgnored: "Ignored",
            notedTerminalTypeId: "Terminal type",
            comment: "Comment",
            commentUserId: "Author of the comment",
            commentDate: "Comment date"
        }
    }
},
reset: 'Reset',
    allRead: 'Mark all as read',
    viewed: 'Viewed',
    unviewed: 'Not viewed',
    generatedDocName: 'Generated file name',
    generatedDocNameHintText1: 'Set the name for the generated file using tags in supported functions.',
    generatedDocNameHintExample: 'Example: Act_${docNum}_from_${document_date}_${P_name}. ',
    generatedDocNameHintText2: 'It is possible to create a document name without using tags',
    reportFileNamePatternPlaceholder: 'Enter an expression for the name of the generated document',
    pasteExampleFromHint: 'Insert standard name',
    pasteAllTagsBtn: {
    reportTotalAmountExpression: 'Insert the Total Amount of all financial terms',
        reportTotalCountExpression: 'Insert the Total Count of all financial terms',
        reportTotalExpression: 'Insert the Rate Amount of all financial terms',
},
pasteAllTagsHint: 'If there are tags with converted currency when you click the button, these tags replace the ones with the original currency in the expression',
    // reportTotalExpressionWarning: (rateAbs, currency) => `Attention! If the amount does not exceed the value from the financial term ${rateAbs} ${currency}, the total amount will equal the minimum value ${rateAbs} ${currency}!`
    reportTotalExpressionWarning1: `Attention! If the amount does not exceed the minimum value from the financial terms (`,
    reportTotalExpressionWarning2: `),  the total amount will equal the minimum value!`,
    reportTagExpressionWarning: {
    reportTotalCountExpression: 'Attention! It is forbidden to use tags in the “Number of completed transactions| ${report_total_count}” fields in the selected financial term and the uploaded Act Template.',
        reportTotalAmountExpression: 'Attention! It is forbidden to use tags in the “Transaction count | ${report_total_amount}” fields in the selected financial term and the uploaded Act Template'
    },
    rateAmountTagInput: 'Input value',
    fillManualInputsTitle: 'Fill in the manual input fields',
    excludeManualInputLabel: 'Exclude manual input',
    rateAmountTagInputError: 'Enter a value or exclude manual input!',
    generateAttachment: 'Generate attachment',
    showOther: 'more',
    reportPeriodIncompliance: 'Attention! The reporting period does not match the financial term dates',
    reportPeriodIsNotCorrespondToLastValidReportDate: (date) => `Report generation is allowed until ${date}`,
    wrongDateRange: (date) => `The start date must be before the end date`,
    linksToGroupFinTermsLabel: "Links to financial terms: ",
    addFinTermTooltip: 'To add a new financial term, click on the highlighted area. Once added, check the date period in the current financial term; the dates should not overlap.',
    finTermParamTooltip: {
    BY_VOLUME_SIMPLE: byVolumeParamTooltips,
        BY_VOLUME_COMPLEX: byVolumeParamTooltips,
        BYRATE: byVolumeParamTooltips,
        BY_HOURS: {
        rateHours: 'Specify the rate according to the selected currency in the “Currency” section in the financial term',
    },
    BY_TX_COUNT: byTxFinTermParamTooltips,
        BY_TX_COUNT_SIMPLE: byTxFinTermParamTooltips,
        BY_TX_COUNT_COMPLEX: byTxFinTermParamTooltips,
        FIXED: {
        rateAbs: 'Fixed payment, based on the calculation of the selected payout period (month/quarter/year), is applied throughout the validity period of the date or date range. The specified amount will be calculated based on the chosen payout period, taking into account the dates selected when generating the document.'
    },
    MANUAL: {},
    MIN: {
        rateAbs: 'The minimum payment applied after calculating all added financial terms is applied throughout the validity period of the date or date range. If the specified amount does not exceed the minimum value after calculating all added financial terms,  the total amount will be equal to the value of all added financial terms.'
    }
},
sourceParamTooltip: {
    trackers: 'Specify "Task, Bug, User story, Bug story", separated by commas',
    userIds: 'Specify the ID of a user or multiple users, separated by commas with spaces',
    procIdsExcluded: 'Excludes the specified processor IDs, when generating the act, transactions that relate to the specified IDs will be excluded',
    mngrMercIdsExcluded: 'Excludes the specified IDs of the manager and the manager\'s merchants; when generating an act, transactions that relate to the specified IDs will be excluded',
    mngrIdsExcluded: 'Excludes the specified manager IDs, when generating an act, transactions that relate to the specified IDs will be excluded',
    mercIdsExcluded: 'Excludes the specified merchant IDs, when generating the act, transactions that relate to the specified IDs will be excluded',
},
groupFinTermTypeTooltip: {
    BY_VOLUME_SIMPLE: 'Calculation based on the entered parameters with the amount range specified in the “Amount starting from” and “Amount up to” fields. It is possible to specify an unlimited number of amount ranges while maintaining a logical sequence',
        BY_VOLUME_COMPLEX: 'Calculation based on the entered parameters with the amount range specified in the “Amount starting from” and “Amount up to” fields as a cumulative total. The cumulative total entails the calculation based on intermediate values specified in the “Amount starting from” and “Amount up to” fields at the end of each intermediate period',
        BYRATE: 'Calculation based on a fixed rate with parameters specified in the term. Applied throughout the duration of the date or date range validity period',
        BY_HOURS: '',
        BY_TX_COUNT: 'Calculation based on a fixed rate specified in the “Price” field and multiplied by the transaction count (transactional calculation) within the specified date period',
        BY_TX_COUNT_SIMPLE: 'Calculation based on the range of transactions specified in the "Quantity starting from" and "Quantity up to" fields or the specified date range. It is possible to specify an unlimited number of transaction ranges while maintaining a logical sequence.',
        BY_TX_COUNT_COMPLEX: 'Calculation based on the range of transactions specified in the "Quantity starting from" and "Quantity up to" fields or the specified date range as a cumulative total.  The cumulative total entails the calculation based on intermediate values specified in the “Quantity starting from” and “Quantity up to” fields at the end of each intermediate period',
        FIXED: 'Calculation based on a fixed rate with parameters specified in the term. Applied throughout the duration of the date or date range validity period',
        MANUAL: 'During the selection of a financial term, there will be an option for manual input of the value during report generation',
        MIN: 'ONLY joint use with other financial terms. The minimum payment applied after the calculation of all added financial terms is applied for the duration of the date or date range validity period',
},
groupFinTermSourceTooltip: {
    PNE: 'Calculation and data generation based on the parameters specified when accessing the external PayNetEasy Gateway RU service',
        PNE_EU: 'Calculation and data generation based on the parameters specified when accessing the external PayNetEasy Gateway EU service',
        REDMINE: 'Calculation and data generation based on the parameters specified when accessing the external Redmine service',
        EMPTY: 'Calculation and data generation based on the parameters specified in the FlowDoc System without accessing external services',
},
payerTooltip: "A natural person or legal entity that pays for the goods or services to the specified Recipient",
    payeeTooltip: "A natural person or legal entity that receives the payment for the goods or services from the specified Payer",
    currencyTooltip: "The original currency type for calculating transactions based on the parameters specified in the data source",
    payoutPeriodTooltip: 'The invoice issuance period for the provided services. Calculation based on the parameters (amount, period) specified in the Financial term when selecting the Data Source: "Without source"',
    param1CTooltip: 'Fill in the parameters for generating the export file in .csv format. The file is available when transitioning to the statuses: “Prepared” or “Adjusted”',
    ignoreInExportTooltip: "When the checkbox is selected, this Financial term will be ignored during export in .csv format",
    moreLinkTo: 'More',
    addItemRowHeading: {
    name: 'Name',
        createDate: 'Date of creation',
        status: 'Status',
        statusDate: 'Date of termination',
        contractNo: 'Number',
        validityTerm: 'Validity period',
        contractType: 'Type'
},
settingsTitle: 'Settings',
    orgSettingsTitle: 'Settings',
    notificationMenuTitle: 'Notifications',
    organizationSettingsMenuTitle: 'Organization',
    backToUp: 'Back',
    notificationSettingsTitle: 'Notification settings',
    notificationSettingsFormHeader: {
    organizationHeader: 'Organization',
        AGENT_NOTFICATION: 'Counterparties',
        CONTRACT_NOTIFICATION: 'Contracts',
        LINKED_AGENT_NOTIFICATION: 'Linked counterparties',
        LINKED_CONTRACT_NOTIFICATION: 'Linked contracts',
        FINTERM_NOTIFICATION: 'Financial terms',
        CONTRACT_END_DATE_NOTIFICATION: 'Contract validity period',
        ADD_AGREEMENT_END_DATE_NOTIFICATION: 'Additional agreement validity period',
        FIN_TERM_END_DATE_NOTIFICATION: 'Financial term validity period',
        ALL: 'All'
},
notificationSettingsInputValue: {
    yes: 'Yes',
        no: 'No'
},
notificationForAllOrgsLabel: 'All',
    organizationLinkedAgent: 'Linked counterparty',
    linkedAgentIsNotSelected: 'Linked counterparty not selected',

    addAgreementsDates: 'Date of signing',
    createAddAgreementModalTitle: 'Addition of an additional agreement',
    editAddAgreementModalTitle: 'Editing of an additional agreement',
    addAgreementName: 'Name',
    addAgreementFileLabel: 'Source file',
    newAddAgreementFile: 'New',
    fromDocumentsList: 'From the list',
    fileFromDocumentsListLabel: 'List of uploaded documents',
    selectStatusPlaceholder: 'Select status',
    fileIsNotChosen: 'File not selected',
    deleteAddAgreementModalTitle: 'Deletion',
    deleteAddAgreementConfirmation: `Delete `,
    deleteWithFileCheckboxLabel: (fileName) => `Delete the linked document ${fileName}`,
    fileWithIdNotFound: (fileId) => `File with ID=${fileId} is not in the list of documents`,
    isNotVerifiedWithFinTerms: 'There is a change in financial terms or calculations',
    isNotVerifiedWithFinTermsComment: 'Comment on financial term changes',
    isNotVerifiedWithFinTermsOptions: {
    yes: "Yes",
        no: "No"
},
isNotVerifiedWithFinTermsPlaceholder: 'Specify the presence of changes',
    isCheckedAndVerified: "Additional agreement verified",

    fileLocation: 'Location',
    withoutFolder: 'Without folder',
    contextSnippetLabel: 'Matching context',
    filterCheckboxLabel: {
    strictPeriod: 'Strict period'
},
documentIndexStatusTooltip: {
    INDEXED: 'The document was indexed successfully',
        NO_CONTENT: 'The document is empty and therefore was not indexed',
        ERROR: 'Indexation error',
        PENDING: 'The document is indergoing indexation',
        NOT_APPLICABLE: 'The document was not indexed'
},
searchInOrgContextBtnLabel: 'Search',
    verifiedStatusText: {
    VERIFIED: 'Verified',
        UNVERIFIED: 'Not verified'
},
isVerifiedLabel: 'Verified',
    updatesNotificationsTitle: 'Receive notifications about the creation/modification/deletion of core entities',
    endDatesNotificationsTitle: 'Receive notifications about entity validity periods',
    endDatesNotificationsNote: '*You will receive the notifications 30, 15, 7, and 0 (upon actual status change) days in advance.',
    textReportFilterPlaceholder: 'Input act number',
    fileWillBeDeleted: 'The file will be deleted once the changes are saved',

    selectAddAgreementOrMainContract: 'Select a base agreement',
    scrollToTop: '↑ Up ↑',
    editBtnLabel: 'Edit',
    deleteBtnLabel: 'Delete',
    restoreBtnLabel: 'Restore',

    applyCustomFunction: 'Apply custom function',
    customFunction: 'Custom function',

    dateRangePeriodTypeLabels: {
    day: 'By days',
        month: 'By months',
        quarter: 'By quarters'
},
btnDenyDatesLabel: 'Cancellation',
    btnApplyDatesLabel: 'Ready',
    monthOptionsLabels: {
    '01':'January',
        '02':'February',
        '03':'March',
        '04':'April',
        '05':'May',
        '06':'June',
        '07':'July',
        '08':'August',
        '09':'September',
        '10':'October',
        '11':'November',
        '12': 'December'
},
quarterOptionsLabels: {
    '1': 'I',
        '2': 'II',
        '3': 'III',
        '4': 'IV'
},
selectMonthPlaceholder: 'Month',
    selectQuarterPlaceholder: 'Quarter',
    selectYearPlaceholder: 'Year',
    selectedQuarterLabel: 'Selected quarter:',

    contractAgentsDisabledDeleteTooltip: 'The counterparty is linked to financial terms. Deletion is not possible!',
    addAgreementsDisabledDeleteTooltip: (finTermGroupIds) => `Additional agreement is used in financial terms: ${finTermGroupIds}`,

    addServices: 'Additional services',
    btnNewAddService: 'Add a service',
    newAddServiceHeading: 'New additional service',
    editModeStr: 'Editing',
    addServiceEditSteps: {
    type: 'Service type',
        docs: 'Documents',
        sheets: 'List of services',
        confirmation: 'Confirmation'
},
addServiceFormLabels: {
    status: 'Status',
        nomenNumber1c: 'Act nomenclature code',
        projectName: 'Nomenclature group code (Project/Direction)',
        name: 'Service name',
        userId: 'Person in charge',
        date: 'Date of provision',
        payoutPeriod: 'Invoice issuance period',
        ignoreInExport: 'Ignore when generating the data export file',
        isDetachedInvoice: 'Invoice separately',
        actTemplateId: 'Act template'
},
addServiceFormPlaceholders: {
    name: 'Enter service name',
        userId: 'Select the person in charge',
        payoutPeriod: 'Select period',
        nomenNumber1c: 'Enter act nomenclature code',
        projectName: 'Enter nomenclature group code (Project/Direction)',
        actTemplateId: 'Select the act template to which the service invoice will be added'
},
addServiceFormNoOptionsMessage: {
    userId: () => 'The list of users is empty. Contact the administrator',
        payoutPeriod: () => 'The list of templates is empty. Contact the administrator',
        actTemplateId: () => 'The list of templates is empty'
},
addServiceFormLabelTooltips: {
    actTemplateId: 'Act template to which the service invoice will be added'
},
addServiceInvoiceSubheader: 'Invoice issuance',
    addServiceDocsSubheader: 'Confirmation document',
    stepsNavigationButtons: {
    cancel: 'Close',
        back: 'Back',
        forward: 'Proceed'
},
addServiceFinishButtonLabel: {
    update: 'Save',
        create: 'Add'
},
sheetLabels: {
    baseCurrency: 'Base currency',
        secCurrency: 'Secondary currency',
        totalAmount: 'Total amount'
},
sheetPlaceholders: {
    currency: 'Select a currency'
},
sheetNoOptionsMessage: {
    currency: 'The list of currencies is empty. Contact the administrator'
},
sheetCalculatedItemsHeader: {
    addServiceTypeId: 'Name',
        price: 'Price',
        number: 'Quantity',
        amount: 'Amount'
},
sheetCalculatedItemPlaceholders: {
    addServiceTypeId: 'Select a service',
},
sheetCalculatedItemNoOptionsMessage: {
    addServiceTypeId: 'The list of services is empty. Contact the administrator'
},
addSheetButtonLabel: '+ Additional currency',
    addServiceDescSubheader: 'Service description',
    addServiceSheetsSubheader: 'List of services',
    addServiceViewSubheader: 'Additional service',
    deleteAddServiceModal: {
    title: 'Deletion',
        confirmation: `Delete `,
        preventDeleteTitle: 'The deletion and editing of the additional service is not possible',
        preventDeleteText: (addServiceName) => `A report has been created based on the additional service ${addServiceName}`,
        withFiles: (fileNames) => `Delete linked documents: ${fileNames}`
},
showAllBtnLabel: 'Show all',
    hideAllBtnLabel: 'Hide all',

    payoutPeriodIsNotSet: 'Payout period is not set',
    payoutPeriodIsNotCompliedWithAddService: '- does not match the payout period of this additional service',

    actTemplateCardHeadings: {
    finTerms: 'Financial terms',
        addServices: 'Additional services'
},
noAddServicesForGivenPeriod: 'No additional services found for the selected period',
    addServiceWithGivenIdIsNotFound: (id) => `Additional service with id=${id} not found`,
    uploadedAddServicesAmountWarning: (amount) => `Number of invoices issued for additional services - ${amount}`,
    addServiceHasReportsWarning: 'This service is already being used in reports: ',

    finTermGatesSectionTitle: "Parameters for receiving transactions",
    gateIdLabel: "Gateway ID",
    terminalsLabel: "Terminal IDs",
    terminalsPlaceholder: "Select terminals",
    terminalsNoOptionsMessage: "All terminals are selected",
    terminalsOptionsMenuTerminalCaption: "terminals",
    terminalsOptionsMenuGroupFinTermCaption: "Financial terms",

    gatesParams: "Gateway parameters",
    gatesParamsCardHeader: {
    gateId: "Gateway ID",
        source: "Source",
        currency: "Currency",
        gateName: "Name",
        gateStatus: "Current status",
        gateCreationDate: "Created:",
        gateChangeDate: "Updated:",
        gateIgnored: "Ignored"
},
terminalLabelPrefix: "Terminal",
    isIgnoredTrueValue: "Ignored",
    isIgnoredFalseValue: "Not ingnored",
    ignoreBtnLabel: "Ignore",
    clickToIgnore: "Click to ignore",
    clickToResetIgnore: "Click to stop ignoring",
    ignoreGateWarning: "Please note that all manual settings from the \"Gateway Parameters\" tab will be reset.",
    ignoreGateBtnLabel: "Ignore all",
    selectFinTermBtnLabel: "Select financial terms",
    editFinTermBtnLabel: "Edit",
    selectFinTermModalTitle: (terminalId) => `Financial term for terminal ${terminalId}`,
    selectFinTermsForGateModalTitle: (gateId) => `Financial terms for terminals of gateway ${gateId}`,
    selectFinTermsForChosenGatesModalTitle: (gateIdArr) => `Financial terms for all terminals of gateways ${gateIdArr}`,
    onlyChosenBtnLabel: "Only selected",
    allBtnLabel: "All",
    applyBtnLabel: "Apply",
    gatesParamsCardAlert: "Financial terms not applicable",
    requestPaynetGatesParamsButtonLabel: 'Update base',
    alertPaynetSyncLabel: "Synchronization error. Data mismatch with PPG PNE.",
    alertPaynetSyncReportGenerationIsProhibited: "Report generation is prohibited.",
    alertPaynetSyncReportGenerationIsAllowed: (date) => `Report generation is allowed before ${date}.`,
    linkToNotCompliedAgent: "Go to the counterparty",
    linkToContract: "Go to the contract",
    alertUnverifiedContractFinTerm: "Financial calculation not verified.",
    alertUnverifiedAddAgreementsLabel: "Unverified additional agreements found.",
    alertUnverifiedAddAgreementsGenerationIsProhibited: "Report generation is prohibited.",
    linkToUnverifiedAddAgreements: "Go to additional agreements",
    addSourceAndMerchantId: "+ Add source",
    addSourceAndManagerId: "+ Add source",
    addSourceAndProcessorId: "+ Add source",
    requestPaynetDateEmpty: "Update date",
    terminalIsNotLinkedToFinTerm: "Not linked",
    finTermGatesArrayIsEmpty: "The list of gateways and terminals is empty. Check whether the Merchant IDs are mentioned in ",
    finTermGatesArrayIsEmptyLink: "Main Payer data",
    newSourceParamsSectionTitle: "Calculation parameters",
    gateStatusLabel: {
    E: "On",
        D: "Off"
},
terminalStatusLabel: {
    E: "On",
        D: "Off"
},

btnAddReportTagExpressionLabel: "Add a calculation",
    reportTagExpressionName: `report_tag_`,
    operationLabel: {
        "FT": "FT",
        "ΣFT": "ΣFT",
        "TAG": "TAG",
        "VAT": "VAT",
        "currency": "CUR"
},
selectTagPlaceholder: "Select a tag",
    selectReportTagExpressionFinTermPlaceholder: "Select a financial term",
    reportTagExpressionsError: {
    unequalBracketsNumber: "The number of opening and closing brackets does not match",
        moreThanOneParenthesis: "Inside the brackets, there can only be one inserted expression in brackets",
        parenthesisIsNotAfterOpenBracket: "The expression within the brackets must follow immediately after the opening bracket",
        firstOrderComputationIsNotAfterOpenBracket: "The primary arithmetic operation must follow immediately after the opening bracket",
        numberIsInUnsupportedOperation: "Numbers are not supported in this arithmetic operation",
        tagIsMultiplier: "The tag cannot be a multiplier",
        wrongNumberMultiplier: "Incorrect number format in the multiplier"
},

    sortAndFilterBar: {
        inputPlaceholder: "Search",
            inputPropsLabels: {
            gatesParams: {
                ALL: "By all parameters",
                    gateId: "By gateway ID",
                    source: "By source",
                    gateStatus: "By gateway status",
                    terminalIds: "By terminal ID"
            }
        },
        sortOptionsLabels: {
            gateId_sort_top: "Gateway ID",
            gateId_sort_down: "Gateway ID",
            gateCreationDate_sort_top: "Created",
            gateCreationDate_sort_down: "Created",
            gateChangeDate_sort_top: "Updated",
            gateChangeDate_sort_down: "Updated",
        },
        filterMenuLabel: {
            source: "Source",
            gateStatus: "Current status",
            gateCreationDate: "Created",
            gateChangeDate: "Updated"
        },
        clearFilterBtnLabel: "Clear filter - "

    },
    infoPanelLabel: {
        total: "Found in total:",
        found: "Found",
        chosen: "Select",
        choose: "Selected:",
        actions: "Action with the selected",
        of: "of"
    },
    sortAndFilterInfoPanel: {
        ignoreBtnLabel: "Ignore",
        selectFinTermBtnLabel: "Select financial terms",
        cleanFinTermsAndIgnores: "Nullify"
    },
    gateShowModeOptions: {
        withErrors: "With errors",
        withoutErrors: "Without errors",
        withIgnores: "Ignored",
        all: "All",
    },
    yesLabel: "Yes",
    noLabel: "No",

    confirmActionModal: {
    modalTitle: "Action confirmation",
        modalActionBtnText: "Confirm",
        modalCancelBtnText: "Cancel"
    },
    confirmIgnoreSelectedGates: "Ignore selected gateways and their terminals?",
    confirmCleanFinTermsAndIgnoresForChosenGates: "Clear the lists of financial terms and stop ignoring selected gateways and the terminals linked to them?",
    confirmDeleteCommentAndNotedType: "Delete comment and terminal type?",


    addMerchantBtnLabel: "+ Add merchant",
    removeMerchantBtnLabel: "Delete merchant",
    sortSelectLabel: "Sorting",
    sFFiltersListLabel: "Filter",

    groupFinTermsFilters: {
        groupFinTermNamePlaceholder: "Input financial term name"
    },
    gatesParamsFilters: {
        gateIdPlaceholder: "Input gateway ID",
        gateNamePlaceholder: "Input gateway name",
        terminalIdPlaceholder: "Input terminal ID",
    },
    gatesParamsCardComment: {
        modalTitle: "Comment",
        modalCancelBtnText: "Cancellation",
        modalActionBtnText: "Ready",
        notedTerminalTypeIdLabel: "Terminal type",
        notedTerminalTypeIdLabelPlaceholder: "Select type",
        notedTerminalTypeIdNoOptions: "Add types in the",
        notedTerminalTypeIdNoOptionsLinkLabel: "settings",
        commentLabel: "Comment"
    },
    searchPageFiltersLabels: {
        WITH_CONTRACTS: "With contracts",
        WITHOUT_CONTRACTS: "Without contracts",
        WITH_FINTERMS: "With financial terms",
        WITHOUT_FINTERMS: "Without financial terms",
        WITH_ERRORS: "With errors",
        WITHOUT_ERRORS: "Without errors",
        byAgentContractsPlaceholder: "By contract availability",
        byAgentFinTermsPlaceholder: "By financial terms availability",
        byComplianceWithPaynetPlaceholder: "By error presence",
        byUnverifiedAddAgreementsPlaceholder: "By unverified additional agreement availability",
        contractTypeId: "By contract type"
    },

    modalCloseBtnLabel: "Close",

    currentDocNumLabel: "Act number",
    currentDocNumCheckboxLabel: "Assign a number",

    editTitleLabel: "Editing",
    editTitleSuffix: " | edit",
    subHeaderTitle: {
    newGroupFinTerm: 'New financial term',
        newActTemplate: "New act template",
        newAddService: "New additional service"
    },
    allOrganizationsLabel: "All organizations",
    contractMenu: {
    mainData: "Main data",
        addAgreements: "Additional agreements",
        finTerms: "Financial terms",
        relatedAgents: "Counterparties",
        relatedContracts: "Contracts",
        documents: "Documents",
        actTemplates: "Act templates",
        addServices: "Additional services",
        history: "History",
        sources: "Sources",
        gatesParams: 'Gateway parameters',
    },

    noOptionsToSelect: "No options available to select from",
    emptyListV2Label: "There is nothing here!",

    deleteAgentForbiddenReason: {
    CONNECTED_TO_CONTRACT: "Deletion is not possible. Linked to a contract",
        HAS_MERCHANT_ID: "Deletion is not possible. Has a Merchant ID",
        HAS_MANAGER_ID: "Deletion is not possible. Has a Manager ID",
        HAS_PROCESSOR_ID: "Deletion is not possible. Has a Processor ID",
        HAS_FILE: "Deletion is not possible. File uploaded"
    },

    countriesDirectionsLabelTitle: "Directions and countries",
    cardCountryCodesPlaceholder: "Select countries",
    addDirectionAndCountryCodes: "+ Add a direction",
    directionLabel: "Direction",
    cardCountryCodes: "Countries",
    datepicker: {
        january: "January",
        february: "February",
        march: "March",
        april: "April",
        may: "May",
        june: "June",
        july: "July",
        august: "August",
        september: "September",
        october: "October",
        november: "November",
        december: "December",
        monday: "Mon",
        thursday: "Thu",
        wednesday: "Wed",
        tuesday: "Tue",
        friday: "Fri",
        saturday: "Sat",
        sunday: "Sun"
    }

};

export default textEn;
