//@flow
import text from "../services/localization/text";
import {
  actTemplatesSuffix,
  addAgreementsSuffix,
  addServicesSuffix,
  agentsSuffix,
  contractsSuffix,
  contrPathView,
  documentsSuffix,
  groupFinTermsSuffix,
  historySuffix,
  mainDataSuffix,
  orgPath,
  sourcesGatesParamsSuffix,
  sourcesMainSuffix,
  sourcesSuffix
} from "../services/urlStrings/urlStrings";
import type {
  contractId as contractIdType,
  organizationId as organizationIdType
} from "../services/flowTypes/dataFlowTypes";
import {permissionsStrValues} from "./permissionsStrValues";

const contractFinTermsMenuItems = (organizationId:organizationIdType, contractId:contractIdType) => {
  const contractViewPath = orgPath(organizationId) + contrPathView(contractId);
  return [
    {
      exact: true,
      linkTo: contractViewPath + sourcesMainSuffix,
      linkName: text.dataSources,
      permittedAction: permissionsStrValues.contract.update
    },
    {
      exact: true,
      linkTo: contractViewPath + sourcesGatesParamsSuffix,
      linkName: text.contractMenu.gatesParams,
      permittedAction: permissionsStrValues.contract.view
    }
  ];
}
const contractMenuItems = (organizationId:organizationIdType, contractId:contractIdType) => {
  const contractViewPath = orgPath(organizationId) + contrPathView(contractId);
  return [
    {
      linkTo: contractViewPath + mainDataSuffix,
      linkName: text.contractMenu.mainData,
      permittedAction: permissionsStrValues.contract.view
    },
    {
      linkTo: contractViewPath + addAgreementsSuffix,
      linkName: text.contractMenu.addAgreements,
      permittedAction: permissionsStrValues.addAgreement.view
    },
    {
      linkTo: contractViewPath + groupFinTermsSuffix,
      linkName: text.contractMenu.finTerms,
      permittedAction: permissionsStrValues.finTerm.view
    },
    {
      linkTo: contractViewPath + sourcesSuffix,
      linkName: text.contractMenu.sources,
      permittedAction: permissionsStrValues.finTerm.view
    },
    {
      linkTo: contractViewPath + agentsSuffix,
      linkName: text.contractMenu.relatedAgents,
      permittedAction: permissionsStrValues.contractLinkedAgent.view
    },
    {
      linkTo: contractViewPath + contractsSuffix,
      linkName: text.contractMenu.relatedContracts,
      permittedAction: permissionsStrValues.contractLinkedContract.view
    },
    {
      linkTo: contractViewPath + documentsSuffix,
      linkName: text.contractMenu.documents,
      permittedAction: permissionsStrValues.contractDocument.view
    },
    {
      linkTo: contractViewPath + actTemplatesSuffix,
      linkName: text.contractMenu.actTemplates,
      permittedAction: permissionsStrValues.actTemplate.view
    },
    {
      linkTo: contractViewPath + addServicesSuffix,
      linkName: text.contractMenu.addServices,
      permittedAction: permissionsStrValues.addService.view
    },
    {
      linkTo: contractViewPath + historySuffix,
      linkName: text.contractMenu.history,
      permittedAction: permissionsStrValues.contractHistory.view
    }
  ];
};
export default contractMenuItems;
export { contractMenuItems, contractFinTermsMenuItems };