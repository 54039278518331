//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../../../service/RenderOnReady/RenderOnReady";
import type {errorObject} from "../../../../../services/flowTypes/appFlowTypes";
import type {itemType} from "../../../../../services/flowTypes/componentFlowTypes";
import {
  onInitItemDocumentsState,
  onUpdateItemDocumentsList,
  setReadyToRender
} from "../../../../../services/flowTypes/propFnFlowTypes";
import api from "../../../../../services/apiInstance/api";
import type {agentId, contractId, folderId} from "../../../../../services/flowTypes/dataFlowTypes";
import apiErrorHandler from "../../../../../services/apiInstance/apiErrorHandler";
import itemDocumentsActions from "../../../../../store/organization/item/itemDocuments/itemDocumentsActions";
import { withRouter } from 'react-router-dom';

type Props = {
  itemType: itemType,
  setReadyToRender: setReadyToRender,
  itemId: agentId | contractId,
  onUpdateItemDocumentsList: onUpdateItemDocumentsList,
  onInitItemDocumentsState: onInitItemDocumentsState,
  folderId: folderId,
  isAllFilesShown: boolean
};
type State = {
  errorObject: errorObject
};
class DocumentsListFetcher extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null
    };
  }
  definePromise = () => {
    if (this.props.itemType === 'contract') {
      return api.getContractDocumentsList;
    }
    if (this.props.itemType === 'agent') {
      return api.getAgentDocumentsList;
    }
    if (this.props.itemType === 'organizations') {
      return api.getOrganizationDocumentsList;
    }
  };
  getDocumentsList = () => {
    this.props.onInitItemDocumentsState();
    const promise = this.definePromise();
    if (promise) {
      promise(this.props.itemId)
        .then(response => {
          let items = response.data;
          if(!this.props.isAllFilesShown){
            if(this.props.match.params.folderId){
              items = items.filter(item => item.folderId === +this.props.match.params.folderId);
            } else {
              items = items.filter(item => !item.folderId);
            }
          }
          this.props.onUpdateItemDocumentsList(items);
          this.props.setReadyToRender(items);
        })
        .catch(error => {
          this.setState({
            errorObject: apiErrorHandler(error)
          });
        });
    }
  };
  componentDidUpdate(prevProps){
    if ((prevProps.itemId !== this.props.itemId) || prevProps.isAllFilesShown !== this.props.isAllFilesShown) {
      this.getDocumentsList();
    }
  }
  componentDidMount(){
    this.getDocumentsList();
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={true} errorObject={this.state.errorObject}/>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    onInitItemDocumentsState: () => dispatch({type: itemDocumentsActions.ITEM_DOCUMENTS_STATE_INIT}),
    onUpdateItemDocumentsList: (value) => dispatch({type: itemDocumentsActions.ITEM_DOCUMENTS_LIST_SET, value: value})
  };
};

export default connect(null, mapDispatchToProps)(withRouter(DocumentsListFetcher));