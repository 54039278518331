//@flow
import * as React from 'react';
import {connect} from "react-redux";
import itemDataParamsEditActions
  from "../../../../../store/organization/item/itemDataParamsEdit/itemDataParamsEditActions";
import {onUpdateBlockFields} from "../../../../../services/flowTypes/propFnFlowTypes";
import type {paramsBlockFieldsComponents, paramsBlockIndex} from "../../../../../services/flowTypes/componentFlowTypes";
import type {itemDataParams, objectParamList, paramsBlockFields} from "../../../../../services/flowTypes/dataFlowTypes";
import CardBtn from "../../../../simple/Buttons/CardBtn/CardBtn";
import text from "../../../../../services/localization/text";
import type {modalIsShown} from "../../../../../services/flowTypes/appFlowTypes";
import CheckboxesModal from "../../../CheckboxesModal/CheckboxesModal";
import DragAndDrop from "../../../DragAndDrop/DragAndDrop";
import {contractEditPageIds} from "../../../../../tests/testIds";

type Props = {
  paramsBlockIndex: paramsBlockIndex,
  onUpdateBlockFields: onUpdateBlockFields,
  fields: paramsBlockFields,
  paramsList: objectParamList,
  paramsBlockFieldsComponents: paramsBlockFieldsComponents,
  itemDataParamsEdit: itemDataParams
};
type State = {
  modalIsShown: modalIsShown
};

class ParamsBlockFieldsHandler extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false
    };
  }
  showModal = () => {
    this.setState({modalIsShown: true});
  };
  hideModal = () => {
    this.setState({modalIsShown: false});
  };
  getCheckedListItems = (listItems) => {
    listItems.forEach(item => {
      if (item.type.includes('TEXT')) {
        item.textValue = '';
      } else {
        item.dateValue = '';
      }
      delete item.id
    });
    const fields = [...this.props.fields, ...listItems];
    this.props.onUpdateBlockFields(this.props.paramsBlockIndex, fields);
    this.hideModal();
  };
  setNewOrder = (fields) => {
    this.props.onUpdateBlockFields(this.props.paramsBlockIndex, fields);
  };
  getAllAppliedFields = () => {
    let allAppliedFields = [];
    for (let i=0; i < this.props.itemDataParamsEdit.length; i++) {
      allAppliedFields = [...allAppliedFields, ...this.props.itemDataParamsEdit[i].fields];
    }
    return allAppliedFields;
  };
  render() {
    const listItems = this.props.paramsList.filter(paramsListItem => {
      return !this.getAllAppliedFields().find(item => item.name === paramsListItem.name);
    });
    return (
      <>
        <DragAndDrop dragAndDropInitArr={this.props.fields} setNewOrder={this.setNewOrder}
                     dragAndDropItems={this.props.paramsBlockFieldsComponents}/>
        <CardBtn onClick={this.showModal} addClasses={'mt_1du mb_4du'}
                 id={contractEditPageIds.buttonBlockAddField(this.props.paramsBlockIndex)}>
          {text.addField}</CardBtn>
        {this.state.modalIsShown ? (
          <CheckboxesModal
            showSearch={true}
            isModalActionBtnDisabled={!listItems.length}
            listItems={listItems}
            getCheckedListItems={this.getCheckedListItems}
            hideModal={this.hideModal}
            modalTitle={text.addFieldsModalTitle}
            isLabelInsteadOfName={true}
          />
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    itemDataParamsEdit: state.organization.item.itemDataParamsEdit,
    fields: state.organization.item.itemDataParamsEdit[ownProps.paramsBlockIndex].fields,
    paramsList: state.organization.item.itemDataParamsList
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onUpdateBlockFields: (paramsBlockIndex, fields) => dispatch({
      type: itemDataParamsEditActions.PARAMS_BLOCK_FIELDS_UPDATE,
      value: { paramsBlockIndex, fields }
    })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ParamsBlockFieldsHandler);