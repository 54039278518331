import ability from "./permissions";
import checkWindowAppData from "./checkWindowAppData";

const updateAbilities = (organizationId) => {
  checkWindowAppData();
  const userPermissions = JSON.parse(window.APP_DATA.userPermissions);
  const orgPermissionsObj = userPermissions.find(item => item.organizationId === organizationId);
  if (orgPermissionsObj) {
    const globalPermissionsRules = ability.rules.filter(item => item.actions === 'useGlobalPermission');
    let rules = [];
    for(let i = 0; i < orgPermissionsObj.permissions.length; i++){
      rules.push({actions: 'usePermission', subject: [orgPermissionsObj.permissions[i]]});
    }
    rules.push({actions: 'useRole', subject: [orgPermissionsObj.role]});
    const updatedRules = [...globalPermissionsRules, ...rules];
    ability.update(updatedRules);
  } else {
    console.error('No permissions for current actions!');
  }
};
export default updateAbilities;