//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id, onChange} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './CheckboxSelect.css';
import type {propValue, selectOptions} from "../../../../services/flowTypes/componentFlowTypes";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import TextActionButton from "../../Buttons/TextActionButton/TextActionButton";
import text from "../../../../services/localization/text";

type Props = {
  id?: id,
  addClasses?: addClasses,
  selectOptions: selectOptions,
  selectValuesArr: Array<propValue>,
  onChange: onChange,
  isApplyBtnMode?: boolean
};
type State = {
  selectValuesArrInState: Array<propValue>
};
//!!!!!!!!!!!!!!!!!!!! isApplyBtnMode prop is prepared partially!
// According to layout clicked checkboxes has to be applied after apply btn click. But the question is how to define
// is checkbox only clicked or it is already applied

class CheckboxSelect extends React.Component <Props, State>{
  constructor(props: Props) {
    super(props);
    this.state = {
      selectValuesArrInState: []
    };
  }
  apply = () => {
    this.props.onChange(this.state.selectValuesArrInState);
    this.setState({selectValuesArrInState: []});
  };
  reset = () => {
    this.setState({selectValuesArrInState: []});
  };
  handleClick = (selectValuesArr) => {
    if (this.props.isApplyBtnMode) {
      this.setState({selectValuesArrInState: selectValuesArr});
    } else {
      this.props.onChange(selectValuesArr);
    }
  };
  componentDidMount(): * {
    //!!!!!!!!!!!!!!!!!!!! isApplyBtnMode prop is prepared partially!
    // According to layout clicked checkboxes has to be applied after apply btn click. But the question is how to define
    // is checkbox only clicked or it is already applied
  }

  render(): React$Node {
    const options = this.props.selectOptions.map((item,i) => {
      const checked = this.props.isApplyBtnMode ? this.state.selectValuesArrInState.includes(item.value) :
        this.props.selectValuesArr.includes(item.value);
      const onClick = () => {
        const selectValuesArr = [...this.props.selectValuesArr];
        if (checked) {
          const index = selectValuesArr.findIndex(el => el === item.value);
          selectValuesArr.splice(index, 1);
        } else {
          selectValuesArr.push(item.value);
        }
        this.handleClick(selectValuesArr);
      };
      const addClasses = i === this.props.selectOptions.length-1 ? "" : "mb_1du";
      return <CheckboxInput key={"CheckboxSelectItem"+i} addClasses={addClasses} checked={checked} onClick={onClick}
                            checkboxLabel={item.label}/>
    });
    return (
      <div id={this.props.id} className={baseClass("CheckboxSelect", this.props.addClasses)}>
        {options}
        {this.props.isApplyBtnMode ? (
          <>
            <TextActionButton addClasses={"ResetCheckboxSelect mb_1du mt_1du"} onClick={this.reset}>{text.reset}</TextActionButton>
            <TextActionButton addClasses={"ApplyCheckboxSelect"} onClick={this.apply}>{text.applyBtnLabel}</TextActionButton>
          </>
        ) : null}
      </div>
    );
  }


}

export default CheckboxSelect;