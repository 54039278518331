export const supPrefix = '/sup';
export const supMyOrgsPath = `/sup/my-organizations`;
export const supOrgsPath = `/sup/organizations`;
export const supUsersPath = `/sup/users`;
export const supSettPath = `/sup/settings`;
export const supOrgPath = (id) => `/sup/organizations/id/${id}`;
export const supOrgPathView = (id) => `/sup/organizations/id/${id}/view`;
export const supOrgPathEdit = (id) => `/sup/organizations/id/${id}/edit`;
export const supUserPath = (id) => `/sup/users/id/${id}`;
export const supUserPathView = (id) => `/sup/users/id/${id}/view`;
export const supUserPathEdit = (id) => `/sup/users/id/${id}/edit`;

export const orgPath = (organizationId) => `/organizations/${organizationId}`;
export const contrPath = (id) => `/contracts/id/${id}`;
export const contrPathView = (id) => `/contracts/id/${id}/view`;
export const contrPathEdit = (id) => `/contracts/id/${id}/edit`;
export const agentPath = (id) => `/agents/id/${id}`;
export const agentPathView = (id) => `/agents/id/${id}/view`;
export const agentPathEdit = (id) => `/agents/id/${id}/edit`;

export const reportPath = (id) => `/reports/id/${id}`;
export const reportPathView = (id) => `/reports/id/${id}/view`;
export const reportsListByStatusPath = (status) => `/reports/status/${status}`;

export const groupFinTermPath = (groupId) => `${groupFinTermsSuffix}/${groupId}`;
export const actTemplatePath = (actTemplateId) => `/act-templates/${actTemplateId}`;
export const addServicePath = (addServiceId) => `/add-services/${addServiceId}`;

export const newOrganizationSuffix = "/new-organization";
export const newUserSuffix = "/new-user";
export const newContractSuffix = "/contracts/new-contract";
export const newAgentSuffix = "/agents/new-agent";
export const newGroupFinTermSuffix = "/new-group-fin-term";
export const newActTemplateSuffix = "/new-act-template";

export const newAddServiceSuffix = "/new-add-service";
export const typeSuffix = "/type";
export const docsSuffix = "/docs";
export const sheetsSuffix = "/sheets";
export const confirmationSuffix = "/confirmation";
export const newAddService_typeSuffix = newAddServiceSuffix + typeSuffix;
export const newAddService_docsSuffix = newAddServiceSuffix + docsSuffix;
export const newAddService_sheetsSuffix = newAddServiceSuffix + sheetsSuffix;
export const newAddService_confirmationSuffix = newAddServiceSuffix + confirmationSuffix;


export const gatesParamsSuffix = '/gates-params';
export const contractsSuffix = '/contracts';
export const agentsSuffix = '/agents';
export const reportsSuffix = '/reports';
export const mainDataSuffix = '/main-data';
export const addAgreementsSuffix = '/add-agreements';
export const addServicesSuffix = '/add-services';
export const historySuffix = '/history';
export const adjustmentHistorySuffix = '/adjustment-history';
export const groupFinTermsBaseSuffix = '/fin-terms';
export const groupFinTermsGatesParamsSuffix = `${groupFinTermsBaseSuffix}${gatesParamsSuffix}`;
export const groupFinTermsSuffix = `${groupFinTermsBaseSuffix}/group-fin-terms`;
export const sourcesSuffix = '/sources';
export const sourcesMainSuffix = '/sources/main';
export const sourcesGatesParamsSuffix = `/sources${gatesParamsSuffix}`;
export const documentsSuffix = '/documents';
export const actTemplatesSuffix = '/act-templates';
export const userOrganizationsSuffix = '/user-organizations';
export const paramsSuffix = '/params';

export const orgSettingsSuffix = '/settings';
export const viewNotificationsSuffix = '/view/notifications';
export const editNotificationsSuffix = '/edit/notifications';
export const viewOrganizationSettingsSuffix = '/view/organization';
export const editOrganizationSettingsSuffix = '/edit/organization';
export const viewAddParamsSettingsSuffix = '/view/add-params';

export const historySearchSuffix = '?count=20&offset=0&onlyFinTerms=false&onlyActTemplates=false';