//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {containerMode, id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './AdaptiveFormContainer.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  fieldsArr: Array<React.Node>,
  containerMode: containerMode
};

const AdaptiveFormContainer = (props: Props) => {
  switch (props.containerMode) {
    case "twoHalfCardWidthFields":
      return (
        <div id={props.id}
             className={baseClass("AdaptiveFormContainer_twoHalfCardWidthFields", props.addClasses)}>
          <div className="AdaptiveFormContainer_twoHalfCardWidthFields__field-container">{props.fieldsArr[0]}</div>
          <div className="AdaptiveFormContainer_twoHalfCardWidthFields__field-container">{props.fieldsArr[1]}</div>
        </div>
      );
    case "twoHalfCardWidthFieldsModal":
      return (
        <div id={props.id}
             className={baseClass("AdaptiveFormContainer_twoHalfCardWidthFieldsModal", props.addClasses)}>
          <div className="AdaptiveFormContainer_twoHalfCardWidthFieldsModal__field-container">{props.fieldsArr[0]}</div>
          <div className="AdaptiveFormContainer_twoHalfCardWidthFieldsModal__field-container">{props.fieldsArr[1]}</div>
        </div>
      );
    case "twoHalfCardWidthFieldsJustifyContentNormal":
      return (
        <div id={props.id}
             className={baseClass("AdaptiveFormContainer_twoHalfCardWidthFieldsJustifyContentNormal", props.addClasses)}>
          <div className="AdaptiveFormContainer_twoHalfCardWidthFieldsJustifyContentNormal__field-container">{props.fieldsArr[0]}</div>
          <div className="AdaptiveFormContainer_twoHalfCardWidthFieldsJustifyContentNormal__field-container">{props.fieldsArr[1]}</div>
        </div>
      );
    case "twoHalfCardWidthFields1100":
      return (
        <div id={props.id}
             className={baseClass("AdaptiveFormContainer_twoHalfCardWidthFields1100", props.addClasses)}>
          <div className="AdaptiveFormContainer_twoHalfCardWidthFields1100__field-container">{props.fieldsArr[0]}</div>
          <div className="AdaptiveFormContainer_twoHalfCardWidthFields1100__field-container">{props.fieldsArr[1]}</div>
        </div>
      );
    case "twoHalfCardWidthFields1100JustifyContentNormal":
      return (
        <div id={props.id}
             className={baseClass("AdaptiveFormContainer_twoHalfCardWidthFields1100JustifyContentNormal", props.addClasses)}>
          <div className="AdaptiveFormContainer_twoHalfCardWidthFields1100JustifyContentNormal__field-container">{props.fieldsArr[0]}</div>
          <div className="AdaptiveFormContainer_twoHalfCardWidthFields1100JustifyContentNormal__field-container">{props.fieldsArr[1]}</div>
        </div>
      );
    case "twoQuarterCardWidthFields":
      return (
        <div id={props.id}
             className={baseClass("AdaptiveFormContainer_twoQuarterCardWidthFields", props.addClasses)}>
          <div className="AdaptiveFormContainer_twoQuarterCardWidthFields__field-container">{props.fieldsArr[0]}</div>
          <div className="AdaptiveFormContainer_twoQuarterCardWidthFields__field-container">{props.fieldsArr[1]}</div>
        </div>
      );
    case "threeQuarterCardWidthFields":
      return (
        <div id={props.id}
             className={baseClass("AdaptiveFormContainer_threeQuarterCardWidthFields", props.addClasses)}>
          <div className="AdaptiveFormContainer_threeQuarterCardWidthFields__field-container">{props.fieldsArr[0]}</div>
          <div className="AdaptiveFormContainer_threeQuarterCardWidthFields__field-container">{props.fieldsArr[1]}</div>
          <div className="AdaptiveFormContainer_threeQuarterCardWidthFields__field-container">{props.fieldsArr[2]}</div>
        </div>
      );
    case "threeQuarterCardWidth":
      return (
        <div id={props.id}
             className={baseClass("AdaptiveFormContainer_threeQuarterCardWidth", props.addClasses)}>
          {props.fieldsArr}
        </div>
      );
    default:
      return <div className="flex">{props.fieldsArr}</div>
  }

};

export default AdaptiveFormContainer;