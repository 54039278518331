//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {objectParamList} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import type {isSupLevel, itemType} from "../../../../../../../../services/flowTypes/componentFlowTypes";
import ItemParamCard from "./ItemParamCard/ItemParamCard";
import {initUpdateOrganizationParams} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import EmptyListV2 from "../../../../../../../../components/simple/Containers/EmptyList/EmptyListV2";

type Props = {
  objectParamList: objectParamList,
  itemType: itemType,
  initUpdateOrganizationParams: initUpdateOrganizationParams,
  isSupLevel?: isSupLevel
};

class ItemParamsList extends React.Component <Props> {
  render() {
    const itemParamsCard = this.props.objectParamList === null ? null : this.props.objectParamList.map((item, i) => {
      return (
        <ItemParamCard
          key={'ItemParamCard'+i}
          objectParam={item}
          itemType={this.props.itemType}
          paramIndex={i}
          initUpdateOrganizationParams={this.props.initUpdateOrganizationParams}
          isSupLevel={this.props.isSupLevel}
        />
      );
    });
    return (
      itemParamsCard === null ? null : itemParamsCard.length ? itemParamsCard : (<EmptyListV2/>)
      );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    objectParamList: state.organization.organizationParams[ownProps.itemType]
  };
};
export default connect(mapStateToProps, null)(ItemParamsList);