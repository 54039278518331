//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {disabled, id, inputValue, onChange, placeholder} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './FormTagSelect.css';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import type {tagsList} from "../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../services/localization/text";
import createOption from "../../../../utils/createOption";
import {contractEditPageIds} from "../../../../tests/testIds";
import type {creationIsProhibited, noOptionsMessage} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  id?: id,
  delimiters?: [string],
  mask?: string,
  addClasses?: addClasses,
  value: tagsList,
  onChange?: onChange,
  options?: tagsList,
  disabled?: disabled,
  idForTest?: id,
  creationIsProhibited?: creationIsProhibited,
  placeholder?: placeholder,
  noOptionsMessage?: noOptionsMessage,
  menuHeader?: React.Node
};
type State = {
  inputValue: inputValue
};

class FormTagSelect extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      inputValue: '',
      delimiters: [...this.props.delimiters || [], 'Tab']
    };
  }

  handleChange = (value: tagsList) => {
    const newValue = value.map(item => {
      return item.value;
    });
    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  };
  handleInputChange = (value: string) => {
    let inputValue;
    if (this.props.mask) {
      const allowedCharacters = new RegExp(`[^${this.props.mask}]`, 'g');
      inputValue = value.replace(allowedCharacters, '');
    } else {
      inputValue = value
    }
    if (!this.props.delimiters || !this.props.delimiters.some(delimiter => inputValue.startsWith(delimiter))) {
      this.setState({inputValue: inputValue.trim()});
    }
  };
  handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const {inputValue} = this.state;
    if (!inputValue) return;

    if(this.state.delimiters.includes(event.key)) {
      // case 'Enter':
      // console.group('Value Added');
      this.setState({
        inputValue: ''
      }, () => {
        const oldValue = this.props.value.slice();
        const newValue = [...oldValue, inputValue];
        if (this.props.onChange) {
          this.props.onChange(newValue);
        }
      });
      event.preventDefault();
    }
  };
  render(){
    const customTagSelectStyles = {
      control: (provided, state) => ({
        ...provided,
        borderRadius: '2px',
        width: this.props.disabled ? '100%' : '80%',
        // backgroundColor: this.props.disabled ? 'var(--item-back-color)' : 'transparent',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        minHeight: '50px',
        // boxShadow: this.props.disabled ? 'unset' : '0 3px 5px 0 rgba(0,0,0,.1)',
        border: "1px solid var(--sidebar-border-color)",
        padding: 'var(--distance-unit) 0 0 var(--distance-unit)'
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: 'transparent'
      }),
      menu: (provided, state) => ({
        ...provided,
        boxShadow: "0px 10px 40px -20px #0000009e"
      }),
      menuList: (provided, state) => ({
        ...provided,
        backgroundColor: '#ffffff'
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        padding: '0'
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: 'var(--dark-text-color)',
        fontFamily: 'var(--main-font-family)',
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '40px'
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'var(--main-accent-color)' : 'var(--main-text-color)',
        backgroundColor: state.isFocused ? 'var(--item-back-color)' : '#ffffff',
        fontFamily: 'var(--main-font-family)',
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '40px'
      }),
      multiValue: (provided, state) => ({
        ...provided,
        lineHeight: '34px',
        backgroundColor: 'var(--tag-label-color)',
        borderRadius: '5px',
        margin: '0 var(--distance-unit) var(--distance-unit) 0'
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        lineHeight: '34px',
        color: 'var(--main-accent-color)',
        fontFamily: 'var(--main-font-family)',
        fontSize: '10px',
        fontWeight: '600',
        textTransform: 'uppercase',
        paddingLeft: '20px',
        padding: '0px 15px 0px 20px'
      }),
      multiValueRemove: (provided, state) => ({
        ...provided,
        display: this.props.disabled ? 'none' : 'flex',
        lineHeight: '34px',
        color: state.isHovered ? 'var(--warning-color)' : 'var(--main-accent-color)',
        fontSize: '18px',
        paddingLeft: '13px',
        paddingRight: '13px',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'var(--tag-label-del-ico-hover-color)'
        }
      }),
    };
    const value = this.props.value.map(item =>{ return createOption(item);});
    const options = this.props.options ? this.props.options : [];
    const Menu = (menuProps) => {
      return this.props.menuHeader ? (
        <components.Menu {...menuProps}>
          {this.props.menuHeader}
          {menuProps.children}
        </components.Menu>

      ) : <components.Menu {...menuProps}/>;
    };
    const MultiValueRemove = (props) => {
      const innerProps = {...props.innerProps, id: contractEditPageIds.buttonRemoveTag(props.data.value)};
      const newProps = {...props, innerProps: innerProps};
      return (
        <components.MultiValueRemove {...newProps}/>
      );
    };
    const ClearIndicator = (props) => {
      const { innerProps: { ref, ...restInnerProps } } = props;
      const id = this.props.idForTest ? this.props.idForTest + 'BtnRemoveAll' : 'BtnRemoveAll';
      return (
        <div {...restInnerProps} ref={ref} >
          <span className="FormTagSelect__clear-all-container">
            <span className="FormTagSelect__clear-all" id={id}>{text.clearAll}</span>
          </span>
        </div>
      );
    };
    const dropdownIndicatorClassName = this.props.disabled ? "FormTagSelect__enter-ico FormTagSelect__enter-ico_hidden"
      : "FormTagSelect__enter-ico";
    const transformedComponents = {
      DropdownIndicator: ()=> {return (<span className={dropdownIndicatorClassName + " mr_2du"}/>)},
      ClearIndicator: ClearIndicator,
      MultiValueRemove: MultiValueRemove,
      Menu: Menu
      // ClearIndicator: () => {return (<span className="FormTagSelect__clear-all">{text.clearAll}</span>)}
    };
    const creationIsProhibited = this.props.creationIsProhibited ? this.props.creationIsProhibited : false;
    const noOptionsMessage = this.props.noOptionsMessage ? this.props.noOptionsMessage : ()=>null;
    return (
      <div id={this.props.id} className={baseClass("FormTagSelect", this.props.addClasses)}>
        {creationIsProhibited ? (
          <Select
            components={transformedComponents}
            isDisabled={this.props.disabled}
            inputValue={this.state.inputValue}
            isClearable
            isMulti
            onChange={this.handleChange}
            onInputChange={this.handleInputChange}
            placeholder={this.props.placeholder}
            value={value}
            options={options}
            styles={customTagSelectStyles}
            inputId={this.props.idForTest}
            noOptionsMessage={noOptionsMessage}
            // defaultMenuIsOpen={true}
          />
        ) : (
          <CreatableSelect
            components={transformedComponents}
            isDisabled={this.props.disabled}
            inputValue={this.state.inputValue}
            isClearable
            isMulti
            onChange={this.handleChange}
            onInputChange={this.handleInputChange}
            onKeyDown={this.handleKeyDown}
            placeholder={this.props.placeholder}
            value={value}
            options={options}
            styles={customTagSelectStyles}
            inputId={this.props.idForTest}
            noOptionsMessage={noOptionsMessage}
            // defaultMenuIsOpen={true}
          />
        )}
      </div>
    );
  }
}

export default FormTagSelect;