import {
  supSettPath, viewNotificationsSuffix
} from "../services/urlStrings/urlStrings";
import text from "../services/localization/text";

const genSettingsMenuItems = () => {
  return [
    {
      linkTo: supSettPath + viewNotificationsSuffix,
      linkName: text.notificationMenuTitle,
      isPermitted: true
    }
  ];
};
export default genSettingsMenuItems;