//@flow
import * as React from 'react';
import type {organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import organizationMenuItems from "../../../../../../configData/organizationMenuItems";
import ColumnMenu from "../../../../../../components/simple/Containers/RowMenu/ColumnMenu";
import SideLeftContainer from "../../../../../../components/simple/Containers/SideLeftContainer/SideLeftContainer";

type Props = {
  organizationId: organizationId
};

const OrganizationMenu = (props:Props) => {
  const menuItemsData = organizationMenuItems(props.organizationId);
  return <SideLeftContainer>
    <ColumnMenu menuItemsData={menuItemsData} isGlobalPermission={true} addClasses={"OrganizationMenu"}/>
  </SideLeftContainer>
};
export default OrganizationMenu;