//@flow
import * as React from 'react';
import {onClickOutside} from "../../../services/flowTypes/propFnFlowTypes";
import type {children, elementRefType} from "../../../services/flowTypes/appFlowTypes";
import type {isActive} from "../../../services/flowTypes/componentFlowTypes";

type Props = {
  onClickOutside: onClickOutside,
  children: children,
  isActive: isActive
};

class CloseOnClickOutside extends React.Component <Props> {
  ref: elementRefType;
  constructor(props: Props) {
    super(props);
    this.ref = React.createRef();
  }
  handleOutsideClick = (e:SyntheticEvent<HTMLElement>) => {
    if (!this.ref.current.contains(e.target)) {
      this.props.onClickOutside();
    }
  };
  componentDidUpdate(prevProps){
    if (prevProps.isActive !== this.props.isActive && this.props.isActive) {
      document.addEventListener('mousedown', this.handleOutsideClick);
    }
    if (prevProps.isActive !== this.props.isActive && !this.props.isActive) {
      document.removeEventListener('mousedown', this.handleOutsideClick);
    }
  }
  componentWillUnmount(){
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }
  render() {
    return (
     <div className="CloseOnClickOutside" ref={this.ref}>
       {this.props.children}
     </div>
    );
  }
}

export default CloseOnClickOutside;    