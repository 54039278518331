//@flow
import * as React from 'react';
import RenderOnReady from "../../../../components/service/RenderOnReady/RenderOnReady";
import type {errorObject, isReadyToRender, locationType, match, history} from "../../../../services/flowTypes/appFlowTypes";
import type {organizationId, organizationName} from "../../../../services/flowTypes/dataFlowTypes";
import connect from "react-redux/es/connect/connect";
import organizationMainDataActions from "../../../../store/organization/organizationMainData/organizationMainDataActions";
import {onGetOrganizationMainData} from "../../../../services/flowTypes/propFnFlowTypes";
import concatIdName from "../../../../utils/concatIdName";
import api from "../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../services/apiInstance/apiErrorHandler";
import GetDataOnMountAndUpdate from "../../../../components/service/GetDataOnMountAndUpdate/GetDataOnMountAndUpdate";
import FixedHeaderContent from "../../../../components/simple/Containers/FixedHeader/FixedHeaderContent";
import OrganizationButtonsRoutes from "../../../../components/service/Routes/OrganizationButtonsRoutes";
import type {isSupLevel} from "../../../../services/flowTypes/componentFlowTypes";
import FixedSubHeader from "../../../../components/simple/Containers/FixedHeader/FixedSubHeader";
import {mainDataSuffix, supOrgPathView} from "../../../../services/urlStrings/urlStrings";
import text from "../../../../services/localization/text";
import OrganizationMenu from "./organization/OrganizationMenu/OrganizationMenu";

type Props = {
  match: match,
  onGetOrganizationMainData: onGetOrganizationMainData,
  organizationId: organizationId,
  name: organizationName,
  location: locationType,
  isSupLevel?: isSupLevel,
  history: history
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};

class Organization extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      errorObject: null
    };
  }
  defineTitles = () => {
    if (!this.props.organizationId) {
      return {headerTitle: "", headerTitleLinkTo: "", subHeaderTitle: ""};
    }
    const organizationTitle = concatIdName(this.props.organizationId, this.props.name);
    const organizationTitleLinkTo =  supOrgPathView(this.props.organizationId) + mainDataSuffix;

    return this.props.location.pathname.includes("edit") ?
      {headerTitle: organizationTitle, headerTitleLinkTo: organizationTitleLinkTo, subHeaderTitle: text.editTitleLabel}
      :  {headerTitle: text.organizations, headerTitleLinkTo: null, subHeaderTitle: organizationTitle}
      ;
  };
  setOrganizationMainData = (organizationMainData) => {
    this.props.onGetOrganizationMainData(organizationMainData);
    this.setState({isReadyToRender: true});
  };
  getOrganizationMainData = () => {
    const organizationId = this.props.isSupLevel ? +this.props.match.params.id : +this.props.match.params.organizationId;
    api.getOrganizationById(organizationId)
      .then(response => {
        this.setOrganizationMainData(response.data);
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };
  render(){
    const {headerTitle, headerTitleLinkTo, subHeaderTitle} = this.defineTitles();
    return (
      this.props.isSupLevel ? (
        <GetDataOnMountAndUpdate itemId={this.props.match.params.id} getDataOnMountAndUpdate={this.getOrganizationMainData}>
          <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}>

              <FixedHeaderContent title={headerTitle} titleLinkTo={headerTitleLinkTo} history={this.props.history}
                                  isSupLevel={true}/>
              <FixedSubHeader title={subHeaderTitle}
                              buttons={<OrganizationButtonsRoutes organizationId={this.props.organizationId}/>}
                              history={this.props.history}/>
              {this.props.location.pathname.includes("edit") || this.props.location.pathname.includes("/new-") ?
                null : (
                  <OrganizationMenu organizationId={this.props.organizationId}/>
                )}
          </RenderOnReady>
        </GetDataOnMountAndUpdate>
      ) : (
        <GetDataOnMountAndUpdate itemId={this.props.match.params.id} getDataOnMountAndUpdate={this.getOrganizationMainData}>
          <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        </GetDataOnMountAndUpdate>

        )

    );
  }
}
const mapStateToProps = state => {
  return {
    organizationId: state.organization.mainData.id,
    name: state.organization.mainData.name
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetOrganizationMainData: (organizationMainData) => dispatch({
      type: organizationMainDataActions.ORGANIZATION_MAIN_DATA_SET,
      value: organizationMainData})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Organization);