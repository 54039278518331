//@flow
import * as React from 'react';
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import "./GatesParamsCard.css"
import Heading4 from "../../../../../../components/simple/TextComponents/Heading4/Heading4";
import Text1 from "../../../../../../components/simple/TextComponents/Text1/Text1";
import type {
  contractId,
  gatesParamsItem,
  gatesParamsResponse
} from "../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../services/localization/text";
import DataListContainer from "../../../../../../components/simple/Containers/DataListContainer/DataListContainer";
import update from 'immutability-helper';
import formatDate from "../../../../../../utils/formatDate";
import formatTime from "../../../../../../utils/formatTime";
import GatesParamsCardGroupFinTerms from "./GatesParamsCardGroupFinTerms";
import OnOffIcon from "../../../../../../components/simple/Icons/OnOffIcon/OnOffIcon";
import GatesParamsCardComment from "./GatesParamsCardComment";
import TurnOnOffBtn from "../../../../../../components/simple/Buttons/TurnOnOffBtn/TurnOnOffBtn";
import TextOverflowHandler
  from "../../../../../../components/simple/Containers/TextOverflowHandler/TextOverflowHandler";

type Props = {
  gatesParamsItem: gatesParamsItem,
  gatesParamsItemIndex: number,
  gatesParamsResponse: gatesParamsResponse,
  updateContractParams: Function,
  contractId: contractId,
  checkbox: React.Node,
  ignoreGates: boolean
};

class GatesParamsCard extends React.Component <Props> {
  // defineIsIgnoredLabel = (isIgnored) => {
  //   return isIgnored ? text.isIgnoredTrueValue : text.isIgnoredFalseValue;
  // };
  isGateDisableForIgnore = () => {
    let hasFinTermsOrIgnored = false;
    for (let j=0; j < this.props.gatesParamsItem.terminals.length; j++) {
      hasFinTermsOrIgnored = this.props.gatesParamsItem.isIgnored ?
        !!this.props.gatesParamsItem.terminals[j].groupFinTerms.length
        || !this.props.gatesParamsItem.terminals[j].isIgnored
        :
        !!this.props.gatesParamsItem.terminals[j].groupFinTerms.length
        || this.props.gatesParamsItem.terminals[j].isIgnored;
      if (hasFinTermsOrIgnored) {
        return hasFinTermsOrIgnored;
      }
    }
    return hasFinTermsOrIgnored;
  };
  isAllTerminalsIgnored = (terminals) => {
    let isAllIgnored = true;
    for (let i=0; i < terminals.length; i++ ) {
      if (!terminals[i].isIgnored) {
        return false;
      }
    }
    return isAllIgnored;
  };
  handleIgnoreTerminalClick = (terminalIndex, isIgnoredUpdated) => {
    const terminalsToUpdate = this.props.gatesParamsResponse.gatesParams[this.props.gatesParamsItemIndex].terminals;
    const isPrevTerminalsAllIgnored = this.isAllTerminalsIgnored(terminalsToUpdate);
    const terminals = update(terminalsToUpdate, {[terminalIndex]: {isIgnored: {$set: isIgnoredUpdated}}});
    let gatesParamsResponse = {};
    if (this.isAllTerminalsIgnored(terminals)) {
      gatesParamsResponse = update(this.props.gatesParamsResponse, {gatesParams: {[this.props.gatesParamsItemIndex]:
            {
              terminals: {$set: terminals},
              isIgnored: {$set: isIgnoredUpdated}
            }}} );
    } else if (isPrevTerminalsAllIgnored && !isIgnoredUpdated) {
      gatesParamsResponse = update(this.props.gatesParamsResponse, {gatesParams: {[this.props.gatesParamsItemIndex]:
            {
              terminals: {$set: terminals},
              isIgnored: {$set: isIgnoredUpdated}
            }}} );
    } else {
      gatesParamsResponse = update(this.props.gatesParamsResponse, {gatesParams: {[this.props.gatesParamsItemIndex]:
            {terminals: {[terminalIndex]: {isIgnored: {$set: isIgnoredUpdated}}}}}} );
    }

    this.props.updateContractParams(gatesParamsResponse);
  };
  handleIgnoreGateClick = (isIgnoredUpdated) => {
    const terminals = this.props.gatesParamsItem.terminals.map(item => {
      return {...item, isIgnored: isIgnoredUpdated};
    });
    const gatesParamsResponse = update(this.props.gatesParamsResponse, {gatesParams: { [this.props.gatesParamsItemIndex]:
          {
            isIgnored: {$set: isIgnoredUpdated},
            terminals: {$set: terminals}
          }}});
    this.props.updateContractParams(gatesParamsResponse);
  };
  updateTerminal = (terminal, terminalIndex) => {
    const gatesParamsResponse = update(this.props.gatesParamsResponse, {gatesParams: {[this.props.gatesParamsItemIndex]:
          {terminals: {[terminalIndex]: {$set: terminal}}}}} );
    this.props.updateContractParams(gatesParamsResponse);
  };
  render() {
    const terminals = this.props.gatesParamsItem.terminals.map((item,i) => {
      return (
        <div className="GatesParamsCard__terminal" key={"terminal"+i}>
          <div className="GatesParamsCard__terminalRowLeft">
            <div className="GatesParamsCard__terminalStatus">
              <OnOffIcon isOn={item.terminalStatus === "E"}/>
            </div>
            <Text1 addClasses="GatesParamsCard__terminalId Text1_lh20">{text.terminalLabelPrefix + item.terminalId}</Text1>
            <GatesParamsCardGroupFinTerms contractId={this.props.contractId} terminal={item}
                                          disabled={item.isIgnored && !item.groupFinTerms.length}
                                          source={this.props.gatesParamsItem.source}
                                          updateContractParams={this.props.updateContractParams}
                                          gatesParamsResponse={this.props.gatesParamsResponse}
                                          gatesParamsItemIndex={this.props.gatesParamsItemIndex } terminalIndex={i}
            />
            <div className="GatesParamsCard__alertIcon">
              {item.groupFinTerms.length || item.isIgnored ? null : (
                <div className={`GatesParamsCard__isIgnoredIcon GatesParamsCard__isIgnoredIcon_${item.isIgnored}`}/>
              )}
            </div>
          </div>
          {this.props.ignoreGates ? null : (<div className="GatesParamsCard__terminalRowRight">
            <GatesParamsCardComment terminalIndex={i} terminal={item} updateTerminal={this.updateTerminal}/>

            <div className="GatesParamsCard__ignore">

              <Text1 addClasses={"Text1_card-label-color Text1_lh20"}>{text.ignoreBtnLabel}</Text1>
              <TurnOnOffBtn isOn={item.isIgnored} onClick={(e)=>this.handleIgnoreTerminalClick(i, !item.isIgnored)}
                            disabled={item.groupFinTerms.length && !item.isIgnored}
                            addClasses={"ml_2du"}/>
            </div>
          </div>)}
        </div>
      );
    });
    const cardClassName = this.props.gatesParamsItem.isError && !this.props.ignoreGates ? "NCard_error" : "";
    return (
      <NCard addClasses={`mb_2du NCard__wide-width ${cardClassName}`}>
        <div className="GatesParamsCard__header mb_2du">
          <div className="GatesParamsCard__headerLeft">
            {this.props.ignoreGates ? null : this.props.checkbox}
            <div className="GatesParamsCard__headerLeftContainer">
              <div className="GatesParamsCard__gateId">
                <Heading4 addClasses={'Heading4_card-label-color Heading4_lh16 mb_1du'}>
                  {text.gatesParamsCardHeader.gateId}</Heading4>
                <Text1 addClasses={"Text1_card-label-color"}>{this.props.gatesParamsItem.gateId}</Text1>
              </div>
              <div className="GatesParamsCard__source">
                <Heading4 addClasses={'Heading4_card-label-color Heading4_lh16 mb_1du'}>
                  {text.gatesParamsCardHeader.source}</Heading4>
                <Text1>
                  {text.sourceNames[this.props.gatesParamsItem.source]}</Text1>
              </div>
              <div className="GatesParamsCard__currency">
                {this.props.gatesParamsItem.currency ? (
                  <>
                    <Heading4 addClasses={'Heading4_card-label-color Heading4_lh16 mb_1du'}>
                      {text.gatesParamsCardHeader.currency}</Heading4>
                    <Text1>
                      {this.props.gatesParamsItem.currency}</Text1>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className="GatesParamsCard__headerRight">
            <div className="GatesParamsCard__gateName">
              {this.props.gatesParamsItem.gateName ? <>
                <Heading4 addClasses={'Heading4_card-label-color Heading4_lh16 mb_1du'}>
                  {text.gatesParamsCardHeader.gateName}</Heading4>
                <Text1 addClasses={"Text1_card-label-color"}>
                  <TextOverflowHandler addClasses={""}>
                    {this.props.gatesParamsItem.gateName}
                  </TextOverflowHandler>
                </Text1>
              </> : null}
            </div>
            {this.props.ignoreGates ? null : (<div className="GatesParamsCard__gateStatus">
                <Heading4 addClasses={'Heading4_card-label-color Heading4_lh16 mb_1du'}>
                  {text.gatesParamsCardHeader.gateStatus}</Heading4>
                <Text1 addClasses={`Text1_${this.props.gatesParamsItem.gateStatus} tt_uc flex Text1_fs12`}>
                  <OnOffIcon isOn={this.props.gatesParamsItem.gateStatus === "E"} addClasses={"mr_1du"}/>
                  {text.gateStatusLabel[this.props.gatesParamsItem.gateStatus]}</Text1>
              </div>)}
          </div>
        </div>
        <DataListContainer itemsList={terminals}/>
        <div className="GatesParamsCard__bottom">
          <div className="GatesParamsCard__dates">
            <div className="GatesParamsCard__gateCreationDate">
              <Heading4 addClasses={'Heading4_card-label-color Heading4_lh16 mr_1du'}>
                {text.gatesParamsCardHeader.gateCreationDate}</Heading4>
              <Text1 addClasses={"Text1_card-label-color"}>{formatDate(this.props.gatesParamsItem.gateCreationDate)
              + " " + formatTime(this.props.gatesParamsItem.gateCreationDate)}</Text1>
            </div>
            <div className="GatesParamsCard__gateChangeDate">
              <Heading4 addClasses={'Heading4_card-label-color Heading4_lh16 mr_1du'}>
                {text.gatesParamsCardHeader.gateChangeDate}</Heading4>
              <Text1 addClasses={"Text1_card-label-color"}>{formatDate(this.props.gatesParamsItem.gateChangeDate)
              + " " + formatTime(this.props.gatesParamsItem.gateChangeDate)}</Text1>
            </div>
          </div>
          {this.props.ignoreGates ? null : (
              <div className="GatesParamsCard__gateIgnoreLabel">
                <Text1 addClasses={"Text1_card-label-color Text1_lh20"}>{text.ignoreBtnLabel}</Text1>
                <TurnOnOffBtn isOn={this.props.gatesParamsItem.isIgnored}
                              disabled={this.isGateDisableForIgnore()}
                              onClick={(e) => this.handleIgnoreGateClick(!this.props.gatesParamsItem.isIgnored)}
                              addClasses={"ml_2du"}/>
                {/*<Heading4 addClasses={'Heading4_card-label-color Heading4_lh16 mb_1du'}>*/}
                {/*  {text.gatesParamsCardHeader.gateIgnored}</Heading4>*/}
                {/*<Text1 addClasses={"Text1_card-label-color"}>*/}
                {/*  {this.props.gatesParamsItem.isIgnored ? text.yesLabel : text.noLabel}</Text1>*/}
              </div>
          )}
        </div>

      </NCard>
    );
  }
}

export default GatesParamsCard;
