//@flow
import * as React from 'react';
import {
  mainDataSuffix,
  newUserSuffix,
  supUserPathEdit, supUserPathView, supUsersPath, userOrganizationsSuffix
} from "../../../services/urlStrings/urlStrings";
import PrivateRoute from "./PrivateRoute";
import ability from "../../../services/permissions/permissions";
import type {userId} from "../../../services/flowTypes/dataFlowTypes";
import UserMainDataControls
  from "../../../supPages/Users/pages/User/pages/UserMainDataEdit/userMainDataEdit/UserMainDataControls";
import UserEditButton from "../../../supPages/Users/pages/User/pages/UserMainDataView/UserEditButton";
import AddUserOrganizationsButton
  from "../../../supPages/Users/pages/User/pages/UserOrganizations/EditUserOrganizations/AddUserOrganizationsButton";
import {globalPermissionsStrValues} from "../../../configData/permissionsStrValues";

type Props = {
  userId: userId
};

const UserButtonsRoutes = (props: Props) => {
  return (
    <>
      <PrivateRoute exact path={supUsersPath + newUserSuffix} addProps={{userId: props.userId}}
                    component={UserMainDataControls}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.user.create)} />
      <PrivateRoute exact path={supUserPathView(':id') + mainDataSuffix} addProps={{userId: props.userId}}
                    component={UserEditButton}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.user.update)} />
      <PrivateRoute exact path={supUserPathEdit(':id') + mainDataSuffix} addProps={{userId: props.userId}}
                    component={UserMainDataControls}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.user.update)} />
      <PrivateRoute exact path={supUserPathView(':id') + userOrganizationsSuffix} addProps={{userId: props.userId}}
                    component={AddUserOrganizationsButton}
                    canUseRoute={ability.can('useGlobalPermission', globalPermissionsStrValues.user.update)} />
    </>
  );
};

export default UserButtonsRoutes;