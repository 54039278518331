//@flow
import organizationsSearchResultsActions from './organizationsSearchResultsActions';
import type {action} from "../../../services/flowTypes/appFlowTypes";
import type {organizationsSearchResults} from "../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = {
  total: 0,
  organizations: null
};

const organizationsSearchResultsReducer = (state:organizationsSearchResults = initialState, action:reducerAction) => {
  switch (action.type) {
    case organizationsSearchResultsActions.SET_ORGANIZATIONS_INIT_STATE:
      return initialState;
    case organizationsSearchResultsActions.GET_ORGANIZATIONS:
      return action.value;
    default:
      return state;
  }
};

export default organizationsSearchResultsReducer;