//@flow
import * as React from 'react';
import './LogoutContainer.css';
import BtnLogout from "../../simple/Buttons/BtnLogout/BtnLogout";
import type {errorObject, id, isReadyToRender} from "../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import api from "../../../services/apiInstance/api";
import RenderOnReady from "../../service/RenderOnReady/RenderOnReady";
import apiErrorHandler from "../../../services/apiInstance/apiErrorHandler";
import {mainPageIds} from "../../../tests/testIds";
import getCsrf from "../../../utils/getCsrf";
import ButtonTooltipContainer from "../../simple/Containers/ButtonTooltipContainer/ButtonTooltipContainer";
import text from "../../../services/localization/text";

type Props = {
  id?: id,
  addClasses?: addClasses
};
type State = {
  isReadyToRender: isReadyToRender,
  errorObject: errorObject
};


class LogoutContainer extends React.Component <Props, State> {
  constructor(props: Props){
    super(props);
    this.state = {
      errorObject: null,
      isReadyToRender: true
    }
  }
  logout = () => {
    this.setState({isReadyToRender: false});
    const csrf = getCsrf();
    api.logout(csrf)
      .then(response => {
        this.setState({isReadyToRender: true});
        window.location = '/login';
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
        window.location = '/login';
      });
  };
  render() {
    return (
      <div className={this.props.addClasses}>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        <ButtonTooltipContainer tooltipContent={text.logOut} tooltipPosition={'top'}>
          <BtnLogout onClick={this.logout}
                     id={mainPageIds.buttonLogout}
          />
        </ButtonTooltipContainer>
      </div>
    );
  }
}

export default LogoutContainer;