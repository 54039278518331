export const HistoryCardDefProps = {
  formattedHistoryItem: {
    date: "16.12.2019",
    formattedHistoryItemUserActions: [
      {
        userName: 'Young Accountant',
        formattedActions: [
          {id: 0, formattedTime: '10:34', formattedActionDescription: 'Тип контракта изменен',
            formattedLinkLabel: '#2 Лицензионное соглашение', formattedLink: '/', isUnviewed: true},
          {id: 1, formattedTime: '10:24', formattedActionDescription: 'Тип контракта изменен',
            formattedLinkLabel: '#2 Лицензионное соглашение', formattedLink: '/', isUnviewed: true}
        ]
      },
      {
        userName: 'Experienced Accountant',
        formattedActions: [
          {id: 3, formattedTime: '9:34', formattedActionDescription: 'Тип контракта изменен',
            formattedLinkLabel: '#2 Лицензионное соглашение', formattedLink: '/', isUnviewed: false},
          {id: 4, formattedTime: '8:24', formattedActionDescription: 'Тип контракта изменен',
            formattedLinkLabel: '#2 Лицензионное соглашение', formattedLink: '/', isUnviewed: false}
        ]
      }
    ]
  }
};