//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './FormDateRangePicker.css';
import type {itemDate} from "../../../../services/flowTypes/dataFlowTypes";
import type {dateRangePickerFocusedInput, itemDateId} from "../../../../services/flowTypes/componentFlowTypes";
import {onDatesChange} from "../../../../services/flowTypes/propFnFlowTypes";
import text from "../../../../services/localization/text";
import moment, {locale} from '../../../../services/moment/momentInstance';
import BtnEditAct from "../../Buttons/BtnEditAct/BtnEditAct";
import {
  getPrevMonthEndDate,
  getPrevMonthStartDate,
  getPrevQuarterEndDate,
  getPrevQuarterStartDate
} from "../../../../utils/getPrevMonthDates";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import valid_DD_MM_YYYY from "../../../../utils/dates";

type Props = {
  id?: id,
  addClasses?: addClasses,
  startDate: itemDate,
  endDate: itemDate,
  startDateId?: itemDateId,
  endDateId?: itemDateId,
  onDatesChange: onDatesChange,
  minimumNights?: number,
  onWrongDateRange?: Function
};
type State = {
  focusedInput: dateRangePickerFocusedInput
};
type dateObjType = {
  startDate: itemDate,
  endDate: itemDate
};



const today = moment();
const yesterdayDate = moment().subtract(1, 'day');
export const formattedYesterdayValue = moment(yesterdayDate).format('YYYY-MM-DD');
export const datePresets = [{
    text: text.today,
    start: today,
    end: today
  },
  {
    text: text.yesterday,
    start: yesterdayDate,
    end: yesterdayDate
  },
  {
    text: text.currentMonth,
    start: moment().startOf('month'),
    end: moment().endOf('month')
  },
  {
    text: text.prevMonth,
    start: getPrevMonthStartDate(),
    end: getPrevMonthEndDate()
}];
export const quarterDatePresets = [
  {
    text: text.currentQuarter,
    start: moment().startOf('quarter'),
    end: moment().endOf('quarter')
  },
  {
    text: text.prevQuarter,
    start: getPrevQuarterStartDate(),
    end: getPrevQuarterEndDate()
  }
];


class FormDateRangePicker extends React.Component <Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      focusedInput: null
    };
  }
  setDateOnBtnClick = (dateObj:dateObjType) => {
    this.onDatesChange(dateObj);
    this.setState({focusedInput: null});
  };
  renderCalendarInfo = () => {
    const btns = datePresets.map((item, i) => {
      const startDate = item.start;
      const endDate = item.end;
      const onClick = () => this.setDateOnBtnClick({startDate, endDate});
      return (<BtnEditAct key={'btn'+i} onClick={onClick} addClasses={'ml_2du'}>{item.text}</BtnEditAct>)
    });
    return (
      <div className={'FormDateRangePicker__CalendarInfo'}>{btns}</div>
    );
  };
  onDatesChange = (datesArr) => {
    const [startDate, endDate] = datesArr;
    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';
    const formattedDate = {
      startDate: formattedStartDate,
      endDate: formattedEndDate
    };
    if (moment(endDate).isBefore(startDate)) {
      this.props.onWrongDateRange && this.props.onWrongDateRange()
    } else {
      this.props.onDatesChange(formattedDate);
    }
  };

  onInputChange = (event)=> {
    const value = event.target.value
    if (value && value.indexOf('-') > -1) {
      const [start, end] = value.split('-')
      let datesArr = [valid_DD_MM_YYYY(start), valid_DD_MM_YYYY(end)];
      datesArr[0] && datesArr[1] && this.onDatesChange(datesArr);
    }
  }

  render(){
    const startDate = this.props.startDate === "" || this.props.startDate === "Invalid date" ? null : new Date(this.props.startDate);
    const endDate = this.props.endDate === "" || this.props.endDate === "Invalid date" ? null : new Date(this.props.endDate);
    const monthLang = process.env.LANG === 'ru' ? 'ru-RU' : 'en-GB';

    return (
      <div id={this.props.id} className={baseClass("FormDateRangePicker", this.props.addClasses)}>
        <DatePicker
            selected={startDate}
            onChange={this.onDatesChange}
            startDate={startDate}
            onChangeRaw={this.onInputChange}
            endDate={endDate}
            selectsRange
            showIcon={true}
            dateFormat="dd.MM.yyyy"
            monthsShown={2}
            calendarStartDay={1}
            locale={locale}
            renderCustomHeader={({
                                   monthDate,
                                   customHeaderCount,
                                   decreaseMonth,
                                   increaseMonth,
                                 }) => (
                <div>
                  <button
                      aria-label="Previous Month"
                      className={
                        "react-datepicker__navigation react-datepicker__navigation--previous"
                      }
                      style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
                      onClick={decreaseMonth}
                  >
            <span
                className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                }
            >
              {"<"}
            </span>
                  </button>
                  <span className="react-datepicker__current-month">
            {monthDate.toLocaleString(monthLang, {
              month: "long",
              year: "numeric",
            })}
          </span>
                  <button
                      aria-label="Next Month"
                      className={
                        "react-datepicker__navigation react-datepicker__navigation--next"
                      }
                      style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
                      onClick={increaseMonth}
                  >
            <span
                className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                }
            >
              {">"}
            </span>
                  </button>
                </div>
            )}
        />
      </div>
    );
  }
}

export default FormDateRangePicker;
