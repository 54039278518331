//@flow
import * as React from 'react';
import type {children, disabled, id, onClick} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import './BtnEditAct.css';

type Props = {
  id?: id,
  addClasses?: addClasses,
  children: children,
  onClick: onClick,
  disabled?: disabled,
  isChooseActive?: boolean
};

const BtnEditAct = (props: Props) => {
  const className = props.isChooseActive ? "BtnEditAct BtnEditAct_isChooseActive" : "BtnEditAct";
  return (
    <button type="button" id={props.id} onClick={props.onClick} disabled={props.disabled}
         className={baseClass(className, props.addClasses)}>
      {props.children}</button>
  );
};

export default BtnEditAct;