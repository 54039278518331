//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {children} from "../../../../../../../services/flowTypes/appFlowTypes";
import type {addServiceSheet} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {onGetAddServiceProp} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import addServiceEditActions from "../../../../../../../store/organization/item/addServiceEdit/addServiceEditActions";
import {getImmutableCopyOfSheets} from "../AddServiceSheetsEdit";

type Props = {
  children: children,
  sheets: Array<addServiceSheet>,
  onGetAddServiceProp: onGetAddServiceProp
};

class GetSheetsOnUnmount extends React.Component <Props> {
  componentWillUnmount(): * {
    this.props.onGetAddServiceProp('sheets', getImmutableCopyOfSheets(this.props.sheets));
  }
  render() {
    return (this.props.children);
  }
}

const mapStateToProps = state => {
  return {
    sheets: state.organization.item.sheets,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetAddServiceProp: (prop, data) => dispatch({type: addServiceEditActions.ADD_SERVICE_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GetSheetsOnUnmount);    