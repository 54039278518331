//@flow
import * as React from 'react';
import ConnectedFormGroup from "../ConnectedFormGroup/ConnectedFormGroup";
import addServiceEditActions from "../../../store/organization/item/addServiceEdit/addServiceEditActions";
import {connect} from "react-redux";
import type {
  formFieldType, isSearchable, noOptionsMessage,
  propName,
  propValue,
  selectOptions,
  sheetIndex
} from "../../../services/flowTypes/componentFlowTypes";
import type {disabled, id, isReadyToRender, label, placeholder} from "../../../services/flowTypes/appFlowTypes";
import type {
  onDeleteValidationStateFormProp,
  onGetAddServiceProp,
  onGetSheetProp
} from "../../../services/flowTypes/propFnFlowTypes";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import sheetsActions from "../../../store/organization/item/sheets/sheetsActions";
import addServiceValidationScheme from "../../../configData/addServiceValidationScheme";
import RenderOnReady from "../../service/RenderOnReady/RenderOnReady";
import validationActions from "../../../store/validation/validationActions";
import type {actTemplateId, actTemplates} from "../../../services/flowTypes/dataFlowTypes";

type Props = {
  sheetIndex: sheetIndex,
  formFieldType: formFieldType,
  propName: propName,
  propValue: propValue,
  onGetSheetProp: onGetSheetProp,
  label: label,
  options?: selectOptions,
  addClasses?: addClasses,
  placeholder?: placeholder,
  noOptionsMessage?: noOptionsMessage,
  idForTest?: id,
  isSearchable?: isSearchable,
  selectInputId?: id,
  onGetAddServiceProp: onGetAddServiceProp,
  onDeleteValidationStateFormProp: onDeleteValidationStateFormProp,
  actTemplateId: actTemplateId,
  actTemplatesView: actTemplates
};
type State = {
  propName: propName,
  isReadyToRender: isReadyToRender,
  disabled: disabled
};

class SheetFormGroup extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      propName: '',
      disabled: false
    };
  }
  setPropName = () => {
    return 'sheet' + this.props.sheetIndex + this.props.propName;
  };
  handleGetSheetProp = (prop, data) => {
    this.props.onGetSheetProp(this.props.sheetIndex, this.props.propName, data);
  };
  componentDidMount(): * {
    this.setState({
      propName: this.setPropName(),
      isReadyToRender: true
    });
    if (this.props.actTemplateId) {
      const actTemplateObj = this.props.actTemplatesView.find(item => item.id === this.props.actTemplateId);
      if (!actTemplateObj) {
        throw new Error('ActTemplate is not found to determine currency!')
      }
      const actTemplateCurrency = actTemplateObj ? actTemplateObj.currency : '';
      this.props.onGetSheetProp(this.props.sheetIndex, this.props.propName, actTemplateCurrency);
      this.setState({disabled: true});
    } else {
      this.props.onGetSheetProp(this.props.sheetIndex, this.props.propName, this.props.propValue);
    }
  }
  componentWillUnmount(){
    this.props.onDeleteValidationStateFormProp(this.state.propName);
  }
  render() {
    const propValidationPatternsArr = addServiceValidationScheme.sheets[this.props.propName] ?
      addServiceValidationScheme.sheets[this.props.propName] : null;
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
        <ConnectedFormGroup
          formFieldType={this.props.formFieldType}
          propName={this.state.propName}
          propValue={this.props.propValue}
          onGetPropValue={this.handleGetSheetProp}
          label={this.props.label}
          options={this.props.options}
          propValidationPatternsArr={propValidationPatternsArr}
          addClasses={this.props.addClasses}
          idForTest={this.props.idForTest}
          placeholder={this.props.placeholder}
          noOptionsMessage={this.props.noOptionsMessage}
          isSearchable={this.props.isSearchable}
          selectInputId={this.props.selectInputId}
          disabled={this.state.disabled}
        />
      </RenderOnReady>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    propValue: state.organization.item.sheets[ownProps.sheetIndex][ownProps.propName],
    actTemplateId: state.organization.item.addServiceEdit.actTemplateId,
    actTemplatesView: state.organization.item.actTemplatesView
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetSheetProp: (sheetIndex, propName, propValue) => dispatch({type: sheetsActions.SHEETS_PROP_UPDATE, value: {
        sheetIndex: sheetIndex,
        propName: propName,
        propValue: propValue
      }}),
    onGetAddServiceProp: (prop, data) => dispatch({type: addServiceEditActions.ADD_SERVICE_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onDeleteValidationStateFormProp: (prop) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_DELETE, value: prop})

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SheetFormGroup);