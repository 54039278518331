//@flow
import * as React from 'react';
import type {disabled, id, onChange, placeholder, label, readOnly} from "../../../services/flowTypes/appFlowTypes";
import type {
  formFieldType,
  inputIco,
  inputMaxLength,
  isClearable,
  isSearchable,
  noOptionsMessage,
  openDirection,
  propValue,
  selectOptions,
  selectedOption,
  creationIsProhibited,
  inputDelimiter,
  filterSelectBtn,
  isStaticMode,
  isOptionOverflowVisible, isTagLabelEditable, dateRangePeriodType
} from "../../../services/flowTypes/componentFlowTypes";
import NInput from "../../simple/FormComponents/NInput/NInput";
import NSelect from "../../simple/FormComponents/NSelect/NSelect";
import Textarea from "../../simple/FormComponents/Textarea/Textarea";
import FormTagSelect from "../../simple/FormComponents/FormTagSelect/FormTagSelect";
import FormSingleDatePicker from "../../simple/FormComponents/FormSingleDatePicker/FormSingleDatePicker";
import NSwitcher from "../../simple/FormComponents/NSwitcher/NSwitcher";
import SelectFullCard from "../../simple/FormComponents/SelectFullCard/SelectFullCard";
import './DynamicTypeFormField.css';
import type {itemDate, paramMask, tagsList} from "../../../services/flowTypes/dataFlowTypes";
import FormCreatableSelect from "../../simple/FormComponents/FormCreatableSelect/FormCreatableSelect";
import formatInputWithDelimiter from "../../../utils/formatInputWithDelimiter";
import CheckboxInput from "../../simple/FormComponents/CheckboxInput/CheckboxInput";
import FormDateRangePicker from "../../simple/FormComponents/FormDateRangePicker/FormDateRangePicker";
import FilterSelect from "../../simple/FormComponents/FilterSelect/FilterSelect";
import type {onDateRangeCheckboxChange} from "../../../services/flowTypes/propFnFlowTypes";
import FilterInput from "../../simple/FormComponents/FilterInput/FilterInput";
import FilterWrappedDateRangePicker
  from "../../simple/FormComponents/WrappedDateRangePicker/FilterWrappedDateRangePicker";
import FilterMultiSelect from "../../simple/FormComponents/FilterMultiSelect/FilterMultiSelect";

type Props = {
  formFieldType: formFieldType,
  propValue: propValue,
  onChange: onChange,
  options?: selectOptions,
  inputMaxLength?: inputMaxLength,
  idForTest?: id,
  isClearable?: isClearable,
  placeholder?: placeholder,
  noOptionsMessage?: noOptionsMessage,
  inputIco?: inputIco,
  disabled?: disabled,
  isSearchable?: isSearchable,
  selectInputId?: id,
  openDirection?: openDirection,
  creationIsProhibited?: creationIsProhibited,
  inputDelimiter?: inputDelimiter,
  label?: label,
  mask?: paramMask,
  startDateId?: id,
  endDateId?: id,
  propNameLabel?: label,
  filterSelectBtn?: filterSelectBtn,
  isStaticMode?: isStaticMode,
  isOptionOverflowVisible?: isOptionOverflowVisible,
  dateRangeCheckboxValue?: propValue,
  dateRangeCheckboxLabel?: label,
  onDateRangeCheckboxChange?: onDateRangeCheckboxChange,
  isTagLabelEditable?: isTagLabelEditable,
  preferableDateRangePeriodType?: dateRangePeriodType,
  readOnly?: readOnly,
  dataUiTestId?: string
};
type selectedOptionType = selectedOption;
type State = {
  prevValue: propValue
};

class DynamicTypeFormField extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      prevValue: ''
    };
  }
  handleInputChange = (e:SyntheticEvent<HTMLInputElement>) => {
    this.props.onChange(e.currentTarget.value);
  };
  handleSelectChange = (selectedOption:selectedOptionType) => {
    const value = selectedOption === null ? null : selectedOption.value;
    this.props.onChange(value);
  };
  handleTagSelectChange = (value:tagsList) => {
    this.props.onChange(value);
  };
  handleDelimitedListChange = (value:tagsList) => {
    const stringValue = value.join(',');
    this.props.onChange(stringValue);
  };
  handleDateChange = (value:itemDate) => {
    this.props.onChange(value);
  };
  handleDateRangeChange = ({startDate, endDate}) => {
    this.props.onChange({startDate, endDate});
  };
  handleInputWithDelimiterChange = (e:SyntheticEvent<HTMLInputElement>) => {
    const value = formatInputWithDelimiter(e.currentTarget.value, this.props.inputDelimiter, this.props.mask, this.state.prevValue);
    this.setState({prevValue: value});
    this.props.onChange(value);
  };
  handleCheckboxInputChange = () => {
    const value = this.props.propValue === 'true' ? 'false' : 'true';
    this.props.onChange(value);
  };
  handleBooleanCheckboxInputChange = () => {
    this.props.onChange(!this.props.propValue);
  };
  handleDateRangeCheckboxInputChange = () => {
    this.props.onDateRangeCheckboxChange(!this.props.dateRangeCheckboxValue);
  };
  defineFormField = () => {
    const hasIcoAddClasses = this.props.inputIco ? 'pr_forIco' : '';
    const selectedOption = this.props.options ?
      this.props.options.find(item => item.value === this.props.propValue) : null;
    const selectValue = selectedOption ? selectedOption : null;
    switch (this.props.formFieldType) {
      case 'input':
        return (<NInput id={this.props.idForTest} value={this.props.propValue} onChange={this.handleInputChange}
                        inputMaxLength={this.props.inputMaxLength} addClasses={hasIcoAddClasses} placeholder={this.props.placeholder}/>);
      case 'input-with-delimiter':
        const propValueWithDelimiter = this.props.propValue.length ? this.props.propValue.split(',') : [];
        return (<FormTagSelect value={propValueWithDelimiter} onChange={this.handleDelimitedListChange}
                               mask={this.props.mask}
                               delimiters={[',']}
                               creationIsProhibited={false} placeholder={this.props.placeholder}
                               options={this.props.options} idForTest={this.props.idForTest}/>);
      case 'textarea':
        return (<Textarea id={this.props.idForTest} value={this.props.propValue} onChange={this.handleInputChange}
                          inputMaxLength={this.props.inputMaxLength}/>);
      case 'select':
        return (<NSelect value={selectValue} options={this.props.options} id={this.props.idForTest}
                         onChange={this.handleSelectChange} isClearable={this.props.isClearable}
                         isSearchable={this.props.isSearchable} isDisabled={this.props.disabled}
                         inputId={this.props.selectInputId}
                         placeholder={this.props.placeholder} noOptionsMessage={this.props.noOptionsMessage}/>);
      case 'select-full-card':
        const selectedSFCOption = this.props.options ?
          this.props.options.find(item => item.value === this.props.propValue) : null;
        const selectSFCValue = selectedSFCOption ? selectedSFCOption : null;
        return (<SelectFullCard value={selectSFCValue} onChange={this.handleSelectChange} placeholder={this.props.placeholder}
                                inputId={this.props.selectInputId} isSearchable={this.props.isSearchable}
                                isOptionOverflowVisible={this.props.isOptionOverflowVisible}
                                noOptionsMessage={this.props.noOptionsMessage}
                                options={this.props.options} id={this.props.idForTest} isDisabled={this.props.disabled}/>);
      case 'switcher':
        const selectedSwitcherOption = this.props.options ?
          this.props.options.find(item => item.value === this.props.propValue) : null;
        return (<NSwitcher options={this.props.options} onChange={this.handleSelectChange} disabled={this.props.disabled}
                           value={selectedSwitcherOption} id={this.props.idForTest}/>);
      case 'tagSelect':
        return (<FormTagSelect value={this.props.propValue} onChange={this.handleTagSelectChange}
                               creationIsProhibited={this.props.creationIsProhibited} placeholder={this.props.placeholder}
                               options={this.props.options} idForTest={this.props.idForTest}/>);
      case 'delimitedList':
        const propValue = this.props.propValue.length ? this.props.propValue.split(',') : [];
        return (<FormTagSelect value={propValue} onChange={this.handleDelimitedListChange}
                               creationIsProhibited={this.props.creationIsProhibited} placeholder={this.props.placeholder}
                               options={this.props.options} idForTest={this.props.idForTest}/>);
      case 'creatableSelect':
        return (<FormCreatableSelect value={this.props.propValue} onChange={this.handleTagSelectChange}
                                     creationIsProhibited={this.props.creationIsProhibited} isDisabled={this.props.disabled}
                                     placeholder={this.props.placeholder} isTagLabelEditable={this.props.isTagLabelEditable}
                                     options={this.props.options} idForTest={this.props.idForTest}/>);
      case 'date':
        return (<FormSingleDatePicker dateId={this.props.idForTest} date={this.props.propValue}
                                      onDatesChange={this.handleDateChange} openDirection={this.props.openDirection}/>);
      case 'dateRange':
        return (<FormDateRangePicker
          startDate={this.props.propValue.startDate}
          endDate={this.props.propValue.endDate}
          onDatesChange={this.handleDateRangeChange}
          startDateId={this.props.startDateId}
          endDateId={this.props.endDateId}
        />);
      case 'checkbox':
        const checked = this.props.propValue === 'true';
        return (<CheckboxInput checked={checked} onClick={this.handleCheckboxInputChange} id={this.props.idForTest}
                               checkboxLabel={this.props.label}/>);
      case 'checkbox-boolean':
        return (<CheckboxInput checked={this.props.propValue} onClick={this.handleBooleanCheckboxInputChange} id={this.props.idForTest}
                               checkboxLabel={this.props.label} readOnly={this.props.readOnly}/>);
      case 'filter-select':
        return (<FilterSelect value={selectValue} options={this.props.options} id={this.props.idForTest}
                         onChange={this.handleSelectChange} isClearable={this.props.isClearable}
                         isSearchable={true} propNameLabel={this.props.propNameLabel}
                         inputId={this.props.selectInputId} filterSelectBtn={this.props.filterSelectBtn}
                              isStaticMode={this.props.isStaticMode}
                         placeholder={this.props.placeholder} noOptionsMessage={this.props.noOptionsMessage}/>);
      case 'filter-delimitedList':
        const filterDelimitedListValue = this.props.propValue.length ? this.props.propValue.split(',') : [];
        return (<FilterMultiSelect value={filterDelimitedListValue} onChange={this.handleDelimitedListChange}
                                   placeholder={this.props.placeholder} propNameLabel={this.props.propNameLabel}
                                   isStaticMode={this.props.isStaticMode} filterSelectBtn={this.props.filterSelectBtn}
                                   options={this.props.options} idForTest={this.props.idForTest}/>);
      case 'filter-dateRange':
        const addClassesFilterDateRange = this.props.isStaticMode ?
          "FormDateRangePicker_in_Filters FormDateRangePicker_in_Filters_static-mode"
          : "FormDateRangePicker_in_Filters";
        return (
          <div className={'flex'}>
            <div className="filter-dateRange-propNameLabel">{this.props.propNameLabel + ":"}</div>
            <FormDateRangePicker
              startDate={this.props.propValue.startDate}
              endDate={this.props.propValue.endDate}
              onDatesChange={this.handleDateRangeChange}
              startDateId={this.props.idForTest + "_startDateId"}
              endDateId={this.props.idForTest + "_endDateId"}
              addClasses={addClassesFilterDateRange}
            />
            {this.props.dateRangeCheckboxLabel ? (
              <div className="filter-dateRange-strictPeriodCheckbox ml_2px">
                <CheckboxInput checked={this.props.dateRangeCheckboxValue} onClick={this.handleDateRangeCheckboxInputChange}
                               id={this.props.idForTest + "_dateRangeCheckbox"}
                               checkboxLabel={this.props.dateRangeCheckboxLabel}/>
              </div>
            ) : null}
            <div className="ml_2px">
              {this.props.filterSelectBtn}
            </div>
          </div>
        );
      case 'filter-wrapped-date-range':
        const dateRangeCheckboxLabel = this.props.dateRangeCheckboxLabel ? (
          <div className="filter-dateRange-strictPeriodCheckbox ml_2px">
            <CheckboxInput checked={this.props.dateRangeCheckboxValue} onClick={this.handleDateRangeCheckboxInputChange}
                           id={this.props.idForTest + "_dateRangeCheckbox"}
                           checkboxLabel={this.props.dateRangeCheckboxLabel}/>
          </div>
        ) : null;
        return (<FilterWrappedDateRangePicker propValue={this.props.propValue} onChange={this.handleDateRangeChange}
                                              propNameLabel={this.props.propNameLabel}
                                              dateRangeCheckboxLabel={dateRangeCheckboxLabel}
                                              preferableDateRangePeriodType={this.props.preferableDateRangePeriodType}
                                              filterSelectBtn={this.props.filterSelectBtn} />)
      case 'filter-input':
        return (<FilterInput id={this.props.idForTest} value={this.props.propValue} onChange={this.handleInputChange}
                        inputMaxLength={this.props.inputMaxLength} placeholder={this.props.placeholder}
                             filterSelectBtn={this.props.filterSelectBtn}
                             propNameLabel={this.props.propNameLabel}/>);
      default:
        return null;
    }
  };
  componentDidMount() {
    this.setState({prevValue: this.props.propValue});
  }
  render() {
    const formField = this.defineFormField();
    return (
      <div className="DynamicTypeFormField__container">
        {formField}
        {this.props.inputIco}
      </div>

    );
  }
}

export default DynamicTypeFormField;    