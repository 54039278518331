//@flow
import gatesParamsSearchActions from './gatesParamsSearchActions';
import type {action} from "../../../../services/flowTypes/appFlowTypes";
import type {gatesParamsSearch} from "../../../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = {};

const gatesParamsSearchReducer = (state:gatesParamsSearch = initialState, action:reducerAction) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case gatesParamsSearchActions.GATES_PARAMS_SEARCH_INIT_STATE_SET:
      return {...initialState, ...action.value};
    case gatesParamsSearchActions.GATES_PARAMS_SEARCH_PROP_UPDATE:
      return {...newState, ...action.value};
    case gatesParamsSearchActions.GATES_PARAMS_SEARCH_PROP_DELETE:
      for (let i=0; i < action.value.length; i++) {
        delete newState[action.value[i]];
      }
      return newState;
    default:
      return state;
  }
};

export default gatesParamsSearchReducer;