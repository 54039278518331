//@flow
import * as React from 'react';
import './ArrowIcon.css';
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";

type Props = {
  addClasses?: addClasses
};
const ArrowIcon = (props:Props) => {
  return (<div className={baseClass("ArrowIcon mr_2du ml_2du", props.addClasses)}/>);
};

export default ArrowIcon;    