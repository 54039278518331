//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './AgentCard.css';
import type {
  agent,
  agentLinkedTypeList, agentRelated, contractAgentsItem
} from "../../../../services/flowTypes/dataFlowTypes";
import type {
  delAgentIsDisabled, disabledReasonTooltipText, handledAgent, isLinkedItemsMode,
  isWideMode,
  linkId,
  onDelAgentClick
} from "../../../../services/flowTypes/componentFlowTypes";
import connect from "react-redux/es/connect/connect";
import ListItemCard from "../../../../components/composed/ListItemCard/ListItemCard";
import type {onEditBtnClick} from "../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  agent: agent | contractAgentsItem,
  agentRelated: agentRelated,
  onDelAgentClick?: onDelAgentClick,
  delAgentIsDisabled?: delAgentIsDisabled,
  linkId?: linkId,
  buttonDeleteAgentId?: id,
  onEditBtnClick?: onEditBtnClick,
  btnEditId?: id,
  agentLinkedTypeList: agentLinkedTypeList,
  isWideMode?: isWideMode,
  handledAgent: handledAgent,
  isLinkedItemsMode?: isLinkedItemsMode,
  btnDelDisabledReasonTooltipText?: disabledReasonTooltipText
};

class AgentCard extends React.Component <Props, void > {
  render(){
    const cardTiles = [
      {tileIconType: 'finterms', numberLabel: this.props.agentRelated.fintermsCount}
    ];
    if (this.props.agentRelated.hasOwnProperty('contractsCount')) {
        cardTiles.push({tileIconType: 'contracts', numberLabel: this.props.agentRelated.contractsCount});
    }
    const linkTypeObj = this.props.agentLinkedTypeList.find(item => item.id === this.props.agent.typeId);
    const linkType = linkTypeObj ? linkTypeObj.name : null;
    return (<ListItemCard
        itemId={this.props.agent.id}
        itemName={this.props.agent.name}
        id={this.props.id}
        addClasses={baseClass("AgentCard", this.props.addClasses)}
        linkId={this.props.linkId}
        linkTo={this.props.handledAgent.linkToView}
        // createDate={this.props.handledAgent.createDate}
        validityTerm={this.props.handledAgent.createDate}
        itemStatus={this.props.agent.status}
        itemStatusLabel={this.props.handledAgent.itemStatusLabel}
        tags={this.props.handledAgent.tags}
        cardTiles={cardTiles}
        onDeleteBtnClick={this.props.onDelAgentClick}
        btnDelId={this.props.buttonDeleteAgentId}
        btnDelIsDisabled={this.props.delAgentIsDisabled}
        onEditBtnClick={this.props.onEditBtnClick}
        btnEditId={this.props.btnEditId}
        linkDate={this.props.agent.linkDate}
        linkType={linkType}
        linkDescription={this.props.agent.description}
        isWideMode={this.props.isWideMode}
        isLinkedItemsMode={this.props.isLinkedItemsMode}
        btnDelDisabledReasonTooltipText={this.props.btnDelDisabledReasonTooltipText}
        warningComponent={this.props.handledAgent.warningComponent}
    />);
  }
}

const mapStateToProps = state => {
  return {
    agentLinkedTypeList: state.organization.organizationData.statuses.agentLinkedTypeList
  }
};
export default connect(mapStateToProps, null)(AgentCard);