//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {disabled, id, label, onChange, placeholder} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import Select, { components } from 'react-select';
import type {tagsList} from "../../../../services/flowTypes/dataFlowTypes";
import createOption from "../../../../utils/createOption";
import type {
  filterSelectBtn, isStaticMode,
  noOptionsMessage
} from "../../../../services/flowTypes/componentFlowTypes";
import Heading4 from "../../TextComponents/Heading4/Heading4";
import AddFilterBtn from "../../Buttons/AddFilterBtn/AddFilterBtn";
import FilterSelectIcon from "../../Icons/FilterSelectIcon/FilterSelectIcon";

type Props = {
  id?: id,
  addClasses?: addClasses,
  value: tagsList,
  onChange?: onChange,
  options?: tagsList,
  disabled?: disabled,
  idForTest?: id,
  placeholder?: placeholder,
  noOptionsMessage?: noOptionsMessage,
  menuHeader?: React.Node,
  propNameLabel?: label,
  filterSelectBtn?: filterSelectBtn,
  isStaticMode?: isStaticMode
};

class FilterMultiSelect extends React.Component <Props> {
  handleChange = (value:tagsList) => {
    const newValue = value.map(item => {
      return item.value;
    });
    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  };

  render(){
    const customTagSelectStyles = {
      container: (provided, state) => ({
        ...provided,
        display: 'flex'
      }),
      control: (provided, state) => ({
        ...provided,
        borderRadius: this.props.isStaticMode ? 'var(--border-radius)' : 'var(--filter-select-border-radius)',
        backgroundColor: 'var(--filter-select-back-color)',
        // backgroundColor: 'var(--item-back-color)',
        border: '1px solid transparent',
        height: 'var(--el-height)',
        cursor: 'pointer',
        padding: '0 16px',
        boxShadow: 'unset'
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: 'transparent'
      }),
      menu: (provided, state) => ({
        ...provided,
        marginTop: '0',
        width: 'auto',
        boxShadow: "var(--select-box-shadow)"
      }),
      menuList: (provided, state) => ({
        ...provided,
        backgroundColor: '#ffffff'
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        padding: '2px 0'
      }),
      // singleValue: (provided, state) => ({
      //   ...provided,
      //   color: 'var(--main-accent-color)',
      //   fontFamily: 'var(--main-font-family)',
      //   fontSize: '14px',
      //   fontWeight: 'normal',
      //   lineHeight: '36px',
      //   position: 'static',
      //   transform: 'unset',
      //   maxWidth: '100%'
      // }),
      placeholder: (provided, state) => ({
        ...provided,
        position: 'static',
        transform: 'unset',
        color: 'var(--main-text-color)',
        fontFamily: 'var(--main-font-family)',
        fontSize: '14px',
        fontWeight: '700',
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'var(--main-accent-color)' : 'var(--main-text-color)',
        backgroundColor: state.isFocused ? 'var(--item-back-color)' : '#ffffff',
        fontFamily: 'var(--main-font-family)',
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '40px'
      }),
      multiValue: (provided, state) => ({
        ...provided,
        lineHeight: '34px',
        backgroundColor: 'var(--filter-select-back-color)',
        borderRadius: '5px',
        margin: '0 var(--distance-unit) 0 0',

      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        lineHeight: '34px',
        color: 'var(--card-label-color)',
        fontFamily: 'var(--main-font-family)',
        fontSize: '14px',
        fontWeight: 'normal',
        padding: '0px'
      }),
      multiValueRemove: (provided, state) => ({
        ...provided,
        display: this.props.disabled ? 'none' : 'flex',
        lineHeight: '34px',
        color: state.isHovered ? 'var(--warning-color)' : 'var(--main-accent-color)',
        fontSize: '18px',
        paddingLeft: '13px',
        paddingRight: '13px',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'var(--tag-label-del-ico-hover-color)'
        }
      }),
    };
    const value = this.props.value.map(item =>{ return createOption(item);});
    const options = this.props.options ? this.props.options : [];
    const Menu = (menuProps) => {
      return this.props.menuHeader ? (
        <components.Menu {...menuProps}>
          {this.props.menuHeader}
          {menuProps.children}
        </components.Menu>

      ) : <components.Menu {...menuProps}/>;
    };
    // const SingleValue = (singleValueProps) => {
    //   const children = this.props.propNameLabel ?
    //     <div className={"flex ai_c"}>
    //       <Heading4 addClasses={"mr_1du"}>{this.props.propNameLabel + ": "}</Heading4>
    //       <Text1 addClasses={"Text1_card-label-color"}>{singleValueProps.children}</Text1></div>
    //     : singleValueProps.children;
    //   const newProps = {...singleValueProps, children: children};
    //   return (
    //     <components.SingleValue {...newProps} />
    //   );
    // };
    const Control = (controlProps) => {
      console.log(controlProps);
      const children = this.props.propNameLabel && controlProps.hasValue ?
        <div className={"flex ai_c"}>
          <Heading4 addClasses={"mr_1du"}>{this.props.propNameLabel + ": "}</Heading4>
          <div className={"flex"}>{controlProps.children}</div></div>
        : controlProps.children;
      const newProps = {...controlProps, children: children};
      return (
        <components.Control {...newProps} />
      );
    };
    // const MultiValue = (multiValueProps) => {
    //   const children = this.props.propNameLabel ?
    //     <div className={"flex ai_c"}>
    //       <Heading4 addClasses={"mr_1du"}>{this.props.propNameLabel + ": "}</Heading4>
    //       <Text1 addClasses={"Text1_card-label-color"}>{multiValueProps.children}</Text1></div>
    //     : multiValueProps.children;
    //   const newProps = {...multiValueProps, children: children};
    //   return (
    //     <components.MultiValue {...newProps} />
    //   );
    // };
    // const MultiValueRemove = (props) => {
    //   const innerProps = {...props.innerProps, id: contractEditPageIds.buttonRemoveTag(props.data.value)};
    //   const newProps = {...props, innerProps: innerProps};
    //   return (
    //     <components.MultiValueRemove {...newProps}/>
    //   );
    // };

    // const ClearIndicator = (props) => {
    //   const { innerProps: { ref, ...restInnerProps } } = props;
    //   const id = this.props.idForTest ? this.props.idForTest + 'BtnRemoveAll' : 'BtnRemoveAll';
    //   return (
    //     <div {...restInnerProps} ref={ref} >
    //       <span className="FormTagSelect__clear-all-container">
    //         <span className="FormTagSelect__clear-all" id={id}>{text.clearAll}</span>
    //       </span>
    //     </div>
    //   );
    // };
    const DropdownIndicator = (dropdownIndicatorProps) => {
      return this.props.isAddFilterMode ? (
        <div className={'AddFilterBtn__wrapper'}>
          <AddFilterBtn onClick={null} isActive={dropdownIndicatorProps.isFocused}/>
        </div>
      ) : (<FilterSelectIcon/>)};
    const transformedComponents = {
      DropdownIndicator: DropdownIndicator,
      // ClearIndicator: ClearIndicator,
      // SingleValue: SingleValue,
      // MultiValue: MultiValue,
      Control: Control,
      // MultiValueRemove: MultiValueRemove,
      Menu: Menu
      // ClearIndicator: () => {return (<span className="FormTagSelect__clear-all">{text.clearAll}</span>)}
    };
    const noOptionsMessage = this.props.noOptionsMessage ? this.props.noOptionsMessage : ()=>null;
    return (
      <div className={'flex'}>
        <div id={this.props.id} className={baseClass("FilterMultiSelect", this.props.addClasses)}>
          <Select
            components={transformedComponents}
            isDisabled={this.props.disabled}
            isClearable
            isMulti
            onChange={this.handleChange}
            placeholder={this.props.placeholder}
            value={value}
            options={options}
            styles={customTagSelectStyles}
            inputId={this.props.idForTest}
            noOptionsMessage={noOptionsMessage}
            // defaultMenuIsOpen={true}
          />
        </div>
        {this.props.filterSelectBtn ? (
          <div className="ml_2px" >
            {this.props.filterSelectBtn}
          </div>
        ) : null}

      </div>

    );
  }
}

export default FilterMultiSelect;