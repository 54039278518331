//@flow
import * as React from 'react';
import type {sheetIndex} from "../../../services/flowTypes/componentFlowTypes";
import SectionDivider from "../../simple/Containers/SectionDivider/SectionDivider";
import text from "../../../services/localization/text";
import type {addServiceSheet} from "../../../services/flowTypes/dataFlowTypes";
import NLabel from "../../simple/FormComponents/NLabel/NLabel";
import NHeading2 from "../../simple/TextComponents/NHeading2/NHeading2";
import getCurrencyLabel from "../../../utils/getCurrencyLabel";
import SheetCalculatedItemsHeader from "./SheetCalculatedItemsHeader";
import SheetCalculatedItemView from "./SheetCalculatedItemView";
import getMaskedInputValue from "../../../utils/getMaskedInputValue";

type Props = {
  sheetIndex: sheetIndex,
  sheet: addServiceSheet
};

const SheetView = (props: Props) => {
  const currencyLabel = props.sheetIndex ? text.sheetLabels.secCurrency : text.sheetLabels.baseCurrency;
  const calculatedItems = props.sheet.calculatedItems.map((item, i) => {
    return (<SheetCalculatedItemView currency={props.sheet.currency} calculatedItem={item} key={'calculatedItem'+i}
                                     calculatedItemIndex={i}/>)
  });
  return (
    <div className="SheetView">
      {props.sheetIndex ? (
        <SectionDivider addClasses={'mt_6du mb_6du'}/>
      ) : null}
      <div className="flex_jc-sb mb_2du">
        <div>
          <NLabel>{currencyLabel}</NLabel>
          <NHeading2 addClasses={'mr_1du NHeading2_lh40'}>{getCurrencyLabel(props.sheet.currency)}</NHeading2>
        </div>
        <div className="ta_r">
          <NLabel>{text.sheetLabels.totalAmount}</NLabel>
          <div className="flex jc_fe">
            <NHeading2 addClasses={'mr_1du NHeading2_lh40'}>{getMaskedInputValue(props.sheet.totalAmount)}</NHeading2>
            <NHeading2 addClasses={'NHeading2_grey NHeading2_lh40'}>{getCurrencyLabel(props.sheet.currency)}</NHeading2>
          </div>
        </div>
      </div>
      <SheetCalculatedItemsHeader/>
      {calculatedItems}
    </div>
  );
};

export default SheetView;    