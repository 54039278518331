const options = [
  {label: "Tab1", value: "Tab1"},
  {label: "Tab2", value: "Tab2"},
  {label: "Tab3", value: "Tab3"}
];

const switcherValueStore = {
  valueObject: {
    value: "Tab1"
  }
};

const handleChange = (option) => {
  switcherValueStore.valueObject.value = option.value;
};

export const NSwitcherDefProps = {
  options: options,
  onChange: handleChange,
  value: switcherValueStore.valueObject.value,
  name: 'switcherName'
};