//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {
  isIgnored,
  isScrollToError,
  terminal,
  validationIsAllowed
} from "../../../../../../../../services/flowTypes/dataFlowTypes";
import type {
  onGetValidationStateFormProp,
  onGetValidationStateProp
} from "../../../../../../../../services/flowTypes/propFnFlowTypes";
import type {children} from "../../../../../../../../services/flowTypes/appFlowTypes";
import type {propError} from "../../../../../../../../services/flowTypes/componentFlowTypes";
import groupFinTermsValidationScheme from "../../../../../../../../configData/groupFinTermsValidationScheme";
import NFormGroup from "../../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import validationActions from "../../../../../../../../store/validation/validationActions";
import validationPatterns from "../../../../../../../../services/validationPatterns/validationPatterns";

type Props = {
  finTermGateIndex: number,
  terminals: terminal[],
  isIgnored: isIgnored,
  children: children,
  onGetValidationStateProp: onGetValidationStateProp,
  onGetValidationStateFormProp: onGetValidationStateFormProp,
  validationIsAllowed: validationIsAllowed,
  isScrollToError: isScrollToError,
  propError: propError
};

class TerminalsLengthValidator extends React.Component <Props> {
  validate = () => {
    const error = this.props.isIgnored ? '' :
      validationPatterns[groupFinTermsValidationScheme.gates.terminalsLength](this.props.terminals.length);
    this.props.onGetValidationStateProp('isScrollToError', false);
    this.props.onGetValidationStateFormProp(`gate${this.props.finTermGateIndex}terminalsLength`, error);
  };
  componentDidUpdate(prevProps){
    if (prevProps.finTermGateIndex !== this.props.finTermGateIndex
      || prevProps.terminals !== this.props.terminals
      || prevProps.isIgnored !== this.props.isIgnored) {
      this.validate();
    }
  }
  componentDidMount(){
    this.validate();
  }
  render() {
    const propError = this.props.validationIsAllowed ? this.props.propError : '';
    return (
      <NFormGroup isScrollToError={this.props.isScrollToError} propError={propError} addClasses={"Terminals"}>
        {this.props.children}
      </NFormGroup>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    validationIsAllowed: state.validation.validationIsAllowed,
    isScrollToError: state.validation.isScrollToError,
    propError: state.validation.form[`gate${ownProps.finTermGateIndex}terminalsLength`],
    terminals: state.organization.item.groupFinTermsEdit.gates[ownProps.finTermGateIndex].terminals,
    isIgnored: state.organization.item.groupFinTermsEdit.gates[ownProps.finTermGateIndex].isIgnored
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetValidationStateFormProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_FORM_PROP_SET, value: {
        prop: prop,
        data: data
      }}),
    onGetValidationStateProp: (prop, data) => dispatch({type: validationActions.VALIDATION_STATE_PROP_SET, value: {
        prop: prop,
        data: data
      }})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TerminalsLengthValidator);