//@flow
import * as React from 'react';
import HistoryFilters from "./HistoryFilters";
import HistoryList from "./HistoryList";
import HistoryPagination from "./HistoryPagination";
import HistoryFetcher from "./HistoryFetcher";
import './HistoryView.css';
import HistoryFilterFlags from "./HistoryFilterFlags";
import type {filtersFromParams, history} from "../../../services/flowTypes/appFlowTypes";
import type {setFiltersToUrl} from "../../../services/flowTypes/propFnFlowTypes";
import type {historyType} from "../../../services/flowTypes/componentFlowTypes";
import type {agentId, contractId, reportId, userId} from "../../../services/flowTypes/dataFlowTypes";

type Props = {
  filtersFromParams: filtersFromParams,
  setFiltersToUrl: setFiltersToUrl,
  historyType: historyType,
  itemId?: contractId | agentId | reportId | userId,
  routerHistory: history
};

const HistoryView = (props:Props) => {
  return (
    <div className={`HistoryView HistoryView_${props.historyType}`}>
      {props.itemId ? null : (
        <HistoryFilterFlags filtersFromParams={props.filtersFromParams} setFiltersToUrl={props.setFiltersToUrl}/>
      )}
      <HistoryFilters filtersFromParams={props.filtersFromParams} setFiltersToUrl={props.setFiltersToUrl}
                      historyType={props.historyType} />
      <HistoryList logIdFromUrl={props.filtersFromParams.logId}/>
      <HistoryPagination filtersFromParams={props.filtersFromParams} setFiltersToUrl={props.setFiltersToUrl}
                         isInitOnMount={!!props.itemId}/>
      <HistoryFetcher filtersFromParams={props.filtersFromParams} historyType={props.historyType} itemId={props.itemId}
                      routerHistory={props.routerHistory}/>
    </div>
  );
};
export default HistoryView;