//@flow
import * as React from 'react';
import './TextActionButton.css';
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import type {children, disabled, id, onClick} from "../../../../services/flowTypes/appFlowTypes";
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";

type Props = {
  addClasses?: addClasses,
  children: children,
  onClick: onClick,
  id?: id,
  dataUiTestId?: string,
  disabled?: disabled
};
//TODO: add test
const TextActionButton = (props: Props) => {
  return (
    <button type="button" id={props.id} className={baseClass("TextActionButton ", props.addClasses)}
            onClick={props.onClick} data-ui-test-id={props.dataUiTestId} disabled={props.disabled}>
      {props.children}
    </button>
  );
};

export default TextActionButton;