export const RowMenuDefProps = {
  menuItemsData: [
    {linkTo: "/", linkName: "Row Menu Item 1", isPermitted: true},
    {linkTo: "/", linkName: "Row Menu Item 2", isPermitted: true},
    {linkTo: "/", linkName: "Row Menu Item 3", isPermitted: true},
    {linkTo: "/", linkName: "Row Menu Item 4", isPermitted: true},
    {linkTo: "/", linkName: "Row Menu Item 4", isPermitted: true},
    {linkTo: "/", linkName: "Row Menu Item 4", isPermitted: true},
    {linkTo: "/", linkName: "Row Menu Item 4", isPermitted: true},
  ]
};