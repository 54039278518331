//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {
  elementRefType,
  formFieldName,
  formFieldValue,
  id, inputValue,
  onBlur,
  onFocus, readOnly
} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import './SingleTagInput.css';
import preventEventPropagation from "../../../../utils/preventEventPropagation";
import type {tagIndex} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  value: formFieldValue,
  name?: formFieldName,
  onChange?: onChange,
  onFocus?: onFocus,
  onBlur?: onBlur,
  readOnly?: readOnly,
  tagIndex: tagIndex
};
type State = {
  singleTagInputValue: inputValue
};

class SingleTagInput extends React.Component <Props, State> {
  ref: elementRefType;
  constructor(props:Props) {
    super(props);
    this.state = {
      singleTagInputValue: ''
    };
    this.ref = React.createRef();
  }
  handleKeyDown = (e) => {
    preventEventPropagation(e);
    if (e.key === 'Enter') {
      this.ref.current.blur();
    }
  };
  onSingleTagInputChange = (e:SyntheticEvent<HTMLInputElement>) => {
    this.setState({singleTagInputValue: e.currentTarget.value});
  };
  componentDidMount(): * {
    this.setState({singleTagInputValue: this.props.value});
  }
  render() {
    const onBlur = (e) => this.props.onBlur(this.state.singleTagInputValue, this.props.tagIndex);
    return (
      <div className="SingleTagInput__container">
        <div className="SingleTagInput__hidden-value">{this.state.singleTagInputValue}</div>
        <input id={this.props.id} value={this.state.singleTagInputValue} name={this.props.name} ref={this.ref}
               onChange={this.onSingleTagInputChange} onBlur={onBlur}
               onKeyDown={this.handleKeyDown}
               className={baseClass("SingleTagInput", this.props.addClasses)}
               readOnly={this.props.readOnly}/>
      </div>
    );
  }
}
export default SingleTagInput;