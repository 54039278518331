//@flow
import historyLocationsActions from './historyLocationsActions';
import type {action} from "../../services/flowTypes/appFlowTypes";
import update from 'immutability-helper';
import type {historyLocations} from "../../services/flowTypes/dataFlowTypes";

type reducerAction = action;
const initialState = [];

const historyLocationsReducer = (state:historyLocations = initialState, action:reducerAction) => {
  switch (action.type) {
    case historyLocationsActions.LOCATION_ADD:
      return update(state, {$push: [action.value]});
    default:
      return state;
  }
};

export default historyLocationsReducer;