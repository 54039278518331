//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../../service/RenderOnReady/RenderOnReady";
import type {errorObject} from "../../../../services/flowTypes/appFlowTypes";
import type {inOrgContextSearchRequest} from "../../../../services/flowTypes/dataFlowTypes";
import searchInOrgContextResponseActions
  from "../../../../store/organization/searchInOrgContextResponse/searchInOrgContextResponseActions";
import type {
  onFinishRequest,
  onGetSearchInOrgContextResponse, onInitSearchInOrgContextState,
  onStartRequest
} from "../../../../services/flowTypes/propFnFlowTypes";
import api from "../../../../services/apiInstance/api";
import apiErrorHandler from "../../../../services/apiInstance/apiErrorHandler";
import debounce from 'lodash.debounce';

type Props = {
  requestBody: inOrgContextSearchRequest,
  onGetSearchInOrgContextResponse: onGetSearchInOrgContextResponse,
  onInitSearchInOrgContextState: onInitSearchInOrgContextState,
  onStartRequest: onStartRequest,
  onFinishRequest: onFinishRequest
};
type State = {
  errorObject: errorObject,
  // isReadyToRender: isReadyToRender
};

class SearchInOrgContextFetcher extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorObject: null,
      // isReadyToRender: true
    };
    this.debounceSearchRequest = debounce(this.getSearchResponse, 500);
  }
  getSearchResponse = () => {
    // this.setState({isReadyToRender: false});
    this.props.onStartRequest();
    api.searchInOrgContext(this.props.requestBody)
      .then((response) => {
        this.props.onGetSearchInOrgContextResponse(response.data);
        this.props.onFinishRequest();
        // this.setState({isReadyToRender: true});
      })
      .catch((error) => {
        this.props.onFinishRequest();
        this.setState({
          errorObject: apiErrorHandler(error),
          // isReadyToRender: true
        });
      });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.requestBody !== this.props.requestBody) {
      if (this.props.requestBody.text.length > 2) {
        this.debounceSearchRequest();
      } else {
        this.props.onInitSearchInOrgContextState();
      }
    }
  }
  render() {
    return (
      <RenderOnReady isReadyToRender={true} errorObject={this.state.errorObject}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    requestBody: state.organization.searchInOrgContext.requestBody
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitSearchInOrgContextState: () => dispatch({
      type: searchInOrgContextResponseActions.SEARCH_IN_ORG_CONTEXT_RESPONSE_INIT_STATE_SET}),
    onGetSearchInOrgContextResponse: (responseData) => dispatch({
      type: searchInOrgContextResponseActions.SEARCH_IN_ORG_CONTEXT_RESPONSE_GET, value: responseData})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchInOrgContextFetcher);    