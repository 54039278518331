//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {user} from "../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../services/localization/text";
import Can from "../../../../../../services/permissions/Can";
import PropValueView from "../../../../../../components/composed/PropValueView/PropValueView";
import StatusLabel from "../../../../../../components/simple/TextComponents/StatusLabel/StatusLabel";
import formatDate from "../../../../../../utils/formatDate";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import SendActivationLink from "./SendActivationLink/SendActivationLink";
// import NFormGroup from "../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
// import NLabel from "../../../../../../components/simple/FormComponents/NLabel/NLabel";
// import FormTagSelect from "../../../../../../components/simple/FormComponents/FormTagSelect/FormTagSelect";
import formatTime from "../../../../../../utils/formatTime";
import type {locationType} from "../../../../../../services/flowTypes/appFlowTypes";
import {globalPermissionsStrValues} from "../../../../../../configData/permissionsStrValues";
import AdaptiveFormContainer
  from "../../../../../../components/simple/Containers/AdaptiveFormContainer/AdaptiveFormContainer";
import "./UserMainDataView.css";

type Props = {
  mainData: user,
  location: locationType
};

class UserMainDataView extends React.Component <Props> {
  render() {
    return (
      <div className="UserMainDataView">
        <NCard>
          <AdaptiveFormContainer fieldsArr={[
            <PropValueView label={text.name} value={this.props.mainData.name} key={"name"}/>,
            <PropValueView label={text.lastSeen}
                           value={
                             this.props.mainData.lastSeen ?
                               (formatDate(this.props.mainData.lastSeen) + " " + formatTime(this.props.mainData.lastSeen))
                               : "-"
                           }
                           key={"lastSeen"}/>
          ]} containerMode={"twoHalfCardWidthFields"} addClasses={"mb_3du"}/>
          <AdaptiveFormContainer fieldsArr={[
            <PropValueView label={text.email} value={this.props.mainData.email} key={"email"}/>,
            <PropValueView label={text.phone} value={this.props.mainData.phone} key={"phone"}/>
          ]} containerMode={"twoHalfCardWidthFields"} addClasses={"mb_3du"}/>
          <AdaptiveFormContainer fieldsArr={[
            <PropValueView label={text.userOrganizationsLabel} addClasses={"w_half-card"}
                           value={this.props.mainData.userPermissions.length}/>,
            <PropValueView label={text.status} addClasses={"w_half-card"} value={
              <div className="flex">
                <StatusLabel addClasses={`StatusLabel_${this.props.mainData.status} StatusLabel_bold mr_2du`}>
                  {text.userStatus[this.props.mainData.status]}
                </StatusLabel>
                <Can do={'useGlobalPermission'} on={globalPermissionsStrValues.user.update}>
                  <SendActivationLink userId={this.props.mainData.id}/>
                </Can>
              </div>
            }/>
          ]} containerMode={"twoHalfCardWidthFields"} addClasses={"mb_3du"}/>
          {/*{this.props.mainData.globalPermissions && this.props.mainData.globalPermissions.length ? (*/}
          {/*  <NFormGroup>*/}
          {/*    <NLabel>{text.globalPermissionsLabel}</NLabel>*/}
          {/*    <FormTagSelect disabled={true} value={this.props.mainData.globalPermissions} />*/}
          {/*  </NFormGroup>*/}
          {/*) : (*/}
          {/*  <PropValueView label={text.globalPermissionsLabel} value={''} />*/}
          {/*)}*/}
        </NCard>
      </div>

  );
  }
}
const mapStateToProps = state => {
  return {
    mainData: state.user.mainData,
  };
};
export default connect(mapStateToProps, null)(UserMainDataView);
